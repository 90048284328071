import { useContext, useEffect } from 'react';
import useUserSettings from './useUserSettings';
import { Filter } from '../models';
import { DataStore } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { NavigationContext, navigationActionConstants } from '../contexts/navigation';

/**
 * Keeps filter, URL query params, NavigationContext and UserSetting in sync
 * NOTE: it prioritizes URL query parameters, then uses UserSetting
 * NOTE2: to ensure functionality of URL query parameters, it ignores UserSetting subscription updates
 * @param {object} filters filter that comes out of Filter component
 * NOTE: this should be converted from DataStore Filter model, id set to friendlyId and location parsed
 * @param {function} setFilter filter setter
 * @param {object} TYPE UFO (unidentified fucking object) 😰, refer to MovementActionList or BerthStatusList for details
 * @param {string} userSettingIdField field name of filter ID field in UserSetting
 */
const useSyncFilter = (filters, setFilter, TYPE, userSettingIdField) => {
  const { userSetting, updateUserSettings } = useUserSettings();
  const [navigationContext, dispatchNavigationContext] = useContext(NavigationContext);
  const location = useLocation();
  const history = useHistory();

  // initializes filter
  useEffect(() => {
    if (Array.isArray(userSetting)) return;
    if (filters) return;
    // resets filter
    const resetFilter = () => {
      setFilter && setFilter(prev => prev?.id !== TYPE.id ? TYPE.AllFilter : prev);
    };
    // attempts to set filter from URL query params and returns true on success
    const tryQueryParams = async () => {
      const queryParams = new URLSearchParams(location.search);
      const urlFriendlyId = queryParams.get(TYPE.full);
      if (!urlFriendlyId) {
        return false;
      } else if (urlFriendlyId === TYPE.id) {
        resetFilter();
        return true;
      } else {
        const newFilter = (await DataStore.query(Filter, c => c.friendlyId("eq", urlFriendlyId))).pop();
        if (newFilter) {
          // need to parse stringified location and inject friendlyId to prevent Filter component from casting Explosion
          setFilter && setFilter({ ...newFilter, location: JSON.parse(newFilter.location), id: newFilter.friendlyId });
        }
        return Boolean(newFilter);
      }
    };
    // attempts to set filter from UserSetting and returns true on success
    const tryUserSetting = async () => {
      if (!userSetting || !userSetting[userSettingIdField]) return false;
      const newFilter = await DataStore.query(Filter, userSetting[userSettingIdField]);
      if (newFilter) {
        // need to parse stringified location and inject friendlyId to prevent Filter component from casting Explosion
        setFilter && setFilter({ ...newFilter, location: JSON.parse(newFilter.location), id: newFilter.friendlyId });
      }
      return Boolean(newFilter);
    };
    (async () => {
      // sequentially try to set filter
      await tryQueryParams() || await tryUserSetting() || resetFilter();
    })();
  }, [userSetting, TYPE]);

  // syncs UserSetting with filter
  useEffect(() => {
    if (!filters || Array.isArray(userSetting)) return;
    // reset filter (unset)
    if (filters.id === TYPE.id && userSetting && userSetting[userSettingIdField]) {
      updateUserSettings(userSettingIdField, null);
    } else {
      (async () => {
        // filter ids come as friendlyId, don't ask why (;´Д｀)
        const newFilter = (await DataStore.query(Filter, c => c.friendlyId("eq", filters.id))).pop();
        if (newFilter && (!userSetting || newFilter.id !== userSetting[userSettingIdField])) {
          updateUserSettings(userSettingIdField, newFilter.id);
        }
      })();
    }
  }, [filters]);

  // syncs URL params with current filter
  useEffect(() => {
    if (!filters) return;
    const queryParams = new URLSearchParams(location.search);
    const urlFriendlyId = queryParams.get(TYPE.full);
    if (urlFriendlyId && urlFriendlyId === filters.id) return;
    queryParams.set(TYPE.full, filters.id);
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.push(newUrl);
  },[dispatchNavigationContext, filters, history, location.pathname]);


  // syncs NavigationContext.listView query params with current filter
  useEffect(() => {
    if (!filters) return;
    const navigationContextQueryParams = new URLSearchParams(navigationContext.defaultView.substring(navigationContext.defaultView.indexOf("?")));
    if (navigationContextQueryParams.get(TYPE.full) !== filters.id) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set(TYPE.full, filters.id);
      const newUrl = `${location.pathname}?${queryParams.toString()}`;
      dispatchNavigationContext({ type: navigationActionConstants.SET_DEFAULT_VIEW, payload: newUrl });
    }
  },[dispatchNavigationContext, filters]);
};

export default useSyncFilter;