export const TRANSLATIONS_EN_GB = {
  Common: {
    Buttons: {
      Create: "CREATE",
      Update: "UPDATE",
      Add: "Add",
      DiscardChanges: "Discard Changes",
      Confirm: "Confirm",
      Cancel: "Cancel",
      Close: "Close",
      Save: "Save",
      Request: "Request",
      Export: "Export",
      Leave: "Leave",
      Now: "Now"
    },
    Labels: {
      M: "m",
      M3: "m\u00B3",
      Feet: "ft",
      T: "t",
      KW: "kW",
      HP: "hp",
      KN: "kn",
      NMI: "nmi",
      MB: "mb",
      Each: "",
      C: "C",
      F: "F",
      Unspecified: "?",
      UnsavedChanges: "Unsaved Changes",
      IncomingChanges: "Incoming Changes",
      Nominal: "Nominal",
      All: "All",
      CustomAction: "{{- actionName}} action",
      Comments: "Comment",
      More: "More",
      Hours: "Hours",
      Minutes: "Minutes",
      Days: "Days"
    },
    Errors: {
      TimeCheckActionBefore: "The action\'s planned time must be after the previous action\'s actual time",
      TimeCheckActionAfter: "The action\'s planned time must be before the next action\'s planned time",
      TimeCheckArrivalCheck: "The arrival must be before any other action\'s planned time",
      TimeCheckDepartureCheck: "The departure must be after any other action\'s planned time",
      TimeCheckShiftBefore: "The shift\'s planned time must be after the previous action",
      TimeCheckShiftAfter: "The shift\'s planned time must be before the next action",
      TimeCheckArrivalRequest: "The requested time is after a departure request",
      TimeCheckDepartureRequest: "The requested time is before an arrival request",
      TimeCheckArrivalBeforeDeparture: "The arrival time must be before the departure time",
      TimeCheckDepartureAfterArrival: "The departure time must be after the arrival time",
      PastDate: "You have entered a date in the past",
      IncomingChangesWarning:"These details have been changed by someone else since you opened them. Please click Confirm to refresh the page and discard your changes.",
      InvalidDateFormat: "Invalid date format",
      InvalidDateFormatUse: "Invalid date format. Use {{- format}}",
      ErrorCode: "Error code: {{errorCode}}"
    }
  },
  ActionMovementType: {
    Labels: {
      Arrival: "Arrival",
      Departure: "Departure",
      BerthShiftArrival: "Berth Shift Arrival",
      BerthShiftDeparture: "Berth Shift Departure",
      ShiftArrival: "Shift arrival",
      ShiftDeparture: "Shift departure",
      All: "All"
    }
  },
  ActionState: {
    Labels: {
      Planned: "Planned",
      InProgress: "In progress",
      Completed: "Completed",
      Cancelled: "Cancelled",
      Deleted: "Deleted",
      ApprovalDeclined: "Approval Declined",
      ApprovalPending: "Pending Approval",
      Requested: "Requested",
      Created: "Created",
      ArrivalRequested: "Arrival requested",
      ArrivalApprovalPending: "Arrival approval pending",
      ArrivalApprovalDeclined: "Arrival approval declined",
      ArrivalPlanned: "Planned arrival",
      ArrivalInProgress: "Arrival in progress",
      ArrivalCompleted: "Arrival completed",
      ArrivalCancelled: "Arrival cancelled",
      ArrivalDeleted: "Arrival deleted",
      DepartureRequested: "Departure requested",
      DepartureApprovalPending: "Departure approval pending",
      DepartureApprovalDeclined: "Departure approval declined",
      DeparturePlanned: "Planned departure",
      DepartureInProgress: "Departure in progress",
      DepartureCompleted: "Departure completed",
      DepartureCancelled: "Departure cancelled",
      DepartureDeleted: "Departure deleted",
      ShiftArrivalRequested: "Shift arrival requested",
      ShiftArrivalApprovalPending: "Shift arrival approval pending",
      ShiftArrivalApprovalDeclined: "Shift arrival approval declined",
      ShiftArrivalPlanned: "Planned shift arrival",
      ShiftArrivalInProgress: "Shift arrival in progress",
      ShiftArrivalCompleted: "Shift arrival completed",
      ShiftArrivalCancelled: "Shift arrival cancelled",
      ShiftArrivalDeleted: "Shift arrival deleted",
      ShiftDepartureRequested: "Shift departure requested",
      ShiftDepartureApprovalPending: "Shift departure approval pending",
      ShiftDepartureApprovalDeclined: "Shift departure approval declined",
      ShiftDeparturePlanned: "Planned shift departure",
      ShiftDepartureInProgress: "Shift departure in progress",
      ShiftDepartureCompleted: "Shift departure completed",
      ShiftDepartureCancelled: "Shift departure cancelled",
      ShiftDepartureDeleted: "Shift departure deleted",
      AgentHandoverRequested: "Agent handover requested",
      AgentHandoverApprovalPending: "Agent handover approval pending",
      AgentHandoverApprovalDeclined: "Agent handover approval declined",
      AgentHandoverPlanned: "Planned agent handover",
      AgentHandoverInProgress: "Agent handover in progress",
      AgentHandoverCompleted: "Agent handover completed",
      AgentHandoverCancelled: "Agent handover cancelled",
      AgentHandoverDeleted: "Agent handover deleted",
      CustomActionRequested: "{{action}} requested",
      CustomActionApprovalPending: "{{action}} approval pending",
      CustomActionApprovalDeclined: "{{action}} approval declined",
      CustomActionPlanned: "Planned {{action}}",
      CustomActionInProgress: "{{action}} in progress",
      CustomActionCompleted: "{{action}} completed",
      CustomActionCancelled: "{{action}} cancelled",
      CustomActionDeleted: "{{action}} deleted"
    }
  },
  ActionType: {
    Labels: {
      Name: "Name",
      Movement: "Movement",
      Event: "Event",
      ActionType: "action type"
    }
  },
  Admin: {
    Labels: {
      Administration: "Administration",
      AISStatusMonitoring: "AIS Status Monitoring",
      Automation: "Automation",
      CertificateType: "Certificate types",
      GeneralSettings: "General settings",
      SecondaryApproval: "Secondary approval",
      Notifications: "Notifications",
      PortDefinitions: "Port definitions",
      OperationalSettings: "Operational settings",
      ActionTypes: "Action types",
      Contacts: "Contacts",
      PortCallCategories: "Port call categories",
      Tariffs: "Tariffs",
      TariffBook: "Tariff book",
      TariffUnits: "Tariff units",
      CargoType: "Cargo types"
    }
  },
  AdminActionType: {
    Labels: {
      ActionTypeDefaults: "Action type defaults",
      ActionTypeDefaultsHelp: "New actions of this type will be automatically pre-populated with defaults provided below",
      MovementArrival: "Movement (Arrival)",
      MovementDeparture: "Movement (Departure)",
      MovementShiftArrival: "Movement (Shift Arrival)",
      MovementShiftDeparture: "Movement (Shift Departure)",
      DeletedHelp: "This action type has been deleted",
      InvalidTariff: "There are chargeable items with an invalid tariff associated with this action"
    }
  },
  AdminApproval: {
    Labels: {
      EnableSecondaryApproval: "Enable secondary approval",
      EnableSecondaryApprovalHelp: "Enabling secondary level approval ensures port calls are reviewed by an authorised user prior to processing",
      VesselExceptionList: "Vessel Exception List"
    }
  },
  AdminAutomation: {
    Labels: {
      NoAutomationRules: "No automation rules are defined",
      NoAutomationRulesHelp: "Please contact Marlin SmartPort support for help",
      EnablePortCallAutomation: "Enable port call automation",
      EnablePortCallAutomationHelp: "Enable port call automation to automatically create port calls and record movement changes",
      NoVesselsSpecified: "No vessels specified",
      UseAllVessels: "Use all vessels",
      UseAllVesselsHelp: "Apply automation to all vessels that enter the port"
    }
  },
  AdminGeneralSettings: {
    Labels: {
      ApplicationCurrency: "Application currency",
      ApplicationCurrencyHelp: "Configure application-wide currency",
      Language: "Language",
      LanguageHelp: "Configure application-wide locale",
      FiltersTitle: "Movement and berth filters",
      AdminFilters: "Restrict filter editing to Administration users"
    }
  },
  AdminModelEditor: {
    Labels: {
      CreateEntry: "Create a new entry",
      DeleteEntry: "Delete entry",
      DeleteEntryMessage: "Are you sure you want to delete this item? You are about to perform an irreversible action.",
      ReadOnly: "Read only",
      ConfirmSave: "Confirm Save",
      DuplicateEntry: "Duplicate entry",
      CustomDeleteEntry: "Delete {{- itemName}}",
      CustomDuplicateEntry: "Duplicate {{- itemName}}",
      UnsavedChangesMessage: "You have unsaved changes, are you sure you want to leave?"
    }
  },
  AdminTariff: {
    Buttons: {
      AddTariff: "Add Tariff",
      AddActionTypeItem: "Add Item"
    },
    Labels: {
      DuplicateTariff: "A tariff with that code already exists",
      Name: "Name",
      Description: "Description",
      DateValidFrom: "Valid from",
      DateValidTo: "Valid to",
      Tariffs: "Tariffs",
      DefaultChargeables: "Default chargeable items",
      TariffBookSaveWarning: "Changing the tariff book's 'Valid from' or 'Valid to' dates will not update the unit cost of planned or in-progress port calls using these tariffs.",
      AddTariff: "Add Tariff",
      UpdateTariff: "Update Tariff",
      FilterByCode: "Filter by code",
      ActionType: "Associated action type",
      NumChargeableItems: "Num. chargeable items",
      DeleteTariffTitle: "Delete Tariff",
      DeleteChargeableItemTitle: "Delete Item",
      DeleteChargeableItemMessage: "Are you sure you want to delete the default chargeable items for '{{- chargeableItem}}'?",
      DeleteTariffItemMessage: "Are you sure you want to delete the tariff '{{- tariffName}}'?. If this tariff is used in any default chargeable items, then these will also be deleted.",
      TariffBook: "tariff book"
    },
    Errors: {
      InvalidDate: "Valid to must be after Valid from",
      MissingName: "Name must be specified"
    }
  },
  AdminPortCallCategory: {
    Labels: {
      PortCallCategory: "port call category"
    }
  },
  AdminCargoType: {
    Labels: {
      CargoType: "cargo type"
    }
  },
  AdminNotification: {
    Error: {
      ActionTypeNotSpecified: "Action type must be specified",
      EventTypeNotSpecified: "Event type must be specified",
      MissingValues: "Incomplete definition, missing values"
    },
    Labels: {
      Name: "Name",
      Description: "Description",
      EnableNotification: "Enable notification",
      EnableNotifications: "Enable notifications",
      EnableReminder: "Enable reminder",
      Recipents: "Send to",
      Trigger: "Trigger",
      ActionType: "Action type",
      TriggerType: "Trigger type",
      ReminderTime: "Reminder time",
      PortCallCategory: "Filter by port call category",
      Category: "Category",
      PortCallTags: "Filter by port call tags",
      Tags: "Tags",
      All: "All tags",
      Any: "Any tags",
      Location: "Filter by location",
      ActionWithoutLocation: "Actions without location",
      Enabled: "Enabled",
      Status: "Status",
      SelectAction: "Select action",
      SelectEvent: "Select event",
      EventCreated: "Created",
      EventStarted: "Started",
      EventCompleted: "Completed",
      EventPendingApproval: "Pending approval",
      EventApproved: "Approved",
      EventDeclined: "Declined",
      EventRequested: "Requested",
      EventCancelled: "Cancelled",
      EventAwaitingApproval: "Awaiting approval",
      EventAwaitingFurtherApproval: "Awaiting further approval",
      TimeBefore: "Upcoming",
      TimeAfter: "Late",
      MovementArrival: "Movement (Arrival)",
      MovementDeparture: "Movement (Departure)",
      MovementShiftArrival: "Movement (Shift Arrival)",
      MovementShiftDeparture: "Movement (Shift Departure)",
      IncompleteWarningMsg: "Email settings are not fully configured to allow notifications to be sent. Please contact Marlin SmartPort support for help",
      NotificationsTab: "Notifications",
      RemindersTab: "Reminders",
      ReminderBefore: "Before",
      ReminderAfter: "After",
      ReminderBeforePlannedTime: "Before planned time of action",
      ReminderAfterPlannedTime: "After planned time of action",
      Notification: "notification",
      Reminder: "reminder"
    }
  },
  TimePeriodSelect: {
    Errors: {
      InvalidPeriod: "Time perdiod must be specified"
    },
    Labels: {
      TimePeriod: "Time period",
      TimeUnit: "Unit"
    }
  },
  AppHeader: {
    Labels: {
      CurrentDateAndTime: "Current date & time",
      TidalForecast: "Tidal forecast",
      TestTidalForecast: "Test tidal forecast",
      LoadingWeatherInformation: "Loading weather information",
      ErrorLoadingWeatherInformation: "Error loading weather information",
      LoadingTidalInformation: "Loading tidal information",
      ErrorRetrievingTidalInformation: "Error retrieving tidal information",
      Language: "Language",
      LogOut: "Log out"
    }
  },
  Category: {
    Labels: {
      Category: "Category",
      Name: "Name",
      Description: "Description"
    }
  },
  EditorContentTabs: {
    Labels: {
      Details: "Details",
      Requests: "Requests",
      LogBook: "Logbook"
    }
  },
  ExportPortCalls: {
    Labels: {
      VesselName: "Vessel Name",
      VesselLength: "Length",
      Berth: "Berth",
      Loading:"Exporting Port Calls ...",
      PreparingDocument: "Please wait while document is being prepared",
      ActualTimeOfArrival: "Actual time of arrival",
      ActualTimeOfDeparture: "Actual time of departure",
      ExportPortCalls: "Export port calls",
      ExportConfirmMessage_one: "{{count}} port call will be exported as {{fileType}}.",
      ExportConfirmMessage: "{{count}} port calls will be exported as {{fileType}}.",
      Preview: "Preview",
      Configure: "Configure",
      SelectColumns: "Select columns for export",
      PreviewColumns: "Preview of selected columns",
      PreviewSampleDataSet: "This is an example dataset to illustrate the preview of selected columns.",
      SelectUnits: "Select units",
      Length: "Length",
      Feet: "Feet",
      Metres: "Metres",
      PreviewCaption: "Drag drop colums to change order"
    },
    Errors: {
      Loading:"Unable to Export PortCall",
      ExternalError:"AWS External Service {{error}}",
      TimeoutError:"Exceeded Lamba Execution timed out"
    }
  },
  ExportVessels: {
    Labels: {
      ExportVessels: "Export vessels",
      Vessels: "Vessels",
      ExportConfirmMessage_one: "{{count}} vessel will be exported as {{fileType}}.",
      ExportConfirmMessage: "{{count}} vessels will be exported as {{fileType}}."
    }
  },
  NavBarVertical: {
    Labels: {
      NoRequests: "No Requests in Inbox",
      Requests: "Inbox",
      RequestInbox: "Inbox",
      CreatePortCall: "Create port call",
      CreatePortCallDisabled: "Create port call (disabled for non port controller users)",
      RequestPortCall: "Request port call",
      BoardView: "Board view",
      VoyageBoardView: "Voyage board view",
      MapView: "Map view",
      DataBrowser: "Data browser",
      PortCalls: "Port calls",
      Vessels: "Vessels",
      Voyages: "Voyages",
      Dashboard: "Dashboard",
      Documentation: "Documentation",
      Administration: "Administration"
    }
  },
  PortCallDetails: {
    Labels: {
      Itinerary: "Itinerary",
      PortCallCategory: "Port Call Category",
      ReportingCategory: "Reporting Category",
      Remarks: "Remarks",
      Tags: "Tags",
      Loading:"Loading Port Call details ...",
      PortCallTags: "Port call tags",
      Attachments: "Attachments",
      UnsavedChangesMessage: "There are unsaved changes in the current editor. Save changes before updating further details",
      OverwritePortCallTags: "Overwrite Port Call Tags",
      OverwritePortCallTagsMessage: "Existing port call tags will be overwritten with this vessel change. Would you like to overwrite with new tags?",
      OverwritePortCallCategory: "Overwrite Port Call Category",
      OverwritePortCallCategoryMessage: "Existing port call category will be overwritten with this vessel change. Would you like to overwrite with new category?",
      LeavePromptMessage: "You have unsaved changes, are you sure you want to leave?",
      ConfirmChangeToCargo: "Confirm change to Cargo"
    },
    Errors: {
      Loading:"Unable to load Port Call details",
      AppSyncError:"AppSync {{error}}"
    }
  },
  PortCallList: {
    Labels: {
      PortCalls: "Port calls",
      SearchResult:"Found {{rowCount}} port calls",
      NoMoreData: "No more data available.",
      Uncategorised: "Uncategorised",
      Port: "PORT",
      Category: "CATEGORY",
      Order: "Order",
      Tags: "TAGS",
      Vessel: "VESSEL",
      From: "FROM",
      To: "TO",
      OldestFirst: "Oldest First",
      NewestFirst: "Newest First",
      DepartureNotScheduled: "Departure not scheduled",
      ArrivalNotScheduled: 'Arrival not scheduled',
      ShowAll: "Show All",
      Status: "Status",
      SearchByKeyword: "Search by keyword"
    }
  },
  PortCallStatus: {
    Labels: {
      Requested: "Requested",
      Prearrival: "Prearrival",
      Arrival: "Arrival",
      InPort: "In port",
      Departure: "Departure",
      Cancelled: "Cancelled",
      Deleted: "Deleted",
      Closed: "Closed",
      undefined: "Undefined"
    }
  },
  Tariff: {
    Labels: {
      Code: "Code",
      TariffUnit: "Tariff unit",
      Description: "Description",
      UnitCost: "Unit cost",
      Currency: "Currency",
      Notes: "Notes",
      MinimumCost: "Minimum cost (optional)",
      MinimumCostShort: "Min.",
      MinimumCostShort2: "Minimum cost",
      ActionTypes: "Action types",
      ActionTypesHelp: "This tariff will be available only for specified action types",
      Unspecified: "Unspecified"
    }
  },
  TariffUnit: {
    Labels: {
      Name: "Name",
      SecondaryUnitName: "Secondary unit name",
      TariffUnit: "tariff unit"
    }
  },
  Vessel: {
    Labels: {
      Vessel: "Vessel",
      Name: "Name",
      TemporaryName: "Temporary name",
      IMO: "IMO",
      IMONumber: "IMO Number",
      MMSI: "MMSI",
      CallSign: "Call sign",
      Flag: "Flag",
      YearBuilt: "Year built",
      Number: "Number",
      Type: "Type",
      DeadweightTonnage: "Deadweight tonnage",
      Length: "Length overall",
      Beam: "Maximum beam",
      Draught: "Draught",
      NominalDraught: "Nominal draught",
      GrossTonnage: "Gross tonnage",
      NetTonnage: "Net tonnage",
      PortOfRegistry: "Port of registry",
      Dimensions: "Dimensions",
      AgentName: "Agent name",
      Identity: "Identity",
      Details: "Details",
      VesselDetails: "Vessel details",
      Tonnage: "Tonnage",
      VesselAgent: "Vessel Agent",
      PrimaryPropulsion: "Primary Propulsion",
      Propulsion: "Propulsion",
      PropulsionType: "Propulsion type",
      NumPropellers: "No. propellers",
      Direction: "Direction",
      TotalPower: "Total power",
      Units: "Units",
      Rudder: "Rudder",
      RudderType: "Rudder type",
      NumRudders: "No. rudders",
      ForwardAzimuth: "Forward Azimuth",
      AftAzimuth: "Aft Azimuth",
      ForwardTunnelThrusters: "Forward Tunnel Thrusters",
      AftTunnelThrusters: "Aft Tunnel Thrusters",
      NumThrusters: "No. thrusters",
      MastersName: "Master\'s name",
      NumCrew: "Number of crew",
      NumPassengers: "Number of passengers",
      ForwardDraught: "Forward draught",
      AftDraught: "Aft draught",
      SailingDraught: "Sailing draught",
      AirDraught: "Air draught",
      ArrivalDraught: "Arrival draught",
      DepartureDraught: "Departure draught",
      PortCallID: "Port Call ID: {{portCallId}}",
      VoyageId: "Voyage ID: {{voyageId}}",
      Certificates: "Certificates",
      LastVesselPosition: "Vessel position last updated: {{positionTimestamp}} ({{age}} ago)",
      CentreVessel: "Centre vessel"
    },
    Errors:{
      VesselPositionNotFound:"Vessel position not found"
    }
  },
  Map: {
    Labels: {
      AISShipType: "AIS ship type",
      Navigation: "Navigation",
      Destination: "Destination",
      ETA: "ETA",
      Speed: "Speed",
      Heading: "Heading",
      CMG: "CMG",
      Latitude: "Latitude",
      Longitude: "Longitude",
      Time: "Time",
      LastUpdated: "Last updated",
      Age: "Age"
    }
  },
  VesselTooltip: {
    Labels: {
      ArrivalDraught: "Arrival sailing draught",
      DepartureDraught: "Departure sailing draught"
    }
  },
  RequestDetails: {
    Labels: {
      WithdrawRequest: "Withdraw request",
      WithdrawDialogTitle: "Withdraw {{type}} request",
      WithdrawDialogMessage: "Are you sure you want to withdraw this request?",
      LastUpdated: "Last updated",
      UpdateRequest: "Update request",
      ShowBerthUtilization: "Show berth utilization",
      HideBerthUtilization: "Hide berth utilization",
      Current: "Current",
      Requested: "Requested",
      CurrentTime: "Current time",
      CurrentLocation: "Current location",
      RequestedTime: "Requested time",
      Time: "time",
      Location: "Location",
      ChangeTime: "Change time",
      ErrorInvalidTime: "Invalid time",
      ErrorCommentRequired: "Comment is required when rejecting request",
      ErrorArrivalApproved: "Arrival request must be approved",
      ErrorDepartureAfterArrival: "Departure must come after last arrival",
      ErrorArrivalBeforeAction: "Arrival must come before other actions",
      Comment: "Comment",
      HideComments: "Hide <number/> comments",
      ShowComments: "Show <number/> more comments"
    }
  },
  RequestInbox: {
    Labels: {
      Inbox: "Inbox",
      RequestHeadingsMovement: "Movement",
      RequestHeadingsArrival: "Arrival",
      RequestHeadingsDeparture: "Departure",
      RequestHeadingsPortCall: "Port Call",
      RequestHeadingsRemarks: "Remarks",
      NoRequests: "No Requests",
      AcknowledgeUpdate: "Acknowledge update",
      Comments: "Comments",
      UpdatedAt: "Updated At",
      ShowDetails: "Show Details",
      Source: "Source",
      Date: "Date",
      Notification: "Notification",
      RequestType: "Request Type",
      Invalid: "Invalid"
    }
  },
  RequestTooltip: {
    Labels: {
      RequestType: "Request Type",
      Source: "Source",
      UpdatedAt: "Updated At",
      Status: "Status"
    }
  },
  RequestState: {
    Labels: {
      Pending: "Pending",
      Rejected: "Rejected",
      Approved: "Approved",
      Withdrawn: "Withdrawn",
      Notification: "Notification",
      PartiallyApproved: "Partially approved",
      PartiallyRejected: "Partially rejected",
      PendingAction: "Pending",
      RejectAction: "Reject",
      ApproveAction: "Approve"
    }
  },
  RequestType: {
    Labels: {
      CreatePortCall: "Create port call request",
      UpdatePortCallCargo: "Cargo update request",
      CancelPortCall: "Cancel port call request",
      UpdateActionArrivalTimePlanned: "Update arrival time request",
      UpdateActionDepartureTimePlanned: "Update departure time request",
      UpdateActionDraught: "Draught update request",
      CancelAction: "Cancel action request",
      CreateDeparture: "Create departure request"
    }
  },
  Contact: {
    Error: {
      ContactNotSpecified: "Contact must be specified"
    },
    Labels: {
      Type: "Type",
      Name: "Full name",
      DisplayName: "Name",
      Reference: "Reference",
      Address: "Address",
      Address1: "Address 1",
      Address2: "Address 2",
      Town: "Town",
      County: "County",
      Postcode: "Postcode",
      TelephoneNumber: "Telephone number",
      Fax: "Fax",
      Email: "Email",
      ContactAName: "Contact name",
      ContactADetail: "Contact details",
      ContactBName: "Contact name",
      ContactNDetail: "Contact details",
      DeletedHelp: "This contact has been deleted",
      Contact: "contact"
    }
  },
  ContactType: {
    Labels: {
      Agent: "Agent",
      Stevedore: "Stevedore",
      PortStaff: "Port Staff",
      VesselAgent: "Vessel Agent",
      VesselMaster: "Vessel Master"
    }
  },
  AgentHandover: {
    Labels: {
      From: "From",
      NoActiveAgent: "No active agent"
    }
  },
  AgentHandoverCreateDialog: {
    Labels: {
      AgentHandover: "Agent Handover",
      PlannedTimeOfHandover: "Planned time of handover"
    }
  },
  AgentHandoverEdit: {
    Labels: {
      Schedule: "Schedule",
      NoAgent: "No active agent",
      PlannedTime: "PlannedTime",
      Details: "Details"
    },
    Buttons: {
      SetPlannedTime: "Set Planned Time"
    }
  },
  VesselDataEdit: {
    Labels: {
      UpdatedAt: "Updated at",
      RefreshVesselData: "Refresh vessel data",
      DisabledRefresh: "Refresh data is disabled due to missing IMO and MMSI",
      ChangeVessel: "Change vessel",
      UpdateVessel: "Update vessel"
    }
  },
  VesselForm: {
    Errors: {
      EmptyName: "Name cannot be empty",
      MissingUnits: "Missing units",
      InvalidImo: "Not a valid IMO, checksum failed",
      DuplicateImo: "Vessel with this IMO exists",
      InvalidMmsi: "MMSI must contain 9 digits",
      DuplicateMmsi: "Vessel with this MMSI exists"
    },
    Labels: {
      CreateVessel: "Create Vessel",
      UpdateVessel: "Update Vessel",
      UpdatePortCallOpenMessage: "This will update all open port calls for the given vessel. Future port calls will also inherit incoming changes. Are you sure you want to continue?",
      UpdatePortCallMessage: "This will only update vessel details of the selected port call. Are you sure you want to continue?",
      UnsavedChanges: "You have unsaved changes, are you sure you want to leave?",
      Image: "Image"
    }
  },
  VesselItinerary: {
    Labels: {
      PortOfLoading: "Port of loading",
      LastPort: "Last port",
      NextPort: "Next port",
      PortOfDischarge: "Port of discharge"
    }
  },
  VesselSearch: {
    Labels: {
      UnknownVessel: "Unknown vessel",
      TemporaryName: "Temporary name",
      CallSign: "Call Sign",
      Beam: "Beam",
      LengthOverall: "Length Overall",
      NominalDraught: "Nominal Draught"
    },
    Errors: {
      NotAValidImo: "Not a valid IMO",
      NotAValidMmsi: "Not a valid MMSI"
    }
  },
  LocationTimeline: {
    Labels: {
      NotAllocated: "Not Allocated",
      NoUpcomingAction: "No upcoming action"
    }
  },
  Agent: {
    Labels: {
      Agent: "Agent",
      CharterAgent: "Charter Agent",
      CreateAgent: "Create Agent",
      Name: "Name",
      CompanyName: "Company name",
      DisplayName: "Display name",
      ContactDetails: "Contact Details",
      ContactName: "Contact name",
      TelephoneNumber: "Telephone number",
      FaxNumber: "Fax number"
    },
    Buttons: {
      CreateAgent: "Create agent"
    },
    Errors: {
      EmptyCompanyName: "Company name cannot be empty",
      EmptyTelephoneNumber: "Telephone number cannot be empty"
    }
  },
  VesselEditImageForm: {
    Buttons: {
      Select: "SELECT IMAGE",
      Replace: "REPLACE IMAGE",
      Discard: "DISCARD",
      DiscardCrop: "DISCARD CROPPED IMAGE"
    },
    Errors: {
      FileTypeTitle: "Incorrect File Type",
      FileTypeMessage: "Please upload a file with an image extention type such as .jpg or .png",
      FailedToUpload: "Failed to upload",
      FailedToDelete: "Failed to delete",
      ErrorMessage: "Error Message: {{error}}",
      Support: "Please try again or contact Support team"
    }
  },
  ImageDialog: {
    Labels: {
      CropImage: "Crop Image"
    }
  },
  VesselImage: {
    Labels: {
      Image: "Vessel Image"
    }
  },
  PropulsionType: {
    Labels: {
      FixedPitch: "Fixed Pitch Propeller (FPP)",
      ControllablePitch: "Controllable Pitch Propeller (CPP)",
      FixedVariablePitch: "Fixed Variable Pitch (FVPP)",
      AzimuthThruster: "Azimith Thruster",
      VoithSchneider: "Voith Schneider Drive",
      ZType: "Z Type"
    }
  },
  RudderType: {
    Labels: {
      Standard: "Standard",
      Flapped: "Flap/Flapped (Becker)",
      Fishtail: "Fishtail (Schilling)"
    }
  },
  PropellerDirection: {
    Labels: {
      Left: "Left-hand",
      Right: "Right-hand"
    }
  },
  ActionDetails: {
    Labels: {
      Schedule: "Schedule",
      Loading:"Loading action details ...",
      CharterAgent: "Charter Agent",
      Location: "Location",
      Pilotage: "Pilotage",
      Vessel: "Vessel",
      VesselDetails: "Details",
      VesselCondition: "Condition",
      Checklist: "Checklist",
      Attachments: "Attachments",
      ChargeableItems: "Chargeable Items",
      Prompt: "You have unsaved changes, are you sure you want to leave?",
      UnsavedChangesMessage: "There are unsaved changes in the current editor. Save changes before updating further details",
      AutomationWarning: "This will prevent {{movementType}} from further automation",
      SubmitForApproval: "Submit for approval",
      ConfirmDraught: "Confirm change to Draught",
      Remarks: "Remarks",
      ConfirmSaveTitle: "Save Port Call {{movementType}}: {{vesselName}}",
      ConfirmSaveMessage: "Are you sure you want to save {{movementType}} for {{vesselName}}?",
      ReopenPortCallMessage: "This action will reopen the port call and add it to the berth status display."
    },
    Errors: {
      Loading:"Unable to load action details",
      AppSyncError:"AppSync {{error}}"
    }
  },
  MovementEdit: {
    Labels: {
      Attachments: "Attachments",
      ChargeableItems: "Chargeable Items",
      Checklist: "Checklist"
    }
  },
  ActionScheduleEdit: {
    Buttons: {
      SetRequestedTime: "Set Requested Time",
      SetPlannedTime: "Set Planned Time",
      SetVesselReadyTime: "Set Vessel Ready Time",
      SetPilotBoardingTime: "Set Pilot Boarding Time",
      SetActualTime: "Set Actual Time",
      RequestChange: "Request Change"
    },
    Labels: {
      VoyageId: "Voyage ID",
      MovementSchedule: "{{movement}} Schedule",
      MovementLocation: "{{movement}} Location",
      NotBerthableLocation: "This location is not berthable",
      RequestedTime: "Requested time",
      PlannedTime: "Planned time",
      VesselReadyTime: "Vessel ready time",
      PilotBoardingTime: "Pilot boarding time",
      ActualTime: "Actual time",
      CharterAgent: "Charter Agent",
      Pilotage: "Pilotage",
      Pilots: "Pilots",
      Linesmen: "Linesmen",
      MooringVessels: "Mooring vessels",
      Loading: "Loading...",
      NoVoyages: "No voyages for this vessel"
    }
  },
  ActionVesselEdit: {
    Labels: {
      Vessel: "Vessel",
      MovementDetails: "{{movement}} Details"
    }
  },
  ActionPropulsionEdit: {
    Labels: {
      MovementCondition: "{{movement}} Condition",
      PrimaryPropulsion: "Primary Propulsion",
      PropulsionState: "Propulsion state",
      PropulsionRemarks: "Propulsion remarks",
      RudderState: "Rudder state",
      RudderRemarks: "Rudder remarks",
      ForwardAzimuthState: "Forward azimuth state",
      ForwardAzimuthRemarks: "Forward azimuth remarks",
      AftAzimuthState: "Aft azimuth state",
      AftAzimuthRemarks: "Aft azimuth remarks",
      ForwardTunnelThrustersState: "Forward tunnel thruster state",
      ForwardTunnelThrustersRemarks: "Forward tunnel thruster remarks",
      AftTunnelThrustersState: "Aft tunnel thruster state",
      AftTunnelThrustersRemarks: "Aft tunnel thruster remarks"
    }
  },
  ActionPropulsionOperationalState: {
    Labels: {
      FullyOperational: "Fully Operational",
      HasFault: "Has Fault"
    }
  },
  AgentRequestTimeChangeDialog: {
    Buttons: {
      SendRequest: "Send Request"
    },
    Labels: {
      Title: "Request change to {{movement}}",
      RequestedTimeArrival: "Requested time of arrival",
      RequestedTimeDeparture: "Requested time of departure",
      Remarks: "Remarks"
    }
  },
  DocumentEdit: {
    Buttons: {
      Upload: "Upload",
      AddAttachment: "Add Attachment"
    },
    Labels: {
      NoFile: "Select file to upload",
      Filename: "Attachment selected",
      Progress: "Uploading...",
      Complete: "Upload complete",
      Downloaded: "Attachment downloaded",
      Deleted: "Attachment deleted",
      Attachments: "Attachments"
    },
    Errors: {
      DuplicateTitle: "Attachment {{doc}} Already Exists",
      DuplicateMessage: "Uploading {{doc}} will overwrite the attachment with the same name",
      TooLargeTitle: "Attachment is too large to upload",
      TooLargeMessage: "The attachment {{doc}} is too large to upload and needs to be under {{limit}}",
      FailedUploadTitle: "{{doc}} failed to upload",
      FailedUploadMessage: "Attachment failed to upload. (Error Message: {{error}})",
      FailedDeleteTitle: "{{doc}} failed to delete",
      FailedDeleteMessage: "Attachment failed to delete. (Error Message: {{error}})",
      FailedDownloadTitle: "{{doc}} failed to download",
      FailedDownloadMessage: "Attachment failed to download. (Error Message: {{error}})",
      Support: "Please try again or contact Support team.",
      FailedUnknownTitle: "Unhandled error",
      FailedUnknownMessage: "Unhandled error"
    }
  },
  DocumentList: {
    Labels: {
      DeleteAttachmentTitle: "Delete Attachment {{doc}}",
      DeleteAttachmentMessage: "Are you sure you want to delete {{doc}}",
      NoAttachments: "No Attachments Uploaded",
      NoDeletePermission: "You do not have permission to delete this file"
    }
  },
  AddTodo: {
    Labels: {
      Select: "Select..."
    }
  },
  ChargeableItems: {
    Buttons: {
      Add: "Add Chargeable Item",
      Update: "Update Items"
    },
    Labels: {
      Title: "Chargeable Items",
      Tariff: "Tariff",
      Notes: "Notes",
      UnitCost: "Unit Cost",
      MinimumCost: "Minimum Cost",
      Quantity: "Quantity",
      QuantityShort: "QTY",
      Total: "Total",
      Empty: "",
      Delete: "Delete Chargeable Item",
      UnitType: "Unit Type",
      OverriddenUnitCost: "Overridden Unit Cost",
      ItemNotes: "Chargeable Item Notes",
      ResetTariff: "Select another tariff",
      UnitCostTooltip: "Original Tariff Unit Cost",
      ResetTooltip: "Reset Unit Cost",
      NoTariffs: "Create tariffs for this action type to add chargeable items",
      NoTariffsIsAdminIsAdmin: "Create tariffs for this action type to add chargeable items",
      NoTariffsIsAdminNotAdmin: "There are no tariffs allocated to this action type.\n Please contact your system administration to update tariffs",
      NoChargeable: "There are no chargeable items. \nClick the Add Chargeable Item button to add some"
    },
    Errors: {
      InvalidChargeable: "All Chargeable Items need a tariff selected",
      TariffBookOutOfRange: "The action is outside the date range of this item's tariff book",
      TariffBookDeleted: "The tariff book for this item has been deleted",
      TariffDeleted: "The tariff for this item has been deleted",
      ActionInvalidTariffs: "This action contains invalid chargeable items"
    }
  },
  FileTypes: {
    Labels: {
      Fal5CrewList: "Crew List (FAL 5 form)"
    }
  },
  PreArrivalUpload: {
    Buttons: {
      CreateForm: "Create Form",
      EditForm: "Edit Form",
      UploadFile: "Upload File"
    },
    Labels: {
      UploadedBy: "Uploaded by",
      UpdatedBy: "Updated by",
      ReplaceTitle: "{{fileType}} exists",
      ReplaceUploadMessage: "Uploading a new {{fileType}} will replace existing data. Are you sure you want to continue?",
      ReplaceCreateFormMessage: "Creating a new {{fileType}} will replace existing data. Are you sure you want to continue?",
      DeleteTitle: "Delete {{fileType}}",
      DeleteMessage: "This will delete {{fileName}}. Are you sure you want to continue?"
    },
    Errors: {
      Title: "Something went wrong!",
      FailedToUpload: "Failed to upload the file. Please try again.",
      FailedToDownload: "Failed to download the file. Please try again."
    }
  },
  Fal5CrewList: {
    Buttons: {
      AddMember: "Add Member"
    },
    Labels: {
      Arrival: "Arrival",
      Departure: "Departure",
      _1_1_NameOfShip: "1.1 Name of ship",
      _1_2_ImoNumber: "1.1 IMO number",
      _1_3_Callsign: "1.1 Call sign",
      _1_4_VoyageNumber: "1.1 Voyage number",
      _2_PortOfArrivalDeparture: "2. Port of arrival/departure",
      _3_DateOfArrivalDeparture: "3. Date of arrival/departure",
      _4_FlagStateOfShip: "4. Flag State of ship",
      _5_LastPortOfCall: "5. Last port of call",
      _6_Num: "6. No",
      _7_FamilyName: "7. Family name",
      _8_GivenNames: "8. Given names",
      _9_RankOrRating: "9. Rank or rating",
      _10_Nationality: "10. Nationality",
      _11_DateOfBirth: "11. Date of birth",
      _12_PlaceOfBirth: "12. Place of birth",
      _13_Gender: "13. Gender",
      _14_NatureOfIdentidyDoc: "14. Nature of identity document",
      _15_NumberOfIdentidyDoc: "15. Number of identity document",
      _16_IssuingStateOfIdentityDoc: "16. Issuing State of identity document",
      _17_ExpireDateOfIdentityDoc: "17. Expiry date of identity document"
    }
  },
  PortCall: {
    Buttons: {
      AddDeparture: "Add departure",
      MoreOptions: "More Options"
    },
    Errors: {
      InvalidDateFormat: "Invalid Date Format",
      ArrivalBeforeDeparture: "Arrival must come before departure",
      DepartureAfterArrival: "Departure must come after arrival"
    },
    Labels: {
      NewPortCallTitle: "Create Port Call",
      RequestPortCall: "Request Port Call",
      PlannedTimeOfArrival: "Planned time of arrival",
      PlannedTimeOfDeparture: "Planned time of departure",
      ArrivalLocation: "Arrival location",
      DepartureLocation: "Departure location",
      Schedule: "Schedule",
      EnterRemarksForPortControl: "Enter remarks for port control",
      ArrivalDraughts: "Arrival Draughts",
      DepartureDraughts: "Departure Draughts"
    }
  },
  Cargo: {
    Labels: {
      Cargo: "Cargo",
      Cargos: "Cargos",
      CargoItem: "Cargo item",
      CargoItems: "Cargo items",
      Incoming: "Incoming",
      IncomingCargo: "Incoming cargo",
      Outgoing: "Outgoing",
      OutgoingCargo: "Outgoing cargo",
      CargoType: "Cargo type",
      OtherCargo: "Other cargo",
      NoCargo: "No cargo items",
      More: "more"
    },
    Buttons: {
      AddCargo: "Add cargo"
    }
  },
  MovementActionList: {
    Labels: {
      Schedule: "Schedule",
      ScheduleDesc: "Planned time and estimated time (if available)",
      PortCallId: "Port Call ID",
      PortCallIdDesc: "Unique port call identifier (auto generated by SmartPort)",
      VoyageId: "Voyage ID",
      VoyageIdDesc: "Voyage identifier",
      Vessel: "Vessel",
      VesselDesc: "Details of vessel",
      PortOfRegistry: "Port Of Registry",
      PortOfRegistryDesc: "Port in which a vessel is registered",
      Dimensions: "Dimensions",
      DimensionsDesc: "Overall Length x Beam (and Sailing Draught if available)",
      Length: "Length",
      LengthFull: "Length Overall",
      LengthDesc: "Ovreall length of vessel",
      Beam: "Beam",
      BeamDesc: "Vessel width at widest point",
      Draught: "Draught",
      DraughtDesc: "Sailing draught of vessel for movement.  Nominal draught of vessel if no sailing draught is available",
      AirDraught: "Air Draught",
      AirDraughtDesc: "Height of the vessel above the waterline for movement",
      BowThrusters: "BT",
      BowThrustersFull: "Bow Thrusters",
      BowThrustersDesc: "Indicates the presence of bow thrusters",
      Location: "Location",
      LocationDesc: "Port location the vessel will be berthed in or leaving from",
      CharterAgent: "Charter agent",
      Cargo: "Cargo",
      CargoDesc: "Type and amount of cargo",
      NextPort: "Next Port",
      NextPortDesc: "Next Port",
      LastPort: "Last Port",
      LastPortDesc: "Previous port of call",
      LatestUserLogEntry: "Log book",
      LatestUserLogEntryDesc: "Last user-entered log book entry",
      Remarks: "Remarks",
      RemarksDesc: "User-entered remarks",
      GrossTonnage: "GT",
      GrossTonnageFull: "Gross Tonnage",
      GrossTonnageDesc: "Nonlinear measure of a ship's overall internal volume",
      Checklist: "Checklist",
      ChecklistDesc: "Outstanding tasks for the movement",
      FilterType: "Movements",
      AllType: "All {{type}}",
      NotAllocated: "Not Allocated"
    }
  },
  Filter: {
    Buttons: {
      ApplyFilters: "Apply Filters"
    },
    Labels: {
      EnterFilterName: "Enter a name to save a new filter",
      NoName: "Please enter a name to save the filter",
      UniqueName: "Name must be unique",
      SpecialCharacters: "Name must not contain special characters",
      SelectFilter: "Select Filter",
      FilterUpdated: "This filter will be updated if no name is entered",
      ApplyFilters: "Apply Filters",
      SelectType: "Select Type",
      FilterName: "Name of Filter",
      FilterNamePrompt: "Enter a name to save as a new filter"
    }
  },
  Logbook: {
    Buttons: {
      AddEntry: "Add Entry"
    },
    Labels: {
      Logbook: "Logbook",
      ItemCreatedByUser: "<item/> created by <user/>",
      ItemUpdatedByUser: "<item/> updated by <user/>",
      ItemFileUploadedByUser: "<item/> file <file/> uploaded by <user/>",
      ItemFileUpdatedByUser: "<item/> file <file/> updated by <user/>",
      ItemFileDeletedByUser: "<item/> file <file/> deleted by <user/>",
      FileUploadedByUser: "File <file/> uploaded by <user/>",
      FileUpdatedByUser: "File <file/> updated by <user/>",
      FileDeletedByUser: "File <file/> deleted by <user/>",
      UserCommentedOnItem: "<user/> commented on <item/>",
      UserCommented: "<user/> commented",
      Comment: "Comment",
      Action: "Action",
      NoDataAvailable: "No data available",
      AssociateWithSelectedItem: "Associate with selected <item/>",
      ShowOnlyEntriesForSelectedItem: "Show only entries for selected <item/>",
      ItemRequestedTime: "{{item}} requested time",
      RequestedTime: "Requested time",
      Request: "Request",
      PortCall: "Port Call"
    }
  },
  BerthStatusDisplay: {
    Labels: {
      Berth: "Berth",
      Berths: "Berths",
      ActiveAgent: "Active Agent",
      Remarks: "Remarks",
      ShowPreviousDetails: "Show previous details",
      ShowRefreshingDetails: "Stop refreshing details",
      StartRefreshingDetails: "Start refreshing details",
      ShowNextDetails: "Show next details",
      DetailsDettings: "Details settings",
      NoVesselsInPort: "No vessels in port",
      FilterAll: "All",
      FilterOccupied: "Occupied",
      FilterVacant: "Vacant",
      FilterNameAllBerths: "All {{name}}",
      Every1Second: "Every 1 second",
      Every5Second: "Every 5 seconds",
      Every15Second: "Every 15 seconds",
      Every1Minute: "Every 1 minute",
      RefreshRate: "Refresh rate",
      DisplayedDetails: "Displayed details"
    }
  },
  ToggleFullscreen: {
    Labels: {
      ToggleFullscreen: "Toggle fullscreen"
    }
  },
  PortCallMenuItem: {
    Labels: {
      Movement: "Movement",
      PortCall: "Port Call",
      None: "None available",
      StartDeparture: "Start departure",
      StartArrival: "Start arrival",
      RecordDeparture: "Record departure",
      RecordArrival: "Record arrival",
      StartShiftDeparture: "Start shift departure",
      StartShiftArrival: "Start shift arrival",
      RecordShiftDeparture: "Record shift departure",
      RecordShiftArrival: "Record shift arrival",
      DeleteShift: "Delete shift",
      UndoLast: "Undo last {{- actionName}} {{ actionState}}",
      UndoCompleted: "Undo completed",
      UndoInProgress: "Undo in progress",
      CancelPortCall: "Cancel port call",
      DeletePortCall: "Delete port call",
      ShowDetails: "Show details",
      AgentHandover: "Agent Handover",
      PlannedTimeOfHandover: "Planned time of handover",
      CharterAgent: "Charter agent",
      DeleteDeparture: "Delete departure",
      CancelDeparture: "Cancel departure",
      Approve: "Approve",
      Decline: "Decline",
      MarkForApproval: "Submit for approval",
      RequiresApproval: "Requires approval",
      StartCustom: "Start next action",
      RecordCustom: "Record next action",
      CancelCustom: "Cancel action",
      DeleteCustom: "Delete action",
      DeleteAgentHandover: "Delete agent handover",
      NonDockableLocationWarning: "Select a berthable location to enable this action",
      NoArrivalLocationWarning: "Please select an arrival location to enable this action",
      RequestApprovalWarning: "Approve request to enable this action",
      CompletePortCall: "Complete port call",
      Spotlight: "Spotlight",
      ViewInvoice: "View Invoice",
      NoVoyageAdminPermissionToCancel: "You do not have permission to cancel part of a voyage",
      NoVoyageAdminPermissionToDelete: "You do not have permission to delete part of a voyage",
      ClosePortCall: "Close port call"
    }
  },
  PortCallTimeline: {
    Buttons: {
      LifecycleNone: "None available",
      LifecycleStartDeparture: "Start departure",
      LifecycleStartArrival: "Start arrival",
      LifecycleRecordDeparture: "Record departure",
      LifecycleRecordArrival: "Record arrival",
      LifecycleStartShiftDeparture: "Start shift departure",
      LifecycleStartShiftArrival: "Start shift arrival",
      LifecycleRecordShiftDeparture: "Record shift departure",
      LifecycleRecordShiftArrival: "Record shift arrival",
      LifecycleRequiresApproval: "Requires approval",
      LifecycleStartCustom: "Start next action",
      LifecycleRecordCustom: "Record next action"
    },
    Labels: {
      AddArrival: "Add arrival",
      AddDeparture: "Add departure",
      AddBerthShift: "Add shift",
      AddAgentHandover: "Add agent handover",
      AddCustom: "Add {{- actionName}} action"
    }
  },
  PortCallTimelineMenu: {
    Errors: {
      PortCallUpdateFailed: "An error occured updating the port call. Click Confirm to retry",
      VoyageUpdateFailed: "An error occured updating the voyage. Click Confirm to retry"
    },
    Labels: {
      Remarks: "Remarks",
      CompletePortCall: "Complete Port Call: {{vesselName}}",
      PortCallOutstandingTodos: "There are outstanding checklist items against the actions in this port call, are you sure you wish to continue?",
      CompletePortCallMessage: "{{todos}} This action will close the port call. Please ensure all details are entered prior to completing the port call.",
      OutstandingTodos: "There are outstanding checklist items against this action, are you sure you wish to continue?",
      ArrivalDialogTitle: "Port Call Arrival: {{vesselName}}",
      ArrivalDialogMessage: "Are you sure you want to initiate arrival for {{vesselName}}? {{todos}}",
      ShiftArrivalDialogTitle: "Shift Arrival: {{vesselName}}",
      ShiftArrivalDialogMessage: "Are you sure you want to initiate shift arrival for {{vesselName}}? {{todos}}",
      RecordArrivalDialogTitle: "Port Call Arrival: {{vesselName}}",
      RecordArrivalDialogInput: "Actual Time of Arrival",
      RecordShiftArrivalDialogTitle: "Shift Arrival: {{vesselName}}",
      RecordShiftArrivalDialogInput: "Actual Time of Arrival",
      DepartureDialogTitle: "Port Call Departure: {{vesselName}}",
      DepartureDialogInput: "Actual Time of Departure",
      ShiftDepartureDialogTitle: "Shift Departure: {{vesselName}}",
      ShiftDepartureDialogInput: "Actual Time of Departure",
      DeleteShiftDialogTitle: "Delete Shift: {{vesselName}}",
      DeleteShiftDialogMessage: "Are you sure you want to delete the shift? {{todos}}",
      RecordDepartureDialogTitle: "Port Call Departure: {{vesselName}}",
      RecordDepartureDialogMessage: "Are you sure you want to complete departure and close port call for {{vesselName}}? {{todos}} This action will close the port call. Please ensure all details are entered prior to completing departure.",
      RecordShiftDepartureDialogTitle: "Shift Departure: {{vesselName}}",
      RecordShiftDepartureDialogMessage: "Are you sure you want to complete shift departure for {{vesselName}}? {{todos}}",
      UndoActionDialogTitle: "Undo state: {{vesselName}}",
      UndoActionDialogMessage: "Are you sure you want to undo last {{- actionName}} {{actionState}} for {{vesselName}}?",
      CancelPortCallDialogTitle: "Cancel Port Call: {{vesselName}}",
      CancelPortCallDialogMessage: "Are you sure you want to cancel port call for {{vesselName}}? This action will close the port call. Please ensure all details are entered prior to cancellation.",
      CancelRequestDialogTitle: "Port Call Cancellation Request for : {{vesselName}}",
      CancelRequestDialogMessage: "Are you sure you want to request a cancellation for {{vesselName}}",
      DeletePortCallDialogTitle: "Delete Port Call: {{vesselName}}",
      DeletePortCallDialogMessage: "Are you sure you want to delete port call for {{vesselName}}? This action will close the port call. Please ensure all details are entered prior to deletion.",
      CloseArrivalPortCallDialogTitle: 'Close Port Call: {{vesselName}}',
      CloseArrivalPortCallDialogMessage: 'Are you sure you want to close the port call for {{vesselName}}? This action will remove the port call from the berth status display. Please ensure all details are entered prior to closure.',
      ActionCancelDialogTitle: "Cancel {{- actionName}}: {{vesselName}}",
      ActionCancelDialogMessage: "Are you sure you want to cancel the {{- actionName}} for {{vesselName}}? {{todos}}",
      CancelDepartureRequestDialogTitle: "Departure Cancellation Request for: {{vesselName}}?",
      CancelDepartureRequestDialogMessage: "Are you sure you want to request a cancellation for departure for {{vesselName}}?",
      DeleteActionDialogTitle: "Delete {{- actionName}}: {{vesselName}}",
      DeleteActionDialogMessage: "Are you sure you want to delete the {{- actionName}} for {{vesselName}}?",
      StartCustomActionDialogTitle: "Port Call {{- actionName}}: {{vesselName}}",
      StartCustomActionDialogInput: "Actual Time of {{- actionName}}",
      RecordCustomActionDialogTitle: "Port Call {{- actionName}}: {{vesselName}}",
      RecordCustomActionDialogMessage: "Are you sure you want to complete {{- actionName}}: {{vesselName}}?",
      ApproveActionDialogTitle: "Approve {{- actionName}}: {{vesselName}}",
      ApproveActionDialogMessage: "Approve {{- actionName}}: {{vesselName}}?",
      DeclineActionDialogTitle: "Decline {{- actionName}}: {{vesselName}}",
      DeclineActionDialogMessage: "Are you sure you want to decline {{- actionName}}: {{vesselName}}?",
      MarkApprovalDialogTitle: "Submit for approval {{- actionName}}: {{vesselName}}",
      MarkApprovalDialogMessage: "Are you sure you want to mark for approval {{- actionName}}: {{vesselName}}?",
      DeleteAgentHandoverDialogTitle: "Delete agent handover",
      DeleteAgentHandoverDialogMessage: "Are you sure you want to delete agent handover to {{agentName}}?",
      AutomationArrivalWarning: "This will prevent arrival from further automation",
      AutomationDepartureWarning: "This will prevent departure from further automation"
    }
  },
  CustomAction: {
    Errors: {
      FirstAction: "Planned time must be after first action",
      Departure: "Planned time must be before departure",
      LastCompletedInProgress: "Planned time must be after last completed/ in progress action",
      InvalidDate: "Invalid Date Format",
      TimeCheckActualBeforeComplete: "Start time must be before completion time",
      TimeCheckCompleteAfterActual: "Completion time must be after start time"
    }
  },
  CustomActionMenu: {
    Labels: {
      DeleteAction: "Delete {{- actionName}} action",
      CancelAction: "Cancel {{- actionName}} action",
      UndoAction: "Undo {{- actionName}} action",
      RecordAction: "Record {{- actionName}} action",
      StartAction: "Start {{- actionName}} action",
      CompleteAction: "Complete {{- actionName}} action",
      UndoCompleted: "Undo completed",
      UndoStarted: "Undo started",
      CancelActionDialogTitle: "Cancel {{- actionName}} action: {{vesselName}}",
      CancelActionDialogMessage: "Are you sure you want to cancel the {{- actionName}} action for {{vesselName}}?",
      UndoActionDialogTitle: "Undo state: {{vesselName}}",
      UndoActionDialogMessage: "Are you sure you want to undo last {{- actionName}} action {{actionState}} for {{vesselName}}?",
      RecordActionDialogTitle: "Port Call {{- actionName}} action: {{vesselName}}",
      RecordActionDialogMessage: "Are you sure you want to complete {{- actionName}} action: {{vesselName}}?",
      StartActionDialogTitle: "Port Call {{- actionName}} action: {{vesselName}}",
      StartActionDialogInput: "Start Time of {{- actionName}} action",
      DeleteActionDialogTitle: "Delete {{- actionName}} action",
      DeleteActionDialogMessage: "Are you sure you want to delete {{- actionName}} action?",
      CompleteActionDialogInput: "Completion Time of {{- actionName}} action"
    }
  },
  CustomActionEdit: {
    Labels: {
      Schedule: "{{- actionName}} Schedule",
      Notes: "Notes"
    }
  },
  CustomActionCreateDialog: {
    Labels: {
      Title: "Add {{- actionName}}",
      Service: "Service",
      PlannedTime: "Planned Time",
      Notes: "Notes"
    }
  },
  CustomActionScheduleEdit: {
    Labels: {
      SetPlannedTime: "Set Planned Time",
      SetActualTime: "Set Actual Time",
      SetCompletionTime: "Set Completion Time",
      Time: "Time",
      SetTime: "Set Time",
      StartTime: "Start time",
      CompletionTime: "Completion time"
    },
    Errors: {
      ImvalidTime: "Invalid Time"
    }
  },
  ShiftCreateDialog: {
    Labels: {
      NewShift: "New Shift"
    },
    Errors: {
      ShiftDepartureAfterArrival: "Shift departure must come after a valid arrival movement and before its departure",
      ShiftDepartureBeforeCompleted: "Shift departure must not come before a completed movement",
      ShiftAfterDeparture: "Shift arrival must come directly after its departure but before last movement"
    }
  },
  ArrivalCreateDialog: {
    Labels: {
      NewArrival: "New Arrival"
    }
  },
  DepartureCreateDialog: {
    Labels: {
      NewDeparture: "New Departure"
    }
  },
  AgentDepartureCreateDialog: {
    Labels: {
      NewDeparture: "Request New Departure",
      Remarks: "Remarks"
    },
    Errors: {
      DepartureAfterArrival: "Departure must come after last action"
    }
  },
  TimeTooltip: {
    Labels: {
      ActualTime: "Actual time",
      StartTime: "Start time",
      PlannedTime: "Planned time",
      CompletionTime: "Completion time",
      EstimatedTime: "Estimated time",
      RequestedTime: "Requested time",
      Notes: "Notes",
      Action: "Action",
      UpdatedAgo: "updated {{time}} ago"
    }
  },
  Weather: {
    Labels: {
      TemperatureAt: "Temperature ({{symbol}}) at {{time}}",
      VisibilityAt: "Visibility ({{unit}}) at {{time}}",
      AtmosphericPressureAt: "Atmospheric pressure ({{unit}}) at {{time}}",
      WindDirectionAt: "{{degrees}}° {{direction}} at {{time}}",
      Gusting: "Gusting",
      WeatherAt: "{{weather}} at {{time}}"
    }
  },
  WeatherIcon: {
    Labels: {
      Sunny: "Sunny",
      MostlySunny: "Mostly Sunny",
      PartlySunny: "Partly Sunny",
      IntermittentClouds: "Intermittent Clouds",
      HazySunshine: "Hazy Sunshine",
      MostlyCloudy: "Mostly Cloudy",
      Cloudy: "Cloudy",
      Dreary: "Dreary (overcast)",
      Fog: "Fog",
      Showers: "Showers",
      MostlyCloudyWithShowers: "Mostly Cloudy w/ Showers",
      PartlySunnyWithShowers: "Partly Sunny w/ Showers",
      TStorms: "T-Storms",
      MostlyCloudyWithTStorms: "Mostly Cloudy w/ T-Storms",
      PartlySunnyWithTStorms: "Partly Sunny w/ T-Storms",
      Rain: "Rain",
      Flurries: "Flurries",
      MostlyCloudyWithFlurries: "Mostly Cloudy w/ Flurries",
      PartlySunnyWithFlurries: "Partly Sunny w/ Flurries",
      Snow: "Snow",
      MostlyCloudyWithSnow: "Mostly Cloudy w/ Snow",
      Ice: "Ice",
      Sleet: "Sleet",
      FreezingRain: "Freezing Rain",
      RainAndSnow: "Rain and Snow",
      Hot: "Hot",
      Cold: "Cold",
      Windy: "Windy",
      Clear: "Clear",
      MostlyClear: "Mostly Clear",
      PartlyCloudy: "Partly Cloudy",
      HazyMoonlight: "Hazy Moonlight",
      PartlyCloudyWithShowers: "Partly Cloudy w/ Showers",
      PartlyCloudyWithTStorms: "Partly Cloudy w/ T-Storms"
    }
  },
  WindDirection: {
    Labels: {
      N: "N",
      NbE: "NbE",
      NNE: "NNE",
      NEbN: "NEbN",
      NE: "NE",
      NEbE: "NEbE",
      ENE: "ENE",
      EbN: "EbN",
      E: "E",
      EbS: "EbS",
      ESE: "ESE",
      SEbE: "SEbE",
      SE: "SE",
      SEbS: "SEbS",
      SSE: "SSE",
      SbE: "SbE",
      S: "S",
      SbW: "SbW",
      SSW: "SSW",
      SWbS: "SWbS",
      SW: "SW",
      SWbW: "SWbW",
      WSW: "WSW",
      WbS: "WbS",
      W: "W",
      WbN: "WbN",
      WNW: "WNW",
      NWbW: "NWbW",
      NW: "NW",
      NWbN: "NWbN",
      NNW: "NNW",
      NbW: "NbW"
    }
  },
  FileExportFields: {
    Labels: {
      CargoIn: "in",
      CargoOut: "out",
      PortCall: "Port Call",
      Vessel: "Vessel",
      Agent: "Agent",
      Arrival: "Arrival",
      Departure: "Departure",
      VesselName: "Vessel name",
      PortCallReferenceID: "Reference ID",
      PortCallPortOfLoading: "Port of loading",
      PortCallLastPort: "Last port",
      PortCallNextPort: "Next port",
      PortCallPortOfDischarge: "Port of discharge",
      PortCallCategory: "Category",
      PortCallStatus: "Port call status",
      PortCallCargo: "Cargo",
      PortCallCargoIn: "Cargo in",
      PortCallCargoOut: "Cargo out",
      PortCallRemarks: "Remarks",
      PortCallTags: "Port call tags",
      PortCallPort: "Port",
      ArrivalTimeRequested: "Arrival time requested",
      ArrivalTimePlanned: "Arrival time planned",
      ArrivalTimeEstimated: "Arrival time estimated",
      ArrivalTimeReady: "Arrival time vessel ready",
      ArrivalTimeActual: "Arrival time actual",
      ArrivalPilotBoarding: "Arrival pilot boarding time",
      ArrivalLocation: "Arrival location",
      ArrivalPilots: "Arrival pilots",
      ArrivalLinesmen: "Arrival linesmen",
      ArrivalMooringVessels: "Arrival mooring vessels",
      ArrivalMastersName: "Arrival master's name",
      ArrivalCrew: "Arrival number of crew",
      ArrivalPassengers: "Arrival number of passengers",
      ArrivalForwardDraught: "Arrival forward draught",
      ArrivalAftDraught: "Arrival aft draught",
      ArrivalSailingDraught: "Arrival sailing draught",
      ArrivalAirDraught: "Arrival air draught",
      DepartureTimeRequested: "Departure time requested",
      DepartureTimePlanned: "Departure time planned",
      DepartureTimeReady: "Departure time ready",
      DepartureTimeActual: "Departure time actual",
      DeparturePilotBoarding: "Departure pilot boarding time",
      DepartureLocation: "Departure location",
      DeparturePilots: "Departure pilots",
      DepartureLinesmen: "Departure linesmen",
      DepartureMooringVessels: "Departure mooring vessels",
      DepartureMastersName: "Departure master's name",
      DepartureCrew: "Departure num crew",
      DeparturePassengers: "Departure num passengers",
      DepartureForwardDraught: "Departure forward draught",
      DepartureAftDraught: "Departure aft draught",
      DepartureSailingDraught: "Departure sailing draught",
      DepartureAirDraught: "Departure air draught"
    }
  },
  Snackbar: {
    Labels: {
      Syncing: "Syncing",
      UpdatedBy: "Updated by {{userId}} at {{time}}",
      ArrivalScheduled: "New arrival scheduled",
      ArrivalPlanned: "Arrival schedule updated",
      ArrivalRequested: "New arrival requested",
      ArrivalStarted: "Arrival has started",
      ArrivalCompleted: "Arrival has completed",
      ArrivalCancelled: "Arrival has been cancelled",
      ArrivalApproved: "Arrival has been approved",
      ArrivalDeclined: "Arrival has been declined",
      DepartureScheduled: "New departure scheduled",
      DeparturePlanned: "Departure schedule updated",
      DepartureRequested: "New departure requested",
      DepartureStarted: "Departure has started",
      DepartureCompleted: "Departure has completed",
      DepartureCancelled: "Departure has been cancelled",
      DepartureApproved: "Departure has been approved",
      DepartureDeclined: "Departure has been declined",
      ShiftArrivalScheduled: "New berth shift arrival scheduled",
      ShiftArrivalPlanned: "Berth shift arrival schedule updated",
      ShiftArrivalStarted: "Berth shift arrival has started",
      ShiftArrivalCompleted: "Berth shift arrival has completed",
      ShiftArrivalCancelled: "Berth shift arrival has been cancelled",
      ShiftArrivalApproved: "Berth shift arrival has been approved",
      ShiftArrivalDeclined: "Berth shift arrival has been declined",
      ShiftDepartureScheduled: "New berth shift departure scheduled",
      ShiftDeparturePlanned: "Berth shift departure schedule updated",
      ShiftDepartureStarted: "Berth shift departure has started",
      ShiftDepartureCompleted: "Berth shift departure has completed",
      ShiftDepartureCancelled: "Berth shift departure has been cancelled",
      ShiftDepartureApproved: "Berth shift departure has been approved",
      ShiftDepartureDeclined: "Berth shift departure has been declined"
    }
  },
  AIS: {
    Labels: {
      LastMessageTime: "Last message time",
      ReceiverName: "Receiver name",
      InvalidAISData: "Invalid AIS data",
      Vessels: "Vessels",
      AISReceivers: "AIS Receivers",
      Status: "Status",
      ThresholdSettings: "Threshold settings",
      WarningThreshold: "Warning threshold",
      WarningThresholdHelp: "Display warning status on the receiver when threshold is reached",
      CriticalThreshold: "Critical threshold",
      CriticalThresholdHelp: "Display critical status on the receiver when threshold is reached",
      CriticalThresholdError: "Critical threshold must come after warning threshold"
    }
  },
  CheckboxTree: {
    Labels: {
      SelectAll: "Select all",
      DeselectAll: "Deselect all",
      ExpandAll: "Expand all",
      CollapseAll: "Collapse all"
    }
  },
  MapLayers: {
    Labels: {
      Vessels: "Vessels",
      AISReceivers: "AIS Receivers",
      Zones: "Zones",
      MarlinGIS: "Marlin GIS",
      EncAidToNav: "Aids To Navigations",
      EncDangers: "Dangers",
      EncRestrictions: "Restrictions",
      EncCables: "Cables",
      EncPipelines: "Pipelines",
      EncPlatforms: "Offshore Platforms",
      EncAnchors: "Anchors",
      EncHydrography: "Hydrography",
      EncSoundings: "Soundings",
      EncAllSoundings: "All Soundings",
      EncTopography: "Topography",
      EncCoverages: "Coverages",
      EncBaseLabel: "Base - Label",
      EncAidsToNavigationLabel: "Aids To Navigation - Label",
      EncHydrographyLabel: "Hydrography - Label",
      EncRestrictionsLabel: "Restrictions - Label",
      EncTopographyLabel: "Topography - Label",
      EncMetadata: "Metadata",
      EncBaseWater: "Base Water",
      EncBaseLand: "Base Land",
      EncBase: "Base"
    }
  },
  ExternalNotification: {
    Txt: {
      Notification: "Notification",
      PortCallSummary: "PORT CALL SUMMARY",
      Actual: "Actual",
      Requested: "Requested",
      Planned: "Planned",
      Schedule: "SCHEDULE",
      To: "TO",
      From: "FROM",
      At: "at",
      Local: "Local",
      UTC: "UTC",
      Day: "day",
      Days: "days",
      Hour: "hour",
      Hours: "hours",
      Minute: "minute",
      Minutes: "minutes",
      LocationNotSpecified: "Location not specified",
      ArrivalCreated: "An arrival has been created.",
      ArrivalStarted: "An arrival has been started.",
      ArrivalCompleted: "An arrival has been completed.",
      ArrivalPendingApproval: "An arrival is awaiting approval.",
      ArrivalPendingFurtherApproval: "An arrival is awaiting further approval.",
      ArrivalApproved: "An arrival has been approved.",
      ArrivalDeclined: "An arrival has been declined.",
      ArrivalRequested: "An arrival has been requested.",
      ArrivalBefore: "This is a reminder that an upcoming arrival has been scheduled at {{dateTime}}.",
      ArrivalAfter: "This is a reminder that there is a late arrival scheduled ({{diff}} ago).",
      DepartureCreated: "A departure has been created.",
      DepartureStarted: "A departure has been started.",
      DepartureCompleted: "A departure has been completed.",
      DeparturePendingApproval: "A departure is awaiting approval.",
      DeparturePendingFurtherApproval: "A departure is awaiting further approval.",
      DepartureApproved: "A departure has been approved.",
      DepartureDeclined: "A departure has been declined.",
      DepartureCancelled: "A departure has been cancelled.",
      DepartureRequested: "A departure has been requested.",
      DepartureBefore: "This is a reminder that an upcoming departure has been scheduled at {{dateTime}}.",
      DepartureAfter: "This is a reminder that there is a late departure scheduled ({{diff}} ago).",
      ShiftArrivalCreated: "A shift arrival has been created.",
      ShiftArrivalStarted: "A shift arrival has been started.",
      ShiftArrivalCompleted: "A shift arrival has been completed.",
      ShiftArrivalPendingApproval: "A shift arrival is awaiting approval.",
      ShiftArrivalApproved: "A shift arrival has been approved.",
      ShiftArrivalDeclined: "A shift arrival has been declined.",
      ShiftArrivalBefore: "This is a reminder that an upcoming shift arrival has been scheduled at {{dateTime}}.",
      ShiftArrivalAfter: "This is a reminder that there is a late shift arrival scheduled ({{diff}} ago).",
      ShiftDepartureCreated: "A shift departure has been created.",
      ShiftDepartureStarted: "A shift departure has been started.",
      ShiftDepartureCompleted: "A shift departure has been completed.",
      ShiftDeparturePendingApproval: "A shift departure is awaiting approval.",
      ShiftDepartureApproved: "A shift departure has been approved.",
      ShiftDepartureDeclined: "A shift departure has been declined.",
      ShiftDepartureBefore: "This is a reminder that an upcoming shift departure has been scheduled at {{dateTime}}.",
      ShiftDepartureAfter: "This is a reminder that there is a late shift departure scheduled ({{diff}} ago).",
      Custom: "Custom",
      CustomCreated: "{{name}} action has been created.",
      CustomStarted: "{{name}} action has been started.",
      CustomCompleted: "{{name}} action has been completed.",
      CustomCancelled: "{{name}} action has been cancelled.",
      CustomBefore: "This is a reminder that an upcoming {{name}} action has been scheduled at {{dateTime}}.",
      CustomAfter: "This is a reminder that there is a late {{name}} action scheduled ({{diff}} ago).",
      Regards: "Regards",
      PortControl: "Port Control",
      FooterMsg: "Do not reply directly to this email. This email was sent from a notification-only address that cannot accept incoming mail. If you have any questions or need assistance please contact Administrator for help.",
      Link: "If you are a registered SmartPort user you can see port call at"
    }
  },
  CargoType: {
    Labels: {
      Name: "Name",
      Unit: "Unit of measurement",
      Metre: "Metre",
      Tonne: "Tonne",
      MetersCubed: "Cubic metre",
      Each: "Each",
      DuplicateName: "A cargo type with this name already exists"
    }
  },
  CertificateType: {
    Buttons: {
      AddCertificate: "Add certificate"
    },
    Labels: {
      Name: "Name",
      Description: "Description",
      HelperMessage: "Add a new type of {{category}}",
      NoCertificates: "No certificates",
      ExpiredHelp: "This certificate has expired",
      InvalidHelp: "This certificate is invalid",
      CertificateType: "Certificate type",
      CertificateCategory: "Certificate category",
      CertificateTypeLowerCase: "certificate type",
      CertificateCategoryLowerCase: "certificate category",
      Notes: "Notes",
      ValidFrom: "Valid from",
      ValidTo: "Valid to",
      To: "to",
      More: "(+{{more}} more)",
      ReferenceNumber: "Reference number",
      ReferenceNumberShort: "Ref",
      NameOfHolder: "Name of holder",
      DeleteCertificateTitle: "Delete Certificate : {{name}}",
      DeleteCertificateMessage: "Are you sure you want to delete certificate {{name}}?",
      TypeNotSpecified: "Type not specified",
      Certificates: "Certificates",
      Valid: "Valid",
      Invalid: "Invalid"
    }
  },
  VesselList: {
    Labels: {
      ShowVesselDetails: "Show vessel details",
      NoMoreData: "No more data available.",
      GrossTonnage: "GT",
      NetTonnage: "NT",
      DeadweightTonnage: "DWT",
      PortOfRegistry: "Port of registry",
      Type: "Type",
      Certificates: "Certificates",
      VesselDesc: "Details of vessel",
      ImoDesc: "Vessel IMO",
      MmsiDesc: "Vessel MMSI",
      CallSignDesc: "Vessel call sign",
      DimensionsDesc: "Overall Length x Beam (and Sailing Draught if available)",
      GrossTonnageDesc: "Nonlinear measure of a ship's overall internal volume",
      NetTonnageDesc: "The space available for the accommodations of passengers and the stowage of cargo",
      DeadweightTonnageDesc: "A measure of how much weight a vessel can carry",
      PortOfRegistryDesc: "Port in which a vessel is registered",
      YearBuiltDesc: "Year of build",
      TypeDesc: "Type of vessel",
      FlagDesc: "Flag of vessel",
      CertificatesDesc: "Vessel certificates"
    }
  },
  VoyageBrowser: {
    Labels: {
      ArrivalDetails: 'Arrival Details',
      ArrivalDetailsDesc: 'Voyage arrival details',
      DepartureDetails: 'Departure Details',
      DepartureDetailsDesc: 'Voyage departure details',
      DepartureNotScheduled: 'Initial departure not scheduled',
      ArrivalNotScheduled: 'Final arrival not scheduled',
      LocationNotAllocated: 'Not Allocated',
      Status: 'Status',
      StatusDesc: 'Voyage status'
    }
  },   
  VoyageMenu: {
    Labels: {
      Voyage: 'Voyage',
      ShowDetails: 'Show details',
      DeleteVoyage: 'Delete voyage',
      DeleteVoyageDialogTitle: "Delete Voyage : {{voyageName}}",
      DeleteVoyageDialogMessage: "Are you sure you want to delete {{voyageName}}? This action will close the voyage. Please ensure all details are entered prior to deletion.",
      CancelVoyage: 'Cancel voyage',
      CancelVoyageDialogTitle: "Cancel Voyage : {{voyageName}}",
      CancelVoyageDialogMessage: "Are you sure you want to cancel {{voyageName}}? This action will close the voyage. Please ensure all details are entered prior to cancellation.",
    }
  },
  VoyageTimeline:{
    Labels:{
      BookendTitle: "VOYAGE {{movementType}}",
      AsPlanned:"As planned",
      Delayed:"Delayed",
      InProgress: 'IN PROGRESS',
      Cancelled: 'CANCELLED',
      Planned: 'PLANNED',
      Incomplete: 'INCOMPLETE',
      Completed: 'COMPLETED',
      Aborted: 'ABORTED'
    }
  },
  Invoice: {
    Labels: {
      Invoice: 'INVOICE',
      PortCallId: 'PORT CALL ID',
      Schedule: 'SCHEDULE',
      Location: 'LOCATION',
      Arrival: 'ARRIVAL',
      Departure: 'DEPARTURE',
      VesselDetails: 'VESSEL DETAILS',
      ScheduleSummary: 'SCHEDULE SUMMARY',
      Name: 'NAME',
      ListOfCharges: 'LIST OF CHARGES',
      NoItems: 'Currently no chargeable items',
      Id: "ID",
      Tarrif: "TARIFF",
      Quantity: "QUANTITY",
      UnitCost: "UNIT COST",  
      Total: 'TOTAL',
      GrossTotal: 'GROSS TOTAL'
    }
    // TODO: keep test translations up to date with Invoice
  }
}
