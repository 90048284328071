import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  DotsVertical,
  AccountMinus,
} from 'mdi-material-ui';
import { useHistory, useParams } from 'react-router-dom';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { DataStore } from 'aws-amplify';
import { PortCall, Action, ActionState } from '../../models';
import { useTranslation } from 'react-i18next';

const AgentHandoverEventActionMenu = ({
  action,
  readOnly
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false); // open={Boolean(confirmProps)} flickers on close
  const [confirmProps, setConfirmProps] = useState(null);
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();

  const handleClose = (e) => {
    setAnchorEl(null);
    e && e.preventDefault();
  }

  const handleDelete = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.DeleteAgentHandoverDialogTitle'),
      message: t('PortCallTimelineMenu.Labels.DeleteAgentHandoverDialogMessage', { agentName: action.actionAgent.name }),
      onConfirm: async () => {
        const portCall = (await DataStore.query(PortCall, c => c.id('eq', action.portCall.id))).pop();
        await DataStore.save(PortCall.copyOf(portCall, updated => {
          updated.agents = portCall.agents.filter(a => a.actionId !== action.id)
        }));
        await DataStore.save(Action.copyOf(action, updated => {
          updated.state = ActionState.DELETED
        }));

        setOpenConfirm(false);
        if(params.actionId && params.actionId === action.id){
          history.push(`/port-call/${portCall.id}`);
        }
      }
    });
    setOpenConfirm(true);
  }

  return (
    <>
      <IconButton
        onClick={e => { setAnchorEl(e.currentTarget); e.preventDefault(); }}
        style={{ pointerEvents: 'all' }}
        disabled={readOnly}
      >
        <DotsVertical />
      </IconButton>
      {anchorEl && <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleDelete}
        >
          <ListItemIcon>
            <AccountMinus />
          </ListItemIcon>
          <ListItemText primary={t('PortCallMenuItem.Labels.DeleteAgentHandover')} />
        </MenuItem>
      </Menu>
      }
      <ConfirmDialog {...confirmProps} onClose={() => setOpenConfirm(false)} open={openConfirm} />
    </>
  )
}

export default AgentHandoverEventActionMenu;