import React, { useContext, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { Button, CardContent, Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, Link, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataStore } from 'aws-amplify';
import Loading from '../Loading';
import { CategoryType, PortCall, VesselData } from '../../models';
import useLastPortCallByVesselId from '../../hooks/useLastPortCallByVesselId';
import { NavigationContext, navigationActionConstants } from '../../contexts/navigation';
import { UIContext } from '../../contexts/ui';
import { DataContext, DataContextConstants } from '../../contexts/dataContext';
import { PortCallDetailsHref } from '../../constants/PortCallDetails';
import { saveCargo } from './utils';
import isEqual from 'lodash/isEqual';
import { getPendingPortCallRequests } from "../../utils/getters";
import LogBook from '../LogBook/LogBook';
import ContentIndex from '../ContentIndex';
import VesselDataEdit from '../Vessel/VesselDataEdit';
import VesselItineraryEdit from '../Vessel/VesselItineraryEdit';
import RemarksEdit from './Tabs/RemarksEdit';
import CargoTab from './Tabs/CargoTab';
import PortCallTimeline from './PortCallTimeline';
import VesselSearchForm from '../Vessel/VesselSearchForm';
import DocumentEdit from '../Documents/DocumentEdit';
import PortCallTagAutocomplete from '../Tag/PortCallTagAutocomplete'
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import PortCallTagsDialog from '../../components/Dialog/PortCallTagsDialog';
import CategoryAutocomplete from '../Category/CategoryAutocomplete';
import PortCallCategoryDialog from '../Dialog/PortCallCategoryDialog';
import UnknownVesselDisplayField from '../Vessel/UnknownVesselDisplayField';
import RequestDetails from '../Requests/RequestDetails';
import EditorContentTabs, { EditorContentTabIds, useEditorContentTab } from '../Common/EditorContentTabs';
import useAuditMeta from '../../hooks/useAuditMeta';

const useStyles = makeStyles(theme => ({
  scrollPane: {
    height: '100%',
    overflow: 'auto',
  },
  rootPane: {
    display: "flex",
    flexFlow: "column",
    height: '100%',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    position: 'relative'
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  card: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  contentWrapper: {
    '& > :last-child': {
      marginBottom: "5rem"
    }
  }
}));

const PortCallDetails = ({ id, requestId, portCallDetails }) => {
  const { t } = useTranslation();
  const auditMeta = useAuditMeta();
  const [portCallReference, setPortCallReference] = useState(null);
  const [portCall, setPortCall] = useState(null);
  const lastPortCall = useLastPortCallByVesselId(portCall?.vessel?.id);
  const [portCallTagsConfirmation, setPortCallTagsConfirmation] = useState({ open: false });
  const [portCallCategoryConfirmation, setPortCallCategoryConfirmation] = useState({ open: false });
  const [isUnknownVessel, setIsUnknownVessel] = useState(false);
  //Warn user when another user edit action details
  const isFirstRender = useRef(true);
  const [incomingChanges, setIncomingChanges] = useState({ open: false, acceptIncomingChanges: false });

  const classes = useStyles();
  const tab = useEditorContentTab();
  const [navigationContext,dispatchNavigationContext] = useContext(NavigationContext);
  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const [dataContext, dispatchDataContext] = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();
  const [confirmSave, setConfirmSave] = useState({ open: false, openFunction: null });

  const portCallRequests = useMemo(() => getPendingPortCallRequests(portCall), [portCall]);

  useEffect(() => {
    dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: window.location.pathname });
}, [dispatchNavigationContext]);

  const saveDisabled = useMemo(() => (
    !portCall ||
    !portCallReference ||
    (!portCall?.vesselData?.name) ||
    (!isUnknownVessel && !portCall?.vessel) ||
    isEqual(portCall, portCallReference) ||
    Boolean(portCall?.cargos?.find(c => !c.type && c.deleted !== true))),
    [portCall, portCallReference, isUnknownVessel]);

  useEffect(() => {
    if (!isEqual(portCallReference, portCallDetails)) {
      //Only show the upcoming changes to the change that made from other user edited the action and ignore, if it is the first render
      isFirstRender.current
        ? (isFirstRender.current = false)
        : portCallDetails?.auditMeta?.userId &&
        uiContext?.userName !== portCallDetails?.auditMeta?.userId && !saveDisabled &&
        setIncomingChanges(prev => { return {...prev, open: true }});

      setPortCall(portCallDetails);
      setPortCallReference(portCallDetails);
      if (portCallDetails) {
        setIsUnknownVessel(Boolean(!portCallDetails.vessel));
      }
    }
  }, [portCallReference, portCallDetails, setPortCallReference, setPortCall, setIsUnknownVessel, uiContext?.userName, saveDisabled]);

  const setCategory = useCallback(value => setPortCall(prev => PortCall.copyOf(prev, updated => {
    updated.category = value;
    updated.portCallCategoryId_ = value ? value.id : null;
  })), [setPortCall]);

  const setVessel = useCallback((value, vesselUpdated = null) => {
    const latestVesselDetails = (value) => {
      return PortCall.copyOf(portCall, updated => {
        updated.vessel = value;
        updated.portCallVesselId = value ? value.id : null;
        updated.portCallVesselId_ = value ? value.id : null;
        updated.vesselData = Boolean(value) ? value.vesselData : new VesselData({ name: '' });
      })
    }
    setPortCall(latestVesselDetails(value));
    //update portcall, when the vesseldata change 
    //if there is any changes from vessel don't trigger the save button,(by update portcallreference when update portcall)
    vesselUpdated && setPortCallReference(latestVesselDetails(value));

  }, [portCall, setPortCall]);

  const setVesselData = useCallback(value => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.vesselData = { ...value, certificates: portCall?.vesselData.certificates }
    }))
  }, [portCall, setPortCall]);

  const clearVessel = useCallback(() => {
    setVessel(null);
    setVesselData(new VesselData({ name: '', imo: '', mmsi: '' }));
  }, [setVessel, setVesselData]);

  useEffect(() => {
    if (!lastPortCall || !portCallReference || !portCall || portCall?.vessel?.id === portCallReference?.vessel?.id) return;
    // If the current tags or category are different from the last port call for the
    // new vessel (vessel has been changed) then display user prompts.
    if (JSON.stringify(portCall.portCallTags) !== JSON.stringify(lastPortCall.portCallTags)) {
      setPortCallTagsConfirmation({ open: true, oldTags: portCall.portCallTags, newTags: lastPortCall.portCallTags });
    }
    if (portCall.portCallCategoryId_ !== lastPortCall.portCallCategoryId_) {
      setPortCallCategoryConfirmation({ open: true, oldCategory: portCall.category, newCategory: lastPortCall.category });
    }
  }, [lastPortCall, portCallReference?.vessel?.id, portCall?.vessel?.id, setPortCall, setPortCallTagsConfirmation, setPortCallCategoryConfirmation])

  const cargos = useMemo(() => portCall && portCall.cargos ? portCall.cargos : [], [portCall]);
  const setCargos = useCallback(value => setPortCall(PortCall.copyOf(portCall, updated => { updated.cargos = value; })), [portCall, setPortCall]);

  // TODO until datastore supports empty string arrays
  const portCallTags = useMemo(() => portCall && portCall.portCallTags ? portCall.portCallTags.filter(t => t) : [], [portCall]);
  const setPortCallTags = useCallback((value) => setPortCall(PortCall.copyOf(portCall, updated => { updated.portCallTags = value.length ? value : ['']; })), [portCall, setPortCall]);

  useEffect(() => {
    if (portCall && dataContext.pendingCreateVessel) {
      dispatchDataContext({ type: DataContextConstants.SET_PENDING_CREATE_VESSEL, payload: null });
      const vesselUpdated = true;
      setVessel(dataContext.pendingCreateVessel,vesselUpdated );
    }
  }, [dataContext, dispatchDataContext, portCall, setVessel]);

  const handleSavePortCall = () => {
    saveCargo(portCallReference, cargos);
    DataStore.save(PortCall.copyOf(portCall, updated => { updated.auditMeta = auditMeta; }));
  }

  // TODO until datastore supports empty string arrays
  const documents = useMemo(() => portCall && portCall.documents ? portCall.documents.filter(d => d) : [], [portCall]);
  const setDocuments = useCallback((value) => DataStore.save(PortCall.copyOf(portCallReference, updated => { updated.documents = value.length ? value : ['']; })), [portCallReference]);

  const checkSaveState = () => {
    setConfirmSave({ open: true });
  }

  const indexTree = useMemo(() => [
    {
      label: t('PortCallDetails.Labels.Itinerary'),
      href: PortCallDetailsHref.HREF_ITINERARY,
    },
    {
      label: t('PortCallDetails.Labels.PortCallCategory'),
      href: PortCallDetailsHref.HREF_CATEGORY,
    },
    {
      label: t('PortCallDetails.Labels.Attachments'),
      href: PortCallDetailsHref.HREF_ATTACHMENTS,
    },
    {
      label: t('Vessel.Labels.Vessel'),
      href: PortCallDetailsHref.HREF_VESSEL,
      children: [
        {
          href: PortCallDetailsHref.HREF_IDENTITY,
          label: t('Vessel.Labels.Identity'),
        },
        {
          href: PortCallDetailsHref.HREF_DETAILS,
          label: t('Vessel.Labels.Details'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_DIMENSIONS,
          label: t('Vessel.Labels.Dimensions'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_TONNAGE,
          label: t('Vessel.Labels.Tonnage'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_CERTIFICATES,
          label: t('CertificateType.Labels.Certificates'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_PROPULSION,
          label: t('Vessel.Labels.Propulsion'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_RUDDER,
          label: t('Vessel.Labels.Rudder'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_FWD_AZIMUTH,
          label: t('Vessel.Labels.ForwardAzimuth'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_AFT_AZIMUTH,
          label: t('Vessel.Labels.AftAzimuth'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_FWD_TUNNEL,
          label: t('Vessel.Labels.ForwardTunnelThrusters'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_AFT_TUNNEL,
          label: t('Vessel.Labels.AftTunnelThrusters'),
        }
      ]
    },
    {
      label: t('Cargo.Labels.Cargo'),
      href: PortCallDetailsHref.HREF_CARGO,
    },
    {
      label: t('PortCallDetails.Labels.Remarks'),
      href: PortCallDetailsHref.HREF_REMARKS,
    },
    {
      label: t('PortCallDetails.Labels.Tags'),
      href: PortCallDetailsHref.HREF_TAGS,
    },
  ], [t]);

  // return by timeout if item gets deleted and results in empty view
  useEffect(() => {
    let timeout;
    if (!portCall) {
      timeout = setTimeout(() => {
        history.push(navigationContext.lastView);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [portCall, history, location]);

  if (!portCall || !portCallReference) return <Loading />
  return (
    <>
      <Prompt
        when={!saveDisabled}
        message={(_, action) => {
          return action === "PUSH" ? t("PortCallDetails.Labels.LeavePromptMessage") : null;
        }}
      />
      <Grid container id={'PortCallDetails'}>
        <Grid
          className={classes.greyPanel}
          item
          xs={5}
        >
          {portCall &&
            <PortCallTimeline portCall={portCall} portCallReference={portCallReference} saveDisabled={saveDisabled} />
          }
        </Grid>

        <Grid
          className={classes.rootPane}
          item
          xs={7}
        >
          <CardContent style={{ flexShrink: 0 }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <EditorContentTabs requests={portCallRequests} />
              <Box position="absolute" right="2rem" display="flex">
                <Typography>
                  <Link
                     id="PortCallDetailsDiscardCloseButton"
                    className={classes.linkButton}
                    to={navigationContext.lastView}
                  >
                    {!saveDisabled ? t("Common.Buttons.DiscardChanges") : t("Common.Buttons.Close")}
                  </Link>
                </Typography>
                {tab === EditorContentTabIds.DETAILS &&
                  <Button
                    id="PortCallDetailsSaveButton"
                    color="primary"
                    onClick={handleSavePortCall}
                    variant="contained"
                    disabled={saveDisabled}
                  >
                    {t("Common.Buttons.Save")}
                  </Button>
                }
              </Box>
            </Grid>
          </CardContent>
          {tab === EditorContentTabIds.DETAILS &&
            <Grid
              item
              container
            >
              <CardContent style={{ flexShrink: "0" }}>
                <ContentIndex tree={indexTree} containerId="portcalldetailscontent" />
              </CardContent>
              <Box flexGrow="1" overflow="auto" height="100%" id="portcalldetailscontent" className={classes.contentWrapper}>
                <CardContent className={classes.card}>
                  <VesselItineraryEdit
                    disabled={readOnly}
                    vessel={portCall.vessel}
                    portCall={portCall}
                    setPortCall={setPortCall}
                  />

                  <Typography id={PortCallDetailsHref.HREF_CATEGORY} variant="h6" className={classes.formLabelLabel}>
                    {t("PortCallDetails.Labels.PortCallCategory")}
                  </Typography>
                  <CategoryAutocomplete
                    id="CategoryInput"
                    disabled={readOnly}
                    type={CategoryType.PORTCALL}
                    value={portCall.category}
                    onChange={(value) => setCategory(value)}
                    label={t('PortCallDetails.Labels.ReportingCategory')}
                  />
                  <Box pb="1rem" />
                </CardContent>
                <CardContent>
                  <Typography
                    id="port-call-attachments"
                    variant="h6"
                    style={{ paddingBottom: '0.5rem' }}
                  >
                    {t('PortCallDetails.Labels.Attachments')}
                  </Typography>
                  <DocumentEdit
                    anchorId="port-call-attachments"
                    filePath={`portcalls/${portCall.id}/`}
                    portCallId={portCall.id}
                    objId={portCall.id}
                    objDocuments={documents}
                    onChange={setDocuments}
                    checkSaveState={checkSaveState}
                    saveDisabled={saveDisabled}
                  />
                </CardContent>
                <CardContent className={classes.card}>
                  {!portCall.vessel
                    ? readOnly
                      ?
                      <UnknownVesselDisplayField vessel={portCall.vesselData} />
                      :
                      <VesselSearchForm
                        vessel={portCall.vessel}
                        setVessel={setVessel}
                        clearVessel={clearVessel}
                        vesselData={portCall.vesselData}
                        setVesselData={setVesselData}
                        isUnknownVessel={isUnknownVessel}
                        setIsUnknownVessel={setIsUnknownVessel}
                        direction="vertical"
                      />
                    :
                    <VesselDataEdit
                      actions={portCall.actions}
                      readOnly={readOnly}
                      vesselData={portCall.vesselData}
                      setVesselData={setVesselData}
                      vessel={portCall.vessel}
                      setVessel={setVessel}
                      portCallId={id}
                      editVessel={saveDisabled}
                      portCallStatus={portCall.status}
                    />
                  }
                </CardContent>
                <CargoTab
                  disabled={readOnly}
                  id={id}
                  cargos={cargos}
                  setCargos={setCargos}
                />

                <CardContent className={classes.card}>
                  <RemarksEdit
                    disabled={readOnly}
                    portCall={portCall}
                    setPortCall={setPortCall}
                  />
                </CardContent>

                <CardContent className={classes.card}>
                  <Typography
                    className={classes.formLabelLabel}
                    id="port-call-tags"
                    variant="h6"
                  >
                    {t('PortCallDetails.Labels.Tags')}
                  </Typography>
                  <PortCallTagAutocomplete
                    disabled={readOnly}
                    value={portCallTags}
                    onChange={setPortCallTags}
                    label={t("PortCallDetails.Labels.PortCallTags")}
                  />
                </CardContent>
              </Box>
            </Grid>
          }
          {tab === EditorContentTabIds.REQUESTS &&
            <Grid
              className={classes.scrollPane}
              item
            >
              {portCallRequests.map(r => <RequestDetails request={r} key={r.id} portCall={portCall} />)}
            </Grid>
          }
          {tab === EditorContentTabIds.LOGBOOK && <LogBook />}
          <ConfirmDialog
            open={incomingChanges?.open}
            title={t("Common.Labels.IncomingChanges")}
            message={t('Common.Errors.IncomingChangesWarning')}
            onConfirm={() => setIncomingChanges({ open: false, acceptIncomingChanges: true })}
            confirmText={t("Common.Buttons.Confirm")}
            hideCancel={true}
          />
          <ConfirmDialog
            open={confirmSave.open}
            title={t("Common.Labels.UnsavedChanges")}
            message={t("PortCallDetails.Labels.UnsavedChangesMessage")}
            onClose={() => { setConfirmSave({ open: false }) }}
            confirmText={t("Common.Buttons.Leave")}
          />
          <PortCallTagsDialog
            open={portCallTagsConfirmation.open && Boolean(lastPortCall)}
            title={t("PortCallDetails.Labels.OverwritePortCallTags")}
            message={t("PortCallDetails.Labels.OverwritePortCallTagsMessage")}
            onClose={() => { setPortCallTagsConfirmation({ open: false }) }}
            newTags={portCallTagsConfirmation.newTags && portCallTagsConfirmation?.newTags?.join(', ')}
            oldTags={portCallTagsConfirmation.oldTags && portCallTagsConfirmation?.oldTags?.join(', ')}
            onConfirm={(setNewTags) => {
              if (setNewTags) {
                setPortCall(PortCall.copyOf(portCall, updated => {
                  updated.portCallTags = lastPortCall.portCallTags;
                }));
              }
              setPortCallTagsConfirmation({ open: false })
            }}
          />
          <PortCallCategoryDialog
            open={portCallCategoryConfirmation.open && Boolean(lastPortCall)}
            title={t("PortCallDetails.Labels.OverwritePortCallCategory")}
            message={t("PortCallDetails.Labels.OverwritePortCallCategoryMessage")}
            onClose={() => { setPortCallCategoryConfirmation({ open: false }) }}
            newCategory={portCallCategoryConfirmation.newCategory}
            oldCategory={portCallCategoryConfirmation.oldCategory}
            onConfirm={(setNewCategory) => {
              if (setNewCategory) {
                setPortCall(PortCall.copyOf(portCall, updated => {
                  updated.category = lastPortCall.category;
                  updated.portCallCategoryId_ = lastPortCall.category ? lastPortCall.category.id : null;
                }));
              }
              setPortCallCategoryConfirmation({ open: false })
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PortCallDetails;