import React, { useContext, useEffect, useState } from 'react';

const TariffBookEditorContext = React.createContext();
const TariffBookEditorUpdateContext = React.createContext();

export const useTariffBookEditorContext = () => {
  return useContext(TariffBookEditorContext);
};

export const useTariffBookEditorUpdateContext = () => {
  return useContext(TariffBookEditorUpdateContext);
};

export const TariffBookEditorProvider = ({ tariffBook, children }) => {
  // console.log("TBOOK", tariffBook);
  const [ editBook, setEditBook ] = useState({
    bookId: tariffBook.id,
    tariffs: tariffBook.tariffs,
    defaultCharges: tariffBook.defaultCharges,
  });

  useEffect(() => {
    setEditBook({
      bookId: tariffBook.id,
      tariffs: tariffBook.tariffs,
      defaultCharges: tariffBook.defaultCharges
    });
  }, [tariffBook]);

  const updateTariffs = ( bookId, tariffs ) => {
    // console.log('updateTariffs', tariffs);
    setEditBook(prev => {
      return {
        ...prev,
        bookId: bookId,
        tariffs: tariffs
      }
    });
  };

  const updateDefaultCharges = ( defaultCharges ) => {
    // console.table('updateDefaultCharges', defaultCharges);
    setEditBook(prev => {
      return {
        ...prev,
        defaultCharges: defaultCharges
      }
    });
  };

  return (
    <TariffBookEditorContext.Provider value={editBook}>
      <TariffBookEditorUpdateContext.Provider value={{
        updateTariffs: updateTariffs,
        updateDefaultCharges: updateDefaultCharges,
      }}>
        {children}
      </TariffBookEditorUpdateContext.Provider>
    </TariffBookEditorContext.Provider>
  )
};