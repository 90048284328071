import testFormatDistance from "./testFormatDistance";
import testFormatLong from "./testFormatLong";
import testFormatRelative from "./testFormatRelative";
import testLocalize from "./testLocalize";
import testMatch from "./testMatch";

const _ = {
  code: 'test',
  formatDistance: testFormatDistance,
  formatLong: testFormatLong,
  formatRelative: testFormatRelative,
  localize: testLocalize,
  match: testMatch,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};

export default _;