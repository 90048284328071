export const ActionDetailsHref = {
  HREF_SCHEDULE: 'action-schedule',
  HREF_CONTACT: 'action-contact',
  HREF_PILOTAGE: 'action-pilotage',
  HREF_VESSEL: 'action-vessel',
  HREF_VESSEL_DETAILS: 'action-vessel-details',
  HREF_VESSEL_CONDITION: 'action-vessel-condition',
  HREF_CHECKLIST: 'action-checklist',
  HREF_ATTACHMENTS: 'action-attachments',
  HREF_CHARGEABLES: 'action-chargeables',
  HREF_NOTES: 'action-notes',
  HREF_FAL5_CREW_LIST: 'fal5-crew-list'
};
