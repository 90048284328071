import React, {
  useContext,
  useEffect
} from 'react';
import { Box, CardContent, List, ListItem, ListItemText, ListSubheader, makeStyles, Typography } from '@material-ui/core';
import AdminGeneralSettings from './AdminGeneralSettings';
import AdminTariff from './AdminTariff';
import AdminTariffBook from './AdminTariffBook/AdminTariffBook';
import AdminTariffUnit from './AdminTariffUnit';
import AdminPortCallCategory from './AdminPortCallCategory';
import AdminContact from './AdminContact';
import { UIContext } from '../../contexts/ui';
import AdminActionTypeModelEditor from './AdminActionTypeModelEditor';
import AdminAutomation from './AdminAutomation';
import AdminApproval from './AdminApproval';
import AdminNotifications from './AdminNotifications';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import { useTranslation } from 'react-i18next';
import FeatureFlags from '../../constants/FeatureFlags';
import AdminAISStatusMonitoring from './AdminAISStatusMonitoring';
import { Route, useLocation, useHistory, NavLink } from 'react-router-dom';
import AdminCertificateType from './AdminCertificateType';
import AdminCargoType from './AdminCargoType';

const useStyles = makeStyles(() => ({
  left: {
    minWidth: "12rem",
    background: "#f5f5f5",
    borderRight: "0.0625rem solid #e0e0e0",
  },
  listSubHeader: {
    height: "2rem",
    display: "flex",
    alignItems: "center",
    userSelect: "none"
  }
}));

const Admin = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [uiContext] = useContext(UIContext);
  const { isAdmin } = uiContext;
  const isAutomationEnabled = useFeatureSetting('portcall-automation');
  const isAisReceiverStatusEnabled = useFeatureSetting(FeatureFlags.AIS_RECEIVER_STATUS);
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  const ADMIN_PORT_DEFINITION_ITEM = {
    id: 'PortDefinitions',
    label: t('Admin.Labels.PortDefinitions'),
    children: [
      {
        id: 'ActionTypes',
        label: t('Admin.Labels.ActionTypes'),
        Component: AdminActionTypeModelEditor,
        path: "/admin/definitions/action-types"
      },
      ...[!isTariffBookEnabled &&
      {
        id: 'Tariffs',
        label: t('Admin.Labels.Tariffs'),
        Component: AdminTariff,
        path: "/admin/definitions/tariffs"
      }].filter(i => i),
      ...[isTariffBookEnabled && {
        id: 'TariffBook',
        label: t('Admin.Labels.TariffBook'),
        Component: AdminTariffBook,
        path: "/admin/definitions/tariffbook"
      }].filter(i => i),
      {
        id: 'TariffUnits',
        label: t('Admin.Labels.TariffUnits'),
        Component: AdminTariffUnit,
        path: "/admin/definitions/tariff-units"
      },
      {
        id: 'PortCallCategories',
        label: t('Admin.Labels.PortCallCategories'),
        Component: AdminPortCallCategory,
        path: "/admin/definitions/port-call-categories"
      },
      {
        id: 'CargoType',
        label: t('Admin.Labels.CargoType'),
        Component: AdminCargoType,
        path: "/admin/definitions/cargo-type"
      },
      {
        id: 'CertificateType',
        label: t('Admin.Labels.CertificateType'),
        Component: AdminCertificateType,
        path: "/admin/definitions/certificate-type"
      },
    ]
  };

  const OPERATIONAL_SETTINGS_ITEM = {
    id: 'OperationalSettings',
    label: t('Admin.Labels.OperationalSettings'),
    children: [
      {
        id: 'Contacts',
        label: t('Admin.Labels.Contacts'),
        Component: AdminContact,
        path: "/admin/contacts"
      },
      {
        id: 'Notifications',
        label: t('Admin.Labels.Notifications'),
        Component: AdminNotifications,
        path: "/admin/notifications"
      },
      ...[isAutomationEnabled && {
        id: 'Automation',
        label: t('Admin.Labels.Automation'),
        Component: AdminAutomation,
        path: "/admin/automation"
      }].filter(i => i),
      {
        id: 'SecondaryApproval',
        label: t('Admin.Labels.SecondaryApproval'),
        Component: AdminApproval,
        path: "/admin/secondary-approval"
      }
    ]
  };

  const adminMenuWithFeatures = [
    {
      id: 'GeneralSettings',
      label: t('Admin.Labels.GeneralSettings'),
      Component: AdminGeneralSettings,
      path: "/admin/general-settings"
    },
    ADMIN_PORT_DEFINITION_ITEM,
    OPERATIONAL_SETTINGS_ITEM,
    ...[
      isAisReceiverStatusEnabled && {
        id: 'AISReceiverStatus',
        label: t('Admin.Labels.AISStatusMonitoring'),
        Component: AdminAISStatusMonitoring,
        path: "/admin/ais-status-monitoring"
      },
    ].filter(i => i),
  ]
  

  // in case user manages to reach this point
  useEffect(() => {
    if (location.pathname === "/admin") {
      history.replace("/admin/general-settings");
    }
  }, [location, history]);

  const renderMenuItem = (menu, indent) =>
    <NavLink to={menu.path} style={{ color: "inherit", textDecoration: "inherit" }}>
      <ListItem id={menu.id} key={menu.id} button disableRipple selected={location.pathname === menu.path} style={{ paddingLeft: `${indent}rem` }}>
        <ListItemText primary={menu.label} primaryTypographyProps={{ noWrap: true }} />
      </ListItem>
    </NavLink>
  const renderSubMenu = (menu, indent) =>
    <List
      key={menu.id}
      disablePadding
      subheader={
        <ListSubheader className={classes.listSubHeader} style={{ paddingLeft: `${indent}rem` }}>
          <Typography variant="body2" noWrap>{menu.label}</Typography>
        </ListSubheader>
      }
    >
      {menu.children.map(m => m.children ? renderSubMenu(m, indent + 1) : renderMenuItem(m, indent + 1))}
    </List>;

  if (!isAdmin) return null;
  return (    
    <Box flexGrow="1" display="flex" >
      <CardContent className={classes.left} >
        <Box display="flex" alignItems="center" height="2rem" paddingBottom="1rem">
          <Typography variant="h5">{t('Admin.Labels.Administration')}</Typography>
        </Box>
        <List disablePadding>
          {adminMenuWithFeatures.map(m => m?.children ? renderSubMenu(m, 1) : renderMenuItem(m, 1))}
        </List>
      </CardContent>
      <Box display="flex" flex="1 1">
        {adminMenuWithFeatures.map(item =>
          <>
            <Route exact path={item.path}>
              {item.Component && <item.Component />}
            </Route>
            {item.children?.map(child =>
              <Route exact path={child.path}>
                {child.Component && <child.Component />}
              </Route>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Admin;
