import { Box, Typography, ListItemIcon, makeStyles } from '@material-ui/core';
import { MovementActionDisplayTypeKeys as madt } from '../../../constants/MovementActionDisplayType';
import { useTranslation } from 'react-i18next';
import VoyageListItem from './VovageListItem';
import ActionState from '../../Action/ActionState';
import VoyageListItemDateTime from './VoyageListItemDateTime';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width:'100%',
    gap: '1.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  dateTime:{
   width:'5rem'
  },
  smallIcon:{
    marginRight:0
  },
  parentLocation: {
    marginBottom: '-0.25rem'
  }
}));

const VoyageAction = ({ portCall, action, ...otherProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const actionTime = action.timeActual || action.timePlanned; 

  return (
    <VoyageListItem {...otherProps} portCall={portCall} action={action}>
      <Box className={classes.container} >
        <ListItemIcon className={`TimelineMovementActionIcon ${classes.smallIcon}`}>
          <ActionState action={action} />
        </ListItemIcon>
        <Typography variant="subtitle1" noWrap className={`TimelineMovementActionLabel ${classes.dateTime}`}>
          {t(madt[action.movementType])}
        </Typography>
        <VoyageListItemDateTime actionTime={actionTime} />
      </Box>
    </VoyageListItem>
  )
}
export default VoyageAction;