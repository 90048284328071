function declension(scheme, count) {
  // scheme for count=1 exists
  if (scheme.one !== undefined && count === 1) {
    return scheme.one;
  }

  var rem10 = count % 10;
  var rem100 = count % 100; // 1, 21, 31, ...

  if (rem10 === 1 && rem100 !== 11) {
    return scheme.singularNominative.replace('{{count}}', count); // 2, 3, 4, 22, 23, 24, 32 ...
  } else if (rem10 >= 2 && rem10 <= 4 && (rem100 < 10 || rem100 > 20)) {
    return scheme.singularGenitive.replace('{{count}}', count); // 5, 6, 7, 8, 9, 10, 11, ...
  } else {
    return scheme.pluralGenitive.replace('{{count}}', count);
  }
}

function buildLocalizeTokenFn(scheme) {
  return function (count, options) {
    if (options.addSuffix) {
      if (options.comparison > 0) {
        if (scheme.future) {
          return declension(scheme.future, count);
        } else {
          return 'XXXXXXX ' + declension(scheme.regular, count);
        }
      } else {
        if (scheme.past) {
          return declension(scheme.past, count);
        } else {
          return declension(scheme.regular, count) + ' XXXXXXX';
        }
      }
    } else {
      return declension(scheme.regular, count);
    }
  };
}

var formatDistanceLocale = {
  lessThanXSeconds: buildLocalizeTokenFn({
    regular: {
      one: 'XXXXXXX',
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      one: 'XXXXXXX',
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xSeconds: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    },
    past: {
      singularNominative: '{{count}} XXXXXXX XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  halfAMinute: function (_, options) {
    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'XXXXXXX';
      } else {
        return 'XXXXXXX';
      }
    }

    return 'XXXXXXX';
  },
  lessThanXMinutes: buildLocalizeTokenFn({
    regular: {
      one: 'XXXXXXX',
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      one: 'XXXXXXX',
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xMinutes: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    },
    past: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  aboutXHours: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xHours: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    }
  }),
  xDays: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    }
  }),
  aboutXWeeks: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xWeeks: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    }
  }),
  aboutXMonths: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xMonths: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    }
  }),
  aboutXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  xYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: '{{count}} XXXXXXX',
      singularGenitive: '{{count}} XXXXXXX',
      pluralGenitive: '{{count}} XXXXXXX'
    }
  }),
  overXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  }),
  almostXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    },
    future: {
      singularNominative: 'XXXXXXX {{count}} XXXXXXX',
      singularGenitive: 'XXXXXXX {{count}} XXXXXXX',
      pluralGenitive: 'XXXXXXX {{count}} XXXXXXX'
    }
  })
};

function testFormatDistance(token, count, options) {
  options = options || {};
  return formatDistanceLocale[token](count, options);
}

export default testFormatDistance;