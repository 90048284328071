export const filterTypes = {
  ALL: "All",
}

export const filterActions = {
  DEFAULT_FILTER: "DEFAULT_FILTER",
  UPDATE_FILTER: "UPDATE_FILTER",
  USE_FILTER: "USE_FILTER",
  NO_NAME: "NO_NAME",
  SPECIAL_CHARACTERS: "SPECIAL_CHARACTERS",
  NOT_UNIQUE: "NOT_UNIQUE",
  NEW_FILTER: "NEW_FILTER",
}

export const filterCondition = {
  CHANGED: "CHANGED",
  NO_CHANGE: "NO_CHANGE",
  NEW: "NEW"
}