import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PortCallCategoryDialog = ({ open, title, message, onConfirm, onClose, cancelText = "Cancel", confirmText = "Confirm", oldCategory, newCategory }) => {
  const [category, setCategory] = useState("newCategory");
  const handleChange = (e) => {
    setCategory(e.target.value); 
  };

  const categoryLabel = (category, label) => {
    return (
      <Typography component="div">
        <Box fontWeight="fontWeightBold" display="inline">
          {label}
        </Box>
        {category ? category.name : (<Box fontWeight="fontWeightLight" display="inline">No category</Box>)}
      </Typography>);
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick') {
      return false;
    }
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <RadioGroup name="category" value={category} onChange={handleChange}>
          <FormControlLabel value="newCategory" control={<Radio />} label={categoryLabel(newCategory, "Overwrite with new category: ")} />
          <FormControlLabel value="oldCategory" control={<Radio />} label={categoryLabel(oldCategory, "Keep existing category: ")} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {onConfirm && 
          <Button onClick={() => onConfirm(category === "newCategory")} color="primary">
            {confirmText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

PortCallCategoryDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  messageSecondary: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

export default PortCallCategoryDialog;