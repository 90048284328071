import React, {
  createContext,
  useReducer,
} from 'react';


export const mapActionConstants = {
  SET_MAP: 'SET_MAP',
};

const initialState = {
  map: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case mapActionConstants.SET_MAP:
      return {
        ...state,
        map: action.payload
      };
    default:
      return state;
  }
}

export const setMap = (map) => ({
  type: mapActionConstants.SET_MAP,
  payload: map,
});

export const MapContext = createContext({});

export const MapContextProvider = ({ children }) => (
  <MapContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </MapContext.Provider>
);