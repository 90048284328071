import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

/**
 * 
 *  ContentIndex
 *  Renders a nested list of in page links.
 *  Component accepts a tree object with labels
 *  and optional links. Any children render
 *  recursively (I think).
 * 
 */

const useStyles = makeStyles(theme => ({
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  menuLinkActive: {
    color: "rgb(000,150,214)"
  },
  fontWeightRegular: {
    fontWeight: '400',
    fontSize: '.875rem',
    lineHeight: '1.4rem'
  },
  active: {
    color: "red"
  }
  
}));

const Item = ({ 
  children,
  href,
  label,
  outlineLevel,
  filter = [],
  activeHref
}) => {
  const classes = useStyles();
  const filteredChildren = filter && children ? children.filter(el => !filter.includes(el.href)) : children;

  return (
    <>
      {label !== '' &&
        <>
        <Typography
          component="p"
          className={classes.fontWeightRegular}
          variant={outlineLevel}
        >
        {
          href &&
          <a
            className={`${classes.menuLink} ${href === activeHref ? classes.menuLinkActive : ''}`}
            href={`#${href}`}
          >
            {label}
          </a>
        }
        {
          !href &&
          <>{label}</>
        }
        </Typography>
        {filteredChildren &&
          <Box
            key={`${label}${href ? `${href}` : ''}-wrapper`}
            pl="1rem"
          >
            {
              filteredChildren.map(item => {
                return (
                  <Item
                    children={item.children}
                    activeHref={activeHref}
                    href={item.href}
                    key={`${item.label}${item.href ? `${item.href}` : ''}`}
                    label={item.label}
                    outlineLevel="h6"
                    filter={filter}
                  />
                )
              })
            }
          </Box>
        }
        </>
      }
    </>
  )
}

// margin to set active href early
const ACTIVE_CALC_OFFSET = 25;

export const ContentIndex = ({
  tree,
  filter = [],
  containerId
}) => {
  const filteredItems = filter ? tree.filter(el => !filter.includes(el.href)) : tree;
  const [activeHref, setActiveHref] = useState(null);
  useEffect(() => {
    if (!containerId || !tree) return;
    const container = document.getElementById(containerId);
    // only one level deep should be enough
    const parse = (items) => {
      let next;
      for (let i of items) {
        const el = document.getElementById(i.href);
        if (el && el.offsetTop - container.offsetTop - ACTIVE_CALC_OFFSET < container.scrollTop) {
          next = i.href;
        }
        if (i.children) {
          next = parse(i.children) || next;
        }
      }
      return next;
    }
    const handleScroll = () => {
      const nextHref = parse(tree);
      if (nextHref) {
        setActiveHref(prev => prev !== nextHref ? nextHref : prev);
      }
    }
    if (container) {
      container.addEventListener("scroll", handleScroll, { passive: true });
      tree.length && setActiveHref(tree[0].href);
    }
    return () => container && window.removeEventListener("scroll", handleScroll);
  }, [containerId, tree, setActiveHref]);
  return (
    <>
      {
        filteredItems.map((item, index) => {
          return (
            <div key={`outer-wrapper-${index}`}>
              <Item
                children={item.children || null}
                activeHref={activeHref}
                href={item.href}
                key={`${item.label}${item.href ? `${item.href}` : ''}`}
                label={item.label}
                outlineLevel="h5"
                filter={filter}
              />
            </div>
          )
        })
      }
    </>
  )
}

ContentIndex.defaultProps = {}

ContentIndex.propTypes = {
  // The tree to render, array of objects
  tree: PropTypes.arrayOf(PropTypes.shape({
    //The text displayed for the item
    label: PropTypes.string.isRequired,
    // The destination of the item link (will be prefixed with '#')
    href: PropTypes.string,
    // Child items which have the same shape but no children
    children: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    }))
  })),
}

export default withRouter(ContentIndex);