import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
} from '@material-ui/core';

/**
 *
 *  PortListItem
 *  Displays an port as a material list item.
 *  Primary text is always the agent name.
 *
 */

const PortListItem = ({
  port,
  button,
  disableGutters,
  selected,
  ...otherProps
}) => {
  return (
    <ListItem
      button={button}
      component="div"
      disableGutters={disableGutters}
      selected={selected}
      {...otherProps}
    >
      <ListItemText
        primary={`${port.name} (${port.countryCode} ${port.portCode})` }
        primaryTypographyProps={{
          component: 'p',
          noWrap: true,
          variant: 'body1'
        }}
      />
    </ListItem>
  );
};

PortListItem.defaultProps = {
  button: false,
  disableGutters: true,
  selected: false,
};

PortListItem.propTypes = {
  // whether to render item as a button
  button: PropTypes.bool,
  // whether to diable gutters
  diableGutters: PropTypes.bool,
  // passed to ListItem
  selected: PropTypes.bool,
}

export default PortListItem;
