import { isBefore, isEqual } from 'date-fns';
import { getCertificateName } from './getters';
/*
  Utilities for certificates validty check
*/

export const isBeforeOrEqual = (date1, date2) => isBefore(date1, date2) || isEqual(date1, date2)

export const updateDateTime = (date, hours, minutes, secs) => {
  const newDate = new Date(date);
  newDate.setHours(hours)
  newDate.setMinutes(minutes)
  newDate.setSeconds(secs)
  return newDate
}

/** Check if the certificate expired */
export const isExpired = (certificateToDate, toDate) => isBeforeOrEqual(updateDateTime(certificateToDate, 23, 59, 59), new Date(toDate)) ?? false

/** Checks if the certificate is valid in a range of dates(from, to) */
export const isValid = (certificateFromDate, certificateToDate, fromDate, toDate) => (isBeforeOrEqual(updateDateTime(certificateFromDate, 0, 0, 1), new Date(fromDate)) && isBeforeOrEqual(new Date(toDate), updateDateTime(certificateToDate, 23, 59, 59))) ?? true

/** Checks validty for one certificate */
export const checkValidityForCertificate = (certificate, fromDate = new Date(), toDate = new Date()) => ({
  ...certificate,
  name: getCertificateName(certificate),
  isValid: isValid(certificate?.validFromDate, certificate?.validToDate, fromDate, toDate),
  isExpired: isExpired(certificate?.validToDate, toDate)
})

/** Checks validty for a list of certificates */
export const checkValidity = (certificates, fromDate = new Date(), toDate = new Date()) => certificates.map(c => checkValidityForCertificate(c, fromDate, toDate))
