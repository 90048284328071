export const dateToString = (date) => {
    if(!date) return "";
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

export const byteToString = (bytes, decimals = 2) => {
  if(!bytes || bytes === 0) return '';
  const k = 1024;
  decimals = decimals < 0 ? 0: decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

export const truncateFileName = (str, n = 40) => {
  if(!str) return "";
  if(str.length < n) return str;
  return `${str.substr(0,n-1)}...`; 
}
  
