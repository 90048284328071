import { useTranslation } from "react-i18next";
import LoadingDialog from "../../Dialog/LoadingDialog";
import { useCallback, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { onFileExportResult } from "../../../graphql/subscriptions";
import { FileExportResultStatus } from "../../../models";

const ExportLoadingDialog = ({ open, modal, loading, error = null, onError, onSuccess, onClose }) => {
  const cancelled = useRef(null);
  const { t } = useTranslation();

  const _onError = useCallback((localError = null) => {
    const errorMessage = localError ? localError : error;
      if (!cancelled.current) {
          cancelled.current = true;
          onError && typeof onError === 'function' && onError(errorMessage);
      }
  }, [error, onError]);
  
  const _onSuccess = useCallback(({ s3Key, responseId, status }) => {
      if (!cancelled.current) {
           cancelled.current = true;
           onSuccess && typeof onSuccess === 'function' && onSuccess(s3Key, responseId, status);
      }
  }, [onSuccess]);
  
  useEffect(() => {
    //only continue subcribe if dialog haven't return value
    if (cancelled.current) return;
      //Timeout after 60 seconds (return error) from start of export if STARTED not received
      const timeout = setTimeout(() => _onError(), 60000);
      //make a subscriotion to onFileExportResult, until it recieved s3Key or return any error
      const subscription = API.graphql(graphqlOperation(onFileExportResult)).subscribe({
        next: ({ value }) => {
      const { s3Key, id: responseId, status } = value?.data?.onFileExportResult;
        s3Key && _onSuccess({ s3Key, responseId, status });
        //**Error handling
        //clear timeout, when recieved STARTED
        if (status === FileExportResultStatus.STARTED) {
            cancelled.current = false;
            clearTimeout(timeout);
        }
        //throw an error ,if Any ERROR status in subscription
        if (status === FileExportResultStatus.ERROR) {
             _onError();
        }
        },
            error: (e) => _onError(e)
    });
    return () => {
        clearTimeout(timeout);
        subscription.unsubscribe();
    }
  }, [onSuccess, _onError, _onSuccess]);
  
  return (
        <LoadingDialog
            modal={modal}
            loading={loading}
            message={t('ExportPortCalls.Labels.PreparingDocument')}
            title={t('ExportPortCalls.Labels.Loading')}
            error={{ errorMessage: error?.message, errorCode: error?.errorCode, errorDetails: error?.errorDetails }}
            onClose={onClose}
            open={open}
        />
    )
}
export default ExportLoadingDialog;