import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const AIS_RECEIVER_TIMESTAMP_FORMAT = "DD-MM-YYYY HH:mm:ss"

/**
 * Parse the AIS last timestamp formatted as string
 * @param {String} timestamp ISO format timestamp
 * @returns An Date object(Dayjs)
 */
export const parseAISReceiverTimestamp = (timestamp) => {
  let tsDayjs = dayjs(timestamp);
  if(!tsDayjs.isValid()){
    tsDayjs = dayjs(timestamp, AIS_RECEIVER_TIMESTAMP_FORMAT);
  }
  return tsDayjs;
}

/**
 * Parse a position formatted as a string of lat,long
 * @param {String} stringPos the position to parse
 * @returns An object of position(lat,long)
 */
export const parsePosition = (stringPos) => {
  return {
    lat: stringPos?.split(',')[0],
    long: stringPos?.split(',')[1]
  }
}