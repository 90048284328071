import React , {useEffect, useState, useRef, useContext } from 'react';

import Overlay from 'ol/Overlay';
import { MapContext } from './contexts/map';
import { Attrs } from './constants';

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';

import { debounce } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  popup: {
    overflow: 'visible',
    position: 'absolute',
    bottom: '8px',
    left: '-50px',
    minWidth: '150px',
    '&:after': {
      content: '""',
      position: 'absolute',
      boxShadow: 'rgba(0, 0, 0, 0.3) 2px 2px 2px',
      transform: 'rotate(45deg)',
      top: 'calc(100% - 0.5rem)',
      left: '45px',
      borderWidth: '8px',
      borderStyle: 'solid',
      borderColor: 'transparent #FFF #FFF transparent',
    }
  },
}));

const Popup = ({ children, action, overlayId, delay=100}) => {
  const [ context ] = useContext(MapContext);
  const [ popup, setPopup ] = useState(new Overlay({}));
  const popupRef = useRef(null);
  const [ selectedFeature, setSelectedFeature ] = useState(null);
  const classes = useStyles();

  useEffect(() => {

    if(context.map) {
      context.map.on(action, (event) => {
        const feature = context.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });

        if(feature) {
          if(!feature.get(Attrs.POPUP)) return;
          // console.log('set feature');
          showPopup(feature, event.coordinate);
        } else {
          // console.log('unset feature');
          if(!selectedFeature) {
            setSelectedFeature(null);
            context.map.getOverlayById(overlayId).setPosition(undefined);
          }
        }
      });
    }

    setPopup(new Overlay({
      id: overlayId,
      element: popupRef.current,
      stopEvent: false,
      offset: [0, -10],
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    }));
  }, [context]);

  useEffect(() => {
    context.map && context.map.addOverlay(popup);    
  }, [popup]);

  // Inject feature as a prop to child component
  const newChildren = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      feature: selectedFeature
    });
  });

  const showPopup = debounce(async (feature, position) => {
    setSelectedFeature(feature);
    context.map.getOverlayById(overlayId).setPosition(position);
  }, delay);

  // console.log('Popup render', children);

  return (
    <Card id="popup" ref={popupRef} className={classes.popup} >
      {newChildren}
    </Card>
  );
};

export default Popup;