export const TodoStatus = {
    DONE: 'DONE',
    READY: 'READY',
    DRAFT: 'DRAFT', 
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELLED: 'CANCELLED',
    DELETED: 'DELETED',
}

export const outstandingTodosText = 'There are outstanding checklist items against this action, are you sure you wish to continue?';