import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useRef
} from 'react';
import PropTypes from "prop-types";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Fab,
  TextField,
  makeStyles
} from '@material-ui/core';
import {
  AccountPlus,
  Plus,
} from 'mdi-material-ui';

import MoveDepart from '../../icons/MoveDepart';
import ShiftDepart from '../../icons/ShiftDepart';
import ShiftCreateDialog from './ShiftCreateDialog';
import DepartureCreateDialog from './DepartureCreateDialog';
import AgentHandoverCreateDialog from './AgentHandoverCreateDialog';
import CustomActionCreateDialog from './CustomActionCreateDialog';
import AgentDepartureCreateDialog from './AgentPortal/AgentDepartureCreateDialog';
import { ActionState } from '../../models';
import { UIContext } from '../../contexts/ui';
import {
  getActionArrival,
  getActionDeparture,
} from "../../utils/getters";
import {
  getCustomActionIcon
} from "../../constants/CustomActionConstants";
import { useTranslation } from 'react-i18next';
import ArrivalCreateDialog from './ArrivalCreateDialog';

const useStyles = makeStyles(() => ({
  searchField: {
    '&.MuiListItem-button:hover': {
      backgroundColor: '#fff',
    },
    '&.MuiListItem-button': {
      backgroundColor: '#fff',
    },
  },
  overrides: {
    width: '100%'
  }
}));

const PortCallTimelineActionMenu = ({
  portCall,
  actions,
  dispatchToPortCall,
  customActionTypes,
  saveDisabled,
  ...otherProps
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [shiftCreateAnchorEl, setShiftCreateAnchorEl] = useState(null);
  const [departureCreateAnchorEl, setDepartureCreateAnchorEl] = useState(null);
  const [arrivalCreateAnchorEl, setArrivalCreateAnchorEl] = useState(null);
  const [agentHandoverCreateAnchorEl, setAgentHandoverCreateAnchorEl] = useState(null);
  const [{ anchor: customActionCreateAnchorEl, customAction }, setCustomActionCreateIdAnchorEl] = useState({ customAction: null, anchor: null })

  const [uiContext,] = useContext(UIContext);
  const agentPortal = uiContext.agentPortal;
  const userContactId = uiContext.userContactId;
  const readOnly = uiContext.readOnly;
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const classes = useStyles();

  
  // disabled value is true if port call is in requested or cancelled state
  const disabled = portCall.status === ActionState.REQUESTED || portCall.status === ActionState.CANCELLED;

  const arrival = getActionArrival(actions);
  const departure = getActionDeparture(actions);
  /** add shift berth 
   * - can be arrival only portcall, but not departure only portcall
   * - if portcall has departure, departure state is not equal to COMPLETED or IN_PROGRESS
  */
  const isShiftAble = useMemo(() => !Boolean(!arrival && departure) && Boolean(!departure || (departure.state !== ActionState.COMPLETED && departure.state !== ActionState.IN_PROGRESS)), [arrival, departure]);
  
  const customActionItems = customActionTypes.map((cA) => ({
    cA: cA,
    name: t('PortCallTimeline.Labels.AddCustom', { actionName: cA.name }),
    dataId: cA.id,
    condition:!agentPortal && arrival && customActionTypes && customActionTypes.length > 0,
    _getCustomActionIcon: getCustomActionIcon,
    _setCustomActionCreateIdAnchorEl: setCustomActionCreateIdAnchorEl
  }));

  const actionMenuItems = useMemo(() => [
    {
      name: t('PortCallTimeline.Labels.AddArrival'),
      condition: !arrival,
      id: "AddArrivalMenuItem",
      _setAnchorEl: setArrivalCreateAnchorEl,
      Icon: MoveDepart
    },
    {
      name: t('PortCallTimeline.Labels.AddDeparture'),
      condition: !departure,
      id: "AddDepartureMenuItem",
      _setAnchorEl: setDepartureCreateAnchorEl,
      Icon: MoveDepart
    },
    {
      name: t('PortCallTimeline.Labels.AddBerthShift'),
      condition: !agentPortal && isShiftAble,
      id: "AddShiftMenuItem",
      disabled: Boolean(!arrival?.movementLocation),
      _setAnchorEl: setShiftCreateAnchorEl,
      Icon: ShiftDepart
    },
    {
      name: t('PortCallTimeline.Labels.AddAgentHandover'),
      condition: !agentPortal && arrival,
      id: "AddAgentHandOverMenuItem",
      _setAnchorEl: setAgentHandoverCreateAnchorEl,
      Icon: AccountPlus
    },
    ...customActionItems
  ], [agentPortal, arrival, customActionItems, departure, isShiftAble, t]);

  const handleFilterChanged = (event) => {
    setFilter(event.target.value);
  };

  const filteredActionMenuItems = useMemo(() => actionMenuItems.filter(i => i.name.toLowerCase().includes(filter.toLowerCase())), [filter, actionMenuItems]);

  const handleClickAway = () => {
    setAnchorEl(null);
    setFilter("");
  };

  const handleOnClick = (item) => {
    item._setCustomActionCreateIdAnchorEl ?
    item._setCustomActionCreateIdAnchorEl({customAction: item.cA, anchor: anchorEl}) : 
    item._setAnchorEl(anchorEl);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Boolean(anchorEl) && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [anchorEl]);


  return (
    <div {...otherProps}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fab
          color="primary"
          disabled={readOnly || disabled || (!!agentPortal && !!departure) || !saveDisabled }
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
          id="AddActionButton"
        >
          <Plus />
        </Fab>
      </ClickAwayListener>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        id="AddActionMenu"
      >
        {!agentPortal && <MenuItem 
          className={`${classes.searchField}`}
          disableRipple
        >
          <TextField
            id="ActionMenuFilterInput"
            label='Search for...'
            value={filter}
            inputRef={inputRef}
            onChange={handleFilterChanged}
            autoComplete="off"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`${classes.overrides}`}
          />
        </MenuItem>}
        {filteredActionMenuItems.map((item, index) => 
          item.condition && 
            <MenuItem 
              key={`${item.id}-${index}`}
              onClick={() => handleOnClick(item)}
              data-id={item.dataId}
              id={item.id}
              disabled={item.disabled? item.disabled : false}
            >
              <ListItemIcon>
                {item._getCustomActionIcon ? item._getCustomActionIcon() : <item.Icon/>}
              </ListItemIcon> 
              <ListItemText primary={item.name} />
            </MenuItem>
        )}
      </Menu>
      {
        arrivalCreateAnchorEl && !agentPortal &&
        <ArrivalCreateDialog portCall={portCall} onClose={() => setArrivalCreateAnchorEl(null)} anchorEl={arrivalCreateAnchorEl} />
      }
      {departureCreateAnchorEl && !agentPortal &&
        <DepartureCreateDialog portCall={portCall} onClose={() => setDepartureCreateAnchorEl(null)} anchorEl={departureCreateAnchorEl} />
      }
      {departureCreateAnchorEl && agentPortal &&
        <AgentDepartureCreateDialog portCall={portCall} onClose={() => setDepartureCreateAnchorEl(null)} anchorEl={departureCreateAnchorEl} contactId={userContactId} />
      }
      {shiftCreateAnchorEl &&
        <ShiftCreateDialog portCall={portCall} onClose={() => setShiftCreateAnchorEl(null)} anchorEl={shiftCreateAnchorEl} />
      }
      {agentHandoverCreateAnchorEl &&
        <AgentHandoverCreateDialog portCall={portCall} onClose={() => setAgentHandoverCreateAnchorEl(null)} anchorEl={agentHandoverCreateAnchorEl} />
      }
      {customActionCreateAnchorEl &&
        <CustomActionCreateDialog
          portCall={portCall}
          customAction={customAction}
          customActionTypes={customActionTypes}
          onClose={() => setCustomActionCreateIdAnchorEl({ id: "", anchor: null })}
          anchorEl={customActionCreateAnchorEl}
        />
      }
    </div>
  );
};

PortCallTimelineActionMenu.propTypes = {
  portCall: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  dispatchToPortCall: PropTypes.func,
  customActionTypes: PropTypes.array
}

PortCallTimelineActionMenu.defaultProps = {
  customActionTypes: []
}

export default PortCallTimelineActionMenu;
