import { Typography, Tooltip } from "@material-ui/core";

const TariffUnitAdornment = ({ unitText }) => {
  return (
    <Tooltip title={unitText}>
      <Typography noWrap variant='caption' style={{ maxWidth: '80px'}}>{unitText ? unitText : ''}</Typography>
    </Tooltip>
  )
};

export default TariffUnitAdornment;