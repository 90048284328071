import ConfirmDialog from "../../Dialog/ConfirmDialog";
import TextInputDialog from "../../Dialog/TextInputDialog";

const VoyageConfirmDialog = ({
  openConfirm,
  textConfirm,
  setOpenConfirm,
  setTextConfirm,
  confirmProps,
  calculatingVoyageUpdate,
  errorMessage,
  resetTransaction,
  loading,
  error
}) => {

  return (
    <>
      {openConfirm &&
        <ConfirmDialog
          {...confirmProps}
          modal={loading || calculatingVoyageUpdate}
          loading={loading || calculatingVoyageUpdate}
          error={{ message: errorMessage, errorCode: error?.errorCode }}
          onClose={() => {
            setOpenConfirm(false);
            resetTransaction();
          }}
          open={openConfirm}
        />
      }
      {textConfirm &&
        <TextInputDialog
          {...confirmProps}
          modal={loading || calculatingVoyageUpdate}
          loading={loading || calculatingVoyageUpdate}
          textRequired={true}
          error={{ message: errorMessage, errorCode: error?.errorCode }}
          onClose={() => {
            setTextConfirm(false);
            resetTransaction();
          }}
          open={textConfirm}
        />
      }
    </>
  );
};

export default VoyageConfirmDialog;