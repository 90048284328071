import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Account, Alert } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';

/**
 *
 *  AgentListItem
 *  Displays an agent as a material list item.
 *  Primary text is always the agent name.
 *  The ...otherProps spread is required for the
 *  component to work in the Editable Agent 
 *  and Automcomplete components
 *
 */

const AgentListItem = ({
  agent,
  button,
  disableGutters,
  selected,
  display,
  icon,
  label,
  ...otherProps
}) => {
  const { t } = useTranslation();
  if (!agent) return null;
  return (
    <ListItem
      button={button}
      component="div"
      disableGutters={disableGutters}
      selected={selected}
      {...otherProps}
    >
      {icon &&
      <ListItemIcon>
        <Account />
      </ListItemIcon>
      }
      <ListItemText
        primary={display && agent.displayName ? agent.displayName : agent.name}
        primaryTypographyProps={{
          component: 'p',
          noWrap: true,
          variant: 'body1'
        }}
        secondary={label}
      />
      {Boolean(agent?.deleted) &&
        <>
          <Box mr="0.5rem" />
          <Tooltip title={t("Contact.Labels.DeletedHelp")}>
            <ListItemIcon>
              <Alert fontSize='small' />
            </ListItemIcon>
          </Tooltip>
        </>
      }
    </ListItem>
  );
};

AgentListItem.defaultProps = {
  button: false,
  disableGutters: true,
  selected: false,
  display: false
};

AgentListItem.propTypes = {
  // whether to render item as a button
  button: PropTypes.bool,
  // whether to diable gutters
  diableGutters: PropTypes.bool,
  // passed to ListItem
  selected: PropTypes.bool
}

export default AgentListItem;
