import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AppHeader from '../containers/AppHeader';
import NavBarVertical from '../components/NavBarVertical';
import BerthStatusList from '../containers/BerthStatusList';
import MovementActionList from '../containers/MovementActionList';
import AgentPortCallDetails from '../components/PortCall/AgentPortal/AgentPortCallDetails';
import AgentActionDetails from '../components/Action/AgentPortal/AgentActionDetails';
import PortMap from '../components/Map/PortMap';
import { DialogContextProvider } from '../contexts/dialog';
import { NavigationContextProvider } from '../contexts/navigation';
import { ImageFileContextProvider } from '../contexts/image';
import { UIContext } from '../contexts/ui';
import RequestPortCall from '../components/QuickPortCall/RequestPortCall';
import PortCallBrowser from '../components/PortCall/PortCallBrowser';
import VesselList from '../components/Vessel/VesselList';
import VesselEditPage from '../components/Vessel/VesselEditPage';
import useActiveActions from '../hooks/useActiveActions';
import usePortCallDetails from '../hooks/usePortCallDetails';
import SyncingSnackbar from '../components/Snackbar/SyncingSnackbar';
import { MarlinAuthProvider } from '../contexts/marlinAuthContext';
import { mapConfig } from '../environment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RouteConstants from '../constants/RouteConstants'
import useFeatureSetting from '../hooks/useFeatureSetting';
import PortCallList from '../components/PortCall/PortCallList';

const AgentPortal = () => {
  const activeActions = useActiveActions();
  const portCallDetails = usePortCallDetails();
  const [uiContext,] = useContext(UIContext);
  const userContactId = uiContext.userContactId;
  const history = useHistory();
  const isPortCallBrowserEnabled = useFeatureSetting('portcall-browser');
  // Expose react-router to the testing framework
  useEffect(() => {
    window.__E2E__.history = history;
  }, [history]);
  return (
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
      >
        <SnackbarProvider 
          maxSnack={3} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
          preventDuplicate={true}
          autoHideDuration={10000}
        >
          <DialogContextProvider>
            <Route path="/">
              <AppHeader disableCurrentConditions />
            </Route>

            <Box
              display="flex"
              height="100%"
            >
              <NavigationContextProvider>
              <ImageFileContextProvider>
                <MarlinAuthProvider wmsList={mapConfig.wms}>
                <Route path="/">
                  <NavBarVertical />
                </Route>
                <Route exact path={['/', '/lists/berths']}>
                  <BerthStatusList activeActions={activeActions} />
                </Route>
                <Route exact path={['/', '/lists/movements']}>
                  <MovementActionList activeActions={activeActions} />
                </Route>
                <Route exact path={RouteConstants.PORTCALL_LIST}>
                  {/* PortCallBrowser show list of archived and unarchived portcalls, PortCallList only show list of unarchived portcalls*/}
                    {isPortCallBrowserEnabled ? <PortCallBrowser/> : <PortCallList/>}
                </Route>
                <Route exact path={RouteConstants.VESSEL_LIST}>
                  <VesselList />
                </Route>
                <Route 
                  exact 
                  path="/port-call/:id/(requests)?/:requestId?" 
                  render={({ match: { params } }) => <AgentPortCallDetails {...params} portCallDetails={portCallDetails} userContactId={userContactId} /> }
                />
                <Route 
                  exact 
                  path="/port-call/:portCallId/action/:actionId/(requests)?/:requestId?" 
                  render={({ match: { params } }) => <AgentActionDetails {...params} portCallDetails={portCallDetails} userContactId={userContactId} /> }
                />

                <Route exact path="/request">
                  <RequestPortCall />
                </Route>

                <Route exact path="/vessels/:vesselId/">
                  <VesselEditPage />
                </Route>

                <Route exact path="/map">
                  <PortMap />
                </Route>
                </MarlinAuthProvider>
              </ImageFileContextProvider>
              </NavigationContextProvider>

            </Box>
          </DialogContextProvider>
        <SyncingSnackbar />
      </SnackbarProvider>
    </Box>
  );
};

export default props => <BrowserRouter><AgentPortal {...props} /></BrowserRouter>;
