import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Typography, TextField, FormGroup, FormControlLabel, Switch, ListItem, ListItemText, Box, FormControl, Checkbox, Tooltip, RadioGroup, Radio, MenuItem } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { useTheme } from '@material-ui/styles';
import { CategoryType, NotificationRule, NotificationTriggerType, NotificationType, ActionMovementType, TimeUnits, PortCallTag, MatchingType } from '../../models';
import { Check, Alert } from 'mdi-material-ui';
import isEqual from 'lodash.isequal';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import MultiContactAutocomplete from '../Contact/MultiContactAutocomplete';
import LocationCheckBoxTree from '../Location/LocationCheckBoxTree';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useExternalNotificationSetting from '../../hooks/useExternalNotificationSettings';
import useParameterStore from '../../hooks/useParameterStore';
import { ActionTypeIds } from '../../environment';
import { useTranslation } from 'react-i18next';
import { DataStore } from 'aws-amplify';
import TimePeriodSelect from '../TimePeriodSelect';
import CategoryAutocomplete from '../Category/CategoryAutocomplete';
import TagAutocomplete from '../Tag/TagAutocomplete';
import { sortLocations } from '../../utils/sorters';
import { makeStyles } from '@material-ui/styles';

const CustomActionType = 'CUSTOM';

const AdminNotificationHeaderContent = ( {tabIndex} ) => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('AdminNotification.Labels.Name')} flexBasis="50%" />
      <AdminModelEditorHeaderColumn label={t('AdminNotification.Labels.Description')} flexBasis="50%" />
      <AdminModelEditorHeaderColumn label={t('AdminNotification.Labels.Enabled')} flexBasis="15%" />
      <AdminModelEditorHeaderColumn label={t('AdminNotification.Labels.Status')} flexBasis="15%" />
    </>
  );
};

const AdminNotificationListItemContent = ({ value, tabIndex }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Typography noWrap style={{ flexBasis: "50%" }}>{value.name}</Typography>
      <Typography noWrap style={{ flexBasis: "50%" }}>{value.description}</Typography>
      <div style={{ flexBasis: "15%" }}>{value.enabled ? <Check style={{ opacity: .25 }}/> : null }</div>
      <div style={{ flexBasis: "15%" }}>
        { !value.recipients.length || !value.actionTypeId ? 
          <Tooltip title={t('AdminNotification.Error.MissingValues')} placement="top">
            <Alert className='AdminNotificationStatusWarning' style={{ color: theme.palette.warning.main }}/>
          </Tooltip>
          : null }
      </div>
    </>
  )
}

const AdminNotificationCustomHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { appExternalNotificationEnabled, updateExternalNotificationSetting } = useExternalNotificationSetting();
  const { isNotificationsConfigComplete } = useParameterStore();
  const [ warningMessage, setWarningMessage ] = useState('')

  const handleNotificationsEnabled = useCallback((event) => {
    updateExternalNotificationSetting(event?.target?.checked ?? false);
  }, [updateExternalNotificationSetting])

  useEffect(() => {
    if(appExternalNotificationEnabled) {
      if(isNotificationsConfigComplete === true) {
        setWarningMessage('')
      } else if(isNotificationsConfigComplete === false) {
        setWarningMessage(t('AdminNotification.Labels.IncompleteWarningMsg'))
      }
    } else {
      setWarningMessage('')
    }
  }, [appExternalNotificationEnabled, isNotificationsConfigComplete, t])

  return (
    <>
      <FormGroup component="div" style={{flex: "none"}}>
        <FormControlLabel
          control={
            <Switch
              id="EnabledNotificationsButton"
              checked={appExternalNotificationEnabled}
              onChange={handleNotificationsEnabled}
            />
          }
          label={t('AdminNotification.Labels.EnableNotifications')}
        />
      </FormGroup>
      <Box style={{ marginTop: '1rem'}}>
        {warningMessage && <Typography style={{color: theme?.palette?.warning?.main}}>{warningMessage}</Typography>}
      </Box>
    </>
  );
};

const EventType = {
  CREATED: 'AdminNotification.Labels.EventCreated',
  STARTED: 'AdminNotification.Labels.EventStarted',
  COMPLETED: 'AdminNotification.Labels.EventCompleted',
  PENDING_APPROVAL: 'AdminNotification.Labels.EventPendingApproval',
  APPROVED: 'AdminNotification.Labels.EventApproved',
  DECLINED: 'AdminNotification.Labels.EventDeclined',
  REQUESTED: 'AdminNotification.Labels.EventRequested',
  CANCELLED: 'AdminNotification.Labels.EventCancelled' 
};

const notificationTypeMapping = [
  { type: NotificationTriggerType.ARRIVAL_CREATED, source: ActionMovementType.ARRIVAL, event: EventType.CREATED },
  { type: NotificationTriggerType.ARRIVAL_STARTED, source: ActionMovementType.ARRIVAL, event: EventType.STARTED },
  { type: NotificationTriggerType.ARRIVAL_COMPLETED, source: ActionMovementType.ARRIVAL, event: EventType.COMPLETED },
  { type: NotificationTriggerType.ARRIVAL_PENDING_APPROVAL, source: ActionMovementType.ARRIVAL, event: EventType.PENDING_APPROVAL },
  { type: NotificationTriggerType.ARRIVAL_APPROVED, source: ActionMovementType.ARRIVAL, event: EventType.APPROVED },
  { type: NotificationTriggerType.ARRIVAL_DECLINED, source: ActionMovementType.ARRIVAL, event: EventType.DECLINED },
  { type: NotificationTriggerType.ARRIVAL_REQUESTED, source: ActionMovementType.ARRIVAL, event: EventType.REQUESTED },
  { type: NotificationTriggerType.ARRIVAL_AFTER, source: ActionMovementType.ARRIVAL, event: null },
  { type: NotificationTriggerType.ARRIVAL_BEFORE, source: ActionMovementType.ARRIVAL, event: null },

  { type: NotificationTriggerType.DEPARTURE_CREATED, source: ActionMovementType.DEPARTURE, event: EventType.CREATED },
  { type: NotificationTriggerType.DEPARTURE_STARTED, source: ActionMovementType.DEPARTURE, event: EventType.STARTED },
  { type: NotificationTriggerType.DEPARTURE_COMPLETED, source: ActionMovementType.DEPARTURE, event: EventType.COMPLETED },
  { type: NotificationTriggerType.DEPARTURE_PENDING_APPROVAL, source: ActionMovementType.DEPARTURE, event: EventType.PENDING_APPROVAL },
  { type: NotificationTriggerType.DEPARTURE_APPROVED, source: ActionMovementType.DEPARTURE, event: EventType.APPROVED },
  { type: NotificationTriggerType.DEPARTURE_DECLINED, source: ActionMovementType.DEPARTURE, event: EventType.DECLINED },
  { type: NotificationTriggerType.DEPARTURE_CANCELLED, source: ActionMovementType.DEPARTURE, event: EventType.CANCELLED },
  { type: NotificationTriggerType.DEPARTURE_REQUESTED, source: ActionMovementType.DEPARTURE, event: EventType.REQUESTED },
  { type: NotificationTriggerType.DEPARTURE_AFTER, source: ActionMovementType.DEPARTURE, event: null },
  { type: NotificationTriggerType.DEPARTURE_BEFORE, source: ActionMovementType.DEPARTURE, event: null },

  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_CREATED, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.CREATED },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_STARTED, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.STARTED },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_COMPLETED, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.COMPLETED },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_PENDING_APPROVAL, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.PENDING_APPROVAL },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_APPROVED, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.APPROVED },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_DECLINED, source: ActionMovementType.SHIFT_ARRIVAL, event: EventType.DECLINED },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_AFTER, source: ActionMovementType.SHIFT_ARRIVAL, event: null },
  { type: NotificationTriggerType.BERTH_SHIFT_ARRIVAL_BEFORE, source: ActionMovementType.SHIFT_ARRIVAL, event: null },

  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_CREATED, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.CREATED },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_STARTED, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.STARTED },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_COMPLETED, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.COMPLETED },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_PENDING_APPROVAL, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.PENDING_APPROVAL },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_APPROVED, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.APPROVED },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_DECLINED, source: ActionMovementType.SHIFT_DEPARTURE, event: EventType.DECLINED },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_AFTER, source: ActionMovementType.SHIFT_DEPARTURE, event: null },
  { type: NotificationTriggerType.BERTH_SHIFT_DEPARTURE_BEFORE, source: ActionMovementType.SHIFT_DEPARTURE, event: null },

  { type: NotificationTriggerType.CUSTOM_ACTION_CREATED, source: CustomActionType, event: EventType.CREATED },
  { type: NotificationTriggerType.CUSTOM_ACTION_STARTED, source: CustomActionType, event: EventType.STARTED },
  { type: NotificationTriggerType.CUSTOM_ACTION_COMPLETED, source: CustomActionType, event: EventType.COMPLETED },
  { type: NotificationTriggerType.CUSTOM_ACTION_CANCELLED, source: CustomActionType, event: EventType.CANCELLED },
  { type: NotificationTriggerType.CUSTOM_ACTION_AFTER, source: CustomActionType, event: null },
  { type: NotificationTriggerType.CUSTOM_ACTION_BEFORE, source: CustomActionType, event: null },

];

const defaultSourceOptions = [
  { tname: 'AdminNotification.Labels.MovementArrival', actionTypeId: ActionTypeIds.MOVEMENT, source: ActionMovementType.ARRIVAL },
  { tname: 'AdminNotification.Labels.MovementDeparture', actionTypeId: ActionTypeIds.MOVEMENT, source: ActionMovementType.DEPARTURE },
  { tname: 'AdminNotification.Labels.MovementShiftArrival', actionTypeId: ActionTypeIds.MOVEMENT, source: ActionMovementType.SHIFT_ARRIVAL },
  { tname: 'AdminNotification.Labels.MovementShiftDeparture', actionTypeId: ActionTypeIds.MOVEMENT, source: ActionMovementType.SHIFT_DEPARTURE },
];

const TabActionState = 0;
const TabReminders = 1;

const ReminderTypeBefore = 'before';
const ReminderTypeAfter = 'after';

const reminderTypeFromTriggerType = (triggerType) => {
  switch(triggerType) {
    case NotificationTriggerType.ARRIVAL_BEFORE:
    case NotificationTriggerType.DEPARTURE_BEFORE:
    case NotificationTriggerType.BERTH_SHIFT_DEPARTURE_BEFORE:
    case NotificationTriggerType.BERTH_SHIFT_ARRIVAL_BEFORE:
    case NotificationTriggerType.CUSTOM_ACTION_BEFORE:
      return ReminderTypeBefore;
    case NotificationTriggerType.ARRIVAL_AFTER:
    case NotificationTriggerType.DEPARTURE_AFTER:
    case NotificationTriggerType.BERTH_SHIFT_DEPARTURE_AFTER:
    case NotificationTriggerType.BERTH_SHIFT_ARRIVAL_AFTER:   
    case NotificationTriggerType.CUSTOM_ACTION_AFTER:
      return ReminderTypeAfter;
    default:
      return ReminderTypeBefore;
  }
};

const triggerTypeFromSourceReminder = (source, reminderType) => {
  if(!source || !reminderType) return undefined;
  if(source === ActionMovementType.ARRIVAL) {
    if(reminderType === ReminderTypeAfter) return NotificationTriggerType.ARRIVAL_AFTER;
    if(reminderType === ReminderTypeBefore) return NotificationTriggerType.ARRIVAL_BEFORE;
  }
  else if(source === ActionMovementType.DEPARTURE) {
    if(reminderType === ReminderTypeAfter) return NotificationTriggerType.DEPARTURE_AFTER;
    if(reminderType === ReminderTypeBefore) return NotificationTriggerType.DEPARTURE_BEFORE;
  }
  else if(source === ActionMovementType.SHIFT_ARRIVAL) {
    if(reminderType === ReminderTypeAfter) return NotificationTriggerType.BERTH_SHIFT_ARRIVAL_AFTER;
    if(reminderType === ReminderTypeBefore) return NotificationTriggerType.BERTH_SHIFT_ARRIVAL_BEFORE;
  }
  else if(source === ActionMovementType.SHIFT_DEPARTURE) {
    if(reminderType === ReminderTypeAfter) return NotificationTriggerType.BERTH_SHIFT_DEPARTURE_AFTER;
    if(reminderType === ReminderTypeBefore) return NotificationTriggerType.BERTH_SHIFT_DEPARTURE_BEFORE;
  }
  else {
    if(reminderType === ReminderTypeAfter) return NotificationTriggerType.CUSTOM_ACTION_AFTER;
    if(reminderType === ReminderTypeBefore) return NotificationTriggerType.CUSTOM_ACTION_BEFORE;
  }
};

const mapLocations = (locations) => locations
  .sort(sortLocations)
  .map(l => {
    return {
      id: l.id,
      name: l.name,
      parent: l.parent ? l.parent.id : null
    };
  });
  
const AdminNotificationFormContent = ({ outerValue, value, disabled, onChange, onChangeCheckbox, onChangeRaw, tabIndex }) => {
  const [ contactList, setContactList ] = useState([]);
  const { actionTypes, contacts, locations, categories } = useContext(DataStoreContext);
  const { t } = useTranslation();

  const buildActionOptions = useCallback((at) => {
    return actionTypes
    .filter(at => at.id !== ActionTypeIds.MOVEMENT && at.id !== ActionTypeIds.EVENT)
    .filter(at => !at.deleted)
    .map(at => { return { name: at.name, actionTypeId: at.id, source: CustomActionType} } )
    .concat(defaultSourceOptions.map(at => { return { ...at, name: t(at.tname) } } ))
    .sort((a, b) => a.name.localeCompare(b.name));
  }, [actionTypes, t]);

  const findCategory = useCallback((id) => {
    return categories.find(el => el.id === id);
  }, [categories]);

  const [ sourceOptions, setSourceOptions ] = useState(buildActionOptions(actionTypes));
  const [ eventOptions, setEventOptions ] = useState([]);
  const [ source, setSource ] = useState(null);
  const [ event, setEvent ] = useState(null); 
  const [ locationFilter, setLocationFilter ] = useState(value.locations);
  const [ actionsWithoutLocation, setActionsWithoutLocation ] = useState(value.actionsWithoutLocation);
  const [ reminderType, setReminderType ] = useState(reminderTypeFromTriggerType(value.triggers[0]))
  const [ reminderTimeOffset, setReminderTimeOffset ] = useState({ offset: value.offset, offsetUnit: value.offsetUnit })
  const [ category, setCategory ] = useState(value.categories ? findCategory(value.categories[0]) : null);
  const [tags, setTags] = useState(value?.tags ? value?.tags?.filter(t => t) : []);
  const [tagsMatchingType, setTagsMatchingType] = useState(value?.tagsMatchingType ?? MatchingType.ALL);

  useEffect(() => {
    const lastEvent = outerValue.triggers.length ? notificationTypeMapping.find(n => n.type === outerValue.triggers[0]) : null;
    const lastSource = lastEvent ? sourceOptions.find(a => a.actionTypeId === outerValue.actionTypeId && a.source === lastEvent.source) : null; 

    setSource(lastSource);
    setEvent(lastEvent);
    setLocationFilter(outerValue.locations.length ? outerValue.locations : []);
    setActionsWithoutLocation(outerValue.actionsWithoutLocation);
    setReminderType(reminderTypeFromTriggerType(lastEvent?.type));
    setReminderTimeOffset({ offset: outerValue.offset, offsetUnit: outerValue.offsetUnit });
    setCategory(outerValue?.categories?.length ? findCategory(outerValue.categories[0]) : null);
    setTags(outerValue?.tags ? outerValue?.tags?.filter(t => t) : []);
    setTagsMatchingType(outerValue?.tagsMatchingType ?? MatchingType.ALL);
  }, [outerValue, sourceOptions, findCategory]);

  useEffect(() => {
    setSourceOptions(buildActionOptions(actionTypes));
  }, [actionTypes, buildActionOptions]);

  useEffect(() => {
    source && setEventOptions(notificationTypeMapping.filter(nt => nt.source === source.source))
  }, [source]);

  const onSourceChange = (newValue) => {
    setSource(newValue);
    const eventType = tabIndex === TabReminders ? triggerTypeFromSourceReminder(newValue?.source, reminderType) : null;
    setEvent(eventType);

    // Note, used onChangeRaw so that multiple fields can be updated at the same time.
    onChangeRaw(NotificationRule.copyOf(value, updated => {
      updated.actionTypeId = newValue ? newValue.actionTypeId : null;
      updated.triggers = eventType ? [ eventType ] : [] 
    }));
  };

  const onEventChange = (value) => {
    setEvent(value);
    onChange({target: { name: 'triggers', value: value ? [value.type] : [] } } );
  }

  const onLocationsChange = (value) => {
    value && onChange({ target: { name: 'locations', value: value } });
  }

  const onActionsWithoutLocationToggled = (event) => {
    setActionsWithoutLocation(event.target.checked);
    onChange({ target: { name: 'actionsWithoutLocation', value: event.target.checked } });
  };

  const onReminderTimeChanged = (offsetMins, offsetUnit) => {
    setReminderTimeOffset({ offset: offsetMins, offsetUnit: offsetUnit });
    onChangeRaw(NotificationRule.copyOf(value, updated => {
      updated.offset = parseInt(offsetMins);
      updated.offsetUnit = offsetUnit
    }));
  };

  const onReminderOffsetTypeChanged = (event) => {
    setReminderType(event.target.value);
    const eventType = triggerTypeFromSourceReminder(source?.source, event.target.value); 
    setEvent(eventType);
    onChange({ target: { name: 'triggers', value: eventType ? [eventType] : [] } });
  };

  const onCategoryChanged = (_category) => {
    setCategory(_category);
    onChange({ target: { name: 'categories', value: _category?.id ? [_category.id] : [] } });
  };

  const onTagMatchingTypeChanged = (event) => {
    setTagsMatchingType(event?.target?.value);
    onChange({ target: { name: 'tagsMatchingType', value: event?.target?.value ?? MatchingType.ALL } });
  }

  const onTagsChanged = (newTags) => {
    const selectedTags = newTags ? newTags.filter(t => t) : [];
    setTags(selectedTags);
    onChange({ target: { name: 'tags', value: selectedTags } });
  } 

  useEffect(() => {
    // Load recipents
    contacts.length && 
      setContactList(outerValue.recipients ? outerValue.recipients.map(id => contacts.find(c => c.id === id)).filter(c => c) : [])
  }, [outerValue, contacts, setContactList])

  useEffect(() => {
    // Save recipents
    !isEqual(contactList.map(c => c.id), value.recipients) && onChange({ target: { name: 'recipients', value: contactList.length ? contactList.map(c => c.id) : [] } } )
  }, [contactList, onChange, value])

  const useStyles = makeStyles(theme => ({
    optionTagWrapper: {
      '& .MuiChip-label': {
        paddingLeft: '0.50rem',
        paddingRight: '0.50rem'
      },
      '& .MuiListItemIcon-root': {
        marginRight: '0.50rem',
        '& .MuiSvgIcon-root': {
          color: '#000'
        }
      }
    }
  }));

  const classes = useStyles();

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch id='EnableNotificationSwtich' name="enabled" checked={value.enabled} onChange={onChangeCheckbox}/>}
          label={tabIndex === TabActionState ?
            t('AdminNotification.Labels.EnableNotification') :
            t('AdminNotification.Labels.EnableReminder')}
        />
      </FormGroup>
      <Typography variant="h6">{t('AdminNotification.Labels.Name')}</Typography>
      <TextField
        id='NotificationNameInput'
        required 
        disabled={disabled} 
        value={value.name || ''} 
        name="name" 
        label={t('AdminNotification.Labels.Name')} 
        fullWidth 
        variant="outlined" 
        onChange={onChange} 
        margin="normal"
      />
      <TextField
        id='NotificationDescriptionInput'
        disabled={disabled}
        value={value.description || ''} 
        name="description" 
        label={t('AdminNotification.Labels.Description')} 
        fullWidth 
        variant="outlined" 
        onChange={onChange} 
        margin="normal" 
        multiline 
        minRows={2}
      />

      <Typography variant="h6">{t('AdminNotification.Labels.Trigger')}</Typography>
      <Autocomplete
        id='NotificationSourceInput'
        required
        autoHighlight
        options={sourceOptions}
        getOptionSelected={(option, value) => 
            option.actionTypeId === value.actionTypeId &&
            option.source === value.source
        }
        getOptionLabel={(item) => item && item.name}
        value={source}
        onChange={(e, value) => onSourceChange(value)}
        renderOption={item => (
          <ListItem dense component="div">
            <ListItemText primary={item.name} />
          </ListItem>
        )}
        renderInput={props => (
          <TextField
            {...props}
            required={true}
            variant="outlined"
            label={t('AdminNotification.Labels.SelectAction')}
            fullWidth
            margin="normal"
            error={!source}
            helperText={ !source ? t('AdminNotification.Error.ActionTypeNotSpecified') : null }
          />
        )}
      />
      { tabIndex === TabActionState && 
        <Autocomplete
          id='NotificationEventInput'
          required
          options={eventOptions}
          getOptionLabel={(item) => item && t(item.event)}
          value={event}
          onChange={(e, value) => onEventChange(value)}
          renderOption={item => (
            <ListItem dense component="div">
              <ListItemText primary={t(item.event)} />
            </ListItem>
          )}
          renderInput={props => (
            <TextField
              {...props}
              required={true}
              variant="outlined"
              label={t('AdminNotification.Labels.SelectEvent')}
              fullWidth
              margin="normal"
              error={!event}
              helperText={ !event ? t('AdminNotification.Error.EventTypeNotSpecified') : null }
            />
          )}
        />
      }
      { tabIndex === TabReminders &&
        <>
          <Typography variant="h6">{t('AdminNotification.Labels.ReminderTime')}</Typography>
          <FormControl component='fieldset' flex fullWidth>
            <TextField
              select 
              id="ReminderSelect"
              defaultValue={ReminderTypeBefore}
              value={reminderType}
              onChange={onReminderOffsetTypeChanged}
              variant="outlined"
              margin="normal" 
              fullWidth
            >
              <MenuItem
                id='ReminderTimeSelect-Before'
                value={ReminderTypeBefore}
                className={classes.optionTagWrapper}
              >
                {t('AdminNotification.Labels.ReminderBeforePlannedTime')}
              </MenuItem>
              <MenuItem
                id='ReminderTimeSelect-After'
                value={ReminderTypeAfter}
              >
                {t('AdminNotification.Labels.ReminderAfterPlannedTime')}
              </MenuItem>
            </TextField>
          </FormControl>
          <TimePeriodSelect
            id='ReminderPeriodSelectInput'
            required
            value={reminderTimeOffset.offset}
            valueUnit={reminderTimeOffset.offsetUnit}
            onChanged={onReminderTimeChanged}
          />
        </>
      }
      <Typography variant="h6">{t('AdminNotification.Labels.Recipents')}</Typography>
      <MultiContactAutocomplete
        id='NotificationRecipentsInput'
        filterSelectedOptions
        label={t('AdminNotification.Labels.Recipents')}
        value={contactList}
        onChange={(cl) => setContactList(cl)}
        errorOnEmpty={true}
        required={true}
      />
      <Typography variant="h6">{t('AdminNotification.Labels.PortCallCategory')}</Typography>
      <CategoryAutocomplete
        id="NotificationsCategoryInput"
        label={t('AdminNotification.Labels.Category')}
        type={CategoryType.PORTCALL}
        value={category}
        onChange={onCategoryChanged}
      />
      <Typography variant="h6">{t('AdminNotification.Labels.PortCallTags')}</Typography>
      <FormControl component='fieldset' flex row>
        <RadioGroup name='tag-matching-type' value={tagsMatchingType} onChange={onTagMatchingTypeChanged} row>
          <FormControlLabel id='TagMatchingType-All' value={MatchingType.ALL} control={<Radio />} label={t('AdminNotification.Labels.All')} />
          <FormControlLabel id='TagMatchingType-Any' value={MatchingType.ANY} control={<Radio />} label={t('AdminNotification.Labels.Any')} />
        </RadioGroup>
      </FormControl>
      <TagAutocomplete
        id="NotificationsTagsInput"
        label={t('AdminNotification.Labels.Tags')}
        model={PortCallTag}
        values={tags}
        onChange={onTagsChanged}
      />
      <Typography variant="h6">{t('AdminNotification.Labels.Location')}</Typography>
      <Box>
        <FormControl>
          <FormControlLabel control={
            <Checkbox
              id='NotificationWithoutLocationCheckbox'
              name="actionsWithoutLocation"
              checked={actionsWithoutLocation}
              onChange={onActionsWithoutLocationToggled}
            />
          } label={t('AdminNotification.Labels.ActionWithoutLocation')} />
        </FormControl>
        <LocationCheckBoxTree
          id="NotificationLocationTree"
          locations={locations}
          checked={locationFilter}
          onChange={(l) => onLocationsChange(l)}
          mapLocations={mapLocations}
        />
      </Box>
    </>
  );
};

const AdminAutomation = () => {
  const { locations } = useContext(DataStoreContext);
  const { t } = useTranslation();
  const notification = t('AdminNotification.Labels.Notification');
  const reminder = t('AdminNotification.Labels.Reminder');

  return (
    <AdminModelEditor
      HeaderContent={AdminNotificationHeaderContent}
      ListItemContent={AdminNotificationListItemContent}
      FormContent={AdminNotificationFormContent}
      CustomHeader={AdminNotificationCustomHeader}
      model={NotificationRule}
      condition={c => c.deleted("ne", true)}
      filter={i => !i.deleted}
      sort={(a, b) => (a.name > b.name) ? 1 : -1}
      canUpdate={() => true}
      canDelete={() => true}
      onDelete={async (i) => await DataStore.save(NotificationRule.copyOf(i, updated => { updated.deleted = true; }))}
      canSave={(i, tabIndex) => {
        const canSave = Boolean(i.name) && 
          Boolean(i.recipients.length) && 
          Boolean(i.triggers.length) && 
          Boolean(i.actionTypeId);
        if(tabIndex === TabReminders) return canSave && i.offset && (i.offset > 0);
        else return canSave;
      }}
      canAdd={() => true}
      customDeleteTooltip={(i, tabIndex) => tabIndex === TabActionState ? 
      t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: notification }) : 
      t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: reminder }) }
      tabs={[
        t('AdminNotification.Labels.NotificationsTab'), 
        t('AdminNotification.Labels.RemindersTab')
      ]}
      transform={(items, tabIndex) => {
        if(tabIndex === TabActionState) {
          return items.filter(el => !el.offset)
        } else if(tabIndex === TabReminders) {
          return items.filter(el => el.offset && el.offset > 0)
        }
      }}
      defaultItem={(tabIndex) => {
        return {
          enabled: true,
          type: NotificationType.EMAIL,
          recipients: [],
          categories: [],
          locations: mapLocations(locations).map(l => l.id),
          actionsWithoutLocation: true,
          actionTypeId: undefined,
          triggers: [],
          offset: tabIndex === TabReminders ? 1 : null,
          offsetUnit: tabIndex === TabReminders ? TimeUnits.HOUR : null
        }
      }}
    />
  )
};

export default AdminAutomation;