import React , {useEffect, useState, useContext, useCallback } from 'react';
import { MapContext } from '../contexts/map';

// OpenLayers
import VectorLayer from 'ol/layer/Vector';
import { Fill, Style, Stroke } from 'ol/style';
import LayerGroup from 'ol/layer/Group';
import CircleStyle from 'ol/style/Circle';

import AISReceiverSource from './AISReceiverSource';
import useAISStatus from '../hooks/useAISStatus';
import AISReceiverLabelLayer from './AISLabelLayer';
import { Attrs, LayerAttrs } from '../constants';

const AISReceiverLayer = ({ id, title, zIndex, onClick, visible, showLabels=false}) => {

  const [context] = useContext(MapContext);
  const [ added, setAdded ] = useState(false);

  const { receiversStatus, getColor} = useAISStatus();

  const getColorStyle = useCallback((color) => new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({color: color}),
      stroke: new Stroke({
        color: '#a2a3a2',
        width: 2
      })
    }),
  }), [])

  const [ source ] = useState(new AISReceiverSource());

  const [ layer ] = useState(new VectorLayer({
    source: source,
    style: (feature) => {
      return getColorStyle(feature.get(Attrs.COLOR))
    }
  }));

  const [ labels ] = useState(new AISReceiverLabelLayer({
    source: source,
    declutter: true
  }));

  const createLayerGroup = () => {
    const group = new LayerGroup({
      title: title,
      layers: [
        layer,
        labels
      ]
    });
    group.set(LayerAttrs.ID, id);
    group.set(LayerAttrs.I18N, 'AISReceivers');
    return group;
  }
  const [ layerGroup ] = useState(createLayerGroup);

  useEffect(() => {
    source.updateAISReceivers(receiversStatus, getColor);
  }, [receiversStatus, source, getColor])

  useEffect(() => {
    if(context?.map && !added) {
      layerGroup.getLayersArray().forEach((layer) => layer.setZIndex(zIndex));
      layerGroup.setZIndex(zIndex);
      context.map.addLayer(layerGroup);

      onClick && context.map.on('click', (event) => {
        const feature = context.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });

        for(let f of source.getFeatures()) {
          if(feature === f) {
            onClick(feature);
          }
        }
      });

      setAdded(true);
    }
  }, [context, layerGroup]);

  useEffect(() => {
    labels.setVisible(showLabels)
  }, [showLabels]);

  useEffect(() => {
    layerGroup.setVisible(visible)
  }, [visible]);

  return (
    <>
    </>
  )
};

export default AISReceiverLayer;