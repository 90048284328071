import React, { useEffect, useState } from 'react';

import { makeStyles, Divider, ListItem, ListItemText } from '@material-ui/core';

import { flagCodeFromMMSI, shipTypeDescription } from './utils/AisTypes';
import VesselListItem from '../Vessel/VesselListItem';
import VesselImage from '../Image/VesselImage';
import { DataStore } from 'aws-amplify';
import { Vessel } from '../../models';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0  1rem  0 1rem',
  },
  imagePlaceholder: {
    backgroundColor: 'grey',
    width: '100%',
    alignContent: 'center'
  },
  vesselType: {
    padding: '0.5rem  1rem  0.5rem 1rem',
  },
  item: {
    padding: 0
  },
}));

const VesselInfoPopup = ({meta}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ dbVessel, setDbVessel ] = useState(null);
  const [ fetchComplete, setFetchComplete ] = useState(false);

  useEffect(() => {
    const getVesselRec = async (mmsi) => (await DataStore.query(Vessel, c => c.mmsi("eq", mmsi))).pop();
    
    meta && getVesselRec(meta.mmsi).then((db) => {
      setDbVessel(db ? db : null);
      setFetchComplete(true);
    });

  }, [meta]);

  return (
    // !fetchComplete ? <div>Loading</div> :
    <>
      <VesselListItem
        className={classes.root} 
        vesselData={ {
          ...meta,
          imo: meta.imo !== '0' ? meta.imo : 'Unknown',
          flag: flagCodeFromMMSI(meta.mmsi),
        }}
        disableGutters={true}
      />
      {fetchComplete && !dbVessel &&
        <Divider/>
      }
      {fetchComplete && dbVessel && dbVessel.vesselData && dbVessel.vesselData.image &&
          <VesselImage 
            image={dbVessel.vesselData.image} 
            className={classes.imagePlaceholder}
            containerStyles={{
              width: '100%',
              minHeight: '6rem'
            }}
          />
      }
      {fetchComplete &&
          <ListItem 
            component="div">
            <ListItemText
              className={classes.item}
              primary={dbVessel ? dbVessel.vesselData.type : shipTypeDescription(meta.shipType)}
              secondary={t('Vessel.Labels.Type')}
              primaryTypographyProps={{
                noWrap: true
              }}
            />
          </ListItem>
        } 
    </>
  );
};

export default VesselInfoPopup;