import React, { useContext, useMemo, useState, useEffect } from 'react';
import { MenuItem, Divider, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { CloseCircleOutline, Check, DeleteOutline, InformationOutline, UndoVariant, MinusCircleOutline } from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import { ActionStateLabelKeys } from '../../../constants/ActionStateLabel';
import { NavigationContext } from '../../../contexts/navigation';
import { DatastoreStatusContext } from '../../../contexts/datastoreStatusContext';
import { UIContext } from '../../../contexts/ui';
import { DataStore } from 'aws-amplify';
import { getCompletedArrival, getActionArrival, getActionDeparture, getOutstandingTodos, isAgentAssociatedWithPortCall, getActiveMovements } from '../../../utils/getters';
import { generateTimePlannedChecks, CHECKS } from '../../../utils/generators';
import { Action, ActionState, Cargo, Request, RequestType, RequestState, PortCallStatus, PortCall } from '../../../models';
import { getAgentContact, buildRequestSource, getUserEmail, getUserName } from '../../../utils/agentPortal';
import { cancelPortCall, closePortCall, hasVoyageMovements } from '../utils';
import { useTranslation } from 'react-i18next';
import SpotlightMenuOption from '../../Spotlight/SpotlightMenuOption';
import InvoiceMenuOption from '../../Invoice/InvoiceMenuOption';
import useAuditMeta from '../../../hooks/useAuditMeta';
import { rejectRequests } from '../../../utils/requests';
import RouteConstants from '../../../constants/RouteConstants';
import { buildPortCallMenuItems } from './buildMenuItems';
import { isVoyageLastArrival } from '../../../utils/voyages';

const PortCallMenu = ({
  archived,
  portCall,
  actions,
  shiftActions,
  skipCheck,
  anchorEl,
  contexts,
  handleUndoAction,
  nextUndoableActions,
  undoableActionName,
  handleClose,
  setOpenText,
  setConfirmProps,
  setTimeProps,
  setOpenCompletePortCallTimes,
  setOpenConfirm,
  setOpenUnArchive,
  updatePortCallActionsAsTransaction,
  setMapDialogOpen,
  setInvoiceDialogOpen,
  classes,
  unarchivePortCall,
  disabledCancel,
  disabledDelete
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [navigationContext,] = useContext(NavigationContext);
  const [uiContext,] = useContext(UIContext);
  const [isVoyageLastArrivalPortCall, setIsVoyageLastArrivalPortCall] = useState(false);

  const auditMeta = useAuditMeta();
  const readOnly = uiContext.readOnly;
  const agentPortal = uiContext.agentPortal;
  const userContactId = uiContext.userContactId;

  const isVoyageAdmin = uiContext.isVoyageAdmin;
  const isSynced = useContext(DatastoreStatusContext);
  const agentPortCall = !agentPortal || (agentPortal && isAgentAssociatedWithPortCall(portCall, userContactId));

  // actionDisable include readOnly from permssions and sync status from datastore
  const actionDisable = readOnly || !isSynced;

  const isPortCallArchived = useMemo(() => (portCall && portCall.archived) || archived, [archived, portCall])

  const completedArrival = getCompletedArrival(portCall?.actions);

  const menu = useMemo(() => {
    return buildPortCallMenuItems(
      portCall,
      contexts,
      agentPortal,
      skipCheck,
      anchorEl,
      nextUndoableActions,
      isPortCallArchived,
      disabledCancel,
      disabledDelete,
      completedArrival
    );
  }, [anchorEl, contexts, agentPortal, isPortCallArchived, nextUndoableActions, portCall, skipCheck, disabledDelete, disabledCancel, completedArrival]);

  useEffect(() => {
    (async () => {
      if (!menu?.portCall?.canHandleClosePortCall) {
        setIsVoyageLastArrivalPortCall(false);
      } else {
        const checkVoyageLastArrival = await isVoyageLastArrival(completedArrival?.id, completedArrival?.actionVoyageId);
        setIsVoyageLastArrivalPortCall(checkVoyageLastArrival);
      }
    })();
  }, [menu, completedArrival]);

  const handlePortCallCancelRequest = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.CancelRequestDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.CancelRequestDialogMessage', { vesselName: portCall.vesselData.name }),
      inputLabel: t('PortCallTimelineMenu.Labels.Remarks'),
      onConfirm: async (remarks) => {
        const agent = await getAgentContact(userContactId);
        const requestSource = await buildRequestSource(agent);
        await DataStore.save(new Request({
          portCall: portCall,
          requestPortCallId_: portCall.id,
          state: RequestState.REQUEST_STATE_PENDING,
          type: RequestType.REQUEST_TYPE_CANCEL_PORTCALL,
          source: requestSource,
          portCallData: {
            vesselName: portCall.vesselData.name,
            vesselImo: portCall.vesselData.imo,
            vesselMmsi: portCall.vesselData.mmsi,
            vesselCallsign: portCall.vesselData.callSign
          },
          actionData: [],
          agent: agent,
          requesterUserName: await getUserName(),
          requesterEmail: await getUserEmail(),
          remark: remarks,
          auditMeta: { ...auditMeta, comment: remarks }
        }));
        setOpenText(false);
        // redirect when viewing port call
        if (history.location.pathname.startsWith('/port-call'))
          history.push(navigationContext.lastView);
      }
    });
    setOpenText(true);
  };

  const handlePortCallDelete = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.DeletePortCallDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.DeletePortCallDialogMessage', { vesselName: portCall.vesselData.name }),
      onConfirm: async () => {
        // TODO confirm this 
        const updatedPortCall = PortCall.copyOf(portCall, updated => {
          updated.status = PortCallStatus.DELETED
        });
        await DataStore.save(updatedPortCall);
        for (let action of actions) {
          const updatedAction = Action.copyOf(action, updated => {
            updated.state = ActionState.DELETED
          });
          await DataStore.save(updatedAction);
        }
        const cargos = await DataStore.query(Cargo, c => c.cargoPortCallId('eq', portCall.id));
        for (let cargo of cargos) {
          const updated = Cargo.copyOf(cargo, updated => {
            updated.deleted = true;
          });
          await DataStore.save(updated);
        }
        const requests = await DataStore.query(Request, r => r.requestPortCallId_('eq', portCall.id));
        for (let request of requests) {
          const updated = Request.copyOf(request, updated => {
            updated.state = RequestState.REQUEST_STATE_DELETED;
          });
          await DataStore.save(updated);
        }
        setOpenConfirm(false);
        // redirect when viewing port call
        if (history.location.pathname.startsWith('/port-call'))
          history.push(navigationContext.lastView);
      }
    });
    setOpenConfirm(true);
  };

  const handleCompletePortCall = () => {
    const arrival = getActionArrival(actions);
    const incompleteArrival = [ActionState.PLANNED, ActionState.IN_PROGRESS].includes(arrival?.state) && arrival;
    const departure = getActionDeparture(actions);
    const arrivalCompleted = arrival?.state === ActionState.COMPLETED;
    const anyOutstandingTodos = getOutstandingTodos(arrival) || getOutstandingTodos(departure);

    let inputLabels = {};
    if (incompleteArrival) {
      inputLabels['arrival'] = t('PortCallTimelineMenu.Labels.RecordArrivalDialogInput');
    };
    inputLabels['departure'] = t('PortCallTimelineMenu.Labels.DepartureDialogInput');

    handleClose()

    const checks = generateTimePlannedChecks(portCall.actions, departure, CHECKS.BEFORE);

    const timeProps = {
      title: t('PortCallTimelineMenu.Labels.CompletePortCall', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.CompletePortCallMessage', { todos: anyOutstandingTodos ? t('PortCallTimelineMenu.Labels.PortCallOutstandingTodos') : '' }),
      arrivalCompleted: arrivalCompleted,
      completingPortCall: true,
      inputLabels: inputLabels,
      initialDepartureValue: departure.timeActual ? departure.timeActual : departure.timePlanned,
      ...(incompleteArrival && { initialArrivalValue: incompleteArrival?.timePlanned }),
      departureTimeSet: departure.timeActual ? true : false,
      checks,
      onConfirm: async (arrivalValue, departureValue, arrivalComment, departureComment) => {
        await updatePortCallActionsAsTransaction({
          portCall: portCall,
          actions: [...incompleteArrival ? [incompleteArrival] : [], departure],
          auditMeta: auditMeta,
          values: {
            arrival: arrivalValue,
            departure: departureValue
          },
          comments: {
            arrival: arrivalComment,
            departure: departureComment,
          },
          onSuccess: () => {
            setOpenCompletePortCallTimes(false);
            rejectRequests(portCall.requests);            
            //complete port call will fallback on dashboard / voyageTimeline 
            let completePortCalllastView = navigationContext.defaultView;
            if(navigationContext.currentView?.match(RouteConstants.VOYAGE_DETAIL_URL_PATTERN)){
              completePortCalllastView = navigationContext.currentView;
            }
            history.push(completePortCalllastView);
          }
        });
      }
    };
    setTimeProps(timeProps);
    setOpenCompletePortCallTimes(true);
  };

  const handlePortCallCancel = () => {
    if (agentPortal) {
      return handlePortCallCancelRequest();
    }

    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.CancelPortCallDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.CancelPortCallDialogMessage', { vesselName: portCall.vesselData.name }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await cancelPortCall(portCall, comment, auditMeta);
        setOpenText(false);
        // redirect when viewing port call
        if (history.location.pathname.startsWith('/port-call'))
          history.push(navigationContext.lastView);
      }
    });
    setOpenText(true);
  };

  const handlePortCallCloseArrivalBookend = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.CloseArrivalPortCallDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.CloseArrivalPortCallDialogMessage', { vesselName: portCall.vesselData.name }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await closePortCall(portCall, comment, auditMeta);
        setOpenText(false);
        // redirect when viewing port call
        if (history.location.pathname.startsWith('/port-call'))
          history.push(navigationContext.lastView);
      }
    });
    setOpenText(true);
  };

  const handlePortCallDetails = async () => {
    if (isPortCallArchived) {
      setOpenUnArchive({ open: true, title: t('PortCallDetails.Labels.Loading'), loading: true, error: null })
      await unarchivePortCall({
        portCallId: portCall.id,
        title: t('PortCallDetails.Labels.Loading'),
        onSuccess: () => {
          portCall.id && history.push(`/port-call/${portCall.id}/`);
        },
        onError: () => setOpenUnArchive((prev) => ({
          ...(prev || []),
          open: true, loading: false, error: {
            message: t('PortCallDetails.Errors.Loading'),
            errorCode: 400
          }
        })),
        onAppSyncError: (message) => setOpenUnArchive((prev) => ({
          ...(prev || []),
          open: true, loading: false, error: {
            message: message,
            errorCode: 400
          }
        })),
      });
    } else {
      history.push(`/port-call/${portCall.id}/`);
    }
  };

  const handleOpenMapDialog = () => {
    handleClose();
    setMapDialogOpen(true);
  };

  // View Invoice
  const handleOpenInvoiceDialog = () => {
    handleClose();
    setInvoiceDialogOpen(true);
  };

  const actionArrival = getActionArrival(actions)
  const actionDeparture = getActionDeparture(actions)
  const canCancelOrDeletePortCall = !hasVoyageMovements(portCall.actions) || isVoyageAdmin;
  const uncompletableArrival = actionArrival && (!actionArrival?.movementLocation?.dockable)
  const uncompletableDeparture = actionDeparture && (!actionDeparture?.movementLocation?.dockable)

  return (
    menu.portCall ?
    <div id={`PortCallTimelinePortCallMenu`} data-id={contexts.includes("portCall") ? portCall.id : ''}>
      <ListSubheader>{t('PortCallMenuItem.Labels.PortCall')}</ListSubheader>
      {menu.portCall.canPortCallDetails &&
        <MenuItem
          disabled={!agentPortCall || !portCall?.id}
          onClick={handlePortCallDetails}
          id={'PortCallShowDetailsMenuItem'}
        >
          <ListItemIcon>
            <InformationOutline />
          </ListItemIcon>
          <ListItemText primary={t('PortCallMenuItem.Labels.ShowDetails')} />
        </MenuItem>
      }
      <SpotlightMenuOption handleOpenMapDialog={handleOpenMapDialog} />
      {menu.portCall.canUndoAction &&
        <MenuItem
          disabled={actionDisable}
          onClick={() => handleUndoAction(nextUndoableActions[0])}
          id={'PortCallUndoLastMenuItem'}
        >
          <ListItemIcon>
            <UndoVariant />
          </ListItemIcon>
          <ListItemText primary={t('PortCallMenuItem.Labels.UndoLast', { actionName: undoableActionName, actionState: t(ActionStateLabelKeys[nextUndoableActions[0].state]).toLowerCase() })} />
        </MenuItem>
      }
      {menu.portCall.canHandleCompletePortCall &&
        <MenuItem
          disabled={
            actionDisable ||
            getActiveMovements(shiftActions).length > 0 ||
            uncompletableArrival ||
            uncompletableDeparture ||
            !actionDeparture
          }
          onClick={handleCompletePortCall}
          id={'CompletePortCall'}
        >
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          <ListItemText primary={t('PortCallMenuItem.Labels.CompletePortCall')} />
        </MenuItem>
      }
      {isVoyageAdmin && isVoyageLastArrivalPortCall && <MenuItem
        disabled={actionDisable}
        onClick={handlePortCallCloseArrivalBookend}
        id={'PortCallCloseMenuItem'}
      >
        <ListItemIcon>
          <MinusCircleOutline/>
        </ListItemIcon>
        <ListItemText
          primary={t('PortCallMenuItem.Labels.ClosePortCall')}
        />
      </MenuItem>}
      {/* For the portCall details page, with url 
      e.g http://localhost:3000/port-call/98fc0d0b-1336-4f3b-837a-e4ca83b121fa/action/c6e003ff-320c-48a7-b6fa-c9496cf56a45,
      the contexts array is ['portCall'] */}
      {contexts.length === 1 && contexts.includes("portCall") &&
        <InvoiceMenuOption
          agentPortal={agentPortal}
          handleOpenInvoiceDialog={handleOpenInvoiceDialog}
        />
      }
      {(menu.portCall.canHandlePortCallCancel || menu.portCall.canHandlePortCallDelete) &&
        <div>
          {menu.portCall.canPortCallDetails &&
            <Divider className={classes.menuDivider} />
          }
          {menu.portCall.canHandlePortCallCancel &&
            <MenuItem
              disabled={!agentPortCall || actionDisable || !canCancelOrDeletePortCall}
              onClick={handlePortCallCancel}
              id={'PortCallCancelMenuItem'}
            >
              <ListItemIcon>
                <CloseCircleOutline />
              </ListItemIcon>
              <ListItemText
                primary={t('PortCallMenuItem.Labels.CancelPortCall')}
                secondary={!canCancelOrDeletePortCall && t('PortCallMenuItem.Labels.NoVoyageAdminPermissionToCancel')}
              />
            </MenuItem>
          }
          {menu.portCall.canHandlePortCallDelete &&
            <MenuItem
              disabled={actionDisable || !canCancelOrDeletePortCall}
              onClick={handlePortCallDelete}
              id={'PortCallDeleteMenuItem'}
            >
              <ListItemIcon>
                <DeleteOutline />
              </ListItemIcon>
              <ListItemText
                primary={t('PortCallMenuItem.Labels.DeletePortCall')}
                secondary={!canCancelOrDeletePortCall && t('PortCallMenuItem.Labels.NoVoyageAdminPermissionToDelete')}
              />
            </MenuItem>
          }
        </div>
      }
    </div>:<></>
  )
}
export default PortCallMenu;