import { FileType } from '../models';

export const FileTypeLabels = {
  [FileType.FAL5_CREW_LIST]: "FileTypes.Labels.Fal5CrewList"
};

export const PortCallDataEditLabels = {
  REPLACE_TITLE: (t, fileType) => t('PreArrivalUpload.Labels.ReplaceTitle', {fileType: t(FileTypeLabels[fileType])}),
  REPLACE_UPLOAD_MESSAGE: (t, fileType) => t('PreArrivalUpload.Labels.ReplaceUploadMessage', {fileType: t(FileTypeLabels[fileType])}),
  REPLACE_CREATEFORM_MESSAGE: (t, fileType) => t('PreArrivalUpload.Labels.ReplaceCreateFormMessage', {fileType: t(FileTypeLabels[fileType])}),
  DELETE_TITLE: (t, fileType) => t('PreArrivalUpload.Labels.DeleteTitle', {fileType: t(FileTypeLabels[fileType])}),
  DELETE_MESSAGE: (t, fileName) => t('PreArrivalUpload.Labels.DeleteMessage', {fileName: fileName}),
};