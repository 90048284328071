export const TRANSLATIONS_TEST = {
  Common: {
    Buttons: {
      Create: 'XXXXXXX',
      Update: 'XXXXXXX',
      Add: 'XXXXXXX',
      DiscardChanges: 'XXXXXXX',
      Confirm: 'XXXXXXX',
      Cancel: 'XXXXXXX',
      Close: 'XXXXXXX',
      Save: 'XXXXXXX',
      Request: 'XXXXXXX',
      Export: "XXXXXXX",
      Leave: "XXXXXXX",
      Now: "XXXXXXX"
    },
    Labels: {
      M: 'X',
      M3: 'X',
      Feet: 'X',
      T: 'X',
      KW: 'XX',
      HP: 'XX',
      KN: 'XX',
      NMI: 'XX',
      MB: 'XX',
      Each: '',
      C: 'X',
      F: 'X',
      Unspecified: 'X',
      UnsavedChanges: 'XXXXXXX',
      IncomingChanges: "XXXXXXX",
      Nominal: 'XXXXXXX',
      All: 'XXXXXXX',
      CustomAction: '{{actionName}} XXXXXXX',
      Comments: 'XXXXXXX',
      More: 'XXXXXXX',
      Hours: "XXXXXXX",
      Minutes: "XXXXXXX",
      Days: "Days"
    },
    Errors: {
      TimeCheckActionBefore: 'XXXXXXX',
      TimeCheckActionAfter: 'XXXXXXX',
      TimeCheckArrivalCheck: 'XXXXXXX',
      TimeCheckDepartureCheck: 'XXXXXXX',
      TimeCheckShiftBefore: 'XXXXXXX',
      TimeCheckShiftAfter: 'XXXXXXX',
      TimeCheckArrivalRequest: 'XXXXXXX',
      TimeCheckDepartureRequest: 'XXXXXXX',
      TimeCheckArrivalBeforeDeparture: 'XXXXXXX',
      TimeCheckDepartureAfterArrival: 'XXXXXXX',
      PastDate: 'XXXXXXX',
      IncomingChangesWarning:'XXXXXXX',
      InvalidDateFormat: 'XXXXXXX',
      InvalidDateFormatUse: 'XXXXXXX {{- format}}',
      ErrorCode: "XXXXXXX: {{errorCode}}"
    }
  },
  ActionMovementType: {
    Labels: {
      Arrival: 'XXXXXXX',
      Departure: 'XXXXXXX',
      BerthShiftArrival: 'XXXXXXX',
      BerthShiftDeparture: 'XXXXXXX',
      ShiftArrival: 'XXXXXXX',
      ShiftDeparture: 'XXXXXXX',
      All: 'XXXXXXX'
    }
  },
  ActionState: {
    Labels: {
      Planned: 'XXXXXX',
      InProgress: 'XXXXX',
      Completed: 'XXXXX',
      Cancelled: 'XXXXX',
      Deleted: 'XXXXX',
      ApprovalDeclined: 'XXXXX',
      ApprovalPending: 'XXXXX',
      Requested: 'XXXXX',
      Created: 'XXXXX',
      ArrivalRequested: 'XXXXX',
      ArrivalApprovalPending: 'XXXXX',
      ArrivalApprovalDeclined: 'XXXXX',
      ArrivalPlanned: 'XXXXX',
      ArrivalInProgress: 'XXXXX',
      ArrivalCompleted: 'XXXXX',
      ArrivalCancelled: 'XXXXX',
      ArrivalDeleted: 'XXXXX',
      DepartureRequested: 'XXXXX',
      DepartureApprovalPending: 'XXXXX',
      DepartureApprovalDeclined: 'XXXXX',
      DeparturePlanned: 'XXXXX',
      DepartureInProgress: 'XXXXX',
      DepartureCompleted: 'XXXXX',
      DepartureCancelled: 'XXXXX',
      DepartureDeleted: 'XXXXX',
      ShiftArrivalRequested: 'XXXXX',
      ShiftArrivalApprovalPending: 'XXXXX',
      ShiftArrivalApprovalDeclined: 'XXXXX',
      ShiftArrivalPlanned: 'XXXXX',
      ShiftArrivalInProgress: 'XXXXX',
      ShiftArrivalCompleted: 'XXXXX',
      ShiftArrivalCancelled: 'XXXXX',
      ShiftArrivalDeleted: 'XXXXX',
      ShiftDepartureRequested: 'XXXXX',
      ShiftDepartureApprovalPending: 'XXXXX',
      ShiftDepartureApprovalDeclined: 'XXXXX',
      ShiftDeparturePlanned: 'XXXXX',
      ShiftDepartureInProgress: 'XXXXX',
      ShiftDepartureCompleted: 'XXXXX',
      ShiftDepartureCancelled: 'XXXXX',
      ShiftDepartureDeleted: 'XXXXX',
      AgentHandoverRequested: 'XXXXX',
      AgentHandoverApprovalPending: 'XXXXX',
      AgentHandoverApprovalDeclined: 'XXXXX',
      AgentHandoverPlanned: 'XXXXX',
      AgentHandoverInProgress: 'XXXXX',
      AgentHandoverCompleted: 'XXXXX',
      AgentHandoverCancelled: 'XXXXX',
      AgentHandoverDeleted: 'XXXXX',
      CustomActionRequested: '{{action}} XXXXX',
      CustomActionApprovalPending: '{{action}} XXXXX',
      CustomActionApprovalDeclined: '{{action}} XXXXX',
      CustomActionPlanned: 'XXXXX {{action}}',
      CustomActionInProgress: '{{action}} XXXXX',
      CustomActionCompleted: '{{action}}  XXXXX',
      CustomActionCancelled: '{{action}} XXXXX',
      CustomActionDeleted: '{{action}} XXXXX',
    }
  },
  ActionType: {
    Labels: {
      Name: 'XXXXXXX',
      Movement: 'XXXXXXX',
      Event: 'XXXXXXX',
      ActionType: "XXXXXXX"
    }
  },
  Admin: {
    Labels: {
      Administration: 'XXXXXXX',
      AISStatusMonitoring: 'XXXXXXX',
      Automation: 'XXXXXXX',
      CertificateType: "XXXXXXX",
      GeneralSettings: 'XXXXXXX',
      SecondaryApproval: 'XXXXXXX',
      Notifications: 'XXXXXXX',
      PortDefinitions: 'XXXXXXX',
      OperationalSettings: 'XXXXXXX',
      ActionTypes: 'XXXXXXX',
      Contacts: 'XXXXXXX',
      PortCallCategories: 'XXXXXXX',
      Tariffs: 'XXXXXXX',
      TariffBook: "XXXXXXX",
      TariffUnits: 'XXXXXXX',
      CargoType: 'XXXXXXX'
    }
  },
  AdminActionType: {
    Labels: {
      ActionTypeDefaults: "XXXXXXX",
      ActionTypeDefaultsHelp: "XXXXXXX",
      MovementArrival: 'XXXXXXX',
      MovementDeparture: 'XXXXXXX',
      MovementShiftArrival: 'XXXXXXX',
      MovementShiftDeparture: 'XXXXXXX',
      DeletedHelp: "XXXXXXX",
      InvalidTariff: "XXXXXXX"
    }
  },
  AdminApproval: {
    Labels: {
      EnableSecondaryApproval: 'XXXXXXX',
      EnableSecondaryApprovalHelp: 'XXXXXXX',
      VesselExceptionList: 'XXXXXXX'
    }
  },
  AdminAutomation: {
    Labels: {
      NoAutomationRules: 'XXXXXXX',
      NoAutomationRulesHelp: 'XXXXXXX',
      EnablePortCallAutomation: 'XXXXXXX',
      EnablePortCallAutomationHelp: 'XXXXXXX',
      NoVesselsSpecified: 'XXXXXXX',
      UseAllVessels: 'XXXXXXX',
      UseAllVesselsHelp: 'XXXXXXX'
    }
  },
  AdminGeneralSettings: {
    Labels: {
      ApplicationCurrency: "XXXXXXX",
      ApplicationCurrencyHelp: "XXXXXXX",
      Language: "XXXXXXX",
      LanguageHelp: "XXXXXXX",
      FiltersTitle: "XXXXXXX",
      AdminFilters: "XXXXXXX"
    }
  },
  AdminModelEditor: {
    Labels: {
      CreateEntry: "XXXXXXX",
      DeleteEntry: "XXXXXXX",
      DeleteEntryMessage: "XXXXXXX",
      ReadOnly: "XXXXXXX",
      ConfirmSave: "XXXXXXX",
      DuplicateEntry: "XXXXXX",
      CustomDeleteEntry: "XXXXXX {{- itemName}}",
      CustomDuplicateEntry: "XXXXXX {{- itemName}}",
      UnsavedChangesMessage: "XXXXXX"
    }
  },
  AdminTariff: {
    Buttons: {
      AddTariff: "XXXXXXX",
      AddActionTypeItem: "XXXXXXX"
    },
    Labels: {
      DuplicateTariff: "XXXXXXX",
      Name: "XXXXXXX",
      Description: "XXXXXXX",
      DateValidFrom: "XXXXXXX",
      DateValidTo: "XXXXXXX",
      Tariffs: "XXXXXXX",
      DefaultChargeables: "XXXXXXX",
      TariffBookSaveWarning: "XXXXXXX",
      AddTariff: "XXXXXXX",
      UpdateTariff: "XXXXXXX",
      FilterByCode: "XXXXXXX",
      ActionType: "XXXXXXX",
      NumChargeableItems: "XXXXXXX",
      DeleteTariffTitle: "XXXXXXX",
      DeleteChargeableItemTitle: "XXXXXXX",
      DeleteChargeableItemMessage: "XXXXXXX {{- chargeableItem}}",
      DeleteTariffItemMessage: "XXXXXXX {{- tariffName}}",
      TariffBook: "XXXXXX"
    },
    Errors: {
      InvalidDate: "XXXXXXX",
      MissingName: "XXXXXXX"
    }
  },
  AdminPortCallCategory: {
    Labels: {
      PortCallCategory: "XXXXXXX"
    }
  },
  AdminCargoType: {
    Labels: {
      CargoType: "XXXXXXX"
    }
  },
  AdminNotification: {
    Error: {
      ActionTypeNotSpecified: "XXXXXXX",
      EventTypeNotSpecified: "XXXXXXX",
      MissingValues: "XXXXXXX"
    },
    Labels: {
      Name: "XXXXXXX",
      Description: "XXXXXXX",
      EnableNotification: "XXXXXXX",
      EnableNotifications: "XXXXXXX",
      EnableReminder: "XXXXXXX",
      Recipents: "XXXXXXX",
      Trigger: "XXXXXXX",
      ActionType: "XXXXXXX",
      TriggerType: "XXXXXXX",
      ReminderTime: "XXXXXXX",
      PortCallCategory: "XXXXXXX",
      Category: "XXXXXXX",
      PortCallTags: "XXXXXXX",
      Tags: "XXXXXXX",
      All: "XXX",
      Any: "XXX",
      Location: "XXXXXXX",
      ActionWithoutLocation: "XXXXXXX",
      Enabled: "XXXXXXX",
      Status: "XXXXXXX",
      SelectAction: "XXXXXXX",
      SelectEvent: "XXXXXXX",
      EventCreated: "XXXXXXX",
      EventStarted: "XXXXXXX",
      EventCompleted: "XXXXXXX",
      EventPendingApproval: "XXXXXXX",
      EventApproved: "XXXXXXX",
      EventDeclined: "XXXXXXX",
      EventRequested: "XXXXXXX",
      EventCancelled: "XXXXXXX",
      EventAwaitingApproval: "XXXXXXX",
      EventAwaitingFurtherApproval: "XXXXXXX",
      TimeBefore: "XXXXXXX",
      TimeAfter: "XXXXXXX",
      MovementArrival: "XXXXXXX",
      MovementDeparture: "XXXXXXX",
      MovementShiftArrival: "XXXXXXX",
      MovementShiftDeparture: "XXXXXXX",
      IncompleteWarningMsg: "XXXXXXX",
      NotificationsTab: "XXXXXXX",
      RemindersTab: "XXXXXXX",
      ReminderBefore: "XXXXXXX",
      ReminderAfter: "XXXXXXX",
      ReminderBeforePlannedTime: "XXXXXXX",
      ReminderAfterPlannedTime: "XXXXXXX",
      Notification: "XXXXXXX",
      Reminder: "XXXXXXX"
    }
  },
  TimePeriodSelect: {
    Errors: {
      InvalidPeriod: "XXXXXXX"
    },
    Labels: {
      TimePeriod: "XXXXXXX",
      TimeUnit: "XXXXXXX"
    }
  },
  AppHeader: {
    Labels: {
      CurrentDateAndTime: 'XXXXXXX',
      TidalForecast: 'XXXXXXX',
      TestTidalForecast: 'XXXXXXX',
      LoadingWeatherInformation: 'XXXXXXX',
      ErrorLoadingWeatherInformation: 'XXXXXXX',
      LoadingTidalInformation: 'XXXXXXX',
      ErrorRetrievingTidalInformation: 'XXXXXXX',
      Language: 'XXXXXXX',
      LogOut: 'XXXXXXX'
    }
  },
  Category: {
    Labels: {
      Category: "XXXXXXX",
      Name: 'XXXXXXX',
      Description: 'XXXXXXX'
    }
  },
  EditorContentTabs: {
    Labels: {
      Details: 'XXXXXXX',
      Requests: 'XXXXXXX',
      LogBook: 'XXXXXXX'
    }
  },
  ExportPortCalls: {
    Labels: {
      VesselName: "XXXXXXX",
      VesselLength: "XXXXXXX",
      Berth: "XXXXXXX",
      Loading: "XXXXXXX",
      PreparingDocument: "XXXXXXX",
      ActualTimeOfArrival: "XXXXXXX",
      ActualTimeOfDeparture: "XXXXXXX",
      ExportPortCalls: "XXXXXXX",
      ExportConfirmMessage_one: "{{count}} XXXXXXX {{fileType}}.",
      ExportConfirmMessage: "{{count}} XXXXXXX {{fileType}}.",
      Preview: "XXXXXXX",
      Configure: "XXXXXXX",
      SelectColumns: "XXXXXXX",
      PreviewColumns: "XXXXXXX",
      PreviewSampleDataSet: "XXXXXXX",
      SelectUnits: "XXXXXXX",
      Length: "XXXXXXX",
      Feet: "XXXXXXX",
      Metres: "XXXXXXX",
      PreviewCaption: "XXXXXXX"
    },
    Errors: {
      Loading:"XXXXXXX",
      ExternalError:"XXXXXXX {{error}}",
      TimeoutError:"XXXXXXX"
    }
  },
  ExportVessels: {
    Labels: {
      ExportVessels: "XXXXXXX",
      Vessels: "XXXXXXX",
      ExportConfirmMessage_one: "{{count}} XXXXXXX {{fileType}}.",
      ExportConfirmMessage: "{{count}} XXXXXXX {{fileType}}."
    }
  },
  NavBarVertical: {
    Labels: {
      NoRequests: 'XXXXXXX',
      Requests: 'XXXXXXX',
      RequestInbox: 'XXXXXXX',
      CreatePortCall: 'XXXXXXX',
      CreatePortCallDisabled: 'XXXXXXX',
      RequestPortCall: 'XXXXXXX',
      BoardView: 'XXXXXXX',
      MapView: 'XXXXXXX',
      VoyageBoardView: 'XXXXXXX',
      DataBrowser: "XXXXXXX",
      PortCalls: 'XXXXXXX',
      Vessels: "XXXXXXX",
      Voyages: "XXXXXXX",
      Dashboard: 'XXXXXXX',
      Documentation: 'XXXXXXX',
      Administration: 'XXXXXXX'
    }
  },
  PortCallDetails: {
    Labels: {
      Itinerary: 'XXXXXXX',
      PortCallCategory: 'XXXXXXX',
      ReportingCategory: 'XXXXXXX',
      Remarks: 'XXXXXXX',
      Tags: 'XXXXXXX',
      Loading: 'XXXXXXX',
      PortCallTags: 'XXXXXXX',
      Attachments: 'XXXXXXX',
      UnsavedChangesMessage: 'XXXXXXX',
      OverwritePortCallTags: 'XXXXXXX',
      OverwritePortCallTagsMessage: 'XXXXXXX',
      OverwritePortCallCategory: 'XXXXXXX',
      OverwritePortCallCategoryMessage: 'XXXXXXX',
      LeavePromptMessage: 'XXXXXXX',
      ConfirmChangeToCargo: 'XXXXXXX'
    },
    Errors: {
      Loading:'XXXXXXX',
      AppSyncError:'XXXXXXX {{error}}'
    }
  },
  PortCallList: {
    Labels: {
      PortCalls: 'XXXXXXX',
      SearchResult:"XXXXXXXXXXX {{rowCount}} XX",
      NoMoreData: 'XXXXXXX',
      Uncategorised: 'XXXXXXX',
      Port: 'XXXXXXX',
      Category: 'XXXXXXX',
      Order: 'XXXXXXX',
      Tags: 'XXXXXXX',
      Vessel: 'XXXXXXX',
      From: 'XXXXXXX',
      To: 'XXXXXXX',
      OldestFirst: 'XXXXXXX',
      NewestFirst: 'XXXXXXX',
      DepartureNotScheduled: 'XXXXXXX',
      ArrivalNotScheduled: 'XXXXXXX',
      ShowAll: 'XXXXXXX',
      Status: 'XXXXXXX',
      SearchByKeyword: 'XXXXXXX'
    }
  },
  PortCallStatus: {
    Labels : {
      Requested: 'XXXXXXX',
      Prearrival: 'XXXXXXX',
      Arrival: 'XXXXXXX',
      InPort: 'XXXXXXX',
      Departure: 'XXXXXXX',
      Cancelled: 'XXXXXXX',
      Deleted: 'XXXXXXX',
      Closed: 'XXXXXXX',
      'undefined': 'XXXXXXX'
    }
  },
  Tariff: {
    Labels: {
      Code: "XXXXXXX",
      TariffUnit: "XXXXXXX",
      Description: "XXXXXXX",
      UnitCost: "XXXXXXX",
      Currency: "XXXXXXX",
      Notes: "XXXXXXX",
      MinimumCost: "XXXXXXX",
      MinimumCostShort: "XXXXXXX",
      MinimumCostShort2: "XXXXXXX",
      ActionTypes: "XXXXXXX",
      ActionTypesHelp: "XXXXXXX",
      Unspecified: "XXXXXXX"
    }
  },
  TariffUnit: {
    Labels: {
      Name: 'XXXXXXX',
      SecondaryUnitName: "XXXXXXX",
      TariffUnit: "XXXXXXX"
    }
  },
  Vessel: {
    Labels: {
      Vessel: 'XXXXXXX',
      Name: 'XXXXXXX',
      TemporaryName: 'XXXXXXX',
      IMO: 'XXXXXXX',
      IMONumber: 'XXXXXXX',
      MMSI: 'XXXXXXX',
      CallSign: 'XXXXXXX',
      Flag: 'XXXXXXX',
      YearBuilt: 'XXXXXXX',
      Number: 'XXXXXXX',
      Type: 'XXXXXXX',
      DeadweightTonnage: 'XXXXXXX',
      Length: 'XXXXXXX',
      Beam: 'XXXXXXX',
      Draught: 'XXXXXXX',
      NominalDraught: 'XXXXXXX',
      GrossTonnage: 'XXXXXXX',
      NetTonnage: 'XXXXXXX',
      PortOfRegistry: 'XXXXXXX',
      Dimensions: 'XXXXXXX',
      AgentName: 'XXXXXXX',
      Identity: 'XXXXXXX',
      Details: 'XXXXXXX',
      VesselDetails: 'XXXXXXX',
      Tonnage: 'XXXXXXX',
      VesselAgent: 'XXXXXXX',
      PrimaryPropulsion: 'XXXXXXX',
      Propulsion: 'XXXXXXX',
      PropulsionType: 'XXXXXXX',
      NumPropellers: 'XXXXXXX',
      Direction: 'XXXXXXX',
      TotalPower: 'XXXXXXX',
      Units: 'XXXXXXX',
      Rudder: 'XXXXXXX',
      RudderType: 'XXXXXXX',
      NumRudders: 'XXXXXXX',
      ForwardAzimuth: 'XXXXXXX',
      AftAzimuth: 'XXXXXXX',
      ForwardTunnelThrusters: 'XXXXXXX',
      AftTunnelThrusters: 'XXXXXXX',
      NumThrusters: 'XXXXXXX',
      MastersName: 'XXXXXXX',
      NumCrew: 'XXXXXXX',
      NumPassengers: 'XXXXXXX',
      ForwardDraught: 'XXXXXXX',
      AftDraught: 'XXXXXXX',
      SailingDraught: 'XXXXXXX',
      AirDraught: 'XXXXXXX',
      ArrivalDraught: 'XXXXXXX',
      DepartureDraught: 'XXXXXXX',
      PortCallID: 'XXXXXXX: {{portCallId}}',
      VoyageId: 'XXXXXXX: {{voyageId}}',
      Certificates: 'XXXXXXX',
      LastVesselPosition: 'XXXXXXX: {{positionTimestamp}} ({{age}} xxx)',
      CentreVessel: 'XXXXXXX'
    },
    Errors:{
      VesselPositionNotFound:'XXXXXXX'
    }
  },
  Map: {
    Labels: {
      AISShipType: 'XXXXXXX',
      Navigation: 'XXXXXXX',
      Destination: 'XXXXXXX',
      ETA: 'XXXXXXX',
      Speed: 'XXXXXXX',
      Heading: 'XXXXXXX',
      CMG: 'XXXXXXX',
      Latitude: 'XXXXXXX',
      Longitude: 'XXXXXXX',
      Time: 'XXXXXXX',
      LastUpdated: 'XXXXXXX',
      Age: 'XXXXXXX',
    }
  },
  VesselTooltip: {
    Labels: {
      ArrivalDraught: 'XXXXXXX',
      DepartureDraught: 'XXXXXXX'
    }
  },
  RequestDetails: {
    Labels: {
      WithdrawRequest: 'XXXXXXX',
      WithdrawDialogTitle: 'XXXXXXX {{type}} XXXXXXX',
      WithdrawDialogMessage: 'XXXXXXX?',
      LastUpdated: 'XXXXXXX',
      UpdateRequest: 'XXXXXXX',
      ShowBerthUtilization: 'XXXXXXX',
      HideBerthUtilization: 'XXXXXXX',
      Current: 'XXXXXXX',
      Requested: 'XXXXXXX',
      CurrentTime: 'XXXXXXX',
      CurrentLocation: 'XXXXXXX',
      RequestedTime: 'XXXXXXX',
      Time: 'XXXXXXX',
      Location: 'XXXXXXX',
      ChangeTime: 'XXXXXXX',
      ErrorInvalidTime: 'XXXXXXX',
      ErrorCommentRequired: 'XXXXXXX',
      ErrorArrivalApproved: 'XXXXXXX',
      ErrorDepartureAfterArrival: 'XXXXXXX',
      ErrorArrivalBeforeAction: 'XXXXXXX',
      Comment: 'XXXXXXX',
      HideComments: 'XXXXXXX <number/> XXXXXXX',
      ShowComments: 'XXXXXXX <number/> XXXXXXX'
    }
  },
  RequestInbox: {
    Labels: {
      Inbox: "XXXXXXX",
      RequestHeadingsMovement: 'XXXXXXX',
      RequestHeadingsArrival: 'XXXXXXX',
      RequestHeadingsDeparture: 'XXXXXXX',
      RequestHeadingsPortCall: 'XXXXXXX',
      RequestHeadingsRemarks: 'XXXXXXX',
      NoRequests: 'XXXXXXX',
      AcknowledgeUpdate: 'XXXXXXX',
      Comments: 'XXXXXXX',
      UpdatedAt: 'XXXXXXX',
      ShowDetails: 'XXXXXXX',
      Source: 'XXXXXXX',
      Date: 'XXXXXXX',
      Notification: 'XXXXXXX',
      RequestType: 'XXXXXXX',
      Invalid: 'XXXXXXX'
    }
  },
  RequestTooltip: {
    Labels: {
      RequestType: 'XXXXXXX',
      Source: 'XXXXXXX',
      UpdatedAt: 'XXXXXXX',
      Status: 'XXXXXXX'
    }
  },
  RequestState: {
    Labels: {
      Pending: 'XXXXXXX',
      Rejected: 'XXXXXXX',
      Approved: 'XXXXXXX',
      Withdrawn: 'XXXXXXX',
      Notification: 'XXXXXXX',
      PartiallyApproved: 'XXXXXXX',
      PartiallyRejected: 'XXXXXXX',
      PendingAction: 'XXXXXXX',
      RejectAction: 'XXXXXXX',
      ApproveAction: 'XXXXXXX'
    }
  },
  RequestType: {
    Labels: {
      CreatePortCall: 'XXXXXXX',
      UpdatePortCallCargo: 'XXXXXXX',
      CancelPortCall: 'XXXXXXX',
      UpdateActionArrivalTimePlanned: 'XXXXXXX',
      UpdateActionDepartureTimePlanned: 'XXXXXXX',
      UpdateActionDraught: 'XXXXXXX',
      CancelAction: 'XXXXXXX',
      CreateDeparture: 'XXXXXXX'
    }
  },
  Contact: {
    Error: {
      ContactNotSpecified: "XXXXXXX"
    },
    Labels: {
      Type: 'XXXXXXX',
      Name: 'XXXXXXX',
      DisplayName: 'XXXXXXX',
      Reference: 'XXXXXXX',
      Address: 'XXXXXXX',
      Address1: 'XXXXXXX',
      Address2: 'XXXXXXX',
      Town: 'XXXXXXX',
      County: 'XXXXXXX',
      Postcode: 'XXXXXXX',
      TelephoneNumber: 'XXXXXXX',
      Fax: 'XXXXXXX',
      Email: 'XXXXXXX',
      ContactAName: 'XXXXXXX',
      ContactADetail: 'XXXXXXX',
      ContactBName: 'XXXXXXX',
      ContactNDetail: 'XXXXXXX',
      DeletedHelp: 'XXXXXXX',
      Contact: "XXXXXXX"
    }
  },
  ContactType: {
    Labels: {
      Agent: 'XXXXXXX',
      Stevedore: 'XXXXXXX',
      PortStaff: 'XXXXXXX',
      VesselAgent: 'XXXXXXX',
      VesselMaster: 'XXXXXXX',
    }
  },
  AgentHandover: {
    Labels: {
      From: 'XXXXXXX',
      NoActiveAgent: 'XXXXXXX'
    }
  },
  AgentHandoverCreateDialog: {
    Labels: {
      AgentHandover: 'XXXXXXX',
      PlannedTimeOfHandover: 'XXXXXXX'
    }
  },
  AgentHandoverEdit: {
    Labels: {
      Schedule: 'XXXXXXX',
      NoAgent: 'XXXXXXX',
      PlannedTime: 'XXXXXXX',
      Details: 'XXXXXXX',
    },
    Buttons: {
      SetPlannedTime: 'XXXXXXX',
    }
  },
  VesselDataEdit: {
    Labels: {
      UpdatedAt: 'XXXXXXX',
      RefreshVesselData: 'XXXXXXX',
      DisabledRefresh: 'XXXXXXX',
      ChangeVessel: 'XXXXXXX',
      UpdateVessel: 'XXXXXXX',
    }
  },
  VesselForm: {
    Errors: {
      EmptyName: 'XXXXXXX',
      MissingUnits: 'XXXXXXX',
      InvalidImo: 'XXXXXXX',
      DuplicateImo: 'XXXXXXX',
      InvalidMmsi: 'XXXXXXX',
      DuplicateMmsi: 'XXXXXXX'
    },
    Labels: {
      CreateVessel: 'XXXXXXX',
      UpdateVessel: 'XXXXXXX',
      UpdatePortCallOpenMessage: "XXXXXXX?",
      UpdatePortCallMessage: "XXXXXXX?",
      UnsavedChanges: "You have unsaved changes, are you sure you want to leave?",
      Image: 'XXXXXXX'
    }
  },
  VesselItinerary: {
    Labels: {
      PortOfLoading: 'XXXXXXX',
      LastPort: 'XXXXXXX',
      NextPort: 'XXXXXXX',
      PortOfDischarge: 'XXXXXXX',
    }
  },
  VesselSearch: {
    Labels: {
      UnknownVessel: 'XXXXXXX',
      TemporaryName: 'XXXXXXX',
      CallSign: 'XXXXXXX',
      Beam: 'XXXXXXX',
      LengthOverall: 'XXXXXXX',
      NominalDraught: 'XXXXXXX'
    },
    Errors: {
      NotAValidImo: 'XXXXXXX',
      NotAValidMmsi: 'XXXXXXX',
    }
  },
  LocationTimeline: {
    Labels: {
      NotAllocated: 'XXXXXXX',
      NoUpcomingAction: 'XXXXXXX'
    }
  },
  Agent: {
    Labels: {
      Agent: "XXXXXXX",
      CharterAgent: 'XXXXXXX',
      CreateAgent: 'XXXXXXX',
      Name: 'XXXXXXX',
      CompanyName: 'XXXXXXX',
      DisplayName: 'XXXXXXX',
      ContactDetails: 'XXXXXXX',
      ContactName: 'XXXXXXX',
      TelephoneNumber: 'XXXXXXX',
      FaxNumber: 'XXXXXXX'
    },
    Buttons: {
      CreateAgent: 'XXXXXXX'
    },
    Errors: {
      EmptyCompanyName: 'XXXXXXX',
      EmptyTelephoneNumber: 'XXXXXXX'
    }
  },
  VesselEditImageForm: {
    Buttons: {
      Select: 'XXXXXXX',
      Replace: 'XXXXXXX',
      Discard: 'XXXXXXX',
      DiscardCrop: 'XXXXXXX'
    },
    Errors: {
      FileTypeTitle: 'XXXXXXX',
      FileTypeMessage: 'XXXXXXX',
      FailedToUpload: 'XXXXXXX',
      FailedToDelete: 'XXXXXXX',
      ErrorMessage: 'XXXXXXX: {{error}}',
      Support: 'XXXXXXX'
    },
  },
  ImageDialog: {
    Labels: {
      CropImage: 'XXXXXXX'
    }
  },
  VesselImage: {
    Labels: {
      Image: 'XXXXXXX'
    }
  },
  PropulsionType: {
    Labels: {
      FixedPitch: 'XXXXXXX',
      ControllablePitch: 'XXXXXXX',
      FixedVariablePitch: 'XXXXXXX',
      AzimuthThruster: 'XXXXXXX',
      VoithSchneider: 'XXXXXXX',
      ZType: 'XXXXXXX'
    }
  },
  RudderType: {
    Labels: {
      Standard: 'XXXXXXX',
      Flapped: 'XXXXXXX',
      Fishtail: 'XXXXXXX'
    }
  },
  PropellerDirection: {
    Labels: {
      Left: 'XXXXXXX',
      Right: 'XXXXXXX'
    }
  },
  ActionDetails: {
    Labels: {
      Schedule: 'XXXXXXX',
      Loading: 'XXXXXXX',
      CharterAgent: 'XXXXXXX',
      Location: 'XXXXXXX',
      Pilotage: 'XXXXXXX',
      Vessel: 'XXXXXXX',
      VesselDetails: 'XXXXXXX',
      VesselCondition: 'XXXXXXX',
      Checklist: 'XXXXXXX',
      Attachments: 'XXXXXXX',
      ChargeableItems: 'XXXXXXX',
      Prompt: 'XXXXXXX',
      UnsavedChangesMessage: 'XXXXXXX',
      AutomationWarning: 'XXXXXXX {{movementType}} XXXXXXX',
      SubmitForApproval: 'XXXXXXX',
      ConfirmDraught: 'XXXXXXX',
      Remarks: 'XXXXXXX',
      ConfirmSaveTitle: 'XXXXXXX {{movementType}}: {{vesselName}}',
      ConfirmSaveMessage: 'XXXXXXX {{movementType}} XXXXXXX {{vesselName}}?',
      ReopenPortCallMessage: 'XXXXXXX'
    },
    Errors: {
      Loading:'XXXXXXX',
      AppSyncError:'XXXXXXX {{error}}'
    }
  },
  MovementEdit: {
    Labels: {
      Attachments: 'XXXXXXX',
      ChargeableItems: 'XXXXXXX',
      Checklist: 'XXXXXXX'
    }
  },
  ActionScheduleEdit: {
    Buttons: {
      SetRequestedTime: 'XXXXXXX',
      SetPlannedTime: 'XXXXXXX',
      SetVesselReadyTime: 'XXXXXXX',
      SetPilotBoardingTime: 'XXXXXXX',
      SetActualTime: 'XXXXXXX',
      RequestChange: 'XXXXXXX'
    },
    Labels: {
      VoyageId: 'XXXXXXX',
      MovementSchedule: '{{movement}} XXXXXXX',
      MovementLocation: '{{movement}} XXXXXXX',
      NotBerthableLocation: 'XXXXXXX',
      RequestedTime: 'XXXXXXX',
      PlannedTime: 'XXXXXXX',
      VesselReadyTime: 'XXXXXXX',
      PilotBoardingTime: 'XXXXXXX',
      ActualTime: 'XXXXXXX',
      CharterAgent: 'XXXXXXX',
      Pilotage: 'XXXXXXX',
      Pilots: 'XXXXXXX',
      Linesmen: 'XXXXXXX',
      MooringVessels: 'XXXXXXX',
      Loading: 'XXXXXXX',
      NoVoyages: 'XXXXXXX'
    }
  },
  ActionVesselEdit: {
    Labels: {
      Vessel: 'XXXXXXX',
      MovementDetails: '{{movement}} XXXXXXX',
    }
  },
  ActionPropulsionEdit: {
    Labels: {
      MovementCondition: '{{movement}} XXXXXXX',
      PrimaryPropulsion: 'XXXXXXX',
      PropulsionState: 'XXXXXXX',
      PropulsionRemarks: 'XXXXXXX',
      RudderState: 'XXXXXXX',
      RudderRemarks: 'XXXXXXX',
      ForwardAzimuthState: 'XXXXXXX',
      ForwardAzimuthRemarks: 'XXXXXXX',
      AftAzimuthState: 'XXXXXXX',
      AftAzimuthRemarks: 'XXXXXXX',
      ForwardTunnelThrustersState: 'XXXXXXX',
      ForwardTunnelThrustersRemarks: 'XXXXXXX',
      AftTunnelThrustersState: 'XXXXXXX',
      AftTunnelThrustersRemarks: 'XXXXXXX',
    }
  },
  ActionPropulsionOperationalState: {
    Labels: {
      FullyOperational: 'XXXXXXX',
      HasFault: 'XXXXXXX'
    }
  },
  AgentRequestTimeChangeDialog: {
    Buttons: {
      SendRequest: 'XXXXXXX',
    },
    Labels: {
      Title: 'XXXXXXX {{movement}}',
      RequestedTimeArrival: 'XXXXXXX',
      RequestedTimeDeparture: 'XXXXXXX',
      Remarks: 'XXXXXXX',
    }
  },
  DocumentEdit: {
    Buttons: {
      Upload: 'XXXXXXX',
      AddAttachment: 'XXXXXXX'
    },
    Labels: {
      NoFile: 'XXXXXXX',
      Filename: 'XXXXXXX',
      Progress: 'XXXXXXX',
      Complete: 'XXXXXXX',
      Downloaded: 'XXXXXXX',
      Deleted: 'XXXXXXX',
      Attachments: 'XXXXXXX'
    },
    Errors: {
      DuplicateTitle: 'XXXXXXX {{doc}} XXXXXXX',
      DuplicateMessage: 'XXXXXXX {{doc}} XXXXXXX',
      TooLargeTitle: 'XXXXXXX',
      TooLargeMessage: 'XXXXXXX {{doc}} XXXXXXX {{limit}}',
      FailedUploadTitle: '{{doc}} XXXXXXX',
      FailedUploadMessage: 'XXXXXXX. (XXXXXXX: {{error}}',
      FailedDeleteTitle: '{{doc}} XXXXXXX',
      FailedDeleteMessage: 'XXXXXXX (XXXXXXX: {{error}}',
      FailedDownloadTitle: '{{doc}} XXXXXXX',
      FailedDownloadMessage: 'XXXXXXX (XXXXXXX: {{error}}',
      Support: 'XXXXXXX',
      FailedUnknownTitle: 'XXXXXXX',
      FailedUnknownMessage: 'XXXXXXX'
    }
  },
  DocumentList: {
    Labels: {     
      DeleteAttachmentTitle: 'XXXXXXX {{doc}}',
      DeleteAttachmentMessage: 'XXXXXXX {{doc}}',
      NoAttachments: 'XXXXXXX',
      NoDeletePermission: 'XXXXXXX'
    }
  },
  AddTodo: {
    Labels: {
      Select: 'XXXXXXX'
    }
  },
  ChargeableItems: {
    Buttons: {
      Add: 'XXXXXXX',
      Update: 'XXXXXXX',
    },
    Labels: {
      Title: 'XXXXXXX',
      Tariff: 'XXXXXXX',
      Notes: 'XXXXXXX',
      UnitCost: 'XXXXXXX',
      MinimumCost: 'XXXXXXX',
      Quantity: 'XXXXXXX',
      QuantityShort: 'XXXXXXX',
      Total: 'XXXXXXX',
      Empty: '',
      Delete: 'XXXXXXX',
      UnitType: 'XXXXXXX',
      OverriddenUnitCost: 'XXXXXXX',
      ItemNotes: 'XXXXXXX',
      ResetTariff: 'XXXXXXX',
      UnitCostTooltip: 'XXXXXXX',
      ResetTooltip: 'XXXXXXX',
      NoTariffs: 'XXXXXXX',
      NoTariffsIsAdminIsAdmin: "XXXXXXX",
      NoTariffsIsAdminNotAdmin: "XXXXXXX",
      NoChargeable: 'XXXXXXX'
    },
    Errors: {
      InvalidChargeable: 'XXXXXXX',
      TariffBookDeleted: "XXXXXXX",
      TariffBookOutOfRange: "XXXXXXX",
      TariffDeleted: "XXXXXXX",
      ActionInvalidTariffs: "XXXXXXX"
    }
  },
  FileTypes: {
    Labels: {
      Fal5CrewList: 'XXXXXXX'
    }
  },
  PreArrivalUpload: {
    Buttons: {
      CreateForm: 'XXXXXXX',
      EditForm: 'XXXXXXX',
      UploadFile: 'XXXXXXX',
    },
    Labels: {
      UploadedBy: 'XXXXXXX',
      UpdatedBy: 'XXXXXXX',
      ReplaceTitle: '{{fileType}} XXXXXXX',
      ReplaceUploadMessage: 'XXXXXXX {{fileType}} XXXXXXX',
      ReplaceCreateFormMessage: 'XXXXXXX {{fileType}} XXXXXXX',
      DeleteTitle: 'XXXXXXX {{fileType}}',
      DeleteMessage: 'XXXXXXX {{fileName}} XXXXXXX'
    },
    Errors: {
      Title: 'XXXXXXX',
      FailedToUpload: 'XXXXXXX',
      FailedToDownload: 'XXXXXXX',
    }
  },
  Fal5CrewList: {
    Buttons: {
      AddMember: 'XXXXXXX',
    },
    Labels: {
      Arrival: 'XXXXXXX',
      Departure: 'XXXXXXX',
      _1_1_NameOfShip: "XXXXXXX",
      _1_2_ImoNumber: "XXXXXXX",
      _1_3_Callsign: "XXXXXXX",
      _1_4_VoyageNumber: "XXXXXXX",
      _2_PortOfArrivalDeparture: "XXXXXXX",
      _3_DateOfArrivalDeparture: "XXXXXXX",
      _4_FlagStateOfShip: "XXXXXXX",
      _5_LastPortOfCall: "XXXXXXX",
      _6_Num: 'XXXXXXX',
      _7_FamilyName: 'XXXXXXX',
      _8_GivenNames: 'XXXXXXX',
      _9_RankOrRating: 'XXXXXXX',
      _10_Nationality: 'XXXXXXX',
      _11_DateOfBirth: 'XXXXXXX',
      _12_PlaceOfBirth: 'XXXXXXX',
      _13_Gender: 'XXXXXXX',
      _14_NatureOfIdentidyDoc: 'XXXXXXX',
      _15_NumberOfIdentidyDoc: 'XXXXXXX',
      _16_IssuingStateOfIdentityDoc: 'XXXXXXX',
      _17_ExpireDateOfIdentityDoc: 'XXXXXXX',
    }
  },
  PortCall: {
    Buttons: {
      AddDeparture: 'XXXXXXX',
      MoreOptions: 'XXXXXXX'
    },
    Errors: {
      InvalidDateFormat: 'XXXXXXX',
      ArrivalBeforeDeparture: 'XXXXXXX',
      DepartureAfterArrival: 'XXXXXXX'
    },
    Labels: {
      NewPortCallTitle: 'XXXXXXX',
      RequestPortCall: 'XXXXXXX',
      PlannedTimeOfArrival: 'XXXXXXX',
      PlannedTimeOfDeparture: 'XXXXXXX',
      ArrivalLocation: 'XXXXXXX',
      DepartureLocation: 'XXXXXXX',
      Schedule: 'XXXXXXX',
      EnterRemarksForPortControl: 'XXXXXXX',
      ArrivalDraughts: 'XXXXXXX',
      DepartureDraughts: 'XXXXXXX',
    }
  },
  Cargo: {
    Labels: {
      Cargo: 'XXXXXXX',
      Cargos: 'XXXXXXX',
      CargoItem: 'XXXXXXX',
      CargoItems: 'XXXXXXX',
      Incoming: 'XXXXXXX',
      IncomingCargo: 'XXXXXXX',
      Outgoing: 'XXXXXXX',
      CargoType: 'XXXXXXX',
      OutgoingCargo: 'XXXXXXX',
      OtherCargo: 'XXXXXXX',
      NoCargo: 'XXXXXXX',
      More: 'XXXXXXX'
    },
    Buttons: {
      AddCargo: 'XXXXXXX'
    }
  },
  MovementActionList: {
    Labels: {
      Schedule: 'XXXXXXX',
      ScheduleDesc: 'XXXXXXX',
      PortCallId: 'XXXXXXX',
      PortCallIdDesc: 'XXXXXXX',
      VoyageId: 'XXXXXXX',
      VoyageIdDesc: 'XXXXXXX',
      Vessel: 'XXXXXXX',
      VesselDesc: 'XXXXXXX',
      PortOfRegistry: 'XXXXXXX',
      PortOfRegistryDesc: 'XXXXXXX',
      Dimensions: 'XXXXXXX',
      DimensionsDesc: 'XXXXXXX',
      Length: 'XXXXXXX',
      LengthFull: 'XXXXXXX',
      LengthDesc: 'XXXXXXX',
      Beam: 'XXXXXXX',
      BeamDesc: 'XXXXXXX',
      Draught: 'XXXXXXX',
      DraughtDesc: 'XXXXXXX',
      AirDraught: 'XXXXXXX',
      AirDraughtDesc: 'XXXXXXX',
      BowThrusters: 'XXXXXXX',
      BowThrustersFull: 'XXXXXXX',
      BowThrustersDesc: 'XXXXXXX',
      Location: 'XXXXXXX',
      LocationDesc: 'XXXXXXX',
      CharterAgent: 'XXXXXXX',
      Cargo: 'XXXXXXX',
      CargoDesc: 'XXXXXXX',
      NextPort: 'XXXXXXX',
      NextPortDesc: 'XXXXXXX',
      LastPort: 'XXXXXXX',
      LastPortDesc: 'XXXXXXX',
      LatestUserLogEntry: 'XXXXXXX',
      LatestUserLogEntryDesc: 'XXXXXXX',
      Remarks: 'XXXXXXX',
      RemarksDesc: 'XXXXXXX',
      GrossTonnage: 'XXXXXXX',
      GrossTonnageFull: 'XXXXXXX',
      GrossTonnageDesc: "XXXXXXX",
      Checklist: 'XXXXXXX',
      ChecklistDesc: 'XXXXXXX',
      FilterType: 'XXXXXXX',
      AllType: 'XXXXXXX {{type}}',
      NotAllocated: 'XXXXXXX'
    }
  },
  Filter: {
    Buttons: {
      ApplyFilters: 'XXXXXXX'
    },
    Labels: {
      EnterFilterName: 'XXXXXXX',
      NoName: 'XXXXXXX',
      UniqueName: 'XXXXXXX',
      SpecialCharacters: 'XXXXXXX',
      SelectFilter: 'XXXXXXX',
      FilterUpdated: 'XXXXXXX',
      ApplyFilters: 'XXXXXXX',
      SelectType: 'XXXXXXX',
      FilterName: 'XXXXXXX',
      FilterNamePrompt: 'XXXXXXX'
    }
  },
  Logbook: {
    Buttons: {
      AddEntry: 'XXXXXXX',
    },
    Labels: {
      Logbook: 'XXXXXXX',
      ItemCreatedByUser: '<item/> XXXXXXX <user/>',
      ItemUpdatedByUser: '<item/> XXXXXXX <user/>',
      ItemFileUploadedByUser: "<item/> XXXXXXX <file/> XXXXXXX <user/>",
      ItemFileUpdatedByUser: "<item/> XXXXXXX <file/> XXXXXXX <user/>",
      ItemFileDeletedByUser: "<item/> XXXXXXX <file/> XXXXXXX <user/>",
      FileUploadedByUser: "XXXXXXX <file/> XXXXXXX <user/>",
      FileUpdatedByUser: "XXXXXXX <file/> XXXXXXX <user/>",
      FileDeletedByUser: "XXXXXXX <file/> XXXXXXX <user/>",
      UserCommentedOnItem: '<user/> XXXXXXX <item/>',
      UserCommented: '<user/> XXXXXXX',
      Comment: 'XXXXXXX',
      Action: 'XXXXXXX',
      NoDataAvailable: 'XXXXXXX',
      AssociateWithSelectedItem: 'XXXXXXX <item/>',
      ShowOnlyEntriesForSelectedItem: 'XXXXXXX <item/>',
      ItemRequestedTime: '{{item}} XXXXXXX',
      RequestedTime: 'XXXXXXX',
      Request: 'XXXXXXX',
      PortCall: 'XXXXXX'
    }
  },
  BerthStatusDisplay: {
    Labels: {
      Berth: 'XXXXXXX',
      Berths: 'XXXXXXX',
      ActiveAgent: 'XXXXXXX',
      Remarks: 'XXXXXXX',
      ShowPreviousDetails: 'XXXXXXX',
      ShowRefreshingDetails: 'XXXXXXX',
      StartRefreshingDetails: 'XXXXXXX',
      ShowNextDetails: 'XXXXXXX',
      DetailsDettings: 'XXXXXXX',
      NoVesselsInPort: 'XXXXXXX',
      FilterAll: 'XXX',
      FilterOccupied: 'XXXX',
      FilterVacant: 'XXXXXXX',
      FilterNameAllBerths: 'XXXXXXX {{name}}',
      Every1Second: 'XXXXXXX',
      Every5Second: 'XXXXXXX',
      Every15Second: 'XXXXXXX',
      Every1Minute: 'XXXXXXX',
      RefreshRate: 'XXXXXXX',
      DisplayedDetails: 'XXXXXXX'
    }
  },
  ToggleFullscreen: {
    Labels: {
      ToggleFullscreen: 'XXXXXXX'
    }
  },
  PortCallMenuItem: {
    Labels: {
      Movement: 'XXXXXXX',
      PortCall: 'XXXXXXX',
      None: 'XXXXXXX',
      StartDeparture: 'XXXXXXX',
      StartArrival: 'XXXXXXX',
      RecordDeparture: 'XXXXXXX',
      RecordArrival: 'XXXXXXX',
      StartShiftDeparture: 'XXXXXXX',
      StartShiftArrival: 'XXXXXXX',
      RecordShiftDeparture: 'XXXXXXX',
      RecordShiftArrival: 'XXXXXXX',
      DeleteShift: 'XXXXXXX',
      UndoLast: 'XXXXXXX {{actionName}} {{actionState}}',
      UndoCompleted: 'XXXXXXX',
      UndoInProgress: 'XXXXXXX',
      CancelPortCall: 'XXXXXXX',
      DeletePortCall: 'XXXXXXX',
      ShowDetails: 'XXXXXXX',
      AgentHandover: 'XXXXXXX',
      PlannedTimeOfHandover: 'XXXXXXX',
      CharterAgent: 'XXXXXXX',
      DeleteDeparture: 'XXXXXXX',
      CancelDeparture: 'XXXXXXX',
      Approve: 'XXXXXXX',
      Decline: 'XXXXXXX',
      MarkForApproval: 'XXXXXXX',
      RequiresApproval: 'XXXXXXX',
      StartCustom: 'XXXXXXX',
      RecordCustom: 'XXXXXXX',
      CancelCustom: 'XXXXXXX',
      DeleteCustom: 'XXXXXXX',
      DeleteAgentHandover: 'XXXXXXX',
      NonDockableLocationWarning: 'XXXXXXXX',
      NoArrivalLocationWarning: 'XXXXXXXX',
      RequestApprovalWarning: 'XXXXXXXX',
      CompletePortCall: 'XXXXXXXX',
      Spotlight: 'XXXXXXXX',
      ViewInvoice: 'XXXXXXXX',
      NoVoyageAdminPermissionToCancel: 'XXXXXXXX',
      NoVoyageAdminPermissionToDelete: 'XXXXXXXX',
      ClosePortCall: 'XXXXXXXX'
    }
  },
  PortCallTimeline: {
    Buttons: {
      LifecycleNone: 'XXXXXXX',
      LifecycleStartDeparture: 'XXXXXXX',
      LifecycleStartArrival: 'XXXXXXX',
      LifecycleRecordDeparture: 'XXXXXXX',
      LifecycleRecordArrival: 'XXXXXXX',
      LifecycleStartShiftDeparture: 'XXXXXXX',
      LifecycleStartShiftArrival: 'XXXXXXX',
      LifecycleRecordShiftDeparture: 'XXXXXXX',
      LifecycleRecordShiftArrival: 'XXXXXXX',
      LifecycleRequiresApproval: 'XXXXXXX',
      LifecycleStartCustom: `XXXXXXX`,
      LifecycleRecordCustom: 'XXXXXXX'
    },
    Labels: {
      AddArrival: 'XXXXXXX',
      AddDeparture: 'XXXXXXX',
      AddBerthShift: 'XXXXXXX',
      AddAgentHandover: 'XXXXXXX',
      AddCustom: 'XXXXXXX {{actionName}} XXXXXXX'
    }
  },
  PortCallTimelineMenu: {
    Errors: {
      PortCallUpdateFailed: "XXXXXXX",
      VoyageUpdateFailed: "XXXXXXX"
    },
    Labels: {
      Remarks: 'XXXXXXX',
      CompletePortCall: 'XXXXXXX: {{vesselName}}',
      PortCallOutstandingTodos: 'XXXXXXX',
      CompletePortCallMessage: '{{todos}} XXXXXXX',
      OutstandingTodos: 'XXXXXXX',
      ArrivalDialogTitle: 'XXXXXXX: {{vesselName}}',
      ArrivalDialogMessage: 'XXXXXXX {{vesselName}} {{todos}}',
      ShiftArrivalDialogTitle: 'XXXXXXX: {{vesselName}}',
      ShiftArrivalDialogMessage: 'XXXXXXX {{vesselName}} {{todos}}',
      RecordArrivalDialogTitle: 'XXXXXXX: {{vesselName}}',
      RecordArrivalDialogInput: 'XXXXXXX',
      RecordShiftArrivalDialogTitle: 'XXXXXXX: {{vesselName}}',
      RecordShiftArrivalDialogInput: 'XXXXXXX',
      DepartureDialogTitle: 'XXXXXXX: {{vesselName}}',
      DepartureDialogInput: 'XXXXXXX',
      ShiftDepartureDialogTitle: 'XXXXXXX: {{vesselName}}',
      ShiftDepartureDialogInput: 'XXXXXXX',
      DeleteShiftDialogTitle: 'XXXXXXX: {{vesselName}}',
      DeleteShiftDialogMessage: 'XXXXXXX {{todos}}',
      RecordDepartureDialogTitle: 'XXXXXXX: {{vesselName}}',
      RecordDepartureDialogMessage: 'XXXXXXX {{vesselName}} {{todos}} XXXXXXX',
      RecordShiftDepartureDialogTitle: 'XXXXXXX: {{vesselName}}',
      RecordShiftDepartureDialogMessage: 'XXXXXXX {{vesselName}} {{todos}}',
      UndoActionDialogTitle: 'XXXXXXX: {{vesselName}}',
      UndoActionDialogMessage: 'XXXXXXX {{actionName}} {{actionState}} XXXXXXX {{vesselName}}',
      CancelPortCallDialogTitle: 'XXXXXXX: {{vesselName}}',
      CancelPortCallDialogMessage: 'XXXXXXX {{vesselName}} XXXXXXX',
      CancelRequestDialogTitle: 'XXXXXXX : {{vesselName}}',
      CancelRequestDialogMessage: 'XXXXXXX {{vesselName}}',
      DeletePortCallDialogTitle: 'XXXXXXX: {{vesselName}}',
      DeletePortCallDialogMessage: 'XXXXXXX {{vesselName}} XXXXXXX',
      CloseArrivalPortCallDialogTitle: 'XXXXXXX: {{vesselName}}',
      CloseArrivalPortCallDialogMessage: 'XXXXXXX {{vesselName}} XXXXXXX',
      ActionCancelDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      ActionCancelDialogMessage: 'XXXXXXX {{actionName}} XXXXXXX {{vesselName}} {{todos}}',
      CancelDepartureRequestDialogTitle: 'XXXXXXX: {{vesselName}}',
      CancelDepartureRequestDialogMessage: 'XXXXXXX {{vesselName}}',
      DeleteActionDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      DeleteActionDialogMessage: 'XXXXXXX {{actionName}} XXXXXXX {{vesselName}}',
      StartCustomActionDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      StartCustomActionDialogInput: 'XXXXXXX {{actionName}}',
      RecordCustomActionDialogTitle: 'XXXXXXX{{actionName}}: {{vesselName}}',
      RecordCustomActionDialogMessage: 'XXXXXXX {{actionName}}: {{vesselName}}',
      ApproveActionDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      ApproveActionDialogMessage: 'XXXXXXX {{actionName}}: {{vesselName}}',
      DeclineActionDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      DeclineActionDialogMessage: 'XXXXXXX {{actionName}}: {{vesselName}}',
      MarkApprovalDialogTitle: 'XXXXXXX {{actionName}}: {{vesselName}}',
      MarkApprovalDialogMessage: 'XXXXXXX {{actionName}}: {{vesselName}}',
      DeleteAgentHandoverDialogTitle: 'XXXXXXX',
      DeleteAgentHandoverDialogMessage: 'XXXXXXX {{agentName}}',
      AutomationArrivalWarning: 'XXXXXXX',
      AutomationDepartureWarning: 'XXXXXXX'
    }
  },
  CustomAction: {
    Errors: {
      FirstAction: 'XXXXXXX',
      Departure: 'XXXXXXX',
      LastCompletedInProgress: 'XXXXXXX',
      InvalidDate: 'XXXXXXX',
      TimeCheckActualBeforeComplete: 'XXXXXXX',
      TimeCheckCompleteAfterActual: "XXXXXXX"
    }
  },
  CustomActionMenu: {
    Labels: {
      DeleteAction: 'XXXXXXX {{actionName}} XXXXXXX',
      CancelAction: 'XXXXXXX {{actionName}} XXXXXXX',
      UndoAction: 'XXXXXXX {{actionName}} XXXXXXX',
      RecordAction: 'XXXXXXX {{actionName}} XXXXXXX',
      StartAction: 'XXXXXXX {{actionName}} XXXXXXX',
      CompleteAction: "XXXXXXX {{actionName}} XXXXXXX",
      UndoCompleted: "XXXXXXX",
      UndoStarted: "XXXXXXX",
      CancelActionDialogTitle: 'XXXXXXX {{actionName}} XXXXXXX: {{vesselName}}',
      CancelActionDialogMessage: 'XXXXXXX {{actionName}} XXXXXXX XXXXXXX {{vesselName}}',
      UndoActionDialogTitle: 'XXXXXXX: {{vesselName}}',
      UndoActionDialogMessage: 'XXXXXXX {{actionName}} {{actionState}} XXXXXXX XXXXXXX {{vesselName}}?',
      RecordActionDialogTitle: 'XXXXXXX {{actionName}} XXXXXXX: {{vesselName}}',
      RecordActionDialogMessage: 'XXXXXXX {{actionName}} XXXXXXX: {{vesselName}}',
      StartActionDialogTitle: 'XXXXXXX {{actionName}} XXXXXXX: {{vesselName}}',
      StartActionDialogInput: 'XXXXXXX {{actionName}} XXXXXXX',
      DeleteActionDialogTitle: 'XXXXXXX {{actionName}} XXXXXXX',
      DeleteActionDialogMessage: 'XXXXXXX {{actionName}} XXXXXXX?',
      CompleteActionDialogInput: 'XXXXXXX {{actionName}} XXXXXXX'
    }
  },
  CustomActionEdit: {
    Labels: {
      Schedule: '{{actionName}} XXXXXXX',
      Notes: "XXXXXXX"
    }
  },
  CustomActionCreateDialog: {
    Labels: {
      Title: 'XXXXXXX {{actionName}}',
      Service: 'XXXXXXX',
      PlannedTime: 'XXXXXXX',
      Notes: 'XXXXXXX'
    }
  },
  CustomActionScheduleEdit: {
    Labels: {
      SetPlannedTime: 'XXXXXXX',
      SetActualTime: 'XXXXXXX',
      SetCompletionTime: 'XXXXXXX',
      Time: 'XXXXXXX',
      SetTime: 'XXXXXXX',
      StartTime: 'XXXXXXX',
      CompletionTime: 'XXXXXXX',
    },
    Errors: {
      InvalidTime: 'XXXXXXX'
    }
  },
  ShiftCreateDialog: {
    Labels: {
      NewShift: 'XXXXXXX'
    },
    Errors: {
      ShiftDepartureAfterArrival: 'XXXXXXX',
      ShiftDepartureBeforeCompleted: 'XXXXXXX',
      ShiftAfterDeparture: 'XXXXXXX'
    }
  },
  ArrivalCreateDialog: {
    Labels: {
      NewArrival: 'XXXXXXX',
    }
  },
  DepartureCreateDialog: {
    Labels: {
      NewDeparture: 'XXXXXXX',
    }
  },
  AgentDepartureCreateDialog: {
    Labels: {
      NewDeparture: 'XXXXXXX',
      Remarks: 'XXXXXXX'
    },
    Errors: {
      DepartureAfterArrival: 'XXXXXXX'
    }
  },
  TimeTooltip: {
    Labels: {
      ActualTime: 'XXXXX',
      StartTime: 'XXXXX',
      PlannedTime: 'XXXXX',
      CompletionTime: 'XXXXX',
      EstimatedTime: 'XXXXX',
      RequestedTime: 'XXXXX',
      Notes: 'XXXXX',
      Action: 'XXXXX',
      UpdatedAgo: 'XXXXX {{time}} XXX'
    }
  },
  Weather: {
    Labels: {
      TemperatureAt: 'XXXXXXX ({{symbol}}) XXX {{time}}',
      VisibilityAt: 'XXXXXXX ({{unit}}) XXX {{time}}',
      AtmosphericPressureAt: 'XXXXXXX ({{unit}}) XXX {{time}}',
      WindDirectionAt: '{{degrees}}X {{direction}} XXX {{time}}',
      Gusting: 'XXXXXXX',
      WeatherAt: '{{weather}} XXX {{time}}'
    }
  },
  WeatherIcon: {
    Labels: {
      Sunny: 'XXXXXXX',
      MostlySunny: 'XXXXXXX',
      PartlySunny: 'XXXXXXX',
      IntermittentClouds: 'XXXXXXX',
      HazySunshine: 'XXXXXXX',
      MostlyCloudy: 'XXXXXXX',
      Cloudy: 'XXXXXXX',
      Dreary: 'XXXXXXX',
      Fog: 'XXXXXXX',
      Showers: 'XXXXXXX',
      MostlyCloudyWithShowers: 'XXXXXXX',
      PartlySunnyWithShowers: 'XXXXXXX',
      TStorms: 'XXXXXXX',
      MostlyCloudyWithTStorms: 'XXXXXXX',
      PartlySunnyWithTStorms: 'XXXXXXX',
      Rain: 'XXXXXXX',
      Flurries: 'XXXXXXX',
      MostlyCloudyWithFlurries: 'XXXXXXX',
      PartlySunnyWithFlurries: 'XXXXXXX',
      Snow: 'XXXXXXX',
      MostlyCloudyWithSnow: 'XXXXXXX',
      Ice: 'XXXXXXX',
      Sleet: 'XXXXXXX',
      FreezingRain: 'XXXXXXX',
      RainAndSnow: 'XXXXXXX',
      Hot: 'XXXXXXX',
      Cold: 'XXXXXXX',
      Windy: 'XXXXXXX',
      Clear: 'XXXXXXX',
      MostlyClear: 'XXXXXXX',
      PartlyCloudy: 'XXXXXXX',
      HazyMoonlight: 'XXXXXXX',
      PartlyCloudyWithShowers: 'XXXXXXX',
      PartlyCloudyWithTStorms: "XXXXXXX"
    }
  },
  WindDirection: {
    Labels: {
      N: 'XXX',
      NbE: 'XXX',
      NNE: 'XXX',
      NEbN: 'XXX',
      NE: 'XXX',
      NEbE: 'XXX',
      ENE: 'XXX',
      EbN: 'XXX',
      E: 'XXX',
      EbS: 'XXX',
      ESE: 'XXX',
      SEbE: 'XXX',
      SE: 'XXX',
      SEbS: 'XXX',
      SSE: 'XXX',
      SbE: 'XXX',
      S: 'XXX',
      SbW: 'XXX',
      SSW: 'XXX',
      SWbS: 'XXX',
      SW: 'XXX',
      SWbW: 'XXX',
      WSW: 'XXX',
      WbS: 'XXX',
      W: 'XXX',
      WbN: 'XXX',
      WNW: 'XXX',
      NWbW: 'XXX',
      NW: 'XXX',
      NWbN: 'XXX',
      NNW: 'XXX',
      NbW: 'XXX'
    }
  },
  FileExportFields: {
    Labels: {
      CargoIn: "XXXXXXX",
      CargoOut: 'XXXXXXX',
      PortCall: "XXXXXXX",
      Vessel: "XXXXXXX",
      Agent: "XXXXXXX",
      Arrival: "XXXXXXX",
      Departure: "XXXXXXX",
      VesselName: "XXXXXXX",
      PortCallReferenceID: "XXXXXXX",
      PortCallPortOfLoading: "XXXXXXX",
      PortCallLastPort: "XXXXXXX",
      PortCallNextPort: "XXXXXXX",
      PortCallPortOfDischarge: "XXXXXXX",
      PortCallCategory: "XXXXXXX",
      PortCallStatus: "XXXXXXX",
      PortCallCargo: "XXXXXXX",
      PortCallCargoIn: "XXXXXXX",
      PortCallCargoOut: "XXXXXXX",
      PortCallRemarks: "XXXXXXX",
      PortCallTags: "XXXXXXX",
      PortCallPort: "XXXXXXX",
      ArrivalTimeRequested: "XXXXXXX",
      ArrivalTimePlanned: "XXXXXXX",
      ArrivalTimeEstimated: "XXXXXXX",
      ArrivalTimeReady: "XXXXXXX",
      ArrivalTimeActual: "XXXXXXX",
      ArrivalPilotBoarding: "XXXXXXX",
      ArrivalLocation: "XXXXXXX",
      ArrivalPilots: "XXXXXXX",
      ArrivalLinesmen: "XXXXXXX",
      ArrivalMooringVessels: "XXXXXXX",
      ArrivalMastersName: "XXXXXXX",
      ArrivalCrew: "XXXXXXX",
      ArrivalPassengers: "XXXXXXX",
      ArrivalForwardDraught: "XXXXXXX",
      ArrivalAftDraught: "XXXXXXX",
      ArrivalSailingDraught: "XXXXXXX",
      ArrivalAirDraught: "XXXXXXX",
      DepartureTimeRequested: "XXXXXXX",
      DepartureTimePlanned: "XXXXXXX",
      DepartureTimeReady: "XXXXXXX",
      DepartureTimeActual: "XXXXXXX",
      DeparturePilotBoarding: "XXXXXXX",
      DepartureLocation: "XXXXXXX",
      DeparturePilots: "XXXXXXX",
      DepartureLinesmen: "XXXXXXX",
      DepartureMooringVessels: "XXXXXXX",
      DepartureMastersName: "XXXXXXX",
      DepartureCrew: "XXXXXXX",
      DeparturePassengers: "XXXXXXX",
      DepartureForwardDraught: "XXXXXXX",
      DepartureAftDraught: "XXXXXXX",
      DepartureSailingDraught: "XXXXXXX",
      DepartureAirDraught: "XXXXXXX"
    }
  },
  Snackbar: {
    Labels: {
      Syncing: "XXX",
      UpdatedBy: "XXX {{userId}} XXX {{time}}",
      ArrivalScheduled : "XXX",
      ArrivalPlanned : "XXX",
      ArrivalRequested: "XXX",
      ArrivalStarted: "XXX",
      ArrivalCompleted: "XXX",
      ArrivalCancelled: "XXX",
      ArrivalApproved: "XXX",
      ArrivalDeclined: "XXX",
      DepartureScheduled: "XXX",
      DeparturePlanned : "XXX",
      DepartureRequested: "XXX",
      DepartureStarted: "XXX",
      DepartureCompleted: "XXX",
      DepartureCancelled: "XXX",
      DepartureApproved: "XXX",
      DepartureDeclined: "XXX",
      ShiftArrivalScheduled: "XXX",
      ShiftArrivalPlanned : "XXX",
      ShiftArrivalStarted: "XXX",
      ShiftArrivalCompleted: "XXX",
      ShiftArrivalCancelled: "XXX",
      ShiftArrivalApproved: "XXX",
      ShiftArrivalDeclined: "XXX",
      ShiftDepartureScheduled: "XXX",
      ShiftDeparturePlanned : "XXX",
      ShiftDepartureStarted: "XXX",
      ShiftDepartureCompleted: "XXX",
      ShiftDepartureCancelled: "XXX",
      ShiftDepartureApproved: "XXX",
      ShiftDepartureDeclined: "XXX"
    }
  },
  AIS: {
    Labels: {
      LastMessageTime: "XXX",
      ReceiverName: "XXX",
      InvalidAISData: "XXX",
      Vessels: "XXX",
      AISReceivers: "XXX",
      Status: "Status",
      ThresholdSettings: "XXXXXXX",
      WarningThreshold: "XXXXXXX",
      WarningThresholdHelp: "XXXXXXX",
      CriticalThreshold: "XXXXXXX",
      CriticalThresholdHelp: "XXXXXXX",
      CriticalThresholdError: "XXXXXXX"
    }
  },
  CheckboxTree: {
    Labels: {
      SelectAll: "XXXXXXX",
      DeselectAll: "XXXXXXX",
      ExpandAll: "XXXXXXX",
      CollapseAll: "XXXXXXX"
    }
  },
  MapLayers: {
    Labels: {
      Vessels: "XXXXXXX",
      AISReceivers: "XXXXXXX",
      Zones: "XXXXXXX",
      MarlinGIS: "XXXXXXX",
      EncAidToNav: "XXXXXXX",
      EncDangers: "XXXXXXX",
      EncRestrictions: "XXXXXXX",
      EncCables: "XXXXXXX",
      EncPipelines: "XXXXXXX",
      EncPlatforms: "XXXXXXX",
      EncAnchors: "XXXXXXX",
      EncHydrography: "XXXXXXX",
      EncSoundings: "XXXXXXX",
      EncAllSoundings: "XXXXXXX",
      EncTopography: "XXXXXXX",
      EncCoverages: "XXXXXXX",
      EncBaseLabel: "XXXXXXX",
      EncAidsToNavigationLabel: "XXXXXXX",
      EncHydrographyLabel: "XXXXXXX",
      EncRestrictionsLabel: "XXXXXXX",
      EncTopographyLabel: "XXXXXXX",
      EncMetadata: "XXXXXXX",
      EncBaseWater: "XXXXXXX",
      EncBaseLand: "XXXXXXX",
      EncBase: "XXXXXXX"
    }
  },
  ExternalNotification: {
    Txt: {
      Notification: "XXXXXXX",
      PortCallSummary: "XXXXXXX",
      Actual: "XXXXXXX",
      Requested: "XXXXXXX",
      Planned: "XXXXXXX",
      Schedule: "XXXXXXX",
      To: "XXXXXXX",
      From: "XXXXXXX",
      At: "XXXX",
      Local: "XXXXXXX",
      UTC: "XXX",
      Day: "XXX",
      Days: "XXX",
      Hour: "XXX",
      Hours: "XXX",
      Minute: "XXX",
      Minutes: "XXX",
      LocationNotSpecified: "XXXXXXX",
      ArrivalCreated: "XXXXXXX",
      ArrivalStarted: "XXXXXXX",
      ArrivalCompleted: "XXXXXXX",
      ArrivalPendingApproval: "XXXXXXX",
      ArrivalPendingFurtherApproval: "XXXXXXX",
      ArrivalApproved: "XXXXXXX",
      ArrivalDeclined: "XXXXXXX",
      ArrivalRequested: "XXXXXXX",
      ArrivalBefore: "XXXXXXX",
      ArrivalAfter: "XXXXXXX",
      DepartureCreated: "XXXXXXX",
      DepartureStarted: "XXXXXXX",
      DepartureCompleted: "XXXXXXX",
      DeparturePendingApproval: "XXXXXXX",
      DeparturePendingFurtherApproval:  "XXXXXXX",
      DepartureApproved: "XXXXXXX",
      DepartureDeclined: "XXXXXXX",
      DepartureCancelled: "XXXXXXX",
      DepartureRequested: "XXXXXXX",
      DepartureBefore: "XXXXXXX",
      DepartureAfter: "XXXXXXX",
      ShiftArrivalCreated: "XXXXXXX",
      ShiftArrivalStarted: "XXXXXXX",
      ShiftArrivalCompleted: "XXXXXXX",
      ShiftArrivalPendingApproval: "XXXXXXX",
      ShiftArrivalApproved: "XXXXXXX",
      ShiftArrivalDeclined: "XXXXXXX",
      ShiftArrivalBefore: "XXXXXXX",
      ShiftArrivalAfter: "XXXXXXX",
      ShiftDepartureCreated: "XXXXXXX",
      ShiftDepartureStarted: "XXXXXXX",
      ShiftDepartureCompleted: "XXXXXXX",
      ShiftDeparturePendingApproval: "XXXXXXX",
      ShiftDepartureApproved: "XXXXXXX",
      ShiftDepartureDeclined: "XXXXXXX",
      ShiftDepartureBefore: "XXXXXXX",
      ShiftDepartureAfter: "XXXXXXX",
      Custom: "XXXXXXX",
      CustomCreated: "{{name}} XXXXXXX",
      CustomStarted: "{{name}} XXXXXXX", 
      CustomCompleted: "{{name}} XXXXXXX",
      CustomCancelled: "{{name}} XXXXXXX", 
      CustomBefore: "{{name}} XXXXXXX",
      CustomAfter: "{{name}} XXXXXXX",
      Regards: "XXXXXXX",
      PortControl: "XXXXXXX",
      FooterMsg: "XXXXXXX",
      Link: 'XXXXXX'
    }
  },
  CargoType: {
    Labels: {
      Name: "XXXXXXX",
      Unit: "XXXXXXX",
      Metre: "XXXXXXX",
      Tonne: "XXXXXXX",
      MetersCubed: "XXXXXXX",
      Each: "XXXXXXX",
      DuplicateName: "XXXXXXX",
    }
  },
  CertificateType: {
    Buttons: {
      AddCertificate: "XXXXXXX"
    },
    Labels: {
      Name: "XXXXXXX",
      Description: "XXXXXXX",
      NoCertificates: "XXXXXXX",
      ExpiredHelp: "XXXXXXX",
      InvalidHelp: "XXXXXXX",
      HelperMessage: "XXXXXXX {{category}}",
      CertificateType: "XXXXXXX",
      CertificateCategory: "XXXXXXX",
      CertificateTypeLowerCase: "XXXXXXX",
      CertificateCategoryLowerCase: "XXXXXXX",
      Notes: "XXXXXXX",
      ValidFrom: "XXXXXXX",
      ValidTo: "XXXXXXX",
      To: 'XXXXXXX',
      More: '(+{{more}} XXXXXXX)',
      ReferenceNumber: "XXXXXXX",
      ReferenceNumberShort: "XXXXXXX",
      NameOfHolder: "XXXXXXX",
      DeleteCertificateTitle: "XXXXXXX : {{name}}",
      DeleteCertificateMessage: "XXXXXXX {{name}}?",
      TypeNotSpecified: "XXXXXXX",
      Certificates: "XXXXXXX",
      Valid: "XXXXXXX",
      Invalid: "XXXXXXX",
    }
  },
  VesselList: {
    Labels: {
      ShowVesselDetails: "XXXXXXX",
      NoMoreData: "XXXXXXX",
      GrossTonnage: "XXXXXXX",
      NetTonnage: "XXXXXXX",
      DeadweightTonnage: "XXXXXXX",
      PortOfRegistry: "XXXXXXX",
      Type: "XXXXXXX",
      Certificates: "XXXXXXX",
      VesselDesc: "XXXXXXX",
      ImoDesc: "XXXXXXX",
      MmsiDesc: "XXXXXXX",
      CallSignDesc: "XXXXXXX",
      DimensionsDesc: "XXXXXXX",
      GrossTonnageDesc: "XXXXXXX",
      NetTonnageDesc: "XXXXXXX",
      DeadweightTonnageDesc: "XXXXXXX",
      PortOfRegistryDesc: "XXXXXXX",
      YearBuiltDesc: "XXXXXXX",
      TypeDesc: "XXXXXXX",
      FlagDesc: "XXXXXXX",
      CertificatesDesc: "XXXXXXX"
    }
  },
  VoyageBrowser: {
    Labels: {
      ArrivalDetails: 'XXXXXXX',
      ArrivalDetailsDesc: 'XXXXXXX',
      DepartureDetails: 'XXXXXXX',
      DepartureDetailsDesc: 'XXXXXXX',
      DepartureNotScheduled: 'XXXXXXX',
      ArrivalNotScheduled: 'XXXXXXX',
      LocationNotAllocated: 'XXXXXXX',
      Status: 'XXXXXXX',
      StatusDesc: 'XXXXXXX'
    }
  },
  VoyageMenu: {
    Labels: {
      Voyage: 'XXXXXXX',
      ShowDetails: 'XXXXXXX',
      DeleteVoyage: 'XXXXXXX',
      DeleteVoyageDialogTitle: 'XXXXXXX: {{voyageName}}',
      DeleteVoyageDialogMessage: 'XXXXXXX {{voyageName}} XXXXXXX',
      CancelVoyage: 'XXXXXXX',
      CancelVoyageDialogTitle: 'XXXXXXX: {{voyageName}}',
      CancelVoyageDialogMessage: 'XXXXXXX {{voyageName}} XXXXXXX',
    }
  },
  VoyageTimeline:{
    Labels:{
      BookendTitle: "XXXXXXX {{movementType}}",
      AsPlanned: 'XXXXXXX',
      Delayed: 'XXXXXXX',
      InProgress: 'XXXXXXX',
      Cancelled: 'XXXXXXX',
      Planned: 'XXXXXXX',
      Incomplete: 'XXXXXXX',
      Completed: 'XXXXXXX',
      Aborted: 'XXXXXXX'
    }
  },
  Invoice: {
    Labels: {
      Invoice: 'XXXXXXX',
      PortCallId: 'XXXXXXX',
      Schedule: 'XXXXXXX',
      Location: 'XXXXXXX',
      Arrival: 'XXXXXXX',
      Departure: 'XXXXXXX',
      VesselDetails: 'XXXXXXX',
      ScheduleSummary: 'XXXXXXX',
      Name: 'XXXXXXX',
      ListOfCharges: 'XXXXXXX',
      NoItems: 'XXXXXXX',
      Id: 'XXXXXXX',
      Tarrif: 'XXXXXXX',
      Quantity: 'XXXXXXX',
      UnitCost: 'XXXXXXX',  
      Total: 'XXXXXXX',
      GrossTotal: 'XXXXXXX'
    }
  }  
}