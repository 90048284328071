import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Menu, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DotsVertical } from 'mdi-material-ui';
import { ActionStateLabelKeys } from '../../constants/ActionStateLabel';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import TimeInputDialog from '../Dialog/TimeInputDialog';
import TextInputDialog from '../Dialog/TextInputDialog';
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import { getNextUndoableActions, getActionName, getActionPrev, isAgentAssociatedWithPortCall } from '../../utils/getters';
import { Action, ActionMovementType, ActionState, Cargo, PortCall } from '../../models';
import { ActionTypeIds } from '../../environment';
import { completePortCall, updateActionState } from './utils';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { UIContext } from '../../contexts/ui';
import useAuditMeta from '../../hooks/useAuditMeta';
import useTransactionMutation from '../../hooks/useTransactionMutation';
import useApprovalSetting from '../../hooks/useApprovalSetting';
import { useTranslation } from 'react-i18next';
import LoadingDialog from '../Dialog/LoadingDialog';
import PortCallCompletionTimeInputDialog from '../Dialog/PortCallCompletionTimeInputDialog';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';
import InvoiceDialog from '../Invoice/InvoiceDialog'
import PortCallMenu from './TimelineMenu/PortCallMenu';
import MovementMenu from './TimelineMenu/MovementMenu';
import { archivePortCalls, getPortCall } from '../../graphql/queries';
import { buildPortCallMenuItems, buildMovementMenuItems } from './TimelineMenu/buildMenuItems';

const useStyles = makeStyles(theme => ({
  menuDivider: {
    marginTop: '0.5rem',

    marginBottom: '0.5rem'
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  }
}));
const initialUnArchivedState = { open: false, loading: false, error: null, title: null };

const PortCallTimelinePortCallMenu = ({
  contexts = ['portCall', 'details'],
  disabled,
  ButtonProps,
  relevantAction,
  portCall,
  skipCheck = false,
  archived = false,
  disabledCancel = false,
  disabledDelete = false,
  hideButton
}) => {
  const { actions } = portCall;
  const { t } = useTranslation();
  const [uiContext,] = useContext(UIContext);
  const isPortCallArchived = useMemo(() => (portCall && portCall.archived) || archived, [archived, portCall]);

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const [openConfirm, setOpenConfirm] = useState(false); // open={Boolean(confirmProps)} flickers on close
  const [openUnArchive, setOpenUnArchive] = useState(initialUnArchivedState);
  const [confirmProps, setConfirmProps] = useState(null);

  const [openTime, setOpenTime] = useState(false);
  const [timeProps, setTimeProps] = useState(null);
  const [openCompletePortCallTimes, setOpenCompletePortCallTimes] = useState(null);
  const [openText, setOpenText] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const auditMeta = useAuditMeta();

  const [updateMutation, resetTransaction, { loading, error }] = useTransactionMutation();
  const [shiftActions, setShiftActions] = useState([]);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const sactions = await DataStore.query(Action, a => a.actionPortCallId_('eq', portCall.id)
        .or(c => c
          .movementType('eq', ActionMovementType.SHIFT_ARRIVAL)
          .movementType('eq', ActionMovementType.SHIFT_DEPARTURE))
      );
      setShiftActions(isPortCallArchived ? [] : sactions);
    })();
  }, [portCall, isPortCallArchived]);

  const updateActionStateAsTransaction = async (update) => {
    await updateMutation(await updateActionState(update.action, update.state, update.comment, update.auditMeta, update.updates, update.ignorePortStatus), update.onSuccess);
  };

  const updatePortCallActionsAsTransaction = async (update) => {
    await updateMutation(completePortCall(update.portCall, update.actions, update.auditMeta, update.values, update.comments), update.onSuccess);
  };

  const nextUndoableActions = useMemo(() => (actions && getNextUndoableActions(actions)) || [], [actions]);
  const handleUndoAction = (a) => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.UndoActionDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.UndoActionDialogMessage', { vesselName: portCall.vesselData.name, actionName: undoableActionName, actionState: t(ActionStateLabelKeys[a.state]).toLowerCase() }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        // datastore refuses to update when given a nextUndoable action - querying fixes it
        // TODO tidy up ugly names
        const _a = await DataStore.query(Action, a.id);
        const newState = a.state === ActionState.COMPLETED ? ActionState.IN_PROGRESS : ActionState.PLANNED;
        let input = {};
        // unset timeActual when arrival changes to IN_PROGRESS or departure changes to PLANNED
        if (a.movementType) {
          if (
            (newState === ActionState.IN_PROGRESS && [ActionMovementType.ARRIVAL, ActionMovementType.SHIFT_ARRIVAL].includes(a.movementType)) ||
            (newState === ActionState.PLANNED && [ActionMovementType.DEPARTURE, ActionMovementType.SHIFT_DEPARTURE].includes(a.movementType))
          ) input = { timeActual: null };
        } else {
          input = newState === ActionState.IN_PROGRESS ? {} : { timeActual: null };
        }
        await updateActionStateAsTransaction({
          action: _a,
          state: newState,
          comment: comment,
          auditMeta: auditMeta,
          updates: input,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  // The name of the next Undoable Action
  const undoableActionName = nextUndoableActions
    && nextUndoableActions.length > 0
    && getActionName(t, nextUndoableActions[0]);

  const handleClose = (e) => {
    setAnchorEl(null);
    e && e.preventDefault();
  }

  useEffect(() => {
    // Only set the error text if an error is still active
    setErrorMessage(error && error.statusCode !== 200 ? t('PortCallTimelineMenu.Errors.PortCallUpdateFailed') : undefined);
  }, [t, error]);

  const unarchivePortCall = async ({ portCallId, onSuccess, onError, onAppSyncError }) => {
    const delay = (seconds) => { return new Promise(resolve => setTimeout(resolve, seconds)); }
    try {
      const result = await API.graphql(graphqlOperation(archivePortCalls, { dateFrom: null, dateTo: null, portCallId: portCallId, unarchive: true }));
      if (result?.data?.archivePortCalls?.success) {
        const unarchivedPortCall = await API.graphql(graphqlOperation(getPortCall, { id: portCallId }));
        //check data store until the unarchived portcall appeared, check maximum of 5 times, if the data store still not have all information then return unsuccessful 
        const queryDataStoreLimit = 5;
        let isPortCallInDataStore = false;
        for (let i = 0; i < queryDataStoreLimit; i++) {
          const portCallDataStore = portCallId && (await DataStore.query(PortCall, c => c.id('eq', portCallId)))?.pop();
          const actionsDataStore = portCallId && await DataStore.query(Action, c => c.actionPortCallId_('eq', portCallId));
          const cargosDataStore = portCallId && await DataStore.query(Cargo, c => c.cargoPortCallId('eq', portCallId));
          //check if the unarchived portcall in data store , check portcallIds, check actionsIds, check cargosIds
          isPortCallInDataStore = portCallDataStore?.id === portCallId &&
            actionsDataStore?.every((action) => unarchivedPortCall?.data?.getPortCall?.actions?.items?.find((a) => a.id === action.id))
            && cargosDataStore?.every((cargo) => unarchivedPortCall?.data?.getPortCall?.cargos?.items?.find((c) => c.id === cargo.id))
          if (isPortCallInDataStore) break;
          await delay(1500);
        }
        const callbackFn = isPortCallInDataStore ? onSuccess : onError;
        callbackFn && typeof callbackFn === 'function' && callbackFn();
      }
    } catch (error) {
      onAppSyncError && typeof onAppSyncError === 'function' && onAppSyncError(t('PortCallDetails.Errors.AppSyncError', { error: error.toString() }));
    }

  }

  const { actionTypes } = useContext(DataStoreContext);
  const { appApprovalEnabled } = useApprovalSetting();
  const isApprover = uiContext.isApprover;
  const userContactId = uiContext.userContactId;
  const action = useMemo(() => relevantAction || (nextUndoableActions.length && nextUndoableActions[0]), [relevantAction, nextUndoableActions]);
  const prevAction = useMemo(() => (action && actions && getActionPrev(actions, action)) || [], [action, actions]);
  const customActionTypes = useMemo(() => actionTypes.filter(at => at.id !== ActionTypeIds.MOVEMENT && at.id !== ActionTypeIds.EVENT), [actionTypes]);
  const agentPortal = uiContext.agentPortal;
  const agentPortCall = !agentPortal || (agentPortal && isAgentAssociatedWithPortCall(portCall, userContactId));

  const movementItems = useMemo(() => {
    return buildMovementMenuItems(
      portCall,
      action,
      prevAction,
      contexts,
      agentPortal,
      skipCheck,
      anchorEl,
      nextUndoableActions,
      archived,
      customActionTypes,
      appApprovalEnabled,
      isApprover,
      agentPortCall
    );
  }, [action, archived, agentPortCall, agentPortal, anchorEl, appApprovalEnabled, contexts, customActionTypes, isApprover, nextUndoableActions, portCall, prevAction, skipCheck]);

  const portCallItems = useMemo(() => {
    return buildPortCallMenuItems(
      portCall,
      contexts,
      agentPortal,
      skipCheck,
      anchorEl,
      nextUndoableActions,
      isPortCallArchived,
      disabledCancel,
      disabledDelete
    );
  }, [anchorEl, contexts, agentPortal, isPortCallArchived, nextUndoableActions, portCall, skipCheck, disabledDelete, disabledCancel]);

  const noOptions = useMemo(() => {
    const pcItems = portCallItems.portCall && Object.keys(portCallItems?.portCall).length;
    const mItems = movementItems.movement && Object.keys(movementItems?.movement).length;
    // console.log('++ noOptions', portCallItems, movementItems, pcItems, mItems);
    return !pcItems && !mItems;
  }, [portCallItems, movementItems]);

  return (
    <>
      {!hideButton &&
        <IconButton
          {...ButtonProps}
          disabled={!skipCheck && (disabled || noOptions)}
          onClick={e => { setAnchorEl(e.currentTarget); e.preventDefault(); ButtonProps && ButtonProps.onClick && ButtonProps.onClick(e); }}
          style={{ ...ButtonProps && ButtonProps.style, pointerEvents: !skipCheck && (disabled || noOptions) ? 'none' : ButtonProps && ButtonProps.style && ButtonProps.style.pointerEvents }}
          id={`PortCallTimelinePortCallMenuButton`}
        >
          <DotsVertical />
        </IconButton>
      }
      {anchorEl && <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div>
            <MovementMenu 
              disabledCancel={disabledCancel}
              disabledDelete={disabledDelete}
              archived={archived}
              portCall={portCall}
              skipCheck={skipCheck}
              anchorEl={anchorEl}
              contexts={contexts}
              relevantAction={relevantAction}
              nextUndoableActions={nextUndoableActions}
              handleUndoAction={handleUndoAction}
              updateActionStateAsTransaction={updateActionStateAsTransaction}
              handleClose={handleClose}
              setOpenText={setOpenText}
              setConfirmProps={setConfirmProps}
              setTimeProps={setTimeProps}
              setOpenTime={setOpenTime}
              setOpenConfirm={setOpenConfirm}
              setOpenUnArchive={setOpenUnArchive}
              classes={classes}
              unarchivePortCall={unarchivePortCall}
            />

            <PortCallMenu 
              disabledCancel={disabledCancel}
              disabledDelete={disabledDelete}
              archived={archived}
              portCall={portCall}
              actions={actions}
              skipCheck={skipCheck}
              anchorEl={anchorEl}
              contexts={contexts}
              undoableActionName={undoableActionName}
              setOpenUnArchive={setOpenUnArchive}
              handleUndoAction={handleUndoAction}
              nextUndoableActions={nextUndoableActions}
              handleClose={handleClose}
              shiftActions={shiftActions}
              setOpenText={setOpenText}
              setConfirmProps={setConfirmProps}
              setTimeProps={setTimeProps}
              setOpenCompletePortCallTimes={setOpenCompletePortCallTimes}
              setOpenConfirm={setOpenConfirm}
              setMapDialogOpen={setMapDialogOpen}
              setInvoiceDialogOpen={setInvoiceDialogOpen}
              classes={classes}
              unarchivePortCall={unarchivePortCall}
              updatePortCallActionsAsTransaction={updatePortCallActionsAsTransaction}
            />
        </div>
      </Menu>}
      <ConfirmDialog
        {...confirmProps}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />
      {openUnArchive?.open &&
        <LoadingDialog
          modal={openUnArchive?.loading}
          loading={openUnArchive?.loading}
          title={openUnArchive?.title}
          error={{ errorMessage: openUnArchive?.error?.message, errorCode: openUnArchive?.error?.errorCode, errorDetails: openUnArchive?.error?.errorDetails }}
          onClose={() => {
            setOpenUnArchive(initialUnArchivedState)
          }}
          open={openUnArchive?.open}
        />}
      {openTime &&
        <TimeInputDialog
          {...timeProps}
          modal={loading}
          loading={loading}
          error={{ message: errorMessage, errorCode: error?.errorCode }}
          onClose={() => {
            setOpenTime(false);
            resetTransaction();
          }}
          open={openTime}
        />}
      {openText &&
        <TextInputDialog
          {...confirmProps}
          modal={loading}
          loading={loading}
          error={{ message: errorMessage, errorCode: error?.errorCode }}
          onClose={() => {
            setOpenText(false);
            resetTransaction();
          }}
          open={openText}
        />}
      {openCompletePortCallTimes &&
        <PortCallCompletionTimeInputDialog
          {...timeProps}
          modal={loading}
          loading={loading}
          error={{ message: errorMessage, errorCode: error?.errorCode }}
          onClose={() => {
            setOpenCompletePortCallTimes(false);
            resetTransaction();
          }}
          open={openCompletePortCallTimes}
        />}
      {mapDialogOpen &&
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={portCall.vesselData}
        />
      }
      {invoiceDialogOpen &&
        <InvoiceDialog
          open={invoiceDialogOpen}
          onClose={() => setInvoiceDialogOpen(false)}
          portCall={portCall}
        />
      }
    </>
  );
}
export default PortCallTimelinePortCallMenu;
