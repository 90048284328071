import React from 'react';
import { ListItem, ListItemText, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default ({ contact }) => {
  const { t } = useTranslation();

  return(
    <Grid container spacing={0}>
    <Grid item>
      {contact.reference &&
        <ListItem>
          <ListItemText
            primary={contact.reference}
            secondary={t('Contact.Labels.Reference')}
          />
        </ListItem>
      }
      {contact.number &&
        <ListItem>
          <ListItemText
            primary={contact.number}
            secondary={t('Contact.Labels.TelephoneNumber')}
          />
        </ListItem>
      }
      {contact.contacta_name &&
        <ListItem>
          <ListItemText
            primary={contact.contacta_name}
            secondary={t('Contact.Labels.ContactAName')}
          />
        </ListItem>
      }    
      {contact.email &&
        <ListItem>
          <ListItemText
            primary={contact.email}
            secondary={t('Contact.Labels.Email')}
          />
        </ListItem>
      }
      {contact.fax &&
        <ListItem>
          <ListItemText
            primary={contact.fax}
            secondary={t('Contact.Labels.Fax')}
          />
        </ListItem>
      }
      {contact.address1 &&
        <ListItem>
          <ListItemText
            primary={contact.address1}
            secondary={t('Contact.Labels.Address1')}
          />
        </ListItem>
      } 
      {contact.address2 &&
        <ListItem>
          <ListItemText
            primary={contact.address2}
            secondary={t('Contact.Labels.Address2')}
          />
        </ListItem>
      } 
    </Grid>
    <Grid item>    
      {contact.town &&
        <ListItem>
          <ListItemText
            primary={contact.town}
            secondary={t('Contact.Labels.Town')}
          />
        </ListItem>
      } 
      {contact.county &&
        <ListItem>
          <ListItemText
            primary={contact.county}
            secondary={t('Contact.Labels.County')}
          />
        </ListItem>
      }    
      {contact.postcode &&
        <ListItem>
          <ListItemText
            primary={contact.postcode}
            secondary={t('Contact.Labels.Postcode')}
          />
        </ListItem>
      }
    </Grid>
    </Grid>
  );
}