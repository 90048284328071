import React from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import { cargoTypeFilterOptions, sortContacts } from '../../utils/sorters';
import { CargoType } from '../../models';
import AgentListItem from '../Agent/AgentListItem';
import useQuery from '../../hooks/useQuery';

const CargoTypeAutocomplete = ({ value, onChange, label, ...other }) => {
  const cargoTypes = useQuery(CargoType, { sort: sortContacts });
  const filteredCargoTypes = cargoTypes.filter(c => c.deleted !== true)

  return (
    <Autocomplete
      {...other}
      className="CargoTypeAutocomplete"
      autoHighlight
      options={filteredCargoTypes}
      filterOptions={cargoTypeFilterOptions}
      getOptionLabel={(item) => (item && item.name)}
      getOptionSelected={(option, value) => option.id === value.id}
      value={value || null}
      disableClearable
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <AgentListItem agent={item} />
      )}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
};

export default CargoTypeAutocomplete;