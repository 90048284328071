import React, {
  useRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Storage } from 'aws-amplify'; 
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { format } from 'date-fns';
import { 
  documentMethods, 
  documentStatusArray,
  errors,
} from '../../constants/Documents';
import { 
  byteToString,
  truncateFileName,
} from '../../utils/Documents';
import DocumentList from './DocumentList';
import ConfirmDialog from "../Dialog/ConfirmDialog";
import { UIContext } from '../../contexts/ui';
import { useTranslation } from 'react-i18next';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import '../../translations/i18n';
import useAuditMeta from '../../hooks/useAuditMeta';
import { DateFnsLanguageMap } from '../../translations';
import { DataStore } from 'aws-amplify';
import { AuditLog, AuditLogType } from '../../models';

const {
  ADD, 
  REMOVE,
  DUPLICATE
} = documentMethods;

const {
  NO_FILE,
  FILE_NAME, 
  PROGRESS_BAR, 
  COMPLETE,
  DOWNLOAD,
  DELETE,
} = documentStatusArray;

const {
  UPLOADING,
  DELETING,
  DOWNLOADING,
  ATTACHMENT,
} = errors;

/** Maps document change method used in this component to AuditLogType */
const MethodToAuditLogTypeMap = {
  [ADD]: AuditLogType.AUDIT_FILE_CREATE,
  [REMOVE]: AuditLogType.AUDIT_FILE_DELETE,
  [DUPLICATE]: AuditLogType.AUDIT_FILE_UPDATE
};

const useStyles = makeStyles(theme => ({
  documentUpload: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0.25rem",
    flexFlow: "column",
    textAlign: "center",
  },
  parent: {
    display: "flex",
    paddingTop: "0.25rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: "1rem"
  },
  flexChild2: {
    alignSelf: "flex-end",
  },
}));

//Need to provide the following to this component:
//The id for the side bar link to go to
//A file path for the file to be stored
//objID and the documents array
const DocumentEdit = ({
  filePath,
  portCallId,
  objId,
  objDocuments,
  onChange,
  disableDownload,
  disableDelete,
  checkSaveState,
  saveDisabled,
  hideEmptyMessage = false
}) => {
  const { userId, source } = useAuditMeta();
  const [uiContext] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const timeoutFileStatus = useRef(null);
  const timeForStatusReset = 5000; //milliseconds
  const maxFileSize = 10482180.2935; //bytes
  const TIME_LIMIT = 3600; // 1 hour later download link will expire

  const fileInputRef = useRef()
  const [fileStatus, setStatus] = useState(NO_FILE);
  const [file, setFile] = useState();
  const [duplicate, setDuplicate] = useState({status: false})
  const [progress, setProgress] = useState(0);
  const [parsedDocs, setParsedDocs] = useState([]);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmProps, setConfirmProps] = useState(null);
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();

  //Confirm dialog for if user is uploading an attachment with the same name
  const handleDuplicateCheck = () => {
    setConfirmProps({
      title: t('DocumentEdit.Errors.DuplicateTitle', {doc: file.name}),
      message: t('DocumentEdit.Errors.DuplicateMessage', {doc: file.name}),
      onConfirm: () => {
        onSubmit()
        setOpenConfirm(false);
      }
    });
    setOpenConfirm(true);
  }

  const handleFileSizeCheck = () => {
    setConfirmProps({
      title: t('DocumentEdit.Errors.TooLargeTitle'),
      message: t('DocumentEdit.Errors.TooLargeMessage', {doc: file.name, limit: byteToString(maxFileSize)}),
    })
    setOpenConfirm(true);
  }

  const handleError = (process, error) => {
    const document = !file ? ATTACHMENT : truncateFileName(file.name, 40);
    let title, message;
    switch(process) {
      case DOWNLOADING:
        title = 'DocumentEdit.Errors.FailedDownloadTitle';
        message = 'DocumentEdit.Errors.FailedDownloadMessage';
        break; 
      case UPLOADING:
        title = 'DocumentEdit.Errors.FailedUploadTitle';
        message = 'DocumentEdit.Errors.FailedUploadMessage';
        break;
      case DELETING:
        title = 'DocumentEdit.Errors.FailedDeleteTitle';
        message = 'DocumentEdit.Errors.FailedDeleteMessage';
        break;
      default:
        title = 'DocumentEdit.Errors.FailedUnknownTitle';
        message = 'DocumentEdit.Errors.FailedUnknownMessage';
        break;
    }
    setConfirmProps({
      title: t(title, {doc: document}),
      message: t(message, {error: error.message}),
      messageSecondary: t('DocumentEdit.Errors.Support')
    })
    setOpenConfirm(true);
  }

  //If file status changes will clear timeout function
  useEffect(() => {
    switch(fileStatus.key) {
      case DELETE.key:
      case COMPLETE.key:
      case DOWNLOAD.key:
        timeoutFileStatus.current = setTimeout(() => setStatus(NO_FILE), timeForStatusReset);
        break;
      default:
        clearTimeout(timeoutFileStatus.current);
    }
    return () => clearTimeout(timeoutFileStatus.current);
  }, [fileStatus])
  
  //Use effect to parse the document data being given
  //JSON string of document information to Array of documents
  useEffect(() => {
    let Documents = [];
    objDocuments.forEach(doc => {
      const {key, name, type, size, upload, user } = JSON.parse(doc);
      const stringDate = format(new Date(upload), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] });
      const stringSize = byteToString(Number(size))
      const stringName = truncateFileName(name, 45);
      Documents = [
        ...Documents,
        {key, name, stringName, type, size: stringSize, upload: stringDate, user }
      ]
    })
    setParsedDocs(Documents);
  }, [objDocuments, dateTimeFormat, i18n])

  //Use effect for when file variable is changed
  // Determines if it is a duplicate and will handle that case
  useEffect(() => {
    if(!file || file === "") return;
    if(file.size > maxFileSize) {
      handleFileSizeCheck();
      return;
    }
    setStatus(FILE_NAME);
    const isDuplicate = duplicateDocumentNameCheck(file.name);
    if(isDuplicate.status === true) {
      setDuplicate(isDuplicate);
    } else {
      onSubmit();
    }
  }, [file])

  //Use effect to deal with the flow for duplicates
  useEffect(() => {
    if(!file || file === "" || duplicate.status === false) return;
    handleDuplicateCheck()
  }, [duplicate])

  //Main function for handling the documents
  //Performing the mutate function
  const updateDocumentsArray = async ({key: document}, payload) => {
    onChange(await documentArray(document, payload));
  }

  // Creates and populates AuditLog object
  const saveAuditLog = async (method, fields) => {
    if (MethodToAuditLogTypeMap[method]) {
      await DataStore.save(new AuditLog({
        type: MethodToAuditLogTypeMap[method],
        portCallId,
        objectId: objId,
        userId,
        source,
        ...fields
      }));
    }
  };

  //Change the document array depending on Adding, Removing, Duplication
  const documentArray = async (document, {method, changeIndex = -1}) => {
    let documentDetails;
    const upload = new Date().toISOString();
    if(method === ADD || method === DUPLICATE) {
      const { size, type, name, } = file;
      documentDetails = JSON.stringify({
        key: document,
        name,
        type,
        size,
        upload,
        user: userId
      });
    }
    switch (method) {
      case ADD: {
        await saveAuditLog(method, {
          timeChanged: upload,
          data: documentDetails,
          new: documentDetails
        });
        return [...objDocuments, documentDetails];
      }
      case REMOVE: {
        await saveAuditLog(method, {
          timeChanged: upload,
          data: objDocuments[changeIndex],
          old: objDocuments[changeIndex]
        });
        return objDocuments.filter((_ , index) => index !== changeIndex);
      }
      case DUPLICATE: {
        const prev = JSON.parse(objDocuments[changeIndex]);
        const next = JSON.parse(documentDetails);
        await saveAuditLog(method, {
          timeChanged: upload,
          data: documentDetails,
          old: JSON.stringify({ upload: prev.upload, key: prev.key, size: prev.size !== next.size ? prev.size : undefined, user: prev.user !== next.user ? prev.user : undefined }),
          new: JSON.stringify({ upload: next.upload, key: next.key, size: prev.size !== next.size ? next.size : undefined, user: prev.user !== next.user ? next.user : undefined })
        });
        return [...objDocuments.filter((_ , index) => index !== changeIndex), documentDetails];
      }
      default:
        return objDocuments;
    }
  }

  //Clear the file input
  const clearFileInput = () => {
    setFile("");
    document.getElementById("raised-button-file").value="";
  }

  //Method to reset the component
  const reset = () => {
    setDuplicate({status:false})
    setProgress(0);
    clearFileInput();
  }

  const getDocument = async(key) => {
    Storage.get(key, {expires: TIME_LIMIT})
    .then(file => {
      window.open(file, "_blank");
      setStatus(DOWNLOAD);
    })
    .catch(err => handleError(DOWNLOADING, err))
  }

  const deleteDocument = (key, index) => {
    Storage.remove(key)
    .then(() => {
      updateDocumentsArray({key}, {method: REMOVE, changeIndex: index})
      setStatus(DELETE);
    })
    .catch(err => handleError(DELETING, err));
  }

  const documentUploadComplete = (result) => {
    if(duplicate.status === true) {
      updateDocumentsArray(result, {method: DUPLICATE, changeIndex: duplicate.foundIndex})
    } else {
      updateDocumentsArray(result, {method: ADD})
    }
    setStatus(COMPLETE)
    reset();
  }

  const classes = useStyles();

  const onSubmit = () => {
    setStatus(PROGRESS_BAR);
    const documentKey = `${filePath}${file.name}`;
    Storage.put(documentKey, file, {
      progressCallback(progress) {
        setProgress((progress.loaded/progress.total)*100);
      }
    })
    .then (result => documentUploadComplete(result)) 
    .catch(err => handleError(UPLOADING, err));
  }

  const duplicateDocumentNameCheck = (docName) => {
    const foundIndex = parsedDocs.findIndex(doc => doc.name === docName)
    if(foundIndex !== -1) {
      return {status:true, foundIndex};
    }
    return {status:false};
  }

  const onChangeInput = (e) => {
    const tempFile = e.target.files[0];
    if(!tempFile) return;
    setFile(tempFile);
  }

  const strOutput = (str, {variant="body1", color="primary"} = {}) => {
    return (
      <Typography variant={variant} color={color} >
        {str}
      </Typography>
    )
  }

  const progressBar = () => {
    if(fileStatus.key !== PROGRESS_BAR.key) return;
    return (
      <LinearProgress 
        variant="determinate"
        value={progress}
      />
    )
  }

  const fileName = () => {
      switch(fileStatus.key) {
        case PROGRESS_BAR.key:
        case FILE_NAME.key:
          return strOutput(file.name);
        default:
          return strOutput("");
      }
  }

  const fileLabel = () => {
    switch(fileStatus.key) {
      case NO_FILE.key:
        return '';
      case PROGRESS_BAR.key:
        return strOutput(t(PROGRESS_BAR.text))
      case FILE_NAME.key:
        return strOutput(t(FILE_NAME.text));
      case COMPLETE.key:
        return strOutput(t(COMPLETE.text))
      case DOWNLOAD.key:
        return strOutput(t(DOWNLOAD.text))
      case DELETE.key:
        return strOutput(t(DELETE.text))
      default: 
        return ''
    }
  }

  return (
    <>
      <DocumentList
        documents={parsedDocs}
        deleteDocument={deleteDocument}
        getDocument={getDocument}
        fileStatus={fileStatus}
        disableDownload={disableDownload}
        disableDelete={disableDelete}
        checkSaveState={checkSaveState}
        saveDisabled={saveDisabled}
        hideEmptyMessage={hideEmptyMessage}
      />
      {!readOnly &&
        <div className={classes.parent} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
          <div>
            { !!saveDisabled && 
              <input
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              ref={fileInputRef}
              onChange={onChangeInput}
              className="upload-document"
            />
            }
            <label htmlFor="raised-button-file">
              <Button 
                id="document-input-file"
                variant="outlined" 
                component="span" 
                color="primary"
                onClick={!saveDisabled ? checkSaveState : null}
              >
                <Typography>{t('DocumentEdit.Buttons.AddAttachment')}</Typography>
              </Button>
            </label>
          </div>
          <div style={{display: 'flex', alignItems: 'center', paddingLeft: '0.25rem'}}>
            {fileLabel()}
          </div>
        </div>
      }
      {[PROGRESS_BAR.key, FILE_NAME.key].includes(fileStatus.key) &&
        <div className={classes.documentUpload}>
          {fileName()}
        </div>
      }
      {fileStatus.key === PROGRESS_BAR.key &&
        <div style={{height: "1rem"}}>
          {progressBar()}
        </div>
      }
      
      <ConfirmDialog 
        {...confirmProps} 
        onClose={() => {
            setOpenConfirm(false);
            setStatus(NO_FILE);
            reset();
          }
        }
        open={openConfirm}
      />
    </>
  )
}

export default DocumentEdit;
