import { useMemo } from "react";
import { Box } from "@material-ui/core";
import VoyageBookend from "./VoyageBookend";
import VoyageTimeLineLocation from "./VoyageTimelineLocation";

const VoyageBookendCard = ({ stripColour, portCalls, firstMovement, voyageFirstDeparture, voyageLastArrival, isMiddelActions, locationMap, action, classes }) => {

  const actionLocation = useMemo(() => {
    return locationMap?.get(action?.actionMovementLocationId);
  }, [locationMap, action?.actionMovementLocationId]);

  const backgroundColor = { background: `${action?.id === voyageLastArrival?.id ? stripColour?.bottom : stripColour?.top}` };

  const classesStyle = useMemo(() => {
    if (voyageFirstDeparture?.id === action?.id) {
      if (isMiddelActions) {
        const nextActions = firstMovement?.actions?.length;
        return `${classes.stripe} ${classes.timelinePosition} ${nextActions > 1 ? classes.bookendToActionsStripe : classes.bookendToActionStripe }`
      } else {
        if (voyageLastArrival) {
          return `${classes.stripe} ${classes.timelinePosition} ${classes.bookendToBookendStripe}`
        } else {
          return `${classes.stripe} ${classes.timelinePosition} ${classes.noHeight}`
        }
      }
    } else if (voyageLastArrival?.id === action?.id) {
      if (isMiddelActions) {
        return `${classes.stripe} ${classes.timelineRevert}`
      } else {
        return `${classes.stripe} ${classes.noHeight} `
      }
    }
  }, [action?.id, classes, firstMovement?.actions?.length, isMiddelActions, voyageFirstDeparture?.id, voyageLastArrival]);
  
  const portCall = useMemo(()=>{
    return portCalls?.find((p)=> p?.portCall?.id === (action?.actionPortCallId || action?.portCall?.id))?.portCall;
  },[action?.actionPortCallId, action?.portCall?.id, portCalls]);

  return action ?
    <Box flexDirection="row" display="flex">
      <Box className={classes.locationContainer}>
        <VoyageTimeLineLocation location={actionLocation} locationMap={locationMap} />
      </Box>
      <Box className={classes.circleWrapper}>
        <div className={`${classes.circle} circle`} width='3rem' height='3rem' />
        <Box className={classesStyle} sx={backgroundColor} />
      </Box>
      <Box className={classes.timelineActionsContainer}>
        <Box className={classes.row}>
          <VoyageBookend
            portCall={portCall}
            disableGutters={true}
            isDelay={false}
            action={action}
          />
        </Box>
      </Box>
    </Box>
    : null;
}
export default VoyageBookendCard;