import React from "react";
import {
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import useImageUrl from "../../hooks/useImageUrl";
import { croppedImageDimensions } from "../../constants/Image";
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const {width: defaultwidth} = croppedImageDimensions;

const VesselImage = ({
  containerStyles,
  imageStyles,
  image,
}) => {
  const imgUrl = useImageUrl(image)
  const { t } = useTranslation();
  const useStyles = makeStyles(() => ({
    image: {
      maxWidth: "100%",
    },
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    container: {
     width: defaultwidth,
    }
  }))

  const classes = useStyles();

  const renderLoad = () => {
    return(
      <div className={classes.container} style={containerStyles}>
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      </div>
    )
  };

  const renderImage = () => {

    return(
      <div className={classes.container} style={containerStyles}>
          <img 
            src={imgUrl.result}  
            className={classes.image} 
            alt={t('VesselImage.Labels.Image')}
            style={imageStyles}
          >
          </img>
      </div>
    )
  };

  const render = () => {
    if(!image) return null;
    if(imgUrl.err) return null;
    if(imgUrl.result) return renderImage();
    return renderLoad();
  };

  return render()  
}

export default VesselImage;