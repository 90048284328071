/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const upsertVesselState = /* GraphQL */ `
  mutation UpsertVesselState($input: UpsertVesselStateInput!) {
    upsertVesselState(input: $input) {
      id
      name
      mmsi
      imo
      callSign
      navStatus
      shipType
      lat
      lon
      height
      depth
      speed
      cmg
      heading
      rot
      positionQuality
      positioningDevice
      eta
      destination
      dimForward
      dimAft
      dimPort
      dimStbd
      recordTimestamp
      positionTimestamp
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const executeTransaction = /* GraphQL */ `
  mutation ExecuteTransaction($request: TransactionRequest) {
    executeTransaction(request: $request) {
      statusCode
      errorCode
      errorMessage
      data
      __typename
    }
  }
`;
export const notifyUpdatePortCall = /* GraphQL */ `
  mutation NotifyUpdatePortCall($input: ID) {
    notifyUpdatePortCall(input: $input) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const notifyUpdateAction = /* GraphQL */ `
  mutation NotifyUpdateAction($input: ID) {
    notifyUpdateAction(input: $input) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const notifyUpdateCargo = /* GraphQL */ `
  mutation NotifyUpdateCargo($input: ID) {
    notifyUpdateCargo(input: $input) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const notifyUpdateRequest = /* GraphQL */ `
  mutation NotifyUpdateRequest($input: ID) {
    notifyUpdateRequest(input: $input) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const notifyUpdateVoyage = /* GraphQL */ `
  mutation NotifyUpdateVoyage($input: ID) {
    notifyUpdateVoyage(input: $input) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const notifyCreatePortCall = /* GraphQL */ `
  mutation NotifyCreatePortCall($input: ID) {
    notifyCreatePortCall(input: $input) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const notifyCreateAction = /* GraphQL */ `
  mutation NotifyCreateAction($input: ID) {
    notifyCreateAction(input: $input) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const notifyCreateCargo = /* GraphQL */ `
  mutation NotifyCreateCargo($input: ID) {
    notifyCreateCargo(input: $input) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const notifyCreateRequest = /* GraphQL */ `
  mutation NotifyCreateRequest($input: ID) {
    notifyCreateRequest(input: $input) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const notifyCreateVoyage = /* GraphQL */ `
  mutation NotifyCreateVoyage($input: ID) {
    notifyCreateVoyage(input: $input) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const fileExportResult = /* GraphQL */ `
  mutation FileExportResult(
    $id: ID
    $s3Key: String
    $status: FileExportResultStatus
  ) {
    fileExportResult(id: $id, s3Key: $s3Key, status: $status) {
      s3Key
      id
      status
      from
      sentAt
      __typename
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserSetting = /* GraphQL */ `
  mutation CreateUserSetting(
    $input: CreateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    createUserSetting(input: $input, condition: $condition) {
      id
      language
      mapLayer
      exportConfig {
        lengthUnit
        columns
        __typename
      }
      movementsFilterId
      berthsFilterId
      fullScreen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserSetting = /* GraphQL */ `
  mutation UpdateUserSetting(
    $input: UpdateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    updateUserSetting(input: $input, condition: $condition) {
      id
      language
      mapLayer
      exportConfig {
        lengthUnit
        columns
        __typename
      }
      movementsFilterId
      berthsFilterId
      fullScreen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserSetting = /* GraphQL */ `
  mutation DeleteUserSetting(
    $input: DeleteUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    deleteUserSetting(input: $input, condition: $condition) {
      id
      language
      mapLayer
      exportConfig {
        lengthUnit
        columns
        __typename
      }
      movementsFilterId
      berthsFilterId
      fullScreen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      type
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      type
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      type
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuditLog = /* GraphQL */ `
  mutation CreateAuditLog(
    $input: CreateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    createAuditLog(input: $input, condition: $condition) {
      id
      table
      type
      source
      userId
      comment
      timeChanged
      objectId
      portCallId
      old
      new
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditLog = /* GraphQL */ `
  mutation UpdateAuditLog(
    $input: UpdateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    updateAuditLog(input: $input, condition: $condition) {
      id
      table
      type
      source
      userId
      comment
      timeChanged
      objectId
      portCallId
      old
      new
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditLog = /* GraphQL */ `
  mutation DeleteAuditLog(
    $input: DeleteAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    deleteAuditLog(input: $input, condition: $condition) {
      id
      table
      type
      source
      userId
      comment
      timeChanged
      objectId
      portCallId
      old
      new
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const createVoyage = /* GraphQL */ `
  mutation CreateVoyage(
    $input: CreateVoyageInput!
    $condition: ModelVoyageConditionInput
  ) {
    createVoyage(input: $input, condition: $condition) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const updateVoyage = /* GraphQL */ `
  mutation UpdateVoyage(
    $input: UpdateVoyageInput!
    $condition: ModelVoyageConditionInput
  ) {
    updateVoyage(input: $input, condition: $condition) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const deleteVoyage = /* GraphQL */ `
  mutation DeleteVoyage(
    $input: DeleteVoyageInput!
    $condition: ModelVoyageConditionInput
  ) {
    deleteVoyage(input: $input, condition: $condition) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const createPortCall = /* GraphQL */ `
  mutation CreatePortCall(
    $input: CreatePortCallInput!
    $condition: ModelPortCallConditionInput
  ) {
    createPortCall(input: $input, condition: $condition) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const updatePortCall = /* GraphQL */ `
  mutation UpdatePortCall(
    $input: UpdatePortCallInput!
    $condition: ModelPortCallConditionInput
  ) {
    updatePortCall(input: $input, condition: $condition) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const deletePortCall = /* GraphQL */ `
  mutation DeletePortCall(
    $input: DeletePortCallInput!
    $condition: ModelPortCallConditionInput
  ) {
    deletePortCall(input: $input, condition: $condition) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const createPortCallData = /* GraphQL */ `
  mutation CreatePortCallData(
    $input: CreatePortCallDataInput!
    $condition: ModelPortCallDataConditionInput
  ) {
    createPortCallData(input: $input, condition: $condition) {
      id
      type
      portCallId
      actionId
      data
      s3file {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallDataS3fileId
      __typename
    }
  }
`;
export const updatePortCallData = /* GraphQL */ `
  mutation UpdatePortCallData(
    $input: UpdatePortCallDataInput!
    $condition: ModelPortCallDataConditionInput
  ) {
    updatePortCallData(input: $input, condition: $condition) {
      id
      type
      portCallId
      actionId
      data
      s3file {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallDataS3fileId
      __typename
    }
  }
`;
export const deletePortCallData = /* GraphQL */ `
  mutation DeletePortCallData(
    $input: DeletePortCallDataInput!
    $condition: ModelPortCallDataConditionInput
  ) {
    deletePortCallData(input: $input, condition: $condition) {
      id
      type
      portCallId
      actionId
      data
      s3file {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallDataS3fileId
      __typename
    }
  }
`;
export const createContactType = /* GraphQL */ `
  mutation CreateContactType(
    $input: CreateContactTypeInput!
    $condition: ModelContactTypeConditionInput
  ) {
    createContactType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContactType = /* GraphQL */ `
  mutation UpdateContactType(
    $input: UpdateContactTypeInput!
    $condition: ModelContactTypeConditionInput
  ) {
    updateContactType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContactType = /* GraphQL */ `
  mutation DeleteContactType(
    $input: DeleteContactTypeInput!
    $condition: ModelContactTypeConditionInput
  ) {
    deleteContactType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      type {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      displayName
      reference
      address1
      address2
      town
      county
      postcode
      number
      fax
      email
      contacta_name
      contacta_detail
      contactb_name
      contactb_detail
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contactTypeId
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      type {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      displayName
      reference
      address1
      address2
      town
      county
      postcode
      number
      fax
      email
      contacta_name
      contacta_detail
      contactb_name
      contactb_detail
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contactTypeId
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      type {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      displayName
      reference
      address1
      address2
      town
      county
      postcode
      number
      fax
      email
      contacta_name
      contacta_detail
      contactb_name
      contactb_detail
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contactTypeId
      __typename
    }
  }
`;
export const createVessel = /* GraphQL */ `
  mutation CreateVessel(
    $input: CreateVesselInput!
    $condition: ModelVesselConditionInput
  ) {
    createVessel(input: $input, condition: $condition) {
      id
      imo
      mmsi
      callSign
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      vesselAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      vesselVesselAgentId
      __typename
    }
  }
`;
export const updateVessel = /* GraphQL */ `
  mutation UpdateVessel(
    $input: UpdateVesselInput!
    $condition: ModelVesselConditionInput
  ) {
    updateVessel(input: $input, condition: $condition) {
      id
      imo
      mmsi
      callSign
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      vesselAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      vesselVesselAgentId
      __typename
    }
  }
`;
export const deleteVessel = /* GraphQL */ `
  mutation DeleteVessel(
    $input: DeleteVesselInput!
    $condition: ModelVesselConditionInput
  ) {
    deleteVessel(input: $input, condition: $condition) {
      id
      imo
      mmsi
      callSign
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      vesselAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      vesselVesselAgentId
      __typename
    }
  }
`;
export const createCargoType = /* GraphQL */ `
  mutation CreateCargoType(
    $input: CreateCargoTypeInput!
    $condition: ModelCargoTypeConditionInput
  ) {
    createCargoType(input: $input, condition: $condition) {
      id
      name
      units
      code
      charge
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCargoType = /* GraphQL */ `
  mutation UpdateCargoType(
    $input: UpdateCargoTypeInput!
    $condition: ModelCargoTypeConditionInput
  ) {
    updateCargoType(input: $input, condition: $condition) {
      id
      name
      units
      code
      charge
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCargoType = /* GraphQL */ `
  mutation DeleteCargoType(
    $input: DeleteCargoTypeInput!
    $condition: ModelCargoTypeConditionInput
  ) {
    deleteCargoType(input: $input, condition: $condition) {
      id
      name
      units
      code
      charge
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCargo = /* GraphQL */ `
  mutation CreateCargo(
    $input: CreateCargoInput!
    $condition: ModelCargoConditionInput
  ) {
    createCargo(input: $input, condition: $condition) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const updateCargo = /* GraphQL */ `
  mutation UpdateCargo(
    $input: UpdateCargoInput!
    $condition: ModelCargoConditionInput
  ) {
    updateCargo(input: $input, condition: $condition) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const deleteCargo = /* GraphQL */ `
  mutation DeleteCargo(
    $input: DeleteCargoInput!
    $condition: ModelCargoConditionInput
  ) {
    deleteCargo(input: $input, condition: $condition) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const createTariffUnit = /* GraphQL */ `
  mutation CreateTariffUnit(
    $input: CreateTariffUnitInput!
    $condition: ModelTariffUnitConditionInput
  ) {
    createTariffUnit(input: $input, condition: $condition) {
      id
      name
      secondaryUnitName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTariffUnit = /* GraphQL */ `
  mutation UpdateTariffUnit(
    $input: UpdateTariffUnitInput!
    $condition: ModelTariffUnitConditionInput
  ) {
    updateTariffUnit(input: $input, condition: $condition) {
      id
      name
      secondaryUnitName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTariffUnit = /* GraphQL */ `
  mutation DeleteTariffUnit(
    $input: DeleteTariffUnitInput!
    $condition: ModelTariffUnitConditionInput
  ) {
    deleteTariffUnit(input: $input, condition: $condition) {
      id
      name
      secondaryUnitName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTariff = /* GraphQL */ `
  mutation CreateTariff(
    $input: CreateTariffInput!
    $condition: ModelTariffConditionInput
  ) {
    createTariff(input: $input, condition: $condition) {
      id
      unit {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data {
        code
        description
        unitCost
        currency
        notes
        minimumCost
        actionTypes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tariffUnitId
      __typename
    }
  }
`;
export const updateTariff = /* GraphQL */ `
  mutation UpdateTariff(
    $input: UpdateTariffInput!
    $condition: ModelTariffConditionInput
  ) {
    updateTariff(input: $input, condition: $condition) {
      id
      unit {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data {
        code
        description
        unitCost
        currency
        notes
        minimumCost
        actionTypes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tariffUnitId
      __typename
    }
  }
`;
export const deleteTariff = /* GraphQL */ `
  mutation DeleteTariff(
    $input: DeleteTariffInput!
    $condition: ModelTariffConditionInput
  ) {
    deleteTariff(input: $input, condition: $condition) {
      id
      unit {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data {
        code
        description
        unitCost
        currency
        notes
        minimumCost
        actionTypes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tariffUnitId
      __typename
    }
  }
`;
export const createTariffBook = /* GraphQL */ `
  mutation CreateTariffBook(
    $input: CreateTariffBookInput!
    $condition: ModelTariffBookConditionInput
  ) {
    createTariffBook(input: $input, condition: $condition) {
      id
      name
      description
      dateValidFrom
      dateValidTo
      tariffs {
        id
        unitId
        cost {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        __typename
      }
      defaultCharges {
        id
        actionTypeId
        movementType
        defaultItems {
          id
          tariffId
          overrideUnitCost
          quantity
          secondaryMult
          notes
          __typename
        }
        __typename
      }
      parentBook
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTariffBook = /* GraphQL */ `
  mutation UpdateTariffBook(
    $input: UpdateTariffBookInput!
    $condition: ModelTariffBookConditionInput
  ) {
    updateTariffBook(input: $input, condition: $condition) {
      id
      name
      description
      dateValidFrom
      dateValidTo
      tariffs {
        id
        unitId
        cost {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        __typename
      }
      defaultCharges {
        id
        actionTypeId
        movementType
        defaultItems {
          id
          tariffId
          overrideUnitCost
          quantity
          secondaryMult
          notes
          __typename
        }
        __typename
      }
      parentBook
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTariffBook = /* GraphQL */ `
  mutation DeleteTariffBook(
    $input: DeleteTariffBookInput!
    $condition: ModelTariffBookConditionInput
  ) {
    deleteTariffBook(input: $input, condition: $condition) {
      id
      name
      description
      dateValidFrom
      dateValidTo
      tariffs {
        id
        unitId
        cost {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        __typename
      }
      defaultCharges {
        id
        actionTypeId
        movementType
        defaultItems {
          id
          tariffId
          overrideUnitCost
          quantity
          secondaryMult
          notes
          __typename
        }
        __typename
      }
      parentBook
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createActionType = /* GraphQL */ `
  mutation CreateActionType(
    $input: CreateActionTypeInput!
    $condition: ModelActionTypeConditionInput
  ) {
    createActionType(input: $input, condition: $condition) {
      id
      name
      lifecycle
      editable
      deleted
      used
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateActionType = /* GraphQL */ `
  mutation UpdateActionType(
    $input: UpdateActionTypeInput!
    $condition: ModelActionTypeConditionInput
  ) {
    updateActionType(input: $input, condition: $condition) {
      id
      name
      lifecycle
      editable
      deleted
      used
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteActionType = /* GraphQL */ `
  mutation DeleteActionType(
    $input: DeleteActionTypeInput!
    $condition: ModelActionTypeConditionInput
  ) {
    deleteActionType(input: $input, condition: $condition) {
      id
      name
      lifecycle
      editable
      deleted
      used
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      type
      name
      portUnlocode
      allocatable
      dockable
      locationParentId
      parent {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      children {
        items {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      parentName
      dimensions {
        length
        breadth
        depth
        __typename
      }
      shape
      coords
      path
      number
      marlinZoneId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationChildrenId
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      type
      name
      portUnlocode
      allocatable
      dockable
      locationParentId
      parent {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      children {
        items {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      parentName
      dimensions {
        length
        breadth
        depth
        __typename
      }
      shape
      coords
      path
      number
      marlinZoneId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationChildrenId
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      type
      name
      portUnlocode
      allocatable
      dockable
      locationParentId
      parent {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      children {
        items {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      parentName
      dimensions {
        length
        breadth
        depth
        __typename
      }
      shape
      coords
      path
      number
      marlinZoneId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationChildrenId
      __typename
    }
  }
`;
export const createPilotTag = /* GraphQL */ `
  mutation CreatePilotTag(
    $input: CreatePilotTagInput!
    $condition: ModelPilotTagConditionInput
  ) {
    createPilotTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePilotTag = /* GraphQL */ `
  mutation UpdatePilotTag(
    $input: UpdatePilotTagInput!
    $condition: ModelPilotTagConditionInput
  ) {
    updatePilotTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePilotTag = /* GraphQL */ `
  mutation DeletePilotTag(
    $input: DeletePilotTagInput!
    $condition: ModelPilotTagConditionInput
  ) {
    deletePilotTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMooringVesselTag = /* GraphQL */ `
  mutation CreateMooringVesselTag(
    $input: CreateMooringVesselTagInput!
    $condition: ModelMooringVesselTagConditionInput
  ) {
    createMooringVesselTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMooringVesselTag = /* GraphQL */ `
  mutation UpdateMooringVesselTag(
    $input: UpdateMooringVesselTagInput!
    $condition: ModelMooringVesselTagConditionInput
  ) {
    updateMooringVesselTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMooringVesselTag = /* GraphQL */ `
  mutation DeleteMooringVesselTag(
    $input: DeleteMooringVesselTagInput!
    $condition: ModelMooringVesselTagConditionInput
  ) {
    deleteMooringVesselTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLinesmanTag = /* GraphQL */ `
  mutation CreateLinesmanTag(
    $input: CreateLinesmanTagInput!
    $condition: ModelLinesmanTagConditionInput
  ) {
    createLinesmanTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLinesmanTag = /* GraphQL */ `
  mutation UpdateLinesmanTag(
    $input: UpdateLinesmanTagInput!
    $condition: ModelLinesmanTagConditionInput
  ) {
    updateLinesmanTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLinesmanTag = /* GraphQL */ `
  mutation DeleteLinesmanTag(
    $input: DeleteLinesmanTagInput!
    $condition: ModelLinesmanTagConditionInput
  ) {
    deleteLinesmanTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPortCallTag = /* GraphQL */ `
  mutation CreatePortCallTag(
    $input: CreatePortCallTagInput!
    $condition: ModelPortCallTagConditionInput
  ) {
    createPortCallTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePortCallTag = /* GraphQL */ `
  mutation UpdatePortCallTag(
    $input: UpdatePortCallTagInput!
    $condition: ModelPortCallTagConditionInput
  ) {
    updatePortCallTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePortCallTag = /* GraphQL */ `
  mutation DeletePortCallTag(
    $input: DeletePortCallTagInput!
    $condition: ModelPortCallTagConditionInput
  ) {
    deletePortCallTag(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFilter = /* GraphQL */ `
  mutation CreateFilter(
    $input: CreateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    createFilter(input: $input, condition: $condition) {
      id
      friendlyId
      type
      name
      status
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFilter = /* GraphQL */ `
  mutation UpdateFilter(
    $input: UpdateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    updateFilter(input: $input, condition: $condition) {
      id
      friendlyId
      type
      name
      status
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFilter = /* GraphQL */ `
  mutation DeleteFilter(
    $input: DeleteFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    deleteFilter(input: $input, condition: $condition) {
      id
      friendlyId
      type
      name
      status
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTodoTypeTemplate = /* GraphQL */ `
  mutation CreateTodoTypeTemplate(
    $input: CreateTodoTypeTemplateInput!
    $condition: ModelTodoTypeTemplateConditionInput
  ) {
    createTodoTypeTemplate(input: $input, condition: $condition) {
      id
      todoTypeName
      todoTypeTitle
      todoTypeDescription
      todoTypeColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTodoTypeTemplate = /* GraphQL */ `
  mutation UpdateTodoTypeTemplate(
    $input: UpdateTodoTypeTemplateInput!
    $condition: ModelTodoTypeTemplateConditionInput
  ) {
    updateTodoTypeTemplate(input: $input, condition: $condition) {
      id
      todoTypeName
      todoTypeTitle
      todoTypeDescription
      todoTypeColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTodoTypeTemplate = /* GraphQL */ `
  mutation DeleteTodoTypeTemplate(
    $input: DeleteTodoTypeTemplateInput!
    $condition: ModelTodoTypeTemplateConditionInput
  ) {
    deleteTodoTypeTemplate(input: $input, condition: $condition) {
      id
      todoTypeName
      todoTypeTitle
      todoTypeDescription
      todoTypeColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      type
      typeId
      subType
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      type
      typeId
      subType
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      type
      typeId
      subType
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVesselState = /* GraphQL */ `
  mutation CreateVesselState(
    $input: CreateVesselStateInput!
    $condition: ModelVesselStateConditionInput
  ) {
    createVesselState(input: $input, condition: $condition) {
      id
      name
      mmsi
      imo
      callSign
      navStatus
      shipType
      lat
      lon
      height
      depth
      speed
      cmg
      heading
      rot
      positionQuality
      positioningDevice
      eta
      destination
      dimForward
      dimAft
      dimPort
      dimStbd
      recordTimestamp
      positionTimestamp
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVesselState = /* GraphQL */ `
  mutation UpdateVesselState(
    $input: UpdateVesselStateInput!
    $condition: ModelVesselStateConditionInput
  ) {
    updateVesselState(input: $input, condition: $condition) {
      id
      name
      mmsi
      imo
      callSign
      navStatus
      shipType
      lat
      lon
      height
      depth
      speed
      cmg
      heading
      rot
      positionQuality
      positioningDevice
      eta
      destination
      dimForward
      dimAft
      dimPort
      dimStbd
      recordTimestamp
      positionTimestamp
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVesselState = /* GraphQL */ `
  mutation DeleteVesselState(
    $input: DeleteVesselStateInput!
    $condition: ModelVesselStateConditionInput
  ) {
    deleteVesselState(input: $input, condition: $condition) {
      id
      name
      mmsi
      imo
      callSign
      navStatus
      shipType
      lat
      lon
      height
      depth
      speed
      cmg
      heading
      rot
      positionQuality
      positioningDevice
      eta
      destination
      dimForward
      dimAft
      dimPort
      dimStbd
      recordTimestamp
      positionTimestamp
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAutomationRule = /* GraphQL */ `
  mutation CreateAutomationRule(
    $input: CreateAutomationRuleInput!
    $condition: ModelAutomationRuleConditionInput
  ) {
    createAutomationRule(input: $input, condition: $condition) {
      id
      enabled
      vessels
      allVessels
      plan {
        steps {
          event
          marlinZoneIds
          actions {
            type
            params
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAutomationRule = /* GraphQL */ `
  mutation UpdateAutomationRule(
    $input: UpdateAutomationRuleInput!
    $condition: ModelAutomationRuleConditionInput
  ) {
    updateAutomationRule(input: $input, condition: $condition) {
      id
      enabled
      vessels
      allVessels
      plan {
        steps {
          event
          marlinZoneIds
          actions {
            type
            params
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAutomationRule = /* GraphQL */ `
  mutation DeleteAutomationRule(
    $input: DeleteAutomationRuleInput!
    $condition: ModelAutomationRuleConditionInput
  ) {
    deleteAutomationRule(input: $input, condition: $condition) {
      id
      enabled
      vessels
      allVessels
      plan {
        steps {
          event
          marlinZoneIds
          actions {
            type
            params
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createS3File = /* GraphQL */ `
  mutation CreateS3File(
    $input: CreateS3FileInput!
    $condition: ModelS3FileConditionInput
  ) {
    createS3File(input: $input, condition: $condition) {
      id
      name
      s3Path
      mimeType
      sizeBytes
      portCallId
      actionId
      type
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateS3File = /* GraphQL */ `
  mutation UpdateS3File(
    $input: UpdateS3FileInput!
    $condition: ModelS3FileConditionInput
  ) {
    updateS3File(input: $input, condition: $condition) {
      id
      name
      s3Path
      mimeType
      sizeBytes
      portCallId
      actionId
      type
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteS3File = /* GraphQL */ `
  mutation DeleteS3File(
    $input: DeleteS3FileInput!
    $condition: ModelS3FileConditionInput
  ) {
    deleteS3File(input: $input, condition: $condition) {
      id
      name
      s3Path
      mimeType
      sizeBytes
      portCallId
      actionId
      type
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotificationRule = /* GraphQL */ `
  mutation CreateNotificationRule(
    $input: CreateNotificationRuleInput!
    $condition: ModelNotificationRuleConditionInput
  ) {
    createNotificationRule(input: $input, condition: $condition) {
      id
      enabled
      name
      description
      kind
      recipients
      locations
      categories
      tags
      tagsMatchingType
      actionsWithoutLocation
      triggers
      actionTypeId
      type
      offset
      offsetUnit
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotificationRule = /* GraphQL */ `
  mutation UpdateNotificationRule(
    $input: UpdateNotificationRuleInput!
    $condition: ModelNotificationRuleConditionInput
  ) {
    updateNotificationRule(input: $input, condition: $condition) {
      id
      enabled
      name
      description
      kind
      recipients
      locations
      categories
      tags
      tagsMatchingType
      actionsWithoutLocation
      triggers
      actionTypeId
      type
      offset
      offsetUnit
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotificationRule = /* GraphQL */ `
  mutation DeleteNotificationRule(
    $input: DeleteNotificationRuleInput!
    $condition: ModelNotificationRuleConditionInput
  ) {
    deleteNotificationRule(input: $input, condition: $condition) {
      id
      enabled
      name
      description
      kind
      recipients
      locations
      categories
      tags
      tagsMatchingType
      actionsWithoutLocation
      triggers
      actionTypeId
      type
      offset
      offsetUnit
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCertificateType = /* GraphQL */ `
  mutation CreateCertificateType(
    $input: CreateCertificateTypeInput!
    $condition: ModelCertificateTypeConditionInput
  ) {
    createCertificateType(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      certificateTypeCategoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCertificateType = /* GraphQL */ `
  mutation UpdateCertificateType(
    $input: UpdateCertificateTypeInput!
    $condition: ModelCertificateTypeConditionInput
  ) {
    updateCertificateType(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      certificateTypeCategoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCertificateType = /* GraphQL */ `
  mutation DeleteCertificateType(
    $input: DeleteCertificateTypeInput!
    $condition: ModelCertificateTypeConditionInput
  ) {
    deleteCertificateType(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      certificateTypeCategoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
