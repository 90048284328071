import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { Typography, TextField, InputAdornment, Chip, Box } from '@material-ui/core';
import { Tariff, TariffData } from '../../models';
import isEqual from 'lodash.isequal';
import TariffUnitAutocomplete from '../TariffUnit/TariffUnitAutocomplete';
import ActionTypeListItem from '../ActionType/ActionTypeListItem';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import NumberFormat from "react-number-format";
import SettingConstants from '../../constants/SettingConstants';
import CurrencyFormat from '../Common/CurrencyFormat';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import MultiActionTypeAutocomplete from '../ActionType/MultiActionTypeAutocomplete';
import { getFullTarrifUnit } from '../../utils/getters';

const actionTypeIdsToActionTypes = (ids, actionTypes) => ids ? ids.map(id => actionTypes.find(at => at.id === id)).filter(at => at) : []

const AdminTariffHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('Tariff.Labels.Code')} flexBasis="15%" />
      <AdminModelEditorHeaderColumn label={t('Tariff.Labels.Description')} flexBasis="35%" />
      <AdminModelEditorHeaderColumn label={t('Tariff.Labels.ActionTypes')} flexBasis="25%" />
      <AdminModelEditorHeaderColumn label={t('Tariff.Labels.UnitCost')} flexBasis="25%" />
    </>
  );
};

const AdminTariffListItemContent = ({ value }) => {
  const { t } = useTranslation();
  const { settings, actionTypes } = useContext(DataStoreContext);
  const appCurrencySetting = settings.length && JSON.parse(settings.find(s => s.name === SettingConstants.APP_CURRENCY).value);
  const mappedActionTypes = actionTypeIdsToActionTypes(value.data.actionTypes, actionTypes);
  return (
    <>
      <Typography noWrap style={{ flexBasis: "15%" }}>{value.data.code}</Typography>
      <Typography variant="caption" noWrap style={{ flexBasis: "35%", opacity: 0.5 }}>{value.data.description}</Typography>
      <Box flexBasis="25%" display="flex" alignItems="center" style={{ opacity: 0.5 }}>
        {mappedActionTypes.slice(0, 2).map(at => <Chip label={<ActionTypeListItem actionType={at} deletedWarning={true} />} style={{ marginRight: "0.25rem" }} />)}
        {mappedActionTypes.length > 2 && <Typography variant="caption" noWrap>+ {mappedActionTypes.length - 2} {t('Common.Labels.More').toLowerCase()}</Typography>}
      </Box>
      <Typography variant="caption" noWrap style={{ flexBasis: "25%", opacity: 0.5 }}>
        {value.data.unitCost ? <NumberFormat value={value.data.unitCost} displayType="text" thousandSeparator={true} prefix={appCurrencySetting.symbol} fixedDecimalScale decimalScale={2} /> : t('Tariff.Labels.Unspecified')} / {getFullTarrifUnit(value?.unit?.name, value?.unit?.secondaryUnitName)}
        {value.data.minimumCost && <> ({t('Tariff.Labels.MinimumCostShort')}<NumberFormat value={value.data.minimumCost} displayType="text" thousandSeparator={true} prefix={appCurrencySetting.symbol} fixedDecimalScale decimalScale={2} />)</>}
      </Typography>
    </>
  );
}

const AdminTariffFormContent = ({ outerValue, value, disabled, onChange, validation }) => {
  const { t } = useTranslation();
  const onChangeData = (e) => onChange({ target: { name: 'data', value: { ...value.data, [e.target.name]: e.target.value } } });
  const onChangeDataNumber = (e) => onChange({ target: { name: 'data', value: { ...value.data, [e.target.name]: parseFloat(e.target.value) } } });
  const { actionTypes } = useContext(DataStoreContext);
  const [actionTypesValue, setActionTypesValue] = useState([]);
  useEffect(() => { actionTypes.length && setActionTypesValue(outerValue.data.actionTypes ? outerValue.data.actionTypes.map(id => actionTypes.find(at => at.id === id)).filter(at => at) : []) }, [outerValue, actionTypes, setActionTypesValue]);
  useEffect(() => { !isEqual(actionTypesValue.map(at => at.id), value.data.actionTypes) && onChangeData({ target: { name: 'actionTypes', value: actionTypesValue.map(at => at.id) } }) }, [actionTypesValue, value, onChangeData]);
  const { settings } = useContext(DataStoreContext);
  const appCurrencySetting = settings.length && JSON.parse(settings.find(s => s.name === SettingConstants.APP_CURRENCY).value);
  const [unitCodeError, setUnitCodeError] = useState('')
  useEffect(() => { setUnitCodeError(Boolean(!validation?.isEqual && !validation?.isUnique) ? t('AdminTariff.Labels.DuplicateTariff'): ''); }, [validation]);

  return (
    <>
      <TextField 
        id="AdminTariffCodeInput"
        required 
        disabled={disabled} 
        value={value.data.code || ''} 
        name="code" 
        label={t('Tariff.Labels.Code')} 
        fullWidth variant="outlined" 
        margin="normal" 
        onChange={onChangeData}
        error={unitCodeError !== ''}
        helperText={unitCodeError} />
      <TextField id="AdminTariffDescriptionInput" disabled={disabled} value={value.data.description || ''} name="description" label={t('Tariff.Labels.Description')} fullWidth variant="outlined" multiline margin="normal" onChange={onChangeData} />
      <Box display="flex" id="AdminTariffCostInputs">
        <TextField
          disabled={disabled}
          value={value.data.unitCost}
          label={t('Tariff.Labels.UnitCost')}
          type="number"
          fullWidth
          variant="outlined"
          margin="normal"
          onChange={(e) => onChangeDataNumber({ target: { name: "unitCost", value: e.target.value } })}
          InputProps={{
            className: 'UnitCostInput',
            inputComponent: CurrencyFormat,
            startAdornment: <InputAdornment position="start">{appCurrencySetting.symbol}</InputAdornment>
          }}
          style={{marginRight: ".5rem"}}
        />
        <TextField
          id="AdminTariffMinimumCostInput"
          disabled={disabled}
          value={value.data.minimumCost}
          label={t('Tariff.Labels.MinimumCost')}
          type="number"
          fullWidth
          variant="outlined"
          margin="normal"
          onChange={(e) => onChangeDataNumber({ target: { name: "minimumCost", value: e.target.value } })}
          InputProps={{
            className: 'MinimumCostInput',
            inputComponent: CurrencyFormat,
            startAdornment: <InputAdornment position="start">{appCurrencySetting.symbol}</InputAdornment>
          }}
          style={{marginLeft: ".5rem"}}
        />
      </Box>
      <TariffUnitAutocomplete id="AdminTariffUnitInput" required disabled={disabled} label={t('Tariff.Labels.TariffUnit')} value={value.unit} onChange={value => onChange({ target: { name: 'unit', value } })} />
      <MultiActionTypeAutocomplete
        id="AdminTariffActionTypeInput"
        required 
        filterSelectedOptions 
        label={t('Tariff.Labels.ActionTypes')} 
        value={actionTypesValue} 
        onChange={setActionTypesValue} 
        helperText={t('Tariff.Labels.ActionTypesHelp')} />
      <TextField id="AdminTariffNoteInput" disabled={disabled} value={value.data.notes || ''} name="notes" label={t('Tariff.Labels.Notes')} fullWidth variant="outlined" multiline minRows={2} maxRows={10} margin="normal" onChange={onChangeData} />
    </>
  );
};

export default () =>
  <AdminModelEditor
    HeaderContent={AdminTariffHeaderContent}
    ListItemContent={AdminTariffListItemContent}
    FormContent={AdminTariffFormContent}
    model={Tariff}
    sort={(a, b) => (a.data.code > b.data.code) ? 1 : -1}
    canUpdate={() => true}
    canDelete={() => true}
    canSave={i => Boolean(i.unit && i.data.code && i.data.actionTypes && i.data.actionTypes.length)}
    canAdd={() => true}
    getUniqueField={i => i?.data?.code}
    defaultItem={() => {
      return {
        data: new TariffData({
          minimumCost: null, 
          unitCost: null
        })
      }
    }}
  />
