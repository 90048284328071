import { 
  getActionTime, 
  getActionDeparture,
  getNextUndoableAction,
} from '../utils/getters';
import { sub } from 'date-fns';

const CUSTOM_ACTION_LABEL = {
  FIRST_ACTION: 'CustomAction.Errors.FirstAction',
  DEPARTURE: 'CustomAction.Errors.Departure',
  LAST_COMPLETED_IN_PROGRESS_ACTION: 'CustomAction.Errors.LastCompletedInProgress',
  INVALID_DATE: 'CustomAction.Errors.InvalidDate',
};

// Checks for the time planned
// Return message if there is an error
export const checkPlannedTime = (tP, lC, portCall, checkDeparture=true, checkLifecycle=true) => {
  if(!Boolean(tP)) return CUSTOM_ACTION_LABEL.INVALID_DATE;
  const timePlanned = new Date(tP);
  // Check if time is before departure
  if(checkDeparture){
    const departure = getActionDeparture(portCall.actions);
    const departureTime = departure 
      ? sub(new Date(getActionTime(departure)), {minutes: 1} ) 
      : null;
    if( departureTime && timePlanned > departureTime)
      return CUSTOM_ACTION_LABEL.DEPARTURE;
  }
  // Check if the action has a lifecycle and checkLifecycle = true
  if(lC && checkLifecycle) {
    // Check that it's after the last completed or in progress action
    const lastCompletedInProgressAction = getNextUndoableAction(portCall.actions);
    const lastCompletedInProgressActionTime = lastCompletedInProgressAction && new Date(getActionTime(lastCompletedInProgressAction)); 
    if(lastCompletedInProgressActionTime && timePlanned < lastCompletedInProgressActionTime)
      return CUSTOM_ACTION_LABEL.LAST_COMPLETED_IN_PROGRESS_ACTION
  }
  return ""  
};