import CheckboxTree from '../CheckBoxTree';

const LocationCheckBoxTree = ({locations, disabled, mapLocations, ...other}) => {

  return (
    <CheckboxTree
      {...other}
      disabled={disabled}
      items={mapLocations(locations)}
    />
  )
}

export default LocationCheckBoxTree;
