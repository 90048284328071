import React,
  { useState,
    useCallback,
    useRef,
} from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  makeStyles,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import {
  croppedImageDimensions,
} from '../../constants/Image';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Close } from "mdi-material-ui"
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const {
  width,
  height,
} = croppedImageDimensions;

const useStyles = makeStyles(() => ({
  imageCrop: {
    margin: "0 auto",
    marginRight: '1rem',
  },
  button: {
    marginRight: "1rem",
    marginTop: "1rem",
  },
  imageContainer: {
    height: "auto",
  },
}));

//Convert base64String into file for upload
//Needs the filename and base64String
//Returns the file
const base64StringtoFile = (base64String, filename) => {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {type: mime})
}

//Takes in the canvas reference, the image, and the crop details
//Displays the cropped version of the image to the canvas reference
const createCropPreview = async(canvasReference, image, crop) => {
  const canvas = canvasReference;
  canvas.style.display = "block";
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    image, //image
    crop.x * scaleX, //sx
    crop.y * scaleY, //sy
    crop.width * scaleX, //sWidth
    crop.height * scaleY, //sHeight
    0, //dx
    0, //dy
    width, //dWidth
    height, // dHeight
  );
};

//Method to get the file extenstion from the Base64 string such as JPG
const extractImageFileExtensionFromBase64 = (base64Data) => {
  return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
}

const ImageDialog = ({
  setOpen,
  open,
  upImg,
  canvasRef,
  file,
  setCroppedFile,
  photoKey,
  clearFileInput,
}) => {
  //States used by the react crop component
  const [crop, setCrop] = useState({unit: '%', width: 30, aspect: 16/9 });
  const imgRef = useRef(null);

  const classes = useStyles();
  const { t } = useTranslation();

  //Sets imgRef to the image from the React Crop Component
  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);
 
  //Create file method which takes the canvas
  //Takes the base64 data from it
  //Checks if there is a prexisiting key
  const createFile = () => {
    const canvas = canvasRef.current;
    const imageData64 = canvas.toDataURL(file.type);
    let fileName;
    if(photoKey) {
      fileName = photoKey;
    }
    else {
      const extension = extractImageFileExtensionFromBase64(imageData64); 
      const imageID = uuidv4();
      fileName = `vessel/${imageID}.${extension}`
    }
    const croppedFile = base64StringtoFile(imageData64, fileName);
    setCroppedFile({file: croppedFile, fileKey: fileName});
  }

  //Clear the file input
  const handleClose = () => {
    clearFileInput();
    setOpen(false);
  };

  const handleConfirm = async() => {
    if (canvasRef.current && imgRef.current && crop.width && crop.height) {
      createCropPreview(canvasRef.current, imgRef.current, crop);
      createFile();
      handleClose();
    }
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
       <Card>
         <CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t('ImageDialog.Labels.CropImage')}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box  className={classes.imageContainer}>
          {(open && !upImg)  ? (
            <Box
              display="flex" 
              justifyContent="center" 
              alignItems="center"
            >
              <CircularProgress/>
            </Box>
            ) : (open && upImg) ? 
            <ReactCrop
              className={classes.imageCrop}
              src={upImg}
              crop={crop}
              onChange={c => setCrop(c)}
              keepSelection
              onImageLoaded={onLoad}
              imageStyle={{maxHeight: "70vh"}}
            />
          : null
          } 
        </Box>
         <Box 
            display="flex" 
            justifyContent="flex-end" 
            alignItems="center"
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              {t('Common.Buttons.Confirm')}
            </Button>
          </Box>
        </CardContent>
       </Card> 
      </Dialog>
  );
}

export default ImageDialog;