import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
  <SvgIcon {...props}>
    <path d="m5 3c-1.1 0-2 0.89-2 2v14c0 1.1046 0.8954 2 2 2h5v-2h-5v-14h5v-2h-5z" />
    <path d="m13.918 15.58-1.4173 1.42-5.0003-5 5.0003-5 1.4173 1.41-2.589 2.59h5.1728v2h-5.1728z" />
    <path d="m15 3v2h2v-2zm4 0v2h2c0-1.11-0.9-2-2-2zm0 4v2h2v-2zm0 4v2h2v-2zm0 4v2h2v-2zm-4 4v2h2v-2zm4 0v2c1.1046 0 2-0.89543 2-2z" />
  </SvgIcon>
);
