import React from 'react';
import { Typography } from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';
import { format } from 'date-fns';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { DateFnsLanguageMap } from '../../translations';
import { Trans, useTranslation } from 'react-i18next';

const UserTypography = ({ user }) => <Typography variant="body2" color="primary" component="span">{user}</Typography>
const DateTimeTypography = ({dateTime, dateTimeFormat, language}) => <Typography variant="caption" color="textSecondary" component="span" style={{ paddingLeft: ".25rem" }}>{format(new Date(dateTime), dateTimeFormat, { locale: DateFnsLanguageMap[language] })}</Typography>
const CommentTypography = ({comment}) => <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>{comment}</Typography>

export default ({ logbookEntry }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n } = useTranslation();
  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
              <Trans 
                  i18nKey="Logbook.Labels.UserCommented"
                  components={{ 
                    user: <UserTypography user={logbookEntry?.userId ?? ''} />,
                  }}
                />
              <DateTimeTypography dateTime={logbookEntry?.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
            </Typography>
          }
          secondary={<CommentTypography comment={logbookEntry?.comment ?? ''} />}
        />
      </ListItem>
    </>
  );
};