import matchSorter from "match-sorter";
import { getActionTime } from "./getters";
import { ExportSortDirection } from "../models";

/*
  Utilities for sorting data models and model-specific filterOptions for Autocomplete
*/

export const SORT_DIRECTION = {
  DESC: 0,
  ASC: 1
}

export const SORT_TYPE = {
  [ExportSortDirection.NEWEST_FIRST]: SORT_DIRECTION.DESC,
  [ExportSortDirection.OLDEST_FIRST]: SORT_DIRECTION.ASC
}

export const getSortTypeByDirection = (sortDirection) => Object.keys(SORT_TYPE).find((type) => sortDirection === SORT_TYPE[type])

export const sortActions = (a, b) => getActionTime(a) > getActionTime(b)
  ? 1
  : getActionTime(a) == getActionTime(b)
    ? a.createdAt > b.createdAt ? 1 : -1
    : -1;
export const sortActionsReverse = (a, b) => getActionTime(a) > getActionTime(b)
  ? -1
  : getActionTime(a) == getActionTime(b)
    ? a.createdAt > b.createdAt ? -1 : 1
    : 1;

export const sortLocations = (a, b) => a.number < b.number ? -1 : 1;
export const locationFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['path'],
  baseSort: (a, b) => sortLocations(a.item, b.item)
});

export const sortContacts = (a, b) => a.name.localeCompare(b.name);
export const sortContactsByType = (a, b) => a.type?.name && b.type?.name
  ? a.type.name.localeCompare(b.type.name) > 0
    ? 1
    : a.type.name.localeCompare(b.type.name) < 0
      ? -1
      : a.name.localeCompare(b.name)
  : a.type?.name
    ? -1
    : 1;
export const contactFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['name'],
  baseSort: (a, b) => sortContacts(a.item, b.item)
});

export const sortCargoTypes = (a, b) => a.name.localeCompare(b.name);
export const cargoTypeFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['name'],
  baseSort: (a, b) => sortCargoTypes(a.item, b.item)
});

export const sortPorts = (a, b) => a.searchString.localeCompare(b.searchString);
export const portFilterOptions = (options, { inputValue }) => {
  if (inputValue.length < 2) return [];
  const result = matchSorter(options, inputValue, {
    keys: ['searchString'],
    baseSort: (a, b) => sortPorts(a.item, b.item)
  });
  result.length = Math.min(result.length, 50);
  return result;
};

export const sortCategories = (a, b) => a.name < b.name ? -1 : 1;
export const categoryFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: [item => item.name.toLowerCase()],
  baseSort: (a, b) => sortCategories(a.item, b.item)
});
// custom sorter to keep uncategorized entry at the top (label may be localized so description is used for matching)
export const sortCategoriesWithUncategorized = (a, b) => (a.description === "UNCATEGORIZED") ? -1 : (b.description === "UNCATEGORIZED") ? 1 : a.name < b.name ? -1 : 1;
export const categoryFilterOptionsWithUncategorized = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: [item => item.name.toLowerCase()],
  baseSort: (a, b) => sortCategoriesWithUncategorized(a.item, b.item),
});

// export const vesselFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['vesselData.name', 'vesselData.imo'] });
export const vesselFilterOptions = (options, { inputValue }) => {
  if (!inputValue.length) return [];
  const result = matchSorter(options, inputValue, { keys: ['vesselData.name', 'vesselData.imo', 'vesselData.mmsi'], });
  result.length = Math.min(result.length, 50);
  return result;
};
export const vesselFilterOptionsByName = (options, { inputValue }) => {
  if (!inputValue.length) return [];
  const result = matchSorter(options, inputValue, { keys: ['vesselData.name'], });
  result.length = Math.min(result.length, 50);
  return result;
};
export const vesselFilterOptionsByImo = (options, { inputValue }) => {
  if (!inputValue.length) return [];
  const result = matchSorter(options, inputValue, { keys: ['vesselData.imo'], });
  result.length = Math.min(result.length, 50);
  return result;
};
export const vesselFilterOptionsByMmsi = (options, { inputValue }) => {
  if (!inputValue.length) return [];
  const result = matchSorter(options, inputValue, { keys: ['vesselData.mmsi'], });
  result.length = Math.min(result.length, 50);
  return result;
};

export const portCallTagFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['name'] });

export const sortActionTypes = (a, b) => a.name.localeCompare(b.name);
export const actionTypeFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['name'],
  baseSort: (a, b) => sortActionTypes(a.item, b.item)
});

export const sortContactTypes = (a, b) => a.name.localeCompare(b.name);
export const contactTypeFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['name'],
  baseSort: (a, b) => sortContactTypes(a.item, b.item)
});

export const sortTariffUnits = (a, b) => a.name.localeCompare(b.name);
export const tariffUnitFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['name'],
  baseSort: (a, b) => sortTariffUnits(a.item, b.item)
});

export const sortTariffs = (a, b) => a.data.code.localeCompare(b.data.code);

export const currencyFilterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
  keys: ['code', 'symbol', 'name'],
});

export const sortCertificateTypes = (a, b) => {
  if (!a.category || !b.category) {
    return a.name.localeCompare(b.name);
  }
  const category = a.category.name.localeCompare(b.category.name)
  return category !== 0 ? category : a.name.localeCompare(b.name);
};

export const sortCertificatesByValidFrom = (a, b) => a.validFromDate > b.validFromDate ? 1 : -1;

export const sortVesselsByName = (a, b) => a?.vesselData?.name?.localeCompare(b?.vesselData?.name);

export const sortVoyagesByName = (a, b) => a?.name?.localeCompare(b?.name, 'en', { numeric: true });


export const sortVoyagesByEarliestDate = (a, b) => 
  getActionTime(a?.voyageFirstDeparture)  > getActionTime(b?.voyageFirstDeparture) ? 1 : !getActionTime(a?.voyageFirstDeparture)? 1 : -1 -1;
