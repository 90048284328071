import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { ListItemText, ListItem, Typography, List, Paper, Portal, Box, debounce } from '@material-ui/core';
import { useCallback } from 'react';
import VesselListItem from '../Vessel/VesselListItem';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/styles';
import ActionStateAndTimeListItem from '../Action/ActionStateAndTimeListItem';
import { sortActionsReverse, sortLocations } from '../../utils/sorters';
import { DataStore } from 'aws-amplify';
import { Location, ActionState, Action, ActionMovementType, PortCall, ActionType, PortCallStatus } from '../../models';
import { differenceInMilliseconds, format, getHours, isAfter, isBefore } from 'date-fns';
import { getActionArrival, getActionDeparture, getActionTime } from '../../utils/getters';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { DateFnsLanguageMap } from '../../translations';

const INITIAL_TIME_OFFSET_PX = 120;
const ROW_HEIGHT_REM = 1.5;
const ZOOM_LEVELS = [
  { // day
    columnMs: 24 * 60 * 60 * 1000,
    primaryFormat: 'MMMM',
    secondaryFormat: 'dd',
    isEven: time => Boolean((+new Date(0) - time) / 1000 / 60 / 60 / 24 % 2), // TODO optimize this?
    multiplier: 2000,
    from: 500000,
    to: 1800000
  },
  { // 1hour
    columnMs: 12 * 60 * 60 * 1000,
    primaryFormat: 'MMMM d',
    secondaryFormat: 'HH:mm',
    isEven: time => Boolean(getHours(time) === 12),
    multiplier: 500,
    from: 100000
  },
  { // 1hour
    columnMs: 60 * 60 * 1000,
    primaryFormat: 'MMMM d',
    secondaryFormat: 'HH:mm',
    isEven: time => Boolean(getHours(time) % 2),
    multiplier: 100,
    from: 10000
  }
];

const useStyles = makeStyles(theme => ({
  column: {
    fontSize: '1rem',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  even: {
    background: 'rgba(0,0,0,0.03)'
  },
  odd: {
    background: 'rgba(0,0,0,0.01)'
  },

  headerBg: {
    background: theme.palette.common.white,
    width: '100%',
    height: ROW_HEIGHT_REM * 2 + 'rem',
    boxSizing: 'border-box',
    zIndex: 1,
    position: 'absolute',
    top: 0
  },
  headerTypographyPrimary: {
    lineHeight: '1.5rem',
    fontWeight: 300,
    opacity: 0.5,
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    position: 'absolute',
    paddingLeft: '0.25rem',
    zIndex: 2
  },
  headerTypographySecondary: {
    opacity: 0.75,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: ROW_HEIGHT_REM + 'rem',
    paddingLeft: '0.25rem',
    zIndex: 2
  },

  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: ROW_HEIGHT_REM * 2 + 'rem',
    pointerEvents: 'none',
    overflow: 'hidden auto',
    border: '1px solid ' + theme.palette.divider,
  },

  currentTimePast: {
    background: 'repeating-linear-gradient(-45deg, rgba(0,0,0,0.05), rgba(0,0,0,0.05) 0.1rem, rgba(0,0,0,0) 0.1rem,rgba(0,0,0,0) 0.5rem)',
    position: 'absolute',
    top: 0,
    // top: ROW_HEIGHT_REM * 2 + 'rem', // uncomment to remove from header
    height: '100%',
    zIndex: 1
  },
  currentTime: {
    position: 'absolute',
    width: '3rem',
    zIndex: 3,
    pointerEvents: 'none',
    top: ROW_HEIGHT_REM + 'rem'
  },
  currentTimeTypography: {
    fontSize: '0.75rem',
    transform: 'translate(-50%)',
    borderRadius: '.25rem .25rem 0 0',
    background: theme.palette.grey[500],
    color: theme.palette.common.white,
    lineHeight: ROW_HEIGHT_REM + 'rem'
  },
  currentTimeLine: {
    transform: 'translate(-50%)',
    width: '0.1rem',
    background: theme.palette.grey[500],
    opacity: 0.33
  },

  item: {
    position: 'absolute',
    background: theme.palette.primary.main,
    // opacity: 0.5,
    borderRadius: '1rem',
    border: '1px solid rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    pointerEvents: 'all',
    cursor: 'pointer',
    zIndex: 5,
  },
  itemDefault: {
    background: theme.palette.grey[500],
    opacity: 0.33
  },
  itemRequested: {
    background: "#ff9800",
    height: "1.25rem !important",
    top: "0.125rem !important"
  },
  itemNoRange: {
    opacity: 0.33,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  row: {
    pointerEvents: 'all',
    '&:hover': {
      // background: theme.palette.action.hover,
    }
  },
  rowNotAllocated: {
    background: 'rgba(0,0,0,0.03)',
    color: 'rgba(0,0,0,0.38)'
  },
  rowTypography: {
    fontSize: '0.75rem',
    paddingLeft: '0.5rem',
    lineHeight: '.75rem'
  },
  rowParentTypography: {
    fontSize: '0.5rem',
    paddingLeft: '0.5rem',
    // position: 'absolute',
    // top: '-0.15rem',
    paddingTop: '.1rem',
    lineHeight: '0.5rem',
    opacity: 0.5
  },

  shiftPath: {
    fill: 'none',
    strokeWidth: '0.15rem',
    stroke: 'rgba(0,0,0,0.1)',
    strokeLinecap: 'square',
    strokeDasharray: '0.5rem'
  },
  shiftPathSelected: {
    stroke: 'rgba(0, 150, 214, 0.75)',
  },

  disabledTypography: {
    color: theme.palette.text.disabled
  },
  tooltipItemActive: {
    background: theme.palette.primary.main
  },
  tooltipItemRequested: {
    background: "#ff9800"
  }

}));

// delay after timeline goes idle before updating render data (when dragging)
const UPDATE_DEBOUNCE_DELAY = 250;

const Columns = ({ time, millisecondsToPx, zoom, width }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { columnMs, primaryFormat, secondaryFormat, isEven } = ZOOM_LEVELS[zoom.level];
  const columnWidth = millisecondsToPx(columnMs);
  const columnNum = width / columnWidth + 1;

  const offsetMs = +time % columnMs;
  const offsetPx = millisecondsToPx(offsetMs);

  const columns = [];
  let lastPrimaryText = null;
  let primaryOffset = 0;
  for (let i = 0; i < columnNum; i++) {
    const columnTime = new Date(+time + (i * columnMs - offsetMs));

    // pin first primary text to the left 
    let primaryText = format(columnTime, primaryFormat, { locale: DateFnsLanguageMap[i18n.language] });
    if (lastPrimaryText !== primaryText) {
      primaryOffset = lastPrimaryText === null ? millisecondsToPx(differenceInMilliseconds(time, columnTime)) : 0; // TODO Math.min() so it doesn't overflow into next offset
      lastPrimaryText = primaryText;
    } else {
      primaryText = null;
    }

    columns.push(
      <div
        key={i}
        className={`${classes.column} ${isEven(columnTime) ? classes.even : classes.odd}`}
        style={{ position: 'absolute', left: i * columnWidth - offsetPx, top: 0, width: columnWidth, height: '100%' }}
      >
        {primaryText &&
          <Typography component="div" variant="body2" className={classes.headerTypographyPrimary} style={{ left: primaryOffset }}>{primaryText}</Typography>
        }
        <Typography component="div" variant="caption" className={classes.headerTypographySecondary}>{format(columnTime, secondaryFormat, { locale: DateFnsLanguageMap[i18n.language] })}</Typography>
      </div>
    );
  }
  return (
    <>
      <div className={classes.headerBg} />
      {columns}
    </>
  );
};

const CurrentTime = ({ time, timeTo, millisecondsToPx, height }) => {
  const currentTime = new Date();
  const { i18n } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.currentTimePast} style={{ width: millisecondsToPx(currentTime - time) }} /> {/* TODO may be better to find another way */}
      {isAfter(currentTime, time) && isBefore(currentTime, timeTo) &&
        <div className={classes.currentTime} style={{ left: millisecondsToPx(currentTime - time) }}> {/* TODO fix top positioning */}
          <Typography component="div" variant="caption" align="center" className={classes.currentTimeTypography}>{format(currentTime, 'HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}</Typography>
          <div className={classes.currentTimeLine} style={{ height }} />
        </div>
      }
    </>
  );
};

const ItemType = {
  DEFAULT: 0,
  ACTIVE: 1,
  REQUESTED: 2
};

const Item = ({ item, classes, millisecondsToPx, time, timeTo, lastMouseDown, remToPx, type }) => {
  return (
    <div
      className={`${classes.item} ${type === ItemType.DEFAULT && classes.itemDefault} ${type === ItemType.REQUESTED && classes.itemRequested}`}
      style={{
        top: 0,
        left: millisecondsToPx(item.from - time),
        height: '100%',
        width: item.to ? millisecondsToPx(item.to - time) - millisecondsToPx(item.from - time) : ROW_HEIGHT_REM + 'rem', // circle if no end range set
        pointerEvents: lastMouseDown ? 'none' : 'all',
        zIndex: Math.max(1, Math.trunc(millisecondsToPx(item.from - time))) + (type === ItemType.REQUESTED ? 10000 : 0)  // ensures all items are hoverable from left to right; TODO maybe make more efficient
      }}
    >
      {!item.to && <div className={`${classes.item} ${type === ItemType.DEFAULT && classes.itemDefault} ${type === ItemType.REQUESTED && classes.itemRequested} ${classes.itemNoRange}`}
        style={{
          left: -1, // 1 comes from .item border-left-size
          top: -1,
          bottom: -1,
          width: millisecondsToPx(timeTo - item.from),
        }} />}
      {item.shift &&
        <ItemShift
          item={item}
          millisecondsToPx={millisecondsToPx}
          remToPx={remToPx}
          type={type}
        />}
    </div>
  );
};
Item.defaultProps = {
  type: ItemType.DEFAULT
}

const ItemShift = ({ item, millisecondsToPx, remToPx, type }) => {
  const width = Math.max(4, millisecondsToPx(differenceInMilliseconds(item.shift.to, item.to))); // force minimum width
  const heightRem = ROW_HEIGHT_REM * (Math.abs(item.shift.rowOffset) + 1);
  const left = 'calc(100% + 2px)'; // 2 comes from .item border-size
  const top = item.shift.rowOffset > 0 ? 0 : ROW_HEIGHT_REM * item.shift.rowOffset + 'rem';
  const classes = useStyles();
  return (
    <svg style={{ pointerEvents: 'none', position: 'absolute', left, top, width, height: heightRem + 'rem' }}>
      {item.shift.rowOffset > 0 ?
        <path className={`${classes.shiftPath} ${type === ItemType.ACTIVE && classes.shiftPathSelected}`} d={`M0,${remToPx(ROW_HEIGHT_REM) / 2} C${width / 2},${remToPx(ROW_HEIGHT_REM) / 2} ${width - width / 2},${remToPx(heightRem - (ROW_HEIGHT_REM / 2))} ${width},${remToPx(heightRem - (ROW_HEIGHT_REM / 2))}`} />
        :
        <path className={`${classes.shiftPath} ${type === ItemType.ACTIVE && classes.shiftPathSelected}`} d={`M0,${remToPx(heightRem - ROW_HEIGHT_REM / 2)} C${width / 2},${remToPx(heightRem - ROW_HEIGHT_REM / 2)} ${width - width / 2},${remToPx(ROW_HEIGHT_REM) / 2} ${width},${remToPx(ROW_HEIGHT_REM) / 2}`} />
      }
    </svg>
  );
};

const Rows = ({ time, timeTo, lastMouseDown, pxToMilliseconds, millisecondsToPx, remToPx, customPortCall, customAction, requestedPortCall, scrollRef, mousePos }) => {
  const { t, i18n } = useTranslation() 
  const [renderData, setRenderData] = useState([]);
  const [tooltipData, setTooltipData] = useState(null);
  const tooltipRef = useRef(null);
  const [tooltipRect, setTooltipRect] = useState({ width: 0, height: 0 });
  const [needsFocus, setNeedsFocus] = useState(true);
  useEffect(() => {
    setNeedsFocus(true);
  }, [customPortCall, customAction, requestedPortCall]);

  const { locations: initialLocations } = useContext(DataStoreContext);
  const locations = useMemo(() => initialLocations.filter(l => l.allocatable).sort(sortLocations), [initialLocations]);
  const [portCallsMerged, setPortCallsMerged] = useState([]);

  // called when portcalls get changed or timeline is moved
  const updateRenderData = useCallback(debounce((portCalls, time, timeTo) => {
    const locationToIndex = (location) => location ? locations.findIndex(l => l.id === location.id) + 1 : 0; // +1 from custom unallocated row
    if (portCalls && locations.length) {
      const parsePortCall = (portCall, type) => {
        // skip default portcall when custom or requested is available
        if (type === ItemType.DEFAULT && ((customPortCall && portCall.id === customPortCall.id) || (requestedPortCall && portCall.id === requestedPortCall.id))) return;

        const arrival = getActionArrival(portCall.actions);
        if (!arrival) return;
        const departure = getActionDeparture(portCall.actions);

        // cull port call rendering
        const arrivalTime = new Date(getActionTime(arrival));
        const departureTime = departure && new Date(getActionTime(departure));
        if ((!departure && isBefore(arrivalTime, timeTo))
          || (isAfter(arrivalTime, time) && isBefore(arrivalTime, timeTo))
          || (isAfter(departureTime, time) && isBefore(departureTime, timeTo))
          || (isBefore(arrivalTime, time) && isAfter(departureTime, timeTo))) {

          // reference to port call movement added to render data
          let itemRefIndex = locationToIndex(arrival.movementLocation);
          let itemRef = {
            from: arrivalTime,
            fromAction: arrival,
            portCall,
            type
          };
          rows[itemRefIndex].items = [
            ...rows[itemRefIndex].items ? rows[itemRefIndex].items : [],
            itemRef
          ];

          // handle shifts
          const shifts = portCall.actions.filter(a => a.movementType === ActionMovementType.SHIFT_ARRIVAL || a.movementType === ActionMovementType.SHIFT_DEPARTURE).sort(sortActionsReverse);
          let next;
          // shifts
          while (next = shifts.pop()) {
            try {
              if (next.movementType !== ActionMovementType.SHIFT_DEPARTURE) continue;
              const nextArrival = shifts.pop();
              if (!nextArrival || nextArrival.movementType !== ActionMovementType.SHIFT_ARRIVAL) continue;
              // update last item's range to depature
              itemRef.to = new Date(getActionTime(next));
              itemRef.toAction = next;
              itemRef.shift = {
                rowOffset: locationToIndex(nextArrival.movementLocation) - itemRefIndex,
                to: new Date(getActionTime(nextArrival)),
              };
              // add new item at shift's end position
              itemRef = {
                from: new Date(getActionTime(nextArrival)),
                fromAction: nextArrival,
                portCall,
                type
              };
              itemRefIndex = locationToIndex(nextArrival.movementLocation);
              rows[itemRefIndex].items = [
                ...rows[itemRefIndex].items ? rows[itemRefIndex].items : [],
                itemRef
              ];
            } catch (err) {
              console.log(err);
            }
          }
          // update last item's range if departure
          if (departure) {
            itemRef.to = departureTime;
            itemRef.toAction = departure;
          }
        };
      }

      const rows = [{ item: new Location({ name: t('LocationTimeline.Labels.NotAllocated') }) }, ...locations.map(l => ({ item: l }))];
      portCalls.forEach(pc => parsePortCall(pc, ItemType.DEFAULT));
      if (customPortCall) {
        parsePortCall(customPortCall, ItemType.ACTIVE);
        // vertical scroll focus
        if (needsFocus) {
          setNeedsFocus(false);
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            // pretty heavy load..any better way?
            if (row.items && row.items.find(a =>
              (customAction && (a.fromAction.id === customAction.id || (a.toAction && a.toAction.id === customAction.id))) ||
              customPortCall.actions.find(pa => a.fromAction.id === pa.id || (a.toAction && a.toAction.id === pa.id))
            )) {
              // scroll to row - 3
              setTimeout(() => scrollRef.current && scrollRef.current.scroll(0, (i - 3) * remToPx(ROW_HEIGHT_REM)), 100); // doesn't focus on page load without timeout
              break;
            }
          }
        }
      }
      if (requestedPortCall) {
        parsePortCall(requestedPortCall, ItemType.REQUESTED);
        // vertical scroll focus
        if (needsFocus) {
          setNeedsFocus(false);
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            // pretty heavy load..any better way?
            if (row.items && row.items.find(a =>
              (customAction && (a.fromAction.id === customAction.id || (a.toAction && a.toAction.id === customAction.id))) ||
              requestedPortCall.actions.find(pa => a.fromAction.id === pa.id || (a.toAction && a.toAction.id === pa.id))
            )) {
              // scroll to row - 3
              setTimeout(() => scrollRef.current && scrollRef.current.scroll(0, (i - 3) * remToPx(ROW_HEIGHT_REM)), 100); // doesn't focus on page load without timeout
              break;
            }
          }
        }
      }
      setRenderData(rows);
    }
  }, UPDATE_DEBOUNCE_DELAY), [setRenderData, locations, customPortCall, requestedPortCall, customAction, needsFocus, setNeedsFocus, t]);

  // update render data when port calls get changed or timeline is moved
  useEffect(() => {
    updateRenderData(portCallsMerged, time, timeTo);
  }, [portCallsMerged, time, timeTo, updateRenderData]);

  // query data
  useEffect(() => {
    let cancelled = false;
    (async () => {
      // perform paginated querying
      let page = 0;
      let limit = 500;
      let actions = [];
      do {
        if (cancelled) return;
        const portCalls = await DataStore.query(PortCall, c => c
          .status("ne", PortCallStatus.DELETED)
          .status("ne", PortCallStatus.CANCELLED));
        const data = await DataStore.query(Action, c => c
          .or((c) => portCalls.reduce((c, p) => c.actionPortCallId_('eq', p.id), c))
          .movementType("ne", null)
          .state("ne", ActionState.CANCELLED)
          .state("ne", ActionState.DELETED)
          .state("ne", ActionState.REQUESTED), {
            limit: limit, 
            page: page,
            sort: c => c.timePlanned("DESCENDING") 
          });
        if (cancelled) return;
        
        actions = [...actions, ...data];
        // link in locations to actions
        actions = actions.map(action => {
          if(!action.actionMovementLocationId) return action;
          return Action.copyOf(action, updated => {
            updated.movementLocation = locations.find(el => el.id === action.actionMovementLocationId);
          });
        });
        // could probably get away without merging portcalls in here and have renderData get calculated from actions directly
        setPortCallsMerged(prev => {
          return [...prev, ...data.filter(a => a.movementType === ActionMovementType.ARRIVAL).map((arrival) =>
            PortCall.copyOf(arrival.portCall, updated => {
              updated.actions = actions.filter(a => a.portCall && a.portCall.id === updated.id);
            })
          )];
        });
        page = data.length < limit ? null : ++page;
      } while (page)
    })();

    const subscription = DataStore.observe(Action).subscribe(async msg => {
      const { element, opType } = msg;
      let actionDeleted = (opType === 'DELETE') || (element?.state === ActionState.DELETED) ;
      // access latest data
      let item;
      setPortCallsMerged(prev => {
        const portCall = prev.find(p => p.id === element.actionPortCallId_);
        item = portCall && portCall.actions.find(i => i.id === element.id)
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item && item._version >= element._version) {
        return;
      }

      if (!actionDeleted) {
        // action connections
        const type = element.actionTypeId ? await DataStore.query(ActionType, element.actionTypeId) : null;
        const movementLocation = element.actionMovementLocationId ? await DataStore.query(Location, element.actionMovementLocationId) : null;
        if (cancelled) return;

        setPortCallsMerged(prev => prev.map(p => p.id === element.actionPortCallId_ ?
          PortCall.copyOf(p, updated => {
            // update relevant action
            updated.actions = [
              ...updated.actions.filter(a => a.id !== element.id),
              Action.copyOf(element, updated => {
                updated.type = type;
                updated.movementLocation = movementLocation;
              })
            ];
          }) : p
        ));
      } else {
        // remove
        setPortCallsMerged(prev => prev.map(p => p.id === element.actionPortCallId_ ?
          PortCall.copyOf(p, updated => {
            updated.actions = updated.actions.filter(a => a.id !== element.id);
          }) : p
        ));
      }
    });
    
    return () => {
      cancelled = true;
      subscription.unsubscribe();
    }
  }, [setPortCallsMerged, locations]);

  useEffect(() => {
    // calculate tooltip data
    if (mousePos && renderData.length) {
      const rowIndex = Math.trunc((mousePos.top + scrollRef.current.scrollTop) / remToPx(ROW_HEIGHT_REM));
      if (renderData[rowIndex] && renderData[rowIndex].items) {
        const mouseTime = new Date(+time + pxToMilliseconds(mousePos.left));
        const items = renderData[rowIndex].items.filter(i => isBefore(i.from, mouseTime) && (!i.to || isAfter(i.to, mouseTime)));
        setTooltipData(items.length ? {
          location: renderData[rowIndex],
          items,
          time: new Date(mouseTime)
        } : null);
      } else {
        tooltipData && setTooltipData(null);
      }
    }
  }, [renderData, scrollRef, mousePos]);

  const classes = useStyles();
  if (!renderData || !renderData.length || !locations.length) return null;
  if (tooltipRef.current) {
    const { width, height } = tooltipRef.current.getBoundingClientRect();
    if (tooltipRect.width !== width || tooltipRect.height !== height)
      setTooltipRect({ width, height });
  }

  return (    
    <>
      {mousePos && tooltipData && <Portal>
        <Paper
          ref={tooltipRef}
          elevation={8}
          style={{
            position: 'absolute',
            left: Math.min(Math.max(mousePos.pageX, tooltipRect.width / 2), window.innerWidth - tooltipRect.width / 2),
            top: mousePos.pageY - (mousePos.top + scrollRef.current.scrollTop) % remToPx(ROW_HEIGHT_REM),
            zIndex: 2147483647,
            pointerEvents: 'none',
            transform: 'translate(-50%, -100%)'
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" pl="1rem" pr="1rem">
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle1" style={{ marginBottom: '-0.5rem' }}>{tooltipData.location.parentName}</Typography>
              <Typography variant="h5">{tooltipData.location.name}</Typography>
            </Box>
            <ListItemText
              style={{ flexGrow: 0 }}
              secondary={format(tooltipData.time, 'HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
              primary={format(tooltipData.time, 'MMM dd yyyy', { locale: DateFnsLanguageMap[i18n.language] })}
            />
          </Box>

          {tooltipData && tooltipData.items.map(i =>
            <ListItem key={i.fromAction.id} className={`${i.type === ItemType.ACTIVE && classes.tooltipItemActive} ${i.type === ItemType.REQUESTED && classes.tooltipItemRequested}`} style={{ padding: 0 }}>
              <Box width="16rem">
                <VesselListItem vesselData={i.portCall.vesselData} />
              </Box>
              <Box width="10rem">
                <ActionStateAndTimeListItem action={i.fromAction} disableTooltip />
              </Box>
              <Box width="10rem">
                {i.toAction ? <ActionStateAndTimeListItem action={i.toAction} disableTooltip /> : <Typography className={classes.disabledTypography} noWrap>{t('LocationTimeline.Labels.NoUpcomingAction')}</Typography>}
              </Box>
            </ListItem>
          )}

        </Paper>
      </Portal>}

      <List disablePadding>
        {renderData.map((l, index) =>
          <ListItem key={l.item.id} style={{ height: ROW_HEIGHT_REM + 'rem' }} disableGutters className={`${index === 0 && classes.rowNotAllocated} ${classes.row}`}>
            <Box display="flex" flexDirection="column">
              <Typography component="p" variant="caption" className={classes.rowParentTypography} noWrap>{l.item.parentName}</Typography>
              <Typography component="p" variant="body1" className={classes.rowTypography} noWrap>{l.item.name}</Typography>
            </Box>
            {l.items && l.items.map(i =>
              <Item
                key={i.fromAction.id}
                item={i}
                classes={classes}
                time={time} timeTo={timeTo}
                lastMouseDown={lastMouseDown}
                millisecondsToPx={millisecondsToPx}
                remToPx={remToPx}
                type={i.type}
              />)}
          </ListItem>
        )}
      </List>
    </>
  );
};

const Timeline = ({ width, height, customPortCall, requestedPortCall, customAction }) => {
  const ref = useRef(null);
  const scrollRef = useRef(null);
  const [lastMouseDown, setLastMouseDown] = useState(null); // last mouse position
  const [mousePos, setMousePos] = useState(null); // mouse position in scrollRef (with header offset)

  const [zoom, setZoom] = useState({
    level: 0, // index of ZOOM_LEVELS
    scale: 20 * 60 * 1000 // current scale in milliseconds (per pixel)
  });
  // multiply by milliseconds to get grid pixels
  const millisecondsToPx = useCallback((ms) => ms / zoom.scale, [zoom.scale]);
  // multiple by grid pixels to get millixeconds
  const pxToMilliseconds = useCallback((px) => px * zoom.scale, [zoom.scale]);

  // horizontal scroll focus (render time from left)
  const [time, setTime] = useState(new Date(+new Date() - pxToMilliseconds(INITIAL_TIME_OFFSET_PX))); // or width * 0.1 for 10% from left etc
  useEffect(() => {
    if (!customPortCall && !requestedPortCall) return;
    let end;
    if (requestedPortCall) {
      requestedPortCall.actions.forEach(a => {
        if (!end || (getActionTime(a) > end)) end = getActionTime(a);
      });
    } else if (customPortCall) {
      customPortCall.actions.forEach(a => {
        if (!end || (getActionTime(a) > end)) end = getActionTime(a);
      });
    }
    if (end) {
      setTime(new Date(+new Date(end) - pxToMilliseconds(width / 2)));
    }
  }, [customPortCall, requestedPortCall]);

  // render time to (right edge)
  const timeTo = useMemo(() => new Date(+time + pxToMilliseconds(width)), [time, pxToMilliseconds]);

  // TODO change on resize/review when REM changes
  const fontSizePx = useMemo(() => parseFloat(getComputedStyle(document.documentElement).fontSize), []);
  const remToPx = useCallback(rem => rem * fontSizePx, [fontSizePx]);

  const handleMouseDown = ({ pageX, pageY }) => {
    const { left, top } = ref.current.getBoundingClientRect();
    setLastMouseDown({
      left: pageX - left,
      top: pageY - top
    });
  };
  const handleMouseUp = () => {
    lastMouseDown && setLastMouseDown(null);
  };
  const handleMouseEnter = () => {
    lastMouseDown && setLastMouseDown(null);
  }
  const handleMouseLeave = () => {
    lastMouseDown && setLastMouseDown(null);
  };
  const handleMouseMove = ({ pageX, pageY }) => {
    if (lastMouseDown) {
      const { left, top } = ref.current.getBoundingClientRect();
      const offsetLeft = lastMouseDown.left - (pageX - left);
      const offsetTop = lastMouseDown.top - (pageY - top);
      setTime(new Date(+time + pxToMilliseconds(offsetLeft)));
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollTop + offsetTop });
      setLastMouseDown({
        left: pageX - left,
        top: pageY - top
      });
    }
  };
  const handleWheel = ({ deltaY, shiftKey, pageX }) => {
    if (shiftKey) {
      const newScale = zoom.scale + deltaY * ZOOM_LEVELS[zoom.level].multiplier;
      let newLevel = zoom.level;
      if (newScale < ZOOM_LEVELS[newLevel].from) {
        if (ZOOM_LEVELS.length > newLevel + 1) {
          newLevel++;
        } else {
          return;
        }
      } else if (ZOOM_LEVELS[newLevel].to && newScale > ZOOM_LEVELS[newLevel].to) {
        return;
      } else if (newLevel > 0 && newScale > ZOOM_LEVELS[newLevel - 1].from) {
        newLevel--;
      }
      setZoom({ level: newLevel, scale: newScale });

      const { left } = ref.current.getBoundingClientRect();
      const prevTime = new Date(+time + pxToMilliseconds(pageX - left));
      const prevLeft = pageX - left;
      // pxToMilliseconds(prevLeft) would return with old scale,
      // use new scale manually to get latest result and batch states in a single call to prevent flicker
      setTime(new Date(+prevTime - prevLeft * newScale));
    }
  };
  const handleScrollRefMouseMove = ({ pageX, pageY }) => {
    const { left, top } = scrollRef.current.getBoundingClientRect();
    setMousePos({
      pageX,
      pageY,
      left: pageX - left,
      top: pageY - top
    });
  };
  const handleScrollRefMouseLeave = () => {
    setMousePos(null);
  };

  const classes = useStyles();
  return (
    <div
      style={{
        position: 'relative',
        background: 'rgba(0,0,0,0.01)',
        width: width,
        height: height,
        overflow: 'hidden',
        userSelect: 'none'
      }}
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onWheel={handleWheel}
    >
      <Columns time={time} millisecondsToPx={millisecondsToPx} pxToMilliseconds={pxToMilliseconds} zoom={zoom} width={width} />
      <CurrentTime time={time} timeTo={timeTo} millisecondsToPx={millisecondsToPx} height={height} />
      <div ref={scrollRef} className={classes.content} onMouseMove={handleScrollRefMouseMove} onMouseLeave={handleScrollRefMouseLeave}>
        <Rows time={time} timeTo={timeTo} lastMouseDown={lastMouseDown} pxToMilliseconds={pxToMilliseconds} millisecondsToPx={millisecondsToPx} remToPx={remToPx} customPortCall={customPortCall} requestedPortCall={requestedPortCall} customAction={customAction} scrollRef={scrollRef} mousePos={mousePos} />
      </div>
    </div>
  );
};

// wrap with autosizer
const LocationTimeline =  ({ customPortCall, requestedPortCall, customAction }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <AutoSizer>
      {({ width, height }) => <Timeline width={width} height={height} customPortCall={customPortCall} requestedPortCall={requestedPortCall} customAction={customAction} />}
    </AutoSizer>
  </div>
);

export default LocationTimeline;