import { useState, useEffect } from 'react';
import PORTS from '../data/Port.min.json';
import { Port } from '../models';
import { sortPorts } from '../utils/sorters';

export default () => {
  const [ports, setPorts] = useState();
  useEffect(() => {
    setPorts(PORTS.map(p => new Port({ countryCode: p[0], portCode: p[1], name: p[2], searchString: p[0] + ' ' + p[1] + ' ' + p[2] })).sort(sortPorts));
  }, []);
  return ports;
};