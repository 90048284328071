import React from 'react';
import { 
  ListItem, 
  ListItemText,
  Typography,
  makeStyles,
  Box
} from '@material-ui/core';
import PropTypes from "prop-types";
import renderValueCurrencyString from '../../utils/renderValueCurrencyString';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles({
  unitCostReset: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
  },
});

// Component takes in the unit cost and currency
// Renders a simple list item
const TariffUnitCostTooltip = ({ 
  originalUnitCost, 
  currencySymbol, 
  handleResetUnitCost
}) => {

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={renderValueCurrencyString(originalUnitCost, currencySymbol)}
          secondary={t('ChargeableItems.Labels.UnitCostTooltip')}
      />
      </ListItem>
      <Box>
        <Typography
          className={classes.unitCostReset}
          onClick={handleResetUnitCost}
        >
          {t('ChargeableItems.Labels.ResetTooltip')}
        </Typography>
      </Box>
    </>
  );
};

TariffUnitCostTooltip.propTypes = {
  originalUnitCost: PropTypes.number,
  currencySymbol: PropTypes.string,
  handleResetUnitCost: PropTypes.func,
}

TariffUnitCostTooltip.defaultProps = {
  originalUnitCost: 0,
  currencySymbol: ""
}

export default TariffUnitCostTooltip