/**
 * Constants used for displaying units
 */

// Cargo units
export const METRES = 1;
export const TONNES = 2;
export const METRES_CUBED = 3;
export const EACH = 100;
// Non-cargo units
export const KW = 'KW';
export const HP = 'HP';
export const KT = 'KT';
export const KN = 'KN';
export const CELCIUS = 'CELCIUS';
export const FAHRENHEIT = 'FAHRENHEIT';


export const Units = {
  [undefined]: {name: 'unspecified', symbol: '?'},
  [METRES]: { name: 'metres', symbol: 'm'},
  [TONNES]: { name: 'tonnes', symbol: 't'},
  [METRES_CUBED]: { name: 'metres cubed', symbol: 'm\u00B3'},
  [KW]:     { name: 'kilowatt', symbol: 'kW'},
  [HP]:     { name: 'horsepower', symbol: 'hp'},
  [KT]:     { name: 'knots', symbol: 'kt'},
  [EACH]:   { name: 'each', symbol: ''},
  [CELCIUS]: { name: 'celcius', symbol: 'C'},
  [FAHRENHEIT]: {name: 'fahrenheit', symbol: 'F'}
};

export const UnitsKeys = {
  [undefined]: 'Common.Labels.Unspecified',
  [METRES]: 'Common.Labels.M',
  [METRES_CUBED]: 'Common.Labels.M3',
  [TONNES]: 'Common.Labels.T',
  [KW]:     'Common.Labels.KW',
  [HP]:     'Common.Labels.HP',
  [KT]:     'Common.Labels.KN',
  [KN]:     'Common.Labels.KN',
  [EACH]:   'Common.Labels.Each',
  [CELCIUS]: 'Common.Labels.C',
  [FAHRENHEIT]: 'Common.Labels.F'
};

export const UnitsSymbolKeyFromString = (str) => {
  const unit = UnitsKeys[str];
  return unit ?? 'Common.Labels.Unspecified';
};

export const CargoUnitNames = {
  [METRES]: 'CargoType.Labels.Metre',
  [TONNES]: 'CargoType.Labels.Tonne',
  [METRES_CUBED]: 'CargoType.Labels.MetersCubed',
  [EACH]: 'CargoType.Labels.Each'
}
