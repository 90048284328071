import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DateFnsLanguageMap } from '../translations';
import { useTranslation } from 'react-i18next';

const Clock = ({ timeFormat, dateFormat, showDate }) => {
  const [dt, setDt] = useState(new Date());
  const { i18n } = useTranslation();
  useEffect(() => {
    const ticker = setInterval(() => {
      setDt(new Date());
    }, 1000);

    return () => {
      clearInterval(ticker);
    };
  }, [setDt]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h6" align="center" style={{ lineHeight: 'inherit' }}>
          {format(dt, timeFormat, { locale: DateFnsLanguageMap[i18n.language] })}
        </Typography>
      </div>

      {showDate && (
        <Typography variant="caption" align="center">
          {format(dt, dateFormat, { locale: DateFnsLanguageMap[i18n.language] })}
        </Typography>
      )}
    </>
  );
};

Clock.defaultProps = {
  timeFormat: 'HH:mm',
  dateFormat: 'EEE do MMM',
  showDate: true
};
export default Clock;
