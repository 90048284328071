import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Badge, ListItem, ListItemIcon } from '@material-ui/core';
import ScheduleTime from '../ScheduleTime';
import RequestTooltip from '../Tooltip/RequestTooltip';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import { Email } from 'mdi-material-ui';
import { RequestColor } from '../../constants/RequestConstants';
import ActionStateAndTimeTooltip from '../Tooltip/ActionStateAndTimeTooltip';
import { ActionState as as_ } from '../../models';
import ActionState from './ActionState';

const ActionStateAndTimeListItem = ({ action, request, disableTooltip, delay, ...otherProps }) => {
  const [useRequestTooltip, setUseRequestTooltip] = useState(false);
  const approvalBadgeContent = action && (action.state === as_.APPROVAL_PENDING ? "!" : action.state === as_.APPROVAL_DECLINED ? "×" : null);
  const approvalBadgeColor = approvalBadgeContent && action.state === as_.APPROVAL_DECLINED ? "error" : "primary";
  return (
    <>
      {
        action &&
        <PopoverTooltip delay={delay} tooltip={useRequestTooltip ? <RequestTooltip portCall={action.portCall} request={request} /> : <ActionStateAndTimeTooltip action={action} />} disableTooltip={disableTooltip} {...otherProps}>
          <ListItem disableGutters component="div">
          <ListItemIcon>
              {approvalBadgeContent
                ? <Badge badgeContent={approvalBadgeContent} color={approvalBadgeColor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
                  <ActionState action={action} />
                </Badge>
                : <ActionState action={action} />
              }
            </ListItemIcon>
            <ScheduleTime planned={action.timePlanned} actual={action.timeActual} estimated={action.timeEstimated} estimatedUpdatedAt={action.timeEstimatedUpdatedAt} />
            {request && <Email onMouseEnter={() => setUseRequestTooltip(true)} onMouseLeave={() => setUseRequestTooltip(false)} style={{ color: RequestColor.color }} />}
          </ListItem>
        </PopoverTooltip>
      }
    </>
  );
}

ActionStateAndTimeListItem.propTypes = {
  action: PropTypes.object,
  request: PropTypes.object,
  disableTooltip: PropTypes.bool
}

export default ActionStateAndTimeListItem;