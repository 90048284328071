
// date-fns/locale/_lib/buildLocalizeFn/index.js
function buildLocalizeFn(args) {
  return function (dirtyIndex, dirtyOptions) {
    var options = dirtyOptions || {};
    var context = options.context ? String(options.context) : 'standalone';
    var valuesArray;

    if (context === 'formatting' && args.formattingValues) {
      var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
      var width = options.width ? String(options.width) : defaultWidth;
      valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
    } else {
      var _defaultWidth = args.defaultWidth;

      var _width = options.width ? String(options.width) : args.defaultWidth;

      valuesArray = args.values[_width] || args.values[_defaultWidth];
    }

    var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
    return valuesArray[index];
  };
}

var eraValues = {
  narrow: ['XX', 'XX'],
  abbreviated: ['XX', 'XX'],
  wide: ['XXXXXXX', 'XXXXXXX']
};
var quarterValues = {
  narrow: ['1', '2', '3', '4'],
  abbreviated: ['1-XX', '2-XX', '3-XX', '4-XX'],
  wide: ['1-XX', '2-XX', '3-XX', '4-XX']
};
var monthValues = {
  narrow: ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
  abbreviated: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  wide: ['XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX']
};
var formattingMonthValues = {
  narrow: ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
  abbreviated: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  wide: ['XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX']
};
var dayValues = {
  narrow: ['X', 'X', 'X', 'X', 'X', 'X', 'X'],
  short: ['XX', 'XX', 'XX', 'XX', 'XX', 'XX', 'XX'],
  abbreviated: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  wide: ['XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX', 'XXXXXXX']
};
var dayPeriodValues = {
  narrow: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  },
  abbreviated: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  },
  wide: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  }
};
var formattingDayPeriodValues = {
  narrow: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  },
  abbreviated: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  },
  wide: {
    am: 'XX',
    pm: 'XX',
    midnight: 'XXXXXXX',
    noon: 'XXXXXXX',
    morning: 'XXXXXXX',
    afternoon: 'XXXXXXX',
    evening: 'XXXXXXX',
    night: 'XXXXXXX'
  }
};

const testLocalize = {
  ordinalNumber: (dirtyNumber) => dirtyNumber,
  era: buildLocalizeFn({
    values: eraValues,
    defaultWidth: 'wide'
  }),
  quarter: buildLocalizeFn({
    values: quarterValues,
    defaultWidth: 'wide',
    argumentCallback: function (quarter) {
      return Number(quarter) - 1;
    }
  }),
  month: buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
    formattingValues: formattingMonthValues,
    defaultFormattingWidth: 'wide'
  }),
  day: buildLocalizeFn({
    values: dayValues,
    defaultWidth: 'wide'
  }),
  dayPeriod: buildLocalizeFn({
    values: dayPeriodValues,
    defaultWidth: 'any',
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: 'wide'
  })
};

export default testLocalize;