
import { useEffect, useState } from 'react'
import useUserSettings from './useUserSettings';

/**
 * Hook to set/update map layer settings for user
 * @returns {Object} settings for map layers
 * @returns {function} function to update the user settings for map layers
 */
const useMapLayerSetting = () => {
  const { userSetting, updateUserSettings } = useUserSettings();
  const [mapLayerSetting, setMapLayerSetting] = useState(undefined);
  const MAP_LAYER_KEY = 'mapLayer';
  
  useEffect(() => {
    const mapLayerSettingStr = userSetting ? userSetting[MAP_LAYER_KEY] : undefined
    try{
      const mapLayerSettingJson = mapLayerSettingStr ? JSON.parse(mapLayerSettingStr) : undefined
      setMapLayerSetting(mapLayerSettingJson);
    }catch(err){}
  }, [userSetting]);

  const updateMapLayerSetting = async (newMapLayerSetting) => {
    await updateUserSettings(MAP_LAYER_KEY, JSON.stringify(newMapLayerSetting))
  };

  return { mapLayerSetting,  updateMapLayerSetting };
};

export default useMapLayerSetting;