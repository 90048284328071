import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Popover,
} from '@material-ui/core';
import { Cog } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import '../translations/i18n';

/* Documentation

Creates a menu from the column configuration 
object that is used by the DataTable.
Allows for hiding and showing columns
Has no use outside the context of DataTable
as far as I know

Returns FieldSelect component

*/

const useStyles = makeStyles({
  icon: {
    // marginLeft: '12px',
  },
  popover: {
    marginTop: '.5rem'
  }
});

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

export const FieldSelect = ({
  columns,
  filterAnchorEl,
  toggleVisible,
  visible,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles()
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        id="FieldSelect"
        size="small"
        className={classes.icon}
        onClick={() => setOpen(true)}
      >
        <Cog />
      </IconButton>
      <Popover 
        id="fieldSelectPopover"
        open={open}
        anchorEl={filterAnchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={anchorOrigin}
        className={classes.popover}
      >
        <CardContent>
          <FormControl
            component="fieldset"
            fullWidth
            id="fieldSelectList"
          >
            <FormGroup>
              {columns.filter(c => Boolean(c?.label?.label))
                .map(field => (
                  <FormControlLabel
                    key={field.id}
                    control={
                      <Checkbox
                        checked={visible[field.id]}
                        onChange={e => toggleVisible(e.currentTarget.value)}
                        value={field.id}
                      />
                    }
                    label={t(field.label.label).toUpperCase()}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </CardContent>
      </Popover>
    </>
  );
};

FieldSelect.propTypes = {
  // Array of objects describing the data in the table
  columns: PropTypes.array.isRequired,
  // Ref that the menu will be attached to
  filterAnchorEl: PropTypes.object.isRequired,
  // Function to toggle visibility of columns
  toggleVisible: PropTypes.func.isRequired,
  // Object of coluimn visibility
  visible: PropTypes.object.isRequired
}

export default FieldSelect;