import { ActionState } from "../models";

// Check if there is no value or if value is not 0
// Returns true if number or 0
// Returns false if falsy or empty string 
export const isNumber = (value) => !(!value && value !==0);

// Checks whether value exists, is empty string, or 0
export const isValueNotNull = (value) => value || value === '' || value === 0

// Return Number value if number
// Or null if not
export const numberInputString = num => isNumber(num) ? Number(num) : '';

// Returns the tariffs minimum cost or 0;
export const getTariffMinimumCost = minimumCost => minimumCost || Number.NEGATIVE_INFINITY;

// Gets the tariff overrideUnitCost if there is one
// overrideUnitCost can be empty string or zero for display 
// otherwise the tariffdata Unit cost
export const getTariffUnitCost = (cI) => isValueNotNull(cI.overrideUnitCost) ? cI.overrideUnitCost : cI.tariffData.unitCost;

// Create a string from the tariffUnit prefixed string
export const renderTariffUnitType = (u) => u ? `${u}` : '';

// Form the Tariff Code and Description
export const renderTariff = (tD) => `${tD.code} ${tD.description}`;

// Calculate total from tariffData, unitCost or quantity
export const calculateTotal = ({tariffData, unitCost: initialUnitCost, quantity, secondaryMult, secondaryValuePresent=false}) => {
  // Unit cost is either inputted value or just null 
  const unitCost = initialUnitCost || null;
  const minimumCost = tariffData?.minimumCost || 0.00;
  const noPrice = 0.00;
  // There is no quantity or quantity is 0 - total is 0
  if(!isNumber(quantity) || quantity === 0) {
    return noPrice;
  // There is no unit cost but quantity is above 1 - total is minimum cost
  } else if(!isNumber(unitCost)) {
    return minimumCost;
  } else if(isNumber(secondaryMult)) {
    if (secondaryMult > 0) {
      return Math.max(Number(quantity) * Number(secondaryMult) * (Number(unitCost)), getTariffMinimumCost(tariffData.minimumCost)) 
    } else return Number(quantity) * Number(secondaryMult) * (Number(unitCost))
  } else {
    // There is quantity above 1 and unit cost - total is calculated
    return Math.max(Number(quantity) * (Number(unitCost)), getTariffMinimumCost(tariffData.minimumCost)) 
  }
};

/**
 * gets an array of chargeable items for a given portCall
 * @param {PortCall} portcall An portcall object
 * @returns {Array} chargeableItems
 */
export const getPortCallChargeableItems = (portCall) => {
  const actions = portCall.actions;
  const actionsToBeInvoiced = getActionsToBeInvoiced(actions)
  const chargeableItems = [];
  if (actionsToBeInvoiced) {
    actionsToBeInvoiced.forEach(action => {
      if (action.chargeableItems) {
        chargeableItems.push(...action.chargeableItems);
      }
    })
  }
  return chargeableItems;
}

/**
 * Get the chargeable items gross total
 * @param {Array} chargeableItems An array of chargeable items
 * @returns {Number} Gross total
 */
export const getChargeableItemsGrossTotal = (chargeableItems) => {
  let grossTotal = 0
  if (!chargeableItems) return grossTotal;
  chargeableItems.forEach(chargeableItem => {
    grossTotal+= chargeableItem.total;
  });
  return grossTotal
}

/**
 * Get actions that are going to be invoiced
 * @param {Array} actions
 * @returns {Array} actions
 */
export const getActionsToBeInvoiced = (actions) => {
  return actions && actions
  .filter(a =>
    a.state !== ActionState.APPROVAL_DECLINED &&
    a.state !== ActionState.DELETED &&
    a.state !== ActionState.CANCELLED &&
    a.state !== ActionState.SUPERSEDED);
}