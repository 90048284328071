import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';

import {
  PropulsionTypeLabelKeys as pts,
  RudderTypeLabelKeys as rts,
  PropellerDirectionLabelKeys as pds
} from '../../constants/Propulsion';
import { MovementActionDisplayTypeKeys as mt } from '../../constants/MovementActionDisplayType';
import { EditorSelect } from '../Common/EditorSelect';
import { UnitsSymbolKeyFromString } from '../../constants/Units';
import { UIContext } from '../../contexts/ui';
import { Action, ActionPropulsionOperationalState } from '../../models';
import { ActionTypeIds } from '../../environment';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(() => ({
  formLabelLabel: {
    textTransform: 'uppercase',
    marginBottom: '9px',
    // marginTop: '26px',
  },
}));

export const ActionPropulsionEdit = ({
  action,
  setAction,
  vesselData,
}) => {
  const classes = useStyles();
  const [uiContext,] = useContext(UIContext);
  const { t } = useTranslation();

  const readOnly = uiContext.readOnly;
  
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = value === '' ? null : (type === 'number') ? parseFloat(value) : value;
    setAction(Action.copyOf(action, updated => { updated[name] = newValue; }));
  };

  return (
    <>
      <Typography
        className={classes.formLabelLabel}
        id="action-vessel-condition"
        variant="overline"
      >
        {action && action.type.id === ActionTypeIds.MOVEMENT && t('ActionPropulsionEdit.Labels.MovementCondition', {movement: t(mt[action.movementType])})}
      </Typography>
      {vesselData && vesselData.propulsionType &&
        <Typography>
          {t('ActionPropulsionEdit.Labels.PrimaryPropulsion')}:{' '}
          {vesselData.propellerCount &&
            `${vesselData.propellerCount} x `
          }
          {t(pts[vesselData.propulsionType])}
          {vesselData.properllerDirection &&
            ` (${pds[vesselData.properllerDirection]})`
          }
          {vesselData.propulsionPower && vesselData.propulsionPowerUnit &&
            ` ${vesselData.propulsionPower} ${t(UnitsSymbolKeyFromString(vesselData.propulsionPowerUnit))}`
          }
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementPropulsionState"
        label={t('ActionPropulsionEdit.Labels.PropulsionState')}
        name="movementPropulsionState"
        value={action.movementPropulsionState}
        onChange={handleChange}
      >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.PropulsionRemarks')}
        margin="normal"
        name="movementPropulsionRemarks"
        value={action.movementPropulsionRemarks || ''}
        onChange={handleChange}
        />

      {vesselData && vesselData && vesselData.rudderType &&
        <Typography>
          {t('Vessel.Labels.Rudder')}:{' '}
          {vesselData.rudderCount &&
            `${vesselData.rudderCount} x `
          }
          {rts[vesselData.rudderType]}
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementRudderState"
        label={t('ActionPropulsionEdit.Labels.RudderState')}
        name="movementRudderState"
        value={action.movementRudderState || ''}
        onChange={handleChange}
        >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.RudderRemarks')}
        margin="normal"
        name="movementRudderRemarks"
        value={action.movementRudderRemarks || ''}
        onChange={handleChange}
        />
      {vesselData && vesselData.fwdAzimuthCount && vesselData.fwdAzimuthPower && vesselData.fwdAzimuthPowerUnit &&
        <Typography>
          {t('Vessel.Labels.ForwardAzimuth')}:{' '}
          {vesselData.fwdAzimuthCount &&
            `${vesselData.fwdAzimuthCount} x ${vesselData.fwdAzimuthPower} ${t(UnitsSymbolKeyFromString(vesselData.fwdAzimuthPowerUnit))}`
          }
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementFwdAzimuthState"
        label={t('ActionPropulsionEdit.Labels.ForwardAzimuthState')}
        name="movementFwdAzimuthState"
        value={action.movementFwdAzimuthState}
        onChange={handleChange}
        >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.ForwardAzimuthRemarks')}
        margin="normal"
        name="movementFwdAzimuthRemarks"
        value={action.movementFwdAzimuthRemarks || ''}
        onChange={handleChange}
        />
      {vesselData && vesselData.aftAzimuthCount && vesselData.aftAzimuthPower && vesselData.aftAzimuthPowerUnit &&
        <Typography>
          {t('Vessel.Labels.AftAzimuth')}:{' '}
          {vesselData.aftAzimuthCount &&
            `${vesselData.aftAzimuthCount} x ${vesselData.aftAzimuthPower} ${t(UnitsSymbolKeyFromString(vesselData.aftAzimuthPowerUnit))}`
          }
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementAftAzimuthState"
        label={t('ActionPropulsionEdit.Labels.AftAzimuthState')}
        name="movementAftAzimuthState"
        value={action.movementAftAzimuthState}
        onChange={handleChange}
        >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.AftAzimuthRemarks')}
        margin="normal"
        name="movementAftAzimuthRemarks"
        value={action.movementAftAzimuthRemarks || ''}
        onChange={handleChange}
        />
      {vesselData && vesselData.fwdTunnelThrusterCount && vesselData.fwdTunnelThrusterPower && vesselData.fwdTunnelThrusterPowerUnit &&
        <Typography>
          {t('Vessel.Labels.ForwardTunnelThrusters')}:{' '}
          {vesselData.fwdTunnelThrusterCount &&
            `${vesselData.fwdTunnelThrusterCount} x ${vesselData.fwdTunnelThrusterPower} ${t(UnitsSymbolKeyFromString(vesselData.fwdTunnelThrusterPowerUnit))}`
          }
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementFwdTunnelThrusterState"
        label={t('ActionPropulsionEdit.Labels.ForwardTunnelThrustersState')}
        name="movementFwdTunnelThrusterState"
        value={action.movementFwdTunnelThrusterState}
        onChange={handleChange}
        >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.ForwardTunnelThrustersRemarks')}
        margin="normal"
        name="movementFwdTunnelThrusterRemarks"
        value={action.movementFwdTunnelThrusterRemarks || ''}
        onChange={handleChange}
        />
      {vesselData && vesselData.aftTunnelThrusterCount && vesselData.aftTunnelThrusterPower && vesselData.aftTunnelThrusterPowerUnit &&
        <Typography>
          {t('Vessel.Labels.AftTunnelThrusters')}:{' '}
          {vesselData.aftTunnelThrusterCount &&
            `${vesselData.aftTunnelThrusterCount} x ${vesselData.aftTunnelThrusterPower} ${t(UnitsSymbolKeyFromString(vesselData.aftTunnelThrusterPowerUnit))}`
          }
        </Typography>
      }
      <EditorSelect
        disabled={readOnly}
        labelId="movementAftAzimuthState"
        label={t('ActionPropulsionEdit.Labels.AftTunnelThrustersState')}
        name="movementAftTunnelThrusterState"
        value={action.movementAftTunnelThrusterState}
        onChange={handleChange}
        >
        <MenuItem value={ActionPropulsionOperationalState.FULLY_OPERATIONAL}>{t('ActionPropulsionOperationalState.Labels.FullyOperational')}</MenuItem>
        <MenuItem value={ActionPropulsionOperationalState.HAS_FAULT}>{t('ActionPropulsionOperationalState.Labels.HasFault')}</MenuItem>
      </EditorSelect>
      <TextField
        disabled={readOnly}
        variant="outlined"
        multiline
        fullWidth
        label={t('ActionPropulsionEdit.Labels.AftTunnelThrustersRemarks')}
        margin="normal"
        name="movementAftTunnelThrusterRemarks"
        value={action.movementAftTunnelThrusterRemarks || ''}
        onChange={handleChange}
      />
    </>
  )
}

export default ActionPropulsionEdit;