import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  makeStyles,
  Popover,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import MoveDepart from '../../icons/MoveDepart';
import { Action, ActionMovementType, PortCallStatus, TemplateSubType, PortCall } from '../../models';
import { 
  getActionLastLifecycle, 
  getActionLastArrival,
  getActionTime, 
  getActionableNext,
  getActionTodosAsync,
  getLastCancelledDeparture
} from '../../utils/getters';
import { DataStore } from 'aws-amplify';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { add, isBefore } from 'date-fns';
import { ActionTypeIds } from '../../environment';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useInitialActionState from '../../hooks/useInitialActionState';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import useTariffBooks from '../../hooks/useTariffBooks';
import { createAllChargeablesItemFromBooks } from '../../utils/tariffBook';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";

const useStyles = makeStyles(() => ({
  notchedOutline: {
    border: 0
  },
  reopenPortCallMessage: {
    paddingRight: '1rem',
    justifyContent: 'center',
    width: "100%"
  }
}));

const DepartureCreateDialog = ({ portCall, anchorEl, onClose }) => {
  const movementLocation = getActionLastArrival(portCall.actions)?.movementLocation || getLastCancelledDeparture(portCall.actions)?.movementLocation;
  const dataStoreContext = useContext(DataStoreContext);
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);
  const { tariffBooks } = useTariffBooks();

  const [departure, setDeparture] = useState(() => {
    // default to last arrival
    const lastLifecycleAction = getActionLastLifecycle(portCall.actions) || getLastCancelledDeparture(portCall.actions)
    const timePlanned = add(new Date(getActionTime(lastLifecycleAction)), { hours: 1 }).toISOString();
    // try to get template first
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_DEPARTURE);
    if (template) {
      // FIXME remove isTariffBookEnabled when feature flag removed
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(portCall?.vessel?.id), isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.movementLocation = movementLocation;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: getInitialActionState(portCall?.vessel?.id),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.DEPARTURE,
        timePlanned,
        movementLocation: movementLocation,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      });
    }
  });

  useEffect(() => {
    setDeparture(Action.copyOf(departure, updated => { 
      updated.state = getInitialActionState(portCall?.vessel?.id)
    }));
  }, [getInitialActionState])

  const prevActionTime = useMemo(() => getActionTime(getActionLastLifecycle(portCall.actions, departure)), [portCall.actions, departure.timePlanned]);
  const nextAction = useMemo(() => getActionableNext(portCall.actions, departure), [portCall.actions, departure.timePlanned]);


  // if there is a next action
  // or departure timePlanned is before previous action
  // return error message
  const checkDepartureTimePlanned = useCallback(() => {
    const timeBefore = prevActionTime && add(new Date(prevActionTime), { minutes: 1});
    const departureTime = new Date(departure.timePlanned);
    if(Boolean(nextAction) && timeBefore && isBefore(departureTime, timeBefore)) return t('PortCall.Errors.DepartureAfterArrival')
    return;
  },[departure.timePlanned, nextAction, prevActionTime, t]);
  
  // there should be a valid time planned
  // check the departure time
  const departureError = useMemo(() =>
    (Boolean(!departure.timePlanned) && t('PortCall.Errors.InvalidDateFormat') ) || checkDepartureTimePlanned(), 
    [departure.timePlanned, t, checkDepartureTimePlanned]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangeDepartureTime = (value) => {
    setDeparture(Action.copyOf(departure, updated => { updated.timePlanned = value; }));
  };

  const handleCreateDeparture = async () => {
    const todos = await getActionTodosAsync(departure);
    let chargeableItems = departure.chargeableItems;
    // FIXME remove if and set chargableItems when feature flag removed
    if(isTariffBookEnabled) {
      chargeableItems = createAllChargeablesItemFromBooks(
        departure.timePlanned,
        departure.type.id,
        departure.movementType,
        tariffBooks,
        dataStoreContext.tariffUnits);
    }
    if (portCall.status === PortCallStatus.CLOSED) {
      await DataStore.save(PortCall.copyOf(portCall, updated => {
        updated.status = PortCallStatus.IN_PORT;
      }));
    };
    await DataStore.save(Action.copyOf(departure, updated => {
      updated.todos = todos;
      updated.chargeableItems = chargeableItems;
    }));
    handleClose();
  };


  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            {/* Title  */}
            <Typography variant="h5">{t('DepartureCreateDialog.Labels.NewDeparture')}</Typography>
            {/* Close  */}
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box display="flex" height="5rem">
            {/* Time Picker */}
            <Box width='100%' mt={'0.25rem'} mb={'1rem'}>
              <StringKeyboardDateTimePicker
                warningPastDate={true}
                id='DeparturePlannedTimeInput'
                label={t('PortCall.Labels.PlannedTimeOfDeparture')}
                value={departure.timePlanned}
                onChange={handleChangeDepartureTime}
                error={Boolean(departureError)}
                helperText={departureError}
                okLabel={t('Common.Buttons.Confirm')}
              />
            </Box>
            {/* Berth Location */}
            <Box width='100%' ml={'1rem'} mr={'1rem'}>
              <TextField
                variant="outlined"
                label={t('PortCall.Labels.DepartureLocation')}
                fullWidth
                margin="normal"
                disabled
                value={movementLocation ? movementLocation.name : ''}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  },
                  startAdornment:
                    <>
                      {movementLocation && <MoveDepart color="primary" />}
                    </>
                }}
              />
            </Box>
          </Box>

          {portCall.status === PortCallStatus.CLOSED && 
            <Typography className={classes.reopenPortCallMessage}>
              {t('ActionDetails.Labels.ReopenPortCallMessage')}
            </Typography>
          }
          {/* Create Button */}
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end" m={'1rem'}>
            <Button
              id='DepartureCreateButton'
              variant="contained"
              color="primary"
              size="large"
              disabled={!!departureError}
              onClick={handleCreateDeparture}
            >
              {t('Common.Buttons.Create')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

DepartureCreateDialog.propTypes = {
  portCall: PropTypes.object,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
}

export default DepartureCreateDialog;
