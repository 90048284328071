
import { useContext, useMemo } from 'react'
import { getDateTimeSetting } from '../utils/getters';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { DefaultDateTimeFormat, dateTimeFormatString, timeFormatString, dateFormatSetting } from '../utils/dateUtils';
/**
 * Return a date/time setting. If key is set the individual unit from the array of units is returned
 * @param {String} key Key into unit map
 * @returns {Object} Unit setting
 */
const useDateTimeSetting = () => {
  const { settings } = useContext(DataStoreContext);
  const dateTimeSetting = useMemo(() => getDateTimeSetting(settings), [settings]);
  return {
    setting: dateTimeSetting ? dateTimeSetting : DefaultDateTimeFormat,
    dateTimeFormat: dateTimeSetting ? dateTimeFormatString(dateTimeSetting) : dateTimeFormatString(),
    dateFormat: dateTimeSetting ? dateFormatSetting(dateTimeSetting) : dateFormatSetting(),
    timeFormat: dateTimeSetting ? timeFormatString(dateTimeSetting) : timeFormatString()
  };
};

export default useDateTimeSetting;