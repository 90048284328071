import { TariffBook } from "../models";
import { v4 as uuidv4 } from 'uuid';
import duplicatedTariffBookName from "./duplicatedTariffBookName";

/**
 * Return an array of all initial and new tariff IDs from a book
 * @param {TariffBook} item TariffBook
 * @returns {Array} Array of tariff ID strings
 */
const getTariffIds = (item) => {
  let idObjects = [];
  for (let i = 0; i < item.tariffs.length; i++) {
    let idObject = { initialId: item.tariffs[i].id, newId: uuidv4() };
    idObjects.push(idObject);
  }

  return (idObjects);
};

/**
 * Generate a new set of default chargable items based on a remapping of tariff IDs
 * @param {TariffBook} item Tariff book 
 * @param {Array} tariffIds Array of objects returned from getTariffIds (initial and new tariff IDs) 
 * @returns {Array} Array of default chargeable items
 */
const getDefaultCharges = (item, tariffIds) => {
  let newDefaultCharges = [];

  // console.log(tariffIds)
  for (let i = 0; i < item.defaultCharges.length; i++) {
    let newDefaultItems = [];
    for (let j = 0; j < item.defaultCharges[i].defaultItems.length; j++) {
      const currentDefaultItem = item.defaultCharges[i].defaultItems[j];
      const newTariffId = tariffIds.find(e => e.initialId === currentDefaultItem.tariffId);
      let newDefaultItem = { ...currentDefaultItem, id: uuidv4(), tariffId: newTariffId.newId };
      newDefaultItems.push(newDefaultItem);
    };
    let newCharge = { ...item.defaultCharges[i], id: uuidv4(), defaultItems: newDefaultItems };
    newDefaultCharges.push(newCharge);
  }

  return newDefaultCharges;
};

/**
 * Generate a new set of tariffs based on a remapping of tariff IDs
 * @param {TariffBook} item TariffBook
 * @param {Array} tariffIds Array of objects returned from getTariffIds (initial and new tariff IDs)
 * @returns Array of tariff items
 */
const getTariffs = (item, tariffIds) => {
  let newTariffs = [];

  for (let i = 0; i < item.tariffs.length; i++) {
    const currentTariff = item.tariffs[i];
    const newTariffId = tariffIds.find(e => e.initialId === currentTariff.id);
    let newTariff = { ...currentTariff, id: newTariffId.newId };
    newTariffs.push(newTariff);
  }

  return newTariffs;
};

/**
 * Duplicate a tariff book. The returned tariff book will will be identical in structure but will
 * have regenerated IDs for tariffs and chargeable items.
 * @param {TariffBook} item Source tariff book 
 * @param {Array[TariffBook]} books Array of tariff books 
 * @returns {TariffBook} New tariff book model
 */
export const duplicateTariffBook = (item, books) => {

  const newName = duplicatedTariffBookName(item, books)
  
  const tariffIds = getTariffIds(item);
  const newTariffs = getTariffs(item, tariffIds);
  const newDefaultCharges = getDefaultCharges(item, tariffIds);
  const newTariffBook = {
    ...item,
    id: uuidv4(),
    defaultCharges: newDefaultCharges,
    tariffs: newTariffs,
    name: newName
  };
  const { _deleted, _lastChangedAt, _version, ...restObject } = newTariffBook;

  return (new TariffBook(restObject));
};