import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from "prop-types";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  DotsVertical,
  Check,
  DeleteOutline,
  UndoVariant,
  CloseCircleOutline,
  PlayCircleOutline
} from 'mdi-material-ui';
import { useHistory, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getAuditLogEntryByObjectId } from '../../graphql/queries';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import TimeInputDialog from '../Dialog/TimeInputDialog';
import { DataStore } from 'aws-amplify';
import { Action, ActionState } from '../../models';
import { ActionStateLabelKeys } from '../../constants/ActionStateLabel';
import { 
  getCustomActionLocation,
  getNextActionableAction, 
  getNextUndoableActions,
} from '../../utils/getters';
import {
  generateTimePlannedChecks, 
  CHECKS
} from '../../utils/generators';
import { DatastoreStatusContext } from '../../contexts/datastoreStatusContext';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import TextInputDialog from '../Dialog/TextInputDialog';
import CompletionTimeInputDialog from '../Dialog/CompletionTimeInputDialog';

// Function for rendering menu item with the needed propeties
const renderMenuItem = ({onClick, disabled, icon, primary, id}) => {
  return <MenuItem
    onClick={onClick}
    disabled={disabled}
    id={id}
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={primary} />
  </MenuItem>
}

const CustomActionMenu = ({
  action,
  portCall,
  readOnly
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // Confirm Props
  const [openConfirm, setOpenConfirm] = useState(false); 
  const [confirmProps, setConfirmProps] = useState(null);
  // Time Dialog Props
  const [openTime, setOpenTime] = useState(false);
  const [openCompletionTime, setOpenCompletionTime] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [timeProps, setTimeProps] = useState(null);
  const [completionTimeProps, setCompletionTimeProps] = useState(null);

  const isSynced = useContext(DatastoreStatusContext);
  // actionDisable include readOnly from permssions and sync status from datastore
  const actionDisable = readOnly || !isSynced;

  const history = useHistory();
  const params = useParams();
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();

  // hasAction object to determine the menu items being shown
  const [hasAction, setHasActions] = useState({});
  // hasActionExist - boolean for if there are any menu items
  const hasActionExist = hasAction && Object.keys(hasAction).length > 0;

  // LifeCycle of the custom action
  const hasLifeCycle = action && action.type.lifecycle;

  // Work out if the current action is the actionable id 
  const isActionableId = useMemo(() => {
    if(!hasLifeCycle) return ''
    const nextActionableActions =  portCall.actions && getNextActionableAction(portCall.actions) || [];
    if(nextActionableActions) return action.id === nextActionableActions.id; 
    return '';
  }, [portCall.actions, hasLifeCycle]);

  // Work out if current action can be undone 
  const isNextUndoableAction = useMemo(() => {
    if(!hasLifeCycle) return ''
    const nextUndoableActions = portCall.actions && getNextUndoableActions(portCall.actions) || [];
    if(nextUndoableActions.length > 0) return action.id === nextUndoableActions[0].id;
    return '';
  }, [portCall.actions, hasLifeCycle])


  // Name of the type
  const customActionName = action.type.name;

  // useEffect to work out what menu options are available
  useEffect(() => {
    // If the customAction doesn't have a lifeCycle
    // Show the delete menu item - canDelete
    // Show the cancel menu item - canCancel
    if(!hasLifeCycle) {
      setHasActions({ 
        ...hasAction,
        canDelete: action.state === ActionState.PLANNED || action.state === ActionState.CANCELLED,
        canCancel: action.state === ActionState.PLANNED,
        canStart: action.state === ActionState.PLANNED,
        canComplete: action.state === ActionState.IN_PROGRESS || action.state === ActionState.PLANNED,
        canUndoStarted: action.state === ActionState.IN_PROGRESS,
        canUndoCompleted: action.state === ActionState.COMPLETED
       });
    } else {
      // Determine if the custom action is Planned 
      const isCustomActionPlanned = action.state === ActionState.PLANNED;
      // If custom action planned or cancelled then can delete 
      const canDelete = isCustomActionPlanned || action.state === ActionState.CANCELLED;
      // If In progress then will record the time of the customAction
      const canHandleCustomActionRecord = action.state === ActionState.IN_PROGRESS;
      // Action can be undone
      const canUndo = (action.state === ActionState.IN_PROGRESS || action.state === ActionState.COMPLETED) && isNextUndoableAction;
      setHasActions({
        ...hasAction,
        canDelete,
        canCancel: isCustomActionPlanned,
        canHandleCustomActionStart: isCustomActionPlanned,
        canHandleCustomActionRecord,
        canUndo,
      })
    }
  }, [action, portCall])

  // Update action with the state
  // If there are any properties in updated objects
  // Run through them
  const updateActionState = (updateAction, state, updates) => {
    DataStore.save(Action.copyOf(updateAction, updated => {
      updated.state = state;
      updates && Object.keys(updates).forEach(key => {
        updated[key] = updates[key];
      });
    }));
  }

  // Close mehtod for the menu
  const handleClose = (e) => {
    setAnchorEl(null);
    e && e.preventDefault();
  }

  // Delete Method
  // Close menu and the open Confirm Dialog
  // If Dialog Confirmed Delete Action
  // Push the page to the portCall page
  const handleDelete = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.DeleteActionDialogTitle', { actionName: customActionName }),
      message: t('CustomActionMenu.Labels.DeleteActionDialogMessage', { actionName: customActionName }),
      onConfirm: async () => {
        await DataStore.save(Action.copyOf(action, updated => {
          updated.state = ActionState.DELETED
        }));
        setOpenConfirm(false);
        if(params.actionId && params.actionId === action.id){
          history.push(`/port-call/${portCall.id}`);
        }
      }
    });
    setOpenConfirm(true);
  }

  // Record the time of when the custom action is recorded
  // On confirm set the action to In Progress and with a timeActual Value
  const handleCustomActionStart = () => {
    handleClose();
    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BOTH); 
    setTimeProps({
      title: t('CustomActionMenu.Labels.StartActionDialogTitle', { actionName: customActionName, vesselName: portCall.vesselData.name}),
      inputLabel: t('CustomActionMenu.Labels.StartActionDialogTitle', { actionName: customActionName }),
      initialValue: action.timePlanned,
      checks,
      onConfirm: (value) => {
        updateActionState(action, ActionState.IN_PROGRESS, { timeActual: value });
        setOpenTime(false);
      }
    });
    setOpenTime(true);
  };


  // Complete the custom action
  // On confirm set the action to COMPLETED
 const handleCustomActionRecord = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.RecordActionDialogTitle', { actionName: customActionName, vesselName: portCall.vesselData.name }),
      message: t('CustomActionMenu.Labels.RecordActionDialogMessage', { actionName: customActionName, vesselName: portCall.vesselData.name }),
      onConfirm: () => {
        updateActionState(action, ActionState.COMPLETED);
        setOpenConfirm(false);
      }
    });
    setOpenConfirm(true);
  };

  // Start the custom action. 
  //This is separate not related to the lifecycle operation (Start)
  const handleCustomActionStartNoLifecycle = () => {
    handleClose();
    setTimeProps({
      title: t('CustomActionMenu.Labels.StartActionDialogTitle', { actionName: customActionName, vesselName: portCall.vesselData.name}),
      inputLabel: t('CustomActionMenu.Labels.StartActionDialogInput', { actionName: customActionName }),
      initialValue: action.timePlanned,
      hasComment: true,
      onConfirm: (value, comment) => {
        updateActionState(action, ActionState.IN_PROGRESS, { timeActual: value, auditMeta: {...auditMeta, comment} });
        setOpenTime(false);
      }
    });
    setOpenTime(true);
 }

  // Complete the custom action. 
  //This is separate not related to the lifecycle operation (Record)
  const handleCustomActionComplete = () => {
    handleClose();
    setCompletionTimeProps({
      title: t('CustomActionMenu.Labels.RecordActionDialogTitle', { actionName: customActionName, vesselName: portCall.vesselData.name}),
      actionName: customActionName,
      hasComment: true,
      startTimeDisabled: !!(action.timeActual),
      initialValue: action.timeActual? action.timeActual : action.timePlanned,
      movementLocation: action.movementLocation,
      onConfirm: async (timeActual, timeCompleted, comment) => {
        const actions = await DataStore.query(Action, a => a.actionPortCallId_('eq', action?.portCall?.id));
        const actionLocation = timeActual && getCustomActionLocation(timeActual, actions)
        updateActionState(action, ActionState.COMPLETED, { timeCompleted, timeActual, auditMeta: {...auditMeta, comment}, movementLocation: actionLocation });
        setOpenCompletionTime(false);
      }
    })
    setOpenCompletionTime(true);
 }

  // Confirmation Dialog for Undoing Action
  // newState is worked out based on current state
  // input is worked out based on newState
  // Action is updated
  const handleCustomActionUndo = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.UndoActionDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('CustomActionMenu.Labels.UndoActionDialogMessage', { actionName: customActionName, actionState: ActionStateLabelKeys[action.state].toLowerCase(), vesselName: portCall.vesselData.name, actionMovementLocationId: null, movementLocation: null }),
      onConfirm: async () => {
        const newState = action.state === ActionState.COMPLETED ? ActionState.IN_PROGRESS : ActionState.PLANNED;
        const input = newState === ActionState.IN_PROGRESS ? {} : { timeActual: null};
        updateActionState(action, newState, input);
        setOpenConfirm(false);
      }
    });
    setOpenConfirm(true);
  };

  const handleCustomActionUndoCompleted = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.UndoActionDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('CustomActionMenu.Labels.UndoActionDialogMessage', { actionName: customActionName, actionState: t(ActionStateLabelKeys[action.state]).toLowerCase(), vesselName: portCall.vesselData.name }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        const result = await API.graphql(graphqlOperation(getAuditLogEntryByObjectId, {
          objectId: action.id,
          sortDirection: 'DESC',
          limit: 4,
          filter: {
            type: {eq: "AUDIT_ACTION_STATE"}
          }
        }));

        let newState = ActionState.IN_PROGRESS;
        if(result?.data?.getAuditLogEntryByObjectId?.items?.length > 0){
          const auditLog = result?.data?.getAuditLogEntryByObjectId?.items[0]
          if(auditLog?.old){
            try{
              const oldState = JSON.parse(auditLog?.old);
              if(oldState?.state === 'PLANNED'){
                newState = ActionState.PLANNED;
              }
            }catch(err){
              console.error('Unable to parse auditLog(old state): ', auditLog);
            }
          }
        }
        const newTimes = (newState === ActionState.IN_PROGRESS) ? {timeCompleted: null} : {timeCompleted: null, timeActual: null}
        updateActionState(action, newState, { ...newTimes, auditMeta: {...auditMeta, comment}, actionMovementLocationId: null, movementLocation: null});
        setOpenText(false);
      }
    });
    setOpenText(true);
  };

  const handleCustomActionUndoStarted = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.UndoActionDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('CustomActionMenu.Labels.UndoActionDialogMessage', { actionName: customActionName, actionState: t(ActionStateLabelKeys[action.state]).toLowerCase(), vesselName: portCall.vesselData.name }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        updateActionState(action, ActionState.PLANNED, { timeActual: null, auditMeta: {...auditMeta, comment}});
        setOpenText(false);
      }
    });
    setOpenText(true);
  }

  const handleCustomActionCancel = () => {
    handleClose();
    setConfirmProps({
      title: t('CustomActionMenu.Labels.CancelActionDialogTitle', { actionName: customActionName, vesselName: portCall.vesselData.name }),
      message: t('CustomActionMenu.Labels.CancelActionDialogMessage', { actionName: customActionName, vesselName: portCall.vesselData.name }),
      onConfirm: () => {
        updateActionState(action, ActionState.CANCELLED);
        setOpenConfirm(false);
      }
    });
    setOpenConfirm(true);
  };

  return (
    <>
      {/* Dot Menu - onClick open Menu */}
      <IconButton
        onClick={e => { setAnchorEl(e.currentTarget); e.preventDefault(); }}
        disabled={!hasActionExist}
        style={{ pointerEvents: 'all' }}
        className="ActionMenuButton"
      >
        <DotsVertical />
      </IconButton>
      {/* Menu */}
      {
        anchorEl && hasActionExist &&
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {/*  Start Custom Action   */}
          {
            hasAction.canHandleCustomActionStart &&
            renderMenuItem({
              onClick: handleCustomActionStart ,
              disabled: !isActionableId || readOnly,
              icon: <Check />,
              primary: t('CustomActionMenu.Labels.StartAction', { actionName: customActionName})
            })
          }
          {/*  Complete Custom Action   */}
          {
            hasAction.canHandleCustomActionRecord &&
            renderMenuItem({
              onClick: handleCustomActionRecord ,
              disabled: !isActionableId || actionDisable,
              icon: <Check />,
              primary: t('CustomActionMenu.Labels.RecordAction', { actionName: customActionName})
            })
          }
          {/*  Undo Custom Action   */}
          {
            hasAction.canUndo &&
            renderMenuItem({
              onClick: handleCustomActionUndo,
              disabled: actionDisable,
              icon: <UndoVariant />,
              primary: t('CustomActionMenu.Labels.UndoAction', { actionName: customActionName})
            })
          }
          {/*  Start Custom Action(Not lifecycle)   */}
          {
            (hasAction.canStart) &&
            renderMenuItem({
              onClick: handleCustomActionStartNoLifecycle ,
              disabled: actionDisable,
              icon: <PlayCircleOutline />,
              primary: t('CustomActionMenu.Labels.StartAction', { actionName: customActionName}),
              id: "StartActionMenuItem"
            })
          }
          {/*  Complete Custom Action (Not lifecycle)  */}
          {
            hasAction.canComplete &&
            renderMenuItem({
              onClick: handleCustomActionComplete,
              disabled: actionDisable,
              icon: <Check />,
              primary: t('CustomActionMenu.Labels.CompleteAction', { actionName: customActionName}),
              id: "CompleteActionMenuItem"
            })
          }
          {
            hasAction.canUndoCompleted &&
            renderMenuItem({
              onClick: handleCustomActionUndoCompleted,
              disabled: actionDisable,
              icon: <UndoVariant />,
              primary: t('CustomActionMenu.Labels.UndoCompleted'),
              id: "UndoCompletedActionMenuItem"
            })
          }
          {
            hasAction.canUndoStarted && 
            renderMenuItem({
              onClick: handleCustomActionUndoStarted,
              disabled: actionDisable,
              icon: <UndoVariant />,
              primary: t('CustomActionMenu.Labels.UndoStarted'),
              id: "UndoInProgressActionMenuItem"
            })
          }
          {/*  Cancel Custom Action   */}
          {
            hasAction.canCancel &&
            renderMenuItem({
              onClick: handleCustomActionCancel,
              disabled: actionDisable,
              icon: <CloseCircleOutline />,
              primary: t('CustomActionMenu.Labels.CancelAction', { actionName: customActionName}),
              id: "CancelActionMenuItem"
            })
          }
          {/* Delete Custom Action  */}
          {
            hasAction.canDelete &&
            renderMenuItem({
              onClick: handleDelete,
              disabled: actionDisable,
              icon: <DeleteOutline />,
              primary: t('CustomActionMenu.Labels.DeleteAction', { actionName: customActionName}),
              id: "DeleteActionMenuItem"
            })
          }
        </Menu>
      }
      <ConfirmDialog {...confirmProps} onClose={() => setOpenConfirm(false)} open={openConfirm} />
      {openTime && <TimeInputDialog {...timeProps} onClose={() => setOpenTime(false)} open={openTime} />}
      {openCompletionTime && <CompletionTimeInputDialog {...completionTimeProps} onClose={() => setOpenCompletionTime(false)} open={openCompletionTime} />}
      {openText && <TextInputDialog {...confirmProps} onClose={() => setOpenText(false)} open={openText} />}
    </>
  )
}

CustomActionMenu.propTypes = {
  action: PropTypes.object,
  portCall: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default CustomActionMenu;