import React from 'react';
import { Box, Typography } from '@material-ui/core';
import EditorField from '../Common/EditorField';
import { PortCallDetailsHref } from '../../constants/PortCallDetails';
import { useTranslation } from 'react-i18next';

// Simple display of an unknown vessel just showing the name
const UnknownVesselDisplayField = ({ vessel }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL}
        variant="h6"
      >
        {t("Vessel.Labels.Vessel")}
      </Typography>
      <Typography
        id={PortCallDetailsHref.HREF_IDENTITY}
        variant="overline"
      >
        {t("Vessel.Labels.Identity")}
      </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.TemporaryName")}
        name="name"
        value={vessel.name}
      />
    </Box>
  )
};

export default UnknownVesselDisplayField;
