import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';

export const customOnCreateVoyageSubscription = /* GraphQL */ `
subscription OnUpdateVoyage {
  onUpdateVoyage {
    id
    name
    status
    vessel {
      id
      imo
      mmsi
      callSign
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        flag
      }
    }
    voyageFirstDeparture
    voyageLastArrival
    voyageVesselId
  }
}
`;

const buildListVoyagesQuery = (limit, nextToken, filter) => {
  const token = (nextToken == null) ? null : `"${nextToken}"`;
  const queryFilter = filter ? filter : null;

  return /* GraphQL */ `
  query voyages {
    listVoyages (
      nextToken: ${token}
      limit: ${limit}  
      filter: ${queryFilter}
    ) {
      items {
        id
        name
        vessel {
          mmsi
          imo
          vesselData {
            name
          }
        }
        voyageVesselId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }`
};

const buildGetVoyageQuery = (id) => {
  return /* GraphQL */`
  query GetVoyage($id: ID!) {
    getVoyage (id: $id) {
      id
      name
      status
      vessel {
        vesselData {
          name
          imo
          mmsi
          flag
          yearBuilt
        }
      }
      actions {
        items {
          id
          type {
            id
          }
          portCall {
            id
            status
          }
          portCallArchived
          state
          timeActual
          timePlanned
          timeEstimated
          timeEstimatedUpdatedAt
          actionTypeId
          movementType
          movementLocation {
            id
            name
            locationParentId
            parentName
            type
          }
        }
      }
    }
  }`
};

/**
 * Get a voyage with actions by ID
 * @param {String} voyageId 
 * @returns {Voyage}
 */
export const getVoyageById = async (voyageId) => {
  //console.log(gql(buildGetVoyageQuery(voyageId)));
  const { data } = await API.graphql(graphqlOperation(buildGetVoyageQuery(voyageId), { id: voyageId }));
  return data ? data['getVoyage'] : undefined;
};

/**
 * Fetch list voyages from AppSync matching optional filter
 * @param {String} filter AppSync query filter. If undefined all voyages are fetched
 * @example `{ voyageVesselId: { eq: "937a2e29-0747-4af7-821b-880dbf8286bf" } }`
 * @returns {Array[Voyage]}
 */
export const fetchVoyages = async (filter) => {
  const QueryLimit = 500;
  let nextToken = null;
  let voyages = []

  try {
    do {
      const query = buildListVoyagesQuery(QueryLimit, nextToken, filter);
      const result = await API.graphql(graphqlOperation(gql(query)));
      voyages = voyages.concat(result?.data?.listVoyages.items);
      nextToken = result?.data?.listVoyages.nextToken;
    } while(nextToken !== null);
  
    return voyages.filter(el => el._deleted !== true);

  } catch(error) {
    return [];
  }
};

const getVoyageName = /* GraphQL */ `
  query GetVoyage($id: ID!) { 
    getVoyage(id: $id) {
      id
      name
    }
  }
`;

const getVoyageLastArrival = /* GraphQL */ `
  query GetVoyage($id: ID!) { 
    getVoyage(id: $id) {
      voyageLastArrival
    }
  }
`;

/**
 * Checks if an arrival is the voyage last arrival bookend
 * @param {String} arrivalId 
 * @param {String} voyageId
 * @returns {Boolean} True if the arrival is the voyage last arrival bookend
 */
export const isVoyageLastArrival = async (arrivalId, voyageId) => {
  if (!arrivalId || !voyageId) return false;
  
  const { data } = await API.graphql(graphqlOperation(getVoyageLastArrival, { id: voyageId }));
  const voyage = data ? data['getVoyage'] : undefined;
  if (!voyage?.voyageLastArrival) return false;
  
  const lastArrival = JSON.parse(voyage?.voyageLastArrival)
  return lastArrival.id === arrivalId; 
};

export const getVoyageNameById = async (voyageId) => {
  const { data } = await API.graphql(graphqlOperation(getVoyageName, { id: voyageId }));
  return data ? data['getVoyage'] : undefined;
};
