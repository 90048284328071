import { Box } from "@material-ui/core";
import VoyageAction from "./VoyageAction";
import VoyageTimeLineLocation from "./VoyageTimelineLocation";

const VoyageActionsCard = ({ voyageMid, voyageFirstDeparture, lastMovement, firstMovement, locationMap, classes }) => {

  return (
    voyageMid?.map((portCall, index) => {
      const actions = portCall?.actions;
      const isLastTimelineItem = lastMovement?.portCall?.id === portCall?.portCall?.id;
      const isFirstTimelineItem = firstMovement?.portCall?.id === portCall?.portCall?.id && index === 0;
      const nextActions = voyageMid[index + 1]?.actions;
      const isNextLast = voyageMid[index + 1]?.portCall?.id === lastMovement?.portCall?.id;

      let classesStyle = null;
      if (isNextLast) {
        classesStyle = `${classes.stripe} ${classes.actionTimelinePosition} ${nextActions?.length === 1 ? actions?.length === 1 ? classes.bookendToActionStripe : classes.actionsStripe : actions?.length === 1 ? classes.actionToActionsLastStripe : classes.lastStripe}`;
      }
      else {
        if (nextActions?.length === 1) {
          classesStyle = `${classes.stripe} ${classes.actionTimelinePosition} ${actions?.length > 1 ? classes.actionsStripe : classes.actionStripe}`;
        } else if (nextActions?.length > 1) {
          classesStyle = `${classes.stripe} ${classes.actionTimelinePosition} ${classes.actionsStripe}`;
        } else if (nextActions?.length === 0 && isLastTimelineItem) {
          classesStyle = `${classes.noHeight}`;
        }
      }
      if (isFirstTimelineItem && !voyageFirstDeparture && actions?.length > 1) {
        classesStyle += ` ${classes.firstActionTimelinePosition}`;
      }
      return (
        <Box key={portCall?.portCall?.id}>
          <Box flexDirection="row" display="flex">
            <Box className={classes.locationContainer}>
              <VoyageTimeLineLocation location={actions[0]?.movementLocation} locationMap={locationMap} />
            </Box>
            <Box className={classes.circleWrapper}>
              <div className={`${classes.circle} circle`} width='3rem' height='3rem' />
              <Box className={classesStyle} />
            </Box>
            <Box className={classes.timelineActionsContainer}>
              <Box>
                {
                  actions?.map((action) =>
                    <Box className={classes.row} key={action?.id}>
                      <VoyageAction portCall={portCall?.portCall} key={action.id} disableGutters={true} action={action} isDelay={false} />
                    </Box>
                  )
                }
              </Box>
            </Box>
          </Box>
        </Box>
      )
    })
  )
}
export default VoyageActionsCard;