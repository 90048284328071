export const VesselTableHeaders = {
  Vessel: {label: 'Vessel.Labels.Vessel', description: 'VesselList.Labels.VesselDesc'},
  IMO: {label: 'Vessel.Labels.IMO', description: 'VesselList.Labels.ImoDesc'},
  MMSI: {label: 'Vessel.Labels.MMSI', description: 'VesselList.Labels.MmsiDesc'},
  CallSign: {label: 'Vessel.Labels.CallSign', description: 'VesselList.Labels.CallSignDesc'},
  Dimensions: {label: 'Vessel.Labels.Dimensions', description: 'VesselList.Labels.DimensionsDesc'},
  GrossTonnage: {label: 'VesselList.Labels.GrossTonnage', description: 'VesselList.Labels.GrossTonnageDesc'},
  NetTonnage: {label: 'VesselList.Labels.NetTonnage', description: 'VesselList.Labels.NetTonnageDesc'},
  DeadweightTonnage: {label: 'VesselList.Labels.DeadweightTonnage', description: 'VesselList.Labels.DeadweightTonnageDesc'},
  PortOfRegistry: {label: 'VesselList.Labels.PortOfRegistry', description: 'VesselList.Labels.PortOfRegistryDesc'},
  YearBuilt: {label: 'Vessel.Labels.YearBuilt', description: 'VesselList.Labels.YearBuiltDesc'},
  Type: {label: 'VesselList.Labels.Type', description: 'VesselList.Labels.TypeDesc'},
  Flag: {label: 'Vessel.Labels.Flag', description: 'VesselList.Labels.FlagDesc'},
  Certificates: {label: 'VesselList.Labels.Certificates', description: 'VesselList.Labels.CertificatesDesc'}
}