import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { VoyageStatus } from "../models";
import { API, graphqlOperation } from "aws-amplify";
import { onCreateVoyage, onDeleteVoyage } from "../graphql/subscriptions";
import { buildVoyageQuery } from "../components/Voyage/utils";
import gql from "graphql-tag";
import { customOnCreateVoyageSubscription } from "../utils/voyages";

const useVoyageList = (liveVoyages=false) => {
  const initialLoad = useRef(true);
  const [voyages, setVoyages] = useState(null);

  const updateVoyage = useCallback((voyage) => {
    const { voyageFirstDeparture, voyageLastArrival, ...items } = voyage || {};
    return items ? {
      ...items,
      voyageFirstDeparture: JSON.parse(voyageFirstDeparture),
      voyageLastArrival: JSON.parse(voyageLastArrival)
    } : null;
  }, []);

  const filter = liveVoyages ?
    `{
    and: [
      { status: { ne: ${VoyageStatus.CANCELLED}  } }
      { status: { ne: ${VoyageStatus.DELETED}  } }
      { status: { ne: ${VoyageStatus.COMPLETED}  } }
    ]
  }` : `{ status: { ne: ${VoyageStatus.DELETED}  } }`

  useEffect(() => {
    let nextToken;
    let items = [];
    let promise = null;

    if (initialLoad.current) {
      const update = async () => {
        while (nextToken !== null) {
          const query = buildVoyageQuery({ nextToken: nextToken, filter: filter });
          promise = API.graphql(graphqlOperation(gql(query)));
          const result = await promise;
          //console.log("Queried listVoyages", JSON.stringify({ nextToken }));
          nextToken = result?.data?.listVoyages?.nextToken;
          items = items.concat(result?.data?.listVoyages?.items);
        }
        //console.log('items ', items.length);

        const updatedVoyages = items?.map((voyage) => updateVoyage(voyage, voyage?.actions?.items));
        updatedVoyages && setVoyages(updatedVoyages)
      };
      update();
    }

    const subscriptions = [];
    subscriptions.push(API.graphql(graphqlOperation(customOnCreateVoyageSubscription)).subscribe({
      next: ({ value }) => {
        const updatedVoyage = value?.data?.onUpdateVoyage;
        //console.log("-----onUpdateVoyage-----")
        //console.log({ updatedVoyage });
        updatedVoyage && setVoyages(prev =>
          prev?.map((voyage) => voyage?.id === updatedVoyage?.id ? {
            ...updatedVoyage,
            voyageFirstDeparture: JSON.parse(updatedVoyage?.voyageFirstDeparture),
            voyageLastArrival: JSON.parse(updatedVoyage?.voyageLastArrival)
          } : voyage)
        );
      }
    }));
    subscriptions.push(API.graphql(graphqlOperation(onCreateVoyage)).subscribe({
      next: ({ value }) => {
        const newVoyage = value?.data?.onCreateVoyage;
        //console.log({ newVoyage });
        newVoyage && setVoyages(prev => [...(prev || []), newVoyage]);
      }
    }));
    subscriptions.push(API.graphql(graphqlOperation(onDeleteVoyage)).subscribe({
      next: ({ value }) => {
        const deletedVoyage = value?.data?.onDeleteVoyage;
        //console.log({ deletedVoyage });
        deletedVoyage && setVoyages(prev => !prev ? prev :
          prev.map((voyage) => voyage?.id === deletedVoyage.id ? null : voyage));
      }
    }));
    return () => {
      API.cancel(promise);
      subscriptions.forEach((s) => s.unsubscribe())
    }
  }, [updateVoyage]);

  const filterdVoyage = useMemo(() => voyages?.filter((voyage) => voyage
    && voyage?._deleted !== true
    && (
      liveVoyages ?
        ![VoyageStatus.COMPLETED, VoyageStatus.CANCELLED, VoyageStatus.DELETED].includes(voyage?.status) :
        voyage?.status !== VoyageStatus.DELETED
    )
  ) || [], [voyages, liveVoyages]);
  return filterdVoyage;
}
export default useVoyageList;