import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, CardContent, FormControl, FormHelperText, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import useApprovalSetting from '../../hooks/useApprovalSetting';
import VesselListEditor from '../Vessel/VesselListEditor';
import { useTranslation } from 'react-i18next';

const AdminApproval = () => {
  const { t } = useTranslation();
  const { appApprovalEnabled, approvalExceptionList, updateApprovalSetting } = useApprovalSetting()

  const [loading, setLoading] = useState(false)

  const handleApprovalEnabled = useCallback((event) => {
    setLoading(true)
    updateApprovalSetting(event?.target?.checked ?? false, approvalExceptionList);
  }, [approvalExceptionList, updateApprovalSetting]);

  const handleApprovalExceptionListUpdate = useCallback((newList) => {
    setLoading(true)
    updateApprovalSetting(appApprovalEnabled, newList);
  }, [appApprovalEnabled, updateApprovalSetting]);

  useEffect(() => {
    setLoading(false)
  }, [approvalExceptionList])

  return (
    <Box display="flex" flexGrow="1" flexDirection="column">
      <Box height="2rem" flex="none"/>
      <Box display="flex" flex="none">
        <CardContent style={{ flexGrow: 1 }}>
          <FormControl component="div">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch 
                    checked={appApprovalEnabled}
                    onChange={handleApprovalEnabled} />
                }
                label={t('AdminApproval.Labels.EnableSecondaryApproval')}
              />
            </FormGroup>
            <FormHelperText>{t('AdminApproval.Labels.EnableSecondaryApprovalHelp')}</FormHelperText>
          </FormControl>
        </CardContent>
      </Box>
      <Box display="flex" flexDirection="column" flexGrow="1">
        <CardContent style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Typography variant="body1" style={{flex: "none" }}>{t('AdminApproval.Labels.VesselExceptionList')}</Typography>
          <VesselListEditor
            vesselList={approvalExceptionList}
            getVesselValue={(v)=>v.id}
            displayVesselValueIfNotFound={true}
            onUpdate={handleApprovalExceptionListUpdate}
            disabled={!appApprovalEnabled || loading}
          />
        </CardContent>
      </Box>
    </Box>
  );
};

export default AdminApproval;