import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Vessel, VesselData } from '../../models';
import VesselForm from '../QuickPortCall/VesselForm';

const VesselCreatePage = ({}) => {
  const history = useHistory();
  const { disablePortcallUpdate } = useParams()
  const [vessel, setVessel] = useState(new Vessel({ vesselData: new VesselData({ name: '' }) }));
  return (
    <VesselForm
      vessel={vessel}
      setVessel={setVessel}
      showTitle
      onBack={() => history.goBack()}
      disablePortcallUpdate={disablePortcallUpdate}
    />
  );
};

export default VesselCreatePage;
