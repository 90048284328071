/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";
import { CountryLanguageMap } from '../translations';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({

  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function CountrySelect({ label, onChange, value, disabled }) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [allCodes,] = useState(Object.keys(countries.getAlpha2Codes()));

  const getCountryNameByCode = useCallback((code) => countries.getName(code, CountryLanguageMap[i18n.language].lang), [i18n.language]);

  useEffect(() => {
    countries.registerLocale(CountryLanguageMap[i18n.language].locale);
    setSelectedCountryCode('');//reset selected value after changing language
  }, [i18n.language]);

  useEffect(() => {
    if(selectedCountryCode !== value) {
      setSelectedCountryCode(value);// update selected value with the actual value to get the correct country name
    }
  }, [selectedCountryCode, value]);

  return (
    <Autocomplete
      options={allCodes}
      classes={classes}
      autoHighlight
      getOptionLabel={code => getCountryNameByCode(code)}
      renderOption={code => (
        <React.Fragment>
          <span>{countryToFlag(code)}</span>
          {getCountryNameByCode(code)} ({code})
        </React.Fragment>
      )}
      value={selectedCountryCode && allCodes.find(c => c === selectedCountryCode)}
      onChange={(_, value) => {
        setSelectedCountryCode(value);
        return onChange(value || '')
      }}
      renderInput={params => { 
        delete params.inputProps.className;
        delete params.InputProps.className
        return (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="normal"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'disabled', // disable autocomplete and autofill
          }}
        />
      ) }}
      disabled={disabled}
    />
  );
}
