import { CrosshairsGps } from 'mdi-material-ui';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SpotlightMenuOption = ({handleOpenMapDialog}) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      onClick={handleOpenMapDialog}
      id={'SpotlightMenuItem'}
    >
      <ListItemIcon>
        <CrosshairsGps />
      </ListItemIcon>
      <div display='flex'>
        <ListItemText primary={t('PortCallMenuItem.Labels.Spotlight')} />
      </div>
    </MenuItem>
  );
};

export default SpotlightMenuOption;