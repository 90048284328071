/* eslint-disable import/no-anonymous-default-export */
import { Vessel, Certificate } from '../../models';
import ListEditor from '../Common/ListEditor';
import { useTranslation } from 'react-i18next';
import CertificateTypeAutocomplete from './CertificateTypeAutocomplete';
import { v4 as uuidv4 } from 'uuid';
import StringKeyboardDatePicker from '../Common/StringKeyboardDatePicker';
import { makeStyles, TextField } from '@material-ui/core';
import { useContext, useState } from 'react';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { set } from 'date-fns';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { getCertificateName } from '../../utils/getters';

const useStyles = makeStyles(theme => ({
  typeAutocomplete: {
    minWidth: "18rem"
  },
  dateTimePicker: {
    minWidth: "11rem"
  }
}));

export default ({ vessel, setVessel, disabled }) => {

  const { t } = useTranslation();
  const { certificateType } = useContext(DataStoreContext);
  const classes = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deletedCertificate, setDeletedCertificate] = useState({ index: undefined, name: t('CertificateType.Labels.TypeNotSpecified') })

  const setListitem = (value) => {
    setVessel(prev => {
      const index = prev.vesselData.certificates.findIndex(c => c.id === value.id);
      return Vessel.copyOf(prev, updated => {
        const prevValue = updated.vesselData.certificates[index];
        let newValue = value;
        if (prevValue?.type !== newValue?.type) {
          const type = certificateType.find(ct => ct.id === newValue.type);
          const categories = type?.certificateTypeCategoryId && certificateType.find(ct => ct.id === type.certificateTypeCategoryId);

          newValue.typeName = type?.name;
          newValue.typeCategory = categories?.id;
          newValue.typeCategoryName = categories?.name;
        }
        updated.vesselData.certificates = [...updated.vesselData.certificates];
        updated.vesselData.certificates.splice(index, 1, newValue);
      });
    });
  }

  const handleCreateCertificate = () => {
    setVessel(prev => Vessel.copyOf(prev, updated => {
      updated.vesselData.certificates = [
        ...updated.vesselData.certificates || [],
        new Certificate({
          id: uuidv4(),
          validFromDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
          validToDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString()
        })
      ];
    }));
  }
  const handleDeleteCertificate = (index) => {
    const certificate = vessel.vesselData.certificates[index];
    const certName = getCertificateName(certificate, t);
    setDeletedCertificate({ index: index, name: `${certName}` });
    setShowConfirmDelete(true);
  }
  return (
    <>
      <ListEditor
        id="CertificateEditor"
        allowEmptyValue
        list={vessel.vesselData.certificates}
        setListItem={setListitem}
        createLabel={t('CertificateType.Buttons.AddCertificate')}
        onCreate={handleCreateCertificate}
        onDelete={handleDeleteCertificate}
        renderItem={(item, onChange) =>{
          const type = item.type && certificateType.find(ct => ct.id === item.type);
          const certificate = item.typeCategory && certificateType.find(ct => ct.id === item.typeCategory);
          const value = type && certificate && {...type,category:{...certificate}};
          return(<>
            <CertificateTypeAutocomplete
              required
              filter={ct => ct.certificateTypeCategoryId}
              value={value || []}
              label={t('CertificateType.Labels.CertificateType')}
              onChange={(value) => { onChange({ target: { name: "type", value: value.id } }) }}
              fullWidth
              disabled={disabled}
              className={`${classes.typeAutocomplete} CertificateTypeInput`}
            />
            <StringKeyboardDatePicker
              className={classes.dateTimePicker}
              value={item.validFromDate}
              required
              onChange={value => onChange({ target: { name: "validFromDate", value: value ? new Date(value).toISOString() : value } })}
              okLabel={t('Common.Buttons.Confirm')}
              inputVariant="outlined"
              label={t('CertificateType.Labels.ValidFrom')}
              fullWidth
              margin="normal"
              disabled={disabled}
            />
            <StringKeyboardDatePicker
              className={`${classes.dateTimePicker} CertificateValidToInput`}
              value={item.validToDate}
              required
              onChange={value => onChange({ target: { name: "validToDate", value: value ? new Date(value).toISOString() : value } })}
              okLabel={t('Common.Buttons.Confirm')}
              error={item.validToDate < item.validFromDate}
              inputVariant="outlined"
              label={t('CertificateType.Labels.ValidTo')}
              fullWidth
              margin="normal"
              disabled={disabled}
            />
            <TextField
              name="referenceNumber"
              value={item.referenceNumber || ""}
              label={t('CertificateType.Labels.ReferenceNumber')}
              onChange={onChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disabled}
            />
            <TextField
              name="nameOfHolder"
              value={item.nameOfHolder || ""}
              label={t('CertificateType.Labels.NameOfHolder')}
              onChange={onChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={disabled}
            />
            <TextField
              name="notes"
              value={item.notes || ""}
              label={t('CertificateType.Labels.Notes')}
              onChange={onChange}
              variant="outlined"
              fullWidth
              multiline
              margin="normal"
              className="CertificateNotesInput"
              disabled={disabled}
            />
          </>)}
        }
        disabled={disabled}
      />
      <ConfirmDialog
        open={showConfirmDelete}
        title={t("CertificateType.Labels.DeleteCertificateTitle", { name: deletedCertificate.name })}
        message={t("CertificateType.Labels.DeleteCertificateMessage", { name: deletedCertificate.name })}
        onConfirm={() => {
          setVessel(prev => Vessel.copyOf(prev, updated => {
            updated.vesselData.certificates = [...updated.vesselData.certificates];
            updated.vesselData.certificates.splice(deletedCertificate.index, 1);
            if (!updated.vesselData.certificates.length) updated.vesselData.certificates = null;
          }));
          setShowConfirmDelete(false)
        }}
        onClose={() => setShowConfirmDelete(false)}
      />
    </>
  );
}