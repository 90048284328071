import React, { useState, useMemo } from 'react';
import { Typography, TextField, Box, Grid, Card, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import VesselListItem from '../Vessel/VesselListItem';
import { Link as RouteLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { VesselData } from '../../models';
import { validateVesselImo, validateVesselMmsi } from '../../utils/validators';
import { vesselFilterOptionsByName, vesselFilterOptionsByImo, vesselFilterOptionsByMmsi } from '../../utils/sorters';
import VesselAutocomplete from './VesselAutocomplete';
import { METRES, UnitsSymbolKeyFromString } from '../../constants/Units';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import CertificateList from '../Certificate/CertificateList';

const useStyles = makeStyles(theme => ({
  scrollPane: {
    height: 'calc(100% - 6.25rem)',
    overflow: 'auto',
  },
  rootPane: {
    height: '100%',
    overflow: 'auto',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    position: 'relative'
  },
  whitePanel: {
    height: '100%',
    backgroundColor: theme.palette.white
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  card: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  vesselInformation: {
    marginRight: "2rem"
  },
  link: {
    textDecoration: 'none',
    pointerEvents: 'auto',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  linkDisabled: {
    pointerEvents: 'none'
  }
}));

//This function is used to render the labels for vessels.
//This will take in width of the Label, The Labels and Values in Value Array Object
//The appStr is the string attached to the end of the value
//The sepStr seperates if there are multiple values
const fieldLabel = (classes, width, valueArray = {}, appStr = "", sepStr = "") => {
  const labelKeys = Object.keys(valueArray);
  if (labelKeys.length === 0) return;
  const { label, value, notNull } = labelKeys.reduce((acc, key, i) => {
    const { label, value, notNull } = acc;
    const returnValue = valueArray[key] || "-";
    let isNull = notNull;
    if (!!valueArray[key]) isNull = true;
    if (i > 0) return { label: `${label} ${sepStr} ${key}`, value: `${value} ${sepStr} ${returnValue}`, notNull: isNull };
    return { label: key, value: returnValue, notNull: isNull };
  }, { label: "", value: "", notNull: false });

  const returnValue = notNull ? `${value} ${appStr}` : "-";

  return (
    <Box display="flex">
      <Typography className={classes.vesselFieldLabel} variant="body1" style={{ width: `${width}rem` }}>{label}</Typography>
      <Typography variant="body1">{returnValue}</Typography>
    </Box>
  );
}

const VesselSearchForm = ({
  autoFocus,
  direction,
  vessel,
  setVessel,
  clearVessel,
  vesselData,
  setVesselData,
  isUnknownVessel,
  setIsUnknownVessel,
  allowCreateVessel,
  disablePortcallUpdate,
  hideUnknownVesselCheckbox,
  hideTitle,
  disabled,
  certificateFrom,
  certificateTo
}) => {
  const { t } = useTranslation()
  const classes = useStyles();

  const [imoInput, setImoInput] = useState("");
  const imoError = useMemo(() => imoInput && !validateVesselImo(imoInput) ? t('VesselSearch.Errors.NotAValidImo') : null, [imoInput, t]);
  const [mmsiInput, setMmsiInput] = useState("");
  const mmsiError = useMemo(() => mmsiInput && !validateVesselMmsi(mmsiInput) ? t('VesselSearch.Errors.NotAValidMmsi') : null, [mmsiInput, t]);

  const handleVesselDataChange = (e) => {
    const { name, value, type, maxLength } = e.currentTarget;
    if (type === 'number' && value.length > maxLength) return;
    setVesselData(new VesselData({ ...vesselData, [name]: value }));
  };

  return (
    <>
      <Box display="flex" flex="none" alignItems="flex-end" justifyContent="space-between" style={{opacity: disabled ? 0.4 : 1}}>
        <Box width={direction === 'horizontal' ? '33%' : 'auto'} display="flex" alignItems="flex-end" justifyContent="space-between" flexGrow={Number(direction === 'vertical')}>
          {!hideTitle && <Typography variant="h5">{t('Vessel.Labels.Vessel')}</Typography>}
          {!hideUnknownVesselCheckbox && !vessel && <FormControlLabel id="UnknownVesselCheckbox" control={<Checkbox checked={isUnknownVessel} onChange={e => setIsUnknownVessel(e.target.checked)} color="primary" style={{ padding: 0, paddingLeft: '0.25rem', paddingRight: '0.25rem' }} />} label={t('VesselSearch.Labels.UnknownVessel')} />}
        </Box>
        {!isUnknownVessel && !vessel && allowCreateVessel &&
          <RouteLink id="NavCreateVesselButton" to={`/vessel-create${disablePortcallUpdate ? '/disable-port-call-update' : ''}`} className={`${classes.link} ${(disabled) ? classes.linkDisabled : ''} MuiTypography-colorPrimary`}>
            <Typography>{t('VesselForm.Labels.CreateVessel')}</Typography>
          </RouteLink>
        }
      </Box>
      <Box display="flex" flexDirection={direction === 'vertical' ? 'column' : 'row'} flex="none">
        {!isUnknownVessel && vessel &&
          <Card style={{ width: '100%' }}>
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={5} style={{ flexGrow: 1 }}>
                <VesselListItem
                  vesselData={vessel.vesselData}
                />
              </Grid>
              <Grid item xs={2} className={classes.vesselInformation}>
                {fieldLabel(classes, 5, { [t('Vessel.Labels.MMSI')]: vessel.vesselData.mmsi })}
                {fieldLabel(classes, 5, { [t('VesselSearch.Labels.CallSign')]: vessel.vesselData.callSign })}
              </Grid>
              <Grid item xs={4} >
                {fieldLabel(classes, 12, { [t('VesselSearch.Labels.LengthOverall')]: vessel.vesselData.lengthOverall, [t('VesselSearch.Labels.Beam')]: vessel.vesselData.beam }, t(UnitsSymbolKeyFromString(METRES)), "x")}
                {fieldLabel(classes, 12, { [t('VesselSearch.Labels.NominalDraught')]: vessel.vesselData.draught }, t(UnitsSymbolKeyFromString(METRES)))}
              </Grid>
              <Grid item xs style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                <IconButton size="small" onClick={clearVessel}><Close size="small" /></IconButton>
              </Grid>
              {Boolean(vesselData.certificates?.length) && <Grid item xs={12} style={{ padding: ".5rem 1rem" }}>
                <CertificateList
                  id="VesselSearchCertificateList"
                  certificates={vesselData.certificates} 
                  fromDate={certificateFrom}
                  toDate={certificateTo}
                />
              </Grid>}
            </Grid>
          </Card>
        }
        {isUnknownVessel &&
          <Box width={direction === 'horizontal' ? 'calc(33% - .5rem + 1px)' : '100%'}>
            <TextField
              autoFocus={autoFocus}
              variant="outlined"
              label={t('VesselSearch.Labels.TemporaryName')}
              placeholder={t('VesselSearch.Labels.UnknownVessel')}
              fullWidth
              margin="normal"
              name="name"
              value={vesselData.name || ''}
              inputProps={{
                autoComplete: 'off'
              }}
              onChange={handleVesselDataChange}
              id="vesselNameUnknown"
            />
          </Box>
        }
        {!isUnknownVessel && !vessel &&
          <>
            <VesselAutocomplete 
              value={vessel} 
              onChange={value => value ? setVessel(value) : clearVessel()}
              id="vesselNameAutoComplete"
              style={{ width: '100%' }}
              label={t('Vessel.Labels.Name')}
              filterOptions={vesselFilterOptionsByName}
              disabled={disabled}
              getOptionLabel={()=>("")}
            />
            <VesselAutocomplete 
              value={vessel} 
              onChange={value => value ? setVessel(value) : clearVessel()}
              style={{ width: '100%', marginLeft: direction === 'horizontal' && '1rem', marginRight: direction === 'horizontal' && '1rem' }}
              label={t('Vessel.Labels.IMO')}
              filterOptions={vesselFilterOptionsByImo}
              onInputChange={(e, value) => setImoInput(value)}
              textfieldProps={{
                error: Boolean(imoError),
                helperText: imoError,
                type: "number",
              }}
              maxLength={7}
              variant="imo"
              disabled={disabled}
              getOptionLabel={()=>("")}
            />
            <VesselAutocomplete 
              value={vessel} 
              onChange={value => value ? setVessel(value) : clearVessel()}
              style={{ width: '100%' }}
              label={t('Vessel.Labels.MMSI')}
              filterOptions={vesselFilterOptionsByMmsi}
              onInputChange={(e, value) => setMmsiInput(value)}
              textfieldProps={{
                error: Boolean(mmsiError),
                helperText: mmsiError,
                type: "number",
              }}
              maxLength={9}
              variant="mmsi"
              disabled={disabled}
              getOptionLabel={()=>("")}
            />
          </>
        }
      </Box>
    </>
  );
};

VesselSearchForm.defaultProps = {
  autoFocus: false,
  direction: 'horizontal',
};

VesselSearchForm.propTypes = {
  autoFocus: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default VesselSearchForm;