import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, TextField, Box, CardContent, Button, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Close } from 'mdi-material-ui';

import { makeStyles } from '@material-ui/styles';

import CargoInstanceArrayForm from './CargoInstanceArrayForm';
import { useHistory } from "react-router-dom";
import { NavigationContext } from '../../contexts/navigation';
import VesselSearchForm from '../Vessel/VesselSearchForm';
import { PortCall, PortCallStatus, Action, ActionState, ActionMovementType, VesselData, Request, RequestState, RequestType, TemplateSubType, LocationType, PortCallOrigin } from '../../models';
import { DataStore } from 'aws-amplify';
import LocationTimeline from '../Location/LocationTimeline';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { add } from 'date-fns';
import { ActionTypeIds } from '../../environment';
import { UIContext } from '../../contexts/ui';
import { buildRequestSource, getUserEmail, getUserName } from '../../utils/agentPortal';
import { createPortCall } from './create';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import { isSameDateHourMinute } from '../../utils/generators';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useAuditMeta from '../../hooks/useAuditMeta';
import useLastPortCallByVesselId from '../../hooks/useLastPortCallByVesselId';
import { useTranslation } from 'react-i18next';
import { METRES, UnitsKeys } from '../../constants/Units';
import DocumentEdit from '../Documents/DocumentEdit';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import useTariffBooks from '../../hooks/useTariffBooks';

const useStyles = makeStyles(() => ({
  root: {
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  vesselFieldLabel: {
    paddingRight: '2rem',
    textAlign: 'right',
    fontWeight: '300',
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '8rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const RequestPortCall = () => {
  const classes = useStyles();
  const history = useHistory();
  const [navigationContext,] = useContext(NavigationContext);
  const [uiContext] = useContext(UIContext);
  const { userContactId, agentPortal } = uiContext;
  const dataStoreContext = useContext(DataStoreContext);
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const { tariffBooks } = useTariffBooks();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  const [portCall, setPortCall] = useState(() => {
    const actions = [];
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_ARRIVAL);
    const currentTime = new Date().toISOString();
    if (template) {
      actions.push(Action.copyOf(generateActionFromTemplate(template, dataStoreContext, ActionState.REQUESTED, isTariffBookEnabled), updated => {
        updated.state = ActionState.REQUESTED;
        updated.timePlanned = currentTime;
        updated.timeRequested = currentTime;
        updated.auditMeta = auditMeta;
      }));
    } else {
      actions.push(new Action({
        state: ActionState.REQUESTED,
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.ARRIVAL,
        timePlanned: currentTime,
        timeRequested: currentTime,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      }));
    }
    return new PortCall({
      status: PortCallStatus.REQUESTED,
      portCallTags: [''],
      documents: [''],
      vesselData: new VesselData({ name: '' }),
      actions,
      cargos: [],
      agents: [],
      origin: PortCallOrigin.AGENT_REQUEST
    });
  });

  const lastPortCall = useLastPortCallByVesselId(portCall?.vessel?.id);
  useEffect(() => {
    console.log(portCall, lastPortCall);
    if (!portCall || !lastPortCall) return;
    setPortCall(prev => {
      if (!prev) return prev;
      let newItem = prev;
      if (lastPortCall.portCallTags && JSON.stringify(lastPortCall.portCallTags) !== JSON.stringify(prev.portCallTags)) {
        newItem = PortCall.copyOf(newItem, updated => {
          updated.portCallTags = lastPortCall.portCallTags;
        });
      }
      if (lastPortCall.category && (!prev.category || prev.category.id !== lastPortCall.category.id)) {
        newItem = PortCall.copyOf(newItem, updated => {
          updated.category = lastPortCall.category;
          updated.portCallCategoryId_ = lastPortCall.category.id;
        });
      }
      return newItem;
    })
  }, [portCall, setPortCall, lastPortCall]);

  const vessel = useMemo(() => portCall && (portCall.vessel || null), [portCall]);
  const setVesselData = useCallback(value => setPortCall(PortCall.copyOf(portCall, updated => {
    updated.vesselData = value
  })), [portCall, setPortCall]);
  const setVessel = useCallback((value) => setPortCall(PortCall.copyOf(portCall, updated => {
    updated.vessel = value;
    updated.portCallVesselId_ = value.id;
    updated.vesselData = value.vesselData;
  })), [portCall, setPortCall]);
  const clearVessel = useCallback(() => setPortCall(PortCall.copyOf(portCall, updated => {
    updated.vessel = null;
    updated.vesselData = new VesselData({ name: '' });
  })), [portCall, setPortCall]);

  const [isUnknownVessel, setIsUnknownVessel] = useState(false);
  // may as well handle this separately as its unused in LocationTimeline
  const [cargos, setCargos] = useState([]);

  const setRemarks = useCallback((value) => setPortCall(PortCall.copyOf(portCall, updated => {
    updated.remarks = value;
  })), [portCall, setPortCall]);

  const arrival = useMemo(() => portCall.actions.find(a => a.movementType === ActionMovementType.ARRIVAL), [portCall]);

  const setArrival = useCallback((value) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [
        ...updated.actions.filter(a => a.movementType !== ActionMovementType.ARRIVAL),
        value
      ].filter(a => a);
    }));
  }, [portCall, setPortCall]);
  const arrivalError = useMemo(() => !arrival.timePlanned, [arrival]);

  const departure = useMemo(() => portCall.actions.find(a => a.movementType === ActionMovementType.DEPARTURE), [portCall]);
  const setDeparture = useCallback((value) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [
        ...updated.actions.filter(a => a.movementType !== ActionMovementType.DEPARTURE),
        value
      ].filter(a => a);
    }));
  }, [portCall, setPortCall]);
  const departureError = useMemo(() => departure &&
    ((!departure.timePlanned && t('PortCall.Errors.InvalidDateFormat')) ||
      (departure.timePlanned <= arrival.timePlanned || (arrival.timePlanned && isSameDateHourMinute(departure.timePlanned, arrival.timePlanned))) && t('PortCall.Errors.DepartureAfterArrival')),
    [departure, arrival]);
  const addDeparture = useCallback((value) => {
    const timePlanned = add(new Date(arrival.timePlanned), { days: 1 }).toISOString();
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_DEPARTURE);
    if (template) {
      setDeparture(Action.copyOf(generateActionFromTemplate(template, dataStoreContext, ActionState.REQUESTED, isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.timeRequested = timePlanned;
        updated.state = ActionState.REQUESTED;
        updated.auditMeta = auditMeta;
      }));
    } else {
      setDeparture(new Action({
        state: ActionState.REQUESTED,
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.DEPARTURE,
        timePlanned,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta,
      }));
    }
  }, [setDeparture, arrival]);
  const clearDeparture = useCallback(() => { setDeparture(null) }, [setDeparture]);

  const location = useMemo(() => arrival && arrival.movementLocation || null, [arrival]);
  const setLocation = useCallback((value) => {
    setArrival(Action.copyOf(arrival, updated => { updated.movementLocation = value; }));
  }, [arrival, setArrival]);

  const arrivalForwardDraught = useMemo(() => (arrival && arrival.vesselForwardDraught) || null, [arrival]);
  const arrivalAftDraught = useMemo(() => (arrival && arrival.vesselAftDraught) || null, [arrival]);
  const arrivalSailingDraught = useMemo(() => (arrival && arrival.vesselSailingDraught) || null, [arrival]);
  const arrivalAirDraught = useMemo(() => (arrival && arrival.vesselAirDraught) || null, [arrival]);
  const setArrivalDraught = useCallback((event) => {
    const { name, value, type, maxLength } = event.target;
    if (maxLength > 0 && value.length > maxLength) return;
    const newValue = value === '' ? null : (type === 'number') ? parseFloat(value) : value;
    setArrival(Action.copyOf(arrival, updated => {
      updated[name] = newValue;
    }));
  }, [arrival, setArrival]);

  const departureForwardDraught = useMemo(() => (departure && departure.vesselForwardDraught) || null, [departure]);
  const departureAftDraught = useMemo(() => (departure && departure.vesselAftDraught) || null, [departure]);
  const departureSailingDraught = useMemo(() => (departure && departure.vesselSailingDraught) || null, [departure]);
  const departureAirDraught = useMemo(() => (departure && departure.vesselAirDraught) || null, [departure]);
  const setDepartureDraught = useCallback((event) => {
    const { name, value, type, maxLength } = event.target;
    if (maxLength > 0 && value.length > maxLength) return;
    const newValue = value === '' ? null : (type === 'number') ? parseFloat(value) : value;
    setDeparture(Action.copyOf(departure, updated => {
      updated[name] = newValue;
    }));
  }, [departure, setDeparture]);

  // const [agent, setAgent] = useState(uiContext.agentPortal ? userContactId : null);
  const contacts = dataStoreContext.contacts.filter(i => Boolean(i.name));
  const contact = useMemo(() => contacts.find(el => el.id === userContactId), [contacts]);

  const handleRemarksChange = (e) => {
    setRemarks(e.currentTarget.value);
  }

  const handleRequestPortCall = async (moreOptions) => {

    const newPortCall = await createPortCall(portCall, location, arrival, departure, contact, cargos, auditMeta, isTariffBookEnabled, dataStoreContext, tariffBooks);

    // create request
    const requestActions = [];
    if (newPortCall.arrival) {
      requestActions.push({
        actionId: newPortCall.arrival.id,
        actionTypeId: newPortCall.arrival.type.id,
        movementType: newPortCall.arrival.movementType,
        timeRequested: newPortCall.arrival.timeRequested,
        timePlanned: newPortCall.arrival.timePlanned,
        approved: RequestState.REQUEST_STATE_PENDING,
        locationId: newPortCall.arrival.movementLocation?.id
      })
    };
    if (newPortCall.departure) {
      requestActions.push({
        actionId: newPortCall.departure.id,
        actionTypeId: newPortCall.departure.type.id,
        movementType: newPortCall.departure.movementType,
        timeRequested: newPortCall.departure.timeRequested,
        timePlanned: newPortCall.departure.timePlanned,
        approved: RequestState.REQUEST_STATE_PENDING,
        locationId: newPortCall.departure?.movementLocation?.id
      })
    };
    const requestSource = await buildRequestSource(contact);
    await DataStore.save(new Request({
      portCall: newPortCall.portCall,
      requestPortCallId_: newPortCall.portCall.id,
      state: RequestState.REQUEST_STATE_PENDING,
      type: RequestType.REQUEST_TYPE_CREATE_PORTCALL,
      source: requestSource,
      agent: contact,
      requesterUserName: await getUserName(),
      requesterEmail: await getUserEmail(),
      portCallData: {
        vesselName: portCall.vesselData.name,
        vesselImo: portCall.vesselData.imo,
        vesselMmsi: portCall.vesselData.mmsi,
        vesselCallsign: portCall.vesselData.callSign
      },
      actionData: requestActions,
      remark: portCall.remarks,
      auditMeta: { ...auditMeta, comment: portCall.remarks }
    }));

    history.push(navigationContext.defaultView);
  };

  const disabled = useMemo(() =>
    (isUnknownVessel ? Boolean(!portCall.vesselData.name) : Boolean(!vessel)) ||
    (cargos.some(c => !c.type)) ||
    Boolean(arrivalError) ||
    Boolean(departureError),
    [isUnknownVessel, vessel, portCall.vesselData, arrivalError, departureError, cargos]);

  // const documents = useMemo(() => {
  //   return arrival && arrival.documents ? arrival?.documents?.filter(d => d) : []
  // }, [arrival]);

  const handleUploadedDocuments = (value) => {
    setArrival(Action.copyOf(arrival, updated => { updated.documents = value; }));
  }

  return (
    <Box display="flex" overflow="hidden auto" width="100%">
      <Grid container wrap="nowrap" direction="column" className={classes.root}>

        <Grid item container justifyContent="center" alignItems="center" className={classes.header}>
          <Typography variant="h2">{t('PortCall.Labels.RequestPortCall')}</Typography>
          <IconButton onClick={() => { history.push(navigationContext.defaultView); }} style={{ marginLeft: -59, position: 'relative', left: 96 }}><Close fontSize="large" /></IconButton>
        </Grid>

        <Grid item className={classes.content} container justifyContent="center">

          <Box width="100%">
            <CardContent style={{ maxWidth: '60rem', margin: '0 auto', paddingTop: 0 }}>
              <VesselSearchForm
                vessel={vessel}
                setVessel={setVessel}
                clearVessel={clearVessel}
                vesselData={portCall.vesselData}
                setVesselData={setVesselData}
                isUnknownVessel={isUnknownVessel}
                setIsUnknownVessel={setIsUnknownVessel}
                certificateFrom={arrival.timePlanned}
                certificateTo={departure?.timePlanned || new Date().toISOString()}
              />
            </CardContent>
            <CardContent style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Typography variant="h5">{t('PortCall.Labels.Schedule')}</Typography>
              </Box>

              <Box display="flex" height="fit-content">
                <StringKeyboardDateTimePicker
                  warningPastDate={true}
                  id="ArrivalTimePlannedInput"
                  label={t('PortCall.Labels.PlannedTimeOfArrival')}
                  style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
                  value={arrival.timePlanned}
                  onChange={value => setArrival(Action.copyOf(arrival, updated => { updated.timePlanned = value; updated.timeRequested = value }))}
                  okLabel={t('Common.Buttons.Confirm')}
                />

                <LocationAutocomplete
                  id="ArrivalLocationInput"
                  style={{ width: '100%', marginLeft: '1rem', marginRight: '1rem' }}
                  value={location}
                  onChange={(value) => setLocation(value)}
                  label={t('PortCall.Labels.ArrivalLocation')}
                  filter={l => l.type === LocationType.PORT}
                />

                <ContactAutocomplete
                  id="CharteredAgentInput"
                  style={{ width: '100%' }}
                  value={contact}
                  // onChange={(value) => setAgent(value)}
                  label={t('Agent.Labels.CharterAgent')}
                  disabled={true}
                />
              </Box>

              <Box id='ArrivalAttachmentsContainer' style={{ paddingTop: 0, paddingBottom: '1rem', maxWidth: '60rem', margin: '0 auto' }}>
                <DocumentEdit
                  filePath={`portcalls/${portCall.id}/actions/${arrival.id}`}
                  portCallId={portCall.id}
                  objId={arrival.id}
                  objDocuments={arrival?.documents?.filter(d => d) ?? []}
                  onChange={handleUploadedDocuments}
                  saveDisabled
                  disableDelete
                  disableDownload
                  hideEmptyMessage
                />
              </Box>

              <Box display="flex" height="5rem" alignItems="center">
                {departure ?
                  <>
                    <StringKeyboardDateTimePicker
                      width='calc(33.333% - 0.666rem)'
                      warningPastDate={true}
                      id="DepartureTimePlannedInput"
                      label={t('PortCall.Labels.PlannedTimeOfDeparture')}
                      style={{ width: '100%', marginTop: '1.25rem', marginBottom: '1rem' }}
                      value={departure.timePlanned}
                      onChange={value => setDeparture(Action.copyOf(departure, updated => { updated.timePlanned = value; updated.timeRequested = value }))}
                      error={Boolean(departureError)}
                      helperText={departureError}
                      okLabel={t('Common.Buttons.Confirm')}
                    />
                    <Grid item style={{ margin: '.25rem 0 1rem 1rem' }}>
                      <IconButton onClick={clearDeparture}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </>
                  :
                  <Button
                    id="AddDepartureButton"
                    color="primary"
                    variant="outlined"
                    onClick={addDeparture}
                    disabled={arrivalError}
                  ><Typography>{t('PortCall.Buttons.AddDeparture')}</Typography>
                  </Button>
                }
              </Box>

            </CardContent>

            {!agentPortal &&
              <div style={{ height: '20rem', margin: '-1rem 2rem 2rem' }}>
                <LocationTimeline customPortCall={portCall} />
              </div>
            }

            <CardContent id='CargoContainer' style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>
              <Typography variant="h5">{t('Cargo.Labels.Cargo')}</Typography>
              <CargoInstanceArrayForm cargos={cargos} setCargos={setCargos} />
            </CardContent>

            <CardContent id='DraughtContainer' style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>
              <Typography variant="h5">{t("PortCall.Labels.ArrivalDraughts")}</Typography>
              <Box display="flex" height="5rem">
                <TextField
                  id="ArrivalForwardDraughtInput"
                  style={{ width: '100%', marginRight: '1rem' }}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={t("Vessel.Labels.ForwardDraught")}
                  margin="normal"
                  name="vesselForwardDraught"
                  value={arrivalForwardDraught || ''}
                  onChange={(e) => setArrivalDraught(e)}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.1
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                  }}
                />
                <TextField
                  id="ArrivalAftDraughtInput"
                  style={{ width: '100%', marginRight: '1rem' }}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={t("Vessel.Labels.AftDraught")}
                  margin="normal"
                  name="vesselAftDraught"
                  value={arrivalAftDraught || ''}
                  onChange={(e) => setArrivalDraught(e)}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.1
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                  }}
                />
                <TextField
                  id="ArrivalSailingDraughtInput"
                  style={{ width: '100%', marginRight: '1rem' }}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={t("Vessel.Labels.SailingDraught")}
                  margin="normal"
                  name="vesselSailingDraught"
                  value={arrivalSailingDraught || ''}
                  onChange={(e) => setArrivalDraught(e)}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.1
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                  }}
                />
                <TextField
                  id="ArrivalAirDraughtInput"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={t("Vessel.Labels.AirDraught")}
                  margin="normal"
                  name="vesselAirDraught"
                  value={arrivalAirDraught || ''}
                  onChange={(e) => setArrivalDraught(e)}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.1
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                  }}
                />
              </Box>
            </CardContent>

            {departure &&
              <CardContent id='DepartureDraughtContainer' style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>
                <Typography variant="h5">{t("PortCall.Labels.DepartureDraughts")}</Typography>
                <Box display="flex" height="5rem">
                  <TextField
                    id="DepartureForwardDraughtInput"
                    style={{ width: '100%', marginRight: '1rem' }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    label={t("Vessel.Labels.ForwardDraught")}
                    margin="normal"
                    name="vesselForwardDraught"
                    value={departureForwardDraught || ''}
                    onChange={(e) => setDepartureDraught(e)}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.1
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                    }}
                  />
                  <TextField
                    id="DepartureAftDraughtInput"
                    style={{ width: '100%', marginRight: '1rem' }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    label={t("Vessel.Labels.AftDraught")}
                    margin="normal"
                    name="vesselAftDraught"
                    value={departureAftDraught || ''}
                    onChange={(e) => setDepartureDraught(e)}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.1
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                    }}
                  />
                  <TextField
                    id="DepartureSailingDraughtInput"
                    style={{ width: '100%', marginRight: '1rem' }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    label={t("Vessel.Labels.SailingDraught")}
                    margin="normal"
                    name="vesselSailingDraught"
                    value={departureSailingDraught || ''}
                    onChange={(e) => setDepartureDraught(e)}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.1
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                    }}
                  />
                  <TextField
                    id="DepartureAirDraughtInput"
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    label={t("Vessel.Labels.AirDraught")}
                    margin="normal"
                    name="vesselAirDraught"
                    value={departureAirDraught || ''}
                    onChange={(e) => setDepartureDraught(e)}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.1
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
                    }}
                  />
                </Box>
              </CardContent>
            }
            <CardContent id='RemarksContainer' style={{ maxWidth: '60rem', margin: '0 auto' }}>
              <Typography variant="h5">{t("PortCallDetails.Labels.Remarks")}</Typography>
              <TextField
                id="RequestRemarks"
                autoComplete="off"
                variant="outlined"
                label={t("PortCall.Labels.EnterRemarksForPortControl")}
                onChange={handleRemarksChange}
                fullWidth
              />
            </CardContent>
          </Box>
        </Grid>

        <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
            style={{ margin: '1rem' }}
            onClick={() => handleRequestPortCall()}
            id="CreatePortCallButton"
          >
            {t("Common.Buttons.Request")}
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
};

export default RequestPortCall;