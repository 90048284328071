import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DialogLoadingProgress from './DialogLoadingProgress';

const useStyles = makeStyles(theme => ({
  dialogContents: {
    minWidth: '400px'
  },
  warningMessage: {
    color: '#ff9800'
  }
}));

/**
 * Simple text entry confirm dialog
 */
const TextInputDialog = ({ open, title, inputLabel, initialValue, onConfirm, onClose, message, warningMessage, textRequired, modal, loading, error}) => {
  // State to store the value of the Date Input
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if(modal && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} id='TextInputDialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContents}>
        {warningMessage && <DialogContentText id='TextInputDialogWarningMessage' className={classes.warningMessage}>{warningMessage}</DialogContentText>}
        {message && <DialogContentText>{message}</DialogContentText>}
        <TextField
          id={'TextInputDialogCommentInput'}
          style={{ width: '100%', marginTop: '0.25rem' }}
          variant="outlined"
          label={inputLabel}
          fullWidth
          multiline
          maxRows={3}
          margin='normal'
          value={value}
          onChange={value => setValue(value.currentTarget.value)}
        />
        <DialogLoadingProgress 
          loading={loading}
          errorMessage={error?.message}
          errorCode={error?.errorCode}
      />
      </DialogContent>
      <DialogActions>
        <div id={'TextInputDialogCancelButton'}>
          <Button
            onClick={onClose}
            color="default"
            disabled={loading}
          >
            {t('Common.Buttons.Cancel')}
          </Button>
        </div>
        <div id={'TextInputDialogConfirmButton'}>
          <Button
            disabled={ (textRequired && !value) || loading }
            onClick={() => onConfirm(value)}
            color="primary"
          >
            {t('Common.Buttons.Confirm')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

TextInputDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  inputLabel: PropTypes.string,
  initialValue: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.node,
  modal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object
};

TextInputDialog.defaultProps = {
  title: 'Dialog Title',
  inputLabel: 'Text Label',
  initialValue: '',
  message: null,
  modal: false,
  loading: false,
  error: undefined
}

export default TextInputDialog;