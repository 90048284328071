import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  FormControl,
  FormHelperText
} from '@material-ui/core';

/**
 * 
 *  EditorSelect
 *  Wraps up components for 
 *  making a MUI <Select> group
 * 
 */

export const EditorSelect = ({
  children,
  label,
  labelId,
  name,
  onChange,
  value,
  disabled = false,
  error,
  helperText
}) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);


  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="outlined"
      disabled={disabled}
      error={error}
>
      <InputLabel
        ref={inputLabel}
        id={labelId}
        error={error} 
      >
        {label}  
      </InputLabel>
      <Select
        labelId={labelId}
        fullWidth
        labelWidth={labelWidth}
        name={name}
        value={value || ''}
        onChange={onChange}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

EditorSelect.propTypes = {
  // String displayed as label for field
  label: PropTypes.string,
  // Id used to get and set width of label
  labelId: PropTypes.string,
  // Field name 
  name: PropTypes.string,
  // Change function
  onChange: PropTypes.func,
  // Value
  value: PropTypes.any,
};

export default EditorSelect;