import React, { useMemo } from 'react';
import propTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { 
  List, 
  ListItemText, 
  TextField, 
} from '@material-ui/core';

const sortTariffs = (a, b) => a.code.localeCompare(b.code);

const TariffAutocomplete = ({ 
  value, 
  tariffs,
  onChange, 
  textFieldProps,
  ...other 
}) => {
  const sortedTariffs = useMemo(() => tariffs.sort(sortTariffs), [tariffs]);

  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={sortedTariffs}
      getOptionSelected={(option, value) => value.code === option.code}
      getOptionLabel={({ code }) => code}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      // Render a list item to show the code and description
      renderOption={({id, code, description}) => (
          <List dense>
            <ListItemText
              primary={code}
              secondary={description}
              data-id={id}
              className={'TariffItemData'}
            />
          </List>
        )
      } 
      // Filters Tariffs by code and description based on user input
      filterOptions={(options, state) => {
        const input = state.inputValue.trim().toLowerCase();
        return !input 
        ? options 
        : options.filter(({ code, description }) => (code && code.toLowerCase().includes(input)) || (description && description.toLowerCase().includes(input)))
      }}
      // Render the TextField Input
      // Show Error if there is one
      renderInput={props => (
        <TextField
          {...props}
          {...textFieldProps}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
};

TariffAutocomplete.propTypes = {
  value: propTypes.any,
  tariffs: propTypes.array,
  onChange: propTypes.func,
  textFieldProps: propTypes.any
};

TariffAutocomplete.defaultProps = {
};

export default TariffAutocomplete;