import React from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import RequestListItem from '../Common/RequestListItem';

const useStyles = makeStyles({
});


export default ({ request }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={classes.container}
    >
      <Grid item>
        {request && <RequestListItem key={request.id} request={request} />}
      </Grid>
    </Grid>
  )
};