
import React, { useContext, useEffect, useState } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { Setting } from '../models';
import SettingConstants from '../constants/SettingConstants';

/**
 * A hook that handle setting flag to enabled/disable automation
 * @return {Object} automation enabled flag with a helper function to update app automation setting
 */
const useAutomationSetting = () => {
  const { settings } = useContext(DataStoreContext);

  const [automationSetting, setAutomationSetting] = useState();
  const [appAutomationEnabled, setAppAutomationEnabled] = useState();

  useEffect(() => {
    const setting = settings?.find(s => s.name === SettingConstants.APP_PORTCALL_AUTOMATION);
    setAutomationSetting(setting)
    const settingValue = setting ? JSON.parse(setting.value) : null;
    setAppAutomationEnabled(settingValue?.enabled ?? false);
  }, [settings])

  const updateAutomationSetting = React.useCallback((enabled) => {
    const settingValue = {enabled}
    DataStore.save(automationSetting
      ? Setting.copyOf(automationSetting, updated => { updated.value = JSON.stringify(settingValue) })
      : new Setting({ name: SettingConstants.APP_PORTCALL_AUTOMATION, value: JSON.stringify(settingValue) })
    );
  }, [automationSetting])

  return {
    appAutomationEnabled, 
    updateAutomationSetting
  }
}

export default useAutomationSetting;