import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField, ListItem, ListItemText } from '@material-ui/core'
import { sortVoyagesByName } from '../../utils/sorters';
import { fetchVoyages } from '../../utils/voyages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { getVoyageNameById } from '../../utils/voyages';

const VoyageAutocomplete = ({ value, onChange, vesselId, voyageId, ...other }) => {
  const [open, setOpen] = useState(false);
  const [voyages, setVoyages] = useState([]);  
  const [voyage, setVoyage] = useState('');  
  const { t } = useTranslation();
  const [noVoyages, setNoVoyages] = useState(false);
  const loading = open && voyages.length === 0 

  const currentVoyageId = useMemo(() => voyageId, [voyageId]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const newVoyages = await fetchVoyages(`{ voyageVesselId: { eq: "${vesselId}" } }`)
      if(active){
        setVoyages(newVoyages.sort(sortVoyagesByName))
        setNoVoyages( newVoyages.length ? false : true)
      }
    })();
  }, [loading]);

  useEffect(() => {
    (async () => {
    if(currentVoyageId){
      const voyage = await getVoyageNameById(currentVoyageId)
      setVoyage(voyage)
    } else setVoyage(null)
    })();
  }, [voyageId]);

  useEffect(() => {
    if(!open){
      setVoyages([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...other}
      className="VoyageAutocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoHighlight
      value={voyage}
      options={voyages}
      loading={loading}
      loadingText={!noVoyages ? t('ActionScheduleEdit.Labels.Loading') : t('ActionScheduleEdit.Labels.NoVoyages')}
      getOptionLabel={(item) => item && item.name}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={item.name} />
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={t('ActionScheduleEdit.Labels.VoyageId')}
          fullWidth
          margin="normal"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && !noVoyages ? <CircularProgress color="inherit" size={20} /> : null}
                {props.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default VoyageAutocomplete;