import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { TariffUnit } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { getFullTarrifUnit } from '../../utils/getters';
import { useTranslation } from 'react-i18next';

const AdminTariffUnitHeaderContent = () => {
  const { t } = useTranslation();
  return <AdminModelEditorHeaderColumn label={t('TariffUnit.Labels.Name')} flexBasis="100%" />
};

const AdminTariffUnitListItemContent = ({ value }) => 
  <Typography noWrap style={{ flexBasis: "100%" }}>{getFullTarrifUnit(value?.name, value.secondaryUnitName)}</Typography>
  

const AdminTariffUnitFormContent = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextField id="TariffUnitName" required disabled={disabled} value={value.name || ''} name="name" label={t('TariffUnit.Labels.Name')} fullWidth variant="outlined" onChange={onChange} margin="normal"/>
      <TextField id="TariffSecondaryUnitName" disabled={disabled} value={value?.secondaryUnitName || ''} name="secondaryUnitName" label={t('TariffUnit.Labels.SecondaryUnitName')} fullWidth variant="outlined" onChange={onChange} margin="normal"/>
    </>
  )
}

const AdminTariffUnit = () =>{
  const { t } = useTranslation();
  const itemName = t('TariffUnit.Labels.TariffUnit');

  return (
    <AdminModelEditor
    HeaderContent={AdminTariffUnitHeaderContent}
    ListItemContent={AdminTariffUnitListItemContent}
    FormContent={AdminTariffUnitFormContent}
    model={TariffUnit}
    sort={(a, b) => (a.name > b.name) ? 1 : -1}
    canUpdate={() => true}
    canDelete={() => true}
    canSave={i => Boolean(i.name)}
    canAdd={() => true}
    defaultItem={() => {
      return { }
    }}
    customDeleteTooltip={() => t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })}
  />
  );
};

export default AdminTariffUnit;