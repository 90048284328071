import { useMemo } from "react";
import { Box, ListItemText } from "@material-ui/core";
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
import { DateFnsLanguageMap } from '../../../translations';

const VoyageListItemDateTime = ({ actionTime,className=null }) => {
  const { t } = useTranslation();
  const actionDateTime = useMemo(() => actionTime && format(dayjs(actionTime).toDate(), 'HH:mm, dd MMM yyyy', { locale: DateFnsLanguageMap[t.language] }), [actionTime, t]);
  
  return (
    <Box component="span" display="flex" alignItems="center" id={'VoyageListItemDateTime'} style={{width:'max-content'}}>
      <ListItemText
        className={className}
        primary={actionDateTime}
        primaryTypographyProps={{
          noWrap: true
        }}
      />
    </Box>
  )
};

export default VoyageListItemDateTime;