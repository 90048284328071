import React, { useState, useEffect } from 'react';
import { ExportFieldKeys, ExportFieldGroups } from './export-fields';
import {
  Checkbox,
  FormControl, FormGroup, FormControlLabel
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const initialiseState = (settings) => {
  const fields = {};
  for(let group of ExportFieldGroups) {
    for(let field of group.fields) {
      fields[field] = settings && settings.find(f => f === field) ? true : false;
    }
  }
  return fields
};

const ExportFieldGroup = ({group, settings, onChange}) => {
  const [ fieldState, setFieldState ] = useState(initialiseState(settings) );
  const [ orderedFields, setOrderedFields ] = useState(settings); 
  const { t } = useTranslation();

  const handleChange = (event) => {
    const fields = { ...fieldState, [event.target.name]: event.target.checked};
    setFieldState(fields);

    const selectedFields = orderedFields.map(f => f);
    if(!event.target.checked) {
      // Remove item
      selectedFields.splice(selectedFields.indexOf(event.target.name), 1);
    } else {
      // Add item to end of existing fields if it not already present
      if(!selectedFields.find(el => el === event.target.name)) {
        selectedFields.push(event.target.name);
      }
    }
    setOrderedFields(selectedFields);
    
    onChange && onChange(selectedFields);
  };

  useEffect(() => {
    setOrderedFields(settings);
  }, [settings]);

  return (
    <FormControl component="fieldset">
      <FormGroup row={true}>
        {
          group?.fields?.map(field => <FormControlLabel
            key={field}
            control={<Checkbox id={field} checked={fieldState[field]} onChange={handleChange} name={field} />}
            label={t(ExportFieldKeys[field])}
          />)
        }
      </FormGroup>
    </FormControl>
  );
};

export default ExportFieldGroup;