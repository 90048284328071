import { calculateTotal } from './chargeableItems';
import { v4 as uuidv4 } from 'uuid';
import { ActionMovementType, ChargeableItem } from "../models";

export const tariffBookS3Path = (bookId) => `tariff-book/${bookId}.json`;

export const bookWithDateRange = (date, book, deleted=false) => {
  if(!date || !book) return false;
  const inputDate = new Date(date);
  if(!inputDate) return false;
  // const withinRange = false; 

  if(!deleted && book.deleted) return false;

  // Open ended book, no time range
  if(!book.dateValidFrom && !book.dateValidTo) {
    return true;
  }

  const dateFrom = book.dateValidFrom ? new Date(book.dateValidFrom) : undefined;
  const dateTo = book.dateValidTo ? new Date(book.dateValidTo) : undefined;

  if(dateFrom && (inputDate < dateFrom)) return false;
  if(dateTo && (inputDate > dateTo)) return false;

  return true;
}

/**
 * Select the applicable tariff books given a date
 * @param {String} date Date
 * @param {Array[TariffBook]} books Array of all tariff books
 * @returns {Array[TariffBook]} Array of select tariff books
 */
export const selectTariffBook = (date, books) => {
  if(!date || !books || !books.length) return [];
  const inputDate = new Date(date);
  if(!inputDate) return [];
  const withinRange = []; 

  for(let book of books) {
    if(bookWithDateRange(date, book)) {
      withinRange.push(book);
      continue;
    }
  }
  return withinRange;
};

/**
 * Returns true if tariff uses action type else false
 * @param {TariffCostItem} tariff Tariff cost item from book
 * @param {String} actionTypeId Action type ID
 * @returns {Boolean} Returns true if tariff uses action type else false
 */
export const tariffUsesActionType = (tariff, actionTypeId) => {
  if(!tariff || !actionTypeId) return false; 
  return tariff.cost.actionTypes.find(el => el === actionTypeId);
};

/**
 * Flatten tariff from a number of books into a single array
 * Note: The property bookI is injected into each tariff so it can be related back to the parent book
 * @param {Array[TariffBook]} books 
 * @param {Function} filter Filter function on returned tariffs
 * @returns {Array[TariffCostItem]} Array of tariffs from all books with filter applied 
 */
export const flattenTariffs = (books, filter) => {
  let all = [];
  for(let book of books) {
    // Inject book ID
    const tariffs = book.tariffs.map(tariff => {
      return { ...tariff, bookId: book.id }
    });
    all = all.concat(filter 
      ? tariffs.filter(filter)
      : tariffs);
  }
  return all;
};

/**
 * Create ChargeableItems from the book definition
 * @param {String} bookId
 * @param {Array[TariffCostItem]} tariffs 
 * @param {Array[TariffDefaultCharges]} defaultChargeableItem 
 * @param {Array[TariffUnit]} tariffUnits 
 * @returns Array[ChargeableItem]
 */
export const createChargeableItemFromBook = (bookId, tariffs, defaultChargeableItem, tariffUnits) => {
  if(!tariffs || !defaultChargeableItem || !tariffUnits) return undefined;
  
  const tariff = tariffs.find(el => el.id === defaultChargeableItem.tariffId)
  if(!tariff) return undefined;
  const tariffUnit = tariffUnits.find(el => el.id === tariff.unitId);
  if(!tariffUnit) return undefined;

  const total = calculateTotal({
    tariffData: tariff.cost, 
    unitCost: tariff.cost.unitCost,
    quantity: defaultChargeableItem.quantity,
    secondaryMult: defaultChargeableItem.secondaryMult && 
      tariffUnit.secondaryUnitName && 
      (!defaultChargeableItem.secondaryMult > 0) ?
      0 : defaultChargeableItem.secondaryMult
  });

  return new ChargeableItem({
    id: defaultChargeableItem.id || uuidv4(),
    tariffData: tariff.cost,
    tariffUnit: tariffUnit.name,
    tariffSecondaryUnit: tariffUnit.secondaryUnitName,
    tariffId: tariff.id,
    tariffBookId: bookId,
    notes: defaultChargeableItem.notes,
    quantity: defaultChargeableItem.quantity,
    secondaryMult: defaultChargeableItem.secondaryMult,
    total: total
  });
};

/**
 * Return an array of chargable items base on date, actionType and movememntType
 * Used to generate default chargeable items for an action
 * @param {Date} date Input date
 * @param {String} actionType Action type filter
 * @param {ActionMovementType} movementType Action movement type, can be null/undefined
 * @param {Array[TariffBook]} books Array of tariff books
 * @param {Array[TariffUnit]} tariffUnits Array of tariff units
 * @returns {Arrary[ChargeableItem]} Chargeable items 
 */
export const createAllChargeablesItemFromBooks = (date, actionType, movementType, books, tariffUnits) => {
  if(!books || !tariffUnits || !date) return [];
  const chargeableItems = [];
  const booksWithinRange = selectTariffBook(date, books);
  
  for(let book of booksWithinRange) {
    for(let defaultCharge of book.defaultCharges) {
      if(actionType !== defaultCharge.actionTypeId) continue;
      if(movementType && (movementType !== defaultCharge.movementType)) continue;
      for(let defaultItem of defaultCharge.defaultItems) {
        const newItem = createChargeableItemFromBook(book.id, book.tariffs, defaultItem, tariffUnits);
        newItem && chargeableItems.push(newItem);
      }
    }
  }
  return chargeableItems;
};

/**
 * Create DefaultChargeableItem from ChargeableItem
 * @param {Array[ChargeableItem]} chargeableItems 
 * @returns Array[ChargeableItem]
 */
export const createDefaultChargeableItemFromChargeableItem = (chargeableItems) => {
  if(!chargeableItems) return [];
  return chargeableItems.map(el => ({
    id: el.id,
    tariffId: el.tariffId,
    quantity: el.quantity,
    secondaryMult: el.secondaryMult,
    notes: el.notes
  }));
};

/**
 * Generate a name for a action type taking into account the movement type if set
 * @param {ActionType} actionType Action type
 * @param {ActionMovementType} movementType 
 * @param {Function} t Translation function 
 * @returns String
 */
export const getActionTypeName = (actionType, movementType, t) => {
  if(!actionType) return '';
  if(movementType === ActionMovementType.ARRIVAL) return t('AdminActionType.Labels.MovementArrival');
  if(movementType === ActionMovementType.DEPARTURE) return t('AdminActionType.Labels.MovementDeparture');
  if(movementType === ActionMovementType.SHIFT_ARRIVAL) return t('AdminActionType.Labels.MovementShiftArrival');
  if(movementType === ActionMovementType.SHIFT_DEPARTURE) return t('AdminActionType.Labels.MovementShiftDeparture');
  return actionType.name;
};

/**
 * Create a map of tariff book IDs to a set lookup of tariff IDs
 * Use for fast lookup in chargable item validation
 * @param {Array[TariffBook]} books 
 * @returns {Map} Map of tariff book ID to set of tariff IDs
 */
export const createTariffMap = (books) => {
  if(!books) return new Map();
  const booksMap = new Map();
  for(let book of books) {
    const tariffMap = new Set();
    booksMap.set(book.id, tariffMap);
    for(let tariff of book.tariffs) {
      tariffMap.add(tariff.id);
    } 
  }
  return booksMap;
};

/**
 * Test a chargeable against deleted or out of date range books
 * @param {Object} tariff 
 * @param {String} date 
 * @param {TariffBook} book 
 * @returns {Object|undefined} Error object if fails test else undefined
 */
const testChargeable = (tariff, date, book) => {
  if(tariff && book.deleted) {
    return {
      error: 1,
      errorMessageT: 'ChargeableItems.Errors.TariffBookDeleted'
    }; 
  }

  if(tariff && !bookWithDateRange(date, book)) {
    return {
      error: 2,
      errorMessageT: 'ChargeableItems.Errors.TariffBookOutOfRange'
    };
  }

  return undefined;
}

export const validateChargeableItemMap = (chargeableItem, date, books, tariffMap) => {
  if(!chargeableItem?.tariffId || !date || !tariffMap) return undefined;

  let exists = false;
  for(let book of books) {
    const match = tariffMap.get(book.id)?.has(chargeableItem.tariffId);
    const invalid = testChargeable(match, date, book);
    if(invalid) return invalid;

    if(match) {
      exists = true;
      break;
    }
  }

  // chargeable item tariff could not be found in any books
  if(!exists) {
    return {
      error: 3,
      errorMessageT: 'ChargeableItems.Errors.TariffDeleted'
    }
  }

  // No errors
  return { error: 0, errorMessageT: undefined };
}

/**
 * Validate a chargeable item's tariff validity
 * Reasons for failure
 *   Tariff exists but book has been deleted
 *   Tariff exists but book is outside of date range
 *   Tariff has been deleted - not found in any book
 * @param {ChargeableItem} chargeableItem 
 * @param {Array[TariffBook]} books
 * @returns {Object} Object with error of 0 if no issues else error > 1 and translation string message
 */
export const validateChargeableItem = (chargeableItem, date, books) => {
  if(!chargeableItem?.tariffId || !date || !books) return undefined;

  let exists = false;
  for(let book of books) {
    const match = book.tariffs.find(el => el.id === chargeableItem?.tariffId);
    const invalid = testChargeable(match, date, book);
    if(invalid) return invalid;

    if(match) {
      exists = true;
      break;
    }
  }

  // chargeable item tariff could not be found in any books
  if(!exists) {
    return {
      error: 3,
      errorMessageT: 'ChargeableItems.Errors.TariffDeleted'
    }
  }

  // No errors
  return { error: 0, errorMessageT: undefined };
};

/**
 * Validate an action for valid chargeable items
 * See validateChargeableItem for potential error conditions
 * @param {Action} action 
 * @param {Array[TariffBook]} books 
 * @returns {Object} Object with error of 0 if no issues else error of 1 and translation string message
 */
export const validateActionChargableItems = (action, books) => {
  if(!action || !action.timePlanned || !books) return false;
  for(let chargeable of action.chargeableItems) {
    const ret = validateChargeableItem(chargeable, action.timePlanned, books);
    if(ret?.error > 0) {
      // return on first error
      return {
        error: 1,
        errorMessageT: 'ChargeableItems.Errors.ActionInvalidTariffs'
      }
    }
  }
  return { error: 0, errorMessageT: '' }
};

export const validateActionChargableItemsMap = (action, books, tariffMap) => {
  if(!action || !action.timePlanned || !books || !action.chargeableItems) return false;
  for(let chargeable of action.chargeableItems) {
    const ret = validateChargeableItemMap(chargeable, action.timePlanned, books, tariffMap);
    if(ret?.error > 0) {
      // return on first error
      return {
        error: 1,
        errorMessageT: 'ChargeableItems.Errors.ActionInvalidTariffs'
      }
    }
  }
  return { error: 0, errorMessageT: '' }
};