import { useState, useEffect } from 'react';
import CURRENCIES from '../data/Currency.json';
import { getCurrencySymbol } from '../utils/utils';
import { Currency } from '../models';

export default () => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(Object.keys(CURRENCIES).map(c => {
      const symbol = getCurrencySymbol(c);
      return new Currency({ code: c, name: CURRENCIES[c], symbol });
    }));
  }, []);
  return items;
};