import {useState, useEffect} from 'react';
import { Storage } from 'aws-amplify';

const parseImageData = (imageData) => {
  if(!imageData) return null;
  const {photoKey} = JSON.parse(imageData);
  return photoKey
}

const useImageUrl = (imageData) => {
  const [imgUrl, setImgUrl] = useState({result: null, err: null});

  useEffect(() => {
    if(!imageData || imgUrl.result) return;
    const key = parseImageData(imageData);
    if(!key) return;
    Storage.get(key)
      .then(result => setImgUrl({result}))
      .catch(err => setImgUrl({err})); 
  },[imageData]);

  return imgUrl;
}

export default useImageUrl;