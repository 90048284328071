import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  CardContent,
  Typography
} from '@material-ui/core';

import { MovementActionDisplayTypeKeys as mt } from '../../constants/MovementActionDisplayType';
import { UIContext } from '../../contexts/ui';
import EditorField from '../Common/EditorField';
import LocationTimeline from '../Location/LocationTimeline';
import { RequestColor } from "../../constants/RequestConstants";
import PilotTagAutocomplete from '../Tag/PilotTagAutocomplete';
import { Action, PortCall, RequestType, ActionMovementType, ActionState } from '../../models';
import LinesmanTagAutocomplete from '../Tag/LinesmanTagAutocomplete';
import MooringVesselTagAutocomplete from '../Tag/MooringVesselTagAutocomplete';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { ActionTypeIds } from '../../environment';
import { getActionNext, getActionValidMovements, isActionAnyArrival, isActionAnyDeparture, isDepartureOnly } from '../../utils/getters';
import { generateArrivalTimePlannedCheck, generateShiftTimePlannedCheck, generateDepartureTimePlannedCheck } from "../../utils/generators";
import { ActionDetailsHref } from '../../constants/ActionDetails';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import Checklist from '../Todo/Checklist';
import DocumentEdit from '../Documents/DocumentEdit';
import VoyageAutocomplete from '../Voyage/VoyageAutocomplete';

const { color } = RequestColor;

const useStyles = makeStyles(() => ({
  formLabelLabel: {
    //textTransform: 'uppercase',
    marginBottom: '9px',
    marginTop: '26px',
  },
  formLabelLabelFirst: {
    marginTop: '0',
  },
  chipRoot: {
    marginRight: '0.5rem',
  },
  cssLabel: {
    color: color,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${color} !important`,
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '5px',
    borderColor: `${color} !important`,
  },

}));

export const ActionScheduleEdit = ({
  portCall,
  portCallReference,
  setPortCall,
  action,
  setAction,
  activeAgent,
  selectedRequest,
  setSelectedRequest,
  objDocuments,
  setDocuments,
  checkSaveState,
  saveDisabled,
  handleChangeTodos,
  handleLocalError
}) => {
  const classes = useStyles();
  const [uiContext,] = useContext(UIContext);
  const { readOnly, agentPortal } = uiContext;
  const [request, setRequest] = useState();
  const { t } = useTranslation();
  const isVoyageAdmin = uiContext.isVoyageAdmin;
  const isMovement = action && action.type.id === ActionTypeIds.MOVEMENT;
  const isArrivalDeparture = action && (action.movementType === ActionMovementType.ARRIVAL || action.movementType === ActionMovementType.DEPARTURE);

  // Storing error for action timePlanned - string message
  const [error, setError] = useState("");

  // Intermediate store for action time planned
  const [actionTimePlanned, setActionTimePlanned] = useState(action.timePlanned);


  // Update action time planned if the incoming changes
  useEffect(() => {
    setActionTimePlanned(action.timePlanned)
    setError("");
  }, [action.timePlanned]);

  //detect error to set errorLocal to parent component
  useEffect(() => {
    handleLocalError(error);
  }, [error, handleLocalError])

  useEffect(() => {
    if (!selectedRequest || !selectedRequest.id) {
      setRequest(null);
    } else if (selectedRequest.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED
      || selectedRequest.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED) {
      setRequest({
        id: selectedRequest.id,
        timePlanned: selectedRequest.requestedTimes[action.id].timePlanned,
        time: selectedRequest.requestedTimes[action.id].timePlanned,
      });
    } else {
      setRequest(null);
    }
  }, [selectedRequest]);

  const handleChange = (name, value) => {
    setAction(Action.copyOf(action, updated => { updated[name] = value; }));
  };

  useEffect(() => {
    handlePlannedTimeChange(actionTimePlanned);
  }, [t]);

  const handlePlannedTimeChange = (value) => {
    if (!value) {
      setActionTimePlanned(value);
    } else {
      let checks = "";
      switch (action.movementType) {
        case ActionMovementType.ARRIVAL:
          checks = generateArrivalTimePlannedCheck(value, portCallReference.actions, action);
          break;
        case ActionMovementType.SHIFT_DEPARTURE:
        case ActionMovementType.SHIFT_ARRIVAL:
          checks = generateShiftTimePlannedCheck(value, action.id, portCallReference.actions);
          break;
        case ActionMovementType.DEPARTURE:
          checks = generateDepartureTimePlannedCheck(value, portCallReference.actions);
          break;
        default:
          return;
      }
      // Update the editor with the relevant error messages
      setError(checks ? t(checks) : '');
      // If there are no checks update the time planned
      checks ? setActionTimePlanned(value) : setAction(Action.copyOf(action, updated => { updated.timePlanned = value; }));
    }
  }

  const handleLocationChange = (value) => {
    // may need to change multiple actions so do it directly on portcall
    let newActions = portCall.actions.filter(a => a.id !== action.id);

    // find next location that needs a change
    let next;
    if (isActionAnyArrival(action)) {
      next = action;
      do { next = getActionNext(getActionValidMovements(portCall.actions), next); }
      while (next && !isActionAnyDeparture(next));
    }

    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [
        ...newActions.filter(a => !next || a.id !== next.id),
        Action.copyOf(action, updated => {
          updated.movementLocation = value;
          value ? updated.actionMovementLocationId = value.id : updated.actionMovementLocationId = null
        }),
        ...next ? [Action.copyOf(next, updated => {
          updated.movementLocation = value;
          value ? updated.actionMovementLocationId = value.id : updated.actionMovementLocationId = null
        })] : []
      ];
    }));
  }

  const handleRequestTimeChange = (value) => {
    setSelectedRequest({
      ...selectedRequest,
      requestedTimes: {
        ...selectedRequest.requestedTimes,
        [action.id]: { timePlanned: value }
      }
    });
    setRequest({
      ...request,
      time: value,
    });
  };

  // TODO until datastore supports empty string arrays
  const movementPilots = useMemo(() => action && action.movementPilots ? action.movementPilots.filter(t => t) : [], [action]);
  const setMovementPilots = useCallback((value) => setAction(Action.copyOf(action, updated => { updated.movementPilots = value.length ? value : ['']; })), [action, setAction]);
  // TODO until datastore supports empty string arrays
  const movementLinesmen = useMemo(() => action && action.movementLinesmen ? action.movementLinesmen.filter(t => t) : [], [action]);
  const setMovementLinesmen = useCallback((value) => setAction(Action.copyOf(action, updated => { updated.movementLinesmen = value.length ? value : ['']; })), [action, setAction]);
  // TODO until datastore supports empty string arrays
  const movementMooringVessels = useMemo(() => action && action.movementMooringVessels ? action.movementMooringVessels.filter(t => t) : [], [action]);
  const setMovementMooringVessels = useCallback((value) => setAction(Action.copyOf(action, updated => { updated.movementMooringVessels = value.length ? value : ['']; })), [action, setAction]);

  // Action Label based on movement type
  const actionLabel = isMovement && t(mt[action.movementType]);

  const uneditableState = action.state === ActionState.COMPLETED || action.state === ActionState.IN_PROGRESS;
  const isDepartureOnlyPortCall = isDepartureOnly(portCall?.actions);

  return (
    <>
      <CardContent>
        <Typography
          className={classes.formLabelLabelFirst}
          id={ActionDetailsHref.HREF_SCHEDULE}
          variant="h6"
        >
          {t('ActionScheduleEdit.Labels.MovementSchedule', { movement: actionLabel })}
        </Typography>
        {isArrivalDeparture &&
          <VoyageAutocomplete
            id='VoyageIdInput'
            onChange={(value) => handleChange('actionVoyageId', value?.id ? value?.id : null)}
            disabled={true}
            vesselId={portCall.portCallVesselId}
            voyageId={action?.actionVoyageId}
          />
        }
        <StringKeyboardDateTimePicker
          warningPastDate={true}
          disabled={readOnly}
          label={t('ActionScheduleEdit.Labels.RequestedTime')}
          fullWidth
          margin="normal"
          allowEmptyValue
          value={action.timeRequested}
          okLabel={t('ActionScheduleEdit.Buttons.SetRequestedTime')}
          onChange={(value) => handleChange("timeRequested", value)}
        />
        {
          request && request.id ? (
            <StringKeyboardDateTimePicker
              warningPastDate={true}
              id={'ActionSchedulePlannedTime'}
              label={t('ActionScheduleEdit.Labels.PlannedTime')}
              fullWidth
              margin="normal"
              value={request.time}
              onChange={(value) => handleRequestTimeChange(value)}
              okLabel={t('ActionScheduleEdit.Buttons.SetPlannedTime')}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric"
              }}
            />
          ) : (
            <StringKeyboardDateTimePicker
              warningPastDate={true}
              id={'ActionSchedulePlannedTime'}
              disabled={readOnly || uneditableState}
              label={t('ActionScheduleEdit.Labels.PlannedTime')}
              fullWidth
              margin={error ? "" : "normal"}
              error={error ? true : false}
              helperText={error || ""}
              value={actionTimePlanned}
              okLabel={t('ActionScheduleEdit.Buttons.SetPlannedTime')}
              onChange={handlePlannedTimeChange}
            />
          )
        }
        <StringKeyboardDateTimePicker
          disabled={readOnly}
          label={t('ActionScheduleEdit.Labels.VesselReadyTime')}
          fullWidth
          margin="normal"
          allowEmptyValue
          value={action.movementVesselReadyTime}
          okLabel={t('ActionScheduleEdit.Buttons.SetVesselReadyTime')}
          onChange={(value) => handleChange("movementVesselReadyTime", value)}
        />
        <StringKeyboardDateTimePicker
          disabled={readOnly}
          label={t('ActionScheduleEdit.Labels.PilotBoardingTime')}
          fullWidth
          margin="normal"
          allowEmptyValue
          value={action.movementPilotBoardingTime}
          okLabel={t('ActionScheduleEdit.Buttons.SetPilotBoardingTime')}
          onChange={(value) => handleChange("movementPilotBoardingTime", value)}
        />
        <StringKeyboardDateTimePicker
          disabled={true}
          ampm={false}
          label={t('ActionScheduleEdit.Labels.ActualTime')}
          fullWidth
          margin="normal"
          allowEmptyValue
          value={action.timeActual}
          okLabel={t('ActionScheduleEdit.Buttons.SetActualTime')}
          onChange={(value) => handleChange("timeActual", value)}
        />

        {isMovement &&
          <LocationAutocomplete
            id="ArrivalLocationInput"
            disabled={readOnly || (!isActionAnyArrival(action) && !isDepartureOnlyPortCall) || uneditableState}
            value={action.movementLocation || null}
            onChange={handleLocationChange}
            label={t('ActionScheduleEdit.Labels.MovementLocation', { movement: actionLabel })}
            helperText={(action.movementLocation?.dockable === false) ? t('ActionScheduleEdit.Labels.NotBerthableLocation') : ""}
          />
        }

        {!agentPortal &&
          <Box height="20rem" mt="1rem">
            <LocationTimeline customPortCall={portCall} customAction={action} />
          </Box>
        }
        <>
          <Typography
            className={classes.formLabelLabel}
            id={ActionDetailsHref.HREF_CONTACT}
            variant="h6"
          >
            {t('ActionScheduleEdit.Labels.CharterAgent')}
          </Typography>
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Name')}
            value={activeAgent ? activeAgent.name : ''}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.DisplayName')}
            value={activeAgent ? activeAgent.displayName : ''}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.TelephoneNumber')}
            value={activeAgent ? activeAgent.number : ''}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Fax')}
            value={activeAgent ? activeAgent.fax : ''}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Email')}
            value={activeAgent ? activeAgent.email : ''}
          />
        </>
      </CardContent>

      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_CHECKLIST}
          variant="h6"
        >
          {t('MovementEdit.Labels.Checklist')}
        </Typography>
        <Checklist
          items={action.todos}
          actionId={action.id}
          showHeader={true}
          showDetails={true}
          clickHandler={handleChangeTodos}
          deleteHandler={handleChangeTodos}
          createHandler={handleChangeTodos}
        />
      </CardContent>

      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_ATTACHMENTS}
          variant="h6"
        >
          {t('MovementEdit.Labels.Attachments')}
        </Typography>
        <DocumentEdit
          filePath={`portcalls/${portCall.id}/actions/${action.id}/`}
          portCallId={portCall.id}
          objId={action.objId}
          objDocuments={objDocuments}
          onChange={setDocuments}
          checkSaveState={checkSaveState}
          saveDisabled={saveDisabled}
        />
      </CardContent>

      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_PILOTAGE}
          variant="h6"
        >
          {t('ActionScheduleEdit.Labels.Pilotage')}
        </Typography>
        <PilotTagAutocomplete
          disabled={readOnly}
          value={movementPilots}
          onChange={setMovementPilots}
          label={t('ActionScheduleEdit.Labels.Pilots')}
        />
        <LinesmanTagAutocomplete
          disabled={readOnly}
          value={movementLinesmen}
          onChange={setMovementLinesmen}
          label={t('ActionScheduleEdit.Labels.Linesmen')}
        />
        <MooringVesselTagAutocomplete
          disabled={readOnly}
          value={movementMooringVessels}
          onChange={setMovementMooringVessels}
          label={t('ActionScheduleEdit.Labels.MooringVessels')}
        />
      </CardContent>
    </>
  )
}

ActionScheduleEdit.propTypes = {
  portCall: PropTypes.object,
  setPortCall: PropTypes.func,
  action: PropTypes.object,
  setAction: PropTypes.func,
  activeAgent: PropTypes.object,
  selectedRequest: PropTypes.object,
  setSelectedRequest: PropTypes.func,
}

export default ActionScheduleEdit;
