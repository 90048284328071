import React, { useContext } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { ListItem, ListItemText, TextField } from '@material-ui/core';
import { actionTypeFilterOptions } from '../../utils/sorters';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import { ActionTypeIds } from '../../environment';

const ActionTypeAutocomplete = ({ value, onChange, startAdornment, label, required, helperText, excludeOption, ...other }) => {
  const { t } = useTranslation();
  const { actionTypes } = useContext(DataStoreContext);
  const getActionTypeName = (at) => {
    if (at.id === ActionTypeIds.MOVEMENT) return t('ActionType.Labels.Movement');
    if (at.id === ActionTypeIds.EVENT) return t('ActionType.Labels.Event');
    return at.name;
  }
  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={actionTypes.filter(at => {
        if(at?.deleted) return false;
        if(excludeOption && excludeOption(at)) return false;
        return true;
      })}
      filterOptions={actionTypeFilterOptions}
      getOptionLabel={(item) => (item && getActionTypeName(item))}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={getActionTypeName(item)}/>
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          helperText={helperText}
        />
      )}
    />
  );
};

export default ActionTypeAutocomplete;