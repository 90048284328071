import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { Attrs, FeatureType } from '../constants';
import CircleStyle from 'ol/style/Circle';
import { OUTLINE_THRESHOLD } from './OLVesselLayer';

const ZOOM_ZERO_RESOLUTION = 156543.03390625;
const FillColor = 'rgba(255, 255, 255, 0.7)'

const outlineStyle = new Style({
  stroke: new Stroke({
    color: 'white',
    width: 2
  }),
  fill: new Fill({
    color: FillColor
  }),  
});

const createSpotlightStyle = (feature, resolution, dom) => {
  const type = feature.get(Attrs.TYPE);
  if(type !== FeatureType.Spotlight) return null;

  const currentZoom = Math.log2(ZOOM_ZERO_RESOLUTION) - Math.log2(resolution);
  const outline = resolution < OUTLINE_THRESHOLD;
  const radius = currentZoom;
  
  // Switch geometries based on zoom level
  const geom = outline ? Attrs.SPOTLIGHT : Attrs.GEOM;
  if(feature.getGeometryName() !== geom) feature.setGeometryName( geom );

  if(outline) {
    return outlineStyle;
  }
  
  return new Style({
    image: new CircleStyle({
      radius: radius + 10,
      stroke: new Stroke({
        color: 'white',
        width: 2
      }),
      fill: new Fill({
        color: FillColor,
      }),
    }),
  }); 
};

class SpotlightVesselLayer extends VectorLayer {
  constructor(opt_options) {
    opt_options.style = createSpotlightStyle;
    super(opt_options);
  };
};

export default SpotlightVesselLayer;
