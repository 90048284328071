import React, { createContext, useReducer } from 'react';

const initialState = {
  creatingPortCall: false,
  inProgress: {
    state: false,
  },
  dialogOpen: false,
  selectedPortCallId: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'openCreatePortCallDialog': 
      return Object.assign({}, state, {
        creatingPortCall: true,
        dialogOpen: true,
        selectedPortCallId: null,
      });

    case 'openEditPortCallDialog':
      return Object.assign({}, state, {
        creatingPortCall: false,
        dialogOpen: true,
        selectedPortCallId: action.payload.item,
      });

    case 'closeCreatePortCallDialog':
    case 'closeEditPortCallDialog':
      return Object.assign({}, state, {
        creatingPortCall: false,
        dialogOpen: false,
        selectedPortCallId: null,
      });
    
    case 'inProgressPortCallDialog':
      return Object.assign({}, state, {
        inProgress: action.payload.state
      })
    default:
      return state;
  }
}

export const openCreatePortCallDialog = () => ({
  type: 'openCreatePortCallDialog',
  payload: {}
});

export const closeCreatePortCallDialog = () => ({
  type: 'closeCreatePortCallDialog',
  payload: {}
});

export const closeEditPortCallDialog = () => ({
  type: 'closeEditPortCallDialog',
  payload: {}
});

export const openEditPortCallDialog = (item, state) => ({
  type: 'openEditPortCallDialog',
  payload: { item, state }
});

export const inProgressPortCallDialog = (state) => ({
  type: 'inProgressPortCallDialog',
  payload: {state},
});

export const DialogContext = createContext();

export const DialogContextProvider = ({ children }) => (
  <DialogContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DialogContext.Provider>
);