import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  Card,
  Tooltip,
  Divider
} from '@material-ui/core';
import PortCallTimelineStatusLabel from './PortCallTimelineStatusLabel';
import VesselListItem from '../Vessel/VesselListItem';
import { Attachment } from 'mdi-material-ui';
import VesselSpotlightLauncher from '../Spotlight/VesselSpotlightLauncher';
import { ActionState } from '../../models';
import { itemRequested } from '../../constants/RequestConstants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  selected: {
    border: '.25rem solid rgb(000,150,214) !important',
  },
  vesselCard: {
    display: "flex",
    height: "5rem",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    boxSizing: 'border-box',
    overflow: 'visible',
    paddingRight: '3rem',
    margin: '1rem',
  },
  selectableVesselCard: {
    '&:hover': {
      background: theme.palette.action.hover
    }
  },
  itemRequested,
  divider: {
    margin: '0.5rem 1.2rem 0.5rem 0.5rem'
  }
}));

const PortCallVesselCard = ({
  nextAction,
  portCall,
  actionId,
  vessel,
  setMapDialogOpen,
  disableLifeCycle,
  readOnly,
  isSynced,
  nextActionDisabled,
  nextMovementDisabled,
  action,
  portCallRequests,
  handleNextAction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tooltipTitle = () => {
    if (action.state === ActionState.REQUESTED) {
      return t("PortCallMenuItem.Labels.RequestApprovalWarning")
    } else if (!action.movementLocation) {
      return t("PortCallMenuItem.Labels.NoArrivalLocationWarning")
    } else {
      return t("PortCallMenuItem.Labels.NonDockableLocationWarning")
    };
  };

  const nextActionButton = () => {
    const button =
      <Button variant="contained"
        color="primary"
        onClick={(e) => { handleNextAction(); e.preventDefault(); }}
        disabled={disableLifeCycle || readOnly || !isSynced || nextActionDisabled}
        id={'PortCallDetailsNextActionButton'}
      >
        <Typography noWrap>{t(nextAction)}
        </Typography>
      </Button>
    if (nextMovementDisabled) {
      return <Tooltip title={tooltipTitle()}><span>{button}</span></Tooltip>
    } else return button
  };

  return <Card className={`
      ${classes.vesselCard} 
      ${actionId  ? '' : classes.selected}
    `}>
    <Box flexGrow={1}>
      <VesselListItem
        vesselData={vessel}
        portCallId={portCall?.referenceId}
        disabled={!vessel}
      />
    </Box>
    <VesselSpotlightLauncher vesselData={vessel} setMapDialogOpen={setMapDialogOpen} />
    <Divider orientation="vertical" flexItem className={classes.divider} />

    {/* TODO temporary fix until datastore supports empty string arrays */}
    {portCall?.documents.filter(d => d)?.length > 0 &&
      <Attachment style={{
        transform: "rotate(-90deg)",
        marginRight: "1rem"
      }}
      />
    }
    {nextAction &&
      <Box display={"flex"} alignItems={"center"}>
        {nextActionButton}
      </Box> 
    }
    {portCall &&
      <PortCallTimelineStatusLabel portCall={portCall} />
    }
    {portCallRequests?.length > 0 ? <div className={classes.itemRequested} /> : null}
  </Card>
}

export default PortCallVesselCard;