import React, { useState, useEffect, useContext, useCallback, forwardRef, useMemo } from 'react';
import { API, graphqlOperation, DataStore } from 'aws-amplify';
import { onCreateAuditLog } from '../../graphql/subscriptions';
import { AuditLogType } from '../../models/index';
import { Typography, IconButton, Box, ListItem, ListItemIcon } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Close, OpenInNew, AlertOctagon, CommentTextOutline } from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import { useSnackbar, SnackbarContent } from 'notistack';
import { format } from 'date-fns';
import { UIContext } from '../../contexts/ui';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import VesselListItem from '../Vessel/VesselListItem';
import ActionState from '../Action/ActionState';
import ScheduleTime from '../ScheduleTime';
import { ActionTypeIds } from '../../environment';
import { Action, ActionState as as_, ActionMovementType as mat_, PortCall } from '../../models';
import { styles } from './snackBarStyles'
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';

const validLogEntries = [
  AuditLogType.AUDIT_ACTION_STATE,
  AuditLogType.AUDIT_ERROR,
  AuditLogType.AUDIT_COMMENT
];

const getPortCall = async (portCallId) => {
  return await DataStore.query(PortCall, c => c.id('eq', portCallId));
};

const getAction = async (actionId) => {
  return await DataStore.query(Action, c => c.id('eq', actionId));
};

/** 
 * Action details component
 * @param {Action} action Action object
 * @return {React.Node}
*/
const ActionDetails = ({action}) => {
  return(
    <ListItem dense disableGutters>
      <ListItemIcon>
        <ActionState action={action}/>
      </ListItemIcon>
      <ScheduleTime planned={action.timePlanned} actual={action.timeActual} />
    </ListItem>
  );
};

/**
 * Movement state snack bar content
 * props:
 *   id: snackbar id
 *   item: audit log item
 *   action: movement action
 */
const MovementStateSnackbarContent = forwardRef(({id, item, action}, ref) => {
  const { closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const movementStateUpdateLabels = useMemo(() => (
    {
      arrival: {
        scheduled : t('Snackbar.Labels.ArrivalScheduled'),
        planned : t('Snackbar.Labels.ArrivalPlanned'),
        requested: t('Snackbar.Labels.ArrivalRequested'),
        started: t('Snackbar.Labels.ArrivalStarted'),
        completed: t('Snackbar.Labels.ArrivalCompleted'),
        cancelled: t('Snackbar.Labels.ArrivalCancelled'),
        approved: t('Snackbar.Labels.ArrivalApproved'),
        declined: t('Snackbar.Labels.ArrivalDeclined'),
      },
      departure: {
        scheduled: t('Snackbar.Labels.DepartureScheduled'),
        planned : t('Snackbar.Labels.DeparturePlanned'),
        requested: t('Snackbar.Labels.DepartureRequested'),
        started: t('Snackbar.Labels.DepartureStarted'),
        completed: t('Snackbar.Labels.DepartureCompleted'),
        cancelled: t('Snackbar.Labels.DepartureCancelled'),
        approved: t('Snackbar.Labels.DepartureApproved'),
        declined: t('Snackbar.Labels.DepartureDeclined'),
    
      },
      shiftArrival: {
        scheduled: t('Snackbar.Labels.ShiftArrivalScheduled'),
        planned : t('Snackbar.Labels.ShiftArrivalPlanned'),
        started: t('Snackbar.Labels.ShiftArrivalStarted'),
        completed: t('Snackbar.Labels.ShiftArrivalCompleted'),
        cancelled: t('Snackbar.Labels.ShiftArrivalCancelled'),
        approved: t('Snackbar.Labels.ShiftArrivalApproved'),
        declined: t('Snackbar.Labels.ShiftArrivalDeclined'),
      },
      shiftDeparture: {
        scheduled: t('Snackbar.Labels.ShiftDepartureScheduled'),
        planned : t('Snackbar.Labels.ShiftDeparturePlanned'),
        started: t('Snackbar.Labels.ShiftDepartureStarted'),
        completed: t('Snackbar.Labels.ShiftDepartureCompleted'),
        cancelled: t('Snackbar.Labels.ShiftDepartureCancelled'),
        approved: t('Snackbar.Labels.ShiftDepartureApproved'),
        declined: t('Snackbar.Labels.ShiftDepartureDeclined'),
      }
    }
  ), [t])
  /**
   * Generate a string describing a movement action state change.
   * @param {AuditLog} item Audit log item
   * @returns {String} Description of change to action
   */
  const headerText = useCallback((item, action) => {
    const oldState = item.old ? JSON.parse(item.old) : null;
    const newState = item.new ? JSON.parse(item.new) : null;
    if(!action) return '';
    
    let movementType = ''
    let update = '';
    
    switch(action.movementType) {
      case mat_.ARRIVAL: movementType += 'arrival'; break;
      case mat_.DEPARTURE: movementType += 'departure'; break;
      case mat_.SHIFT_ARRIVAL: movementType += 'shiftArrival'; break;
      case mat_.SHIFT_DEPARTURE: movementType += 'shiftDeparture'; break;
      default: break;
    }
    
    if(!oldState && newState?.state) {
      // Action created
      if(newState.state === as_.REQUESTED) update = 'requested';
      else update = 'scheduled';
    } else if((oldState?.state === as_.APPROVAL_PENDING && newState?.state === as_.PLANNED) ||
      (oldState?.state === as_.REQUESTED)) {
      // Action approved
      update = 'approved'; 
    } else {
      switch(newState.state) {
        case as_.APPROVAL_DECLINED: update = 'declined'; break;
        case as_.APPROVAL_PENDING: update = 'planned'; break;
        case as_.PLANNED: update = 'planned'; break;
        case as_.IN_PROGRESS: update = 'started'; break;
        case as_.COMPLETED: update = 'completed'; break;
        case as_.CANCELLED: update = 'cancelled'; break;
        default: break;
      }
    }
    
    return movementType && update ? movementStateUpdateLabels[movementType][update] : '';
  }, [movementStateUpdateLabels]);

  const handleClose = () => {
    closeSnackbar(id)
  };

  const handleLink = () => {
    if(action) {
      const route = `/port-call/${action.portCall.id}/action/${action.id}`;
      route && history.push(route); 
      handleClose();
    }
  };

  return (
    action ?
    <SnackbarContent ref={ref} >
      <AuditLogSnackbarContent
        title={headerText(item, action)}
        userId={item.userId}
        timeChanged={item.timeChanged}
        handleClose={handleClose}
        handleLink={handleLink}
      >
        <div style={{display: "flex", flexDirection: "row", gap: "16px", alignItems: "center"}}>
          <div>
            <ActionDetails action={action} />
          </div>
          <div>
            <VesselListItem vesselData={action?.portCall?.vesselData} disableGutters dense />
          </div>
        </div>
      </AuditLogSnackbarContent>
    </SnackbarContent> : null
  );
});

/**
 * Action state snackbar contents. Enqueues a new snackbar for the action linked to the audit log item 
 * props:
 *   item: audit log item 
 */
const ActionStateSnackbar = ({item}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetch = async (item) => {
      const auditAction = (await getAction(item.objectId)).pop();
      const validAction = auditAction?.type.id === ActionTypeIds.MOVEMENT && auditAction.movementType;
      if(validAction && auditAction?.state !== as_.DELETED) {
        enqueueSnackbar(item.id, {
          key: item.id,
          content: key => <MovementStateSnackbarContent id={key} item={item} action={auditAction} />
        });
      }
    };

    item !== undefined && item.type === AuditLogType.AUDIT_ACTION_STATE && fetch(item);
  }, [item, enqueueSnackbar]);

  return null;  
};

/**
 * Snackbar content for portcall based audit items
 * props:
 *   id: snackbar id
 *   item: audit log item
 *   portCall: port call data
 *   action: action data
 *   header: Custom header component
 */
const PortCallSnackbarContent = forwardRef(({id, item, portCall, action, header}, ref) => {
  const { closeSnackbar } = useSnackbar();
  const history = useHistory();

  const handleClose = () => closeSnackbar(id);
  const handleLink = () => {
    const route = portCall && action
      ? `/port-call/${portCall.id}/action/${action.id}/logbook`
      : `/port-call/${portCall.id}/logbook`;
    route && history.push(route); 
    handleClose();
  };

  return (
    portCall ?
    <SnackbarContent ref={ref} >
      <AuditLogSnackbarContent
        title={item.comment}
        userId={item.userId}
        timeChanged={item.timeChanged}
        handleClose={handleClose}
        handleLink={handleLink}
        header={header}
      >
        <div style={{display: "flex", flexDirection: "row", gap: "16px", alignItems: "center"}}>
          <div>
            <VesselListItem vesselData={portCall.vesselData} disableGutters dense />
          </div>
        </div>
      </AuditLogSnackbarContent>
    </SnackbarContent> : null
  );
});

const ErrorHeader = ({text}) => {
  const theme = useTheme();
  return (
    <div style={{
      display: "flex", 
      flexDirection: "row", 
      gap: "10px", 
      alignItems: "center", 
      backgroundColor: theme.palette.error.main, 
      paddingLeft: "4px", 
      paddingRight: "8px", 
      paddingTop: "4px", 
      paddingBottom: "4px",
      marginBottom: "2px",
      maxWidth: "300px"
    }}>
      <AlertOctagon fontSize="small"/>
      <Typography noWrap ariant='body1'>
        {text}
      </Typography>
    </div>
  );
};

/**
 * Snackbar displaying errors from audit log
 * props:
 *   item: audit log item
 */
const ErrorSnackbar = ({item}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetch = async (item) => {
      const auditPortCall = (await getPortCall(item.portCallId)).pop();
      if(auditPortCall) {
        enqueueSnackbar(item.id, {
          key: item.id,
          content: key => <PortCallSnackbarContent id={key} item={item} portCall={auditPortCall} header={<ErrorHeader text={item.comment} />}/>
        });
      }
    };

    item !== undefined && item.type === AuditLogType.AUDIT_ERROR && fetch(item);
  }, [item, enqueueSnackbar]);

  return null;
};

const CommentHeader = ({text}) => {
  return (
    <div style={{
      display: "flex", 
      flexDirection: "row", 
      gap: "10px", 
      alignItems: "center", 
      paddingLeft: "4px", 
      paddingRight: "8px", 
      paddingTop: "4px", 
      paddingBottom: "4px",
      marginBottom: "2px",
      maxWidth: "300px"
    }}>
      <CommentTextOutline fontSize="small"/>
      <Typography noWrap variant='body1'>
        {text}
      </Typography>
    </div>
  );
};

/**
 * Snackbar displaying user comments from audit log
 * props:
 *   item: audit log item
 */
const UserCommentSnackbar = ({item}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetch = async (item) => {
      const auditPortCall = (await getPortCall(item.portCallId)).pop();
      const auditAction = item.objectId && (await getAction(item.objectId)).pop();
      if(auditPortCall) {
        enqueueSnackbar(item.id, {
          key: item.id,
          content: key => <PortCallSnackbarContent id={key} item={item} portCall={auditPortCall} action={auditAction} header={<CommentHeader text={item.comment} />} />
        });
      }
    };

    item !== undefined && item.type === AuditLogType.AUDIT_COMMENT && fetch(item);
  }, [item, enqueueSnackbar]);

  return null;
}

/**
 * Header component for message, user and timestamp
 * props:
 *   text: Notification text, what is the notification about
 *   userId: User ID
 *   timeChanged: Time of audit log entry
 *   customHeader: Custom header component
 */
const Header = ({text, userId, timeChanged, customHeader}) => {
  const { timeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  const infoHeader = () => {
    return (
      <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
        <Typography variant='body1'>
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <div style={{display: "flex", flexDirection: "column", paddingRight: "70px"}}>
      <Typography variant='caption' display='block'>
        { customHeader ? customHeader : infoHeader() }
        { t('Snackbar.Labels.UpdatedBy', {userId: userId, time: format(new Date(timeChanged), timeFormat, { locale: DateFnsLanguageMap[i18n.language] })}) }
      </Typography>
    </div>
  );
};

/**
 * Audit log snackbar content. Generic layout for snackbar containing header, close/link buttons and custom content
 * props:
 *   title: Notification text placed in header
 *   userId: User ID
 *   timeChanged: Time of audit log entry
 *   handleClose: Callback for close button
 *   handleLink: Callback for link button
 *   children: Child components for custom content
 *   classes: Styling classes 
 */
const AuditLogSnackbarContent = withStyles(styles)(({title, userId, timeChanged, error, handleClose, handleLink, header, children, classes}) => {
  return (
    <Box className={classes.root} width={'100%'}>
      <div style={{display: "flex", flexDirection: "column", paddingTop: "16px", paddingBottom: "6px", paddingLeft: "16px", paddingRight: "16px"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Header text={title} userId={userId} timeChanged={timeChanged} error={error} customHeader={header}/>
          <div style={{display: "flex", flexDirection: "row", position: "absolute", top: "10px", right: "10px"}}>
            {handleLink && 
              <IconButton
                id='NotificationLinkButton'
                style={{padding: "6px"}}
                aria-label='open'
                color='inherit'
                onClick={handleLink}
              >
                <OpenInNew fontSize="small"/>
              </IconButton>
            }
            <IconButton
              id='NotificationCloseButton'
              style={{padding: "6px"}}
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <Close fontSize="small"/>
            </IconButton>
          </div>
        </div>
        {children}
      </div>
    </Box>
  );
});

/**
 * Snackbar notification triggered of new entries to the audit log
 */
const AuditLogSnackbar = () => {
  const [ uiContext, ] = useContext(UIContext);
  const [ auditEntry, setAuditEntry ] = useState(undefined);

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onCreateAuditLog)).subscribe({
      next: ({ provider, value }) => {
        const item = value?.data?.onCreateAuditLog;
        if(uiContext.userName !== item?.userId &&
          validLogEntries.includes(item?.type)) {
          setAuditEntry(item);
        }
      },
      error: error => console.warn(error)
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [uiContext.userName]);

  return (
    <>
      <ActionStateSnackbar item={auditEntry} />
      <ErrorSnackbar item={auditEntry} />
      <UserCommentSnackbar item={auditEntry} />
    </>
    // Add components for other audit log snackbars here
  )
};

export default AuditLogSnackbar;
