import { format } from 'date-fns';

import { getFullTarrifUnit } from "../../utils/getters";
import { IntlNumberFormat } from "../../utils/currency";
import { getTariffUnitCost } from "../../utils/chargeableItems";
import { DateFnsLanguageMap } from '../../translations';
import { Box } from '@material-ui/core';

const renderTariffColumn = (description, tariffDataNotes, notes) => {
  return (
    <>
      <Box>{description}</Box>
      <Box>{tariffDataNotes}</Box>
      <Box>{notes}</Box>
    </>
    
  )
}

export const getListOfChargesRows = (chargeableItems, language, currency, total, t) => {
  const listOfChargesRows = chargeableItems.map( chargeableItem => {
    const { tariffData: {
      code,
      description,
      notes: tariffDataNotes
    },
    notes,
    quantity,
    secondaryMult,
    tariffUnit,
    tariffSecondaryUnit,
    total
    } = chargeableItem

    return {
      id: `${code}`,
      tariff: renderTariffColumn(description, tariffDataNotes, notes),
      quantity: `${tariffSecondaryUnit ? `${quantity} x ${secondaryMult}` : quantity} ${getFullTarrifUnit(tariffUnit, tariffSecondaryUnit)}`,
      unitCost: `${IntlNumberFormat(language, currency, getTariffUnitCost(chargeableItem))} / ${getFullTarrifUnit(tariffUnit, tariffSecondaryUnit)}`,
      total: IntlNumberFormat(language, currency, total)
    }
  })

  listOfChargesRows.push({
    unitCost: t('Invoice.Labels.GrossTotal'),
    total: IntlNumberFormat(language, currency, total)
  })

  return listOfChargesRows
}

export const getVesselDetailsRows = (vesselData) => ([
  {
    name: vesselData.name,
    imo: vesselData.imo,
    mmsi: vesselData.mmsi
  },
])

export const getScheduleSummaryRows = (portName, arrival, arrivalTime, departure, departureTime, dateTimeFormat, i18n) => ([
  {
    location: portName,
    arrival: arrival && format(new Date(arrivalTime), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }),
    departure: departure && format(new Date(departureTime), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })
  },
])