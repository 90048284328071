import React from 'react';
import {
  Badge,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import {
  CheckCircle,
  Close,
} from 'mdi-material-ui';

import { TodoStatus as ts } from '../../constants/TodoStatus';
import { IconMap } from './IconMap';

const useStyles = makeStyles(theme => {
  return {
    strikeThroughDone: {
      textDecoration: 'line-through'
    },
    largeBadge:{
      fontSize: '1.75rem',
      color: theme.palette.colors.green[300],
    },
    badgeBackground:{
      backgroundColor: 'white', 
      borderRadius: '50%',
    },
  }
});

const TodoListItem = ({
  item,
  backgroundColor = 'rgb(160, 160, 160)',
  clickHandler,
  deleteHandler,
  showDelete,
  disabled
}) => {
  const classes = useStyles();

  return (
    <ListItem onClick={() => !disabled && clickHandler && clickHandler(item)}>
      <ListItemAvatar>
        <Badge
          badgeContent={item.status === ts.DONE ? <div className={classes.badgeBackground}><CheckCircle className={classes.largeBadge} /></div> : null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap='circular'
        >
          <IconMap item={item} backgroundColor={backgroundColor} />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        className={`${item.status === ts.DONE ? classes.strikeThroughDone : ''}`}
        primary={item.title}
        secondary={item.description}
      />
      {showDelete && item.status !== ts.DELETED &&
        <ListItemSecondaryAction>
          {!disabled &&
            <IconButton
              edge="end"
              onClick={() => deleteHandler(item)}
            >
              <Close />
            </IconButton>
          }
        </ListItemSecondaryAction>
      }
    </ListItem>)
};

export default TodoListItem;