import React from "react";
import { makeStyles, IconButton, Typography } from "@material-ui/core";
import { DeleteOutline, Pencil } from 'mdi-material-ui';

const useStyles = makeStyles(theme => {
  return {
    columnIcon: {
      maxWidth: "40px",
      maxHeight: "40px",
      alignSelf: "auto",
      marginTop: ".6rem",
      width: "100%"
    },
  }
});

const ChargeableListEditorRow = ({ data, index, style}) => {
  const classes = useStyles();
  return (
    <div
      style={{ 
        ...style, 
        width: "auto", 
        display: "flex", 
        alignItems: "baseline", 
        minWidth: "100%", 
        gap: "10px",
      }}
      key={data[index].data.id}
      data-id={data[index].data.id}
      className={'ChargeableListEditorRow'}
    >
      <Typography
        name="name"
        style={{ width: "60%" }}
      >
        {data[index].name || ""}
      </Typography>
      <Typography
        style={{ width: "40%" }}
      >
        {data[index].data.defaultItems.length}
      </Typography>
      <IconButton
        onClick={() => data[index].onUpdate && data[index].onUpdate(data[index].data)}
        className={classes.columnIcon}
        id="UpdateDefaultChargeableItem"
      >
        <Pencil fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => data[index].onDelete && data[index].onDelete(data[index].data)}
        className={classes.columnIcon}
        id='DeleteDefaultChargeableItem'
      >
        <DeleteOutline fontSize="small" />
      </IconButton>
    </div>
  )
};

export default ChargeableListEditorRow;