import { DataStore } from 'aws-amplify';
import { API, graphqlOperation } from "aws-amplify";
import { useState, useEffect, useMemo, useCallback } from 'react';
import { PortCall, PortCallStatus } from '../models';
import { ActionTypeIds } from '../environment';
import { getVoyage } from '../graphql/queries';
import { PortCallStatusToActionState } from '../utils/getters';
import { customOnCreateVoyageSubscription } from '../utils/voyages';
import useActiveActions from './useActiveActions';
import { onUpdatePortCall } from '../graphql/subscriptions';
import { InvalidVoyageActionStates } from '../constants/StateCategories';
const deleteOp = [PortCallStatus.CANCELLED, PortCallStatus.DELETED];

/**
 * get valid movement actions include Action with CANCELLED state
 * @param {Array[Action]} actions 
 * @returns array of actions
 */
export const getValidMovements = (actions) => actions && actions
  .filter(a => a.actionTypeId === ActionTypeIds.MOVEMENT &&
    !InvalidVoyageActionStates.includes(a.state));

/**
* Create an array of port call based on an array of movement actions
* @param {Array<Action>} movements 
* @returns Array of port calls containing actions
*/
const portCallsFromMovements = (movements) => {
  if (!movements || !movements.length) return [];

  const portCallMap = new Map();

  // group movements by port call
  for (let m of movements) {
    let pc = portCallMap.get(m.portCall.id)
    if (!pc) {
      pc = { portCall: m.portCall, actions: [] };
      portCallMap.set(m.portCall.id, pc);
    }
    const updateMovement = deleteOp.includes(m.portCall.status) ? { ...m, state: PortCallStatusToActionState[m.portCall.status] } : m;
    pc.actions.push(updateMovement);
  }

  const portCalls = [];
  portCallMap.forEach((value, key) => {
    const { portCall, actions: updateActions } = value;
    const { actions: _, ...p } = portCall;
    portCalls.push({ portCall: { ...p, actions: updateActions }, actions: updateActions });
  });

  return portCalls;
};

const useVoyage = (voyageId) => {
  const subscribeIgnoreAction = true;
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const [voyage, setVoyage] = useState(undefined);
  const activeActions = useActiveActions(subscribeIgnoreAction);
  const filterActions = useMemo(() => getValidMovements(actions), [actions]);

  const getUpdateActions = useCallback((actions) => {
    if (!actions) return [];
    return actions?.map((action) => {
      const portCall = action?.portCall;
      if (!action) return action;
      const isPortCallDelete = deleteOp.includes(portCall?.status);
      //handle delete
      if (isPortCallDelete) {
        return { ...action, state: PortCallStatusToActionState[portCall?.status] }
      }
      return action;
    });
  }, []);

  useEffect(() => {
    const update = async () => {
      const result = await API.graphql(graphqlOperation(getVoyage, { id: voyageId }));
      const data = result?.data?.getVoyage || [];
      const actions = getUpdateActions(getValidMovements(data.actions.items));
      //console.log({ actions })
      setVoyage({
        ...data,
      });
      setActions(actions);
      setLoading(false);
    };

    if (!voyageId) return;

    setLoading(true);
    update();

  }, [getUpdateActions, voyageId]);

  useEffect(() => {
    let cancelled = false;
    if (activeActions && !cancelled) {
      //console.log({ activeActions });
      setActions(prev => {
        if (!prev) return prev;
        const actions = prev.map(action => {
          const activeAction = activeActions?.find((a) => a.id === action.id);
          //console.log({ action, activeAction }, activeAction?.id === action?.id)
          //update
          if (activeAction) {
            if (activeAction?.id === action?.id) {
              const isVoyageChange = action && action?.actionVoyageId !== activeAction?.actionVoyageId;
              //console.log(action?._version,action?.state, activeAction?._version,activeAction?.state);
              //if isVoyageChange remove it,else update
              if (isVoyageChange) {
                return null;
              } else if (action?._version < activeAction?._version) {
                //console.log('isUpdate',action?.state,activeAction?.state)
                return { ...activeAction };
              }
            }
          }
          return action;

        })?.filter((a) => a);
        const addActions = activeActions?.filter((a) => a?.actionVoyageId === voyage?.id && actions?.every((action) => action?.id !== a.id)) || [];
        return addActions.length ? [...actions, ...addActions] : actions;
      });
    }

    return () => {
      cancelled = true;
    }
  }, [activeActions, voyage?.id])


  useEffect(() => {
    const subscriptions = [];
    let cancelled = false;
    //subscribtion for voyage change
    const onUpdateVoyageSubscribe = API.graphql(graphqlOperation(customOnCreateVoyageSubscription)).subscribe({
      next: ({ value }) => {
        const updateVoyage = value?.data?.onUpdateVoyage;
        //console.log("-----onUpdateVoyage-----")
        //console.log({updateVoyage});
        if (updateVoyage && updateVoyage.id === voyage?.id && !cancelled) {
          setVoyage(updateVoyage);
        }
      }
    });

    //subscribtion for portcall staus change to CANCELLED/DELETED 
    subscriptions.push(DataStore.observe(PortCall).subscribe(msg => {
      const { element } = msg;
      const deleteState = PortCallStatusToActionState[element?.status];
      let actions;
      setActions(prev => {
        actions = prev && prev.filter(i => i.portCall.id === element.id);
        return prev; // this doesn't emit a state change
      });
      if (actions && element && deleteState && !cancelled) {
        setActions(prev => !prev ? prev :
          prev.map(i => {
            if ((i.actionPortCallId || i.portCall.id) === element.id) {
              return { ...i, state: deleteState }
            }
            return i;
          }
          ));
      }
    }));

    //subscribtion for portcall change that data store unaware of such as archive
    const onUpdatePortCallSubscribe = API.graphql(graphqlOperation(onUpdatePortCall)).subscribe({
      next: ({ value }) => {
        const element = value?.data?.onUpdatePortCall;
        let actions;
        setActions(prev => {
          actions = prev && prev.filter(i => i.portCall.id === element.id);
          return prev; // this doesn't emit a state change
        });
        if (actions && element && !cancelled) {
          setActions(prev => !prev ? prev :
            prev.map(i => {
              if ((i.actionPortCallId || i.portCall.id) === element.id) {
                const item = [...(i.portCall.actions.items || [])];
                return { ...i, portCall: { ...element, actions: item } }
              }
              return i;
            }
            ));
        }
      }
    });


    return () => {
      onUpdateVoyageSubscribe.unsubscribe();
      onUpdatePortCallSubscribe.unsubscribe();
      subscriptions.forEach(s => s.unsubscribe());
      cancelled = true;
    }
  }, [voyage?.id]);

  const voyageFirstDeparture = useMemo(() => {
    return voyage?.voyageFirstDeparture ? actions?.find((a) => a.id === JSON.parse(voyage?.voyageFirstDeparture)?.id) : null;
  }, [actions, voyage?.voyageFirstDeparture]);

  const voyageLastArrival = useMemo(() => {
    return voyage?.voyageLastArrival ? actions?.find((a) => a.id === JSON.parse(voyage?.voyageLastArrival)?.id) : null;
  }, [actions, voyage?.voyageLastArrival]);

  const portCalls = useMemo(() => portCallsFromMovements(filterActions), [filterActions]);

  return {
    loading: loading,
    voyage: {
      id: voyage?.id,
      name: voyage?.name,
      status: voyage?.status,
      voyageFirstDeparture: voyageFirstDeparture,
      voyageLastArrival: voyageLastArrival
    },
    vessel: voyage?.vessel?.vesselData,
    actions: [...(filterActions || [])],
    portCalls: portCalls
  }
};

export default useVoyage;