import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

// This component takes in data from MovementActionDisplayTypes in Constants
// Takes in a label object with a label string, fullLabel string and description string
// Render fullLabel if there is one other wise just the label
// Renders the description if there is one below that
export default ({ label: {label, fullLabel, description} }) => {
  const { t } = useTranslation();
  return (
    <>
      <ListItem>
        <ListItemText
          primary={t(fullLabel) || t(label)}
        />
      </ListItem>
      { description ? (<ListItem>
        <ListItemText
          primary={t(description)}
        />
      </ListItem>) : null
      }
    </>
  );
};