import React, { useRef, useState, useEffect, useContext } from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { MenuDown } from "mdi-material-ui";
import { useLocation } from "react-router-dom";
import { DataStore } from "aws-amplify";
import Filters from "../Filter/Filter.js";
import ToggleFullScreenRoute from "../ToggleFullScreenRoute";
import { locationArrayFlatToTree } from "../../utils/utils";
import { filterCondition } from "../../constants/Filters";
import { Filter } from "../../models/index.js";
import { DataStoreContext } from "../../contexts/dataStoreContext.js";
import useQuery from "../../hooks/useQuery.js";

const {CHANGED, NO_CHANGE, NEW} = filterCondition;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem 2rem",
    borderBottom: "0.0625rem solid #ccc",
    paddingLeft: "1rem",
    position: "relative",
    height: "3.5rem",
  },
  heading: {
    padding: 0,
  },
}));

export const ListHeader = ({
  filters,
  setFilter,
  enabledFilters,
  TYPE,
  filterSettings,
  queryParams,
  children
}) => {
  const [ open, setOpen ] = useState(false);
  const filterAnchorEl = useRef(null);
  const classes = useStyles();
  const location = useLocation();
  const { locations } = useContext(DataStoreContext);
  const [ locationTree, setLocationTree ] = useState([]);
  const [ allChecked, setAllChecked ] = useState([]);
  const [ filterList, setFilterList ] = useState([]);
  const data = useQuery(Filter);
 
  //Method to load existing filters and create subscription method
  useEffect(() => {
    if (data.length !== 0) {
      const newFilterList = data
        .filter((filter) => filter.type === TYPE.type)
        .reduce((acc, filter) => {
          // force friendlyId at this point instead of editing <Filter/>
          const { friendlyId: id, name, status, location: unparsedLocation } = filter;
          const location = JSON.parse(unparsedLocation);
          const newFilter = { id, name, status, location };
          return [...acc, newFilter];
        }, []);
      setFilterList([TYPE.AllFilter, ...newFilterList]);
    }
  }, [setFilterList, TYPE, data]);

  //useEffect on component mounting that puts the flat array of locations into a tree structure
  //and stores it in the locationTree variable.
  useEffect(() => {
    const [root, allIds] = locationArrayFlatToTree(locations);
    if (TYPE.extraNodes) {
      TYPE.extraNodes.forEach((node) => {
        root.push(node);
        allIds.add(node.id);
      });
    }

    // let's combine the outer and inner checked checkboxes
    root.forEach(item => allIds.add(item.id));

    //Set the all checked array as the allIds
    setAllChecked(Array.from(allIds));

    //Set the location tree as the root array
    setLocationTree(root);
  }, [locations, setAllChecked, setLocationTree, TYPE.extraNodes]);

  useEffect(() => {
    if (filters?.condition) {
      switch (filters.condition) {
        case NO_CHANGE:
          break;
        case CHANGED:
          const changeArray = Array.from(filterList);
          const index = filterList.findIndex((x) => x.id === filters.id);
          changeArray[index] = { ...filters };
          setFilterList(changeArray);
          (async () => {
            // find filter by friendlyId
            const data = await DataStore.query(Filter, c => c.friendlyId("eq", filters.id));
            await DataStore.save(Filter.copyOf(data.pop(), updated => {
              updated.name = filters.name;
              updated.status = filters.status;
              updated.location = JSON.stringify(filters.location);
            }));
          })();
          break;
        case NEW:
          const newArray = Array.from(filterList);
          newArray.push({ ...filters });
          setFilterList(newArray);
          (async () => {
            await DataStore.save(new Filter({
              friendlyId: filters.id,
              type: TYPE.type,
              name: filters.name,
              status: filters.status,
              location: JSON.stringify(filters.location)
            }));
          })();
          break;
        default:
          break;
      }
    }
  }, [filters]);

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        {filters &&
        <Button
          className={classes.heading}
          ref={filterAnchorEl}
          onClick={() => setOpen(true)}
          variant="text"
          color="inherit"
          id={`${TYPE.type}-FilterButton`}
        >
          {`${filters.name}`}
          <MenuDown fontSize="small" />
        </Button>
        }
        {children}
        {filters &&
        <Filters
          open={open}
          setOpen={setOpen}
          filterAnchorEl={filterAnchorEl}
          enabledFilters={enabledFilters}
          filters={filters}
          setFilter={setFilter}
          locationTree={locationTree}
          filterList={filterList}
          allChecked={allChecked}
          TYPE={TYPE}
          hideEditControls={filterSettings.hideEditControls}
          disabledEdit={filterSettings.disableEdit}
        />
        }
      </Grid>
      <Box alignItems="center" display="flex" position="absolute" right="1rem">
        <ToggleFullScreenRoute
          route={`/lists/${TYPE.full}${location.search}`}
        />
      </Box>
    </Grid>
  );
};

export default ListHeader;
