import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Typography,
  TextField
} from '@material-ui/core';

import CustomActionScheduleEdit from './CustomActionScheduleEdit';
import DocumentEdit from '../Documents/DocumentEdit';
import ChargeablesEditor from '../Chargeables/ChargeablesEditor';
import { Action } from '../../models';
import { DataStore } from 'aws-amplify';
import { ActionDetailsHref } from '../../constants/ActionDetails';
import { useTranslation } from 'react-i18next';

const CustomActionEdit = ({
  portCall,
  action,
  setAction,
  saveDisabled,
  checkSaveState,
}) => {

  // TODO until datastore supports empty string arrays
  const documents = useMemo(() => action && action.documents ? action.documents.filter(d => d) : [], [action]);
  const setDocuments = useCallback((value) => DataStore.save(Action.copyOf(action, updated => { updated.documents = value.length ? value : ['']; })), [action]);
  const { t } = useTranslation();
  return (
    <>
      {/* Schedule Edit */}
      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_SCHEDULE}
          variant="h6"
        >
          {t('CustomActionEdit.Labels.Schedule', { actionName: action.type.name })}  
        </Typography>
        <CustomActionScheduleEdit
          portCall={portCall}
          action={action}
          setAction={setAction}
        />
      </CardContent>
      {/* Notes */}
      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_NOTES}
          variant="h6"
        >
          {t('CustomActionEdit.Labels.Notes')}  
        </Typography>
        <TextField
          id="custom-action-description"
          value={action.remarks ? action.remarks : ''}
          type="text"
          multiline={true}
          variant="outlined"
          minRows={4}
          fullWidth
          onChange={(e) => setAction(Action.copyOf(action, updated => { updated.remarks = e?.target?.value ?? ''; }))}
        />
      </CardContent>
      {/* Chargeable Items */}
      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_CHARGEABLES}
          variant="h6"
        >
          {t('ActionDetails.Labels.ChargeableItems')}
        </Typography>
        <ChargeablesEditor
          portCall={portCall}
          action={action}
          setAction={(value) => DataStore.save(value)}
          checkSaveState={checkSaveState}
          saveDisabled={saveDisabled}
        />
      </CardContent>
      {/* Attachments */}
      <CardContent>
        <Typography 
          id={ActionDetailsHref.HREF_ATTACHMENTS}
          variant="h6"
          style={{paddingBottom: '0.5rem'}}
        >
          {t('ActionDetails.Labels.Attachments')}
        </Typography>
        <DocumentEdit
          filePath={`portcalls/${portCall.id}/actions/${action.id}/`}
          portCallId={portCall.id}
          objId={action.id}
          objDocuments={documents}
          onChange={setDocuments}
          saveDisabled={saveDisabled}
          checkSaveState={checkSaveState}
        />
      </CardContent>
    </>
  )
}

CustomActionEdit.propTypes = {
  action: PropTypes.object,
  portCall: PropTypes.object,
  setAction: PropTypes.func,
  saveDisabled: PropTypes.bool,
  checkSaveState: PropTypes.func,
}

export default CustomActionEdit;