import React, { useState, useMemo, useCallback } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { clientName } from '../../environment';
import { DateFnsLanguageMap } from '../../translations';
import { format } from 'date-fns';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import ExportUnitSelection from '../Export/ExportUnitSelection';
import { ExportField, ExportFieldKeys, ExportFieldLengthKeys, ExportUnitKeys, DefaultVesselHeaderKeys, DefaultLengthUnit, ExportFieldGroups } from '../Export/export-fields';
import ExportDialog from '../Export/ExportDialog';
import { formatLength, formatCertificate } from '../Export/export-utils';
import ExportFieldGroup from '../Export/ExportFieldGroup';
import { checkValidity } from '../../utils/certificates';
import { VESSEL_PREVIEW } from '../../constants/VesselPreview';

const ExportVesselsDialog = ({ open, vessels, onClose }) => {
  const { t, i18n } = useTranslation();
  const { dateTimeFormat, dateFormat } = useDateTimeSetting();
  const [ exportColumns, setExportColumns ] = useState(DefaultVesselHeaderKeys);

  const [selectedLengthUnit, setSelectedLengthUnit] = useState(DefaultLengthUnit);
  
  const exportKeys = useMemo(() => ({
    [ExportField.VesselName]: {
      get: v => v.vesselData.name
    },
    [ExportField.VesselIMO]: {
      get: v => v.vesselData.imo
    },
    [ExportField.VesselMMSI]: {
      get: v => v.vesselData.mmsi
    },
    [ExportField.VesselCallSign]: {
      get: v => v.vesselData.callSign
    },
    [ExportField.VesselFlag]: {
      get: v => v.vesselData.flag
    },
    [ExportField.VesselType]: {
      get: v => v.vesselData.type
    },
    [ExportField.VesselYearBuilt]: {
      get: v => v.vesselData.yearBuilt
    },
    [ExportField.VesselPortOfRegistry]: {
      get: v => v.vesselData.portOfRegistry && `${v.vesselData.portOfRegistry.name}, ${v.vesselData.portOfRegistry.countryCode}`
    },
    [ExportField.VesselLength]: { 
      get: v => v.vesselData.lengthOverall && formatLength(v.vesselData.lengthOverall, selectedLengthUnit)
    },
    [ExportField.VesselBeam]: {
      get: v => v.vesselData.beam && formatLength(v.vesselData.beam, selectedLengthUnit)
    },
    [ExportField.VesselDraught]: {
      get: v => v.vesselData.draught && formatLength(v.vesselData.draught, selectedLengthUnit)
    },
    [ExportField.VesselDeadweightTonnage]: {
      get: v => v.vesselData.deadWeightTonnage
    },
    [ExportField.VesselGrossTonnage]: {
      get: v => v.vesselData.grossTonnage
    },
    [ExportField.VesselNetTonnage]: {
      get: v => v.vesselData.netTonnage
    },
    [ExportField.VesselCertificates]: {
      get: v => v?.vesselData?.certificates && checkValidity(v?.vesselData?.certificates)?.map(c => formatCertificate(c, t, dateFormat)).join(', ')
    }
  }), [t, selectedLengthUnit, dateFormat]);

  const getLabelWithUnit = useCallback((key) => {
    return (ExportFieldLengthKeys.find(k => k === key))
    ? `${t(ExportFieldKeys[key])} (${t(ExportUnitKeys[selectedLengthUnit])})`
    :  t(ExportFieldKeys[key]);
  }, [t, selectedLengthUnit])

  const exportConfig = {
    fileType: {
      ext: "csv",
      mimeType: "text/csv"
    },
  };
  const generateRow = useCallback((vessel => {
    return exportColumns.map(key => exportKeys[key].get(vessel) || '')
  }), [exportColumns, exportKeys]);

  const fileName = useMemo(() => `${clientName}_${t("ExportVessels.Labels.Vessels")}_${format(new Date(), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })}.${exportConfig.fileType.ext}`, [dateTimeFormat, i18n])

  const getData = useCallback(() => {
    return [
      exportColumns.map(key => exportKeys[key] && getLabelWithUnit(key)).map(i => `\"${i}\"`).join(","),
      ...vessels.map(vessel => generateRow(vessel).map(i => `\"${i}\"`).join(","))
    ].join("\n");
  }, [vessels, exportColumns, exportKeys, getLabelWithUnit, generateRow])
  
  const handleExportFieldChange = (fields) => {
    setExportColumns(fields);
  };

  const handleClose = (event) => {
    onClose(event)
  }

  const preview = useMemo(() => {
   const vessel = VESSEL_PREVIEW;
   return generateRow(vessel);
  }, [generateRow]);

  const labels = exportColumns?.map(key => exportKeys[key] && getLabelWithUnit(key))

  const onExport = useCallback(() => {
    // force browser to download data blob as a file
    const download = (data, filename, type) => {
      const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
      const file = new Blob([BOM, data], { type: type + ";charset=utf-8" });
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        // Others
        const a = document.createElement("a"),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    };
    if(getData && fileName && exportConfig?.fileType?.mimeType) {
      download(getData(), fileName, exportConfig.fileType.mimeType);
    }else{
      console.error(`Error: Failed to export to a file(fileName: ${fileName}, mimeType: ${exportConfig?.fileType?.mimeType})`)
    }
  }, [exportConfig.fileType.mimeType, fileName, getData])

  return (
    <ExportDialog 
      open={open}
      title={t("ExportVessels.Labels.ExportVessels")}
      fileName={fileName}
      exportColumns={exportColumns}
      getData={getData}
      onExport={onExport}
      handleClose={handleClose}
      preview={{labels, data: preview}}
      handleExportFieldReorder={handleExportFieldChange}
      exportConfig={exportConfig}
      exportMessage={t("ExportVessels.Labels.ExportConfirmMessage", { count: vessels?.length || 0, fileType: exportConfig.fileType.ext.toUpperCase() })}
      exportDialogContent={
        <div key='ExportVesselsDialogContent'>
          <DialogContentText>{t('ExportPortCalls.Labels.SelectColumns')}</DialogContentText>        
          <ExportFieldGroup key={'ExportVesselsFieldGroup'} group={ExportFieldGroups.find(g => g?.id === 'ExportVesselPanel')} settings={exportColumns} onChange={handleExportFieldChange} />
          <DialogContentText>{t('ExportPortCalls.Labels.SelectUnits')}</DialogContentText>
          <ExportUnitSelection selectedLengthUnit={selectedLengthUnit} onLengthUnitChange={setSelectedLengthUnit}/>
        </div>
      }
     />
  );
};

export default ExportVesselsDialog;