import { useMemo, useState, useRef, useEffect, useContext } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import VoyageBookendCard from "./VoyageBookendCard";
import VoyageActionsCard from "./VoyageActionsCard";
import { ActionMovementType } from "../../../models";
import { sortActions } from "../../../utils/sorters";

const INCREASE_HEIGHT = 50;
const FIXED_SCROLLBAR = 5;
const INCREASE_SCROLLBAR = 10;
const FIXED_MINIMUM_HEIGHT = 400;
const FIXED_MINIMUM_HEIGHT_NO_BOOKEND = 0;
const FIXED_ACTION_CARD_HEIGHT = 65;

const MAIN_COLOUR = `rgb(000,150,214)`;
const BOTTOM_COLOUR = `linear-gradient(180deg, rgba(0,150,214,1) 54%, rgba(255,255,255,1) 69%, rgba(225,243,250,1) 76%, rgba(0,150,214,1) 91%)`;
const TOP_COLOUR = `linear-gradient(0deg, rgba(0,150,214,1) 60%, rgba(255,255,255,1) 81%, rgba(225,243,250,1) 84%, rgba(0,150,214,1) 92%)`

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    width: '100%',
    overflow: 'visible',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      scrollbarWidth: 'thin',
    }
  },
  row: {
    display: 'flex',
    height: 'fit-content',
    width: '50vw'
  },
  timelineActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '1rem 0',
  },
  circleWrapper: {
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexBasis: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  circle: {
    width: '1rem',
    height: '1rem',
    transform: 'scale(0.75)',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    transition: 'all .25s ease-out',
  },
  noHeight: {
    height: '0 !important',
  },
  stripe: {
    position: 'absolute',
    left: '0.38rem',
    width: '0.25rem',
    backgroundColor: 'rgb(000,150,214)',
  },
  timelinePosition: {
    top: '3.3rem',
  },
  firstActionTimelinePosition: {
    top: '3.6rem !important',
  },
  actionTimelinePosition: {
    top: '2.9rem',
  },
  bookendToActionStripe: {
    height: '5rem'
  },
  bookendToActionsStripe: {
    height: '6.5rem'
  },
  actionToActionsLastStripe:{
    height: '6.5rem'
  },
  bookendToBookendStripe: {
    height: '6rem'
  },
  lastStripe: {
    height: '8.7rem'
  },
  actionStripe: {
    height: '5rem'
  },
  actionsStripe: {
    height: '8rem'
  },
  timelineRevert: {
    top: '-4rem',
    height: '7rem',
  },
  location: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    marginLeft: '10px',
    minWidth: '160px',
  },
  locationContainer: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    flexShrink: '0',
    flexBasis: '10.65rem'
  }
}));

const getWindowSize = () => {
  return { innerWidth: window.innerWidth, innerHeight: window.innerHeight };
};

/**
 * Sort an array of port calls based on the arrival time if present
 * @param {Array<PortCall>} portCalls 
 * @returns 
 */
const sortPortCallsByArrival = (portCalls) => {
  if (!portCalls) return [];

  // Add a sorting key to the port call based on available values
  const withSortKey = portCalls?.map(p => {
    const arrival = p?.actions?.find(el => el.movementType === ActionMovementType.ARRIVAL);
    const departure = p?.actions?.find(el => el.movementType === ActionMovementType.DEPARTURE);
    let sortTime;

    if (arrival) sortTime = arrival.timeActual || arrival.timeEstimated || arrival.timePlanned;
    else if (!arrival && departure) sortTime = departure.timeActual || departure.timeEstimated || departure.timePlanned;

    return {
      ...p,
      sortTime: sortTime,
      actions: p.actions.sort(sortActions)
    }
  });
  //console.log('withSortKey', withSortKey);

  const sortedPortCalls = withSortKey.sort((a, b) => {
    if (a.sortTime > b.sortTime) return 1;
    if (a.sortTime < b.sortTime) return -1;
    return 0;
  });

  return sortedPortCalls;
};

const VoyageTimeline = ({ portCalls, voyage }) => {
  const classes = useStyles();
  const { locations } = useContext(DataStoreContext);
  const sortedPortCalls = useMemo(() => sortPortCallsByArrival(portCalls), [portCalls]);

  const voyageMid = useMemo(() => {
    const { voyageFirstDeparture, voyageLastArrival } = voyage;
    const _portCalls = [];
    for (const item of sortedPortCalls) {
      const {portCall,actions} = item;
      const updateActions = actions?.filter((a) => a.id !== voyageFirstDeparture?.id && a.id !== voyageLastArrival?.id) || [];
      if (updateActions.length) {
        _portCalls.push({ portCall, actions: updateActions });
      }
    }
    //console.log({_portCalls})
    return _portCalls;
  }, [sortedPortCalls, voyage]);

  const lastMovement = voyageMid?.length && voyageMid[voyageMid?.length - 1];
  const firstMovement = voyageMid?.length && voyageMid[0];

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  const ContainerSize = useMemo(() => {
    let fixedHeight = FIXED_MINIMUM_HEIGHT;
    let paddingRight = FIXED_SCROLLBAR;

    const heightSize = Math.floor(windowSize?.innerWidth / 1000);
    if (!voyage?.voyageFirstDeparture && !voyage?.voyageLastArrival) {
      fixedHeight = FIXED_MINIMUM_HEIGHT_NO_BOOKEND;
    } else {
      if (heightSize > 1) {
        fixedHeight = FIXED_MINIMUM_HEIGHT + (heightSize * INCREASE_HEIGHT);
      }
    }
    const height = windowSize?.innerHeight - fixedHeight;
    return { height: height, paddingRight: heightSize > 1 ? paddingRight + (INCREASE_SCROLLBAR * heightSize) : paddingRight };
  }, [voyage?.voyageFirstDeparture, voyage?.voyageLastArrival, windowSize]);

  const setContainerSize = useMemo(() => {
    const midLength = voyageMid?.reduce((acc, p) => p?.actions?.length ? acc + p.actions.length : acc, 0);
    let calHeight = 0;
    if (midLength) {
      calHeight = midLength * FIXED_ACTION_CARD_HEIGHT;
    }
    const { height, paddingRight } = ContainerSize;
    const setHeight = (calHeight >= height) ? { height: `${height}px` } : { minHeight: `${calHeight}px` };
    const setPaddingRight = { paddingRight: `${paddingRight}px` };
    return { ...setPaddingRight, ...setHeight, };
  }, [ContainerSize, voyageMid]);

  const locationMap = useMemo(() => {
    const lmap = new Map();
    locations.forEach(el => lmap.set(el.id, el));
    return lmap;
  }, [locations]);

  // console.log('voyage', voyage);
  // console.log('voyageMid', voyageMid)
  const scrollContainerRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [stripColour, setStripColour] = useState({
    top: MAIN_COLOUR,
    bottom: MAIN_COLOUR
  });
  const boundingClientRect = scrollContainerRef.current?.getBoundingClientRect();

  useEffect(() => {
    const scrollRef = scrollContainerRef?.current;
    const handleScroll = () => {
      setScrollPosition(Math.ceil(scrollRef?.scrollTop));
    };
    scrollRef?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, scrollContainerRef.current?.scrollHeight]);

  useEffect(() => {
    const scrollbarHeight = Math.ceil(scrollContainerRef.current?.scrollHeight) - Math.ceil(boundingClientRect?.height);
    const isScrollToBottom = scrollbarHeight - scrollPosition <= 2;
    const noGradient = (isScrollToBottom && scrollPosition === 0);
    const topGradient = (isScrollToBottom && scrollPosition !== 0);
    const bothGradients = (!isScrollToBottom && scrollPosition !== 0);
    const bottomGradient = (scrollbarHeight > 0 && scrollPosition === 0);
    //console.log({isScrollToBottom,noGradient,topGradient,bothGradients, scrollbarHeight,scrollPosition, containerRef:scrollContainerRef.current?.scrollHeight, boundingClientRect:boundingClientRect?.height})
    if (noGradient) {
      setStripColour({ top: MAIN_COLOUR, bottom: MAIN_COLOUR });
    } else if (topGradient) {
      setStripColour({ top: TOP_COLOUR, bottom: MAIN_COLOUR });
    } else if (bottomGradient) {
      setStripColour({ top: MAIN_COLOUR, bottom: BOTTOM_COLOUR });
    } else if (bothGradients) { setStripColour({ top: TOP_COLOUR, bottom: BOTTOM_COLOUR }) }

  }, [scrollPosition, boundingClientRect?.height, boundingClientRect?.width])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        flexGrow="1"
        margin="auto"
      >
        {/* First Departure */}
        <VoyageBookendCard
          classes={classes}
          portCalls={portCalls}
          stripColour={stripColour}
          firstMovement={firstMovement}
          voyageLastArrival={voyage?.voyageLastArrival}
          voyageFirstDeparture={voyage?.voyageFirstDeparture}
          isMiddelActions={Boolean(voyageMid?.length)}
          locationMap={locationMap}
          action={voyage?.voyageFirstDeparture}
        />

        <Box className={classes.scrollContainer} style={setContainerSize} ref={scrollContainerRef}>
          <VoyageActionsCard
            classes={classes}
            portCalls={portCalls}
            voyageMid={voyageMid}
            voyageFirstDeparture={voyage?.voyageFirstDeparture}
            lastMovement={lastMovement}
            firstMovement={firstMovement}
            locationMap={locationMap}
          />
        </Box>

        {/* Last Arrival */}
        <VoyageBookendCard
          classes={classes}
          portCalls={portCalls}
          stripColour={stripColour}
          firstMovement={firstMovement}
          voyageLastArrival={voyage?.voyageLastArrival}
          voyageFirstDeparture={voyage?.voyageFirstDeparture}
          isMiddelActions={Boolean(voyageMid?.length)}
          locationMap={locationMap}
          action={voyage?.voyageLastArrival}
        />

      </Box>
    </>
  );
};

export default VoyageTimeline;