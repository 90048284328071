import React, { useEffect, useState } from 'react';
import { Box, Tooltip, makeStyles, Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import { getTidalEventsExternal } from '../../graphql/queries';
import { format, isBefore } from 'date-fns';
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';

const tidalEventTypes = {
  HIGH_WATER: 'H',
  LOW_WATER: 'L',
}

const useStyles = makeStyles(theme => ({
  root: {
    '&:not(:first-child)': {
      paddingLeft: '1rem',
    },
    flexDirection: 'column',
    display: 'flex'
  },
  bold: {
    fontWeight: 900
  }
}));

const Tide = ({ eventType, dateTime, height }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.bold}>
        {tidalEventTypes[eventType]}
      </Typography>
      <Typography variant="caption">{format(new Date(dateTime), 'HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}</Typography>
      <Typography variant="caption">{height.toFixed(2)} {t('Common.Labels.M')}</Typography>
    </div>
  );
};

const Tides = ({ limit, duration, pollInterval }) => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const update = async () => {
      const { data } = await API.graphql({ query: getTidalEventsExternal, variables: { duration, useApi: true } });
      setData(data);
    };
    update();
    const interval = setInterval(() => update(), pollInterval);
    return () => clearInterval(interval);
  }, [setData, duration, pollInterval]);

  if (!data) {
    return <Typography variant="caption">{t('AppHeader.Labels.LoadingTidalInformation')}</Typography>;
  }
  if (data.getTidalEventsExternal.serviceResponse.error || !data.getTidalEventsExternal.events) {
    return <Typography variant="caption">{t('AppHeader.Labels.ErrorRetrievingTidalInformation')}</Typography>;
  }
  const testTidalData = data?.getTidalEventsExternal?.serviceResponse?.service === 'UNKNOWN';
  return (
    <Tooltip title={testTidalData ? t('AppHeader.Labels.TestTidalForecast') : t('AppHeader.Labels.TidalForecast')}>
      <Box style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
        {data.getTidalEventsExternal.events
          .filter(event => !isBefore(new Date(event.dateTime), new Date()))
          .slice(0, limit)
          .map((event) => {
            return <Tide {...event} key={event.dateTime} />
          })}
      </Box>
    </Tooltip>
  )
};

Tides.defaultProps = {
  limit: 4
}

export default Tides;
