import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TodoStatus as ts } from '../../constants/TodoStatus';
import TodoIconWithPopover from './TodoIconWithPopover';
import { DatastoreStatusContext } from '../../contexts/datastoreStatusContext';
import { DataStoreContext } from '../../contexts/dataStoreContext';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
}));

/**
 * Checklist with a debounced update and lock when syncing
 */
const ChecklistListItem = function ({ items, actionId, disabled, onChange }) {
  const [pending, setPending] = useState(null);
  const [loading, setLoading] = useState(false);
  const isSynced = useContext(DatastoreStatusContext);

  const { todoTypeTemplates } = useContext(DataStoreContext);
  const getTodoTypeColor = (todo) => {
    const template = todoTypeTemplates.length && todoTypeTemplates.find(t => t.todoTypeName === todo.todoType);
    return template && template.todoTypeColor;
  }

  const handleClick = async (todo) => {
    if (loading) return;
    setLoading(true);
    const newItems = [...(pending || items)];
    const index = newItems.findIndex(t => t.todoType === todo.todoType && t.status === todo.status);
    newItems[index] = {
      ...newItems[index],
      status: newItems[index].status === ts.READY ? ts.DONE : newItems[index].status === ts.DONE ? ts.READY : newItems[index].status
    };
    onChange(newItems);
    setPending(newItems);
    // pending and loading will be unset on item update (remount)
  };

  const filtered = (pending || items || []).filter(t => t.status !== ts.DELETED);
  const xsmall = filtered.length > 3;
  const classes = useStyles();
  return (
    <div id='checklist' className={classes.root}>
      {filtered.map((item, index) =>
        <TodoIconWithPopover
          key={`${actionId}-${index}`}
          item={item}
          clickHandler={() => handleClick(item)}
          small={!xsmall}
          xsmall={xsmall}
          backgroundColor={getTodoTypeColor(item)}
          disabled={disabled || !isSynced || loading}
        />
      )}
    </div>
  )
};

export default ChecklistListItem;
