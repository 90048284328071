export const TRANSLATIONS_FR_CA = {
  "Column1": {
    "Column2": {
      "Column3": "Column4"
    }
  },
  "Common": {
    "Buttons": {
      "Create": "CRÉER",
      "Update": "METTRE À JOUR",
      "Add": "Ajouter",
      "DiscardChanges": "Annuler les changements",
      "Confirm": "Confirmer",
      "Cancel": "Annuler",
      "Close": "Fermer",
      "Save": "Enregistrer",
      "Request": "Demande",
      "Export": "Exporter",
      "Leave": "Quitter"
    },
    "Labels": {
      "M": "m",
      "M3": "m³",
      "Feet": "pi",
      "T": "t",
      "KW": "kW",
      "HP": "CV",
      "KN": "kN",
      "NMI": "mmi",
      "MB": "mb",
      "Each": "",
      "C": "C",
      "F": "F",
      "Unspecified": "?",
      "UnsavedChanges": "Modifications non enregistrées",
      "Nominal": "Nominal",
      "All": "Tous",
      "CustomAction": "Action {{- actionName}}",
      "Comments": "Commentaire",
      "More": "Plus",
      "Hours": "Heures",
      "Minutes": "Minutes",
      "Days": "Jours"
    },
    "Errors": {
      "TimeCheckActionBefore": "L’heure prévue de l’action doit être postérieure à l’heure réelle de l’action précédente",
      "TimeCheckActionAfter": "L’heure prévue de l’action doit être antérieure à l’heure prévue de l’action suivante",
      "TimeCheckArrivalCheck": "L’arrivée doit être antérieure à l’heure prévue pour toute autre action",
      "TimeCheckDepartureCheck": "Le départ doit être postérieur à l’heure prévue pour toute autre action",
      "TimeCheckShiftBefore": "L’heure prévue de l’équipe doit être postérieure à l’action précédente",
      "TimeCheckShiftAfter": "L’heure prévue de l’équipe doit être antérieure à l’action suivante",
      "TimeCheckArrivalRequest": "L’heure demandée se situe après une demande de départ",
      "TimeCheckDepartureRequest": "L’heure demandée se situe avant une demande d’arrivée",
      "PastDate": "Vous avez saisi une date dans le passé",
      "InvalidDateFormat": "Format de date non valide",
      "InvalidDateFormatUse": "Format de date non valide. Utilisez le {{- format}}",
      "ErrorCode": "Code d’erreur"
    }
  },
  "ActionMovementType": {
    "Labels": {
      "Arrival": "Arrivée",
      "Departure": "Départ",
      "BerthShiftArrival": "Arrivée de l’équipe d’amarrage",
      "BerthShiftDeparture": "Départ de l’équipe d’amarrage",
      "ShiftArrival": "Arrivée de l’équipe",
      "ShiftDeparture": "Départ de l’équipe",
      "All": "Tous"
    }
  },
  "ActionState": {
    "Labels": {
      "Planned": "Prévu",
      "InProgress": "En cours",
      "Completed": "Terminé",
      "Cancelled": "Annulé",
      "Deleted": "Supprimé",
      "ApprovalDeclined": "Approbation refusée",
      "ApprovalPending": "En attente d’approbation",
      "Requested": "Demandé",
      "Created": "Créé",
      "ArrivalRequested": "Arrivée demandée",
      "ArrivalApprovalPending": "Autorisation d’arrivée en attente",
      "ArrivalApprovalDeclined": "Autorisation d’arrivée refusée",
      "ArrivalPlanned": "Arrivée prévue",
      "ArrivalInProgress": "Arrivée en cours",
      "ArrivalCompleted": "Arrivée terminée",
      "ArrivalCancelled": "Arrivée annulée",
      "ArrivalDeleted": "Arrivée supprimée",
      "DepartureRequested": "Départ demandé",
      "DepartureApprovalPending": "Autorisation de départ en attente",
      "DepartureApprovalDeclined": "Autorisation de départ refusé",
      "DeparturePlanned": "Départ prévu",
      "DepartureInProgress": "Départ en cours",
      "DepartureCompleted": "Départ terminé",
      "DepartureCancelled": "Départ annulé",
      "DepartureDeleted": "Départ supprimé",
      "ShiftArrivalRequested": "Arrivée de l’équipe demandée",
      "ShiftArrivalApprovalPending": "Autorisation d’arrivée de l’équipe en attente",
      "ShiftArrivalApprovalDeclined": "Autorisation d’arrivée de l’équipe refusée",
      "ShiftArrivalPlanned": "Arrivée de l’équipe prévue",
      "ShiftArrivalInProgress": "Arrivée de l’équipe en cours",
      "ShiftArrivalCompleted": "Arrivée de l’équipe terminée",
      "ShiftArrivalCancelled": "Arrivée de l’équipe annulée",
      "ShiftArrivalDeleted": "Arrivée de l’équipe supprimée",
      "ShiftDepartureRequested": "Départ de l’équipe demandé",
      "ShiftDepartureApprovalPending": "Autorisation de départ de l’équipe en attente",
      "ShiftDepartureApprovalDeclined": "Autorisation de départ de l’équipe refusée",
      "ShiftDeparturePlanned": "Départ de l’équipe prévu",
      "ShiftDepartureInProgress": "Départ de l’équipe en cours",
      "ShiftDepartureCompleted": "Départ de l’équipe terminé",
      "ShiftDepartureCancelled": "Départ de l’équipe annulé",
      "ShiftDepartureDeleted": "Départ de l’équipe supprimé",
      "AgentHandoverRequested": "Transfert d’agent demandé",
      "AgentHandoverApprovalPending": "Approbation du transfert d’agent en attente",
      "AgentHandoverApprovalDeclined": "Approbation du transfert d’agent refusée",
      "AgentHandoverPlanned": "Transfert d’agent prévu",
      "AgentHandoverInProgress": "Transfert d’agent en cours",
      "AgentHandoverCompleted": "Transfert d’agent terminé",
      "AgentHandoverCancelled": "Transfert d’agent annulé",
      "AgentHandoverDeleted": "Transfert d’agent supprimé",
      "CustomActionRequested": "{{action}} demandé(e)",
      "CustomActionApprovalPending": "Approbation pour {{action}} en cours",
      "CustomActionApprovalDeclined": "Approbation pour {{action}} refusée",
      "CustomActionPlanned": "{{action}} prévu(e)",
      "CustomActionInProgress": "{{action}} en cours",
      "CustomActionCompleted": "{{action}} terminé(e)",
      "CustomActionCancelled": "{{action}} annulé(e)",
      "CustomActionDeleted": "{{action}} supprimé(e)"
    }
  },
  "ActionType": {
    "Labels": {
      "Name": "Nom",
      "Movement": "Mouvement",
      "Event": "Événement",
      "ActionType": "type d’action"
    }
  },
  "Admin": {
    "Labels": {
      "Administration": "Administration",
      "AISStatusMonitoring": "Surveillance de l’état des SIA",
      "Automation": "Automatisation",
      "CertificateType": "Types de certificats",
      "GeneralSettings": "Paramètres généraux",
      "SecondaryApproval": "Approbation secondaire",
      "Notifications": "Notifications",
      "PortDefinitions": "Définitions des ports",
      "OperationalSettings": "Paramètres opérationnels",
      "ActionTypes": "Types d’action",
      "Contacts": "Contacts",
      "PortCallCategories": "Catégories d’appels portuaires",
      "Tariffs": "Tarifs",
      "TariffBook": "Recueil des tarifs",
      "TariffUnits": "Unités tarifaires",
      "CargoType": "Types de cargaison"
    }
  },
  "AdminActionType": {
    "Labels": {
      "ActionTypeDefaults": "Valeurs par défaut des types d’action",
      "ActionTypeDefaultsHelp": "Les nouvelles actions de ce type seront automatiquement pré-remplies avec les valeurs par défaut indiquées ci-dessous",
      "MovementArrival": "Mouvement (Arrivée)",
      "MovementDeparture": "Mouvement (Départ)",
      "MovementShiftArrival": "Mouvement (Arrivée de l’équipe)",
      "MovementShiftDeparture": "Mouvement (Départ de l’équipe)",
      "DeletedHelp": "Ce type d’action a été supprimé",
      "InvalidTariff": "Cette action est associée à des éléments imputables dont le tarif n’est pas valide"
    }
  },
  "AdminApproval": {
    "Labels": {
      "EnableSecondaryApproval": "Activer l’approbation secondaire",
      "EnableSecondaryApprovalHelp": "L’activation de l’approbation secondaire garantit que les appels portuaires sont examinés par un utilisateur autorisé avant d’être traités",
      "VesselExceptionList": "Liste d’exceptions pour les navires"
    }
  },
  "AdminAutomation": {
    "Labels": {
      "NoAutomationRules": "Aucune règle d’automatisation n’est définie",
      "NoAutomationRulesHelp": "Veuillez contacter le service d’assistance de Marlin SmartPort pour obtenir de l’aide",
      "EnablePortCallAutomation": "Activer l’automatisation des appels portuaires",
      "EnablePortCallAutomationHelp": "Activer l’automatisation des appels portuaires pour créer automatiquement des appels portuaires et enregistrer les changements de mouvement",
      "NoVesselsSpecified": "Pas de navires spécifiés",
      "UseAllVessels": "Utiliser tous les navires",
      "UseAllVesselsHelp": "Appliquer l’automatisation à tous les navires qui entrent dans le port"
    }
  },
  "AdminGeneralSettings": {
    "Labels": {
      "ApplicationCurrency": "Devise d’application",
      "ApplicationCurrencyHelp": "Configurer la devise à l’échelle de l’application",
      "Language": "Langue",
      "LanguageHelp": "Configurer la région à l’échelle de l’application",
      "FiltersTitle": "Filtres de mouvements et de postes d’amarrage",
      "AdminFilters": "Limiter la modification des filtres aux administrateurs"
    }
  },
  "AdminModelEditor": {
    "Labels": {
      "CreateEntry": "Créer une nouvelle entrée",
      "DeleteEntry": "Supprimer l’entrée",
      "DeleteEntryMessage": "Êtes-vous sûr(e) de vouloir supprimer cet élément? Vous êtes sur le point d’effectuer une action irréversible.",
      "ReadOnly": "En lecture seule",
      "ConfirmSave": "Confirmer l’enregistrement",
      "DuplicateEntry": "Dupliquer l’entrée",
      "CustomDeleteEntry": "Supprimer {{- itemName}}",
      "CustomDuplicateEntry": "Dupliquer {{- itemName}}",
      "UnsavedChangesMessage": "Il reste des modifications non enregistrées, êtes-vous sûr(e) de vouloir quitter?"
    }
  },
  "AdminTariff": {
    "Buttons": {
      "AddTariff": "Ajouter le tarif",
      "AddActionTypeItem": "Ajouter un élément"
    },
    "Labels": {
      "DuplicateTariff": "Un tarif avec ce code existe déjà",
      "Name": "Nom",
      "Description": "Description",
      "DateValidFrom": "Valable à partir de",
      "DateValidTo": "Valable jusqu’au",
      "Tariffs": "Tarifs",
      "DefaultChargeables": "Éléments imputables par défaut",
      "TariffBookSaveWarning": "La modification des dates de début et de fin de validité du livre des tarifs n’entraînera pas la mise à jour du coût unitaire des appels portuaires planifiés ou en cours utilisant ces tarifs.",
      "AddTariff": "Ajouter le tarif",
      "UpdateTariff": "Mettre à jour le tarif",
      "FilterByCode": "Filtrer par code",
      "ActionType": "Type d’action associé",
      "NumChargeableItems": "Nombre d’éléments imputables",
      "DeleteTariffTitle": "Supprimer le tarif",
      "DeleteChargeableItemTitle": "Supprimer l’élément",
      "DeleteChargeableItemMessage": "Êtes-vous sûr(e) de vouloir supprimer les éléments imputables par défaut pour « {{- chargeableItem}} »?",
      "DeleteTariffItemMessage": "Êtes-vous sûr(e) de vouloir supprimer le tarif « {{- tariffName}} »? Si ce tarif est utilisé pour des éléments imputables par défaut, ceux-ci seront également supprimés.",
      "TariffBook": "recueil des tarifs"
    },
    "Errors": {
      "InvalidDate": "« Valable jusqu’à » doit être postérieur à « Valable à partir de »",
      "MissingName": "Le nom doit être spécifié"
    }
  },
  "AdminPortCallCategory": {
    "Labels": {
      "PortCallCategory": "catégorie d’appel portuaire"
    }
  },
  "AdminCargoType": {
    "Labels": {
      "CargoType": "type de cargaison"
    }
  },
  "AdminNotification": {
    "Error": {
      "ActionTypeNotSpecified": "Le type d’action doit être spécifié",
      "EventTypeNotSpecified": "Le type d’événement doit être spécifié",
      "MissingValues": "Définition incomplète, valeurs manquantes"
    },
    "Labels": {
      "Name": "Nom",
      "Description": "Description",
      "EnableNotification": "Activer la notification",
      "EnableNotifications": "Activer les notifications",
      "EnableReminder": "Activer le rappel",
      "Recipents": "Envoyer à",
      "Trigger": "Déclencheur",
      "ActionType": "Type d’action",
      "TriggerType": "Type de déclencheur",
      "ReminderTime": "Délai de rappel",
      "PortCallCategory": "Filtrer par catégorie d’appel portuaire",
      "Category": "Catégorie",
      "PortCallTags": "Filtrer par étiquettes d’appel portuaire",
      "Tags": "Étiquettes",
      "All": "Toutes les étiquettes",
      "Any": "N’importe quelles étiquettes",
      "Location": "Filtrer par zone géographique",
      "ActionWithoutLocation": "Actions sans zone géographique",
      "Enabled": "Activé",
      "Status": "État",
      "SelectAction": "Sélectionnez l’action",
      "SelectEvent": "Sélectionnez l’événement",
      "EventCreated": "Créé",
      "EventStarted": "Commencé",
      "EventCompleted": "Terminé",
      "EventPendingApproval": "En attente d’approbation",
      "EventApproved": "Approuvé",
      "EventDeclined": "Refusé",
      "EventRequested": "Demandé",
      "EventCancelled": "Annulé",
      "EventAwaitingApproval": "En attente d’approbation",
      "EventAwaitingFurtherApproval": "En attente d’approbation supplémentaire",
      "TimeBefore": "À venir",
      "TimeAfter": "En retard",
      "MovementArrival": "Mouvement (Arrivée)",
      "MovementDeparture": "Mouvement (Départ)",
      "MovementShiftArrival": "Mouvement (Arrivée de l’équipe)",
      "MovementShiftDeparture": "Mouvement (Départ de l’équipe)",
      "IncompleteWarningMsg": "Les paramètres de messagerie ne sont pas entièrement configurés pour permettre l’envoi de notifications. Veuillez contacter le service d’assistance de Marlin SmartPort pour obtenir de l’aide",
      "NotificationsTab": "Notifications",
      "RemindersTab": "Rappels",
      "ReminderBefore": "Avant",
      "ReminderAfter": "Après",
      "ReminderBeforePlannedTime": "Avant l’heure prévue de l’action",
      "ReminderAfterPlannedTime": "Après l’heure prévue de l’action",
      "Notification": "notification",
      "Reminder": "rappel"
    }
  },
  "TimePeriodSelect": {
    "Errors": {
      "InvalidPeriod": "La période doit être spécifiée"
    },
    "Labels": {
      "TimePeriod": "Période",
      "TimeUnit": "Unité"
    }
  },
  "AppHeader": {
    "Labels": {
      "CurrentDateAndTime": "Date & heure actuelles",
      "TidalForecast": "Prévisions des marées",
      "TestTidalForecast": "Test de prévision des marées",
      "LoadingWeatherInformation": "Chargement des informations météorologiques",
      "ErrorLoadingWeatherInformation": "Erreur de chargement des informations météorologiques",
      "LoadingTidalInformation": "Chargement des informations sur les marées",
      "ErrorRetrievingTidalInformation": "Erreur dans la récupération des informations sur les marées",
      "Language": "Langue",
      "LogOut": "Déconnexion"
    }
  },
  "Category": {
    "Labels": {
      "Category": "Catégorie",
      "Name": "Nom",
      "Description": "Description"
    }
  },
  "EditorContentTabs": {
    "Labels": {
      "Details": "Détails",
      "Requests": "Demandes",
      "LogBook": "Journal de bord"
    }
  },
  "ExportPortCalls": {
    "Labels": {
      "VesselName": "Nom du navire",
      "VesselLength": "Longueur",
      "Berth": "Poste d’amarrage",
      "ActualTimeOfArrival": "Heure d’arrivée effective",
      "ActualTimeOfDeparture": "Heure de départ effective",
      "ExportPortCalls": "Exporter les appels portuaires",
      "ExportConfirmMessage_one": "{{count}} appel portuaire sera exporté en tant que {{fileType}}.",
      "ExportConfirmMessage": "{{count}} appels portuaires seront exportés en tant que {{fileType}}.",
      "Preview": "Aperçu",
      "Configure": "Configurer",
      "SelectColumns": "Sélectionner les colonnes à exporter",
      "PreviewColumns": "Aperçu des colonnes sélectionnées",
      "SelectUnits": "Sélectionner les unités",
      "Length": "Longueur",
      "Feet": "Pieds",
      "Metres": "Mètres",
      "PreviewCaption": "Glisser-déposer des colonnes pour modifier l’ordre"
    }
  },
  "ExportVessels": {
    "Labels": {
      "ExportVessels": "Exporter les navires",
      "Vessels": "Navires",
      "ExportConfirmMessage_one": "{{count}} navire sera exporté en tant que {{fileType}}.",
      "ExportConfirmMessage": "{{count}} navires seront exportés en tant que {{fileType}}."
    }
  },
  "NavBarVertical": {
    "Labels": {
      "NoRequests": "Aucune demande dans la boîte de réception",
      "Requests": "Boîte de réception",
      "RequestInbox": "Boîte de réception",
      "CreatePortCall": "Créer un appel portuaire",
      "CreatePortCallDisabled": "Créer un appel portuaire (désactivé pour les utilisateurs qui ne sont pas des contrôleurs de port)",
      "RequestPortCall": "Demander un appel portuaire",
      "BoardView": "Vue sous forme de tableau",
      "MapView": "Vue sous forme de carte",
      "DataBrowser": "Navigateur de données",
      "PortCalls": "Appels portuaires",
      "Vessels": "Navires",
      "Dashboard": "Tableau de bord",
      "Documentation": "Documentation",
      "Administration": "Administration"
    }
  },
  "PortCallDetails": {
    "Labels": {
      "Itinerary": "Itinéraire",
      "PortCallCategory": "Catégorie d’appel portuaire",
      "ReportingCategory": "Catégorie de rapport",
      "Remarks": "Remarques",
      "Tags": "Étiquettes",
      "PortCallTags": "Étiquettes d’appels portuaires",
      "Attachments": "Annexes",
      "UnsavedChangesMessage": "Il y a des modifications non enregistrées dans l’éditeur actuel. Sauvegarder les modifications avant de mettre à jour d’autres détails",
      "OverwritePortCallTags": "Écraser les étiquettes d’appel portuaire",
      "OverwritePortCallTagsMessage": "Les étiquettes d’appel portuaire existantes seront écrasées par ce changement de navire. Aimeriez-vous écraser avec les nouvelles étiquettes?",
      "OverwritePortCallCategory": "Écraser la catégory d’appel portuaire",
      "OverwritePortCallCategoryMessage": "La catégorie d’appel portuaire existante sera écrasée par ce changement de navire. Aimeriez-vous écraser avec la nouvelle catégorie?",
      "LeavePromptMessage": "Il reste des modifications non enregistrées, êtes-vous sûr(e) de vouloir quitter?",
      "ConfirmChangeToCargo": "Confirmer la modification à la cargaison"
    }
  },
  "PortCallList": {
    "Labels": {
      "PortCalls": "Appels portuaires",
      "NoMoreData": "Aucune autre donnée n’est disponible.",
      "Uncategorised": "Sans catégorie",
      "Port": "PORT",
      "Category": "CATÉGORIE",
      "Order": "Commande",
      "Tags": "ÉTIQUETTES",
      "Vessel": "NAVIRE",
      "From": "DE",
      "To": "À",
      "OldestFirst": "Le plus ancien d’abord",
      "NewestFirst": "Le plus récent d’abord",
      "DepartureNotScheduled": "Départ non prévu",
      "ShowAll": "Tout afficher",
      "Status": "État",
      "SearchByKeyword": "Recherche par mot-clé"
    }
  },
  "PortCallStatus": {
    "Labels": {
      "Requested": "Demandé",
      "Prearrival": "Avant l’arrivée",
      "Arrival": "Arrivée",
      "InPort": "Au port",
      "Departure": "Départ",
      "Cancelled": "Annulé",
      "Deleted": "Supprimé",
      "Closed": "Fermé",
      "undefined": "Non défini"
    }
  },
  "Tariff": {
    "Labels": {
      "Code": "Code",
      "TariffUnit": "Unité tarifaire",
      "Description": "Description",
      "UnitCost": "Coût unitaire",
      "Currency": "Devise",
      "Notes": "Notes",
      "MinimumCost": "Coût minimum (optionnel)",
      "MinimumCostShort": "Min",
      "MinimumCostShort2": "Coût minimum",
      "ActionTypes": "Types d’action",
      "ActionTypesHelp": "Ce tarif ne sera disponible que pour les types d’actions spécifiés",
      "Unspecified": "Non spécifié"
    }
  },
  "TariffUnit": {
    "Labels": {
      "Name": "Nom",
      "SecondaryUnitName": "Nom de l’unité secondaire",
      "TariffUnit": "unité tarifaire"
    }
  },
  "Vessel": {
    "Labels": {
      "Vessel": "Navire",
      "Name": "Nom",
      "TemporaryName": "Nom temporaire",
      "IMO": "OMI",
      "IMONumber": "Numéro OMI",
      "MMSI": "ISMM",
      "CallSign": "Indicatif d’appel",
      "Flag": "Pavillon",
      "YearBuilt": "Année de construction",
      "Number": "Nombre",
      "Type": "Type",
      "DeadweightTonnage": "Tonnage du port en lourd",
      "Length": "Longueur totale",
      "Beam": "Poutre maximale",
      "Draught": "Tirant d’eau",
      "NominalDraught": "Tirant d’eau nominal",
      "GrossTonnage": "Tonnage brut",
      "NetTonnage": "Tonnage net",
      "PortOfRegistry": "Port d’immatriculation",
      "Dimensions": "Dimensions",
      "AgentName": "Nom de l’agent",
      "Identity": "Identité",
      "Details": "Détails",
      "VesselDetails": "Détails du navire",
      "Tonnage": "Tonnage",
      "VesselAgent": "Agent maritime",
      "PrimaryPropulsion": "Propulsion primaire",
      "Propulsion": "Propulsion",
      "PropulsionType": "Type de propulsion",
      "NumPropellers": "Nombre d’hélices",
      "Direction": "Direction",
      "TotalPower": "Puissance totale",
      "Units": "Unités",
      "Rudder": "Gouvernail",
      "RudderType": "Type de gouvernail",
      "NumRudders": "Nombre de gouvernails",
      "ForwardAzimuth": "Azimut avant",
      "AftAzimuth": "Azimut arrière",
      "ForwardTunnelThrusters": "Propulseurs en tunnel avant",
      "AftTunnelThrusters": "Propulseurs en tunnel arrière",
      "NumThrusters": "Nombre de propulseurs",
      "MastersName": "Nom du maître",
      "NumCrew": "Nombre de membres d’équipage",
      "NumPassengers": "Nombre de passagers",
      "ForwardDraught": "Tirant d’eau avant",
      "AftDraught": "Tirant d’eau arrière",
      "SailingDraught": "Creux de la voile",
      "AirDraught": "Tirant d’air",
      "ArrivalDraught": "Tirant d’eau à l’arrivée",
      "DepartureDraught": "Tirant d’eau au départ",
      "PortCallID": "ID de l’appel portuaire",
      "Certificates": "Certificats"
    }
  },
  "Map": {
    "Labels": {
      "AISShipType": "Type de navire AIS",
      "Navigation": "Navigation",
      "Destination": "Destination",
      "ETA": "ETA",
      "Speed": "Vitesse",
      "Heading": "Titre",
      "CMG": "CMG",
      "Latitude": "Latitude",
      "Longitude": "Longitude",
      "Time": "Heure",
      "LastUpdated": "Dernière mise à jour",
      "Age": "Âge"
    }
  },
  "VesselTooltip": {
    "Labels": {
      "ArrivalDraught": "Creux de la voile à l’arrivée",
      "DepartureDraught": "Creux de la voile au départ"
    }
  },
  "RequestDetails": {
    "Labels": {
      "WithdrawRequest": "Retirer la demande",
      "WithdrawDialogTitle": "Retirer la demande {{type}}",
      "WithdrawDialogMessage": "Êtes-vous sûr de vouloir retirer cette demande?",
      "LastUpdated": "Dernière mise à jour",
      "UpdateRequest": "Demande de mise à jour",
      "ShowBerthUtilization": "Afficher l’utilisation des postes d’amarrage",
      "HideBerthUtilization": "Masquer l’utilisation des postes d’amarrage",
      "Current": "Actuel",
      "Requested": "Demandé",
      "CurrentTime": "Heure actuelle",
      "CurrentLocation": "Lieu actuel",
      "RequestedTime": "Heure demandée",
      "Time": "heure",
      "Location": "Lieu",
      "ChangeTime": "Modifier l’heure",
      "ErrorInvalidTime": "Heure non valide",
      "ErrorCommentRequired": "Un commentaire est requis en cas de rejet de la demande",
      "ErrorArrivalApproved": "La demande d’arrivée doit être approuvée",
      "ErrorDepartureAfterArrival": "Le départ doit avoir lieu après la dernière arrivée",
      "ErrorArrivalBeforeAction": "L’arrivée doit précéder les autres actions",
      "Comment": "Commentaire",
      "HideComments": "Masquer <number/> commentaires",
      "ShowComments": "Afficher <number/> commentaires de plus"
    }
  },
  "RequestInbox": {
    "Labels": {
      "Inbox": "Boîte de réception",
      "RequestHeadingsMovement": "Mouvement",
      "RequestHeadingsArrival": "Arrivée",
      "RequestHeadingsDeparture": "Départ",
      "RequestHeadingsPortCall": "Appel portuaire",
      "RequestHeadingsRemarks": "Remarques",
      "NoRequests": "Aucune demande",
      "AcknowledgeUpdate": "Accuser réception de la mise à jour",
      "Comments": "Commentaires",
      "UpdatedAt": "Mis à jour à",
      "ShowDetails": "Afficher les détails",
      "Source": "Source",
      "Date": "Date",
      "Notification": "Notification",
      "RequestType": "Type de demande",
      "Invalid": "Invalide"
    }
  },
  "RequestTooltip": {
    "Labels": {
      "RequestType": "Type de demande",
      "Source": "Source",
      "UpdatedAt": "Mis à jour à",
      "Status": "État"
    }
  },
  "RequestState": {
    "Labels": {
      "Pending": "En attente",
      "Rejected": "Rejeté",
      "Approved": "Approuvé",
      "Withdrawn": "Retiré",
      "Notification": "Notification",
      "PartiallyApproved": "Partiellement approuvé",
      "PartiallyRejected": "Partiellement rejeté",
      "PendingAction": "En attente",
      "RejectAction": "Rejeter",
      "ApproveAction": "Approuver"
    }
  },
  "RequestType": {
    "Labels": {
      "CreatePortCall": "Créer une demande d’appel portuaire",
      "UpdatePortCallCargo": "Demande de mise à jour de la liste des cargaisons",
      "CancelPortCall": "Annuler la demande d’appel portuaire",
      "UpdateActionArrivalTimePlanned": "Demande de mise à jour de l’heure d’arrivée",
      "UpdateActionDepartureTimePlanned": "Demande de mise à jour de l’heure de départ",
      "UpdateActionDraught": "Demande de mise à jour du tirant d’eau",
      "CancelAction": "Annuler la demande d’action",
      "CreateDeparture": "Créer une demande de départ"
    }
  },
  "Contact": {
    "Error": {
      "ContactNotSpecified": "Le contact doit être spécifié"
    },
    "Labels": {
      "Type": "Type",
      "Name": "Nom complet",
      "DisplayName": "Nom",
      "Reference": "Référence",
      "Address": "Adresse",
      "Address1": "Adresse 1",
      "Address2": "Adresse 2",
      "Town": "Ville",
      "County": "Comté",
      "Postcode": "Code postal",
      "TelephoneNumber": "Numéro de téléphone",
      "Fax": "Fax",
      "Email": "Courriel",
      "ContactAName": "Nom du contact",
      "ContactADetail": "Coordonnées de la personne à contacter",
      "ContactBName": "Nom du contact",
      "ContactNDetail": "Coordonnées de la personne à contacter",
      "DeletedHelp": "Ce contact a été supprimé",
      "Contact": "contact"
    }
  },
  "ContactType": {
    "Labels": {
      "Agent": "Agent",
      "Stevedore": "Manutentionnaire",
      "PortStaff": "Personnel du port",
      "VesselAgent": "Agent maritime",
      "VesselMaster": "Capitaine du navire"
    }
  },
  "AgentHandover": {
    "Labels": {
      "From": "De",
      "NoActiveAgent": "Pas d’agent actif"
    }
  },
  "AgentHandoverCreateDialog": {
    "Labels": {
      "AgentHandover": "Transfert d’agent",
      "PlannedTimeOfHandover": "Heure prévue pour le transfert"
    }
  },
  "AgentHandoverEdit": {
    "Labels": {
      "Schedule": "Calendrier",
      "NoAgent": "Pas d’agent actif",
      "PlannedTime": "Heure prévue",
      "Details": "Détails"
    },
    "Buttons": {
      "SetPlannedTime": "Définir l’heure prévue"
    }
  },
  "VesselDataEdit": {
    "Labels": {
      "UpdatedAt": "Mis à jour à",
      "RefreshVesselData": "Actualiser les données du navire",
      "DisabledRefresh": "L’actualisation des données est désactivée en raison de l’absence d’OMI et d’ISMM",
      "ChangeVessel": "Changer de navire",
      "UpdateVessel": "Mettre à jour le navire"
    }
  },
  "VesselForm": {
    "Errors": {
      "EmptyName": "Le nom ne peut pas être vide",
      "MissingUnits": "Unités manquantes",
      "InvalidImo": "OMI non valide, échec de la somme de contrôle",
      "DuplicateImo": "Il existe un navire avec cet OMI",
      "InvalidMmsi": "L’ISMM doit contenir 9 chiffres",
      "DuplicateMmsi": "Il existe un navire avec cet ISMM"
    },
    "Labels": {
      "CreateVessel": "Créer un navire",
      "UpdateVessel": "Mettre à jour le navire",
      "UpdatePortCallOpenMessage": "Cette opération met à jour tous les appels portuaires ouverts pour le navire en question. Les futurs appels portuaires hériteront également des modifications apportées. Êtes-vous sûr(e) de vouloir continuer?",
      "UpdatePortCallMessage": "Cette opération ne met à jour que les détails du navire pour l’appel portuaire sélectionné. Êtes-vous sûr(e) de vouloir continuer?",
      "UnsavedChanges": "Il reste des modifications non enregistrées, êtes-vous sûr(e) de vouloir quitter?",
      "Image": "Image"
    }
  },
  "VesselItinerary": {
    "Labels": {
      "PortOfLoading": "Port de chargement",
      "LastPort": "Dernier port",
      "NextPort": "Port suivant",
      "PortOfDischarge": "Port de déchargement"
    }
  },
  "VesselSearch": {
    "Labels": {
      "UnknownVessel": "Navire inconnu",
      "TemporaryName": "Nom temporaire",
      "CallSign": "Indicatif d’appel",
      "Beam": "Poutre",
      "LengthOverall": "Longueur totale",
      "NominalDraught": "Tirant d’eau nominal"
    },
    "Errors": {
      "NotAValidImo": "OMI non valide",
      "NotAValidMmsi": "ISMM non valide"
    }
  },
  "LocationTimeline": {
    "Labels": {
      "NotAllocated": "Non attribué",
      "NoUpcomingAction": "Pas d’action à venir"
    }
  },
  "Agent": {
    "Labels": {
      "Agent": "Agent",
      "CharterAgent": "Agent d’affrètement",
      "CreateAgent": "Créer un agent",
      "Name": "Nom",
      "CompanyName": "Nom de l’entreprise",
      "DisplayName": "Nom d’affichage",
      "ContactDetails": "Coordonnées de la personne à contacter",
      "ContactName": "Nom du contact",
      "TelephoneNumber": "Numéro de téléphone",
      "FaxNumber": "Numéro de fax"
    },
    "Buttons": {
      "CreateAgent": "Créer un agent"
    },
    "Errors": {
      "EmptyCompanyName": "Le nom de l’entreprise ne peut pas être vide",
      "EmptyTelephoneNumber": "Le numéro de téléphone ne peut pas être vide"
    }
  },
  "VesselEditImageForm": {
    "Buttons": {
      "Select": "SELECTIONNER L’IMAGE",
      "Replace": "REMPLACER L’IMAGE",
      "Discard": "SUPPRIMER",
      "DiscardCrop": "SUPPRIMER L’IMAGE RECADRÉE"
    },
    "Errors": {
      "FileTypeTitle": "Type de fichier incorrect",
      "FileTypeMessage": "Veuillez télécharger un fichier avec un type d’extension d’image tel que .jpg ou .png",
      "FailedToUpload": "Échec du téléversement",
      "FailedToDelete": "Échec de la suppression",
      "ErrorMessage": "Message d’erreur",
      "Support": "Veuillez réessayer ou contacter l’équipe d’assistance"
    }
  },
  "ImageDialog": {
    "Labels": {
      "CropImage": "Recadrer l’image"
    }
  },
  "VesselImage": {
    "Labels": {
      "Image": "Image du navire"
    }
  },
  "PropulsionType": {
    "Labels": {
      "FixedPitch": "Hélice à pas fixe (FPP)",
      "ControllablePitch": "Hélice à pas variable (CPP)",
      "FixedVariablePitch": "Pas fixe et variable (FVPP)",
      "AzimuthThruster": "Propulseur Azimith",
      "VoithSchneider": "Voith Schneider Drive",
      "ZType": "Type Z"
    }
  },
  "RudderType": {
    "Labels": {
      "Standard": "Norme",
      "Flapped": "Rabattu/Flapped (Becker)",
      "Fishtail": "Queue de poisson (Schilling)"
    }
  },
  "PropellerDirection": {
    "Labels": {
      "Left": "Gauche",
      "Right": "Droite"
    }
  },
  "ActionDetails": {
    "Labels": {
      "Schedule": "Calendrier",
      "CharterAgent": "Agent d’affrètement",
      "Location": "Lieu",
      "Pilotage": "Pilotage",
      "Vessel": "Navire",
      "VesselDetails": "Détails",
      "VesselCondition": "Condition",
      "Checklist": "Liste de contrôle",
      "Attachments": "Annexes",
      "ChargeableItems": "Éléments imputables",
      "Prompt": "Il reste des modifications non enregistrées, êtes-vous sûr(e) de vouloir quitter?",
      "UnsavedChangesMessage": "Il y a des modifications non enregistrées dans l’éditeur actuel. Sauvegarder les modifications avant de mettre à jour d’autres détails",
      "AutomationWarning": "Cela empêchera {{movementType}} de poursuivre l’automatisation",
      "SubmitForApproval": "Soumettre pour approbation",
      "ConfirmDraught": "Confirmer le changement du tirage d’eau",
      "Remarks": "Remarques",
      "ConfirmSaveTitle": "Sauvegarder {{movementType}} de l’appel portuaire",
      "ConfirmSaveMessage": "Êtes-vous sûr de vouloir enregistrer {{movementType}} pour {{vesselName}}?"
    }
  },
  "MovementEdit": {
    "Labels": {
      "Attachments": "Annexes",
      "ChargeableItems": "Éléments imputables",
      "Checklist": "Liste de contrôle"
    }
  },
  "ActionScheduleEdit": {
    "Buttons": {
      "SetRequestedTime": "Définir l’heure demandée",
      "SetPlannedTime": "Définir l’heure prévue",
      "SetVesselReadyTime": "Régler le temps de préparation du navire",
      "SetPilotBoardingTime": "Régler l’heure d’embarquement des pilotes",
      "SetActualTime": "Régler l’heure réelle",
      "RequestChange": "Demande de modification"
    },
    "Labels": {
      "MovementSchedule": "Calendrier de {{movement}}",
      "MovementLocation": "Lieu de {{movement}}",
      "NotBerthableLocation": "Ce lieu n’est pas amarrable",
      "RequestedTime": "Heure demandée",
      "PlannedTime": "Heure prévue",
      "VesselReadyTime": "Temps de préparation du navire",
      "PilotBoardingTime": "Heure d’embarquement des pilotes",
      "ActualTime": "Heure réelle",
      "CharterAgent": "Agent d’affrètement",
      "Pilotage": "Pilotage",
      "Pilots": "Pilotes",
      "Linesmen": "Juges de ligne",
      "MooringVessels": "Bateaux d’amarrage"
    }
  },
  "ActionVesselEdit": {
    "Labels": {
      "Vessel": "Navire",
      "MovementDetails": "Détails de {{movement}}"
    }
  },
  "ActionPropulsionEdit": {
    "Labels": {
      "MovementCondition": "Condition de {{movement}}",
      "PrimaryPropulsion": "Propulsion primaire",
      "PropulsionState": "État de la propulsion",
      "PropulsionRemarks": "Remarques sur la propulsion",
      "RudderState": "État du gouvernail",
      "RudderRemarks": "Remarques sur le gouvernail",
      "ForwardAzimuthState": "État de l’azimut avant",
      "ForwardAzimuthRemarks": "Remarques sur l’azimut avant",
      "AftAzimuthState": "État de l’azimut arrière",
      "AftAzimuthRemarks": "Remarques sur l’azimut arrière",
      "ForwardTunnelThrustersState": "État du propulseur en tunnel avant",
      "ForwardTunnelThrustersRemarks": "Remarques sur le propulseur en tunnel avant",
      "AftTunnelThrustersState": "État du propulseur en tunnel arrière",
      "AftTunnelThrustersRemarks": "Remarques sur le propulseur en tunnel arrière"
    }
  },
  "ActionPropulsionOperationalState": {
    "Labels": {
      "FullyOperational": "Pleinement opérationnel",
      "HasFault": "Est défectueux"
    }
  },
  "AgentRequestTimeChangeDialog": {
    "Buttons": {
      "SendRequest": "Envoyer une demande"
    },
    "Labels": {
      "Title": "Demande de modification de {{movement}}",
      "RequestedTimeArrival": "Heure d’arrivée souhaitée",
      "RequestedTimeDeparture": "Heure de départ souhaitée",
      "Remarks": "Remarques"
    }
  },
  "DocumentEdit": {
    "Buttons": {
      "Upload": "Téléverser",
      "AddAttachment": "Ajouter une pièce jointe"
    },
    "Labels": {
      "NoFile": "Sélectionner le fichier à téléverser",
      "Filename": "Pièce jointe sélectionnée",
      "Progress": "Téléversement…",
      "Complete": "Téléversement complet",
      "Downloaded": "Pièce jointe téléchargée",
      "Deleted": "Pièce jointe supprimée",
      "Attachments": "Annexes"
    },
    "Errors": {
      "DuplicateTitle": "La pièce jointe {{doc}} existe déjà",
      "DuplicateMessage": "Téléverser {{doc}} remplacera la pièce jointe portant le même nom",
      "TooLargeTitle": "La pièce jointe est trop volumineuse pour être téléversée",
      "TooLargeMessage": "La pièce jointe {{doc}} est trop volumineuse pour être téléversée et doit faire moins de {{limite}}",
      "FailedUploadTitle": "Échec du téléversement de {{doc}}",
      "FailedUploadMessage": "Le téléversement de la pièce jointe a échoué. (Message d’erreur",
      "FailedDeleteTitle": "Échec de la suppression de {{doc}}",
      "FailedDeleteMessage": "La pièce jointe n’a pas pu être supprimée. (Message d’erreur",
      "FailedDownloadTitle": "Échec du téléchargement de {{doc}}",
      "FailedDownloadMessage": "Le téléchargement de la pièce jointe a échoué. (Message d’erreur",
      "Support": "Veuillez réessayer ou contacter l’équipe d’assistance.",
      "FailedUnknownTitle": "Erreur non gérée",
      "FailedUnknownMessage": "Erreur non gérée"
    }
  },
  "DocumentList": {
    "Labels": {
      "DeleteAttachmentTitle": "Supprimer la pièce jointe {{doc}}",
      "DeleteAttachmentMessage": "Êtes-vous sûr(e) de vouloir supprimer {{doc}}?",
      "NoAttachments": "Aucune pièce jointe téléversée",
      "NoDeletePermission": "Vous n’avez pas l’autorisation de supprimer ce fichier"
    }
  },
  "AddTodo": {
    "Labels": {
      "Select": "Sélectionner…"
    }
  },
  "ChargeableItems": {
    "Buttons": {
      "Add": "Ajouter un élément imputable",
      "Update": "Points de mise à jour"
    },
    "Labels": {
      "Title": "Éléments imputables",
      "Tariff": "Tarif",
      "Notes": "Notes",
      "UnitCost": "Coût unitaire",
      "MinimumCost": "Coût minimum",
      "Quantity": "Quantité",
      "QuantityShort": "QTÉ",
      "Total": "Total",
      "Empty": "",
      "Delete": "Supprimer l’élément imputable",
      "UnitType": "Type d’unité",
      "OverriddenUnitCost": "Coût unitaire écrasé",
      "ItemNotes": "Notes sur les éléments imputables",
      "ResetTariff": "Sélectionner un autre tarif",
      "UnitCostTooltip": "Tarif original du coût unitaire",
      "ResetTooltip": "Réinitialiser le coût unitaire",
      "NoTariffs": "Créer des tarifs pour ce type d’action afin d’ajouter des éléments imputables",
      "NoTariffsIsAdminIsAdmin": "Créer des tarifs pour ce type d’action afin d’ajouter des éléments imputables",
      "NoTariffsIsAdminNotAdmin": "Aucun tarif n’est attribué à ce type d’action.",
      "NoChargeable": "Il n’y a pas d’éléments imputables. "
    },
    "Errors": {
      "InvalidChargeable": "Tous les éléments imputables doivent faire l’objet d’un tarif sélectionné",
      "TariffBookOutOfRange": "L’action se situe en dehors de la plage de dates du livre des tarifs de cet élément",
      "TariffBookDeleted": "Le livre des tarifs pour cet élément a été supprimé",
      "TariffDeleted": "Le tarif de cet élément a été supprimé",
      "ActionInvalidTariffs": "Cette action contient des éléments imputables non valides"
    }
  },
  " Please contact your system administration to update tariffs": {
    "": {
      "": ""
    }
  },
  "Click the Add Chargeable Item button to add some": {
    "": {
      "": ""
    }
  },
  "FileTypes": {
    "Labels": {
      "Fal5CrewList": "Liste d’équipage (formulaire FAL 5)"
    }
  },
  "PreArrivalUpload": {
    "Buttons": {
      "CreateForm": "Créer un formulaire",
      "EditForm": "Modifier le formulaire",
      "UploadFile": "Téléverser le document"
    },
    "Labels": {
      "UploadedBy": "Téléversé par",
      "UpdatedBy": "Mise à jour par",
      "ReplaceTitle": "{{fileType}} existe",
      "ReplaceUploadMessage": "Téléversé un nouveau {{fileType}} remplacera les données existantes. Êtes-vous sûr(e) de vouloir continuer?",
      "ReplaceCreateFormMessage": "La création d’un nouveau {{fileType}} remplace les données existantes. Êtes-vous sûr(e) de vouloir continuer?",
      "DeleteTitle": "Supprimer {{fileType}}",
      "DeleteMessage": "Cela supprimera {{fileName}}. Êtes-vous sûr(e) de vouloir continuer?"
    },
    "Errors": {
      "Title": "Quelque chose n’a pas fonctionné!",
      "FailedToUpload": "Le téléversement du fichier a échoué. Veuillez réessayer.",
      "FailedToDownload": "Le téléchargement du document a échoué. Veuillez réessayer."
    }
  },
  "Fal5CrewList": {
    "Buttons": {
      "AddMember": "Ajouter un membre"
    },
    "Labels": {
      "Arrival": "Arrivée",
      "Departure": "Départ",
      "_1_1_NameOfShip": "1.1 Nom du navire",
      "_1_2_ImoNumber": "1.1 Numéro OMI",
      "_1_3_Callsign": "1.1 Indicatif d’appel",
      "_1_4_VoyageNumber": "1.1 Numéro de voyage",
      "_2_PortOfArrivalDeparture": "2. Port d’arrivée/départ",
      "_3_DateOfArrivalDeparture": "3. Date d’arrivée/départ",
      "_4_FlagStateOfShip": "4. État du pavillon du navire",
      "_5_LastPortOfCall": "5. Dernier port d’escale",
      "_6_Num": "6. Numéro",
      "_7_FamilyName": "7. Nom de famille",
      "_8_GivenNames": "8. Prénoms",
      "_9_RankOrRating": "9. Rang ou notation",
      "_10_Nationality": "10. Nationalité",
      "_11_DateOfBirth": "11. Date de naissance",
      "_12_PlaceOfBirth": "12. Lieu de naissance",
      "_13_Gender": "13. Sexe",
      "_14_NatureOfIdentidyDoc": "14. Nature du document d’identité",
      "_15_NumberOfIdentidyDoc": "15. Numéro du document d’identité",
      "_16_IssuingStateOfIdentityDoc": "16. État de délivrance du document d’identité",
      "_17_ExpireDateOfIdentityDoc": "17. Date d’expiration du document d’identité"
    }
  },
  "PortCall": {
    "Buttons": {
      "AddDeparture": "Ajouter un départ",
      "MoreOptions": "Plus d’options"
    },
    "Errors": {
      "InvalidDateFormat": "Format de date non valide",
      "DepartureAfterArrival": "Le départ doit suivre l’arrivée"
    },
    "Labels": {
      "NewPortCallTitle": "Créer un appel portuaire",
      "RequestPortCall": "Demander un appel portuaire",
      "PlannedTimeOfArrival": "Heure d’arrivée prévue",
      "PlannedTimeOfDeparture": "Heure de départ prévue",
      "ArrivalLocation": "Lieu d’arrivée",
      "DepartureLocation": "Lieu de départ",
      "Schedule": "Calendrier",
      "EnterRemarksForPortControl": "Saisir des remarques pour le contrôle du port",
      "ArrivalDraughts": "Tirant d’eau à l’arrivée",
      "DepartureDraughts": "Tirant d’eau au départ"
    }
  },
  "Cargo": {
    "Labels": {
      "Cargo": "Cargaison",
      "Cargos": "Cargaisons",
      "CargoItem": "Élément de cargaison",
      "CargoItems": "Éléments de cargaison",
      "Incoming": "À venir",
      "IncomingCargo": "Fret entrant",
      "Outgoing": "Sortant",
      "OutgoingCargo": "Fret sortant",
      "CargoType": "Type de cargaison",
      "OtherCargo": "Autres cargaisons",
      "NoCargo": "Pas d’articles de cargaison",
      "More": "plus"
    },
    "Buttons": {
      "AddCargo": "Ajouter une cargaison"
    }
  },
  "MovementActionList": {
    "Labels": {
      "Schedule": "Calendrier",
      "ScheduleDesc": "Heure prévue et heure estimée (si disponible)",
      "PortCallId": "ID de l’appel portuaire",
      "PortCallIdDesc": "Identifiant unique de l’appel portuaire (généré automatiquement par SmartPort)",
      "Vessel": "Navire",
      "VesselDesc": "Détails du navire",
      "PortOfRegistry": "Port d’immatriculation",
      "PortOfRegistryDesc": "Port dans lequel un navire est enregistré",
      "Dimensions": "Dimensions",
      "DimensionsDesc": "Longueur hors tout x poutre (et creux de la voile si disponible)",
      "Length": "Longueur",
      "LengthFull": "Longueur totale",
      "LengthDesc": "Longueur totale du navire",
      "Beam": "Poutre",
      "BeamDesc": "Largeur du navire au point le plus large",
      "Draught": "Tirant d’eau",
      "DraughtDesc": "Creux de la voile du navire pour le déplacement.  Tirant d’eau nominal du navire si le creux de la voile n’est pas disponible",
      "AirDraught": "Tirant d’air",
      "AirDraughtDesc": "Hauteur du navire au-dessus de la ligne de flottaison pour le mouvement",
      "BowThrusters": "BT",
      "BowThrustersFull": "Propulseurs d’étrave",
      "BowThrustersDesc": "Indique la présence de propulseurs d’étrave",
      "Location": "Lieu",
      "LocationDesc": "Emplacement du port dans lequel le navire sera amarré ou d’où il partira",
      "CharterAgent": "Agent d’affrètement",
      "Cargo": "Cargaison",
      "CargoDesc": "Type et quantité de cargaison",
      "NextPort": "Port suivant",
      "NextPortDesc": "Port suivant",
      "LastPort": "Dernier port",
      "LastPortDesc": "Dernier port d’escale",
      "LatestUserLogEntry": "Journal de bord",
      "LatestUserLogEntryDesc": "Dernière entrée du journal de bord saisie par l’utilisateur",
      "Remarks": "Remarques",
      "RemarksDesc": "Remarques introduites par l’utilisateur",
      "GrossTonnage": "GT",
      "GrossTonnageFull": "Tonnage brut",
      "GrossTonnageDesc": "Mesure non linéaire du volume interne global d’un navire",
      "Checklist": "Liste de contrôle",
      "ChecklistDesc": "Tâches en cours pour le mouvement",
      "FilterType": "Mouvements",
      "AllType": "Tous {{type}}",
      "NotAllocated": "Non attribué"
    }
  },
  "Filter": {
    "Buttons": {
      "ApplyFilters": "Appliquer les filtres"
    },
    "Labels": {
      "EnterFilterName": "Saisir un nom pour enregistrer un nouveau filtre",
      "NoName": "Veuillez saisir un nom pour enregistrer le filtre",
      "UniqueName": "Le nom doit être unique",
      "SpecialCharacters": "Le nom ne doit pas contenir de caractères spéciaux",
      "SelectFilter": "Sélectionner un filtre",
      "FilterUpdated": "Ce filtre sera mis à jour si aucun nom n’est saisi",
      "ApplyFilters": "Appliquer les filtres",
      "SelectType": "Sélectionner le type",
      "FilterName": "Nom du filtre",
      "FilterNamePrompt": "Saisir un nom pour l’enregistrer en tant que nouveau filtre"
    }
  },
  "Logbook": {
    "Buttons": {
      "AddEntry": "Ajouter une entrée"
    },
    "Labels": {
      "Logbook": "Journal de bord",
      "ItemCreatedByUser": "<item/> créé par <user/>",
      "ItemUpdatedByUser": "<item/> mis à jour par <user/>",
      "ItemFileUploadedByUser": "<item/> document <file/> téléversé par <user/>",
      "ItemFileUpdatedByUser": "<item/> document <file/> mis à jour par <user/>",
      "ItemFileDeletedByUser": "<item/> document <file/> supprimé par <user/>",
      "FileUploadedByUser": "Document <file/> téléversé par <user/>",
      "FileUpdatedByUser": "Document <file/> mis à jour par <user/>",
      "FileDeletedByUser": "Document <file/> supprimé par <user/>",
      "UserCommentedOnItem": "<user/> a commenté <item/>",
      "UserCommented": "<user/> mis un commentaire",
      "Comment": "Commentaire",
      "Action": "Action",
      "NoDataAvailable": "Pas de données disponibles",
      "AssociateWithSelectedItem": "Associer à <item/> sélectionné",
      "ShowOnlyEntriesForSelectedItem": "Afficher uniquement les entrées pour <item/> sélectionné",
      "ItemRequestedTime": "Heure demandée pour {{item}}",
      "RequestedTime": "Heure demandée",
      "Request": "Demande",
      "PortCall": "Appel portuaire"
    }
  },
  "BerthStatusDisplay": {
    "Labels": {
      "Berth": "Poste d’amarrage",
      "Berths": "Postes d’amarrage",
      "ActiveAgent": "Agent actif",
      "Remarks": "Remarques",
      "ShowPreviousDetails": "Afficher les détails précédents",
      "ShowRefreshingDetails": "Arrêter l’actualisation des détails",
      "StartRefreshingDetails": "Commencer à actualiser les détails",
      "ShowNextDetails": "Afficher les détails suivants",
      "DetailsDettings": "Paramètres détaillés",
      "NoVesselsInPort": "Pas de navires au port",
      "FilterAll": "Tous",
      "FilterOccupied": "Occupé",
      "FilterVacant": "Vacant",
      "FilterNameAllBerths": "Tous {{name}}",
      "Every1Second": "Toutes les 1 secondes",
      "Every5Second": "Toutes les 5 secondes",
      "Every15Second": "Toutes les 15 secondes",
      "Every1Minute": "Toutes les 1 minute",
      "RefreshRate": "Taux de rafraîchissement",
      "DisplayedDetails": "Détails affichés"
    }
  },
  "ToggleFullscreen": {
    "Labels": {
      "ToggleFullscreen": "Basculer en plein écran"
    }
  },
  "PortCallMenuItem": {
    "Labels": {
      "Movement": "Mouvement",
      "PortCall": "Appel portuaire",
      "None": "Non disponible",
      "StartDeparture": "Débuter le départ",
      "StartArrival": "Débuter l’arrivée",
      "RecordDeparture": "Enregistrer le départ",
      "RecordArrival": "Enregistrer l’arrivée",
      "StartShiftDeparture": "Débuter le départ de l’équipe",
      "StartShiftArrival": "Débuter l’arrivée de l’équipe",
      "RecordShiftDeparture": "Enregistrer le départde l’équipe",
      "RecordShiftArrival": "Enregistrer l’arrivée de l’équipe",
      "DeleteShift": "Supprimer le quart de travail",
      "UndoLast": "Annuler la dernière {{ actionState}} {{- actionName}}",
      "UndoCompleted": "Annulation terminée",
      "UndoInProgress": "Annulation en cours",
      "CancelPortCall": "Annuler l’appel portuaire",
      "DeletePortCall": "Supprimer l’appel portuaire",
      "ShowDetails": "Afficher les détails",
      "AgentHandover": "Transfert d’agent",
      "PlannedTimeOfHandover": "Heure prévue pour le transfert",
      "CharterAgent": "Agent d’affrètement",
      "DeleteDeparture": "Supprimer le départ",
      "CancelDeparture": "Annuler le départ",
      "Approve": "Approuver",
      "Decline": "Refuser",
      "MarkForApproval": "Soumettre pour approbation",
      "RequiresApproval": "Nécessite une approbation",
      "StartCustom": "Démarrer l’action suivante",
      "RecordCustom": "Enregistrer l’action suivante",
      "CancelCustom": "Annuler l’action",
      "DeleteCustom": "Supprimer l’action",
      "DeleteAgentHandover": "Supprimer le transfert d’agent",
      "NonDockableLocationWarning": "Sélectionnez un lieu d’amarrage pour activer cette action",
      "NoArrivalLocationWarning": "Veuillez sélectionner un lieu d’arrivée pour activer cette action",
      "RequestApprovalWarning": "Approuver la demande d’activation de cette action"
    }
  },
  "PortCallTimeline": {
    "Buttons": {
      "LifecycleNone": "Non disponible",
      "LifecycleStartDeparture": "Débuter le départ",
      "LifecycleStartArrival": "Débuter l’arrivée",
      "LifecycleRecordDeparture": "Enregistrer le départ",
      "LifecycleRecordArrival": "Enregistrer l’arrivée",
      "LifecycleStartShiftDeparture": "Débuter le départ de l’équipe",
      "LifecycleStartShiftArrival": "Débuter l’arrivée de l’équipe",
      "LifecycleRecordShiftDeparture": "Enregistrer le départde l’équipe",
      "LifecycleRecordShiftArrival": "Enregistrer l’arrivée de l’équipe",
      "LifecycleRequiresApproval": "Nécessite une approbation",
      "LifecycleStartCustom": "Démarrer l’action suivante",
      "LifecycleRecordCustom": "Enregistrer l’action suivante"
    },
    "Labels": {
      "AddDeparture": "Ajouter un départ",
      "AddBerthShift": "Ajouter un quart de travail",
      "AddAgentHandover": "Ajouter un transfert d’agent",
      "AddCustom": "Ajouter l’action {{- actionName}}"
    }
  },
  "PortCallTimelineMenu": {
    "Errors": {
      "PortCallUpdateFailed": "Une erreur s’est produite lors de la mise à jour de l’appel portuaire. Cliquez sur Confirmer pour réessayer"
    },
    "Labels": {
      "Remarks": "Remarques",
      "OutstandingTodos": "Des points de la liste de contrôle sont en suspens pour cette action. Êtes-vous sûr(e) de vouloir continuer?",
      "ArrivalDialogTitle": "Arrivée de l’appel portuaire",
      "ArrivalDialogMessage": "Êtes-vous sûr(e) de vouloir lancer l’arrivée pour {{vesselName}}? {{todos}}",
      "ShiftArrivalDialogTitle": "Arrivée de l’équipe",
      "ShiftArrivalDialogMessage": "Êtes-vous sûr de vouloir lancer l’arrivée de l’équipe pour {{vesselName}}? {{todos}}",
      "RecordArrivalDialogTitle": "Arrivée de l’appel portuaire",
      "RecordArrivalDialogInput": "Heure d’arrivée effective",
      "RecordShiftArrivalDialogTitle": "Arrivée de l’équipe",
      "RecordShiftArrivalDialogInput": "Heure d’arrivée effective",
      "DepartureDialogTitle": "Départ appel portuaire",
      "DepartureDialogInput": "Heure de départ effective",
      "ShiftDepartureDialogTitle": "Départ de l’équipe",
      "ShiftDepartureDialogInput": "Heure de départ effective",
      "DeleteShiftDialogTitle": "Supprimer le quart de travail",
      "DeleteShiftDialogMessage": "Êtes-vous sûr(e) de vouloir supprimer le quart de travail? {{todos}}",
      "RecordDepartureDialogTitle": "Départ appel portuaire",
      "RecordDepartureDialogMessage": "Êtes-vous sûr de vouloir terminer le départ et de clôturer l’appel portuaire de {{nom du navire}}? {{todos}} Cette action clôturera l’appel portuaire. Veuillez vous assurer que tous les détails ont été saisis avant de procéder à la clôturation du départ.",
      "RecordShiftDepartureDialogTitle": "Départ de l’équipe",
      "RecordShiftDepartureDialogMessage": "Êtes-vous sûr(e) de vouloir compléter le départ de l’équipe pour {{vesselName}}? {{todos}}",
      "UndoActionDialogTitle": "Annuler l’état",
      "UndoActionDialogMessage": "Êtes-vous sûr de vouloir annuler la dernière {{actionState}} {{- actionName}} pour {{vesselName}}?",
      "CancelPortCallDialogTitle": "Annuler l’appel portuaire",
      "CancelPortCallDialogMessage": "Êtes-vous sûr de vouloir annuler l’appel portuaire pour {{vesselName}}? Cette action clôturera l’appel portuaire. Veuillez vous assurer que tous les détails ont été saisis avant d’annuler.",
      "CancelRequestDialogTitle": "Demande d’annulation d’un appel portuaire pour ",
      "CancelRequestDialogMessage": "Êtes-vous sûr(e) de vouloir demander une annulation pour {{vesselName}}?",
      "DeletePortCallDialogTitle": "Supprimer l’appel portuaire",
      "DeletePortCallDialogMessage": "Êtes-vous sûr(e) de vouloir supprimer l’appel portuaire pour {{vesselName}}? Cette action clôturera l’appel portuaire. Veuillez vous assurer que tous les détails ont été saisis avant de supprimer.",
      "ActionCancelDialogTitle": "Annuler {{- actionName}}",
      "ActionCancelDialogMessage": "Êtes-vous sûr(e) de vouloir annuler l’action {{- actionName}} pour {{vesselName}}? {{todos}}",
      "CancelDepartureRequestDialogTitle": "Demande d’annulation de départ pour",
      "CancelDepartureRequestDialogMessage": "Êtes-vous sûr(e) de vouloir demander une annulation de départ pour {{vesselName}}?",
      "DeleteActionDialogTitle": "Supprimer {{- actionName}}",
      "DeleteActionDialogMessage": "Êtes-vous sûr(e) de vouloir supprimer l’action {{- actionName}} pour {{vesselName}}?",
      "StartCustomActionDialogTitle": "{{- actionName}} appel portuaire",
      "StartCustomActionDialogInput": "Heure réelle de {{- actionName}}",
      "RecordCustomActionDialogTitle": "{{- actionName}} appel portuaire",
      "RecordCustomActionDialogMessage": "Êtes-vous sûr(e) de vouloir compléter {{- actionName}}?",
      "ApproveActionDialogTitle": "Approuver {{- actionName}}",
      "ApproveActionDialogMessage": "Approuver {{- actionName}}",
      "DeclineActionDialogTitle": "Refuser {{- actionName}}",
      "DeclineActionDialogMessage": "Êtes-vous sûr(e) de vouloir refuser {{- actionName}}?",
      "MarkApprovalDialogTitle": "Soumettre {{- actionName}} pour approbation",
      "MarkApprovalDialogMessage": "Êtes-vous sûr(e) de vouloir marquer {{- actionName}} pour approbation",
      "DeleteAgentHandoverDialogTitle": "Supprimer le transfert d’agent",
      "DeleteAgentHandoverDialogMessage": "Êtes-vous sûr de vouloir supprimer le transfert d’agent à {{agentName}}?",
      "AutomationArrivalWarning": "Cela empêchera d’automatiser l’arrivée davantage",
      "AutomationDepartureWarning": "Cela empêchera d’automatiser le départ davantage"
    }
  },
  "CustomAction": {
    "Errors": {
      "FirstAction": "L’heure prévue doit se situer après la première action",
      "Departure": "L’heure prévue doit être avant le départ",
      "LastCompletedInProgress": "L’heure prévue doit être postérieure à la dernière action achevée ou en cours",
      "InvalidDate": "Format de date non valide",
      "TimeCheckActualBeforeComplete": "L’heure de début doit être antérieure à l’heure de fin",
      "TimeCheckCompleteAfterActual": "L’heure de fin doit être postérieure à l’heure de début"
    }
  },
  "CustomActionMenu": {
    "Labels": {
      "DeleteAction": "Supprimer l’action {{- actionName}}",
      "CancelAction": "Annuler l’action {{- actionName}}",
      "UndoAction": "Annuler l’action {{- actionName}}",
      "RecordAction": "Enregistrer l’action {{- actionName}}",
      "StartAction": "Démarrer l’action {{- actionName}}",
      "CompleteAction": "Compléter l’action {{- actionName}}",
      "UndoCompleted": "Annulation terminée",
      "UndoStarted": "Annulation lancée",
      "CancelActionDialogTitle": "Annuler l’action {{- actionName}}",
      "CancelActionDialogMessage": "Êtes-vous sûr(e) de vouloir annuler l’action {{- actionName}} pour {{vesselName}}?",
      "UndoActionDialogTitle": "Annuler l’état",
      "UndoActionDialogMessage": "Êtes-vous sûr(e) de vouloir annuler la dernière {{actionState}} de l’action {{- actionName}} pour {{vesselName}}?",
      "RecordActionDialogTitle": "L’action {{- actionName}} pour l’appel portuaire",
      "RecordActionDialogMessage": "Êtes-vous sûr(e) de vouloir compléter l’action {{- actionName}}?",
      "StartActionDialogTitle": "L’action {{- actionName}} pour l’appel portuaire",
      "StartActionDialogInput": "Heure de début de l’action {{- actionName}}",
      "DeleteActionDialogTitle": "Supprimer l’action {{- actionName}}",
      "DeleteActionDialogMessage": "Êtes-vous sûr de vouloir supprimer l’action {{- actionName}}?",
      "CompleteActionDialogInput": "Heure d’achèvement de l’action {{- actionName}}"
    }
  },
  "CustomActionEdit": {
    "Labels": {
      "Schedule": "Calendrier de {{- actionName}}",
      "Notes": "Notes"
    }
  },
  "CustomActionCreateDialog": {
    "Labels": {
      "Title": "Ajouter {{- actionName}}",
      "Service": "Service",
      "PlannedTime": "Heure prévue",
      "Notes": "Notes"
    }
  },
  "CustomActionScheduleEdit": {
    "Labels": {
      "SetPlannedTime": "Définir l’heure prévue",
      "SetActualTime": "Régler l’heure réelle",
      "SetCompletionTime": "Définir l’heure d’exécution",
      "Time": "Heure",
      "SetTime": "Définir l’heure",
      "StartTime": "Heure de début",
      "CompletionTime": "Délai de réalisation"
    },
    "Errors": {
      "ImvalidTime": "Heure non valide"
    }
  },
  "ShiftCreateDialog": {
    "Labels": {
      "NewShift": "Nouvelle équipe"
    },
    "Errors": {
      "ShiftDepartureAfterArrival": "Le départ de l’équipe doit suivre un mouvement d’arrivée valide",
      "ShiftDepartureBeforeCompleted": "Le départ de l’équipe ne doit pas intervenir avant un mouvement achevé",
      "ShiftAfterDeparture": "L’arrivée de l’équipe doit suivre directement son départ"
    }
  },
  "DepartureCreateDialog": {
    "Labels": {
      "NewDeparture": "Nouveau départ"
    }
  },
  "AgentDepartureCreateDialog": {
    "Labels": {
      "NewDeparture": "Demande de nouveau départ",
      "Remarks": "Remarques"
    },
    "Errors": {
      "DepartureAfterArrival": "Le départ doit intervenir après la dernière action"
    }
  },
  "TimeTooltip": {
    "Labels": {
      "ActualTime": "Heure réelle",
      "StartTime": "Heure de début",
      "PlannedTime": "Heure prévue",
      "CompletionTime": "Délai de réalisation",
      "EstimatedTime": "Durée estimée",
      "RequestedTime": "Heure demandée",
      "Notes": "Notes",
      "Action": "Action",
      "UpdatedAgo": "mis à jour il y a {{time}}"
    }
  },
  "Weather": {
    "Labels": {
      "TemperatureAt": "Température ({{symbol}}) à {{time}}",
      "VisibilityAt": "Visibilité ({{unit}}) à {{time}}",
      "AtmosphericPressureAt": "Pression atmosphérique ({{unit}}) à {{time}}",
      "WindDirectionAt": "{{degrees}}° {{direction}} à {{time}}",
      "Gusting": "Rafale",
      "WeatherAt": "{{weather}} à {{time}}"
    }
  },
  "WeatherIcon": {
    "Labels": {
      "Sunny": "Ensoleillé",
      "MostlySunny": "Majoritairement ensoleillé",
      "PartlySunny": "Partiellement ensoleillé",
      "IntermittentClouds": "Nuages intermittents",
      "HazySunshine": "Rayon de soleil brumeux",
      "MostlyCloudy": "Principalement nuageux",
      "Cloudy": "Nuageux",
      "Dreary": "Maussade (couvert)",
      "Fog": "Brouillard",
      "Showers": "Averses",
      "MostlyCloudyWithShowers": "Principalement nuageux avec averses",
      "PartlySunnyWithShowers": "Partiellement ensoleillé avec averses",
      "TStorms": "Orages",
      "MostlyCloudyWithTStorms": "Principalement nuageux avec orages",
      "PartlySunnyWithTStorms": "Partiellement ensoleillé avec orages",
      "Rain": "Pluie",
      "Flurries": "Rafales de neige",
      "MostlyCloudyWithFlurries": "Principalement nuageux avec rafales de neige",
      "PartlySunnyWithFlurries": "Partiellement ensoleillé avec rafales de neige",
      "Snow": "Neige",
      "MostlyCloudyWithSnow": "Principalement nuageux avec neige",
      "Ice": "Glace",
      "Sleet": "Grésil",
      "FreezingRain": "Pluie verglaçante",
      "RainAndSnow": "Pluie et neige",
      "Hot": "Chaud",
      "Cold": "Froid",
      "Windy": "Venteux",
      "Clear": "Clair",
      "MostlyClear": "Principalement clair",
      "PartlyCloudy": "Partiellement nuageux",
      "HazyMoonlight": "Clair de lune brumeux",
      "PartlyCloudyWithShowers": "Partiellement nuageux avec averses",
      "PartlyCloudyWithTStorms": "Partiellement nuageux avec orages"
    }
  },
  "WindDirection": {
    "Labels": {
      "N": "N",
      "NbE": "NqNE",
      "NNE": "NNE",
      "NEbN": "NEqN",
      "NE": "NE",
      "NEbE": "NEqE",
      "ENE": "ENE",
      "EbN": "EqNE",
      "E": "E",
      "EbS": "EqSE",
      "ESE": "ESE",
      "SEbE": "SEqE",
      "SE": "SE",
      "SEbS": "SEqS",
      "SSE": "SSE",
      "SbE": "SqSE",
      "S": "S",
      "SbW": "SqSW",
      "SSW": "SSW",
      "SWbS": "SWqS",
      "SW": "SW",
      "SWbW": "SWqW",
      "WSW": "WSW",
      "WbS": "WqSW",
      "W": "W",
      "WbN": "WqNW",
      "WNW": "WNW",
      "NWbW": "NWqW",
      "NW": "NW",
      "NWbN": "NWqN",
      "NNW": "NNW",
      "NbW": "NqNW"
    }
  },
  "FileExportFields": {
    "Labels": {
      "CargoIn": "chargé",
      "CargoOut": "retiré",
      "PortCall": "Appel portuaire",
      "Vessel": "Navire",
      "Agent": "Agent",
      "Arrival": "Arrivée",
      "Departure": "Départ",
      "VesselName": "Nom du navire",
      "PortCallReferenceID": "ID de référence",
      "PortCallPortOfLoading": "Port de chargement",
      "PortCallLastPort": "Dernier port",
      "PortCallNextPort": "Port suivant",
      "PortCallPortOfDischarge": "Port de déchargement",
      "PortCallCategory": "Catégorie",
      "PortCallStatus": "Statut de l’appel portuaire",
      "PortCallCargo": "Cargaison",
      "PortCallCargoIn": "Cargo chargé",
      "PortCallCargoOut": "Cargo retiré",
      "PortCallRemarks": "Remarques",
      "PortCallTags": "Étiquettes d’appels portuaires",
      "ArrivalTimeRequested": "Arrivée demandée",
      "ArrivalTimePlanned": "Heure d’arrivée prévue",
      "ArrivalTimeEstimated": "Heure d’arrivée estimée",
      "ArrivalTimeReady": "Heure d’arrivée navire prêt",
      "ArrivalTimeActual": "Heure d’arrivée réelle",
      "ArrivalPilotBoarding": "Heure d’embarquement du pilote d’arrivée",
      "ArrivalLocation": "Lieu d’arrivée",
      "ArrivalPilots": "Pilotes à l’arrivée",
      "ArrivalLinesmen": "Juges de ligne à l’arrivée",
      "ArrivalMooringVessels": "Arrivée des navires d’amarrage",
      "ArrivalMastersName": "Nom du maître de l’arrivée",
      "ArrivalCrew": "Nombre de membres d’équipage à l’arrivée",
      "ArrivalPassengers": "Nombre de passagers à l’arrivée",
      "ArrivalForwardDraught": "Arrivée tirant d’eau avant",
      "ArrivalAftDraught": "Arrivée tirant d’eau arrière",
      "ArrivalSailingDraught": "Creux de la voile à l’arrivée",
      "ArrivalAirDraught": "Arrivée tirage d’air",
      "DepartureTimeRequested": "Heure de départ souhaitée",
      "DepartureTimePlanned": "Heure de départ prévue",
      "DepartureTimeReady": "Heure de départ prêt",
      "DepartureTimeActual": "Heure de départ réelle",
      "DeparturePilotBoarding": "Heure d’embarquement du pilote de départ",
      "DepartureLocation": "Lieu de départ",
      "DeparturePilots": "Pilotes de départ",
      "DepartureLinesmen": "Juges de ligne de départ",
      "DepartureMooringVessels": "Navires d’amarrage au départ",
      "DepartureMastersName": "Nom du capitaine de départ",
      "DepartureCrew": "Nombre de membres d’équipage au départ",
      "DeparturePassengers": "Nombre de passagers au départ",
      "DepartureForwardDraught": "Départ tirant d’eau avant",
      "DepartureAftDraught": "Depart tirant d’eau arrière",
      "DepartureSailingDraught": "Creux de la voile au départ",
      "DepartureAirDraught": "Tirant d’air au départ"
    }
  },
  "Snackbar": {
    "Labels": {
      "Syncing": "Synchronisation",
      "UpdatedBy": "Mis à jour par {{userId}} à {{time}}",
      "ArrivalScheduled": "Nouvelle arrivée prévue",
      "ArrivalPlanned": "Mise à jour de l’horaire d’arrivée",
      "ArrivalRequested": "Nouvel arrivage demandé",
      "ArrivalStarted": "L’arrivée a commencé",
      "ArrivalCompleted": "L’arrivée est terminée",
      "ArrivalCancelled": "L’arrivée a été annulée",
      "ArrivalApproved": "L’arrivée a été approuvée",
      "ArrivalDeclined": "L’arrivée a été refusée",
      "DepartureScheduled": "Nouveau départ prévu",
      "DeparturePlanned": "Mise à jour de l’horaire de départ",
      "DepartureRequested": "Nouveau départ demandé",
      "DepartureStarted": "Le départ a commencé",
      "DepartureCompleted": "Le départ est terminé",
      "DepartureCancelled": "Le départ a été annulé",
      "DepartureApproved": "Le départ a été approuvé",
      "DepartureDeclined": "Le départ a été refusé",
      "ShiftArrivalScheduled": "L’arrivée d’une nouvelle équipe d’amarrage est prévue",
      "ShiftArrivalPlanned": "Mise à jour de l’horaire d’arrivée de l’équipe d’amarrage",
      "ShiftArrivalStarted": "L’arrivée de l’équipe d’amarrage a commencé",
      "ShiftArrivalCompleted": "L’arrivée de l’équipe d’amarrage est terminée",
      "ShiftArrivalCancelled": "L’arrivée de l’équipe d’amarrage a été annulée",
      "ShiftArrivalApproved": "L’arrivée de l’équipe d’amarrage a été approuvée",
      "ShiftArrivalDeclined": "L’arrivée de l’équipe d’amarrage a été refusée",
      "ShiftDepartureScheduled": "Nouveau départ de l’équipe d’amarrage prévu",
      "ShiftDeparturePlanned": "Mise à jour des horaires de départ de l’équipe d’amarrage",
      "ShiftDepartureStarted": "Le départ de l’équipe d’amarrage a commencé",
      "ShiftDepartureCompleted": "Le départ de l’équipe d’amarrage est terminé",
      "ShiftDepartureCancelled": "Le départ de l’équipe d’amarrage a été annulé",
      "ShiftDepartureApproved": "Le départ de l’équipe d’amarrage a été approuvé",
      "ShiftDepartureDeclined": "Le départ de l’équipe d’amarrage a été refusé"
    }
  },
  "AIS": {
    "Labels": {
      "LastMessageTime": "Heure du dernier message",
      "ReceiverName": "Nom du récepteur",
      "InvalidAISData": "Données AIS non valides",
      "Vessels": "Navires",
      "AISReceivers": "Récepteurs AIS",
      "Status": "État",
      "ThresholdSettings": "Paramètres de seuil",
      "WarningThreshold": "Seuil d’alerte",
      "WarningThresholdHelp": "Afficher l’état d’alerte sur le récepteur lorsque le seuil est atteint",
      "CriticalThreshold": "Seuil critique",
      "CriticalThresholdHelp": "Afficher l’état critique sur le récepteur lorsque le seuil est atteint",
      "CriticalThresholdError": "Le seuil critique doit venir après le seuil d’alerte"
    }
  },
  "CheckboxTree": {
    "Labels": {
      "SelectAll": "Tout sélectionner",
      "DeselectAll": "Tout désélectionner",
      "ExpandAll": "Tout développer",
      "CollapseAll": "Tout fermer"
    }
  },
  "MapLayers": {
    "Labels": {
      "Vessels": "Navires",
      "AISReceivers": "Récepteurs AIS",
      "Zones": "Zones",
      "MarlinGIS": "Marlin GIS",
      "EncAidToNav": "Aides à la navigation",
      "EncDangers": "Dangers",
      "EncRestrictions": "Restrictions",
      "EncCables": "Câbles",
      "EncPipelines": "Pipelines",
      "EncPlatforms": "Plates-formes offshore",
      "EncAnchors": "Ancres",
      "EncHydrography": "Hydrographie",
      "EncSoundings": "Sondages",
      "EncAllSoundings": "Tous les sondages",
      "EncTopography": "Topographie",
      "EncCoverages": "Couvertures",
      "EncBaseLabel": "Base-Étiquette",
      "EncAidsToNavigationLabel": "Aides à la navigation-Étiquette",
      "EncHydrographyLabel": "Hydrographie-Étiquette",
      "EncRestrictionsLabel": "Restrictions-Étiquette",
      "EncTopographyLabel": "Topographie-Étiquette",
      "EncMetadata": "Métadonnées",
      "EncBaseWater": "Eau de base",
      "EncBaseLand": "Terrain de base",
      "EncBase": "Base"
    }
  },
  "ExternalNotification": {
    "Txt": {
      "Notification": "Notification",
      "PortCallSummary": "RÉSUMÉ DE L’APPEL PORTUAIRE",
      "Actual": "Réel",
      "Requested": "Demandé",
      "Planned": "Prévu",
      "Schedule": "CALENDRIER",
      "To": "À",
      "From": "DE",
      "At": "à/au",
      "Local": "Locale",
      "UTC": "UTC",
      "Day": "jour",
      "Days": "jours",
      "Hour": "heure",
      "Hours": "heures",
      "Minute": "minute",
      "Minutes": "minutes",
      "LocationNotSpecified": "Lieu non spécifié",
      "ArrivalCreated": "Une arrivée a été créée.",
      "ArrivalStarted": "Une arrivée a été lancée.",
      "ArrivalCompleted": "Une arrivée a été complétée.",
      "ArrivalPendingApproval": "Une arrivée est en attente d’approbation.",
      "ArrivalPendingFurtherApproval": "Une arrivée est en attente d’approbation supplémentaire.",
      "ArrivalApproved": "Une arrivée a été approuvée.",
      "ArrivalDeclined": "Une arrivée a été refusée.",
      "ArrivalRequested": "Une arrivée a été demandée.",
      "ArrivalBefore": "Ceci est un rappel qu’une arrivée prochaine a été programmée à {{dateTime}}.",
      "ArrivalAfter": "Il s’agit d’un rappel qu’une arrivée tardive est prévue (il y a {{diff}}).",
      "DepartureCreated": "Un départ a été créé.",
      "DepartureStarted": "Un départ a été lancé.",
      "DepartureCompleted": "Un départ a été complété.",
      "DeparturePendingApproval": "Un départ est en attente d’approbation.",
      "DeparturePendingFurtherApproval": "Un départ est en attente d’approbation supplémentaire.",
      "DepartureApproved": "Un départ a été approuvé.",
      "DepartureDeclined": "Un départ a été refusé.",
      "DepartureCancelled": "Un départ a été annulé.",
      "DepartureRequested": "Un départ a été demandé.",
      "DepartureBefore": "Ceci est un rappel qu’un prochain départ a été programmé à {{dateTime}}.",
      "DepartureAfter": "Ceci est un rappel qu’un départ tardif est prévu (il y a {{diff}}).",
      "ShiftArrivalCreated": "Une arrivée d’équipe a été créée.",
      "ShiftArrivalStarted": "L’arrivée d’une équipe a été lancée.",
      "ShiftArrivalCompleted": "Une arrivée d’équipe a été complétée.",
      "ShiftArrivalPendingApproval": "Une arrivée d’équipe est en attente d’approbation.",
      "ShiftArrivalApproved": "Une arrivée d’équipe a été approuvée.",
      "ShiftArrivalDeclined": "Une arrivée d’équipe a été refusée.",
      "ShiftArrivalBefore": "Ceci est un rappel qu’une arrivée d’équipe prochaine a été programmée à {{dateTime}}.",
      "ShiftArrivalAfter": "Il s’agit d’un rappel qu’une arrivée tardive d’équipe est prévue (il y a {{diff}}).",
      "ShiftDepartureCreated": "Un départ d’équipe a été créé.",
      "ShiftDepartureStarted": "Un départ d’équipe a été lancé.",
      "ShiftDepartureCompleted": "Un départ d’équipe a été complété.",
      "ShiftDeparturePendingApproval": "Un départ d’équipe est en attente d’approbation.",
      "ShiftDepartureApproved": "Un départ d’équipe a été approuvé.",
      "ShiftDepartureDeclined": "Un départ d’équipe a été refusé.",
      "ShiftDepartureBefore": "Ceci est un rappel qu’un prochain départ d’équipe a été programmé à {{dateTime}}.",
      "ShiftDepartureAfter": "Ceci est un rappel qu’un départ d’équipe tardif est prévu (il y a {{diff}}).",
      "Custom": "Personnalisé",
      "CustomCreated": "l’action {{name}} a été créée.",
      "CustomStarted": "l’action {{name}} a été lancée.",
      "CustomCompleted": "l’action {{name}} a été terminée.",
      "CustomCancelled": "l’action {{name}} a été annulée.",
      "CustomBefore": "Ceci est un rappel qu’une prochaine action {{name}} a été programmée à {{dateTime}}.",
      "CustomAfter": "Ceci est un rappel qu’une action {{name}} tardive est programmée (il y a {{diff}}).",
      "Regards": "Cordialement,",
      "PortControl": "Contrôle du port",
      "FooterMsg": "Ne répondez pas directement à ce courriel. Ce courriel a été envoyé à partir d’une adresse de notification qui ne peut pas accepter de courrier entrant. Si vous avez des questions ou si vous avez besoin d’aide, veuillez contacter l’administrateur.",
      "Link": "Si vous êtes un utilisateur enregistré de SmartPort, vous pouvez consulter l’appel portuaire à l’adresse suivante"
    }
  },
  "CargoType": {
    "Labels": {
      "Name": "Nom",
      "Unit": "Unité de mesure",
      "Metre": "Mètre",
      "Tonne": "Tonne",
      "MetersCubed": "Mètre cube",
      "Each": "Chaque",
      "DuplicateName": "Un type de fret portant ce nom existe déjà"
    }
  },
  "CertificateType": {
    "Buttons": {
      "AddCertificate": "Ajouter un certificat"
    },
    "Labels": {
      "Name": "Nom",
      "Description": "Description",
      "HelperMessage": "Ajouter un nouveau type de {{category}}",
      "NoCertificates": "Aucun certificat",
      "ExpiredHelp": "Ce certificat a expiré",
      "InvalidHelp": "Ce certificat n’est pas valide",
      "CertificateType": "Type de certificat",
      "CertificateCategory": "Catégorie de certificat",
      "CertificateTypeLowerCase": "type de certificat",
      "CertificateCategoryLowerCase": "catégorie de certificat",
      "Notes": "Notes",
      "ValidFrom": "Valable à partir de",
      "ValidTo": "Valable jusqu’au",
      "To": "à",
      "More": "(+{{more}} plus)",
      "ReferenceNumber": "Numéro de référence",
      "ReferenceNumberShort": "Réf",
      "NameOfHolder": "Nom du titulaire",
      "DeleteCertificateTitle": "Supprimer le certificat ",
      "DeleteCertificateMessage": "Êtes-vous sûr de vouloir supprimer le certificat {{name}}?",
      "TypeNotSpecified": "Type non spécifié",
      "Certificates": "Certificats",
      "Valid": "Valable",
      "Invalid": "Invalide"
    }
  },
  "VesselList": {
    "Labels": {
      "ShowVesselDetails": "Afficher les détails du navire",
      "NoMoreData": "Aucune autre donnée n’est disponible.",
      "GrossTonnage": "GT",
      "NetTonnage": "NT",
      "DeadweightTonnage": "DWT",
      "PortOfRegistry": "Port d’immatriculation",
      "Type": "Type",
      "Certificates": "Certificats",
      "VesselDesc": "Détails du navire",
      "ImoDesc": "OMI du navire",
      "MmsiDesc": "Numéro ISMM du navire",
      "CallSignDesc": "Indicatif d’appel du navire",
      "DimensionsDesc": "Longueur hors tout x poutre (et creux de la voile si disponible)",
      "GrossTonnageDesc": "Mesure non linéaire du volume interne global d’un navire",
      "NetTonnageDesc": "L’espace disponible pour le logement des passagers et l’arrimage du fret",
      "DeadweightTonnageDesc": "Mesure du poids qu’un navire peut transporter",
      "PortOfRegistryDesc": "Port dans lequel un navire est enregistré",
      "YearBuiltDesc": "Année de construction",
      "TypeDesc": "Type de navire",
      "FlagDesc": "Pavillon du navire",
      "CertificatesDesc": "Certificats de navires"
    }
  }
}