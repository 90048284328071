import React from 'react';
import {
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import LatestUserLogbookTooltip from '../Tooltip/LatestUserLogbookTooltip';

/* 
 *  Documentation
 *  Displays the latest user entered logbook entry as 2 truncated lines
 *  Returns a component
*/
const useStyles = makeStyles(theme => ({
  latestUserLogbookEntry: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  }
}));

export const PortCallLatestLogbookEntry = ({
  logbookEntry,
  delay,
  ...otherProps
}) => {
  const classes = useStyles();
  const parsedComment = logbookEntry?.comment
    ? logbookEntry?.comment.split('\n').map((item, index) => <span key={index}>{item}<br /></span>)
    : '';
  const truncatedComment = parsedComment.slice(0, 2);
  const small = logbookEntry?.comment && logbookEntry?.comment.length > 25;
  return (
    <ListItem
      component="div"
      disableGutters={true}
      {...otherProps}
    >
      <div className={classes.latestUserLogbookEntry}>
        <PopoverTooltip tooltip={<LatestUserLogbookTooltip logbookEntry={logbookEntry} />} delay={delay}>
          <Typography variant={small ? "caption" : "body1"}>
            {truncatedComment}
          </Typography>
        </PopoverTooltip>
      </div>
    </ListItem>
  )
}
export default PortCallLatestLogbookEntry;