import React, { useContext, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  makeStyles,
  Popover,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import MoveDepart from '../../../icons/MoveDepart';
import { Action, ActionMovementType, ActionState, PortCall, Request, RequestState, RequestType, TemplateSubType } from '../../../models';
import { 
  getActionLastLifecycle, 
  getActionLastArrival,
  getActionTime, 
  getActionableNext,
  getActionTodosAsync
} from '../../../utils/getters';
import { DataStore } from 'aws-amplify';
import StringKeyboardDateTimePicker from '../../Common/StringKeyboardDateTimePicker';
import { add, isBefore } from 'date-fns';
import { ActionTypeIds } from '../../../environment';
import { getAgentContact, buildRequestSource, getUserEmail, getUserName } from '../../../utils/agentPortal';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import { generateActionFromTemplate } from '../../../utils/templateUtils';
import useAuditMeta from '../../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import { createAllChargeablesItemFromBooks } from '../../../utils/tariffBook';
import useFeatureSetting from '../../../hooks/useFeatureSetting';
import FeatureFlags from "../../../constants/FeatureFlags";
import useTariffBooks from '../../../hooks/useTariffBooks';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    border: 0
  }
}));

const AgentDepartureCreateDialog = ({ portCall, anchorEl, contactId, onClose }) => {
  const { movementLocation } = getActionLastArrival(portCall.actions);
  const dataStoreContext = useContext(DataStoreContext);
  const { tariffBooks } = useTariffBooks();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  const [departure, setDeparture] = useState(() => {
    // default to last arrival
    const lastLifecycleAction = getActionLastLifecycle(portCall.actions);
    const timePlanned = add(new Date(getActionTime(lastLifecycleAction)), { hours: 1 }).toISOString();
    // try to get template first
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_DEPARTURE);
    if (template) {
      // FIXME remove isTariffBookEnabled, and undefined param when feature flag removed
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, ActionState.REQUESTED, isTariffBookEnabled), updated => {
        updated.state = ActionState.REQUESTED;
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.movementLocation = movementLocation;
        updated.actionPortCallId_ = portCall.id;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: ActionState.REQUESTED,
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.DEPARTURE,
        timePlanned,
        movementLocation: movementLocation,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
      });
    }
  });
  const [ remarks, setRemarks ] = useState('');
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();

  const prevActionTime = useMemo(() => getActionTime(getActionLastLifecycle(portCall.actions, departure)), [portCall.actions, departure.timePlanned]);
  const nextAction = useMemo(() => getActionableNext(portCall.actions, departure), [portCall.actions, departure.timePlanned]);

  // if there is a next action
  // or departure timePlanned is before previous action
  // return error message
  const checkDepartureTimePlanned = () => {
    const timeBefore = prevActionTime && add(new Date(prevActionTime), { minutes: 1});
    const departureTime = new Date(departure.timePlanned);
    if(Boolean(nextAction) && timeBefore && isBefore(departureTime, timeBefore)) return t('AgentDepartureCreateDialog.Errors.DepartureAfterArrival')
    return;
  }
  // there should be a valid time planned
  // check the departure time
  const departureError = useMemo(() =>
    (Boolean(!departure.timePlanned) && t('PortCall.Errors.InvalidDateFormat') ) || checkDepartureTimePlanned(), 
    [nextAction, prevActionTime, departure.timePlanned]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangeDepartureTime = (value) => {
    setDeparture(Action.copyOf(departure, updated => { updated.timePlanned = value; }));
  };

  const handleChangeRemarks = value => {
    setRemarks(value.currentTarget.value);
  };

  const handleCreateDeparture = async () => {
    const todos = await getActionTodosAsync(departure);
    let chargeableItems = departure.chargeableItems;
    // FIXME remove if and set chargableItems when feature flag removed
    if(isTariffBookEnabled) {
      chargeableItems = createAllChargeablesItemFromBooks(
        departure.timePlanned,
        departure.type.id,
        departure.movementType,
        tariffBooks,
        dataStoreContext.tariffUnits);
    }
    await DataStore.save(Action.copyOf(departure, updated => {
      updated.todos = todos;
      updated.chargeableItems = chargeableItems;
      updated.auditMeta = auditMeta;
    }));
    const lastPortCall = await DataStore.query(PortCall, portCall.id);
    const agent = await getAgentContact(contactId);
    const requestSource = await buildRequestSource(agent);

    await DataStore.save(new Request({
      portCall: lastPortCall,
      requestPortCallId_: lastPortCall.id,
      state: RequestState.REQUEST_STATE_PENDING,
      type: RequestType.REQUEST_TYPE_CREATE_DEPARTURE,
      source: requestSource,
      portCallData: {
        vesselName: portCall.vesselData.name,
        vesselImo: portCall.vesselData.imo,
        vesselMmsi: portCall.vesselData.mmsi,
        vesselCallsign: portCall.vesselData.callSign
      },
      actionData: [{
        actionId: departure.id,
        actionTypeId: departure.type.id,
        movementType: departure.movementType,
        timeRequested: departure.timePlanned,
        timePlanned: departure.timePlanned,
        locationId: departure.movementLocation.id,
        approved: RequestState.REQUEST_STATE_PENDING
      }],
      agent: agent,
      requesterUserName: await getUserName(),
      requesterEmail: await getUserEmail(),
      remark: remarks,
      auditMeta: {...auditMeta, comment: remarks}
    }));

    handleClose();
  };


  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            {/* Title  */}
            <Typography variant="h5">{t('AgentDepartureCreateDialog.Labels.NewDeparture')}</Typography>
            {/* Close  */}
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box display="flex" height="5rem" marginBottom='1.5rem'>
            {/* Time Picker */}
            <Box width='100%' mt={'0.25rem'} mb={'1rem'}>
              <StringKeyboardDateTimePicker
                warningPastDate={true}
                label={t('PortCall.Labels.PlannedTimeOfDeparture')}
                value={departure.timePlanned}
                onChange={handleChangeDepartureTime}
                error={Boolean(departureError)}
                helperText={departureError}
                okLabel={t('Common.Buttons.Confirm')}
              />
            </Box>
            {/* Berth Location */}
            <Box width='100%' ml={'1rem'} mr={'1rem'}>
              <TextField
                variant="outlined"
                label={t('PortCall.Labels.DepartureLocation')}
                fullWidth
                margin="normal"
                disabled
                value={movementLocation ? movementLocation.name : ''}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  },
                  startAdornment:
                    <>
                      {movementLocation && <MoveDepart color="primary" />}
                    </>
                }}
              />
            </Box>
          </Box>
          <TextField
            style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
            variant="outlined"
            label={t('AgentDepartureCreateDialog.Labels.Remarks')}
            fullWidth
            margin='normal'
            value={remarks}
            onChange={handleChangeRemarks}
          />
          {/* Create Button */}
          <Box display="flex" justifyContent="flex-end" alignItems="center" m={'1rem'}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!!departureError}
              onClick={handleCreateDeparture}
            >
              {t('Common.Buttons.Request')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

AgentDepartureCreateDialog.propTypes = {
  portCall: PropTypes.object,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
}

export default AgentDepartureCreateDialog;
