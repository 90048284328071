import { useState, useEffect, useCallback } from 'react';

/**
 * Handles initialization, saving/loading of data browser filters
 * @param {Object} options
 * @param {string} options.key Settings key
 * @param {string} options.initialValue Default filter values
 * @param {string} options.hydrateFunctions async functions that to parse loaded data before assignment
 * NOTE: loaded field gets culled if initialValue is missing
 */
const useDataBrowserFilter = (options) => {
  const { key, initialValue, hydrateFunctions } = options;
  const [filter, setFilter] = useState(initialValue);
  const _setFilter = useCallback((value) => {
    setFilter(value);
  }, []);

  // filter load on mount
  useEffect(() => {
    (async () => {
      const preferences = JSON.parse(localStorage.getItem(key));
      // initialize or rehydrate filter
      if (preferences?.filter) {
        let newFilter = {};
        for (let k of Object.keys(initialValue)) {
          newFilter[k] = (hydrateFunctions[k] && preferences.filter[k] && (!Array.isArray(preferences.filter[k]) || Boolean(preferences.filter[k].length)))
            ? await hydrateFunctions[k](preferences.filter[k])
            : initialValue[k]
        }
        setFilter(newFilter);
      }
    })();
  }, [setFilter, initialValue]);

  // filter update on change
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({ filter }));
  }, [filter]);

  return [filter, _setFilter];
};

export default useDataBrowserFilter;