import { View } from '@aws-amplify/ui-react';
import { Typography } from '@material-ui/core';
import { LOGIN_CONSTANTS } from '../constants';

export const ResetPassWordHeader = () => {

  return (
    <View className="ResetPassWordHeader">
    <Typography variant="h5">{LOGIN_CONSTANTS.Authentication.Common.Labels.Verification}</Typography>
      <Typography variant="h6">{LOGIN_CONSTANTS.Authentication.Common.Labels.SendVerification}</Typography>
    </View>
  );
}