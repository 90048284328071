import { Avatar, Box, CardContent, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, IconButton, InputBase, makeStyles, Popover, Typography } from '@material-ui/core';
import React, { useState, useContext, useMemo, useRef } from 'react';
import { Magnify, SortAscending, SortDescending, Close, MenuDown, Calendar } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import VesselAutocomplete from '../Vessel/VesselAutocomplete';
import { PortCallStatus, PortCallTag, Category, CategoryType, LocationType } from '../../models';
import { PortCallStatusLabelsKeys } from '../../constants/PortCallStatus';
import isEqual from 'lodash.isequal';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import { endOfDay, startOfDay } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { Autocomplete } from '@material-ui/lab';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { portCallTagFilterOptions, categoryFilterOptionsWithUncategorized, SORT_DIRECTION } from '../../utils/sorters';
import useQuery from '../../hooks/useQuery';
import CertificateTypeAutocomplete from '../Vessel/CertificateTypeAutocomplete';

const useStyles = makeStyles(theme => ({
  list: {
    '& > *:not(:first-child)': {
      marginTop: "0.75rem"
    }
  },
  chipLabel: {
    padding: 0,
    width: "100%",
    alignItems: "center",
    display: "flex",
  },
  multiRoot: {
    height: "inherit"
  },
  inputRoot: {
    padding: '0 0.75rem !important',
    width: '100%'
  },
  startAdornment: {
    paddingRight: '0.75rem',
    opacity: 0.5,
    pointerEvents: 'none',
    flexShrink: 0,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    height: '2rem'
  },
  vesselFlag: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.5rem'
  },
  popover: {
    width: "14rem"
  },
}));

/**
 * Data browser sidebar
 * @param {Object} props
 * @param {string} props.children
 * @param {string} props.label
 */
const DataBrowserSidebar = (props) => {
  const { label, children, exportButton } = props;
  const classes = useStyles();
  return (
    <Box borderRight="0.0625rem solid #e0e0e0" p="0 1rem" flex="0 0 18rem">
      <Box display="flex" pl="0.75rem" pr=".5rem" height="2.5rem" alignItems="center" justifyContent="space-between">
        <Typography variant="overline">
          {label}
        </Typography>
        {exportButton}
      </Box>
      <Box display="flex" flexDirection="column" className={classes.list}>
        {children}
      </Box>
    </Box>
  );
};

export default DataBrowserSidebar;

export const DataBrowserVesselFilter = ({ filter, setFilter, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ label: classes.chipLabel }}
      clickable
      label={
        <VesselAutocomplete
          fullWidth
          value={filter.vessel}
          onChange={value => setFilter({ ...filter, vessel: value ? value : null })}
          label={t('Vessel.Labels.Name')}
          autoHighlight
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={params.inputProps}
              classes={{ root: classes.inputRoot }}
              startAdornment={
                <>
                  <Typography variant="caption" className={classes.startAdornment}>{t('PortCallList.Labels.Vessel')}</Typography>
                  {filter?.vessel?.vesselData?.flag && <Avatar className={classes.vesselFlag} src={`${process.env.PUBLIC_URL}/flags/${filter.vessel.vesselData.flag.trim().toLowerCase()}.svg`} />}
                </>
              }
              endAdornment={filter?.vessel
                ? <IconButton size="small" onClick={e => { setFilter({ ...filter, vessel: null }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Magnify fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          )}
        />
      }
    />
  )
}

export const DataBrowserCertificateTypeFilter = ({ name, typesName, allowedCategories, filter, setFilter, isCategory, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const value = filter[name];
  // sets value and filters out typesName array if name is provided (e.g. category gets set and paired types filter needs updating)
  const setValue = value => {
    const types = value.length && typesName && { [typesName]: filter[typesName].filter(ct => value.some(cc => cc.category?.id === ct.certificateTypeCategoryId)) };
    setFilter({ ...filter, [name]: value, ...types });
  };
  const label = isCategory ? t('CertificateType.Labels.CertificateCategory') : t('CertificateType.Labels.CertificateType');
  const filterFunc = useMemo(() => isCategory 
    ? (ct => !ct.certificateTypeCategoryId) 
    : (ct => ct.certificateTypeCategoryId && (!(allowedCategories?.length) || allowedCategories.some(cc => ct.category?.id === cc.certificateTypeCategoryId))), [isCategory, allowedCategories]);
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ root: classes.multiRoot, label: classes.chipLabel }}
      clickable
      label={
        <CertificateTypeAutocomplete
          filter={filterFunc}
          fullWidth
          multiple
          filterSelectedOptions
          value={value}
          onChange={value => setValue(value ? value : [])}
          label={label}
          autoHighlight
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={params.inputProps}
              classes={{ root: classes.inputRoot }}
              startAdornment={
                <>
                  <Typography variant="caption" className={classes.startAdornment}>{label}</Typography>
                  {params.InputProps.startAdornment}
                </>
              }
              endAdornment={Boolean(value?.length)
                ? <IconButton size="small" onClick={() => setValue([])} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Magnify fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          )}
        />
      }
    />
  )
}

export const selectablePortCallStatuses = Object.keys(PortCallStatus)
  .filter(s => s !== PortCallStatus.DELETED && s !== PortCallStatus.SUPERSEDED)
  .sort((a, b) => a > b ? 1 : -1);

export const DataBrowserPortCallStatusFilter = ({ filter, setFilter, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation()
  return (
    <>
      <Chip 
        {...props}
        disableRipple
        classes={{ label: `${classes.chipLabel} ${classes.inputRoot}` }}
        ref={ref}
        label={<>
          <Typography component="div" className={classes.startAdornment} variant="caption" style={{ textTransform: "uppercase" }}>{t('PortCallList.Labels.Status')}</Typography>
          {isEqual(selectablePortCallStatuses, filter.status)
            ? <Typography style={{ whiteSpace: "normal", padding: ".25rem 1rem .25rem 0" }}>{t('PortCallList.Labels.ShowAll')}</Typography>
            : <Typography variant="body2" style={{ whiteSpace: "normal", padding: ".25rem 1rem .25rem 0" }}>{filter.status.map(s => t(PortCallStatusLabelsKeys[s])).join(", ")}</Typography>
          }
          {filter.status.length !== selectablePortCallStatuses.length
            ? <IconButton size="small" onClick={e => { e.stopPropagation(); setFilter({ ...filter, status: selectablePortCallStatuses }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
            : <MenuDown fontSize="small" style={{ position: "absolute", right: ".75rem", opacity: .5 }} />
          }
        </>
        }
        clickable
        onClick={() => setOpen(true)}
      />
      <Popover 
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <CardContent className={classes.popover}>
          <FormControl 
            id="PortCallStatusFilter"
            component="fieldset"
            fullWidth
          >
            <FormGroup>
              {selectablePortCallStatuses
                .map(status => (
                  <FormControlLabel
                    id={`PortCallStatusFilter-${status}`}
                    key={status}
                    control={
                      <Checkbox 
                        checked={filter.status && filter.status.some(s => s === status)}
                        onChange={e => (filter.status.length > 1 || e.currentTarget.checked) &&
                          setFilter({
                            ...filter, status: e.currentTarget.checked
                              ? [...filter.status, status].sort((a, b) => a > b ? 1 : -1)
                              : filter.status.filter(s => s !== status)
                          })
                        }
                        value={status}
                      />
                    }
                    label={t(PortCallStatusLabelsKeys[status])}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </CardContent>
      </Popover>
    </>
  );
}

export const DataBrowserPortCallSortDirectionFilter = ({ filter, setFilter, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ label: `${classes.chipLabel} ${classes.inputRoot}` }}
      label={<>
        <Typography component="div" className={classes.startAdornment} variant="caption" style={{ textTransform: "uppercase" }}>{t('PortCallList.Labels.Order')}</Typography>
        <Typography >{filter.sortDirection === SORT_DIRECTION.ASC ? t('PortCallList.Labels.OldestFirst') : t('PortCallList.Labels.NewestFirst')}</Typography>
        {filter.sortDirection === SORT_DIRECTION.ASC ? <SortAscending fontSize="small" style={{ position: "absolute", right: ".75rem", opacity: .5 }} /> : <SortDescending fontSize="small" style={{ position: "absolute", right: ".75rem", opacity: .5 }} />}
      </>
      }
      clickable
      onClick={() => setFilter({ ...filter, sortDirection: filter.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC })}
    />
  )
}

export const DataBrowserLocationFilter = ({ filter, setFilter, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ label: classes.chipLabel }}
      clickable
      label={
        <LocationAutocomplete
          fullWidth
          autoSelectSingle={false}
          popupIcon={null}
          filter={(l) => l.type === LocationType.PORT}
          value={filter.location}
          getOptionLabel={(item) => item.name}
          onChange={(value) => { setFilter({ ...filter, location: value ? value : null }) }}
          renderOption={null}
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={params.inputProps}
              classes={{ root: classes.inputRoot, input: classes.locationInput }}
              startAdornment={
                <>
                  <Typography variant="caption" className={classes.startAdornment}>{t('PortCallList.Labels.Port')}</Typography>
                </>
              }
              endAdornment={filter.location
                ? <IconButton size="small" onClick={e => { e.stopPropagation(); setFilter({ ...filter, location: null }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Magnify fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          )}
        />
      }
    />
  )
}

export const DataBrowserDateFilter = ({ filter, setFilter, name, label, isEndOfDay = false, ...props }) => {
  const classes = useStyles();
  const { dateTimeFormat } = useDateTimeSetting();
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ label: classes.chipLabel }}
      clickable
      label={
        <DatePicker
          autoOk
          disableToolbar
          variant="inline"
          format={dateTimeFormat}
          value={filter[name]}
          onChange={value => setFilter({ ...filter, [name]: isEndOfDay ? endOfDay(value) : startOfDay(value) })}
          TextFieldComponent={params =>
            <InputBase
              inputRef={params.inputRef}
              onClick={params.onClick}
              onKeyDown={params.onKeyDown}
              value={params.value}
              classes={{ root: classes.inputRoot }}
              startAdornment={<Typography component="div" variant="caption" className={classes.startAdornment}>{label}</Typography>}
              endAdornment={filter[name]
                ? <IconButton size="small" onClick={e => { e.stopPropagation(); setFilter({ ...filter, [name]: null }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Calendar fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          }
        />
      }
    />
  )
}

export const isUncategorizedCategory = (c) => Boolean(c?.description === "UNCATEGORIZED");
export const getUncategorizedCategory = () => new Category({
  name: '',
  description: "UNCATEGORIZED", // use this for matching as label may be localized
  type: CategoryType.PORTCALL
})

export const DataBrowserCategoryFilter = ({ filter, setFilter, name, label, isEndOfDay = false, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const { categories } = useContext(DataStoreContext);
  const categoriesCustom = useMemo(() => [getUncategorizedCategory(), ...categories], [categories, t]);
  const value = useMemo(() => isUncategorizedCategory(filter.category) ? getUncategorizedCategory() : filter.category, [filter.category, t]);
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ label: classes.chipLabel }}
      clickable
      label={
        <Autocomplete
          // freeSolo
          fullWidth
          autoHighlight
          popupIcon={null}
          options={categoriesCustom}
          filterOptions={categoryFilterOptionsWithUncategorized}
          getOptionLabel={(item) => isUncategorizedCategory(item) ? t('PortCallList.Labels.Uncategorised') : item.name}
          onChange={(e, value) => { setFilter({ ...filter, category: value ? value : null }); e.stopPropagation(); }}
          value={value}
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={params.inputProps}
              classes={{ root: classes.inputRoot, input: classes.categoryInput }}
              startAdornment={
                <>
                  <Typography variant="caption" className={classes.startAdornment}>{t('PortCallList.Labels.Category')}</Typography>
                </>
              }
              endAdornment={filter.category
                ? <IconButton size="small" onClick={e => { e.stopPropagation(); setFilter({ ...filter, category: null }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Magnify fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          )}
        />
      }
    />
  )
}

export const DataBrowserTagsFilter = ({ filter, setFilter, name, label, isEndOfDay = false, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const portCallTags = useQuery(PortCallTag, { id: "PortCallList.PortCallTag" });
  // Autocomplete doesn't want to filter entries automatically
  const portCallTagsFiltered = useMemo(() => portCallTags.filter(t => !filter.tags.some(t2 => t2.id === t.id)), [portCallTags, filter.tags]);
  return (
    <Chip
      {...props}
      disableRipple
      classes={{ root: classes.multiRoot, label: classes.chipLabel }}
      clickable
      label={
        <Autocomplete
          classes={filter.tags.length ? { clearIndicator: classes.clearIndicator } : {}}
          fullWidth
          multiple
          options={portCallTagsFiltered}
          filterOptions={portCallTagFilterOptions}
          getOptionLabel={(item) => item.name}
          filterSelectedOptions
          popupIcon={null}
          onChange={(e, value) => setFilter({ ...filter, tags: value })}
          value={filter.tags}
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={params.inputProps}
              classes={{ root: classes.inputRoot }}
              variant="outlined"
              startAdornment={
                <>
                  <Typography variant="caption" className={classes.startAdornment}>{t('PortCallList.Labels.Tags')}</Typography>
                  {params.InputProps.startAdornment}
                </>
              }
              endAdornment={filter.tags.length
                ? <IconButton size="small" onClick={e => { e.stopPropagation(); setFilter({ ...filter, tags: [] }); }} className={classes.endAdornment}><Close fontSize="small" /></IconButton>
                : <Magnify fontSize="small" style={{ opacity: 0.5 }} />
              }
            />
          )}
        />
      }
    />
  )
}