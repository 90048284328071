import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Box, ListItemText, List, ListItem, IconButton, ListItemSecondaryAction} from '@material-ui/core';
import VesselSearchForm from '../Vessel/VesselSearchForm';
import { AlertCircle, Close } from 'mdi-material-ui';
import VesselListItem from '../Vessel/VesselListItem';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'; 

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.4, 
    pointerEvents: 'none'
  },
  alertStyle: { 
    marginRight: '1rem', 
    color: 'rgba(0, 0, 0, 0.54)'
  },
  listStyle: {
    overflowY: "auto",
    flexGrow: 1
  }
}));

const VesselListEditor = ({
  vesselList, 
  onUpdate, 
  getVesselValue, 
  disabled, 
  displayVesselValueIfNotFound, 
  acceptDuplicate, 
  validateVesselValue
}) => {
  const { vessels } = useContext(DataStoreContext);
  const classes = useStyles();
  const [vList, setVList] = useState([])
  const { t } = useTranslation();

  const handleSetVessel = useCallback((vessel) => {
    if(!acceptDuplicate && vesselList?.find(v => getVesselValue(vessel) === v)){
      return;
    }
    if(validateVesselValue){
      if(!validateVesselValue(getVesselValue(vessel))) {
        return;
      }
    }
    onUpdate([getVesselValue(vessel), ...vesselList])
  }, [vesselList])

  const handleRemoveVessel = useCallback((vesselItem) => {
    onUpdate(vesselList.filter((vessel) => vessel !== vesselItem))
  }, [vesselList]);

  useEffect(() => {
    const vL = [];
    for(const vessel of vesselList) {
      const foundVessel = vessels.find(v => getVesselValue(v) === vessel);
      if(foundVessel) {
        vL.push(foundVessel)
      }else if(displayVesselValueIfNotFound) {
        vL.push(vessel)
      }
    }
    setVList(vL)
  }, [vesselList])

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <VesselSearchForm
        vessel={null}
        setVessel={handleSetVessel}
        allowCreateVessel
        hideUnknownVesselCheckbox={true}
        disabled={disabled}
        clearVessel={() => {}}
        disablePortcallUpdate={true}
        hideTitle={true}
      />

      {vList.length === 0 &&
        (
        <Box>
          <ListItem disabled={disabled}>
            <AlertCircle className={classes.alertStyle} />
            <ListItemText 
              primary={t('AdminAutomation.Labels.NoVesselsSpecified')} 
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
        </Box>)
      }

      <Box overflow='auto' display="flex" flexDirection="column" flexGrow="1" className={disabled ? classes.disabled : null}>
        <List  className={classes.listStyle}>
          {vList.map((vessel) => (
              <ListItem key={vessel?.id ?? vessel} button disableRipple disabled={disabled}>
                {vessel?.vesselData 
                  ? <VesselListItem vesselData={vessel.vesselData} disableGutters />
                  : (displayVesselValueIfNotFound ? <ListItem component="div">{vessel}</ListItem> : null)
                }
                <ListItemSecondaryAction>
                  <IconButton onClick={() => {handleRemoveVessel(getVesselValue(vessel) ?? vessel)}}><Close fontSize="small" /></IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              ))}
        </List>
      </Box>  
    </Box>
  )
};

export default VesselListEditor;