
import { useContext, useMemo, useState, useEffect, useCallback } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { Setting } from '../models';
import SettingConstants from '../constants/SettingConstants';

/** 
 * This hooks returns the ais status setting for the application. This includes 
 * an object(map) of thresholds/colors for each receiver. 
 * returns {
 *  aisThresholdMap, 
    updateAisStatusSetting
 * }
*/
const useAISReceiverStatusSettings = () => {
  
  const { settings } = useContext(DataStoreContext);

  const [aisThresholdMap, setAisThresholdMap] = useState(undefined);

  const aisStatusSetting = useMemo(() => settings?.find(s => s.name === SettingConstants.AIS_RECEIVER_STATUS), [settings]);

  useEffect(() => {
    try{
      const settingValue = aisStatusSetting ? JSON.parse(aisStatusSetting.value) : undefined;
      setAisThresholdMap(settingValue?.aisThresholdMap ?? {});
    }catch(err){
      setAisThresholdMap({});
    }
  }, [aisStatusSetting])

  const updateAisStatusSetting = useCallback((newAisThresholdMap) => {
    const aisStatusSettingValue = JSON.stringify({
      aisThresholdMap: newAisThresholdMap
    })
    DataStore.save(aisStatusSetting
      ? Setting.copyOf(aisStatusSetting, updated => { updated.value = aisStatusSettingValue })
      : new Setting({ name: SettingConstants.AIS_RECEIVER_STATUS, value: aisStatusSettingValue })
    );
  }, [aisStatusSetting])

  return {
    aisThresholdMap, 
    updateAisStatusSetting
  }
}

export default useAISReceiverStatusSettings