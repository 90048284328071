import React from 'react';
import { Typography, TextField, InputAdornment, Box, FormHelperText } from '@material-ui/core';
import { TariffData } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { useTranslation } from 'react-i18next';
import useAISStatus from '../../components/Map/hooks/useAISStatus';
import useAISReceiverStatusSettings from '../../hooks/useAISReceiverStatusSettings';
import { formatDuration } from 'date-fns';
import { DateFnsLanguageMap } from '../../translations';

const HOURS_MAX = 60 * 24 * 30; // 30 days
const MINUTES_MAX = 59;

const AdminAISStatusMonitoringHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('AIS.Labels.ReceiverName')} flexBasis="40%" />
      <AdminModelEditorHeaderColumn label={t('AIS.Labels.Status')} flexBasis="20%" />
      <AdminModelEditorHeaderColumn label={t("AIS.Labels.WarningThreshold")} flexBasis="20%" />
      <AdminModelEditorHeaderColumn label={t("AIS.Labels.CriticalThreshold")} flexBasis="20%" />
    </>
  );
};

const AdminAISStatusMonitoringListItemContent = ({ value }) => {
  const { i18n } = useTranslation();
  const warningValue = formatDuration({
    hours: parseInt(value.thresholds.WARNING.range[0] / 60),
    minutes: value.thresholds.WARNING.range[0] % 60,
  }, { locale: DateFnsLanguageMap[i18n.language] });
  const criticalValue = formatDuration({
    hours: parseInt(value.thresholds.CRITICAL.range[0] / 60),
    minutes: value.thresholds.CRITICAL.range[0] % 60,
  }, { locale: DateFnsLanguageMap[i18n.language] })
  return (
    <>
      <Typography noWrap style={{ flexBasis: "40%" }}>{value.id}</Typography>
      <Box flexBasis="20%">
        <Box style={{ width: "1rem", height: "1rem", background: value.color, borderRadius: "100%" }} />
      </Box>
      <Typography variant="caption" noWrap style={{ flexBasis: "20%", opacity: 0.5 }}>{warningValue}</Typography>
      <Typography variant="caption" noWrap style={{ flexBasis: "20%", opacity: 0.5 }}>{criticalValue}</Typography>
    </>
  );
}

const AdminAISStatusMonitoringFormContent = ({ value, onChangeRaw }) => {
  const { t } = useTranslation();
  // parse values to hours/minutes
  const values = {
    WARNING: {
      hours: parseInt(value.thresholds.WARNING.range[0] / 60),
      minutes: value.thresholds.WARNING.range[0] % 60,
    },
    CRITICAL: {
      hours: parseInt(value.thresholds.CRITICAL.range[0] / 60),
      minutes: value.thresholds.CRITICAL.range[0] % 60,
    }
  };
  const buildValue = (warningValue, criticalValue) => ({
    ...value,
    thresholds: {
      ...value.thresholds,
      GOOD: { 
        ...value.thresholds.GOOD,
        range:[0, warningValue - 1],
      },
      WARNING: { 
        ...value.thresholds.WARNING,
        range:[warningValue, criticalValue - 1],
      },
      CRITICAL: { 
        ...value.thresholds.CRITICAL,
        range:[criticalValue],
      }
    }
  })
  // calculate minutes and reconstruct objects
  const onChangeHours = (threshold, v) => {
    if (v < 0 || v > HOURS_MAX) return; // 1 month
    const newValue = values[threshold].minutes + (parseInt(v) || 0) * 60;
    onChangeRaw(buildValue(
      threshold  === "WARNING" ? newValue : value.thresholds.WARNING.range[0],
      threshold  === "CRITICAL" ? newValue : value.thresholds.CRITICAL.range[0]
    ));
  };
  const onChangeMinutes = (threshold, v) => {
    if (v < 0 || v > MINUTES_MAX) return;
    const newValue = values[threshold].hours * 60 + (parseInt(v) || 0);
    onChangeRaw(buildValue(
      threshold  === "WARNING" ? newValue : value.thresholds.WARNING.range[0],
      threshold  === "CRITICAL" ? newValue : value.thresholds.CRITICAL.range[0]
    ));
  };
  const error = value.thresholds.WARNING.range[0] >= value.thresholds.CRITICAL.range[0];
  return (
    <>
      <TextField
        label={t("AIS.Labels.ReceiverName")}
        variant="outlined"
        value={value.id}
        InputLabelProps={{
          shrink: true
        }}
        disabled
        fullWidth
      />
      <Box height="2rem" />

      <Typography variant="h6" gutterBottom>{t("AIS.Labels.ThresholdSettings")}</Typography>

      <Box display="flex">
        <TextField
          id="WarningThresholdHoursInput"
          label={t("AIS.Labels.WarningThreshold")}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={values.WARNING.hours.toString()}
          onChange={e => onChangeHours("WARNING", e.target.value)}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment: <InputAdornment position="end" disablePointerEvents>{t("Common.Labels.Hours").toLowerCase()}</InputAdornment>
          }}
          type="number"
        />
        <Box width="1rem" />
        <TextField
          id="WarningThresholdMinutesInput"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={values.WARNING.minutes.toString()}
          onChange={e => onChangeMinutes("WARNING", e.target.value)}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment: <InputAdornment position="end" disablePointerEvents>{t("Common.Labels.Minutes").toLowerCase()}</InputAdornment>
          }}
          type="number"
        />
      </Box>
      <FormHelperText variant='outlined'>{t("AIS.Labels.WarningThresholdHelp")}</FormHelperText>

      <Box height="1rem" />

      <Box display="flex">
        <TextField
          id="CriticalThresholdHoursInput"
          label={t("AIS.Labels.CriticalThreshold")}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={values.CRITICAL.hours.toString()}
          onChange={e => onChangeHours("CRITICAL", e.target.value)}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment: <InputAdornment position="end" disablePointerEvents>{t("Common.Labels.Hours").toLowerCase()}</InputAdornment>
          }}
          type="number"
          error={error}
        />
        <Box width="1rem" />
        <TextField
          id="CriticalThresholdMinutesInput"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={values.CRITICAL.minutes.toString()}
          onChange={e => onChangeMinutes("CRITICAL", e.target.value)}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment: <InputAdornment position="end" disablePointerEvents>{t("Common.Labels.Minutes").toLowerCase()}</InputAdornment>
          }}
          type="number"
          error={error}
        />
      </Box>
      <FormHelperText error={error} variant='outlined'>{error ? t("AIS.Labels.CriticalThresholdError") : t("AIS.Labels.CriticalThresholdHelp")}</FormHelperText>
    </>
  );
};

export default () => {
  // this thing keeps setting up to date (or in case the user hasn't opened the map yet)
  const { receiversStatus, getColor } = useAISStatus();
  const { aisThresholdMap, updateAisStatusSetting } = useAISReceiverStatusSettings();
  const items = aisThresholdMap ? Object.keys(aisThresholdMap).map(key => {
    const lastTimestamp = receiversStatus && receiversStatus.find(r => r.receiverName === key)?.lastMessage[0]?.lastTimestamp;
    return {
      id: key,
      color: lastTimestamp && getColor(key, lastTimestamp),
      thresholds: aisThresholdMap[key]
    };
  }) : [];
  return <AdminModelEditor
    HeaderContent={AdminAISStatusMonitoringHeaderContent}
    ListItemContent={AdminAISStatusMonitoringListItemContent}
    FormContent={AdminAISStatusMonitoringFormContent}
    items={items}
    sort={(a, b) => (a.id > b.id) ? 1 : -1}
    canUpdate={() => true}
    canDelete={() => false}
    canSave={i => i.thresholds.WARNING.range[0] < i.thresholds.CRITICAL.range[0]}
    onSave={i => {
      updateAisStatusSetting({ ...aisThresholdMap, [i.id]: i.thresholds})
    }}
    canAdd={() => false}
    defaultItem={() => {
      return {
        data: new TariffData({
          minimumCost: null,
          unitCost: null
        })
      }
    }}
  />
}
