import React, { useEffect, useMemo, useState } from 'react';
import { Typography, TextField, Box, CardContent, Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import { ArrowLeft } from 'mdi-material-ui';
import { Contact } from '../../models';
import { DataStore } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '85rem',
    margin: '0 auto'
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '12rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const ContactForm = ({ onChange, onBack, showTitle, contactType }) => {
  const { t } = useTranslation()
  const [contact, setContact] = useState(new Contact({}));
  // automatically set default contactType
  useEffect(() => {
    contactType && setContact(prev => {
      if (prev?.type?.id === contactType?.id) return prev;
      return Contact.copyOf(prev, updated => {
        updated.type = contactType;
      });
    });
  }, [setContact, contactType]);

  const handleChange = (e) => {
    const { name, value, maxLength } = e.currentTarget;
    if (maxLength > 0 && value.length > maxLength) return;
    setContact(Contact.copyOf(contact, updated => {
      updated[name] = value;
    }));
  };

  const handleCreate = async () => {
    const data = await DataStore.save(contact);
    console.log(contact, data);
    onChange && onChange(contact);
    onBack && onBack();
  };

  const nameError = useMemo(() => contact.name !== undefined && !contact.name.length && t('Agent.Errors.EmptyCompanyName'), [contact.name, t]);
  const numberError = useMemo(() => contact.number !== undefined && !contact.number.length && t('Agent.Errors.EmptyTelephoneNumber'), [contact.number, t]);
  const createDisabled = useMemo(() => !contact.name || !contact.number || nameError || numberError, [contact, nameError, numberError]);

  const classes = useStyles();
  return (
    <Box overflow="hidden auto" width="100%">
      <Grid container wrap="nowrap" direction="column" className={classes.root}>

        {showTitle &&
          <Grid item container justifyContent='center' alignItems="center" className={classes.header}>
            {onBack && <IconButton onClick={onBack} style={{ marginLeft: -59, position: 'relative', left: -32 }}><ArrowLeft fontSize="large" /></IconButton>}
            <Typography variant="h2">{t('Agent.Labels.CreateAgent')}</Typography>
          </Grid>
        }

        <Grid item xs={12}>
          <CardContent>
            <Typography variant="h5">{t('Agent.Labels.Name')}</Typography>
            <Box display="flex">
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Agent.Labels.CompanyName')}
                    margin="normal"
                    name="name"
                    value={contact.name || ''}
                    onChange={handleChange}
                    error={Boolean(nameError)}
                    helperText={nameError}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Agent.Labels.DisplayName')}
                    margin="normal"
                    name="displayName"
                    value={contact.displayName || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Reference')}
                    margin="normal"
                    name="reference"
                    value={contact.reference || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5">{t('Agent.Labels.ContactDetails')}</Typography>
            <Box display="flex">
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Agent.Labels.ContactName')}
                    margin="normal"
                    name="contacta_name"
                    value={contact.contacta_name || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.TelephoneNumber')}
                    margin="normal"
                    name="number"
                    value={contact.number || ''}
                    onChange={handleChange}
                    error={Boolean(numberError)}
                    helperText={numberError}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Agent.Labels.FaxNumber')}
                    margin="normal"
                    name="fax"
                    value={contact.fax || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Email')}
                    margin="normal"
                    name="email"
                    value={contact.email || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5">{t('Contact.Labels.Address')}</Typography>
            <Box display="flex">
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Address1')}
                    margin="normal"
                    name="address1"
                    value={contact.address1 || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Address2')}
                    margin="normal"
                    name="address2"
                    value={contact.address2 || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Town')}
                    margin="normal"
                    name="town"
                    value={contact.town || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.County')}
                    margin="normal"
                    name="county"
                    value={contact.county || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label={t('Contact.Labels.Postcode')}
                    margin="normal"
                    name="postcode"
                    value={contact.postcode || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Grid>

        <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.footer}>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              // dispatch(clearItem());
              onBack && onBack();
            }}
          >
            <Typography>{t('Common.Buttons.DiscardChanges')}</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={createDisabled}
            style={{ margin: '1rem' }}
            onClick={handleCreate}
            id="CreateContactButton"
          >
            {t('Common.Buttons.Create')}
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ContactForm;
