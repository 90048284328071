import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { executeTransaction } from "../graphql/mutations";

const doTransaction = async (updateItems) => {
  try {
    const ret = await API.graphql(graphqlOperation(executeTransaction, {
      request: {
        updateItems: updateItems
      }
    }));
    return ret;
  } catch (error) {
    console.error('TRANS: executeTransaction error', error);
    return {
      data: {
        executeTransaction: {
          statusCode: 400,
          errorCode: 1,
          errorMessage: `Failed to executeTransaction function ${error}`,
          data: undefined
        }
      }
    }
  }
};

const StatusCodeOK = 200;

const useTransactionMutation = () => {
  const [ update, setUpdate ] = useState({ loading: false, data: undefined, error: undefined });

  const updateMutation = async (updateItems, onSuccess) => {
    setUpdate({ loading: true, data: undefined, error: undefined });
    const result = await doTransaction(updateItems);
    setUpdate({ 
      loading: false, 
      data: result.data?.executeTransaction?.data, 
      error: result.data?.executeTransaction?.statusCode !== StatusCodeOK  ? {
        errorCode: result.data.executeTransaction?.errorCode, 
        errorMessage: result.data.executeTransaction?.errorMessage,
        statusCode: result.data.executeTransaction?.statusCode
      } : undefined
    });

    if (!result.data?.executeTransaction?.errorCode &&
      onSuccess && 
      typeof onSuccess === 'function') {
      onSuccess();
    }; 

    if (!onSuccess) {
      if (!result.data?.executeTransaction?.errorCode) {
        return true;
      } else
        return false; 
    };
  };

  const reset = () => {
    setUpdate({ loading: false, data: undefined, error: undefined });
  };

  return [ updateMutation, reset, { ...update } ];
};

export default useTransactionMutation;