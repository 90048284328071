import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import '../../translations/i18n';
import GenericRow from "./GenericRow";

const paddingLeft = '1rem'

const useStyles = makeStyles(theme => ({
  tableContainerWrapper: {
    marginBottom: '1rem'
  },
  tableWrapper: {
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  title: {
    background: theme.palette.primary.main,
    color: 'white',
    paddingLeft
  },
  headCell: {
    background: 'none',
    border: 'none',
    paddingBottom: '0.5rem !important'
  },
  firstCell: {
    paddingLeft
  },
  cell: {
    height: '3.5rem',
    borderBottom: 'none',
    color: theme.palette.grey[700],
    maxWidth: '300px',
    verticalAlign: 'top',
    paddingBottom: '1rem'
  },
  emphasisedLastRowCellsBase: {
    height: '5rem',
  },
  emphasisedLastRowCells: {
    fontSize: '1.25rem',
    fontWeight: 500,
    borderTop: `1px solid ${theme.palette.primary.main}`,
  }
}));

const GenericTable = ({
  title,
  columns,
  rows,
  emphasiseLastRow,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
 return (
  <Box className={classes.tableContainerWrapper}>
    <TableContainer>
    {title && <Typography 
    variant='h6'
    className={classes.title}
    >{title}</Typography>}
    <Box className={classes.tableWrapper}>
      <Table padding="none" stickyHeader>
          <TableHead>
            <TableRow>
              {columns
                .map(({id, label}, cellIndex) => (
                  <TableCell
                    key={id}
                    className={cellIndex === 0 ? [classes.headCell, classes.firstCell].join(' ') : classes.headCell}
                  >
                    {t(label)}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {rows 
                .map((row, index) =>
                <GenericRow 
                  key={index}
                  row={row}
                  columns={columns}
                  classes={classes}
                  isLastRow={index + 1 === rows.length}
                  emphasiseLastRow={emphasiseLastRow}
                />)
              }
          </TableBody>
        </Table>
    </Box>
    </TableContainer>
  </Box>
  );
}



export default GenericTable;