import React from 'react';
import { makeStyles, ListItem, ListItemText, Typography, Paper } from '@material-ui/core';
import { parseCargoManifest } from '../../utils/parseCargoManifest';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const CargoTooltip = ({ cargo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const manifestFilter = cargo.manifest.filter(c => c.deleted !== true)
  const manifest = cargo && manifestFilter ? manifestFilter : null;

  const { incoming, outgoing, other } = parseCargoManifest(manifest, cargo);

  const cargoFormatter = (item) => {
    return `${item.name}${item.amount ? `: ${ item.amount } ${ item.units ? t(item.units) : '' }` : ''}`;
  }

  return (
    <Paper className={classes.root}>
      {incoming.length
        ? <Typography variant="body2">{t('Cargo.Labels.IncomingCargo')}</Typography>
        : ''
      }
      {incoming.map(item =>
        <ListItem key={item.id}>
          <ListItemText
            primary={cargoFormatter(item)}
            primaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body1'
            }}
            secondary={item.comment ? item.comment : ''}
            secondaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body2'
            }}
          />
        </ListItem>
      )}
      {outgoing.length
        ? <Typography variant="body2">{t('Cargo.Labels.OutgoingCargo')}</Typography>
        : ''
      }
      {outgoing.map(item =>
        <ListItem key={item.id}>
          <ListItemText
            primary={cargoFormatter(item)}
            primaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body1'
            }}
            secondary={item.comment ? item.comment : ''}
            secondaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body2'
            }}
          />
        </ListItem>
      )}
      {other.length
        ? <Typography variant="body2">{t('Cargo.Labels.OtherCargo')}</Typography>
        : ''
      }
      {other.map(item =>
        <ListItem key={item.id}>
          <ListItemText
            primary={cargoFormatter(item)}
            primaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body1'
            }}
            secondary={item.comment ? item.comment : ''}
            secondaryTypographyProps={{
              component: 'p',
              noWrap: true,
              variant: 'body2'
            }}
          />
        </ListItem>
      )}
      {!incoming.length && !outgoing.length && !other.length
        ? <Typography variant="body1">{t('Cargo.Labels.NoCargo')}</Typography>
        : ''
      }
    </Paper>
  )
};

export default CargoTooltip;
