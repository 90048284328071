import React , {useEffect, useState, useContext, useMemo, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Map from 'ol/Map';
import View from 'ol/View';
import {defaults as defaultControls, ScaleLine, MousePosition} from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM'
import { fromLonLat } from 'ol/proj';
import { toStringHDMS } from 'ol/coordinate';

// import styles
import 'ol/ol.css';
import './OLMap.css';

// Local code
import { MapContext, setMap } from './contexts/map';
import { BaseLayerZIndex } from './constants';
import { debounce } from '../../utils/utils';


const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
});

/**
 * Load map settings from local storage
 * @param {Map} map OpenLayers map
 * @param {String} localStorageKey Storage key
 * @param {Object} options Save options
 */
const loadSetting = (map, localStorageKey, options) => {
  let settings = JSON.parse(localStorage.getItem(localStorageKey)) || null;
  if(settings) {
    options.restorePan && map.getView().setCenter(settings.center);
    options.restoreZoom && map.getView().setZoom(settings.zoom);
  }
};

/**
 * Save map settings to local storage
 */
const saveSettings = debounce(async (map, localStorageKey) => {
  localStorage.setItem(localStorageKey, JSON.stringify({
    zoom: map.getView().getZoom(),
    center: map.getView().getCenter()
  }));
}, 1000);

const OLMap = ({
  children,
  mapId='map',
  defaults,
  centerPosition,
  restorePan=true,
  restoreZoom=true,
}) => {

  const classes = useStyles();
   
  const baseSource = useMemo(()=>new OSM(),[]);
  const [, dispatch] = useContext(MapContext);

  const [ map ] = useState(
    new Map({
      controls: defaultControls().extend([
        new ScaleLine({
          units: "nautical",
          bar: true,
          steps: 4,
          text: true,
          minWidth: 140
        }),
        new MousePosition({
          coordinateFormat: toStringHDMS,
          projection: 'EPSG:4326',
        })
      ]),
      layers: [
        new TileLayer({
          source: new OSM(),
          zIndex: BaseLayerZIndex
        })
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: fromLonLat(defaults.center),
        zoom: defaults.zoom,
        resolutions: baseSource.getTileGrid().getResolutions()
      }),
      pixelRatio: 1,
      target: ''
    })
  );

  const localStorageKey = `smartport::preferences::${mapId}::view`

  useEffect(() => {
    loadSetting(map, localStorageKey, { restorePan, restoreZoom });

    dispatch(setMap(map));
    map.setTarget('map');
  
    // Save setting on change to pan or zoome
    (restorePan || restoreZoom) && map.getView().on([ 'change:center', 'change:resolution' ], () => {
      saveSettings(map, localStorageKey);
    });

    return () => {
      // Save on unmount
      (restorePan || restoreZoom) && saveSettings(map, localStorageKey);
      map.setTarget(undefined);
    };
  }, [map, dispatch, localStorageKey, restorePan, restoreZoom]);

  const centerOnFeature = useCallback((coordinates, zoom) => {
    map.getView().setCenter(coordinates);
  },[map]);

  useEffect(() => {
    centerPosition?.lon && centerPosition?.lat && centerOnFeature (
      fromLonLat([centerPosition?.lon, centerPosition?.lat]), 
      centerPosition?.zoom && defaults.zoom
    );
  }, [centerPosition, centerOnFeature, defaults.zoom]);

  // Expose map to the testing framework
  useEffect(() => {
    window.__E2E__.map = map;
  }, [map]);

  return (
    <>
      <div id="map" className={classes.map}>
        {children}
      </div>
    </>
  );
};

export default OLMap;