import React from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField, ListItem, ListItemText } from '@material-ui/core';
import { categoryFilterOptions } from '../../utils/sorters';
import { useContext } from 'react';
import { DataStoreContext } from '../../contexts/dataStoreContext';

export default ({ type, value, onChange, startAdornment, label, ...other }) => {
  const categories = useContext(DataStoreContext).categories.filter(c => !type || c.type === type);
  const filteredCategories = categories.filter(c => c.deleted !== true)
  
  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={filteredCategories}
      filterOptions={categoryFilterOptions}
      getOptionLabel={(item) => (item && item.name)}
      getOptionSelected={(option, value) => option.id === value.id}
      value={value || null}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={item.name} />
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment
          }}
        />
      )}
    />
  );
};
