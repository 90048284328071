import React , {useEffect, useContext, useState } from 'react';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import TileState from 'ol/TileState';
import { MapContext } from '../contexts/map';
import { LayerAttrs } from '../constants';

/**
 * Build a list of layers that ae considered always on.
 * Specifically these are layers that have no title or i18n string
 * @param {Array<Object} layers 
 * @returns {Array<String>} String array of layer IDs
 */
const alwaysVisibleLayers = (layers) => {
  return layers ? 
    layers.filter(el => !el.title && !el.i18n).map(el => el.id) : 
    [];
};

/**
 * Build a list of all layers that should be visible based on
 * user selection or settings in environment.js
 * The resulting array should have no duplicates. This is important otherwise
 * the WMS server will not return any data.
 * @param {Array<Object>} layers 
 * @param {Object} visibleLayersSettings 
 * @returns {Array<String>} String array of layer IDs
 */
const buildVisibleLayers = (layers, visibleLayersSettings) => {
  const always = alwaysVisibleLayers(layers);
  return [
    ...always, 
    ...visibleLayersSettings.filter(el => !always.find(el2 => el === el2))
  ];
};

/**
 * Return true if the OpenLayer layer should be visible based on layer selection
 * @param {Array<String>} layers List of IDs
 * @returns {Boolean} True or false
 */
const showLayer = (layers) => layers ? layers.length > 0 : false; 

/**
 * WMS layer component
 * Implements the functionality to display one or more WMS layer from the same source
 * If multiple layer are selected for display then all will be requested in the same GetMap call to the server
 * @param {Object} Props 
 * @returns Empty component - no visible UI, layer is attached to the map
 */
const WMSLayer = ({url, authHeader, layers, zIndex, attributions, visibleLayers}) => {
  const [ context ] = useContext(MapContext);
  const [ layer, setLayer ] = useState(null);

  useEffect(() => {
    const tileLoadFunction = (tile, src) => {
      const client = new XMLHttpRequest();
      client.responseType = 'blob';
      client.open('GET', src);
      client.setRequestHeader('Authorization', authHeader);
      client.onload = () => {
        const imageUrl = URL.createObjectURL(client.response);
        tile.getImage().onload = () => {
          URL.revokeObjectURL(imageUrl)
        }
        tile.getImage().src = imageUrl;
      };
      client.onerror = () => {
        tile.setState(TileState.ERROR);
      };
      client.send();
    };

    if(!context.map) return;
    
    layer && context.map.removeLayer(layer);

    const allVisibleLayers = buildVisibleLayers(layers, visibleLayers);
    const tileLayer = new TileLayer({
      source: new TileWMS({
        url: url,
        params: {
          'TILED': true,
          'LAYERS': allVisibleLayers.toString()
        },
        tileLoadFunction: authHeader ? tileLoadFunction : undefined,
        attributions: attributions, 
      }),
      visible: showLayer(allVisibleLayers),
      zIndex: zIndex
    });
    tileLayer.set([LayerAttrs.WMS_LAYERS], layers);
    setLayer(tileLayer);
    context.map.addLayer(tileLayer);

    return () => {
      if(context.map) {
        context.map.removeLayer(layer);
      }
    };

  }, [context.map, authHeader]);

  useEffect(() => {
    if(!layer) return;
    // Update the WMS source with the current list of active layers
    const allVisibleLayers = buildVisibleLayers(layers, visibleLayers)
    layer.getSource().updateParams({'LAYERS': allVisibleLayers.toString()});
    // If there are no layers listed then hide the WMS layer completely
    layer.setVisible(showLayer(allVisibleLayers))
  }, [visibleLayers]);

  return (<></>);
};

export default  WMSLayer;