import { DataStore } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { Action, ActionType, Cargo, CargoType, Category, Contact, ContactType, Location, PortCall, Request, Tariff, TariffUnit, Vessel, CertificateType } from '../models';
import { buildConnectionSubscription } from '../utils/utils';

/**
 * Note: Correct functioning requires both condition and filter props duplicating logic
 * 
 * condition: DataStore ProducerModelPredicate criteria for querying (faster than filtering)
 * filter: filter function for handling subscriptions (MUST BE USED TOGETHER WITH condition and should match it logically)
 * sort: sort function to use on Arrays 
 */

const useQuery = (model, options) => {
  const { condition, filter, sort } = options || {};
  const [items, setItems] = useState([]);
  useEffect(() => {
    // prevent leaks on unmounts
    let cancelled = { value: false };
    const update = async () => {
      var t0 = performance.now();
      let data = await DataStore.query(model, condition);
      if (filter) data = data.filter(filter);
      if (sort) data = data.sort(sort);
      var t1 = performance.now()
      // console.log(`Queried ${model.name} in ${Math.floor(t1 - t0)} milliseconds.`, options || '', JSON.stringify(options) || '');
      if (!cancelled.current) setItems(data);
    };
    update();
    // Provide criteria only o non-array queries (condition is ID), otherwise criteria may prevent us from receiving an important update
    const subscriptions = [];
    subscriptions.push(DataStore.observe(model, (typeof condition === "string") ? condition : undefined).subscribe(async msg => {
      if (cancelled.current) return;
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT") && (!filter || filter(element));

      // access latest data without triggering a re-render
      let item;
      setItems(prev => {
        item = Array.isArray(prev) ? prev.find(i => i.id === element.id) : prev;
        return prev; // this doesn't emit a state change
      });
      // check version
      if (item && item._version >= element._version) return;
      if (cancelled.current) return;

      let newItem = element;
      if (isUpdateOp) {
        // ---
        // Until DataStore provides shape parity between queries and subscriptions, manually reconstruct connections here
        // In subscriptions you can expect connection IDs that are not explicitly exposed in schema
        // Note: To find what's available you can navigate to AppSync -> Schema in AWS and check model inputs
        if (model === Action) {
          const portCall = newItem.actionPortCallId && await DataStore.query(PortCall, newItem.actionPortCallId);
          const actionAgent = newItem.actionActionAgentId && await DataStore.query(Contact, newItem.actionActionAgentId);
          const type = newItem.actionTypeId && await DataStore.query(ActionType, newItem.actionTypeId);
          const movementLocation = newItem.actionMovementLocationId && await DataStore.query(Location, newItem.actionMovementLocationId);
          newItem = Action.copyOf(newItem, updated => {
            if (portCall) updated.portCall = portCall;
            if (actionAgent) updated.actionAgent = actionAgent;
            if (type) updated.type = type;
            if (movementLocation) updated.movementLocation = movementLocation;
          });
        }
        else if (model === Cargo) {
          const type = newItem.cargoTypeId && await DataStore.query(CargoType, newItem.cargoTypeId);
          newItem = Cargo.copyOf(newItem, updated => {
            if (type) updated.type = type;
          });
        }
        else if (model === Contact) {
          const type = newItem.contactTypeId && await DataStore.query(ContactType, newItem.contactTypeId);
          newItem = Contact.copyOf(newItem, updated => {
            if (type) updated.type = type;
          });
        }
        else if (model === Location) {
          const parent = newItem.locationParentId && await DataStore.query(Location, newItem.locationParentId);
          newItem = Location.copyOf(newItem, updated => {
            if (parent) updated.parent = parent;
          });
        }
        else if (model === PortCall) {
          const category = newItem.portCallCategoryId && await DataStore.query(Category, newItem.portCallCategoryId);
          const vessel = newItem.portCallVesselId && await DataStore.query(Vessel, newItem.portCallVesselId);
          newItem = PortCall.copyOf(newItem, updated => {
            if (category) updated.category = category;
            if (vessel) updated.vessel = vessel;
          });
        }
        else if (model === Request) {
          const portCall = newItem.requestPortCallId && await DataStore.query(PortCall, newItem.requestPortCallId);
          const agent = newItem.requestAgentId && await DataStore.query(Contact, newItem.requestAgentId);
          newItem = Request.copyOf(newItem, updated => {
            if (portCall) updated.portCall = portCall;
            if (agent) updated.agent = agent;
          });
        }
        else if (model === Tariff) {
          const unit = newItem.tariffUnitId && await DataStore.query(TariffUnit, newItem.tariffUnitId);
          newItem = Tariff.copyOf(newItem, updated => {
            if (unit) updated.unit = unit;
          });
        }
        else if (model === Vessel) {
          const vesselAgent = newItem.vesselVesselAgentId && await DataStore.query(Contact, newItem.vesselVesselAgentId);
          newItem = Vessel.copyOf(newItem, updated => {
            if (vesselAgent) updated.vesselAgent = vesselAgent;
          });
        }
        else if (model === CertificateType) {
          const category = newItem.certificateTypeCategoryId && await DataStore.query(CertificateType, newItem.certificateTypeCategoryId);
          newItem = CertificateType.copyOf(newItem, updated => {
            if (category) updated.category = category;
          });
        }
        if (cancelled.current) return;
      }
      // ---

      setItems(prev => {
        let newItems = prev;
        if (isUpdateOp) {
          newItems = Array.isArray(prev)
            ? [...(item ? prev.filter(i => i.id !== newItem.id) : prev), newItem]
            : newItem;
        }
        else if (item) {
          newItems = Array.isArray(prev)
            ? prev.filter(i => i.id !== newItem.id)
            : null;
        }
        if (newItems !== prev && Array.isArray(newItems)) {
          if (filter) newItems = newItems.filter(filter);
          if (sort) newItems = newItems.sort(sort);
        }
        return newItems;
      });
    }));

    // Model specific subscriptions for handling connection updates
    if (model === Action) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "portCall", fieldModel: PortCall }));
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "actionAgent", fieldModel: Contact }));
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "type", fieldModel: ActionType }));
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "movementLocation", fieldModel: Location }));
    }
    else if (model === Cargo) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "type", fieldModel: CargoType }));
    }
    else if (model === Contact) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "type", fieldModel: ContactType }));
    }
    else if (model === Location) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "parent", fieldModel: Location }));
    }
    else if (model === PortCall) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "category", fieldModel: Category }));
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "vessel", fieldModel: Vessel }));
    }
    else if (model === Request) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "portCall", fieldModel: PortCall }));
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "agent", fieldModel: Contact }));
    }
    else if (model === Tariff) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "unit", fieldModel: TariffUnit }));
    }
    else if (model === Vessel) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "vesselAgent", fieldModel: Contact }));
    }
    else if (model === CertificateType) {
      subscriptions.push(buildConnectionSubscription({ cancelled, setItems, model, fieldName: "category", fieldModel: CertificateType }));
    }

    return () => {
      subscriptions.map(s => s.unsubscribe());
      cancelled.current = true;
    }
  }, [setItems]);
  return items;
};

export default useQuery;
