import React, {  } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { ListItem, ListItemText, TextField } from '@material-ui/core';
import { currencyFilterOptions } from '../../utils/sorters';
import useCurrencies from '../../hooks/useCurrencies';

export default ({ value, onChange, label, required, helperText, ...other }) => {
  const currencies = useCurrencies();
  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={currencies}
      filterOptions={currencyFilterOptions}
      getOptionLabel={(item) => (item && `${item.code} (${item.name})`)}
      value={value || null}
      getOptionSelected={(option, value) => option.code === value.code}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={item.symbol} secondary={`${item.code} (${item.name})`} secondaryTypographyProps={{ noWrap: true }} />
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          helperText={helperText}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
          InputProps={{
            ...props.InputProps,
          }}
        />
      )}
    />
  );
};
