import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Timer } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
const ICON_INDICATOR_MINUTES = 15;
const UPDATE_RATE_MILLISECONDS = 6000;
const SHOW_SLIPPAGE_DAYS_MIN = 2;

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      background: 'none'
    }
  },
  smallFontSize: {
    fontSize: '0.85rem'
  },
  icon: {
    paddingLeft: '0.25rem'
  },
  popover: {
    pointerEvents: 'none'
  },
  ahead: {
    color: green[500],
  },
  behind: {
    color: red[500],
  }
}));

const TimeEstimate = ({ requestedTime, requested, planned, actual, estimated, estimatedUpdatedAt, reverse }) => {
  //set the state to show timer icon , color fading when timer is closer to planned time
  const [opacity, setOpacity] = useState(0);
  const classes = useStyles();
  const time = requestedTime || actual || planned || requested;
  const timedayjs = (time && dayjs(time)) || null;

  // dynamic opacity updates
  useEffect(() => {
    if (actual || !estimated || !estimatedUpdatedAt) return;
    const update = () => {
      // calculate duration between current and updated times and set opacity from that
      const updatedDiffMin = Math.ceil(dayjs.duration(dayjs().diff(dayjs(estimatedUpdatedAt))).asMinutes());
      setOpacity(1 - updatedDiffMin / ICON_INDICATOR_MINUTES);
      // clear interval if reached the end
      if (updatedDiffMin > ICON_INDICATOR_MINUTES) {
        clearInterval(interval);
      }
    };

    const interval = setInterval(update, UPDATE_RATE_MILLISECONDS);
    update();
    return () => { clearInterval(interval); }
  }, [actual, estimated, estimatedUpdatedAt]);

  const ahead = estimated < planned;
  let estimatedDays, estimatedHours, estimatedMinutes;
  let showDiff = false;

  if (!actual && estimated) {
    const estimatedDiff = dayjs.duration(dayjs(estimated).diff(timedayjs));
    const totalDiffMinutes = Math.floor(Math.abs(estimatedDiff.asMinutes()));
    showDiff = (totalDiffMinutes !== 0)
    if (showDiff) {
      estimatedDays = Math.floor(Math.abs(estimatedDiff.asDays()));
      if (estimatedDays < SHOW_SLIPPAGE_DAYS_MIN) {
        estimatedHours = Math.floor(Math.abs(estimatedDiff.asHours()));
        estimatedMinutes = totalDiffMinutes - estimatedHours * 60;
      }
    }
  }

  return (
    showDiff ?
      <Box
        display="flex"
        flexDirection={reverse ? 'row' : 'column'}
        alignItems="center"
        justifyContent={reverse ? 'center' : 'space-between'}
        marginLeft={reverse ? '-1.2rem' : '0rem'}
        width="fit-content"
      >
        <Typography className={`${ahead ? classes.ahead : classes.behind} ${classes.icon} ${classes.smallFontSize}`}>
          {ahead ? '-' : '+'}
          {(estimatedMinutes || estimatedHours) ?
            <>{('0' + estimatedHours).slice(-2)}:{('0' + estimatedMinutes).slice(-2)}</>
            :
            <>{estimatedDays}days</>
          }
        </Typography>
        {opacity > 0 && <Timer className={`${ahead ? classes.ahead : classes.behind} ${classes.icon} ${classes.smallFontSize}`} style={{ opacity: opacity }} />}
      </Box> : <></>
  )
}
export default TimeEstimate;