import React from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import weatherIcons from '../../data/weatherIcons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  responsiveImage: {
    maxWidth: '100%',
    height: 'auto',
  }
}));

// AccuWeather
const WeatherIconLabelMap = {
  1: 'Sunny',
  2: 'MostlySunny',
  3: 'PartlySunny',
  4: 'IntermittentClouds',
  5: 'HazySunshine',
  6: 'MostlyCloudy',
  7: 'Cloudy',
  8: 'Dreary',
  11: 'Fog',
  12: 'Showers',
  13: 'MostlyCloudyWithShowers',
  14: 'PartlySunnyWithShowers',
  15: 'TStorms',
  16: 'MostlyCloudyWithTStorms',
  17: 'PartlySunnyWithTStorms',
  18: 'Rain',
  19: 'Flurries',
  20: 'MostlyCloudyWithFlurries',
  21: 'PartlySunnyWithFlurries',
  22: 'Snow',
  23: 'MostlyCloudyWithSnow',
  24: 'Ice',
  25: 'Sleet',
  26: 'FreezingRain',
  29: 'RainAndSnow',
  30: 'Hot',
  31: 'Cold',
  32: 'Windy',
  33: 'Clear',
  34: 'MostlyClear',
  35: 'PartlyCloudy',
  36: 'IntermittentClouds',
  37: 'HazyMoonlight',
  38: 'MostlyCloudy',
  39: 'PartlyCloudyWithShowers',
  40: 'MostlyCloudyWithShowers',
  41: 'PartlyCloudyWithTStorms',
  42: 'MostlyCloudyWithTStorms',
  43: 'MostlyCloudyWithFlurries',
  44: 'MostlyCloudyWithSnow'
};

const WeatherIcon = ({
  code,
  observationTime = null
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const text = WeatherIconLabelMap[code] ? t(`WeatherIcon.Labels.${WeatherIconLabelMap[code]}`) : t('Common.Labels.Unspecified');
  return (
    <Box
      width="3rem"
    >
      <Tooltip title={observationTime ? t('Weather.Labels.WeatherAt', { weather: text, time: observationTime, interpolation: { escapeValue: false }}) : text}>
        <img
          alt={observationTime ? t('Weather.Labels.WeatherAt', { weather: text, time: observationTime}) : text}
          className={classes.responsiveImage}
          src={`/icons/weather/${weatherIcons[code].file}.svg`}
        />
      </Tooltip>
    </Box>
  );
};

export default WeatherIcon;
