import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import { PowerOn, SubdirectoryArrowRight, MapMarker } from 'mdi-material-ui';

/**
 * 
 *  BerthListItem
 *  Component used for displaying a location item (formerly Berth).
 *  As locations are heirarchical there are options for displaying
 *  this relationship or not. Use parent boolean to turn on or off
 *  the displaying of the parent location (if any), and a treeIndent
 *  integer for specifying how deep it is nested.
 *  Any 'otherProps' will be those of the Material UI ListItem API
 *  such as 'button'.
 * 
 *  At time of writing 4 indent classes is plenty. Add more as 
 *  necessary.
 * 
 */
const useStyles = makeStyles(theme => ({
  treeIndent1: {
    paddingLeft: '1rem'
  },
  treeIndent2: {
    paddingLeft: '1rem'
  },
  treeIndent3: {
    paddingLeft: '2rem'
  },
  treeIndent4: {
    paddingLeft: '3rem'
  },
  parent: {
    fontSize: '0.625rem',
    marginBottom: '-0.25rem'
  },
  secondary: {
    color: theme.palette.text.secondary
  }
}));

const BerthListItem = ({
  disableGutters,
  item,
  parent,
  selected,
  treeIndent,
  lastZone,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <ListItem
      component="div"
      className={classes[`treeIndent${treeIndent}`]}
      selected={selected}
      disableGutters={disableGutters}
      disabled={Boolean(!item)}
      {...otherProps}
    >
      {treeIndent > 1 &&
        <ListItemIcon>
          <SubdirectoryArrowRight/>
        </ListItemIcon>
      }
      {treeIndent === 1 &&
        <ListItemIcon>
          <PowerOn
            style={{
              marginLeft: '-0.55rem',
              marginRight: ''
            }}
          />
        </ListItemIcon>
      }
      <Box display="flex" flexDirection="column">
        <Typography component="p" variant="caption" className={classes.parent}>{item && item.parentName}</Typography>
        <Typography component="p" variant="body1" noWrap>{item ? item.name : 'Not Allocated'}</Typography>
        <Typography className={classes.secondary} variant="body2" noWrap>{lastZone && lastZone.trim() && <Box component="span" display="flex" alignItems="center"><MapMarker fontSize="small" />{lastZone}</Box>}</Typography>
      </Box>
    </ListItem>
  );
};

BerthListItem.defaultProps = {
  disableGutters: false,
  parent: true,
  treeIndent: 0,
}

BerthListItem.propTypes = {
  // Whether to disable gutters on the base list item
  disableGutters: PropTypes.bool,
  // The location item that will be used for display
  item: PropTypes.object,
  // Boolean to determine if the component should show its parent location
  parent: PropTypes.bool,
  // Number to indicate how indented the item should be in a tree display 
  treeIndent: PropTypes.number,
}

export default BerthListItem