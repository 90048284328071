import { useMemo } from "react";
import { Box, makeStyles, ListItemText, Typography } from "@material-ui/core";
import { LocationType } from "../../../models";

const useStyles = makeStyles(theme => ({
  location: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    marginLeft: '10px',
    minWidth: '160px',
  },
}));

const VoyageTimeLineLocation = ({ location, locationMap }) => {
  const classes = useStyles();

  const port = useMemo(() => {
    if(!locationMap || !location) return;
    if ((location?.type === LocationType.PORT) && (location?.name)) return location;
    return locationMap.get(location?.id);
  }, [location, locationMap]);

  return (
    <Box className={classes.location}>
      <ListItemText
        primary={
          <Typography component="p" variant="caption" align="right">
            {port?.name ? port.name : null}
          </Typography>
        }
        secondary={
          port?.type !== LocationType.PORT ?
            <Typography component="p" variant="caption" align="right">
               {port?.parentName ? port.parentName : null}
            </Typography>
          : null
        }
        secondaryTypographyProps={{
          noWrap: true
        }}
      />
    </Box>
  );
};

export default VoyageTimeLineLocation;