import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core';
import ChargeablesRowEdit from './ChargeableRowEdit';
import { DatastoreStatusContext } from '../../../contexts/datastoreStatusContext';
import { useTranslation } from 'react-i18next';
import { UIContext } from '../../../contexts/ui';
// root - styles for Table
// button - styles for Button
// Styles for the widths of the columns Head
// and therefore the rest of the table
const useStyles = makeStyles({
  typographyBox: {
    height: '6rem',
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    width: '100%',
    maxHeight: '50vh'
  },
  numericInput: {
    maxHeight: '5rem',
    width: "14rem"
  },
  icon: {
    width: "3rem"
  },
  errorText: {
    whiteSpace: 'pre-line',
  },
  smallText: {
    width: "9rem"
  },
  unitCost: {
    maxHeight: '5rem',
    width: "15rem"
  },
  tariff: {
    maxHeight: '5rem',
    width: "20rem"
  }
});

// Function to turn chargeable item to object for invalid state
const chargeableItemsToInvalidObject = (chargeableItems) => (chargeableItems.reduce((acc, { id }) => ({ ...acc, [id]: false }), {}));

// Component for Table that contain the chargeable items 
// chargeableItems ([]) - Array of chargeable items
// setChargeableItems (func) - Function to update the Chargeable items with new value
// handleSaveAndClose (func) - Function for saving the chargeableItems and closing dialog
// handleClose (func) - Function to close the dialog without saving
// tariffs([]) - Array of tariffs available for this action
// columns([]) - Array of columns that will be available on the edit table
// currencySymbol - Currency symbol
// confirmButtonLabel - Custom confirm button label
// disableAdd - Disable add new item button
// canConfirm - Custom function to control state of confirm button
// disableUnitCost - disable unit cost editing
const ChargeablesTable = ({
  chargeableItems,
  saveChargeableItems,
  handleSaveAndClose,
  handleClose,
  tariffs,
  columns,
  currencySymbol,
  confirmButtonLabel,
  disableAdd,
  canConfirm,
  disableUnitCost,
  actionTime
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  //get current user 
  const [uiContext] = useContext(UIContext);
  const { isAdmin } = uiContext;

  const isSynced = useContext(DatastoreStatusContext);

  // Used to identify the new row to be added to scroll to it if large table
  const addingRef = useRef(null);

  // State for adding a chargeable item
  const [addingCI, setAddingCI] = useState(false);

  // State for storing whether a chargeable item are in a valid state
  // Object with id of chargeable items as key
  // Initially set to chargeable items with reduce to ids with property of false
  const [invalid, setInvalid] = useState(chargeableItemsToInvalidObject(chargeableItems));

  // Checks if there are any keys with property set to true
  const invalidEditor = invalid && Object.keys(invalid).some((key) => invalid[key]);

  // Effect to scroll to the newly added if availble 
  useEffect(() => {
    if (addingCI && addingRef.current) {
      addingRef.current.scrollIntoView();
    };
  }, [addingCI, addingRef])

  // Store of whether there are any chargeable items
  const chargeableItemsExist = chargeableItems && chargeableItems.length > 0;

  // Variable for the tariffs  tariffs && tariffs.length > 0;
  const tariffsExist = tariffs && tariffs.length > 0;

  // Method to save chargeable items to the array of chargeable items
  // Find the index of the chargeable item by id
  // Create new copy of chargeable items
  // If the chargeable item is found, it will be replaced by the new Chargeable item
  // Else will be added to the end of the array
  const saveChargeableItem = (chargeableItem, id) => {
    const changedChargeableItemIndex = chargeableItems.findIndex((cI) => cI.id === id);
    const newChargeableItems = [...chargeableItems];
    setInvalid({ ...invalid, [id]: false });
    if (changedChargeableItemIndex !== -1) {
      newChargeableItems[changedChargeableItemIndex] = chargeableItem;
    } else {
      newChargeableItems.push(chargeableItem);
      setAddingCI(false);
    }
    saveChargeableItems(newChargeableItems);
  }

  // Method to delete chargeable item
  // Find index of the chargeable item with the id passed in
  // If found will remove element from the array
  // Else will set the adding state to false 
  const deleteChargeableItem = (id) => {
    const deleteChargeableItemIndex = chargeableItems.findIndex((cI) => cI.id === id);
    setInvalid({ ...invalid, [id]: false });
    if (deleteChargeableItemIndex !== -1) {
      const newChargeableItems = [...chargeableItems];
      newChargeableItems.splice(deleteChargeableItemIndex, 1);
      saveChargeableItems(newChargeableItems);
    } else {
      setAddingCI(false);
    }
  }

  // set the key of id to true
  const invalidateChargeableItem = (id) => {
    if (!id) return;
    setInvalid({ ...invalid, [id]: true });
  }

  return (
    <>
      {/* Check for tariffs, then check if the chargeable items exist or one is to be added */}
      <Box>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {columns.map(({ id, label, className }) => (
                  <TableCell
                    key={id}
                    variant='head'
                    className={classes[className]}
                  >
                    {t(label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {chargeableItemsExist &&
                chargeableItems.map(cI =>
                  <TableRow
                    key={cI.id}
                    data-id={cI?.id}
                    className={'ChargeableItemData'}
                    hover
                  >
                    <ChargeablesRowEdit
                      chargeableItem={cI}
                      tariffs={tariffs}
                      saveChargeableItem={saveChargeableItem}
                      deleteChargeableItem={deleteChargeableItem}
                      currencySymbol={currencySymbol}
                      invalidateChargeableItem={invalidateChargeableItem}
                      disableUnitCost={disableUnitCost}
                      actionTime={actionTime}
                    />
                  </TableRow>
                )}
              {addingCI && <TableRow ref={addingRef} hover id={'AddChargeablesRow'}>
                <ChargeablesRowEdit
                  tariffs={tariffs}
                  saveChargeableItem={saveChargeableItem}
                  deleteChargeableItem={deleteChargeableItem}
                  currencySymbol={currencySymbol}
                  invalidateChargeableItem={invalidateChargeableItem}
                />
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!tariffsExist &&
        <Box className={classes.typographyBox}>
          <Typography variant='subtitle1' className={classes.errorText}>
            {isAdmin ? t('ChargeableItems.Labels.NoTariffsIsAdminIsAdmin') : t('ChargeableItems.Labels.NoTariffsIsAdminNotAdmin')}
          </Typography>
        </Box>
      }
      {tariffsExist && !chargeableItemsExist && !addingCI &&
        <Box className={classes.typographyBox}>
          <Typography variant='subtitle1' className={classes.errorText}>
            {t('ChargeableItems.Labels.NoChargeable')}
          </Typography>
        </Box>
      }
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="0.5rem"
      >
        <Button id="ChargeableEditTableButton"
          color="primary"
          variant="outlined"
          onClick={() => setAddingCI(true)}
          disabled={addingCI || !tariffsExist || disableAdd}
        >
          {t('ChargeableItems.Buttons.Add')}
        </Button>
        <Box
          display="flex"
          alignItems="center"
        >
          {invalidEditor && (
            <Box marginRight="1rem">
              <Typography
                color="error"
              >{t('ChargeableItems.Errors.InvalidChargeable')}</Typography>
            </Box>
          )
          }
          <Box marginRight="1rem">
            <Button
              id="CloseChargeablesTableButton"
              color="primary"
              onClick={() => handleClose()}
            >
              {t('Common.Buttons.Close')}
            </Button>
          </Box>
          <Button
            id="SaveAndCloseChargeablesTableButton"
            color="primary"
            variant="contained"
            disabled={invalidEditor || !isSynced || (canConfirm && !canConfirm(chargeableItems))}
            onClick={() => handleSaveAndClose()}
          >
            {t(confirmButtonLabel)}
          </Button>
        </Box>
      </Box>
    </>
  );
}

ChargeablesTable.propTypes = {
  action: PropTypes.object,
  chargeableItems: PropTypes.array,
  saveChargeableItems: PropTypes.func,
  tariffs: PropTypes.array,
  columns: PropTypes.array,
  handleSaveAndClose: PropTypes.func,
  handleClose: PropTypes.func,
  currencySymbol: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  canConfirm: PropTypes.func
}

// Default Props for Chargeable Table
ChargeablesTable.defaultProps = {
  action: null,
  chargeableItems: null,
  saveChargeableItems: null,
  handleSaveAndClose: null,
  handleClose: null,
  tariffs: [],
  columns: [],
  currencySymbol: "",
  confirmButtonLabel: 'Common.Buttons.Save',
  canConfirm: null
}

export default ChargeablesTable;