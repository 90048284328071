import { ExportUnits } from './export-fields';
import { format } from 'date-fns';

export const METRES_TO_FEET = 3.2808399;

export const formatLength = (value, selectedLengthUnit) => Number(value * (selectedLengthUnit === ExportUnits.METRES ? 1 : METRES_TO_FEET)).toFixed(2)

export const getUnit = (selectedLengthUnit) => Object.keys(ExportUnits).find((unit)=> selectedLengthUnit === ExportUnits[unit]);
/** Format the certificate details in: 
//<CAT>: <TYPE> (Valid | Invalid) (<DATE_FROM> To <DATE_TO>) <NAME> Ref: <REF>
*/
 export const formatCertificate = (certificate, t, dateFormat) => {
  const txt = [];
  txt.push(certificate?.name)
  txt.push(`(${certificate?.isValid ? t('CertificateType.Labels.Valid') : t('CertificateType.Labels.Invalid')})`)
  txt.push(`(${format(new Date(certificate?.validFromDate), dateFormat)} ${t('CertificateType.Labels.To')} ${format(new Date(certificate?.validToDate), dateFormat)})`)
  certificate?.nameOfHolder && txt.push(certificate?.nameOfHolder)
  certificate?.referenceNumber && txt.push(`${t('CertificateType.Labels.ReferenceNumberShort')}: ${certificate?.referenceNumber}`)
  return txt.join(' ');
}