import React, { useState, useEffect, forwardRef } from 'react';
import { Hub } from 'aws-amplify';
import { Backdrop, Box, CircularProgress, debounce, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import { styles } from './snackBarStyles'
import { useTranslation } from 'react-i18next';

const debounced = debounce(f => f(), 5000); // it performs delta then full sync so, to reduce it from going on and off, add debounce when closing

const SyncingSnackbarContent = withStyles(styles)(forwardRef((({classes}, ref) => {
  const { t } = useTranslation();
  return (
    <SnackbarContent ref={ref}>
      <Box className={classes.root} display="flex" alignItems="center" flex="auto" padding="16px">
        <CircularProgress size="1rem" />
        <Typography style={{ paddingLeft: "0.5rem" }}>{t('Snackbar.Labels.Syncing')}</Typography>
      </Box>
    </SnackbarContent>
  )
})));

const SnackbarKey = 'datastore-sync';

const SyncingSnackbar = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const listener = Hub.listen("datastore", async ({ payload }) => {
      if (payload.event === "syncQueriesStarted") {
        setOpen(true);
        enqueueSnackbar('syncing', {
          key: SnackbarKey,
          persist: true,
          content: key => <SyncingSnackbarContent />
        });
      }
      else if (payload.event === "syncQueriesReady") {
        debounced(() => {
          setOpen(false);
          closeSnackbar(SnackbarKey);
        });
      }
    });
    return () => {
      listener();
    }
  })
  return (
    <Backdrop open={open} style={{ zIndex: 100000, background: "none" }}/>
  )
};

export default SyncingSnackbar;