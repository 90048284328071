import React from 'react';
import useQuery from '../hooks/useQuery';
import { ActionType, Location, Setting, Tariff, Template, Request, Vessel, TodoTypeTemplate, Category, AutomationRule, UserSetting, ContactType, Contact, NotificationRule, CertificateType, TariffBook, TariffUnit, CargoType } from '../models';
import { sortActionTypes, sortContactTypes, sortContacts, sortLocations, sortTariffs, sortTariffUnits } from '../utils/sorters';

/**
 * Helper DataStoreContext that keeps common models live in accessible app-wide context
 */

export const useDataStoreProvider = () => {
  const locations = useQuery(Location, { sort: sortLocations });
  const actionTypes = useQuery(ActionType, { sort: sortActionTypes });
  const contactTypes = useQuery(ContactType, { sort: sortContactTypes });
  const settings = useQuery(Setting);
  const templates = useQuery(Template);
  const tariffs = useQuery(Tariff, { sort: sortTariffs });
  const requests = useQuery(Request);
  const vessels = useQuery(Vessel);
  const todoTypeTemplates = useQuery(TodoTypeTemplate);
  const categories = useQuery(Category);
  const automationRules = useQuery(AutomationRule);
  const userSettings = useQuery(UserSetting);
  const contacts = useQuery(Contact, { sort: sortContacts });
  const notificationRule = useQuery(NotificationRule);
  const certificateType = useQuery(CertificateType);
  const tariffUnits = useQuery(TariffUnit, { sort: sortTariffUnits });
  const cargoTypes = useQuery(CargoType);
  const tariffBooks = useQuery(TariffBook);
  return {
    locations,
    actionTypes,
    contactTypes,
    contacts,
    settings,
    templates,
    tariffs,
    requests,
    vessels,
    todoTypeTemplates,
    categories,
    automationRules,
    userSettings,
    notificationRule,
    certificateType,
    tariffUnits,
    tariffBooks,
    cargoTypes
  };
};

export const DataStoreContext = React.createContext({
  locations: [],
  actionTypes: [],
  contactTypes: [],
  contacts: [],
  settings: [],
  templates: [],
  tariffs: [],
  requests: [],
  vessels: [],
  todoTypeTemplates: [],
  categories: [],
  userSettings: [],
  notificationRule: [],
  certificateType: [],
  tariffUnits: [],
  tariffBooks: [],
  cargoTypes: []
});
