// import * as status from './PortCallStatus';
// import { orange } from '@material-ui/core/colors';

import { RequestState, RequestType } from "../models";

export const RequestColor = {
  color: '#ff9800',
  backgroundColor: '#ff980011' 
};

export const itemRequested = {
  borderLeft: '.5rem solid ' + RequestColor.color,
  background: RequestColor.backgroundColor,
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  pointerEvents: 'none',
}

export const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: false,
}

export const RequestStateActionLabelsKeys = {
  [RequestState.REQUEST_STATE_PENDING]: 'RequestState.Labels.PendingAction',
  [RequestState.REQUEST_STATE_REJECTED]: 'RequestState.Labels.RejectAction',
  [RequestState.REQUEST_STATE_APPROVED]: 'RequestState.Labels.ApproveAction'
}

export const RequestStateLabelsKeys = {
  [RequestState.REQUEST_STATE_PENDING]: 'RequestState.Labels.Pending',
  [RequestState.REQUEST_STATE_APPROVED]: 'RequestState.Labels.Approved',
  [RequestState.REQUEST_STATE_REJECTED]: 'RequestState.Labels.Rejected',
  [RequestState.REQUEST_STATE_WITHDRAWN]: 'RequestState.Labels.Withdrawn',
  [RequestState.REQUEST_STATE_NOTIFICATION]: 'RequestState.Labels.Notification',
  PARTIALLY_APPROVED: 'RequestState.Labels.PartiallyApproved',
  PARTIALLY_REJECTED: 'RequestState.Labels.PartiallyRejected'
};

export const RequestTypeLabelsKeys = {
  [RequestType.REQUEST_TYPE_CREATE_PORTCALL]: 'RequestType.Labels.CreatePortCall',
  [RequestType.REQUEST_TYPE_UPDATE_PORTCALL_CARGO]: 'RequestType.Labels.UpdatePortCallCargo',
  [RequestType.REQUEST_TYPE_CANCEL_PORTCALL]: 'RequestType.Labels.CancelPortCall',
  [RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED]: 'RequestType.Labels.UpdateActionArrivalTimePlanned',
  [RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED]: 'RequestType.Labels.UpdateActionDepartureTimePlanned',
  [RequestType.REQUEST_TYPE_UPDATE_ACTION_DRAUGHT]: 'RequestType.Labels.UpdateActionDraught',
  [RequestType.REQUEST_TYPE_CANCEL_ACTION]: 'RequestType.Labels.CancelAction',
  [RequestType.REQUEST_TYPE_CREATE_DEPARTURE]: 'RequestType.Labels.CreateDeparture'
};

export const RequestAction = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};