import React, {useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import useQuery from '../../hooks/useQuery';

const TagAutocomplete = ({ values, onChange, label, model, ...other }) => {
  const _tags = useQuery(model);
  const tags = useMemo(() => _tags.map(tag => tag.name).sort((a, b) => a?.localeCompare(b)),[_tags]);

  const handleChange = (value) => {
    onChange && onChange(value);
  };
  
  return (
    <Autocomplete
      {...other}
      filterSelectedOptions
      freeSolo
      forcePopupIcon
      multiple
      autoHighlight
      options={tags}
      getOptionLabel={(item) => item}
      value={values}
      onChange={(e, value) => handleChange(value)}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
};

export default TagAutocomplete;