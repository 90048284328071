
import { useContext, useCallback } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { NotificationRule } from '../models';

/**
 * A hook to get all notification rules and update them.
 * @return {Object} notification rule list and helper functions
 */
const useNotificationRules = () => {
  const { notificationRule } = useContext(DataStoreContext);

  const updateNotificationRulesForDeletedContact = useCallback(async (contactId) => {
    if(!contactId){
      return;
    }
    for(const rule of notificationRule) {
      if(rule?.recipients?.find(r => r === contactId)) {
        await DataStore.save(NotificationRule.copyOf(rule, updated => { 
          updated.recipients = rule?.recipients?.filter(r => r !== contactId)
        }));
      }
    }
  }, [notificationRule])

  const updateNotificationRulesForDeletedActionType = useCallback(async (actionTypeId) => {
    if(!actionTypeId){
      return;
    }
    for(const rule of notificationRule) {
      if(rule?.actionTypeId === actionTypeId) {
        await DataStore.save(NotificationRule.copyOf(rule, updated => { 
          updated.actionTypeId = null
          updated.triggers = []
        }));
      }
    }
  }, [notificationRule])

  return {
    notificationRule,
    updateNotificationRulesForDeletedContact,
    updateNotificationRulesForDeletedActionType
  }
}

export default useNotificationRules;