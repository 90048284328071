// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CategoryType = {
  "PORTCALL": "PORTCALL"
};

const AuditLogType = {
  "AUDIT_ACTION_STATE": "AUDIT_ACTION_STATE",
  "AUDIT_REQUEST_UPDATE": "AUDIT_REQUEST_UPDATE",
  "AUDIT_ERROR": "AUDIT_ERROR",
  "AUDIT_COMMENT": "AUDIT_COMMENT",
  "AUDIT_NOTIFICATION": "AUDIT_NOTIFICATION",
  "AUDIT_FILE_CREATE": "AUDIT_FILE_CREATE",
  "AUDIT_FILE_UPDATE": "AUDIT_FILE_UPDATE",
  "AUDIT_FILE_DELETE": "AUDIT_FILE_DELETE",
  "AUDIT_PORTCALL_STATE": "AUDIT_PORTCALL_STATE"
};

const RequestState = {
  "REQUEST_STATE_PENDING": "REQUEST_STATE_PENDING",
  "REQUEST_STATE_APPROVED": "REQUEST_STATE_APPROVED",
  "REQUEST_STATE_REJECTED": "REQUEST_STATE_REJECTED",
  "REQUEST_STATE_WITHDRAWN": "REQUEST_STATE_WITHDRAWN",
  "REQUEST_STATE_NOTIFICATION": "REQUEST_STATE_NOTIFICATION",
  "REQUEST_STATE_DELETED": "REQUEST_STATE_DELETED"
};

const RequestType = {
  "REQUEST_TYPE_CREATE_PORTCALL": "REQUEST_TYPE_CREATE_PORTCALL",
  "REQUEST_TYPE_UPDATE_PORTCALL_CARGO": "REQUEST_TYPE_UPDATE_PORTCALL_CARGO",
  "REQUEST_TYPE_CANCEL_PORTCALL": "REQUEST_TYPE_CANCEL_PORTCALL",
  "REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED": "REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED",
  "REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED": "REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED",
  "REQUEST_TYPE_UPDATE_ACTION_DRAUGHT": "REQUEST_TYPE_UPDATE_ACTION_DRAUGHT",
  "REQUEST_TYPE_CANCEL_ACTION": "REQUEST_TYPE_CANCEL_ACTION",
  "REQUEST_TYPE_CREATE_DEPARTURE": "REQUEST_TYPE_CREATE_DEPARTURE"
};

const VoyageStatus = {
  "PLANNED": "PLANNED",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "INCOMPLETE": "INCOMPLETE",
  "DELETED": "DELETED",
  "ABORTED": "ABORTED"
};

const PortCallStatus = {
  "REQUESTED": "REQUESTED",
  "PREARRIVAL": "PREARRIVAL",
  "ARRIVAL": "ARRIVAL",
  "IN_PORT": "IN_PORT",
  "DEPARTURE": "DEPARTURE",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED",
  "CLOSED": "CLOSED",
  "SUPERSEDED": "SUPERSEDED"
};

const PortCallOrigin = {
  "AUTOMATION": "AUTOMATION",
  "AGENT_REQUEST": "AGENT_REQUEST",
  "PORT_CONTROLLER": "PORT_CONTROLLER",
  "REST_VOYAGE_IMPORT": "REST_VOYAGE_IMPORT"
};

const PropulsionType = {
  "PROPULSION_FIXED_PITCH": "PROPULSION_FIXED_PITCH",
  "PROPULSION_CONTROLLABLE_PITCH": "PROPULSION_CONTROLLABLE_PITCH",
  "PROPULSION_FIXED_VARIABLE_PITCH": "PROPULSION_FIXED_VARIABLE_PITCH",
  "PROPULSION_AZIMUTH_THRUSTER": "PROPULSION_AZIMUTH_THRUSTER",
  "PROPULSION_VOITH_SCHNEIDER": "PROPULSION_VOITH_SCHNEIDER",
  "PROPULSION_Z_TYPE": "PROPULSION_Z_TYPE"
};

const PropellerDirection = {
  "PROPELLER_LEFT": "PROPELLER_LEFT",
  "PROPELLER_RIGHT": "PROPELLER_RIGHT"
};

const PowerUnit = {
  "KW": "KW",
  "HP": "HP"
};

const RudderType = {
  "RUDDER_STANDARD": "RUDDER_STANDARD",
  "RUDDER_FLAPPED": "RUDDER_FLAPPED",
  "RUDDER_FISHTAIL": "RUDDER_FISHTAIL"
};

const ActionState = {
  "REQUESTED": "REQUESTED",
  "APPROVAL_PENDING": "APPROVAL_PENDING",
  "APPROVAL_DECLINED": "APPROVAL_DECLINED",
  "PLANNED": "PLANNED",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED",
  "SUPERSEDED": "SUPERSEDED"
};

const ActionMovementType = {
  "ARRIVAL": "ARRIVAL",
  "DEPARTURE": "DEPARTURE",
  "SHIFT_ARRIVAL": "SHIFT_ARRIVAL",
  "SHIFT_DEPARTURE": "SHIFT_DEPARTURE"
};

const ActionEventType = {
  "AGENT_HANDOVER": "AGENT_HANDOVER"
};

const ActionPropulsionOperationalState = {
  "FULLY_OPERATIONAL": "FULLY_OPERATIONAL",
  "HAS_FAULT": "HAS_FAULT"
};

const LocationType = {
  "PORT": "PORT",
  "TERMINAL": "TERMINAL",
  "HARBOUR": "HARBOUR",
  "INNERHARBOUR": "INNERHARBOUR",
  "QUAY": "QUAY",
  "PIER": "PIER",
  "WHARF": "WHARF",
  "JETTY": "JETTY",
  "MOORINGPOINT": "MOORINGPOINT",
  "BERTH": "BERTH",
  "BASIN": "BASIN",
  "ANCHORAGEAREA": "ANCHORAGEAREA"
};

const TodoStatus = {
  "DRAFT": "DRAFT",
  "PENDING": "PENDING",
  "READY": "READY",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED"
};

const TemplateType = {
  "ACTION": "ACTION"
};

const TemplateSubType = {
  "ACTION_ARRIVAL": "ACTION_ARRIVAL",
  "ACTION_DEPARTURE": "ACTION_DEPARTURE",
  "ACTION_SHIFT_DEPARTURE": "ACTION_SHIFT_DEPARTURE",
  "ACTION_SHIFT_ARRIVAL": "ACTION_SHIFT_ARRIVAL"
};

const AutomationActionType = {
  "CREATE_PORTCALL": "CREATE_PORTCALL",
  "START_ARRIVAL": "START_ARRIVAL",
  "COMPLETE_ARRIVAL": "COMPLETE_ARRIVAL",
  "CREATE_AND_START_DEPARTURE": "CREATE_AND_START_DEPARTURE",
  "COMPLETE_DEPARTURE": "COMPLETE_DEPARTURE"
};

const AutomationEventType = {
  "ENTER_ZONE": "ENTER_ZONE",
  "ZONE_MIN_SPEED": "ZONE_MIN_SPEED"
};

const FileType = {
  "FAL5_CREW_LIST": "FAL5_CREW_LIST"
};

const NotificationTriggerType = {
  "ARRIVAL_CREATED": "ARRIVAL_CREATED",
  "ARRIVAL_STARTED": "ARRIVAL_STARTED",
  "ARRIVAL_COMPLETED": "ARRIVAL_COMPLETED",
  "ARRIVAL_PENDING_APPROVAL": "ARRIVAL_PENDING_APPROVAL",
  "ARRIVAL_APPROVED": "ARRIVAL_APPROVED",
  "ARRIVAL_DECLINED": "ARRIVAL_DECLINED",
  "ARRIVAL_REQUESTED": "ARRIVAL_REQUESTED",
  "ARRIVAL_BEFORE": "ARRIVAL_BEFORE",
  "ARRIVAL_AFTER": "ARRIVAL_AFTER",
  "DEPARTURE_CREATED": "DEPARTURE_CREATED",
  "DEPARTURE_STARTED": "DEPARTURE_STARTED",
  "DEPARTURE_COMPLETED": "DEPARTURE_COMPLETED",
  "DEPARTURE_PENDING_APPROVAL": "DEPARTURE_PENDING_APPROVAL",
  "DEPARTURE_APPROVED": "DEPARTURE_APPROVED",
  "DEPARTURE_DECLINED": "DEPARTURE_DECLINED",
  "DEPARTURE_CANCELLED": "DEPARTURE_CANCELLED",
  "DEPARTURE_REQUESTED": "DEPARTURE_REQUESTED",
  "DEPARTURE_BEFORE": "DEPARTURE_BEFORE",
  "DEPARTURE_AFTER": "DEPARTURE_AFTER",
  "BERTH_SHIFT_ARRIVAL_CREATED": "BERTH_SHIFT_ARRIVAL_CREATED",
  "BERTH_SHIFT_ARRIVAL_STARTED": "BERTH_SHIFT_ARRIVAL_STARTED",
  "BERTH_SHIFT_ARRIVAL_COMPLETED": "BERTH_SHIFT_ARRIVAL_COMPLETED",
  "BERTH_SHIFT_ARRIVAL_CANCELLED": "BERTH_SHIFT_ARRIVAL_CANCELLED",
  "BERTH_SHIFT_ARRIVAL_PENDING_APPROVAL": "BERTH_SHIFT_ARRIVAL_PENDING_APPROVAL",
  "BERTH_SHIFT_ARRIVAL_APPROVED": "BERTH_SHIFT_ARRIVAL_APPROVED",
  "BERTH_SHIFT_ARRIVAL_DECLINED": "BERTH_SHIFT_ARRIVAL_DECLINED",
  "BERTH_SHIFT_ARRIVAL_BEFORE": "BERTH_SHIFT_ARRIVAL_BEFORE",
  "BERTH_SHIFT_ARRIVAL_AFTER": "BERTH_SHIFT_ARRIVAL_AFTER",
  "BERTH_SHIFT_DEPARTURE_CREATED": "BERTH_SHIFT_DEPARTURE_CREATED",
  "BERTH_SHIFT_DEPARTURE_STARTED": "BERTH_SHIFT_DEPARTURE_STARTED",
  "BERTH_SHIFT_DEPARTURE_COMPLETED": "BERTH_SHIFT_DEPARTURE_COMPLETED",
  "BERTH_SHIFT_DEPARTURE_CANCELLED": "BERTH_SHIFT_DEPARTURE_CANCELLED",
  "BERTH_SHIFT_DEPARTURE_PENDING_APPROVAL": "BERTH_SHIFT_DEPARTURE_PENDING_APPROVAL",
  "BERTH_SHIFT_DEPARTURE_APPROVED": "BERTH_SHIFT_DEPARTURE_APPROVED",
  "BERTH_SHIFT_DEPARTURE_DECLINED": "BERTH_SHIFT_DEPARTURE_DECLINED",
  "BERTH_SHIFT_DEPARTURE_BEFORE": "BERTH_SHIFT_DEPARTURE_BEFORE",
  "BERTH_SHIFT_DEPARTURE_AFTER": "BERTH_SHIFT_DEPARTURE_AFTER",
  "CUSTOM_ACTION_CREATED": "CUSTOM_ACTION_CREATED",
  "CUSTOM_ACTION_CANCELLED": "CUSTOM_ACTION_CANCELLED",
  "CUSTOM_ACTION_STARTED": "CUSTOM_ACTION_STARTED",
  "CUSTOM_ACTION_COMPLETED": "CUSTOM_ACTION_COMPLETED",
  "CUSTOM_ACTION_BEFORE": "CUSTOM_ACTION_BEFORE",
  "CUSTOM_ACTION_AFTER": "CUSTOM_ACTION_AFTER"
};

const NotificationType = {
  "EMAIL": "EMAIL"
};

const NotificationKind = {
  "ACTION_STATE_CHANGE": "ACTION_STATE_CHANGE",
  "ACTION_REMINDER": "ACTION_REMINDER"
};

const TimeUnits = {
  "MINUTE": "MINUTE",
  "HOUR": "HOUR",
  "DAY": "DAY"
};

const MatchingType = {
  "ALL": "ALL",
  "ANY": "ANY"
};

const ExternalService = {
  "UNKNOWN": "UNKNOWN",
  "MARINE_TRAFFIC": "MARINE_TRAFFIC",
  "IHS": "IHS",
  "ADMIRALTY": "ADMIRALTY",
  "ACCUWEATHER": "ACCUWEATHER",
  "WORLDTIDES": "WORLDTIDES"
};

const TidalEventType = {
  "HIGH_WATER": "HIGH_WATER",
  "LOW_WATER": "LOW_WATER"
};

const FileExportResultStatus = {
  "STARTED": "STARTED",
  "COMPLETE": "COMPLETE",
  "ERROR": "ERROR"
};

const ExportUnits = {
  "METRES": "METRES",
  "FEET": "FEET"
};

const ExportSortDirection = {
  "NEWEST_FIRST": "NEWEST_FIRST",
  "OLDEST_FIRST": "OLDEST_FIRST"
};

const { Setting, UserSetting, Category, AuditLog, Request, Voyage, PortCall, PortCallData, ContactType, Contact, Vessel, CargoType, Cargo, TariffUnit, Tariff, TariffBook, ActionType, Action, Location, PilotTag, MooringVesselTag, LinesmanTag, PortCallTag, Filter, TodoTypeTemplate, Template, VesselState, AutomationRule, S3File, NotificationRule, CertificateType, ExportPortCallConfig, AuditMeta, AuditLogEmbedded, RequestPortCallData, RequestActionData, PortCallAgent, PortCallData_FAL5_CREW_LIST, PortCallData_FAL5_CREW_LIST_Member, VesselData, TariffData, ChargeableItem, DefaultChargeableItem, TariffCostItem, TariffDefaultCharges, AutomationMeta, Dimensions, Port, Currency, Todo, ActionTemplate, ChargeableItemTemplate, ArchivePortCallsResponse, AutomationAction_CreatePortCall, AutomationAction, AutomationStep, AutomationPlan, Certificate, ExternalServiceResponse, VesselExternalGetResponse, TidalEvent, TidalEventsResponse, AccuWeatherForecastHeadline, AccuWeatherSun, AccuWeatherMoon, AccuWeatherObservation, AccuWeatherVector, AccuWeatherTemperature, AccuWeatherDegreeDaySummary, AccuWeatherAirAndPollenObservation, AccuWeatherWind, AccuWeatherWindCondition, AccuWeatherWindGustCondition, AccuWeatherDay, AccuWeatherDailyForecast, ForecastAccuWeatherResponse, AccuWeatherConditionObservation, AccuWeatherCurrentConditions, CurrentConditionsAccuWeatherResponse, MarlinAuthResponse, TransactionResponse, FileExportResult, ExportResponse } = initSchema(schema);

export {
  Setting,
  UserSetting,
  Category,
  AuditLog,
  Request,
  Voyage,
  PortCall,
  PortCallData,
  ContactType,
  Contact,
  Vessel,
  CargoType,
  Cargo,
  TariffUnit,
  Tariff,
  TariffBook,
  ActionType,
  Action,
  Location,
  PilotTag,
  MooringVesselTag,
  LinesmanTag,
  PortCallTag,
  Filter,
  TodoTypeTemplate,
  Template,
  VesselState,
  AutomationRule,
  S3File,
  NotificationRule,
  CertificateType,
  CategoryType,
  AuditLogType,
  RequestState,
  RequestType,
  VoyageStatus,
  PortCallStatus,
  PortCallOrigin,
  PropulsionType,
  PropellerDirection,
  PowerUnit,
  RudderType,
  ActionState,
  ActionMovementType,
  ActionEventType,
  ActionPropulsionOperationalState,
  LocationType,
  TodoStatus,
  TemplateType,
  TemplateSubType,
  AutomationActionType,
  AutomationEventType,
  FileType,
  NotificationTriggerType,
  NotificationType,
  NotificationKind,
  TimeUnits,
  MatchingType,
  ExternalService,
  TidalEventType,
  FileExportResultStatus,
  ExportUnits,
  ExportSortDirection,
  ExportPortCallConfig,
  AuditMeta,
  AuditLogEmbedded,
  RequestPortCallData,
  RequestActionData,
  PortCallAgent,
  PortCallData_FAL5_CREW_LIST,
  PortCallData_FAL5_CREW_LIST_Member,
  VesselData,
  TariffData,
  ChargeableItem,
  DefaultChargeableItem,
  TariffCostItem,
  TariffDefaultCharges,
  AutomationMeta,
  Dimensions,
  Port,
  Currency,
  Todo,
  ActionTemplate,
  ChargeableItemTemplate,
  ArchivePortCallsResponse,
  AutomationAction_CreatePortCall,
  AutomationAction,
  AutomationStep,
  AutomationPlan,
  Certificate,
  ExternalServiceResponse,
  VesselExternalGetResponse,
  TidalEvent,
  TidalEventsResponse,
  AccuWeatherForecastHeadline,
  AccuWeatherSun,
  AccuWeatherMoon,
  AccuWeatherObservation,
  AccuWeatherVector,
  AccuWeatherTemperature,
  AccuWeatherDegreeDaySummary,
  AccuWeatherAirAndPollenObservation,
  AccuWeatherWind,
  AccuWeatherWindCondition,
  AccuWeatherWindGustCondition,
  AccuWeatherDay,
  AccuWeatherDailyForecast,
  ForecastAccuWeatherResponse,
  AccuWeatherConditionObservation,
  AccuWeatherCurrentConditions,
  CurrentConditionsAccuWeatherResponse,
  MarlinAuthResponse,
  TransactionResponse,
  FileExportResult,
  ExportResponse
};