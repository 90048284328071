import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  Popover,
  FormHelperText
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { DataStore } from 'aws-amplify';
import { ActionMovementType, Request, RequestType, RequestState, Action } from '../../../models';
import StringKeyboardDateTimePicker from '../../Common/StringKeyboardDateTimePicker';
import { getAgentContact, buildRequestSource, getUserEmail, getUserName } from '../../../utils/agentPortal';
import {
  generateArrivalTimePlannedCheck,
  generateDepartureTimePlannedCheck,
  generateArrivalRequestCheck,
  generateDepartureRequestCheck
} from "../../../utils/generators";
import useAuditMeta from '../../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

const AgentRequestTimeChangeDialog = ({ portCall, action, contactId, anchorEl, onClose }) => {
  const [ requestTime, setRequestTime ] = useState(action.timePlanned);
  const [ remarks, setRemarks ] = useState('');
  const [ requests, setRequests ] = useState([]);
  const [ lastRequest, setLastRequest ] = useState(null);
  const [error, setError] = useState('');
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  let timeLabel = '';
  let titleLabel = '';
  let requestType;

  switch(action.movementType) {
    case ActionMovementType.ARRIVAL:
      timeLabel = t('AgentRequestTimeChangeDialog.Labels.RequestedTimeArrival');
      titleLabel = t('ActionMovementType.Labels.Arrival');
      requestType = RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED;
      break;
    case ActionMovementType.DEPARTURE:
      timeLabel = t('AgentRequestTimeChangeDialog.Labels.RequestedTimeDeparture');
      titleLabel = t('ActionMovementType.Labels.Departure');
      requestType = RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED;
      break;
    default:
      break;
  }

  useEffect(() => {
    const checkForExisting = async () => {
      // store all pending arrival/departure requests for the agent 
      const pendingRequests = await DataStore.query(Request, c =>
        c.state('eq', RequestState.REQUEST_STATE_PENDING)
        .requestPortCallId_('eq', portCall.id)
        .or(c=> c.type('eq', RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED)
          .type('eq', RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED)));
      
      setRequests(pendingRequests.filter(el => (el.agent && (el.agent.id === contactId))));

      const oldRequest = pendingRequests
        .filter(el => (el.agent && (el.agent.id === contactId)))
        .filter(el => el.type === requestType)
        .find(el => {
          const actionData = el.actionData.find(ad => ad.actionId === action.id);
          return actionData;
        });
      setLastRequest(oldRequest);
      oldRequest && setRequestTime(oldRequest.actionData[0].timePlanned);
      oldRequest && setRemarks(oldRequest.remark);
    }

    checkForExisting();

  }, [contactId, requestType, action.id, portCall.id])

  const handleChangeRequestTime = value => {
    let checks = '';
    let checkPlanned = '';
    let checkRequest = '';
    switch(requestType) {
      case RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED:
        checkPlanned = generateArrivalTimePlannedCheck(value, portCall.actions, action);
        checkRequest = generateArrivalRequestCheck(requests, value);
        break;
      case RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED:
        checkPlanned = generateDepartureTimePlannedCheck(value, portCall.actions);
        checkRequest = generateDepartureRequestCheck(requests, value);
        break;  
      default: break;
    }
    checks = checkPlanned ? checkPlanned : checkRequest ? checkRequest : ''; 
    setError(checks ? t(checks) : '');
    !checks && setRequestTime(value);
  };

  const handleChangeRemarks = value => {
    setRemarks(value.currentTarget.value);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCreateRequest = async () => {
    let request;
    const agent = await getAgentContact(contactId);
    const requestSource = await buildRequestSource(agent);

    if(lastRequest) {
      const requesterUserName = await getUserName();
      const requesterEmail = await getUserEmail();
      request = Request.copyOf(lastRequest, updated => {
        updated.source = requestSource;
        updated.actionData = [{
          actionId: action.id,
          actionTypeId: action.type.id,
          movementType: action.movementType,
          timeRequested: requestTime,
          timePlanned: requestTime,
          locationId: action.movementLocation.id,
          approved: RequestState.REQUEST_STATE_PENDING
        }];
        updated.requesterUserName = requesterUserName;
        updated.requesterEmail = requesterEmail;
        updated.remark = remarks;
        updated.updatedAt = new Date().toISOString();
        updated.auditMeta = { ...auditMeta, comment: remarks }
      });
    }

    // Update action with latest request time
    const oldAction = await DataStore.query(Action, action.id);
    await DataStore.save(Action.copyOf(oldAction, updated => {
      updated.timeRequested = requestTime
    }));

    if(!request) {
      // new request
      request = new Request({
        portCall: portCall,
        requestPortCallId_: portCall.id,
        state: RequestState.REQUEST_STATE_PENDING,
        type: requestType,
        source: requestSource,
        portCallData: {
          vesselName: portCall.vesselData.name,
          vesselImo: portCall.vesselData.imo,
          vesselMmsi: portCall.vesselData.mmsi,
          vesselCallsign: portCall.vesselData.callSign
        },
        actionData: [{
          actionId: action.id,
          actionTypeId: action.type.id,
          movementType: action.movementType,
          timeRequested: requestTime,
          timePlanned: requestTime,
          locationId: action.movementLocation.id,
          approved: RequestState.REQUEST_STATE_PENDING
        }],
        agent: agent,
        requesterUserName: await getUserName(),
        requesterEmail: await getUserEmail(),
        remark: remarks,
        auditMeta: {...auditMeta, comment: remarks}
      });
    }

    await DataStore.save(request);

    handleClose();
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            <Typography variant="h5">{t('AgentRequestTimeChangeDialog.Labels.Title', {movement: titleLabel})}</Typography>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box>
            <StringKeyboardDateTimePicker
              warningPastDate={true}
              label={timeLabel}
              fullWidth
              error={error ? true : false}
              helperText={error || ""}
              value={requestTime}
              margin={error ? "none" : "normal"}
              onChange={handleChangeRequestTime}
            />
            <TextField
              style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
              variant="outlined"
              label={t('AgentRequestTimeChangeDialog.Labels.Remarks')}
              fullWidth
              margin='normal'
              value={remarks}
              onChange={handleChangeRemarks}
            />

          </Box>

          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              disabled={error !== ''}
              variant="contained"
              color="primary"
              size="large"
              style={{ margin: '1rem' }}
              onClick={handleCreateRequest}
            >
              {t('AgentRequestTimeChangeDialog.Buttons.SendRequest')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  )
};

export default AgentRequestTimeChangeDialog;
