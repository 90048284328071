
import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { getPortCallDatasByActionId, getPortCallDatasByPortCallId } from '../graphql/queries';
import { onCreatePortCallData, onUpdatePortCallData, onDeletePortCallData } from '../graphql/subscriptions';

/**
 * Returns PortCallData (single) for a given portCall/action, subscribes for changes
 * @prop {String} portCallId
 * @prop {String} actionId
 * @prop {String} type FileType
 */
const usePortCallData = ({ portCallId, actionId, type }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!portCallId && !actionId) return;
    let promise;
    const query = actionId ? getPortCallDatasByActionId : getPortCallDatasByPortCallId;
    const variables = actionId ? { actionId, type: { "eq": type }, limit: 100 } : { portCallId, type: { "eq": type }, limit: 100 };
    (async () => {
      try {
        // query until we get the first valid entry or reach the end
        while (1) {
          promise = API.graphql(graphqlOperation(query, variables));
          const { data } = await promise;
          const item = data?.[Object.keys(data).pop()].items?.filter(i => !i._deleted).pop() || null;
          if (item || !data.nextToken) {
            setData(item);
            break;
          }
        }
      } catch (err) {
        console.log('usePortCallData Error:', err);
      }
    })();
    const subscription = API.graphql(graphqlOperation(onCreatePortCallData)).subscribe({
      next: ({ value }) => {
        const data = value?.data?.onCreatePortCallData;
        if (!data || data.portCallId !== portCallId || (actionId && data.actionId !== actionId)) return;
        setData(data);
      }
    });
    const subscription2 = API.graphql(graphqlOperation(onUpdatePortCallData)).subscribe({
      next: ({ value }) => {
        const data = value?.data?.onUpdatePortCallData;
        if (!data || data.portCallId !== portCallId || (actionId && data.actionId !== actionId)) return;
        setData(data._deleted ? null : data); // idfk, do the check just in case
      }
    });
    const subscription3 = API.graphql(graphqlOperation(onDeletePortCallData)).subscribe({
      next: ({ value }) => {
        const data = value?.data?.onDeletePortCallData;
        if (!data || data.portCallId !== portCallId || (actionId && data.actionId !== actionId)) return;
        setData(null);
      }
    });
    return () => {
      API.cancel(promise);
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, [setData, portCallId, actionId, type])
  return data; // could probably return a setter here too if one wanted for faster updates so user wouldn't wait for subscription
}

export default usePortCallData;