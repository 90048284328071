/* eslint-disable import/no-anonymous-default-export */
import React, { useContext } from 'react';
import { Box, CardContent, FormLabel, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';
import CurrencyAutocomplete from '../Currency/CurrencyAutocomplete';
import { Setting } from '../../models';
import SettingConstants from '../../constants/SettingConstants';
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useLanguage from '../../hooks/useLanguage';
import useFilterSetting from '../../hooks/useFilterSettings';
import { LanguageLabels } from '../../constants/LanguageLabels';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { settings } = useContext(DataStoreContext);
  const appCurrencySetting = settings.find(s => s.name === SettingConstants.APP_CURRENCY);
  const { languages, language, setLanguage } = useLanguage();
  const { filterSetting, setFilterSetting } = useFilterSetting();

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box height="2rem" />
      <CardContent>
        <CurrencyAutocomplete
          label={t('AdminGeneralSettings.Labels.ApplicationCurrency')}
          helperText={t('AdminGeneralSettings.Labels.ApplicationCurrencyHelp')}
          disableClearable
          value={appCurrencySetting && JSON.parse(appCurrencySetting.value)}
          onChange={(value) => {
            DataStore.save(appCurrencySetting
              ? Setting.copyOf(appCurrencySetting, updated => { updated.value = JSON.stringify(value) })
              : new Setting({ name: SettingConstants.APP_CURRENCY, value: JSON.stringify(value) })
            );
          }}
        />

        <Box height="2rem" />
        <FormControl variant="outlined" style={{ width: "18rem" }}>
          <InputLabel shrink>{t('AdminGeneralSettings.Labels.Language')}</InputLabel>
          <Select
            value={language || ""}
            onChange={(e) => setLanguage(e.target.value)}
            label={t('AdminGeneralSettings.Labels.Language')}
          >
            {languages.map(l =>
              <MenuItem value={l} key={l}>{LanguageLabels[l]}</MenuItem>
            )}
          </Select>
          <FormHelperText>{t('AdminGeneralSettings.Labels.LanguageHelp')}</FormHelperText>
        </FormControl>

        <Box height="2rem" />
        <FormControl variant="outlined">
          <FormLabel>{t('AdminGeneralSettings.Labels.FiltersTitle')}</FormLabel>
          <FormControlLabel
            control={
              <Switch
                id="AdminFilterEditSwtich"
                checked={filterSetting.adminEditOnly}
                onChange={(event) => setFilterSetting({ adminEditOnly: event.target.checked })}
                name="adminFilterEdit"
              />  
            }
            label={t("AdminGeneralSettings.Labels.AdminFilters")}
          />
        </FormControl>
      </CardContent>
    </Box>
  );
};
