import React from 'react';

import { makeStyles, ListItem, ListItemText } from '@material-ui/core';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { parseAISReceiverTimestamp } from './utils/ais-utils';

const useStyles = makeStyles(theme => ({
  item: {
    padding: 0
  },
}));

const AISReceiverInfoPopup = ({meta}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { dateTimeFormat } = useDateTimeSetting();

  const dayJsTimestamp = parseAISReceiverTimestamp(meta?.lastTimestamp);
  const timestamp = dayJsTimestamp.isValid() ? format(dayJsTimestamp.toDate(), dateTimeFormat) : undefined;
  
  const isValidMetaData = meta?.vesselMMSI && timestamp

  return (
    <>
      {isValidMetaData 
      ? <>
          {meta?.receiverName && <ListItem component="div">
            <ListItemText
              className={classes.item}
              primary={meta?.receiverName}
              secondary={t('AIS.Labels.ReceiverName')}
              primaryTypographyProps={{
                noWrap: true
              }}
            />
          </ListItem>}
          <ListItem component="div">
            <ListItemText
              className={classes.item}
              primary={timestamp}
              secondary={t('AIS.Labels.LastMessageTime')}
              primaryTypographyProps={{
                noWrap: true
              }}
            />
          </ListItem>
        </>
      : <ListItem component="div">
          <ListItemText
            className={classes.item}
            primary={t('AIS.Labels.InvalidAISData')}
            primaryTypographyProps={{
              noWrap: true
            }}
          />
        </ListItem>
      }
    </>
  );
};

export default AISReceiverInfoPopup;