import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PortCall } from '../../../models';
 
const useStyles = makeStyles(theme => ({
  formLabelLabel: {
    // textTransform: 'uppercase',
    marginBottom: '9px',
    marginTop: '26px',
  },
}));

export const RemarksEdit = ({ 
  portCall,
  disabled,
  setPortCall,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        component="div"
        className={classes.formLabelLabel}
        id="port-call-remarks"
        variant="h6"
      >
        Remarks
      </Typography>
      <TextField
        disabled={disabled}
        variant="outlined"
        fullWidth
        multiline
        onChange={e => setPortCall(PortCall.copyOf(portCall, updated => { updated.remarks = e.target.value }))}
        value={portCall.remarks || ''}
      />
    </>
  )
}

export default RemarksEdit;