/**
 * Group action into portcalls array 
 * @param {Array[Action]} actions
 * @returns Array of port calls
 */
export const groupActionsByPortCall = (actions) => {
	if (!actions) return [];

	const portCalls = new Map();
	for (const action of actions) {
		const id = action?.actionPortCallId;
		if (!id) continue;
		const { actions, ...portcall } = action.portCall;
		if (portCalls.has(id)) {
			const oldAction = portCalls.get(id).actions;
			const portcallAction = oldAction.concat(action);

			portCalls.set(id, {
				...portcall,
				actions: portcallAction
			});
		} else {
			portCalls.set(id, {
				...portcall,
				actions: [action]
			});
		}
	}

	return convertObjectMapToArray(portCalls);
};

/**
 * Return array of Map object
 * @param {Map} Map of objects
 * @returns Array
 */
const convertObjectMapToArray = (map = new Map()) => {
	if (!map.size) return [];
	const array = [];
	map.forEach((value, key) => {
		if (Object.keys(value).length) {
			array.push({ ...value })
		}
	});
	return array;
}