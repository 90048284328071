import React, { useState } from "react";
import {
  InformationOutline,
  DotsVertical,
  Close,
} from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  IconButton,
  ListItem,
  Tooltip,
  Box
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import VesselListItem from '../Vessel/VesselListItem';
import VesselTooltip from '../Tooltip/VesselTooltip';
import RequestRemark from './RequestRemark';
import { RequestTypeLabelsKeys } from '../../constants/RequestConstants';
import { RequestState, RequestType, Request } from "../../models";
import { DataStore } from "aws-amplify";
import useAuditMeta from "../../hooks/useAuditMeta";
import TextInputDialog from "../Dialog/TextInputDialog";
import { format } from 'date-fns';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { useTranslation } from "react-i18next";
import { DateFnsLanguageMap } from "../../translations";
import SpotlightMapDialog from "../Spotlight/SpotlightMapDialog";

const {
  REQUEST_TYPE_CREATE_PORTCALL,
  REQUEST_TYPE_UPDATE_PORTCALL_CARGO,
  REQUEST_TYPE_CANCEL_PORTCALL,
  REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED,
  REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED,
  REQUEST_TYPE_UPDATE_ACTION_DRAUGHT,
  REQUEST_TYPE_CANCEL_ACTION,
  REQUEST_TYPE_CREATE_DEPARTURE
} = RequestType;

const {
  REQUEST_TYPE_CREATE_PORTCALL: CREATE_PORTCALL,
  REQUEST_TYPE_UPDATE_PORTCALL_CARGO: UPDATE_PORTCALL_CARGO,
  REQUEST_TYPE_CANCEL_PORTCALL: CANCEL_PORTCALL,
  REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED: UPDATE_DEPARTURE,
  REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED: UPDATE_ARRIVAL,
  REQUEST_TYPE_UPDATE_ACTION_DRAUGHT: UPDATE_DRAUGHT,
  REQUEST_TYPE_CANCEL_ACTION: CANCEL_ACTION,
  REQUEST_TYPE_CREATE_DEPARTURE: CREATE_DEPARTURE
} = RequestTypeLabelsKeys;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: "2rem",
  }
}))

const RequestInbox = ({
  requests = [],
  setOpenDrawer,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n, t } = useTranslation();
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  const handleClick = (requestType, requestId, id, actionData) => {
    let link;
    switch (requestType) {
      case REQUEST_TYPE_CANCEL_ACTION:
      case REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED:
      case REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED:
      case REQUEST_TYPE_UPDATE_ACTION_DRAUGHT:
      case REQUEST_TYPE_CREATE_DEPARTURE:
        link = actionData ? `/port-call/${id}/action/${actionData[0].actionId}/requests/${requestId}` : `/port-call/${id}/requests/${requestId}`;
        break;
      case REQUEST_TYPE_CANCEL_PORTCALL:
      case REQUEST_TYPE_CREATE_PORTCALL:
        link = `/port-call/${id}/requests/${requestId}`;
        break;
      case REQUEST_TYPE_UPDATE_PORTCALL_CARGO:
        link = `/port-call/${id}`;
        break;
      default:
        return;
    }
    setAnchorEl(null)
    setOpenDrawer(false);
    history.push(link);
  }

  const renderTitle = (requestType) => {
    let title;
    switch (requestType) {
      case REQUEST_TYPE_CANCEL_ACTION:
      case REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED:
      case REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED:
      case REQUEST_TYPE_UPDATE_ACTION_DRAUGHT:
      case REQUEST_TYPE_CREATE_DEPARTURE:
        title = t('RequestInbox.Labels.RequestHeadingsMovement');
        break;
      case REQUEST_TYPE_CANCEL_PORTCALL:
      case REQUEST_TYPE_CREATE_PORTCALL:
      case REQUEST_TYPE_UPDATE_PORTCALL_CARGO:
        title = t('RequestInbox.Labels.RequestHeadingsPortCall');
        break;
      default:
        break;
    }
    return title;
  }

  const renderVessel = (vesselData) => {
    return (
      <PopoverTooltip transformOriginHorizontal="left" anchorOriginHorizontal="center" tooltip={<VesselTooltip vesselData={vesselData} setMapDialogOpen={setMapDialogOpen}/>}>
        <VesselListItem
          disableGutters={true}
          vesselData={vesselData}
        />
      </PopoverTooltip>)
  }

  const renderRequestType = (requestType, requestState) => {
    let type = "";

    switch (requestType) {
      case REQUEST_TYPE_CANCEL_ACTION:
        type = t(CANCEL_ACTION);
        break;
      case REQUEST_TYPE_UPDATE_PORTCALL_CARGO:
        type = t(UPDATE_PORTCALL_CARGO);
        break;
      case REQUEST_TYPE_CANCEL_PORTCALL:
        type = t(CANCEL_PORTCALL);
        break;
      case REQUEST_TYPE_CREATE_PORTCALL:
        type = t(CREATE_PORTCALL);
        break;
      case REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED:
        type = t(UPDATE_ARRIVAL);
        break;
      case REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED:
        type = t(UPDATE_DEPARTURE);
        break;
      case REQUEST_TYPE_UPDATE_ACTION_DRAUGHT:
        type = t(UPDATE_DRAUGHT);
        break;
      case REQUEST_TYPE_CREATE_DEPARTURE:
        type = t(CREATE_DEPARTURE);
        break;
      default:
        type = t('RequestInbox.Labels.Invalid');
        break;
    }

    return renderText(type, requestState === RequestState.REQUEST_STATE_NOTIFICATION ? t('RequestInbox.Labels.Notification') : t('RequestInbox.Labels.RequestType'), true);
  }

  const renderText = (text, secText, disableGutters) => {
    return (
      <ListItem
        disableGutters={disableGutters}
      >
        <Tooltip title={text}>
          <ListItemText
            primary={text}
            secondary={secText}
            primaryTypographyProps={{ noWrap: true }}
          />
        </Tooltip>
      </ListItem>
    )
  }

  const renderMenuIcon = (id) => {
    return (
      <IconButton
        id={`openRequestButton-${id}`}
        onClick={e => { setAnchorEl({ target: e.currentTarget, id }); e.preventDefault(); }}
      >
        <DotsVertical />
      </IconButton>
    )
  }

  const [dismissRequest, setDismissRequest] = useState(false);
  const auditMeta = useAuditMeta();
  const handleDismiss = async (comment) => {
    await DataStore.save(Request.copyOf(dismissRequest, updated => {
      updated.state = RequestState.REQUEST_STATE_APPROVED
      updated.auditMeta = { ...auditMeta, comment };
      updated.updatedAt = new Date().toISOString();
    }));
    setDismissRequest(null);
  }
  const renderDismissNotification = (request) => {
    return (
      <IconButton
        onClick={() => setDismissRequest(request)}
      >
        <Close />
      </IconButton>
    )
  }


  const renderDate = (date) => {
    const dateText = new Date(date);
    return renderText(format(dateText, dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }), t('RequestInbox.Labels.UpdatedAt'), true);
  }

  const renderMenu = (id, dataType, portCallId, actionData) => {
    if (!anchorEl) return;
    const { target, id: targetId } = anchorEl;
    if (targetId !== id) return;
    return (
      <Menu
        open={Boolean(target)}
        anchorEl={target}
        onClose={() => setAnchorEl(null)}
      >
        <ListSubheader>{renderTitle(dataType)}</ListSubheader>
        <MenuItem 
          id={"RequestInboxShowDetails"}
          onClick={() => handleClick(dataType, id, portCallId, actionData)}
        >
          <ListItemIcon>
            <InformationOutline />
          </ListItemIcon>
          <ListItemText
            primary={t('RequestInbox.Labels.ShowDetails')}
          />
        </MenuItem>
      </Menu>
    )
  }



  const renderRequest = (request) => {
    const {
      id,
      state,
      portCall: { id: portCallId, vesselData },
      type,
      source,
      updatedAt,
      actionData,
      remark
    } = request;
    return (
      <React.Fragment key={id}>
        <Grid item xs={12}>
          <Grid alignContent="center" container spacing={2} alignItems='center'>
            <Grid item xs={3}>
              {renderVessel(vesselData)}
            </Grid>
            <Grid item xs={2}>
              {renderRequestType(type, state)}
            </Grid>
            <Grid item xs={2}>
              {renderDate(updatedAt, t('RequestInbox.Labels.Date'))}
            </Grid>
            <Grid item xs={3}>
              {renderText(source, t('RequestInbox.Labels.Source'), true)}
            </Grid>
            <Grid item xs={1}>
              {remark && <RequestRemark remark={remark} />}
            </Grid>
            <Grid item xs={1}>
              <Box display="flex">
                {renderMenuIcon(id)}
                {state === RequestState.REQUEST_STATE_NOTIFICATION && renderDismissNotification(request)}
              </Box>
            </Grid>
            {renderMenu(id, type, portCallId, actionData)}
          </Grid>
        </Grid>
        { mapDialogOpen && 
          <SpotlightMapDialog
            open={mapDialogOpen}
            onClose={() => setMapDialogOpen(false)}
            vesselData={request.portCall.vesselData}
          /> 
        }
      </React.Fragment>
    )
  }

  const renderNoRequests = () => {
    return (
      <Grid item xs={12}>
        <Typography>{t('RequestInbox.Labels.NoRequests')}</Typography>
      </Grid>
    )
  }

  return (
    <div className={classes.root} >
      <Grid alignContent="center" justifyContent="space-between" container spacing={2}>
        {requests.length > 0 ? requests.sort(({ createdAt: a }, { createdAt: b }) => new Date(b) - new Date(a)).map(request => renderRequest(request)) : renderNoRequests()}
      </Grid>
      {dismissRequest && <TextInputDialog
        open={Boolean(dismissRequest)}
        title={t('RequestInbox.Labels.AcknowledgeUpdate')}
        onClose={() => setDismissRequest(null)}
        onConfirm={handleDismiss}
        inputLabel={t('RequestInbox.Labels.Comments')}
      />}
    </div>
  )
}

export default RequestInbox;