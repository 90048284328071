import { LOGIN_CONSTANTS } from "./constants";

export const CustomFormFields = {
  confirmResetPassword: {
    confirmation_code: {
      placeholder: LOGIN_CONSTANTS.Authentication.Common.Labels.EnterConfirmationCode,
      isRequired: true,
    },
  }
}
