import { DataStore } from 'aws-amplify';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Action, ActionType, Cargo, Contact, PortCall, PortCallStatus, Request, RequestState, Vessel, Location, CargoType, Category, ActionState } from '../models';
import { buildConnectionSubscription } from '../utils/utils';
import { InactivePortCallStatus } from '../constants/StateCategories';

/**
 * usePortCallDetails
 * 
 * if queryId(portCallId) provided then it will use queryId, else take port call Id from url path (only works on actions details and portcall details page)
 * Helper for querying and managing subscriptions of selected port call used in PortCallDetails and ActionDetails
 * 
 */

const usePortCallDetails = (queryId = null) => {
  const [item, setItem] = useState(null);
  const location = useLocation();
  const portCallId = useMemo(() => queryId ?  queryId: location.pathname.startsWith('/port-call') && location.pathname.substring(11, 11 + 36), [location, queryId]);
  
  useEffect(() => {
    // could speed up switching between PortCallDetails/ActionDetails if this hook skipped re-querying between them but that may introduce other issues
    if (!portCallId) {
      setItem(() => null);
      return;
    }
    let cancelled = { current: false };

    const update = async () => {
      var t0 = performance.now();
      const portCall = await DataStore.query(PortCall, portCallId);
      if (!portCall) return;
      const category = (portCall.category && await DataStore.query(Category, portCall.category.id)) || null;
      const vessel = (portCall.vessel && await DataStore.query(Vessel, portCall.vessel.id)) || null;
      const actions = await DataStore.query(Action, c => c.actionPortCallId_("eq", portCallId).state("ne",ActionState.SUPERSEDED));
      const cargos = await DataStore.query(Cargo, c => c.cargoPortCallId("eq", portCallId));
      const requests = !InactivePortCallStatus.includes(portCall.status)
        ? await DataStore.query(Request, c => c.requestPortCallId_("eq", portCallId).state("eq", RequestState.REQUEST_STATE_PENDING))
        : [];

      // Resolve connections from Action
      const newActions = []
      for(let action of actions) {
        let newAction;
        if(action.actionActionAgentId && !action.actionAgent) {
          const contact = (await DataStore.query(Contact, c => c.id("eq", action.actionActionAgentId))).pop();
          newAction = Action.copyOf(action, updated => {
            updated.actionAgent = contact;
          });
        }
        if(action.actionMovementLocationId && !action.movementLocation) {
          const location = (await DataStore.query(Location, c => c.id("eq", action.actionMovementLocationId))).pop();
          newAction = Action.copyOf(action, updated => {
            updated.movementLocation = location;
          });
        }
        if(newAction) newActions.push(newAction);
        else newActions.push(action);
      }
      var t1 = performance.now()
      console.log('usePortCallDetails:', `Queried in ${Math.floor(t1 - t0)} milliseconds.`);

      if (!cancelled.current) {
        setItem(PortCall.copyOf(portCall, updated => {
          updated.category = category;
          updated.vessel = vessel;
          updated.actions = newActions;
          updated.cargos = cargos;
          updated.requests = requests;
        }));
      }
    };
    update();

    // Add all subscriptions to array so they can be unsubscribed on unmount
    const subscriptions = [];
    subscriptions.push(DataStore.observe(PortCall, portCallId).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && 
        (opType === "UPDATE" || opType === "INSERT") &&
        (element.status !== PortCallStatus.DELETED);

      if (isUpdateOp) {
        // access latest data
        let item;
        setItem(prev => {
          item = prev;
          return prev; // this doesn't emit a state change
        });
        // check version
        if (item && item._version >= element._version) return;
        if (cancelled.current) return;

        // update
        const category = element.portCallCategoryId ? await DataStore.query(Category, element.portCallCategoryId) : null;
        const vessel = element.portCallVesselId ? await DataStore.query(Vessel, element.portCallVesselId) : null;
        if (cancelled.current) return;
        setItem(prev => !prev ? prev :
          PortCall.copyOf(element, updated => {
            // connections may have changed so make this case generic and requery at all times (id lookup is fast)
            updated.category = category;
            updated.vessel = vessel;
            // take connections from cached data
            updated.actions = prev.actions;
            updated.cargos = prev.cargos;
            updated.requests = 
              ( prev.status !== PortCallStatus.CANCELLED &&
                prev.status !== PortCallStatus.DELETED &&
                prev.status !== PortCallStatus.CLOSED)
            ? prev.requests
            : [];
          })
        );
      } else {
        // remove
        setItem(null);
      }
    }));
    
    subscriptions.push(DataStore.observe(Action, c => c.actionPortCallId_("eq", portCallId)).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // console.log('usePortCallDetails: Action subscription', element);

      // access latest data
      let item;
      setItem(prev => {
        item = prev && prev.actions.find(i => i.id === element.id);
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled.current) return;

      if (isUpdateOp) {
        // update
        const t0 = performance.now();
        // action connections
        const portCall = element?.portCall.id ? await DataStore.query(PortCall, element.portCall.id) : null;
        const actionAgent = element.actionActionAgentId ? await DataStore.query(Contact, element.actionActionAgentId) : null;
        const type = element.actionTypeId ? await DataStore.query(ActionType, element.actionTypeId) : null;
        const movementLocation = element.actionMovementLocationId ? await DataStore.query(Location, element.actionMovementLocationId) : null;
        const t1 = performance.now();

        console.log('usePortCallDetails Action subscription:', `Queried in ${Math.floor(t1 - t0)} milliseconds.`);
        if (cancelled.current) return;
        // console.log('usePortCallDetails element', element);
        // console.log('usePortCallDetails portCall', portCall);
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            // update relevant action
            updated.actions = [
              ...updated.actions.filter(a => a.id !== element.id),
              Action.copyOf(element, updated => {
                updated.portCall = portCall
                updated.actionAgent = actionAgent;
                updated.type = type;
                updated.movementLocation = movementLocation;
              })
            ];
          })
        );
      } else {
        // remove
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            updated.actions = updated.actions.filter(a => a.id !== element.id);
          })
        );
      }
    }));
    
    subscriptions.push(DataStore.observe(Cargo, c => c.cargoPortCallId("eq", portCallId)).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");
      console.log('element:', element, 'opType:', opType)

      // access latest data
      let item;
      setItem(prev => {
        item = prev && prev.cargos && prev.cargos.find(c => c.id === element.id);
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled.current) return;

      if (isUpdateOp) {
        const type = element.cargoTypeId ? await DataStore.query(CargoType, element.cargoTypeId) : null;
        if (cancelled.current) return;
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            updated.cargos = [
              ...(updated.cargos ? updated.cargos.filter(c => c.id !== element.id) : []),
              Cargo.copyOf(element, updated => { updated.type = type; })
            ];
          })
        );
        
      } else if (item) {
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            updated.cargos = updated.cargos ? updated.cargos.filter(c => c.id !== element.id) : [];
          })
        );
      }
    }));
    
    subscriptions.push(DataStore.observe(Request, c => c.requestPortCallId_("eq", portCallId)).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT") && (element.state === RequestState.REQUEST_STATE_PENDING);

      // access latest data
      let item;
      setItem(prev => {
        item = prev && prev.requests && prev.requests.find(i => i.id === element.id);
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled.current) return;

      if (isUpdateOp) {
        const agent = element.requestAgentId ? await DataStore.query(Contact, element.requestAgentId) : null;
        if (cancelled.current) return;
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            updated.requests = [
              ...(updated.requests ? updated.requests.filter(c => c.id !== element.id) : []),
              Request.copyOf(element, updated => { updated.agent = agent; })
            ]
          })
        );
      } else if (item) {
        setItem(prev => !prev ? prev :
          PortCall.copyOf(prev, updated => {
            updated.requests = updated.requests ? updated.requests.filter(c => c.id !== element.id) : [];
          })
        );
      }
    }));
    
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItem, model: PortCall, fieldName: "category", fieldModel: Category }));
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItem, model: PortCall, fieldName: "vessel", fieldModel: Vessel }));
    // shortcut to handle portCall.[actions] updates
    const setItemActions = _ => setItem(prev => prev ? PortCall.copyOf(prev, updated => { updated.actions = _(prev.actions); }) : prev);
    // portCall is already getting handled above, leaving it here for brevity sake
    // subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItemActions, model: Action, fieldName: "portCall", fieldModel: PortCall }));
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItemActions, model: Action, fieldName: "actionAgent", fieldModel: Contact }));
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItemActions, model: Action, fieldName: "type", fieldModel: ActionType }));
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItemActions, model: Action, fieldName: "movementLocation", fieldModel: Location }));
    subscriptions.push(buildConnectionSubscription({ cancelled, setItems: setItemActions, model: Action, fieldName: "vessel", fieldModel: Vessel }));

    return () => {
      subscriptions.map(s => s.unsubscribe());
      cancelled.current = true;
    }
  }, [setItem, portCallId]);
  return item;
};

export default usePortCallDetails;