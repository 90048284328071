
export const PortCallDetailsHref = {
  HREF_ITINERARY: 'port-call-itinerary',
  HREF_VESSEL: 'port-call-vessel',
  HREF_IDENTITY: 'port-call-vessel-identity',
  HREF_DETAILS: 'port-call-vessel-details',
  HREF_VESSEL_DIMENSIONS: 'port-call-vessel-dimensions',
  HREF_VESSEL_TONNAGE: 'port-call-vessel-tonnage',
  HREF_VESSEL_AGENT: 'port-call-vessel-agent',
  HREF_VESSEL_PROPULSION: 'port-call-vessel-propulsion',
  HREF_VESSEL_RUDDER: 'port-call-vessel-rudder',
  HREF_VESSEL_FWD_AZIMUTH: 'port-call-vessel-ancilliary-fwd-azimuth',
  HREF_VESSEL_AFT_AZIMUTH: 'port-call-vessel-ancilliary-aft-azimuth',
  HREF_VESSEL_FWD_TUNNEL: 'port-call-vessel-ancilliary-fwd-tunnel-thruster',
  HREF_VESSEL_AFT_TUNNEL: 'port-call-vessel-ancilliary-aft-tunnel-thruster',
  HREF_VESSEL_CERTIFICATES: 'port-call-vessel-certificates',
  HREF_CARGO: 'port-call-cargo',
  HREF_REMARKS: 'port-call-remarks',
  HREF_CATEGORY: 'port-call-category',
  HREF_TAGS: 'port-call-tags',
  HREF_ATTACHMENTS: 'port-call-attachments'
};
