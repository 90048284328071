import React from 'react';
import { CargoType } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography, MenuItem } from '@material-ui/core';
import { CargoUnitNames } from '../../constants/Units';
import { METRES, TONNES, METRES_CUBED, EACH } from '../../constants/Units';
import { useState, useEffect, useContext } from 'react';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { DataStore } from 'aws-amplify';

const AdminCargoTypeHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('CargoType.Labels.Name')} flexBasis="50%" />
      <AdminModelEditorHeaderColumn label={t('CargoType.Labels.Unit')} flexBasis="50%" />
    </>
  );
};

const AdminCargoTypeListItemContent = ({ value }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box style={{ flexBasis: "50%" }} id={value.name}>
        <Typography
        >
          {value.name}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        noWrap style={{
          flexBasis: "50%",
          opacity: 0.5,
          marginLeft: "1rem"
        }}
      >
        {t(CargoUnitNames[value.units])}
      </Typography>
    </>
  );
};

const AdminCargoTypeFormContent = ({ 
  value, 
  onChange, 
  onChangeNumber, 
  outerValue, 
  setValidName,
}) => {
  const { t } = useTranslation();
  const units = [METRES, TONNES, METRES_CUBED, EACH]
  const { cargoTypes } = useContext(DataStoreContext)
  const [duplicationErrorMessage, setDuplicationErrorMessage] = useState('')

  // Checks the existence of cargo type name 
  const existentCargoTypeName = cargoTypes.filter(c => c.name === value.name).filter(c => !c.deleted).length > 0
  // Checks the existence of cargo type 
  const existentCargoType = cargoTypes.filter(c => c.id === outerValue.id).length > 0
  // Returns the name of the existing cargo type
  const nameOfExistingCargoType = existentCargoTypeName && cargoTypes.filter(c => c.name === value.name)[0].name

  useEffect(() => {
    if (existentCargoType || existentCargoType.deleted) {
      nameOfExistingCargoType && (nameOfExistingCargoType !== outerValue.name) ?
        setDuplicationErrorMessage(t('CargoType.Labels.DuplicateName')) : setDuplicationErrorMessage('')
    } else existentCargoTypeName ?
      setDuplicationErrorMessage(t('CargoType.Labels.DuplicateName')) : setDuplicationErrorMessage('')
  }, [value])

  useEffect(() => {
    if (duplicationErrorMessage){
      setValidName(false)
    } else {
      setValidName(true)
    }
  }, [duplicationErrorMessage])
  
  return (
    <>
      <TextField
        id="CargoTypeName"
        required
        disabled={outerValue.name && true}
        value={value.name || ''}
        name="name"
        label={t('CargoType.Labels.Name')}
        fullWidth variant="outlined"
        margin="normal"
        onChange={onChange}
        error={duplicationErrorMessage !== ''}
        helperText={duplicationErrorMessage}
      />
      <TextField
        select
        id="CargoTypeSelect"
        label={t('CargoType.Labels.Unit')}
        value={value.units || ''}
        onChange={e => onChangeNumber({
          target: { name: 'units', value: e.target.value }
        })}
        fullWidth variant="outlined"
        margin="normal"
        disabled={outerValue.name && true}
      >
        {units.map(unit =>
          <MenuItem value={unit} id={t(CargoUnitNames[unit])}>
            {t(CargoUnitNames[unit])}
          </MenuItem>
        )}
      </TextField>
    </>
  );
};

const AdminCargoType = () => {
  const { t } = useTranslation();
  const itemName = t('AdminCargoType.Labels.CargoType');

  return (
    <>
      <AdminModelEditor
        HeaderContent={AdminCargoTypeHeaderContent}
        ListItemContent={AdminCargoTypeListItemContent}
        FormContent={AdminCargoTypeFormContent}
        model={CargoType}
        sort={(a, b) => (a.name > b.name) ? 1 : -1}
        filter={i => !i.deleted}
        onDelete={async (i) => {
          await DataStore.save(CargoType.copyOf(i, updated => { updated.deleted = true; }));
        }}
        canUpdate={() => true}
        canDelete={() => true}
        canSave={i => Boolean(i.name)}
        canAdd={() => true}
        defaultItem={() => {
          return {
            data: new CargoType({
              name: "",
              units: null
            })
          }
        }}
        customDeleteTooltip={() => t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })}
      />
    </>
  );
};

export default AdminCargoType; 