import { Box, Typography, makeStyles } from "@material-ui/core"
import ActionStateAndTimeListItem from "../../Action/ActionStateAndTimeListItem";
import { ActionMovementType } from "../../../models";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  parent: {
    fontSize: '0.625rem',
    marginBottom: '-0.25rem'
  },
  secondary: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },
  noDetails: { 
    opacity: 0.5,
    fontSize: '0.875rem'
  }
}));

const VoyageMovementListItem = ({ movement, movementType }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    movement?.id ? <Box display='flex' justifyContent='flex-start'>
      <Box marginRight='15px'>
        <ActionStateAndTimeListItem
          action={movement}
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent='center'>
        <Typography variant="caption"className={classes.parent}>{movement?.location?.parentName}</Typography>
        <Typography 
          id='LocationName'
          variant="body1" 
          noWrap>{movement?.location?.name ? movement?.location?.name : t('VoyageBrowser.Labels.LocationNotAllocated')}
        </Typography>
        <Typography variant="body2"className={classes.secondary}>{movement?.location?.portUnlocode}</Typography>
      </Box>
    </Box> : 
    <Typography className={classes.noDetails}>
      {t( movementType === ActionMovementType.DEPARTURE? 'VoyageBrowser.Labels.DepartureNotScheduled' : 'VoyageBrowser.Labels.ArrivalNotScheduled')}
    </Typography>
  )
}

export default VoyageMovementListItem;