
export const Attrs = {
  NAME: 'name',
  COLOR: 'color',
  GEOM: 'geometry',
  META: 'meta',
  HEADING: 'heading',
  OUTLINE: 'outline',
  LAST_TIMESTAMP: 'threshold',
  TYPE: 'type',
  VERSION: 'version',
  SPOTLIGHT: 'spotlight',
  OUTER_RADIUS : 'outerradius',
  CENTRE: 'centre',
  POPUP: 'popup',
};

export const LayerAttrs = {
  TITLE: 'title',
  ID: 'id',
  I18N: 'i18n',
  WMS_LAYERS: 'wms-layers'
};

export const FeatureType = {
  Vessel: 'Vessel',
  AISReceiver: 'AISReceiver',
  Spotlight: 'Spotlight'
};

// export const TYPE5 = 'type5';
// export const SPEED = 'speed';

// Fixed layer Z-index
export const BaseLayerZIndex = 0;
export const AisReceiverLayerZIndex = 100;
export const VesselLayerZIndex = 101;

