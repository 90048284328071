import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { ConfirmResetPassWordHeader } from './Components/ConfirmResetPassword';
import { ForgotPassWordHeader } from './Components/ForgotPassword';
import { ResetPassWordHeader } from './Components/ResetPassword';
import { SignInBody, SignInFooter, SignInHeader } from './Components/SignIn';
import "./custom.css";

export const ComponentsCustomStyle = {
  Header: Header,
  Footer: Footer,

  SignIn: {
    Header: SignInHeader,
    Body: SignInBody,
    Footer: SignInFooter,
  },
  ChangePassword: {
    Header: ForgotPassWordHeader
  },
  ResetPassword: {
    Header: ResetPassWordHeader
  },
  ConfirmResetPassword: {
    Header: ConfirmResetPassWordHeader
  }
};

