import { useEffect, useState, useContext, useCallback, useRef, useMemo } from "react";
import { makeStyles, Typography, Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import { useTariffBookEditorContext, useTariffBookEditorUpdateContext } from "./TariffBookEditorContext";
import { sortActionTypes } from "../../../utils/sorters";
import ConfirmationDialog from "../../ConfirmationDialog";
import ChargeableListEditorRow from './ChargeableListEditorRow'
import DefaultChargeableItemDialog from "./DefaultChargeableItemDialog";
import { getActionTypeName } from '../../../utils/tariffBook';

const useStyes = makeStyles(theme => {
  return {
    tableHeader: {
      width: '100%',
    },
    tableHeaderText: {
      opacity: 0.5
    },
    columHeaders: {
      gap: "10px"
    },
    columnIcon: {
      maxWidth: "40px",
      maxHeight: "40px",
      alignSelf: "auto",
      marginTop: ".6rem",
      width: "100%"
    },
  }
});

const ActionTypeListEditor = ({ onChanged }) => {
  const { t } = useTranslation();
  const classes = useStyes();
  const { actionTypes, tariffUnits } = useContext(DataStoreContext);
  const tariffBook = useTariffBookEditorContext();
  const updateTariffBook = useTariffBookEditorUpdateContext();
  const listRef = useRef();
  const [ allItems, setAllItems ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(undefined);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false);


  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    setSelectedItem(undefined);
  }, []);

  const handleDialogUpdate = (newDefaultItems) => {
    setDialogOpen(false);
    if(!tariffBook.defaultCharges.find(el => el.id === newDefaultItems.id)) {
      // Creating a new entry
      const newDefaultCharges = [ ...tariffBook.defaultCharges, newDefaultItems ];
      updateTariffBook.updateDefaultCharges(newDefaultCharges);
      
      onChanged && onChanged({
        tariffs: tariffBook.tariffs,
        defaultCharges: newDefaultCharges
      });
    } else {
      // Update existing entry
      const newDefaultCharges = tariffBook.defaultCharges.map(el => {
        if(el.id === newDefaultItems.id) return newDefaultItems
        return el;
      });
      updateTariffBook.updateDefaultCharges(newDefaultCharges);
      
      onChanged && onChanged({
        tariffs: tariffBook.tariffs,
        defaultCharges: newDefaultCharges
      });
    }
  };

  const handleDeleteDefaultItem = (defaultItem) => {
    const newDefaultCharges = [ ...tariffBook.defaultCharges.filter(el => el.id !== defaultItem.id) ];
    updateTariffBook.updateDefaultCharges(newDefaultCharges);

    onChanged && onChanged({
      tariffs: tariffBook.tariffs,
      defaultCharges: newDefaultCharges
    });
    
    setDeleteDialogOpen(false);
    setSelectedItem(undefined);
  };

  const handleAddActionType = () => {
    console.log(handleAddActionType);
    setDialogOpen(true);
    setSelectedItem(undefined);
  };

  const handleRowUpdate = (chargeableItem) => {
    setSelectedItem(chargeableItem);
    setDialogOpen(true);
  };

  const handleRowDelete = (chargeableItem) => {
    setSelectedItem(chargeableItem);
    setDeleteDialogOpen(true);
  };

  useEffect(() => {
    // console.log('useEffect --> defaultChanges changed');
    setAllItems(tariffBook.defaultCharges
      .filter(el => el.defaultItems.length > 0)
      .map(el => {
        return {
          name: getActionTypeName(actionTypes.find(at => at.id === el.actionTypeId), el.movementType, t),
          data: {
            ...el,
          },
          onUpdate: (actionType) => handleRowUpdate(actionType),
          onDelete: (actionType) => handleRowDelete(actionType),
        }
      })
      .sort(sortActionTypes)
    );
  }, [tariffBook.defaultCharges, actionTypes, t]);

  const selectedActionTypes = useMemo(() => 
    tariffBook.defaultCharges
      .filter(el => el.defaultItems.length > 0 && el.actionTypeId)
      .map(el => ({ actionTypeId: el.actionTypeId, movementType: el.movementType }))
  , [tariffBook]);

  return (
    <>
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box
        display='flex'
        justifyContent='end'
        alignItems='flex-end'
        paddingTop="16px"
        paddingBottom="16px"
      >
        <Button
          id="TariffBookAddChargeable"
          variant="outlined" 
          color="primary"
          onClick={handleAddActionType}
        >
          {t('AdminTariff.Buttons.AddActionTypeItem')}
        </Button>
      </Box>
      <Box className={classes.columHeaders} display='flex'>
        <Box className={classes.tableHeader} style={{ width: "60%" }}><Typography className={classes.tableHeaderText}>{t('AdminTariff.Labels.ActionType')}</Typography></Box>
        <Box className={classes.tableHeader} style={{ width: "40%" }}><Typography className={classes.tableHeaderText}>{t('AdminTariff.Labels.NumChargeableItems')}</Typography></Box>
        <Box className={classes.columnIcon}/>
        <Box className={classes.columnIcon}/>
      </Box>
      <Box id='ChargeableListEditor' flexGrow="1">
        <AutoSizer>
          {({ height, width }) => (     
            <FixedSizeList
              key={'ChargeableList'}
              itemCount={allItems.length}
              height={height}
              width={width}
              itemSize={50}
              itemData={ allItems }
              ref={listRef}
            >
              {ChargeableListEditorRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
    { dialogOpen &&
      <DefaultChargeableItemDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onUpdate={handleDialogUpdate}
        bookId={tariffBook.bookId}
        tariffs={tariffBook.tariffs}
        defaultCharges={selectedItem}
        selectedActionTypes={selectedActionTypes}
        tariffUnits={tariffUnits}
      />
    }
    { deleteDialogOpen &&
      <ConfirmationDialog
        open={deleteDialogOpen}
        title={t('AdminTariff.Labels.DeleteChargeableItemTitle')}
        message={t('AdminTariff.Labels.DeleteChargeableItemMessage', {
          chargeableItem: getActionTypeName(
            actionTypes.find(at => at.id === selectedItem.actionTypeId), selectedItem.movementType, t)
          })}
        confirmLabel={t('Common.Buttons.Confirm')}
        handleConfirm={() => handleDeleteDefaultItem(selectedItem)}
        handleCancel={() => setDeleteDialogOpen(false)}
      />
    }
  </>
  )
};

export default ActionTypeListEditor;
