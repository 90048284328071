import React, { useRef, useState } from 'react';
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, LinearProgress, TextField, Typography } from "@material-ui/core";
import usePortCallData from "../../hooks/usePortCallData";
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { createPortCallData, createS3File, deletePortCallData, updatePortCallData } from '../../graphql/mutations';
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { CloudDownload, Delete, File, Upload } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/styles';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { format } from 'date-fns';
import { FileTypeLabels, PortCallDataEditLabels } from '../../constants/PortCallDataLabels';
import { getActionTime, isActionAnyArrival, isActionAnyDeparture } from '../../utils/getters'
import StringKeyboardDatePicker from '../Common/StringKeyboardDatePicker';
import ListEditor from '../Common/ListEditor';
import { AuditLog, AuditLogType, FileType, PortCallData_FAL5_CREW_LIST, PortCallData_FAL5_CREW_LIST_Member } from '../../models';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { DateFnsLanguageMap } from '../../translations';

const DOWNLOAD_URL_EXPIRATION_SECONDS = 3600;

const PortCallDataFromFileType = {
  [FileType.FAL5_CREW_LIST]: PortCallData_FAL5_CREW_LIST
}

const PortCallDataDefaults = {
  [FileType.FAL5_CREW_LIST]: new PortCallData_FAL5_CREW_LIST({ members: [] })
};

const useStyles = makeStyles(theme => ({
  chip: {
    height: "3rem",
    width: "100%",
    '& > *': {
      width: "100%"
    },
  },
  chipIcon: {
    color: theme.palette.text.secondary
  },
  chipActions: {
    // opacity: 0.54
  },
  progress: {
    position: "relative",
    top: "-.25rem"
  },
  formDialogPaper: {
    maxWidth: "100%",
    width: "100%"
  },
  formBox: {
    '& > :not(:first-child)': {
      marginLeft: ".5rem"
    }
  },
  formDelete: {
    alignSelf: "center"
  },
  paddedInput: {
    paddingTop: "1rem"
  },
  datePicker: {
    '& > div': {
      paddingTop: "1rem"
    }
  }
}));

/**
 * Upload file to S3 and create S3File record
 * @prop {File} file file to upload (from input file form)
 * @prop {string} portCallId 
 * @prop {string} actionId 
 * @prop {FileType} type 
 * @prop {function} onProgress callback with { loaded, total }
 * @prop {function} onSuccess callback with S3File record
 * @prop {function} onError callback with error
 */
export const uploadS3File = (t, { file, portCallId, actionId, type, auditMeta, onProgress, onSuccess, onError }) => {
  // portcalls/ID/(actions/ID/)FileType/ID/name
  const id = uuidv4();
  let key = `portcalls/${portCallId}/`;
  if (actionId) key += `actions/${actionId}/`;
  key += `${type}/${id}/${file.name}`;

  Storage.put(key, file, {
    progressCallback: onProgress
  })
    .then(async (result) => {
      const { data } = await API.graphql(graphqlOperation(createS3File, {
        input: {
          id,
          name: file.name,
          s3Path: result.key,
          mimeType: file.type,
          sizeBytes: file.size,
          portCallId,
          actionId,
          type,
          auditMeta
        }
      }));
      if (!data.createS3File) onError && onError(new Error("Failed to create S3File record."));
      else onSuccess && onSuccess(data.createS3File);
    })
    .catch(onError);
}

/**
 * Create or update PortCallData entry 
 * @param {*} key S3 key
 */
const savePortCallData = async ({ prev, portCallId, actionId, type, data, portCallDataS3fileId, auditMeta }) => {
  if (prev) {
    return (await API.graphql(graphqlOperation(updatePortCallData, {
      input: {
        id: prev.id,
        _version: prev._version,
        data,
        portCallDataS3fileId,
        auditMeta
      }
    }))).data.updatePortCallData;
  } else {
    return (await API.graphql(graphqlOperation(createPortCallData, {
      input: {
        type,
        portCallId,
        actionId,
        data,
        portCallDataS3fileId,
        auditMeta
      }
    }))).data.createPortCallData;
  }
}

const PortCallDataTextField = (props) =>
  <TextField
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    size="small"
    margin="dense"
    fullWidth
    {...props}
  />

const PortCallDataDatePicker = (props) =>
  <StringKeyboardDatePicker
    allowEmptyValue
    autoOk
    inputVariant="outlined"
    fullWidth
    InputLabelProps={{ shrink: true }}
    margin="dense"
    {...props}
  />

const PortCallDataForm = ({ open, portCall, action, form, setForm, onClose, type, onSave }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={open} classes={{ paper: classes.formDialogPaper }}>
      <DialogTitle>{t(FileTypeLabels[type])}</DialogTitle>
      <DialogContent>
        {type === FileType.FAL5_CREW_LIST && <PortCallDataForm_FAL5_CREW_LIST portCall={portCall} action={action} type={type} form={form} setForm={setForm} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Common.Buttons.DiscardChanges')}</Button>
        <Button color="primary" onClick={onSave}>{t('Common.Buttons.Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const PortCallDataForm_FAL5_CREW_LIST = ({ portCall, action, type, form, setForm }) => {
  const { dateFormat, dateTimeFormat } = useDateTimeSetting();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const setMember = (newItem, index) => setForm(prev => new PortCallDataFromFileType[type]({ ...prev, members: [...prev.members.slice(0, index), newItem, ...prev.members.slice(index + 1)] }));
  const handleCreateMember = () => setForm(prev => new PortCallDataFromFileType[type]({ ...prev, members: [...prev.members, new PortCallData_FAL5_CREW_LIST_Member({ no: prev.members.length + 1 })] }));
  const handleDeleteMember = (index) => setForm(prev => new PortCallDataFromFileType[type]({ ...prev, members: [...prev.members.slice(0, index), ...prev.members.slice(index + 1)] }));
  if (!form) return null;
  return (
    <>
      <Box display="flex">
        <FormControlLabel control={<Checkbox defaultChecked={isActionAnyArrival(action)} color="default" disabled />} label={t('Fal5CrewList.Labels.Arrival')} />
        <FormControlLabel disabled control={<Checkbox defaultChecked={isActionAnyDeparture(action)} color="default" disabled />} label={t('Fal5CrewList.Labels.Departure')} />
      </Box>
      <Box display="flex" className={classes.formBox}>
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._1_1_NameOfShip')} value={portCall?.vesselData?.name} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._1_2_ImoNumber')} value={portCall?.vesselData?.imo} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._1_3_Callsign')} value={portCall?.vesselData?.callSign} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._1_4_VoyageNumber')} disabled />
      </Box>
      <Box display="flex" className={classes.formBox}>
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._2_PortOfArrivalDeparture')} value={action?.movementLocation?.portUnlocode} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._3_DateOfArrivalDeparture')} value={format(new Date(getActionTime(action)), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._4_FlagStateOfShip')} value={portCall?.vesselData?.flag} disabled />
        <PortCallDataTextField variant="filled" label={t('Fal5CrewList.Labels._5_LastPortOfCall')} value={portCall?.lastPort} disabled />
      </Box>
      <Box height="1rem" />
      <ListEditor
        id="PortCallDataEditor"
        list={form?.members}
        setListItem={setMember}
        createLabel={t('Fal5CrewList.Buttons.AddMember')}
        onCreate={handleCreateMember}
        onDelete={handleDeleteMember}
        renderItem={(item, onChange) =>
          <>
            <PortCallDataTextField name="no" value={item.no || ""} label={t('Fal5CrewList.Labels._6_Num')} disabled style={{ width: "40rem" }} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="familyName" value={item.familyName || ""} label={t('Fal5CrewList.Labels._7_FamilyName')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="givenNames" value={item.givenNames || ""} label={t('Fal5CrewList.Labels._8_GivenNames')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="rankOrRating" value={item.rankOrRating || ""} label={t('Fal5CrewList.Labels._9_RankOrRating')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="nationality" value={item.nationality || ""} label={t('Fal5CrewList.Labels._10_Nationality')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataDatePicker
              value={item.dateOfBirth}
              format={dateFormat}
              onChange={value => onChange({ target: { name: "dateOfBirth", value } })}
              okLabel={t('Common.Buttons.Confirm')}
              label={t('Fal5CrewList.Labels._11_DateOfBirth')}
              className={classes.datePicker}
            />
            <PortCallDataTextField name="placeOfBirth" value={item.placeOfBirth || ""} label={t('Fal5CrewList.Labels._12_PlaceOfBirth')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="gender" value={item.gender || ""} label={t('Fal5CrewList.Labels._13_Gender')} onChange={onChange} style={{ width: "60rem" }} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="natureOfIdentityDocument" value={item.natureOfIdentityDocument || ""} label={t('Fal5CrewList.Labels._14_NatureOfIdentidyDoc')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="numberOfIdentityDocument" value={item.numberOfIdentityDocument || ""} label={t('Fal5CrewList.Labels._15_NumberOfIdentidyDoc')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataTextField name="issuingStateOfIdentityDocument" value={item.issuingStateOfIdentityDocument || ""} label={t('Fal5CrewList.Labels._16_IssuingStateOfIdentityDoc')} onChange={onChange} InputProps={{ className: classes.paddedInput }} />
            <PortCallDataDatePicker
              value={item.expiryDateOFIdentityDocument || ""}
              format={dateFormat}
              onChange={value => onChange({ target: { name: "expiryDateOFIdentityDocument", value } })}
              okLabel={t('Common.Buttons.Confirm')}
              label={t('Fal5CrewList.Labels._17_ExpireDateOfIdentityDoc')}
              className={classes.datePicker}
            />
          </>
        }
      />
    </>
  )
}

const PortCallDataEdit = ({ portCall, action, type }) => {
  const { t, i18n } = useTranslation();
  const auditMeta = useAuditMeta();
  const inputRef = useRef(null);
  const { dateTimeFormat } = useDateTimeSetting();
  const [progress, setProgress] = useState(null);
  const [confirmProps, setConfirmProps] = useState({ open: false});
  const hideConfirm = () => setConfirmProps(prev => ({ ...prev, open: false }));
  const [form, setForm] = useState(null);
  const [openForm, setOpenForm] = useState(false); // to prevent flickering when closing
  const data = usePortCallData({ portCallId: portCall?.id, actionId: action?.id, type });
  const handleEditForm = () => {
    setForm(new PortCallDataFromFileType[type](JSON.parse(data.data)));
    setOpenForm(true);
  };
  const handleCreateForm = async () => {
    if (data) {
      setConfirmProps({
        title: PortCallDataEditLabels.REPLACE_TITLE(t, type) ,
        message: PortCallDataEditLabels.REPLACE_CREATEFORM_MESSAGE(t, type),
        onClose: hideConfirm,
        onConfirm: () => {
          hideConfirm();
          setForm(PortCallDataDefaults[type]);
          setOpenForm(true);
        },
        open: true
      });
    } else {
      setForm(PortCallDataDefaults[type]);
      setOpenForm(true);
    }
  };
  const handleSaveForm = async () => {
    setProgress(0);
    setOpenForm(false);
    await savePortCallData({ prev: data, portCallId: portCall?.id, actionId: action?.id, type, data: JSON.stringify(form), portCallDataS3fileId: null, auditMeta });
    setProgress(null);
  };
  // check if data exists and ask for confirmation
  const handlePreUpload = (e) => {
    if (data) {
      setConfirmProps({
        title: PortCallDataEditLabels.REPLACE_TITLE(t, type),
        message: PortCallDataEditLabels.REPLACE_UPLOAD_MESSAGE(t, type),
        onClose: hideConfirm,
        onConfirm: () => { hideConfirm(); inputRef.current.click(); },
        open: true
      });
    } else {
      inputRef.current.click();
    }
  }
  // on file input change
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    uploadS3File(t, {
      file, portCallId: portCall?.id, actionId: action?.id, type, auditMeta,
      onProgress: (progress) => setProgress((progress.loaded / progress.total) * 100),
      onSuccess: async (s3file) => {
        await savePortCallData({ prev: data, portCallId: portCall?.id, actionId: action?.id, type, data: null, portCallDataS3fileId: s3file.id, auditMeta });
        await DataStore.save(new AuditLog({
          type: data?.s3file ? AuditLogType.AUDIT_FILE_UPDATE : AuditLogType.AUDIT_FILE_CREATE,
          timeChanged: s3file.createdAt,
          portCallId: portCall.id,
          objectId: action.id,
          ...auditMeta,
          data: JSON.stringify({ name: s3file.name }),
          old: JSON.stringify(data?.s3File)
        }));
        setProgress(null);
      },
      onError: (err) => {
        setProgress(null);
        setConfirmProps({
          title: t('PreArrivalUpload.Errors.Title'),
          message: t('PreArrivalUpload.Errors.FailedToUpload'),
          onClose: hideConfirm,
          onConfirm: hideConfirm,
          open: true,
          hideCancel: true
        });
        console.error(err);
      }
    });
  };
  // on download file
  const handleDownload = () => {
    Storage.get(data.s3file.s3Path, { expires: DOWNLOAD_URL_EXPIRATION_SECONDS })
      .then(file => {
        window.open(file, "_blank");
      })
      .catch(err => {
        setConfirmProps({
          title: t('PreArrivalUpload.Errors.Title'),
          message: t('PreArrivalUpload.Errors.FailedToDownload'),
          onClose: hideConfirm,
          onConfirm: hideConfirm,
          open: true,
          hideCancel: true
        });
        console.error(err);
      });
  }
  const handleDelete = () => {
    setConfirmProps({
      title: PortCallDataEditLabels.DELETE_TITLE(t, type),
      message: PortCallDataEditLabels.DELETE_MESSAGE(t, data?.s3file?.name || FileTypeLabels[data.type]),
      onClose: hideConfirm,
      onConfirm: async () => {
        hideConfirm();
        setProgress(0);
        await API.graphql(graphqlOperation(deletePortCallData, { input: { id: data.id, _version: data._version } }));
        await DataStore.save(new AuditLog({
          type: AuditLogType.AUDIT_FILE_DELETE,
          timeChanged: new Date().toISOString(), // delete mutation doesn't change updatedAt
          portCallId: portCall.id,
          objectId: action.id,
          ...auditMeta,
          data: JSON.stringify({ name: data?.s3file?.name }),
          old: JSON.stringify(data?.s3File)
        }));
        setProgress(null);
      },
      open: true,
    });
  };
  const classes = useStyles();
  return (
    <>
      <Box pt=".5rem">
        {progress !== null &&
          <LinearProgress variant={progress ? "determinate" : "indeterminate"} value={progress} className={classes.progress} />
        }
        {data &&
          <Chip
            className={classes.chip}
            size="small"
            label={
              <Box display="flex" alignItems="center">
                <Box pr="0.5rem" color="textSecondary">
                  <File fontSize="small" className={classes.chipIcon} />
                </Box>
                <Box>
                  <Typography variant="body2">{data?.s3file?.name || FileTypeLabels[data.type]}</Typography>
                  <Box display="flex">
                    {Boolean(data.auditMeta?.userId) &&
                      <>
                        <Typography variant="caption" color="textSecondary">{data?.s3file?.id ? t('PreArrivalUpload.Labels.UploadedBy') : ('PreArrivalUpload.Labels.UpdatedBy')} {data.auditMeta.userId}</Typography>
                        <Box ml="0.5rem" />
                      </>
                    }
                    <Typography variant="caption" color="textSecondary">{format(new Date(data.updatedAt), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })}</Typography>
                  </Box>
                </Box>
                <Box ml="auto" display="flex">
                  {data?.s3file &&
                    <Box className={classes.chipActions}>
                      <IconButton onClick={handleDownload} disabled={progress !== null}>
                        <CloudDownload fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                  <Box className={classes.chipActions}>
                    <IconButton onClick={handleDelete} disabled={progress !== null}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            }
          />
        }
      </Box>

      <Box pt=".5rem" display="flex" justifyContent="end" alignItems="center">
        {data?.data
          ? <Button variant="contained" color="primary" disabled={progress !== null} onClick={handleEditForm} >{t('PreArrivalUpload.Buttons.EditForm')}</Button>
          : <Button variant="outlined" color="primary" disabled={progress !== null} onClick={handleCreateForm} >{t('PreArrivalUpload.Buttons.CreateForm')}</Button>
        }
        <Box pl=".25rem" />
        <Button variant="outlined" color="primary" disabled={progress !== null} startIcon={<Upload fontSize="small" />} onClick={handlePreUpload} >
          {t('PreArrivalUpload.Buttons.UploadFile')}
        </Button>
        <input type="file" name="file" onChange={handleUpload} ref={inputRef} hidden />
      </Box>

      <PortCallDataForm open={openForm} portCall={portCall} action={action} form={form} setForm={setForm} onClose={() => { setOpenForm(false); }} type={type} onSave={handleSaveForm} />
      <ConfirmDialog {...confirmProps} />
    </>
  );
};

export default PortCallDataEdit;