import React, { useCallback } from 'react';
import {
  ListItem,
  ListItemText,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Alert } from 'mdi-material-ui';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { ActionTypeIds } from '../../environment';

const ActionTypeListItem = ({
  actionType,
  deletedWarning = false,
  ...other
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getActionTypeName = useCallback(() => {
    if (actionType.id === ActionTypeIds.MOVEMENT) return t('ActionType.Labels.Movement');
    if (actionType.id === ActionTypeIds.EVENT) return t('ActionType.Labels.Event');
    return actionType.name;
  }, [actionType])
  
  return (
    <ListItem {...other} dense component="div" style={{padding: '0rem'}}>
      <ListItemText primary={getActionTypeName()}/>
      {deletedWarning && Boolean(actionType?.deleted) &&
        <>
          <Box mr="0.5rem" />
          <Tooltip title={t("AdminActionType.Labels.DeletedHelp")}>
            <Alert fontSize='small' style={{ color: theme?.palette?.warning?.main }}/>
          </Tooltip>
        </>
      }
    </ListItem>
  );
};

export default ActionTypeListItem;
