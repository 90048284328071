import React from 'react';
import { Autocomplete } from "@material-ui/lab";
import { ListItem, ListItemText, TextField } from '@material-ui/core';
import { tariffUnitFilterOptions, sortTariffUnits } from '../../utils/sorters';
import { TariffUnit } from '../../models';
import useQuery from '../../hooks/useQuery';
import { getFullTarrifUnit } from '../../utils/getters';

export default ({ value, onChange, startAdornment, label, required, ...other }) => {
  const tariffUnits = useQuery(TariffUnit, { sort: sortTariffUnits });
  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={tariffUnits}
      filterOptions={tariffUnitFilterOptions}
      getOptionLabel={(item) => getFullTarrifUnit(item?.name, item.secondaryUnitName)}
      getOptionSelected={(option, value) => option?.id === value?.id}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={getFullTarrifUnit(item?.name, item.secondaryUnitName)} />
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment
          }}
        />
      )}
    />
  );
};
