import React, { } from 'react';
import { TextField, Box, Grid, InputAdornment, IconButton } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { UnitsKeys } from '../../constants/Units';
import CargoTypeAutocomplete from '../Cargos/CargoTypeAutocomplete';
import { Cargo } from '../../models';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

export default ({ cargo, setCargo, onRemove, disabled }) => {
  const { t } = useTranslation()
  const handleChangeCargoType = (type) => {
    setCargo(Cargo.copyOf(cargo, updated => { updated.type = type; }));
  };
  const handleChange = (e) => {
    const { name, value, type, maxLength } = e.currentTarget;
    if (maxLength > 0 && value.length > maxLength) return;
    if (type === 'number' && parseFloat(value) < 0) return; // prevent negative
    console.log('setting cargo', name, parseFloat(value), type);
    setCargo(Cargo.copyOf(cargo, updated => { updated[name] = parseFloat(value); }));
  };

  return (
    <Grid container alignItems="flex-start" className='CargoInstanceContainer'>
      <Grid item style={{ flexGrow: 1 }}>
        <Box pr="1rem">
          <CargoTypeAutocomplete
            value={cargo.type}
            disabled={disabled}
            onChange={handleChangeCargoType}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box pr=".5rem">
          <TextField
            variant="outlined"
            disabled={disabled}
            fullWidth
            InputProps={{
              endAdornment: cargo.type && UnitsKeys[cargo.type.units] &&
                <InputAdornment position="end">{t(UnitsKeys[cargo.type.units])}</InputAdornment>,
            }}
            inputProps={{
              min: 0,
              className: `IncomingCargoInput`
            }}
            label={t('Cargo.Labels.Incoming')}
            type="number"
            name="initialAmount"
            margin="normal"
            onChange={handleChange}
            value={cargo.initialAmount || ''}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box pl=".5rem">
          <TextField
            variant="outlined"
            disabled={disabled}
            fullWidth
            InputProps={{
              endAdornment: cargo.type && UnitsKeys[cargo.type.units] &&
                <InputAdornment position="end">{t(UnitsKeys[cargo.type.units])}</InputAdornment>,
            }}
            inputProps={{
              min: 0,
              className: `OutgoingCargoInput`
            }}
            label={t('Cargo.Labels.Outgoing')}
            type="number"
            margin="normal"
            name="amount"
            onChange={handleChange}
            value={cargo.amount || ''}
          />
        </Box>
      </Grid>
      <Grid item style={{ margin: '.25rem 0 1rem 1rem' }}>
        {!disabled &&
          <IconButton className="RemoveCargoButton" onClick={onRemove}>
            <Close />
          </IconButton>
        }
      </Grid>

    </Grid>
  );
};