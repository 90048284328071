import React from 'react';
import { makeStyles } from '@material-ui/core';
import logoClient from '../resources/images/logoClient.png';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '3rem',
    flexShrink: 0
  },
}));

const variants = {
  client: logoClient,
  default: logoClient
};

export default ({ className, variant, ...other }) => {
  const classes = useStyles();

  return <img alt="logo" src={variants[variant] || variants.default} className={`${classes.root} ${className}`} {...other} />;
};
