import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Component Wraps Number Format Component
// Creates a input component to display currency that is right aligned
const CurrencyFormat = ({value, inputRef, className, onChange, readOnly, currency, displayType, textAlign}) =>
  <NumberFormat
    displayType={displayType}
    readOnly={readOnly}
    value={value}
    getInputRef={inputRef}
    className={className}
    style={{ textAlign: textAlign || "right" }}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    fixedDecimalScale
    decimalScale={2}
    prefix={currency}
  />;

CurrencyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
  currency: PropTypes.string,
  readOnly: PropTypes.bool,
  displayType: PropTypes.string,
};

CurrencyFormat.defaultProps = {
  readOnly: false,
  displayType: "input",
  currency: "",
};

export default CurrencyFormat;