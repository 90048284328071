import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';
import { Account } from 'mdi-material-ui';

import ActionStateAndTimeTooltip from '../../components/Tooltip/ActionStateAndTimeTooltip';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  }
}));

const TimelineAgentHandoverEventAction = ({ item }) => {
  const classes = useStyles();

  return <Box className={classes.root}>
    <PopoverTooltip tooltip={<ActionStateAndTimeTooltip action={item} />} style={{ display: 'flex', alignItems: 'center' }}>
      <ListItemIcon>
        <Account />
      </ListItemIcon>
      {item.actionAgent &&
        <Box>
          <Typography
            className={'TimelineAgentHandoverAgentName'}
            component="span"
            variant="body1"
            noWrap
          >
            {item.actionAgent.displayName ? item.actionAgent.displayName : item.actionAgent.name}
          </Typography>
          {' '}
          <Typography
            className={'TimelineAgentHandoverAgentNumber'}
            component="span"
            variant="body2"
            noWrap
          >
            {item.actionAgent.number}
          </Typography>
        </Box>
      }
    </PopoverTooltip>
  </Box>;
}

TimelineAgentHandoverEventAction.propTypes = {
  item: PropTypes.object.isRequired
}

export default TimelineAgentHandoverEventAction;