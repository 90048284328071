import * as colors from '@material-ui/core/colors';

const inPortColor = colors.cyan[600];
const departureColor = colors.grey[900];
const preArrivalColor = colors.blueGrey[300];
const arrivalColor = colors.amber[600];
const cancelledColor = colors.grey[400];

export default {
  palette: {
    colors: { ...colors },
    IN_PORT: inPortColor,
    PREARRIVAL: preArrivalColor,
    ARRIVAL: arrivalColor,
    DEPARTURE: departureColor,
    CANCELLED: cancelledColor,
    primary: colors.blue,
    secondary: colors.red
  },
  shape: {
    borderRadius: 0,
  },
  // LocationTimeline blobs use PX offsets as zIndex for depth sorting and need other elements to be pushed a bit further
  zIndex: {
    mobileStepper: 10000,
    speedDial: 10500,
    appBar: 11000,
    drawer: 12000,
    modal: 13000,
    snackbar: 14000,
    tooltip: 15000
  },
  overrides: {
    MuiLink: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MuiButton: {
      root: {
        minWidth: 0,
      }
    },
    MuiPopOver:{
      root:{
        boxSizing:'border-box',
      }
    },
    MuiChip: {
      root: {
        height: '2rem',
        transition: 'none',
        borderRadius: 0,
      },
      sizeSmall: {
        height: '1.5rem',
      },
      avatarSmall: {
        width: '1.5rem',
        height: '1.5rem',
      },
      icon: {
        marginLeft: '0.3125rem',
        marginRight: '-0.5rem',
      },
      iconSmall: {
        width: '1rem',
        marginRight: '-0.3125rem',
      },
      label: {
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
      },
      labelSmall: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
      deleteIcon: {
        margin: '0 0.3125rem 0 -0.5rem',
      },
      deleteIconSmall: {
        height: '1rem',
        margin: '0 1px 0 -0.5625rem',
      },
    },
    MuiAvatar: {
      root: {
        width: '3rem',
        height: '3rem',
      }
    },
    MuiListItem: {
      root: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },
      gutters: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
      dense: {
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
      },
      secondaryAction: {
        paddingRight: '3rem',
      }
    },
    MuiListItemAvatar: {
      root: {
        marginRight: '1rem',
        minWidth: 'auto',
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: '1rem',
        minWidth: 'auto',
        color: 'inherit'
      }
    },
    MuiListItemText: {
      root: {
        marginTop: '0.25rem',
        marginBottom: '0.25rem',
      }
    },
    MuiOutlinedInput: {
      // root: {
      //   padding: '.5rem'
      // },
      // input: {
      //   padding: '10.5px 6px',
      // }
    },
    MuiCircularProgress: {
      root: {
        padding: '0.25rem'
      }
    },
    MuiInputAdornment: {
      root: {
        flexShrink: 0
      }
    },
    MuiAutocomplete: {
      root: {
        '& > div': {
          width: '100%'
        }
      },
      noOptions: {
        display: 'none'
      },
    },
    MuiCardHeader: {
      root: {
        padding: '1rem',
      },
      action: {
        alignSelf: 'unset',
        marginTop: 0,
      } 
    },
    MuiCardContent: {
      root: {
        padding: '2rem',
      }
    },
    MuiCardActions: {
      root: {
        padding: '0.5rem',
      }
    },
    MuiDialogActions: {
      root: {
        padding: '0.5rem',
      }
    },
    MuiDialogTitle: {
      root: {
        textTransform: 'uppercase',
      }
    },
    MuiTooltip: {
      tooltip: {
        padding: '0.25rem 0.5rem',
        maxWidth: '20rem',
      },
      touch: {
        padding: '0.5rem 1rem',
      }
    },
    MuiTypography:{
      gutterBottom: {
        marginBottom: '1rem',
      },
      paragraph: {
        marginBottom: '1rem',
      },
      colorTextSecondary: {
        color: 'inherit',
        opacity: 0.54
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        height: '6.25rem',
      }
    },
    MuiPickerDTToolbar: {
      toolbar: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      }
    },
    MuiPickersDay: {
      day: {
        width: '2.25rem',
        height: '2.25rem',
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: '20rem',
        minWidth: '18.5rem',
        minHeight: '19rem',
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        maxHeight: '1rem',
      },
      dayLabel: {
        width: '2.25rem',
      },
      transitionContainer: {
        height: '1.5rem',
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        height: '13rem',
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0.25rem',
        marginBottom: '.25rem',
      },
    },
    MuiTableCell: {
      root: {
        boxSizing: 'border-box',
        height: '5rem',
        maxHeight: '5rem',
        overflow: 'hidden',
        padding: '0 1rem',
        borderBottom: '0.0625rem solid rgba(224, 224, 224, 1)',
        '&:last-child': {
          paddingRight: '1rem',
        }
      },
      head: {
        height: '2.5rem',
        paddingTop: 0,
        paddingBottom: 0,
      },
      stickyHeader: {
        backgroundColor: colors.grey[100],
      }
    },
    MuiTab: {
      wrapper: {
        flexDirection:'row',
      },
    },
    MuiLinearProgress: {
      root: {
        height: '0.25rem',
        marginBottom: '-0.25rem'
      }
    }
  },
};
