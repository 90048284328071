import { ActionState } from '../models';

export default {
  [ActionState.PLANNED]: 'Planned',
  [ActionState.IN_PROGRESS]: 'In progress',
  [ActionState.COMPLETED]: 'Completed',
  [ActionState.CANCELLED]: 'Cancelled',
  [ActionState.DELETED]: 'Deleted',
  [ActionState.APPROVAL_DECLINED]: 'Approval Declined',
  [ActionState.APPROVAL_PENDING]: 'Pending Approval',
  [ActionState.REQUESTED]: 'Requested'
};

export const ActionStateLabelKeys = {
  [ActionState.PLANNED]: 'ActionState.Labels.Planned',
  [ActionState.IN_PROGRESS]: 'ActionState.Labels.InProgress',
  [ActionState.COMPLETED]: 'ActionState.Labels.Completed',
  [ActionState.CANCELLED]: 'ActionState.Labels.Cancelled',
  [ActionState.DELETED]: 'ActionState.Labels.Deleted',
  [ActionState.APPROVAL_DECLINED]: 'ActionState.Labels.ApprovalDeclined',
  [ActionState.APPROVAL_PENDING]: 'ActionState.Labels.ApprovalPending',
  [ActionState.REQUESTED]: 'ActionState.Labels.Requested'
};