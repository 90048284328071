/**
 * Currently available application developer features (stored in Setting as FEATURE_FLAGS)
 */
const FeatureFlags = {
  /** 26058 Alternative header style for agent users */
  AGENT_APP_STYLE: "agent-app-style",
  /** 7525 AIS Status feed on the map and AIS Status Monitoring admin section for its threshold configuration */
  AIS_RECEIVER_STATUS: "ais-receiver-status",
  /** 54266 Hide test dictionary for production releases */
  TEST_DICTIONARY: "test-dictionary",
  /** 75806 Tariff book */
  TARIFF_BOOK: "tariff-book",
  /** 75806 Port call export */
  PORTCALL_EXPORT: "portcall-export",
  /** PortCall Browser */
  PORTCALL_BROWSER:"portcall-browser",
  /** Rest API */
  REST_API: "rest-api",
  /** Invoice */
  INVOICE: "invoice"
}

export default FeatureFlags;