import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import {
  Typography,
  Box,
  List,
  Divider,
  ListItem,
  Chip,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch
} from '@material-ui/core';
import { ArrowRight, Send } from 'mdi-material-ui';
import { useParams } from 'react-router';
import { getAuditLogEntryByPortCallId } from '../../graphql/queries';
import { onCreateAuditLog } from '../../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import { AuditLogType, Request, RequestType, ActionMovementType, Action, PortCall, AuditLogEmbedded } from '../../models';
import { makeStyles } from '@material-ui/styles';
import { ActionStateLabelKeys } from '../../constants/ActionStateLabel';
import { RequestStateLabelsKeys, RequestTypeLabelsKeys } from '../../constants/RequestConstants';
import { getActionName, IsAuditLogRelatedToActionId } from '../../utils/getters';
import { capitalize } from '../../utils/utils';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { format } from 'date-fns';
import useQuery from '../../hooks/useQuery';
import { ActionMovementTypeLabelKeys } from '../../constants/ActionMovementTypeLabel';
import { createAuditLog } from '../../graphql/mutations';
import useAuditMeta from '../../hooks/useAuditMeta';
import { DataStore } from 'aws-amplify';
import { Alert } from '@material-ui/lab';
import { DateFnsLanguageMap } from '../../translations';
import { Trans, useTranslation } from 'react-i18next';
import { PortCallStatusLabelsKeys } from '../../constants/PortCallStatus';

const COMMENT_CHARACTER_LIMIT = 256;
const LOGBOOK_PREFERENCES_KEY = `smartport::preferences::LogBook`;
const LOGBOOK_STICKY_SCROLL_MARIGN = 1;

const useStyles = makeStyles(theme => ({
  filter: {
    position: "absolute",
    top: "-2rem",
    right: "1rem",
    height: "2rem"
  },
  list: {
    background: "rgb(248, 248, 248)",
    border: "1px solid rgb(226, 226, 226)",
    padding: "1rem 0px 1rem",
    overflowY: "auto"
  },
  listItem: {
    padding: "1rem"
  },
  textfield: {
    width: "100%",
    flexShrink: 0,
    '& .MuiInputBase-input': {
      paddingBottom: "2rem"
    }
  },
  textfieldButton: {
    alignSelf: "flex-end",
    flexShrink: 0,
  },
  alert: {
    alignItems: "center",
    '& .MuiAlert-message': {
      padding: 0
    }
  }
}));

const ItemTypography = ({ item }) => <Typography variant="body2" component="span" style={{ fontSize: "inherit", fontWeight: 500 }}>{item}</Typography>
const UserTypography = ({ user }) => <Typography variant="body2" color="primary" component="span">{user}</Typography>
const TimeTypography = ({children, dateTimeFormat, language}) => <Typography variant="caption" color="textSecondary" component="span" style={{ paddingLeft: ".25rem" }}>{format(new Date(children), dateTimeFormat, { locale: DateFnsLanguageMap[language] })}</Typography>
const CommentTypography = ({itemId, comment}) => <Typography className='LogBookComment' data-id={itemId} variant="body2" style={{ padding: "0.5rem 1rem", margin: "0.5rem 0.5rem 0.1rem", border: "1px solid rgba(0,0,0,0.1)", backgroundColor: "white", whiteSpace: "break-spaces" }}>{comment}</Typography>
const StateChangeChip = ({ label, prev, next }) =>
  <Box display="flex" alignItems="center" pl="1rem" pt=".5rem">
    {label && <Typography variant="caption" color="textSecondary" style={{ paddingRight: ".5rem" }}>{label}</Typography>}
    {prev && <>
      <Chip size="small" label={prev} />
      <ArrowRight />
    </>}
    <Chip size="small" label={next} color="primary" />
  </Box>

const AuditLogEntry_PortCallState = ({ item }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  let _old, _new;
  try {
    _old = item.old && JSON.parse(item.old);
    _new = item.new && JSON.parse(item.new);
  } catch { }

  const name = t('Logbook.Labels.PortCall');
  const key = !_old ? "Logbook.Labels.ItemCreatedByUser" : "Logbook.Labels.ItemUpdatedByUser";
  const prevState = _old?.status && _new?.status ? t(PortCallStatusLabelsKeys[_old.status]) : null;
  const nextState = t(PortCallStatusLabelsKeys[_new.status]);
  
  return (
    <Box width="100%">
      <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
        <Trans 
          i18nKey={key}
          components={{ 
            item: <ItemTypography item={name} />,
            user: <UserTypography user={item.userId} />,
          }}
        />
        <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
      </Typography>
      <StateChangeChip prev={prevState} next={nextState} />
      {Boolean(item.comment) && <CommentTypography itemId={item.id} comment={item.comment}/>}
    </Box>
  )
}

const AuditLogEntry_ActionState = ({ item, actions }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  let _old, _new;
  try {
    _old = item.old && JSON.parse(item.old);
    _new = item.new && JSON.parse(item.new);
  } catch { }

  const action = actions?.find(a => a.id === item.objectId);
  const name = action ? getActionName(t, action, false) : t('Logbook.Labels.Action');
  const key = !_old ? "Logbook.Labels.ItemCreatedByUser" : "Logbook.Labels.ItemUpdatedByUser";
  const prevState = _old?.state && _new?.state ? t(ActionStateLabelKeys[_old.state]) : null;
  const nextState = t(ActionStateLabelKeys[_new.state]);
  return (
    <Box width="100%">
      <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
        <Trans 
          i18nKey={key}
          components={{ 
            item: <ItemTypography item={name} />,
            user: <UserTypography user={item.userId} />,
          }}
        />
        <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
      </Typography>
      <StateChangeChip prev={prevState} next={nextState} />
      {Boolean(item.comment) && <CommentTypography itemId={item.id} comment={item.comment} />}
    </Box>
  )
}

const AuditLogEntry_RequestUpdate = ({ item, actions, requests }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  let _old, _new;
  try {
    _old = item.old && JSON.parse(item.old);
    _new = item.new && JSON.parse(item.new);
  } catch { }

  const request = requests?.find(r => r.id === item.objectId);
  const stateChanges = [];
  if (request) {
    // cases that use top level request.state
    if ([RequestType.REQUEST_TYPE_CANCEL_PORTCALL].includes(request.type) || _new?.state) {
      if (_new?.state) {
        stateChanges.push({
          prev: _old?.state ? t(RequestStateLabelsKeys[_old.state]) : null,
          next: t(RequestStateLabelsKeys[_new.state])
        });
      }
      // special case, requires custom labels for each [actionData]
    } else if ([RequestType.REQUEST_TYPE_CREATE_PORTCALL].includes(request.type) && _new?.actionData) {
      for (let ad of _new.actionData) {
        const action = actions.find(a => a.id === ad.actionId);
        const oldAd = _old?.actionData?.length && _old.actionData.find(_ad => _ad.actionId === ad.actionId);
        // edge case, in case a departure is yeeted with DataStore.delete(), it can no longer be accessed so assume it's departure here
        const labelName = (action ? getActionName(t, action) : t(ActionMovementTypeLabelKeys[ActionMovementType.DEPARTURE]));
        if (ad?.approved) {
          stateChanges.push({
            label: capitalize(labelName),
            prev: oldAd?.approved ? t(RequestStateLabelsKeys[oldAd.approved]) : null,
            next: t(RequestStateLabelsKeys[ad.approved])
          });
        }
        if (ad?.timeRequested) {
          stateChanges.push({
            label: capitalize(t("Logbook.Labels.ItemRequestedTime", { item: labelName })),
            prev: oldAd?.timeRequested ? format(new Date(oldAd?.timeRequested), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }) : null,
            next: format(new Date(ad.timeRequested), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })
          });
        }
      }

      // cases with one [actionData], uses [actionData].approved as state
    } else {
      const ad = _new?.actionData?.length && _new.actionData[0];
      const oldAd = _old?.actionData?.length && _old.actionData[0];
      if (ad?.approved) {
        stateChanges.push({
          prev: oldAd?.approved ? t(RequestStateLabelsKeys[oldAd?.approved]) : null,
          next: t(RequestStateLabelsKeys[ad.approved])
        });
      }
      if (ad?.timeRequested) {
        stateChanges.push({
          label: t("Logbook.Labels.RequestedTime"),
          prev: oldAd?.timeRequested ? format(new Date(oldAd?.timeRequested), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }) : null,
          next: format(new Date(ad.timeRequested), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })
        });
      }
    }
  }

  const name = capitalize(request ? t(RequestTypeLabelsKeys[request.type]) : t("Logbook.Labels.Request"));
  const key = (_new && !_old) ? "Logbook.Labels.ItemCreatedByUser" : "Logbook.Labels.ItemUpdatedByUser";
  return (
    <Box width="100%">
      <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
        {!_old && !_new && item.comment
          ? <Trans 
              i18nKey="Logbook.Labels.UserCommentedOnItem"
              components={{ 
                item: <ItemTypography item={name} />,
                user: <UserTypography user={item.userId} />,
              }}
            />
          : <Trans 
              i18nKey={key}
              components={{ 
                item: <ItemTypography item={name} />,
                user: <UserTypography user={item.userId} />,
              }}
            />
        }
        <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
      </Typography>
      {stateChanges.map(changes => <StateChangeChip {...changes} />)}
      {Boolean(item.comment) && <CommentTypography itemId={item.id} comment={item.comment} />}
    </Box>
  )
}

const AuditLogEntry_Comment = ({ item, actions }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  const isActionEntry = (item.portCallId !== item.objectId);
  const action = isActionEntry && actions.find(a => a.id === item.objectId);
  const name = isActionEntry && action ? getActionName(t, action, false) : t('Logbook.Labels.Action');
  return (
    <Box width="100%">
      <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
        {!isActionEntry
          ? <Trans 
              i18nKey="Logbook.Labels.UserCommented"
              components={{ 
                user: <UserTypography user={item.userId} />,
              }}
            />
          : <Trans 
              i18nKey="Logbook.Labels.UserCommentedOnItem"
              components={{ 
                user: <UserTypography user={item.userId} />,
                item: <ItemTypography item={name} />,
              }}
            />
        }
        <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
      </Typography>
      {Boolean(item.comment) && <CommentTypography itemId={item.id} comment={item.comment} />}
    </Box>
  )
}

const AuditLogEntry_Error = ({ item }) => {
  const classes = useStyles();
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n } = useTranslation();
  return (
    <Box width="100%">
      <Alert severity="error" className={classes.alert}>
        <Typography component="span" variant="body2" style={{ whiteSpace: "break-spaces" }}>
          {item.comment} <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
        </Typography>
      </Alert>
    </Box>
  )
}

const AuditLogFileLabelMap = {
  [AuditLogType.AUDIT_FILE_CREATE]: "Logbook.Labels.FileUploadedByUser",
  [AuditLogType.AUDIT_FILE_UPDATE]: "Logbook.Labels.FileUpdatedByUser",
  [AuditLogType.AUDIT_FILE_DELETE]: "Logbook.Labels.FileDeletedByUser",
};
const AuditLogActionFileLabelMap = {
  [AuditLogType.AUDIT_FILE_CREATE]: "Logbook.Labels.ItemFileUploadedByUser",
  [AuditLogType.AUDIT_FILE_UPDATE]: "Logbook.Labels.ItemFileUpdatedByUser",
  [AuditLogType.AUDIT_FILE_DELETE]: "Logbook.Labels.ItemFileDeletedByUser",
};
const AuditLogEntry_File = ({ item, actions }) => {
  const { dateTimeFormat } = useDateTimeSetting();
  const { t, i18n } = useTranslation();
  const data = JSON.parse(item.data);
  const action = actions.find(a => a.id === item.objectId);
  return (
    <Box width="100%">
      <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
        <Trans 
          i18nKey={action ? AuditLogActionFileLabelMap[item.type] : AuditLogFileLabelMap[item.type]}
          components={{ 
            item: <ItemTypography item={action && capitalize(getActionName(t, action))} />,
            file: <ItemTypography item={data.name} />,
            user: <UserTypography user={item.userId} />,
          }}
        />
        <TimeTypography children={item.timeChanged} dateTimeFormat={dateTimeFormat} language={i18n.language} />
      </Typography>
    </Box>
  )
}

const ComponentMap = {
  [AuditLogType.AUDIT_ACTION_STATE]: AuditLogEntry_ActionState,
  [AuditLogType.AUDIT_PORTCALL_STATE]: AuditLogEntry_PortCallState,
  [AuditLogType.AUDIT_REQUEST_UPDATE]: AuditLogEntry_RequestUpdate,
  [AuditLogType.AUDIT_COMMENT]: AuditLogEntry_Comment,
  [AuditLogType.AUDIT_ERROR]: AuditLogEntry_Error,
  [AuditLogType.AUDIT_FILE_CREATE]: AuditLogEntry_File,
  [AuditLogType.AUDIT_FILE_UPDATE]: AuditLogEntry_File,
  [AuditLogType.AUDIT_FILE_DELETE]: AuditLogEntry_File,
}

const LogBookEntry = ({ item, actions, requests }) => {
  const Component = ComponentMap[item.type] || null;
  if (Component) {
    return <Component item={item} actions={actions} requests={requests} />;
  }
  console.warn(`AuditLogEntry for ${item.type} not implemented`);
  return null;
}

const LogBookTextField = ({ portCallId, action }) => {
  const { userId, source } = useAuditMeta();
  const [comment, setComment] = useState('');
  const [associate, setAssociate] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();

  const onSave = async () => {
    setComment('');
    const log = await API.graphql(graphqlOperation(createAuditLog, {
      input: {
        table: "PortCall",
        type: AuditLogType.AUDIT_COMMENT,
        source,
        userId,
        comment,
        timeChanged: new Date().toISOString(),
        objectId: associate && action?.id || portCallId,
        portCallId
      }
    }));
    // update latestUserLogEntry
    if (log?.data?.createAuditLog) {
      const embedded = new AuditLogEmbedded({
        id: log.data.createAuditLog.id,
        comment: log.data.createAuditLog.comment,
        userId: log.data.createAuditLog.userId,
        timeChanged: log.data.createAuditLog.timeChanged,
      });
      // port call
      if (log.data.createAuditLog.objectId === portCallId) {
        const portCall = await DataStore.query(PortCall, portCallId);
        await DataStore.save(PortCall.copyOf(portCall, updated => {
          updated.latestUserLogEntry = embedded;
        }));
        // action
      } else {
        const action = await DataStore.query(Action, log.data.createAuditLog.objectId);
        await DataStore.save(Action.copyOf(action, updated => {
          updated.latestUserLogEntry = embedded;
        }));
      }
    }
  };
  return (
    <TextField
      className={classes.textfield}
      label={t("Logbook.Labels.Comment")}
      variant="filled"
      multiline
      value={comment}
      onChange={(e) => setComment(e.target.value.substring(0, COMMENT_CHARACTER_LIMIT))}
      InputProps={{
        endAdornment:
          <>
            {Boolean(action) &&
              <FormGroup style={{ position: "absolute", bottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={e => setAssociate(e.target.checked)} checked={associate} color="primary" />} label={
                  <Typography variant="caption">
                    <Trans 
                      i18nKey="Logbook.Labels.AssociateWithSelectedItem"
                      components={{ 
                        item: <ItemTypography item={capitalize(getActionName(t, action))} />,
                      }}
                    />
                  </Typography>}
                />
              </FormGroup>
            }
            <Button className={classes.textfieldButton} size="small" color="primary" variant="contained" disabled={!comment.trim()} onClick={onSave}>
              <Send fontSize="small" style={{ paddingRight: ".5rem" }} />
              <Typography noWrap>{t('Logbook.Buttons.AddEntry')}</Typography>
            </Button>
          </>
      }}
    />
  );
}

const LogBook = () => {
  const listRef = useRef(null);
  // "portCallId" is "id" in ActionDetails
  const { id, portCallId, actionId } = useParams();
  // null = loading; [] = no data
  const [logs, setLogs] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const promise = API.graphql(graphqlOperation(getAuditLogEntryByPortCallId, {
      portCallId: id || portCallId,
      sortDirection: 'ASC',
      limit: 1000,
    }));
    const update = async () => {
      const result = await promise;
      if (result?.data?.getAuditLogEntryByPortCallId?.items.length > 0) {
        setLogs(result.data.getAuditLogEntryByPortCallId.items.filter(log => (log?.type !== AuditLogType.AUDIT_NOTIFICATION)))
      }
    }
    update();
    const subscription = API.graphql(graphqlOperation(onCreateAuditLog)).subscribe({
      next: ({ value }) => {
        value?.data?.onCreateAuditLog?.portCallId === (id || portCallId) && setLogs(prev => [...(prev || []), value.data.onCreateAuditLog])
      }
    });
    return () => {
      API.cancel(promise);
      subscription.unsubscribe();
    }
  }, [id, portCallId, setLogs]);

  // sticky scroll until user manually pulls away from the bottom
  const [sticky, setSticky] = useState(true);
  useLayoutEffect(() => {
    if (!listRef.current || !Array.isArray(logs)) return;
    if (sticky) {
      listRef.current.scrollTo(0, listRef.current.scrollHeight)
    }
  }, [listRef, logs, sticky]);
  const onScroll = () => {
    if (!listRef.current) return;
    const newSticky = listRef.current.offsetHeight + listRef.current.scrollTop >= listRef.current.scrollHeight - LOGBOOK_STICKY_SCROLL_MARIGN;
    setSticky(prev => newSticky == prev ? prev : newSticky);
  };

  // need to query manually to include deleted/approved entries that get culled in pc details
  const actions = useQuery(Action, { condition: c => c.actionPortCallId_("eq", id || portCallId) });
  const requests = useQuery(Request, { condition: c => c.requestPortCallId_("eq", id || portCallId) });

  // associated action filtering
  const action = actionId && actions.find(a => a.id === actionId);
  const [showAssociated, _setShowAssociated] = useState(false);
  const setShowAssociated = (value) => {
    _setShowAssociated(value);
    localStorage.setItem(LOGBOOK_PREFERENCES_KEY, JSON.stringify({ showAssociated: value }));
  }
  useEffect(() => {
    const preferences = JSON.parse(localStorage.getItem(LOGBOOK_PREFERENCES_KEY));
    if (preferences)
      setShowAssociated(preferences.showAssociated);
  }, [setShowAssociated]);

  const logsFiltered = useMemo(() => actionId && showAssociated && Array.isArray(logs)
    ? logs.filter(l => IsAuditLogRelatedToActionId(l, actionId))
    : logs,
    [logs, actionId, showAssociated]); 
  // pre-build a map for rendering as this may get expensive { logId: Boolean, ... }
  const logsFilteredDisabledMap = useMemo(() => Array.isArray(logsFiltered)
    ? logsFiltered.reduce((prev, cur) => ({ ...prev, [cur.id]: actionId && !IsAuditLogRelatedToActionId(cur, actionId) }), {})
    : {},
    [logsFiltered, actionId]);

  const classes = useStyles();
  return (
    <Box m="1rem 2rem 2rem" display="flex" flexDirection="column" position="relative">
      {Boolean(action) &&
        <FormGroup className={classes.filter}>
          <FormControlLabel
            control={<Switch color="primary" checked={showAssociated} onChange={e => setShowAssociated(e.target.checked)} />}
            label={
              <Typography variant="caption">
                <Trans 
                  i18nKey={"Logbook.Labels.ShowOnlyEntriesForSelectedItem"}
                  components={{ 
                    item: <ItemTypography item={getActionName(t, action, false)} />,
                  }}
                />
              </Typography>}
          />
        </FormGroup> 
      }
      {Array.isArray(logsFiltered) && Boolean(!logsFiltered.length) &&
        <Typography variant="caption" style={{ margin: "1rem" }}>{t('Logbook.Labels.NoDataAvailable')}</Typography>
      }
      {Array.isArray(logsFiltered) && Boolean(logsFiltered.length) &&
        <List ref={listRef} className={classes.list} onScroll={onScroll}>
          {logsFiltered.map((l, index) =>
            <Box key={l.id}>
              {index > 0 && <Divider light variant="middle" />}
              <ListItem button disableRipple className={classes.listItem} disabled={logsFilteredDisabledMap[l.id]}>
                <LogBookEntry item={l} actions={actions} requests={requests} />
              </ListItem>
            </Box>)
          }
        </List>
      }
      <LogBookTextField portCallId={id || portCallId} action={action} />
    </Box>
  );
}

export default LogBook;
