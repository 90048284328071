import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { Popover, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: 'none',
  },
  wrapper: {
    width: '100%',
  }
}));

const PopoverTooltip = ({ 
  delay, 
  children, 
  tooltip, 
  anchorOriginHorizontal, 
  transformOriginHorizontal, 
  disableTooltip,
  disableFullWidthWrapper,
  ...otherProps 
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const timeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    }
  }, [])

  const handleOpen = ({ currentTarget }) => {
    if (disableTooltip) return;
    timeout.current = setTimeout(() => {
      setAnchorEl(currentTarget);
    }, delay);
  };

  const handleClose = () => {
    if (disableTooltip) return;
    clearTimeout(timeout.current);
    setAnchorEl(null);
  };

  return (
    <Box 
      className={disableFullWidthWrapper ? '' : classes.wrapper} 
      onMouseEnter={handleOpen} 
      onFocus={handleOpen} 
      onBlur={handleClose} 
      onMouseLeave={handleClose} 
      {...otherProps}
    >
      {children}
      {anchorEl && tooltip &&
        <Popover
          className={classes.root}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: anchorOriginHorizontal,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: transformOriginHorizontal,
          }}
          disableEnforceFocus
          disableRestoreFocus
          disableAutoFocus
          PaperProps={{
            style: {
              pointerEvents: 'all',
              maxWidth: '40rem'
            }
          }}
        >
          <Box onMouseLeave={handleClose}>
            {tooltip}
          </Box>
        </Popover>
      }
    </Box>
  );
};

PopoverTooltip.defaultProps = {
  delay: 500,
  anchorOriginHorizontal: "center",
  transformOriginHorizontal: "center"
};

PopoverTooltip.propTypes = {
  delay: PropTypes.number,
  tooltip: PropTypes.object.isRequired,
  children: PropTypes.any,
  tooltip: PropTypes.any,
  anchorOriginHorizontal: PropTypes.string, 
  transformOriginHorizontal: PropTypes.string, 
  disableTooltip: PropTypes.bool
};

export default PopoverTooltip;