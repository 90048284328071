import React, { useContext, useEffect } from 'react';
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationContext, navigationActionConstants } from '../../../contexts/navigation';
import VoyageVesselCard from "../VoyageVesselCard";
import VoyageTimeline from './VoyageTimeline';
import useVoyage from '../../../hooks/useVoyage';
import { VoyageStatus } from '../../../models';
import { useHistory } from 'react-router';
import RouteConstants from '../../../constants/RouteConstants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'relative',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10%',
    width: '100vw'
  },
  vesselCard: {
    minWidth: '80vw',
    '& > div': {
      boxSizing: 'border-box',
      width: '100%',
    }
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  row: {
    display: 'flex',
    height: 'fit-content',
    width: '60vw'
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    flexBasis: '100%'
  },
  rootPane: {
    display: "flex",
    flexFlow: "column",
    height: '100%',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  scrollPane: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
}));

const VoyageDetails = ({ voyageId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [navigationContext, dispatchNavigationContext] = useContext(NavigationContext);
  const { voyage, vessel, portCalls } = useVoyage(voyageId);
  //console.log('voyage hook', voyage, vessel);

  //Redirect to voyage browser if voyage is DELETED
  useEffect(()=>{
    let timeout;
    if(voyage?.status === VoyageStatus.DELETED){
     timeout = setTimeout(()=>{
       history.push(navigationContext.lastView);
     },2000);
    }
    return () => clearTimeout(timeout);
  },[history, navigationContext.lastView, voyage?.status]);

  useEffect(() => {
    if (voyageId) {
      dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: `${RouteConstants.VOYAGE}/${voyageId}` });
    }
  }, [dispatchNavigationContext, voyageId]);

  return (
    <Grid container id={'VoyageDetails'} className={classes.container}>
      <Grid
        className={classes.greyPanel}
      //xs={9}
      >
        <Box className={classes.scrollPane}>
          <Box className={classes.header}>
            <Box className={classes.vesselCard}>
              <VoyageVesselCard vessel={vessel}
                voyageId={voyage?.name}
                voyageStatus={voyage?.status}
              />
            </Box>
            {/* Close button need to relocate when logbook section added */}
            <Typography className={classes.close}>
              <Link
                id="VoyageDetailsCloseButton"
                className={classes.linkButton}
                to={navigationContext.lastView}
              >
                {t("Common.Buttons.Close")}
              </Link>
            </Typography>
          </Box>
          <Box className={classes.cardContainer}>
            <VoyageTimeline portCalls={portCalls} voyage={voyage} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
};

export default VoyageDetails;