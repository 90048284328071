import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Divider
} from '@material-ui/core';
import VesselListItem from '../Vessel/VesselListItem';
import VesselSpotlightLauncher from '../Spotlight/VesselSpotlightLauncher';
import { itemRequested } from '../../constants/RequestConstants';
import VoyageStatusLabel from './VoyageStatusLabel';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';

const useStyles = makeStyles(theme => ({
  vesselCard: {
    display: "flex",
    height: "5rem",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    boxSizing: 'border-box',
    overflow: 'visible',
    paddingRight: '3rem',
    margin: '1rem',
  },
  selectableVesselCard: {
    '&:hover': {
      background: theme.palette.action.hover
    }
  },
  itemRequested,
  divider: {
    margin: '0.5rem 1.2rem 0.5rem 0.5rem'
  }
}));

const VoyageVesselCard = ({
  vessel,
  voyageId,
  voyageStatus,
}) => {
  const classes = useStyles();
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  return (
    <>
      <Card className={`
      ${classes.vesselCard} 
      ${!voyageId && classes.selectableVesselCard}
    `}>
        <Box flexGrow={1}>
          <VesselListItem
            vesselData={vessel}
            disabled={!vessel}
            voyageId={voyageId}
          />
        </Box>
        <VesselSpotlightLauncher vesselData={vessel} setMapDialogOpen={setMapDialogOpen} />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        {voyageStatus &&
          <VoyageStatusLabel voyageStatus={voyageStatus} sx={{ marginLeft: '2px' }} />
        }
      </Card>
      {mapDialogOpen &&
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={vessel}
        />}
    </>
  )
}

export default VoyageVesselCard;