
import { useContext, useCallback } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { AutomationRule } from '../models';

/**
 * A hook that handles fetching all automation rules and updating an automation rule.
 * @return {Object} automation rule list and helper functions
 */
const useAutomationRules = () => {
  const { automationRules } = useContext(DataStoreContext);

  const updateAutomationRule = useCallback((automationRule, newValues) => {
    DataStore.save(AutomationRule.copyOf(automationRule, updated => { 
      for (const [key, value] of Object.entries(newValues)) {
        updated[key] = value
      }
    }));
  }, [])

  return {
    automationRules,
    updateAutomationRule
  }
}

export default useAutomationRules;