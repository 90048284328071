import React from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Box,
  makeStyles,
  InputAdornment,
  InputBase,
  ListItemText
} from '@material-ui/core';
import {
  InformationOutline,
  Tooltip as TooltipFilled,
  Text,
} from 'mdi-material-ui';
import PopoverTooltip from "../../Tooltip/PopoverTooltip";
import TariffTooltip from "../../Tooltip/TariffTooltip";
import {
  renderTariffUnitType,
  isNumber,
  getTariffUnitCost,
} from "../../../utils/chargeableItems";
import renderValueCurrencyString from '../../../utils/renderValueCurrencyString';
import PropTypes from 'prop-types';
import {
  ChargeableItemColors
} from "../../../constants/ChargeableItemConstants";
import CurrencyFormat from '../../Common/CurrencyFormat';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { getFullTarrifUnit } from "../../../utils/getters";
import useChargeableItemValidation from "../../../hooks/useChargeableItemValidation";
import InvalidTariffWarning from "../InvalidTariffWarning";

const useStyles = makeStyles({
  root: {
    verticalAlign: 'top',
    paddingTop: '0.6rem',
    height: '4rem'
  },
  popoverIcon: {
    marginLeft: ".5rem"
  },
  showOverride: {
    border: `5px solid ${ChargeableItemColors.override}`
  },
  last: {
    paddingTop: '0.6rem'
  }
});

// Renders a list item with the notes and label for popover
const renderTariffNotesPopover = (t, notes) =>
  <Box m={1}>
    <ListItemText
      primary={notes}
      secondary={t('ChargeableItems.Labels.ItemNotes')}
    />
  </Box>

// Wraps component with Table Cell component
export const renderTableCell = (component, key, align, classes, style) =>
  <TableCell align={align} key={key} classes={classes} style={style}>
    {component}
  </TableCell>

// Creates a Typography component with the value
export const renderQuantityTypography = (quantity, secondaryMult, tariffSecondaryUnit) =>
  <Typography noWrap>
    {tariffSecondaryUnit ? `${quantity} x ${secondaryMult}` : quantity}
  </Typography>

// Takes in Chargeable Item
// Renders the overridden cost if there is one
// Render the Unit type
const renderTariffUnitCostPopover = (t, {
  overrideUnitCost,
  tariffData: {
    unitCost,
  },
  tariffUnit,
  tariffSecondaryUnit
},
  currencySymbol
) =>
  <Box
    m={1}
  >
    {
      isNumber(overrideUnitCost) && isNumber(unitCost) &&
      <ListItemText
        primary={renderValueCurrencyString(unitCost, currencySymbol)}
        secondary={t('ChargeableItems.Labels.OverriddenUnitCost')}
      />
    }
    <ListItemText
      primary={renderTariffUnitType(getFullTarrifUnit(tariffUnit, tariffSecondaryUnit))}
      secondary={t('ChargeableItems.Labels.UnitType')}
    />
  </Box>

// Check that there are notes in the tariff
// Created so that checks can be added in the future if needed
export const tariffPopoverCheck = (tD) => !!tD.notes || !!tD.minimumCost;

// Creates the popover component for the tariff
// Along with the informaiton icon
export const renderTariffPopover = (tD, currencySymbol) =>
  <PopoverTooltip tooltip={<TariffTooltip tariff={tD} currencySymbol={currencySymbol} />}>
    <Box height="auto" width="100%" display="flex" alignContent="center">
      <InformationOutline />
    </Box>
  </PopoverTooltip>

// Renders a read only input to show currency values
// Sets them as right aligning
// With the currency symbol as the start adornment
export const renderViewOnlyCurrency = (value, currencySymbol) =>
  <InputBase
    type="number"
    variant="standard"
    value={value}
    inputComponent={CurrencyFormat}
    inputProps={{ readOnly: true }}
    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
  />

// Renders List Item
export const renderTariffListItem = (id, primary, secondary) =>
  <ListItemText
    className={id}
    primary={primary}
    secondary={secondary}
  />

const ChargeablesRowView = ({
  chargeableItem,
  columns,
  currencySymbol,
  actionTime
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // Deconstructing data inside the chargeable Item
  const {
    tariffData: {
      code,
      description
    },
    tariffData,
    notes,
    quantity,
    secondaryMult,
    tariffUnit,
    tariffSecondaryUnit,
    total,
    overrideUnitCost
  } = chargeableItem;

  const { validateChargeableItem } = useChargeableItemValidation();
  const validTariff = validateChargeableItem(chargeableItem, actionTime)


  // FIXME when tariff book fflag is removed, remove if below and initialise tariff vars
  // if(isTariffBookEnabled && dataStoreContext) {
  //   tariffBooks = dataStoreContext.tariffBooks;
  // }

  // Object containing the render methods for the different
  // Information for the Chargeable Item
  const renderArrayChargeableItems = {
    // Tariff Render
    tariff:
      <Box display="flex">
        <Box
          display="flex"
        >
          {renderTariffListItem('tariff', code, description)}

          {tariffPopoverCheck(tariffData) &&
            <Box marginLeft={1} marginTop={1}>
              {renderTariffPopover(tariffData, currencySymbol)}
            </Box>}
        </Box>
        <Box
          paddingTop='0.5em'
          paddingLeft='0.5em'
          justifyContent="center"
        >
          {validTariff?.error > 0 &&
            <Box 
            style={{ marginRight: '0.5em' }}
            id="InvalidTariffRow">
              <InvalidTariffWarning
                tooltip={t(validTariff.errorMessageT)}
              />
            </Box>}
        </Box>
      </Box>,
    // Notes Render
    notes: notes &&
      <Box
        display="flex"
        marginTop={1}
        justifyContent="center"
      >
        <PopoverTooltip tooltip={renderTariffNotesPopover(t, notes)}>
          <Text />
        </PopoverTooltip>
      </Box>,
    // Unit Cost Render
    unitCost:
      <Box
        display="flex"
        alignContent="center"
        alignItems="center"
      >
        {renderTariffListItem('unitCost', renderViewOnlyCurrency(getTariffUnitCost(chargeableItem), currencySymbol))}
        <Box
          style={{ width: '3rem', paddingLeft: '0.6rem' }}
        >
          {isNumber(overrideUnitCost) && <PopoverTooltip tooltip={renderTariffUnitCostPopover(t, chargeableItem, currencySymbol)}>
            <TooltipFilled
              style={{ color: ChargeableItemColors.override }}
            />
          </PopoverTooltip>
          }
        </Box>
      </Box>,
    // Quantity Render
    quantity:
      <Box display="flex" alignItems="center" justifyContent="center">
        {renderTariffListItem('quantity', renderQuantityTypography(quantity, secondaryMult, tariffSecondaryUnit), getFullTarrifUnit(tariffUnit, tariffSecondaryUnit))}
      </Box>,
    // Total Render
    total:
      <Box display="flex" alignItems="center" justifyContent="center">
        {renderTariffListItem('total', renderViewOnlyCurrency(total, currencySymbol))}
      </Box>

  }

  // The Row will be rendered based on the columns defined in 
  // ChargeablesEditor - Only the read only values are shown
  return (
    <TableRow data-id={chargeableItem.id} className={'CharegeableRowViewItem'}>
      {columns.map(({ id, align = "inherit" }, index) =>
        renderTableCell(renderArrayChargeableItems[id], `${id}${chargeableItem.id}`, align, { root: classes.root }, (index === columns.length - 1) ? { paddingTop: '0.6rem' } : {}))
      }
    </TableRow>
  )
}

// PropTypes for Chargeable Row View
ChargeablesRowView.propTypes = {
  chargeableItem: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string
}


// Default Props for Chargeable Row
ChargeablesRowView.defaultProps = {
  chargeableItem: {},
  columns: [],
  currencySymbol: ""
}

export default ChargeablesRowView;