import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Drawer, makeStyles } from '@material-ui/core';

import { MapContextProvider } from './contexts/map';
import OLMap from './OLMap';
import Popup from './Popup';
import WMSLayer from './Layers/WMSLayer';
import VesselLayer from './Layers/VesselLayer';
import FeatureInfo from './FeatureInfo';
import VesselInfoPanel from './VesselInfoPanel';
import Feature from '../Feature/Feature';
import { useTranslation } from 'react-i18next';

import { mapConfig } from '../../environment.js';
import { MarlinAuthContext } from '../../contexts/marlinAuthContext';
import { OceanWiseAttribution } from './Attributions';
import AISReceiverLayer from './Layers/AISReceiverLayer';
import LayerSwitcherControl from './LayerSwitcherControl';
import { BaseLayerZIndex, AisReceiverLayerZIndex, VesselLayerZIndex } from './constants';

import useMapLayerSetting from '../../hooks/useMapLayerSetting';
import FeatureFlags from '../../constants/FeatureFlags';

const useStyles = makeStyles(() => ({
  container:{
    width: '100%', 
    height: '100%', 
    position:'relative' 
  },
  drawer: {
    flexShrink: 0,
    minWidth: '25rem',
    overflow: 'hidden',
  },
  layerSwitcher: {
    top: '0.5em',
    right: '2.5em',
    width: '2rem',
    height: '2rem',
    transition: 'opacity .25s linear, visibility 0s linear',
    padding: 0,
    borderRadius: 0,
    boxShadow: '0px 1px 4px rgb(0 0 0 / 30%)'

  }
}));

const VESSEL_LAYER_ID = 'Vessels';
const AIS_RECEIVER_LAYER_ID = 'AISReceivers';

const INIT_LAYER_SETTINGS = {
  [VESSEL_LAYER_ID]: {
    visible: true,
    showLabels: false
  },
  [AIS_RECEIVER_LAYER_ID]: {
    visible: true,
    showLabels: true
  }
};

const PortMap = ({
  mapId, 
  showLayerSwitcherControl = true,
  centerPosition = null,
  restorePan=true,
  restoreZoom=true,
  onSpotlightVesselUpdate = null,
  spotlightVessels = [],
}) => {

  const position = [mapConfig.defaultLocation.lng, mapConfig.defaultLocation.lat];
  const [showVesselPopup] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const { accessToken } = React.useContext(MarlinAuthContext);
  const { mapLayerSetting, updateMapLayerSetting } = useMapLayerSetting();
  const [layerSettings, setLayerSettings] = useState(INIT_LAYER_SETTINGS);

  const layerNames = useMemo(() => ({
    [VESSEL_LAYER_ID]: { name: t('MapLayers.Labels.Vessels') },
    [AIS_RECEIVER_LAYER_ID]: { name: t('MapLayers.Labels.AISReceivers') }
  }), [t]);

  // How to pass feature to children

  const drawerToggleOpen = () => {
    setDrawerOpen(true);
  };

  const drawerToggleClose = () => {
    setDrawerOpen(false);
  };

  const vesselFeatureClicked = (feature) => {
    setSelectedFeature(feature.getProperties());
    drawerToggleOpen();
  };

  const authHeader = (wms, token) => {
    let marlinAuthToken = `Bearer ${token}`; // TODO get marlin access token from context
    if (wms?.marlinAuth) return marlinAuthToken;
    if (wms?.authCredentials) return wms.authCredentials;
    return undefined
  }

  const buildWmsLayer = useCallback(() => {
    const isLayerVisible = (layer) => {
      // No label, will not appear in layer control, always show
      if (!layer.title && !layer.i18n) return true;
      // Use last user setting
      if (mapLayerSetting && mapLayerSetting[layer.id]) return mapLayerSetting[layer.id]?.visible;
      // Start hidden
      return false;
    };

    const attributions = (wms) => {
      if (wms.oceanWiseAttribution) return OceanWiseAttribution;
      return undefined;
    };

    let zIndex = BaseLayerZIndex;

    return !mapConfig.wms ? null : mapConfig.wms.map(wms => {
      zIndex++;

      // Build a list of the visible wms layers based on user settings
      const visibleLayers = [];
      for (let layer of wms.layers) {
        if (mapLayerSetting && mapLayerSetting[layer.id]?.visible)
          visibleLayers.push(layer.id)
      }

      return (
        <WMSLayer
          key={wms.description}
          attributions={attributions(wms)}
          authHeader={authHeader(wms, accessToken)}
          layers={wms.layers}
          url={wms.url}
          zIndex={zIndex}
          visibleLayers={visibleLayers}
        />
      )
    })

  }, [accessToken, mapLayerSetting])

  const layerVisiblityChanged = (layerKey, visible) => {
    const curLayer = { ...layerSettings[layerKey], visible: visible };
    const newLayerSettings = { ...layerSettings, [layerKey]: curLayer }
    updateMapLayerSetting(newLayerSettings);
  };

  useEffect(() => {
    if (!mapLayerSetting) {
      return;
    }
    setLayerSettings(mapLayerSetting)
  }, [mapLayerSetting]);

  const handleOnClose = (reason) => {
    if (reason !== 'escapeKeyDown') {
      // Set 'open' to false
      setDrawerOpen(false);
    }
  }

  return (
    <MapContextProvider>
      <Box className={classes.container}>
        <OLMap
          mapId={mapId}
          defaults={{
            center: position,
            zoom: mapConfig.defaultLocation.zoom
          }}
          centerPosition={centerPosition}
          restorePan={restorePan}
          restoreZoom={restoreZoom}
        >
          {buildWmsLayer()}
          <Feature flag={FeatureFlags.AIS_RECEIVER_STATUS}>
            <AISReceiverLayer
              id={AIS_RECEIVER_LAYER_ID}
              title={layerNames[AIS_RECEIVER_LAYER_ID]?.name ?? ''}
              zIndex={AisReceiverLayerZIndex}
              showLabels={layerSettings[AIS_RECEIVER_LAYER_ID]?.showLabels ?? false}
              visible={layerSettings[AIS_RECEIVER_LAYER_ID]?.visible ?? false}
            />
          </Feature>
          <VesselLayer
            id={VESSEL_LAYER_ID}
            title={layerNames[VESSEL_LAYER_ID]?.name ?? ''}
            zIndex={VesselLayerZIndex}
            onClick={vesselFeatureClicked}
            showLabels={layerSettings[VESSEL_LAYER_ID]?.showLabels ?? false}
            visible={layerSettings[VESSEL_LAYER_ID]?.visible ?? false}
            onSpotlightVesselUpdate={onSpotlightVesselUpdate}
            spotlightVessels={spotlightVessels}
          />
          <Popup
            show={showVesselPopup}
            action='pointermove'
            overlayId='info'>
            <FeatureInfo />
          </Popup>
        </OLMap>
        <Drawer
          anchor="right"
          open={drawerOpen}
          className={classes.drawer}
          onClose={handleOnClose}
        >
          <VesselInfoPanel vessel={selectedFeature} onClose={drawerToggleClose} />
        </Drawer>
        {showLayerSwitcherControl && <LayerSwitcherControl layerSettings={layerSettings} onVisiblityChanged={layerVisiblityChanged} />}
      </Box>
    </MapContextProvider>
  );
}
export default PortMap;