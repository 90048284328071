import React, { useMemo, useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Popover,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  TextField,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { DataStore } from 'aws-amplify';
import { 
  getActionLastArrival, 
  getActionTime, 
} from '../../utils/getters';
import { checkPlannedTime } from '../../utils/customAction';
import { ActionState, Action } from '../../models';
import { add } from 'date-fns';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import useTariffBooks from '../../hooks/useTariffBooks';
import { createAllChargeablesItemFromBooks } from '../../utils/tariffBook';

const useStyles = makeStyles(() => ({
  formInput: {
    width: '100%',
    marginTop: '0.25rem',
    marginBottom: '1rem'
  },
  button: {
    margin: "1rem 0 1rem 1rem"
  }
}));

const CustomActionCreateDialog = ({
  portCall,
  customAction: initialCustomAction,
  customActionTypes,
  anchorEl,
  onClose
}) => {
  const dataStoreContext = useContext(DataStoreContext);
  const classes = useStyles();

  const initialActionState = ActionState.PLANNED
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const { tariffBooks } = useTariffBooks();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  // State to store selected custom Action
  const [selectedCustomAction, setSelectedCustomAction] = useState(initialCustomAction)

  // State to store custom action object to be added
  // Initialises an Action Object with the portCall,
  // The type of the custom action selected
  // A state of Planned
  // And a planned time of an hour after arrival
  const [customAction, setCustomAction] = useState(() => {
    // default to last arrival
    const defaultArrival = getActionLastArrival(portCall.actions);
    const timePlanned = add(new Date(getActionTime(defaultArrival)), { hours: 1 }).toISOString();
    // try to get template first
    const template = dataStoreContext.templates.find(t => t.typeId === selectedCustomAction.id);
    if (template) {
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, initialActionState, isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.timePlanned = timePlanned;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: initialActionState,
        type: { id: selectedCustomAction.id },
        timePlanned,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      });
    }
  });

  // Update the type of the custom action if there is a change
  useEffect(() => {
    if (!selectedCustomAction) return;
    if (selectedCustomAction.id === customAction.type.id) return;
    // try to get template
    const template = dataStoreContext.templates.find(t => t.typeId === selectedCustomAction.id);
    if (template) {
      // FIXME when tariff book fflag is removed, remove last two arguments
      setCustomAction(Action.copyOf(generateActionFromTemplate(template, dataStoreContext, undefined, isTariffBookEnabled), updated => {
        updated.remarks = customAction.remarks;
        updated.timePlanned = customAction.timePlanned;
        updated.portCall = customAction.portCall;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      }));
    } else {
      setCustomAction(Action.copyOf(customAction, updated => { updated.type = { id: selectedCustomAction.id }; }));
    }
  }, [selectedCustomAction, customAction, setCustomAction, dataStoreContext, isTariffBookEnabled, portCall.id, auditMeta])

  //Deconstructing Custom Action
  const {
    id,
    name,
    lifecycle,
  } = selectedCustomAction;

  // Set the custom action timeplanned as the new inputted
  const handleChangeTime = (value) => {
    setCustomAction(Action.copyOf(customAction, updated => { updated.timePlanned = value; }));
  };

  // Find the new custom action selected in the array of custom actions
  // Set the selectedCustomAction as the new one
  const handleSelectChange = (e) => {
    const customActionId = e.target.value;
    const newCustomAction = customActionTypes.find(cat => cat.id === customActionId);
    setSelectedCustomAction(newCustomAction);
  }

  // Set the remarks of the new action as the inputted value
  const handleNotesChange = (e) => {
    setCustomAction(Action.copyOf(customAction, updated => { updated.remarks = e.target.value; }));
  }

  // State to hold whether there is an error with the form
  const customActionError = useMemo(() => {
    const checkDeparture = false;
    const error = checkPlannedTime(customAction.timePlanned, lifecycle, portCall,checkDeparture);
    return error ? t(error) : '';
  }, [customAction.timePlanned, lifecycle, portCall, t]);

  // Save the action 
  const handleCreate = () => {
    if (!isTariffBookEnabled) {
      // FIXME when tariff book fflag is removed, use code from else
      DataStore.save(customAction);
    } else {
      const newCustomAction = Action.copyOf(customAction, updated => {
        updated.chargeableItems = createAllChargeablesItemFromBooks(
          customAction.timePlanned,
          customAction.type.id,
          undefined,
          tariffBooks,
          dataStoreContext.tariffUnits);
      }
      );
      DataStore.save(newCustomAction);
    }
    onClose();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      id="CreateCustomActionDialog"
    >
      <Card>
        <CardContent>
          {/* Title */}
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            <Typography variant="h5">{t('CustomActionCreateDialog.Labels.Title', { actionName: name })}</Typography>
            <IconButton id="CustomActionCloseButton" onClick={() => onClose()}><Close /></IconButton>
          </Box>
          {/* Custom Action */}
          <FormControl variant="outlined" className={classes.formInput} >
            <InputLabel id="custom-action-select-label">{t('CustomActionCreateDialog.Labels.Service')}</InputLabel>
            <Select
              labelId="custom-action-select-label"
              value={id}
              onChange={handleSelectChange}
              label={t('CustomActionCreateDialog.Labels.Service')}
            >
              {
                customActionTypes &&
                customActionTypes.map(cat =>
                  <MenuItem key={`custom-action-select${cat.id}`} value={cat.id}>{cat.name}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          {/* Time Planned */}
          <Box display="flex" height="fit-content">
            <StringKeyboardDateTimePicker
              warningPastDate={true}
              id="CustomActionTimePlannedInput"
              className={classes.formInput}
              label={t('CustomActionCreateDialog.Labels.PlannedTime')}
              value={customAction.timePlanned}
              onChange={handleChangeTime}
              error={!!customActionError}
              helperText={customActionError}
            />
          </Box>
          {/* Notes */}
          <FormControl variant="outlined" className={classes.formInput} >
            <TextField
              id="CustomActionNotesInput"
              label={t('CustomActionCreateDialog.Labels.Notes')}
              multiline
              minRows={4}
              variant="outlined"
              value={customAction.remarks}
              InputLabelProps={{ shrink: true }}
              onChange={handleNotesChange}
            />
          </FormControl>
          {/* Create Button */}
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              disabled={!!customActionError}
              onClick={handleCreate}
              id="CreateCustomActionCreateButton"
            >
              {t('Common.Buttons.Create')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

CustomActionCreateDialog.propTypes = {
  portCall: PropTypes.object,
  customAction: PropTypes.object,
  customActionTypes: PropTypes.array,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
}

CustomActionCreateDialog.defaultProps = {
  customActionTypes: []
}

export default CustomActionCreateDialog;
