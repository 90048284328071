import React, {useState, useRef, useEffect, useContext} from 'react';
import { 
  Typography,  
  Grid, 
  Button,
  makeStyles,
} from '@material-ui/core';
import ImageDialog from '../Image/ImageDialog';
import {
  croppedImageDimensions,
  STATUS,
} from '../../constants/Image';
import {
  parseImageData,
  stringifyImageData,
} from '../../utils/Image';
import ConfirmDialog from "../Dialog/ConfirmDialog";
import VesselImage from "../Image/VesselImage";
import { ImageFileContext, imageFileActionConstants } from "../../contexts/image";
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

//Constant dimensions for image based on 16:9 ratio
const { width, height } = croppedImageDimensions;

//Constants used for ImageContext 
const {STORE, UPLOAD, DELETE, RESET} = imageFileActionConstants;

//Function to do a basic check that the file is the right kind
//Takes in a file type, splits it anc checks that it is image type
const fileCheck = (type) => {
  if(!type) return false;
  const mainType = type.split("/")[0];
  return mainType === "image";
}

//For the redux field
const vesselImage = "image";

//The variable statuses that this component can be in
const {EMPTY, INITIAL, CROPPED, } = STATUS;

const useStyles = makeStyles(() => ({
  buttonLeft: {
    margin: "1rem",
    marginLeft: "0px",
  },
  buttonRight: {
    margin: "1rem",
    marginRight: "0px",
  },
  imgContainer: {
    marginTop: "1rem",
  },
  buttonContainer: {
    width: `${width}px`,
  }
}))

//Clear the file input which is got by id
const clearFileInput = () => {
    document.getElementById("raised-image-button-file").value="";
  }

//Takes in the imageData from the Vessel
//Takes in dispatch Item for the form changes
const VesselEditImageForm = ({
  imageData,
  onChange,
  hideButtons
}) => {
  //Status of the component - Initialises to be EMPTY
  const [status, setStatus] = useState(EMPTY);
  //Reference to the exsting Image Data before changes
  const imageDataRef = useRef(null);

  //Dialog for cropping the image
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  //Confimation box for file type errors or s3 errors
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmProps, setConfirmProps] = useState(null);

  //upImg is the variable used to store the image read as a file
  const [upImg, setUpImg] = useState();
  //This is a reference to the canvas component to write the cropped image
  const canvasRef = useRef(null);
  //This stores the file that is taken by the file input
  const file = useRef(null);
  //This is the state that store the cropped file that is set to be uploaded
  //With the file and fileKey 
  const [croppedFile, setCroppedFile] = useState(null);
  //Context with reducer so that the file can be stored before being uploaded 
  const [ImageFileContextState ,dispatchImageFile] = useContext(ImageFileContext);

  //photoKey is used for the name of the file to be uploaded
  const [photoKey, setPhotoKey] = useState(null);

  //This is used for redux to recognise a change the image
  //This will trigger the upload
  const photoCount = useRef(null);

  const classes = useStyles();
  const { t } = useTranslation();


  //Method to check if there is an imageData Key
  //Parse the JSON string of imageData to key and count
  //Sets a Ref for the photoCount for redux purposes:
  //To recognise change as the key doesn't change
  useEffect(() => {
    console.log("imageData", imageData);
    if(imageData && status === EMPTY ) {
      const {key, count} = parseImageData(imageData)
      if(!key || !count) return;
      setPhotoKey(key)
      photoCount.current = count;
      //If there isn't a dataRef for image
      //Then set it for a reference if things change
      if(!imageDataRef.current) {
        imageDataRef.current = imageData;
      }
      setStatus(INITIAL);
    } else if(imageData && status === CROPPED) {
      //We're overwriting the existing changes
      if(imageDataRef.current === imageData) {
        emptyCanvas();
        dispatchImageFile({type: RESET});
        setStatus(INITIAL)
      }
    }
  }, [imageData])

  useEffect(() => {
    if(ImageFileContextState && ImageFileContextState.error) {
      handleError(ImageFileContextState.process, ImageFileContextState.error);
    }
  }, [ImageFileContextState])

  //Method to handle wrong file type
  //Opens up a dialog with set message
  const handleWrongFileType = () => {
    setConfirmProps({
      title: t('VesselEditImageForm.Errors.FileTypeTitle'),
      message: t('VesselEditImageForm.Errors.FileTypeMessage'),
    })
    setOpenConfirm(true)
  }

  //Method to open dialog for error with uploading or deleting
  const handleError = (process, error) => {
    let text = process === UPLOAD ? t('VesselEditImageForm.Errors.FailedToUpload') : t('VesselEditImageForm.Errors.FailedToDelete');
    let title = text; 
    let message = text + t('VesselEditImageForm.Errors.ErrorMessage', {error: error.message});
    let messageSecondary = t('VesselEditImageForm.Errors.Support');
    
    setConfirmProps({
      title: title,
      message: message,
      messageSecondary: messageSecondary
    })
    setOpenConfirm(true);
  }

  //If there is a croppedImage from the dialog this method will run
  useEffect(() => {
    //If no Cropped file then return
    if(!croppedFile) return;

    //Set the status of the component to cropped
    setStatus(CROPPED);
    const {file: fileCrop, fileKey} = croppedFile;
    let count = photoCount.current + 1;
    if (!count) count = 0;
    const newImageData = stringifyImageData(fileKey, count);
    onChange(newImageData);
    dispatchImageFile({type: STORE , payload: {name: fileKey, file: fileCrop, type: UPLOAD}})
  }, [croppedFile, vesselImage]);

  //Method for when the file changes in the file input
  //Sets the file ref as the file if there is one
  //checks the type - if it's the wrong type then warn the user
  //otherwise read the file in and store as the upImg
  //Open the Img Dialog
  const handleFileChange = (e) => {
    if((!e.target.files || e.target.files.length === 0)) return;
    file.current = e.target.files[0]
    if(fileCheck(file.current.type)) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(file.current)
      setImageDialogOpen(true);
    } else {
      handleWrongFileType();
    }
  }

  //Method for emptying the canvas
  const emptyCanvas = () => {
    const canvas = canvasRef.current;
    canvas.style.display = "none";
    const context = canvas.getContext('2d');
    context.clearRect(0,0,0,0);
  }

  //Handling the deletion/discarding of an image in the canvas
  const handleDelete = () => {
    switch(status) {
      case INITIAL:
        onChange(null);
        dispatchImageFile({type: STORE, payload: {name: photoKey, file: null, type: DELETE}})
        setStatus(EMPTY)
        break;
      case EMPTY:
      case CROPPED:
        emptyCanvas();
        let newValue = imageDataRef.current;
        if(!newValue) newValue = null;
        console.log("newValue", newValue);
        onChange(newValue);
        dispatchImageFile({type: RESET});
        setStatus(EMPTY);
        break;
      default: break;
    }
  }

  //Render the button depending on the status
  const renderInputButton = () => {
    const direction = status !== EMPTY ? classes.buttonRight : classes.buttonLeft;
    return(
          <Grid item>
            <input
              style={{ display: 'none' }}
              id="raised-image-button-file"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-image-button-file">
              <Button 
                id="image-input-file"
                variant="contained" 
                component="span" 
                color="primary"
                className={direction}
              >
                {status === EMPTY ?
                t('VesselEditImageForm.Buttons.Select') : 
                t('VesselEditImageForm.Buttons.Replace')}
              </Button>
            </label>
          </Grid>
    )
  }

  //Render the discard button depending on status
  const renderDiscardButton = () => {
    const label = status === CROPPED ? t('VesselEditImageForm.Buttons.DiscardCrop') : t('VesselEditImageForm.Buttons.Discard');
    return(
            <Button
              className={classes.buttonLeft}
              color="primary"
              size="large"
              onClick={handleDelete}
            >
              <Typography>{label}</Typography>
            </Button>
    );
  }

  //Render buttons
  const renderButtons = () => {
    return(
      <>
        {status !== EMPTY && 
          <Grid item>
            {renderDiscardButton()}
          </Grid>
        }
        {renderInputButton()}
      </>
    )
  }


  return (
    <>
      <Grid container styles={{margin: "0 auto"}} direction="column">
        <Grid item className={status !== EMPTY ? classes.imgContainer : ""}>
          {status === INITIAL && <VesselImage image={imageData} containerStyles={{width: width, height: height}}  /> }
          <canvas style={{display: "none"}} ref={canvasRef}></canvas>
        </Grid>
        <Grid container className={classes.buttonContainer} justifyContent="space-between">
          {!hideButtons && renderButtons()}
        </Grid>
      </Grid>
      <ImageDialog
        setOpen={setImageDialogOpen}
        open={imageDialogOpen}
        upImg={upImg}
        canvasRef={canvasRef}
        file={file.current}
        setCroppedFile={setCroppedFile}
        photoKey={photoKey}
        clearFileInput={clearFileInput}
      />
      <ConfirmDialog 
        {...confirmProps} 
        onClose={() => {
            handleDelete();
            setOpenConfirm(false);
          }
        }
        open={openConfirm}
      />
    </>
  )
}

export default VesselEditImageForm;