import React, { createContext, useReducer } from 'react';

const initialState = {
  authError: null, // { inputs: ['username', 'password'], data: 'Error Message' }
  accessToken: null, // { jwtToken, payload: { auth_time, client_id, cognito:groups: [], exp, iat, iss, jti, scope, sub, token_use, username } }
  readOnly: true,
  agentPortal: false,
  userContactId: '',
  isAdmin: false,
  isVoyageAdmin: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setAuthError':
      return Object.assign({}, state, {
        authError: action.payload.error,
      });

    case 'setAccessToken':
      return Object.assign({}, state, {
        accessToken: action.payload,
      });

    case 'setReadOnly':
      return Object.assign({}, state, {
        readOnly: action.payload,
      });

    case 'setAgentPortal':
      return Object.assign({}, state, {
        agentPortal: action.payload
      });

    case 'setUserContactId':
      return Object.assign({}, state, {
        userContactId: action.payload
      });

    case 'setIsAdmin':
      return Object.assign({}, state, {
        isAdmin: action.payload,
      });

    case 'setIsVoyageAdmin':
      return Object.assign({}, state, {
        isVoyageAdmin: action.payload,
      });

    case 'setIsApprover':
      return Object.assign({}, state, {
        isApprover: action.payload,
      });

    case 'setUserName':
      return Object.assign({}, state, {
        userName: action.payload,
      });


    default:
      return state;
  }
};

// actions

export const setAuthError = (error) => ({
  type: 'setAuthError',
  payload: { error }
});

export const setAccessToken = (accessToken) => ({
  type: 'setAccessToken',
  payload: accessToken,
});

export const setReadOnly = (readOnly) => ({
  type: 'setReadOnly',
  payload: readOnly,
});

export const setAgentPortal = (agentPortal) => ({
  type: 'setAgentPortal',
  payload: agentPortal,
});

export const setUserContactId = (userContactId) => ({
  type: 'setUserContactId',
  payload: userContactId,
});

export const setIsAdmin = (isAdmin) => ({
  type: 'setIsAdmin',
  payload: isAdmin,
});

export const setIsVoyageAdmin = (isVoyageAdmin) => ({
  type: 'setIsVoyageAdmin',
  payload: isVoyageAdmin,
});

export const setIsApprover = (isApprover) => ({
  type: 'setIsApprover',
  payload: isApprover
});

export const setUserName = (userName) => ({
  type: 'setUserName',
  payload: userName,
});

export const UIContext = createContext();

export const UIContextProvider = ({ children }) => (
  <UIContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </UIContext.Provider>
);
