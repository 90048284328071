import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getMarlinAccessToken } from '../graphql/queries';

const MARLIN_AUTH_REFRESH_TOKEN_KEY = 'smartport::marlinAuth::token'

export const useInterval = (callback, interval, immediateRun, deps) => {
  const savedCallback = React.useRef(callback)
  const intervalId = React.useRef()

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    const tick = () => savedCallback.current()

    if (interval !== null) {
      immediateRun && tick()
      const id = intervalId.current = setInterval(tick, interval)
      return () => clearInterval(id)
    }
  }, [interval, immediateRun, ...(deps ?? [])])

  return intervalId
}

const minToMil = (min) => (1000 * 60 * min)

export const MarlinAuthContext = React.createContext();

export const MarlinAuthProvider = ({ wmsList, children }) => {

  const [needAuth, setNeedAuth] = React.useState(false)
  const [refreshToken, setRefreshToken] = React.useState(undefined)
  const [accessToken, setAccessToken] = React.useState(undefined)
  const [accessTokenExpiryAt, setAccessTokenExpiryAt] = React.useState(0)
  const [isQuerying, setIsQuerying] = React.useState(false)

  const accessTokenPromise = React.useRef()

  const updateMarlinAccessToken = React.useCallback(async () => {
    setIsQuerying(true)
    accessTokenPromise.current = API.graphql(graphqlOperation(getMarlinAccessToken, { 
      refreshToken: refreshToken
    }));
    const { data } = await accessTokenPromise.current;
    if(data?.getMarlinAccessToken?.accessTokenJwt !== undefined) {
      setRefreshToken(data?.getMarlinAccessToken?.refreshToken)
      setAccessToken(data?.getMarlinAccessToken?.accessTokenJwt)
      setAccessTokenExpiryAt(data?.getMarlinAccessToken?.accessTokenExpiry)
    }
    setIsQuerying(false)
  }, [refreshToken])

  React.useEffect(() => {
    const _needAuth = wmsList?.find(wms => wms?.marlinAuth) !== undefined
    setNeedAuth(_needAuth)
    if(_needAuth) {
      setRefreshToken(localStorage.getItem(MARLIN_AUTH_REFRESH_TOKEN_KEY)) 
    }
    return () => {
      API.cancel(accessTokenPromise.current);
    }
  }, [])

  React.useEffect(() => {
    if(refreshToken !== undefined) {
      localStorage.setItem(MARLIN_AUTH_REFRESH_TOKEN_KEY, refreshToken)
    }
  }, [refreshToken])

  const isValidAccessToken = React.useCallback(() => {
    if(accessTokenExpiryAt !== 0) {
      const accessTokenExpiryDate = new Date(accessTokenExpiryAt * 1000)
      return (accessTokenExpiryDate.getTime() - (new Date()).getTime()) > minToMil(5)      
    }
    return false
  }, [accessTokenExpiryAt])

  useInterval(() => {
    if (needAuth && !isValidAccessToken() && !isQuerying) {
      updateMarlinAccessToken()
    }
  }, minToMil(1), true, [isValidAccessToken, isQuerying, needAuth])

  return (
    <MarlinAuthContext.Provider value={{accessToken}}>
      {children}
    </MarlinAuthContext.Provider>
  )
};
