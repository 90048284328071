import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

import DialogLoadingProgress from './DialogLoadingProgress';

/**
 * Simple confirm dialog
 */
const ConfirmDialog = ({ error, open, title, message, messageSecondary, onConfirm, onClose, cancelText, confirmText, hideCancel, modal, loading }) => {
  const { t } = useTranslation();
  const _cancelText = cancelText ? cancelText : t("Common.Buttons.Cancel");
  const _confirmText = confirmText ? confirmText : t("Common.Buttons.Confirm");
  
  const handleClose = (event, reason) => {
    if(modal && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} id="ConfirmDialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {!!messageSecondary && <DialogContentText>{messageSecondary}</DialogContentText>}
        <DialogLoadingProgress 
          loading={loading} 
          errorMessage={error?.message}
          errorCode={error?.errorCode}
        />
      </DialogContent>
      <DialogActions>
        {!hideCancel &&
          <div id={'ConfirmDialogCancelButton'}>
            <Button 
              onClick={onClose}
              color="default"
              disabled={loading}
            >
              {_cancelText}
            </Button>
          </div>
        }
        {onConfirm &&
          <div id={'ConfirmDialogConfirmButton'}>
            <Button
              onClick={onConfirm}
              color="primary"
              disabled={loading}
            >
              {_confirmText}
            </Button>
          </div>
        }
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  messageSecondary: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  hideCancel: PropTypes.bool,
};

export default ConfirmDialog;