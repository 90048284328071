import React, {
  useContext,
  useEffect,
  useCallback,
  useState, useMemo
} from 'react';
import {
  Button,
  CardContent,
  Grid,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ContentIndex from '../../ContentIndex';
import VesselDataEdit from '../../Vessel/VesselDataEdit';
import CargoTab from '../Tabs/CargoTab';
import PortCallTimeline from '../PortCallTimeline';
import { NavigationContext } from '../../../contexts/navigation';
import DocumentEdit from '../../Documents/DocumentEdit';
import { PortCall, RequestState, RequestType, VesselData, ActionMovementType, Request } from '../../../models';
import { DataStore } from 'aws-amplify';
import Loading from '../../Loading';
import isEqual from 'lodash.isequal';
import { DataContext, DataContextConstants } from '../../../contexts/dataContext';
import { PortCallDetailsHref } from '../../../constants/PortCallDetails';
import {
  getPendingPortCallRequests,
  isAgentAssociatedWithPortCall
} from '../../../utils/getters';
import { saveCargo } from '../utils';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import UnknownVesselDisplayField from '../../Vessel/UnknownVesselDisplayField';
import RequestDetails from '../../Requests/RequestDetails';
import EditorContentTabs, { EditorContentTabIds, useEditorContentTab } from '../../Common/EditorContentTabs';
import { buildRequestSource, getAgentContact } from '../../../utils/agentPortal';
import TextInputDialog from '../../Dialog/TextInputDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  scrollPane: {
    height: '100%',
    overflow: 'auto',
  },
  noScroll: {
    boxSizing: 'content-box',
    height: 'auto',
    maxHeight: '100%',
  },
  rootPane: {
    display: "flex",
    flexFlow: "column",
    height: '100%',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    position: 'relative'
  },
  whitePanel: {
    height: '100%',
    backgroundColor: theme.palette.white
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  card: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: "0px"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  requestCard: {
    padding: 0,
    margin: 0,
  },
  requestButton: {
    marginRight: "1rem",
  },
  requestCardContent: {
    paddingBottom: 0,
    minHeight: "auto",
  },
  cardRoot: {
    padding: 0,
  },
  notchedNoOutline: {
    border: "none"
  },
  requestBadge: {
    background: "#ff9800",
    color: "white"
  },
  contentWrapper: {
    '& > :last-child': {
      marginBottom: "5rem"
    }
  }
}));

// editable fields that should be merged in when updated
const PORTCALL_MERGE_FIELDS = [
  "cargos",
  "documents",
];

const AgentPortCallDetails = ({ id, requestId, portCallDetails, userContactId }) => {
  const { t } = useTranslation();
  const [portCallReference, setPortCallReference] = useState(null);
  const [portCall, setPortCall] = useState(null);
  const [isUnknownVessel, setIsUnknownVessel] = useState(false);
  const [confirmSave, setConfirmSave] = useState({open: false, openFunction: null});
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!isEqual(portCallReference, portCallDetails)) {
      setPortCall(prev => {
        // try to merge
        if (prev && portCallDetails && portCallReference) {
          return PortCall.copyOf(portCallDetails, updated => {
            // iterate through keys and merge current edits
            PORTCALL_MERGE_FIELDS.map(key => {
              if(isEqual(portCallDetails[key], portCallReference[key])) {
                updated[key] = prev[key];
              }
            });
          });
        }
        return portCallDetails;
      });
      setPortCallReference(portCallDetails);
      if (portCallDetails) {
        setIsUnknownVessel(Boolean(!portCallDetails.vessel));
      }
    }
  }, [portCallReference, portCallDetails, setPortCallReference, setPortCall, setIsUnknownVessel]);

  useEffect(() => {
    if (!portCallReference) return;
    
    //Take the user back to the root page if they are not assoicated with the port call
    if(!isAgentAssociatedWithPortCall(portCallReference, userContactId)) {
      history.push('/');
    }
    
    setPortCall(portCallReference);
    setIsUnknownVessel(Boolean(!portCallReference.vessel));
  }, [portCallReference, setPortCall, setIsUnknownVessel, userContactId, history]);

  const setVessel = useCallback(value => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.vessel = value;
      updated.vesselData = Boolean(value) ? value.vesselData : new VesselData({})
    }));
  }, [portCall, setPortCall]);
  const setVesselData = useCallback(value => setPortCall(PortCall.copyOf(portCall, updated => { updated.vesselData = value })), [portCall, setPortCall]);

  const cargos = useMemo(() => portCall && portCall.cargos ? portCall.cargos : [], [portCall]);
  const setCargos = useCallback(value => setPortCall(PortCall.copyOf(portCall, updated => { updated.cargos = value; })), [portCall, setPortCall]);

  const classes = useStyles();

  const [navigationContext,] = useContext(NavigationContext);
  const [dataContext, dispatchDataContext] = useContext(DataContext);

  const portCallRequests = useMemo(() => getPendingPortCallRequests(portCall), [portCall]);

  useEffect(() => {
    if (portCall && dataContext.pendingCreateVessel) {
      dispatchDataContext({ type: DataContextConstants.SET_PENDING_CREATE_VESSEL, payload: null });
      setVessel(dataContext.pendingCreateVessel);
    }
  }, [dataContext, dispatchDataContext, portCall, setVessel]);

  const trySavePortCall = () => {
    if (!isEqual(portCallReference.cargos, cargos)) {
      setOpenUpdateCargo(true);
    } else {
      handleSavePortCall();
    }
  };

  const handleSavePortCall = async (remark) => {
    DataStore.save(portCall);
    saveCargo(portCallReference, cargos);
    if (openUpdateCargo) {
      const agent = await getAgentContact(userContactId);
      const requestSource = await buildRequestSource(agent);
      // check if a pending notification (request) exists
      const pendingNotification = (await DataStore.query(Request, c => c
        .requestPortCallId_("eq", portCall.id)
        .state("eq", RequestState.REQUEST_STATE_NOTIFICATION).type("eq", RequestType.REQUEST_TYPE_UPDATE_PORTCALL_CARGO))
      ).pop();
      if (pendingNotification) {
        // update remark
        await DataStore.save(Request.copyOf(pendingNotification, updated => {
          updated.remark = remark;
          updated.updatedAt = new Date().toISOString();
        }));
      } else {
        // create new notification
        await DataStore.save(new Request({
          portCall: portCall,
          requestPortCallId_: portCall.id,
          state: RequestState.REQUEST_STATE_NOTIFICATION,
          type: RequestType.REQUEST_TYPE_UPDATE_PORTCALL_CARGO,
          source: requestSource,
          portCallData: {
            vesselName: portCall.vesselData.name,
            vesselImo: portCall.vesselData.imo,
            vesselMmsi: portCall.vesselData.mmsi,
            vesselCallsign: portCall.vesselData.callSign
          },
          agent,
          remark
        }))
      }
      setOpenUpdateCargo(false);
    }
  }

  const [openUpdateCargo, setOpenUpdateCargo] = useState(false);

  // TODO until datastore supports empty string arrays
  const documents = useMemo(() => portCall && portCall.documents ? portCall.documents.filter(d => d) : [], [portCall]);
  const setDocuments = useCallback((value) => DataStore.save(PortCall.copyOf(portCallReference, updated => { updated.documents = value.length ? value : ['']; })), [portCallReference]);

  const saveDisabled = useMemo(() =>
    (!portCall || !portCallReference) ||
    (!portCall.vesselData.name) ||
    (!isUnknownVessel && !portCall.vessel) ||
    isEqual(portCall, portCallReference) || // TODO might get too heavy
    Boolean(portCall.cargos.find(c => !c.type)), // empty cargo type
    [portCall, portCallReference, isUnknownVessel]);

    const checkSaveState = () => {
      setConfirmSave({open: true}) ;
    }

  const tab = useEditorContentTab();

  const indexTree = useMemo(() => [
    {
      label: t('PortCallDetails.Labels.Attachments'),
      href: PortCallDetailsHref.HREF_ATTACHMENTS,
    },
    {
      label: t('Vessel.Labels.Vessel'),
      href: PortCallDetailsHref.HREF_VESSEL,
      children: [
        {
          href: PortCallDetailsHref.HREF_IDENTITY,
          label: t('Vessel.Labels.Identity'),
        },
        {
          href: PortCallDetailsHref.HREF_DETAILS,
          label: t('Vessel.Labels.Details'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_DIMENSIONS,
          label: t('Vessel.Labels.Dimensions'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_TONNAGE,
          label: t('Vessel.Labels.Tonnage'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_AGENT,
          label: t('Agent.Labels.Agent'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_CERTIFICATES,
          label: t('CertificateType.Labels.Certificates'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_PROPULSION,
          label: t('Vessel.Labels.Propulsion'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_RUDDER,
          label: t('Vessel.Labels.Rudder'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_FWD_AZIMUTH,
          label: t('Vessel.Labels.ForwardAzimuth'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_AFT_AZIMUTH,
          label: t('Vessel.Labels.AftAzimuth'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_FWD_TUNNEL,
          label: t('Vessel.Labels.ForwardTunnelThrusters'),
        },
        {
          href: PortCallDetailsHref.HREF_VESSEL_AFT_TUNNEL,
          label: t('Vessel.Labels.AftTunnelThrusters'),
        }
      ]
    },
    {
      label: t('Cargo.Labels.Cargo'),
      href: PortCallDetailsHref.HREF_CARGO,
    },
  ], [t]);  

  // return by timeout if item gets deleted and results in empty view
  useEffect(() => {
    let timeout;
    if (!portCall) {
      timeout = setTimeout(() => {
        history.push(navigationContext.lastView);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [portCall, history, location]);

  if (!portCall) return <Loading />
  return (
    <Grid container id="PortCallDetails">
      <Grid
        className={classes.greyPanel}
        item
        xs={5}
      >
        {portCall &&
          <PortCallTimeline 
            portCallReference={portCallReference}
            portCall={portCall} 
            movementTypeFilter={[ActionMovementType.ARRIVAL, ActionMovementType.DEPARTURE, ActionMovementType.SHIFT_ARRIVAL, ActionMovementType.SHIFT_DEPARTURE]}
            disableLifeCycle
            saveDisabled={saveDisabled}
          />
        }
      </Grid>

      <Grid
        className={`${classes.rootPane}`}
        item
        xs={7}
      >
        <CardContent style={{flexShrink: 0}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <EditorContentTabs requests={portCallRequests} />
            <Box position="absolute" right="2rem" display="flex">
              <Typography>
                <Link
                  className={classes.linkButton}
                  to={navigationContext.lastView}
                >
                  {!saveDisabled ? t("Common.Buttons.DiscardChanges") : t("Common.Buttons.Close")}
                </Link>
              </Typography>
              {tab === EditorContentTabIds.DETAILS &&
              <Button
                color="primary"
                onClick={trySavePortCall}
                variant="contained"
                disabled={saveDisabled}
              >
                {t("Common.Buttons.Save")}
              </Button>
              }
            </Box>
          </Grid>
        </CardContent>
        {tab === 0 && 
        <Grid
          item
          container
        >
          <CardContent style={{flexShrink: "0"}}>
            <ContentIndex tree={indexTree} containerId="portcalldetailscontent" />
          </CardContent>
          <Box flexGrow="1" overflow="auto" height="100%" id="portcalldetailscontent" className={classes.contentWrapper}>
          <CardContent>
          <Typography 
            id={PortCallDetailsHref.HREF_ATTACHMENTS}
            variant="h6"
            style={{paddingBottom: '0.5rem'}}
            >
          {t('PortCallDetails.Labels.Attachments')}
          </Typography>
            <DocumentEdit
              anchorId="port-call-attachments"
              filePath={`portcalls/${portCall.id}/`}
              portCallId={portCall.id}
              objId={portCall.id}
              objDocuments={documents}
              onChange={setDocuments}
              disableDownload
              disableDelete
              checkSaveState={checkSaveState}
              saveDisabled={saveDisabled}
            />
          </CardContent>
          <CardContent className={classes.card}>
            {portCall.vessel ?
              <VesselDataEdit
                actions={portCall.actions}
                readOnly
                vesselData={portCall.vesselData}
                setVesselData={setVesselData}
                vessel={portCall.vessel}
                setVessel={setVessel}
                portCallId={id}
                editVessel={saveDisabled}
                portCallStatus={portCall.status}
              />
              :
              <UnknownVesselDisplayField vessel={portCall.vesselData}/>
            }
          </CardContent>
          <CargoTab
              id={id}
              cargos={cargos}
              setCargos={setCargos}
            />
          </Box>
        </Grid>
        }
        {tab === 1 && 
          <Grid
            className={classes.scrollPane}
            item
          >
            {portCallRequests.map(r => <RequestDetails request={r} key={r.id} portCall={portCall} />)}
          </Grid>
        }
        <ConfirmDialog
          open={confirmSave.open}
          title={t("Common.Labels.UnsavedChanges")}
          message={t("PortCallDetails.Labels.UnsavedChangesMessage")}
          onClose={() => {setConfirmSave({open:false})}}
          confirmText={t("Common.Buttons.Leave")}
        />
        {openUpdateCargo && <TextInputDialog
          open={openUpdateCargo}
          title={t("PortCallDetails.Labels.ConfirmChangeToCargo")}
          onClose={() => setOpenUpdateCargo(false)}
          onConfirm={handleSavePortCall}
          inputLabel={t("PortCallDetails.Labels.Remarks")}
        />}
      </Grid>
    </Grid>
  );
};

export default AgentPortCallDetails;