import { DataStore } from 'aws-amplify';
import { useState, useEffect, useContext } from 'react';
import { Action, ActionType, Cargo, Contact, PortCall, PortCallStatus, Request, RequestState, Vessel, Location, CargoType, ActionState, ContactType, Category, PortCallAgent } from '../models';
import { getActionTime } from '../utils/getters';
import { sortActions } from '../utils/sorters';
import { DataStoreContext } from '../contexts/dataStoreContext';

/**
 * useActiveActions
 * 
 * Helper for querying and managing subscriptions of active actions (not CANCELLED, SUPERSEDED, CLOSED or DELETED) with populated port call details
 */

// TODO ms: sorting subscriptions

const useActiveActions = (subscribeIgnoreAction = false) => {
  const [items, setItems] = useState([]);
  const { locations, contacts } = useContext(DataStoreContext);

  const locationsMap = new Map();
  locations.forEach(el => locationsMap.set(el.id, el));

  const contactsMap = new Map();
  contacts.forEach(el => contactsMap.set(el.id, el));

  useEffect(() => {
    let cancelled = false;
    const update = async () => {
      var t0 = performance.now();
      const portCalls = await DataStore.query(PortCall, c => c.status("ne", PortCallStatus.CLOSED).status("ne", PortCallStatus.DELETED).status("ne", PortCallStatus.CANCELLED).status("ne", PortCallStatus.SUPERSEDED));
      const actions_ = await DataStore.query(Action, c => c.or((c) => portCalls.reduce((c, p) => c.actionPortCallId_('eq', p.id), c)).state("ne", ActionState.DELETED).state("ne", ActionState.CANCELLED).state("ne", ActionState.SUPERSEDED));
      const cargos = await DataStore.query(Cargo, c => c.or((c) => portCalls.reduce((c, p) => c.cargoPortCallId('eq', p.id), c)));
      const requests = await DataStore.query(Request, c => c.or((c) => portCalls.reduce((c, p) => c.requestPortCallId_('eq', p.id), c)).state("eq", RequestState.REQUEST_STATE_PENDING));
    
      // movementLocation and actionAgent added to action 
      const actions = actions_.map(action => {
        return Action.copyOf(action, updated => {          
          updated.movementLocation = action.actionMovementLocationId ? 
            locationsMap.get(action.actionMovementLocationId) : 
            null ;
          updated.actionAgent = action.actionActionAgentId ? 
            contactsMap.get(action.actionActionAgentId) : 
            null;
        });
      });

      var t1 = performance.now()
      // console.log('useActiveActions:', `Queried in ${Math.floor(t1 - t0)} milliseconds.`);
      if (!cancelled) {
        setItems(() => {
          const res = actions
            .map(a => Action.copyOf(a, updated => {
              const portCall = a.portCall && portCalls.find(p => p.id === a.portCall.id);
              if (!portCall) return null; // retarded HANDOVER_EVENTs and their PLANNED state
              updated.portCall = PortCall.copyOf(portCall, updated => {
                updated.actions = actions.filter(a => a.portCall && a.portCall.id === portCall.id);
                updated.cargos = cargos.filter(c => c.cargoPortCallId === portCall.id);
                updated.requests = requests.filter(c => c.portCall && c.portCall.id === portCall.id);
              });
            }))
            .filter(a => a)
            .sort(sortActions)
          return res;
        });
      }
    };
    locations && update();
    const subscriptions = [];
    subscriptions.push(DataStore.observe(PortCall).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted &&
        ( opType === "UPDATE" || opType === "INSERT") && 
        ( element.status !== PortCallStatus.CLOSED && 
          element.status !== PortCallStatus.CANCELLED &&
          element.status !== PortCallStatus.DELETED &&
          element.status !== PortCallStatus.SUPERSEDED
        );

      // access latest data
      let action, item;
      setItems(prev => {
        action = prev && prev.find(i => i.portCall.id === element.id);
        if (action) item = action.portCall;
        return prev; // this doesn't emit a state change
      });
      // skip if no relevant action
      if (!action) return;
      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled) return;

      if (isUpdateOp) {
        // update
        const vessel = element.portCallVesselId ? await DataStore.query(Vessel, element.portCallVesselId) : null;
        if (cancelled) return;
        setItems(prev => !prev ? prev :
          prev.map(i => (i.actionPortCallId || i.portCall.id) === element.id
            // update matching
            ? Action.copyOf(i, updated => {
              updated.portCall = PortCall.copyOf(element, updated => {
                updated.vessel = vessel;
                // take connections from cached data
                updated.actions = i.portCall.actions;
                updated.cargos = i.portCall.cargos;
                updated.requests = i.portCall.requests;
                updated.agents = i.portCall.actions.map(a => a.actionAgent && new PortCallAgent({ contactId: a.actionAgent.id, actionId: a.id, time: getActionTime(a) })).filter(a => a);
              });
            })
            : i)
            .sort(sortActions)
        );
      } else if (item) {
        // remove
        setItems(prev => !prev ? prev : prev.filter(i => (i.actionPortCallId || i.portCall.id) !== element.id));
      }
    }));
    subscriptions.push(DataStore.observe(Action).subscribe(async msg => {
      const { element, opType } = msg;
      const _isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT") && element.state !== ActionState.DELETED;
      const isUpdateOp = subscribeIgnoreAction ? !element._deleted && (opType === "UPDATE" || opType === "INSERT") : _isUpdateOp;

      // access latest data
      let item;
      setItems(prev => {
        item = prev && prev.find(i => i.id === element.id);
        return prev; // this doesn't emit a state change
      });

      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled) return;

      const portCall = (element.actionPortCallId_ || element.portCall?.id) ? await DataStore.query(PortCall, (element.actionPortCallId_ || element.portCall.id)) : null;
      const filterPortCall = (portCall.status !== PortCallStatus.CLOSED && portCall.status !== PortCallStatus.CANCELLED && portCall.status !== PortCallStatus.DELETED && portCall.status !== PortCallStatus.SUPERSEDED);
      const filterOp = subscribeIgnoreAction ? true : filterPortCall;   

      if (isUpdateOp && portCall && filterOp) {
        // update
        const t0 = performance.now();
        // action connections
        const actionAgent = element.actionActionAgentId ? await DataStore.query(Contact, element.actionActionAgentId) : null;
        // port call connections
        const vessel = portCall && portCall.vessel ? await DataStore.query(Vessel, portCall.vessel.id) : null;
        const actions_ = portCall ? await DataStore.query(Action, c => c.actionPortCallId_("eq", portCall.id)) : [];
        const cargos = portCall ? await DataStore.query(Cargo, c => c.cargoPortCallId("eq", portCall.id)) : [];
        const requests = portCall ? await DataStore.query(Request, c => c.state("eq", RequestState.REQUEST_STATE_PENDING).requestPortCallId_("eq", portCall.id)) : [];
        const t1 = performance.now();
        console.log('useActiveActions Action subscription:', `Queried in ${Math.floor(t1 - t0)} milliseconds.`);
        if (cancelled) return;

        const actions = actions_.map(action => {
          return Action.copyOf(action, updated => {          
            updated.movementLocation = action.actionMovementLocationId ? 
              locationsMap.get(action.actionMovementLocationId) : 
              null ;
            updated.actionAgent = action.actionActionAgentId ? 
              contactsMap.get(action.actionActionAgentId) : 
              null;
          });
        });

        setItems(prev => {
          // reconstruct portCall
          const p = PortCall.copyOf(portCall, updated => {
            updated.vessel = vessel;
            updated.actions = actions;
            updated.cargos = cargos;
            updated.requests = requests;
            updated.agents = actions.map(a => a.actionAgent && new PortCallAgent({ contactId: a.actionAgent.id, actionId: a.id, time: getActionTime(a) })).filter(a => a);
          });
          return [
            // filter out actions related to action's portCall
            ...(!prev ? [] : prev.filter(a => a.portCall.id !== portCall.id)),
            // action from this subscription is part of query for actions and does not need a special handling case
            ...actions.map(a => Action.copyOf(a, updated => {
              updated.portCall = p;
              if (updated.id === element.id) {
                updated.actionAgent = actionAgent;
              }
            }))
          ].sort(sortActions);
        });
      } else if (item) {
        // remove
        setItems(prev => !prev ? prev : [
          ...prev.filter(i => i.portCall.id !== (element.actionPortCallId || element.portCall.id)),
          // update all actions of related port call and filter out deleted entry
          ...prev.filter(i => i.portCall.id === (element.actionPortCallId || element.portCall.id) && i.id !== element.id).map(i => Action.copyOf(i, updated => {
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              updated.actions = updated.actions.filter(a => a.id !== element.id);
            })
          }))
        ].sort(sortActions));
      }
    }));
    subscriptions.push(DataStore.observe(Cargo).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let action, item;
      setItems(prev => {
        action = prev && prev.find(i => i.portCall.id === element.cargoPortCallId);
        if (action) {
          const cargoAction = prev.find(i => i.portCall.id === element.cargoPortCallId && i.portCall.cargos && i.portCall.cargos.find(c => c.id === element.id));
          if (cargoAction) item = cargoAction.portCall.cargos.find(c => c.id === element.id);
        }
        return prev; // this doesn't emit a state change
      });
      // skip if no relevant action
      if (!action) return;
      // check version if entry exists
      if (item && item._version >= element._version) return;

      if (isUpdateOp) {
        const type = element.cargoTypeId ? await DataStore.query(CargoType, element.cargoTypeId) : null;
        if (cancelled) return;
        // update relevant item
        setItems(prev => !prev ? prev :
          prev.map(i => i.portCall.id === element.cargoPortCallId
            ? Action.copyOf(i, updated => {
              updated.portCall = PortCall.copyOf(i.portCall, updated => {
                updated.cargos = [
                  ...(i.portCall.cargos ? i.portCall.cargos.filter(c => c.id !== element.id) : []),
                  Cargo.copyOf(element, updated => { updated.type = type; })
                ];
              });
            })
            : i)
        );
      } else if (item) {
        setItems(prev => !prev ? prev :
          prev.map(i => i.portCall.id === element.cargoPortCallId
            ? Action.copyOf(i, updated => {
              updated.portCall = PortCall.copyOf(i.portCall, updated => {
                updated.cargos = i.portCall.cargos ? i.portCall.cargos.filter(c => c.id !== element.id) : [];
              });
            })
            : i)
        );
      }
    }));
    subscriptions.push(DataStore.observe(Request).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT") && (element.state === RequestState.REQUEST_STATE_PENDING);

      // access latest data
      let action, item;
      setItems(prev => {
        action = prev && prev.find(i => i.portCall.id === element.requestPortCallId);
        if (action) {
          const requestAction = prev.find(i => i.portCall.id === element.requestPortCallId && i.portCall.requests && i.portCall.requests.find(r => r.id === element.id));
          if (requestAction) item = requestAction.portCall.requests.find(r => r.id === element.id);
        }
        return prev; // this doesn't emit a state change
      });
      // skip if no relevant action
      if (!action) return;
      // check version if entry exists
      if (item && item._version >= element._version) return;
      if (cancelled) return;

      if (isUpdateOp) {
        const agent = element.requestAgentId ? await DataStore.query(Contact, element.requestAgentId) : null;
        if (cancelled) return;
        // update relevant item
        setItems(prev => !prev ? prev :
          prev.map(i => i.portCall.id === element.requestPortCallId
            ? Action.copyOf(i, updated => {
              updated.portCall = PortCall.copyOf(i.portCall, updated => {
                updated.requests = [
                  ...(i.portCall.requests ? i.portCall.requests.filter(c => c.id !== element.id) : []),
                  Request.copyOf(element, updated => { updated.agent = agent; })
                ]
              });
            })
            : i)
        );
      } else if (item) {
        setItems(prev => !prev ? prev :
          prev.map(i => i.portCall.id === element.requestPortCallId
            ? Action.copyOf(i, updated => {
              updated.portCall = PortCall.copyOf(i.portCall, updated => {
                updated.requests = i.portCall.requests ? i.portCall.requests.filter(c => c.id !== element.id) : [];
              });
            })
            : i)
        );
      }
    }));
    subscriptions.push(DataStore.observe(Contact).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let item;
      setItems(prev => {
        for (let a of prev) {
          // action.actionAgent
          if (a.actionAgent?.id === element.id) {
            item = a.actionAgent;
            break;
          }
          // action.portcall.[actions].actionAgent
          if (a.portCall?.actions) {
            for (let a2 of a.portCall?.actions) {
              if (a2.actionAgent?.id === element.id) {
                item = a2.actionAgent;
                break;
              }
            }
          }
          // action.portcall.[requests].agent
          if (a.portCall?.requests) {
            for (let r of a.portCall?.requests) {
              if (r.agent?.id === element.id) {
                item = r.agent;
                break;
              }
            }
          }
          // action.portcall.vessel.vesselAgent
          if (a.portCall?.vessel?.vesselAgent?.id === element.id) {
            item = a.portCall.vessel.vesselAgent;
            break;
          }
          if (item) break;
        }
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item?._version >= element._version) return;
      if (cancelled) return;

      let newItem = null;
      if (isUpdateOp) {
        const type = element.contactTypeId ? await DataStore.query(ContactType, element.contactTypeId) : null;
        newItem = Contact.copyOf(element, updated => { updated.type = type; });
      }
      if (cancelled) return;

      // update relevant item
      setItems(prev => !prev ? prev :
        prev.map(i =>
          Action.copyOf(i, updated => {
            // action.actionAgent
            if (updated.actionAgent?.id === element.id) {
              updated.actionAgent = newItem;
            }
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              // action.portcall.[requests].agent
              updated.requests = updated.requests ? updated.requests.map(r => r.agent?.id === element.id ? Request.copyOf(r, updated => { updated.agent = newItem; }) : r) : [];
              // action.portcall.[actions].actionAgent
              updated.actions = updated.actions ? updated.actions.map(a => a.actionAgent?.id === element.id ? Action.copyOf(a, updated => { updated.actionAgent = newItem; }) : a) : [];
              // action.portcall.vessel.vesselAgent
              if (updated.vessel?.vesselAgent?.id === element.id) {
                updated.vessel = Vessel.copyOf(updated.vessel, updated => { updated.vesselAgent = newItem; });
              }
            });
          }))
      );
    }));
    subscriptions.push(DataStore.observe(ActionType).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let item;
      setItems(prev => {
        for (let a of prev) {
          // action.type
          if (a.type?.id === element.id) {
            item = a.type;
            break;
          }
          // action.portcall.[actions].type
          if (a.portCall?.actions) {
            for (let a2 of a.portCall?.actions) {
              if (a2.type?.id === element.id) {
                item = a2.type;
                break;
              }
            }
          }
          if (item) break;
        }
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item?._version >= element._version) return;
      if (cancelled) return;

      let newItem = null;
      if (isUpdateOp) {
        newItem = element;
      }
      if (cancelled) return;

      // update relevant item
      setItems(prev => !prev ? prev :
        prev.map(i =>
          Action.copyOf(i, updated => {
            // action.type
            if (updated.type?.id === element.id) {
              updated.type = newItem;
            }
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              // action.portcall.[actions].type
              updated.actions = updated.actions ? updated.actions.map(a => a.type?.id === element.id ? Action.copyOf(a, updated => { updated.type = newItem; }) : a) : [];
            });
          }))
      );
    }));
    subscriptions.push(DataStore.observe(Location).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let item;
      setItems(prev => {
        for (let a of prev) {
          // action.movementLocation
          if (a.movementLocation?.id === element.id) {
            item = a.movementLocation;
            break;
          }
          // action.portcall.[actions].movementLocation
          if (a.portCall?.actions) {
            for (let a2 of a.portCall?.actions) {
              if (a2.movementLocation?.id === element.id) {
                item = a2.movementLocation;
                break;
              }
            }
          }
          if (item) break;
        }
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item?._version >= element._version) return;
      if (cancelled) return;

      let newItem = null;
      if (isUpdateOp) {
        const parent = element.locationParentId ? await DataStore.query(Location, element.locationParentId) : null;
        newItem = Location.copyOf(element, updated => { updated.parent = parent; });
      }
      if (cancelled) return;

      // update relevant item
      setItems(prev => !prev ? prev :
        prev.map(i =>
          Action.copyOf(i, updated => {
            // action.movementLocation
            if (updated.movementLocation?.id === element.id) {
              updated.movementLocation = newItem;
            }
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              // action.portcall.[actions].movementLocation
              updated.actions = updated.actions ? updated.actions.map(a => a.movementLocation?.id === element.id ? Action.copyOf(a, updated => { updated.type = newItem; }) : a) : [];
            });
          }))
      );
    }));
    subscriptions.push(DataStore.observe(Category).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let item;
      setItems(prev => {
        for (let a of prev) {
          // action.portcall.category
          if (a.category?.id === element.id) {
            item = a.category;
            break;
          }
        }
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item?._version >= element._version) return;
      if (cancelled) return;

      let newItem = null;
      if (isUpdateOp) {
        newItem = element;
      }
      if (cancelled) return;

      // update relevant item
      setItems(prev => !prev ? prev :
        prev.map(i =>
          Action.copyOf(i, updated => {
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              // action.portcall.category
              if (updated.category?.id === element.id) {
                updated.category = newItem;
              }
            });
          }))
      );
    }));
    subscriptions.push(DataStore.observe(Vessel).subscribe(async msg => {
      const { element, opType } = msg;
      const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");

      // access latest data
      let item;
      setItems(prev => {
        for (let a of prev) {
          // action.portcall.vessel
          if (a.vessel?.id === element.id) {
            item = a.vessel;
            break;
          }
        }
        return prev; // this doesn't emit a state change
      });
      // check version if entry exists
      if (item?._version >= element._version) return;
      if (cancelled) return;

      let newItem = null;
      if (isUpdateOp) {
        newItem = element;
      }
      if (cancelled) return;

      // update relevant item
      setItems(prev => !prev ? prev :
        prev.map(i =>
          Action.copyOf(i, updated => {
            updated.portCall = PortCall.copyOf(i.portCall, updated => {
              // action.portcall.vessel
              if (updated.vessel?.id === element.id) {
                updated.vessel = newItem;
              }
            });
          }))
      );
    }));
    return () => {
      subscriptions.map(s => s.unsubscribe());
      cancelled = true;
    }
  }, [setItems, locations, contacts]);
  return items;
};

export default useActiveActions;