import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import ToggleFullScreenRoute from '../ToggleFullScreenRoute';

const useStyles = makeStyles(theme => {
  return ({
    root: {
      padding: '1rem 2rem',
      borderBottom: '0.0625rem solid #ccc',
      paddingLeft: '1rem',
      position: 'relative',
      height: '3.5rem',
    },
    heading: {
      padding: 0,
    }
  })
});

const SimpleListHeader = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
    >
      <Grid item>
        {children}
      </Grid>
      <Box
        alignItems="center"
        display="flex"
        position="absolute"
        right="1rem"
      >
        <ToggleFullScreenRoute route={`/lists/berths${location.search}`} />
      </Box>
    </Grid>
  );
};

export default SimpleListHeader;
