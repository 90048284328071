import React, { useState, useEffect } from "react";
import { Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TreeItem, TreeView } from "@material-ui/lab";
import { PlusBoxOutline, MinusBoxOutline } from 'mdi-material-ui';
import {filterTypes} from "../../constants/Filters";

//Styles for the checkbox
const useCondensedCheckBoxStyles = makeStyles((theme) => ({
  condensed: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

//Takes a node in - checks if it has children - if not it returns the id of the node
//If it does with go through the children and map out those nodes recusively
//After an array of nodes is made the flat method will return all the elements including the nested ones
//Into a flat array
function leafIds(node) {
  return !node.children || node.children.length === 0
    ? [node.value]
    : node.children.map(leafIds).flat();
}

//Checkbox function
export function FilterCheckboxTree({
  locationTree,
  setLocationFilter,
  filters: { location: checked },
  allChecked,
  disabled
}) {
  const classes = useCondensedCheckBoxStyles();
  //State used to store what nodes are currently checked - Initialising with data from BerthStatusList
  // const [checked, setChecked] = useState(filters.location);
  //State used to store what nodes have been expanded - for the case that the tree is updated
  const [expanded, setExpanded] = useState([]);

  //Initialising the node data with the location Tree
  const nodes = locationTree;

  //Everytime the array of checked items is updated the temporary state is stored
  //in BerthFilter and will be either updated when the user clicks Apply or disgared when then click cancel
  useEffect(() => {
    if (checked[0] === filterTypes.ALL) {
      setLocationFilter(allChecked);
    }
  }, [checked]);

  const handleCheck = (node, parentNode, newValue) => {
    //value is created which is true if the it is within the checked array
    const value = checked.includes(node.value);
    //Checks whether node has children
    if (!node.children || node.children.length === 0) {
      //If the value is the same as the newValue then return
      if (value === newValue) return;
      //Otherwise the checked array - if the value is true then add it to the array
      //if it's false then return the array without that value and its parent
      setLocationFilter(
        newValue
        // lets add the parentNode.value to the array only if it exists
          ? parentNode?.value ? [...checked, node.value, parentNode.value] : [...checked, node.value]
          : checked.filter((id) => (id !== node.value && id !== parentNode?.value))
      );
    } else {
      //If the node has children then get all the ids of the leaf nodes
      const ids = leafIds(node);
      //Get a list of node and its children ids
      const nodeAndLeafIds = [...ids, node.value]
      //create a new array called remaining which has all the nodes that aren't
      //this node and its children
      const remaining = checked.filter((id) => !nodeAndLeafIds.includes(id));
      //If newValue is true then add this node and all the children to the list of checked
      //Else if it's false just return the remaining
      setLocationFilter(newValue ? [...remaining, ...nodeAndLeafIds] : remaining);
    }
  };

  //Function to represent a TreeNode
  function TreeNode({ node, parentNode }) {
    //isChecked goes through all the children nodes and sees if it's in checked
    //If there are any children
    const isChecked = leafIds(node).every((id) => checked.includes(id));
    //isIndeterminate is true if isChecked and there is at least one of it's children
    //nodes in the checked array
    const isIndeterminate =
      !isChecked && leafIds(node).some((id) => checked.includes(id));

    //When the checkbox is toggled perform the handleCheck function
    //passing in the node and the inverse value of isChecked
    const onChange = () => {
      handleCheck(node, parentNode, !isChecked);
    };

    return (
      <TreeItem
        key={node.value}
        nodeId={node.value}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignItems: "center",
              padding: "9px",
            }}
          >
            <Checkbox
              className={classes.condensed}
              checked={isChecked}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()}
              indeterminate={isIndeterminate}
              data-id={node.value}
              disabled={disabled}
            />
            <Typography style={{ fontSize: "inherit" }} variant="caption">
              {node.label}
            </Typography>
          </div>
        }
      >
        {node.children && <TreeNodes nodes={node.children} parentNode={node} />}
      </TreeItem>
    );
  }

  function TreeNodes({ nodes, parentNode }) {
    return (
      <>
        {nodes.map((node) => (
          <TreeNode parentNode={parentNode} node={node} key={node.label} />
        ))}
      </>
    );
  }

  const useStyles = makeStyles({
    root: {
      height: "14rem",
      overflow: "auto",
      flexGrow: 1,
      minWidth: "20rem",
      marginRight: "1rem",
    },
    icon: {
      marginLeft: "0.5rem",
      fontSize: "1.2rem"
    },
  });

  const treeClass = useStyles();

  return (
    <TreeView
      className={treeClass.root}
      defaultCollapseIcon={<MinusBoxOutline className={treeClass.icon}  />}
      defaultExpandIcon={<PlusBoxOutline className={treeClass.icon} />}
      defaultEndIcon={"  "}
      expanded={expanded}
      onNodeToggle={(_, nodes) => setExpanded(nodes)}
    >
      <TreeNodes nodes={nodes} />
    </TreeView>
  );
}
