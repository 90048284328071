import { useState, useContext, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, makeStyles } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import SettingConstants from '../../../constants/SettingConstants';
import ChargeablesTableEdit from '../../Chargeables/edit/ChargeablesTableEdit';
import { COLUMNS } from "../../../constants/ChargeableItemConstants";
import { createChargeableItemFromBook, createDefaultChargeableItemFromChargeableItem, getActionTypeName } from '../../../utils/tariffBook';
import ActionTypeWithMovementAutocomplete from './ActionTypeWithMovementAutocomplete';

const useStyes = makeStyles(theme => {
  return {
    DialogButtonBar: {
      paddingLeft: '24px',
      paddingRight: '24px',
      padddingTop: '8px',
      paddingBottom: '24px'
    },
    ActionTypeAutocomplete: {
      width: '30%',
      paddingBottom: '8px'
    }
  }
});

const initalChargeableItems = (bookId, tariffs, tariffUnits, defaultItems) => {
  return defaultItems ? defaultItems.map(el =>
    createChargeableItemFromBook(bookId, tariffs, el, tariffUnits)
  ) : []
};

const DefaultChargeableItemDialog = ({ open, bookId, tariffs, tariffUnits, defaultCharges, selectedActionTypes, onClose, onUpdate }) => {
  const { t } = useTranslation();
  const classes = useStyes();
  const { settings, actionTypes } = useContext(DataStoreContext);
  const [ appCurrencySetting ] = useState(settings.length && JSON.parse(settings.find(s => s.name === SettingConstants.APP_CURRENCY).value));
  const [ editChargeableItems, setEditChargeableItems ] = useState(initalChargeableItems(bookId, tariffs, tariffUnits, defaultCharges?.defaultItems));
  const [ actionTypeValue, setActionTypeValue ] = useState(null);
  const [ initActionTypeValue, setInitActionTypeValue ] = useState(null);

  const allTariffs = tariffs
    .map(tariff => {
      return {
        ...tariff,
        unit: { ...tariffUnits.find(unit => unit.id === tariff.unitId) }
      }
    })
    // only include tariffs which include the action type
    .filter(tariff => actionTypeValue ? tariff.cost.actionTypes.includes(actionTypeValue.actionTypeId) : true);

  // console.log('XX', tariffs);
  // console.log('XXX', chargeableItems);
  // console.log('XXX', chargeableItems.map(el => createChargeableItemFromBook(bookId, tariffs, el, tariffUnits)));
  // console.log('XXX selectedActionTypes', selectedActionTypes);

  const handleSave = () => {
    const newDefaultCharges = {
      id: defaultCharges?.id ? defaultCharges.id : uuidv4(), 
      actionTypeId: actionTypeValue.actionTypeId,
      movementType: actionTypeValue.movementType,
      defaultItems: createDefaultChargeableItemFromChargeableItem(editChargeableItems)
    };
    console.log('handleSave', newDefaultCharges);
    onUpdate(newDefaultCharges);
  };

  const handleSaveChargeableItems = (newChargeableItems) => {
    console.log('handleSaveChargeableItems', newChargeableItems);
    setEditChargeableItems(newChargeableItems);
  };

  useEffect(() => {
    if(defaultCharges && actionTypes.length) {
      const actionType = actionTypes.find(el => el.id === defaultCharges?.actionTypeId)
      const value = { 
        actionTypeId: actionType.id,
        name: getActionTypeName(actionType, defaultCharges.movementType, t),
        movementType: defaultCharges.movementType
      };
      setActionTypeValue(value);
      setInitActionTypeValue(prev => (!prev ? value : prev));
    }
  }, [actionTypes, defaultCharges, t]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={false}
    >
      <DialogTitle>{t('AdminTariff.Labels.DefaultChargeables')}</DialogTitle>
      <DialogContent>
        <Box flexDirection="column" gap="10px" paddingBottom="16px">
          <ActionTypeWithMovementAutocomplete
            id="ChargeableActionTypeInput"
            className={classes.ActionTypeAutocomplete}
            label={t('AdminTariff.Labels.ActionType')}
            value={actionTypeValue}
            required
            disabled={editChargeableItems?.length > 0}
            onChange={setActionTypeValue}
            excludeOption={(option) => {
              // do no exclude the initially set value, the user may clear this and then reselect
              if((option.actionTypeId === initActionTypeValue?.actionTypeId) && 
                (option.movementType === initActionTypeValue?.movementType)) return false;
              // exclude all action types current in use
              return selectedActionTypes.find(el => 
                (el.actionTypeId === option.actionTypeId) && (el.movementType === option.movementType))}
            }
          /> 
          <ChargeablesTableEdit
            chargeableItems={editChargeableItems}
            tariffs={allTariffs}
            columns={COLUMNS}
            saveChargeableItems={handleSaveChargeableItems}
            handleSaveAndClose={handleSave}
            handleClose={onClose}
            currencySymbol={appCurrencySetting.symbol}
            confirmButtonLabel={'Common.Buttons.Update'}
            disableAdd={!actionTypeValue}
            canConfirm={() => actionTypeValue }
            disableUnitCost
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DefaultChargeableItemDialog;