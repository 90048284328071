import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import { Box, Typography, Card, CardContent, IconButton, TextField, Button, makeStyles, Popover } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { Action, ActionMovementType, ActionState, TemplateSubType } from '../../models';
import { getActionLastLifecycle, getActionTime, getActionTodosAsync, getActionDeparture, getLastCancelledDeparture } from '../../utils/getters';
import { DataStore } from 'aws-amplify';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { isBefore, parseISO, sub } from 'date-fns';
import { ActionTypeIds } from '../../environment';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useInitialActionState from '../../hooks/useInitialActionState';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import useTariffBooks from '../../hooks/useTariffBooks';
import { createAllChargeablesItemFromBooks } from '../../utils/tariffBook';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import MoveArrive from '../../icons/MoveArrive';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    border: 0
  }
}));

const ArrivalCreateDialog = ({ portCall, anchorEl, onClose }) => {
  const dataStoreContext = useContext(DataStoreContext);
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);
  const { tariffBooks } = useTariffBooks();

  const departure = useMemo(() => getActionDeparture(portCall?.actions) || getLastCancelledDeparture(portCall.actions), [portCall?.actions]);
  const lastAction = useMemo(() => {
    const lastLifecycleAction = getActionLastLifecycle(portCall.actions);
    return lastLifecycleAction ? lastLifecycleAction : departure;
  }, [departure, portCall.actions])

  const movementLocation = useMemo(() =>  departure?.movementLocation, [departure]);

  const [arrival, setArrival] = useState(() => {
    // default to last arrival
    const timePlanned = sub(new Date(getActionTime(lastAction)), { days: 1 }).toISOString();
    // try to get template first
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_ARRIVAL);
    if (template) {
      // FIXME remove isTariffBookEnabled when feature flag removed
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(portCall?.vessel?.id), isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.movementLocation = movementLocation;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: getInitialActionState(portCall?.vessel?.id),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.ARRIVAL,
        timePlanned,
        movementLocation: movementLocation,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      });
    }
  });

  useEffect(() => {
    setArrival(Action.copyOf(arrival, updated => {
      updated.state = getInitialActionState(portCall?.vessel?.id)
    }));
  }, [getInitialActionState])

  //#only add arrival when there are no actions started, and is need to be before departure
  const checkArrivalTimePlanned = useCallback(() => {
    const startedState = [ActionState.COMPLETED, ActionState.IN_PROGRESS];
    if (!arrival.timePlanned) return t('PortCall.Errors.InvalidDateFormat');
    const timePlanned = arrival.timePlanned;
    const departureTime = getActionTime(departure);
    if (departure && (!isBefore(parseISO(timePlanned),parseISO(departureTime))) || startedState.find((state) => state === departure?.state)) return t('PortCall.Errors.ArrivalBeforeDeparture')
    return;
  }, [arrival.timePlanned, departure, t]);

  // there should be a valid time planned
  // check the arrival time
  const arrivalError = useMemo(() => checkArrivalTimePlanned(),
    [checkArrivalTimePlanned]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangearrivalTime = (value) => {
    setArrival(Action.copyOf(arrival, updated => { updated.timePlanned = value; }));
  };

  const handleCreateArrival = async () => {
    const todos = await getActionTodosAsync(arrival);
    let chargeableItems = arrival.chargeableItems;
    // FIXME remove if and set chargableItems when feature flag removed
    if (isTariffBookEnabled) {
      chargeableItems = createAllChargeablesItemFromBooks(
        arrival.timePlanned,
        arrival.type.id,
        arrival.movementType,
        tariffBooks,
        dataStoreContext.tariffUnits);
    }
    await DataStore.save(Action.copyOf(arrival, updated => {
      updated.todos = todos;
      updated.chargeableItems = chargeableItems;
    }));
    handleClose();
  };


  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            {/* Title  */}
            <Typography variant="h5">{t('ArrivalCreateDialog.Labels.NewArrival')}</Typography>
            {/* Close  */}
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box display="flex" height="5rem">
            {/* Time Picker */}
            <Box width='100%' mt={'0.25rem'} mb={'1rem'}>
              <StringKeyboardDateTimePicker
                warningPastDate={true}
                id='ArrivalPlannedTimeInput'
                label={t('PortCall.Labels.PlannedTimeOfArrival')}
                value={arrival.timePlanned}
                onChange={handleChangearrivalTime}
                error={Boolean(arrivalError)}
                helperText={arrivalError}
                okLabel={t('Common.Buttons.Confirm')}
              />
            </Box>
            {/* Berth Location */}
            <Box width='100%' ml={'1rem'} mr={'1rem'}>
              <TextField
                variant="outlined"
                label={t('PortCall.Labels.ArrivalLocation')}
                fullWidth
                margin="normal"
                disabled
                value={movementLocation ? movementLocation?.name : ''}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  },
                  startAdornment:
                    <>
                      <MoveArrive color="primary" />
                    </>
                }}
              />
            </Box>
          </Box>
          {/* Create Button */}
          <Box display="flex" justifyContent="flex-end" alignItems="center" m={'1rem'}>
            <Button
              id='ArrivalCreateButton'
              variant="contained"
              color="primary"
              size="large"
              disabled={!!arrivalError}
              onClick={handleCreateArrival}
            >
              {t('Common.Buttons.Create')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

ArrivalCreateDialog.propTypes = {
  portCall: PropTypes.object,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
}

export default ArrivalCreateDialog;
