import { useEffect, useContext, useState } from 'react'
import { TariffContext } from '../contexts/tariffsContext';

const emptyBook = { tariffs: [], defaultCharges: [] };

const useTariffBook = (bookId) => {
  const { tariffBooks } = useContext(TariffContext);
  const [ tariffBook, setTariffBook ] = useState(tariffBooks ? 
    tariffBooks.find(el => el.id === bookId) : { ...emptyBook, id: bookId }
  );
  
  useEffect(() => {
    const existingBook = tariffBooks.find(el => el.id === bookId);
    if(tariffBooks) {
      setTariffBook(existingBook ? existingBook : { ...emptyBook, id: bookId });
      console.log('useTariffBook update state');
    }
  }, [ tariffBooks, bookId ]);
  
  // console.log('useTariffBook', tariffBook)

  return tariffBook;
};

export default useTariffBook;