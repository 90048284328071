import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  TextField,
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  makeStyles,
  Link,
} from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';
import { InformationOutline, Sync, Magnify } from 'mdi-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VesselListItem from './VesselListItem';
import EditorField from '../Common/EditorField';
import { UnitsSymbolKeyFromString } from '../../constants/Units';
import * as P from '../../constants/Propulsion';
import { portName } from '../../utils/utils';
import VesselImage from '../Image/VesselImage';
import {imageRatio} from '../../utils/Image';
import { VesselData, PortCallStatus } from '../../models';
import { PortCallDetailsHref } from '../../constants/PortCallDetails';
import { useTranslation } from 'react-i18next';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import format from 'date-fns/format';
import CertificateList from '../Certificate/CertificateList';
import { getPortCallTimes } from '../../utils/getters';
import { refreshVesselData } from './utils';

/**
 * 
 *  VesselDataEdit
 *  This component is used to edit the data object of a vessel.
 *  It will initialise all fields to empty strings if not 
 *  provided with a vessel.
 *  When any individual fields change it will pass the changes
 *  back to its parent along with a flag that the form is no
 *  longer clean.
 * 
 */

const isImoValid = imo => !imo || imo.length === 7;
const isMmsiValid = mmsi => !mmsi || mmsi.length === 9;
const imageWidth = 20;

const {width, height} = imageRatio({width: imageWidth}) 

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));
export function VesselDataEdit({
  actions,
  readOnly,
  vesselData,
  setVesselData,
  vessel,
  setVessel,
  portCallId,
  editVessel,
  portCallStatus,
}) {
  const { t } = useTranslation();
  const [imoExists,] = useState(false);
  const [mmsiExists,] = useState(false);
  const [syncing, setSyncing] = useState(false);
  
  const [nameOptions,] = useState([]);
  const [nameLoading,] = useState(false);
  
  const [imoOptions,] = useState([]);
  const [imoLoading,] = useState(false);
  
  const [mmsiOptions,] = useState([]);
  const [mmsiLoading,] = useState(false);

  const classes = useStyles();

  const { dateTimeFormat } = useDateTimeSetting();
  const updatedAt = vessel?.updatedAt ? format(new Date(vessel.updatedAt), dateTimeFormat) : null;

  const portCallTimes = useMemo(() => getPortCallTimes(actions), [actions])
  
  if (!vesselData) return null;
  const handleSync = async () => {
    setSyncing(true);
    const { data: { getVesselExternal } } = await refreshVesselData(vessel?.imo, vessel?.mmsi)

    if (getVesselExternal.vessel && getVesselExternal.vessel.vesselData) {
      setVesselData(new VesselData({ ...vesselData, ...getVesselExternal.vessel.vesselData}));
      }
    setSyncing(false);
  };

  const handleChange = ({ target: { name, value } }) => {
    setVesselData(new VesselData({ ...vesselData, [name]: value }));
  };

  const disabled = Boolean(vessel);

  const noImoAndMmsi = !Boolean(vessel?.imo) && !Boolean(vessel?.mmsi);

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL}
        variant="h6"
      >
        {t("Vessel.Labels.Vessel")}
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        height="1rem"
        paddingBottom="1rem"
        paddingTop="1rem"
      >
        {disabled ?
          <>
            <Box display="flex" flexBasis="100%" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography variant="caption" style={{ padding: '0 0.5rem' }}>
                  {`${t("VesselDataEdit.Labels.UpdatedAt")} ${updatedAt}`}
                </Typography>
                <Tooltip title={noImoAndMmsi ? t("VesselDataEdit.Labels.DisabledRefresh") : t("VesselDataEdit.Labels.RefreshVesselData")}>
                  <div>
                    <IconButton disabled={syncing || readOnly || noImoAndMmsi} onClick={handleSync}>
                      {syncing && <CircularProgress style={{ position: 'absolute' }} />}
                      <Sync />
                    </IconButton>
                  </div>
                </Tooltip>
              </Box>
              <Box dispay="flex">
                {!readOnly && portCallStatus && portCallStatus === PortCallStatus.PREARRIVAL && 
                  <Link 
                    onClick={() => setVessel(null)}
                    disabled={true}
                  >
                    <Typography align="right">{t("VesselDataEdit.Labels.ChangeVessel")}</Typography>
                  </Link>
                }
                {editVessel && !readOnly &&
                  <RouteLink
                    className={`${classes.link} MuiTypography-root MuiLink-root MuiTypography-colorPrimary`}
                    to={`/vessel-edit/${portCallId}`}
                    id="UpdateVesselButton"
                  >
                    <Typography align="right">{t("VesselDataEdit.Labels.UpdateVessel")}</Typography>
                  </RouteLink>
                }
              </Box> 
            </Box>
          </>
          : <>
            <InformationOutline fontSize="small" />
            <Typography variant="caption" style={{ padding: '0 0.5rem', flexGrow: 1 }}>A new vessel will be created.</Typography>
          </>
        }
      </Box>

      {vesselData.image && 
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="1rem"
          paddingTop="1rem"
        >
          <VesselImage image={vesselData.image} containerStyles={{width: width, height: height}} />
        </Box>
      
      }
      
      <Typography
        id={PortCallDetailsHref.HREF_IDENTITY}
        variant="overline"
      >
       {t("Vessel.Labels.Identity")} 
      </Typography>
      {!disabled
        ? <Autocomplete
          autoHighlight
          freeSolo
          disabled={disabled}
          options={nameOptions}
          getOptionLabel={(item) => (item.data && item.data.name) || vesselData.name}
          disableClearable // does not seem to function well yet
          value={vessel}
          onChange={(e, value) => value && setVessel(value)}
          inputValue={vesselData.name}
          renderOption={item => (
            <VesselListItem
              vesselData={item.data}
              metaField="imo"
            />
          )}
          renderInput={props => (
            <TextField
              variant="outlined"
              {...props}
              label={t("Vessel.Labels.Name")}
              required
              fullWidth
              margin="normal"
              name="name"
              inputProps={{
                ...props.inputProps,
                autoComplete: 'off'
              }}
              onChange={handleChange}
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <>
                    {nameLoading && <CircularProgress color="inherit" size="1rem" />}
                    {props.InputProps.endAdornment}
                    <Magnify />
                  </>
                ),
              }}
            />
          )}
        />
        : <EditorField
          disabled={disabled}
          label={t("Vessel.Labels.Name")}
          name="name"
          value={vesselData.name}
        />
      }

      {!disabled
        ? <Autocomplete
          autoHighlight
          freeSolo
          disabled={disabled}
          options={imoOptions}
          getOptionLabel={(item) => item.data.imo}
          disableClearable // does not seem to function well yet
          onChange={(e, value) => value && setVessel(value)}
          inputValue={vesselData.imo}
          renderOption={item => (
            <VesselListItem
              vesselData={item.data}
              metaField="imo"
            />
          )}
          renderInput={props => (
            <TextField
              variant="outlined"
              {...props}
              label={t("Vessel.Labels.IMONumber")}
              fullWidth
              margin="normal"
              name="imo"
              inputProps={{
                ...props.inputProps,
                autoComplete: 'off',
                maxLength: 7
              }}
              onChange={handleChange}
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <>
                    {imoLoading && <CircularProgress color="inherit" size="1rem" />}
                    {props.InputProps.endAdornment}
                    <Magnify />
                  </>
                ),
              }}
              error={!isImoValid(vesselData.imo) || (!vessel && imoExists)}
            />
          )}
        />
        : <EditorField
          disabled={disabled}
          label={t("Vessel.Labels.IMO")}
          name="imo"
          value={vesselData.imo}
        />
      }

      {!disabled
        ? < Autocomplete
          autoHighlight
          freeSolo
          disabled={disabled}
          options={mmsiOptions}
          getOptionLabel={(item) => item.data.mmsi}
          disableClearable // does not seem to function well yet
          onChange={(e, value) => value && setVessel(value)}
          inputValue={vesselData.mmsi}
          renderOption={item => (
            <VesselListItem
              vesselData={item.data}
              metaField="imo"
            />
          )}
          renderInput={props => (
            <TextField
              variant="outlined"
              {...props}
              label={t("Vessel.Labels.MMSI")}
              required
              fullWidth
              margin="normal"
              name="mmsi"
              inputProps={{
                ...props.inputProps,
                autoComplete: 'off',
                maxLength: 9
              }}
              onChange={handleChange}
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <>
                    {mmsiLoading && <CircularProgress color="inherit" size="1rem" />}
                    {props.InputProps.endAdornment}
                    <Magnify />
                  </>
                ),
              }}
              error={!isMmsiValid(vesselData.mmsi) || (!vessel && mmsiExists)}
            />
          )}
        />
        : <EditorField
          disabled={disabled}
          label={t("Vessel.Labels.MMSI")}
          name="mmsi"
          value={vesselData.mmsi}
        />
      }
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.CallSign")}
        name="callSign"
        inputProps={{
          style: { textTransform: 'uppercase' }
        }}
        onChange={handleChange}
        value={vesselData.callSign}
        required
      />
      <EditorField
        disabled={disabled}
        name="flag"
        label={t("Vessel.Labels.Flag")}
        onChange={handleChange}
        value={vesselData.flag && vesselData.flag.toUpperCase()}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.Type")}
        name="type"
        onChange={handleChange}
        value={vesselData.type}
      />
      <Typography
        id={PortCallDetailsHref.HREF_DETAILS}
        variant="overline"
      >
        {t("Vessel.Labels.Details")}
      </Typography>
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.YearBuilt")}
        name="yearBuilt"
        inputProps={{
          maxLength: 4,
        }}
        onChange={handleChange}
        value={vesselData.yearBuilt}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.PortOfRegistry")}
        name="portOfRegistry"
        onChange={handleChange}
        value={portName(vesselData.portOfRegistry)}
      />
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_DIMENSIONS}
        variant="overline"
      >
        {t("Vessel.Labels.Dimensions")}
        </Typography>
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.Length")}
        name="lengthOverall"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        onChange={handleChange}
        fieldSuffix="m"
        value={vesselData.lengthOverall}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.Beam")}
        name="beam"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        onChange={handleChange}
        fieldSuffix="m"
        value={vesselData.beam}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.Draught")}
        name="draught"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        onChange={handleChange}
        fieldSuffix="m"
        value={vesselData.draught}
      />
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_TONNAGE}
        variant="overline"
      >
        {t("Vessel.Labels.Tonnage")}
      </Typography>
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.DeadweightTonnage")}
        name="deadWeightTonnage"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">t</InputAdornment>,
        }}
        onChange={handleChange}
        fieldSuffix="t"
        value={vesselData.deadWeightTonnage}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.GrossTonnage")}
        name="grossTonnage"
        type="number"
        onChange={handleChange}
        value={vesselData.grossTonnage}
      />
      <EditorField
        disabled={disabled}
        label={t("Vessel.Labels.NetTonnage")}
        name="netTonnage"
        type="number"
        onChange={handleChange}
        value={vesselData.netTonnage}
      />
      {vessel.vesselAgent &&
        <>
          <Typography
            id={PortCallDetailsHref.HREF_VESSEL_AGENT}
            variant="overline"
          >
            Agent
            </Typography>
          <EditorField
            disabled
            label={t("Agent.Labels.Name")}
            name="vesselAgentName"
            type="number"
            value={vessel.vesselAgent.name}
          />
          <EditorField
            disabled
            label={t("Agent.Labels.TelephoneNumber")}
            name="vesselAgentNumber"
            value={vessel.vesselAgent.number}
          />
        </>
      }
      {actions && <>
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_CERTIFICATES}
        variant="overline"
      >
        {t('CertificateType.Labels.Certificates')}
      </Typography>
      <Box mb="1rem">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1" style={{paddingRight: '2rem', textAlign: 'right', fontWeight: '300'}}>
              {t('CertificateType.Labels.Certificates')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CertificateList certificates={vesselData.certificates} fromDate={portCallTimes.fromDate} toDate={portCallTimes.toDate} style={{flexDirection: 'column'}}/>
          </Grid>
        </Grid>
      </Box>
      </>
      }
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_PROPULSION}
        variant="overline"
      >
        {t("Vessel.Labels.PrimaryPropulsion")}
        </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselPropulsionCount"
        value={vesselData.propellerCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.Type")}
        name="vesselPropulsionType"
        value={t(P.PropulsionTypeLabelKeys[vesselData.propulsionType])}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.Direction")}
        name="vesselPropellerDirection"
        value={t(P.PropellerDirectionLabelKeys[vesselData.propellerDirection])}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.TotalPower")}
        name="vesselPropulsionPower"
        value={vesselData.propulsionPower}
        fieldSuffix={t(UnitsSymbolKeyFromString(vesselData.propulsionPowerUnit))}
/>
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_RUDDER}
        variant="overline"
      >
        {t("Vessel.Labels.Rudder")}
        </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselRudderCount"
        value={vesselData.rudderCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.Type")}
        name="vesselRudderType"
        value={t(P.RudderTypeLabelKeys[vesselData.rudderType])}
      />
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_FWD_AZIMUTH}
        variant="overline"
      >
        {t("Vessel.Labels.ForwardAzimuth")}
        </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselFwdAzimuthCount"
        value={vesselData.fwdAzimuthCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.TotalPower")}
        name="vesselFwdAzimuthPower"
        value={vesselData.fwdAzimuthPower}
        fieldSuffix={t(UnitsSymbolKeyFromString(vesselData.fwdAzimuthPowerUnit))}
      />
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_AFT_AZIMUTH}
        variant="overline"
      >
        {t("Vessel.Labels.AftAzimuth")}
      </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselAftAzimuthCount"
        value={vesselData.aftAzimuthCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.TotalPower")}
        name="vesselAftAzimuthPower"
        value={vesselData.aftAzimuthPower}
        fieldSuffix={t(UnitsSymbolKeyFromString(vesselData.aftAzimuthPowerUnit))}
      />
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_FWD_TUNNEL}
        variant="overline"
      >
        {t("Vessel.Labels.ForwardTunnelThrusters")}
        </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselFwdTunnelThrustCount"
        value={vesselData.fwdTunnelThrusterCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.TotalPower")}
        name="vesselFwdTunnelThrustPower"
        value={vesselData.fwdTunnelThrusterPower}
        fieldSuffix={t(UnitsSymbolKeyFromString(vesselData.fwdTunnelThrusterPowerUnit))}
/>
      <Typography
        id={PortCallDetailsHref.HREF_VESSEL_AFT_TUNNEL}
        variant="overline"
      >
        {t("Vessel.Labels.AftTunnelThrusters")}
        </Typography>
      <EditorField
        disabled
        label={t("Vessel.Labels.Number")}
        name="vesselAftTunnelThrustCount"
        value={vesselData.aftTunnelThrusterCount}
      />
      <EditorField
        disabled
        label={t("Vessel.Labels.TotalPower")}
        name="vesselAftTunnelThrustPower"
        value={vesselData.aftTunnelThrusterPower}
        fieldSuffix={t(UnitsSymbolKeyFromString(vesselData.aftTunnelThrusterPowerUnit))}
        />
    </Box>
  );
}

VesselDataEdit.defaultProps = {
}

VesselDataEdit.propTypes = {
  readOnly: PropTypes.bool,
  vesselData: PropTypes.object,
  setVesselData: PropTypes.func,
  vessel: PropTypes.object,
  setVessel: PropTypes.func,
  portCallId: PropTypes.string,
  editVessel: PropTypes.bool,
  portCallStatus: PropTypes.string,
}

export default VesselDataEdit;
