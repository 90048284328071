import { useState, memo, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { CloseCircleOutline, DeleteOutline, DotsVertical, InformationOutline } from "mdi-material-ui";
import SpotlightMapDialog from "../Spotlight/SpotlightMapDialog";
import SpotlightMenuOption from "../Spotlight/SpotlightMenuOption";
import { VoyageStatus } from '../../models';
import useAuditMeta from '../../hooks/useAuditMeta';
import { getUpdateVoyageTransactionInput } from './utils';
import { getItemByMaxmimumTransaction } from '../../utils/transactions';

const VoyageItemMenu = memo(({ 
  setConfirmProps, 
  voyage, 
  setErrorMessage, 
  error, 
  setCalculatingVoyageUpdate, 
  updateMutation, 
  setTextConfirm, 
  setOpenConfirm
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const history = useHistory();
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const disabled = !voyage;

  const menu = useMemo(() => {
    const hasActions = {};
    if (voyage?.status && [VoyageStatus.PLANNED, VoyageStatus.CANCELLED].includes(voyage.status)) {
      hasActions.canHandleVoyageDelete = true;
    }
    if (voyage?.status && [VoyageStatus.PLANNED].includes(voyage.status)) {
      hasActions.handleVoyageCancel = true;
    }
    return hasActions;
  }, [voyage?.status])

  const handleOpenMapDialog = () => {
    setAnchorEl(null);
    setMapDialogOpen(true);
  };

  const handleShowDetails = () => {
    history && history.push(`/voyage/${voyage.id}`);
  };

  useEffect(() => {
    // Only set the error text if an error is still active
    setErrorMessage(error && error.statusCode !== 200 ? t('PortCallTimelineMenu.Errors.VoyageUpdateFailed') : undefined);
  }, [t, error]);

  const updateVoyageAsTransaction = async ({ voyageId, updateStatus, auditMeta, onSuccess, comment = null }) => {
    setCalculatingVoyageUpdate(true);
    const items = await getUpdateVoyageTransactionInput({ voyageId, updateStatus, comment, auditMeta });
    const { batchItems } = getItemByMaxmimumTransaction(items);

    let update = true; 
    for (const updateItems of batchItems) { 
      const result = await updateMutation(updateItems);
      console.log('result', result)
      if (!result) {
        update = false;
        break;
      }
    };
    
    if (update) onSuccess();
    setCalculatingVoyageUpdate(false);
  };

  const handleVoyageDelete = () => {
    setConfirmProps({
      title: t('VoyageMenu.Labels.DeleteVoyageDialogTitle', { voyageName: voyage?.name }),
      message: t('VoyageMenu.Labels.DeleteVoyageDialogMessage', { voyageName: voyage?.name }),
      onConfirm: async () => {
        await updateVoyageAsTransaction({
          voyageId: voyage?.id,
          updateStatus: VoyageStatus.DELETED,
          auditMeta: auditMeta,
          onSuccess: () => {
            console.log('success')
            setOpenConfirm(false);
          }
        })
      }
    });
    setOpenConfirm(true);
  };

  const handleVoyageCancel = () => {
    setConfirmProps({
      inputLabel: t('Common.Labels.Comments'),
      title: t('VoyageMenu.Labels.CancelVoyageDialogTitle', { voyageName: voyage?.name }),
      message: t('VoyageMenu.Labels.CancelVoyageDialogMessage', { voyageName: voyage?.name }),
      onConfirm: async (comment) => {
        await updateVoyageAsTransaction({
          voyageId: voyage?.id,
          updateStatus: VoyageStatus.CANCELLED,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => {
            console.log('success')
            setTextConfirm(false);
          }
        })
      }
    });
    setTextConfirm(true);
  };

  return (
    <>
      <IconButton
        onClick={e => { setAnchorEl(e?.currentTarget); e?.preventDefault(); }}
        id={`VoyageListItemMenuButton-${voyage.id}`}
        style={{ marginTop: '14px', marginBottom: '14px' }}
      >
        <DotsVertical />
      </IconButton>
      <Menu
        id={'VoyageItemMenu'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <ListSubheader>{t('VoyageMenu.Labels.Voyage')}</ListSubheader>

        {menu.handleVoyageCancel &&
          <MenuItem
            onClick={handleVoyageCancel}
            disabled={disabled}
            id="CancelVoyage"
          >
            <ListItemIcon>
              <CloseCircleOutline />
            </ListItemIcon>
            <ListItemText primary={t('VoyageMenu.Labels.CancelVoyage')} />
          </MenuItem>}

        {menu.canHandleVoyageDelete &&
          <MenuItem
            onClick={handleVoyageDelete}
            disabled={disabled}
            id="DeleteVoyage"
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText primary={t('VoyageMenu.Labels.DeleteVoyage')} />
          </MenuItem>}

        <MenuItem
          id='VoyageShowDetailsMenuItem'
          onClick={handleShowDetails}
          disabled={disabled}
        >
          <ListItemIcon>
            <InformationOutline />
          </ListItemIcon>
          <ListItemText primary={t('VoyageMenu.Labels.ShowDetails')} />
        </MenuItem>
        <SpotlightMenuOption handleOpenMapDialog={handleOpenMapDialog} />
      </Menu>
      {mapDialogOpen &&
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={voyage?.vessel?.vesselData}
        />
      }
    </>
  )
});

export default VoyageItemMenu;
