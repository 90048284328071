import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { isBefore, isEqual, parseISO } from 'date-fns';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  warningMessage: {
    color: '#ff9800'
  }
}));

const CompletionTimeInputDialog = ({ open, title, actionName, initialValue, startTimeDisabled, message, warningMessage, hasComment, onConfirm, onClose}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [startTimeValue, setStartTimeValue] = useState(initialValue);
  const [completionTimeValue, setCompletionTimeValue] = useState(initialValue);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState()
  const [completionTimeErrorMessage, setCompletionTimeErrorMessage] = useState();
  const [comment, setComment] = useState('');

  const isBeforeOrEqual = (time1, time2) => isBefore(parseISO(time1), parseISO(time2)) || isEqual(parseISO(time1), parseISO(time2));

  const handleCheckStartTime = (value) => {
    const checkOk = isBeforeOrEqual(value, completionTimeValue);
    setStartTimeErrorMessage(checkOk ? '' : t('CustomAction.Errors.TimeCheckActualBeforeComplete'));
    setStartTimeValue(value);
    if(checkOk){
      setCompletionTimeErrorMessage('');
    }
  }

  const handleCheckCompletionTime = (value) => {
    const checkOk = isBeforeOrEqual(startTimeValue, value);
    setCompletionTimeErrorMessage(checkOk ? '' : t('CustomAction.Errors.TimeCheckCompleteAfterActual'));
    setCompletionTimeValue(value);
    if(checkOk){
      setStartTimeErrorMessage('');
    }
  }
  return (
    <Dialog open={open} onClose={onClose} id='CompletionTimeInputDialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {warningMessage && <DialogContentText id='TimeInputDialogWarningMessage' className={classes.warningMessage}>{warningMessage}</DialogContentText>}
        {message && <DialogContentText>{message}</DialogContentText>}
        <StringKeyboardDateTimePicker
            id={`StartTimeDialogTimetInput`}
            label={t('CustomActionMenu.Labels.StartActionDialogInput', { actionName })}
            fullWidth
            margin="normal"
            value={startTimeValue}
            onChange={value => handleCheckStartTime(value)}
            disabled={startTimeDisabled}
          />
        <FormHelperText error >{startTimeErrorMessage || ""}</FormHelperText>
        <StringKeyboardDateTimePicker
            id={`CompletionTimeDialogTimetInput`}
            label={t('CustomActionMenu.Labels.CompleteActionDialogInput', { actionName })}
            fullWidth
            margin="normal"
            value={completionTimeValue}
            onChange={value => handleCheckCompletionTime(value)}
          />
        <FormHelperText error >{completionTimeErrorMessage || ""}</FormHelperText>
        {hasComment &&
          <TextField
            id={'TimeInputDialogCommentInput'}
            variant="outlined"
            label={t('Common.Labels.Comments')}
            fullWidth
            multiline
            maxRows={3}
            margin='normal'
            value={comment}
            onChange={value => setComment(value.currentTarget.value)}
          />
        }
      </DialogContent>
      <DialogActions>
        <div id={'TimeInputDialogCancelButton'}>
          <Button onClick={onClose} color="default">
            {t('Common.Buttons.Cancel')}
          </Button>
        </div>
        <div id={'TimeInputDialogConfirmButton'}>
          <Button
            disabled={(!startTimeValue && !completionTimeValue) || !!startTimeErrorMessage || !!completionTimeErrorMessage}
            onClick={() => onConfirm(startTimeValue, completionTimeValue, comment)}
            color="primary"
          >
            {t('Common.Buttons.Confirm')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CompletionTimeInputDialog;