import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { Attrs } from '../constants';

const styleCache = new Map();

const createTextStyle = (feature, resolution, dom) => {
  let lbl = feature.get(Attrs.NAME);
  let style = styleCache.get('text_' + lbl);
  if(!style) { 
    style = new Style({
      text: new Text({
        font: '11px Helvetica, Arial, sans-serif',
        text: lbl,
        textAlign: 'left',
        overflow: true,
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        }),
        offsetX: 10,
        offsetY: 10
      })
    });
    styleCache.set('text_'+lbl, style);
  }

  return style; 
};

class AISReceiverLabelLayer extends VectorLayer {
  constructor(opt_options) {
    opt_options.style = createTextStyle;
    super(opt_options);
  }; 
};

export default AISReceiverLabelLayer;