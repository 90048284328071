import React from 'react';
import { Typography, TextField, Box, Chip } from '@material-ui/core';
import { Contact } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { useTranslation } from 'react-i18next';
import ContactTypeAutocomplete from '../ContactType/ContactTypeAutocomplete';
import { getContactTypeName } from '../../utils/getters';
import { sortContactsByType } from '../../utils/sorters';
import { DataStore } from 'aws-amplify';
import useNotificationRules from '../../hooks/useNotificationRules';

const AdminContactHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('Contact.Labels.DisplayName')} flexBasis="40%" />
      <AdminModelEditorHeaderColumn label={t('Contact.Labels.TelephoneNumber')} flexBasis="35%" />
      <AdminModelEditorHeaderColumn label={t('Contact.Labels.Type')} flexBasis="25%" />
    </>
  );
};

const AdminContactListItemContent = ({ value }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography noWrap style={{ flexBasis: "40%" }}>{value.displayName || value.name}</Typography>
      <Typography noWrap style={{ flexBasis: "35%" }}>{value.number}</Typography>
      <Box flexBasis="25%" display="flex" alignItems="center" style={{ opacity: 0.5 }}>
        {Boolean(value.type) && <Chip label={getContactTypeName(t, value.type)} />}
      </Box>
    </>
  );
};

const AdminContactFormContent = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">{t('Contact.Labels.DisplayName')}</Typography>
      <TextField id="ContactName" required disabled={disabled} value={value.name || ''} name="name" label={t('Contact.Labels.Name')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.displayName || ''} name="displayName" label={t('Contact.Labels.DisplayName')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.reference || ''} name="reference" label={t('Contact.Labels.Reference')} fullWidth variant="outlined" onChange={onChange} margin="normal" />

      <Typography variant="h6">{t('Contact.Labels.ContactADetail')}</Typography>
      <ContactTypeAutocomplete id="ContactType" disableClearable required label={t('Contact.Labels.Type')} value={value.type || null} onChange={value => onChange({ target: { name: "type", value }})} />
      <TextField disabled={disabled} value={value.contacta_name || ''} name="contacta_name" label={t('Contact.Labels.ContactAName')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField id="ContactNumber" required disabled={disabled} value={value.number || ''} name="number" label={t('Contact.Labels.TelephoneNumber')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.fax || ''} name="fax" label={t('Contact.Labels.Fax')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.email || ''} name="email" label={t('Contact.Labels.Email')} fullWidth variant="outlined" onChange={onChange} margin="normal" />

      <Typography variant="h6">{t('Contact.Labels.Address')}</Typography>
      <TextField disabled={disabled} value={value.address1 || ''} name="address1" label={t('Contact.Labels.Address1')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.address2 || ''} name="address2" label={t('Contact.Labels.Address2')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.town || ''} name="town" label={t('Contact.Labels.Town')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.county || ''} name="county" label={t('Contact.Labels.County')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
      <TextField disabled={disabled} value={value.postcode || ''} name="postcode" label={t('Contact.Labels.Postcode')} fullWidth variant="outlined" onChange={onChange} margin="normal" />
    </>
  );
};

const AdminContact = () => {
  const { updateNotificationRulesForDeletedContact } = useNotificationRules();

  const { t } = useTranslation();
  const itemName = t('Contact.Labels.Contact');

  return (
    <AdminModelEditor
      HeaderContent={AdminContactHeaderContent}
      ListItemContent={AdminContactListItemContent}
      FormContent={AdminContactFormContent}
      model={Contact}
      condition={c => c.deleted("ne", true)}
      filter={i => !i.deleted}
      sort={sortContactsByType}
      canUpdate={() => true}
      canDelete={() => true}
      onDelete={async (i) => {
        await DataStore.save(Contact.copyOf(i, updated => { updated.deleted = true; }));
        await updateNotificationRulesForDeletedContact(i?.id);
      }}
      canSave={i => Boolean(i.name) && Boolean(i.number)}
      canAdd={() => true}
      defaultItem={() => { return {} }}
      customDeleteTooltip={() => t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })}
    />
  );
};

export default AdminContact; 
