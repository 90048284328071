import { useState, useContext, useEffect, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box, InputAdornment, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { TariffCostItem, TariffData } from '../../../models';
import TariffUnitAutocomplete from '../../TariffUnit/TariffUnitAutocomplete';
import MultiActionTypeAutocomplete from '../../ActionType/MultiActionTypeAutocomplete';
import SettingConstants from '../../../constants/SettingConstants';
import CurrencyFormat from '../../Common/CurrencyFormat';
import { DataStoreContext } from '../../../contexts/dataStoreContext';

const useStyes = makeStyles(theme => {
  return {
    DialogButtonBar: {
      paddingLeft: '24px',
      paddingRight: '24px',
      padddingTop: '8px',
      paddingBottom: '24px'
    }
  }
});

const createNewTariff = () => {
  return new TariffCostItem({
    id: uuidv4(),
    unitId: undefined, 
    cost: new TariffData({
      code: '',
      description: '',
      unitCost: undefined,
      notes: '',
      minimumCost: undefined,
      actionTypes: []
    })
  });
};

const TariffDialog = ({ tariff, open, disabled, onAdd, onUpdate, onClose, validateCode }) => {
  const { t } = useTranslation();
  const classes = useStyes();
  const [ value, setValue ] = useState(tariff ? tariff : createNewTariff());
  const [ unitCodeError, setUnitCodeError ] = useState('')
  const { settings, actionTypes, tariffUnits } = useContext(DataStoreContext);
  const [ actionTypesValue, setActionTypesValue ] = useState([]);
  const [ validTariff, setValidTariff ] = useState(false);

  const appCurrencySetting = settings.length && JSON.parse(settings.find(s => s.name === SettingConstants.APP_CURRENCY).value);

  const onChange = useCallback((event) => {
    const ev = { name: event.target.name, value: event.target.value };
    setValue(prev => {
      return {
        ...prev,
        cost: {
          ...prev.cost,
          [ev.name]: ev.value
        }
      }
    });
  }, []);

  const onChangeCost = useCallback((event) => {
    const ev = { name: event.target.name, value: event.target.value };
    setValue(prev => {
      return {
        ...prev,
        cost: {
          ...prev.cost,
          [ev.name]: ev.value
        }
      }
    });
  }, []);

  const onChangeCostNumber = useCallback((event) => {
    const ev = { name: event.target.name, value: event.target.value };
    setValue(prev => {
      return {
        ...prev,
        cost: {
          ...prev.cost,
          [ev.name]: parseFloat(ev.value)
        }
      }
    })
  }, []);

  const onChangeUnits = useCallback((unit) => {
    unit && setValue(prev => {
      return {
        ...prev,
        unitId: unit.id
      }
    });
  }, []);

  const handleOnAdd = () => onAdd(value);

  const handleOnUpdate = () => onUpdate(value);

  const handleCodeChanged = (event) => {
    onChangeCost(event);
    validateCode && setUnitCodeError(
      validateCode(value.id, event.target.value) ? '' : t('AdminTariff.Labels.DuplicateTariff'));
  };
 
  useEffect(() => {
    // Build action types model array
    actionTypes.length &&
      setActionTypesValue(value.cost.actionTypes ?
        value.cost.actionTypes.map(id =>
          actionTypes.find(at => at.id === id)) : [])
  }, [actionTypes]);

  useEffect(() => {
    // Flatten actionTypesName to an array of IDs
    onChange({ target: {name: 'actionTypes', value: actionTypesValue.map(el => el.id)}})
  }, [actionTypesValue, onChange])

  useEffect(() => {
    // Validate tariff record
    setValidTariff(value.cost.code && value.cost.unitCost && value.unitId && !unitCodeError);
  }, [value, unitCodeError]);

  return (
    <Dialog open={open}>
      <DialogTitle>{tariff ? t('AdminTariff.Labels.UpdateTariff') : t('AdminTariff.Labels.AddTariff')}</DialogTitle>
      <DialogContent>
        <TextField 
          id="AdminTariffCodeInput"
          required 
          disabled={disabled} 
          value={value.cost.code || ''} 
          name="code" 
          label={t('Tariff.Labels.Code')} 
          fullWidth variant="outlined" 
          margin="normal"
          autoComplete="off" 
          onChange={handleCodeChanged}
          error={unitCodeError !== ''}
          helperText={unitCodeError}
          inputProps={{
            maxLength: 40,
          }}
        />
        <TextField
          id="AdminTariffDescriptionInput"
          disabled={disabled}
          value={value.cost.description || ''}
          name="description"
          label={t('Tariff.Labels.Description')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          multiline margin="normal"
          onChange={(e) => onChange(e)}
          inputProps={{
            maxLength: 255,
          }}
        />
        <Box display="flex" id="AdminTariffCostInputs">
          <TextField
            required
            disabled={disabled}
            value={value.cost.unitCost}
            name="unitCost"
            label={t('Tariff.Labels.UnitCost')}
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
            onChange={(e) => onChangeCostNumber({ target: {name: 'unitCost', value: e.target.value }} )}
            InputProps={{
              className: 'UnitCostInput',
              inputComponent: CurrencyFormat,
              startAdornment: <InputAdornment position="start">{appCurrencySetting.symbol}</InputAdornment>
            }}
            style={{marginRight: ".5rem"}}
          />
          <TextField
            id="AdminTariffMinimumCostInput"
            disabled={disabled}
            value={value.cost.minimumCost}
            name="minimumCost"
            label={t('Tariff.Labels.MinimumCost')}
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
            onChange={(e) => onChangeCostNumber({ target: {name: 'minimumCost', value: e.target.value }} )}
            InputProps={{
              className: 'MinimumCostInput',
              inputComponent: CurrencyFormat,
              startAdornment: <InputAdornment position="start">{appCurrencySetting.symbol}</InputAdornment>
            }}
            style={{marginLeft: ".5rem"}}
          />
        </Box>
        <TariffUnitAutocomplete
          id="AdminTariffUnitInput"
          required
          disabled={disabled}
          label={t('Tariff.Labels.TariffUnit')}
          value={tariffUnits.find(el => el.id === value.unitId) || null}
          onChange={unit => onChangeUnits(unit)}
        />
        <MultiActionTypeAutocomplete
          id="AdminTariffActionTypeInput"
          filterSelectedOptions 
          label={t('Tariff.Labels.ActionTypes')} 
          value={actionTypesValue}
          onChange={setActionTypesValue} 
          helperText={t('Tariff.Labels.ActionTypesHelp')}
        />
        <TextField
          id="AdminTariffNoteInput"
          disabled={disabled}
          value={value.cost.notes || ''}
          name="notes"
          label={t('Tariff.Labels.Notes')}
          fullWidth
          variant="outlined"
          multiline minRows={2}
          maxRows={10}
          margin="normal"
          autoComplete="off"
          onChange={onChangeCost}
          inputProps={{
            maxLength: 255,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.DialogButtonBar}>
        <Button
          id="AdminTariffCloseButton"
          color="primary"
          onClick={onClose}
        >
          {t('Common.Buttons.Close')}
        </Button>
        <Button
          id="AdminTariffConfirmButton"
          color="primary"
          variant="contained"
          disabled={!validTariff}
          onClick={ tariff ? handleOnUpdate : handleOnAdd }
        >
          {tariff ? t('Common.Buttons.Update') : t('AdminTariff.Buttons.AddTariff') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TariffDialog;
