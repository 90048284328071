import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Box,
  CardContent,
  Link,
  Grid,
  InputAdornment,
  MenuItem,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';

import CountrySelect from '../CountrySelect';
import { EditorSelect } from '../Common/EditorSelect';
import * as P from '../../constants/Propulsion';
import VesselEditImageForm from './VesselEditImageForm';
import usePorts from '../../hooks/usePorts';
import { PropulsionType, PropellerDirection, RudderType, Vessel } from '../../models';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import PortSearchAutocomplete from '../Vessel/PortSearchAutocomplete';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { HP, KW, UnitsSymbolKeyFromString } from '../../constants/Units';
import { ContactTypeIds } from '../../environment';
import CertificateEditor from './CertificateEditor';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '85rem',
    margin: '0 auto'
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '12rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const RudderTypes = [
  RudderType.RUDDER_STANDARD,
  RudderType.RUDDER_FLAPPED,
  RudderType.RUDDER_FISHTAIL
];

const PropellerDirections = [
  PropellerDirection.PROPELLER_LEFT,
  PropellerDirection.PROPELLER_RIGHT
];

const VesselAnatomyForm = ({ vessel, setVessel, createAgent, nameError, imoError, mmsiError, propulsionPowerUnitError, disabled }) => {
  const { vesselData } = vessel;

  const handleChangeImage = (value) => {
    setVessel(Vessel.copyOf(vessel, updated => {
      updated.vesselData = { ...vessel.vesselData, image: value };
    }));
  };

  const handleChange = (e) => {
    const { name, value, maxLength, type, min } = e.currentTarget;

    // Convert value to number if type is number
    const valueNumber = type === 'number' && !isNaN(parseFloat(value)) ? parseFloat(value) : null;
    // Check if max length is exceeded 
    if (maxLength > 0 && value.length > maxLength) return;
    // Check if value is more than min
    if (valueNumber && min && valueNumber < min) return;

    setVessel(Vessel.copyOf(vessel, updated => {
      updated.vesselData = { ...vessel.vesselData, [name]: type === 'number' ? valueNumber : value };
      // unset propellerDirection if propellerCount is changed
      if (name === 'propellerCount' && value !== 1 && updated.vesselData.propellerDirection) {
        updated.vesselData = { ...updated.vesselData, propellerDirection: null };
      }
      // unset propulsionPower
      if (name === 'propulsionPower' && !value && updated.vesselData.propulsionPowerUnit) {
        updated.vesselData = { ...updated.vesselData, propulsionPowerUnit: null };
      }
    }));
  };

  const handleChangeVesselAgent = (value) => {
    setVessel(Vessel.copyOf(vessel, updated => {
      updated.vesselAgent = value;
      updated.vesselVesselAgentId = value ? value.id : null
    }));
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setVessel(Vessel.copyOf(vessel, updated => { updated.vesselData = { ...vessel.vesselData, [name]: value }}));
  };

  const handlePortOfRegistryChange = (value) => {
    setVessel(Vessel.copyOf(vessel, updated => { 
      updated.vesselData = { 
        ...vessel.vesselData, 
        portOfRegistry: {...value }
      }
    }));
  };

  const classes = useStyles();
  const validErrors = {};
  const ports = usePorts();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  if (!ports) return null;

  return (
    <Grid item xs={12} className={classes.content}>
      <Tabs value={tab} onChange={(_, value) => setTab(value)} indicatorColor="primary" textColor="primary">
        <Tab id="VesselDetailsTab" label={t('Vessel.Labels.Details')} disableRipple value={0} />
        <Tab id="VesselCertificatesTab" label={t('Vessel.Labels.Certificates')} disableRipple value={1} />
        <Tab id="VesselPropulsionTab" label={t('Vessel.Labels.Propulsion')} disableRipple value={2} />
      </Tabs>
      <CardContent>
        {tab === 0 && <>
          {(!disabled || vesselData.image) && 
          <>
            <Typography variant="h5">{t('VesselForm.Labels.Image')}</Typography>
            <Box display="flex">
              <Grid container style={{ paddingLeft: "8px", paddingBottom: "8px" }} className={classes.root} spacing={2}>
                <VesselEditImageForm
                  imageData={vesselData.image}
                  onChange={handleChangeImage}
                  hideButtons={disabled}
                />
              </Grid>
            </Box>
          </>
        }

        <Typography variant="h5">{t('Vessel.Labels.Identity')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="NameInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.Name')}
                margin="normal"
                name="name"
                value={vesselData.name || ''}
                onChange={handleChange}
                error={Boolean(nameError)}
                helperText={nameError}
                required
                fullWidth
                inputProps={{
                  style: { textTransform: 'uppercase' }
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="IMOInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.IMO')}
                margin="normal"
                name="imo"
                value={vesselData.imo || ''}
                onChange={handleChange}
                error={Boolean(imoError)}
                helperText={imoError}
                inputProps={{
                  maxLength: 7
                }}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="MMSIInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.MMSI')}
                margin="normal"
                name="mmsi"
                value={vesselData.mmsi || ''}
                onChange={handleChange}
                error={Boolean(mmsiError)}
                helperText={mmsiError}
                inputProps={{
                  maxLength: 9
                }}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="CallSignInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.CallSign')}
                margin="normal"
                name="callSign"
                value={vesselData.callSign || ''}
                onChange={handleChange}
                fullWidth
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.Details')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="VesselTypeInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.Type')}
                margin="normal"
                name="type"
                value={vesselData.type || ''}
                onChange={handleChange}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="YearBuiltInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.YearBuilt')}
                margin="normal"
                name="yearBuilt"
                value={vesselData.yearBuilt}
                onChange={handleChange}
                type="number"
                inputProps={{
                  maxLength: 4,
                  min: 1900
                }}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <CountrySelect
                id="FlagSelect"
                label={t('Vessel.Labels.Flag')}
                name="flag"
                value={vesselData.flag}
                onChange={(value) => handleChange({ currentTarget: { name: 'flag', value: value || '' } })}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <PortSearchAutocomplete
                id="PortOfRegistryInput"
                options={ports}
                value={vesselData.portOfRegistry || ''}
                label={t('Vessel.Labels.PortOfRegistry')}
                handleChange={(e, value) => handlePortOfRegistryChange(value)}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h5">{t('Vessel.Labels.Tonnage')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="DeadWeightTonnageInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.DeadweightTonnage')}
                margin="normal"
                name="deadWeightTonnage"
                value={vesselData.deadWeightTonnage}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">t</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                }}
                type="number"
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="GrossTonnageInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.GrossTonnage')}
                margin="normal"
                name="grossTonnage"
                value={vesselData.grossTonnage}
                onChange={handleChange}
                inputProps={{
                  min: 0,
                }}
                type="number"
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="NetTonnageInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.NetTonnage')}
                margin="normal"
                name="netTonnage"
                value={vesselData.netTonnage}
                onChange={handleChange}
                inputProps={{
                  min: 0,
                }}
                type="number"
                fullWidth
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.Dimensions')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="LengthOverallInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.Length')}
                margin="normal"
                name="lengthOverall"
                value={vesselData.lengthOverall}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
                type="number"
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="BeamInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.Beam')}
                margin="normal"
                name="beam"
                value={vesselData.beam}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
                type="number"
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="NominalDraughtInput"
                autoComplete="off"
                variant="outlined"
                label={t('Vessel.Labels.NominalDraught')}
                margin="normal"
                name="draught"
                value={vesselData.draught}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
                type="number"
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" justifyContent="space-between" alignItems="baseline" marginRight="8px">
              <Typography variant="h5">{t('Vessel.Labels.VesselAgent')}</Typography>
              {!disabled && createAgent &&
                <Link onClick={() => createAgent()}><Typography>{t('Agent.Buttons.CreateAgent')}</Typography></Link>}
            </Box>
          </Grid>
        </Grid>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={4} className={classes.content} id="VesselAgentInputContainer">
              <ContactAutocomplete
                id="VesselAgentInput"
                onChange={handleChangeVesselAgent}
                label={t('Vessel.Labels.AgentName')}
                value={vessel.vesselAgent}
                filter={c => c.type?.id === ContactTypeIds.AGENT}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
        </>}
        {tab === 1 && <>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" justifyContent="space-between" alignItems="baseline" marginRight="8px">
              <Typography variant="h5">{t('Vessel.Labels.Certificates')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={12} className={classes.content}>
            <CertificateEditor vessel={vessel} setVessel={setVessel} disabled={disabled} />
            </Grid>
          </Grid>
        </Box>
        </>}
        {tab === 2 && <>
        <Typography variant="h5">{t('Vessel.Labels.Propulsion')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <EditorSelect
                id="PropulsionTypeSelect"
                name='propulsionType'
                label={t('Vessel.Labels.PropulsionType')}
                labelId="propulsionType"
                onChange={handleChangeSelect}
                value={vesselData.propulsionType || ''}
                disabled={disabled}
              >
                {Object.keys(PropulsionType).map(key => <MenuItem value={PropulsionType[key]} key={PropulsionType[key]}>{t(P.PropulsionTypeLabelKeys[PropulsionType[key]])}</MenuItem>)}
              </EditorSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="PropellerCountInput"
                autoComplete="off"
                name="propellerCount"
                variant="outlined"
                label={t('Vessel.Labels.NumPropellers')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.propellerCount || ''}
                inputProps={{
                  maxLength: 2,
                  min: 1,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <EditorSelect
                id="PropellerDirectionInput"
                name='propellerDirection'
                label={t('Vessel.Labels.Direction')}
                labelId="propellerDirection"
                disabled={disabled || vesselData.propellerCount !== 1}
                onChange={handleChangeSelect}
                value={vesselData.propellerDirection || ''}
              >
                {PropellerDirections.map(o => (<MenuItem key={o} value={o}>{t(P.PropellerDirectionLabelKeys[o])}</MenuItem>))}
              </EditorSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="PropulsionPowerInput"
                autoComplete="off"
                name="propulsionPower"
                variant="outlined"
                label={t('Vessel.Labels.TotalPower')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.propulsionPower || ''}
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <EditorSelect
                id="PropulsionPowerUnitSelect"
                name="propulsionPowerUnit"
                label={t('Vessel.Labels.Units')}
                labelId="propulsionPowerUnit"
                onChange={handleChangeSelect}
                value={vesselData.propulsionPowerUnit || ''}
                error={Boolean(propulsionPowerUnitError)}
                helperText={propulsionPowerUnitError}
                disabled={disabled}
              >
                <MenuItem value="KW">{t(UnitsSymbolKeyFromString(KW))}</MenuItem>
                <MenuItem value="HP">{t(UnitsSymbolKeyFromString(HP))}</MenuItem>
              </EditorSelect>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.Rudder')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <EditorSelect
                id="RudderTypeSelect"
                name="rudderType"
                label={t('Vessel.Labels.RudderType')}
                labelId="rudderType"
                onChange={handleChangeSelect}
                value={vesselData.rudderType || ''}
                disabled={disabled}
              >
                {RudderTypes.map(o => (<MenuItem key={o} value={o}>{t(P.RudderTypeLabelKeys[o])}</MenuItem>))}
              </EditorSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="RudderCountInput"
                autoComplete="off"
                name="rudderCount"
                variant="outlined"
                label={t('Vessel.Labels.NumRudders')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.rudderCount || ''}
                inputProps={{
                  maxLength: 1,
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.ForwardAzimuth')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="FwdAzimuthCountInput"
                autoComplete="off"
                name="fwdAzimuthCount"
                variant="outlined"
                label={t('Vessel.Labels.NumThrusters')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.fwdAzimuthCount || ''}
                inputProps={{
                  maxLength: 2,
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="FwdAzimuthPowerInput"
                autoComplete="off"
                name="fwdAzimuthPower"
                variant="outlined"
                label={t('Vessel.Labels.TotalPower')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.fwdAzimuthPower || ''}
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <EditorSelect
                id="FwdAzimuthPowerUnitSelect"
                name="fwdAzimuthPowerUnit"
                label={t('Vessel.Labels.Units')}
                labelId="fwdAzimuthPowerUnit"
                onChange={handleChangeSelect}
                value={vesselData.fwdAzimuthPowerUnit}
                error={Boolean(validErrors['fwdAzimuthPowerUnit'])}
                helperText={validErrors['fwdAzimuthPowerUnit']}
                disabled={disabled}
              >
                <MenuItem value="KW">{t(UnitsSymbolKeyFromString(KW))}</MenuItem>
                <MenuItem value="HP">{t(UnitsSymbolKeyFromString(HP))}</MenuItem>
              </EditorSelect>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.AftAzimuth')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="AftAzimuthCountInput"
                autoComplete="off"
                name="aftAzimuthCount"
                variant="outlined"
                label={t('Vessel.Labels.NumThrusters')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.aftAzimuthCount || ''}
                inputProps={{
                  maxLength: 2,
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="AftAzimuthPowerInput"
                autoComplete="off"
                name="aftAzimuthPower"
                variant="outlined"
                label={t('Vessel.Labels.TotalPower')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.aftAzimuthPower || ''}
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <EditorSelect
                id="AftAzimuthPowerUnitSelect"
                name="aftAzimuthPowerUnit"
                label={t('Vessel.Labels.Units')}
                labelId="aftAzimuthPowerUnit"
                onChange={handleChangeSelect}
                value={vesselData.aftAzimuthPowerUnit}
                error={Boolean(validErrors['aftAzimuthPowerUnit'])}
                helperText={validErrors['aftAzimuthPowerUnit']}
                disabled={disabled}
              >
                <MenuItem value="KW">{t(UnitsSymbolKeyFromString(KW))}</MenuItem>
                <MenuItem value="HP">{t(UnitsSymbolKeyFromString(HP))}</MenuItem>
              </EditorSelect>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.ForwardTunnelThrusters')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="FwdTunnelThrusterCountInput"
                autoComplete="off"
                name="fwdTunnelThrusterCount"
                variant="outlined"
                label={t('Vessel.Labels.NumThrusters')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.fwdTunnelThrusterCount || ''}
                inputProps={{
                  maxLength: 2,
                  min: 0
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="FwdTunnelThrusterPowerInput"
                autoComplete="off"
                name="fwdTunnelThrusterPower"
                variant="outlined"
                label={t('Vessel.Labels.TotalPower')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.fwdTunnelThrusterPower || ''}
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <EditorSelect
                id="FwdTunnelThrusterPowerUnitSelect"
                autoComplete="off"
                name="fwdTunnelThrusterPowerUnit"
                label={t('Vessel.Labels.Units')}
                labelId="fwdTunnelThrusterPowerUnit"
                onChange={handleChangeSelect}
                value={vesselData.fwdTunnelThrusterPowerUnit || ''}
                error={Boolean(validErrors['fwdTunnelThrusterPowerUnit'])}
                helperText={validErrors['fwdTunnelThrusterPowerUnit']}
                disabled={disabled}
              >
                <MenuItem value="KW">{t(UnitsSymbolKeyFromString(KW))}</MenuItem>
                <MenuItem value="HP">{t(UnitsSymbolKeyFromString(HP))}</MenuItem>
              </EditorSelect>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h5">{t('Vessel.Labels.AftTunnelThrusters')}</Typography>
        <Box display="flex">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="AftTunnelThrusterCountInput"
                autoComplete="off"
                name="aftTunnelThrusterCount"
                variant="outlined"
                label={t('Vessel.Labels.NumThrusters')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.aftTunnelThrusterCount || ''}
                inputProps={{
                  maxLength: 2,
                  min: 0
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id="AftTunnelThrusterPowerInput"
                autoComplete="off"
                name="aftTunnelThrusterPower"
                variant="outlined"
                label={t('Vessel.Labels.TotalPower')}
                margin="normal"
                type="number"
                onChange={handleChange}
                value={vesselData.aftTunnelThrusterPower || ''}
                inputProps={{
                  min: 0,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <EditorSelect
                id="AftTunnelThrusterPowerUnitSelect"
                name="aftTunnelThrusterPowerUnit"
                label={t('Vessel.Labels.Units')}
                labelId="aftTunnelThrusterPowerUnit"
                onChange={handleChangeSelect}
                value={vesselData.aftTunnelThrusterPowerUnit || ''}
                error={Boolean(validErrors['aftTunnelThrusterPowerUnit'])}
                helperText={validErrors['aftTunnelThrusterPowerUnit']}
                disabled={disabled}
              >
                <MenuItem value="KW">{t(UnitsSymbolKeyFromString(KW))}</MenuItem>
                <MenuItem value="HP">{t(UnitsSymbolKeyFromString(HP))}</MenuItem>
              </EditorSelect>
            </Grid>
          </Grid>
        </Box>
        </>}
      </CardContent>
    </Grid>
  );
};
export default VesselAnatomyForm;