import React, { useState, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Icon,
  Collapse,
} from '@material-ui/core';
import {
  Account,
  Logout,
  Translate,
} from 'mdi-material-ui';
import { Auth } from 'aws-amplify';
import { UIContext } from '../../contexts/ui';
import useLanguage from '../../hooks/useLanguage';
import { LanguageLabels } from '../../constants/LanguageLabels';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
    cursor: 'pointer',
    paddingRight: '2rem'
  },
  username: {
    fontWeight: 200
  },
}));

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const User = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const menuEl = useRef(null);
  const [uiContext,] = useContext(UIContext);
  const history = useHistory();
  const { languages, userLanguage, setUserLanguage } = useLanguage(true);
  return (
    <>
      <Box display="flex" flexShrink={0} alignItems="flex-start" className={classes.root} ref={menuEl} onClick={() => setOpenMenu(!openMenu)} >
        <Grid container justifyContent="space-evenly" direction="row" alignItems="center">
          <Grid item>
            <Icon
              component="div"
              fontSize="large">
              <Account fontSize="large" />              
            </Icon>
          </Grid>
          <Grid item>
            <Box display="flex" flexShrink={0} alignItems="flex-start" className={classes.username} >
              <Typography className={classes.username} variant="body1">{uiContext.userName}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      
      <Menu
        open={openMenu}
        anchorEl={menuEl.current}
        onClose={() => { setOpenMenu(false); setShowLanguages(false); }}
        anchorOrigin={anchorOrigin}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={() => setShowLanguages(prev => !prev)} selected={showLanguages}>
          <ListItemIcon>
            <Translate fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`${t('AppHeader.Labels.Language')}: ${LanguageLabels[userLanguage]}`} />
        </MenuItem>
        <Collapse in={showLanguages}>
          <Box m="0.5rem 0">
            {languages.map(l =>
              <MenuItem onClick={() => { setUserLanguage(l); setShowLanguages(false); setOpenMenu(false); }} key={l}>
                <ListItemText primary={`${LanguageLabels[l]}`} primaryTypographyProps={{ variant: "body2" }} />
              </MenuItem>
            )}
          </Box>
        </Collapse>
        <MenuItem
          id='LogoutMenuItem'
          onClick={() => {
            Auth.signOut().then(() => {
              history.push('/');
            });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`${t('AppHeader.Labels.LogOut')}`}/>
        </MenuItem>
      </Menu>
    </> 
  )
};

export default User;
