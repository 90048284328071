'use-strict';
import { DataStore } from 'aws-amplify';
import cloneDeep from 'lodash.clonedeep';
import { Storage } from 'aws-amplify';

// takes in the source and target index and list to modify
export const reorderArray = (sourceIndex, targetIndex, list) => {
  // if there are not source or target index return original list
  if(typeof sourceIndex !== "number" || typeof targetIndex !== "number") return list;

  // create clone of list
  const listClone = [...list];
  // remove element at sourceIndex
  const sourceElement = listClone.splice(sourceIndex, 1);
  // if there is an element insert element at targetIndex
  if(sourceElement.length > 0) {
    listClone.splice(targetIndex, 0, sourceElement[0]);
  }
  // return the clone of list
  return [...listClone];
};

// Returns currency symbol for ISO-4217 code in en-US locale
export const getCurrencySymbol = (currency) => (0).toLocaleString('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()

export const kmhToKnot = kmh => Math.round(kmh * 0.539957);

// Returns farhenheit given the temperature in celcius 
export const celciusToFahrenheit = celcius => (celcius * (9/5)) + 32;

export const debounce = (fn, time) => {
  let timeout;
  let gnonce;
  return function () {
    const nonce = gnonce = new Object();
    const functionCall = () => gnonce === nonce && fn.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
};

/**
 * Replace all matching properties, e.g. for graphql preparation ('' to undefined) or the other way around (nulls to '')
 */
export const replaceDeep = (obj, from, to) => {
  Object.keys(obj).map(key => {
    if (obj[key] !== null && typeof obj[key] === 'object') replaceDeep(obj[key], from, to);
    if (obj[key] === from) obj[key] = to;
  });
  return obj;
}

export const formatDimension = (dimension) => {
  return dimension.toFixed(2);
}
export const portName = (port) => {
  if(!port || !port.name) return '';
  if(port && port.name && (!port.countryCode || !port.portCode)) return port.name;
  return port ? `${port.name} (${(port.countryCode + ' ' + port.portCode).trim()})`.trim() : '';
};
export const portNameTrimmed = (port) => {
  return port && port.name ? port.name.split(',')[0].split('(')[0].replace('/', ' / ').replace('  ', ' ') : '';
};

//Function that takes the berth location data array as an input
//And returns it in a tree like structure 
// ms: this will leak parents in allIds and break Filter component unless you sort locations (flatArray) by their number beforehand
export const locationArrayFlatToTree = (flatArray) => {
	const allIds = new Set();
	const root = [];
	const map = {};
	const flat = flatArray.map(({ id, name }) => ({ id, name }));
	flatArray.forEach((item, index) => {
		const copyItem = flat[index];
		if (
			//Will not add the berths or non dockables to the tree
			(item.allocatable === true &&
				item.dockable === true &&
				Boolean(item.parent)) ||
			(item.allocatable === false &&
				item.dockable === false &&
				Boolean(item.parent))
		)
			return;
		//Relabel information to work with Checkbox tree
		copyItem.value = item.id;
		copyItem.label = item.name;
		//Add id to the array of all ids
		allIds.add(item.id);
		//Checks if there is a parent and if there isn't add to the tree as a root
		if (!item.parent) {
			root.push(copyItem);
			return;
		}
		//Else get the parent id of the child
		let parentId = item.parent.id;
		//If parent remove the id from the allIds object
		//Get the index of the parent wihtin the map object
		let parentIndex = map[parentId];
		//If there isn't a parent index then find the parent in the data
		allIds.delete(item.parent.id);
		//and the add it to the map object with it's id as a key
		if (typeof parentIndex !== "number") {
			parentIndex = flat.findIndex((el) => el.id === parentId);
			map[parentId] = parentIndex;
		}
		//If there are no children then add the children property to the parent
		//And add the current item as a child
		if (!flat[parentIndex].children) {
			return (flat[parentIndex].children = [copyItem]);
		}
		//Else there is already children and we just need to add the item to that array
		flat[parentIndex].children.push(copyItem);
	});
	const result = [root, allIds];
	return result;
};

// Creates a tree(s) of locations by deep cloning input and populating children field for each location
// Returns [roots, flat]
// Note: roots is Array as there can be multiple locations with NULL parent
export const locationsToTree = locations => {
  const _locations = cloneDeep(locations);
  const _roots = _locations.filter(l => !l.parent);
  const populate = node => {
    for (let l of _locations) {
      if (l.parent && l.parent.id === node.id) {
        const child = l;
        node.children = [...node.children || [], child];
        populate(child);
      }
    }
    return node;
  };
  return _roots.map(populate);
};

// Returns a flat array in correct order and populates level field for each location
export const flattenLocationsTree = roots => {
  const parse = (node, level) => [
    { ...node, level },
    ...node.children ? node.children.reduce((acc, cur) => [...acc, ...parse(cur, level + 1) ], []) : []
  ];
  return roots.reduce((acc, cur) => [...acc, ...parse(cur, 0)], []);
};

//Truncates string determined by length and add post string if there is one.
export const truncateString = (str, len, postStr = "", useWordBoundary = false ) => {
  if(!str) return "";
  if (str.length <= len) { return str; }
  const subString = str.substr(0, len-1); // the original check
  const wordBoundaryStr = useWordBoundary ? subString(0, subString.lastIndexOf(" ")) : subString;
  return `${wordBoundaryStr}${postStr}`;
}

// Validates item (graphql object) by applying graphql FilterInput and returns true if passed
export const validateItemByVariables = (item, filter) => {
  const handleSpecial = {
    and: arr => {
      for (let f of arr)
        if (!handleKey(f)) return false;
      return true;
    },
    or: arr => {
      for (let f of arr)
        if (handleKey(f)) return true;
      return false;
    },
    not: obj => handleKey(obj)
  };

  const handleField = {
    eq: (field, value) => item[field] === value,
    ne: (field, value) => item[field] !== value,
    gte: (field, value) => item[field] >= value,
    lte: (field, value) => item[field] <= value,
    exists: (field, value) => value === 'true' ? Boolean(item[field]) : !Boolean(item[field]),
    matchPhrase: (field, value) => item[field] ? item[field].includes(value) : false
  }

  const handleKey = f => {
    const field = Object.keys(f)[0];
    // special
    if (['and', 'or', 'not'].includes(field)) {
      return handleSpecial[field](f[field]);
    }
    else {
      const filterType = Object.keys(f[field])[0];
      return handleField[filterType](field, f[field][filterType]);
    }
  };

  return handleKey(filter);
}

// Both string methods remove underscore

// Formatting a string. Ensuring first letter is capitalised
// And the rest of the string is lower case;
export const capitalize = str => (str && typeof str === "string") ? str[0].toUpperCase() + str.slice(1).toLowerCase().replace('_', ' ') : "";

// Formatting a string. Ensuring first letter is lowercase
// And the rest of the string is lower case;
export const lowercase = str => (str && typeof str === "string") ? str[0].toLowerCase() + str.slice(1).toLowerCase().replace('_', ' ') : "";

/**
 * Utility for constructing a DataStore subscription. Supports early update skip by checking against _version of existing entries.
 * @param {*} options.cancelled - boolean contained in object { current: Boolean }
 * @param {*} options.model - DataStore model to subscribe to
 * @param {(prev) => items} options.setItems - state setter from React.useState
 * @param {(prev, element) => item} options.findItem - find instance in existing data
 * @param {element => newItem} options.constructItemAsync - async reconstruct DataStore object (connection) from element 
 * @param {(prev, newItem) => items} options.updateItems - update relevant data with newItem
 * @returns DataStore observable
 */
export const buildSubscription = ({ cancelled, model, setItems, findItem, constructItemAsync, updateItems }) => {
  if (!cancelled || !model || !setItems) {
    throw new Error('buildSubscription: missing required options');
  }
  return DataStore.observe(model).subscribe(async msg => {
    const { element, opType } = msg;
    const isUpdateOp = !element._deleted && (opType === "UPDATE" || opType === "INSERT");
    // access latest data
    if (findItem) {
      let item;
      setItems(prev => {
        item = findItem(prev, element);
        return prev;
      });
      // check version to do early skip
      if (!item || item?._version >= element._version) return;
    }
    if (cancelled.current) return;
    // re-construct element's connections
    let newItem = null;
    if (isUpdateOp) {
      newItem = Boolean(constructItemAsync) 
        ? await constructItemAsync(element) 
        : element;
    }
    if (cancelled.current) return;
    // update relevant item
    setItems(prev => updateItems(prev, newItem));
  });
}

/**
 * Utility for constructing simple connection subscriptions. Handles array and single item data.
 * @param {*} options.cancelled - boolean contained in object { current: Boolean }
 * @param {*} options.model - DataStore model of the main object
 * @param {(prev) => items} options.setItems - state setter from React.useState
 * @param {String} options.fieldName - connection field name
 * @param {*} options.fieldModel - connection field model
 * @returns DataStore observable
 */
export const buildConnectionSubscription = ({ cancelled, model, setItems, fieldName, fieldModel }) => buildSubscription({
  cancelled,
  model: fieldModel,
  setItems,
  findItem: (prev, element) => Array.isArray(prev)
    ? prev.find(i => i[fieldName]?.id === element.id)
    : prev?.[fieldName]?.id === element.id ? prev : null,
  updateItems: (prev, newItem) => Array.isArray(prev)
    ? prev.map(i => i?.[fieldName]?.id === newItem?.id 
      ? model.copyOf(i, updated => { updated[fieldName] = newItem; })
      : i)
    : Boolean(prev)
      ? model.copyOf(prev, updated => { updated[fieldName] = newItem; })
      : prev
});


/**
 * Utility for get S3 url
 * @param {String} key S3 key
 * @returns Object contains fileLocation and error
 */
export const getS3URL = async(key) => {
  return await Storage.get(key, { download: true })
  .then((res) => ({fileLocation : URL.createObjectURL(res.Body), error : null}))
   .catch((err) => ({fileLocation : null, error : err?.toString()}));
}