import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN_GB } from "./locale/en-GB/translations";
import { TRANSLATIONS_ES_MX } from "./locale/es-MX/translations";
import { TRANSLATIONS_FR_CA } from "./locale/fr-CA/translations";
import { TRANSLATIONS_TEST } from "./locale/test/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
    lng: "en-GB",
    fallbackLng: 'en-GB',
    resources: {
      "en-GB": {
        translation: TRANSLATIONS_EN_GB
      },
      "fr-CA": {
        translation: TRANSLATIONS_FR_CA
      },
      "es-MX": {
        translation: TRANSLATIONS_ES_MX
      },
      "test": {
        translation: TRANSLATIONS_TEST
      }
    },
    parseMissingKeyHandler: (key) => {
      return "Missing translation";
    }
 });