export default {
  APP_CURRENCY: 'APP_CURRENCY',
  APP_PORTCALL_APPROVAL: 'APP_PORTCALL_APPROVAL',
  APP_UNITS: 'APP_UNITS',
  APP_LANGUAGE: 'APP_LANGUAGE',
  APP_DATE_TIME: 'APP_DATE_TIME',
  APP_PORTCALL_AUTOMATION: 'APP_PORTCALL_AUTOMATION',
  APP_EXTERNAL_NOTIFICATIONS: 'APP_EXTERNAL_NOTIFICATIONS',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  AIS_RECEIVER_STATUS: 'AIS_RECEIVER_STATUS',
  APP_FILTERS: "APP_FILTERS"
};