import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { documentationUrl } from '../environment.js';

const DOC_HOST = documentationUrl;
const DOC_ROOT_URI = '/index.html';
const DOC_AUTH_URI = '/auth';
const IFRAME_TITLE = 'Marlin SmartPort Documentation';

const Documentation = () => {
  const [tokenSrc, setTokenSrc] = useState('about:blank');
  const [ready, setReady] = useState(false); // incrementing will force reloads

  // requests lambda to set cookies and calls itself after token expires
  const setup = async () => {
    const session = await Auth.currentSession();
    const msToExpire = session.getAccessToken().getExpiration() * 1000 - Date.now();
    // console.log('reloading page in', msToExpire / 1000 / 60, 'minutes', session);
    setTimeout(setup, msToExpire);
    setTokenSrc(`${DOC_HOST}${DOC_AUTH_URI}?accessToken=${encodeURIComponent(session.getAccessToken().getJwtToken())}`);
  };

  // init
  useEffect(() => {
    setup();
  }, []);

  return (
    <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
      {!ready && <CircularProgress />}
      {ready && 
        <iframe
          title={IFRAME_TITLE}
          src={DOC_HOST + DOC_ROOT_URI}
          width="100%"
          height="100%"
          style={{ border: 0 }}
        />
      }
      <iframe
        title={IFRAME_TITLE}
        src={tokenSrc}
        onLoad={() => tokenSrc && !ready && setReady(true)}
        width="1"
        height="1"
        style={{ border: 0 }}
      />
    </Box>
  );
};

export default Documentation;