/**
 * Generate a new tariff book name based all existing books.
 * New books naming schema is '<original> copy', then appending number for each subsequent book copy (e.g. copy 2, copy 3 etc.).
 * @param {TariffBook} item Tariff book name
 * @param {Arrray[TariffBooks]} books 
 * @returns {String} New tariff book name
 */
const duplicatedTariffBookName = (item, books) => {

  const tariffBookNames = []
  for (let i=0; i<books?.length; i++){
    if (books[i].deleted === false){
      tariffBookNames.push(books[i].name);
    }
  }

  const splitName = item.name.split(" ");
  const finalEl = splitName.slice(-1)[0];
  const penultEl = splitName.slice(-2)[0];

  // Tariff Book 
  if (finalEl !== "copy" && penultEl !== "copy"){
    let newName = `${item.name} copy`;
    let i = 2 
    while (tariffBookNames.includes(newName)){
      newName = `${item.name} copy ${i}`
      i++
    }
    return newName;
  };

  // Tariff Book copy
  if (finalEl === "copy"){
    let newName = `${item.name} 2`; 
    let i = 3
    while (tariffBookNames.includes(newName)){
      newName = `${item.name} ${i}`
      i++
    }
    return newName;
  };

  // Tariff Book copy 2
  if (penultEl === "copy" && !isNaN(finalEl)){
    let i = parseInt(finalEl) + 1
    splitName.pop();
    let newName = [...splitName, i].join(" ");
    while (tariffBookNames.includes(newName)){
      newName = [...splitName, i].join(" ")
      i++
    }
    return newName;
  };

}

export default duplicatedTariffBookName;