import React, { useContext, useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, TextField, Tooltip } from '@material-ui/core';
import { contactFilterOptions } from '../../utils/sorters';
import AgentListItem from '../Agent/AgentListItem';
import { Account, Alert } from 'mdi-material-ui';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  alert: {
    position: "absolute",
    padding: "4px",
    right: "4rem",
    color: theme.palette.text.secondary
  },
}));

const ContactAutocomplete = ({ value, onChange, label, multiple, filter, errorOnEmpty, ...other }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { contacts } = useContext(DataStoreContext);
  const sortedContacts = contacts.filter(i => Boolean(i.name) && !i.deleted && (!filter || filter(i)));
  const startAdornment = multiple ? {} : {startAdornment: value && <Account color="primary" />}
  
  const emptyValue = useMemo(() => {
    if(!multiple && !value) return true;
    if(multiple && value && value.length === 0) return true;
    return false;
  }, [value, multiple]);
  
  return (
    <Autocomplete
      {...other}
      autoHighlight
      multiple={multiple}
      options={sortedContacts}
      filterOptions={contactFilterOptions}
      getOptionLabel={(item) => ((item && item.displayName) || item.name)}
      getOptionSelected={(option, value) => option.id === value.id}
      value={value || null}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <AgentListItem agent={item} />
      )}
      renderInput={props => (
        <TextField
          {...props}
          required={other.required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          error={errorOnEmpty && emptyValue}
          helperText={errorOnEmpty && emptyValue ? t('Contact.Error.ContactNotSpecified') : null}
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
          InputProps={{
            ...props.InputProps,
            ...startAdornment,
            endAdornment:
              <>
                {props.InputProps.endAdornment}
                {Boolean(value?.deleted) &&
                  <Tooltip title={t("Contact.Labels.DeletedHelp")}>
                    <Alert fontSize='small' className={classes.alert} />
                  </Tooltip>
                }
              </>
          }}
        />
      )}
    />
  );
};

export default ContactAutocomplete;
