import React, {
  useContext,
  useEffect,
  useState,
  useMemo, useCallback
} from 'react';
import {
  CardContent,
  Button,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';

import ContentIndex from '../../ContentIndex';
import PortCallTimeline from '../../PortCall/PortCallTimeline';
import AgentMovementEdit from './AgentMovementEdit';
import { NavigationContext } from '../../../contexts/navigation';
import { getPendingActionRequests, getActionTime, isAgentAssociatedWithPortCall } from '../../../utils/getters';
import Loading from '../../Loading';
import { DataStore } from 'aws-amplify';
import { ActionEventType, PortCall, ActionMovementType, Request } from '../../../models';
import isEqual from 'lodash.isequal';
import { ActionTypeIds } from '../../../environment';
import { sortActions } from '../../../utils/sorters';
import { ActionDetailsHref } from '../../../constants/ActionDetails';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import EditorContentTabs, { EditorContentTabIds, useEditorContentTab } from '../../Common/EditorContentTabs';
import RequestDetails from '../../Requests/RequestDetails';
import { buildRequestSource, getAgentContact } from '../../../utils/agentPortal';
import TextInputDialog from '../../Dialog/TextInputDialog';
import { RequestType } from '../../../models';
import { RequestState } from '../../../models';
import useFeatureSetting from '../../../hooks/useFeatureSetting';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  scrollPane: {
    height: '100%',
    overflow: 'auto',
  },
  rootPane: {
    display: "flex",
    flexFlow: "column",
    height: '100%',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    position: 'relative'
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  contentWrapper: {
    '& > :last-child': {
      marginBottom: "5rem"
    }
  }
}));

const AgentActionDetails = function ({ portCallId, actionId, requestId, portCallDetails, userContactId }) {
  const [navigationContext,] = useContext(NavigationContext);
  const portCallReference = useMemo(() => portCallDetails, [portCallDetails]);
  const [portCall, setPortCall] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [confirmSave, setConfirmSave] = useState({open: false, openFunction: null});
  const { t } = useTranslation();

  const indexTrees = useMemo(() => { 
    return {
      MOVEMENT: [
        {
          href: ActionDetailsHref.HREF_SCHEDULE,
          label: t('ActionDetails.Labels.Schedule')
        },
        {
          href: ActionDetailsHref.HREF_ATTACHMENTS,
          label: t('ActionDetails.Labels.Attachments'),
        },
        {
          href: ActionDetailsHref.HREF_VESSEL,
          label: t('ActionDetails.Labels.Vessel'),
          children: [
            {
              href: ActionDetailsHref.HREF_VESSEL_DETAILS,
              label: t('ActionDetails.Labels.VesselDetails'),
            },
          ]
        },
      ]
    }
  }, [t]);

  useEffect(() => {
    if (!portCallReference) return;
    
    // Take the user back to the root page if they are not assoicated with the port call
    if(!isAgentAssociatedWithPortCall(portCallReference, userContactId)) {
      history.push('/');
    }
 
    setPortCall(portCallReference);
  }, [portCallReference, setPortCall, userContactId, history]);

  const action = useMemo(() => portCall && portCall.actions.find(a => a.id === actionId, [portCall, actionId]));
  const setAction = useCallback(value => {
    setPortCall(PortCall.copyOf(portCall, updated => { updated.actions = [...portCall.actions.filter(a => a.id !== value.id), value]; }));
  }, [portCall, setPortCall]);

  const actionRequests = useMemo(() => getPendingActionRequests(portCall, action, userContactId), [portCall, action, userContactId]);
  const isMovement = action && action.type.id === ActionTypeIds.MOVEMENT;
  const [selectedRequest, setSelectedRequest] = useState();

  let contentsFilter;
  if(action)  {
    // Specify items we do no want to be included
    contentsFilter = [ActionDetailsHref.HREF_VESSEL_DETAILS];
    if (action.movementType !== ActionMovementType.ARRIVAL && action.movementType !== ActionMovementType.DEPARTURE) {
      contentsFilter = [...contentsFilter, ActionDetailsHref.HREF_VESSEL, ActionDetailsHref.HREF_ATTACHMENTS];
    }
  }

  const trySavePortCall = () => {
    const referenceAction = portCallReference.actions.find(a => a.id === action.id);
    // check if draught is changed
    if (referenceAction.vesselForwardDraught !== action.vesselForwardDraught ||
      referenceAction.vesselAftDraught !== action.vesselAftDraught ||
      referenceAction.vesselSailingDraught !== action.vesselSailingDraught ||
      referenceAction.vesselAirDraught !== action.vesselAirDraught) {
      setOpenUpdateDraught(true);
    } else {
      handleSavePortCall();
    }
  };


  const handleSavePortCall = async (remark) => {
    // may need to save multiple actions
    for (let action of portCall.actions) {
      if (!isEqual(action, portCallReference.actions.find(a => a.id === action.id))) {
        DataStore.save(action);
        if (action.id === actionId && openUpdateDraught) {
          const agent = await getAgentContact(userContactId);
          const requestSource = await buildRequestSource(agent);
          // check if a pending notification (request) exists
          const pendingNotification = (await DataStore.query(Request, c => c
            .requestPortCallId_("eq", portCall.id)
            .state("eq", RequestState.REQUEST_STATE_NOTIFICATION).type("eq", RequestType.REQUEST_TYPE_UPDATE_ACTION_DRAUGHT))
          ).pop();
          if (pendingNotification) {
            // update remark
            await DataStore.save(Request.copyOf(pendingNotification, updated => {
              updated.remark = remark;
              updated.updatedAt = new Date().toISOString();
            }));
          } else {
            // create new notification
            await DataStore.save(new Request({
              portCall: portCall,
              requestPortCallId_: portCall.id,
              state: RequestState.REQUEST_STATE_NOTIFICATION,
              type: RequestType.REQUEST_TYPE_UPDATE_ACTION_DRAUGHT,
              source: requestSource,
              portCallData: {
                vesselName: portCall.vesselData.name,
                vesselImo: portCall.vesselData.imo,
                vesselMmsi: portCall.vesselData.mmsi,
                vesselCallsign: portCall.vesselData.callSign
              },
              actionData: [{ actionId }],
              agent,
              remark
            }))
          }
          setOpenUpdateDraught(false);
        }
      }
    }
  };

  const saveDisabled = useMemo(() =>
    !portCall ||
    !portCallReference ||
    // for now compare only actions
    isEqual([...portCall.actions].sort(sortActions), [...portCallReference.actions].sort(sortActions)) || // isEqual does not ignore ordering; child components may be setting actions out of order
    (action && action.eventType === ActionEventType.AGENT_HANDOVER && (!getActionTime(action) || !action.actionAgent)),
    [portCall, portCallReference, action]);

  const checkSaveState = () => {
    setConfirmSave({open: true}) ;
  }

  const [openUpdateDraught, setOpenUpdateDraught] = useState(false);

  const tab = useEditorContentTab();

  // return by timeout if item gets deleted and results in empty view
  useEffect(() => {
    let timeout;
    if (!portCall || !action) {
      timeout = setTimeout(() => {
        history.push(location.pathname.substring(0, location.pathname.indexOf("/action") || undefined));
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [portCall, action, history, location]);

  // add crew list to content index (feature)
  const isCrewListSubmissionEnabled = useFeatureSetting('crew-list-submission');
  const movementIndexTree = !isCrewListSubmissionEnabled ? indexTrees["MOVEMENT"] : [
    ...indexTrees["MOVEMENT"],
    {
      href: ActionDetailsHref.HREF_FAL5_CREW_LIST,
      label: t('FileTypes.Labels.Fal5CrewList'),
    }
  ];

  const classes = useStyles();
  if (!portCall || !action) return <Loading />
  return (
    <Grid container>
      <Grid
        className={`${classes.scrollPane} ${classes.greyPanel}`}
        item
        xs={5}
      >
        <PortCallTimeline 
          portCall={portCall} 
          portCallReference={portCallReference}
          selectedRequest={selectedRequest} 
          movementTypeFilter={[ActionMovementType.ARRIVAL, ActionMovementType.DEPARTURE, ActionMovementType.SHIFT_ARRIVAL, ActionMovementType.SHIFT_DEPARTURE]}
          portCallReferece={portCallReference}
          disableLifeCycle
          saveDisabled={saveDisabled}
        />
      </Grid>

      <Grid
        className={classes.rootPane}
        item
        xs={7}
      >
        <CardContent style={{flexShrink: 0}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <EditorContentTabs requests={actionRequests} />
            <Box position="absolute" right="2rem" display="flex">
              <Typography>
                <Link
                  className={classes.linkButton}
                  to={navigationContext.defaultView}
                >
                  {!saveDisabled ? t('Common.Buttons.DiscardChanges') : t('Common.Buttons.Close')}
                </Link>
              </Typography>
              {tab === EditorContentTabIds.DETAILS &&
              <Button
                onClick={trySavePortCall}
                color="primary"
                variant="contained"
                disabled={saveDisabled}
              >
                {t('Common.Buttons.Save')}
              </Button>
              }
            </Box>
          </Grid>
        </CardContent>
        {action && tab === EditorContentTabIds.DETAILS && 
        <Grid
          item
          container
          style={{flexWrap: "noWrap"}}
        >
          {
            action && action.type && indexTrees[action.type.name.toUpperCase()] &&
            <CardContent style={{flexShrink: "0"}}>
              { isMovement && <ContentIndex tree={movementIndexTree} filter={contentsFilter} containerId="ActionDetailsContent" /> }
            </CardContent>
          }
          <Box flexGrow="1" overflow="auto" height="100%" id="ActionDetailsContent" className={classes.contentWrapper}>
          {
            action.type.id === ActionTypeIds.MOVEMENT &&
            <AgentMovementEdit
              portCall={portCall}
              setPortCall={setPortCall}
              action={action}
              setAction={setAction}
              setSelectedRequest={setSelectedRequest}
              checkSaveState={checkSaveState}
              saveDisabled={saveDisabled}
            />
          }
          </Box>
        </Grid>
        }
        {tab === EditorContentTabIds.REQUESTS && 
          <Grid
            className={classes.scrollPane}
            item
          >
            {actionRequests.map(r => <RequestDetails request={r} key={r.id} portCall={portCall} />)}
          </Grid>
        }
        <ConfirmDialog
          open={confirmSave.open}
          title={t('ActionDetails.Labels.UnsavedChangesMessage')}
          message={t('ActionDetails.Labels.UnsavedChangesMessage')}
          onClose={() => {setConfirmSave({open:false})}}
          cancelText={t('Common.Buttons.Close')}
        />
        {openUpdateDraught && <TextInputDialog
          open={openUpdateDraught}
          title={t('ActionDetails.Labels.ConfirmDraught')}
          onClose={() => setOpenUpdateDraught(false)}
          onConfirm={handleSavePortCall}
          inputLabel={t('ActionDetails.Labels.Remarks')}
        />}
      </Grid>
    </Grid >
  );
};

export default AgentActionDetails;