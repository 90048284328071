import React, { useState, useEffect, useContext, useRef } from 'react';
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Typography, ListItem, ListItemSecondaryAction, Button, LinearProgress } from "@material-ui/core";
import VesselListItem from '../Vessel/VesselListItem';
import ActionStateAndTimeListItem from '../Action/ActionStateAndTimeListItem';
import PortCallTimelinePortCallMenu from './PortCallTimelinePortCallMenu.js';
import { makeStyles } from '@material-ui/styles';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import VesselTooltip from '../Tooltip/VesselTooltip';
import { Email, Export } from 'mdi-material-ui';
import { PortCallStatusLabelsKeys } from '../../constants/PortCallStatus';
import { NavigationContext, navigationActionConstants } from '../../contexts/navigation';
import { UIContext } from '../../contexts/ui';
import RequestTooltip from '../Tooltip/RequestTooltip';
import { itemRequested, RequestColor } from '../../constants/RequestConstants';
import { itemApprovalPending, itemApprovalDeclined } from '../../constants/ApprovalConstants';
import { PortCallStatus, Vessel, RequestType, PortCallTag, ActionState, Category, Location, ActionMovementType } from '../../models';
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import ExportPortCallsDialog from './Export/ExportPortCallsDialog';
import ExportPortCallsDialogDS from './Export/ExportPortCallsDialogDS';
import { getActionArrival, getActionDeparture } from '../../utils/getters';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import RouteConstants from '../../constants/RouteConstants';
import DataBrowserSidebar, { DataBrowserPortCallStatusFilter, DataBrowserPortCallSortDirectionFilter, DataBrowserLocationFilter, DataBrowserVesselFilter, selectablePortCallStatuses, DataBrowserDateFilter, DataBrowserCategoryFilter, DataBrowserTagsFilter, isUncategorizedCategory, getUncategorizedCategory } from '../DataBrowserSidebar/DataBrowserSidebar';
import useDataBrowserFilter from '../DataBrowserSidebar/useDataBrowserFilter';
import { buildPortCallBrowserQuery } from './utils';
import gql from 'graphql-tag';
import useFilterPortCalls from '../../hooks/useFilterPortCalls';
import usePortCalls from '../../hooks/usePortCalls';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import { ActionTypeIds } from '../../environment';
import { SORT_DIRECTION } from '../../utils/sorters';
import FeatureFlags from '../../constants/FeatureFlags';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  item: {
    '&:hover': {
      background: theme.palette.action.hover,
      cursor: 'pointer',
    },
    borderBottom: "0.0625rem solid #eee",
    boxSizing: "border-box"
  },
  itemCancelled: {
    '& > *': {
      opacity: 0.5
    }
  },
  itemRequested,
  itemApprovalPending,
  itemApprovalDeclined
}));


const agentFilterOptions = {
  key: `smartport::preferences::PortCallList`,
  initialValue: {
    location: null,
    from: null,
    to: null,
    vessel: null,
    sortDirection: SORT_DIRECTION.DESC,
    status: selectablePortCallStatuses
  },
  hydrateFunctions: {
    location: async i => await DataStore.query(Location, i.id),
    from: i => new Date(i),
    to: i => new Date(i),
    vessel: async i => await DataStore.query(Vessel, i.id),
    sortDirection: i => i,
    status: i => i,
  }
}

const filterOptions = {
  ...agentFilterOptions,
  initialValue: {
    ...agentFilterOptions.initialValue,
    tags: [],
    category: null,
  },
  hydrateFunctions: {
    ...agentFilterOptions.hydrateFunctions,
    tags: async i => i?.length > 0 ? await DataStore.query(PortCallTag, c => c.or(c => i.reduce((acc, cur) => acc.id("eq", cur.id), c))) : [],
    category: async i => isUncategorizedCategory(i) ? getUncategorizedCategory() : await DataStore.query(Category, i.id),
  }
}

const Sidebar = ({ filter, setFilter, items, loading, numItems, itemsFiltered }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const [showExport, setShowExport] = useState(false);
  const isPortCallExportEnabled = useFeatureSetting(FeatureFlags.PORTCALL_EXPORT);
  return (
    <DataBrowserSidebar
      label={t('PortCallList.Labels.PortCalls')}
      exportButton={
        <Button id="OpenExportPortCallsButton" onClick={() => setShowExport(true)} endIcon={<Export fontSize="small" />} disabled={!itemsFiltered.length} disableRipple>
          <Typography variant="caption" style={{ lineHeight: "initial" }}>{t("Common.Buttons.Export")}</Typography>
        </Button>
      }
    >
      <DataBrowserPortCallStatusFilter id="portcalllist-filter-status" filter={filter} setFilter={setFilter} />
      <DataBrowserPortCallSortDirectionFilter id="portcalllist-filter-sortdirection" filter={filter} setFilter={setFilter} />
      <DataBrowserLocationFilter id="portcalllist-filter-location" filter={filter} setFilter={setFilter} />
      <DataBrowserDateFilter id="portcalllist-filter-from" name="from" label={t('PortCallList.Labels.From')} filter={filter} setFilter={setFilter} />
      <DataBrowserDateFilter id="portcalllist-filter-to" name="to" label={t('PortCallList.Labels.To')} filter={filter} setFilter={setFilter} isEndOfDay />
      <DataBrowserVesselFilter id="portcalllist-filter-vessel" filter={filter} setFilter={setFilter} />
      {!agentPortal && <>
        <DataBrowserCategoryFilter id="portcalllist-filter-category" filter={filter} setFilter={setFilter} />
        <DataBrowserTagsFilter id="portcalllist-filter-tags" filter={filter} setFilter={setFilter} />
      </>}
      {loading ? <LinearProgress /> : null}
      {(!loading && numItems) ?
        <Typography variant="caption" id="PortCallListSearchResult" >
          {t('PortCallList.Labels.SearchResult', { rowCount: numItems })}
        </Typography> :
        null }
      { isPortCallExportEnabled ? 
      <ExportPortCallsDialog open={showExport} onClose={() => setShowExport(false)} portCalls={itemsFiltered} filter={filter}/>
      : <ExportPortCallsDialogDS open={showExport} onClose={() => setShowExport(false)} portCalls={itemsFiltered} />}
    </DataBrowserSidebar>
  );
};

const PortCallBrowser = () => {
  const [{ agentPortal }] = useContext(UIContext);
  const [filter, setFilter] = useDataBrowserFilter(agentPortal ? agentFilterOptions : filterOptions);
  const [, dispatchNavigationContext] = useContext(NavigationContext);
  const [initialDataStoreLoad, dataStoreItems] = usePortCalls();
  const [allPortCalls, setAllPortCalls] = useState(dataStoreItems);
  const [appSyncItems, setAppSyncItems] = useState([]);

  const isFirstRender = useRef(true);
  const [isArchiveItemsLoading, setIsArchiveItemsLoading] = useState(false);

  useEffect(() => {
    if (initialDataStoreLoad)
      setAllPortCalls(dataStoreItems);
    else
      setAllPortCalls([...dataStoreItems, ...appSyncItems]);
  }, [dataStoreItems, appSyncItems, initialDataStoreLoad]);

  useEffect(() => {
    const promiseAll = [];
    if (isFirstRender.current) {

      setIsArchiveItemsLoading(true);
      const PortCallFetchLimit = 800;

      const update = async () => {
        var t0 = performance.now();
        let nextLastToken = null;
        let queryError = false;
        let queryItems = [];

        // Query all portcalls untils nextToken is null
        do {
          try {
            const query = buildPortCallBrowserQuery({ limit: PortCallFetchLimit, nextToken: nextLastToken });
            const promise = API.graphql(graphqlOperation(gql(query)));
            promiseAll.push(promise);
  
            const result = await promise;
            const { items, nextToken } = result?.data?.listPortCalls;
            nextLastToken = nextToken;
  
            if (items?.length) {
              const updatedItems = items
                .filter((p) => p.finalArrivalPlannedTime || p.finalDeparturePlannedTime)
                .map((p) => {
                  const actions = [];
                  // Create placeholder actions from data in port call record
                  if (p.finalArrivalPlannedTime) actions.push({
                    type: { id: ActionTypeIds.MOVEMENT, lifecycle: true },
                    state: p.status === PortCallStatus.CANCELLED || !p.finalArrivalActualTime ? ActionState.PLANNED : ActionState.COMPLETED,
                    movementType: ActionMovementType.ARRIVAL,
                    timeRequested: p.finalArrivalRequestedTime,
                    timePlanned: p.finalArrivalPlannedTime,
                    timeActual: p.finalArrivalActualTime,
                    movementLocation: { portUnlocode: p.portUnlocode },
                    portCall: p
                  });
                  if (p.finalDeparturePlannedTime) actions.push({
                    type: { id: ActionTypeIds.MOVEMENT, lifecycle: true },
                    state: p.status === PortCallStatus.CANCELLED || !p.finalDepartureActualTime ? ActionState.PLANNED : ActionState.COMPLETED,
                    movementType: ActionMovementType.DEPARTURE,
                    timeRequested: p.finalDepartureRequestedTime,
                    timePlanned: p.finalDeparturePlannedTime,
                    timeActual: p.finalDepartureActualTime,
                    movementLocation: { portUnlocode: p.portUnlocode },
                    portCall: p
                  });
                  return { ...p, actions: actions, requests: [] }
                });
  
              queryItems = queryItems.concat(updatedItems);
            }  
          }
          catch(error) {
            // Error from AppSync or due to cancellation of promise
            queryError = true;
            break;
          }
        } while (nextLastToken);

        var t1 = performance.now();
        console.log(`Queried Archived PortCallList in ${Math.floor(t1 - t0)} milliseconds.`);

        if(queryError) return;

        // Update port call list once all data has been received.
        setAppSyncItems(queryItems)
        setIsArchiveItemsLoading(false);
      };
      update();
    }

    return () => {
      console.log('Cancelling all query promises')
      promiseAll.forEach(promise => API.cancel(promise));
      isFirstRender.current = false;
    }
  }, []);

  // set last main view
  useEffect(() => {
    dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: RouteConstants.PORTCALL_LIST });
  }, [dispatchNavigationContext]);

  const itemsFiltered = useFilterPortCalls(allPortCalls, filter);

  // NOTE: All port calls are displayed, but only datastore items are passed to sidebar for export
  return (
    <Box id={"PortCallList"} display="flex" width='100%'>
      <Sidebar filter={filter} setFilter={setFilter} items={dataStoreItems} loading={isArchiveItemsLoading} numItems={itemsFiltered.length} itemsFiltered={itemsFiltered}/>
        <ListInternal itemsFiltered={itemsFiltered} filter={filter} isArchiveItemsLoading={isArchiveItemsLoading} />
    </Box >
  );
};

const Item = ({ item, style, classes, index }) => {
  const { t } = useTranslation();
  const arrival = getActionArrival(item.actions);
  const departure = getActionDeparture(item.actions);

  const portCallRequest = item.requests.find(r => r.type === RequestType.REQUEST_TYPE_CREATE_PORTCALL || r.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL);
  const arrivalRequest = !portCallRequest && arrival && item.requests.find(r => r.actionData && r.actionData.some(ad => ad.actionId === arrival.id));
  const departureRequest = !portCallRequest && departure && item.requests.find(r => r.actionData && r.actionData.some(ad => ad.actionId === departure.id));
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const approvalClass =
    arrival && (arrival.state === ActionState.APPROVAL_PENDING ? classes.itemApprovalPending : arrival.state === ActionState.APPROVAL_DECLINED ? classes.itemApprovalDeclined : null) ||
    departure && (departure.state === ActionState.APPROVAL_PENDING ? classes.itemApprovalPending : departure.state === ActionState.APPROVAL_DECLINED ? classes.itemApprovalDeclined : null);

  return (
    <div data-id={`${item.id}`} id={`PortCallListItem-${index}`} className={`${classes.item} ${item.status === PortCallStatus.CANCELLED ? classes.itemCancelled : ''}`} style={{ ...style, display: 'flex', justifyContent: 'space-betwen', alignItems: 'center' }}>
      <ListItem style={{ width: '25%' }}>
        <Typography style={{ paddingRight: '.5rem' }} noWrap>{item.referenceId}</Typography>
        {portCallRequest && <PopoverTooltip tooltip={<RequestTooltip portCall={item} request={portCallRequest} />} style={{ width: 'auto' }}>
          <Email style={{ color: RequestColor.color }} />
        </PopoverTooltip>}
      </ListItem>
      <ListItem style={{ width: '25%' }}>
        <Typography>{t(PortCallStatusLabelsKeys[item.status])}</Typography>
      </ListItem>
      <PopoverTooltip tooltip={<VesselTooltip vesselData={item.vesselData} portCall={item} setMapDialogOpen={setMapDialogOpen}/>}>
        <VesselListItem
          disableGutters={true}
          vesselData={item.vesselData}
          disabled={!item.portCallVesselId && !item.portCallVesselId_}
        />
      </PopoverTooltip>
      {arrival
        ? <ActionStateAndTimeListItem action={{ ...arrival, portCall: item }} request={arrivalRequest} style={{ width: '50%' }} />
        : <Typography style={{ width: '50%', opacity: 0.5 }}>{t('PortCallList.Labels.ArrivalNotScheduled')}</Typography>
      }
      {departure
        ? <ActionStateAndTimeListItem action={{ ...departure, portCall: item }} request={departureRequest} style={{ width: '50%' }} />
        : <Typography style={{ width: '50%', opacity: 0.5 }}>{t('PortCallList.Labels.DepartureNotScheduled')}</Typography>
      }
      <ListItemSecondaryAction>
        <PortCallTimelinePortCallMenu
          skipCheck
          portCall={item}
        />
      </ListItemSecondaryAction>
      {item.requests.length ? <div className={classes.itemRequested} /> : approvalClass ? <div className={approvalClass} /> : null}
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={item.vesselData}
        /> 
      }    
    </div>
  );
};

const ListInternal = ({ itemsFiltered, isArchiveItemsLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexGrow="1" id="PortCallBrowserList">
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            itemCount={itemsFiltered.length + 1} // extra row to hold reached end message
            height={height}
            itemSize={80}
            width={width}
          >
            {({ index, style }) =>
              (index === itemsFiltered.length) ? (
                <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" style={style}>
                  {
                    !isArchiveItemsLoading &&
                    <Typography variant="caption" align="center">
                      {t('PortCallList.Labels.NoMoreData')}
                    </Typography>
                  }
                </Box>
              ) : (
                <Item classes={classes} item={itemsFiltered[index]} index={index} style={style} />
              )
            }
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>

  );
};

export default PortCallBrowser;
