import React, { useEffect, useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import BerthListItem from '../Berth/BerthListItem';
import { locationFilterOptions } from '../../utils/sorters';
import { useContext } from 'react';
import { DataStoreContext } from '../../contexts/dataStoreContext';

const LocationAutocomplete = ({ value, onChange, startAdornment, label, filter, disabled, autoSelectSingle = true, helperText = null, ...other }) => {
  const locations = useContext(DataStoreContext).locations;
  const locationsFiltered = useMemo(() => locations.filter(l => l.allocatable && (!filter || filter(l))), [filter, locations]);
  useEffect(() => {
    if(autoSelectSingle) {
      if (!value && locationsFiltered.length === 1) {
        onChange(locationsFiltered[0]);
      }
    }
  }, [autoSelectSingle, value, onChange, locationsFiltered]);

  return (
    <Autocomplete
      autoHighlight
      disabled={disabled || (locationsFiltered.length === 1 && autoSelectSingle)}
      options={locationsFiltered}
      filterOptions={locationFilterOptions}
      getOptionLabel={(item) => (item && item.name)}
      getOptionSelected={(option, value) => option?.id === value?.id}
      value={value || null}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <BerthListItem
          item={item}
          parent={false}
          treeIndent={item.path && item.path.split('/').length}
        />
      )}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment
          }}
          helperText={helperText}
        />
      )}
      {...other}
    />
  );
};
export default LocationAutocomplete;