import { useContext, useCallback } from 'react'
import { UIContext } from '../contexts/ui';
import { ActionState } from '../models';
import useApprovalSetting from './useApprovalSetting'

/** 
 * Get a utility getter to get the action state based on the approval setting and user role
 * returns getInitialActionState getter that takes the vessel id as a pramater. 
*/
const useInitialActionState = () => {
  const [user, ] = useContext(UIContext);
  const { appApprovalEnabled, approvalExceptionList} = useApprovalSetting()
  
  //This function returns an action state with value APPROVAL_PENDING or PLANNED 
  //based on the approval setting, the vessel exception list and user role
  const getInitialActionState = useCallback((vesselId) => {
    const initialActionState = appApprovalEnabled && !(user.isApprover) ? ActionState.APPROVAL_PENDING : ActionState.PLANNED
    if(!vesselId) {
      return initialActionState;
    }
    return appApprovalEnabled && approvalExceptionList?.find(id => vesselId === id) ? ActionState.PLANNED : initialActionState
  }, [user, appApprovalEnabled, approvalExceptionList])

  return { 
    getInitialActionState
  }
}

export default useInitialActionState