import { DataStore } from 'aws-amplify';
import { TodoStatus } from "../constants/TodoStatus";
import { ActionTypeIds, arrivalChecklistTypes, departureChecklistTypes } from "../environment";
import {
  RequestType,
  RequestState,
  ActionEventType,
  ActionMovementType,
  ActionState,
  TodoTypeTemplate,
  PortCallStatus,
  LocationType
} from "../models";
import { sortActions, sortActionsReverse } from "./sorters";
import { ActionMovementTypeLabelKeys } from '../constants/ActionMovementTypeLabel'
import {
  capitalize,
  lowercase
} from '../utils/utils';
import {
  TodoStatus as ts,
} from '../constants/TodoStatus';
import SettingConstants from "../constants/SettingConstants";
import { ContactTypeKeys } from "../constants/ContactTypeConstants";
import { isBefore } from 'date-fns';
import { NonLifeCycleActionStates, InactiveActionStates, InoperativeActionStates, InactivePortCallStatus } from '../constants/StateCategories';

/*
  Utilities for accessing various data from models
*/

export const PortCallStatusToActionState = {
  [PortCallStatus.CANCELLED]: ActionState.CANCELLED,
  [PortCallStatus.DELETED]: ActionState.DELETED,
}

export const isActionAnyArrival = (action) => action && action.movementType && (action.movementType === ActionMovementType.ARRIVAL || action.movementType === ActionMovementType.SHIFT_ARRIVAL);

export const isActionAnyDeparture = (action) => action && action.movementType && (action.movementType === ActionMovementType.DEPARTURE || action.movementType === ActionMovementType.SHIFT_DEPARTURE);

/** gets action timeActual when available, falls back to timePlanned */
export const getActionTime = (action) => action ? action.timeActual || action.timePlanned : null;

/** gets first arrival movement */
export const getActionArrival = (actions) => actions && actions?.find(a => a.movementType === ActionMovementType.ARRIVAL);

/** gets final departure movement */
export const getActionDeparture = (actions) => actions && actions
  .find(a => 
    a.movementType === ActionMovementType.DEPARTURE &&
    !NonLifeCycleActionStates.includes(a.state)
  );

/** gets previous action */
export const getActionPrev = (actions, action) => actions && actions
  .filter(a => getActionTime(a) < getActionTime(action))
  .sort(sortActions)
  .pop();

/** gets previous action that was actionable */
export const getActionablePrev = (actions, action) => actions && actions
  .filter(a => getActionTime(a) < getActionTime(action))
  .filter(a => a.state === ActionState.COMPLETED || a.state === ActionState.IN_PROGRESS)
  .filter(a => !a.eventType)
  .filter(a => a.type.lifecycle)
  .sort(sortActions)
  .pop();

/** gets next action */
export const getActionNext = (actions, action) => actions && actions
  .filter(a => getActionTime(a) > getActionTime(action))
  .sort(sortActionsReverse)
  .pop();

/** gets next actionable action */
export const getActionableNext = (actions, action) => actions && actions
  .filter(a => getActionTime(a) > getActionTime(action))
  .filter(a => !InactiveActionStates.includes(a.state))
  .filter(a => !a.eventType)
  .filter(a => a.type.lifecycle)
  .sort(sortActionsReverse)
  .pop();

/** gets first action */
export const getFirstAction = (actions) => actions && [...actions].sort(sortActionsReverse).pop();

/** gets next valid actionble actions filtering out event types and movements that don't have a lifecycle */
export const getNextActionableAction = (actions) => actions && actions
  .filter(a => !InactiveActionStates.includes(a.state))
  .filter(a => !a.eventType)
  .filter(a => a.type.lifecycle)
  .sort(sortActionsReverse)
  .pop();

/** gets next undoable action */
export const getNextUndoableActions = (actions) => actions && actions
  .filter(a => a?.type?.lifecycle)
  .filter(a => a.state === ActionState.COMPLETED || a.state === ActionState.IN_PROGRESS)
  .sort(sortActionsReverse)

/** get the last action that's in progress or complete  */
export const getNextUndoableAction = (actions) => getNextUndoableActions(actions).pop();

/** gets last arrival or shift arrival */
export const getActionLastArrival = (actions) => actions && actions
  .filter(a => !NonLifeCycleActionStates.includes(a.state) &&
    (a.movementType === ActionMovementType.ARRIVAL || a.movementType === ActionMovementType.SHIFT_ARRIVAL))
  .sort(sortActions)
  .pop();

/** gets last cancelled departure */
export const getLastCancelledDeparture = (actions) => actions && actions 
  .filter(a => a.movementType === ActionMovementType.DEPARTURE && a.state === ActionState.CANCELLED)
  .sort(sortActions)
  .pop();

/** get last action with lifecycle */
export const getActionLastLifecycle = (actions) => actions && actions
  .filter(a => a.type && a.type.lifecycle)
  .filter(a => !NonLifeCycleActionStates.includes(a.state))
  .sort(sortActions)
  .pop();

/** gets last completed arrival or shift arrival */
export const getActionLastCompletedArrival = (actions) => actions && actions
  .filter(a => a.state === ActionState.COMPLETED && (a.movementType === ActionMovementType.ARRIVAL || a.movementType === ActionMovementType.SHIFT_ARRIVAL))
  .sort(sortActions)
  .pop();

/** returns true if valid actions do not contain an arrival action */
export const isDepartureOnly = (actions) => {
  const validActions = actions?.filter(a => [ActionState.PLANNED, ActionState.IN_PROGRESS, ActionState.COMPLETED].includes(a.state));
  return validActions?.length && !validActions?.find(a => a.movementType === ActionMovementType.ARRIVAL) ? true : false; 
};

/** filters out requests, cancelled or deleted movements */
export const getActionValidMovements = (actions) => actions && actions
  .filter(a => a.type.id === ActionTypeIds.MOVEMENT && 
    !InoperativeActionStates.includes(a.state));

/** filters out non-active movements */
export const getActiveMovements = (actions) => actions && actions
  .filter(a => a.type.id === ActionTypeIds.MOVEMENT && 
    !InactiveActionStates.includes(a.state));

/** filters out requests, cancelled, deleted and non lifecycle */
export const getActionsValidLifecycle = (actions) => actions && actions
  .filter(a => a.type.lifecycle &&     
    !InoperativeActionStates.includes(a.state));

/** gets action's (or current if no action provided) active agent by resolving to a relevent AGENT_HANDOVER event */
export const getActionActiveAgent = (actions, action) => {
  const handover = actions && actions.filter(a => (a.eventType && a.eventType === ActionEventType.AGENT_HANDOVER) && (getActionTime(a) <= ((action && getActionTime(action)) || new Date().toISOString())))
    .sort(sortActions)
    .pop();
  return handover && handover.actionAgent;
};

/** get the pending request depending on the action */
/** if an agentId is provided then filter for requests relating to that agent id */
export const getPendingActionRequests = (portCall, action, agentId = "") => portCall && portCall.requests && action &&
  !InactivePortCallStatus.includes(portCall.status)
  ? portCall.requests.filter(
    ({ state, type, actionData, agent }) =>
      state === RequestState.REQUEST_STATE_PENDING &&
      actionData && actionData.some(ad => ad.actionId === action.id) &&
      (agentId ? agent && agent.id === agentId : true) &&
      (type === RequestType.REQUEST_TYPE_CANCEL_ACTION ||
        type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED ||
        type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED ||
        type === RequestType.REQUEST_TYPE_CREATE_DEPARTURE))
  : [];


/** get the pending requests for a port call */
export const getPendingPortCallRequests = (portCall) => portCall && portCall.requests && 
  !InactivePortCallStatus.includes(portCall.status)
  ? portCall.requests.filter(
    ({ state, type }) =>
      state === RequestState.REQUEST_STATE_PENDING &&
      (type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL || type === RequestType.REQUEST_TYPE_CREATE_PORTCALL))
  : []

// get pending requests, can filter by agentID
export const getPendingRequests = (portCall, requests, agentId = "") => requests &&
  !InactivePortCallStatus.includes(portCall.status)
  ? requests.filter(({ state, agent, requestPortCallId_ }) => state === RequestState.REQUEST_STATE_PENDING &&
    (agentId ? agent && agent.id === agentId : true) &&
    requestPortCallId_ === portCall.id)
  : [];

export const isAgentAssociatedWithPortCall = (portCall, contactId) => {
  return portCall && portCall.agents && portCall.agents.find(a => a.contactId === contactId);
};
export const isBookingAgent = (portCall, contactId) => {
  let first = portCall && portCall.agents && portCall.agents.map(el => el).sort((a, b) => a.time > b.time ? -1 : 1 ).pop();
  return first && first.contactId === contactId;
};

export const getFirstAgentHandover = (actions) => {
  const handover = actions && actions
    .filter(a => a.eventType === ActionEventType.AGENT_HANDOVER)
    .sort(sortActions)
    .pop();
  return handover && handover;
};

// assumes first entry is the booking agent
/** get outstanding todos - return true if any are in Ready State */
export const getOutstandingTodos = (action) => action
  && action.todos
  && action.todos.filter(item => item.status === ts.READY).length > 0;

/** check if there are requests of a specific type*/
/** takes in action and requestType from RequestType in models */
export const hasRequestTypeForAction = (portCall, actionId, requestType) => portCall && portCall.requests && portCall.requests.length > 0 && portCall.requests.some(({type, actionData}) => type === requestType && actionData && actionData.some(({actionId: a}) => actionId === a))

/** returns action data by movement type, useful in create port call requests */
export const getRequestActionDataByMovementType = (request, actions, movementType) => request && Array.isArray(actions) && Array.isArray(request.actionData) && request.actionData.find(ad => {
  const action = actions.find(a => a.id === ad.actionId);
  return action && 
  !NonLifeCycleActionStates.includes(action.state) &&
  action.movementType === movementType;
});

/** return the appropriate label for movement type if there is one */
/** else return the name of the action type */
/** lower - can decided whether to be lower case (true) or if the first letter is capitalised (false) */
export const getActionName = (t, action, lower = true) => {
  const isCustomAction = !action.movementType && !action.eventType;
  let typeName;
  if (action.movementType)
    typeName = t(ActionMovementTypeLabelKeys[action.movementType]);
  else if (action.eventType === ActionEventType.AGENT_HANDOVER)
    typeName = t('PortCallMenuItem.Labels.AgentHandover');
  else
    typeName = action.type?.name;
  typeName = lower ? lowercase(typeName) : capitalize(typeName);
  return isCustomAction ? t('Common.Labels.CustomAction', {actionName: typeName}) : typeName;
}

// TODO this doesnt sound like it should be here, create utils/generators.js?
/** generates todos from environemnt configuration */
export const getActionTodosAsync = async (action) => {
  const todoTypeTemplates = await DataStore.query(TodoTypeTemplate);
  // what the f is this
  const todoTypeTemplatesMap = todoTypeTemplates.reduce((accumulator, currentItem) => {
    if (!accumulator[currentItem.todoTypeName]) {
      accumulator[currentItem.todoTypeName] = {
        title: currentItem.todoTypeTitle,
        description: currentItem.todoTypeDescription,
      };
    }
    return accumulator;
  }, {});
  const checklistType = action.movementType === ActionMovementType.ARRIVAL ? arrivalChecklistTypes
    : action.movementType === ActionMovementType.DEPARTURE ? departureChecklistTypes
      : null;
  if(!checklistType) return null;
  return checklistType.reduce((acc, item) => {
    const newType = {
      ...todoTypeTemplatesMap[item],
      todoType: item,
      status: TodoStatus.READY
    }
    return [...acc, newType];
  }, []);
};

export const getAgentName = (contact) => {
  return contact.displayName ? contact.displayName : contact.name
};

/**
 * Get translated name of a contact type
 * @param {*} t i18n
 * @param {ContactType} contactType 
 */
export const getContactTypeName = (t, contactType) => ContactTypeKeys[contactType.id] ? t(ContactTypeKeys[contactType.id]) : contactType.name;

/** 
 * Finds and parses value of APP_PORTCALL_APPROVAL setting; use with DataStoreContext
 * returns {name: 'APP_PORTCALL_APPROVAL', value: { enabled: bool }} | null
*/
export const getPortCallApprovalSetting = (settings) => {
  return settings?.find(s => s.name === SettingConstants.APP_PORTCALL_APPROVAL);
};

/** 
 * Finds and parses value of APP_CURRENCY setting; use with DataStoreContext
 * returns { symbol: string, code: string, name: string} | null
*/
export const getPortCallCurrencySetting = (settings) => {
  const setting = settings && settings.find(s => s.name === SettingConstants.APP_CURRENCY);
  return setting ? JSON.parse(setting.value) : null;
};

/**
 * Returns the unit value from Setting records 
 * @param {Array} settings Array of Settings 
 * @returns {Object} Unit settings
 */
export const getUnitsSetting = (settings) => {
  const setting = settings && settings.find(s => s.name === SettingConstants.APP_UNITS);
  return setting ? JSON.parse(setting.value) : null;
};

/**
 * Returns the date/time value from Setting records 
 * @param {Array} settings Array of Settings 
 * @returns {Object} Unit settings
 */
export const getDateTimeSetting = (settings) => {
  const setting = settings && settings.find(s => s.name === SettingConstants.APP_DATE_TIME);
  return setting ? JSON.parse(setting.value) : null;
}

/**
 * Returns true if AuditLog is related to an actionId (by checking objectId and new.[actionData].actionId for matches)
 * NOTE: This may be expensive
 * @param {Object} log Array of Settings 
 * @param {String} actionId Action ID
 * @returns {Boolean} true if related
 */
export const IsAuditLogRelatedToActionId = (log, actionId) => {
  if (log.objectId === actionId) return true;
  // it is unfortunate but there is no better way of doing this right now
  try {
    const _new = log.new && JSON.parse(log.new);
    if (_new?.actionData?.length)
      return _new?.actionData.some(ad => ad.actionId === actionId);
    } catch {}
  return false;
}

/**
 * gets certificate name
 * @param {Object} certificate instance
 * Get translated name of a contact type
 * @param {*} t i18n
 * @returns {String} certificate name
 */
export const getCertificateName = (certificate, t) => {

  if (certificate?.typeCategoryName && certificate?.typeName) {
    return `${certificate.typeCategoryName}: ${certificate.typeName}`;
  }
  if (t && certificate?.referenceNumber) {
    return `${t("CertificateType.Labels.TypeNotSpecified")} (${
      certificate.referenceNumber
      })`;
  }
  return certificate?.typeCategoryName ?? certificate?.typeName ?? "";
};

/**
 * gets port call times
 * @param {Array} actions list of actions
 * @returns {Object} fromDate(arrival time), toDate(departure time). 
 * toDate refers to now if there is no departure
 */
export const getPortCallTimes = (actions) => {
  const fromDate = getActionTime(getActionArrival(actions))
  const toDate = getActionTime(getActionDeparture(actions)) || new Date().toISOString()
  if(isBefore(new Date(toDate), new Date(fromDate))){
    return {
      fromDate, toDate: fromDate
    }
  }
  return {
    fromDate, toDate
  }
}

/**
 * gets the unit label with secondary unit if available
 * @param {String} unitName The primary/main unit name
 * @param {String} secondaryUnitName The secondary unit name if available
 * @returns A string combining primary/secondary
 */
export const getFullTarrifUnit = (unitName, secondaryUnitName) => {
  let fullName = unitName;
  if(fullName && secondaryUnitName) {
    fullName += `/${secondaryUnitName}`;
  }
  return fullName;
}

/**
 * gets the custom action location from the appropriate movement location in the port call
 * @param {String} actionTime The time of the custom action's completion 
 * @param {Array} actions The actions associated with the custom action's port call 
 * @returns {Object} Location 
 */
export const getCustomActionLocation = (actionTime, actions) => {
  if(!actionTime) return;
  const validActions = actions.filter(a => a.type.id === ActionTypeIds.MOVEMENT
    && (a.state === ActionState.COMPLETED || a.state === ActionState.PLANNED || a.state === ActionState.IN_PROGRESS));
  const prevActions = validActions.filter(a => getActionTime(a) < actionTime);
  const validPrevAction = prevActions?.sort(sortActions).pop();
  if(!validPrevAction){
    const nextActions = validActions.filter(a => getActionTime(a) > actionTime);
    const validNextAction = nextActions.sort(sortActionsReverse).pop();
    return validNextAction?.movementLocation;
  }
  return validPrevAction?.movementLocation
}

/**
 * gets a locations map
 * @param {Array} locations An array of location objects
 * @returns {Map} A map of locations
 */
export const getLocationsMap = (locations) => {
  const locationsMap = new Map();
  locations.forEach(el => {
    locationsMap.set(el.id, el);
  });
  return locationsMap;
};

/**
 * gets the most parent location name for a given movementAction
 * @param {Action} action An action object
 * @param {Array} locations An array of location objects containing the `id` and `name` keys
 * @returns {String} Port name e.g: Montrose Port Authority
 */
export const getParentLocationName = (movementLocation, locationsMap) => {
  if (!movementLocation) return;
  if ((!movementLocation?.parent && !movementLocation.locationParentId)) {
    return movementLocation.name;
  }
  const parentLocation = getParentLocation(movementLocation, locationsMap)
  return parentLocation? parentLocation.name : movementLocation.name;
}
/**
  * Returns uppermost movementLocation id
  * @param {Location} movementLocation DataStore Location object
  * @param {Map} Map of id -> Location
  * @returns Location object
  */
export const getParentLocation = (movementLocation, locationsMap = new Map()) => {
  if (!movementLocation?.id || !locationsMap.size) return null;
  const parentId = movementLocation?.parent?.id || movementLocation?.locationParentId;
  return parentId ? 
    getParentLocation(locationsMap?.get(parentId), locationsMap) :
    locationsMap?.get(movementLocation?.id);
};

/** gets completed arrival */
export const getCompletedArrival = (actions) => {
  return actions?.find(a => a?.movementType === ActionMovementType.ARRIVAL && a?.state === ActionState.COMPLETED);
};