import React, { useCallback, useEffect, useState } from 'react';
import { Box, CardContent, FormControl, FormHelperText, FormControlLabel, FormGroup, Switch} from '@material-ui/core';
import Feature from '../Feature/Feature';
import VesselListEditor from '../Vessel/VesselListEditor';
import { validateVesselMmsi } from '../../utils/validators';
import { useTranslation } from 'react-i18next';

const AdminAutomationRule = ( { automationRule, onUpdate, disabled }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  const vesselListDisabled = automationRule?.allVessels || (disabled ?? false) || loading

  const handleAutomationRuleUpdate = useCallback((newList) => {
    setLoading(true);
    onUpdate(automationRule, {
      vessels: newList
    })
  }, [automationRule, onUpdate]);

  const handleAllVesselsChecked = useCallback((event) => {
    setLoading(true);
    onUpdate(automationRule, {
      allVessels: event?.target?.checked ?? false
    });
  }, [automationRule, onUpdate]);

  useEffect(() => {
    setLoading(false)
  }, [automationRule])

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
        <Box display="flex" flexDirection="column" flexGrow="1">
          <CardContent style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Feature flag='portcall-automation-all-vessel'>
              <FormControl component="div">
                <FormGroup>
                  <FormControlLabel
                    control={<Switch
                      checked={automationRule?.allVessels ?? false}
                      onChange={handleAllVesselsChecked}
                      />}
                    label={t('AdminAutomation.Labels.UseAllVessels')}
                    disabled={disabled}
                  />
                </FormGroup>
                <FormHelperText>{t('AdminAutomation.Labels.UseAllVesselsHelp')}</FormHelperText>
              </FormControl>
              <Box height="2rem" />
            </Feature>

            <Box display="flex" flexDirection="column" flexGrow="1">
              <VesselListEditor
                vesselList={automationRule?.vessels}
                getVesselValue={(v)=>v.mmsi}
                displayVesselValueIfNotFound={true}
                validateVesselValue={validateVesselMmsi}
                onUpdate={handleAutomationRuleUpdate}
                disabled={vesselListDisabled}
              />
            </Box>
          
          </CardContent>
        </Box>
      </Box>
  );
};

export default AdminAutomationRule;