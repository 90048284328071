import { View } from '@aws-amplify/ui-react';
import { Typography } from '@material-ui/core';
import { LOGIN_CONSTANTS } from '../constants';

export const ForgotPassWordHeader = () => {

  return (
    <View className="ForgotPassWordHeader">
      <Typography variant="h5">{LOGIN_CONSTANTS.Authentication.Common.Labels.ResetPassword}</Typography>
    </View>
  );
} 