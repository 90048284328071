import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";

/**
 * Simple confirmation dialog with two choices:
 * 1. Confirm, positive action
 * 2. Cancel, negative action.
 * 
 * Optionally a comment field can be added, the contents of which are
 * returned on the confirm callback. 
 */
const ConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const [, setOpen] = useState(props.open);
  const [comment, setComment] = useState(props.comment);

  const handleClose = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    // Callback to to the handleConfirm function passing in the comment
    props.handleConfirm(comment);
  }

  const handleChange = (event, newValue) => {
    // Update the internal state with latest comment value
    setComment(event.target.value);
  }

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
        {props.hasComment ?
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Comment"
          defaultValue={props.comment}
          type="text"
          multiline={true}
          minRows={3}
          fullWidth
          onChange={handleChange}
        />
        : ""}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button
          id="ConfirmDialogCancelButton"
          onClick={props.handleCancel} color="default"
        >
          {t('Common.Buttons.Cancel')}
        </Button>
        <Button
          id="ConfirmDialogConfirmButton"
          onClick={handleConfirm} color="primary"
        >
          {props.confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  hasComment: PropTypes.bool,
  comment: PropTypes.string,
  children: PropTypes.node,
  confirmLabel: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func
};

ConfirmationDialog.defaultProps = {
  open: false,
  title: "Dialog title missing",
  hasComment: false,
  comment: "",
  confirmLabel: "Confirm label missing",
}

export default ConfirmationDialog;