import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import VesselEditForm from './VesselEditForm';
import { PortCall, PortCallStatus, Vessel } from '../../models';
import { UIContext } from '../../contexts/ui';
import { DataStore } from 'aws-amplify';
import { InactivePortCallStatus } from '../../constants/StateCategories';

export const VesselEditPage = () => {
  const { portCallId, vesselId } = useParams();
  const history = useHistory();
  const [{agentPortal}] = useContext(UIContext);  
  const [vesselReference, setVesselReference] = useState(null);
  const [portCall, setPortCall] = useState(null);
  const [vessel, setVessel] = useState(null);
   
  useEffect(() => {
    if (!portCallId && !vesselId) return;
    (async () => {
      const _portCall = portCallId ? await DataStore.query(PortCall, portCallId) : undefined;
      const _vessel = await DataStore.query(Vessel, _portCall ? _portCall.vessel.id : vesselId);
      setVesselReference(Vessel.copyOf(_vessel, updated => {
        updated.vesselData = _portCall ? _portCall.vesselData : _vessel.vesselData;
      }));
      setVessel(_vessel);
      setPortCall(_portCall);
    })();
  }, [portCallId, vesselId, setVessel, setPortCall]);

  const handleOnUpdate = async (value) => {
    const p = portCall ? await DataStore.query(PortCall, portCall.id) : undefined;
    const isClosed = InactivePortCallStatus.includes(p?.status);
    // update only selected port call when closed
    let ps;
    if (isClosed) {
      ps = [p];
    } else {
      // otherwise query all open port calls
      ps = await DataStore.query(PortCall, c => c
        .portCallVesselId_("eq", vessel.id)
        .status("ne", PortCallStatus.CLOSED).status("ne", PortCallStatus.CANCELLED).status("ne", PortCallStatus.DELETED)
      );
    }
    ps.map(async p =>
      await DataStore.save(PortCall.copyOf(p, updated => {
        updated.vesselData = value.vesselData
      }))
    );
    history.goBack();
  };

  return (
    <>
      {vessel &&
        <VesselEditForm
          vessel={vessel}
          vesselReference={vesselReference}
          setVessel={setVessel}
          showTitle
          onBack={() => { history.goBack() }}
          onUpdate={handleOnUpdate}
          portCall={portCall}
          disabled={agentPortal}
        />
      }
    </>
  );
};

export default VesselEditPage; 
