import React from 'react';
import {
  Box,
  Badge,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { CheckCircle } from 'mdi-material-ui';
import { TodoStatus as ts } from '../../constants/TodoStatus';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import { IconMap } from './IconMap';

const useStyles = makeStyles(theme => {
  return {
    strikeThroughDone: {
      textDecoration: 'line-through'
    },
    smallBadge: props => ({
      fontSize: props.badgeSize,
      color: theme.palette.colors.green[500],
    }),
    badgeBackground: props => ({
      width: props.badgeSize,
      height: props.badgeSize,
      backgroundColor: props.paperClassName === 'tableEven' ? 'white' : '#efefef', 
      borderRadius: '50%',
    }),
    xsmallBadge: {
      '& > span': {
        transform: 'scale(0.75) translate(50%, 50%)'
      }
    },
    enabled: {
      cursor: "pointer"
    }
  }
});

const TodoIconWithPopover = ({
  clickHandler,
  item,
  backgroundColor = 'rgb(160, 160, 160)',
  paperClassName,
  xsmall = null,
  small = null,
  disabled
}) => {
  const props = {
    badgeSize: '1.25rem',
    paperClassName: paperClassName,
  };
  const classes = useStyles(props);
  return (
    <Box 
      marginRight={"0.333rem"}
      marginBottom="0.25rem"
      marginTop="0.25rem"
      flexShrink="0"
      className={!disabled && classes.enabled}
      onClick={() => {!disabled && clickHandler && clickHandler(item)}}
    >
      <PopoverTooltip key={item.id} tooltip={
        <ListItem>
          <ListItemText
            className={`${item.status === ts.DONE ? classes.strikeThroughDone : ''}`}
            primary={item.title}
            secondary={item.description}
          />
        </ListItem>
      }>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={item.status === ts.DONE ? <div className={classes.badgeBackground}><CheckCircle className={classes.smallBadge}/></div> : null}
          overlap='circular'
          className={`${xsmall ? classes.xsmallBadge : ''}`}
        >

          <IconMap
            item={item}
            xsmall={xsmall}
            small={small}
            backgroundColor={backgroundColor}
          />
        </Badge>
      </PopoverTooltip>
    </Box>
  )
};

export default TodoIconWithPopover;