import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
} from '@material-ui/core';
import ChargeablesTableView from './view/ChargeablesTableView';
import ChargeablesTableEdit from './edit/ChargeablesTableEdit';
import { Action } from '../../models';
import PropTypes from 'prop-types';
import {
  COLUMNS,
} from "../../constants/ChargeableItemConstants";
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { UIContext } from '../../contexts/ui';
import { getPortCallCurrencySetting, getActionName, getActionTime, getPortCallTimes } from '../../utils/getters';
import VesselListItem from '../../components/Vessel/VesselListItem';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';
import VesselTooltip from '../../components/Tooltip/VesselTooltip';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { DateFnsLanguageMap } from "../../translations";
import CertificateList from "../Certificate/CertificateList";
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import useTariffBooks from "../../hooks/useTariffBooks";
import SpotlightMapDialog from "../Spotlight/SpotlightMapDialog";

// Component for Tables that contain the chargeable items 
// setAction - Function to set the Action 
// action - The action model for which the chargeable items belong
// disabled - Boolean for whether the editor can be opened
// disableUnitCost - Boolean disable unit cost editing
const ChargeablesEditor = ({ 
  portCall,
  action, 
  setAction, 
  disabled,
  checkSaveState = () => { },
  saveDisabled = true,
  disableUnitCost
}) => {
  // deconstruct chargeableItems and actionTypeId from the action being used
  const { chargeableItems, type: { id: actionTypeId } } = action;

  // get the settings from DataStoreContext 
  const { settings, tariffs: initialTariffs, tariffUnits } = useContext(DataStoreContext);
  // get currency out of settings
  const currencySettings = useMemo(() => getPortCallCurrencySetting(settings), [settings]);
  // get currency symbol
  const currencySymbol = currencySettings ? currencySettings.symbol : "";  
  
  const { t, i18n } = useTranslation();
  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);
  const { tariffsForAction } = useTariffBooks();
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  // State to store the chargeable items if there are any
  // or to an empty array if none
  const [chargeableItemsArr, setChargeableItemsArr] = useState(chargeableItems ? chargeableItems : []);

  // State to store chargeableItemsArr before 
  // This is so that changes will not show up in read only table in background
  const [chargeableItemsUpdate, setChargeableItemsUpdate] = useState(chargeableItemsArr);

  // Update the chargeable items with the most current version
  useEffect(() => {
    if(!chargeableItems) {
      setChargeableItemsArr([])
      setChargeableItemsUpdate([])
    } else {
      setChargeableItemsArr(chargeableItems)
      setChargeableItemsUpdate(chargeableItems)
    }
  }, [chargeableItems, setChargeableItemsArr])

  let tariffs = [];
  if(isTariffBookEnabled) {
    const allTariffs = tariffsForAction(action.timePlanned, actionTypeId);
    tariffs = allTariffs.map(tariff => {
      return {
        ...tariff,
        unit: { ...tariffUnits.find(unit => unit.id === tariff.unitId) }
      }
    });
  } else {
    // The tariffs will be retrieved based on the actionTypeId if one is passed in
    // If there isn't one passed in all will be retrieved
    const origTariffs = actionTypeId ? initialTariffs.filter(t => t.data && t.data.actionTypes && t.data.actionTypes.includes(actionTypeId)) : initialTariffs;
    // Map into new format as if it had been selected from the tariff book
    tariffs = origTariffs?.map(tariff => {
      return {
        id: tariff?.id,
        cost: {
          ...tariff?.data
        },
        unitId: tariff?.unit?.id,
        unit: tariff?.unit
      }
    });
  }


  // State for open of the Dialog for the Editable Chargeables Table
  const [open, setOpen] = useState(false);

  // This function will save whatever is in chargeableItemsUpdate
  // Which stores any changes made to the object
  // To the action that was given
  // And then close the Editting Table
  const handleSaveAndClose = () => { 
    setAction(Action.copyOf(action, updated => {
      updated.chargeableItems = chargeableItemsUpdate 
    }));
    setOpen(false);
  }

  // Function will reset the update state variable
  // and close the dialog
  const handleClose = () => {
    setChargeableItemsUpdate(chargeableItemsArr);
    setOpen(false);
  }

  const handleOpen = () => {
    !saveDisabled ? checkSaveState() : setOpen(true);
  }
  
  const actionTime = getActionTime(action);

  // Returns a string of the action name and the state
  const portCallInformation = () => {
    if(action) {
      const actionName = getActionName(t, action, false);
      const actionTime = getActionTime(action);
      const actionDate = actionTime ? `${format(new Date(actionTime), 'HH:mm, d MMMM Y', { locale: DateFnsLanguageMap[i18n.language] })}` : "";
      return `${actionDate} ${actionName}`;
    }
    return "";
  };

  const portCallTimes = useMemo(() => getPortCallTimes(portCall?.actions), [portCall?.actions])

  return (
   <>
      {/* Read Only table of the chargeableItems */}
      <ChargeablesTableView
        chargeableItems={chargeableItemsArr}
        columns={COLUMNS}
        setOpen={handleOpen}
        currencySymbol={currencySymbol}
        disabled={disabled || readOnly}
        actionTime={actionTime}
      />
      {/* Dialog for the Chargeables Editting Table */}
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullWidth={true}
        maxWidth={false}
      >
        {/* Title and close for Editting Dialog */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {action && action.portCall && (
            <Box display="flex" alignItems="center">
              <Box width="auto" paddingLeft="24px" paddingRight="24px" paddingTop="8px">
                <PopoverTooltip tooltip={<VesselTooltip vesselData={action.portCall.vesselData} setMapDialogOpen={setMapDialogOpen}/>} delay={800}>
                  <VesselListItem
                    disableGutters={true}
                    vesselData={action.portCall.vesselData}
                    portCallId={action.portCall.referenceId}
                    disabled={!action.portCall.vesselData}
                    id={'ChargeablesVesselData'}
                  />
                </PopoverTooltip>
              </Box>
              <Box>
                <Typography variant="h6" style={{padding: "calc(8px + .25rem)", paddingLeft: '0', paddingBottom: 'calc(5px + .25rem)', alignSelf: "flex-start"}}>{portCallInformation()}</Typography>
                {portCall?.actions && <CertificateList certificates={action.portCall.vesselData.certificates}
                  fromDate={portCallTimes.fromDate}
                  toDate={portCallTimes.toDate}
                />}
              </Box>
            </Box>
          )}
          <Typography variant="h6" style={{padding: "calc(8px + .25rem)", paddingRight: 24, alignSelf: "flex-start", textTransform: "uppercase"}}>{`${t('ChargeableItems.Labels.Title')} (${chargeableItemsUpdate.length})`}</Typography>
        </Box>
        <DialogContent style={{paddingBottom: 24}}>
          {/* Editting Chargeables Table */}
          <ChargeablesTableEdit
            chargeableItems={chargeableItemsUpdate}
            tariffs={tariffs}
            columns={COLUMNS}
            saveChargeableItems={setChargeableItemsUpdate}
            handleSaveAndClose={handleSaveAndClose}
            handleClose={handleClose}
            currencySymbol={currencySymbol}
            disableUnitCost={disableUnitCost}
            actionTime={actionTime}
          />
        </DialogContent>
      </Dialog>
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={portCall.vesselData}
        /> 
      }
  </>
  );
}

// PropTypes for Chargeable table
ChargeablesEditor.propTypes = {
  action: PropTypes.any.isRequired,
  setAction: PropTypes.func,
  disabled: PropTypes.string,
  checkSaveState: PropTypes.func
}

// Default Props for Chargeable Table
ChargeablesEditor.defaultProps = {
  action: null,
  setAction: null,
  disabled: null,
}

export default ChargeablesEditor;