export const RouteConstants = {
  DEFAULT: '/',
  PORTCALL_LIST: '/portcalls',
  VESSEL_LIST: '/vessels',
  VOYAGE_LIST: '/voyages',
  VOYAGE: '/voyage',
  VOYAGE_SCHEDULE: '/voyage-schedule'
};

export const MOVEMENT_LIST_URL_PATTERN = /\/lists\/movements(.*)/;
export const MOVEMENT_ACTION_LIST_URL_PATTERN = /port-call\/[a-f\d-]+(?:\/\w+\/[a-f\d-]+)?/;
export const VOYAGE_DETAIL_URL_PATTERN =  /\/voyage\/[a-f\d-]+/;

export default RouteConstants;
