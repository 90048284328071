import { View,Text, useTheme } from '@aws-amplify/ui-react';
import { LOGIN_CONSTANTS } from '../constants';

export const Footer = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={tokens.colors.neutral[80]}>
        &copy; {LOGIN_CONSTANTS.Authentication.Common.Labels.AllRightsReserved}
      </Text>
    </View>
  );
}