import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, IconButton, TextField, Button, makeStyles, Popover } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import ShiftArrive from '../../icons/ShiftArrive';
import ShiftDepart from '../../icons/ShiftDepart';
import { Action, ActionMovementType, ActionState, PortCall, TemplateSubType, PortCallStatus } from '../../models';
import { DataStore } from 'aws-amplify';
import { sortActions, sortActionsReverse } from '../../utils/sorters';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import { getActionTime, getActionValidMovements, getActionDeparture, getActionLastArrival, getActionArrival } from '../../utils/getters';
import { isSameDateHourMinute } from '../../utils/generators';
import { add, isAfter, isBefore, parseISO } from 'date-fns'
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { ActionTypeIds } from '../../environment';
import LocationTimeline from '../Location/LocationTimeline';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useInitialActionState from '../../hooks/useInitialActionState';
import useAuditMeta from '../../hooks/useAuditMeta';
import { UIContext } from '../../contexts/ui';
import { useTranslation } from 'react-i18next';
import { createAllChargeablesItemFromBooks } from '../../utils/tariffBook';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import useTariffBooks from '../../hooks/useTariffBooks';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    border: 0
  },
  reopenPortCallMessage: {
    justifyContent: 'center',
    width: "100%"
  }
}));

const ShiftCreateDialog = ({ portCall, anchorEl, onClose }) => {
  const dataStoreContext = useContext(DataStoreContext);
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();
  const [uiContext,] = useContext(UIContext);
  const { agentPortal } = uiContext;
  const { t } = useTranslation();
  const { tariffBooks } = useTariffBooks();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);
  const departureTime = useMemo(() => portCall.actions && getActionTime(getActionDeparture(portCall?.actions)), [portCall.actions])

  const [shiftDeparture, setShiftDeparture] = useState(() => {
    // default to last arrival
    const defaultArrival = getActionLastArrival(portCall.actions);
    const timePlanned = add(new Date(getActionTime(defaultArrival)), { hours: 1 }).toISOString();
    // try to get template first
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_SHIFT_DEPARTURE);
    if (template) {
      // FIXME remove isTariffBookEnabled when feature flag removed
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(portCall?.vessel?.id), isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.movementLocation = defaultArrival.movementLocation;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: getInitialActionState(portCall?.vessel?.id),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.SHIFT_DEPARTURE,
        timePlanned,
        movementLocation: defaultArrival.movementLocation,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      });
    }
  });

  const [shiftArrival, setShiftArrival] = useState(() => {
    const timePlanned = add(new Date(shiftDeparture.timePlanned), { minutes: 30 }).toISOString();
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_SHIFT_ARRIVAL);
    if (template) {
      // FIXME remove isTariffBookEnabled when feature flag removed
      return Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(portCall?.vessel?.id), isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.portCall = portCall;
        updated.actionPortCallId_ = portCall.id;
        updated.auditMeta = auditMeta;
      });
    } else {
      return new Action({
        portCall,
        actionPortCallId_: portCall.id,
        state: getInitialActionState(portCall?.vessel?.id),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.SHIFT_ARRIVAL,
        timePlanned,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      })
    }
  });

  useEffect(() => {
    const newState = getInitialActionState(portCall?.vessel?.id)
    setShiftDeparture(Action.copyOf(shiftDeparture, updated => { updated.state = newState; }));
    setShiftArrival(Action.copyOf(shiftArrival, updated => { updated.state = newState; }));
  }, [getInitialActionState])

  // gets the the previous action to the shift departure
  const prevAction = useMemo(() => getActionValidMovements(portCall.actions)
    .filter(a => getActionTime(a) < getActionTime(shiftDeparture))
    .sort(sortActions)
    .pop(),
    [portCall.actions, shiftDeparture]);


  // gets the next action the shift arrival
  const nextAction = useMemo(() => getActionValidMovements(portCall.actions)
    .filter(a => getActionTime(a) > getActionTime(shiftArrival))
    .sort(sortActionsReverse)
    .pop(),
    [portCall.actions, shiftArrival]);

  // gets any action in between the shift depature and arrival
  const actionsBetween = useMemo(() => getActionValidMovements(portCall.actions)
    .filter(a => getActionTime(a) >= getActionTime(shiftDeparture) && getActionTime(a) <= getActionTime(shiftArrival)),
    [portCall.actions, shiftDeparture, shiftArrival]);

  // creates error if there is no valid time planned for shift departure
  // there is no previous action for the shift departure which is not an arrival or shift arrival
  // there is a next action which is completes or in progress
  const departureError = useMemo(() =>
    (Boolean(!shiftDeparture.timePlanned) && 'Invalid date format') ||
    (Boolean(!prevAction || !isAfter(parseISO(shiftDeparture.timePlanned), parseISO(getActionTime(prevAction))) || (departureTime && !isBefore(parseISO(shiftDeparture.timePlanned), parseISO(departureTime)))) && t('ShiftCreateDialog.Errors.ShiftDepartureAfterArrival')) ||
    (Boolean(nextAction && (nextAction.state === ActionState.COMPLETED || nextAction.state === ActionState.IN_PROGRESS)) && t('ShiftCreateDialog.Errors.ShiftDepartureBeforeCompleted')),
    [shiftDeparture.timePlanned, prevAction, departureTime, nextAction, t]);

  // creates error if there is no valid time planned for shift arrival
  // there are actions between or the shift arrival time planned is not after the shift departure time
  const arrivalError = useMemo(() =>
    (Boolean(!shiftArrival.timePlanned) && 'Invalid date format') ||
    (Boolean(actionsBetween.length || shiftArrival.timePlanned <= shiftDeparture.timePlanned || (departureTime && !isBefore(parseISO(shiftArrival.timePlanned), parseISO(departureTime)))
      || Boolean(nextAction && isSameDateHourMinute(getActionTime(nextAction)), shiftArrival.timePlanned)) && t('ShiftCreateDialog.Errors.ShiftAfterDeparture')),
    [shiftArrival.timePlanned, actionsBetween.length, shiftDeparture.timePlanned, departureTime, nextAction, t]);

  const createDisabled = useMemo(() => Boolean(departureError || arrivalError || !shiftArrival.movementLocation), [departureError, arrivalError, shiftArrival]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangeDepartureTime = value => {
    setShiftDeparture(Action.copyOf(shiftDeparture, updated => { updated.timePlanned = value; }));
    setShiftArrival(Action.copyOf(shiftArrival, updated => { updated.timePlanned = value ? add(new Date(value), { minutes: 30 }).toISOString() : null; }));
  };

  const handleChangeArrivalTime = value => {
    setShiftArrival(Action.copyOf(shiftArrival, updated => { updated.timePlanned = value; }));
  };

  const handleChangeArrivalLocation = value => {
    setShiftArrival(Action.copyOf(shiftArrival, updated => { updated.movementLocation = value; }));
  };

  const handleCreateShift = async () => {
    let departChargeableItems = shiftDeparture.chargeableItems;
    let arrivalChargeableItems = shiftArrival.chargeableItems;

    // FIXME remove if and set chargableItems when feature flag removed
    if (isTariffBookEnabled) {
      departChargeableItems = createAllChargeablesItemFromBooks(
        shiftDeparture.timePlanned,
        shiftDeparture.type.id,
        shiftDeparture.movementType,
        tariffBooks,
        dataStoreContext.tariffUnits);
      arrivalChargeableItems = createAllChargeablesItemFromBooks(
        shiftArrival.timePlanned,
        shiftArrival.type.id,
        shiftArrival.movementType,
        tariffBooks,
        dataStoreContext.tariffUnits);
    }
    await DataStore.save(Action.copyOf(shiftDeparture, updated => {
      updated.movementLocation = prevAction.movementLocation;
      updated.chargeableItems = departChargeableItems;
    }));
    await DataStore.save(Action.copyOf(shiftArrival, updated => {
      updated.chargeableItems = arrivalChargeableItems;
    }));
    if (nextAction && nextAction.type.id === ActionTypeIds.MOVEMENT) {
      await DataStore.save(Action.copyOf(nextAction, updated => {
        updated.movementLocation = shiftArrival.movementLocation;
      }));
    };
    if (portCall.status === PortCallStatus.CLOSED) {
      await DataStore.save(PortCall.copyOf(portCall, updated => {
        updated.status = PortCallStatus.IN_PORT;
      }));
      const arrival = getActionArrival(portCall.actions);
      await DataStore.save(Action.copyOf(arrival, updated => {
        updated.updatedAt = new Date().toISOString();
      }));
    };
    handleClose();
  };

  const customPortCall = useMemo(() =>
    !createDisabled && portCall && PortCall.copyOf(portCall, updated => {
      updated.actions = [...updated.actions, shiftArrival, shiftDeparture];
    }),
    [portCall, shiftArrival, shiftDeparture, createDisabled]
  );

  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            <Typography variant="h5">{t('ShiftCreateDialog.Labels.NewShift')}</Typography>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          {shiftDeparture && <>
            <Box display="flex" height="fit-content">
              <StringKeyboardDateTimePicker
                id={"ShiftDeparturePlannedTimeInput"}
                warningPastDate={true}
                style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
                label={t('PortCall.Labels.PlannedTimeOfDeparture')}
                value={shiftDeparture.timePlanned}
                onChange={handleChangeDepartureTime}
                error={Boolean(departureError)}
                helperText={departureError}
                okLabel={t('Common.Buttons.Confirm')}
              />
              <TextField
                style={{ width: '100%', marginLeft: '1rem' }}
                variant="outlined"
                label={t('PortCall.Labels.DepartureLocation')}
                fullWidth
                margin="normal"
                disabled
                value={prevAction && prevAction.movementLocation ? prevAction.movementLocation.name : ''}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  },
                  startAdornment:
                    <>
                      {prevAction && prevAction.movementLocation && <ShiftDepart color="primary" />}
                    </>
                }}
              />
            </Box>
          </>}

          {shiftArrival && <>
            <Box display="flex" height="fit-content">
              <StringKeyboardDateTimePicker
                id={"ShiftArrivalPlannedTimeInput"}
                warningPastDate={true}
                style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
                label={t('PortCall.Labels.PlannedTimeOfArrival')}
                value={shiftArrival.timePlanned}
                onChange={handleChangeArrivalTime}
                error={Boolean(arrivalError)}
                helperText={arrivalError}
                okLabel={t('Common.Buttons.Confirm')}
              />

              <LocationAutocomplete
                id="ArrivalLocationInput"
                label={t('PortCall.Labels.ArrivalLocation')}
                style={{ width: '100%', marginLeft: '1rem' }}
                value={shiftArrival.movementLocation || null}
                onChange={handleChangeArrivalLocation}
                startAdornment={shiftArrival.movementLocation && <ShiftArrive color="primary" />}
                helperText={(shiftArrival.movementLocation?.dockable === false) ? t('ActionScheduleEdit.Labels.NotBerthableLocation') : ""}
              />

            </Box>
          </>}

          {!agentPortal &&
            <Box height="20rem" mb="1rem">
              <LocationTimeline customPortCall={customPortCall} />
            </Box>
          }

          {portCall.status === PortCallStatus.CLOSED && 
            <Typography className={classes.reopenPortCallMessage}>
              {t('ActionDetails.Labels.ReopenPortCallMessage')}
            </Typography>
          }
          
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={createDisabled}
              style={{ margin: '1rem' }}
              onClick={handleCreateShift}
              id="CreateShiftButton"
            >
              {t('Common.Buttons.Create')}
            </Button>

          </Box>

        </CardContent>
      </Card>
    </Popover>
  );
};

export default ShiftCreateDialog;
