import { useContext, useState, useMemo } from 'react'
import { DataStoreContext } from '../contexts/dataStoreContext';
import { Action, ActionMovementType, PortCall, PortCallStatus } from '../models';
import { UIContext } from '../contexts/ui';
import useQuery from './useQuery';
import ActionState from '../components/Action/ActionState';
import { getActionArrival, getActionTime, getPendingRequests } from '../utils/getters';

const usePortCalls = () => {
    const [{ userContactId }] = useContext(UIContext);
    const { requests, locations } = useContext(DataStoreContext);
    const [ initialLoad, setInitialLoad ] = useState(true);
    
    const portCalls = useQuery(PortCall, {
      condition: c => c.status("ne", PortCallStatus.DELETED),
      filter: i => i.status !== PortCallStatus.DELETED
    });
    const actions = useQuery(Action, {
      condition: c => c
        .state("ne", ActionState.DELETED)
        .or(c => c
          .movementType("eq", ActionMovementType.ARRIVAL)
          .movementType("eq", ActionMovementType.DEPARTURE)),
      filter: i =>
        i.state !== ActionState.DELETED && i.state !== ActionState.SUPERSEDED && (
          i.movementType === ActionMovementType.ARRIVAL ||
          i.movementType === ActionMovementType.DEPARTURE)
    });

    const locationMap = useMemo(() => {
      const lmap = new Map();
      locations.forEach(el => lmap.set(el.id, el));
      return lmap;
    }, [locations]);

    const items = useMemo(() => {
      // only port call data
      if(portCalls.length && !actions.length) {
        //console.log('usePorCalls - Loaded port calls');
        return portCalls?.map(p =>
          PortCall.copyOf(p, updated => {
            updated.actions = [];
            updated.requests = [];
            updated.sortTime = p.createdAt;
          }))
      }

      // port calls and actions
      if(portCalls?.length && actions?.length) {
        //console.log('usePorCalls - Loaded port calls and actions');
        const withActions = portCalls?.map(p =>
          PortCall.copyOf(p, updated => {
            const _actions = actions?.filter(a => a.portCall?.id && a.portCall.id === p.id)
            updated.actions = _actions.map(a => {
              return new Action.copyOf(a, updatedAction => {
                updatedAction.movementLocation = a.actionMovementLocationId ? locationMap.get(a.actionMovementLocationId) : null;
              });
            });
            updated.requests = getPendingRequests(p, requests, userContactId);
        
            // add custom field for port call sorting
            const arrival = getActionArrival(updated.actions);
            updated.sortTime = arrival ? getActionTime(arrival) || arrival.createdAt : updated.createdAt;
          })
        ); 
        setInitialLoad(false);
        return withActions; 
      }

      // No data
      return [];
    } , [portCalls, actions, requests, userContactId, locationMap]);

  return [initialLoad, items ];
};

export default usePortCalls;