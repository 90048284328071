import { useContext, useEffect, useState } from 'react'
import { DataStoreContext } from '../contexts/dataStoreContext';
import SettingConstants from '../constants/SettingConstants';

/**
 * Return a flag indecating if this feature is enabled or not.
 * @param {String} flag feature flag key
 */
const useFeatureSetting = (flag) => {
  const { settings } = useContext(DataStoreContext);

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    try{
      for(let setting of settings){
        if(setting?.name === SettingConstants.FEATURE_FLAGS) {
          const settingFlagJs = JSON.parse(setting?.value);
          const settingFlag = settingFlagJs?.features[flag];
          setEnabled(settingFlag?.enabled ?? false);
          break;
        }
      }
    } catch(error) {
      console.log('Feature: error: ', error);
    }
  }, [settings])

  return enabled;
}

export default useFeatureSetting;