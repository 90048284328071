import { enGB, es, fr } from 'date-fns/locale'
import testLocale from './locale/test/date-fns/testLocale';
import english from "i18n-iso-countries/langs/en.json";
import spanish from "i18n-iso-countries/langs/es.json";
import french from "i18n-iso-countries/langs/fr.json";
import testCountry from './locale/test/countries/test.json';

export const DateFnsLanguageMap = {
  'en-GB': enGB,
  'es-MX': es,
  'fr-CA': fr,
  'test': testLocale
}

export const CountryLanguageMap = {
  'en-GB': {
    locale: english,
    lang: 'en'
  },
  'es-MX': {
    locale: spanish,
    lang: 'es'
  },
  'fr-CA': {
    locale: french,
    lang: 'fr'
  },
  'test': {
    locale: testCountry,
    lang: 'test'
  }
}