import React from 'react';
import { Box, CardContent, FormControl, FormHelperText, FormControlLabel, FormGroup, Switch, ListItemText } from '@material-ui/core';
import Feature from './../Feature/Feature';
import AdminAutomationRule from './AdminAutomationRule';
import { Alert } from 'mdi-material-ui';
import useAutomationSetting from '../../hooks/useAutomationSetting';
import useAutomationRules from '../../hooks/useAutomationRules';
import { useTranslation } from 'react-i18next';

const AdminAutomation = () => {
  const { t } = useTranslation();
  const { appAutomationEnabled, updateAutomationSetting } = useAutomationSetting()
  const { automationRules, updateAutomationRule } = useAutomationRules();

  const handleAutomationEnabled = (event) => {
    updateAutomationSetting(event?.target?.checked ?? false);
  };

  if(automationRules?.length === 0) {
    return (
      <Box display="flex" height="100%"  width="100%" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Alert style={{ fontSize: "6rem", color: "rgba(0, 0, 0, 0.54)", opacity: '0.4'  }} />
          <ListItemText 
            primary={t('AdminAutomation.Labels.NoAutomationRules')} 
            secondary={t('AdminAutomation.Labels.NoAutomationRulesHelp')} 
            primaryTypographyProps={{ variant: "body2" }} 
            secondaryTypographyProps={{ variant: "caption" }}
            style={{ textAlign: "center"}}/>
          </Box>
      </Box>
    )
  }

  return (
    <Feature flag='portcall-automation'>
      <Box display="flex" flexGrow="1" flexDirection="column">
        <Box height="2rem" flex="none"/>
        <Box display="flex" flex="none">
          <CardContent style={{ flexGrow: 1 }}>
            <FormControl component="div">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={appAutomationEnabled}
                      onChange={handleAutomationEnabled} />
                  }
                  label={t('AdminAutomation.Labels.EnablePortCallAutomation')}
                />
              </FormGroup>
              <FormHelperText>{t('AdminAutomation.Labels.EnablePortCallAutomationHelp')}</FormHelperText>
            </FormControl>
          </CardContent>
        </Box>
        <AdminAutomationRule 
          automationRule={automationRules[0]}
          onUpdate={updateAutomationRule}
          disabled={!appAutomationEnabled}
          />
      </Box>
    </Feature>
  );
};

export default AdminAutomation;