const formatRelativeLocale = {
  lastWeek: "'XXXXXXX' eeee 'XXX' p",
  yesterday: "'XXXXXXX' p",
  today: "'XXXXXXX' p",
  tomorrow: "'XXXXXXX' p",
  nextWeek: "eeee 'XXX' p",
  other: 'P'
}

export default function testFormatRelative (token, date, baseDate, options) {
  return formatRelativeLocale[token]
}