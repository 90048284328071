import React, { useCallback, useMemo } from 'react';
import { CardContent, Typography } from '@material-ui/core';

import AgentActionScheduleEdit from './AgentActionScheduleEdit';
import AgentActionVesselEdit from './AgentActionVesselEdit';
import DocumentEdit from '../../Documents/DocumentEdit';
import { Action, ActionMovementType, FileType } from '../../../models';
import { DataStore } from 'aws-amplify';
import PortCallDataEdit from '../../PortCallData/PortCallDataEdit';
import { ActionDetailsHref } from '../../../constants/ActionDetails';
import { FileTypeLabels } from '../../../constants/PortCallDataLabels';
import Feature from '../../Feature/Feature';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

const AgentMovementEditor = ({
  portCall,
  setPortCall,
  action,
  setAction,
  selectedRequest,
  setSelectedRequest,
  saveDisabled,
  checkSaveState,
}) => {
  // TODO until datastore supports empty string arrays
  const documents = useMemo(() => action && action.documents ? action.documents.filter(d => d) : [], [action]);
  const setDocuments = useCallback((value) => DataStore.save(Action.copyOf(action, updated => { updated.documents = value.length ? value : ['']; })), [action]);
  const { t } = useTranslation();

  return (
    <>
      <AgentActionScheduleEdit
        selectedRequest={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        portCall={portCall}
        setPortCall={setPortCall}
        action={action}
        setAction={setAction}
      />
        <CardContent>
          <Typography 
            id={ActionDetailsHref.HREF_ATTACHMENTS}
            variant="h6"
            style={{paddingBottom: '0.5rem'}}
          >
            {t('MovementEdit.Labels.Attachments')}
          </Typography>
          <DocumentEdit
            anchorId="action-attachments"
            filePath={`portcalls/${portCall.id}/actions/${action.id}/`}
            portCallId={portCall.id}
            objId={action.id}
            objDocuments={documents}
            onChange={setDocuments}
            disableDownload
            disableDelete
            checkSaveState={checkSaveState}
            saveDisabled={saveDisabled}
          />
        </CardContent>
      {(action.movementType === ActionMovementType.ARRIVAL || action.movementType === ActionMovementType.DEPARTURE) && <>
        <CardContent>
          <AgentActionVesselEdit
            action={action}
            setAction={setAction}
            // dispatchAdjustSailingDraught={dispatchAdjustSailingDraught}
          />
        </CardContent>
        <Feature flag="crew-list-submission">
          <CardContent>
            <Typography
              id={ActionDetailsHref.HREF_FAL5_CREW_LIST}
              variant="h6"
            >
              {t(FileTypeLabels.FAL5_CREW_LIST)}
            </Typography>
            <PortCallDataEdit portCallId={portCall.id} actionId={action.id} type={FileType.FAL5_CREW_LIST} />
          </CardContent>
        </Feature>
      </>}
    </>
  )
}

export default AgentMovementEditor;