import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  ListItemIcon,
  Badge
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { ActionState as ActionStates, TodoStatus } from '../../models';
import ActionStateAndTimeTooltip from '../../components/Tooltip/ActionStateAndTimeTooltip';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';
import ChecklistSummary from '../../components/Todo/ChecklistSummary';
import ActionState from './ActionState';
import useChargeableItemValidation from '../../hooks/useChargeableItemValidation';
import { MovementActionDisplayTypeKeys as madt } from '../../constants/MovementActionDisplayType';
import { Attachment } from 'mdi-material-ui';
import { processTodoArray } from '../../utils/todo';
import { makeStyles } from '@material-ui/core/styles';
import { itemRequested } from '../../constants/RequestConstants';
import { itemApprovalPending, itemApprovalDeclined } from '../../constants/ApprovalConstants';
import { useTranslation } from 'react-i18next';
import InvalidTariffWarning from '../Chargeables/InvalidTariffWarning';
import { UIContext } from '../../contexts/ui';

/* Make styles if needed */
const useStyles = makeStyles(theme => ({
  itemRequested,
  itemApprovalPending,
  itemApprovalDeclined
}));

const cancelledStyle = {
  textDecorationLine: 'line-through',
  color: '#aaaaaa'
};

const TimelineMovementAction = ({ item, requests }) => {
  const theme = useTheme();
  const classes = useStyles();
  const strike = item.state === ActionStates.CANCELLED;
  const todos = processTodoArray(item.todos);
  const approvalBadgeContent = item && (item.state === ActionStates.APPROVAL_PENDING ? "!" : item.state === ActionStates.APPROVAL_DECLINED ? "×" : null);
  const approvalBadgeClass = approvalBadgeContent && item.state === ActionStates.APPROVAL_DECLINED ? classes.itemApprovalDeclined : classes.itemApprovalPending;
  const approvalBadgeColor = approvalBadgeContent && item.state === ActionStates.APPROVAL_DECLINED ? "error" : "primary";
  const { validateActionChargeableItem } = useChargeableItemValidation();
  const validChargeables = validateActionChargeableItem(item);
  const { t } = useTranslation();
  const [uiContext] = useContext(UIContext);
  const isAgent = uiContext.agentPortal;

  return (
    <>
      {requests ? <div className={classes.itemRequested} /> : approvalBadgeContent ? <div className={approvalBadgeClass} /> : null}
      <PopoverTooltip tooltip={<ActionStateAndTimeTooltip action={item} />} style={{ display: 'flex', alignItems: 'center' }}>
        <ListItemIcon className={'TimelineMovementActionIcon'}>
          {approvalBadgeContent
            ? <Badge badgeContent={approvalBadgeContent} color={approvalBadgeColor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
              <ActionState action={item} />
            </Badge>
            : <ActionState action={item} />
          }
        </ListItemIcon>
        
        <Box flexGrow="1">
          <Typography variant="h6" noWrap style={strike ? cancelledStyle : null} className={'TimelineMovementActionLabel'}>
            {t(madt[item.movementType])}
          </Typography>
          {/* TODO what the fuck */}
          {item.movementLocation && <Typography component="p" variant="caption" noWrap style={{ ...strike ? cancelledStyle : null, marginBottom: '-0.25rem' }}>
            {item.movementLocation.parentName}
          </Typography>}
          {item.movementLocation && <Typography variant="body1" noWrap style={strike ? cancelledStyle : null}>
            {item.movementLocation.name}
          </Typography>}
        </Box>
        <Box className={classes.icons} style={{ marginRight: '1.5em', display: 'flex' }}>
          {(validChargeables?.error > 0 && !isAgent) &&
            <Box 
            style={{ marginRight: '0.5em' }}
            id="InvalidTariffMovementAction">
              <InvalidTariffWarning
                tooltip={t(validChargeables.errorMessageT)}
              />
            </Box>}
          {item.documents && item.documents.filter(d => d).length > 0 &&
            <Attachment
              style={{
                transform: "rotate(-90deg)",
                marginLeft: '-0.3em',
              }}
            />
          }
        </Box>
        {item.todos && todos.filter(todo => todo.status !== TodoStatus.DELETED).length > 0 &&
          <Box
            flexGrow="0"
            marginLeft="-1rem"
            paddingRight="2rem"
          >
            <ChecklistSummary items={todos} />
          </Box>
        }
      </PopoverTooltip>
    </>);
}

TimelineMovementAction.propTypes = {
  item: PropTypes.object.isRequired,
  requests: PropTypes.bool
}

export default TimelineMovementAction;