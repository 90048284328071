import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { customOnCreateVoyageSubscription, fetchVoyages } from '../utils/voyages';
import { onCreateVoyage, onDeleteVoyage } from '../graphql/subscriptions';

/**
 * Query the voyage table for high level information. This hook is intended
 * for use in UI components that might need to display a voyage name or vessel.
 * @returns Object containg loading status and voyages
 */
const useVoyages = () => {
  const [voyages, setVoyages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // query for initial data
    const update = async () => {
      setVoyages(await fetchVoyages());
      setLoading(false);
    };
    setLoading(true);
    update();

    const onCreateSub = API.graphql(graphqlOperation(onCreateVoyage)).subscribe({
      next: ({ value }) => {
        const item = value?.data.onCreateVoyage;
        item && setVoyages(prev => [...prev, item]);
      },
      error: (error) => console.warn(error)
    });

    const onUpdateSub = API.graphql(graphqlOperation(customOnCreateVoyageSubscription)).subscribe({
      next: ({ value }) => {
        const item = value?.data.onUpdateVoyage;
        item && setVoyages(prev => 
          prev.map(el => el.id === item.id ? item : el));
      },
      error: (error) => console.warn(error)
    });
    const onDeleteSub = API.graphql(graphqlOperation(onDeleteVoyage)).subscribe({
      next: ({ value }) => {
        const item = value?.data.onDeleteVoyage;
        item && setVoyages(prev => 
          prev.filter(el => el.id === item.id));
      },
      error: (error) => console.warn(error)
    });

    return () => {
      onCreateSub.unsubscribe();
      onUpdateSub.unsubscribe();
      onDeleteSub.unsubscribe();
    };
  }, [])

  return {
    loading: loading,
    voyages: voyages
  };
};

export default useVoyages;