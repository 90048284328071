import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import PopoverTooltip from '../components/Tooltip/PopoverTooltip';
import FieldSelect from '../components/FieldSelect';
import ColumnTooltip from '../components/Tooltip/ColumnTooltip'
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { difference } from 'lodash';
import { reorderArray } from '../utils/utils';
import { itemRequested } from '../constants/RequestConstants';
import { itemApprovalPending, itemApprovalDeclined } from '../constants/ApprovalConstants';
import { useTranslation } from 'react-i18next';
import '../translations/i18n';

/**
 * Standard Data Table component.
 * Makes use of Material UI's Table components
 * @param {String} id - id of the data table. This is used when storing the table columns order/visibility to the local storage
 * @param {Boolean} showHeader flag to show/hide the table header
 * @param {Object[]} columns - Columns
 * @param {Object[]} rows - Data
 * @param {String[]} initialOrder - Column ids in order
 * @param {String[]} initialVisible - Column with visibility set
 * @param {Boolean} autoSavePreferences flag to enable saving the table columns order/visibility to the local storage
 * @param {String} tableVersion number to force default settings
 */

const useStyes = makeStyles(theme => {
  return {
    root: {
      marginBottom: '5rem',
    },
    header: {
      fontSize: '1rem',
      textTransform: 'uppercase',
      color: '#000',
      background: '#f5f5f5',
      height: "2.5rem",
      display: "flex",
      alignItems: "center",
      overflowX: "hidden"
    },
    headerCol: {
      padding: "0 1rem",
      flexShrink: 0,
      '&:last-child': {
        padding: 0,
        position: "sticky",
        right: 0,
        width: 50,
        display: "flex",
        justifyContent: "center",
        background: '#f5f5f5',
        marginLeft: "auto"
      }
    },
    cell: {
      color: 'inherit',
      fontSize: 'inherit',
      // '&:last-child': {
      //   paddingRight: '0'
      // },
      scrollSnapAlign: 'start',
      position: 'relative'
    },
    title: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    body: {
      padding: theme.spacing(1),
    },
    drag: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      cursor: 'grab',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500
    },
    tableOdd: {
      backgroundColor: '#efefef',
    },
    itemCancelled: {
      '& > *': {
        opacity: 0.5
      }
    },
    itemRequested,
    itemApprovalPending,
    itemApprovalDeclined
  }
});

const orderedColumns = (columns, order) => {
  return order && order.length
    ? order.map(id => {
      return columns.filter(col => col.id === id)[0];
    })
    : columns;
};

const handleDragOver = e => {
  e.preventDefault();
  e.target.style.opacity = 0.75;
  e.target.style.border = '2px dashed #666';
};

const handleDragLeave = e => {
  e.preventDefault();
  e.target.style.opacity = 1;
  e.target.style.border = 'none';
};

const handleDragStart = e => {
  e.target.style.opacity = 0.2;
  e.dataTransfer.setData('sourceIndex', e.target.dataset.index);
};

const handleDragEnd = e => {
  e.target.style.opacity = 1;
  e.target.style.border = 'none';
};

const localStorageTableRef = {
  setItem: (id, value) => localStorage.setItem(`smartport::preferences::${id}::table`, value),
  getItem: id => localStorage.getItem(`smartport::preferences::${id}::table`)
}

const DataTable = ({
  id,
  showHeader = true,
  columns,
  rows,
  initialOrder,
  initialVisible,
  autoSavePreferences = true,
  tableVersion = 0.01
}) => {
  const classes = useStyes();
  const anchorEl = useRef(null);
  const headerRef = useRef(null);
  const outerRef = useRef(null);
  const { t } = useTranslation();

  const savePreferences = useCallback((payload) => autoSavePreferences && localStorageTableRef.setItem(id, JSON.stringify(payload)), [autoSavePreferences]);
  
  let savedPreferences = autoSavePreferences && (JSON.parse(localStorageTableRef.getItem(id)) || null);
  if (savedPreferences && savedPreferences.version !== tableVersion) {
    const newColumns = difference(initialOrder, savedPreferences.order);
    const newOrder = [...savedPreferences.order];
    newColumns.forEach(col => newOrder.splice(initialOrder.indexOf(col), 0, col));
    savedPreferences.order = newOrder;
    savedPreferences.visible = { ...initialVisible, ...savedPreferences.visible };
    savedPreferences.version = tableVersion;
  }

  const [visible, setVisible] = useState((savedPreferences && savedPreferences.visible) || initialVisible);
  const [order, setOrder] = useState((savedPreferences && savedPreferences.order) || initialOrder);

  const allColumns = orderedColumns(columns, order);
  const displayColumns = allColumns.filter(item => item?.id && visible[item.id]);

  // calculation of elements from fontSize
  const [fontSize, setFontSize] = useState(parseFloat(getComputedStyle(document.documentElement).fontSize));
  const remToPx = useCallback(rem => rem * fontSize, [fontSize]);
  const rowHeight = useMemo(() => remToPx(5), [remToPx]);

  const getCellStyle = useCallback((rowIndex, column, columnIndex) => {
    const lastCol = columnIndex === displayColumns.length - 1
    return { 
      width: lastCol ? 50 : remToPx(column.width),
      paddingLeft: !lastCol && "1rem", 
      paddingRight: !lastCol && "1rem", 
      marginRight: lastCol && "0rem", 
      display: "inline-block", 
      marginLeft: lastCol && 'auto',
      position: lastCol && "sticky", right: 0, 
      backgroundColor: lastCol && (rows[rowIndex]?.meta?.className && classes[rows[rowIndex]?.meta?.className] ? 'inherit' : '#fff')
    }
  }, [remToPx, rows, classes, displayColumns])

  const handleOrder = useCallback((e) => {
    const targetIndex = e.target.dataset.index;
    const sourceIndex = e.dataTransfer.getData('sourceIndex');
    if (targetIndex && sourceIndex && targetIndex !== sourceIndex) {
      setOrder(reorderArray(
        Number(sourceIndex),
        Number(targetIndex),
        [...order]
      ));
      e.target.style.opacity = 1;
      e.target.style.border = 'none';
    }
  }, [order, setOrder])

  const toggleVisible = (key) => {
    setVisible({ ...visible, [key]: !visible[key] });
  };

  // update fontSize on window resize
  useEffect(() => {
    const handleResize = () => setFontSize(parseFloat(getComputedStyle(document.documentElement).fontSize));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setFontSize]);

  // need to observe list's resize directly for cases where use switches to fullscreen mode and update scroll position
  useEffect(() => {
    const resizeObserver = new ResizeObserver(function (entries) {
      // delay scrolling
      setTimeout(() => outerRef.current && headerRef.current && headerRef.current.scroll(outerRef.current.scrollLeft, 0), 200);
    });
    headerRef.current && resizeObserver?.observe(headerRef.current);
    return () => headerRef.current && resizeObserver?.unobserve(headerRef.current);
  }, [headerRef.current, outerRef.current]);

  useEffect(() => {
    savePreferences({ order, visible, version: tableVersion });
  }, [order, visible, savePreferences]);


  useEffect(() => {
    function handleScroll(evt) {
      headerRef.current.scroll(evt.target.scrollLeft, 0)
    };

    outerRef?.current?.addEventListener("scroll", handleScroll);

    return function cleanup() {
      outerRef?.current?.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div 
      id="DataTable"
      onScroll={(e) => headerRef.current && headerRef.current.scroll(e.target.scrollLeft, 0)} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {showHeader && <div ref={headerRef} className={classes.header}>
        {displayColumns.map((column, index) => {
          return column &&
            <div
              key={column.id}
              className={classes.headerCol}
            >
              {index !== displayColumns.length - 1 &&
                <PopoverTooltip
                  tooltip={<ColumnTooltip label={column.label} />}
                  delay={0}
                >
                  <div
                    draggable={Boolean(order && order.length)}
                    onDragStart={handleDragStart}
                    data-id={column.id}
                    data-index={order.indexOf(column.id)}
                    onDrop={handleOrder}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDragEnd={handleDragEnd}
                    className={classes.drag} 
                    style={{ width: remToPx(column.width) }}
                  >
                    {t(column.label.label)}
                  </div>
                </PopoverTooltip>}
              {index === (displayColumns.length - 1) && order && (
                <div ref={anchorEl}>
                  <FieldSelect
                    columns={allColumns}
                    filterAnchorEl={anchorEl}
                    visible={visible}
                    toggleVisible={toggleVisible}
                  />
                </div>
              )}
            </div>
        })}
      </div>}
      <div style={{ flexGrow: "1" }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              key={'DataList'}
              itemCount={rows.length}
              height={height}
              itemSize={rowHeight}
              width={width}
              outerRef={outerRef} 
            >
              {({ index, style }) =>
                <div
                  key={rows[index].id}
                  data-id={rows[index].id}
                  className={rows[index]?.meta?.className && classes[rows[index]?.meta?.className] ? `${classes[rows[index]?.meta?.className]} DataTableRow`: "DataTableRow"}
                  style={{ ...style, width: "auto", display: "flex", alignItems: "center", minWidth: "100%", borderBottom: "0.0625rem solid #e0e0e0", boxSizing: "border-box" }}
                >
                  {displayColumns.map((column, columnIndex) => (<div key={column.id} className={classes.cell} style={getCellStyle(index, column, columnIndex)}>
                    {column.format(rows[index][column.id], rows[index])}
                  </div>))
                  }
                  {rows[index].hasRequests 
                    ? <div className={classes.itemRequested} /> 
                    : rows[index].hasApprovalDeclined
                      ? <div className={classes.itemApprovalDeclined} />
                      : rows[index].hasApprovalPending 
                        ? <div className={classes.itemApprovalPending} /> 
                        : null }
                </div>
              }
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default DataTable;
