import { ReceiptTextOutline, CloseCircleOutline } from 'mdi-material-ui';
import { ListItemText, MenuItem } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Feature from '../Feature/Feature';
import FeatureFlags from '../../constants/FeatureFlags';

const InvoiceMenuOption = ({agentPortal, handleOpenInvoiceDialog}) => {
  const { t } = useTranslation();

  return (
    <Feature flag={FeatureFlags.INVOICE}>
      { !agentPortal &&
        <MenuItem
        onClick={handleOpenInvoiceDialog}
        id={'PortCallShowInvoiceMenuItem'}
      >
        <ReceiptTextOutline style={{ marginRight: '1rem' }}>
          <CloseCircleOutline />
        </ReceiptTextOutline>
        <ListItemText primary={t('PortCallMenuItem.Labels.ViewInvoice')} />
      </MenuItem>}
    </Feature>
  )
};

export default InvoiceMenuOption;