import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AW_White_Small from '../../resources/images/AW_White_Small.png';

const useStyles = makeStyles(theme => ({
  accuweatherLogo: {
    width: '4.5rem',
    display: 'block'
  }
}));

const AccuWeatherAttribution = () => {
  const classes = useStyles();
  return (
    <img className={classes.accuweatherLogo} src={AW_White_Small} alt="AccuWeather" /> 
  )
};

export default AccuWeatherAttribution;