import React, {
  useEffect,
  useState,
  useContext, useMemo, useCallback
} from 'react';
import { Box, Grid, Typography, IconButton, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  useLocation,
} from 'react-router-dom';
import { Ferry, ChevronLeft, ChevronRight, Email, Phone, Play, Pause, Cog, CommentTextOutline } from 'mdi-material-ui';
import {  MovementActionType } from '../constants/MovementActionType';
import { METRES, UnitsKeys } from '../constants/Units';
import BerthStatusListItem from '../components/Berth/BerthStatusListItem';
import { CANCELLED, DELETED } from '../constants/ActionState';
import { UIContext } from '../contexts/ui';
import ListHeader from "../components/ListHeader/ListHeader";
import SimpleListHeader from "../components/ListHeader/SimpleListHeader";
import { AnimatedIteratorSettings, useAnimatedIterator, AnimatedIteratorRates } from '../components/Common/AnimatedIterator';
import PopoverTooltip from '../components/Tooltip/PopoverTooltip';
import AgentListItem from '../components/Agent/AgentListItem';
import ContactTooltip from '../components/Tooltip/ContactTooltip';
import CargoTooltip from '../components/Tooltip/CargoTooltip';
import CargoListItem from '../components/Cargos/CargoListItem';
import PortCallRemarks from '../components/PortCall/PortCallRemarks';
import PortCallLatestLogbookEntry from '../components/PortCall/PortCallLatestLogbookEntry';
import { ActionMovementType, ActionState, PortCallStatus } from '../models';
import { getActionActiveAgent, getActionLastCompletedArrival, getNextActionableAction } from '../utils/getters';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { ActionTypeIds } from "../environment";
import { useTranslation } from 'react-i18next';
import useSyncFilter from '../hooks/useSyncFilter';
import useFilterSettings from "../hooks/useFilterSettings.js";
import useUserSettings from '../hooks/useUserSettings';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  berthContainer: {
    minWidth: '30rem',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 'calc(50% - 1rem)',
    borderBottom: '0.0625rem solid #e0e0e0',
    scrollSnapAlign: 'start',
  },
  fullScreenBerthContainer: {
    '&:nth-child(2n+0)': {
      borderLeft: '0.0625rem solid #e0e0e0',
    }
  },
  berthsContainer: {
    boxSizing: 'border-box',
    scrollSnapType: 'y proximity',
  },
  berthHeader: {
    padding: '0.5rem',
    paddingLeft: '1rem',
    backgroundColor: theme.palette.colors.grey[100],
    flexGrow: 0,
    height: '2.4375rem',
  },
  berthIcon: {
    marginBottom: '-0.125rem'
  },
  occupied: {
    fontWeight: 700,
  },
  vacant: {
  },
  emptyBerth: {
    width: '100%',
    borderTop: '0.0625rem #e0e0e0 solid',
  },
  scrollChild: {
    scrollSnapAlign: 'start',
  }
}));

const userSettingFilterIdField = "berthsFilterId";

const RemarksCard = (portCall) => {
  return (
    <Box display="flex" alignItems="center">
      <PortCallRemarks remarks={portCall.remarks} icon />
    </Box>
  )
};

const LatestLogbookEntryCard = (portCall) => {
  // Use last port call comment if available other use last comment from actions else no comment 
  let logEntry = portCall?.latestUserLogEntry;
  if(!logEntry) {
    const action = portCall.actions
      .filter(el => el.type.id === ActionTypeIds.MOVEMENT && !el._deleted && el.state !== ActionState.CANCELLED)
      .reduce((acc, curr) => {
        if(!curr.latestUserLogEntry?.timeChanged) return acc;
        return new Date(acc.latestUserLogEntry?.timeChanged) > new Date(curr.latestUserLogEntry?.timeChanged) ? acc : curr;
      });
    logEntry = action.latestUserLogEntry ? action.latestUserLogEntry : null;
  }

  if(!logEntry) return null;
  return (
    <Box display="flex" alignItems="center">
      <ListItemIcon><CommentTextOutline /></ListItemIcon>
      <PortCallLatestLogbookEntry logbookEntry={logEntry} />
    </Box>
  )
};

// These are the values stored in the Filters DB table as status - NOT UI labels 
const ALL = 'All';
const OCCUPIED = 'Occupied';
const VACANT = 'Vacant';

const BerthStatusList = ({ activeActions }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const { locations } = useContext(DataStoreContext);
  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const agentPortal = uiContext.agentPortal;
  const userContactId = uiContext.userContactId;
  const isFullScreen = location.pathname === '/lists/berths';
  const { userSetting } = useUserSettings();

  const enabledFilters = useMemo(() => [{
    value: ALL,
    label: t('Common.Labels.All')
  }, {
    value: OCCUPIED,
    label: t('BerthStatusDisplay.Labels.FilterOccupied')
  }, {
    value: VACANT,
    label: t('BerthStatusDisplay.Labels.FilterVacant')
  }], [t]);

  const defaultFilters = !agentPortal ? ALL : OCCUPIED;
  const TYPE = useMemo(() => ({
    full: "berths",
    type: "BERTH",
    prefix: "BERTH_",
    id: "AllBerths",
    name: t('BerthStatusDisplay.Labels.Berths'),
    defaultFilters,
    get AllFilter() {
      return {
        id: this.id,
        name: t('BerthStatusDisplay.Labels.FilterNameAllBerths', {name: this.name}),
        status: this.defaultFilters,
        location: [ALL],
      };
    },
  }), [t]);

  const [filters, setFilter] = useState();
  useSyncFilter(filters, setFilter, TYPE, userSettingFilterIdField);
  const { filterSetting } = useFilterSettings();
  const disableFilterEdits = useMemo(() => uiContext.isAdmin ? false : filterSetting.adminEditOnly, [filterSetting, uiContext.isAdmin]); 

  // forces filter update for default entry on language change
  useEffect(() => {
    setFilter(prev => prev && prev.id === TYPE.id ? TYPE.AllFilter : prev);
  }, [TYPE])

  const history = useHistory();

  // updates route on log-in to match userSetting
  useEffect(() => {
    if (userSetting.fullScreen === '/lists/berths') {
      history.replace({pathname:"/lists/berths", search: location.search});
    } else if (userSetting.fullScreen === '/'){
      history.replace({pathname:"/", search: location.search})
    } else return
  }, [userSetting?.id])

  const berths = useMemo(() => {
    if (!filters) return [];
    const newBerths = locations
      .filter(l => l.dockable)
      .map(location => ({
        location,
        portCalls: activeActions
          .filter(a => {
            if(!agentPortal) return true;
            if(a.portCall?.agents?.find(agent => agent.contactId === userContactId)) return true;
            return false;
          })
          .filter(a => a.movementType === ActionMovementType.ARRIVAL && a.portCall.status === PortCallStatus.IN_PORT)
          .map(arrival => {
            const nextAction = arrival.portCall.actions && getNextActionableAction(arrival.portCall.actions.filter(a => a.movementType));
            const lastAction = arrival.portCall.actions && getActionLastCompletedArrival(arrival.portCall.actions);
              return (lastAction && lastAction.movementLocation && lastAction.movementLocation.id === location.id && 
              (!nextAction || (nextAction.movementType !== ActionMovementType.SHIFT_ARRIVAL && (nextAction.state === ActionState.PLANNED || nextAction.state === ActionState.APPROVAL_PENDING || nextAction.state === ActionState.APPROVAL_DECLINED)))) ? arrival.portCall : null;
          })
          .filter(p => p)
      }));

    return newBerths.filter(b =>
      (filters.status === ALL || (filters.status === OCCUPIED ? b.portCalls.length : !b.portCalls.length)) &&
      (filters.location[0] === ALL || !filters.location.length || filters.location.includes(b.location.id) || (b.location.parent && filters.location.includes(b.location.parent.id)))
    )
  }, [locations, activeActions, filters, agentPortal, userContactId]);

  const VesselDetailsCard = useCallback((portCall) => {
    const arrival = portCall.actions.find(a => ![DELETED, CANCELLED].includes(a.state) && a.movementType === MovementActionType.ARRIVAL);
    const departure = portCall.actions.find(a => ![DELETED, CANCELLED].includes(a.state) && a.movementType === MovementActionType.DEPARTURE);
    return (
      <Box display="flex" alignItems="center">
        <ListItemIcon><Ferry /></ListItemIcon>
        <ListItemText style={{ flexBasis: "33%" }} primary={portCall.vesselData.lengthOverall + t(UnitsKeys[METRES])} secondary={t('Vessel.Labels.Length')} />
        <ListItemText style={{ flexBasis: "33%" }} primary={arrival && arrival.vesselSailingDraught ? arrival.vesselSailingDraught + t(UnitsKeys[METRES]) : ''} secondary={arrival && arrival.vesselSailingDraught ? t('Vessel.Labels.ArrivalDraught'): ''} />
        <ListItemText style={{ flexBasis: "33%" }} primary={departure && departure.vesselSailingDraught ? departure.vesselSailingDraught + t(UnitsKeys[METRES]) : ''} secondary={departure && departure.vesselSailingDraught ? t('Vessel.Labels.DepartureDraught'): ''} />
      </Box>
    );
  }, [t]);

  const CargoCard = useCallback((portCall) => {
    return (
      <PopoverTooltip tooltip={<CargoTooltip cargo={{ manifest: portCall.cargos }} />}>
        <Box display="flex" alignItems="center">
          <CargoListItem icon cargo={{ manifest: portCall.cargos, movementType: MovementActionType.ARRIVAL }} label={t('Cargo.Labels.IncomingCargo')} showAmount onlyIncoming />
          <CargoListItem cargo={{ manifest: portCall.cargos, movementType: MovementActionType.ARRIVAL }} label={t('Cargo.Labels.OutgoingCargo')} showAmount onlyOutgoing />
        </Box>
      </PopoverTooltip>
    );
  }, [t]);

  const ActiveAgentCard = useCallback((portCall) => {
    const agent = getActionActiveAgent(portCall.actions);
    return (
      <PopoverTooltip tooltip={<ContactTooltip contact={agent} />}>
        <Box display="flex" alignItems="center">
          <AgentListItem icon agent={agent} display={true} label={t('BerthStatusDisplay.Labels.ActiveAgent')} />
          {agent &&
            <Box display="flex" width="100%" justifyContent="center" flexDirection="column">
              <ListItemText
                primaryTypographyProps={{ component: "div" }}
                secondaryTypographyProps={{ component: "div" }}
                primary={agent.number && <Box display="flex" alignItems="center"><ListItemIcon><Phone fontSize="small" /></ListItemIcon>{agent.number}</Box>}
                secondary={agent.email && <Box display="flex" alignItems="center"><ListItemIcon><Email fontSize="small" /></ListItemIcon>{agent.email}</Box>}
              />
            </Box>
          }
        </Box>
      </PopoverTooltip>
    );
  }, [t]);

  const defaultPortAnimatedItems = useMemo(() => [
    { id: 'vesselDetails', label: t('Vessel.Labels.VesselDetails'), checked: true,  renderItem: portCall => VesselDetailsCard(portCall) },
    { id: 'remarks', label: t('BerthStatusDisplay.Labels.Remarks'), checked: true, renderItem: portCall => RemarksCard(portCall) },
    { id: 'logbook', label: t('Logbook.Labels.Logbook'), checked: true, renderItem: portCall => LatestLogbookEntryCard(portCall) },
    { id: 'activeAgent', label: t('BerthStatusDisplay.Labels.ActiveAgent'), checked: true, renderItem: portCall => ActiveAgentCard(portCall) },
    { id: 'cargos', label: t('Cargo.Labels.Cargos'), checked: true, renderItem: portCall => CargoCard(portCall) }
  ], [t])

const defaultAgentPortalAnimatedItems = useMemo(() => [
{ id: 'vesselDetails', label: t('Vessel.Labels.VesselDetails'), checked: true,  renderItem: portCall => VesselDetailsCard(portCall) },
{ id: 'cargos', label: t('Cargo.Labels.Cargos'), checked: true, renderItem: portCall => CargoCard(portCall) }
], [t]);

  let defaultAnimatedItems = defaultPortAnimatedItems;
  if(agentPortal) {
    defaultAnimatedItems = defaultAgentPortalAnimatedItems;
  }

  const [animatedAnchorEl, setAnimatedAnchorEl] = useState(false);
  const [currentAnimatedItem, nextAnimatedItem, prevAnimatedItem, animatedItems, setAnimatedItems, animated, setAnimated, rate, setRate] = useAnimatedIterator({
    id: 'BerthStatusList',
    defaultItems: defaultAnimatedItems,
    defaultAnimated: isFullScreen,
    defaultRate: AnimatedIteratorRates.SECONDS_5,
  });

  useEffect(() => {
    setAnimated(isFullScreen);
  }, [isFullScreen])

  const animatedControls = () => {
    return (
      <>
        <Tooltip title={t('BerthStatusDisplay.Labels.ShowPreviousDetails')}>
          <IconButton size="small" disabled={!prevAnimatedItem} onClick={prevAnimatedItem} style={{ marginLeft: '1rem' }} color="inherit" >
            <ChevronLeft fontSize="small" />
          </IconButton>
        </Tooltip>
        {animated ?
          <Tooltip title={t('BerthStatusDisplay.Labels.ShowRefreshingDetails')}>
            <IconButton size="small" onClick={() => setAnimated(false)} color="inherit">
              <Pause fontSize="small" />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title={t('BerthStatusDisplay.Labels.StartRefreshingDetails')}>
            <IconButton size="small" onClick={() => setAnimated(true)} color="inherit">
              <Play fontSize="small" />
            </IconButton>
          </Tooltip>
        }
        <Tooltip title={t('BerthStatusDisplay.Labels.ShowNextDetails')}>
          <IconButton size="small" disabled={!nextAnimatedItem} onClick={nextAnimatedItem} color="inherit">
            <ChevronRight fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('BerthStatusDisplay.Labels.DetailsDettings')}>
          <IconButton size="small" onClick={(e) => setAnimatedAnchorEl(e.currentTarget)} color="inherit">
            <Cog fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const noVesselsInPort = () => {
    return (
      <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="caption" align="center">{t('BerthStatusDisplay.Labels.NoVesselsInPort')}</Typography>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow={isFullScreen ? 1 : 0}
      flexShrink={0}
      width="33rem"
    >
      {!agentPortal ? 
        <ListHeader
          enabledFilters={enabledFilters}
          filters={filters}
          setFilter={setFilter}
          defaultFilters={defaultFilters}
          TYPE={TYPE}
          filterSettings={{
            hideEditControls: false,
            disableEdit: agentPortal || disableFilterEdits
          }}
          queryParams={queryParams}
          children={
            isFullScreen && animatedControls()
          }
        /> : 
        <SimpleListHeader
          children={
            isFullScreen && animatedControls()
          }
        />
      }
      <AnimatedIteratorSettings items={animatedItems} setItems={setAnimatedItems} rate={rate} setRate={setRate} anchorEl={animatedAnchorEl} onClose={() => setAnimatedAnchorEl(null)} />
      <Box
        className={classes.berthsContainer}
        display={isFullScreen ? 'flex' : 'block'}
        flexDirection="row"
        justifyContent="flex-start"
        flexWrap="wrap"
        flexGrow="1"
        overflow="auto"
        height="100%"
      >
        {/* Map over berths to display */}
        {berths.map(item => (
          <Grid
            key={item.location.id}
            className={`${classes.berthContainer} ${isFullScreen ? classes.fullScreenBerthContainer : ''}`}
          >
            <Grid
              container
              justifyContent="space-between"
              className={classes.berthHeader}
            >
              <Typography
                variant="body1"
                className={Boolean(item.portCalls.length) ? classes.occupied : classes.vacant}
              >
                {item.location.name} {item.portCalls.map(item => <Ferry key={item.id} className={classes.berthIcon} fontSize="inherit" />)}
              </Typography>
              <Typography variant="body2">{item.location.parent && item.location.parent.name}</Typography>
            </Grid>
            <Grid
              className={classes.scrollChild}
              container
              item
            >
              {item.portCalls.length ? item.portCalls.map(portCall => (
                <BerthStatusListItem
                  key={portCall.id}
                  portCall={portCall}
                  readOnly={readOnly}
                  showPtd={true}
                  fullscreen={isFullScreen}
                  fullscreenRenderItem={currentAnimatedItem.renderItem}
                  agentIdFilter={userContactId}
                  hideAgentDetails={agentPortal}
                />
              ))
                : <div className={isFullScreen ? classes.emptyBerth : ''} />
              }
            </Grid>
          </Grid>
        ))}
        {!berths.length ? noVesselsInPort() : null}
      </Box>
    </Box>
  );
};

export default BerthStatusList;
