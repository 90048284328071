// Column Headers for Chargeable Table inside the InvoiceDialog
// id - For the column heading
// label - Label for the column heading
export const LIST_OF_CHARGES_COLUMNS = [
  {
    id: "id",
    label: "Invoice.Labels.Id",
  },
  {
    id: "tariff",
    label: "Invoice.Labels.Tarrif",
  },
  {
    id: "quantity",
    label: "Invoice.Labels.Quantity",
  },
  {
    id: "unitCost",
    label: "Invoice.Labels.UnitCost",
  },

  {
    id: "total",
    label: "Invoice.Labels.Total",
  }
]

// Column Headers for Vessel Details Table inside the InvoiceDialog
// id - For the column heading
// label - Label for the column heading
export const VESSEL_DETAILS_COLUMNS = [
  {
    id: "name",
    label: "Invoice.Labels.Name",
  },
  {
    id: "imo",
    label: "Vessel.Labels.IMO",
  },
  {
    id: "mmsi",
    label: "Vessel.Labels.MMSI",
  }
]

// Column Headers for Schedule Summary Table inside the InvoiceDialog
// id - For the column heading
// label - Label for the column heading
export const SCHEDULE_SUMMARY_COLUMNS = [
  {
    id: "location",
    label: "Invoice.Labels.Location",
  },
  {
    id: "arrival",
    label: "Invoice.Labels.Arrival",
  },
  {
    id: "departure",
    label: "Invoice.Labels.Departure",
  }
]

