import { v4 as uuidv4 } from 'uuid';

//Function to add extra information to the todo for client side rendering.
//Add the id on to the todo object as well as the pos, and the action id.
//returns the array of todos. Adds a random id if there is none provided.
export const processTodoArray = (items, id = "") => {
    if (!items || items.length === 0) return [];
    if (!id) id = uuidv4();
    return items.map((item, i) => {
      return {...item, id: `${id}${i}`, pos: i, actionId: id}
    });
}