import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Typography, Box, Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import { ArrowLeft } from 'mdi-material-ui';
import VesselAnatomyForm from '../Vessel/VesselAnatomyForm';
import ContactForm from './ContactForm';
import { ImageFileContext, imageFileActionConstants } from '../../contexts/image';
import { validateVesselImo, validateVesselMmsi } from '../../utils/validators';
import { DataStore } from 'aws-amplify';
import { Vessel } from '../../models';
import { DataContext, DataContextConstants } from '../../contexts/dataContext';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { ContactTypeIds } from '../../environment';

const {
  RESET,
} = imageFileActionConstants;

const PAGE_MAIN = 0;
const PAGE_VESSEL_AGENT = 1;

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '85rem',
    margin: '0 auto'
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '12rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const VesselForm = ({ vessel, setVessel, onBack, showTitle, disablePortcallUpdate }) => {
  const [, dispatchDataContext] = useContext(DataContext);
  const { t } = useTranslation();

  const { vesselAgent, vesselData } = vessel;
  const setVesselAgent = (value) => {
    setVessel(Vessel.copyOf(vessel, updated => {
      updated.vesselAgent = value;
    }));
  };

  const { vessels, contactTypes } = useContext(DataStoreContext);  

  const nameError = useMemo(() => vesselData.name !== undefined && !vesselData.name && t('VesselForm.Errors.EmptyName'), [vesselData.name]);
  const propulsionPowerUnitError = useMemo(() => Boolean(vesselData.propulsionPower && !vesselData.propulsionPowerUnit) && t('VesselForm.Errors.MissingUnits'), [vesselData.propulsionPower, vesselData.propulsionPowerUnit]);

  const imoError = useMemo(() => (vesselData.imo !== undefined && vesselData.imo.length && !validateVesselImo(vesselData.imo))
    ? t('VesselForm.Errors.InvalidImo')
    : vessels.find(v => v.imo === vesselData.imo)
      ? t('VesselForm.Errors.DuplicateImo')
      : null,
    [vesselData.imo]);
  const mmsiError = useMemo(() => (vesselData.mmsi !== undefined && vesselData.mmsi.length && !validateVesselMmsi(vesselData.mmsi))
    ? t('VesselForm.Errors.InvalidMmsi')
    : vessels.find(v => v.mmsi === vesselData.mmsi)
      ? t('VesselForm.Errors.DuplicateMmsi')
      : null,
    [vesselData.mmsi]);

  const createDisabled = useMemo(() =>
    !vesselData.name || nameError || imoError || mmsiError || propulsionPowerUnitError ||
    vesselData?.certificates?.some(c => !c.id || !c.type || !c.typeName || !c.typeCategory || !c.typeCategoryName || !c.validFromDate || !c.validToDate || c.validFromDate > c.validToDate),
    [vesselData.name, vesselData.certificates, nameError, imoError, mmsiError, propulsionPowerUnitError]);

  //Context for file that is about to be uploaded
  const [imageFileState, dispatchImageFileState] = useContext(ImageFileContext);

  // on mounting clean any validation error messages
  // that are not relevant
  useEffect(() => {
    dispatchImageFileState({ type: RESET });
  }, []);

  const imageError = (process, error) => {
    dispatchImageFileState({ error, process });
  }

  const handleCreateFinish = async () => {
    const result = await DataStore.save(Vessel.copyOf(vessel, updated => {
      updated.imo = vessel.vesselData.imo;
      updated.mmsi = vessel.vesselData.mmsi;
      updated.callSign = vessel.vesselData.callSign
    }));
    if(!disablePortcallUpdate) {
      dispatchDataContext({ type: DataContextConstants.SET_PENDING_CREATE_VESSEL, payload: result });
    }
    onBack && onBack();
  }

  const handleCreate = () => {
    if (imageFileState && imageFileState.type) {
      dispatchImageFileState({ type: imageFileState.type, onUpload: handleCreateFinish, onError: imageError });
    } else {
      handleCreateFinish();
    }
  }

  const [page, setPage] = useState(PAGE_MAIN);
  const classes = useStyles();
  if (page === PAGE_VESSEL_AGENT) return <ContactForm contact={vesselAgent} contactType={contactTypes.find(ct => ct.id === ContactTypeIds.VESSEL_AGENT)} onChange={setVesselAgent} showTitle onBack={() => setPage(PAGE_MAIN)} />
  if (page === PAGE_MAIN) return (
    <Box overflow="hidden auto" width="100%">
      <Grid container wrap="nowrap" direction="column" className={classes.root}>

        {showTitle &&
          <Grid item container justifyContent='center' alignItems="center" className={classes.header}>
            {onBack && <IconButton onClick={onBack} style={{ marginLeft: -59, position: 'relative', left: -32 }}><ArrowLeft fontSize="large" /></IconButton>}
            <Typography variant="h2">{t('VesselForm.Labels.CreateVessel')}</Typography>
          </Grid>
        }

        <VesselAnatomyForm
          vessel={vessel}
          setVessel={setVessel}
          nameError={nameError}
          propulsionPowerUnitError={propulsionPowerUnitError}
          imoError={imoError}
          mmsiError={mmsiError}
          createAgent={() => setPage(PAGE_VESSEL_AGENT)}
        />

        <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.footer}>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              dispatchImageFileState({ type: RESET });
              onBack && onBack();
            }}
          >
            <Typography>{t('Common.Buttons.DiscardChanges')}</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={createDisabled}
            style={{ margin: '1rem' }}
            onClick={handleCreate}
            id="CreateVesselButton"
          >
            {t('Common.Buttons.Create')}
        </Button>
        </Grid>

      </Grid>
    </Box>
  );
};
export default VesselForm;