import { Icon, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChartMultiline } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';

import { dashboardConfig } from '../../environment.js';

const useStyles = makeStyles(theme => {
  return {
     navLink: {
      display: 'flex',
      height: '4rem',
      marginBottom: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.25)',
      cursor: 'pointer'
    }
  }
});

const DashboardNavLink = () => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <Tooltip title={t('NavBarVertical.Labels.Dashboard')}>
      <a
        href={`${dashboardConfig.dashboardUrl}`}
        target='_blank'
        rel='noopener noreferrer'
        className={classes.navLink}
      >
        <Icon
          fontSize="large"
        >
          <ChartMultiline fontSize="large" />
        </Icon>
      </a>
    </Tooltip>
  )
}

export default DashboardNavLink