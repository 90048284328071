import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import {
  Alert, 
  FileDocument,
  Fire,
  Leaf,
  PoliceBadge,
 } from 'mdi-material-ui';

import { TodoType as tt } from '../../constants/TodoType';


const useStyles = makeStyles((theme) => {
  return {
    READY: props => ({
      backgroundColor: 'transparent',
      color: props.backgroundColor,
    }),
    DONE: {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.25)',
    },
    Avatar: {
      borderStyle: 'solid',
      borderWidth: '0.125rem',
      transition: 'color 300ms',
    },
    AvatarSmall: {
      width: '1.75rem',
      height: '1.75rem',
    },
    AvatarXSmall: {
      width: '1.25rem',
      height: '1.25rem',
      borderWidth: '0.075rem',
      fontSize: '0.75rem',
      '& > svg': {
        fontSize: '0.75rem'
      }
    }
  }
});


const getIcon = (todoType) => {
  switch (todoType) {
    case tt.EMAIL_DEFRA:
      return <Leaf/>;
    case tt.EMAIL_CERS:
      return 'C';
    case tt.EMAIL_FIRE_BRIGADE:
      return <Fire/>;
    case tt.EMAIL_POLICE:
      return <PoliceBadge/>;
    case tt.DANGEROUS_CARGO:
      return <Alert/>;
    case tt.DIVERS_REQUIRED:
      return 'D';
    case tt.PAPERWORK_RECEIVED:
      return <FileDocument />;
    default:
      return 'T';
  }
}

export const IconMap = ({
  backgroundColor = 'transparent',
  clickHandler,
  small = null,
  xsmall = null,
  item,
}) => {
  const props = {backgroundColor: backgroundColor};
  const classes = useStyles(props);
  return (
    <Avatar
      onClick={() => clickHandler && clickHandler(item)} 
      className={`${classes.Avatar} ${small ? classes.AvatarSmall : ''} ${xsmall ? classes.AvatarXSmall : ''} ${classes[item.status]}`}
    >
      {getIcon(item.todoType)}
    </Avatar>
  )
}