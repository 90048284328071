import React from "react";
import {
  Button,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import ChargeableRow from './ChargeablesRowView';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

// smallText - Total Column Width
// unitCost - UnitCost Column Width 
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  smallText: {
    width: "6rem"
  },
  unitCost: {
    width: "8rem"
  },
  numericInput: {
    width: "12rem"
  }
});

// Component for Table View that contain the chargeable items 
// chargeableItems ([]) - Array of chargeable items
// columns - The columns that make up the table
// setOpen - function that open the editting table for the chargeableItems
const ChargeablesTableView = ({
  chargeableItems,
  columns,
  setOpen,
  currencySymbol,
  disabled,
  actionTime
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Store of whether there are any chargeable items
  const chargeableItemsExist = chargeableItems && chargeableItems.length > 0;

  // Determines the read only columns for the read only view
  const columnsReadOnly = columns.filter(c => c.readOnly === true);

 return (
   <>
    { chargeableItemsExist &&
      <TableContainer className={classes.root} >
        <Table padding="none" stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columnsReadOnly
                .map(({id, className, align, viewLabel, label}) => (
                  <TableCell
                    key={id}
                    className={classes[className]}
                    align={align}
                  >
                    {t(viewLabel || label)}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              { chargeableItems 
                .map((item) => 
                <ChargeableRow 
                  key={item.id}
                  chargeableItem={item} 
                  columns={columnsReadOnly}
                  currencySymbol={currencySymbol}
                  actionTime={actionTime}
                />)
              }
          </TableBody>
        </Table>
      </TableContainer>
    }
    <Box marginTop="0.5rem" display="flex" justifyContent="flex-end">
      <Button 
        id="ChargeablesViewTableButton" 
        disabled={disabled}
        color="primary" 
        variant="contained" 
        onClick={ () => setOpen() }
      >
        <Typography>
          { chargeableItemsExist
            ? t('ChargeableItems.Buttons.Update') 
            : t('ChargeableItems.Buttons.Add') 
          }
        </Typography>
      </Button>
    </Box>
  </>
  );
}

// PropTypes for Chargeable table
ChargeablesTableView.propTypes = {
  chargeableItems: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string
}

// Default Props for Chargeable Table
ChargeablesTableView.defaultProps = {
  chargeableItems: [],
  columns: [],
  setOpen: null,
  currencySymbol: ""
}

export default ChargeablesTableView;