import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { setSeconds, addMinutes, subMinutes, isBefore, isAfter, parseISO } from 'date-fns';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DialogLoadingProgress from './DialogLoadingProgress';

const useStyles = makeStyles(theme => ({
  warningMessage: {
    color: '#ff9800'
  }
}));

/**
 * Simple confirm dialog
 */
const TimeInputDialog = ({ open, title, inputLabel, initialValue, onConfirm, onClose, message, warningMessage, checks, hasComment, modal, loading, error}) => {
  // State to store the value of the Date Input
  const [value, setValue] = useState(initialValue);
  const [comment, setComment] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();

  // State to store the error Message if there is one
  const [errorMessage, setErrorMessage] = useState() 

  // Number of checks for the value to be set
  const isChecks = checks.length > 0

  // Function to check value against checks
  // Sets ErrorMessage if a check fails
  // The first check failed with be flagged
  // Depending on whether it's before or after
  // A minute will be added or subtracted to ensure that an equal time isn't accepted
  const handleCheckTime = (value) => {
    const checkFail = checks.find(({time, before}) => before 
      ? isBefore(subMinutes(parseISO(time), 1), parseISO(value)) 
      : isAfter(addMinutes(parseISO(time), 1), parseISO(value)) 
    )
    checkFail ? setErrorMessage(t(checkFail.message)) : setErrorMessage(null)
    setValue(value)
  };

  const handleClose = (event, reason) => {
    if(modal && reason === 'backdropClick') return;
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={handleClose} id='TimeInputDialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {warningMessage && <DialogContentText id='TimeInputDialogWarningMessage' className={classes.warningMessage}>{warningMessage}</DialogContentText>}
        {message && <DialogContentText>{message}</DialogContentText>}
        <StringKeyboardDateTimePicker
          warningPastDate={true}
          id={'TimeInputDialogTimetInput'}
          label={inputLabel}
          fullWidth
          margin="normal"
          value={value}
          onChange={value => isChecks ? handleCheckTime(value) : setValue(value)}
          showInlineNowButton
        />
        <FormHelperText error >{errorMessage || ""}</FormHelperText>
        {hasComment &&
          <TextField
            id={'TimeInputDialogCommentInput'}
            variant="outlined"
            label={t('Common.Labels.Comments')}
            fullWidth
            multiline
            maxRows={3}
            margin='normal'
            value={comment}
            onChange={value => setComment(value.currentTarget.value)}
          />
        }
        <DialogLoadingProgress 
          loading={loading}
          errorMessage={error?.message}
          errorCode={error?.errorCode}
        />
      </DialogContent>
      <DialogActions>
        <div id={'TimeInputDialogCancelButton'}>
          <Button
            onClick={onClose}
            color="default"
            disabled={loading}
          >
            {t('Common.Buttons.Cancel')}
          </Button>
        </div>
        <div id={'TimeInputDialogConfirmButton'}>
          <Button
            disabled={!value || !!errorMessage || loading}
            onClick={() => onConfirm(value, comment)}
            color="primary"
          >
            {t('Common.Buttons.Confirm')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

TimeInputDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  inputLabel: PropTypes.string,
  initialValue: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  checks: PropTypes.array,
  message: PropTypes.string,
  modal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object
};

TimeInputDialog.defaultProps = {
  title: 'Dialog Title',
  inputLabel: 'Time Label',
  initialValue: setSeconds(new Date(), 0).toISOString(),
  checks: [],
  message: null,
  modal: false,
  loading: false,
  error: undefined
}

export default TimeInputDialog;