// Colors for the chargeable Items
export const ChargeableItemColors = {
  override: "rgb(000,150,214)",
  error: "#f44336",
};

// Column Headers for Chargeable Table
// id - For the column heading
// label - Label for the column heading
// readOnly - Boolean to determine which headings are for the view or edit table
//className - gives the heading a className so that css properties can be determined - e.g. width of column
export const COLUMNS = [
  {
    id: "tariff",
    label: "ChargeableItems.Labels.Tariff",
    readOnly: true,
    className: "tariff"
  },
  {
    id: "notes",
    label: "ChargeableItems.Labels.Notes",
    readOnly: true,
    className: "notes",
    align: "left"
  },
  {
    id: "unitCost",
    label: "ChargeableItems.Labels.UnitCost",
    readOnly: true,
    size: "small",
    className: "unitCost",
    align: "center",
  },
  {
    id: "quantity",
    label: "ChargeableItems.Labels.Quantity",
    readOnly: true,
    className: "numericInput",
    align: "center",
    viewLabel: "ChargeableItems.Labels.QuantityShort"
  },
  {
    id: "total",
    label: "ChargeableItems.Labels.Total",
    readOnly: true,
    size: "small",
    className: "smallText",
    align: "center",
  },
  {
    id: "delete",
    label: "ChargeableItems.Labels.Empty",
    readOnly: false,
    className: "icon"
  },
]

