/**
 * Formats currency usign the browser API IntlNumberFormat
 * @param {String} locale e.g en-UK
 * @param {String} currency e.g GBP
 * @param {String} value e.g 20
 * @returns {String} e.g £20
 * 
 * Returns the color and a contrast  
 */
export const IntlNumberFormat = (locale, currency, value) => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });
  
  return numberFormat.format(value);
}

