export const LOGIN_CONSTANTS = {
  Authentication:{
    Common:{
      Buttons:{
        SignIn:"Sign In",
        ForgotPassword:"Forgot Password",
        Next:"Next",
        BackToSignIn:"Back to Sign In"
      },
      Labels:{
        SignIn:"Sign in",
        EnterUsername:"Enter your username",
        EnterPassword:"Enter your password",
        EnterInformation:"Enter Information:",
        EnterConfirmationCode:"Enter your Confirmation Code",
        AllRightsReserved:"All Rights Reserved",
        ResetPassword:"Reset Password",
        Verification:"Verification",
        SendVerification:"Please provide a username and we'll send you a verification code to your registered email."
      }
    }
  }
}