import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { Attrs } from '../constants';
import { profileFromShipType } from '../utils/AisTypes';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const styleCache = new Map();

const debug = false;

const createTextStyle = (feature, resolution, dom) => {
  let lbl = feature.get(Attrs.NAME);
  if(debug) lbl += 
    '\n' + feature.get('meta').positionTimestamp
    + '\n' + parseInt(dayjs.duration(dayjs.utc().diff(new Date(feature.get('meta').positionTimestamp))).as('minutes'), 10)
    + '\n' + profileFromShipType(feature.get('meta').shipType).name;
  let style = styleCache.get('text_' + lbl);
  if(!style) { 
    style = new Style({
      text: new Text({
        font: '11px Helvetica, Arial, sans-serif',
        text: lbl,
        textAlign: 'left',
        overflow: true,
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        }),
        offsetX: 10,
        offsetY: 10
      })
    });
    styleCache.set('text_'+lbl, style);
  }

  return style; 
};

class VesselLabelLayer extends VectorLayer {
  constructor(opt_options) {
    opt_options.style = createTextStyle;
    super(opt_options);
  }; 
};

export default VesselLabelLayer;