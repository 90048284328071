import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import { Box, Typography, Button, IconButton, Menu, MenuItem, ListItemText, ListItemIcon, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import { DotsVertical, Export, InformationOutline, Sync } from 'mdi-material-ui';
import VesselListItem from '../Vessel/VesselListItem';
import VesselDimensionsListItem from '../Vessel/VesselDimensionsListItem';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import DataTable from '../../containers/DataTable';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';
import VesselTooltip from '../../components/Tooltip/VesselTooltip';
import { VesselTableHeaders } from '../../constants/VesselListLabels';
import { useTranslation } from 'react-i18next'; 
import DataBrowserSidebar, { DataBrowserCertificateTypeFilter, DataBrowserVesselFilter } from '../DataBrowserSidebar/DataBrowserSidebar';
import { DataStore } from 'aws-amplify';
import { CertificateType, Vessel } from '../../models';
import useDataBrowserFilter from '../DataBrowserSidebar/useDataBrowserFilter';
import CertificateList from '../Certificate/CertificateList';
import { sortCertificatesByValidFrom, sortVesselsByName } from '../../utils/sorters';
import { isValid } from '../../utils/certificates';
import ExportVesselsDialog from './ExportVesselsDialog';
import { getOpenPortCalls, getVesselFromDS, refreshVesselData, updateOpenPortCalls, updateVesselData } from './utils';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';
import SpotlightMenuOption from '../Spotlight/SpotlightMenuOption';
import { NavigationContext, navigationActionConstants } from '../../contexts/navigation';
import RouteConstants from '../../constants/RouteConstants';

const useStyles = makeStyles(theme => ({
  scrollSnap: {
    scrollSnapType: 'y proximity',
    scrollPadding: '2.5rem 0 0 0',
  }
}));

const tableVersion = 0.03;

const filterOptions = {
  key: "smartport::preferences::VesselList",
  initialValue: {
    vessel: null,
    certificateTypes: [],
    certificateCategories: []
  },
  hydrateFunctions: {
    vessel: async i => await DataStore.query(Vessel, i.id),
    certificateTypes: async i => await DataStore.query(CertificateType, c => c.or(c => i.reduce((acc, cur) => acc.id("eq", cur.id), c))),
    certificateCategories: async i => await DataStore.query(CertificateType, c => c.or(c => i.reduce((acc, cur) => acc.id("eq", cur.id), c))),
  }
};

const VesselList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filter, setFilter] = useDataBrowserFilter(filterOptions);
  const { vessels } = useContext(DataStoreContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [vesselData, setVesselData] = useState();
  const [,dispatchNavigationContext] = useContext(NavigationContext);
  
  useEffect(() => {
    dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: RouteConstants.VESSEL_LIST });
  }, [dispatchNavigationContext]);

  const sortedVessels = useMemo(() => vessels
    .filter(v =>
      (!filter.vessel || v.id === filter.vessel.id) &&
      // has currently valid certificate categories
      (!Boolean(filter.certificateCategories?.length) || filter.certificateCategories.every(cc => v.vesselData?.certificates?.some(c => c.typeCategory === cc.id && isValid(c.validFromDate, c.validToDate, new Date().toISOString(), new Date().toISOString())))) &&
      // has currently valid certificate types
      (!Boolean(filter.certificateTypes?.length) || filter.certificateTypes.every(ct => v.vesselData?.certificates?.some(c => c.type === ct.id && isValid(c.validFromDate, c.validToDate, new Date().toISOString(), new Date().toISOString())))))
    .sort(sortVesselsByName)
    ,[vessels, filter]);


  const handleVesselDataUpdate = async (vesselId, imo, mmsi) => {
    const vesselDS = await getVesselFromDS(vesselId);

    let vesselExternalData;
    try{
      const { data: { getVesselExternal } } = await refreshVesselData(imo, mmsi);
      vesselExternalData = getVesselExternal?.vessel?.vesselData
    }catch(err){
      console.error('External vessel lookup is not available - ', err)
    }
    if(vesselExternalData) {
      if(vesselDS?.vesselData !== vesselExternalData) {
        const updatedExternalData = {...vesselExternalData, certificates: vesselDS?.vesselData?.certificates }
        setVesselData({ id: vesselId, vesselData: updatedExternalData});
        const ps = await getOpenPortCalls(vesselId);
        setShowConfirm(ps && ps.length > 0);
        await updateVesselData(vesselId, updatedExternalData)
      }
    }
  }

  const handleConfirmUpdateOpenPortCall = async () => {
    if(vesselData && vesselData?.id){
      await updateOpenPortCalls(vesselData?.id, vesselData?.vesselData)
    }
    setShowConfirm(false);
  }

  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [vesselMapData, setVesselMapData] = useState(null);

  const defaultColumns = [
    {
      id: 'vesselData',
      label: VesselTableHeaders.Vessel,
      format: (val) => 
        <PopoverTooltip tooltip={<VesselTooltip vesselData={val} setMapDialogOpen={setMapDialogOpen} setVesselMapData={setVesselMapData}/>} delay={800}>
          <VesselListItem
            id='vessel'
            disableGutters={true}
            vesselData={val}
            disabled={!val}
          />
        </PopoverTooltip>,
      visible: true,
      width: 14
    },
    {
      id: 'mmsi',
      label: VesselTableHeaders.MMSI,
      format: val => val && <Typography id='VesselMMSI' noWrap>{val}</Typography>,
      visible: true,
      width: 7
    },
    {
      id: 'callSign',
      label: VesselTableHeaders.CallSign,
      format: val => val && <Typography id='VesselCallSign' noWrap>{val}</Typography>,
      visible: true,
      width: 7
    },
    {
      id: 'dimensions',
      label: VesselTableHeaders.Dimensions,
      format: (val) => <VesselDimensionsListItem
        id='dimensions'
        lengthOverall={val?.lengthOverall}
        beam={val?.beam}
        draught={val?.draught}
      />,
      visible: true,
      width: 10
    },
    { 
      id: 'grossTonnage',
      label: VesselTableHeaders.GrossTonnage,
      format: val => val > 0 && <Typography id='grossTonnage' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 5
    },
    {
      id: 'netTonnage',
      label: VesselTableHeaders.NetTonnage,
      format: val => val > 0 && <Typography id='netTonnage' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 5
    },
    {
      id: 'deadWeightTonnage',
      label: VesselTableHeaders.DeadweightTonnage,
      format: val => val > 0 && <Typography id='deadWeightTonnage' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 5
    },
    {
      id: 'portOfRegistry',
      label: VesselTableHeaders.PortOfRegistry,
      format: val => val && <Typography id='portOfRegistry' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 10
    },
    {
      id: 'yearBuilt',
      label: VesselTableHeaders.YearBuilt,
      format: val => val && <Typography id='yearBuilt' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 8
    },
    {
      id: 'type',
      label: VesselTableHeaders.Type,
      format: val => val && <Typography id='type' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 12
    },
    {
      id: 'flag',
      label: VesselTableHeaders.Flag,
      format: val => val && <Typography id='flag' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 6
    },
    {
      id: 'certificates',
      label: VesselTableHeaders.Certificates,
      format: (val) => val?.length > 0 && 
        <CertificateList 
          certificates={val.slice().sort(sortCertificatesByValidFrom)} 
          limit={2} showMore fromDate={new Date()} toDate={new Date()}
        />,
      visible: true,
      width: 30
    },
    {
      id: 'menu',
      label: '',
      format: (vessel, val) => <ItemMenu vesselData={vesselData} setVesselData={setVesselData} val={val} handleVesselDataUpdate={handleVesselDataUpdate} key={`VesselListItemMenuItem-${vessel?.id}`} vesselId={vessel?.id} imo={vessel?.imo} mmsi={vessel?.mmsi} setMapDialogOpen={setMapDialogOpen}/>,
      visible: true,
      width: 2.625
    }
  ];

  const initialOrder = defaultColumns.map(item => item.id);
  const initialVisible = defaultColumns.reduce((accumulator, currentItem) => {
    accumulator[currentItem.id] = currentItem.visible;
    return accumulator;
  }, {});

  const transformVesselItems = useCallback((_vessels) => {
    return _vessels && _vessels 
      .map(item => {
        return {
          id: item?.id,
          name: item?.vesselData?.name,
          imo: item?.imo,
          mmsi: item?.mmsi,
          callSign: item?.callSign,
          vesselData: item?.vesselData,
          dimensions: {
            lengthOverall: item?.vesselData?.lengthOverall,
            beam: item?.vesselData?.beam,
            draught: item?.vesselData?.draught
          },
          grossTonnage: item?.vesselData?.grossTonnage,
          netTonnage: item?.vesselData?.netTonnage,
          deadWeightTonnage: item?.vesselData?.deadWeightTonnage,
          portOfRegistry: item?.vesselData?.portOfRegistry?.name,
          yearBuilt: item?.vesselData?.yearBuilt,
          type: item?.vesselData?.type,
          flag: item?.vesselData?.flag,
          menu: {
            id: item?.id,
            imo: item?.imo,
            mmsi: item?.mmsi
          },
          certificates: item?.vesselData?.certificates
        }
      })
  }, [])

  const [transformedVesselItems, setTransformedVesselItems] = useState(transformVesselItems(sortedVessels))

  useEffect(() => {
    setTransformedVesselItems(transformVesselItems(sortedVessels))
  }, [sortedVessels])

  const [showExport, setShowExport] = useState(false);

  return (
    <Box
      id="VesselList"
      display="flex"
      width="100%"
    >
      <DataBrowserSidebar 
        label={t("NavBarVertical.Labels.Vessels")}
        exportButton={
          <Button id="OpenExportVesselButton" onClick={() => setShowExport(true)} endIcon={<Export fontSize="small" />} disabled={!transformedVesselItems.length} disableRipple>
            <Typography variant="caption" style={{ lineHeight: "initial" }}>{t("Common.Buttons.Export")}</Typography>
          </Button>
        }
      >
        <DataBrowserVesselFilter
          id="vessellist-filter-vessel"
          filter={filter}
          setFilter={setFilter}
        />
        <DataBrowserCertificateTypeFilter
          id="vessellist-filter-certificateCategories"
          name="certificateCategories"
          typesName="certificateTypes"
          filter={filter}
          setFilter={setFilter}
          isCategory
        />
        <DataBrowserCertificateTypeFilter
          id="vessellist-filter-certificateTypes"
          name="certificateTypes"
          filter={filter}
          setFilter={setFilter}
          allowedCategories={filter.certificateCategories}
        />
        <ExportVesselsDialog open={showExport} onClose={() => setShowExport(false)} vessels={transformedVesselItems} />
      </DataBrowserSidebar>
      <Box
        overflow="auto"
        height="100%"
        flexGrow="1"
        className={classes.scrollSnap}
      >
        {transformedVesselItems ?
          <DataTable
            id={'vessels'}
            columns={defaultColumns}
            showHeader={true}
            rows={transformedVesselItems}
            initialOrder={initialOrder}
            initialVisible={initialVisible}
            autoSavePreferences={true}
            tableVersion={tableVersion}
          />
          : <Loading />
        }
      </Box>
      <ConfirmDialog
        key={'UpdateOpenPortCallConfirmDialog'}
        open={showConfirm}
        title={t("VesselForm.Labels.UpdateVessel")}
        message={t("VesselForm.Labels.UpdatePortCallOpenMessage")}
        onConfirm={handleConfirmUpdateOpenPortCall}
        onClose={() => setShowConfirm(false)}
      />
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={vesselMapData}
        /> 
      }
    </Box>
  )
}

const ItemMenu = ({vesselId, imo, mmsi, handleVesselDataUpdate, val}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [syncing, setSyncing] = useState(false);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  const handleVesselDetailsClicked = () => {
    setAnchorEl(null);
    history.push(`/vessels/${vesselId}`);
  }

  const handleRefreshVesselData = async () => {
    setSyncing(true)
    await handleVesselDataUpdate(vesselId, imo, mmsi)
    setSyncing(false);
    setAnchorEl(null);
  }

  const noImoAndMmsi = !Boolean(imo) && !Boolean(mmsi);

  const handleOpenMapDialog = () => {
    setAnchorEl(null);
    setMapDialogOpen(true);
  };


  return (
    <>
      <IconButton
        onClick={e => { setAnchorEl(e?.currentTarget); e?.preventDefault(); }}
        className={`VesselListItemMenuButton`}
        style={{marginTop: '14px', marginBottom: '14px'}}
      >
        <DotsVertical />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={handleVesselDetailsClicked}
          id="VesselDetailsMenuItem"
        >
          <ListItemIcon>
            <InformationOutline />
          </ListItemIcon>
          <ListItemText primary={t('VesselList.Labels.ShowVesselDetails')} />
        </MenuItem>
        <Tooltip title={noImoAndMmsi ? t("VesselDataEdit.Labels.DisabledRefresh") : ''}>
          <div>
            <MenuItem
              onClick={handleRefreshVesselData}
              id="RefreshVesselDataMenuItem"
              disabled={noImoAndMmsi}
              >
              <ListItemIcon >
                {syncing ? <CircularProgress size='1rem'/> : <Sync />}
              </ListItemIcon>
              <ListItemText primary={t('VesselDataEdit.Labels.RefreshVesselData')} />
            </MenuItem>
          </div>
        </Tooltip>
        <SpotlightMenuOption handleOpenMapDialog={handleOpenMapDialog}/>
      </Menu>
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={val}
        /> 
      }
    </>
  )
}

export default VesselList;
