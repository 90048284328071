import {croppedImageDimensions} from '../constants/Image';

export const parseImageData = (imageData) => {
  if(!imageData) return {key: null, count: null};
  const {photoKey: key = null, photoCount: count = null} = JSON.parse(imageData);
  return {key, count}
}

export const stringifyImageData = (photoKey, photoCount) => {
  const imageData = JSON.stringify({photoKey, photoCount});
  return imageData;
} 

//Takes in a width or height and returns the corresponding width or height depending on the 16:9 ration
export const imageRatio = ({width: inputWidth = null, height: inputHeight = null, unit = "rem"}) => {
  let returnWidth = inputWidth;
  let returnHeight = inputHeight;
  // let returnUnits = unit;
  if(inputWidth && Number.isInteger(inputWidth)) {
    returnHeight = inputWidth * (9/16);
  } else if (inputHeight && Number.isInteger(inputHeight)) {
    returnWidth = inputHeight * (16/9);
  } else {
    returnWidth = `${croppedImageDimensions.width}px`;
    returnHeight = `${croppedImageDimensions.height}px`; 
    // returnUnits = "px";
  } 
  return ({width: `${returnWidth}${unit}`, height: `${returnHeight}${unit}`});
}