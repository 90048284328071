import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PackageVariant } from 'mdi-material-ui';

import { MovementActionType } from '../../constants/MovementActionType';
import { parseCargoManifest } from '../../utils/parseCargoManifest';

import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(theme => ({}));

const CargoListItem = ({
  cargo,
  disableGutters,
  icon,
  text,
  showCount,
  showAmount,
  selected,
  label,
  onlyIncoming,
  onlyOutgoing,
  ...otherProps
}) => {
  const getLabel = useCallback(item => showAmount ? `${item.name} (${item.amount}${item.units ? ' ' + t(item.units) : ''})` : item.name, [showAmount]);

  const classes = useStyles();
  const { t } = useTranslation();
  const manifest = cargo && cargo.manifest && cargo.manifest ? cargo.manifest : null;
  const filteredManifest = manifest?.filter(c => c.deleted !== true);

  const { incoming, outgoing, other } = parseCargoManifest(filteredManifest, cargo);

  let primary = '';
  let secondary = '';
  let count = other && other.length;

  if (manifest) {
    if ((cargo.movementType.includes(MovementActionType.ARRIVAL) || onlyIncoming) && !onlyOutgoing) {
      primary = incoming.length > 0 ? getLabel(incoming[0]) : other.length > 0 ? getLabel(other[0]) : '';
      count = count + (incoming && incoming.length);
    } else if (cargo.movementType.includes(MovementActionType.DEPARTURE) || onlyOutgoing) {
      primary = outgoing.length > 0 ? getLabel(outgoing[0]) : other.length > 0 ? getLabel(other[0]) : '';
      count = count + (outgoing && outgoing.length);
    }
    
    if (count > 1) secondary = `(+${count - 1} ${t('Cargo.Labels.More')})`;
  }

  return (
    <ListItem
      component="div"
      disableGutters={disableGutters}
      selected={selected}
      {...otherProps}
    >
      {icon && manifest && manifest.filter(c => c.deleted !== true).length > 0 
        ? <ListItemIcon>
          <PackageVariant className={classes.iconRoot}></PackageVariant>
        </ListItemIcon>
        : ''
      }
      {text &&
        <ListItemText
          primary={primary}
          primaryTypographyProps={{
            component: 'p',
            noWrap: true,
            variant: 'body1'
          }}
          secondary={(count && label ? label + ' ' : '') + secondary}
          secondaryTypographyProps={{
            component: 'p',
            noWrap: true,
            variant: 'body2'
          }}
        />
      }
      {showCount
        ? <ListItemText
          primary={manifest.length}
          primaryTypographyProps={{
            component: 'p',
            noWrap: true,
            variant: 'body1'
          }}
        />
        : ''
      }
    </ListItem>
  );
};

CargoListItem.defaultProps = {
  text: true,
  icon: false,
  showCount: false,
  disableGutters: true,
  selected: false,
};

CargoListItem.propTypes = {
  // whether to render item with text
  text: PropTypes.bool,
  // whether to render item with
  icon: PropTypes.bool,
  // whether to render item with
  showCount: PropTypes.bool,
  // whether to diable gutters
  diableGutters: PropTypes.bool,
  // passed to ListItem
  selected: PropTypes.bool,
  // cargo information
  cargo: PropTypes.object,
}

export default CargoListItem;