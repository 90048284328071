import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UIContext } from '../../contexts/ui';
import { Action, ActionState } from '../../models';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { checkPlannedTime } from '../../utils/customAction';
import { useTranslation } from 'react-i18next';

export const CustomActionScheduleEdit = ({
  action,
  setAction,
  portCall,
}) => {
  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const { t } = useTranslation();

  // State to store time planned 
  // Allows errors to be rendered before action is updated with inputted time
  const [timePlanned, setTimePlanned ] = useState(action.timePlanned);

  // State to store any error message with timePlanned
  const [error, setError] = useState(null);

  // useEffect will update time planned if the action changes
  useEffect(() => {
    if(!action || !action.timePlanned) return;
    setTimePlanned(action.timePlanned);
    setError(null)
  }, [action, setTimePlanned, setError])

  // LifeCycle from Action Type
  const lifeCycle = action && action.type.lifecycle;

  // Handle the change of time for planned
  // Set the state of time planned to inputted value
  // Check the inputted time planned value against other actions
  // if there is an error then set the error state to show
  // else update action to the new timePlanned
  const handleTimePlannedChange = useCallback((value) => {
    setTimePlanned(value); 
    const checkDeparture = false;
    const errorString = checkPlannedTime(value, lifeCycle, portCall,checkDeparture);
    if(errorString) {
      setError(t(errorString));
    } else {
      setError(null);
      setAction(Action.copyOf(action, updated => { updated.timePlanned = value; }));
    }
  },[action, lifeCycle, portCall, setAction, t]);

  return (
    <>
      {/* Planned time date picker */}
      {/* Lifecycle determines Labels */}
      <StringKeyboardDateTimePicker 
        warningPastDate={true}
        id={'ActionSchedulePlannedTime'}
        disabled={readOnly || action.state === ActionState.COMPLETED || action.state === ActionState.IN_PROGRESS}
        label={t('ActionScheduleEdit.Labels.PlannedTime')}
        fullWidth
        margin="normal"
        value={timePlanned}
        okLabel={t('CustomActionScheduleEdit.Labels.SetPlannedTime')}
        onChange={handleTimePlannedChange}
        error={!!error}
        helperText={error}
      />
      <StringKeyboardDateTimePicker
        id={'ActionScheduleStartTime'}
        disabled={true}
        label={t('CustomActionScheduleEdit.Labels.StartTime') }
        fullWidth
        margin="normal"
        value={action.timeActual}
        allowEmptyValue
      />
      <StringKeyboardDateTimePicker
        id={'ActionScheduleCompletionTime'}
        disabled={true}
        label={t('CustomActionScheduleEdit.Labels.CompletionTime') }
        fullWidth
        margin="normal"
        value={action.timeCompleted}
        allowEmptyValue
      />
      {/* Actual time of Action if there is lifecycle */}
      {
        lifeCycle && 
        <StringKeyboardDateTimePicker
          disabled={true}
          ampm={false}
          label={t('ActionScheduleEdit.Labels.ActualTime')}
          fullWidth
          margin="normal"
          value={action.timeActual}
          okLabel={t('ActionScheduleEdit.Buttons.SetActualTime')}
        />
      }
    </>
  )
}

CustomActionScheduleEdit.propTypes = {
  action: PropTypes.object,
  portCall: PropTypes.object,
  setAction: PropTypes.func,
}

export default CustomActionScheduleEdit;
