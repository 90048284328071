import { useState, useEffect, useCallback } from 'react'
import { Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { parseAISReceiverTimestamp, parsePosition } from '../utils/ais-utils';
import { AISStatusConfig } from '../../../environment.js';
import useAISReceiverStatusSettings from '../../../hooks/useAISReceiverStatusSettings';
dayjs.extend(duration);

const COLOR = {
  DEFAULT: "#989898", GOOD: "#238823", WARNING: "#ffbf00", CRITICAL: "#d2222d"
}
const AIS_STATUS_UPDATE_INTERVAL = 1000 * (AISStatusConfig?.updateInterval ?? 15) * 60

const WARNING_DEFAULT_MINUTES = 60 * 12;
const CRITICAL_DEFAULT_MINUTES = 60 * 24;

//This is the default threshold/color for all receviers. This will be overwritten by settings for each receiver
const DEFAULT_THRESHOLDS = {
  GOOD: {
    range: [0, WARNING_DEFAULT_MINUTES - 1],
    color: COLOR.GOOD
  },
  WARNING: {
    range: [WARNING_DEFAULT_MINUTES, CRITICAL_DEFAULT_MINUTES - 1],
    color: COLOR.WARNING
  },
  CRITICAL: {
    range: [CRITICAL_DEFAULT_MINUTES],
    color: COLOR.CRITICAL
  }
}

const parseAISStatus = (AIS_LOG) => {
  return AIS_LOG?.map(r => {
    return {
      receiverName: r?.receiverName,
      lastMessage: r?.lastMessage,
      ...parsePosition(r?.receiverPosition)
    }
  }) ?? [];
} 

const useAISStatus = () => {

  const { aisThresholdMap, updateAisStatusSetting } = useAISReceiverStatusSettings();

  const [ receiversStatus, setReceiversStatus ] = useState([]);

  //This function can be used to get the color of a receiver based on last message timestamp received
  const getColor = useCallback((receiverName, lastTimestamp) => {
    const minutes = dayjs().diff(parseAISReceiverTimestamp(lastTimestamp), 'minutes');
    
    const thresholds = (aisThresholdMap && aisThresholdMap[receiverName]) ?? DEFAULT_THRESHOLDS;

    const found = thresholds && Object.entries(thresholds).find(threshold => {
      return (threshold[1].range[1] !== undefined) 
      ? minutes >= threshold[1].range[0] && minutes <= threshold[1].range[1]
      : minutes >= threshold[1].range[0]
    });
    if(!found) {
      return COLOR.DEFAULT;
    }
    return found[1].color;
  }, [aisThresholdMap])

  const fetchAISStatus = () => {
    Storage.get(AISStatusConfig.smartportS3, { download: true, cacheControl: 'no-cache' })
      .then(data => {
        data?.Body?.text().then(string => { 
          try{
            setReceiversStatus(parseAISStatus(JSON.parse(string)))
          }catch(err){
            //console.error('Error in parsing ais status file');
          }
        })
      })
      .catch(err => {
        //console.error('AIS file status get error: ', err);
      });
  }

  useEffect(() => {
    if(receiversStatus.length > 0 && aisThresholdMap){
      const thresholdMap = Object.values(receiversStatus).reduce((acc, cur) => {
        return {...acc, [cur?.receiverName]: aisThresholdMap[cur?.receiverName] || DEFAULT_THRESHOLDS};
      }, {})
      updateAisStatusSetting(thresholdMap)
    }
  }, [receiversStatus])

  useEffect(() => {
    const interval = setInterval(fetchAISStatus, AIS_STATUS_UPDATE_INTERVAL);
    fetchAISStatus();
    return () => { clearInterval(interval); }
  }, [])

  return {
    receiversStatus,
    getColor
  }
}

export default useAISStatus