import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, List, ListItem, ListSubheader, makeStyles, Tooltip, Typography, Menu, MenuItem, ListItemText, ClickAwayListener, Tabs, Tab } from '@material-ui/core';
import { Delete, Information, Lock, Plus, ContentCopy } from 'mdi-material-ui';
import { DataStore } from 'aws-amplify';
import isEqual from 'lodash.isequal';
import useQuery from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import ConfirmDialog from '../Dialog/ConfirmDialog';

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    paddingBottom: "0 !important"
  },
  list: {
    overflowY: "auto"
  },
  listItem: {
    height: "2.5rem",
    '&:not(:last-child)': {
      borderBottom: "1px solid rgba(0,0,0,0.1)"
    }
  },
  listItemSecondary: {
    opacity: 0.5
  },
  right: {
    // flex parameters are dynamically set in the main component with flexBasis set with formWidth prop
    borderLeft: "0.0625rem solid #e0e0e0",
    display: "flex",
    flexDirection: "column"
  },
  information: {
    paddingLeft: ".25rem",
    opacity: 0.5
  }
}));

const FormWrap = ({ value, model, onClose, onUpdate, canSave, preSave, canUpdate, FormContent, SaveDialogContent, isUnique, tabIndex, setSaveIsDisabled }) => {
  const [_value, _setValue] = useState(value);
  const [ showSaveDialog, setShowSaveDialog ] = useState(false);

  useEffect(() => _setValue(value), [value, _setValue]);

  const onChange = (e) => {
    _setValue(model.copyOf(_value, updated => updated[e.target.name] = e.target.value));
  };
  const onChangeCheckbox = (e) => {
    _setValue(model.copyOf(_value, updated => updated[e.target.name] = e.target.checked));
  };
  const onChangeNumber = (e) => {
    _setValue(model.copyOf(_value, updated => updated[e.target.name] = parseFloat(e.target.value)));
  };
  const onChangeRaw = (v) => {
    _setValue(v);
  }
  const isUniqueValue = (isUnique && isUnique(_value)) ?? true;
  const isEqualValue = isEqual(value, _value);
  const [validName, setValidName] = useState(true)
  const saveDisabled = isEqualValue || !canSave(_value, tabIndex) || !isUniqueValue || !validName;
  const disabled = !canUpdate(_value);
  const { t } = useTranslation();

  useEffect(() => {
    setSaveIsDisabled(saveDisabled)
  }, [saveDisabled])

  return (
    <>
      <Prompt
        when={!saveDisabled} 
        message={(_, action) => {
          return action === "PUSH" ? t('AdminModelEditor.Labels.UnsavedChangesMessage') : null;
        }}
      />
      <Box id="AdminModelEditorFormContent" display="flex" flexDirection="column" flexGrow="1">
        <AdminModelEditorFormHeader
          readOnly={disabled}
          disabled={saveDisabled}
          onSave={() => {
            if(preSave && value && preSave(value, _value)) {
              setShowSaveDialog(true);
            } else {
              onUpdate(_value)
            }
          }}
          onClose={onClose}
        />
        <Box display="flex" flexDirection="column" flexGrow="1" overflow="hidden auto" p=".5rem 0">
          <Box flexShrink="0" display="flex" flexDirection="column" flexGrow="1">
            <FormContent 
              outerValue={value} 
              value={_value} 
              disabled={disabled} 
              onChange={onChange} 
              onChangeCheckbox={onChangeCheckbox} 
              onChangeNumber={onChangeNumber} 
              onChangeRaw={onChangeRaw} 
              validation={{isUnique: isUniqueValue, isEqual: isEqualValue}} 
              tabIndex={tabIndex}
              setValidName={setValidName}
            />
          </Box>
        </Box>
      </Box>
      <Dialog open={showSaveDialog}>
        <DialogTitle id='AdminModelEditorConfirmSaveTitle'>{t('AdminModelEditor.Labels.ConfirmSave')}</DialogTitle>
        {SaveDialogContent && <DialogContent>
          <SaveDialogContent lastValue={value} value={_value} />
        </DialogContent>}
        <DialogActions>
          <Button
            id='AdminModelEditorConfirmSaveCancelButton'
            onClick={() => setShowSaveDialog(false)}
            color="default"
          >
            {t('Common.Buttons.Cancel')}
          </Button>
          <Button 
            id="AdminModelEditorConfirmSaveSaveButton"
            onClick={() => {
              onUpdate(_value)
              setShowSaveDialog(false)
            }} 
            color="primary"
          >
            {t('Common.Buttons.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AdminModelEditorFormHeader = ({ readOnly, onSave, onClose, disabled }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="flex-end" height="4rem" paddingBottom="1rem" flexShrink="0">
      {readOnly && <Typography variant="caption" style={{ opacity: 0.5 }}> {t('AdminModelEditor.Labels.ReadOnly')}</Typography>}
      <Box flexGrow="1" />
      <Button id={'EditorCloseButton'} onClick={onClose}>{t('Common.Buttons.Close')}</Button>
      {!readOnly && <Button id={'EditorSaveButton'} onClick={onSave} disabled={disabled} variant="contained" color="primary">{t('Common.Buttons.Save')}</Button>}
    </Box>
  );
};

export const AdminModelEditorHeaderColumn = ({ label, flexBasis, helperText }) => {
  const classes = useStyles();
  return (
    <Box flex={`1 1 ${flexBasis}`} display="flex" alignItems="center">
      <Typography>{label}</Typography>
      {helperText &&
        <Tooltip title={helperText} placement="top">
          <Information fontSize="small" className={classes.information} />
        </Tooltip>
      }
    </Box>
  );
};

export default ({
  HeaderContent,       // List header component (required)
  ListItemContent,     // List item component (required)
  FormContent,         // Form editor component (required)
  CustomHeader,        // Custom header shown above list
  SaveDialogContent,   // Contents of save confirmation dialog
  items,               // Custom list of items if not using DataStore
  model,               // DataStore model
  sort,                // Sort function
  filter,              // Filter function
  condition,           // DataStore condition used when querying for items
  canUpdate,           // Used to enable editting of list item
  canDelete,           // Used to disable deletion from list
  canDeleteAsync,      // Used to disable deletion from list
  onDelete,            // Custom delete function
  canSave,             // Used to disable Save button
  onSave,              // Custom save function
  preSave,             // Custom pre-save validation function, triggers save dialog
  canAdd,              // Used to disable the + button on list
  defaultItem,         // Defines default item values created on add
  getUniqueField,      // Used to disable save if item does not meet unquieness function
  transform,           // Custom transform/filter function
  menuItems,           // Custom menu items attached to + button
  tabs,                // List of labels used to created a tabbed list view
  formWidth,           // Percent of space used by form editor, default "30%"  
  canDuplicate,        // Used to enable duplication of list item 
  onDuplicate,         // Custom duplicate function
  customDeleteTooltip, // Custom delete button tooltip 
  customDuplicateTooltip  // Custom duplicate button tooltip
}) => {
  const { t } = useTranslation();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ hoveredItem, setHoveredItem ] = useState(null);
  const [ deleteItem, setDeleteItem ] = useState(null);
  const _items = model ? useQuery(model, { sort, filter, condition }) : items.sort(sort);
  // in case checking if X can be deleted needs to be done asynchronously, wait for hovered item then perform a check, show loading on null
  const [ deleteAsync, setDeleteAsync ] = useState(null);
  const [ currentTab, setCurrentTab ] = useState(0);
  const [ saveIsDisabled, setSaveIsDisabled ] = useState(true);
  const [open, setOpen] = useState({state:false, item: null});


  useEffect(() => {
    let cancelled = false;
    if (!canDeleteAsync || !hoveredItem) return;
    (async () => {
      setDeleteAsync(null);
      const result = await canDeleteAsync(hoveredItem);
      if (!cancelled) setDeleteAsync(result);
    })();
    return () => {
      cancelled = true;
    }
  }, [hoveredItem, canDeleteAsync, setDeleteAsync]);

  const handleDeleteItem = async (item) => {
    if (selectedItem === deleteItem){
      setSelectedItem(null);
    }
    onDelete ? await onDelete(item) : await DataStore.delete(deleteItem);
    setDeleteItem(null);
  }

  const onTabChanged = (event, newValue) => {
    setCurrentTab(newValue);
    setSelectedItem(null);
  };

  const trasformedItems = useMemo(() => (transform) ? transform(_items, currentTab) : _items, [_items, currentTab, transform])

  const classes = useStyles();

  const handleItemClick = (item) => {
    if (selectedItem && selectedItem === item) {
      setSelectedItem(item);
     } else {
      !selectedItem && setSelectedItem(item);
      if (selectedItem){
        if(!saveIsDisabled){
          setOpen({state: true, item: item});
        } else setSelectedItem(item);
      };
    };
  };

  const handleClose = () => {
    setOpen({state:false, item: null});
  };

  const handleConfirm = () => {
    setSelectedItem(open.item);
    setOpen({...open, state: false});
  };
  

  const handleEditorClose = () => {
    if(!saveIsDisabled){
      setOpen({state: true, item: null});
    } else setSelectedItem(null);
  };

  const renderAdminModelItem = (item) => <ListItem
    key={item.id}
    data-id={item.id}
    selected={item === selectedItem}
    button
    disableRipple
    onMouseEnter={() => setHoveredItem(item)}
    onMouseLeave={() => setHoveredItem(null)}
    onClick={() => handleItemClick(item)}
    className={classes.listItem}
  >
    <ListItemContent value={item} classes={classes} tabIndex={currentTab} />
    {item === hoveredItem &&
    <>
      <Box position="absolute" right="3.5rem">
      {canDuplicate && <Tooltip 
        title={customDuplicateTooltip ? 
          customDuplicateTooltip : t('AdminModelEditor.Labels.DuplicateEntry')} 
        placement="top">
        <IconButton id="AdminModelEditorDuplicateButton" size="small" onClick={(e) => { e.stopPropagation(); onDuplicate(item)}}>
          <ContentCopy fontSize="small"/>
        </IconButton>
        </Tooltip>}
      </Box>
      <Box position="absolute" right="1rem">
        {((canDelete && canDelete(item)) || deleteAsync) &&
          <Tooltip 
            title = {customDeleteTooltip ? 
              customDeleteTooltip(item, currentTab) : t('AdminModelEditor.Labels.DeleteEntry')}
            placement="top">
            <IconButton id="AdminModelEditorDeleteButton" size="small" onClick={(e) => { e.stopPropagation(); setDeleteItem(item); }}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      </Box>
      </>
    }
    {!canUpdate(item) &&
      <Box position="absolute" right="1rem" display="flex" alignItems="center">
        <Tooltip title={t('AdminModelEditor.Labels.ReadOnly')} placement="top">
          <Lock fontSize="small" style={{ opacity: .25 }} />
        </Tooltip>
      </Box>
    }
  </ListItem>

  return (
    <>
      <ConfirmDialog
        open={open.state}
        title={t("Common.Labels.UnsavedChanges")}
        message={t('AdminModelEditor.Labels.UnsavedChangesMessage')}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={t("Common.Buttons.Leave")}
      />
      <CardContent className={classes.main}>
        {CustomHeader ? <CustomHeader />  : null}
        { tabs &&
          <Tabs value={currentTab} onChange={onTabChanged} style={{flex: "none"}}>
            { tabs.map((el, index) => <Tab id={`AdminModelTab${index}`} key={`AdminModelTab${index}`} label={el} value={index} /> )}
          </Tabs> 
        }
        <Box display="flex" alignItems="center" height="2rem" flex="none">
          <Box flexGrow={1} />
            {!canAdd || canAdd() ?
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Tooltip title={t('AdminModelEditor.Labels.CreateEntry')} placement="top">
                  <Fab id={'CreateEntryButton'} color="primary" onClick={e => menuItems ? setAnchorEl(e.currentTarget) : setSelectedItem(new model(defaultItem(currentTab)))}>
                    <Plus fontSize="small" />
                  </Fab>
                </Tooltip>
              </ClickAwayListener> : 
              null
            }
            {menuItems && <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              id="AddAdminModelEditorEntryMenu">
            {menuItems.map(mItem => 
              <MenuItem key={mItem.id} id={`AddAdminModelEditorEntryMenu-${mItem.id}`} disabled={!(mItem.enabled && mItem.enabled(selectedItem))} onClick={() => setSelectedItem(new model(mItem.getDefaultItem(selectedItem)))}>
                <ListItemText primary={mItem?.title} />
              </MenuItem>
            )}
          </Menu>}
        </Box>
        <ListSubheader component="div" style={{ display: "flex", flex: "none", height: "2rem", alignItems: "flex-end", marginBottom: "1rem" }}>
          <HeaderContent tabIndex={currentTab} />
        </ListSubheader>

        <List disablePadding dense className={classes.list} id="AdminModelEditorList">
          {trasformedItems.map(i => {
            return (
              <>
                {renderAdminModelItem(i)}
                {i.children && i.children.map(child => renderAdminModelItem(child))}
              </>
            )
          })}
        </List>
      </CardContent>
      {selectedItem &&
        <CardContent className={classes.right} style={{ flex: `0 0 ${formWidth ? formWidth : '33%'}`} }>
          <FormWrap
            model={model}
            value={selectedItem}
            onClose={() => handleEditorClose()}
            onUpdate={value => {onSave ? onSave(value) : DataStore.save(value); setSelectedItem(null)}}
            canUpdate={canUpdate}
            canSave={canSave}
            preSave={preSave}
            isUnique={(value) => getUniqueField && (_items.find((i) => {
              return (i?.id !== value?.id) && (getUniqueField(i) === getUniqueField(value))
            }) === undefined)}
            FormContent={FormContent}
            SaveDialogContent={SaveDialogContent}
            tabIndex={currentTab}
            canDuplicate={canDuplicate}
            saveIsDisabled={saveIsDisabled}
            setSaveIsDisabled={setSaveIsDisabled}
          />
        </CardContent>
      }
      <Dialog onClose={() => setDeleteItem(null)} open={Boolean(deleteItem)}>
        <DialogTitle>{t('AdminModelEditor.Labels.DeleteEntry')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('AdminModelEditor.Labels.DeleteEntryMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteItem(null)} color="default">
            {t('Common.Buttons.Cancel')}
          </Button>
          <Button id="AdminModelEditorConfirmDeleteButton" onClick={() => handleDeleteItem(deleteItem)} color="primary">
            {t('Common.Buttons.Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};