
export const croppedImageDimensions = {
  width: 640,
  height: 360,
}

export const STATUS = {
  EMPTY: "EMPTY",
  INITIAL: "INITIAL",
  CROPPED: "CROPPED",
  REPLACED: "REPLACED",
  DELETED: "DELETED",
}

export const errorLabels = {
  UPLOAD: "upload",
  DELETE: "delete",
}