export const TRANSLATIONS_ES_MX = {
  "Common": {
    "Buttons": {
      "Create": "CREAR",
      "Update": "ACTUALIZAR",
      "DiscardChanges": "Descartar los cambios",
      "Confirm": "Confirmar",
      "Cancel": "Cancelar",
      "Close": "Cerrar",
      "Save": "Guardar",
      "Request": "Solicitar"
    },
    "Labels": {
      "M": "m",
      "T": "t",
      "KW": "kW",
      "HP": "hp",
      "KN": "kn",
      "NMI": "nmi",
      "MB": "mb",
      "Each": "",
      "C": "C",
      "F": "F",
      "Unspecified": "?",
      "UnsavedChanges": "Cambios no guardados",
      "Nominal": "Nominal",
      "All": "Todo",
      "CustomAction": "Acción de {{- actionName}}",
      "Comments": "Comentar",
      "More": "Más"
    },
    "Errors": {
      "TimeCheckActionBefore": "La hora prevista para la acción debe ser posterior a la hora real de la acción anterior",
      "TimeCheckActionAfter": "La hora prevista para la acción debe ser anterior a la hora prevista para la acción siguiente",
      "TimeCheckArrivalCheck": "La llegada debe ser anterior a la hora prevista para cualquier otra acción",
      "TimeCheckDepartureCheck": "La salida debe ser posterior a la hora prevista para cualquier otra acción",
      "TimeCheckShiftBefore": "El horario previsto para el desplazamiento debe ir después de la acción previa",
      "TimeCheckShiftAfter": "El horario previsto para el desplazamiento debe ir antes de la acción siguiente",
      "TimeCheckArrivalRequest": "El horario solicitado va después de una solicitud de salida",
      "TimeCheckDepartureRequest": "El horario solicitado va antes de la solicitud de llegada"
    }
  },
  "ActionMovementType": {
    "Labels": {
      "Arrival": "Llegada",
      "Departure": "Salida",
      "BerthShiftArrival": "Cambio de embarcadero a la llegada",
      "BerthShiftDeparture": "Cambio de embarcadero a la salida",
      "ShiftArrival": "Desplazamiento de llegada",
      "ShiftDeparture": "Desplazamiento de salida",
      "All": "Todo"
    }
  },
  "ActionState": {
    "Labels": {
      "Planned": "Previsto/a",
      "InProgress": "En curso",
      "Completed": "Completado/a",
      "Cancelled": "Cancelado/a",
      "Deleted": "Eliminado/a",
      "ApprovalDeclined": "Autorización denegada",
      "ApprovalPending": "Autorización pendiente",
      "Requested": "Solicitado/a",
      "Created": "Creado/a",
      "ArrivalRequested": "Llegada solicitada",
      "ArrivalApprovalPending": "Autorización de llegada pendiente",
      "ArrivalApprovalDeclined": "Autorización de llegada denegada",
      "ArrivalPlanned": "Llegada prevista",
      "ArrivalInProgress": "Llegada en curso",
      "ArrivalCompleted": "Llegada completada",
      "ArrivalCancelled": "Llegada cancelada",
      "ArrivalDeleted": "Llegada eliminada",
      "DepartureRequested": "Salida solicitada",
      "DepartureApprovalPending": "Autorización de salida pendiente",
      "DepartureApprovalDeclined": "Autorización de salida denegada",
      "DeparturePlanned": "Salida prevista",
      "DepartureInProgress": "Salida en curso",
      "DepartureCompleted": "Salida completada",
      "DepartureCancelled": "Salida cancelada",
      "DepartureDeleted": "Salida eliminada",
      "ShiftArrivalRequested": "Desplazamiento de llegada solicitado",
      "ShiftArrivalApprovalPending": "Autorización para desplazamiento de llegada pendiente",
      "ShiftArrivalApprovalDeclined": "Autorización para desplazamiento de llegada denegada",
      "ShiftArrivalPlanned": "Desplazamiento de llegada previsto",
      "ShiftArrivalInProgress": "Desplazamiento de llegada en curso",
      "ShiftArrivalCompleted": "Desplazamiento de llegada completado",
      "ShiftArrivalCancelled": "Desplazamiento de llegada cancelado",
      "ShiftArrivalDeleted": "Desplazamiento de llegada eliminado",
      "ShiftDepartureRequested": "Desplazamiento de salida solicitado",
      "ShiftDepartureApprovalPending": "Autorización para desplazamiento de salida pendiente",
      "ShiftDepartureApprovalDeclined": "Autorización para desplazamiento de salida denegada",
      "ShiftDeparturePlanned": "Desplazamiento de salida previsto",
      "ShiftDepartureInProgress": "Desplazamiento de salida en curso",
      "ShiftDepartureCompleted": "Desplazamiento de salida completado",
      "ShiftDepartureCancelled": "Desplazamiento de salida cancelado",
      "ShiftDepartureDeleted": "Desplazamiento de salida eliminado",
      "AgentHandoverRequested": "Relevo de agente solicitado",
      "AgentHandoverApprovalPending": "Autorización para relevo de agente pendiente",
      "AgentHandoverApprovalDeclined": "Autorización para relevo de agente denegada",
      "AgentHandoverPlanned": "Relevo de agente previsto",
      "AgentHandoverInProgress": "Relevo de agente en curso",
      "AgentHandoverCompleted": "Relevo de agente completado",
      "AgentHandoverCancelled": "Relevo de agente cancelado",
      "AgentHandoverDeleted": "Relevo de agente eliminado",
      "CustomActionRequested": "{{action}} solicitado/a",
      "CustomActionApprovalPending": "Autorización de {{action}} pendiente",
      "CustomActionApprovalDeclined": "Autorización de {{action}} denegada",
      "CustomActionPlanned": "{{action}} previsto/a",
      "CustomActionInProgress": "{{action}} en curso",
      "CustomActionCompleted": "{{action}} completado/a",
      "CustomActionCancelled": "{{action}} cancelado/a",
      "CustomActionDeleted": "{{action}} eliminado/a"
    }
  },
  "ActionType": {
    "Labels": {
      "Name": "Nombre",
      "Movement": "Movimiento",
      "Event": "Evento"
    }
  },
  "Admin": {
    "Labels": {
      "Administration": "Administración",
      "Automation": "Automatización",
      "GeneralSettings": "Ajustes generales",
      "SecondaryApproval": "Autorización secundaria",
      "Notifications": "Notificaciones",
      "PortDefinitions": "Conceptos del puerto",
      "ActionTypes": "Tipos de acción",
      "Contacts": "Contactos",
      "PortCallCategories": "Categorías de escalas portuarias",
      "Tariffs": "Aranceles",
      "TariffUnits": "Unidades de aranceles"
    }
  },
  "AdminActionType": {
    "Labels": {
      "ActionTypeDefaults": "Tipos de acción",
      "ActionTypeDefaultsHelp": "Las nuevas acciones de este tipo serán automáticamente llenadas con los siguientes valores predeterminados",
      "MovementArrival": "Movimiento (llegada)",
      "MovementDeparture": "Movimiento (salida)",
      "MovementShiftArrival": "Movimiento (desplazamiento de llegada)",
      "MovementShiftDeparture": "Movimiento (desplazamiento de salida)"
    }
  },
  "AdminApproval": {
    "Labels": {
      "EnableSecondaryApproval": "Habilitar autorización secundaria",
      "EnableSecondaryApprovalHelp": "Habilitar la autorización de nivel secundario garantiza que las escalas portuarias sean revisadas por un usuario autorizado antes de su procesamiento.",
      "VesselExceptionList": "Lista de excepciones de embarcaciones"
    }
  },
  "AdminAutomation": {
    "Labels": {
      "NoAutomationRules": "No se han definido reglas de automatización",
      "NoAutomationRulesHelp": "\"En caso de necesitar ayuda: por favor contacte al personal de soporte de Marlin SmartPort\"",
      "EnablePortCallAutomation": "Habilitar la automatización de escalas portuarias",
      "EnablePortCallAutomationHelp": "Habilitar la automatización de escalas portuarias para crear automáticamente las escalas y registrar cambios en el movimiento",
      "NoVesselsSpecified": "No se ha especificado ninguna embarcación",
      "UseAllVessels": "Usar todas las embarcaciones",
      "UseAllVesselsHelp": "Aplicar la automatización a todas las embarcaciones que entren al puerto"
    }
  },
  "AdminGeneralSettings": {
    "Labels": {
      "ApplicationCurrency": "Divisa de la aplicación",
      "ApplicationCurrencyHelp": "Configurar la divisa de la aplicación",
      "Language": "Idioma",
      "LanguageHelp": "Configurar el idioma de la aplicación"
    }
  },
  "AdminModelEditor": {
    "Labels": {
      "CreateEntry": "Crear una nueva entrada",
      "DeleteEntry": "Eliminar entrada",
      "DeleteEntryMessage": "¿Está seguro/a de que desea eliminar este artículo? Está a punto de realizar una acción irreversible.",
      "ReadOnly": "Modo de solo lectura"
    }
  },
  "AdminTariff": {
    "Labels": {
      "DuplicateTariff": "Ya existe un arancel con ese código"
    }
  },
  "AdminNotification": {
    "Labels": {
      "Name": "Nombre",
      "Description": "Descripción",
      "EnableNotification": "Habilitar notificación",
      "EnableNotifications": "Habilitar notificaciones",
      "Recipents": "Enviar a",
      "Trigger": "Activar",
      "ActionType": "Tipo de acción",
      "TriggerType": "Tipo de desencadenante",
      "Location": "Ubicación",
      "Enabled": "Habilitado/a",
      "SelectAction": "Seleccionar acción",
      "SelectEvent": "Seleccionar evento",
      "EventCreated": "Creado/a",
      "EventStarted": "Iniciado/a",
      "EventCompleted": "Completado/a",
      "EventPendingApproval": "Autorización pendiente",
      "EventApproved": "Aprobado/a",
      "EventDeclined": "Denegado",
      "EventRequested": "Solicitado/a",
      "EventCancelled": "Cancelado/a",
      "MovementArrival": "Movimiento (llegada)",
      "MovementDeparture": "Movimiento (salida)",
      "MovementShiftArrival": "Movimiento (desplazamiento de llegada)",
      "MovementShiftDeparture": "Movimiento (desplazamiento de salida)"
    }
  },
  "AppHeader": {
    "Labels": {
      "CurrentDateAndTime": "Fecha y hora actuales",
      "TidalForecast": "Pronóstico de mareas",
      "LoadingWeatherInformation": "Cargando la información meteorológica",
      "ErrorLoadingWeatherInformation": "Error al cargar la información meteorológica",
      "LoadingTidalInformation": "Cargando la información sobre las mareas",
      "ErrorRetrievingTidalInformation": "Error al cargar la información sobre las mareas",
      "Language": "Idioma",
      "LogOut": "Cerrar sesión"
    }
  },
  "Category": {
    "Labels": {
      "Name": "Nombre",
      "Description": "Descripción"
    }
  },
  "EditorContentTabs": {
    "Labels": {
      "Details": "Detalles",
      "Requests": "Solicitudes",
      "LogBook": "Bitácora"
    }
  },
  "NavBarVertical": {
    "Labels": {
      "NoRequests": "No hay ninguna solicitud en la bandeja de entrada",
      "Requests": "Bandeja de entrada",
      "RequestInbox": "Bandeja de entrada",
      "CreatePortCall": "Crear escala portuaria",
      "CreatePortCallDisabled": "Crear escala portuaria (deshabilitado para usuarios externos al puesto de control del puerto)",
      "RequestPortCall": "Solicitar escala portuaria",
      "BoardView": "Vista del panel",
      "MapView": "Vista de mapa",
      "PortCalls": "Escalas portuarias",
      "Dashboard": "Panel de control",
      "Documentation": "Documentación",
      "Administration": "Administración"
    }
  },
  "PortCallDetails": {
    "Labels": {
      "Itinerary": "Itinerario",
      "PortCallCategory": "Categoría de escala portuaria",
      "ReportingCategory": "Categoría de informe",
      "Remarks": "Observaciones",
      "Tags": "Identificadores",
      "PortCallTags": "Identificador de escala portuaria",
      "Attachments": "Documentos adjuntos",
      "UnsavedChangesMessage": "Existen cambios sin guardar en el editor actual. Guarde los cambios antes de actualizar otros detalles",
      "OverwritePortCallTags": "Sobrescribir los identificadores de escala portuaria",
      "OverwritePortCallTagsMessage": "Los identificadores de escala portuaria existentes serán sobrescritos con este cambio de embarcación ¿Desea sobrescribir esta información con nuevos identificadores?",
      "OverwritePortCallCategory": "Sobrescribir la categoría de escala portuaria",
      "OverwritePortCallCategoryMessage": "La categoría de escala portuaria existente sea sobrescrita con este cambio de embarcación. ¿Desea sobrescribir esta información con una nueva categoría?",
      "LeavePromptMessage": "Existen cambios sin guardar. ¿Está seguro/a de que desea salir?",
      "ConfirmChangeToCargo": "Confirmar cambio en el cargamento"
    }
  },
  "PortCallList": {
    "Labels": {
      "PortCalls": "Escalas portuarias",
      "NoMoreData": "No hay más información disponible.",
      "Uncategorised": "Sin categoría definida",
      "Port": "PUERTO",
      "Category": "CATEGORÍA",
      "Order": "Orden",
      "Tags": "IDENTIFICADORES",
      "Vessel": "EMBARCACIÓN",
      "From": "DE",
      "To": "A",
      "OldestFirst": "Lo más antiguo primero",
      "NewestFirst": "Lo más reciente primero",
      "DepartureNotScheduled": "Salida no programada",
      "ShowAll": "Mostrar todo",
      "Status": "Estado",
      "SearchByKeyword": "Búsqueda por palabra clave"
    }
  },
  "PortCallStatus": {
    "Labels": {
      "Requested": "Solicitado/a",
      "Prearrival": "Pre-llegada",
      "Arrival": "Llegada",
      "InPort": "En puerto",
      "Departure": "Salida",
      "Cancelled": "Cancelado/a",
      "Deleted": "Eliminado/a",
      "Closed": "Cerrado/a",
      "undefined": "Indefinido"
    }
  },
  "Tariff": {
    "Labels": {
      "Code": "Código",
      "TariffUnit": "Unidad de arancel",
      "Description": "Descripción",
      "UnitCost": "Costo unitario",
      "Currency": "Divisa",
      "Notes": "Notas",
      "MinimumCost": "Costo mínimo (opcional)",
      "MinimumCostShort": "Min.",
      "ActionTypes": "Tipos de acción",
      "ActionTypesHelp": "Este arancel solo está disponible para tipos de acción específicos",
      "Unspecified": "No especificado"
    }
  },
  "TariffUnit": {
    "Labels": {
      "Name": "Nombre"
    }
  },
  "Vessel": {
    "Labels": {
      "Vessel": "Embarcación",
      "Name": "Nombre",
      "TemporaryName": "Nombre temporal",
      "IMO": "OMI",
      "IMONumber": "Número OMI",
      "MMSI": "MMSI ",
      "CallSign": "Señal de llamada",
      "Flag": "Bandera",
      "YearBuilt": "Año de construcción",
      "Number": "Número",
      "Type": "Tipo",
      "DeadweightTonnage": "Tonelaje de peso muerto",
      "Length": "Eslora total",
      "Beam": "Manga máxima",
      "Draught": "Calado",
      "NominalDraught": "Calado nominal",
      "GrossTonnage": "Arqueo bruto",
      "NetTonnage": "Arqueo neto",
      "PortOfRegistry": "Puerto de registro",
      "Dimensions": "Dimensiones",
      "AgentName": "Nombre de agente",
      "Identity": "Identidad",
      "Details": "Detalles",
      "VesselDetails": "Detalles de la embarcación",
      "Tonnage": "Arqueo",
      "VesselAgent": "Agente de la embarcación",
      "PrimaryPropulsion": "Fuente primaria de propulsión",
      "Propulsion": "Propulsión",
      "PropulsionType": "Tipo de propulsión",
      "NumPropellers": "Número de hélices",
      "Direction": "Dirección",
      "TotalPower": "Potencia total",
      "Units": "Unidades",
      "Rudder": "Timón",
      "RudderType": "Tipo de timón",
      "NumRudders": "Número de timones",
      "ForwardAzimuth": "Acimut de la proa",
      "AftAzimuth": "Acimut de la popa",
      "ForwardTunnelThrusters": "Propulsor de túnel de la proa",
      "AftTunnelThrusters": "Propulsor de túnel de la popa",
      "NumThrusters": "Número de propulsores",
      "MastersName": "Nombre del capitán",
      "NumCrew": "Número de tripulantes",
      "NumPassengers": "Número de pasajeros",
      "ForwardDraught": "Calado de proa",
      "AftDraught": "Calado de popa",
      "SailingDraught": "Calado de navegación",
      "AirDraught": "Calado aéreo",
      "ArrivalDraught": "Calado de llegada",
      "DepartureDraught": "Calado de salida",
      "PortCallID": "Identificador de escala portuaria"
    }
  },
  "Map": {
    "Labels": {
      "AISShipType": "Tipo de buque del SIA ",
      "Navigation": "Navegación",
      "Destination": "Destino",
      "ETA": "Hora estimada de llegada (ETA)",
      "Speed": "Velocidad",
      "Heading": "Rumbo",
      "CMG": "Contenedores CMG",
      "Latitude": "Latitud",
      "Longitude": "Longitud",
      "Time": "Hora",
      "LastUpdated": "Actualizado por última vez el",
      "Age": "Edad"
    }
  },
  "VesselTooltip": {
    "Labels": {
      "ArrivalDraught": "Calado de navegación a la llegada",
      "DepartureDraught": "Calado de navegación a la salida"
    }
  },
  "RequestDetails": {
    "Labels": {
      "WithdrawRequest": "Cancelar la solicitud",
      "WithdrawDialogTitle": "Cancelar la solicitud de {{type}}",
      "WithdrawDialogMessage": "¿Está seguro/a de que desea cancelar esta solicitud?",
      "LastUpdated": "Actualizado por última vez el",
      "UpdateRequest": "Actualizar solicitud",
      "ShowBerthUtilization": "Mostrar uso de embarcadero",
      "HideBerthUtilization": "Ocultar uso de embarcadero",
      "Current": "Actual",
      "Requested": "Solicitado/a",
      "CurrentTime": "Hora actual",
      "CurrentLocation": "Ubicación actual",
      "RequestedTime": "Hora solicitada",
      "Time": "hora",
      "Location": "Ubicación",
      "ChangeTime": "Cambiar hora",
      "ErrorInvalidTime": "Hora inválida",
      "ErrorCommentRequired": "Se requiere un comentario para rechazar la solicitud",
      "ErrorArrivalApproved": "La solicitud de llegada debe ser aprobada",
      "ErrorDepartureAfterArrival": "La salida debe ser posterior a la última llegada",
      "ErrorArrivalBeforeAction": "La llegada va antes de otras acciones",
      "Comment": "Comentar",
      "HideComments": "Ocultar <number/> comentarios",
      "ShowComments": "Mostrar <number/> comentarios más"
    }
  },
  "RequestInbox": {
    "Labels": {
      "Inbox": "Bandeja de entrada",
      "RequestHeadingsMovement": "Movimiento",
      "RequestHeadingsArrival": "Llegada",
      "RequestHeadingsDeparture": "Salida",
      "RequestHeadingsPortCall": "Escala portuaria",
      "RequestHeadingsRemarks": "Observaciones",
      "NoRequests": "No hay solicitudes",
      "AcknowledgeUpdate": "Aceptar la actualización",
      "Comments": "Comentarios",
      "UpdatedAt": "Actualizado a las",
      "ShowDetails": "Mostrar detalles",
      "Source": "Origen",
      "Date": "Fecha",
      "Notification": "Notificación",
      "RequestType": "Tipo de solicitud",
      "Invalid": "Inválido/a"
    }
  },
  "RequestTooltip": {
    "Labels": {
      "RequestType": "Tipo de solicitud",
      "Source": "Origen",
      "UpdatedAt": "Actualizado a las",
      "Status": "Estado"
    }
  },
  "RequestState": {
    "Labels": {
      "Pending": "Pendiente",
      "Rejected": "Rechazado/a",
      "Approved": "Aprobado/a",
      "Withdrawn": "Cancelado/a",
      "Notification": "Notificación",
      "PartiallyApproved": "Parcialmente aprobado/a",
      "PartiallyRejected": "Parcialmente rechazado/a",
      "PendingAction": "Pendiente",
      "RejectAction": "Rechazar",
      "ApproveAction": "Aprobar"
    }
  },
  "RequestType": {
    "Labels": {
      "CreatePortCall": "Crear solicitud de escala portuaria",
      "UpdatePortCallCargo": "Solicitud de actualización de cargamento",
      "CancelPortCall": "Cancelar solicitud de escala portuaria",
      "UpdateActionArrivalTimePlanned": "Actualizar la solicitud de hora de llegada",
      "UpdateActionDepartureTimePlanned": "Actualizar la solicitud de hora de salida",
      "UpdateActionDraught": "Solicitud de actualización de calado",
      "CancelAction": "Cancelar solicitud de acción",
      "CreateDeparture": "Crear solicitud de salida"
    }
  },
  "Contact": {
    "Labels": {
      "Type": "Tipo",
      "Name": "Nombre completo",
      "DisplayName": "Nombre",
      "Reference": "Referencia",
      "Address": "Dirección",
      "Address1": "Dirección 1",
      "Address2": "Dirección 2",
      "Town": "Ciudad",
      "County": "Municipio",
      "Postcode": "Código postal",
      "TelephoneNumber": "Número telefónico",
      "Fax": "Fax",
      "Email": "Correo electrónico",
      "ContactAName": "Nombre del contacto",
      "ContactADetail": "Información de contacto",
      "ContactBName": "Nombre del contacto",
      "ContactNDetail": "Información de contacto"
    }
  },
  "ContactType": {
    "Labels": {
      "Agent": "Agente",
      "Stevedore": "Estibador",
      "PortStaff": "Personal del puerto",
      "VesselAgent": "Agente de la embarcación",
      "VesselMaster": "Capitán de la embarcación"
    }
  },
  "AgentHandover": {
    "Labels": {
      "From": "De",
      "NoActiveAgent": "No hay agentes activos"
    }
  },
  "AgentHandoverCreateDialog": {
    "Labels": {
      "AgentHandover": "Relevo de agente",
      "PlannedTimeOfHandover": "Hora prevista para el relevo"
    }
  },
  "AgentHandoverEdit": {
    "Labels": {
      "Schedule": "Horario",
      "NoAgent": "No hay agentes activos",
      "PlannedTime": "Horario previsto",
      "Details": "Detalles"
    },
    "Buttons": {
      "SetPlannedTime": "Fijar hora prevista"
    }
  },
  "VesselDataEdit": {
    "Labels": {
      "UpdatedAt": "Actualizado a las",
      "RefreshVesselData": "Actualizar información de la embarcación",
      "ChangeVessel": "Cambiar embarcación",
      "UpdateVessel": "Actualizar embarcación"
    }
  },
  "VesselForm": {
    "Errors": {
      "EmptyName": "El campo de nombre no puede ir vacío",
      "MissingUnits": "Unidades faltantes",
      "InvalidImo": "\"Número OMI inválido: checksum fallido\"",
      "DuplicateImo": "Existe una embarcación con este número OMI",
      "InvalidMmsi": "El número MMSI debe contener 9 dígitos",
      "DuplicateMmsi": "Existe una embarcación con este número MMSI"
    },
    "Labels": {
      "CreateVessel": "Crear embarcación",
      "UpdateVessel": "Actualizar embarcación",
      "Image": "Imagen"
    }
  },
  "VesselItinerary": {
    "Labels": {
      "PortOfLoading": "Puerto de carga",
      "LastPort": "Último puerto",
      "NextPort": "Siguiente puerto",
      "PortOfDischarge": "Puerto de descarga"
    }
  },
  "VesselSearch": {
    "Labels": {
      "UnknownVessel": "Embarcación desconocida",
      "TemporaryName": "Nombre temporal",
      "CallSign": "Señal de llamada",
      "Beam": "Manga",
      "LengthOverall": "Eslora total",
      "NominalDraught": "Calado nominal"
    },
    "Errors": {
      "NotAValidImo": "Número OMI inválido",
      "NotAValidMmsi": "Número MMSI inválido"
    }
  },
  "LocationTimeline": {
    "Labels": {
      "NotAllocated": "No asignado/a",
      "NoUpcomingAction": "Ninguna acción próxima"
    }
  },
  "Agent": {
    "Labels": {
      "Agent": "Agente",
      "CharterAgent": "Agente de fletamento",
      "CreateAgent": "Crear agente",
      "Name": "Nombre",
      "CompanyName": "Nombre de la empresa",
      "DisplayName": "Nombre para mostrar",
      "ContactDetails": "Información de contacto",
      "ContactName": "Nombre del contacto",
      "TelephoneNumber": "Número telefónico",
      "FaxNumber": "Número de fax"
    },
    "Buttons": {
      "CreateAgent": "Crear agente"
    },
    "Errors": {
      "EmptyCompanyName": "El campo de nombre de la empresa no puede ir vacío",
      "EmptyTelephoneNumber": "El campo de número telefónico no puede ir vacío"
    }
  },
  "VesselEditImageForm": {
    "Buttons": {
      "Select": "SELECCIONAR IMAGEN",
      "Replace": "REEMPLAZAR IMAGEN",
      "Discard": "DESCARTAR",
      "DiscardCrop": "DESCARTAR IMAGEN RECORTADA"
    },
    "Errors": {
      "FileTypeTitle": "Tipo de archivo incorrecto",
      "FileTypeMessage": "Por favor cargue un archivo de imagen con extensión .jpg o .png",
      "FailedToUpload": "Error al cargar",
      "FailedToDelete": "Error al eliminar",
      "ErrorMessage": "Mensaje de error",
      "Support": "Por favor inténtelo de nuevo o contacte al equipo de soporte"
    }
  },
  "ImageDialog": {
    "Labels": {
      "CropImage": "Recortar imagen"
    }
  },
  "VesselImage": {
    "Labels": {
      "Image": "Imagen de la embarcación"
    }
  },
  "PropulsionType": {
    "Labels": {
      "FixedPitch": "Hélice de paso fijo (FPP)",
      "ControllablePitch": "Hélice de paso controlable (CPP)",
      "FixedVariablePitch": "Hélice de paso variable (CPP)",
      "AzimuthThruster": "Propulsor azimutal",
      "VoithSchneider": "Sistema de propulsión Voith Schneider",
      "ZType": "Propulsor tipo Z"
    }
  },
  "RudderType": {
    "Labels": {
      "Standard": "Propulsor estándar",
      "Flapped": "Timón con aleta (Becker)",
      "Fishtail": "Timón cola de pez (Schilling)"
    }
  },
  "PropellerDirection": {
    "Labels": {
      "Left": "Mano izquierda",
      "Right": "Mano derecha"
    }
  },
  "ActionDetails": {
    "Labels": {
      "Schedule": "Horario",
      "CharterAgent": "Agente de fletamento",
      "Location": "Ubicación",
      "Pilotage": "Pilotaje",
      "Vessel": "Embarcación",
      "VesselDetails": "Detalles",
      "VesselCondition": "Condición",
      "Checklist": "Lista de verificación",
      "Attachments": "Documentos adjuntos",
      "ChargeableItems": "Artículos gravables",
      "Prompt": "Existen cambios sin guardar. ¿Está seguro/a de que desea salir?",
      "UnsavedChangesMessage": "Existen cambios sin guardar en el editor actual. Guarde los cambios antes de actualizar otros detalles",
      "AutomationWarning": "Esto podría impedir una mayor automatización en el{{movementType}}",
      "SubmitForApproval": "Enviar para su autorización",
      "ConfirmDraught": "Confirmar cambio en el calado",
      "Remarks": "Observaciones",
      "ConfirmSaveTitle": "Guardar escala portuaria {{movementType}}",
      "ConfirmSaveMessage": "¿Está seguro/a de que desea guardar {{movementType}} para {{vesselName}}?"
    }
  },
  "MovementEdit": {
    "Labels": {
      "Attachments": "Documentos adjuntos",
      "ChargeableItems": "Artículos gravables",
      "Checklist": "Lista de verificación"
    }
  },
  "ActionScheduleEdit": {
    "Buttons": {
      "SetRequestedTime": "Establecer hora solicitada",
      "SetPlannedTime": "Fijar hora prevista",
      "SetVesselReadyTime": "Establecer el plazo de preparación de la embarcación",
      "SetPilotBoardingTime": "Establecer la hora de embarque del piloto",
      "SetActualTime": "Establecer hora real",
      "RequestChange": "Solicitar cambio"
    },
    "Labels": {
      "MovementSchedule": "Horario de {{movement}}",
      "MovementLocation": "Ubicación de {{movement}}",
      "RequestedTime": "Hora solicitada",
      "PlannedTime": "Hora prevista",
      "VesselReadyTime": "Plazo de preparación de la embarcación",
      "PilotBoardingTime": "Hora de embarque del piloto",
      "ActualTime": "Hora real",
      "CharterAgent": "Agente de fletamento",
      "Pilotage": "Pilotaje",
      "Pilots": "Pilotos",
      "Linesmen": "Operadores",
      "MooringVessels": "Amarradores"
    }
  },
  "ActionVesselEdit": {
    "Labels": {
      "Vessel": "Embarcación",
      "MovementDetails": "Detalles de {{movement}}"
    }
  },
  "ActionPropulsionEdit": {
    "Labels": {
      "MovementCondition": "Condición de {{movement}}",
      "PrimaryPropulsion": "Fuente primaria de propulsión",
      "PropulsionState": "Estado de la propulsión",
      "PropulsionRemarks": "Observaciones sobre la propulsión",
      "RudderState": "Estado del timón",
      "RudderRemarks": "Observaciones sobre el timón",
      "ForwardAzimuthState": "Estado del acimut de la proa",
      "ForwardAzimuthRemarks": "Observaciones sobre el acimut de la proa",
      "AftAzimuthState": "Estado del acimut de la popa",
      "AftAzimuthRemarks": "Observaciones sobre el acimut de la popa",
      "ForwardTunnelThrustersState": "Estado del propulsor de túnel de la proa",
      "ForwardTunnelThrustersRemarks": "Observaciones sobre el propulsor de túnel de la proa",
      "AftTunnelThrustersState": "Estado del propulsor de túnel de la popa",
      "AftTunnelThrustersRemarks": "Observaciones sobre el propulsor de túnel de la popa"
    }
  },
  "ActionPropulsionOperationalState": {
    "Labels": {
      "FullyOperational": "En pleno funcionamiento",
      "HasFault": "Tiene fallas"
    }
  },
  "AgentRequestTimeChangeDialog": {
    "Buttons": {
      "SendRequest": "Enviar solicitud"
    },
    "Labels": {
      "Title": "Solicitar cambio en {{movement}}",
      "RequestedTimeArrival": "Hora de llegada solicitada",
      "RequestedTimeDeparture": "Hora de salida solicitada",
      "Remarks": "Observaciones"
    }
  },
  "DocumentEdit": {
    "Buttons": {
      "Upload": "Cargar un archivo"
    },
    "Labels": {
      "NoFile": "Seleccionar un archivo",
      "Filename": "Archivo adjunto seleccionado",
      "Progress": "Cargando...",
      "Complete": "Carga completada",
      "Downloaded": "Documento adjunto descargado",
      "Deleted": "Documento adjunto eliminado",
      "Attachments": "Documentos adjuntos"
    },
    "Errors": {
      "DuplicateTitle": "El documento adjunto {{doc}} ya existe",
      "DuplicateMessage": "Al cargar el archivo {{doc}} sobrescribirá el documento adjunto con el mismo nombre",
      "TooLargeTitle": "El documento es demasiado grande para cargarse",
      "TooLargeMessage": "\"El archivo {{doc}} es demasiado grande para ser cargado: debe estar por debajo de {{limit}}\"",
      "FailedUploadTitle": "Error al cargar {{doc}}",
      "FailedUploadMessage": "Error al cargar el archivo adjunto. Mensaje de error",
      "FailedDeleteTitle": "Error al eliminar {{doc}}",
      "FailedDeleteMessage": "Error al eliminar el archivo adjunto. Mensaje de error",
      "FailedDownloadTitle": "Error al descargar {{doc}}",
      "FailedDownloadMessage": "Error al descargar el archivo adjunto. Mensaje de error",
      "Support": "Por favor inténtelo de nuevo o contacte al equipo de soporte.",
      "FailedUnknownTitle": "Error no controlado",
      "FailedUnknownMessage": "Error no controlado"
    }
  },
  "DocumentList": {
    "Labels": {
      "DeleteAttachmentTitle": "Eliminar archivo adjunto {{doc}}",
      "DeleteAttachmentMessage": "¿Está seguro/a de que desea eliminar {{doc}?",
      "NoAttachments": "Ningún archivo adjunto fue cargado"
    }
  },
  "AddTodo": {
    "Labels": {
      "Select": "Seleccionar..."
    }
  },
  "ChargeableItems": {
    "Buttons": {
      "Add": "Agregar artículo gravable",
      "Update": "Actualizar artículos"
    },
    "Labels": {
      "Title": "Artículos gravables",
      "Tariff": "Arancel",
      "Notes": "Notas",
      "UnitCost": "Costo unitario",
      "MinimumCost": "Costo mínimo",
      "Quantity": "Cantidad",
      "QuantityShort": "CTD",
      "Total": "Total",
      "Empty": "",
      "Delete": "Eliminar artículo gravable",
      "UnitType": "Tipo de unidad",
      "OverriddenUnitCost": "Costo unitario inválido",
      "ItemNotes": "Notas sobre los artículos gravables",
      "ResetTariff": "Seleccionar otro arancel",
      "UnitCostTooltip": "Costo unitario original del arancel",
      "ResetTooltip": "Restablecer costo unitario",
      "NoTariffs": "Cree aranceles para este tipo de acción para poder agregar artículos gravables",
      "NoChargeable": "No hay artículos gravables. Haga clic en el botón de agregar un artículo gravable para añadir algunos"
    },
    "Errors": {
      "InvalidChargeable": "Para cada artículo gravable necesita seleccionar un arancel"
    }
  },
  "FileTypes": {
    "Labels": {
      "Fal5CrewList": "Lista de la tripulación (formulario FAL 5)"
    }
  },
  "PreArrivalUpload": {
    "Buttons": {
      "CreateForm": "Crear formulario",
      "EditForm": "Editar formulario",
      "UploadFile": "Cargar archivo"
    },
    "Labels": {
      "UploadedBy": "Cargado por",
      "UpdatedBy": "Actualizado por",
      "ReplaceTitle": "{{fileType}} ya existe",
      "ReplaceUploadMessage": "Al cargar un nuevo {{fileType}} la información existente será remplazada. ¿Está seguro/a de que desea continuar?",
      "ReplaceCreateFormMessage": "Al crear un nuevo {{fileType}} la información existente será remplazada. ¿Está seguro/a de que desea continuar?",
      "DeleteTitle": "Eliminar {{fileType}}",
      "DeleteMessage": "Esto eliminará {{fileType}}. ¿Está seguro/a de que desea continuar?"
    },
    "Errors": {
      "Title": "¡Algo salió mal!",
      "FailedToUpload": "Error al cargar el archivo. Por favor inténtelo de nuevo.",
      "FailedToDownload": "Error al descargar el archivo. Por favor inténtelo de nuevo."
    }
  },
  "Fal5CrewList": {
    "Buttons": {
      "AddMember": "Agregar miembro"
    },
    "Labels": {
      "Arrival": "Llegada",
      "Departure": "Salida",
      "_1_1_NameOfShip": "1.1 Nombre del buque",
      "_1_2_ImoNumber": "1.1 Número OMI",
      "_1_3_Callsign": "1.1 Señal de llamada",
      "_1_4_VoyageNumber": "1.1 Número de viaje",
      "_2_PortOfArrivalDeparture": "2. Puerto de llegada/salida",
      "_3_DateOfArrivalDeparture": "3. Fecha de llegada/salida",
      "_4_FlagStateOfShip": "4. Estado del pabellón",
      "_5_LastPortOfCall": "5. Último puerto de escala",
      "_6_Num": "6. Número",
      "_7_FamilyName": "7. Apellidos",
      "_8_GivenNames": "8. Nombres",
      "_9_RankOrRating": "9. Rango o clasificación",
      "_10_Nationality": "10. Nacionalidad",
      "_11_DateOfBirth": "11. Fecha de nacimiento",
      "_12_PlaceOfBirth": "12. Lugar de nacimiento",
      "_13_Gender": "13. Género",
      "_14_NatureOfIdentidyDoc": "14. Tipo de documento de identificación",
      "_15_NumberOfIdentidyDoc": "15. Número de documento de identificación",
      "_16_IssuingStateOfIdentityDoc": "16. Estado que emitió el documento de identificación",
      "_17_ExpireDateOfIdentityDoc": "17. Fecha de vencimiento del documento de identificación"
    }
  },
  "PortCall": {
    "Buttons": {
      "AddDeparture": "Agregar salida",
      "MoreOptions": "Más opciones"
    },
    "Errors": {
      "InvalidDateFormat": "Formato de fecha inválido",
      "DepartureAfterArrival": "La salida debe ser posterior a la llegada"
    },
    "Labels": {
      "NewPortCallTitle": "Crear escala portuaria",
      "RequestPortCall": "Solicitar escala portuaria",
      "PlannedTimeOfArrival": "Hora de llegada prevista",
      "PlannedTimeOfDeparture": "Hora de salida prevista",
      "ArrivalLocation": "Ubicación de llegada",
      "DepartureLocation": "Ubicación de salida",
      "Schedule": "Horario",
      "EnterRemarksForPortControl": "Insertar observaciones para el centro de control del puerto",
      "ArrivalDraughts": "Calados a la llegada",
      "DepartureDraughts": "Calados a la salida"
    }
  },
  "Cargo": {
    "Labels": {
      "Cargo": "Cargamento",
      "Cargos": "Cargamentos",
      "CargoItem": "Artículo del cargamento",
      "CargoItems": "Artículos del cargamento",
      "Incoming": "Entrante",
      "IncomingCargo": "Cargamento entrante",
      "Outgoing": "Saliente",
      "OutgoingCargo": "Cargamento saliente",
      "CargoType": "Tipo de cargamento",
      "OtherCargo": "Otro cargamento",
      "NoCargo": "Sin artículos de cargamento",
      "More": "más"
    },
    "Buttons": {
      "AddCargo": "Agregar cargamento"
    }
  },
  "MovementActionList": {
    "Labels": {
      "Schedule": "Horario",
      "ScheduleDesc": "Hora estimada y hora prevista (si están disponibles)",
      "PortCallId": "Identificador de escala portuaria",
      "PortCallIdDesc": "Identificador único de escala portuaria (generado de forma automática por SmartPort)",
      "Vessel": "Embarcación",
      "VesselDesc": "Detalles de la embarcación",
      "PortOfRegistry": "Puerto de registro",
      "PortOfRegistryDesc": "Puerto donde se registra una embarcación",
      "Dimensions": "Dimensiones",
      "DimensionsDesc": "\"Eslora total x manga (y calado de navegación: si está disponible)\"",
      "Length": "Eslora",
      "LengthFull": "Eslora total",
      "LengthDesc": "Eslora total de la embarcación",
      "Beam": "Manga",
      "BeamDesc": "Ancho de la embarcación en su punto más amplio",
      "Draught": "Calado",
      "DraughtDesc": "Calado de navegación de la embarcación para su desplazamiento.  Calado nominal de la embarcación en caso de que el calado de navegación no esté disponible",
      "AirDraught": "Calado aéreo",
      "AirDraughtDesc": "Altura de la embarcación por encima de la línea de flotación para su desplazamiento",
      "BowThrusters": "BT",
      "BowThrustersFull": "Propulsor de maniobra",
      "BowThrustersDesc": "Indica la presencia de propulsores de maniobra",
      "Location": "Ubicación",
      "LocationDesc": "Lugar del puerto donde la embarcación será atracada o de donde partirá",
      "CharterAgent": "Agente de fletamento",
      "Cargo": "Cargamento",
      "CargoDesc": "Tipo y monto del cargamento",
      "NextPort": "Siguiente puerto",
      "NextPortDesc": "Siguiente puerto",
      "LastPort": "Último puerto",
      "LastPortDesc": "Puerto de escala anterior",
      "LatestUserLogEntry": "Bitácora",
      "LatestUserLogEntryDesc": "Última entrada de la bitácora enviada por un usuario",
      "Remarks": "Observaciones",
      "RemarksDesc": "Observaciones enviadas por un usuario",
      "GrossTonnage": "GT",
      "GrossTonnageFull": "Arqueo bruto",
      "GrossTonnageDesc": "Medida no lineal del volumen interno total de un buque",
      "Checklist": "Lista de verificación",
      "ChecklistDesc": "Tareas restantes para el desplazamiento",
      "FilterType": "Desplazamientos",
      "AllType": "Todos {{type}}",
      "NotAllocated": "No asignado/a"
    }
  },
  "Filter": {
    "Buttons": {
      "ApplyFilters": "Aplicar filtros"
    },
    "Labels": {
      "EnterFilterName": "Introduzca un nombre para guardar un nuevo filtro",
      "NoName": "Por favor introduzca un nombre para guardar el filtro",
      "UniqueName": "El nombre debe ser único",
      "SpecialCharacters": "El nombre no debe incluir caracteres especiales",
      "SelectFilter": "Seleccione el filtro",
      "FilterUpdated": "Este filtro será actualizado si no introduce ningún nombre",
      "ApplyFilters": "Aplicar filtros",
      "SelectType": "Seleccione el tipo",
      "FilterName": "Nombre del filtro",
      "FilterNamePrompt": "Introduzca un nombre para guardar como un nuevo filtro"
    }
  },
  "Logbook": {
    "Buttons": {
      "AddEntry": "Añadir entrada"
    },
    "Labels": {
      "Logbook": "Bitácora",
      "ItemCreatedByUser": "<item/> creado/a por <user/>",
      "ItemUpdatedByUser": "<item/> actualizado/a por <user/>",
      "UserCommentedOnItem": "<user/> ha comentado el <item/>",
      "UserCommented": "<user/> comentó",
      "Comment": "Comentar",
      "Action": "Acción",
      "NoDataAvailable": "No hay información disponible",
      "AssociateWithSelectedItem": "Asociar con los <item/> seleccionados",
      "ShowOnlyEntriesForSelectedItem": "Mostrar únicamente las entradas para el <item/> seleccionado",
      "ItemRequestedTime": "Hora solicitada de {{item}}",
      "RequestedTime": "Hora solicitada",
      "Request": "Solicitar"
    }
  },
  "BerthStatusDisplay": {
    "Labels": {
      "Berth": "Embarcadero",
      "Berths": "Embarcaderos",
      "ActiveAgent": "Agente activo",
      "Remarks": "Observaciones",
      "ShowPreviousDetails": "Mostrar los detalles anteriores",
      "ShowRefreshingDetails": "Dejar de actualizar los detalles",
      "StartRefreshingDetails": "Actualizar los detalles",
      "ShowNextDetails": "Mostrar los detalles siguientes",
      "DetailsDettings": "Ajustes de los detalles",
      "NoVesselsInPort": "No hay embarcaciones en el puerto",
      "FilterAll": "Todo",
      "FilterOccupied": "Ocupado",
      "FilterVacant": "Libre",
      "FilterNameAllBerths": "Todos {{name}}",
      "Every1Second": "Cada segundo",
      "Every5Second": "Cada 5 segundos",
      "Every15Second": "Cada 15 segundos",
      "Every1Minute": "Cada minuto",
      "RefreshRate": "Frecuencia de actualización",
      "DisplayedDetails": "Detalles en pantalla"
    }
  },
  "ToggleFullscreen": {
    "Labels": {
      "ToggleFullscreen": "Cambiar a pantalla completa"
    }
  },
  "PortCallMenuItem": {
    "Labels": {
      "Movement": "Movimiento",
      "PortCall": "Escala portuaria",
      "None": "Ninguno disponible",
      "StartDeparture": "Iniciar salida",
      "StartArrival": "Iniciar llegada",
      "RecordDeparture": "Registrar salida",
      "RecordArrival": "Registrar llegada",
      "StartShiftDeparture": "Iniciar desplazamiento de salida",
      "StartShiftArrival": "Iniciar desplazamiento de llegada",
      "RecordShiftDeparture": "Registrar desplazamiento de salida",
      "RecordShiftArrival": "Registrar desplazamiento de llegada",
      "DeleteShift": "Eliminar desplazamiento",
      "UndoLast": "Deshacer última {{- actionName}} {{- actionState}}",
      "UndoCompleted": "Acción anulada",
      "UndoInProgress": "Anulación en curso",
      "CancelPortCall": "Cancelar escala portuaria",
      "DeletePortCall": "Eliminar escala portuaria",
      "ShowDetails": "Mostrar detalles",
      "AgentHandover": "Relevo de agente",
      "PlannedTimeOfHandover": "Hora prevista para el relevo",
      "CharterAgent": "Agente de fletamento",
      "DeleteDeparture": "Eliminar salida",
      "CancelDeparture": "Cancelar salida",
      "Approve": "Aprobar",
      "Decline": "Denegar",
      "MarkForApproval": "Enviar para su autorización",
      "RequiresApproval": "Se requiere autorización",
      "StartCustom": "Iniciar la acción siguiente",
      "RecordCustom": "Registrar la acción siguiente",
      "CancelCustom": "Cancelar acción",
      "DeleteCustom": "Eliminar acción",
      "DeleteAgentHandover": "Eliminar relevo de agente"
    }
  },
  "PortCallTimeline": {
    "Buttons": {
      "LifecycleNone": "Ninguno disponible",
      "LifecycleStartDeparture": "Iniciar salida",
      "LifecycleStartArrival": "Iniciar llegada",
      "LifecycleRecordDeparture": "Registrar salida",
      "LifecycleRecordArrival": "Registrar llegada",
      "LifecycleStartShiftDeparture": "Iniciar desplazamiento de salida",
      "LifecycleStartShiftArrival": "Iniciar desplazamiento de llegada",
      "LifecycleRecordShiftDeparture": "Registrar desplazamiento de salida",
      "LifecycleRecordShiftArrival": "Registrar desplazamiento de llegada",
      "LifecycleRequiresApproval": "Se requiere autorización",
      "LifecycleStartCustom": "Iniciar la acción siguiente",
      "LifecycleRecordCustom": "Registrar la acción siguiente"
    },
    "Labels": {
      "AddDeparture": "Agregar salida",
      "AddBerthShift": "Añadir desplazamiento",
      "AddAgentHandover": "Añadir relevo de agente",
      "AddCustom": "Añadir acción de {{- actionName}}"
    }
  },
  "PortCallTimelineMenu": {
    "Labels": {
      "Remarks": "Observaciones",
      "OutstandingTodos": "Esta acción no será aplicada a los elementos restantes de la lista de verificación. ¿Está seguro/a de que desea continuar?",
      "ArrivalDialogTitle": "Llegada al puerto de escala",
      "ArrivalDialogMessage": "¿Está seguro/a de que desea iniciar la acción de llegada para {{vesselName}}? {{todos}}",
      "ShiftArrivalDialogTitle": "Desplazamiento de llegada",
      "ShiftArrivalDialogMessage": "¿Está seguro/a de que desea iniciar el desplazamiento de llegada para {{vesselName}}? {{todos}}",
      "RecordArrivalDialogTitle": "Llegada al puerto de escala",
      "RecordArrivalDialogInput": "Hora real de llegada",
      "RecordShiftArrivalDialogTitle": "Desplazamiento de llegada",
      "RecordShiftArrivalDialogInput": "Hora real de llegada",
      "DepartureDialogTitle": "Salida del puerto de escala",
      "DepartureDialogInput": "Hora real de salida",
      "ShiftDepartureDialogTitle": "Desplazamiento de salida",
      "ShiftDepartureDialogInput": "Hora real de salida",
      "DeleteShiftDialogTitle": "Eliminar desplazamiento",
      "DeleteShiftDialogMessage": "¿Está seguro/a de que desea eliminar el desplazamiento? {{todos}}",
      "RecordDepartureDialogTitle": "Salida del puerto de escala",
      "RecordDepartureDialogMessage": "¿Está seguro/a de que desea completar la salida y cerrar la escala portuaria para {{vesselName}}? {{todos}} Esta acción cerrará la escala portuaria. Por favor asegúrese de que todos los detalles han sido introducidos antes de completar la salida.",
      "RecordShiftDepartureDialogTitle": "Desplazamiento de salida",
      "RecordShiftDepartureDialogMessage": "¿Está seguro/a de que desea completar el desplazamiento de salida para {{vesselName}}? {{todos}}",
      "UndoActionDialogTitle": "Deshacer estado",
      "UndoActionDialogMessage": "¿Está seguro/a de que desea deshacer la última {{- actionName}} {{- actionState}} para {{vesselName}}?",
      "CancelPortCallDialogTitle": "Cancelar escala portuaria",
      "CancelPortCallDialogMessage": "¿Está seguro/a de que desea cancelar la escala portuaria para {{vesselName}}? Esta acción cerrará la escala portuaria. Por favor asegúrese de que todos los detalles han sido introducidos antes de la cancelación.",
      "CancelRequestDialogTitle": "Solicitud de cancelación de la escala portuaria para ",
      "CancelRequestDialogMessage": "¿Está seguro/a de que desea solicitar la cancelación para {{vesselName}}?",
      "DeletePortCallDialogTitle": "Eliminar escala portuaria",
      "DeletePortCallDialogMessage": "¿Está seguro/a de que desea eliminar la escala portuaria para {{vesselName}}? Esta acción cerrará la escala portuaria. Por favor asegúrese de que todos los detalles han sido introducidos antes de eliminar la escala portuaria.",
      "ActionCancelDialogTitle": "Cancelar {{- actionName}}",
      "ActionCancelDialogMessage": "¿Está seguro/a de que desea cancelar la {{- actionName}} para {{vesselName}}? {{todos}}",
      "CancelDepartureRequestDialogTitle": "Solicitud de cancelación de salida para",
      "CancelDepartureRequestDialogMessage": "¿Está seguro/a de que desea solicitar la cancelación de salida para {{vesselName}}?",
      "DeleteActionDialogTitle": "Eliminar {{- actionName}}",
      "DeleteActionDialogMessage": "¿Está seguro/a de que desea eliminar la {{- actionName}} para {{vesselName}}?",
      "StartCustomActionDialogTitle": "{{- actionName}} de la escala portuaria",
      "StartCustomActionDialogInput": "Hora real de {{- actionName}}",
      "RecordCustomActionDialogTitle": "{{- actionName}} de la escala portuaria",
      "RecordCustomActionDialogMessage": "¿Está seguro/a de que desea completar la {{- actionName}}?",
      "ApproveActionDialogTitle": "Autorizar {{- actionName}}",
      "ApproveActionDialogMessage": "Autorizar {{- actionName}}",
      "DeclineActionDialogTitle": "Denegar {{- actionName}}",
      "DeclineActionDialogMessage": "¿Está seguro/a de que desea rechazar la {{- actionName}}?",
      "MarkApprovalDialogTitle": "Enviar para autorización {{- actionName}}",
      "MarkApprovalDialogMessage": "¿Está seguro/a de que desea autorizar la {{- actionName}}?",
      "DeleteAgentHandoverDialogTitle": "Eliminar relevo de agente",
      "DeleteAgentHandoverDialogMessage": "¿Está seguro/a de que desea eliminar el relevo de agente para {{- actionName}}?",
      "AutomationArrivalWarning": "Esto impedirá una mayor automatización de la llegada",
      "AutomationDepartureWarning": "Esto impedirá una mayor automatización de la salida"
    }
  },
  "CustomAction": {
    "Errors": {
      "FirstAction": "La hora prevista debe ser posterior a la primera acción",
      "Departure": "La hora prevista debe ser anterior a la salida",
      "LastCompletedInProgress": "La hora prevista debe ser posterior a la última acción completada o en curso",
      "InvalidDate": "Formato de fecha inválido"
    }
  },
  "CustomActionMenu": {
    "Labels": {
      "DeleteAction": "Eliminar acción de {{- actionName}}",
      "CancelAction": "Cancelar acción de {{- actionName}}",
      "UndoAction": "Deshacer acción de {{- actionName}}",
      "RecordAction": "Registrar acción de {{- actionName}}",
      "StartAction": "Iniciar acción de {{- actionName}}",
      "CancelActionDialogTitle": "Cancelar acción de {{- actionName}}",
      "CancelActionDialogMessage": "¿Está seguro/a de que desea cancelar la acción de{{- actionName}} para {{vesselName}}?",
      "UndoActionDialogTitle": "Deshacer estado",
      "UndoActionDialogMessage": "¿Está seguro/a de que desea deshacer la última acción de {{- actionName}} {{- actionState}} para {{vesselName}}?",
      "RecordActionDialogTitle": "Acción de {{- actionName}} de la escala portuaria",
      "RecordActionDialogMessage": "¿Está seguro/a de que desea completar la acción {{- actionName}}?",
      "StartActionDialogTitle": "Acción de {{- actionName}} de la escala portuaria",
      "StartActionDialogInput": "Hora real de la acción de {{- actionName}}",
      "DeleteActionDialogTitle": "Eliminar acción de {{- actionName}}",
      "DeleteActionDialogMessage": "¿Está seguro/a de que desea eliminar la acción {{- actionName}}?"
    }
  },
  "CustomActionEdit": {
    "Labels": {
      "Schedule": "Horario de {{- actionName}}"
    }
  },
  "CustomActionCreateDialog": {
    "Labels": {
      "Title": "Añadir {{- actionName}}",
      "Service": "Servicio",
      "PlannedTime": "Hora prevista",
      "Notes": "Notas"
    }
  },
  "CustomActionScheduleEdit": {
    "Labels": {
      "SetPlannedTime": "Fijar hora prevista",
      "SetActualTime": "Establecer hora real",
      "Time": "Hora",
      "SetTime": "Establecer hora"
    },
    "Errors": {
      "ImvalidTime": "Hora inválida"
    }
  },
  "ShiftCreateDialog": {
    "Labels": {
      "NewShift": "Nuevo desplazamiento"
    },
    "Errors": {
      "ShiftDepartureAfterArrival": "El desplazamiento de salida debe ser posterior a un movimiento de llegada válido",
      "ShiftDepartureBeforeCompleted": "El desplazamiento de salida no puede ser anterior a un movimiento completado",
      "ShiftAfterDeparture": "El desplazamiento de llegada debe iniciarse inmediatamente después de la salida"
    }
  },
  "DepartureCreateDialog": {
    "Labels": {
      "NewDeparture": "Nueva salida"
    }
  },
  "AgentDepartureCreateDialog": {
    "Labels": {
      "NewDeparture": "Solicitar nueva salida"
    },
    "Errors": {
      "DepartureAfterArrival": "La salida debe ser posterior a la última acción"
    }
  },
  "TimeTooltip": {
    "Labels": {
      "ActualTime": "Hora real",
      "PlannedTime": "Hora prevista",
      "EstimatedTime": "Hora estimada",
      "RequestedTime": "Hora solicitada",
      "Notes": "Notas",
      "Action": "acción",
      "UpdatedAgo": "actualizado hace {{time}}"
    }
  },
  "Weather": {
    "Labels": {
      "TemperatureAt": "Temperatura de ({{symbol}}) a las {{time}}",
      "VisibilityAt": "Visibilidad de ({{unit}}) a las {{time}}",
      "AtmosphericPressureAt": "Presión atmosférica de ({{unit}}) a las {{time}}",
      "WindDirectionAt": "{{direction}} de {{degrees}}° a las {{time}}",
      "Gusting": "Ráfagas",
      "WeatherAt": "{{weather}} a las {{time}}"
    }
  },
  "WeatherIcon": {
    "Labels": {
      "Sunny": "Soleado",
      "MostlySunny": "Mayormente soleado",
      "PartlySunny": "Parcialmente soleado",
      "IntermittentClouds": "Nubes intermitentes",
      "HazySunshine": "Sol neblinoso",
      "MostlyCloudy": "Mayormente nublado",
      "Cloudy": "Nublado",
      "Dreary": "Gris (cielo cubierto)",
      "Fog": "Neblina",
      "Showers": "Chubascos",
      "MostlyCloudyWithShowers": "Mayormente nublado con chubascos",
      "PartlySunnyWithShowers": "Parcialmente soleado con chubascos",
      "TStorms": "Tormentas",
      "MostlyCloudyWithTStorms": "Mayormente soleado con tormentas",
      "PartlySunnyWithTStorms": "Parcialmente soleado con tormentas",
      "Rain": "Lluvia",
      "Flurries": "Ráfagas de nieve",
      "MostlyCloudyWithFlurries": "Mayormente nublado con ráfagas",
      "PartlySunnyWithFlurries": "Parcialmente soleado con ráfagas",
      "Snow": "Nieve",
      "MostlyCloudyWithSnow": "Mayormente nublado con nieve",
      "Ice": "Hielo",
      "Sleet": "Aguanieve",
      "FreezingRain": "Lluvia congelada",
      "RainAndSnow": "Lluvia y nieve",
      "Hot": "Caluroso",
      "Cold": "Frío",
      "Windy": "Ventoso",
      "Clear": "Despejado",
      "MostlyClear": "Mayormente despejado",
      "PartlyCloudy": "Parcialmente nublado",
      "HazyMoonlight": "Luz de luna con nubes",
      "PartlyCloudyWithShowers": "Parcialmente nublado con chubascos"
    }
  },
  "WindDirection": {
    "Labels": {
      "N": "Norte (N)",
      "NbE": "Norte con rumbo hacia el este (NbE)",
      "NNE": "Nornoreste (NNE)",
      "NEbN": "Noreste con rumbo hacia el norte (NEbN)",
      "NE": "Nordeste (NE)",
      "NEbE": "Noreste con rumbo hacia el este (NEbE)",
      "ENE": "Estenoreste (ENE)",
      "EbN": "Este con rumbo hacia el norte (EbN)",
      "E": "Este (E)",
      "EbS": "Este con rumbo hacia el sur (EbS)",
      "ESE": "Estesureste (ESE)",
      "SEbE": "Sureste con rumbo hacia el este (SEbE)",
      "SE": "Sureste (ES)",
      "SEbS": "Sureste con rumbo hacia el sur (SEbS)",
      "SSE": "Sursureste (SSE)",
      "SbE": "Sur con rumbo hacia el este (SbE)",
      "S": "Sur (S)",
      "SbW": "Sur con rumbo hacia el oeste (SbW)",
      "SSW": "Sursuroeste (SSW)",
      "SWbS": "Suroeste con rumbo hacia el sur (SWbS)",
      "SW": "Suroeste (SW)",
      "SWbW": "Suroeste con rumbo hacia el oeste (SWbW)",
      "WSW": "Oestesuroeste (WSW)",
      "WbS": "Oeste con rumbo hacia el sur (WbS)",
      "W": "Oeste (W)",
      "WbN": "Oeste con rumbo hacia el norte (WbN)",
      "WNW": "Oestenoroeste (WNW)",
      "NWbW": "Noroeste con rumbo hacia el oeste (NWbW)",
      "NW": "Noroeste (NW)",
      "NWbN": "Noroeste con rumbo hacia el norte (NWbN)",
      "NNW": "Nornoroeste (NNW)",
      "NbW": "Norte con rumbo hacia el oeste (NbW)"
    }
  }
}