import { ActionState, PortCallStatus } from "../models";

export const NonLifeCycleActionStates = [
  ActionState.DELETED,
  ActionState.CANCELLED,
  ActionState.SUPERSEDED
];

export const InoperativeActionStates = [
  ...NonLifeCycleActionStates,
  ActionState.REQUESTED
];

export const InactiveActionStates = [
  ...NonLifeCycleActionStates,
  ActionState.COMPLETED
];

export const InvalidVoyageActionStates = [
  ActionState.DELETED,
  ActionState.SUPERSEDED,
  ActionState.REQUESTED
];

export const InactivePortCallStatus = [
  PortCallStatus.CLOSED, 
  PortCallStatus.CANCELLED, 
  PortCallStatus.DELETED
];

export const InoperativePortCallStatus = [
  PortCallStatus.DELETED,
  PortCallStatus.CANCELLED,
  PortCallStatus.SUPERSEDED
];