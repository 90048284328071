import { ActionState } from '../../../models';
import { Box, ListItem, makeStyles } from '@material-ui/core';
import PopoverTooltip from '../../Tooltip/PopoverTooltip';
import ActionStateAndTimeTooltip from '../../Tooltip/ActionStateAndTimeTooltip';
import TimeEstimate from '../../TimeEstimate';
import { ActionTypeIds } from '../../../environment';
import PortCallTimelinePortCallMenu from '../../PortCall/PortCallTimelinePortCallMenu';
import { useMemo } from 'react';
import usePortCallDetails from '../../../hooks/usePortCallDetails';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexShrink: '0',
    height: 'fit-content',
  },
  cancelled: {
    textDecorationLine: 'line-through',
    color: '#aaaaaa'
  },
  card: {
    display: 'flex',
    height: 'fit-content',
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px rgb(0,0,0,0.3)',
  },
  innerContainer: {
    padding: '0 1rem',
    width: '100%'
  },
  movementLocation: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  timelineConnector: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '1rem',
    height: '100%',
    margin: '0 2rem',
  },
  connector: {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    transform: 'scale(0.75)',
    transition: 'all .25s ease-out',
  },
  timelineStripLine: {
    position: 'absolute',
    height: '4rem',
    zIndex: 2,
    width: 0,
    top: '1rem',
    left: '35.5%',
    border: '0.25rem solid',
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
    borderColor: theme.palette.primary.main,
  },
  notification: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '-30%'
  }
}));

const VoyageListItem = ({
  action,
  portCall,
  children,
  isDelay,
  disableGutters,
  selected,
  disabled,
  isBookend = false,
  ...otherProps
}) => {
  const classes = useStyles();
  const portCallDS = usePortCallDetails(portCall?.id);
  const actionDS = useMemo(() => portCallDS?.actions?.length && portCallDS.actions.find((a) => a.id === action?.id), [action?.id, portCallDS?.actions]);

  const isCancelled = action?.state && action.state === ActionState.CANCELLED;
  const isMovement = action?.type?.id === ActionTypeIds.MOVEMENT;
  const menuDisabled = !portCallDS || !actionDS || !isMovement; 

  return (
    action ?
      <Box className={`${classes.container} ${isCancelled && 'Cancelled'} VoyageTimelineAction`} data-id={action?.id}>
        <Box className={`${classes.card} ${isCancelled && classes.cancelled}`}>
          <PopoverTooltip tooltip={<ActionStateAndTimeTooltip action={action} />} style={{ display: 'flex', alignItems: 'center' }}>
            <ListItem
              className={classes.innerContainer}
              component="div"
              disableGutters={disableGutters}
              selected={selected}
              disabled={disabled}
              {...otherProps}
            >
              {children}
            </ListItem>
          </PopoverTooltip>
          <Box className={classes.notification}>
            <TimeEstimate
              isIgnoreTimer={true}
              requested={action?.timeRequested}
              requestedTime={action?.requestedTime}
              planned={action?.timePlanned}
              actual={action?.timeActual}
              estimated={action?.timeEstimated}
              estimatedUpdatedAt={action?.timeEstimatedUpdatedAt}
              reverse={true}
            /></Box>

          {/*Voyage Status i.e. Delayed, AS planned*/}
          {/* <VoyageListItemStatus isDelay={isDelay} /> */}
          {isBookend ? (
          <PortCallTimelinePortCallMenu
            disabled={menuDisabled}
            relevantAction={actionDS || action}
            portCall={portCallDS || portCall}
            contexts={["portCall", "action", "details"]}
            skipCheck={true}
            archived={portCall?.archived}
            disabledCancel={true}
            disabledDelete={true}
          />
        ) : (
          <PortCallTimelinePortCallMenu
            disabled={menuDisabled}
            relevantAction={actionDS || action}
            portCall={portCallDS || portCall}
            contexts={["portCall", "action", "details"]}
            skipCheck={true}
            archived={portCall?.archived}
          />
        )}
        </Box>

      </Box > : <></>
  );
};

export default VoyageListItem;
