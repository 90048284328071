import React from 'react';
import AISReceiverInfoPopup from './AISReceiverInfoPopup';

import { Attrs, FeatureType } from './constants';
import VesselInfoPopup from './VesselInfoPopup';

const FeatureInfo = ({ feature }) => {
  const meta = feature ? feature.get(Attrs.META) : null;
  const featureType = feature ? feature.get(Attrs.TYPE) : null;

  return (
    !meta ? null :
    <>
      {(featureType === FeatureType.AISReceiver) ? <AISReceiverInfoPopup meta={meta} /> : null}
      {(featureType === FeatureType.Vessel) ? <VesselInfoPopup meta={meta} /> : null}
    </>
  );
};

export default FeatureInfo;