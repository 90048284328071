import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, List, ListItem, ListItemText, ListSubheader, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { Delete, Information, Lock, Plus } from 'mdi-material-ui';
import { DataStore } from 'aws-amplify';
import isEqual from 'lodash.isequal';
import { useContext } from 'react';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { ActionType, ActionState, Template, TemplateType, TemplateSubType, ActionTemplate, ActionMovementType } from '../../models';
import { AdminModelEditorFormHeader } from './AdminModelEditor';
import { generateActionFromTemplate, generateTemplateFromAction } from '../../utils/templateUtils';
import ChargeablesEditor from '../Chargeables/ChargeablesEditor';
import { ActionTypeIds } from '../../environment';
import { sortActionTypes } from '../../utils/sorters';
import { useTranslation } from 'react-i18next';
import useNotificationRules from '../../hooks/useNotificationRules';
import FeatureFlags from '../../constants/FeatureFlags';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { Prompt } from 'react-router-dom';

/**
 * Modified version of AdminModelEditor
 * 
 * Templating required additional logic and listing action subtypes
 */

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    paddingBottom: "0 !important"
  },
  list: {
    overflowY: "auto",
  },
  listItem: {
    height: "2.5rem",
    '&:not(:last-child)': {
      borderBottom: "1px solid rgba(0,0,0,0.1)"
    }
  },
  listItemSecondary: {
    opacity: 0.5
  },
  right: {
    flex: "0 0 33%",
    borderLeft: "0.0625rem solid #e0e0e0",
  },
  information: {
    paddingLeft: ".25rem",
    opacity: 0.5
  }
}));

const ActionTypeTemplateForm = ({ actionType, subType }) => {
  const { t } = useTranslation();
  const dataStoreContext = useContext(DataStoreContext);
  const { templates } = dataStoreContext;

  // if template does not exist, create a new one
  const template = useMemo(() =>
    templates.find(t => t.typeId === actionType.id && (!subType || t.subType === subType)) ||
    new Template({
      name: actionType.name,
      type: TemplateType.ACTION,
      subType: subType || null, typeId: actionType.id,
      value: JSON.stringify(new ActionTemplate({
        movementType: subType ?
          (subType === TemplateSubType.ACTION_ARRIVAL && ActionMovementType.ARRIVAL) ||
          (subType === TemplateSubType.ACTION_DEPARTURE && ActionMovementType.DEPARTURE) ||
          (subType === TemplateSubType.ACTION_SHIFT_ARRIVAL && ActionMovementType.SHIFT_ARRIVAL) ||
          (subType === TemplateSubType.ACTION_SHIFT_DEPARTURE && ActionMovementType.SHIFT_DEPARTURE)
          : null
      }))
    }),
    [actionType, subType, dataStoreContext]);

  // generate action from template to feed into defaults components
  const templateActionReference = useMemo(() => generateActionFromTemplate(template, dataStoreContext, ActionState.PLANNED), [template, actionType, dataStoreContext]);
  const templateAction = useMemo(() => templateActionReference, [templateActionReference]);
  const setTemplateAction = (action) => {
    // generate template from edited action and save it
    const generated = generateTemplateFromAction(action, dataStoreContext);
    const newTemplate = Template.copyOf(template, updated => { updated.value = generated.value; });
    DataStore.save(newTemplate);
  };

  return (
    <>
      <Box display="flex" marginTop="2rem" marginBottom="1rem" alignItems="center">
        <Typography variant="subtitle1">{t('AdminActionType.Labels.ActionTypeDefaults')}</Typography>
        <Tooltip title={t('AdminActionType.Labels.ActionTypeDefaultsHelp')} placement="top">
          <Information fontSize="small" style={{ opacity: 0.25, marginLeft: "0.25rem" }} />
        </Tooltip>
      </Box>
      <Typography variant="h6">{t('ChargeableItems.Labels.Title')}</Typography>
      {templateAction &&
        <ChargeablesEditor
          action={templateAction}
          setAction={(value) => setTemplateAction(value)}
          disableUnitCost
        />
      }
    </>
  );
};

const FormWrap = ({ value, subType, onClose, onUpdate, canSave, canUpdate, setSaveIsDisabled }) => {
  const { t } = useTranslation();
  const [_value, _setValue] = useState(value);
  useEffect(() => _setValue(value), [value, _setValue]);
  const onChange = (name, value) => {
    _setValue(ActionType.copyOf(_value, updated => updated[name] = value));
  };
  const saveDisabled = isEqual(value, _value) || !canSave(_value);
  const disabled = !canUpdate(_value);

  const { actionTypes } = useContext(DataStoreContext);
  const exists = useMemo(() => actionTypes.some(at => at.id === value.id), [actionTypes, value]);
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  useEffect(() => {
    setSaveIsDisabled(saveDisabled)
  }, [saveDisabled])

  return (
    <>
    <Prompt
        when={!saveDisabled} 
        message={(_, action) => {
          return action === "PUSH" ? t('AdminModelEditor.Labels.UnsavedChangesMessage') : null;
        }}
      />
    <Box>
      <AdminModelEditorFormHeader readOnly={disabled} disabled={saveDisabled} onSave={() => onUpdate(_value)} onClose={onClose} />
      <TextField id="ActionTypeName" required disabled={disabled} value={_value.name || ''} label={t('ActionType.Labels.Name')} fullWidth variant="outlined" onChange={e => onChange("name", e.target.value)} />     
      {(value === _value) && exists && !isTariffBookEnabled && 
        <ActionTypeTemplateForm actionType={_value} subType={subType} />
      }
    </Box>
    </>
  );
};

export const AdminModelEditorHeaderColumn = ({ label, flexBasis, helperText }) => {
  const classes = useStyles();
  return (
    <Box flex={`1 1 ${flexBasis}`} display="flex" alignItems="center">
      <Typography>{label}</Typography>
      {helperText &&
        <Tooltip title={helperText} placement="top">
          <Information fontSize="small" className={classes.information} />
        </Tooltip>
      }
    </Box>
  );
};

const AdminActionTypeHeaderContent = () => {
  const { t } = useTranslation();
  return <AdminModelEditorHeaderColumn label={t('ActionType.Labels.Name')} flexBasis="75%" />
}

const AdminActionTypeListItemContent = ({ value }) =>
  <>
    <ListItemText primary={value.name} style={{ flex: "1 1 75%" }} />
  </>

const AdminActionTypeModelEditor = () => {
  const { t } = useTranslation();
  const { updateNotificationRulesForDeletedActionType } = useNotificationRules();
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const { actionTypes } = useContext(DataStoreContext);
  useEffect(() => { selectedItem && setSelectedItem(prev => actionTypes.find(at => at.id === prev.id)); }, [actionTypes]);
  const classes = useStyles();
  const [saveIsDisabled, setSaveIsDisabled] = useState(true);
  const [open, setOpen] = useState({state:false, item: null});

  const canSave = i => Boolean(i.name);
  const canUpdate = i => i.editable;
  const canDelete = i => i.editable;
  const defaultItem = {
    editable: true,
    lifecycle: false
  };

  // create a mapping to template subtypes and inject entries into list
  const eventActionType = useMemo(() => actionTypes.find(at => at.id === ActionTypeIds.EVENT), [actionTypes]);
  const movementActionType = useMemo(() => actionTypes.find(at => at.id === ActionTypeIds.MOVEMENT), [actionTypes]);
  const movementSubTypes = useMemo(() => movementActionType && ({
    [TemplateSubType.ACTION_ARRIVAL]: ActionType.copyOf(movementActionType, updated => { updated.name = t('AdminActionType.Labels.MovementArrival') }),
    [TemplateSubType.ACTION_DEPARTURE]: ActionType.copyOf(movementActionType, updated => { updated.name = t('AdminActionType.Labels.MovementDeparture') }),
    [TemplateSubType.ACTION_SHIFT_ARRIVAL]: ActionType.copyOf(movementActionType, updated => { updated.name = t('AdminActionType.Labels.MovementShiftArrival') }),
    [TemplateSubType.ACTION_SHIFT_DEPARTURE]: ActionType.copyOf(movementActionType, updated => { updated.name = t('AdminActionType.Labels.MovementShiftDeparture') })
  }), [movementActionType, t]);
  const items = movementSubTypes ? [
    ActionType.copyOf(eventActionType, updated => { updated.name = t('ActionType.Labels.Event') }),
    ...actionTypes.filter(at => ![eventActionType.id, movementActionType.id].includes(at.id)).filter(at => !at.deleted),
    ...Object.keys(movementSubTypes).map(key => movementSubTypes[key])
  ].sort(sortActionTypes) : [];

  const selectedSubType = movementSubTypes && Object.keys(movementSubTypes).find(key => movementSubTypes[key] === selectedItem);

  const handleDeleteActionType = useCallback(async () => {
    if(deleteItem){
      await DataStore.save(ActionType.copyOf(deleteItem, updated => { updated.deleted = true; }))
      await updateNotificationRulesForDeletedActionType(deleteItem?.id);
      setSelectedItem(null);
      setDeleteItem(null);
    }
  }, [setSelectedItem, deleteItem, setDeleteItem]);
  
  const handleItemClick = (item) => {
    if (selectedItem && selectedItem === item) {
      setSelectedItem(item);
     } else {
      !selectedItem && setSelectedItem(item);
      if (selectedItem){
        if(!saveIsDisabled){
          setOpen({state: true, item: item});
        } else setSelectedItem(item);
      };
    };
  };

  const handleClose = () => {
    setOpen({state:false, item: null});
  };

  const handleConfirm = () => {
    setSelectedItem(open.item);
    setOpen({...open, state: false});
  };

  const handleEditorClose = () => {
    if(!saveIsDisabled){
      setOpen({state: true, item: null});
    } else setSelectedItem(null);
  };

  const itemName = t('ActionType.Labels.ActionType');

  return (
    <>
      <ConfirmDialog
        open={open.state}
        title={t("Common.Labels.UnsavedChanges")}
        message={t('AdminModelEditor.Labels.UnsavedChangesMessage')}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={t("Common.Buttons.Leave")}
      />
      <CardContent className={classes.main}>
        <Box display="flex" alignItems="center" height="2rem" flex="none">
          <Box flexGrow={1} />
          <Tooltip title={t('AdminModelEditor.Labels.CreateEntry')} placement="top">
            <Fab id={'CreateEntryButton'} color="primary" onClick={() => setSelectedItem(new ActionType(defaultItem))}>
              <Plus fontSize="small" />
            </Fab>
          </Tooltip>
        </Box>

        <ListSubheader component="div" style={{ display: "flex", flex: "none", height: "2rem", alignItems: "flex-end", marginBottom: "1rem" }}>
          <AdminActionTypeHeaderContent />
        </ListSubheader>

        <List disablePadding dense className={classes.list} id="AdminModelEditorList">
          {items.map((i, index) =>
            <ListItem
              key={i.id + index}
              id={"AdminModelEditorList-"+ index }
              data-id={i.id}
              selected={selectedItem && i === selectedItem}
              button
              disableRipple
              onMouseEnter={() => setHoveredItem(i)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={() => handleItemClick(i)}
              className={classes.listItem}
            >
              <AdminActionTypeListItemContent value={i} classes={classes} />
              {i === hoveredItem &&
                <Box position="absolute" right="1rem">
                  {canDelete(i) &&
                    <Tooltip title={t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })} placement="top">
                      <IconButton id="AdminModelEditorDeleteButton" size="small" onClick={(e) => { e.stopPropagation(); setDeleteItem(i); }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  }
                </Box>
              }
              {!canUpdate(i) &&
                <Box position="absolute" right="1rem" display="flex" alignItems="center">
                  <Tooltip title={t('AdminModelEditor.Labels.ReadOnly')} placement="top">
                    <Lock fontSize="small" style={{ opacity: .25 }} />
                  </Tooltip>
                </Box>
              }
            </ListItem>
          )}
        </List>
      </CardContent>
      {selectedItem &&
        <CardContent className={classes.right}>
          <FormWrap
            model={ActionType}
            value={selectedItem}
            subType={selectedSubType}
            onClose={() => handleEditorClose()}
            onUpdate={(value) => { DataStore.save(value); }}
            canUpdate={canUpdate}
            canSave={canSave}
            setSaveIsDisabled={setSaveIsDisabled}
          />
        </CardContent>
      }
      <Dialog onClose={() => setDeleteItem(null)} open={Boolean(deleteItem)}>
        <DialogTitle>{t('AdminModelEditor.Labels.DeleteEntry')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('AdminModelEditor.Labels.DeleteEntryMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteItem(null)} color="default">
            {t('Common.Buttons.Cancel')}
          </Button>
          <Button id="AdminModelEditorConfirmDeleteButton" onClick={handleDeleteActionType} color="primary">
            {t('Common.Buttons.Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminActionTypeModelEditor;