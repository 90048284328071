/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const archivePortCalls = /* GraphQL */ `
  query ArchivePortCalls(
    $dateFrom: AWSDateTime
    $dateTo: AWSDateTime
    $portCallId: String
    $unarchive: Boolean
  ) {
    archivePortCalls(
      dateFrom: $dateFrom
      dateTo: $dateTo
      portCallId: $portCallId
      unarchive: $unarchive
    ) {
      success
      __typename
    }
  }
`;
export const getVesselExternal = /* GraphQL */ `
  query GetVesselExternal($imo: String, $mmsi: String, $useApi: Boolean) {
    getVesselExternal(imo: $imo, mmsi: $mmsi, useApi: $useApi) {
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      serviceResponse {
        error
        httpStatusCode
        service
        errorCode
        message
        responseProcessed
        __typename
      }
      __typename
    }
  }
`;
export const getTidalEventsExternal = /* GraphQL */ `
  query GetTidalEventsExternal($useApi: Boolean, $duration: Int) {
    getTidalEventsExternal(useApi: $useApi, duration: $duration) {
      events {
        eventType
        dateTime
        height
        isApproximateTime
        isApproximateHeight
        filtered
        __typename
      }
      serviceResponse {
        error
        httpStatusCode
        service
        errorCode
        message
        responseProcessed
        __typename
      }
      __typename
    }
  }
`;
export const getForecastAccuWeatherExternal = /* GraphQL */ `
  query GetForecastAccuWeatherExternal($useAwApi: Boolean) {
    getForecastAccuWeatherExternal(useAwApi: $useAwApi) {
      headline {
        effectiveDate
        effectiveEpochDate
        severity
        text
        category
        endDate
        endEpochDate
        mobileLink
        link
        __typename
      }
      forecast {
        date
        epochDate
        sun {
          rise
          epochRise
          set
          epochSet
          __typename
        }
        moon {
          rise
          epochRise
          set
          epochSet
          phase
          age
          __typename
        }
        temperature {
          minimum {
            value
            unit
            unitType
            __typename
          }
          maximum {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        realFeelTemperature {
          minimum {
            value
            unit
            unitType
            __typename
          }
          maximum {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        realFeelTemperatureShade {
          minimum {
            value
            unit
            unitType
            __typename
          }
          maximum {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        hoursOfSun
        degreeDaySummary {
          heating {
            value
            unit
            unitType
            __typename
          }
          cooling {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        airAndPollen {
          name
          value
          category
          categoryValue
          type
          __typename
        }
        day {
          icon
          iconPhrase
          hasPrecipitation
          shortPhrase
          longPhrase
          precipitationProbability
          thunderstormProbability
          rainProbability
          snowProbability
          iceProbability
          wind {
            speed {
              value
              unit
              unitType
              __typename
            }
            direction {
              degrees
              localized
              english
              __typename
            }
            __typename
          }
          windGust {
            speed {
              value
              unit
              unitType
              __typename
            }
            direction {
              degrees
              localized
              english
              __typename
            }
            __typename
          }
          totalLiquid {
            value
            unit
            unitType
            __typename
          }
          rain {
            value
            unit
            unitType
            __typename
          }
          snow {
            value
            unit
            unitType
            __typename
          }
          ice {
            value
            unit
            unitType
            __typename
          }
          hoursOfPrecipitation
          hoursOfRain
          hoursOfSnow
          hoursOfIce
          cloudCover
          __typename
        }
        night {
          icon
          iconPhrase
          hasPrecipitation
          shortPhrase
          longPhrase
          precipitationProbability
          thunderstormProbability
          rainProbability
          snowProbability
          iceProbability
          wind {
            speed {
              value
              unit
              unitType
              __typename
            }
            direction {
              degrees
              localized
              english
              __typename
            }
            __typename
          }
          windGust {
            speed {
              value
              unit
              unitType
              __typename
            }
            direction {
              degrees
              localized
              english
              __typename
            }
            __typename
          }
          totalLiquid {
            value
            unit
            unitType
            __typename
          }
          rain {
            value
            unit
            unitType
            __typename
          }
          snow {
            value
            unit
            unitType
            __typename
          }
          ice {
            value
            unit
            unitType
            __typename
          }
          hoursOfPrecipitation
          hoursOfRain
          hoursOfSnow
          hoursOfIce
          cloudCover
          __typename
        }
        __typename
      }
      serviceResponse {
        error
        httpStatusCode
        service
        errorCode
        message
        responseProcessed
        __typename
      }
      __typename
    }
  }
`;
export const getCurrentConditionsAccuWeatherExternal = /* GraphQL */ `
  query GetCurrentConditionsAccuWeatherExternal($useAwApi: Boolean) {
    getCurrentConditionsAccuWeatherExternal(useAwApi: $useAwApi) {
      currentConditions {
        localObservationDateTime
        expires
        weatherIcon
        weatherText
        isDayTime
        temperature {
          metric {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        visibility {
          metric {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        pressure {
          metric {
            value
            unit
            unitType
            __typename
          }
          __typename
        }
        wind {
          speed {
            metric {
              value
              unit
              unitType
              __typename
            }
            __typename
          }
          direction {
            degrees
            localized
            english
            __typename
          }
          __typename
        }
        windGust {
          speed {
            metric {
              value
              unit
              unitType
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      serviceResponse {
        error
        httpStatusCode
        service
        errorCode
        message
        responseProcessed
        __typename
      }
      __typename
    }
  }
`;
export const getMarlinAccessToken = /* GraphQL */ `
  query GetMarlinAccessToken($refreshToken: String) {
    getMarlinAccessToken(refreshToken: $refreshToken) {
      idTokenJwt
      idTokenExpiry
      accessTokenJwt
      accessTokenExpiry
      refreshToken
      __typename
    }
  }
`;
export const getParameterStoreValue = /* GraphQL */ `
  query GetParameterStoreValue($key: String) {
    getParameterStoreValue(key: $key)
  }
`;
export const ExportPortCall = /* GraphQL */ `
  query ExportPortCall($request: ExportRequest) {
    ExportPortCall(request: $request) {
      s3Key
      __typename
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserSetting = /* GraphQL */ `
  query GetUserSetting($id: ID!) {
    getUserSetting(id: $id) {
      id
      language
      mapLayer
      exportConfig {
        lengthUnit
        columns
        __typename
      }
      movementsFilterId
      berthsFilterId
      fullScreen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $filter: ModelUserSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        mapLayer
        exportConfig {
          lengthUnit
          columns
          __typename
        }
        movementsFilterId
        berthsFilterId
        fullScreen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserSettings = /* GraphQL */ `
  query SyncUserSettings(
    $filter: ModelUserSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        language
        mapLayer
        exportConfig {
          lengthUnit
          columns
          __typename
        }
        movementsFilterId
        berthsFilterId
        fullScreen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      type
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($id: ID!) {
    getAuditLog(id: $id) {
      id
      table
      type
      source
      userId
      comment
      timeChanged
      objectId
      portCallId
      old
      new
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditLogs = /* GraphQL */ `
  query ListAuditLogs(
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        table
        type
        source
        userId
        comment
        timeChanged
        objectId
        portCallId
        old
        new
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditLogs = /* GraphQL */ `
  query SyncAuditLogs(
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        table
        type
        source
        userId
        comment
        timeChanged
        objectId
        portCallId
        old
        new
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditLogEntryByObjectId = /* GraphQL */ `
  query GetAuditLogEntryByObjectId(
    $objectId: ID!
    $timeChanged: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditLogEntryByObjectId(
      objectId: $objectId
      timeChanged: $timeChanged
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        table
        type
        source
        userId
        comment
        timeChanged
        objectId
        portCallId
        old
        new
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditLogEntryByPortCallId = /* GraphQL */ `
  query GetAuditLogEntryByPortCallId(
    $portCallId: ID!
    $timeChanged: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuditLogEntryByPortCallId(
      portCallId: $portCallId
      timeChanged: $timeChanged
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        table
        type
        source
        userId
        comment
        timeChanged
        objectId
        portCallId
        old
        new
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      requestPortCallId
      requestPortCallId_
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      type
      source
      sourceData
      agent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      requesterUserName
      requesterEmail
      portCallData {
        vesselName
        vesselImo
        vesselMmsi
        vesselCallsign
        __typename
      }
      actionData {
        actionId
        actionTypeId
        movementType
        timeRequested
        timePlanned
        approved
        locationId
        __typename
      }
      remark
      createdAt
      updatedAt
      auditMeta {
        comment
        userId
        source
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      requestAgentId
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestPortCallId
        requestPortCallId_
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        type
        source
        sourceData
        agent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        requesterUserName
        requesterEmail
        portCallData {
          vesselName
          vesselImo
          vesselMmsi
          vesselCallsign
          __typename
        }
        actionData {
          actionId
          actionTypeId
          movementType
          timeRequested
          timePlanned
          approved
          locationId
          __typename
        }
        remark
        createdAt
        updatedAt
        auditMeta {
          comment
          userId
          source
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        requestAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRequests = /* GraphQL */ `
  query SyncRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        requestPortCallId
        requestPortCallId_
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        type
        source
        sourceData
        agent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        requesterUserName
        requesterEmail
        portCallData {
          vesselName
          vesselImo
          vesselMmsi
          vesselCallsign
          __typename
        }
        actionData {
          actionId
          actionTypeId
          movementType
          timeRequested
          timePlanned
          approved
          locationId
          __typename
        }
        remark
        createdAt
        updatedAt
        auditMeta {
          comment
          userId
          source
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        requestAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRequestsByPortCallId = /* GraphQL */ `
  query GetRequestsByPortCallId(
    $requestPortCallId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRequestsByPortCallId(
      requestPortCallId: $requestPortCallId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestPortCallId
        requestPortCallId_
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        type
        source
        sourceData
        agent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        requesterUserName
        requesterEmail
        portCallData {
          vesselName
          vesselImo
          vesselMmsi
          vesselCallsign
          __typename
        }
        actionData {
          actionId
          actionTypeId
          movementType
          timeRequested
          timePlanned
          approved
          locationId
          __typename
        }
        remark
        createdAt
        updatedAt
        auditMeta {
          comment
          userId
          source
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        requestAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVoyage = /* GraphQL */ `
  query GetVoyage($id: ID!) {
    getVoyage(id: $id) {
      id
      name
      status
      lastImportSha256
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      voyageFirstDeparture
      voyageLastArrival
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      voyageVesselId
      __typename
    }
  }
`;
export const listVoyages = /* GraphQL */ `
  query ListVoyages(
    $filter: ModelVoyageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoyages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        lastImportSha256
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        voyageFirstDeparture
        voyageLastArrival
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        voyageVesselId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVoyages = /* GraphQL */ `
  query SyncVoyages(
    $filter: ModelVoyageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVoyages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        lastImportSha256
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        voyageFirstDeparture
        voyageLastArrival
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        voyageVesselId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVoyageByName = /* GraphQL */ `
  query GetVoyageByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoyageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVoyageByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        lastImportSha256
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        voyageFirstDeparture
        voyageLastArrival
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        voyageVesselId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCall = /* GraphQL */ `
  query GetPortCall($id: ID!) {
    getPortCall(id: $id) {
      id
      referenceId
      status
      lastZone
      remarks
      portCallCategoryId_
      category {
        id
        name
        description
        type
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      portCallTags
      documents
      actions {
        items {
          id
          actionPortCallId
          actionPortCallId_
          actionVoyageId
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          timeRequested
          timePlanned
          timeEstimated
          timeActual
          timeCompleted
          timeEstimatedUpdatedAt
          actionAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          remarks
          automationLockedOut
          vesselMastersName
          vesselCrew
          vesselPassengers
          vesselForwardDraught
          vesselAftDraught
          vesselSailingDraught
          vesselAirDraught
          type {
            id
            name
            lifecycle
            editable
            deleted
            used
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          movementType
          movementLocation {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          movementVesselReadyTime
          movementPilotBoardingTime
          movementPilots
          movementLinesmen
          movementMooringVessels
          movementPropulsionState
          movementPropulsionRemarks
          movementRudderState
          movementRudderRemarks
          movementFwdAzimuthState
          movementFwdAzimuthRemarks
          movementAftAzimuthState
          movementAftAzimuthRemarks
          movementFwdTunnelThrusterState
          movementFwdTunnelThrusterRemarks
          movementAftTunnelThrusterState
          movementAftTunnelThrusterRemarks
          eventType
          todos {
            title
            description
            status
            todoType
            __typename
          }
          documents
          chargeableItems {
            id
            tariffData {
              code
              description
              unitCost
              currency
              notes
              minimumCost
              actionTypes
              __typename
            }
            tariffUnit
            tariffSecondaryUnit
            tariffId
            tariffBookId
            overrideUnitCost
            notes
            quantity
            secondaryMult
            total
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          automationMeta {
            eventIds
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          actionActionAgentId
          actionTypeId
          actionMovementLocationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      portCallVesselId_
      portCallVesselId
      vessel {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      lastPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      nextPort {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfLoading {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      portOfDischarge {
        name
        portCode
        countryCode
        searchString
        __typename
      }
      cargos {
        items {
          id
          cargoPortCallId
          portCallArchived
          type {
            id
            name
            units
            code
            charge
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          initialAmount
          amount
          comments
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          cargoTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requests {
        items {
          id
          requestPortCallId
          requestPortCallId_
          portCall {
            id
            referenceId
            status
            lastZone
            remarks
            portCallCategoryId_
            category {
              id
              name
              description
              type
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            portCallTags
            documents
            actions {
              items {
                id
                actionPortCallId
                actionPortCallId_
                actionVoyageId
                portCallArchived
                state
                timeRequested
                timePlanned
                timeEstimated
                timeActual
                timeCompleted
                timeEstimatedUpdatedAt
                remarks
                automationLockedOut
                vesselMastersName
                vesselCrew
                vesselPassengers
                vesselForwardDraught
                vesselAftDraught
                vesselSailingDraught
                vesselAirDraught
                movementType
                movementVesselReadyTime
                movementPilotBoardingTime
                movementPilots
                movementLinesmen
                movementMooringVessels
                movementPropulsionState
                movementPropulsionRemarks
                movementRudderState
                movementRudderRemarks
                movementFwdAzimuthState
                movementFwdAzimuthRemarks
                movementAftAzimuthState
                movementAftAzimuthRemarks
                movementFwdTunnelThrusterState
                movementFwdTunnelThrusterRemarks
                movementAftTunnelThrusterState
                movementAftTunnelThrusterRemarks
                eventType
                documents
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                actionActionAgentId
                actionTypeId
                actionMovementLocationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            portCallVesselId_
            portCallVesselId
            vessel {
              id
              imo
              mmsi
              callSign
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              vesselAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              vesselVesselAgentId
              __typename
            }
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            lastPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            nextPort {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfLoading {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            portOfDischarge {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            cargos {
              items {
                id
                cargoPortCallId
                portCallArchived
                initialAmount
                amount
                comments
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                cargoTypeId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            requests {
              items {
                id
                requestPortCallId
                requestPortCallId_
                portCallArchived
                state
                type
                source
                sourceData
                requesterUserName
                requesterEmail
                remark
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                requestAgentId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            agents {
              contactId
              actionId
              time
              __typename
            }
            sourceData
            createdAtDate
            archived
            arrivalTime
            departureTime
            origin
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            portLocationId
            portUnlocode
            finalArrivalPlannedTime
            finalArrivalRequestedTime
            finalArrivalActualTime
            finalDeparturePlannedTime
            finalDepartureRequestedTime
            finalDepartureActualTime
            sortTime
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            portCallCategoryId
            __typename
          }
          portCallArchived
          state
          type
          source
          sourceData
          agent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          requesterUserName
          requesterEmail
          portCallData {
            vesselName
            vesselImo
            vesselMmsi
            vesselCallsign
            __typename
          }
          actionData {
            actionId
            actionTypeId
            movementType
            timeRequested
            timePlanned
            approved
            locationId
            __typename
          }
          remark
          createdAt
          updatedAt
          auditMeta {
            comment
            userId
            source
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          requestAgentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      agents {
        contactId
        actionId
        time
        __typename
      }
      sourceData
      createdAtDate
      archived
      arrivalTime
      departureTime
      origin
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      portLocationId
      portUnlocode
      finalArrivalPlannedTime
      finalArrivalRequestedTime
      finalArrivalActualTime
      finalDeparturePlannedTime
      finalDepartureRequestedTime
      finalDepartureActualTime
      sortTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallCategoryId
      __typename
    }
  }
`;
export const listPortCalls = /* GraphQL */ `
  query ListPortCalls(
    $filter: ModelPortCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPortCalls = /* GraphQL */ `
  query SyncPortCalls(
    $filter: ModelPortCallFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortCalls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallsByCategoryId = /* GraphQL */ `
  query GetPortCallsByCategoryId(
    $portCallCategoryId_: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPortCallsByCategoryId(
      portCallCategoryId_: $portCallCategoryId_
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallsByCreatedAtVesselId = /* GraphQL */ `
  query GetPortCallsByCreatedAtVesselId(
    $portCallVesselId_: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPortCallsByCreatedAtVesselId(
      portCallVesselId_: $portCallVesselId_
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallsByCreatedAtDate = /* GraphQL */ `
  query GetPortCallsByCreatedAtDate(
    $createdAtDate: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPortCallsByCreatedAtDate(
      createdAtDate: $createdAtDate
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallData = /* GraphQL */ `
  query GetPortCallData($id: ID!) {
    getPortCallData(id: $id) {
      id
      type
      portCallId
      actionId
      data
      s3file {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      portCallDataS3fileId
      __typename
    }
  }
`;
export const listPortCallData = /* GraphQL */ `
  query ListPortCallData(
    $filter: ModelPortCallDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortCallData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        portCallId
        actionId
        data
        s3file {
          id
          name
          s3Path
          mimeType
          sizeBytes
          portCallId
          actionId
          type
          auditMeta {
            comment
            userId
            source
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallDataS3fileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPortCallData = /* GraphQL */ `
  query SyncPortCallData(
    $filter: ModelPortCallDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortCallData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        portCallId
        actionId
        data
        s3file {
          id
          name
          s3Path
          mimeType
          sizeBytes
          portCallId
          actionId
          type
          auditMeta {
            comment
            userId
            source
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallDataS3fileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallDatasByPortCallId = /* GraphQL */ `
  query GetPortCallDatasByPortCallId(
    $portCallId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortCallDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPortCallDatasByPortCallId(
      portCallId: $portCallId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        portCallId
        actionId
        data
        s3file {
          id
          name
          s3Path
          mimeType
          sizeBytes
          portCallId
          actionId
          type
          auditMeta {
            comment
            userId
            source
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallDataS3fileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallDatasByActionId = /* GraphQL */ `
  query GetPortCallDatasByActionId(
    $actionId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortCallDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPortCallDatasByActionId(
      actionId: $actionId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        portCallId
        actionId
        data
        s3file {
          id
          name
          s3Path
          mimeType
          sizeBytes
          portCallId
          actionId
          type
          auditMeta {
            comment
            userId
            source
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallDataS3fileId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContactType = /* GraphQL */ `
  query GetContactType($id: ID!) {
    getContactType(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContactTypes = /* GraphQL */ `
  query ListContactTypes(
    $filter: ModelContactTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContactTypes = /* GraphQL */ `
  query SyncContactTypes(
    $filter: ModelContactTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      type {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      displayName
      reference
      address1
      address2
      town
      county
      postcode
      number
      fax
      email
      contacta_name
      contacta_detail
      contactb_name
      contactb_detail
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contactTypeId
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVessel = /* GraphQL */ `
  query GetVessel($id: ID!) {
    getVessel(id: $id) {
      id
      imo
      mmsi
      callSign
      vesselData {
        name
        imo
        mmsi
        image
        callSign
        type
        portOfRegistry {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        flag
        lengthOverall
        beam
        draught
        grossTonnage
        deadWeightTonnage
        netTonnage
        yearBuilt
        propellerCount
        propulsionType
        propellerDirection
        propulsionPower
        propulsionPowerUnit
        rudderCount
        rudderType
        fwdAzimuthCount
        fwdAzimuthPower
        fwdAzimuthPowerUnit
        aftAzimuthCount
        aftAzimuthPower
        aftAzimuthPowerUnit
        fwdTunnelThrusterCount
        fwdTunnelThrusterPower
        fwdTunnelThrusterPowerUnit
        aftTunnelThrusterCount
        aftTunnelThrusterPower
        aftTunnelThrusterPowerUnit
        certificates {
          id
          type
          typeName
          typeCategory
          typeCategoryName
          notes
          validFromDate
          validToDate
          referenceNumber
          nameOfHolder
          __typename
        }
        __typename
      }
      vesselAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      vesselVesselAgentId
      __typename
    }
  }
`;
export const listVessels = /* GraphQL */ `
  query ListVessels(
    $filter: ModelVesselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVessels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVessels = /* GraphQL */ `
  query SyncVessels(
    $filter: ModelVesselFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVessels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVesselsByImo = /* GraphQL */ `
  query GetVesselsByImo(
    $imo: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVesselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVesselsByImo(
      imo: $imo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVesselsByMmsi = /* GraphQL */ `
  query GetVesselsByMmsi(
    $mmsi: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVesselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVesselsByMmsi(
      mmsi: $mmsi
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVesselsByCallSign = /* GraphQL */ `
  query GetVesselsByCallSign(
    $callSign: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVesselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVesselsByCallSign(
      callSign: $callSign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imo
        mmsi
        callSign
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        vesselAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        vesselVesselAgentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCargoType = /* GraphQL */ `
  query GetCargoType($id: ID!) {
    getCargoType(id: $id) {
      id
      name
      units
      code
      charge
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCargoTypes = /* GraphQL */ `
  query ListCargoTypes(
    $filter: ModelCargoTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCargoTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCargoTypes = /* GraphQL */ `
  query SyncCargoTypes(
    $filter: ModelCargoTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCargoTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCargo = /* GraphQL */ `
  query GetCargo($id: ID!) {
    getCargo(id: $id) {
      id
      cargoPortCallId
      portCallArchived
      type {
        id
        name
        units
        code
        charge
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      initialAmount
      amount
      comments
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cargoTypeId
      __typename
    }
  }
`;
export const listCargos = /* GraphQL */ `
  query ListCargos(
    $filter: ModelCargoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCargos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cargoPortCallId
        portCallArchived
        type {
          id
          name
          units
          code
          charge
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        initialAmount
        amount
        comments
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cargoTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCargos = /* GraphQL */ `
  query SyncCargos(
    $filter: ModelCargoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCargos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cargoPortCallId
        portCallArchived
        type {
          id
          name
          units
          code
          charge
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        initialAmount
        amount
        comments
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cargoTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTariffUnit = /* GraphQL */ `
  query GetTariffUnit($id: ID!) {
    getTariffUnit(id: $id) {
      id
      name
      secondaryUnitName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTariffUnits = /* GraphQL */ `
  query ListTariffUnits(
    $filter: ModelTariffUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTariffUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTariffUnits = /* GraphQL */ `
  query SyncTariffUnits(
    $filter: ModelTariffUnitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTariffUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTariff = /* GraphQL */ `
  query GetTariff($id: ID!) {
    getTariff(id: $id) {
      id
      unit {
        id
        name
        secondaryUnitName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data {
        code
        description
        unitCost
        currency
        notes
        minimumCost
        actionTypes
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tariffUnitId
      __typename
    }
  }
`;
export const listTariffs = /* GraphQL */ `
  query ListTariffs(
    $filter: ModelTariffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTariffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        unit {
          id
          name
          secondaryUnitName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        data {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tariffUnitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTariffs = /* GraphQL */ `
  query SyncTariffs(
    $filter: ModelTariffFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTariffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        unit {
          id
          name
          secondaryUnitName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        data {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tariffUnitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTariffBook = /* GraphQL */ `
  query GetTariffBook($id: ID!) {
    getTariffBook(id: $id) {
      id
      name
      description
      dateValidFrom
      dateValidTo
      tariffs {
        id
        unitId
        cost {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        __typename
      }
      defaultCharges {
        id
        actionTypeId
        movementType
        defaultItems {
          id
          tariffId
          overrideUnitCost
          quantity
          secondaryMult
          notes
          __typename
        }
        __typename
      }
      parentBook
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTariffBooks = /* GraphQL */ `
  query ListTariffBooks(
    $filter: ModelTariffBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTariffBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        dateValidFrom
        dateValidTo
        tariffs {
          id
          unitId
          cost {
            code
            description
            unitCost
            currency
            notes
            minimumCost
            actionTypes
            __typename
          }
          __typename
        }
        defaultCharges {
          id
          actionTypeId
          movementType
          defaultItems {
            id
            tariffId
            overrideUnitCost
            quantity
            secondaryMult
            notes
            __typename
          }
          __typename
        }
        parentBook
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTariffBooks = /* GraphQL */ `
  query SyncTariffBooks(
    $filter: ModelTariffBookFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTariffBooks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        dateValidFrom
        dateValidTo
        tariffs {
          id
          unitId
          cost {
            code
            description
            unitCost
            currency
            notes
            minimumCost
            actionTypes
            __typename
          }
          __typename
        }
        defaultCharges {
          id
          actionTypeId
          movementType
          defaultItems {
            id
            tariffId
            overrideUnitCost
            quantity
            secondaryMult
            notes
            __typename
          }
          __typename
        }
        parentBook
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActionType = /* GraphQL */ `
  query GetActionType($id: ID!) {
    getActionType(id: $id) {
      id
      name
      lifecycle
      editable
      deleted
      used
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActionTypes = /* GraphQL */ `
  query ListActionTypes(
    $filter: ModelActionTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActionTypes = /* GraphQL */ `
  query SyncActionTypes(
    $filter: ModelActionTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActionTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      actionPortCallId
      actionPortCallId_
      actionVoyageId
      portCall {
        id
        referenceId
        status
        lastZone
        remarks
        portCallCategoryId_
        category {
          id
          name
          description
          type
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        portCallTags
        documents
        actions {
          items {
            id
            actionPortCallId
            actionPortCallId_
            actionVoyageId
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            timeRequested
            timePlanned
            timeEstimated
            timeActual
            timeCompleted
            timeEstimatedUpdatedAt
            actionAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            remarks
            automationLockedOut
            vesselMastersName
            vesselCrew
            vesselPassengers
            vesselForwardDraught
            vesselAftDraught
            vesselSailingDraught
            vesselAirDraught
            type {
              id
              name
              lifecycle
              editable
              deleted
              used
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            movementType
            movementLocation {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            movementVesselReadyTime
            movementPilotBoardingTime
            movementPilots
            movementLinesmen
            movementMooringVessels
            movementPropulsionState
            movementPropulsionRemarks
            movementRudderState
            movementRudderRemarks
            movementFwdAzimuthState
            movementFwdAzimuthRemarks
            movementAftAzimuthState
            movementAftAzimuthRemarks
            movementFwdTunnelThrusterState
            movementFwdTunnelThrusterRemarks
            movementAftTunnelThrusterState
            movementAftTunnelThrusterRemarks
            eventType
            todos {
              title
              description
              status
              todoType
              __typename
            }
            documents
            chargeableItems {
              id
              tariffData {
                code
                description
                unitCost
                currency
                notes
                minimumCost
                actionTypes
                __typename
              }
              tariffUnit
              tariffSecondaryUnit
              tariffId
              tariffBookId
              overrideUnitCost
              notes
              quantity
              secondaryMult
              total
              __typename
            }
            auditMeta {
              comment
              userId
              source
              __typename
            }
            latestUserLogEntry {
              id
              comment
              userId
              timeChanged
              __typename
            }
            automationMeta {
              eventIds
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            actionActionAgentId
            actionTypeId
            actionMovementLocationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        portCallVesselId_
        portCallVesselId
        vessel {
          id
          imo
          mmsi
          callSign
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          vesselAgent {
            id
            type {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            name
            displayName
            reference
            address1
            address2
            town
            county
            postcode
            number
            fax
            email
            contacta_name
            contacta_detail
            contactb_name
            contactb_detail
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contactTypeId
            __typename
          }
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          vesselVesselAgentId
          __typename
        }
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          portOfRegistry {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          flag
          lengthOverall
          beam
          draught
          grossTonnage
          deadWeightTonnage
          netTonnage
          yearBuilt
          propellerCount
          propulsionType
          propellerDirection
          propulsionPower
          propulsionPowerUnit
          rudderCount
          rudderType
          fwdAzimuthCount
          fwdAzimuthPower
          fwdAzimuthPowerUnit
          aftAzimuthCount
          aftAzimuthPower
          aftAzimuthPowerUnit
          fwdTunnelThrusterCount
          fwdTunnelThrusterPower
          fwdTunnelThrusterPowerUnit
          aftTunnelThrusterCount
          aftTunnelThrusterPower
          aftTunnelThrusterPowerUnit
          certificates {
            id
            type
            typeName
            typeCategory
            typeCategoryName
            notes
            validFromDate
            validToDate
            referenceNumber
            nameOfHolder
            __typename
          }
          __typename
        }
        lastPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        nextPort {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfLoading {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        portOfDischarge {
          name
          portCode
          countryCode
          searchString
          __typename
        }
        cargos {
          items {
            id
            cargoPortCallId
            portCallArchived
            type {
              id
              name
              units
              code
              charge
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            initialAmount
            amount
            comments
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            cargoTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requests {
          items {
            id
            requestPortCallId
            requestPortCallId_
            portCall {
              id
              referenceId
              status
              lastZone
              remarks
              portCallCategoryId_
              category {
                id
                name
                description
                type
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              portCallTags
              documents
              actions {
                nextToken
                startedAt
                __typename
              }
              portCallVesselId_
              portCallVesselId
              vessel {
                id
                imo
                mmsi
                callSign
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                vesselVesselAgentId
                __typename
              }
              vesselData {
                name
                imo
                mmsi
                image
                callSign
                type
                flag
                lengthOverall
                beam
                draught
                grossTonnage
                deadWeightTonnage
                netTonnage
                yearBuilt
                propellerCount
                propulsionType
                propellerDirection
                propulsionPower
                propulsionPowerUnit
                rudderCount
                rudderType
                fwdAzimuthCount
                fwdAzimuthPower
                fwdAzimuthPowerUnit
                aftAzimuthCount
                aftAzimuthPower
                aftAzimuthPowerUnit
                fwdTunnelThrusterCount
                fwdTunnelThrusterPower
                fwdTunnelThrusterPowerUnit
                aftTunnelThrusterCount
                aftTunnelThrusterPower
                aftTunnelThrusterPowerUnit
                __typename
              }
              lastPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              nextPort {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfLoading {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              portOfDischarge {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              cargos {
                nextToken
                startedAt
                __typename
              }
              requests {
                nextToken
                startedAt
                __typename
              }
              agents {
                contactId
                actionId
                time
                __typename
              }
              sourceData
              createdAtDate
              archived
              arrivalTime
              departureTime
              origin
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              portLocationId
              portUnlocode
              finalArrivalPlannedTime
              finalArrivalRequestedTime
              finalArrivalActualTime
              finalDeparturePlannedTime
              finalDepartureRequestedTime
              finalDepartureActualTime
              sortTime
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              portCallCategoryId
              __typename
            }
            portCallArchived
            state
            type
            source
            sourceData
            agent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            requesterUserName
            requesterEmail
            portCallData {
              vesselName
              vesselImo
              vesselMmsi
              vesselCallsign
              __typename
            }
            actionData {
              actionId
              actionTypeId
              movementType
              timeRequested
              timePlanned
              approved
              locationId
              __typename
            }
            remark
            createdAt
            updatedAt
            auditMeta {
              comment
              userId
              source
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            requestAgentId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        agents {
          contactId
          actionId
          time
          __typename
        }
        sourceData
        createdAtDate
        archived
        arrivalTime
        departureTime
        origin
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        portCallCategoryId
        __typename
      }
      portCallArchived
      state
      timeRequested
      timePlanned
      timeEstimated
      timeActual
      timeCompleted
      timeEstimatedUpdatedAt
      actionAgent {
        id
        type {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        displayName
        reference
        address1
        address2
        town
        county
        postcode
        number
        fax
        email
        contacta_name
        contacta_detail
        contactb_name
        contactb_detail
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contactTypeId
        __typename
      }
      remarks
      automationLockedOut
      vesselMastersName
      vesselCrew
      vesselPassengers
      vesselForwardDraught
      vesselAftDraught
      vesselSailingDraught
      vesselAirDraught
      type {
        id
        name
        lifecycle
        editable
        deleted
        used
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      movementType
      movementLocation {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      movementVesselReadyTime
      movementPilotBoardingTime
      movementPilots
      movementLinesmen
      movementMooringVessels
      movementPropulsionState
      movementPropulsionRemarks
      movementRudderState
      movementRudderRemarks
      movementFwdAzimuthState
      movementFwdAzimuthRemarks
      movementAftAzimuthState
      movementAftAzimuthRemarks
      movementFwdTunnelThrusterState
      movementFwdTunnelThrusterRemarks
      movementAftTunnelThrusterState
      movementAftTunnelThrusterRemarks
      eventType
      todos {
        title
        description
        status
        todoType
        __typename
      }
      documents
      chargeableItems {
        id
        tariffData {
          code
          description
          unitCost
          currency
          notes
          minimumCost
          actionTypes
          __typename
        }
        tariffUnit
        tariffSecondaryUnit
        tariffId
        tariffBookId
        overrideUnitCost
        notes
        quantity
        secondaryMult
        total
        __typename
      }
      auditMeta {
        comment
        userId
        source
        __typename
      }
      latestUserLogEntry {
        id
        comment
        userId
        timeChanged
        __typename
      }
      automationMeta {
        eventIds
        __typename
      }
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      actionActionAgentId
      actionTypeId
      actionMovementLocationId
      __typename
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionPortCallId
        actionPortCallId_
        actionVoyageId
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        timeRequested
        timePlanned
        timeEstimated
        timeActual
        timeCompleted
        timeEstimatedUpdatedAt
        actionAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        remarks
        automationLockedOut
        vesselMastersName
        vesselCrew
        vesselPassengers
        vesselForwardDraught
        vesselAftDraught
        vesselSailingDraught
        vesselAirDraught
        type {
          id
          name
          lifecycle
          editable
          deleted
          used
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        movementType
        movementLocation {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        movementVesselReadyTime
        movementPilotBoardingTime
        movementPilots
        movementLinesmen
        movementMooringVessels
        movementPropulsionState
        movementPropulsionRemarks
        movementRudderState
        movementRudderRemarks
        movementFwdAzimuthState
        movementFwdAzimuthRemarks
        movementAftAzimuthState
        movementAftAzimuthRemarks
        movementFwdTunnelThrusterState
        movementFwdTunnelThrusterRemarks
        movementAftTunnelThrusterState
        movementAftTunnelThrusterRemarks
        eventType
        todos {
          title
          description
          status
          todoType
          __typename
        }
        documents
        chargeableItems {
          id
          tariffData {
            code
            description
            unitCost
            currency
            notes
            minimumCost
            actionTypes
            __typename
          }
          tariffUnit
          tariffSecondaryUnit
          tariffId
          tariffBookId
          overrideUnitCost
          notes
          quantity
          secondaryMult
          total
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        automationMeta {
          eventIds
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        actionActionAgentId
        actionTypeId
        actionMovementLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActions = /* GraphQL */ `
  query SyncActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionPortCallId
        actionPortCallId_
        actionVoyageId
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        timeRequested
        timePlanned
        timeEstimated
        timeActual
        timeCompleted
        timeEstimatedUpdatedAt
        actionAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        remarks
        automationLockedOut
        vesselMastersName
        vesselCrew
        vesselPassengers
        vesselForwardDraught
        vesselAftDraught
        vesselSailingDraught
        vesselAirDraught
        type {
          id
          name
          lifecycle
          editable
          deleted
          used
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        movementType
        movementLocation {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        movementVesselReadyTime
        movementPilotBoardingTime
        movementPilots
        movementLinesmen
        movementMooringVessels
        movementPropulsionState
        movementPropulsionRemarks
        movementRudderState
        movementRudderRemarks
        movementFwdAzimuthState
        movementFwdAzimuthRemarks
        movementAftAzimuthState
        movementAftAzimuthRemarks
        movementFwdTunnelThrusterState
        movementFwdTunnelThrusterRemarks
        movementAftTunnelThrusterState
        movementAftTunnelThrusterRemarks
        eventType
        todos {
          title
          description
          status
          todoType
          __typename
        }
        documents
        chargeableItems {
          id
          tariffData {
            code
            description
            unitCost
            currency
            notes
            minimumCost
            actionTypes
            __typename
          }
          tariffUnit
          tariffSecondaryUnit
          tariffId
          tariffBookId
          overrideUnitCost
          notes
          quantity
          secondaryMult
          total
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        automationMeta {
          eventIds
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        actionActionAgentId
        actionTypeId
        actionMovementLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActionByPortCallId = /* GraphQL */ `
  query GetActionByPortCallId(
    $actionPortCallId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActionByPortCallId(
      actionPortCallId: $actionPortCallId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionPortCallId
        actionPortCallId_
        actionVoyageId
        portCall {
          id
          referenceId
          status
          lastZone
          remarks
          portCallCategoryId_
          category {
            id
            name
            description
            type
            deleted
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          portCallTags
          documents
          actions {
            items {
              id
              actionPortCallId
              actionPortCallId_
              actionVoyageId
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              timeRequested
              timePlanned
              timeEstimated
              timeActual
              timeCompleted
              timeEstimatedUpdatedAt
              actionAgent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              remarks
              automationLockedOut
              vesselMastersName
              vesselCrew
              vesselPassengers
              vesselForwardDraught
              vesselAftDraught
              vesselSailingDraught
              vesselAirDraught
              type {
                id
                name
                lifecycle
                editable
                deleted
                used
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              movementType
              movementLocation {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              movementVesselReadyTime
              movementPilotBoardingTime
              movementPilots
              movementLinesmen
              movementMooringVessels
              movementPropulsionState
              movementPropulsionRemarks
              movementRudderState
              movementRudderRemarks
              movementFwdAzimuthState
              movementFwdAzimuthRemarks
              movementAftAzimuthState
              movementAftAzimuthRemarks
              movementFwdTunnelThrusterState
              movementFwdTunnelThrusterRemarks
              movementAftTunnelThrusterState
              movementAftTunnelThrusterRemarks
              eventType
              todos {
                title
                description
                status
                todoType
                __typename
              }
              documents
              chargeableItems {
                id
                tariffUnit
                tariffSecondaryUnit
                tariffId
                tariffBookId
                overrideUnitCost
                notes
                quantity
                secondaryMult
                total
                __typename
              }
              auditMeta {
                comment
                userId
                source
                __typename
              }
              latestUserLogEntry {
                id
                comment
                userId
                timeChanged
                __typename
              }
              automationMeta {
                eventIds
                __typename
              }
              updatedAt
              createdAt
              _version
              _deleted
              _lastChangedAt
              actionActionAgentId
              actionTypeId
              actionMovementLocationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          portCallVesselId_
          portCallVesselId
          vessel {
            id
            imo
            mmsi
            callSign
            vesselData {
              name
              imo
              mmsi
              image
              callSign
              type
              portOfRegistry {
                name
                portCode
                countryCode
                searchString
                __typename
              }
              flag
              lengthOverall
              beam
              draught
              grossTonnage
              deadWeightTonnage
              netTonnage
              yearBuilt
              propellerCount
              propulsionType
              propellerDirection
              propulsionPower
              propulsionPowerUnit
              rudderCount
              rudderType
              fwdAzimuthCount
              fwdAzimuthPower
              fwdAzimuthPowerUnit
              aftAzimuthCount
              aftAzimuthPower
              aftAzimuthPowerUnit
              fwdTunnelThrusterCount
              fwdTunnelThrusterPower
              fwdTunnelThrusterPowerUnit
              aftTunnelThrusterCount
              aftTunnelThrusterPower
              aftTunnelThrusterPowerUnit
              certificates {
                id
                type
                typeName
                typeCategory
                typeCategoryName
                notes
                validFromDate
                validToDate
                referenceNumber
                nameOfHolder
                __typename
              }
              __typename
            }
            vesselAgent {
              id
              type {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              name
              displayName
              reference
              address1
              address2
              town
              county
              postcode
              number
              fax
              email
              contacta_name
              contacta_detail
              contactb_name
              contactb_detail
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contactTypeId
              __typename
            }
            updatedAt
            createdAt
            _version
            _deleted
            _lastChangedAt
            vesselVesselAgentId
            __typename
          }
          vesselData {
            name
            imo
            mmsi
            image
            callSign
            type
            portOfRegistry {
              name
              portCode
              countryCode
              searchString
              __typename
            }
            flag
            lengthOverall
            beam
            draught
            grossTonnage
            deadWeightTonnage
            netTonnage
            yearBuilt
            propellerCount
            propulsionType
            propellerDirection
            propulsionPower
            propulsionPowerUnit
            rudderCount
            rudderType
            fwdAzimuthCount
            fwdAzimuthPower
            fwdAzimuthPowerUnit
            aftAzimuthCount
            aftAzimuthPower
            aftAzimuthPowerUnit
            fwdTunnelThrusterCount
            fwdTunnelThrusterPower
            fwdTunnelThrusterPowerUnit
            aftTunnelThrusterCount
            aftTunnelThrusterPower
            aftTunnelThrusterPowerUnit
            certificates {
              id
              type
              typeName
              typeCategory
              typeCategoryName
              notes
              validFromDate
              validToDate
              referenceNumber
              nameOfHolder
              __typename
            }
            __typename
          }
          lastPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          nextPort {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfLoading {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          portOfDischarge {
            name
            portCode
            countryCode
            searchString
            __typename
          }
          cargos {
            items {
              id
              cargoPortCallId
              portCallArchived
              type {
                id
                name
                units
                code
                charge
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              initialAmount
              amount
              comments
              deleted
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              cargoTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          requests {
            items {
              id
              requestPortCallId
              requestPortCallId_
              portCall {
                id
                referenceId
                status
                lastZone
                remarks
                portCallCategoryId_
                portCallTags
                documents
                portCallVesselId_
                portCallVesselId
                sourceData
                createdAtDate
                archived
                arrivalTime
                departureTime
                origin
                portLocationId
                portUnlocode
                finalArrivalPlannedTime
                finalArrivalRequestedTime
                finalArrivalActualTime
                finalDeparturePlannedTime
                finalDepartureRequestedTime
                finalDepartureActualTime
                sortTime
                updatedAt
                createdAt
                _version
                _deleted
                _lastChangedAt
                portCallCategoryId
                __typename
              }
              portCallArchived
              state
              type
              source
              sourceData
              agent {
                id
                name
                displayName
                reference
                address1
                address2
                town
                county
                postcode
                number
                fax
                email
                contacta_name
                contacta_detail
                contactb_name
                contactb_detail
                deleted
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contactTypeId
                __typename
              }
              requesterUserName
              requesterEmail
              portCallData {
                vesselName
                vesselImo
                vesselMmsi
                vesselCallsign
                __typename
              }
              actionData {
                actionId
                actionTypeId
                movementType
                timeRequested
                timePlanned
                approved
                locationId
                __typename
              }
              remark
              createdAt
              updatedAt
              auditMeta {
                comment
                userId
                source
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              requestAgentId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          agents {
            contactId
            actionId
            time
            __typename
          }
          sourceData
          createdAtDate
          archived
          arrivalTime
          departureTime
          origin
          latestUserLogEntry {
            id
            comment
            userId
            timeChanged
            __typename
          }
          auditMeta {
            comment
            userId
            source
            __typename
          }
          portLocationId
          portUnlocode
          finalArrivalPlannedTime
          finalArrivalRequestedTime
          finalArrivalActualTime
          finalDeparturePlannedTime
          finalDepartureRequestedTime
          finalDepartureActualTime
          sortTime
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          portCallCategoryId
          __typename
        }
        portCallArchived
        state
        timeRequested
        timePlanned
        timeEstimated
        timeActual
        timeCompleted
        timeEstimatedUpdatedAt
        actionAgent {
          id
          type {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          displayName
          reference
          address1
          address2
          town
          county
          postcode
          number
          fax
          email
          contacta_name
          contacta_detail
          contactb_name
          contactb_detail
          deleted
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contactTypeId
          __typename
        }
        remarks
        automationLockedOut
        vesselMastersName
        vesselCrew
        vesselPassengers
        vesselForwardDraught
        vesselAftDraught
        vesselSailingDraught
        vesselAirDraught
        type {
          id
          name
          lifecycle
          editable
          deleted
          used
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        movementType
        movementLocation {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        movementVesselReadyTime
        movementPilotBoardingTime
        movementPilots
        movementLinesmen
        movementMooringVessels
        movementPropulsionState
        movementPropulsionRemarks
        movementRudderState
        movementRudderRemarks
        movementFwdAzimuthState
        movementFwdAzimuthRemarks
        movementAftAzimuthState
        movementAftAzimuthRemarks
        movementFwdTunnelThrusterState
        movementFwdTunnelThrusterRemarks
        movementAftTunnelThrusterState
        movementAftTunnelThrusterRemarks
        eventType
        todos {
          title
          description
          status
          todoType
          __typename
        }
        documents
        chargeableItems {
          id
          tariffData {
            code
            description
            unitCost
            currency
            notes
            minimumCost
            actionTypes
            __typename
          }
          tariffUnit
          tariffSecondaryUnit
          tariffId
          tariffBookId
          overrideUnitCost
          notes
          quantity
          secondaryMult
          total
          __typename
        }
        auditMeta {
          comment
          userId
          source
          __typename
        }
        latestUserLogEntry {
          id
          comment
          userId
          timeChanged
          __typename
        }
        automationMeta {
          eventIds
          __typename
        }
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        actionActionAgentId
        actionTypeId
        actionMovementLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      type
      name
      portUnlocode
      allocatable
      dockable
      locationParentId
      parent {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      children {
        items {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      parentName
      dimensions {
        length
        breadth
        depth
        __typename
      }
      shape
      coords
      path
      number
      marlinZoneId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationChildrenId
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        name
        portUnlocode
        allocatable
        dockable
        locationParentId
        parent {
          id
          type
          name
          portUnlocode
          allocatable
          dockable
          locationParentId
          parent {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          children {
            items {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          parentName
          dimensions {
            length
            breadth
            depth
            __typename
          }
          shape
          coords
          path
          number
          marlinZoneId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationChildrenId
          __typename
        }
        children {
          items {
            id
            type
            name
            portUnlocode
            allocatable
            dockable
            locationParentId
            parent {
              id
              type
              name
              portUnlocode
              allocatable
              dockable
              locationParentId
              parent {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              children {
                nextToken
                startedAt
                __typename
              }
              parentName
              dimensions {
                length
                breadth
                depth
                __typename
              }
              shape
              coords
              path
              number
              marlinZoneId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              locationChildrenId
              __typename
            }
            children {
              items {
                id
                type
                name
                portUnlocode
                allocatable
                dockable
                locationParentId
                parentName
                shape
                coords
                path
                number
                marlinZoneId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                locationChildrenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            parentName
            dimensions {
              length
              breadth
              depth
              __typename
            }
            shape
            coords
            path
            number
            marlinZoneId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            locationChildrenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        parentName
        dimensions {
          length
          breadth
          depth
          __typename
        }
        shape
        coords
        path
        number
        marlinZoneId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationChildrenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPilotTag = /* GraphQL */ `
  query GetPilotTag($id: ID!) {
    getPilotTag(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPilotTags = /* GraphQL */ `
  query ListPilotTags(
    $filter: ModelPilotTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPilotTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPilotTags = /* GraphQL */ `
  query SyncPilotTags(
    $filter: ModelPilotTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPilotTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMooringVesselTag = /* GraphQL */ `
  query GetMooringVesselTag($id: ID!) {
    getMooringVesselTag(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMooringVesselTags = /* GraphQL */ `
  query ListMooringVesselTags(
    $filter: ModelMooringVesselTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMooringVesselTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMooringVesselTags = /* GraphQL */ `
  query SyncMooringVesselTags(
    $filter: ModelMooringVesselTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMooringVesselTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLinesmanTag = /* GraphQL */ `
  query GetLinesmanTag($id: ID!) {
    getLinesmanTag(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLinesmanTags = /* GraphQL */ `
  query ListLinesmanTags(
    $filter: ModelLinesmanTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinesmanTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLinesmanTags = /* GraphQL */ `
  query SyncLinesmanTags(
    $filter: ModelLinesmanTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLinesmanTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortCallTag = /* GraphQL */ `
  query GetPortCallTag($id: ID!) {
    getPortCallTag(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPortCallTags = /* GraphQL */ `
  query ListPortCallTags(
    $filter: ModelPortCallTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortCallTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPortCallTags = /* GraphQL */ `
  query SyncPortCallTags(
    $filter: ModelPortCallTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortCallTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFilter = /* GraphQL */ `
  query GetFilter($id: ID!) {
    getFilter(id: $id) {
      id
      friendlyId
      type
      name
      status
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFilters = /* GraphQL */ `
  query ListFilters(
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        type
        name
        status
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFilters = /* GraphQL */ `
  query SyncFilters(
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFilters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        friendlyId
        type
        name
        status
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTodoTypeTemplate = /* GraphQL */ `
  query GetTodoTypeTemplate($id: ID!) {
    getTodoTypeTemplate(id: $id) {
      id
      todoTypeName
      todoTypeTitle
      todoTypeDescription
      todoTypeColor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTodoTypeTemplates = /* GraphQL */ `
  query ListTodoTypeTemplates(
    $filter: ModelTodoTypeTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoTypeTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        todoTypeName
        todoTypeTitle
        todoTypeDescription
        todoTypeColor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTodoTypeTemplates = /* GraphQL */ `
  query SyncTodoTypeTemplates(
    $filter: ModelTodoTypeTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTodoTypeTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        todoTypeName
        todoTypeTitle
        todoTypeDescription
        todoTypeColor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      type
      typeId
      subType
      name
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        typeId
        subType
        name
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTemplates = /* GraphQL */ `
  query SyncTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        typeId
        subType
        name
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVesselState = /* GraphQL */ `
  query GetVesselState($id: ID!) {
    getVesselState(id: $id) {
      id
      name
      mmsi
      imo
      callSign
      navStatus
      shipType
      lat
      lon
      height
      depth
      speed
      cmg
      heading
      rot
      positionQuality
      positioningDevice
      eta
      destination
      dimForward
      dimAft
      dimPort
      dimStbd
      recordTimestamp
      positionTimestamp
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVesselStates = /* GraphQL */ `
  query ListVesselStates(
    $filter: ModelVesselStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVesselStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mmsi
        imo
        callSign
        navStatus
        shipType
        lat
        lon
        height
        depth
        speed
        cmg
        heading
        rot
        positionQuality
        positioningDevice
        eta
        destination
        dimForward
        dimAft
        dimPort
        dimStbd
        recordTimestamp
        positionTimestamp
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVesselStates = /* GraphQL */ `
  query SyncVesselStates(
    $filter: ModelVesselStateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVesselStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        mmsi
        imo
        callSign
        navStatus
        shipType
        lat
        lon
        height
        depth
        speed
        cmg
        heading
        rot
        positionQuality
        positioningDevice
        eta
        destination
        dimForward
        dimAft
        dimPort
        dimStbd
        recordTimestamp
        positionTimestamp
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAutomationRule = /* GraphQL */ `
  query GetAutomationRule($id: ID!) {
    getAutomationRule(id: $id) {
      id
      enabled
      vessels
      allVessels
      plan {
        steps {
          event
          marlinZoneIds
          actions {
            type
            params
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAutomationRules = /* GraphQL */ `
  query ListAutomationRules(
    $filter: ModelAutomationRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutomationRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enabled
        vessels
        allVessels
        plan {
          steps {
            event
            marlinZoneIds
            actions {
              type
              params
              __typename
            }
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAutomationRules = /* GraphQL */ `
  query SyncAutomationRules(
    $filter: ModelAutomationRuleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAutomationRules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        enabled
        vessels
        allVessels
        plan {
          steps {
            event
            marlinZoneIds
            actions {
              type
              params
              __typename
            }
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getS3File = /* GraphQL */ `
  query GetS3File($id: ID!) {
    getS3File(id: $id) {
      id
      name
      s3Path
      mimeType
      sizeBytes
      portCallId
      actionId
      type
      auditMeta {
        comment
        userId
        source
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listS3Files = /* GraphQL */ `
  query ListS3Files(
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Files(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncS3Files = /* GraphQL */ `
  query SyncS3Files(
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncS3Files(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getS3FilesByPortCallId = /* GraphQL */ `
  query GetS3FilesByPortCallId(
    $portCallId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getS3FilesByPortCallId(
      portCallId: $portCallId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getS3FilesByActionId = /* GraphQL */ `
  query GetS3FilesByActionId(
    $actionId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getS3FilesByActionId(
      actionId: $actionId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        s3Path
        mimeType
        sizeBytes
        portCallId
        actionId
        type
        auditMeta {
          comment
          userId
          source
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotificationRule = /* GraphQL */ `
  query GetNotificationRule($id: ID!) {
    getNotificationRule(id: $id) {
      id
      enabled
      name
      description
      kind
      recipients
      locations
      categories
      tags
      tagsMatchingType
      actionsWithoutLocation
      triggers
      actionTypeId
      type
      offset
      offsetUnit
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotificationRules = /* GraphQL */ `
  query ListNotificationRules(
    $filter: ModelNotificationRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationRules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        name
        description
        kind
        recipients
        locations
        categories
        tags
        tagsMatchingType
        actionsWithoutLocation
        triggers
        actionTypeId
        type
        offset
        offsetUnit
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotificationRules = /* GraphQL */ `
  query SyncNotificationRules(
    $filter: ModelNotificationRuleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationRules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        enabled
        name
        description
        kind
        recipients
        locations
        categories
        tags
        tagsMatchingType
        actionsWithoutLocation
        triggers
        actionTypeId
        type
        offset
        offsetUnit
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCertificateType = /* GraphQL */ `
  query GetCertificateType($id: ID!) {
    getCertificateType(id: $id) {
      id
      name
      description
      deleted
      certificateTypeCategoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCertificateTypes = /* GraphQL */ `
  query ListCertificateTypes(
    $filter: ModelCertificateTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificateTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deleted
        certificateTypeCategoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCertificateTypes = /* GraphQL */ `
  query SyncCertificateTypes(
    $filter: ModelCertificateTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCertificateTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        deleted
        certificateTypeCategoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
