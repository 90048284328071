import React, { useState, useEffect, useCallback } from 'react';
import { KeyboardDatePicker } from "@material-ui/pickers"
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { invalidDateMessage, isValidDate } from '../../utils/dateUtils.js'
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

/** 
 * Wrapper for KeyboardDatePicker to use with DataStore - onChange returns ISO 8601 AWSDate (YYYY-MM-DD)
 * Note: this is a tweaked copy of StringKeyboardDateTimePicker
*/
const StringKeyboardDatePicker = ({ value, onChange, helperText, error, allowEmptyValue, ...other }) => {
  const [ date, setDate ] = useState(value ? new Date(value) : null);
  const { setting, dateFormat } = useDateTimeSetting();
  const { t } = useTranslation();
  let helperTextLocal = helperText;
  let errorLocal = false;

  const formatInvalidDateMessage = useCallback((setting) => {
    const [message, format] = invalidDateMessage(setting);
    return t(message, format);
  }, [t]);

  // Allow empty value string if allowEmptyValue is true, no validation
  if(!value && !date && allowEmptyValue) helperTextLocal = '';
  // Partial entry gives invalid date/time
  else if(!value && !isValidDate(date)) { helperTextLocal = formatInvalidDateMessage(setting); errorLocal = true; }
  // Use any error or helperText provided by parent
  else { helperTextLocal = helperText; errorLocal = error; }
  
  useEffect(() => {
    onChange && onChange(isNaN(date) || date === null ? null : format(date, "yyyy-MM-dd"));
  }, [date]);
  // value changed from outside
  useEffect(() => {
    value !== (date && !isNaN(date) ? format(date, "yyyy-MM-dd"): null) && setDate(value ? new Date(value) : null);
  }, [value]);

  return (
    <KeyboardDatePicker
      {...other}
      ampm={false}
      error={errorLocal}
      format={dateFormat}
      helperText={helperTextLocal}
      invalidDateMessage={formatInvalidDateMessage(setting)}
      value={date}
      onChange={value => setDate(value)}
      cancelLabel={t('Common.Buttons.Cancel')}
    />
  );
};

export default StringKeyboardDatePicker;