import React from 'react';
import { CrosshairsGps } from 'mdi-material-ui';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const VesselSpotlightLauncher = ({setMapDialogOpen, vesselData, setVesselMapData}) => {
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    setMapDialogOpen && setMapDialogOpen(true); 
    setVesselMapData && setVesselMapData(vesselData); 
    e.preventDefault()
  };

  return (
    <Tooltip title={t('PortCallMenuItem.Labels.Spotlight')}>
      <IconButton id={'SpotlightButton'} onClick={handleOnClick}>
        <CrosshairsGps/>
      </IconButton>
    </Tooltip>
  )
};

export default VesselSpotlightLauncher;