import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CargoInstanceArrayForm from '../../QuickPortCall/CargoInstanceArrayForm';
import { useTranslation } from 'react-i18next';

/*
 *  
 *  CargoTab
 *  Displays the cargo items for a port call.
 *  Shows a CargoRow for each cargo instance
 *  entry.
 *  Shows the total num,ber of cargo instances.
 *  Shows a button for the addition of new cargo
 *  instances.
 *
 */


const useStyles = makeStyles(theme => ({
  formLabelLabel: {
    //textTransform: 'uppercase',
    marginBottom: '9px',
    marginTop: '9px',
  },
}));


export const CargoTab = ({ cargos, disabled, setCargos }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const filteredCargo = cargos.filter(c => c.deleted !== true)

  return (
    <CardContent id='CargoContainer'>
      <Typography
        className={classes.formLabelLabel}
        component="div"
        id="port-call-cargo"
        variant="h6"
      >
        {t("Cargo.Labels.Cargo")}
      </Typography>
      {Boolean(filteredCargo.length) && <Box pb="1rem">
        <Typography>
          {filteredCargo.length} {filteredCargo.length > 1 ? t("Cargo.Labels.CargoItems") : t("Cargo.Labels.CargoItem")}
        </Typography>
      </Box>}
      <CargoInstanceArrayForm
        cargos={cargos}
        setCargos={setCargos}
      />
    </CardContent>
  )
}

CargoTab.propTypes = {
  // Array of cargos to display
  cargos: PropTypes.array.isRequired,
}

export default CargoTab;