import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Typography, Box, Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import { ArrowLeft } from 'mdi-material-ui';
import ContactForm from '../QuickPortCall/ContactForm';
import VesselAnatomyForm from './VesselAnatomyForm';
import {
  ImageFileContext,
  imageFileActionConstants,
} from '../../contexts/image';
import { Vessel } from '../../models';
import { validateVesselImo, validateVesselMmsi } from '../../utils/validators';
import { DataStore } from 'aws-amplify';
import { DataContext, DataContextConstants, DataContextOriginConstants } from '../../contexts/dataContext';
import isEqual from 'lodash.isequal';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { ContactTypeIds } from '../../environment';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { InactivePortCallStatus } from '../../constants/StateCategories';

const PAGE_MAIN = 0;

const {
  RESET,
} = imageFileActionConstants;

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '85rem',
    margin: '0 auto'
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '12rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const VesselEditForm = ({ vessel, vesselReference, setVessel, onBack, onUpdate, showTitle, portCall, disabled }) => {
  const [, dispatchDataContext] = useContext(DataContext);
  const { vessels, contactTypes } = useContext(DataStoreContext);
  const { t } = useTranslation();
  const isPortCallClosed = InactivePortCallStatus.includes(portCall?.status);

  const { vesselAgent, vesselData } = vessel;
  const setVesselAgent = (value) => {
    setVessel(Vessel.copyOf(vessel, updated => {
      updated.vesselAgent = value;
    }));
  };

  const nameError = useMemo(() => vesselData.name && !vesselData.name && t('VesselForm.Errors.EmptyName'), [vesselData.name]);
  const propulsionPowerUnitError = useMemo(() => Boolean(vesselData.propulsionPower && !vesselData.propulsionPowerUnit) && t('VesselForm.Errors.MissingUnits'), [vesselData.propulsionPower, vesselData.propulsionPowerUnit]);

  const imoError = useMemo(() => (vesselData.imo && vesselData.imo.length && !validateVesselImo(vesselData.imo))
    ? t('VesselForm.Errors.InvalidImo')
    : vesselData.imo !== vesselReference.imo && vessels.find(v => v.imo === vesselData.imo)
      ? t('VesselForm.Errors.DuplicateImo')
      : null,
    [vesselData.imo]);
  const mmsiError = useMemo(() => (vesselData.mmsi && vesselData.mmsi.length && !validateVesselMmsi(vesselData.mmsi))
    ? t('VesselForm.Errors.InvalidMmsi')
    : vesselData.mmsi !== vesselReference.mmsi && vessels.find(v => v.mmsi === vesselData.mmsi)
      ? t('VesselForm.Errors.DuplicateMmsi')
      : null,
    [vesselData.mmsi]);

  const createDisabled = useMemo(() =>
    !vesselData.name || nameError || imoError || mmsiError || propulsionPowerUnitError || 
    isEqual(vessel, vesselReference) ||
    vesselData?.certificates?.some(c => !c.id || !c.type || !c.typeName || !c.typeCategory || !c.typeCategoryName || !c.validFromDate || !c.validToDate || c.validFromDate > c.validToDate),
    [vesselData.name, vesselData.certificates, nameError, imoError, mmsiError, propulsionPowerUnitError, vessel, vesselReference]
  );

  //Context for file that is about to be uploaded
  const [imageFileState, dispatchImageFileState] = useContext(ImageFileContext);

  const [ page, setPage ] = useState(PAGE_MAIN);
  const classes = useStyles();
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ showUnsavedChangesConfirm, setShowUnsavedChangesConfirm ] = useState(false);

  // on mounting clean any validation error messages
  // that are not relevant
  useEffect(() => {
    dispatchImageFileState({ type: RESET });
  }, []);

  const imageError = (process, error) => {
    dispatchImageFileState({ error, process });
  }

  const handleUpdateFinish = async () => {
    let result = vessel;
    if (!isPortCallClosed) {
      result = await DataStore.save(Vessel.copyOf(vessel, updated => {
        updated.imo = vessel.vesselData.imo;
        updated.mmsi = vessel.vesselData.mmsi;
        updated.callSign = vessel.vesselData.callSign
      }));
      dispatchDataContext({ type: DataContextConstants.SET_PENDING_CREATE_VESSEL, payload: result, origin: DataContextOriginConstants.UPDATE });
    }
    onUpdate && onUpdate(result);
  }

  const handleUpdate = () => {
    if (imageFileState && imageFileState.type) {
      dispatchImageFileState({ type: imageFileState.type, onUpload: handleUpdateFinish, onError: imageError });
    } else {
      handleUpdateFinish();
    }
  }

  const handleDiscardChanges = () => {
    if(!isEqual(vessel.vesselData, vesselReference.vesselData) ||
       vessel.vesselAgent?.id !== vesselReference.vesselAgent?.id) {
      setShowUnsavedChangesConfirm(true);
    }
    else {
      setShowUnsavedChangesConfirm(false);
      dispatchImageFileState({ type: RESET });
      onBack && onBack()
    }
  };

  if (page === 1) return <ContactForm contact={vesselAgent} contactType={contactTypes.find(ct => ct.id === ContactTypeIds.VESSEL_AGENT)} onChange={setVesselAgent} showTitle onBack={() => setPage(PAGE_MAIN)} />
  if (page === 0) return (
    <Box overflow="hidden auto" width="100%">
      <Grid container wrap="nowrap" direction="column" className={classes.root}>

        {showTitle &&
          <Grid item container justifyContent='center' alignItems="center" className={classes.header}>
            {onBack && <IconButton id='VesselEditBackButton' onClick={handleDiscardChanges} style={{ marginLeft: -59, position: 'relative', left: -32 }}><ArrowLeft fontSize="large" /></IconButton>}
            {disabled
             ? <Typography variant="h2">{t('Vessel.Labels.Vessel')}</Typography>
             : <Typography variant="h2">{t('VesselForm.Labels.UpdateVessel')}</Typography>}
          </Grid>
        }

        <VesselAnatomyForm
          vessel={vessel}
          setVessel={setVessel}
          nameError={nameError}
          propulsionPowerUnitError={propulsionPowerUnitError}
          imoError={imoError}
          mmsiError={mmsiError}
          createAgent={() => setPage(1)}
          disabled={disabled}
        />

        {!disabled && 
        <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.footer}>
          <Button
            color="primary"
            size="large"
            onClick={handleDiscardChanges}
          >
            <Typography>{t('Common.Buttons.DiscardChanges')}</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={createDisabled}
            style={{ margin: '1rem' }}
            onClick={() => setShowConfirm(true)}
            id="UpdateVesselButton"
          >
            {t('Common.Buttons.Update')}
        </Button>
        </Grid>
        }

      </Grid>
      <ConfirmDialog
        open={showConfirm}
        title={t("VesselForm.Labels.UpdateVessel")}
        message={isPortCallClosed ? t("VesselForm.Labels.UpdatePortCallMessage") : t("VesselForm.Labels.UpdatePortCallOpenMessage")}
        onConfirm={handleUpdate}
        onClose={() => setShowConfirm(false)}
      />
      <ConfirmDialog
        open={showUnsavedChangesConfirm}
        title={t("Common.Labels.UnsavedChanges")}
        confirmText={t("Common.Buttons.Leave")}
        message={t("VesselForm.Labels.UnsavedChanges")}
        onConfirm={() => {
          dispatchImageFileState({ type: RESET });
          onBack && onBack();
        }}
        onClose={() => setShowUnsavedChangesConfirm(false)}
      />
    </Box>
  );
};

export default VesselEditForm;