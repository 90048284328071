import React, { useContext, useEffect } from 'react';
import {
  Typography,
  Badge
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UIContext } from '../../contexts/ui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  requestBadge: {
    background: "#ff9800",
    color: "white"
  },
}));

export const EditorContentTabIds = {
  DETAILS: 0,
  REQUESTS: 1,
  LOGBOOK: 2
};

export const useEditorContentTab = () => {
  const location = useLocation();
  const [{agentPortal}] = useContext(UIContext);
  if (location.pathname.includes("/requests"))
    return EditorContentTabIds.REQUESTS;
  if (location.pathname.includes("/logbook") && !agentPortal)
    return EditorContentTabIds.LOGBOOK;
  return EditorContentTabIds.DETAILS;
}

const EditorContentTabs = ({ requests }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [{agentPortal}] = useContext(UIContext);
  const portCallId = params.id || params.portCallId;
  const actionId = params.actionId;
  const requestId = params.requiestId;
  const history = useHistory();
  const classes = useStyles();
  const tab = useEditorContentTab();
  
  const onChange = (value) => {
    if (value === tab) return;
    let newPath = `/port-call/${portCallId}`;
    if (actionId)
      newPath += `/action/${actionId}`;
    if (value === EditorContentTabIds.DETAILS)
      history.push(newPath);
    else if (value === EditorContentTabIds.REQUESTS)
      history.push(`${newPath}/requests${requestId ? '/' + requestId : ''}`);
    else if (value === EditorContentTabIds.LOGBOOK)
      history.push(`${newPath}/logbook`);
  };

  useEffect(() => {
    if ((!Array.isArray(requests) || !requests.length) && tab ===  EditorContentTabIds.REQUESTS) {
      let newPath = `/port-call/${portCallId}`;
      if (actionId)
        newPath += `/action/${actionId}`;
      history.push(newPath);
    }
  }, [requests, tab, portCallId, actionId]);

  return (
    <ToggleButtonGroup exclusive value={tab} onChange={(_, value) => { value !== null && onChange(value); }}>
      <ToggleButton value={EditorContentTabIds.DETAILS}>
        <Typography>{t("EditorContentTabs.Labels.Details")}</Typography>
      </ToggleButton>
      <ToggleButton id="PortCallDetailsRequestToggleButton" value={EditorContentTabIds.REQUESTS} disabled={!requests.length}>
        <Typography>{t("EditorContentTabs.Labels.Requests")}</Typography>
        {Boolean(requests.length) &&
          <Badge
            badgeContent={requests.length}
            classes={{ badge: classes.requestBadge }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            style={{ marginLeft: "1rem", marginRight: "0.5rem" }}
          />
        }
      </ToggleButton>
        {!agentPortal &&
          <ToggleButton value={EditorContentTabIds.LOGBOOK} id="LogBookTabButton">
            <Typography>{t("EditorContentTabs.Labels.LogBook")}</Typography>
          </ToggleButton>
        }
    </ToggleButtonGroup>
  );
}

export default EditorContentTabs;