import React, { useState, useContext } from 'react';
import { Layers } from 'mdi-material-ui';
import {
  Box,
  CardContent,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popper,
  makeStyles,
} from '@material-ui/core';
import { MapContext } from './contexts/map';
import { LayerAttrs } from './constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container:{
    width: 0, 
    height: 0 , 
    position: 'absolute', 
    top: '0.5em', 
    right: '2.5em',
  },
  layerSwitcher: {
    width: '2rem',
    height: '2rem',
    padding: 0,
    borderRadius: 0,
    boxShadow: '0px 1px 4px rgb(0 0 0 / 30%)',
    backgroundColor: 'rgba(255, 255, 255, 1.0)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.90)'
    }
  },
  popper:{
    //to set index equal to main page container index 
    zIndex: 13000
  },
  layerControl: {
    backgroundColor: 'rgba(255, 255, 255, 1.0)',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  cardRoot: {
    padding: '1rem'
  }
}));

const LayerSwitcherControl = ({ layerSettings, onVisiblityChanged }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [ context ] = useContext(MapContext);
  const [ layerNames, setLayerNames ] = useState([]);
  const { t } = useTranslation();

  const appendLayerToList = (id, title, i18n, zIndex, layerList) => {
    if(id && (title || i18n)) layerList.push({id: id, title: title, i18n: i18n, zIndex: zIndex})
  };

  /**
   * Return am array containing all the current layers, includes IDs and titles/names
   * @returns {Array<Object>}
   */
  const getLayerNames = () => {
    if(!context.map) return [];
    const layers = [];
    context.map.getLayers().forEach(layer => {
      const id = layer.get(LayerAttrs.ID);
      const title = layer.get(LayerAttrs.TITLE);
      const i18n = layer.get(LayerAttrs.I18N);
      const wmsLayers = layer.get(LayerAttrs.WMS_LAYERS);
      if(wmsLayers) {
        // Expand all WMS layers into their own entries
        for(let wmsLayer of wmsLayers) {
          appendLayerToList(wmsLayer.id, wmsLayer.title, wmsLayer.i18n, layer.getZIndex(), layers);
        }
      } else {
        appendLayerToList(id, title, i18n, layer.getZIndex(), layers);
      }

    });
    layers.sort((a, b) => a.zIndex - b.zIndex);
    layers.reverse();
    return layers;
  };

  const toggleLayerControl = (e) => {
    setLayerNames(anchorEl ? [] : getLayerNames());
    setAnchorEl(anchorEl ? null : e?.currentTarget);
  };

  const open = Boolean(anchorEl)
  const id = open ? 'MapLayerSwitcher-Popper' : undefined;

  return (
      <Box className={classes.container}>
        <IconButton
          aria-describedby={id}
          id="MapLayerSwitcher"
          size="small"
          className={classes.layerSwitcher}
          onClick={toggleLayerControl}
        >
          <Layers />
        </IconButton>
     {anchorEl &&
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement={'left-start'} className={classes.popper}>
        <Box display={'flex'} flexDirection={'column'} className={classes.layerControl}>
          <CardContent className={classes.cardRoot}>
            <FormControl
              component="fieldset"
              style={{padding: 0}}
              fullWidth
            >
              <FormGroup style={{padding: 0}}>
                {layerSettings && layerNames.map(layer => (
                  <FormControlLabel
                    id={`MapLayerSwitcher-${layer.id}`}
                    key={layer.id}
                    control={
                      <Switch
                        size="small"
                        checked={layerSettings[layer.id] ? layerSettings[layer.id]?.visible : false}
                        onChange={e => onVisiblityChanged(e.currentTarget.value, e.currentTarget.checked)}
                        value={layer.id}
                      />
                    }
                    label={layer.i18n ? t(`MapLayers.Labels.${layer.i18n}`) : layer.title}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </CardContent>
        </Box>
      </Popper>}
    </Box>
  );
}

export default LayerSwitcherControl;
