import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { clientName } from '../../../environment';
import { DateFnsLanguageMap } from '../../../translations';
import { format } from 'date-fns';
import {  getActionArrival,getActionDeparture, getActionActiveAgent, getAgentName } from '../../../utils/getters';
import { Action, ActionState, ActionEventType, Cargo, UserSetting, ExportPortCallConfig } from '../../../models';
import { DataStore, API, graphqlOperation } from 'aws-amplify';
import useDateTimeSetting from '../../../hooks/useDateTimeSetting';
import ExportFieldSelection from '../../Export/ExportFieldSelection';
import ExportUnitSelection from '../../Export/ExportUnitSelection';
import { ExportField, ExportFieldKeys, ExportFieldLengthKeys, ExportUnitKeys, DefaultHeaderKeys, DefaultLengthUnit } from '../../Export/export-fields';
import ExportDialog from '../../Export/ExportDialog';
import { formatLength, formatCertificate } from '../../Export/export-utils';
import { PortCallStatusLabelsKeys } from '../../../constants/PortCallStatus';
import { UnitsSymbolKeyFromString } from '../../../constants/Units';
import { createUserSetting } from '../../../graphql/mutations';
import { UIContext } from '../../../contexts/ui';
import { checkValidity } from '../../../utils/certificates';
import { PORTCALL_PREVIEW } from '../../../constants/PortCallPreview';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import { getParentLocation } from '../utils';

const ExportPortCallsDialogDS = ({ open, portCalls, onClose }) => {
  const { t, i18n } = useTranslation();
  const [uiContext,] = useContext(UIContext);
  const { locations } = useContext(DataStoreContext);
  const { dateTimeFormat, dateFormat } = useDateTimeSetting();
  const [ exportColumns, setExportColumns ] = useState(DefaultHeaderKeys);

  const [selectedLengthUnit, setSelectedLengthUnit] = useState(DefaultLengthUnit);
  
  const exportKeys = useMemo(() => ({
    // Port call
    [ExportField.PortCallReferenceID]: {
      get: p => p.referenceId
    },
    [ExportField.PortCallPortOfLoading]: {
      get: p => p.portOfLoading && `${p.portOfLoading.name}, ${p.portOfLoading.countryCode}`
    },
    [ExportField.PortCallLastPort]: {
      get: p => p.lastPort && `${p.lastPort.name}, ${p.lastPort.countryCode}`
    },
    [ExportField.PortCallNextPort]: {
      get: p=> p.nextPort && `${p.nextPort.name}, ${p.nextPort.countryCode}`
    },
    [ExportField.PortCallPortOfDischarge]: {
      get: p => p.portOfDischarge && `${p.portOfDischarge.name}, ${p.portOfDischarge.countryCode}`
    },
    [ExportField.PortCallCategory]: {
      get: p => p.category?.name
    },
    [ExportField.PortCallStatus]: {
      get: p=> t(PortCallStatusLabelsKeys[p.status])
    },
    [ExportField.PortCallCargo]: {
      get: (p, { cargos }) => {
        return cargos?.filter(c => c.cargoPortCallId === p.id).map(c => {
          const quantity = [];
          c.initialAmount && quantity.push(`${c.initialAmount}${c.type?.units ? t(UnitsSymbolKeyFromString(c.type.units)) : ''} ${t('FileExportFields.Labels.CargoIn')}`);
          c.amount && quantity.push(`${c.amount}${c.type?.units ? t(UnitsSymbolKeyFromString(c.type.units)) : ''} ${t('FileExportFields.Labels.CargoOut')}`);
          return `${c.type?.name}${quantity.length ? ` (${quantity.join(', ')})` : ''}`; 
        }).join(", ");
      }
    },
    [ExportField.PortCallRemarks]: {
      get: p => p.remarks
    },
    [ExportField.PortCallTags]: {
      get: p => p.portCallTags.join(', ')
    },
    [ExportField.PortCallPort]: {
      get: (p) => {
        const arrival = getActionArrival(p?.actions);
        const location = arrival && getParentLocation(arrival?.movementLocation, locations);
        const portUnlocode = location?.portUnlocode;
        return location ? `${location?.name}${ portUnlocode ? `, ${portUnlocode}` : '' }` : '';
      }
    },
    // Agent
    [ExportField.AgentName]: {
      get: (p, { handovers }) => {
        const agent = getActionActiveAgent(handovers.filter(a => a.actionPortCallId_ === p.id));
        return agent && getAgentName(agent);
      }
    },
    [ExportField.AgentTelephoneNumber]: {
      get: (p, { handovers }) => getActionActiveAgent(handovers.filter(a => a.actionPortCallId_ === p.id))?.number
    },
    [ExportField.AgentEmail]: {
      get: (p, { handovers }) => getActionActiveAgent(handovers.filter(a => a.actionPortCallId_ === p.id))?.email
    },   
    // Vessel
    [ExportField.VesselName]: {
      get: p => p.vesselData.name
    },
    [ExportField.VesselIMO]: {
      get: p => p.vesselData.imo
    },
    [ExportField.VesselMMSI]: {
      get: p => p.vesselData.mmsi
    },
    [ExportField.VesselCallSign]: {
      get: p => p.vesselData.callSign
    },
    [ExportField.VesselFlag]: {
      get: p => p.vesselData.flag
    },
    [ExportField.VesselType]: {
      get: p => p.vesselData.type
    },
    [ExportField.VesselYearBuilt]: {
      get: p => p.vesselData.yearBuilt
    },
    [ExportField.VesselPortOfRegistry]: {
      get: p => p.vesselData.portOfRegistry && `${p.vesselData.portOfRegistry.name}, ${p.vesselData.portOfRegistry.countryCode}`
    },
    [ExportField.VesselLength]: { 
      get: p => p.vesselData.lengthOverall && formatLength(p.vesselData.lengthOverall, selectedLengthUnit)
    },
    [ExportField.VesselBeam]: {
      get: p => p.vesselData.beam && formatLength(p.vesselData.beam, selectedLengthUnit)
    },
    [ExportField.VesselDraught]: {
      get: p => p.vesselData.draught && formatLength(p.vesselData.draught, selectedLengthUnit)
    },
    [ExportField.VesselDeadweightTonnage]: {
      get: p => p.vesselData.deadWeightTonnage
    },
    [ExportField.VesselGrossTonnage]: {
      get: p => p.vesselData.grossTonnage
    },
    [ExportField.VesselNetTonnage]: {
      get: p => p.vesselData.netTonnage
    },
    [ExportField.VesselCertificates]: {
      get: v => v?.vesselData?.certificates && checkValidity(v?.vesselData?.certificates)?.map(c => formatCertificate(c, t, dateFormat)).join(', ')
    },
    // Arrival
    [ExportField.ArrivalTimeRequested]: {
      get: p => getActionArrival(p.actions)?.timeRequested
    },
    [ExportField.ArrivalTimePlanned]: {
      get: p => {
        const value = getActionArrival(p.actions)?.timePlanned;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.ArrivalTimeEstimated]: {
      get: p =>  {
        const value = getActionArrival(p.actions)?.timeEstimated;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.ArrivalTimeReady]: {
      get: p => {
        const value = getActionArrival(p.actions)?.movementVesselReadyTime;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.ArrivalTimeActual]: {
      get: p => {
        const value = getActionArrival(p.actions)?.timeActual;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.ArrivalPilotBoarding]: {
      get: p => {
        const value = getActionArrival(p.actions)?.movementPilotBoardingTime;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.ArrivalLocation]: {
      get: p => getActionArrival(p.actions)?.movementLocation?.name
    },
    [ExportField.ArrivalPilots]: {
      get: p => getActionArrival(p.actions)?.movementPilots?.join(", ")
    },
    [ExportField.ArrivalLinesmen]: {
      get: p => getActionArrival(p.actions)?.movementLinesmen?.join(", ")
    },
    [ExportField.ArrivalMooringVessels]: {
      get: p => getActionArrival(p.actions)?.movementMooringVessels?.join(", ")
    },
    [ExportField.ArrivalMastersName]: {
      get: p => getActionArrival(p.actions)?.vesselMastersName
    },
    [ExportField.ArrivalCrew]: {
      get: p => getActionArrival(p.actions)?.vesselCrew
    },
    [ExportField.ArrivalPassengers]: {
      get: p => getActionArrival(p.actions)?.vesselPassengers
    },
    [ExportField.ArrivalForwardDraught]: {
      get: p => {
        const value = getActionArrival(p.actions)?.vesselForwardDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.ArrivalAftDraught]: {
      get: p => {
        const value = getActionArrival(p.actions)?.vesselAftDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.ArrivalSailingDraught]: {
      get: p => {
        const value = getActionArrival(p.actions)?.vesselSailingDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.ArrivalAirDraught]: {
      get: p => {
        const value = getActionArrival(p.actions)?.vesselAirDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    //Departure
    [ExportField.DepartureTimeRequested]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.timeRequested;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.DepartureTimePlanned]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.timePlanned;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.DepartureTimeReady]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.movementVesselReadyTime;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.DepartureTimeActual]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.timeActual;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.DeparturePilotBoarding]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.movementPilotBoardingTime;
        return value && format(new Date(value), dateTimeFormat);
      }
    },
    [ExportField.DepartureLocation]: {
      get: p => getActionDeparture(p.actions)?.movementLocation?.name
    },
    [ExportField.DeparturePilots]: {
      get: p => getActionDeparture(p.actions)?.movementPilots?.join(", ")
    },
    [ExportField.DepartureLinesmen]: {
      get: p => getActionDeparture(p.actions)?.movementLinesmen?.join(", ")
    },
    [ExportField.DepartureMooringVessels]: {
      get: p => getActionDeparture(p.actions)?.movementMooringVessels?.join(", ")
    },
    [ExportField.DepartureMastersName]: {
      get: p => getActionDeparture(p.actions)?.vesselMastersName
    },
    [ExportField.DepartureCrew]: {
      get: p => getActionDeparture(p.actions)?.vesselCrew
    },
    [ExportField.DeparturePassengers]: {
      get: p => getActionDeparture(p.actions)?.vesselPassengers
    },
    [ExportField.DepartureForwardDraught]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.vesselForwardDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.DepartureAftDraught]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.vesselAftDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.DepartureSailingDraught]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.vesselSailingDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    },
    [ExportField.DepartureAirDraught]: {
      get: p => {
        const value = getActionDeparture(p.actions)?.vesselAirDraught;
        return value && formatLength(value, selectedLengthUnit);
      }
    }
  }), [t, locations, selectedLengthUnit, dateFormat, dateTimeFormat]);

  const getLabelWithUnit = useCallback((key) => {
    return (ExportFieldLengthKeys.find(k => k === key))
    ? `${t(ExportFieldKeys[key])} (${t(ExportUnitKeys[selectedLengthUnit])})`
    :  t(ExportFieldKeys[key]);
  }, [t, selectedLengthUnit])

  const exportConfig = {
    fileType: {
      ext: "csv",
      mimeType: "text/csv"
    },
  };
  
  // generate a data row from config columns
  const generateRow = useCallback(((portCall, other) => {
    return exportColumns.map(key => exportKeys[key].get(portCall, other) || '');
  }), [exportColumns, exportKeys]);

  // load UserSetting
  useEffect(() => {
    (async () => {
      const userSetting = (await DataStore.query(UserSetting)).pop();
      setExportColumns(prev => 
        // #7739 filter out any duplicate entries
        userSetting?.exportConfig?.columns ? 
        userSetting.exportConfig.columns.filter((item, index) => userSetting.exportConfig.columns.indexOf(item) === index) :
        prev);
      setSelectedLengthUnit(prev => userSetting?.exportConfig?.lengthUnit || prev);
    })();
  }, [setExportColumns, setSelectedLengthUnit]);

    // save UserSetting
  const save = useCallback(async () => {
    const userSetting = (await DataStore.query(UserSetting)).pop();
    if (userSetting) {
      await DataStore.save(UserSetting.copyOf(userSetting, updated => {
        updated.exportConfig = new ExportPortCallConfig({
          lengthUnit: selectedLengthUnit,
          columns: exportColumns
        });
      }));
    } else {
      await API.graphql(graphqlOperation(createUserSetting, {
        input: {
          id: uiContext.userName,
          exportConfig: {
            lengthUnit: selectedLengthUnit,
            columns: exportColumns
          }
        }
      }));
    }
  }, [selectedLengthUnit, exportColumns, uiContext.userName])

  const fileName = useMemo(() => `${clientName} ${format(new Date(), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })}.${exportConfig.fileType.ext}`, [dateTimeFormat, i18n])

  const [cargos, setCargo] = useState([])
  const [handovers, setHandovers] = useState([])

  useEffect(() => {
    if (!open || !portCalls.length) return;
    (async () => {
      const queriedCargo = await DataStore.query(Cargo, c => c.or((c) => portCalls.reduce((c, p) => c.cargoPortCallId('eq', p.id), c)))
      setCargo(queriedCargo.filter(c => c.deleted !== true))
      setHandovers(await DataStore.query(Action, c => c
        .eventType("eq", ActionEventType.AGENT_HANDOVER)
        .state("ne", ActionState.DELETED)))
    })()
  }, [portCalls, open])

  const getData = useCallback(() => {
    return [
      // header
      exportColumns.map(key => exportKeys[key] && getLabelWithUnit(key)).map(i => `\"${i}\"`).join(","),
      ...portCalls.map(p => generateRow(p, { handovers, cargos }).map(i => `\"${i}\"`).join(","))
    ].join("\n");
  }, [portCalls, handovers, cargos, exportColumns, exportKeys, getLabelWithUnit, generateRow])

  const onExport = useCallback(async () => {
    // force browser to download data blob as a file
    const download = (data, filename, type) => {
      const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
      const file = new Blob([BOM, data], { type: type + ";charset=utf-8" });
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        // Others
        const a = document.createElement("a"),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    };
    if(getData && fileName && exportConfig?.fileType?.mimeType) {
      download(getData(), fileName, exportConfig.fileType.mimeType);
    }else{
      console.error(`Error: Failed to export to a file(fileName: ${fileName}, mimeType: ${exportConfig?.fileType?.mimeType})`)
    }
    await save();
  }, [exportConfig.fileType.mimeType, fileName, getData, save])
  
  const handleExportFieldChange = (fields) => {
    setExportColumns(fields);
  };

  const handleClose = (event) => {
    onClose(event)
  }
 
  const preview = useMemo(() => {
    const portCalls = PORTCALL_PREVIEW;
    const handover = portCalls?.actions?.length && portCalls.actions.find((action) => action.eventType === ActionEventType.AGENT_HANDOVER);
    return generateRow(portCalls,{ handovers : [handover], cargos : portCalls.cargos });
  }, [generateRow]);

  const labels = exportColumns?.map(key => exportKeys[key] && getLabelWithUnit(key))

  return (
    <ExportDialog 
      open={open}
      title={t("ExportPortCalls.Labels.ExportPortCalls")}
      fileName={fileName}
      exportColumns={exportColumns}
      getData={getData}
      onExport={onExport}
      handleClose={handleClose}
      preview={{labels, data: preview}}
      handleExportFieldReorder={handleExportFieldChange}
      exportConfig={exportConfig}
      exportMessage={t("ExportPortCalls.Labels.ExportConfirmMessage", { count: portCalls?.length || 0, fileType: exportConfig.fileType.ext.toUpperCase() })}
      exportDialogContent={
        <div key='ExportPortCallsDialogContent'>
          <DialogContentText>{t('ExportPortCalls.Labels.SelectColumns')}</DialogContentText>        
          <ExportFieldSelection settings={exportColumns} onChange={handleExportFieldChange}/>
          <DialogContentText>{t('ExportPortCalls.Labels.SelectUnits')}</DialogContentText>
          <ExportUnitSelection selectedLengthUnit={selectedLengthUnit} onLengthUnitChange={setSelectedLengthUnit}/>
        </div>
      }
     />
  );
};

export default ExportPortCallsDialogDS;