import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { getPortCallsByCreatedAtVesselId } from '../graphql/queries';

/** 
 * Will return last port call with specified vessel id
*/
const useLastPortCallByVesselId = (vesselId) => {
  const [portCall, setPortCall] = useState(null);
  useEffect(() => {
    if (!vesselId) {
      setPortCall(null);
      return;
    }
    const promise = API.graphql(graphqlOperation(getPortCallsByCreatedAtVesselId, {
      portCallVesselId_: vesselId,
      sortDirection: 'DESC',
      limit: 1
    }));
    const update = async () => {
      const result = await promise;
      if (result && result.data && result.data.getPortCallsByCreatedAtVesselId.items.length > 0) {
        setPortCall(result.data.getPortCallsByCreatedAtVesselId.items[0]);
      }
    }
    update();
    return () => {
      API.cancel(promise);
    }
  }, [vesselId, setPortCall])

  return portCall;
};

export default useLastPortCallByVesselId;
