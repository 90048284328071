export const ApprovalColor = {
  color: '#0096d6',
  backgroundColor: '#0096d611' 
};
export const ApprovalDeclinedColor = {
  color: '#f44336',
  backgroundColor: '#f4433611' 
};

export const itemApprovalPending = {
  borderLeft: '.5rem solid ' + ApprovalColor.color,
  background: ApprovalColor.backgroundColor,
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  pointerEvents: 'none',
};

export const itemApprovalDeclined = {
  ...itemApprovalPending,
  borderLeft: '.5rem solid ' + ApprovalDeclinedColor.color,
  background: ApprovalDeclinedColor.backgroundColor,
};
