import React from 'react';
import { ListItem, ListItemText, makeStyles, Divider } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { PLANNED } from '../../constants/ActionState';
import { orange } from '@material-ui/core/colors';
import { format, formatDistanceToNow } from 'date-fns';
import PreviousStateTooltip from './PreviousStateTooltip';
import { capitalize, lowercase } from '../../utils/utils';
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  ahead: {
    color: green[500],
  },
  behind: {
    color: red[500],
  },
  PLANNED: {
  },
  IN_PROGRESS: {
    color: orange[500],
  },
  COMPLETED: {
  },
  CANCELLED: {
  },
  DELETED: {
  }
}));

export default ({ action }) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const ahead = action.timeEstimated < action.timePlanned;
  const isCustomAction = !action.movementType && !action.eventType;

  const getTranslatedActionState = (actionState, actionMovementType, actionEventType) => {
    const actionTypeMap = {
      ARRIVAL: 'Arrival',
      DEPARTURE: 'Departure',
      SHIFT_ARRIVAL: 'ShiftArrival',
      SHIFT_DEPARTURE: 'ShiftDeparture',
      AGENT_HANDOVER: 'AgentHandover'
    };
    const actionStateMap = {
      REQUESTED: 'Requested',
      PLANNED: 'Planned',
      APPROVAL_PENDING: 'ApprovalPending',
      APPROVAL_DECLINED: 'ApprovalDeclined',
      IN_PROGRESS: 'InProgress',
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      DELETED: 'Deleted'
    };
    if(actionTypeMap[actionMovementType || actionEventType] && actionStateMap[actionState]) {
      return t(`ActionState.Labels.${actionTypeMap[actionMovementType || actionEventType]}${actionStateMap[actionState]}`);
    }
    if(actionStateMap[actionState]) {
      return t(`ActionState.Labels.CustomAction${actionStateMap[actionState]}`, {action: t('TimeTooltip.Labels.Action')});
    }
    //fallback if action state and movement/event type are not defined in the map
    return action.state === PLANNED
      ? `${capitalize(action.state)} ${lowercase(action.movementType || action.eventType || t('TimeTooltip.Labels.Action') )}`
      : `${capitalize(action.movementType || action.eventType || t('TimeTooltip.Labels.Action') )} ${lowercase(action.state)}`
  }

  const getTranslatedActionTypeName = (name, actionMovementType, actionEventType) => {
    if(actionMovementType) {
      return t('ActionType.Labels.Movement');
    }
    if(actionEventType) {
      return t('ActionType.Labels.Event');
    }
    return name;
  }

  return (
    <>
      <ListItem>
        <ListItemText
          primary={getTranslatedActionState(action.state, action.movementType, action.eventType)}
          primaryTypographyProps={{
            className: classes[action.state]
          }}
          secondary={getTranslatedActionTypeName(action.type.name, action.movementType, action.eventType)}
        />
      </ListItem>
      {action.timeActual &&
        <ListItem>
          <ListItemText
            primary={format(new Date(action.timeActual), 'd MMMM yyyy, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
            secondary={isCustomAction ? t('TimeTooltip.Labels.StartTime') : t('TimeTooltip.Labels.ActualTime')}
          />
        </ListItem>
      }
      {action.timeCompleted &&
        <ListItem>
          <ListItemText
            primary={format(new Date(action.timeCompleted), 'd MMMM yyyy, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
            secondary={ t('TimeTooltip.Labels.CompletionTime')}
          />
        </ListItem>
      }
      {action.timePlanned &&
        <ListItem>
          <ListItemText
            primary={format(new Date(action.timePlanned), 'd MMMM yyyy, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
            secondary={t('TimeTooltip.Labels.PlannedTime')}
          />
        </ListItem>
      }
      {action.timeRequested &&
        <ListItem>
          <ListItemText
            primary={format(new Date(action.timeRequested), 'd MMMM yyyy, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
            secondary={t('TimeTooltip.Labels.RequestedTime')}
          />
        </ListItem>
      }
      {!action.timeActual && action.timeEstimated &&
        <ListItem>
          <ListItemText
            primary={format(new Date(action.timeEstimated), 'd MMMM yyyy, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
            primaryTypographyProps={{
              className: ahead ? classes.ahead : classes.behind
            }}
            secondary={ `${t('TimeTooltip.Labels.EstimatedTime')} (${t('TimeTooltip.Labels.UpdatedAgo', {time: formatDistanceToNow(new Date(action.timeEstimatedUpdatedAt), { locale: DateFnsLanguageMap[i18n.language] })})})`}
          />
        </ListItem>
      }
      {isCustomAction && action.remarks && 
        <ListItem>
          <ListItemText
            primary={action.remarks}
            secondary={t('TimeTooltip.Labels.Notes')}
          />
        </ListItem>
      }
      <Divider />
      <PreviousStateTooltip action={action} />
    </>
  );
};