import { Box, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VoyageListItem from './VovageListItem';
import VoyageListItemDateTime from './VoyageListItemDateTime';
import ActionState from '../../Action/ActionState';

const useStyles = makeStyles((theme) => ({
  timelineIcon:{
    marginRight:'1.5rem'
  }
}));

const VoyageBookend = ({ portCall, action, ...otherProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const actionTime = action?.timeActual || action?.timePlanned;
  return (
    <VoyageListItem {...otherProps} action={action} portCall={portCall} isBookend={true}>
       <ListItemIcon className={`TimelineMovementActionIcon ${classes.timelineIcon}`}>
          <ActionState action={action} />
        </ListItemIcon>
      <Box>
        <ListItemText
          primary={t("VoyageTimeline.Labels.BookendTitle", { movementType: action.movementType })}
          primaryTypographyProps={{
            component: 'p',
            noWrap: true,
            overflow: 'hidden',
            textoverflow: 'ellipsis',
            id: 'VoyageListItemName',
          }}
          data-id={action.id}
        />
        <VoyageListItemDateTime actionTime={actionTime} />
      </Box>
    </VoyageListItem>
  )
};

export default VoyageBookend;
