import { Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Alert } from 'mdi-material-ui'

const InvalidTariffWarning = ({ tooltip }) => {

  const theme = useTheme();
  
  return (
    <Tooltip title={tooltip}>
      <Alert
        className='InvalidTariffWarning'
        style={{
          color: theme.palette.warning.main,
        }}
      />
    </Tooltip>
  );
};

export default InvalidTariffWarning;