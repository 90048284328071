import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@material-ui/core';

import PortSearchAutocomplete from '../Vessel/PortSearchAutocomplete';
import {  PortCall } from '../../models';
import usePorts from '../../hooks/usePorts';
import { PortCallDetailsHref } from '../../constants/PortCallDetails';
import { useTranslation } from 'react-i18next';

/* Documentation

Description

Returns

Usage example where appropriate

*/

/* Further custom functions */

export const VesselItineraryEdit = ({
  // client,
  disabled,
  // dispatch,
  portCall,
  setPortCall
}) => {
  const { t } = useTranslation();
  const { portOfLoading, portOfDischarge, nextPort, lastPort } = portCall;
  const ports = usePorts();

  const handleChange = useCallback((name, value) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated[name] = value;
    }));
  }, [portCall, setPortCall]);

  if (!ports) return null;
  return (
    <Box pb="2rem">
      <Typography
        id={PortCallDetailsHref.HREF_ITINERARY}
        variant="h6"
      >
        {t("PortCallDetails.Labels.Itinerary")}
      </Typography>
      <PortSearchAutocomplete
        id={'PortOfLoadingAutocomplete'}
        disabled={disabled}
        label={t("VesselItinerary.Labels.PortOfLoading")}
        handleChange={(e, value) => handleChange('portOfLoading', value)}
        options={ports}
        value={portOfLoading}
      />
      <PortSearchAutocomplete
        id={'LastPortAutocomplete'}
        disabled={disabled}
        label={t("VesselItinerary.Labels.LastPort")}
        handleChange={(e, value) => handleChange('lastPort', value)}
        options={ports}
        value={lastPort}
      />
      <PortSearchAutocomplete
        id={'NextPortAutocomplete'}
        disabled={disabled}
        label={t("VesselItinerary.Labels.NextPort")}
        handleChange={(e, value) => handleChange('nextPort', value)}
        options={ports}
        value={nextPort}
      />
      <PortSearchAutocomplete
        id={'PortOfDischargeAutocomplete'}
        disabled={disabled}
        label={t("VesselItinerary.Labels.PortOfDischarge")}
        handleChange={(e, value) => handleChange('portOfDischarge', value)}
        options={ports}
        value={portOfDischarge}
      />
    </Box>
  )
};

VesselItineraryEdit.defaultProps = {}

VesselItineraryEdit.propTypes = {
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  vessel: PropTypes.object,
}

export default VesselItineraryEdit;
