



import { Auth } from 'aws-amplify';
import { DataStore } from 'aws-amplify';
import { Contact } from '../models';

export const getAgentContact = async (contactId) => {
  if(!contactId) return;
  const agents = await DataStore.query(Contact, c => c.id('eq', contactId));
  if(!agents.length) {
    console.error('Unable to find contact for current user', contactId);
    return;
  }
  return agents.pop();
};

export const buildRequestSource = async (contact) => {
  if(!contact) return 'Unknown';
  // const userEmail = (await Auth.currentUserInfo()).attributes['email'];
  // return `${contact.name}, ${userEmail}`
  return `${contact.displayName ? contact.displayName : contact.name}`
};

export const getUserEmail = async () => {
  return (await Auth.currentUserInfo()).attributes['email'];
};

export const getUserName = async () => {
  return (await Auth.currentUserInfo()).username;
};
