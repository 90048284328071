import { DataStore } from 'aws-amplify';
import { Cargo, PortCall, PortCallStatus, ActionState, ActionMovementType } from '../../models';
import isEqual from 'lodash.isequal';
import { ActionTypeIds } from '../../environment';
import { getActiveMovements } from '../../utils/getters';
/**
 * Returns uppermost movementLocation id
 * @param {Object} Object of portcall movementLocation object
 * @returns Location object
 */
export const getParentLocation = (movementLocation = {}, locations = []) => {
  if ( !movementLocation?.id || !locations.length ) return null;
  const parentId = movementLocation?.parent?.id;
  return parentId ? getParentLocation(findLocationById(locations,parentId),locations) : findLocationById(locations,movementLocation?.id);
}
/**
 * Returns Location by Id
 * @param {Array} of Locations 
 * @param {String} of Location Id
 * @returns Location object
 */
export const findLocationById = (locations,id) => locations && locations?.find((location)=>location.id === id);

export const cancelPortCall = async (portCall, comment, auditMeta) => {
  await DataStore.save(PortCall.copyOf(portCall, updated => {
    updated.status = PortCallStatus.CANCELLED
    updated.auditMeta = { ...auditMeta, comment: comment }
  }));
};

export const closePortCall = async (portCall, comment, auditMeta) => {
  await DataStore.save(PortCall.copyOf(portCall, updated => {
    updated.status = PortCallStatus.CLOSED;
    updated.auditMeta = { ...auditMeta, comment: comment }
  }));
};

export const saveCargo = async (portCall, cargos) => {
  if (!isEqual(portCall.cargos, cargos)) {
    for (let cargo of portCall.cargos) {
      const newCargo = cargos.find(c => c.id === cargo.id);
      if (!isEqual(cargo, newCargo)) {
        DataStore.save(newCargo);
      }
    }
    // insert new cargos
    for (let newCargo of cargos) {
      if (!portCall.cargos.find(c => c.id === newCargo.id)) {
        DataStore.save(Cargo.copyOf(newCargo, updated => {
          updated.cargoPortCallId = portCall.id;
        }));
      }
    }
  }
}

/**
 * Updates port call, arrival and/or departure to complete 
 * @param {PortCall} portCall Current port call
 * @param {[Action]} actions Array of actions [arrival, departure]
 * @param {AuditMeta} auditMeta Audit metadata 
 * @param {Object} values Action actual times
 * @param {Object} comments Action completion comments
 * @returns {Array} Array of objects to be updated
 */
export const completePortCall = (portCall, actions, auditMeta, values, comments) => {
  const items = [];

  const portCallData = {
    id: portCall.id,
    _version: portCall._version,
    status: PortCallStatus.CLOSED
  };
  items.push({
    model: 'PortCall',
    objects: [JSON.stringify(portCallData)]
  });

  actions.forEach((action) => {

    const comment = action.movementType === ActionMovementType.ARRIVAL ? comments.arrival : comments.departure;
    const timeActual = action.movementType === ActionMovementType.ARRIVAL ? values.arrival : values.departure;
    
    const updateAction = {
      id: action.id,
      _version: action._version,
      state: ActionState.COMPLETED,
      portCallClosed: true,
      automationLockedOut: true,
      auditMeta: { ...auditMeta, comment: comment},
      timeActual: timeActual
    };
    items.push({
      model: 'Action',
      objects: [JSON.stringify(updateAction)]
    });
  });

  return items;
};

/**
 * Update action and port call status based on next action state
 * @param {Action} action Current action
 * @param {ActionState} state Next action state
 * @param {String} comment User comment 
 * @param {AuditMeta} auditMeta Audit metadata 
 * @param {Object} updates Fields to be set in updated action
 * @param {Boolean} ignorePortStatus Skip updating port call status
 * @returns {Array} Array of objects to be updated
 */
export const updateActionState = async (action, state, comment, auditMeta, updates, ignorePortStatus = false) => {
  // console.trace();
  // maps movement's state to port call status
  // portCall.status = portCallStatus[action.movementType][action.state]
  const portCallStatus = {
    ARRIVAL: {
      PLANNED: PortCallStatus.PREARRIVAL,
      IN_PROGRESS: PortCallStatus.ARRIVAL,
      COMPLETED: PortCallStatus.IN_PORT,
      APPROVAL_DECLINED: '',
      APPROVAL_PENDING: ''
    },
    DEPARTURE: {
      PLANNED: PortCallStatus.IN_PORT,
      IN_PROGRESS: PortCallStatus.DEPARTURE,
      COMPLETED: PortCallStatus.CLOSED,
      APPROVAL_DECLINED: '',
      APPROVAL_PENDING: ''
    },
    SHIFT_ARRIVAL: {
      // shifts do not change port call status
    },
    SHIFT_DEPARTURE: {
      // shifts do not change port call status
    }
  }

  const items = [];
  const portCall = await DataStore.query(PortCall, action.actionPortCallId_);
  const newPortCallStatus = !ignorePortStatus && portCallStatus[action.movementType] ? portCallStatus[action.movementType][state] : "";
  if (portCall && newPortCallStatus) {
    const portCallData = {
      id: portCall.id,
      _version: portCall._version,
      status: newPortCallStatus
    };
    items.push({
      model: 'PortCall',
      objects: [JSON.stringify(portCallData)]
    });
  }

  if (action) {
    const actionData = {
      id: action.id,
      _version: action._version,
      // _version: 100000,
      state: state,
      portCallClosed: newPortCallStatus === PortCallStatus.CLOSED || null,
      automationLockedOut: true,
      auditMeta: { ...auditMeta, comment: comment },
      ...updates
    };
    items.push({
      model: 'Action',
      objects: [JSON.stringify(actionData)]
    });
  }

  return items;
};


export const buildLocationQuery = ({limit=500, nextToken}) => {
  const token = (nextToken == null)?  null : `"${nextToken}"`;
  return `
  query locations {
    listLocations(
      nextToken: ${token}
      limit: 100
    ) {
      items {
        id
        name
        portUnlocode 
      }
    }
  }`
};

export const buildCargoTypeQuery = ({limit=500, nextToken}) => {
  const token = (nextToken == null)?  null : `"${nextToken}"`;
  return `
  query cargoTypes {
    listCargoTypes(
      nextToken: ${token}
      limit: 100
    ) {
      items {
        id
        name
        units
      }
    }
  }`
};

export const buildContactQuery = ({limit=500, nextToken}) => {
  const token = (nextToken == null)?  null : `"${nextToken}"`;
  return `
  query contacts {
    listContacts(
      nextToken: ${token}
      limit: 100
    ) {
      items {
        id 
        displayName
        name
        number
        email
      }
    }
  }`
};

export const buildCategoryQuery = ({limit=500, nextToken}) => {
  const token = (nextToken == null)? null : `"${nextToken}"`;
  return `
  query categories {
    listCategories(
      nextToken: ${token}
      limit: 100
    ) {
      items {
        id
        name
      }
    }
  }`
};

/**
 * Build query for listing open port calls
 * @param {String} nextToken Token for next page of result, can be null
 * @returns {String} GraphQL query
 */
export const buildPortCallBrowserQuery = ({limit=500, nextToken}) => {
  const token = (nextToken == null) ? null : `"${nextToken}"`;
  return `
  query openPortCalls{
  listPortCalls(
    nextToken: ${token}
    limit: ${limit}
    filter: { 
      and: [
        { archived: { eq: true} }
        { status: { ne: DELETED } }
      ]
    }) {
      items {
        id
        referenceId
        status
        portCallCategoryId
        portCallTags
        portCallVesselId
        vesselData {
          name
          imo
          mmsi
          image
          callSign
          type
          flag
          portOfRegistry {
            name
            countryCode
            portCode
          }
          draught
          lengthOverall
          beam
          deadWeightTonnage
          grossTonnage
          netTonnage
        }
        archived
        portLocationId
        portUnlocode
        finalArrivalPlannedTime
        finalArrivalRequestedTime
        finalArrivalActualTime
        finalDeparturePlannedTime
        finalDepartureRequestedTime
        finalDepartureActualTime
        sortTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
    nextToken
    startedAt
  }
}`;
}

/**
 * Checks if any of the movements in the actions given are part of a voyage 
 * @param {[Action]} actions Array of actions 
 * @returns {Boolean} True if at least one movement action is part of a voyage
 */
export const hasVoyageMovements = (actions) => {
  const validMovements = actions?.filter(a => a?.type?.id === ActionTypeIds.MOVEMENT && a?.state !== ActionState.DELETED);
  const voyageMovement = validMovements?.find(a => a?.actionVoyageId);
  return voyageMovement ? true : false;
};

/**
 * Checks if the action given is part of a voyage 
 * @param {Action} action 
 * @returns {Boolean} True if the action is part of a voyage
 */
export const isVoyageMovement = (action) => {
  const voyageMovement = action?.type?.id === ActionTypeIds.MOVEMENT 
    && action?.state !== ActionState.DELETED 
    && Boolean(action?.actionVoyageId);
  return voyageMovement ? true : false;
};

/**
 * Checks if the port call contains an arrival that is part of a voyage and no other active movements 
 * @param {Action} arrival 
 * @param {Array} portCallActions
 * @returns {Boolean} True if there is an arrival that is part of a voyage and no active movements
 */
export const isArrivalOnlyPortCall = (arrival, portCallActions) => {
  if (!arrival || !portCallActions) return false; 
  return arrival?.actionVoyageId && !getActiveMovements(portCallActions).length > 0 ? true : false; 
}; 