export const documentMethods = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  DUPLICATE: 'DUPLICATE',
}

export const documentStatusArray = {
  NO_FILE: {key: "NOFILE", text: "DocumentEdit.Labels.NoFile"},
  FILE_NAME: {key: "FILENAME", text: "DocumentEdit.Labels.Filename"},
  PROGRESS_BAR: {key: "PROGRESSBAR", text: "DocumentEdit.Labels.Progress"},
  COMPLETE: {key: "COMPLETE", text: "DocumentEdit.Labels.Complete"},
  DOWNLOAD: {key: "DOWNLOAD", text: "DocumentEdit.Labels.Downloaded"},
  DELETE: {key: "DELETE", text: "DocumentEdit.Labels.Deleted"},
}

export const documentType = {
  APPLICATION: "application",
  IMAGE: "image",
  PDF: "pdf",
  TEXT: "text",
  AUDIO: "audio",
}

export const errors = {
  UPLOADING: "upload",
  DELETING: "delete",
  DOWNLOADING: "download",
  ATTACHMENT: "attachment",
}