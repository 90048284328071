import React from 'react';
import {
  ListItem, 
  ListItemText, 
  Grid,
  makeStyles,
  Divider
} from '@material-ui/core';
import { portName } from '../../utils/utils';
import { formatDimension } from '../../utils/utils';
import VesselImage from '../Image/VesselImage';
import VesselListItem from '../Vessel/VesselListItem';
import {imageRatio} from '../../utils/Image';
import { useTranslation } from 'react-i18next';
import VesselSpotlightLauncher from '../Spotlight/VesselSpotlightLauncher';

const TooltipMaxWidth = 30; //Width of the tooltip in rem

const {width, height} = imageRatio({width: TooltipMaxWidth});

const useStyles = makeStyles({
  header: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0rem 1rem 0rem 1rem',
  },
  item: {
    padding: 0
  },
  card: {
    padding: '0rem 1rem 0rem 1rem',
  },
  container: {
    width: width,
  }
});


export default ({ vesselData, setMapDialogOpen, setVesselMapData }) => {
  const { t } = useTranslation()
  const classes = useStyles();

  return(
    <Grid 
      container 
      direction="column"
      className={classes.container}
    >
    <Grid item>
        <Grid container className={classes.header} id={'PopoverHeader'}>
          <VesselListItem
            className={classes.item}
            vesselData={vesselData}
            disableGutters={true}
          />
          <VesselSpotlightLauncher setMapDialogOpen={setMapDialogOpen} vesselData={vesselData} setVesselMapData={setVesselMapData}/>
        </Grid>
    </Grid>
    <Grid item>
      {vesselData.image ? <VesselImage image={vesselData.image} containerStyles={{width: width, height: height}} /> : <Divider />}
      <Grid container>
        <Grid item xs={7}>
          {vesselData.type &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={vesselData.type}
                secondary={t('Vessel.Labels.Type')}
              />
            </ListItem>
          </Grid>
          }
          {vesselData.mmsi &&
        <Grid item >
          <ListItem>
            <ListItemText
              primary={vesselData.mmsi}
              secondary={t('Vessel.Labels.MMSI')}
            />
          </ListItem>
          </Grid>
        }
        {vesselData.callSign &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={vesselData.callSign}
                secondary={t('Vessel.Labels.CallSign')}
              />
            </ListItem>
          </Grid>
          }
          {vesselData.portOfRegistry && vesselData.portOfRegistry.name &&
          <Grid item >
            <ListItem>
              <ListItemText
              primary={portName(vesselData.portOfRegistry)}
              secondary={t('Vessel.Labels.PortOfRegistry')}
              />
            </ListItem>
          </Grid>
          }
        </Grid>
        <Grid item xs={5} >
          {vesselData.draught > 0 &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={`${formatDimension(vesselData.draught)} ${t('Common.Labels.M')}`}
                secondary={t('Vessel.Labels.NominalDraught')}
              />
            </ListItem>
          </Grid>
          }
          {(vesselData.lengthOverall > 0 && vesselData.beam > 0) && 
            <Grid item >
              <ListItem>
                <ListItemText 
                primary={`${formatDimension(vesselData.lengthOverall)} x ${formatDimension(vesselData.beam)} ${t('Common.Labels.M')}`}
                secondary={t('Vessel.Labels.Dimensions')}
                />
              </ListItem>
            </Grid>
          }
          {vesselData.deadWeightTonnage > 0 &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={`${vesselData.deadWeightTonnage} ${t('Common.Labels.T')}`}
                secondary={t('Vessel.Labels.DeadweightTonnage')}
              />
            </ListItem>
          </Grid>
          }
          {vesselData.grossTonnage > 0 &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={`${vesselData.grossTonnage}`}
                secondary={t('Vessel.Labels.GrossTonnage')}
              />
            </ListItem>
          </Grid>
          }
          {vesselData.netTonnage > 0 &&
          <Grid item >
            <ListItem>
              <ListItemText
                primary={`${vesselData.netTonnage}`}
                secondary={t('Vessel.Labels.NetTonnage')}
              />
            </ListItem>
          </Grid>
          }
        </Grid>
        
      </Grid>
    </Grid>
  </Grid>
)};