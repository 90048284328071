import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PortCallTagsDialog = ({ open, title, message, onConfirm, onClose, cancelText = "Cancel", confirmText = "Confirm", oldTags, newTags }) => {
  const [tags, setTags] = useState("newTags");
  const handleChange = (e) => {
   setTags(e.target.value); 
  };

  const tagsLabel = (tags, label) => {
    return (
      <Typography component="div">
        <Box fontWeight="fontWeightBold" display="inline">
          {label}
        </Box>
        {tags ? tags : (<Box fontWeight="fontWeightLight" display="inline">No tags</Box>)}
      </Typography>);
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick') {
      return false;
    }
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} id="PortCallTagsDialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <RadioGroup name="tags" value={tags} onChange={handleChange}>
          <FormControlLabel id={'PortCallTagsDialogOverWrite'} value="newTags" control={<Radio />} label={tagsLabel(newTags, "Overwrite with new tags: ")} />
          <FormControlLabel id={'PortCallTagsDialogKeepExisting'} value="oldTags" control={<Radio />} label={tagsLabel(oldTags, "Keep existing tags: ")} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {onConfirm && 
          <Button id={'PortCallTagsDialogConfirmButton'} onClick={() => onConfirm(tags === "newTags")} color="primary">
            {confirmText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

PortCallTagsDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  messageSecondary: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

export default PortCallTagsDialog;