
import { useCallback, useContext } from 'react'
import { TariffContext } from '../contexts/tariffsContext';
import * as tariffBook from '../utils/tariffBook';

const useTariffBooks = () => {
  
  const { tariffBooks } = useContext(TariffContext);

  const tariffsForAction = useCallback((date, actionTypeId) => {
    const selectedBooks = tariffBook.selectTariffBook(date, tariffBooks);
    const allTariffs = tariffBook.flattenTariffs(selectedBooks, (tariff) => tariffBook.tariffUsesActionType(tariff, actionTypeId));
    return allTariffs;
  }, [ tariffBooks ]);

  return {
    tariffBooks: tariffBooks,
    tariffsForAction: tariffsForAction
  };
};

export default useTariffBooks;