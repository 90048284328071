import React, { useState } from 'react';
import { Typography, TextField, Tab, Tabs, Box, DialogContentText } from '@material-ui/core';
import { TariffBook } from '../../../models';
import StringKeyboardDatePicker from '../../Common/StringKeyboardDatePicker';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from '../AdminModelEditor';
import { useTranslation } from 'react-i18next';
import { DataStore } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { dayAfter, dayBefore } from '../../../utils/dateUtils';
import TariffListEditor from './TariffListEditor';
import { TariffBookEditorProvider } from './TariffBookEditorContext';
import ChargeableListEditor from './ChargeableListEditor';
import { tariffBookS3Path } from '../../../utils/tariffBook';
import useTariffBook from '../../../hooks/useTariffBook';
import useTariffBooks from '../../../hooks/useTariffBooks';
import { duplicateTariffBook } from '../../../utils/duplicateTariffBook';

/**
 * Save tariff book to DataStore and S3
 * TariffBook in DataStore will not contain tariffs or defaultCharges
 * @param {TariffBook} book 
 */
export const saveTariffBook = async (book, originalBook) => {
  if(!book || !book?.id) return;

  // console.log('+++saveTariffBook BOOK', book?.tariffs?.length, book);

  const saveToDataStore = async () => {
    // console.log(`+++Saved ${tariffBookS3Path(book.id)} ${book._version} to DataStore`);
    DataStore.save(TariffBook.copyOf(book, updated => {
      // update model fields to clear out tariff held in Dynamo
      updated.tariffs = null;
      updated.defaultCharges = null;
    }));
  } 

  const s3Data = {
    id: book.id,
    tariffs: book.tariffs ? book.tariffs : [],
    defaultCharges: book.defaultCharges ? book.defaultCharges : []
  };

  // console.log(`+++ book.length ${book?.tariffs?.length} originalBook.length ${originalBook?.tariffs?.length}`);

  if((originalBook?.tariffs?.length && (book.tariffs === [])) || book?.tariffs) {
    console.log(`+++Saved ${tariffBookS3Path(book.id)} ${book._version} to S3`);

    // Save to S3 if we have tariffs or if all tariffs have been removed
    // Its only tariffs that matter here as default items cannot exist without tariffs
    //   if book.tariffs = undefined -> changes made only to DB record, no tariff/chargeable update
    //   if book.tariffs = [] -> all tariff have been removed
    //   if book.tariffs = [...] -> edits make to tariffs/chargeables
    await Storage.put(tariffBookS3Path(book.id), s3Data);
  }
  saveToDataStore();
};

const AdminTariffBookHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('AdminTariff.Labels.Name')} />
    </>
  );
};

const AdminTariffBookListItemContent = ({ value }) => {
  return (
    <>
      <Typography noWrap>{value.name}</Typography>
    </>
  );
};

const validateTariffBook = (tariffBook) => {
  if (!Boolean(tariffBook.name)) return false;
  if (tariffBook.dateValidFrom && tariffBook.dateValidTo && tariffBook.dateValidFrom >= tariffBook.dateValidTo)
    return false;
  return true;
};

const handleDuplicateTariffBook = (item, books) => {
  const source = books.find(el => item.id === el.id);
  if(!source) return;
  const tariffBook = duplicateTariffBook(source, books)
  if (validateTariffBook(tariffBook)) {
    saveTariffBook(tariffBook);
  }
};

const TAB_TARIFFS = 0;
const TAB_DEFAULT_CHARGEABLES = 1;

const AdminTariffBookFormContent = ({ outerValue, value, disabled, onChange, onChangeRaw }) => {
  const [ currentTab, setCurrentTab ] = useState(TAB_TARIFFS);
  const [ dateValidErrorMessage, setDateValidErrorMessage ] = useState(undefined);
  const [ validFrom, setValidFrom ] = useState(true);
  const [ validTo, setValidTo ] = useState(true);
  const { t } = useTranslation();
  const tariffBook = useTariffBook(value.id);

  // console.log(`value: ${JSON.stringify(value)}`);
  // console.log(`tariffBook: ${JSON.stringify(tariffBook)}`);

  const onChangeDate = (name, newValue) => {
    // Validation rules
    // Valid:
    //   both from and to can be null
    //   either from or to can be null
    // Invalid:
    //   from cannot be after to
    //   to cannot be before from
    //   to and from are equal

    setValidFrom(true);
    setValidTo(true);
    setDateValidErrorMessage(undefined);

    if (name === 'dateValidFrom') {
      const validTo = value.dateValidTo ? new Date(value.dateValidTo) : undefined;
      const validFrom = newValue ? new Date(newValue) : undefined;
      if (validFrom && validTo && validFrom >= validTo) {
        setDateValidErrorMessage(t('AdminTariff.Errors.InvalidDate'));
        setValidFrom(false);
      }
    }

    if (name === 'dateValidTo') {
      const validTo = newValue ? new Date(newValue) : undefined;
      const validFrom = newValue ? new Date(value.dateValidFrom) : undefined;
      if (validFrom && validTo && validTo <= validFrom) {
        setDateValidErrorMessage(t('AdminTariff.Errors.InvalidDate'));
        setValidTo(false);
      }
    }

    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  const onTabChanged = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onTariffDataChanged = (tariffData) => {
    onChangeRaw(
      TariffBook.copyOf(value, updated => {
        updated.updatedAt = new Date();
        updated.tariffs = tariffData.tariffs;
        updated.defaultCharges = tariffData.defaultCharges;
      }
    ));
  };

  return (
    <>
      <Box display="flex" gridGap={8}>
        <TextField
          id="TariffBookNameInput"
          required
          disabled={disabled}
          value={value.name || ''}
          name="name"
          label={t('AdminTariff.Labels.Name')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          onChange={onChange}
          margin="normal"
          error={!value.name}
          helperText={!value.name ? t('AdminTariff.Errors.MissingName') : undefined}
          inputProps={{
            maxLength: 255,
          }}
        />
        <StringKeyboardDatePicker
          id="TariffBookValidFromInput"
          disabled={disabled}
          label={t('AdminTariff.Labels.DateValidFrom')}
          name="dateValidFrom"
          fullWidth
          inputVariant="outlined"
          margin="normal"
          allowEmptyValue
          value={value.dateValidFrom}
          okLabel={t('Common.Buttons.Confirm')}
          onChange={(date) => onChangeDate('dateValidFrom', date)}
          error={!validFrom}
          helperText={!validFrom && dateValidErrorMessage}
          maxDate={dayBefore(value.dateValidTo)}
          autoComplete="off"
        />
        <StringKeyboardDatePicker
          id="TariffBookValidToInput"
          disabled={disabled}
          label={t('AdminTariff.Labels.DateValidTo')}
          name="dateValidTo"
          fullWidth
          inputVariant="outlined"
          margin="normal"
          allowEmptyValue
          value={value.dateValidTo}
          okLabel={t('Common.Buttons.Confirm')}
          onChange={(date) => onChangeDate('dateValidTo', date)}
          error={!validTo}
          helperText={!validTo && dateValidErrorMessage}
          minDate={dayAfter(value.dateValidFrom)}
          autoComplete="off"
        />
      </Box>
      <TextField
        id="TariffBookDescriptionInput"
        disabled={disabled}
        value={value.description || ''}
        name="description"
        label={t('AdminTariff.Labels.Description')}
        fullWidth
        variant="outlined"
        autoComplete="off"
        onChange={onChange}
        margin="normal"
        inputProps={{
          maxLength: 255,
        }}
      />

      <Tabs value={currentTab} onChange={onTabChanged}>
        <Tab
          id="AdminTarifffBookTariffsTab"
          key="AdminTarifffBookTariffsTab"
          label={t('AdminTariff.Labels.Tariffs')}
          value={TAB_TARIFFS}
        />
        <Tab
          id="AdminTarifffBookDefaultItemsTab"
          key="AdminTarifffBookDefaultItemsTab"
          label={t('AdminTariff.Labels.DefaultChargeables')}
          value={TAB_DEFAULT_CHARGEABLES}
        />
      </Tabs>
      
      <TariffBookEditorProvider tariffBook={tariffBook ? tariffBook : value}>
        { currentTab === TAB_TARIFFS &&
          <TariffListEditor onChanged={onTariffDataChanged} />
        }
        { currentTab === TAB_DEFAULT_CHARGEABLES &&
          <ChargeableListEditor onChanged={onTariffDataChanged} />
        }
      </TariffBookEditorProvider>
    </>
  );
};

const AdminTariffBookSaveDialogContent = ({ lastValue, value }) => {
  const { t } = useTranslation();
  return (
    <>
      <DialogContentText>{t('AdminTariff.Labels.TariffBookSaveWarning')}</DialogContentText>
    </>
  );
};

const AdminTariffBook = () => {
  const { tariffBooks } = useTariffBooks();
  const { t } = useTranslation();
  const itemName = t('AdminTariff.Labels.TariffBook')
  
  return (
    <AdminModelEditor
      HeaderContent={AdminTariffBookHeaderContent}
      ListItemContent={AdminTariffBookListItemContent}
      FormContent={AdminTariffBookFormContent}
      formWidth={'66%'}
      SaveDialogContent={AdminTariffBookSaveDialogContent}
      model={TariffBook}
      customDeleteTooltip={() => t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })}
      customDuplicateTooltip={t('AdminModelEditor.Labels.CustomDuplicateEntry', { itemName: itemName })}
      sort={(a, b) => (a.name > b.name ? 1 : -1)}
      filter={(i) => !i.deleted}
      canUpdate={() => true}
      canDelete={() => true}
      canDuplicate={() => true}
      onDuplicate={(item, books) => handleDuplicateTariffBook(item, tariffBooks)}
      onDelete={async (item) => {
        // soft delete
        await DataStore.save(
          TariffBook.copyOf(item, (updated) => {
            updated.deleted = true;
          })
        );
      }}
      canSave={(i) => validateTariffBook(i)}
      onSave={async (item) => {
        saveTariffBook(item, tariffBooks.find(el => el.id === item.id));
      }}
      preSave={(lastValue, value) => {
        return (
          value?._version && // _version is undefined if creating a new tariff book
          (lastValue?.dateValidFrom !== value?.dateValidFrom || lastValue?.dateValidTo !== value?.dateValidTo)
        );
      }}
      canAdd={() => true}
      getUniqueField={(i) => i?.name}
      defaultItem={() => {
        return {
          name: '',
          description: undefined,
          dateValidFrom: null,
          dateValidTo: null,
          tariffs: [],
          defaultCharges: [],
          deleted: false,
        };
      }}
    />
  )
};

export default AdminTariffBook;