import React from "react";
import {
  TableCell,
  TableRow,
} from '@material-ui/core';

import '../../translations/i18n';

const GenericRow = ({
  columns,
  row,
  classes,
  isLastRow,
  emphasiseLastRow
}) => {
  return (
    <TableRow>
      {columns.map(({ id }, cellIndex) => {
        const classesArray = [classes?.cell]
        if (emphasiseLastRow && isLastRow) {
          classesArray.push(classes?.emphasisedLastRowCellsBase)
          if (row[id]) {
            classesArray.push(classes?.emphasisedLastRowCells)
          }
        }
        if (cellIndex === 0) {
          classesArray.push(classes?.firstCell)
        }
          return (
            <TableCell 
              key={id}
              className={classesArray.join(' ')}
            >
              {row[id]}
            </TableCell>
          )
      })}
  </TableRow>
  )
}

export default GenericRow;