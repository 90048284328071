import React from 'react';
import {
  Chip,
  Box,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import CertificateTooltip from '../Tooltip/CertificateTooltip';
import CertificateListTooltip from '../Tooltip/CertificateListTooltip';
import CertificateListItem from './CertificateListItem';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  listStyle: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8, 
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  itemStyle: {
    marginRight: "0.25rem", 
    marginBottom: "0.2rem"
  }
});

const CertificateList = ({certificates, fromDate, toDate, showValidity = true, icon = true, limit, showMore = false, ...other}) =>{
  const { t } = useTranslation();
  const classes = useStyles();
  const numCerts = certificates?.length;
  const more = numCerts > limit ? numCerts - limit : 0;
  const certs = limit > 0 ? certificates?.slice(0, limit) : certificates;
  return (
    <Box className={classes.listStyle} {...other}>
      {(!certificates || certificates.length === 0) && <Typography>{t('CertificateType.Labels.NoCertificates')}</Typography>}
      {certificates && certs.map((cert, index) => 
        <PopoverTooltip key={`certificate-list-${index}`} tooltip={<CertificateTooltip certificate={cert} />} disableFullWidthWrapper>
          <Chip
            label={
              <CertificateListItem
                certificate={cert}
                fromDate={fromDate}
                toDate={toDate}
                showValidity={showValidity}
                key={index}
                icon={icon}
              />}
            className={classes.itemStyle}
          />
        </PopoverTooltip>
      )}
      {showMore && more > 0 &&
        <PopoverTooltip tooltip={
          <CertificateListTooltip certificates={certificates} showValidity={true} icon />
        }>
          <Typography>{t('CertificateType.Labels.More', {more: more})}</Typography>
        </PopoverTooltip>
      }
    </Box>
  )
}

export default CertificateList;
