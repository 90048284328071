
import { IN_PORT, PREARRIVAL, ARRIVAL, DEPARTURE, DELETED, CANCELLED, } from '../../../constants/PortCallStatus';
import { ActionMovementType, ActionState, ActionType, PortCallStatus } from '../../../models';
import { isArrivalOnlyPortCall } from '../utils';

/**
 * Build an object containing each of the available port call menu items based
 * on input conditions
 * @param {PortCall} portCall 
 * @param {Object} contexts 
 * @param {boolean} agentPortal 
 * @param {boolean} skipCheck 
 * @param {EventTarget} anchorEl 
 * @param {Array<Action>} nextUndoableActions 
 * @param {boolean} isPortCallArchived 
 * @param {boolean} disabledCancel 
 * @param {boolean} disabledDelete 
 * @returns Object of structure { portcall: canPortCallDetails.... }
 */
export const buildPortCallMenuItems = (
  portCall,
  contexts,
  agentPortal,
  skipCheck,
  anchorEl,
  nextUndoableActions,
  isPortCallArchived,
  disabledCancel,
  disabledDelete,
  completedArrival
) => {
  const hasActions = {};
  if (!skipCheck || anchorEl) {
    if (contexts.includes("portCall")) {
      const portCallActions = {};
      if (contexts.includes('details') && ![DELETED].includes(portCall.status))
        portCallActions.canPortCallDetails = true;
      if ([PREARRIVAL].includes(portCall.status) && !isPortCallArchived && !disabledCancel)
        portCallActions.canHandlePortCallCancel = true; 
      if (!agentPortal && [PREARRIVAL, CANCELLED].includes(portCall.status) && !isPortCallArchived && !disabledDelete)
        portCallActions.canHandlePortCallDelete = true;
      if (!agentPortal && nextUndoableActions.length && !isPortCallArchived)
        portCallActions.canUndoAction = true;
      if (!agentPortal && !isPortCallArchived
        && [PREARRIVAL, ARRIVAL, DEPARTURE, IN_PORT].includes(portCall.status))
        portCallActions.canHandleCompletePortCall = true;
      if (!agentPortal && !isPortCallArchived && portCall.status === IN_PORT && isArrivalOnlyPortCall(completedArrival, portCall?.actions))
        portCallActions.canHandleClosePortCall = true;
      if (Object.keys(portCallActions).length)
        hasActions.portCall = portCallActions;
    }
  }
  // console.log('buildPortCallMenuItems', hasActions)
  return hasActions;
};

/**
 * Build an object containing each of the available movement menu items based
 * on input conditions
 * @param {PortCall} portCall 
 * @param {Action} action 
 * @param {Action} prevAction 
 * @param {Object} contexts 
 * @param {boolean} agentPortal 
 * @param {boolean} skipCheck 
 * @param {EventTarget} anchorEl 
 * @param {Array<Action>} nextUndoableActions 
 * @param {boolean} archived 
 * @param {Array<ActionType>} customActionTypes 
 * @param {boolean} appApprovalEnabled 
 * @param {boolean} isApprover 
 * @param {boolean} agentPortCall 
 * @returns Object of structure { movement: showActionsDetails.... }
 */
export const buildMovementMenuItems = (
  portCall,
  action,
  prevAction,
  contexts,
  agentPortal,
  skipCheck,
  anchorEl,
  nextUndoableActions,
  archived,
  customActionTypes,
  appApprovalEnabled,
  isApprover,
  agentPortCall
) => {
  const hasActions = {};
  const cancelledPortCall = portCall.status === CANCELLED;

  if (!skipCheck || anchorEl) {
    if (action) {
      if (action.type.lifecycle) {
        const movementActions = {};
        // only allowed when vessel is set and it's relevant to action and portcall in unarchive
        if (portCall.portCallVesselId && contexts.includes("action") && !archived) {
          // not agent portal
          if (!agentPortal) {
            // Options for if the Approval setting is true
            if (appApprovalEnabled) {
              // Any Departures that are in Approval Declined or Pending can be deleted
              if (action.movementType === DEPARTURE && (action.state === ActionState.APPROVAL_DECLINED || action.state === ActionState.APPROVAL_PENDING)) {
                movementActions.canHandlePortCallDepartureCancel = true;
                movementActions.canHandlePortCallDepartureDelete = true;
              }
              // Options for Approver
              if (isApprover) {
                if (portCall.status !== PortCallStatus.CANCELLED && action.state === ActionState.APPROVAL_PENDING) {
                  movementActions.canHandleApproveAction = true;
                  movementActions.canHandleDeclineAction = true;
                }
                if (portCall.status !== PortCallStatus.CANCELLED && action.state === ActionState.APPROVAL_DECLINED) {
                  movementActions.canHandleApproveAction = true;
                }
                // Options for Other Users
              } else {
                if (action.state === ActionState.APPROVAL_DECLINED || action.state === ActionState.PLANNED) {
                  movementActions.canHandleMarkForApproval = true;
                }
              }
            }
            if (portCall.status === PREARRIVAL && action.movementType === ARRIVAL && action.state === ActionState.PLANNED)
              movementActions.canHandlePortCallArrival = true;
            if ((portCall.status === PREARRIVAL || portCall.status === ARRIVAL) && action.movementType === ARRIVAL && (action.state === ActionState.IN_PROGRESS || action.state === ActionState.PLANNED))
              movementActions.canHandlePortCallRecordArrival = true;
            if (portCall.status === IN_PORT && action.movementType === ActionMovementType.SHIFT_ARRIVAL && action.state === ActionState.PLANNED)
              movementActions.canHandleShiftArrival = true;
            if (action.movementType === ActionMovementType.SHIFT_ARRIVAL && action.state === ActionState.IN_PROGRESS)
              movementActions.canHandleShiftRecordArrival = true;
            if (!cancelledPortCall && action.movementType === DEPARTURE && action.state === ActionState.PLANNED)
              movementActions.canHandlePortCallDeparture = true;
            if (!cancelledPortCall && action.movementType === DEPARTURE && (action.state === ActionState.IN_PROGRESS || action.state === ActionState.PLANNED))
              movementActions.canHandlePortCallRecordDeparture = true;
            if (action.movementType === ActionMovementType.SHIFT_DEPARTURE && action.state === ActionState.PLANNED)
              movementActions.canHandleShiftDeparture = true;
            if (action.movementType === ActionMovementType.SHIFT_DEPARTURE && action.state === ActionState.IN_PROGRESS)
              movementActions.canHandleShiftRecordDeparture = true;
            if (!cancelledPortCall && action.movementType === DEPARTURE && action.state === ActionState.PLANNED)
              movementActions.canHandlePortCallDepartureCancel = true;
            if (!cancelledPortCall && action.movementType === DEPARTURE && action.state === ActionState.PLANNED)
              movementActions.canHandlePortCallDepartureDelete = true;
            if (!cancelledPortCall && action.movementType === DEPARTURE && action.state === ActionState.CANCELLED)
              movementActions.canHandlePortCallDepartureDelete = true;

            if (nextUndoableActions.length) {
              if (action.state === ActionState.COMPLETED && action.id === nextUndoableActions[0].id)
                movementActions.canUndoCompleted = true;
              if (action.state === ActionState.IN_PROGRESS && action.id === nextUndoableActions[0].id)
                movementActions.canUndoInProgress = true;
            }
            if ((action.movementType === ActionMovementType.SHIFT_DEPARTURE || action.movementType === ActionMovementType.SHIFT_ARRIVAL) && (action.state === ActionState.PLANNED || action.state === ActionState.APPROVAL_DECLINED || action.state === ActionState.APPROVAL_PENDING) && (!prevAction || prevAction.movementType !== ActionMovementType.SHIFT_DEPARTURE || (prevAction.state === ActionState.PLANNED || prevAction.state === ActionState.APPROVAL_PENDING || prevAction.state === ActionState.APPROVAL_DECLINED)))
              movementActions.canHandleDeleteShift = true;
            if (customActionTypes && customActionTypes.some(cat => cat.id === action.type.id)) {
              if (action.state === ActionState.PLANNED) {
                movementActions.canHandleCustomActionStart = true;
                movementActions.canHandleCustomActionCancel = true;
              }
              if (action.state === ActionState.IN_PROGRESS)
                movementActions.canHandleCustomActionRecord = true;
              if (action.state === ActionState.CANCELLED || action.state === ActionState.PLANNED || ActionState.APPROVAL_DECLINED || ActionState.APPROVAL_PENDING)
                movementActions.canHandleCustomActionDelete = true;
            }
          } else if (agentPortCall) {
            // In agent Portal and port call relevant to agent
            // Show details if for action if the agent belongs to portcall
            if (contexts.includes('portCall'))
              movementActions.showActionsDetails = true;
            //show cancel option if departure planned
            if (action.movementType === ActionMovementType.DEPARTURE && action.state === ActionState.PLANNED)
              movementActions.canHandlePortCallDepartureCancel = true;
          }
        }

        if (contexts.includes("details") && contexts.includes("action") && appApprovalEnabled)
          movementActions.showActionsDetails = true;

        if (Object.keys(movementActions).length)
          hasActions.movement = movementActions;
      }
    }
  }
  // console.log('buildMovementMenuItems', hasActions)
  return hasActions;
};