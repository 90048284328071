import { useState, useContext, useMemo, useEffect } from "react";
import { Box, Typography } from '@material-ui/core';
import { UIContext } from "../../../contexts/ui";
import { NavigationContext, navigationActionConstants } from '../../../contexts/navigation';
import useVoyageList from "../../../hooks/useVoyageList";
import { ActionMovementType, ActionState } from "../../../models";
import DataTable from "../../../containers/DataTable";
import PopoverTooltip from "../../Tooltip/PopoverTooltip";
import VesselListItem from "../../Vessel/VesselListItem";
import VesselTooltip from "../../Tooltip/VesselTooltip";
import SpotlightMapDialog from "../../Spotlight/SpotlightMapDialog";
import RouteConstants from '../../../constants/RouteConstants';
import { VoyageTableHeaders } from "../../../constants/VoyageBrowserContstants";
import VoyageMovementListItem from "./VoyageMovementListItem";
import Loading from "../../Loading";
import VoyageItemMenu from "../VoyageItemMenu";
import { DataStoreContext } from "../../../contexts/dataStoreContext";
import { sortVoyagesByName } from "../../../utils/sorters";
import VoyageStatusLabel from "../VoyageStatusLabel";
import useTransactionMutation from "../../../hooks/useTransactionMutation";
import VoyageConfirmDialog from "./VoyageConfirmDialog";

const tableVersion = 0.03;

const VoyageBrowser = ({sortOrder, displayStatus=false, lastView, liveVoyages=false, tableId}) => {
  
  const [uiContext] = useContext(UIContext);
  const { isVoyageAdmin } = uiContext;
  const { locations } = useContext(DataStoreContext);
  const [, dispatchNavigationContext] = useContext(NavigationContext);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [vesselMapData, setVesselMapData] = useState(null);
  const voyages = useVoyageList(liveVoyages);
  const [confirmProps, setConfirmProps] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [textConfirm, setTextConfirm] = useState(null);
  const [updateMutation, resetTransaction, { loading, error }] = useTransactionMutation();
  const [calculatingVoyageUpdate, setCalculatingVoyageUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  
  const locationMap = useMemo(() => {
    const lmap = new Map();
    locations.forEach(el => lmap.set(el.id, el));
    return lmap;
  }, [locations]);

  const defaultColumns = [
    {
      id: 'name',
      label: VoyageTableHeaders.NAME,
      format: val => val && <Typography id='VoyageName' noWrap>{val}</Typography>,
      visible: true,
      width: 18
    },
    {
      id: 'vesselData',
      label: VoyageTableHeaders.VESSEL,
      format: (val) =>
        <PopoverTooltip tooltip={<VesselTooltip vesselData={val} setMapDialogOpen={setMapDialogOpen} setVesselMapData={setVesselMapData} />} delay={800}>
          <VesselListItem
            id='vessel'
            disableGutters={true}
            vesselData={val}
            disabled={!val}
          />
        </PopoverTooltip>,
      visible: true,
      width: 18
    },
    {
      id: 'departureDetails',
      label: VoyageTableHeaders.DEPARTURE_DETAILS,
      format: (val) => <VoyageMovementListItem movement={val} movementType={ActionMovementType.DEPARTURE}/>,
      visible: true,
      width: 26
    },
    {
      id: 'arrivalDetails',
      label: VoyageTableHeaders.ARRIVAL_DETAILS,
      format: (val) => <VoyageMovementListItem movement={val} movementType={ActionMovementType.ARRIVAL}/>,
      visible: true,
      width: 26
    },
  ]
  const status = {
    id: 'status',
    label: VoyageTableHeaders.STATUS,
    format: (val) => <VoyageStatusLabel voyageStatus={val} rowView={true}/>,
    visible: true,
    width: 18
  }
  const menu = {
    id: 'menu',
    label: '',
    format: (val) => <VoyageItemMenu 
      voyage={val.voyage} 
      setOpenConfirm={setOpenConfirm} 
      setTextConfirm={setTextConfirm} 
      setErrorMessage={setErrorMessage}
      error={error}
      setCalculatingVoyageUpdate={setCalculatingVoyageUpdate}
      updateMutation={updateMutation}
      setConfirmProps={setConfirmProps}
    />,
    visible: true,
    width: 2.625
  }

  const pageColumns = displayStatus ? [...defaultColumns, status, menu] : [...defaultColumns, menu]
  const initialOrder = pageColumns.map(item => item.id);
  const initialVisible = pageColumns.reduce((accumulator, currentItem) => {
    accumulator[currentItem.id] = currentItem.visible;
    return accumulator;
  }, {});

  useEffect(() => {
    dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: lastView || RouteConstants.VOYAGE_LIST });
  }, [dispatchNavigationContext, lastView]);

  const transformedVoyageItems = useMemo(()=>{
    return voyages.sort(sortOrder || sortVoyagesByName) ? voyages.map(item => {
      return {
        id: item?.id,
        name: item?.name,
        vesselData: item?.vessel?.vesselData,
        menu: {
          voyage: item,
          vesselData: item?.vessel?.vesselData
        },
        departureDetails: {...item?.voyageFirstDeparture, location: locationMap.get(item?.voyageFirstDeparture?.actionMovementLocationId)},
        arrivalDetails: {...item?.voyageLastArrival, location: locationMap.get(item?.voyageLastArrival?.actionMovementLocationId)},
        status: item?.status,
        meta: {
          className: item?.status === ActionState.CANCELLED ? "itemCancelled" : ""
        }
      };
    }) : [];
  }, [voyages, sortOrder, locationMap]);

  if (!isVoyageAdmin) return null;
  return (
    <Box
      id="VoyageList"
      display="flex"
      flexDirection="column"
      flexGrow="2"
    >
      { transformedVoyageItems ?
      <DataTable
        id={tableId || 'voyages'}
        columns={pageColumns}
        showHeader={true}
        rows={transformedVoyageItems}
        initialOrder={initialOrder}
        initialVisible={initialVisible}
        autoSavePreferences={true}
        tableVersion={tableVersion}
      /> : <Loading/> }
      {mapDialogOpen &&
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={vesselMapData}
        />
      }
      <VoyageConfirmDialog
        openConfirm={openConfirm}
        textConfirm={textConfirm}
        setOpenConfirm={setOpenConfirm}
        setTextConfirm={setTextConfirm}
        confirmProps={confirmProps}
        calculatingVoyageUpdate={calculatingVoyageUpdate}
        errorMessage={errorMessage}
        resetTransaction={resetTransaction}
        loading={loading}
        error={error}
      />
    </Box>
  )
}
export default VoyageBrowser;