import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
} from '@material-ui/core';
import { UIContext } from '../../contexts/ui';
import { Link } from 'react-router-dom';

import { EventType as et} from '../../constants/EventType';
import ActionStateAndTimeTooltip from '../../components/Tooltip/ActionStateAndTimeTooltip';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';
import ScheduleTime from '../ScheduleTime';
import PortCallTimelinePortCallMenu from '../PortCall/PortCallTimelinePortCallMenu';
import TimelineMovementAction from './TimelineMovementAction';
import TimelineAgentHandoverEventAction from './TimelineAgentHandoverEventAction';
import TimelineCustomAction from './TimelineCustomAction';
import AgentHandoverEventActionMenu from '../Action/AgentHandoverEventActionMenu';
import CustomActionMenu from './CustomActionMenu';
import { ActionTypeIds } from '../../environment';
import { EditorContentTabIds, useEditorContentTab } from '../Common/EditorContentTabs';

/* Make styles if needed */
const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexBasis: "calc(75% - 2rem)",
    padding: "0 1rem",
    height: "100%",
    position: "relative"
  },
  timelineActionItem: {
    position: 'relative',
    height: '5rem',
    boxSizing: 'border-box',
    padding: 0,
    transition: 'background-color 300ms, border 300ms, padding 300ms',
    '&:hover .card': {
      background: theme.palette.action.hover
    },
    '& .card': {
      // background: theme.palette.action.hover
      // background: 'rgba(0,0,0,0.04)' // hmmmm???
    },

    '&:hover .circle': {
      transform: 'scale(1)',
      transition: 'all .1s ease-in',
    }
  },
  timelineEventItem: {
    height: '2.5rem',
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
  },
  selected: {
    '& .card': {
      border: '.25rem solid ' + theme.palette.primary.main,
      background: theme.palette.action.hover
    },
    '& .circle': {
      transition: 'all .1s ease-out',
      transform: 'scale(1.25) !important'
    }
  },
  noCard: {
    paddingLeft: "1rem",
  },
  circle: {
    width: '1rem',
    height: '1rem',
    transform: 'scale(0.75)',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    transition: 'all .25s ease-out',
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  flexFiller: {
    flexGrow: '1',
  },
  timelineActionWrapper: {
    marginBottom: '2.5rem',
    zIndex: '1',
    flexShrink: '0',
  },
  pointer: {
    cursor: 'pointer',
  }
}));

/* Further custom functions */

export const TimelineAction = ({
  actionId,
  item,
  portCall,
  requests,
  customActionTypes,
  portCallMenuDisabled,
  portCallReference,
}) => {
  const classes = useStyles();

  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;

  const isMovement = item.type.id === ActionTypeIds.MOVEMENT;
  const isAgentHandOver = !isMovement && item.type.id === ActionTypeIds.EVENT && item.eventType === et.AGENT_HANDOVER;
  const isCustomAction = !isAgentHandOver && customActionTypes && customActionTypes.some(cat => cat.id === item.type.id);

  // get reference to action and not editted version
  const itemReference = portCallReference && portCallReference.actions && portCallReference.actions.find(({id}) => item.id === id);

  // selected
  const isSelected = item.id === actionId;

  const getClassNameBasedOnActionType = () => {
    if(isMovement) {
      return `PortCallTimelineAction-Movement`
    } else if(isMovement) {
      return `PortCallTimelineAction-AgentHandOver`
    } else if(isMovement) {
      return `PortCallTimelineAction-CustomAction`
    }
    return ''
  }

  // Function for determining the classNames of the Timeline Action
  const classNameTimeLineAction = () => {
    const isEvent = item.type.id === ActionTypeIds.EVENT ? classes.timelineEventItem : '';
    // Removed as could not see it being used anywhere - Add ${movementTypeClassName} to literal string if needed
    // const movementTypeClassName = item.movementType && classes[item.movementType];
    const selectedClass =  isSelected ? classes.selected : '';
    return `${classes.timelineActionItem} ${isEvent} ${selectedClass} PortCallTimelineAction ${getClassNameBasedOnActionType()}`
  }

  const renderTimelineAction = () =>  (
    <>
      {/* Component for Action */}
      {isMovement && <TimelineMovementAction item={item} requests={requests}/>}
      {/* Component for AgentHandover */}
      {isAgentHandOver && <TimelineAgentHandoverEventAction item={item} />}
      {/* Component for Custom Action */}
      {isCustomAction && <TimelineCustomAction item={item} />}
    </>
  )

  const renderCard = (timelineAction) => isMovement || isCustomAction || isSelected ? 
    <Card className={`${classes.card} card`}>
      {timelineAction}
    </Card>
    : <Box className={classes.noCard}> {timelineAction} </Box>

  // maintain tab
  const tab = useEditorContentTab();
  let linkTo = `/port-call/${portCall.id}/action/${item.id}`;
  if (tab === EditorContentTabIds.REQUESTS)
    linkTo += "/requests";
  else if (tab === EditorContentTabIds.LOGBOOK)
    linkTo += "/logbook"
  return (
    <Box className={classes.timelineActionWrapper} elevation={0}>
      <Box
        className={classNameTimeLineAction()}
        data-id={item.id}
      >
        <Link
          className={`${classes.menuLink} TimelineActionLink`}
          to={linkTo}
        >
          <Box
            display="flex"
            alignItems="center"
            height="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              flexBasis="10rem"
              flexShrink="0"
            >
              {/* Scheduled time on Timeline */}
              <PopoverTooltip tooltip={<ActionStateAndTimeTooltip action={item} />}>
                <ScheduleTime
                  planned={item.timePlanned}
                  actual={item.timeActual}
                  estimated={item.timeEstimated}
                  requestedTime={item.requestedTime}
                  estimatedUpdatedAt={item.timeEstimatedUpdatedAt}
                  reverse={true}
                />
              </PopoverTooltip>
            </Box>
            {/* Render Dot on Timeline  */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexBasis="1rem"
              height="100%"
              ml="2rem"
              mr="2rem"
            >
              <div className={`${classes.circle} circle`} />
            </Box>
            {renderCard(renderTimelineAction())}
          </Box>
        </Link>
        <Box
          position="absolute"
          right="0.5rem"
          top="0"
          display="flex"
          height="100%"
          alignItems="center"
          className={classes.pointer}
        >
          {/* Menu for Movement */}
          {isMovement &&
            <PortCallTimelinePortCallMenu
              disabled={portCallMenuDisabled}
              relevantAction={itemReference}
              portCall={portCallReference}
              contexts={['action']}
              ButtonProps={{
                style: { pointerEvents: 'all' }
              }}
              readOnly={readOnly}
            />
          }
          {/* Menu for AgentHandOver */}
          {isAgentHandOver &&
            <AgentHandoverEventActionMenu
              action={item}
              readOnly={readOnly}
            />
          }
          {/* Menu for Custom Action */}
          {isCustomAction && itemReference &&
            <CustomActionMenu 
              action={itemReference} 
              portCall={portCallReference} 
              readOnly={readOnly}
            />
          }
        </Box>
      </Box>
    </Box>
  )
}

TimelineAction.defaultProps = {
  customActionTypes: [],
}

TimelineAction.propTypes = {
  actionId: PropTypes.string,
  item: PropTypes.object,
  portCall: PropTypes.object,
  portCallReference: PropTypes.object,
  portCallMenuDisabled: PropTypes.bool,
  requests: PropTypes.bool,
  customActionTypes: PropTypes.array,
};

export default TimelineAction;
