import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle,
  CheckCircleOutline,
} from 'mdi-material-ui';

import { TodoStatus as ts } from '../../constants/TodoStatus';

const useStyles = makeStyles(() => {
  return {
    completed: {
      color: '#1c6424',
    },
    notCompleted: {
      color: '#a81717',
    }
  }
});

const ChecklistSummary = ({
  items,
}) => {

  const classes = useStyles();

  const totalItems = items.filter(item => item.status !== ts.DELETED).length;
  const doneItems = items.filter(item => item.status === ts.DONE).length;
  const checklistCompleted = totalItems === doneItems;

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <Grid item className={checklistCompleted ? classes.completed : classes.notCompleted}>
        {checklistCompleted
          ? <CheckCircle />
          : <CheckCircleOutline />
        }
      </Grid>
      <Grid item>
        <Typography>
          {doneItems}/{totalItems}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ChecklistSummary;