import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { RequestTypeLabelsKeys, RequestStateLabelsKeys, dateOptions } from '../../constants/RequestConstants';
import { RequestState } from '../../models';
import { useTranslation } from 'react-i18next';

export default ({ request }) => {
  const { t } = useTranslation()
  if (!request) return null;
  let status = RequestStateLabelsKeys[request.state];
  if (Array.isArray(request.actionData)) {
    const approvedCount = request.actionData.filter(ad => ad.approved === RequestState.REQUEST_STATE_APPROVED).length;
    const rejectedCount = request.actionData.filter(ad => ad.approved === RequestState.REQUEST_STATE_REJECTED).length;
    if (rejectedCount === request.actionData.length)
      status = RequestStateLabelsKeys[RequestState.REQUEST_STATE_REJECTED];
    else if (rejectedCount > 0)
      status = RequestStateLabelsKeys.PARTIALLY_REJECTED;
    else if (approvedCount > 0)
      status = RequestStateLabelsKeys.PARTIALLY_APPROVED;
    else
      status = RequestStateLabelsKeys[request.state];
  }
  return (
    <ListItem>
      <ListItemText primary={t(RequestTypeLabelsKeys[request.type])} secondary={t('RequestTooltip.Labels.RequestType')} style={{  }} />
      <ListItemText primary={request.source} secondary={t('RequestTooltip.Labels.Source')} style={{ marginLeft: "1rem" }} />
      <ListItemText primary={new Date(request.updatedAt).toLocaleDateString(undefined, dateOptions)} secondary={t('RequestTooltip.Labels.UpdatedAt')} style={{ marginLeft: "1rem" }} />
      <ListItemText primary={t(status)} secondary={t('RequestTooltip.Labels.Status')} style={{ marginLeft: "1rem" }} />
    </ListItem>
  );
}