import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { ListItem, ListItemText, TextField } from '@material-ui/core';
import { contactTypeFilterOptions } from '../../utils/sorters';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import { getContactTypeName } from '../../utils/getters';

const ContactTypeAutocomplete = ({ value, onChange, startAdornment, label, required, helperText, ...other }) => {
  const { t } = useTranslation();
  const { contactTypes } = useContext(DataStoreContext);
  const [inputValue, setInputValue] = useState(value?.name || '');
  useEffect(() => { value && setInputValue && setInputValue(getContactTypeName(t, value)) }, [t, setInputValue, value]);
  return (
    <Autocomplete
      {...other}
      autoHighlight
      options={contactTypes}
      filterOptions={contactTypeFilterOptions}
      getOptionLabel={(item) => (item && getContactTypeName(t, item))}
      getOptionSelected={(option, value) => option.id === value.id}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      renderOption={item => (
        <ListItem dense component="div">
          <ListItemText primary={getContactTypeName(t, item)} />
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          helperText={helperText}
        />
      )}
    />
  );
};

export default ContactTypeAutocomplete;