import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Icon } from 'ol/style';
import { Attrs, FeatureType } from '../constants';
import VesselDynamic from '../resources/vesselDynamic.svg'; 
import VesselStationary from '../resources/vesselStationary.svg'; 
import CircleStyle from 'ol/style/Circle';

export const DEG_TO_RAD = Math.PI / 180.0;
export const OUTLINE_THRESHOLD = 6.0;
export const ALPHA = 1.0; // 0.3 match with Marlin

// Cache of all OL style uses in layer 
const styleCache = new Map();

const outlineStyleName = (color) => {
  return 'outline_' + color[0] + color[1] + color[2] + color[3];
};

const iconStyleName = (heading, stationary, dot, color) => {
  return 'icon_' + parseInt(heading,10) + (stationary ? '_1_' : '_0_') + (dot ? '_1_' : '_0_')+ color[0] + color[1] + color[2] + color[3];
};

const shadeFactor = 0.4; 
const darken = (color) => {
  return [
    Math.round(color[1] * (1 - shadeFactor)),
    Math.round(color[0] * (1 - shadeFactor)),
    Math.round(color[2] * (1 - shadeFactor)),
  ];
};

const colorToString = (color) => {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${ALPHA})`;
};

const createVesselStyle = (feature, resolution, dom) => {
  // console.log(resolution);
  const type = feature.get(Attrs.TYPE);
  if(type !== FeatureType.Vessel) return null;

  const meta = feature.get(Attrs.META);
  const type5 = meta.type5;
  const outline = type5 && (resolution < OUTLINE_THRESHOLD);
  const stationary = meta.stationary;
  const symbol = stationary ? VesselStationary : VesselDynamic;
  const color = feature.get(Attrs.COLOR);

  // It is costly to set the feature geometry if it does not change 
  const geom = outline ? Attrs.OUTLINE : Attrs.GEOM;
  if(feature.getGeometryName() !== geom) feature.setGeometryName( geom );

   if(outline) {
    const styleName = outlineStyleName(color);
    let style = styleCache.get(styleName);
    if(!style) {
      const outlineColor = colorToString(darken(color));
      style =  new Style({
        stroke: new Stroke({
          color: outlineColor,
          width: 1.5
        }),
        fill: new Fill({
          color: colorToString(color)
        }),  
      });

      styleCache.set(styleName, style);
      // console.log(`[${styleCache.size}] creating new style`, styleName);
    }
    return style;

  } else {
    const heading = feature.get(Attrs.HEADING);
    const dot = resolution > 40;
    const styleName = iconStyleName(heading, stationary, dot, color);
    let style = styleCache.get(styleName);

    if(!style) {
      if(dot) {
        style = new Style({
          image: new CircleStyle({
            radius: 2,
            fill: new Fill({
              color: color
            }),
            stroke: new Stroke({
              color: 'bbbbbb'
            })
          })
        });
      } else {
        style = new Style({
          image: new Icon({
            src: symbol,
            rotation: stationary ? 0 : heading * DEG_TO_RAD,
            color: colorToString(color)
          })
        });
      }
      styleCache.set(styleName, style);
      // console.log(`[${styleCache.size}] creating new style`, styleName);
    }
    return style;
  }
};

export class OLVesselLayer extends VectorLayer {
  constructor(opt_options) {
    opt_options.style = createVesselStyle;
    super(opt_options);
  }; 
};

