import React from 'react';
import {
  Box,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';

import { formatDimension } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

/**
 * 
 *  VesselListItem
 *  Displays a vessel as a material list item. 
 *  Avatar is always the vessel flag.
 *  Primary text is always the vessel name.
 *  Secondary text can have a node passed in so that 
 *  it can render an icon alongside the secondary text
 *  if necessary.
 *  Meta field is displayed as a material chip element as 
 *  an end adornment.
 * 
 */

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '0.25rem'
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  }
}));

const VesselDimensionsListItem = ({
  lengthOverall,
  beam,
  draught,
  ...otherProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem
      component="div"
      disableGutters
      {...otherProps}
    >
      <Box display="flex" flexDirection="column">
        {Boolean(lengthOverall) && Boolean(beam) &&
          <Typography variant="body1" noWrap>{`${formatDimension(lengthOverall)} x ${formatDimension(beam)}`}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></Typography>
        }
        {Boolean(draught) &&
          <Typography variant="body1">{formatDimension(draught)}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></Typography>
        }
      </Box>
    </ListItem>
  );
};

VesselDimensionsListItem.defaultProps = {
  disableGutters: true,
};

export default VesselDimensionsListItem;
