import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Plus,
} from 'mdi-material-ui';
import { TodoStatus as ts } from '../../constants/TodoStatus';
import { DataStore } from 'aws-amplify';
import { TodoTypeTemplate } from '../../models';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const AddTodo = ({
  createHandler = null,
  existingTodoTypes,
}) => {
  const [todoType, setTodoType] = useState('');
  const [todoTypes, setTodoTypes] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if(!todoTypes || todoTypes.length === 0) disabled !== true && setDisabled(true);
    disabled !== false && setDisabled(false);
  }, [todoTypes]);

  useEffect(() => {
    let isCancelled = false;
    const fetchTemplates = async () => {
      const todoTypeTemplates = await DataStore.query(TodoTypeTemplate);
      !isCancelled && setTodoTypes(todoTypeTemplates);
    };
    fetchTemplates();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Box
      display="flex"
      pl="1rem"
    >
      <Select
        disabled={disabled}
        fullWidth
        labelId="newTodo"
        label="New checklist item"
        name="newTodo"
        value={todoType}
        onChange={(e) => {
          setTodoType(e.target.value);
        }}
        displayEmpty={true}
      >
        <MenuItem value="">{t('AddTodo.Labels.Select')}</MenuItem>
        {todoTypes && todoTypes.length > 0 && todoTypes
          .filter(item => !existingTodoTypes.includes(item.todoTypeName))
          .map(item => {
            return <MenuItem key={item.id} value={item.todoTypeName}>{item.todoTypeTitle}</MenuItem>
          })}
      </Select>
      <IconButton
        disabled={disabled}
        onClick={(e) => {
          const todoTemplate = todoTypes.filter(item => item.todoTypeName === todoType)[0]
          createHandler && createHandler({
            title: todoTemplate.todoTypeTitle,
            description: todoTemplate.todoTypeDescription,
            todoType: todoTemplate.todoTypeName,
            status: ts.READY,
          });
          setTodoType('');
        }}
      >
        <Plus />
      </IconButton>
    </Box>
  )
}

export default AddTodo;