import { API, DataStore } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Vessel, PortCall, PortCallStatus } from '../../models';

/**
 * Refreshs the vessel data from the backend
 * @param {String} imo Vessel IMO
 * @param {String} mmsi Vessel MMSI
 * @returns {Object} an udated vessel data
 */
export const refreshVesselData = async (imo, mmsi) => {
  return API.graphql({
    query: queries.getVesselExternal,
    variables: {
      imo: imo ? Number(imo) : null,
      mmsi: mmsi? Number(mmsi) : null,
      useApi: true
    },
    fetchPolicy: "network-only"
  });
}

/**
 * Gets the vessel data from datastore
 * @param {String} vesselId Vessel UUID
 * @returns {Object} Vessel details
 */
export const getVesselFromDS = async (vesselId) => {
  return DataStore.query(Vessel, vesselId);
}

/**
 * Updates vessel data in datastore
 * @param {String} vesselId Vessel UUID
 * @param {Object} vesselData Vessel data
 * @returns {Object} A reference to the updated vessel details
 */
export const updateVesselData = async (vesselId, vesselData) => {
  const vesselDS = await DataStore.query(Vessel, vesselId);
  const updateVessel = Vessel.copyOf(vesselDS, updated => {
    updated.imo = vesselData.imo;
    updated.mmsi = vesselData.mmsi;
    updated.callSign = vesselData.callSign;
    updated.vesselData = vesselData;
  })
  return DataStore.save(updateVessel);
}

/**
 * Gets all open port calls for a vessel
 * @param {String} vesselId Vessel UUID
 * @returns {Array} A list of port calls
 */
export const getOpenPortCalls = async (vesselId) => {
  const ps = await DataStore.query(PortCall, pc => pc
    .portCallVesselId_("eq", vesselId)
    .status("ne", PortCallStatus.CLOSED).status("ne", PortCallStatus.CANCELLED).status("ne", PortCallStatus.DELETED)
  )
  return ps ?? [];
}

/**
 * Updates all open port calls for a vessel
 * @param {*} vesselId Vessel UUID
 * @param {*} vesselData Vessel data to update vessel snapshots in all open port calls
 */
export const updateOpenPortCalls = async (vesselId, vesselData) => {
  const ps = await getOpenPortCalls(vesselId);
  ps.map(async p => DataStore.save(PortCall.copyOf(p, updated => { 
      updated.vesselData = vesselData
    }))
  );
}