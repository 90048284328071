import React, { useEffect, useState } from 'react';
import { FormControl, TextField, Box, Select, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const Minute = 'MINUTE';
const Hour = 'HOUR';
const Day = 'DAY';
const DefaultValue = 1;

const useStyles = makeStyles(( theme ) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TimePeriodSelect = ({ value, valueUnit, disabled, required, onChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ timeUnit, setTimeUnit ] = useState(valueUnit ? valueUnit : Hour);
  const [ timeValue, setTimeValue ] = useState(value ? value : DefaultValue);

  useEffect(() => {
    setTimeUnit(valueUnit);
    setTimeValue(value);
  }, [value, valueUnit]);

  const onUnitChanged = (event) => {
    setTimeUnit(event.target.value);
    onChanged && onChanged(timeValue, event.target.value);
  };

  const onValueChanged =(event) => {
    setTimeValue(event.target.value);
    onChanged && onChanged(event.target.value, timeUnit);
  };

  const validTimePeriod = (period) => {
    if(!period) return false;
    const periodInt = parseInt(period, 10);
    if(periodInt === 0 || periodInt < 1) return false;
    return true 
  };

  return (
    <Box display='flex' alignItems='baseline'>
      <FormControl variant='outlined' >
        <TextField
          id='TimeValueInput'
          required={required}
          disabled={disabled}
          value={timeValue}
          onChange={onValueChanged}
          label={t('TimePeriodSelect.Labels.TimePeriod')}
          variant="outlined"
          margin="normal"
          type='number'
          error={!validTimePeriod(timeValue) }
          helperText={ !validTimePeriod(timeValue) ? t('TimePeriodSelect.Errors.InvalidPeriod') : null }
        />
      </FormControl>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel shrink >{t('TimePeriodSelect.Labels.TimeUnit')}</InputLabel>
        <Select
          id='TimeUnitSelectInput'
          required={required}
          disabled={disabled}
          value={timeUnit}
          onChange={onUnitChanged}
          label={t('TimePeriodSelect.Labels.TimeUnit')}
        >
          <MenuItem id='TimeUnitMinutes' value={Minute}>{t('Common.Labels.Minutes')}</MenuItem>
          <MenuItem id='TimeUnitHours' value={Hour}>{t('Common.Labels.Hours')}</MenuItem>
          <MenuItem id='TimeUnitDays' value={Day}>{t('Common.Labels.Days')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
};

export default TimePeriodSelect;