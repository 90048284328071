import React, { useContext, useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { sortCertificateTypes } from '../../utils/sorters';

const getLabel = item => item.category ? `${item.category?.name}: ${item.name}` : item.name;

const CertificateTypeAutocomplete = ({ value, onChange, startAdornment, label, filter, required, helperText, ...other }) => {
  const { certificateType } = useContext(DataStoreContext);
  //add category attribute
  const options = useMemo(() => certificateType
    .filter(ct => !ct.deleted && (!filter || filter(ct)))
    .map(ct => ({ category: certificateType?.find((c) => c.id === ct.certificateTypeCategoryId), ...ct }))
    .sort(sortCertificateTypes),
    [certificateType, filter]
  );

  return (
    <Autocomplete
      getOptionSelected={(option, value) => option?.id === value?.id}
      autoHighlight
      options={options}
      getOptionLabel={(item) => item && getLabel(item)}
      value={value || null}
      onChange={(e, value) => onChange && onChange(value)}
      renderInput={props => (
        <TextField
          {...props}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          helperText={helperText}
        />)}
      {...other}
    />
  );
};

export default CertificateTypeAutocomplete;