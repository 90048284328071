import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { makeStyles, TextField, Typography, Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import SettingConstants from '../../../constants/SettingConstants';
import ConfirmationDialog from "../../ConfirmationDialog";
import TariffDialog from "./TariffDialog";
import TariffListEditorRow from "./TariffListEditorRow";
import { useTariffBookEditorContext, useTariffBookEditorUpdateContext } from "./TariffBookEditorContext";
import { getFullTarrifUnit } from '../../../utils/getters';

export const sortTariffs = (a, b) => a.data.cost.code.localeCompare(b.data.cost.code);

const useStyes = makeStyles(theme => {
  return {
    tableHeader: {
      width: '100%',
    },
    tableHeaderText: {
      opacity: 0.5
    },
    columHeaders: {
      gap: "10px"
    },
    columnIcon: {
      maxWidth: "40px",
      maxHeight: "40px",
      alignSelf: "auto",
      marginTop: ".6rem",
      width: "100%"
    },
  }
});


const TariffListEditor = ({ onChanged }) => {
  const { t } = useTranslation();
  const classes = useStyes();
  const { settings, tariffUnits } = useContext(DataStoreContext);
  const [ appCurrencySetting ] = useState(settings.length && JSON.parse(settings.find(s => s.name === SettingConstants.APP_CURRENCY).value));
  const [ filter, setFilter ] = useState(undefined);
  const [ allItems, setAllItems ] = useState([]);
  const [ filteredItems, setFilteredItems ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(undefined);
  const [ lastAddedItem, setLastAddedItem ] = useState(undefined);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ confirmDialogOpen, setConfirmDialogOpen ] = useState(false);
  const tariffBook = useTariffBookEditorContext();
  const updateTariffBook = useTariffBookEditorUpdateContext();
  const listRef = useRef();

  // console.log("LIST", filteredItems.length);

  /**
   * Validate the tariff code, ensure no duplicates
   * @param {String} tariffId Tariff ID of the tariff being edited
   * @param {String} code Taridd code being validated 
   */
  const validiateTariffCode = useCallback((tariffId, code) => {
    // FIXME should the check be case insensitive?
    return !allItems.find(el => (el.data.id !== tariffId) && (el.data.cost.code.toLowerCase() === code.toLowerCase()));
  }, [allItems]);

  const handleFilterChanged = (event) => {
    setFilter(event.target.value);
  };

  const handleAddTariff = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(undefined);
  };

  const handleDialogAdd = (tariff) => {
    setLastAddedItem(tariff);
    setDialogOpen(false);

    const newTariffs = [...tariffBook.tariffs, tariff];
    updateTariffBook.updateTariffs(tariffBook.bookId, newTariffs);
    
    onChanged && onChanged({
      tariffs: newTariffs,
      defaultCharges: tariffBook.defaultCharges
    });
  };

  const handleDialogUpdate = (tariff) => {
    setDialogOpen(false);

    const newTariffs = tariffBook.tariffs.map(el => {
      if(el.id === tariff.id) return tariff;
      return el;
    });
    updateTariffBook.updateTariffs(tariffBook.bookId, newTariffs);

    onChanged && onChanged({
      tariffs: newTariffs,
      defaultCharges: tariffBook.defaultCharges
    });
  };

  const handleRowUpdate = (tariff) => {
    setSelectedItem(tariff);
    setDialogOpen(true);
  };

  const handleRowDelete = (tariff) => {
    setSelectedItem(tariff);
    setConfirmDialogOpen(true);
  };

  const handleRowDeleteConfirm = () => {
    setConfirmDialogOpen(false);

    // hard delete
    // existing chargable items are not affected as they contain a copy of the tariff
    const newTariffs = tariffBook.tariffs.filter(el => {
      if(el.id !== selectedItem.id) return true;
      return false;
    });
    updateTariffBook.updateTariffs(tariffBook.bookId, newTariffs);

    // remove any default chargeable items related to the tariff
    const defaultChargesPostDeletion = tariffBook.defaultCharges.map(charge => {
      return {
        ...charge,
        defaultItems: charge.defaultItems.filter(item => item.tariffId !== selectedItem.id)
      }
    });

    // Remove default charges of the where the number of item is zero
    const defaultChargesClean = defaultChargesPostDeletion.filter(charge => charge.defaultItems.length !== 0);

    updateTariffBook.updateDefaultCharges(defaultChargesClean);

    onChanged && onChanged({
      tariffs: newTariffs,
      defaultCharges: defaultChargesClean
    });
  };

  useEffect(() => {
    setAllItems(tariffBook.tariffs
      .map(el => {
        const unit = tariffUnits.find(un => un.id === el.unitId); 
        return {
          data: {
            ...el,
          },
          currency: appCurrencySetting,
          unit: unit ? getFullTarrifUnit(unit?.name, unit?.secondaryUnitName) : undefined,
          onUpdate: (tariff) => handleRowUpdate(tariff),
          onDelete: (tariff) => handleRowDelete(tariff)
        }
      })
      .sort(sortTariffs)
    )
  }, [tariffBook.tariffs, appCurrencySetting, tariffUnits])

  useEffect(() => {
    if(!filter) setFilteredItems([]);
    else setFilteredItems(allItems.filter(el => el.data.cost.code.toLowerCase().includes(filter.toLowerCase())));
  }, [filter, allItems]);

  useEffect(() => {
    // If an item was added then scroll the tariff list so that it is visible
    if(!lastAddedItem) return; 
    const index = filteredItems.length ?
      filteredItems.findIndex((el) => el.data.id === lastAddedItem.id) :
      allItems.findIndex((el) => el.data.id === lastAddedItem.id);
    if(index >= 0) listRef.current.scrollToItem(index, 'center');
  }, [lastAddedItem, allItems, filteredItems]);

  // console.log('CTX', tariffBook);
  // console.log('CTXY', updateTariffBook);

  return (
    <>
      <Box display="flex" flexDirection="column" flexGrow="1">
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-end'
          paddingTop="16px"
          paddingBottom="16px"
        >
          <TextField
            id="TariffBookFilterInput"
            label={t('AdminTariff.Labels.FilterByCode')}
            value={filter}
            onChange={handleFilterChanged}
            autoComplete="off"
          />
          <Button
            id="TariffBookAddButton"
            variant="outlined" 
            color="primary"
            // disabled={true}
            onClick={handleAddTariff}
          >
            {t('AdminTariff.Buttons.AddTariff')}
          </Button>
        </Box>
        <Box className={classes.columHeaders} display='flex'>
          <Box className={classes.tableHeader} style={{ width: "30%" }}><Typography className={classes.tableHeaderText}>{t('Tariff.Labels.Code')}</Typography></Box>
          <Box className={classes.tableHeader} style={{ width: "50%" }}><Typography className={classes.tableHeaderText}>{t('Tariff.Labels.Description')}</Typography></Box>
          <Box className={classes.tableHeader} style={{ width: "20%" }}><Typography className={classes.tableHeaderText}>{t('Tariff.Labels.UnitCost')}</Typography></Box>
          <Box className={classes.columnIcon}/>
          <Box className={classes.columnIcon}/>
        </Box>
        <Box id='TariffBookListEditor' flexGrow="1">
          <AutoSizer>
            {({ height, width }) => (     
              <FixedSizeList
                key={'TariffList'}
                itemCount={filter ? filteredItems.length : allItems.length}
                height={height}
                width={width}
                itemSize={60}
                itemData={filter ? filteredItems : allItems }
                ref={listRef}
              >
                {TariffListEditorRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      </Box>
      { dialogOpen &&
        <TariffDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onAdd={handleDialogAdd}
          onUpdate={handleDialogUpdate}
          validateCode={validiateTariffCode}
          tariff={selectedItem}
        />
      }
      { confirmDialogOpen &&
        <ConfirmationDialog
          open={confirmDialogOpen}
          title={t('AdminTariff.Labels.DeleteTariffTitle')}
          message={t('AdminTariff.Labels.DeleteTariffItemMessage', { tariffName: selectedItem.cost.code })}
          confirmLabel={t('Common.Buttons.Confirm')}
          handleConfirm={handleRowDeleteConfirm}
          handleCancel={() => setConfirmDialogOpen(false)}
        />
      }
    </>
  );
};

export default TariffListEditor;
