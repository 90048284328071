import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Grid,
  Tooltip
} from '@material-ui/core';

import Logo from '../components/Logo';
import CurrentConditions from './CurrentConditions';
import { clientName } from '../environment';
import User from '../components/User/User';
import LogoWithVersion from '../components/LogoWithVersion';
import useFeatureSetting from '../hooks/useFeatureSetting';
import FeatureFlags from '../constants/FeatureFlags';
import { UIContext } from '../contexts/ui';
import Clock from '../components/Clock';
import { useTranslation } from 'react-i18next';
import { tidesEnabled, weatherEnabled } from '../environment';

const useStyles = makeStyles(() => ({
  toolbar: {
    height: '4.5rem'
  },
  toolbarAlt: {
    height: '4.5rem',
    boxSizing: 'border-box',
    borderBottom: "0.0625rem solid #e0e0e0"
  },
  logo: {
    paddingRight: '1rem'
  },
  logoAlt: {
    paddingRight: '1rem',
    filter: "drop-shadow(0px 0px 0px #000)"
  },
  branding: {
    fontWeight: 300
  },
  divider: {
    width: '1px',
    backgroundColor: 'rgba(255,255,255,0.2)',
    height: '3rem',
    margin: '0rem 1rem'
  }
}));

const AppHeader = function ({disableCurrentConditions}) {
  const classes = useStyles();
  const [{ agentPortal },] = useContext(UIContext);
  const agentAppStyle = useFeatureSetting(FeatureFlags.AGENT_APP_STYLE);
  const useAltStyle = agentAppStyle && agentPortal;
  const { t } = useTranslation();
  return (
    <AppBar position="static" elevation={0} color={useAltStyle ? "default" : "primary"}>
      <Toolbar className={useAltStyle ? classes.toolbarAlt : classes.toolbar}>
        <Box display="flex" flexShrink={0} alignItems="center">
          <Logo className={useAltStyle ? classes.logoAlt : classes.logo} variant="client" />
          <Typography variant="h4" className={classes.branding}>{clientName}</Typography>

          {!tidesEnabled && !weatherEnabled &&
            <Tooltip title={t('AppHeader.Labels.CurrentDateAndTime')}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingLeft="2rem">
                <Clock />
              </Box>
            </Tooltip>
          }

        </Box>
        { !disableCurrentConditions ? <CurrentConditions showClock={tidesEnabled || weatherEnabled} /> : <Grid container alignItems="center" justify="center" spacing={2} /> }
        
        <User />
        <div className={classes.divider} />
        <LogoWithVersion variant={useAltStyle ? "defaultNoSub" : "white"} />
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
