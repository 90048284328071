import React from 'react';
import { ExportFieldGroups } from './export-fields';
import {
  Box,
  Typography, Accordion, AccordionSummary, AccordionDetails
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { ChevronDown } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import ExportFieldGroup from './ExportFieldGroup';

const useStyles = makeStyles(theme => ({
  box: {
    marginBottom: "12px",
    height: '70%',
    overflow: 'hidden auto',
  },
  summary: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const ExportFieldSelection = ({settings, onChange}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const fieldGroup = (group, expanded, onExpandedChange) => 
   (
      <Accordion key={group.id} expanded={expanded} onChange={(id, ex) => onExpandedChange(group?.id, ex)}>
        <AccordionSummary
          id={group.id}
          expandIcon={<ChevronDown />}
          className={classes.summary}
        >
          <Typography>{t(group.name)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExportFieldGroup group={group} settings={settings} onChange={onChange} />
        </AccordionDetails>
      </Accordion>
    )

  const [expanded, setExpanded] = React.useState(ExportFieldGroups[0]?.id);

  return (
    <Box className={classes.box}>
      { ExportFieldGroups.map((group) => fieldGroup(group, expanded === group?.id, (id, isExpanded) => setExpanded(id))) }
    </Box>
  );
};

export default ExportFieldSelection;