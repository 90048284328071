import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  FormControl,
  TextField,
  makeStyles,
  IconButton,
  Box,
  InputAdornment,
  InputBase,
  Tooltip,
  List,
  ListItemText,
  debounce
} from '@material-ui/core';
import {
  DeleteOutline,
  Replay,
  Close,
} from 'mdi-material-ui';
import { ChargeableItem, TariffData } from "../../../models";
import { v4 as uuidv4 } from 'uuid';
import {
  getTariffUnitCost,
  renderTariffUnitType,
  isNumber,
  numberInputString,
  calculateTotal
} from "../../../utils/chargeableItems";
import PopoverTooltip from '../../Tooltip/PopoverTooltip';
import TariffUnitCostTooltip from '../../Tooltip/TariffUnitCostTooltip';
import CurrencyFormat from '../../Common/CurrencyFormat';
import { getFullTarrifUnit } from "../../../utils/getters";
import {
  ChargeableItemColors
} from "../../../constants/ChargeableItemConstants";
import {
  renderTariffPopover,
  tariffPopoverCheck
} from "../view/ChargeablesRowView";
import TariffAutocomplete from "../TariffAutocomplete";
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { DataStoreContext } from "../../../contexts/dataStoreContext";
import useFeatureSetting from "../../../hooks/useFeatureSetting";
import FeatureFlags from "../../../constants/FeatureFlags";
import InvalidTariffWarning from "../InvalidTariffWarning";
import useChargeableItemValidation from "../../../hooks/useChargeableItemValidation";

// select - Styles for Select
const useStyles = makeStyles({
  root: {
    paddingTop: '1.2rem',
    verticalAlign: 'top'
  },
  select: {
    width: '100%',
  },
  textField: {
    width: '100%'
  },
  input: {
    textAlign: 'right',
    color: 'red'
  },
  unitCost: {
  },
  unitCostInput: {
    width: '13rem',
  },
  unitCostReset: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  errorCaption: {
    color: ChargeableItemColors.error
  },
  cssLabel: {
    color: ChargeableItemColors.override,
  },
  notchedOutline: {
    borderColor: `${ChargeableItemColors.override} !important`,
  },
  quantity: {
    display: 'flex',
    alignItems: "start",
    justifyContent: "space-between",
    width: "100%"
  },
  total: {
    verticalAlign: 'inherit',
    paddingBottom: '1.2rem'
  },
  _delete: {
    verticalAlign: 'inherit',
    paddingBottom: '1.2rem'
  }
});

// Check for wheter the chargeable item is valid or not
const checkChargeableItem = (cI) => (!cI.tariffData.code);

// Empty Tariff Data Model for new ChargeableItem
const initialTariffData = new TariffData({
  code: "",
  description: "",
  unitCost: null,
  minimumCost: null,
  notes: "",
  actionTypes: [""],
})

// Empty Chargeable Item for new ChargeableItem
const initialChargeableItem = new ChargeableItem({
  id: "",
  tariffData: initialTariffData,
  tariffUnit: "",
  overrideUnitCost: null,
  notes: "",
  quantity: 0,
  total: 0
})

// chargeableItem({}) - The chargeable item data
// saveChargeableItem(func) - Function for saving chargeable Item
// deleteChargeableItem(func) - Function for deleting chargeable Item
// tariffs([]) - Array for the tariffs for Tariffs Select
// disableUnitCost - Disable editing of unit cost
const ChargeablesRowEdit = ({
  chargeableItem: initialChargeableItemData,
  saveChargeableItem,
  deleteChargeableItem,
  tariffs,
  currencySymbol,
  invalidateChargeableItem,
  disableUnitCost,
  actionTime
}) => {

  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  console.log(initialChargeableItemData)

  const classes = useStyles();
  const { t } = useTranslation();

  // Array of just the tariff data
  const tariffsData = tariffs.map(t => { return { ...t.cost, id: t.id } });

  // State to store whether the chargeable item has changed
  // Will be used for debounce in future
  const [changed, setChanged] = useState(false);

  // Will update the chargeable item once the changes have finished
  const debounceChange = useCallback(debounce(() => setChanged(true), 800), [])

  // Will set the secondary mult to int value if tariffSecondaryUnit exists
  const getSecondaryMult = (initialChargeableItemData) => {
    if (initialChargeableItemData.tariffSecondaryUnit) {
      if (initialChargeableItemData.secondaryMult > 0) {
        return initialChargeableItemData.secondaryMult;
      } else return 0;
    }
  }

  // State of the chargeable item object being editted
  const [chargeableItem, setChargeableItem] = useState(
    initialChargeableItemData ?
      {
        ...initialChargeableItemData,
        secondaryMult: getSecondaryMult(initialChargeableItemData)
      } : { ...initialChargeableItem, id: uuidv4() });

  // Deconstructing chargeable item
  const {
    id,
    tariffData: {
      code,
      description,
      unitCost,
    },
    tariffData,
    tariffUnit,
    tariffSecondaryUnit,
    overrideUnitCost,
    notes,
    quantity,
    secondaryMult,
    total,
  } = chargeableItem;

  // If the chargeable item is valid and has changed
  // The chargeable item is saved
  useEffect(() => {
    if (checkChargeableItem(chargeableItem)) {
      invalidateChargeableItem(id);
    } else {
      if (!changed) return;
      setChanged(false);
      saveChargeableItem(chargeableItem, id)
    }
  }, [chargeableItem, changed])

  // Value from target is the tariffCode
  // Find the corresponding tariff from the tariffCode
  // Set the chargeable item with the new tariff Data
  // Calculate the new total
  const handleTariffChange = ({ code: newTariffCode }) => {
    if (!newTariffCode) return;
    setChanged(true);

    const tariff = tariffs.find(({ cost: { code } }) => code === newTariffCode);
    const newTotal = calculateTotal({ tariffData, unitCost: tariff.cost.unitCost, quantity, secondaryMult });
    setChargeableItem(
      {
        ...chargeableItem,
        tariffData: tariff.cost,
        tariffUnit: tariff?.unit?.name ?? '',
        tariffSecondaryUnit: tariff?.unit?.secondaryUnitName ?? '',
        tariffId: tariff.id,
        tariffBookId: tariff.bookId,
        overrideUnitCost: null,
        total: newTotal,
      })
  }

  // Value from target is the notes
  // Set the changeable item with the new notes
  const handleNotesChange = (e) => {
    debounceChange();
    setChargeableItem(
      {
        ...chargeableItem,
        notes: e.target.value
      }
    );
  }

  // Unit cost is taken form input
  // Total is calculated from unit cost
  // If the unit cost value is the same as the tariff data the override is set to null
  const handleUnitCostChange = (e) => {
    setChanged(true);
    const newUnitCost = numberInputString(e.target.value);
    const newTotal = calculateTotal({ tariffData, quantity, secondaryMult, unitCost: newUnitCost });
    setChargeableItem({
      ...chargeableItem,
      overrideUnitCost: newUnitCost !== unitCost
        ? newUnitCost
        : null,
      total: newTotal
    })
  }

  // Value from target is the quantity
  // Set the changeable item with the new notes
  // Total calculated
  const handleQuantityChange = (e) => {
    setChanged(true);
    const newQuantity = numberInputString(e.target.value);
    if (Number(newQuantity) < 0) return;
    const newTotal = calculateTotal({ tariffData, quantity: newQuantity, secondaryMult, unitCost: getTariffUnitCost(chargeableItem) });
    setChargeableItem(
      {
        ...chargeableItem,
        quantity: newQuantity,
        total: newTotal
      }
    )
  }

  const handleSecondaryMultChange = (e) => {
    setChanged(true);
    const newSecondaryMult = numberInputString(e.target.value);
    if (Number(newSecondaryMult) < 0) return;
    const newTotal = calculateTotal({ tariffData, quantity: chargeableItem.quantity, secondaryMult: newSecondaryMult, unitCost: getTariffUnitCost(chargeableItem) });
    setChargeableItem(
      {
        ...chargeableItem,
        secondaryMult: newSecondaryMult,
        total: newTotal
      }
    )
  }

  // Calculated the total from the original unit cost and set the override to null
  const handleResetUnitCost = () => {
    setChanged(true);
    const newTotal = calculateTotal({ tariffData, unitCost, quantity, secondaryMult });
    setChargeableItem(
      {
        ...chargeableItem,
        overrideUnitCost: null,
        total: newTotal
      }
    )
  }

  // Reset the tariff information in the chargeable item
  const handleResetTariff = () => {
    setChargeableItem(
      {
        ...chargeableItem,
        overrideUnitCost: null,
        total: 0.00,
        tariffData: initialTariffData,
        quantity: 0,
        secondaryMult: null,
        tariffUnit: null,
        tariffSecondaryUnit: null
      })
  }

  // Renders the Popover for the Unit Cost tooltip with the reset method
  const renderTariffUnitCostPopover = (children) =>
    <Box>
      <PopoverTooltip
        delay={0}
        tooltip={
          <TariffUnitCostTooltip
            originalUnitCost={tariffData.unitCost}
            currencySymbol={currencySymbol}
            handleResetUnitCost={handleResetUnitCost}
          />
        }
      >
        {children}
      </PopoverTooltip>
    </Box>

  // Props for the unit cost input
  // inputComponent - Currency Format so that input is right aligned
  // startAdornment - Currecny is determined by £
  const unitCostInputProps = {
    inputComponent: CurrencyFormat,
    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
  }

  const dataStoreContext = useContext(DataStoreContext);
  let tariffBooks = [];

  // FIXME when tariff book fflag is removed, remove if below and initialise tariff vars
  if (isTariffBookEnabled && dataStoreContext) {
    tariffBooks = dataStoreContext.tariffBooks;
  }

  const { validateChargeableItem } = useChargeableItemValidation();
  const validTariff = validateChargeableItem(chargeableItem, actionTime)

  return <>
    {/* Tariff */}
    <TableCell
      classes={{
        root: classes.root
      }}
    >
      {
        code ?
          <Box
            display="flex"
            justifyContent="space-between"
          >
            {/* Show the tariff if there is a code */}
            <List dense disablePadding>
              <ListItemText
                primary={code}
                secondary={description}
              />
            </List>
            <Box
              display="flex"
              alignItems="center"
            >
              {tariffPopoverCheck(tariffData) && renderTariffPopover(tariffData, currencySymbol)}
              {validTariff?.error > 0 &&
                <Box 
                style={{ marginRight: '0.5em'}}
                id="InvalidTariffEditor">
                  <InvalidTariffWarning
                    tooltip={t(validTariff.errorMessageT)}
                  />
                </Box>}
              <Tooltip
                title={t('ChargeableItems.Labels.ResetTariff')}
              >
                <IconButton
                  onClick={() => handleResetTariff()}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          :
          <FormControl
            className={classes.select}
          >
            {/* Tariff Auto Complete to allow the tariff to be selected */}
            <TariffAutocomplete
              id={'ChargeableRowEditTariffInput'}
              tariffs={tariffsData}
              value={code ? tariffData : null}
              onChange={handleTariffChange}
            />
          </FormControl>
      }
    </TableCell>
    {/* Notes  */}
    <TableCell
      classes={{
        root: classes.root
      }}
    >
      <TextField
        value={notes}
        onChange={handleNotesChange}
        className={classes.textField}
        variant="outlined"
        fullWidth
        name="chargeableItemNotes"
      />
    </TableCell>
    {/* Unit Cost */}
    <TableCell
      classes={{
        root: classes.root
      }}
      className={classes.unitCost}
    >
      <Box
        display="flex"
        alignItems="start"
      >
        {/* Entry for the the unit cost */}
        <TextField
          type="number"
          variant="outlined"
          disabled={disableUnitCost}
          value={getTariffUnitCost(chargeableItem)}
          onChange={handleUnitCostChange}
          InputProps={
            isNumber(overrideUnitCost) ?
              {
                ...unitCostInputProps,
                readOnly: disableUnitCost,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }
              : {
                ...unitCostInputProps,
                readOnly: disableUnitCost
              }
          }
          helperText={renderTariffUnitType(getFullTarrifUnit(tariffUnit, tariffSecondaryUnit))}
          FormHelperTextProps={{ style: { textAlign: 'right' } }}
          name="chargeableItemUnitCost"
        />
        {/* Popover for reset of unit cost */}
        <Box
          paddingLeft={1}
          paddingTop={0.5}
          width="50px"
        >
          {isNumber(overrideUnitCost) &&
            renderTariffUnitCostPopover(
              <IconButton
                onClick={() => handleResetUnitCost()}
              >
                <Replay />
              </IconButton>
            )
          }
        </Box>
      </Box>
    </TableCell>
    {/* Quantity */}
    <TableCell
      classes={{
        root: classes.root
      }}
    >
      <Box className={classes.quantity}>
        <TextField
          type="number"
          variant="outlined"
          inputProps={{ style: { textAlign: 'right' } }}
          value={isNumber(quantity) ? quantity : ''}
          onChange={handleQuantityChange}
          helperText={tariffUnit}
          FormHelperTextProps={{ style: { textAlign: 'right' } }}
          name="chargeableItemQuantity"
        />
        {(tariffSecondaryUnit || secondaryMult) && <>
          <TextField
            type="number"
            variant="outlined"
            inputProps={{ style: { textAlign: 'right' } }}
            value={isNumber(secondaryMult) ? secondaryMult : '0'}
            onChange={handleSecondaryMultChange}
            style={{ marginLeft: '0.4rem' }}
            helperText={tariffSecondaryUnit}
            FormHelperTextProps={{ style: { textAlign: 'right' } }}
            name="chargeableItemSecondaryMult"
          />
        </>
        }
      </Box>
    </TableCell>
    {/* Total */}
    <TableCell
      classes={{
        root: classes.root
      }}
      className={classes.total}
    >
      <InputBase
        type="number"
        variant="standard"
        value={total}
        inputComponent={CurrencyFormat}
        inputProps={{ readOnly: true }}
        startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
      />
    </TableCell>
    {/* Delete */}
    <TableCell
      classes={{
        root: classes.root
      }}
      className={classes._delete}
    >
      <Tooltip
        title={t('ChargeableItems.Labels.Delete')}
      >
        <IconButton
          onClick={() => deleteChargeableItem(id)}
          edge="end"
          id={`DeleteChargeableItem-${id}`}
        >
          <DeleteOutline />
        </IconButton>
      </Tooltip>
    </TableCell>
  </>;
}

ChargeablesRowEdit.propTypes = {
  chargeableItem: PropTypes.object,
  saveChargeableItem: PropTypes.func,
  deleteChargeableItem: PropTypes.func,
  invalidateChargeableItem: PropTypes.func,
  tariffs: PropTypes.array,
  currencySymbol: PropTypes.string
};

// Default Props for Chargeable Row
ChargeablesRowEdit.defaultProps = {
  chargeableItem: null,
  saveChargeableItem: null,
  deleteChargeableItem: null,
  invalidateChargeableItem: null,
  tariffs: [],
  currencySymbol: "",
}

export default ChargeablesRowEdit;