import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import {
  Fullscreen,
  FullscreenExit,
} from 'mdi-material-ui';
import { Icon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  NavigationContext,
  navigationActionConstants,
} from '../contexts/navigation';
import { useTranslation } from 'react-i18next';
import useUserSettings from '../hooks/useUserSettings';
import debounce from 'lodash.debounce';

/**
 *  ToggleFullScreenRoute is used to switch 
 *  between a full screen and the index route.
 * 
 *  Only used in the list view headers it also
 *  needs to dispatch changes to teh navigation 
 *  context so that component knows what the 
 *  destination of the lists icon is.
 * 
 *  Renders either a full screen or exit full 
 *  screen icon depending on which route is active
 *  
 */
const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    height: '1.5rem',
  }
}));

export const ToggleFullScreenRoute = ({
  route,
  location,
}) => {
  const { t } = useTranslation();
  const [, dispatchNavigation] = useContext(NavigationContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { userSetting, updateUserSettings } = useUserSettings();
  const validModel = !Array.isArray(userSetting);

  const isFullScreen = location.pathname === route.split('?')[0];
  const destination = isFullScreen ? `/${location.search}` : route;

  const [destinationState, setDestinationState] = useState('');

  const debounceChange = useCallback(debounce((destination) => 
  setDestinationState(`${destination.split('?')[0]}`), 600), []);

  const handleChange = (destination) => {
    debounceChange(destination);
  };

  useEffect(() => {
    if (validModel && destinationState && userSetting && 
      (destinationState !== userSetting.fullScreen)) {
      updateUserSettings('fullScreen', destinationState);
    } else if (!userSetting) {
      updateUserSettings('fullScreen', destinationState);
    }
  }, [destinationState]);

  const fullScreenId = route.split('?')[0].split('/')[2]

  return (
    <Link
      className={classes.link}
      onClick={() => {
        dispatchNavigation({
          type: navigationActionConstants.SET_DEFAULT_VIEW,
          payload: destination,
        });
        setOpen(false);
        handleChange(destination);
      }}
      to={destination}
    >
      <Tooltip title={t('ToggleFullscreen.Labels.ToggleFullscreen')} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <Icon color="inherit">
        {!isFullScreen ? (
          <Fullscreen color="inherit"
           id={`FullScreen-${fullScreenId}`} 
           />
        ) : (
            <FullscreenExit color="inherit"
               id={`FullScreenExit-${fullScreenId}`}
            />
          )}
      </Icon>
      </Tooltip>
    </Link>
  );
};

/**
 *  Default export is withRouter which makes
 *  history, location, and match available
 *  as injected props.
 */
export default withRouter(ToggleFullScreenRoute);
