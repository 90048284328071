import React, { useState, useEffect, useContext, useMemo } from 'react';
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Typography, ListItem, ListItemSecondaryAction, Button } from "@material-ui/core";
import VesselListItem from '../Vessel/VesselListItem';
import ActionStateAndTimeListItem from '../Action/ActionStateAndTimeListItem';
import PortCallTimelinePortCallMenu from './PortCallTimelinePortCallMenu.js';
import { makeStyles } from '@material-ui/styles';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import VesselTooltip from '../Tooltip/VesselTooltip';
import { Email, Export } from 'mdi-material-ui';
import { PortCallStatusLabelsKeys } from '../../constants/PortCallStatus';
import { NavigationContext, navigationActionConstants } from '../../contexts/navigation';
import { UIContext } from '../../contexts/ui';
import RequestTooltip from '../Tooltip/RequestTooltip';
import { itemRequested, RequestColor } from '../../constants/RequestConstants';
import { itemApprovalPending, itemApprovalDeclined } from '../../constants/ApprovalConstants';
import { PortCall, Action, ActionMovementType, PortCallStatus, Vessel, RequestType, PortCallTag, ActionState, Category, Location } from '../../models';
import { DataStore } from 'aws-amplify';
import Loading from '../Loading';
import ExportPortCallsDialog from './Export/ExportPortCallsDialog';
import ExportPortCallsDialogDS from './Export/ExportPortCallsDialogDS';
import { getActionArrival, getActionDeparture, isAgentAssociatedWithPortCall, getPendingRequests, getActionTime } from '../../utils/getters';
import useQuery from '../../hooks/useQuery';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import RouteConstants from '../../constants/RouteConstants';
import DataBrowserSidebar, { DataBrowserPortCallStatusFilter, DataBrowserPortCallSortDirectionFilter, DataBrowserLocationFilter, DataBrowserVesselFilter, selectablePortCallStatuses, DataBrowserDateFilter, DataBrowserCategoryFilter, DataBrowserTagsFilter, isUncategorizedCategory, getUncategorizedCategory } from '../DataBrowserSidebar/DataBrowserSidebar';
import useDataBrowserFilter from '../DataBrowserSidebar/useDataBrowserFilter';
import { SORT_DIRECTION } from '../../utils/sorters';
import FeatureFlags from '../../constants/FeatureFlags';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';

const useStyles = makeStyles(theme => ({
  item: {
    '&:hover': {
      background: theme.palette.action.hover,
      cursor: 'pointer',
    },
    borderBottom: "0.0625rem solid #eee",
    boxSizing: "border-box"
  },
  itemCancelled: {
    '& > *': {
      opacity: 0.5
    }
  },
  itemRequested,
  itemApprovalPending,
  itemApprovalDeclined
}));


const agentFilterOptions = {
  key: `smartport::preferences::PortCallList`,
  initialValue: {
    location: null,
    from: null,
    to: null,
    vessel: null,
    sortDirection: SORT_DIRECTION.DESC,
    status: selectablePortCallStatuses
  },
  hydrateFunctions: {
    location: async i => await DataStore.query(Location, i.id),
    from: i => new Date(i),
    to: i => new Date(i),
    vessel: async i => await DataStore.query(Vessel, i.id),
    sortDirection: i => i,
    status: i => i,
  }
}

const filterOptions = {
  ...agentFilterOptions,
  initialValue: {
    ...agentFilterOptions.initialValue,
    tags: [],
    category: null,
  },
  hydrateFunctions: {
    ...agentFilterOptions.hydrateFunctions,
    tags: async i => i?.length > 0 ? await DataStore.query(PortCallTag, c => c.or(c => i.reduce((acc, cur) => acc.id("eq", cur.id), c))) : [],
    category: async i => isUncategorizedCategory(i) ? getUncategorizedCategory() : await DataStore.query(Category, i.id),
  }
}



const Sidebar = ({ filter, setFilter, items }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const [showExport, setShowExport] = useState(false);
  const isPortCallExportEnabled = useFeatureSetting(FeatureFlags.PORTCALL_EXPORT);
  return (
    <DataBrowserSidebar
      label={t('PortCallList.Labels.PortCalls')}
      exportButton={
        <Button id="OpenExportPortCallsButton" onClick={() => setShowExport(true)} endIcon={<Export fontSize="small" />} disabled={!items.length} disableRipple>
          <Typography variant="caption" style={{ lineHeight: "initial" }}>{t("Common.Buttons.Export")}</Typography>
        </Button>
      }
    >
      <DataBrowserPortCallStatusFilter id="portcalllist-filter-status" filter={filter} setFilter={setFilter} />
      <DataBrowserPortCallSortDirectionFilter id="portcalllist-filter-sortdirection" filter={filter} setFilter={setFilter} />
      <DataBrowserLocationFilter id="portcalllist-filter-location" filter={filter} setFilter={setFilter} />
      <DataBrowserDateFilter id="portcalllist-filter-from" name="from" label={t('PortCallList.Labels.From')} filter={filter} setFilter={setFilter} />
      <DataBrowserDateFilter id="portcalllist-filter-to" name="to" label={t('PortCallList.Labels.To')} filter={filter} setFilter={setFilter} isEndOfDay />
      <DataBrowserVesselFilter id="portcalllist-filter-vessel" filter={filter} setFilter={setFilter} />
      {!agentPortal && <>
        <DataBrowserCategoryFilter id="portcalllist-filter-category" filter={filter} setFilter={setFilter} />
        <DataBrowserTagsFilter id="portcalllist-filter-tags" filter={filter} setFilter={setFilter} />
      </>}
      {isPortCallExportEnabled ? 
      <ExportPortCallsDialog open={showExport} onClose={() => setShowExport(false)} portCalls={items} filter={filter} />
      : <ExportPortCallsDialogDS open={showExport} onClose={() => setShowExport(false)} portCalls={items} filter={filter} /> }
    </DataBrowserSidebar>
  );
};

const PortCallList = ({ contactFilter }) => {
  const [{ agentPortal, userContactId }] = useContext(UIContext);
  const [filter, setFilter] = useDataBrowserFilter(agentPortal ? agentFilterOptions : filterOptions);
  const [, dispatchNavigationContext] = useContext(NavigationContext);
  const { locations } = useContext(DataStoreContext);
  
  const locationsMap = new Map();
  locations.forEach(el => locationsMap.set(el.id, el));

  // TODO check conditions, seems to be returning wrong stuff
  const portCalls = useQuery(PortCall, {
    condition: c => c.status("ne", PortCallStatus.DELETED),
    filter: i => i.status !== PortCallStatus.DELETED
  });
  const actions_ = useQuery(Action, {
    condition: c => c
      .state("ne", ActionState.DELETED)
      .or(c => c
        .movementType("eq", ActionMovementType.ARRIVAL)
        .movementType("eq", ActionMovementType.DEPARTURE)),
    filter: i =>
      i.state !== ActionState.DELETED && (
        i.movementType === ActionMovementType.ARRIVAL ||
        i.movementType === ActionMovementType.DEPARTURE)
  });

  const actions = actions_.map(action => {
    return Action.copyOf(action, updated => {          
      updated.movementLocation = action.actionMovementLocationId ? 
        locationsMap.get(action.actionMovementLocationId) : 
        null
    });
  });

  const { requests } = useContext(DataStoreContext);
  const items = useMemo(() =>
    (portCalls && actions) ? portCalls
      .map(p => PortCall.copyOf(p, updated => {
        updated.actions = actions.filter(a => a.portCall?.id && a.portCall.id === p.id);
        updated.requests = getPendingRequests(p, requests, userContactId);
        // add custom field for port call sorting
        const arrival = getActionArrival(updated.actions);
        updated._sort = arrival ? getActionTime(arrival) || arrival.createdAt : updated.createdAt;
      })) : []
    [portCalls, actions, requests, userContactId]);

  const itemsFiltered = useMemo(() => items
    .filter(p => {
      if (filter.status && !filter.status.includes(p.status)) return false;
      const arrival = getActionArrival(p.actions);
      if (contactFilter && !isAgentAssociatedWithPortCall(p, contactFilter)) return false;
      if (filter.from && (!arrival || arrival.timePlanned < filter.from.toISOString())) return false;
      if (filter.to && (!arrival || arrival.timePlanned > filter.to.toISOString())) return false;
      if (filter.vessel && (!p.portCallVesselId_ || p.portCallVesselId_ !== filter.vessel.id)) return false;
      if (filter.category && (isUncategorizedCategory(filter.category)
        ? p.category
        : (!p.category || p.category.id !== filter.category.id)
      )) return false;
      if (filter.location && !p.actions?.some(a => a.movementLocation?.portUnlocode === filter.location.portUnlocode)) return false;
      if (filter.tags?.length && (!p.portCallTags || !p.portCallTags.some(t => filter.tags.some(ft => t === ft.name)))) return false;
      return true;
    })
    .sort((a, b) => filter.sortDirection === SORT_DIRECTION.ASC ? (a._sort > b._sort ? 1 : -1) : (a._sort > b._sort ? -1 : 1)),
    [items, filter, contactFilter]);

  // set last main view
  useEffect(() => {
    dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: RouteConstants.PORTCALL_LIST });
  }, [dispatchNavigationContext]);
  return (
    <Box id={"PortCallList"} display="flex" width='100%'>
      <Sidebar filter={filter} setFilter={setFilter} items={itemsFiltered} />
      {itemsFiltered ? <ListInternal items={itemsFiltered} /> : <Loading />}
    </Box >
  );
};

const Item = ({ item, style, classes }) => {
  const { t } = useTranslation();
  const arrival = getActionArrival(item.actions);
  const departure = getActionDeparture(item.actions);
  const portCallRequest = item.requests.find(r => r.type === RequestType.REQUEST_TYPE_CREATE_PORTCALL || r.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL);
  const arrivalRequest = !portCallRequest && arrival && item.requests.find(r => r.actionData && r.actionData.some(ad => ad.actionId === arrival.id));
  const departureRequest = !portCallRequest && departure && item.requests.find(r => r.actionData && r.actionData.some(ad => ad.actionId === departure.id));
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const approvalClass =
    arrival && (arrival.state === ActionState.APPROVAL_PENDING ? classes.itemApprovalPending : arrival.state === ActionState.APPROVAL_DECLINED ? classes.itemApprovalDeclined : null) ||
    departure && (departure.state === ActionState.APPROVAL_PENDING ? classes.itemApprovalPending : departure.state === ActionState.APPROVAL_DECLINED ? classes.itemApprovalDeclined : null);
  return (
    <div className={`${classes.item} ${item.status === PortCallStatus.CANCELLED ? classes.itemCancelled : ''}`} style={{ ...style, display: 'flex', justifyContent: 'space-betwen', alignItems: 'center' }}>
      <ListItem style={{ width: '25%' }}>
        <Typography style={{ paddingRight: '.5rem' }} noWrap>{item.referenceId}</Typography>
        {portCallRequest && <PopoverTooltip tooltip={<RequestTooltip portCall={item} request={portCallRequest} />} style={{ width: 'auto' }}>
          <Email style={{ color: RequestColor.color }} />
        </PopoverTooltip>}
      </ListItem>
      <ListItem style={{ width: '25%' }}>
        <Typography>{t(PortCallStatusLabelsKeys[item.status])}</Typography>
      </ListItem>
      <PopoverTooltip tooltip={<VesselTooltip vesselData={item.vesselData} portCall={item} setMapDialogOpen={setMapDialogOpen}/>}>
        <VesselListItem
          disableGutters={true}
          vesselData={item.vesselData}
          disabled={!item.portCallVesselId}
        />
      </PopoverTooltip>
      {arrival
        ? <ActionStateAndTimeListItem action={{ ...arrival, portCall: item }} request={arrivalRequest} style={{ width: '50%' }} />
        : <Typography style={{ width: '50%', opacity: 0.5 }}>{t('PortCallList.Labels.ArrivalNotScheduled')}</Typography>
      }
      {departure
        ? <ActionStateAndTimeListItem action={{ ...departure, portCall: item }} request={departureRequest} style={{ width: '50%' }} />
        : <Typography style={{ width: '50%', opacity: 0.5 }}>{t('PortCallList.Labels.DepartureNotScheduled')}</Typography>
      }
      <ListItemSecondaryAction>
        <PortCallTimelinePortCallMenu
          skipCheck
          portCall={item}
        />
      </ListItemSecondaryAction>
      {item.requests.length ? <div className={classes.itemRequested} /> : approvalClass ? <div className={approvalClass} /> : null}
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={item.vesselData}
        /> 
      } 
    </div>
  );
};

const ListInternal = ({ items }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" flexGrow="1">
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            itemCount={items.length + 1} // extra row to hold reached end message
            height={height}
            itemSize={80}
            width={width}
          >
            {({ index, style }) =>
              (index === items.length) ? (
                <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" style={style}>
                  <Typography variant="caption" align="center">{t('PortCallList.Labels.NoMoreData')}</Typography>
                </Box>
              ) : (
                <Item classes={classes} item={items[index]} style={style} />
              )
            }
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default PortCallList;