import { DataStore } from 'aws-amplify';
import { PortCall, Action, ActionState, Cargo, ActionEventType } from '../../models';
import { sub, isBefore } from 'date-fns';
import { getActionTodosAsync } from '../../utils/getters';
import { ActionTypeIds } from '../../environment';
import { createAllChargeablesItemFromBooks } from '../../utils/tariffBook';

export const createPortCall = async (portCall, location, arrival, departure, agent, cargos, auditMeta, isTariffBookEnabled=false, dataStoreContext, tariffBooks) => {

  let updatedArrival = null;
  let updatedDeparture = null;
  let updatedPortCall = portCall;
  let handOver = null;

  let tariffUnits = [];

  // FIXME when tariff book fflag is removed, remove if below and initialise tariff vars
  if(isTariffBookEnabled && dataStoreContext) {
    tariffUnits = dataStoreContext.tariffUnits;
  }

  if(arrival) {
    const arrivalTodos = await getActionTodosAsync(arrival);
    let chargeableItems = arrival.chargeableItems;
    // FIXME when tariff book fflag is removed, remove if below
    if(isTariffBookEnabled) {
      chargeableItems = createAllChargeablesItemFromBooks(
        arrival.timePlanned,
        arrival.type.id, 
        arrival.movementType, 
        tariffBooks, 
        tariffUnits
      );
    }
    updatedArrival = Action.copyOf(arrival, updated => {
      updated.portCall = portCall;
      updated.actionPortCallId_ = portCall.id;
      updated.todos = arrivalTodos;
      updated.chargeableItems = chargeableItems;
    });
  }
  
  if (departure) {
    const departureTodos = await getActionTodosAsync(departure);
    let chargeableItems = departure.chargeableItems;
    // FIXME when tariff book fflag is removed, remove if below
    if(isTariffBookEnabled) {
      chargeableItems = createAllChargeablesItemFromBooks(
        departure.timePlanned, 
        departure.type.id, 
        departure.movementType, 
        tariffBooks, 
        tariffUnits);
    }
    updatedDeparture = Action.copyOf(departure, updated => {
      updated.portCall = portCall;
      updated.actionPortCallId_ = portCall.id;
      updated.todos = departureTodos;
      if (location) updated.movementLocation = location;
      updated.chargeableItems = chargeableItems;
    });
  }

  // determine agent handover planned time
  const now = new Date();
  const arrivalPlannedTime = new Date(arrival.timePlanned);
  // if the arrival planned time is before creation of port call
  // agent handover planned time is set to 1 second before arrival planned time
  // else it's after and the agent handover planned time is set to current creation time
  const agentHandoverPlannedTime = (isBefore(arrivalPlannedTime, now) 
    ? sub(arrivalPlannedTime, { seconds: 1 }) 
    : now).toISOString();


  if (agent && portCall) {
    // create handover
    handOver = new Action({
      state: ActionState.PLANNED,
      eventType: ActionEventType.AGENT_HANDOVER,
      type: { id: ActionTypeIds.EVENT },
      timePlanned: agentHandoverPlannedTime,
      actionAgent: agent,
      portCall,
      actionPortCallId_: portCall.id,
      auditMeta: auditMeta
    });

    // add agent to portcall
    updatedPortCall = PortCall.copyOf(portCall, updated => {
      updated.agents = [{
        contactId: agent.id,
        time: agentHandoverPlannedTime,
        actionId: handOver.id
      }]
    });
  }

  const retPortCall = await DataStore.save(updatedPortCall);
  let retArrival = null;
  let retDeparture = null;
  let retHandOver = null;
  updatedArrival && (retArrival = await DataStore.save(updatedArrival));
  updatedDeparture && (retDeparture = await DataStore.save(updatedDeparture));
  handOver && (retHandOver = await DataStore.save(handOver));

  for (let cargo of cargos) {
    await DataStore.save(Cargo.copyOf(cargo, updated => { updated.cargoPortCallId = updatedPortCall.id; }));
  }

  return {
    portCall: retPortCall,
    arrival: retArrival,
    departure: retDeparture,
    handOver: retHandOver
  };
};