export const VESSEL_PREVIEW = ({
    "id": "937a2e29-0747-4af7-821b-880dbf8286bf",
    "imo": "8511275",
    "mmsi": "232217000",
    "callSign": "GHXX",
    "vesselData": {
        "name": "OCEAN OBSERVER",
        "imo": "8511275",
        "mmsi": "232217000",
        "image": null,
        "callSign": "GHXX",
        "type": "Research Survey Vessel",
        "portOfRegistry": {
            "name": "Lowestoft",
            "portCode": "LOW",
            "countryCode": "GB",
            "searchString": "Lowestoft LOW GB"
        },
        "flag": "GB",
        "lengthOverall": 80.42,
        "beam": 14.51,
        "draught": 4.765,
        "grossTonnage": 2820,
        "deadWeightTonnage": 1598,
        "netTonnage": 846,
        "yearBuilt": 1987,
        "propellerCount": 2,
        "propulsionType": "PROPULSION_FIXED_PITCH",
        "propellerDirection": null,
        "propulsionPower": 5328,
        "propulsionPowerUnit": "HP",
        "rudderCount": null,
        "rudderType": null,
        "fwdAzimuthCount": null,
        "fwdAzimuthPower": null,
        "fwdAzimuthPowerUnit": "KW",
        "aftAzimuthCount": null,
        "aftAzimuthPower": null,
        "aftAzimuthPowerUnit": "KW",
        "fwdTunnelThrusterCount": 1,
        "fwdTunnelThrusterPower": 668,
        "fwdTunnelThrusterPowerUnit": "KW",
        "aftTunnelThrusterCount": null,
        "aftTunnelThrusterPower": null,
        "aftTunnelThrusterPowerUnit": "KW",
        "certificates": null
    },
    "vesselAgent": null,
    "updatedAt": "2020-10-23T08:01:25.213Z",
    "createdAt": "2020-10-20T10:18:38.308Z",
    "_version": 1,
    "_deleted": null,
    "_lastChangedAt": 1500000000000,
    "vesselVesselAgentId": null
});