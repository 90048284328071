import {
  getActionTime,
  getActionableNext,
  getActionablePrev,
  getActionPrev,
  getActionNext,
  getActionsValidLifecycle
} from './getters'
import {
  ActionMovementType,
  RequestType
} from '../models'
import {
  isBefore,
  isAfter,
  parseISO,
  subMinutes,
  addMinutes,
  isSameDay,
  isSameHour,
  isSameMinute,
} from 'date-fns'

export const CHECKS = {
  BEFORE: "BEFORE",
  AFTER: "AFTER",
  BOTH: "BOTH"
}

const CHECK_MESSAGES = {
  BEFORE: 'Common.Errors.TimeCheckActionBefore',
  AFTER: 'Common.Errors.TimeCheckActionAfter',
  ARRIVAL_CHECK: 'Common.Errors.TimeCheckArrivalCheck',
  DEPARTURE_CHECK: 'Common.Errors.TimeCheckDepartureCheck',
  SHIFT_BEFORE: 'Common.Errors.TimeCheckShiftBefore',
  SHIFT_AFTER: 'Common.Errors.TimeCheckShiftAfter',
  ARRIVAL_REQUEST: 'Common.Errors.TimeCheckArrivalRequest',
  DEPARTURE_REQUEST: 'Common.Errors.TimeCheckDepartureRequest',
  CUSTOM_COMPLETE_AFTER_ACTUAL: 'CustomAction.Errors.TimeCheckCompleteAfterActual'
}

// This function generates an array of objects that are for the TimeInputDialog
// The objects contains information for a checking the inputted time planned by the user
//
// Function takes in the port call actions, the action to check,
// and the type of Check 
export const generateTimePlannedChecks = (actions, action, CHECK) => {
  const checks = [];
  // If CHECK is before or both
  // get the previous actionable action's time
  // if there is a time then push check to check variable
  if(CHECK === CHECKS.BEFORE || CHECK === CHECKS.BOTH) {
    const actionBeforeTime = getActionTime(getActionablePrev(actions, action));
    if(actionBeforeTime) {
      checks.push({
        time: actionBeforeTime, 
        message: CHECK_MESSAGES.BEFORE,
        before: false
      })
    }
  }
  // If CHECK is after or both
  // get the next actionable action's time
  // if there is a time then push check to check variable
  if(CHECK === CHECKS.AFTER || CHECK === CHECKS.BOTH) {
    const actionAfterTime = getActionTime(getActionableNext(actions, action));
    if(actionAfterTime) {
      checks.push({
        time: actionAfterTime, 
        message: CHECK_MESSAGES.AFTER,
        before: true
      })
    }
  }
  return checks
};

// Checks for Arrival Time Planned 
// Return message if actions occurs before other lifecycle actions
export const generateArrivalTimePlannedCheck = (arrivalTimePlanned, actions, action) => {
  if(!arrivalTimePlanned || !actions) return "";

  // get valid lifecycle actions - filter the arrival out
  // filter out actions where the arrival planned time is more than any actions time planned
  const actionsPlannedBefore = getActionsValidLifecycle(actions)
    .filter((action) => !action.movementType || action.movementType !== ActionMovementType.ARRIVAL)
    .filter((action) => arrivalTimePlanned >= getActionTime(action))

  // get next actionable action 
  const nextAction = getActionableNext(actions, action);

  // if there are any found or if there is a next action that has the same hour minute and date then return message
  if((actionsPlannedBefore && actionsPlannedBefore.length !== 0) || 
    (nextAction && isSameDateHourMinute(getActionTime(nextAction), arrivalTimePlanned) 
    && (nextAction.movementType !== 'ARRIVAL' && action.movementType !== 'ARRIVAL'))) 
    return CHECK_MESSAGES.ARRIVAL_CHECK;
  return "";  
}

// Checks for shifts Time Planned
export const generateShiftTimePlannedCheck = (timePlanned, actionID, actions) => {
    const lifecycleActions = getActionsValidLifecycle(actions);
    // find action in the reference
    const action = actions.find(({id}) => actionID === id);
    // checks if the planned time if before the previous actions
    const actionBeforeTime = getActionTime(getActionPrev(lifecycleActions, action));
    if(actionBeforeTime && isBefore(subMinutes(parseISO(timePlanned), 1), parseISO(actionBeforeTime))) {
      return CHECK_MESSAGES.SHIFT_BEFORE
    }
    // checks if the planned time if after the previous actions
    const actionAfterTime = getActionTime(getActionNext(lifecycleActions, action));
    if(actionAfterTime && isAfter(addMinutes(parseISO(timePlanned), 1), parseISO(actionAfterTime))) {
      return CHECK_MESSAGES.SHIFT_AFTER
    }
    return "";
}

// Checks for Departure Time Planned 
// Return message if actions occurs after other lifecycle actions
export const generateDepartureTimePlannedCheck = (departureTimePlanned, actions) => {
  if(!departureTimePlanned || !actions) return "";

  // get valid lifecycle actions - filter the Departure out
  // filter out actions where the departure planned time is less than any actions planned time
  const actionsPlannedAfter = getActionsValidLifecycle(actions)
    .filter((action) => !action.movementType || action.movementType !== ActionMovementType.DEPARTURE)
    .filter((action) => departureTimePlanned <= getActionTime(action))

  // if there are any found return message
  if(actionsPlannedAfter && actionsPlannedAfter.length !== 0) return CHECK_MESSAGES.DEPARTURE_CHECK;
  return ""; 
}

// takes in two different times and compares them down to the minutes
// this is used to make sure that two dates aren't the same on client side despite unseen minutes being set
export const isSameDateHourMinute = (dateA, dateB) => {
  const DateA = parseISO(dateA);
  const DateB = parseISO(dateB);
  return isSameDay(DateA, DateB) && isSameHour(DateA, DateB) && isSameMinute(DateA, DateB) ;
}

// Check that a requested arrival time is not after a requested departure
export const generateArrivalRequestCheck = (requests, timeRequested) => {
  let errors = '';
  let arrivalRequest = requests.filter((request) => 
    request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED &&
    request.actionData &&
    request.actionData[0].timePlanned <=  timeRequested);
  if(arrivalRequest.length) {
    errors = CHECK_MESSAGES.ARRIVAL_REQUEST;
  }
  return errors;
}

// Check that a requested departure time is not before a requested arrival
export const generateDepartureRequestCheck = (requests, timeRequested) => {
  let errors = '';
  let departureRequest = requests.filter((request) => 
    request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED &&
    request.actionData &&
    request.actionData[0].timePlanned >=  timeRequested);
  if(departureRequest.length) {
    errors = CHECK_MESSAGES.DEPARTURE_REQUEST;
  }
  return errors;
}

// Checks that a custom action completion value is after the actual or planned time
export const generateCustomActionCompletedTimeCheck = (action) => {
  const checks = [];
  if(action.timeActual) {
    checks.push({
      time: action.timeActual,
      message: CHECK_MESSAGES.CUSTOM_COMPLETE_AFTER_ACTUAL,
      before: false
    });
  }
  return checks;
};