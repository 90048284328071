import { UnitsKeys } from '../constants/Units';

export function parseCargoManifest(manifest) {
  let incoming = [];
  let outgoing = [];
  let other = [];

  if (manifest) {
    //incoming cargo
    incoming = manifest.filter(item => {
      return (item.initialAmount !== null && item.initialAmount > 0);
    }).map(item => {
      return {
        id: item.id,
        name: item.type.name,
        units: UnitsKeys[item.type?.units],
        amount: item.initialAmount,
        comment: item.comments
      };
    });


    // outgoing cargo
    outgoing = manifest.filter(item => {
      return (item.amount !== null && item.amount > 0);
    }).map(item => {
      return {
        id: item.id,
        name: item.type.name,
        units: UnitsKeys[item.type?.units],
        amount: item.amount,
        comment: item.comments
      };
    });

    //other cargo
    other = manifest.filter(item => {
      return ((item.amount === null || item.amount === 0) && (item.initialAmount === null || item.initialAmount === 0));
    }).map(item => {
      return {
        id: item.id,
        name: item.type.name,
        units: UnitsKeys[item.type?.units],
        amount: item.amount,
        comment: item.comments
      };
    });
  }

  return { incoming, outgoing, other };
}
