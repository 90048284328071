import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { isBefore, parseISO, addMinutes, isAfter } from 'date-fns';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { useTranslation } from 'react-i18next';

import DialogLoadingProgress from './DialogLoadingProgress';

const PortCallCompletionTimeInputDialog = ({ 
  open, 
  title, 
  onConfirm, 
  onClose, 
  message, 
  modal, 
  loading, 
  error, 
  inputLabels,
  initialArrivalValue,
  initialDepartureValue,
  departureTimeSet,
  checks
}) => {
  const [arrivalValue, setArrivalValue] = useState(initialArrivalValue)
  const [departureValue, setDepartureValue] = useState(initialDepartureValue)
  const [arrivalComment, setArrivalComment] = useState('');
  const [departureComment, setDepartureComment] = useState('');
  const [arrivalErrorMessage, setArrivalErrorMessage] = useState('');
  const [departureErrorMessage, setDepartureErrorMessage] = useState('');
  const { t } = useTranslation();

  const checkIsBefore = (time1, time2) => isBefore(parseISO(time1), parseISO(time2));

  const handleClose = (reason) => {
    if(modal && reason === 'backdropClick') return;
    onClose();
  };

  const handleCheckArrivalTime = (value) => {
    const checkOk = checkIsBefore(value, departureValue);
    setArrivalErrorMessage(checkOk ? '' : t('Common.Errors.TimeCheckArrivalBeforeDeparture'));
    checkOk && setDepartureErrorMessage('');
    setArrivalValue(value);
  };

  const handleCheckDepartureTime = (value) => {
    let checkOk;
    if(checks.length > 0 && checks.find(({time}) => isAfter(addMinutes(parseISO(time), 1), parseISO(value)))){
      checkOk = false;
    } else if(arrivalValue && !checkIsBefore(arrivalValue, value)){
      checkOk = false;
    } else checkOk = true;
    setDepartureErrorMessage(checkOk ? '' : t('Common.Errors.TimeCheckDepartureAfterArrival'));
    checkOk && setArrivalErrorMessage('');
    setDepartureValue(value)
  };

  return (
    <Dialog open={open} onClose={handleClose} id='TimeInputDialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message && <DialogContentText style={{marginBottom: '1.2rem'}}>{message}</DialogContentText>}
        {initialArrivalValue && <div>
          <StringKeyboardDateTimePicker
            warningPastDate={true}
            id={'CompletePortCallArrivalTimeInput'}
            label={inputLabels['arrival']}
            fullWidth
            margin="normal"
            value={arrivalValue}
            onChange={value => handleCheckArrivalTime(value)} 
            showInlineNowButton
          />
          <FormHelperText error id='ArrivalTimeErrorMessage'>{arrivalErrorMessage || ""}</FormHelperText>
          <TextField
            id={'CompletePortCallArrivalCommentInput'}
            variant="outlined"
            label={t('Common.Labels.Comments')}
            fullWidth
            multiline
            maxRows={3}
            margin='normal'
            value={arrivalComment}
            style={{marginBottom: '1.2rem'}}
            onChange={value => setArrivalComment(value.target.value)}
          />
        </div>}
        {!departureTimeSet && <StringKeyboardDateTimePicker
          warningPastDate={true}
          id={'CompletePortCallDepartureTimeInput'}
          label={inputLabels['departure']}
          fullWidth
          margin="normal"
          value={departureValue}
          onChange={value => handleCheckDepartureTime(value)} 
          showInlineNowButton
        />}
        <FormHelperText error id='DepartureTimeErrorMessage'>{departureErrorMessage || ""}</FormHelperText>
          <TextField
            id={'CompletePortCallDepartureCommentInput'}
            variant="outlined"
            label={t('Common.Labels.Comments')}
            fullWidth
            multiline
            maxRows={3}
            margin='normal'
            value={departureComment}
            style={{marginBottom: '1.2rem'}}
            onChange={value => setDepartureComment(value.target.value)}
          />
        <DialogLoadingProgress 
          loading={loading}
          errorMessage={error?.message}
          errorCode={error?.errorCode}
        />
      </DialogContent>
      <DialogActions>
        <div id={'TimeInputDialogCancelButton'}>
          <Button
            onClick={onClose}
            color="default"
            disabled={loading}
          >
            {t('Common.Buttons.Cancel')}
          </Button>
        </div>
        <div id={'TimeInputDialogConfirmButton'}>
          <Button
            disabled={ 
              arrivalErrorMessage ||
              departureErrorMessage ||
              loading
            }
            onClick={() => onConfirm(arrivalValue, departureValue, arrivalComment, departureComment)}
            color="primary"
          >
            {t('Common.Buttons.Confirm')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default PortCallCompletionTimeInputDialog;