import { DataStore } from 'aws-amplify';
import { RequestType, RequestState, Request } from '../models';

const requestsArrayCheck = (requests) => !requests || requests.length === 0;

export const updateRequestsState = async (requests, state) => {
  if(!state || requestsArrayCheck(requests)) return;
  for (let request of requests) {
      DataStore.save(Request.copyOf(await DataStore.query(Request, request.id), updated => {
      updated.state = state;
    }));
  }
}

export const rejectRequests = (requests) => !requestsArrayCheck(requests) && updateRequestsState(requests, RequestState.REQUEST_STATE_REJECTED);

// Takes in portCall, actionId
// get requests for that actionId
export const getActionsRequestsFromPortCall = (portCall, actionId) => {
  if(!portCall || !actionId || requestsArrayCheck(portCall.requests)) return [];
  return portCall.requests?.filter((request) => {
    const { actionData, type } = request;
    if(!actionData || type === RequestType.REQUEST_TYPE_CREATE_PORTCALL)
      return false;
    return actionData.some((data) => data.actionId === actionId);
  });
} 

export const rejectPendingRequestsForAction = (portCall, actionId) => {
  rejectRequests(getActionsRequestsFromPortCall(portCall, actionId).filter(r => r.state === RequestState.REQUEST_STATE_PENDING));
}
