import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import Button from '@material-ui/core/Button';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { invalidDateTimeMessage, isValidDate, isPastDate } from '../../utils/dateUtils.js'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DatastoreStatusContext } from '../../contexts/datastoreStatusContext';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'fit-content'
  },
  warning: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'orange'
        }
      }
    },
    '& .MuiFormLabel-root': {
      '&.Mui-error': {
        color: 'orange'
      }
    },
    '& .MuiFormHelperText-root': {
      color: 'orange'
    }
  },
  nowButton: {
    position: 'absolute',
    top: '1rem',
    right: '4rem'
  }

}));
/** Wrapper for KeyboardDateTime to use with DataStore - onChange returns ISO string */
const StringKeyboardDateTimePicker = ({ value, onChange, helperText, error, allowEmptyValue, warningPastDate = false, className, showInlineNowButton, width = '100%', ...other }) => {
  const classes = useStyles();
  const [date, setDate] = useState(value ? new Date(value) : null);
  const { setting, dateTimeFormat } = useDateTimeSetting();
  const { t } = useTranslation();
  const isSynced = useContext(DatastoreStatusContext);
  const [errorAlert, setErrorAlert] = useState({
    errorLocal: error,
    helperTextLocal: helperText,
    warningTextLocal: null
  });

  //To detect when onChange get called , so then it trigger the warning
  const [triggerWarning, setTriggerWarning] = useState(null);

  const formatInvalidDateMessage = useCallback((setting) => {
    const [message, format] = invalidDateTimeMessage(setting);
    return t(message, format);
  }, [t]);

  useMemo(()=>{
    if(!isSynced){
      setErrorAlert({
        errorLocal: error,
        helperTextLocal: helperText,
        warningTextLocal: null
      });
    }
  },[isSynced]);

  useEffect(() => {
    onChange && onChange(isNaN(date) || date === null ? null : date.toISOString());
  }, [date]);

  // value changed from outside
  useEffect(() => {
    setTriggerWarning(false);
    value !== (date && !isNaN(date) ? date.toISOString() : null) && setDate(value ? new Date(value) : null);
  }, [value]);

  useMemo(() => {
    //**** Error Validation
    // Allow empty value string if allowEmptyValue is true, no validation
    if (!value && !date && allowEmptyValue) {
      setErrorAlert({
        errorLocal: false,
        helperTextLocal: '',
        warningTextLocal: ''
      })
    }
    // Partial entry gives invalid date/time
    else if (!value && !isValidDate(date)) {
      setErrorAlert({
        errorLocal: true,
        helperTextLocal: formatInvalidDateMessage(setting),
        warningTextLocal: null
      })
    }
    //Warning When there is no other error (flavour Error over Warning) and it will alert after user enters a date in the past for schedule time 
    //warningPastDate status is set to true for schedule components, not every component need past date alert
    //To use MUI error message functionality, we need to use helperText and error state from MUI.
    //But not to get confuse, Between error and warning ,then we use triggerWarning = true and change mui error style to orange.
    else if (triggerWarning && warningPastDate && !error && date && isPastDate(date)) {
      setErrorAlert({
        errorLocal: true,
        helperTextLocal: '',
        warningTextLocal: t('Common.Errors.PastDate')
      });
    }
    //Use any error or helperText provided by parent
    else {
      setErrorAlert({
        errorLocal: error,
        helperTextLocal: helperText,
        warningTextLocal: null
      });
    }
  }, [date, value, error, helperText]);

  const handleOnChange = (value) => {
    value && setTriggerWarning(true);
    setDate(value);
  };

  return (
    <Box position="relative" width={width}>
      <KeyboardDateTimePicker 
      {...other}
      className={errorAlert?.warningTextLocal ? `${classes.warning} ${className}` : className}
      ampm={false}
      error={errorAlert?.errorLocal}
      format={dateTimeFormat}
      helperText={errorAlert?.helperTextLocal || errorAlert?.warningTextLocal}
      invalidDateMessage={formatInvalidDateMessage(setting)}
      inputVariant="outlined"
      value={date}
      onChange={handleOnChange}
      cancelLabel={t('Common.Buttons.Cancel')}
      showTodayButton={true}
      todayLabel={t('Common.Buttons.Now')}
      inputProps={{'data-testid': 'input'}}
    />
     {showInlineNowButton && <Button 
        className={classes.nowButton}
        onClick={() => handleOnChange(new Date())}
        color="primary"
        data-testid="nowButton"
      >
        {t('Common.Buttons.Now')}
      </Button>}
    </Box>
  );
};

export default StringKeyboardDateTimePicker;