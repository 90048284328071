import { LinearProgress, Box, ListItemText } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Alert } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  box: {
    paddingLeft: '10px'
  }
}));

const DialogLoadingProgress = ( {loading, errorMessage, errorCode }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    errorMessage ?
      <Box display="flex" flexDirection="row" alignItems="center">
        <Alert style={{ color: theme.palette.warning.main }}/> 
        <ListItemText 
          id="DialogLoadingProgressListItemText"
          className={classes.box} 
          primary={errorMessage} 
          secondary={t('Common.Errors.ErrorCode', { errorCode: errorCode } )}
        />
      </Box> :  
      loading ? <LinearProgress /> : null
  )
};

export default DialogLoadingProgress;