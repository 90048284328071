import React, { useEffect, useState, useContext } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Hub, Auth, DataStore, syncExpression, API, graphqlOperation, Storage, Predicates } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { DateFnsLanguageMap } from './translations';
import Routes from './routes/Routes';
import AgentPortal from './routes/AgentPortal';
import { UIContext, setAccessToken, setReadOnly, setIsAdmin, setAgentPortal, setUserContactId, setIsApprover, setUserName, setIsVoyageAdmin } from './contexts/ui';
import { DataStoreContext, useDataStoreProvider } from './contexts/dataStoreContext';
import { TariffContext, useTariffProvider } from './contexts/tariffsContext';
import * as models from './models';
import { Action, Cargo, Contact, PortCall, Request, S3File, PortCallData, UserSetting, NotificationRule } from './models';
import { admin, agent, approver, portcontroller, voyageadmin } from './constants/UserRoles';
import Splash from './components/Splash';

// Expose some variables for the testing framework
window.__E2E__ = { models, DataStore, graphqlOperation: (query, variables) => API.graphql(graphqlOperation(query, variables)), Storage };


const SESSION_REFRESH_INTERVAL = 7500;

const AppLoggedIn = ({ agentPortal }) => {
  const data = useDataStoreProvider();
  const tariffs = useTariffProvider();
  const { i18n } = useTranslation();
  return (
    <DataStoreContext.Provider value={data}>
      <TariffContext.Provider value={tariffs}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={DateFnsLanguageMap[i18n.language]}>
          {!agentPortal ? <Routes /> : <AgentPortal />}
        </MuiPickersUtilsProvider>
      </TariffContext.Provider>
    </DataStoreContext.Provider>
  );
}

// Placing variables here allows for them to be re-evaluated by DataStore between logins
const dataStoreConfig = {
  userContactId: '',
  username: ''
};

DataStore.configure({
  syncExpressions: [
    // Filter any contacts other than the user's
    syncExpression(Contact, () => {
      if (!dataStoreConfig.userContactId) return Predicates.ALL;
      return (c) => c.id('eq', dataStoreConfig.userContactId)
    }),
    // Filter archived data
    syncExpression(PortCall, (c) => c.archived('ne', true)),
    syncExpression(Action, (c) => c.portCallArchived('ne', true)),
    syncExpression(Cargo, (c) => c.portCallArchived('ne', true)),
    syncExpression(Request, (c) => c.portCallArchived('ne', true)),
    // Block PortCallData
    syncExpression(PortCallData, (c) => c.id('eq', '')),
    // Block S3File files from being synced to client
    syncExpression(S3File, (f) => f.id('eq', '')),
    // Filter by userId
    syncExpression(UserSetting, (c) => c.id('eq', dataStoreConfig.username)),
    // Filter soft deleted entries
    syncExpression(NotificationRule, (c) => c.deleted('ne', true)),
  ],
  maxRecordsToSync: 15000
});

const App = ({ authState }) => {

  const [ready, setReady] = useState(false);
  const [context, dispatch] = useContext(UIContext);
  const { route } = useAuthenticator((context) => [context.user]);

  useEffect(() => {

    const configure = async () => {
      const userInfo = await Auth.currentUserInfo();
      // update datastore config and re-evaluate it
      dataStoreConfig.username = userInfo.username;
      dataStoreConfig.userContactId = userInfo.attributes['custom:contact-id'];
      DataStore.start();
    }

    if (route !== 'authenticated') {
      DataStore.clear();
      setReady(false);
      return;
    } else {
      configure();
      const listener = Hub.listen('datastore', async hubData => {
        const { event, data } = hubData.payload;
        // console.log('EVENT', event, JSON.stringify(data, null, 2));
        if (event === "ready") {
          setReady(true);
        }
      });

      return () => {
        listener();
        DataStore.clear();
      }
    }
  }, [setReady, route]);

  // TODO PLEASE HALP
  // periodically update accessToken
  useEffect(() => {
    const update = async () => {
      try {
        const session = await Auth.currentSession(); // throws No current user when logged out
        if (!context.accessToken || context.accessToken.payload.auth_time !== session.getAccessToken().payload.auth_time) {
          dispatch(setAccessToken(session.getAccessToken()));
          const groups = session.getIdToken().payload['cognito:groups'];
          const readOnly = !groups || 
            (!groups.includes(portcontroller) 
            && !groups.includes(admin) 
            && !groups.includes(approver) 
            && !groups.includes(voyageadmin));
          const isAdmin = groups && groups.includes(admin);
          const isVoyageAdmin = groups && groups.includes(voyageadmin);
          const agentPortal = groups && groups.includes(agent);
          const userInfo = await Auth.currentUserInfo();
          const userContactId = userInfo.attributes['custom:contact-id'];
          const isApprover = Boolean(groups && groups.includes(approver));
          const userName = userInfo ? userInfo.username : '';
          dispatch(setReadOnly(readOnly && !agentPortal));
          dispatch(setIsAdmin(isAdmin));
          dispatch(setIsVoyageAdmin(isVoyageAdmin));
          dispatch(setAgentPortal(agentPortal));
          dispatch(setUserContactId(userContactId));
          dispatch(setIsApprover(isApprover));
          dispatch(setUserName(userName));
          console.log(`userInfo ${userInfo} groups ${session.getIdToken().payload['cognito:groups']} readonly ${readOnly} agentPortal ${agentPortal} userContactId ${userContactId}`);
          // TODO show error screen if agentPortal && no user contact ID
          if (agentPortal && !userContactId) console.error('FIXME : Agent user has no contact ID')
        } else if (!['verifyContact', 'signedIn'].includes(route)) {
          // TODO upgrade fucked this part up
          // window.location.reload(false); // MS: stupid hack but works; reload other tabs on login
        }
      } catch (err) {
        route === 'authenticated' && Auth.signOut(); // force signout
      }
    };
    const interval = setInterval(update, SESSION_REFRESH_INTERVAL);
    update();
    return () => { clearInterval(interval); }
  }, [dispatch, context.accessToken, authState, route]);

  if (!ready) return <Splash />
  return route === 'authenticated' ? <AppLoggedIn agentPortal={context.agentPortal} /> : null;
};

export default App;
