import React from 'react';
import { ExportUnits } from './export-fields';
import {
  Box,
  FormControl, FormLabel, FormControlLabel, RadioGroup, Radio,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  box: {
    marginBottom: "12px",
    flexShrink: 0
  },
  radioGroup: {
    alignItems: 'center'
  },
  radioLabel: {
    paddingRight: '10px'
  },
  summary: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const ExportUnitSelection = ({selectedLengthUnit, onLengthUnitChange}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="ExportUnitLengthSelectionLabel"
          name="radio-buttons-group"
          className={classes.radioGroup}
        >
          <FormLabel id="ExportUnitLengthSelectionLabel" className={classes.radioLabel}>{t('ExportPortCalls.Labels.Length')}</FormLabel>
          <FormControlLabel id="ExportUnitLengthSelection-Metres" control={<Radio />} label={t('ExportPortCalls.Labels.Metres')} onChange={() => onLengthUnitChange(ExportUnits.METRES)} checked={selectedLengthUnit === ExportUnits.METRES}/>
          <FormControlLabel id="ExportUnitLengthSelection-Feet" control={<Radio />} label={t('ExportPortCalls.Labels.Feet')} onChange={() => onLengthUnitChange(ExportUnits.FEET)} checked={selectedLengthUnit === ExportUnits.FEET}/>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
export default ExportUnitSelection;