import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import renderValueCurrencyString from '../../utils/renderValueCurrencyString';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const TariffTooltip = ({ tariff, currencySymbol }) => {
  const { t } = useTranslation();

  const { 
    notes,
    minimumCost,
   } = tariff;

  // Data to be displayed in the Popover
  // Can be expanded in the future
  const renderArray = [
    { label: t('ChargeableItems.Labels.Notes'), value: notes || null},
    { label: t('ChargeableItems.Labels.MinimumCost'), value: renderValueCurrencyString(minimumCost, currencySymbol) || null }
  ];

  return (
      <Grid
        container
      >
        {renderArray.map(({label, value, size=12}, i) =>
          value &&
          <Grid item xs={size} key={i}>
            <ListItem>
              <ListItemText
                primary={value}
                secondary={label} 
              />
            </ListItem>
          </Grid>
        )}
      </Grid>
  )
};

TariffTooltip.defaultProps = {
  tariff: {},
  currencySymbol: "",
}

TariffTooltip.propTypes = {
  tariff: PropTypes.object,
  currencySymbol: PropTypes.string,
}

export default TariffTooltip;