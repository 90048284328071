import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Typography
} from '@material-ui/core';

import { COMPLETED, IN_PROGRESS } from '../../constants/ActionState';
import { MovementActionDisplayType as mt } from '../../constants/MovementActionDisplayType';
import { useContext } from 'react';
import { UIContext } from '../../contexts/ui';
import EditorField from '../Common/EditorField';
import { Action } from '../../models';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import { getActionActiveAgent } from '../../utils/getters';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { ActionTypeIds, ContactTypeIds } from '../../environment';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(() => ({
  formLabelLabel: {
    //textTransform: 'uppercase',
    marginBottom: '9px',
    marginTop: '26px',
  },
  formLabelLabelFirst: {
    marginTop: '0',
  },
  chipRoot: {
    marginRight: '0.5rem',
  }
}));

export const ActionScheduleEdit = ({
  action,
  actions,
  portCall,
  updatePortCallAgents
}) => {
  const classes = useStyles();
  const [uiContext,] = useContext(UIContext);
  const prevAgent = useMemo(() => action && actions && getActionActiveAgent(actions, action), [action, actions]);
  const { t } = useTranslation();

  const handleTimeChange = (name, value) => {
    // update list of port call agents
    const portCallAgents = portCall.agents && portCall.agents.map(a => { return {
      ...a,
      time: action.id === a.actionId ? value : a.time
    }});
    updatePortCallAgents(Action.copyOf(action, updated => { updated[name] = value; }), portCallAgents);
  };

  const handleChangeAgent = (value) => {
    // update list of port call agents
    const portCallAgents = portCall.agents && portCall.agents.map(a => { return {
      ...a,
      contactId: action.id === a.actionId ? value.id : a.contactId,
    }});
    updatePortCallAgents(Action.copyOf(action, updated => { updated.actionAgent = value; }), portCallAgents);
  };

  const readOnly = uiContext.readOnly;
  return (
    <CardContent>
      <Typography
        className={classes.formLabelLabelFirst}
        id="action-schedule"
        variant="h6"
      >
        {action && action.type.id === ActionTypeIds.MOVEMENT && mt[action.movementType]} {t("AgentHandoverEdit.Labels.Schedule")}
      </Typography>
      {prevAgent && 
        <Typography variant="h6">{t("AgentHandover.Labels.From")}: {prevAgent ? prevAgent.displayName || prevAgent.name : t("AgentHandover.Labels.NoActiveAgent")}</Typography>
      }
      <StringKeyboardDateTimePicker
        id={"AgentSchedulePlannedTime"}
        warningPastDate={true}
        disabled={readOnly || action.state === COMPLETED || action.state === IN_PROGRESS}
        label={t("AgentHandoverEdit.Labels.PlannedTime")}
        fullWidth
        margin="normal"
        value={action.timePlanned}
        okLabel={t("AgentHandoverEdit.Buttons.SetPlannedTime")}
        onChange={(value) => handleTimeChange("timePlanned", value)}
      />
      <Typography
        className={classes.formLabelLabel}
        id="action-contact"
        variant="h6"
      >
        {t("Agent.Labels.CharterAgent")}
      </Typography>
      <ContactAutocomplete 
        style={{ width: '100%' }}
        value={action.actionAgent}
        onChange={handleChangeAgent}
        disabled={readOnly}
        filter={c => c.type?.id === ContactTypeIds.AGENT}
        key={"AgentHandOverEditContactInput"}
        />
      {action.actionAgent &&
        <>
          <Typography>
            {t("AgentHandoverEdit.Labels.Details")}
      </Typography>
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Reference')}
            value={action.actionAgent.reference}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Fax')}
            value={action.actionAgent.fax}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.TelephoneNumber')}
            value={action.actionAgent.number}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.ContactAName')}
            value={action.actionAgent.contacta_name}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Address1')}
            value={action.actionAgent.address1}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Address2')}
            value={action.actionAgent.address2}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Town')}
            value={action.actionAgent.town}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.County')}
            value={action.actionAgent.county}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Postcode')}
            value={action.actionAgent.postcode}
          />
          <EditorField
            disabled={true}
            label={t('Contact.Labels.Email')}
            value={action.actionAgent.email}
          />
        </>
      }

    </CardContent>
  )
}

export default ActionScheduleEdit;
