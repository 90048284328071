import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExportPreview from './ExportPreview';

const useStyles = makeStyles(theme => ({
  preview: {
    marginBottom: "12px"
  },
  formDialogPaper: {
    maxWidth: "100%",
    width: "50%",
    height: "80%"
  },
  exportDialogContent: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ExportDialog = ({ open, title, onExport, handleClose, exportColumns, preview, handleExportFieldReorder, exportMessage , exportDialogContent}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onClose = (event, reason) => {
    if(reason === 'backdropClick') return;
    handleClose(event);
  }

  const handleExport = async () => {
    onExport && await onExport();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.formDialogPaper }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.exportDialogContent}>
        {exportDialogContent}
        <DialogContentText>{t('ExportPortCalls.Labels.PreviewColumns')}</DialogContentText>
        <Typography variant="caption" align="start">{t('ExportPortCalls.Labels.PreviewSampleDataSet')}</Typography>
        <ExportPreview id="ExportPreview" columns={exportColumns} preview={preview} onOrderChanged={handleExportFieldReorder}/>
        <Typography variant="caption">{t("ExportPortCalls.Labels.PreviewCaption")}</Typography>
        {exportMessage && <DialogContentText id="ExportMessage" style={{ marginTop: "12px"}}>{exportMessage}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button id="ExportClose" onClick={handleClose} color="default">
          {t("Common.Buttons.Cancel")}
        </Button>
        <Button id="ExportButton" onClick={handleExport} color="primary">
          {t("Common.Buttons.Export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;