import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowDown,
  EyeOutline,
  Thermometer,
} from 'mdi-material-ui';

import WeatherIcon from './WeatherIcon';
import { kmhToKnot, celciusToFahrenheit } from '../../utils/utils';
import { getCurrentConditionsAccuWeatherExternal } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { differenceInMilliseconds, format } from 'date-fns';
import useUnitSetting from '../../hooks/useUnitSetting';
import {Units, CELCIUS, FAHRENHEIT}  from '../../constants/Units';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';

/* Documentation

Uses a GQL call to obtain current weather information
from the Accuweather api and display it.

*/

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: theme.typography.caption.fontSize,
    paddingRight: '0.25rem',
    marginTop: '-0.25rem',
  },
}));

const LOCALISATION = 'metric';

// Labels are mapped so a check can be performed beforehand and localization engine doesn't blow up
const WindDirectionLabelMap = {
  N: 'WindDirection.Labels.N',
  NbE: 'WindDirection.Labels.NbE',
  NNE: 'WindDirection.Labels.NNE',
  NEbN: 'WindDirection.Labels.NEbN',
  NE: 'WindDirection.Labels.NE',
  NEbE: 'WindDirection.Labels.NEbE',
  ENE: 'WindDirection.Labels.ENE',
  EbN: 'WindDirection.Labels.EbN',
  E: 'WindDirection.Labels.E',
  EbS: 'WindDirection.Labels.EbS',
  ESE: 'WindDirection.Labels.ESE',
  SEbE: 'WindDirection.Labels.SEbE',
  SE: 'WindDirection.Labels.SE',
  SEbS: 'WindDirection.Labels.SEbS',
  SSE: 'WindDirection.Labels.SSE',
  SbE: 'WindDirection.Labels.SbE',
  S: 'WindDirection.Labels.S',
  SbW: 'WindDirection.Labels.SbW',
  SSW: 'WindDirection.Labels.SSW',
  SWbS: 'WindDirection.Labels.SWbS',
  SW: 'WindDirection.Labels.SW',
  SWbW: 'WindDirection.Labels.SWbW',
  WSW: 'WindDirection.Labels.WSW',
  WbS: 'WindDirection.Labels.WbS',
  W: 'WindDirection.Labels.W',
  WbN: 'WindDirection.Labels.WbN',
  WNW: 'WindDirection.Labels.WNW',
  NWbW: 'WindDirection.Labels.NWbW',
  NW: 'WindDirection.Labels.NW',
  NWbN: 'WindDirection.Labels.NWbN',
  NNW: 'WindDirection.Labels.NNW',
  NbW: 'WindDirection.Labels.NbW'
};

export const Weather = ({
  useAwApi = true,
  basePollInterval = 300000,
}) => {
  const classes = useStyles();

  const [data, setData] = useState(null);
  useEffect(() => {
    let timeout;
    const update = async () => {
      const { data } = await API.graphql({ query: getCurrentConditionsAccuWeatherExternal, variables: { useAwApi } });
      setData(data);
      const expires = data && 
        data.getCurrentConditionsAccuWeatherExternal && 
        data.getCurrentConditionsAccuWeatherExternal.currentConditions && 
        data.getCurrentConditionsAccuWeatherExternal.currentConditions.expires;
      timeout = setTimeout(() => update(), expires ? differenceInMilliseconds(new Date(expires), new Date()) : basePollInterval);
    };
    update();
    return () => clearTimeout(timeout);
  }, [setData, useAwApi, basePollInterval]);

  const temperatureSetting = useUnitSetting('temperature');
  const { dateTimeFormat } = useDateTimeSetting(); 
  const { t, i18n } = useTranslation();
  const defaultTemperatureUnit = { unit: CELCIUS }; // Default to celcius if no setting exists
  const temperatureDefinition = temperatureSetting?.unit ? Units[temperatureSetting.unit] : Units[CELCIUS]; // Default to celcius if no setting exists
  const temperatureUnit = temperatureSetting ? temperatureSetting : defaultTemperatureUnit;

  const currentConditions = data && data.getCurrentConditionsAccuWeatherExternal && data.getCurrentConditionsAccuWeatherExternal.currentConditions;
  
  if (!data) {
    return <Typography variant="caption">{t('AppHeader.Labels.LoadingWeatherInformation')}</Typography>;
  }
  if (data?.getCurrentConditionsAccuWeatherExternal?.serviceResponse?.error) {
    return <Typography variant="caption">{t('AppHeader.Labels.ErrorLoadingWeatherInformation')}</Typography>;
  }

  const {
    localObservationDateTime,
    pressure,
    temperature,
    visibility,
    weatherIcon,
    wind,
    windGust,
  } = currentConditions;

  if (wind.speed.metric) {
    wind.speed.nautical = {
      value: kmhToKnot(wind.speed.metric.value),
      unit: 'kn',
      unitType: 8
    };
    windGust.speed.nautical = {
      value: kmhToKnot(windGust.speed.metric.value),
      unit: 'kn',
      unitType: 8
    };
  }
  if (visibility.metric) {
    visibility.nautical = {
      value: kmhToKnot(visibility.metric.value),
      unit: 'nmi'
    }
  }

  const observationTime = format(new Date(localObservationDateTime), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] });

  const displayTemperature = (celcius, units) => {
    if(units?.unit === FAHRENHEIT) return celciusToFahrenheit(celcius);
    return celcius;
  }

  return <>
    <Grid item>
      {weatherIcon &&
        <WeatherIcon
          code={weatherIcon}
          observationTime={observationTime}
        />
      }
    </Grid>

    <Grid item>
      {temperature && temperatureDefinition && temperatureUnit && typeof temperature?.metric?.value !== 'undefined' &&
        <Tooltip title={t('Weather.Labels.TemperatureAt', { symbol: temperatureUnit === FAHRENHEIT ? t('Common.Labels.F') : t('Common.Labels.C'), time: observationTime, interpolation: { escapeValue: false } })}>
          <Typography variant="h4" >
            {displayTemperature(temperature.metric.value, temperatureUnit).toFixed(1)}&deg;
          </Typography>
        </Tooltip>
      }
    </Grid>

    <Grid item>
      <Box display="flex" flexDirection="column">
        {visibility && visibility.nautical && visibility.nautical.unit && typeof visibility.nautical.value !== 'undefined' &&
          <Tooltip title={t('Weather.Labels.VisibilityAt', { unit: visibility.nautical.unit === 'nmi' ? t('Common.Labels.NMI') : visibility.nautical.unit, time: observationTime, interpolation: { escapeValue: false } })}>
            <Box display="flex" alignItems="center">
              <EyeOutline className={classes.caption} />
              <Typography variant="caption">
                {visibility.nautical.value}{' '}
                {visibility.nautical.unit === 'nmi' ? t('Common.Labels.NMI') : visibility.nautical.unit}
              </Typography>
            </Box>
          </Tooltip>
        }
        {pressure && pressure[LOCALISATION] && pressure[LOCALISATION].unit && typeof pressure[LOCALISATION].value !== 'undefined' &&
          <Tooltip title={t('Weather.Labels.AtmosphericPressureAt', { unit: pressure[LOCALISATION].unit === 'mb' ? t('Common.Labels.MB') : pressure[LOCALISATION].unit, time: observationTime, interpolation: { escapeValue: false } })}>
            <Box display="flex" alignItems="center">
              <Thermometer className={classes.caption} />
              <Typography variant="caption">
                {pressure[LOCALISATION].value}{' '}
                {pressure[LOCALISATION].unit === 'mb' ? t('Common.Labels.MB') : pressure[LOCALISATION].unit}
              </Typography>
            </Box>
          </Tooltip>
        }
      </Box>
    </Grid>

    <div className={classes.divider} />

    <Grid item>
      {wind && wind.direction && typeof wind.direction.degrees !== 'undefined' && wind.direction.localized &&
        <Tooltip title={t('Weather.Labels.WindDirectionAt', { degrees: wind.direction.degrees, direction: WindDirectionLabelMap[wind.direction.localized] ? t(WindDirectionLabelMap[wind.direction.localized]) : wind.direction.localized, time: observationTime, interpolation: { escapeValue: false } })}>
          <ArrowDown
            className={classes.windIcon}
            style={{ transform: `rotateZ(${Math.abs(wind.direction.degrees)}deg)` }}
          />
        </Tooltip>
      }
    </Grid>

    <Grid item>
      {wind && wind.speed && wind.speed.nautical && typeof wind.speed.nautical.unit !== 'undefined' && wind.speed.nautical.value &&
        <>
          <Typography variant="h6">
            {Math.round(wind.speed.nautical.value)}
            <Tooltip title={t('Weather.Labels.Gusting')}>
              <Typography variant="caption" >
                {' '}
                [{Math.round(windGust.speed.nautical.value)}]
          </Typography>
            </Tooltip>
          </Typography>
          <Typography variant="caption">
            {wind.speed.nautical.unit === 'kn' ? t('Common.Labels.KN') : wind.speed.nautical.unit}
          </Typography>
        </>
      }
    </Grid>
  </>
};

Weather.propTypes = {
  // Whether to return live data from query
  useAwApi: PropTypes.bool,
  // How oftern to return a new weather result
  pollInterval: PropTypes.number
}

export default Weather;