import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import useQuery from '../hooks/useQuery';
import { TariffBook } from '../models';

const s3BookPath = (tariffBookId) => `tariff-book/${tariffBookId}.json`;

const fetchTariffBook = async (bookId) => {
  return new Promise((resolve, reject) => {
    const key = s3BookPath(bookId);
    Storage.get(key, { download: true, cacheControl: 'no-cache' })
      .then((result) => {
        result?.Body?.text().then((text) => {
          // console.log('useTariffProvider loaded', key, text);
          resolve(JSON.parse(text));
        })
      }) 
      .catch((error) => {
        console.error('useTariffProvider Failed to load S3 data', error.message);
        reject(error);
      });
  });
};

export const useTariffProvider = () => {
  const dsTariffBooks = useQuery(TariffBook);
  const [ tariffBooks, setTariffBooks ] = useState([]);
 
  useEffect(() => {
    const loadS3 = async () => {

    const bookIds = dsTariffBooks.filter(el => !el.deleted).map(el => el.id);
    const requests = bookIds.map(id => fetchTariffBook(id));

    Promise.all(requests)
      .then(responses => {
        const complete = [];
        for(let response of responses) {
          // console.log('FINAL', response);

          const book = dsTariffBooks.find(el => el.id === response.id);
          if(book) {
            complete.push(
              TariffBook.copyOf(book, updated => {
                updated.tariffs = response.tariffs ? response.tariffs : [];
                updated.defaultCharges = response.defaultCharges ? response.defaultCharges : [];
              })
            );
          }
        }
        // Update state with merge of DataStore and S3 data
        setTariffBooks(complete);
        // console.log('COMPLETE', complete);
      });

    }
    console.log('useTariffProvider', dsTariffBooks.length);
    loadS3();
  }, [ dsTariffBooks ]);

  return {
    tariffBooks
  }
};

export const TariffContext = React.createContext({
  tariffBooks: []
});