import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Popover
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import { DataStore } from 'aws-amplify';
import { getActionActiveAgent, getActionLastArrival, getActionTime } from '../../utils/getters';
import { ActionState, ActionEventType, Action, PortCall } from '../../models';
import { add } from 'date-fns';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { ActionTypeIds, ContactTypeIds } from '../../environment';
import useAuditMeta from '../../hooks/useAuditMeta';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const AgentHandoverCreateDialog = ({ portCall, anchorEl, onClose }) => {
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const [event, setEvent] = useState(() => {
    // default to last arrival
    const defaultArrival = getActionLastArrival(portCall.actions);
    return new Action({
      portCall,
      actionPortCallId_: portCall.id,
      state: ActionState.PLANNED,
      type: { id: ActionTypeIds.EVENT },
      eventType: ActionEventType.AGENT_HANDOVER,
      timePlanned: add(new Date(getActionTime(defaultArrival)), { hours: 1 }).toISOString(),
      documents: [''],
      movementPilots: [''],
      movementLinesmen: [''],
      movementMooringVessels: [''],
      auditMeta: auditMeta
    });
  });

  const activeAgent = useMemo(() => getActionActiveAgent(portCall.actions, event), [portCall.actions, event]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangeTime = (value) => {
    setEvent(Action.copyOf(event, updated => { updated.timePlanned = value; }));
  };

  const handleChangeAgent = (value) => {
    setEvent(Action.copyOf(event, updated => { updated.actionAgent = value; }));
  };

  const handleCreate = async () => {
    DataStore.save(event);

    const lastPortCall = await DataStore.query(PortCall, portCall.id);
    DataStore.save(PortCall.copyOf(lastPortCall, updated => {
      updated.agents = [
        ...lastPortCall.agents,
        {
          contactId: event.actionAgent.id,
          time: event.timePlanned,
          actionId: event.id
        }
      ];
    }));

    handleClose();
  };

  const eventError = useMemo(() =>
    (Boolean(!event.timePlanned) && 'Invalid date format'),
    [event.timePlanned]);
  const createDisabled = useMemo(() => eventError || !event.actionAgent, [eventError, event.actionAgent]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Card id={"AgentHandoverCreateDialog"}>
        <CardContent>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            <Typography variant="h5">{t('AgentHandoverCreateDialog.Labels.AgentHandover')}</Typography>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="2rem">
            <Typography variant="h6">{t('AgentHandover.Labels.From')}: {activeAgent ? activeAgent.displayName || activeAgent.name : t("AgentHandover.Labels.NoActiveAgent")}</Typography>
          </Box>
          <Box display="flex" height="5rem">
            <StringKeyboardDateTimePicker
              id={"AgentHandOverSchedulePlannedTime"}
              warningPastDate={true}
              style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
              label={t('AgentHandoverCreateDialog.Labels.PlannedTimeOfHandover')}
              value={event.timePlanned}
              onChange={handleChangeTime}
              error={Boolean(eventError)}
              helperText={eventError}
            />
          </Box>
          <ContactAutocomplete
            id="AgentHandOverContactInput"
            style={{ width: '100%' }}
            value={event.actionAgent}
            onChange={handleChangeAgent}
            filter={c => c.type?.id === ContactTypeIds.AGENT}
          />
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              id="CreateAgentHandOverButton"
              variant="contained"
              color="primary"
              size="large"
              disabled={createDisabled}
              style={{ margin: '1rem' }}
              onClick={handleCreate}
            >
              {t('Common.Buttons.Create')}
            </Button>

          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

export default AgentHandoverCreateDialog;
