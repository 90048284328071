import React from 'react';
import AgentListItem from '../Agent/AgentListItem';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import ContactAutocomplete from './ContactAutocomplete';
import ContactTooltip from '../Tooltip/ContactTooltip';
import PopoverTooltip from '../Tooltip/PopoverTooltip';

export const useStyles = makeStyles(theme => ({
  optionTagWrapper: {
    '& .MuiChip-label': {
      paddingLeft: '0.50rem',
      paddingRight: '0.50rem'
    },
    '& .MuiListItemIcon-root': {
      marginRight: '0.50rem',
      '& .MuiSvgIcon-root': {
        color: '#000'
      }
    }
  }
}));

const MultiContactAutocomplete = ({ value, onChange, label, errorOnEmpty, ...other }) => {
  const classes = useStyles();
  return (
    <ContactAutocomplete
      {...other}
      value={value}
      onChange={onChange}
      label={label}
      multiple={true}
      errorOnEmpty={errorOnEmpty}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <PopoverTooltip key={`contact-option-${index}`} className={classes.optionTagWrapper} tooltip={<ContactTooltip contact={option} />} disableFullWidthWrapper>
            <Chip
              label={
                <AgentListItem agent={option} icon={true}/>
              }
              {...getTagProps({ index })}
            />
          </PopoverTooltip>
        ));
      }}
    />
  );
};

export default MultiContactAutocomplete;