import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { Link, useParams, useHistory } from 'react-router-dom';

import TimelineAction from '../../components/Action/TimelineAction';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import TimeInputDialog from '../Dialog/TimeInputDialog';
import TextInputDialog from '../Dialog/TextInputDialog';
import { MovementActionType as mat } from '../../constants/MovementActionType';
import PortCallTimelinePortCallMenu from './PortCallTimelinePortCallMenu';
import PortCallTimelineActionMenu from './PortCallTimelineActionMenu';
import { NavigationContext } from '../../contexts/navigation';
import { DatastoreStatusContext } from '../../contexts/datastoreStatusContext';
import { UIContext } from '../../contexts/ui';
import { sortActions } from '../../utils/sorters';
import { 
  getOutstandingTodos,
  getActionName,
  getPendingPortCallRequests,
  getPendingRequests,
} from '../../utils/getters';
import {
  generateTimePlannedChecks,
  CHECKS 
} from '../../utils/generators';
import {
  rejectPendingRequestsForAction,
  rejectRequests
} from '../../utils/requests';
import { updateActionState } from './utils';
import { RequestState, RequestType, ActionState, PortCallStatus } from '../../models';
import { ActionTypeIds } from '../../environment';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import useAuditMeta from '../../hooks/useAuditMeta';
import useAutomationSetting from '../../hooks/useAutomationSetting';
import useTransactionMutation from '../../hooks/useTransactionMutation';
import { EditorContentTabIds, useEditorContentTab } from '../Common/EditorContentTabs';
import { useTranslation } from 'react-i18next';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';
import PortCallVesselCard from './PortCallVesselCard';
import { InactiveActionStates } from '../../constants/StateCategories';


/* Documentation

Description

Returns

Usage example where appropriate

*/

/* Make styles if needed */
const useStyles = makeStyles(theme => ({
  greyPanel: {
    backgroundColor: theme.palette.grey[100]
  },
  menuLink: {
    position: 'relative',
    color: 'inherit',
    textDecoration: 'none',
  },
  scrollPane: {
    height: 'calc(100% - 7rem)',
    overflow: 'auto',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  moreButton: {
    margin: '0.5rem'
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  addButton: {
    marginLeft: -56,
    position: 'absolute',
    right: '2rem',
    top: '7rem',
    zIndex: 100
  },
  cardFieldLabel: {
    paddingRight: '2rem',
    textAlign: 'right',
    fontWeight: '300',
    whiteSpace: 'nowrap'
  },
  timelineStripWrapper: {
    position: 'relative',
  },
  timelineStrip: {
    position: 'absolute',
    left: '12.375rem',
    top: '4.15rem',
    width: '.25rem',
    height: 'calc(100% - 10.75rem)',
    margin: 'auto',
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    zIndex: '0',
  },
  timelineDottedLine: {
    width: 0,
    height: 'calc(100% + 5rem)',
    left: '12.375rem',
    top: '-5rem',
    border: '0.25rem solid',
    borderColor: theme.palette.grey[300],
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
    borderStyle: 'dotted',
    position: 'absolute'
  },
  timelineFakeFadeTop: {
    position: 'absolute',
    width: '2rem',
    height: '5rem',
    top: '-3rem',
    left: '12.375rem',
    background: `linear-gradient(to bottom, ${theme.palette.grey[100]}, transparent)`,
  },
  timelineFakeFadeBottom: {
    position: 'absolute',
    width: '2rem',
    height: '5rem',
    bottom: '0',
    left: '12.375rem',
    background: `linear-gradient(to top, ${theme.palette.grey[100]}, transparent)`,
  },
  portTypography: {
    width: 'calc(20% + 4rem)',
    zIndex: 1,
    whiteSpace: 'nowrap',
  }
}));

const LifecycleLabels = {
  NONE: 'PortCallTimeline.Buttons.LifecycleNone',
  START_DEPARTURE: 'PortCallTimeline.Buttons.LifecycleStartDeparture',
  START_ARRIVAL: 'PortCallTimeline.Buttons.LifecycleStartArrival',
  RECORD_DEPARTURE: 'PortCallTimeline.Buttons.LifecycleRecordDeparture',
  RECORD_ARRIVAL: 'PortCallTimeline.Buttons.LifecycleRecordArrival',
  START_SHIFT_DEPARTURE: 'PortCallTimeline.Buttons.LifecycleStartShiftDeparture',
  START_SHIFT_ARRIVAL: 'PortCallTimeline.Buttons.LifecycleStartShiftArrival',
  RECORD_SHIFT_DEPARTURE: 'PortCallTimeline.Buttons.LifecycleRecordShiftDeparture',
  RECORD_SHIFT_ARRIVAL: 'PortCallTimeline.Buttons.LifecycleRecordShiftArrival',
  REQUIRES_APPROVAL: 'PortCallTimeline.Buttons.LifecycleRequiresApproval',
  START_CUSTOM: 'PortCallTimeline.Buttons.LifecycleStartCustom',
  RECORD_CUSTOM: 'PortCallTimeline.Buttons.LifecycleRecordCustom',
}

/* Further custom functions */
export const PortCallTimeline = ({
  portCall,
  portCallReference,
  movementTypeFilter,
  disableLifeCycle=false,
  saveDisabled
}) => {
  const { t } = useTranslation();
  const { actions, vessel } = portCall;
  const { actions: actionsReference} = portCallReference;
  const classes = useStyles();
  const { actionId } = useParams();
  const history = useHistory();
  const [action, setAction] = useState(null);
  const [nextAction, setNextAction] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false); // open={Boolean(confirmProps)} flickers on close
  const [confirmProps, setConfirmProps] = useState(null);
  const [openTime, setOpenTime] = useState(false);
  const [timeProps, setTimeProps] = useState(null);
  const [openText, setOpenText] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [navigationContext,] = useContext(NavigationContext);
  const [uiContext,] = useContext(UIContext);
  const userContactId = uiContext.userContactId;
  const isSynced = useContext(DatastoreStatusContext);
  const auditMeta = useAuditMeta();
  const [nextMovementDisabled, setNextMovementDisabled] = useState(false);
  const { appAutomationEnabled } = useAutomationSetting();
  const [ updateMutation, resetTransaction, { loading, error } ] = useTransactionMutation();
  const requests = portCall && portCall.requests && getPendingRequests(portCall, portCall.requests, userContactId);
  const portCallRequests = getPendingPortCallRequests(portCall);
  const portCallCreateRequests = requests.filter(r => r.type === RequestType.REQUEST_TYPE_CREATE_PORTCALL);
  const portCallCancelRequests = requests.filter(r => r.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL);
  const actionRequests = requests.filter(r => r.type === RequestType.REQUEST_TYPE_CANCEL_ACTION 
                                          || r.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED
                                          || r.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED
                                          || r.type === RequestType.REQUEST_TYPE_CREATE_DEPARTURE
                                          );

  const sortedActions = useMemo(() => {
    let sorted = [...actions].sort(sortActions);
    sorted = !movementTypeFilter ? sorted : sorted.filter(el => movementTypeFilter.includes(el.movementType));
    return sorted;
  }, [actions]);

  const sortedActionsReference = useMemo(() => {
    let sorted = [...actionsReference].sort(sortActions);
    sorted = !movementTypeFilter ? sorted : sorted.filter(el => movementTypeFilter.includes(el.movementType));
    return sorted;
  }, [actionsReference]);

  const { actionTypes } = useContext(DataStoreContext);
  const customActionTypes = useMemo(() => actionTypes.filter(at => at.id !== ActionTypeIds.MOVEMENT && at.id !== ActionTypeIds.EVENT), [actionTypes]);



  useEffect(() => {
    let nextAction = LifecycleLabels.NONE;
    let movementDisabled = false;
    if (!portCall.vessel) {
      setNextAction(undefined);
      return;
    }
    const firstActionableAction = sortedActionsReference.filter(item => 
      item.type.lifecycle && 
      !InactiveActionStates.includes(item.state) && 
      portCall.status !== PortCallStatus.CANCELLED)[0];
    setAction(firstActionableAction);
    if (!firstActionableAction) {
      if (nextAction) setNextAction(undefined);
      return;
    }
    
    if (firstActionableAction.state === ActionState.APPROVAL_DECLINED || firstActionableAction.state === ActionState.APPROVAL_PENDING) {
      nextAction = LifecycleLabels.REQUIRES_APPROVAL;
    } else if (firstActionableAction.movementType === mat.ARRIVAL) {
      if (!firstActionableAction.movementLocation || firstActionableAction.state === ActionState.REQUESTED){
        movementDisabled = true;
        nextAction = LifecycleLabels.START_ARRIVAL;
      } else if (firstActionableAction.state === ActionState.PLANNED
        && firstActionableAction.movementLocation
        && !firstActionableAction.movementLocation.dockable) {
          nextAction = LifecycleLabels.START_ARRIVAL;
          movementDisabled = true;
      } else if (firstActionableAction.state === ActionState.PLANNED
        && firstActionableAction.movementLocation
        && firstActionableAction.movementLocation.dockable) {
        nextAction = LifecycleLabels.START_ARRIVAL;
      } else if (firstActionableAction.state === ActionState.IN_PROGRESS
        && firstActionableAction.movementLocation
        && firstActionableAction.movementLocation.dockable
      ) {
        nextAction = LifecycleLabels.RECORD_ARRIVAL;
      }
    } else if (firstActionableAction.movementType === mat.DEPARTURE) {
      if ((!firstActionableAction?.movementLocation?.dockable) || firstActionableAction.state === ActionState.REQUESTED){
        movementDisabled = true;
        nextAction = LifecycleLabels.START_DEPARTURE;
      } else if (firstActionableAction.state === ActionState.PLANNED) {
        nextAction = LifecycleLabels.START_DEPARTURE;
      } else if (firstActionableAction.state === ActionState.IN_PROGRESS) {
        nextAction = LifecycleLabels.RECORD_DEPARTURE;
      }
    } else if (firstActionableAction.movementType === mat.SHIFT_ARRIVAL) {
      if (!firstActionableAction.movementLocation){
        movementDisabled = true;
        nextAction = LifecycleLabels.START_SHIFT_ARRIVAL;
      } else if (firstActionableAction.state === ActionState.PLANNED
        && firstActionableAction.movementLocation
        && !firstActionableAction.movementLocation.dockable) {
          nextAction = LifecycleLabels.START_SHIFT_ARRIVAL
          movementDisabled = true;
      } else if (firstActionableAction.state === ActionState.PLANNED
        && firstActionableAction.movementLocation
        && firstActionableAction.movementLocation.dockable) {
        nextAction = LifecycleLabels.START_SHIFT_ARRIVAL;
      } else if (firstActionableAction.state === ActionState.IN_PROGRESS
        && firstActionableAction.movementLocation
        && firstActionableAction.movementLocation.dockable
      ) {
        nextAction = LifecycleLabels.RECORD_SHIFT_ARRIVAL;
      }
    } else if (firstActionableAction.movementType === mat.SHIFT_DEPARTURE) {
      if (firstActionableAction.state === ActionState.PLANNED) {
        nextAction = LifecycleLabels.START_SHIFT_DEPARTURE;
      } else if (firstActionableAction.state === ActionState.IN_PROGRESS) {
        nextAction = LifecycleLabels.RECORD_SHIFT_DEPARTURE;
      }
    } else if(customActionTypes && customActionTypes.some(cat => cat.id === firstActionableAction.type.id)) {
      if (firstActionableAction.state === ActionState.PLANNED) {
        nextAction = LifecycleLabels.START_CUSTOM;
      } else if (firstActionableAction.state === ActionState.IN_PROGRESS) {
        nextAction = LifecycleLabels.RECORD_CUSTOM;
      }
    }
    setNextAction(nextAction);
    setNextMovementDisabled(movementDisabled);
  }, [portCall, portCall.vessel, sortedActionsReference, customActionTypes]);

  const nextActionDisabled = nextAction === LifecycleLabels.NONE || 
  nextAction === LifecycleLabels.REQUIRES_APPROVAL || 
  nextMovementDisabled
  const readOnly = uiContext.readOnly;
  const timelineWidth = '41rem';

  // if request in url go back to action
  function resetUrlforAction() {
    return history.location.pathname.indexOf("request") > 0 && history.push(`/port-call/${portCall.id}/action/${action.id}`);
  }

  // boolean for if there are any todos in Ready State
  const outstandingTodos = getOutstandingTodos(action);

  useEffect(() => {
    // Only set the error text if an error is still active
    setErrorMessage(error && error.statusCode !== 200 ? t('PortCallTimelineMenu.Errors.PortCallUpdateFailed') : undefined);
  }, [t, error]);

  const updateActionStateAsTransaction = async (update) => {
    await updateMutation(await updateActionState( update.action, update.state, update.comment, update.auditMeta, update.updates, update.ignorePortStatus ), update.onSuccess);
  };

  const handlePortCallArrival = () => {
    const basicConfirmProps = {
      title: t('PortCallTimelineMenu.Labels.ArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.ArrivalDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          state: ActionState.IN_PROGRESS,
          action: action,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => {
            rejectPendingRequestsForAction(portCall, action.id);
            resetUrlforAction();
            setOpenText(false);
          } 
        });
      }
    };
    setConfirmProps(appAutomationEnabled ? {...basicConfirmProps, warningMessage: t('PortCallTimelineMenu.Labels.AutomationArrivalWarning')} : basicConfirmProps);
    setOpenText(true);
  };

  const handleShiftArrival = () => {
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.ShiftArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.ShiftArrivalDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false) 
        });
      }
    });
    setOpenText(true);
  };

  const handlePortCallRecordArrival = () => {
    const checks = generateTimePlannedChecks(portCallReference.actions, action, CHECKS.BOTH);

    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.RecordArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.RecordArrivalDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

   const handleShiftRecordArrival = () => {
    const checks = generateTimePlannedChecks(portCallReference.actions, action, CHECKS.BOTH);

    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.RecordShiftArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.RecordShiftArrivalDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false) 
        });
      }
    });
    setOpenTime(true);
  };

  const handlePortCallDeparture = () => {
    const checks = generateTimePlannedChecks(portCallReference.actions, action, CHECKS.BEFORE);

    const basicTimeProps = {
      title: t('PortCallTimelineMenu.Labels.DepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.DepartureDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => {
            rejectPendingRequestsForAction(portCall, action.id);
            resetUrlforAction();
            setOpenTime(false);
          }
        });
      }
    }
    setTimeProps(appAutomationEnabled ? {...basicTimeProps, warningMessage: t('PortCallTimelineMenu.Labels.AutomationDepartureWarning')} : basicTimeProps);
    setOpenTime(true);
  };

  const handleShiftDeparture = () => {
    const checks = generateTimePlannedChecks(portCallReference.actions, action, CHECKS.BOTH);

    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.ShiftDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.ShiftDepartureDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

  const handlePortCallRecordDeparture = () => {
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.RecordDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.RecordDepartureDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => {
            rejectRequests(portCall.requests);
            setOpenText(false);
            history.push(navigationContext.defaultView);
          }
        });
      }
    });
    setOpenText(true);
  };

  const handleShiftRecordDeparture = () => {
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.RecordShiftDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.RecordShiftDepartureDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false) 
        });
      }
    });
    setOpenText(true);
  };


  // Record the time of when the custom action is recorded
  // On confirm set the action to In Progress and with a timeActual Value
  const handleCustomActionStart = () => {
    const checks = generateTimePlannedChecks(portCallReference.actions, action, CHECKS.BOTH);
    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.StartCustomActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      inputLabel: t('PortCallTimelineMenu.Labels.StartCustomActionDialogInput', { actionName: getActionName(t, action) }),
      initialValue: action.timePlanned,
      checks,
      onConfirm: async (value) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: '', 
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false) 
        });
      }
    });
    setOpenTime(true);
  };


  // Complete the custom action
  // On confirm set the action to COMPLETED
 const handleCustomActionRecord = () => {
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.RecordCustomActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      message: t('PortCallTimelineMenu.Labels.RecordCustomActionDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      onConfirm: async () => {
        await updateActionStateAsTransaction({
          action: action, 
          state: ActionState.COMPLETED,
          comment: '',
          auditMeta: auditMeta,
          onSuccess: () => setOpenConfirm(false)
        });
      }
    });
    setOpenConfirm(true);
  };

  const handleNextAction = () => {
    switch (nextAction) {
      case LifecycleLabels.START_DEPARTURE:
        handlePortCallDeparture();
        break;
      case LifecycleLabels.RECORD_DEPARTURE:
        handlePortCallRecordDeparture();
        break;
      case LifecycleLabels.START_ARRIVAL:
        handlePortCallArrival();
        break;
      case LifecycleLabels.RECORD_ARRIVAL:
        handlePortCallRecordArrival();
        break;
      case LifecycleLabels.START_SHIFT_DEPARTURE:
        handleShiftDeparture();
        break;
      case LifecycleLabels.RECORD_SHIFT_DEPARTURE:
        handleShiftRecordDeparture();
        break;
      case LifecycleLabels.START_SHIFT_ARRIVAL:
        handleShiftArrival();
        break;
      case LifecycleLabels.RECORD_SHIFT_ARRIVAL:
        handleShiftRecordArrival();
        break;
      case LifecycleLabels.START_CUSTOM:
        handleCustomActionStart();
        break;
      case LifecycleLabels.RECORD_CUSTOM:
        handleCustomActionRecord();
        break;
      default:
        return null;
    }
  };

  // maintain tab
  const tab = useEditorContentTab();
  let linkTo = `/port-call/${portCall.id}`;
  if (tab === EditorContentTabIds.REQUESTS)
    linkTo += "/requests";
  else if (tab === EditorContentTabIds.LOGBOOK)
    linkTo += "/logbook"


  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  const portCallDetails = { 
    status: portCall.status, 
    documents: portCall.documents,
    referenceId: portCall.referenceId
  };

  const actionDetails = {
    movementLocation: action?.movementLocation,
    state: action?.state
  }

  return (
    <>
        <>
          <Box position="relative" id={'PortCallTimelineCard'}>
            <Link
              className={classes.menuLink}
              to={linkTo}
            >
              <PortCallVesselCard 
                nextAction={nextAction}
                portCall={portCallDetails}
                actionId={actionId}
                vessel={portCall.vesselData}
                setMapDialogOpen={setMapDialogOpen}
                disableLifeCycle={disableLifeCycle}
                readOnly={readOnly}
                isSynced={isSynced}
                nextActionDisabled={nextActionDisabled}
                nextMovementDisabled={nextMovementDisabled}
                action={actionDetails}
                portCallRequests={portCallRequests}
                handleNextAction={handleNextAction}
              />
            </Link>
            <Box
              top="0"
              right="1rem"
              height="100%"
              display="flex"
              position="absolute"
              alignItems="center"
            >
              <PortCallTimelinePortCallMenu
                disabled={readOnly}
                contexts={['portCall']}
                ButtonProps={{
                  className: classes.moreButton
                }}
                portCall={portCallReference}
              />
            </Box>
          </Box>
          <PortCallTimelineActionMenu
            saveDisabled={saveDisabled}
            className={classes.addButton} 
            portCall={portCallReference} 
            actions={actions} 
            customActionTypes={customActionTypes.filter(at => !at.deleted)}
            id={'PortCallTimelineAddActionButton'}
          />
          <Box className={classes.scrollPane}>
            <Box
              display="flex"
              flexDirection="column"
            // padding="2rem"
            >
              <Box
                display="flex"
                minHeight="3rem"
                // account for 10rem margin setting
                width={`calc(${timelineWidth} - 10rem)`}
                margin="0 auto 0 10rem"
                flexDirection="column"
              >
                <Typography align="center" className={classes.portTypography} variant="caption">
                  {t("VesselItinerary.Labels.LastPort")}
                </Typography>
                {portCall.lastPort && <Typography align="center" className={classes.portTypography} id={'PortCallTimelineLastPort'}>
                  {`${portCall.lastPort.name} (${portCall.lastPort.countryCode} ${portCall.lastPort.portCode})`}
                </Typography>}
              </Box>
              <Box
                className={classes.timelineStripWrapper}
                position="relative"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                flexGrow="1"
                pt="2rem"
                pb="2rem"
                width={timelineWidth}
                margin="auto"
              >
                <div className={classes.timelineDottedLine} />
                <div className={classes.timelineFakeFadeTop} />
                <div className={classes.timelineFakeFadeBottom} />
                <Box className={classes.timelineStrip} />
                {sortedActions && sortedActions
                  .reduce((acc, item) => {
                    if(item.state !== ActionState.DELETED) {
                      const isRequested = actionRequests.length > 0 
                      ? actionRequests.some(({actionData}) => Array.isArray(actionData) && actionData.some(ad => ad.actionId === item.id && ad.approved !== RequestState.REQUEST_STATE_APPROVED))
                      : portCallCreateRequests.length > 0 
                      ? portCallCreateRequests.some(({actionData}) => Array.isArray(actionData) && actionData.some(ad => ad.actionId === item.id && ad.approved !== RequestState.REQUEST_STATE_APPROVED))
                      : portCallCancelRequests.length > 0
                      ? true
                      : false;
                      return acc.concat(
                        <TimelineAction
                          actionId={actionId}
                          item={item}
                          key={item.id}
                          portCall={portCall}
                          portCallMenuDisabled={readOnly}
                          requests={isRequested}
                          customActionTypes={customActionTypes}
                          portCallReference={portCallReference}
                        />
                      );
                    }
                    return acc;
                  }, [])
                }
              </Box>
              <Box
                display="flex"
                // account for 10rem margin setting
                width={`calc(${timelineWidth} - 10rem)`}
                margin="0 auto 5rem 10rem"
                flexDirection="column"
              >
                <Typography align="center" className={classes.portTypography} variant="caption">
                  {t("VesselItinerary.Labels.NextPort")}
                </Typography>
                {portCall.nextPort && <Typography align="center" className={classes.portTypography} id={'PortCallTimelineNextPort'}>
                  {`${portCall.nextPort.name} (${portCall.nextPort.countryCode} ${portCall.nextPort.portCode})`}
                </Typography>}
              </Box>
            </Box>
          </Box>
        </>
      <ConfirmDialog
        {...confirmProps} 
        onClose={() => setOpenConfirm(false)} 
        open={openConfirm}
      />
      {openTime && 
        <TimeInputDialog 
          {...timeProps} 
          modal={loading}
          loading={loading}
          error={{message: errorMessage, errorCode: error?.errorCode}}
          onClose={() => {
            setOpenTime(false);
            resetTransaction();
          }} 
          open={openTime}
        />}
      {openText && 
        <TextInputDialog 
          {...confirmProps}
          modal={loading}
          loading={loading}
          error={{message: errorMessage, errorCode: error?.errorCode}}
          onClose={() => {
            setOpenText(false)
            resetTransaction();
          }} 
          open={openText}
        />}
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={portCall.vesselData}
        />}
    </>
  )
}


PortCallTimeline.defaultProps = {};

PortCallTimeline.propTypes = {
  portCall: PropTypes.object,
  movementTypeFilter: PropTypes.array,
  disableLifeCycle: PropTypes.bool,
  portCallReference: PropTypes.object
};

export default PortCallTimeline;
