import React, {
  useContext,
  useEffect,
  useState, useMemo, useRef
} from 'react';
import {
  Button,
  Typography,
  Box,
  Card,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  ListItemIcon,
  Menu
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Close, Delete, DotsVertical, MenuDown, PlaylistCheck, PlaylistRemove, Send, CheckboxBlankCircle } from 'mdi-material-ui';
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import useAuditMeta from '../../hooks/useAuditMeta';
import { Action, ActionMovementType, ActionState, ActionType, Cargo, PortCall, PortCallStatus, Request, RequestState, RequestType } from '../../models';
import { getAuditLogEntryByObjectId } from '../../graphql/queries';
import { onCreateAuditLog } from '../../graphql/subscriptions';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { ActionMovementTypeLabelKeys } from '../../constants/ActionMovementTypeLabel'
import useInitialActionState from '../../hooks/useInitialActionState';
import { UIContext } from '../../contexts/ui';
import { getActionLastArrival, getActionsValidLifecycle, getActionTime, getActionValidMovements, getFirstAction, getRequestActionDataByMovementType } from '../../utils/getters';
import { RequestStateActionLabelsKeys, RequestStateLabelsKeys, RequestTypeLabelsKeys } from '../../constants/RequestConstants';
import { cancelPortCall } from '../PortCall/utils';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import LocationTimeline from '../Location/LocationTimeline';
import { ActionTypeIds } from '../../environment';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { format } from 'date-fns';
import { DateFnsLanguageMap } from '../../translations';
import { Trans, useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  notchedNoOutline: {
    border: "none"
  },
  buttonError: {
    borderColor: "#f44336 !important"
  },
  buttonPrimary: {
    borderColor: "rgb(000,150,214)"
  },
  changeButton: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
  },
  legendCustomIcon: {
    color: theme.palette.primary.main,
    fontSize: ".5rem",
    padding: "0 .5rem"
  },
  legendRequestedIcon: {
    color: "#ff9800",
    fontSize: ".5rem",
    padding: "0 .5rem"
  }
}));

const REQUEST_AUDITLOG_RENDER_COUNT = 2;

const formatTimestamp = (datetime, dateTimeFormat, language) => format(new Date(datetime), dateTimeFormat, { locale: DateFnsLanguageMap[language] });

const RequestMessageLog = ({ id }) => {
  const { userId } = useAuditMeta();
  const [logs, setLogs] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n } = useTranslation();
  useEffect(() => {
    const promise = API.graphql(graphqlOperation(getAuditLogEntryByObjectId, {
      objectId: id,
      sortDirection: 'ASC',
      filter: { comment: { size: { gt: 0 } } },
      limit: 100,
    }));
    const update = async () => {
      const result = await promise;
      if (result && result.data && result.data.getAuditLogEntryByObjectId.items.length > 0) {
        setLogs(result.data.getAuditLogEntryByObjectId.items)
      }
    }
    update();
    const subscription = API.graphql(graphqlOperation(onCreateAuditLog)).subscribe({
      next: ({ value }) => value?.data?.onCreateAuditLog?.comment && setLogs(prev => [...prev, value.data.onCreateAuditLog])
    });
    return () => {
      API.cancel(promise);
      subscription.unsubscribe();
    }
  }, [id]);
  const logsLast = useMemo(() => logs.slice(logs.length - REQUEST_AUDITLOG_RENDER_COUNT), [logs]);
  if (!logs.length) return null;

  const CommentNumber = ({ num }) => <b>{num}</b>
  return (
    <Box style={{ background: "#f8f8f8", borderTop: "1px solid #e2e2e2", padding: "1rem 0", paddingBottom: ".5rem", margin: "0 -1rem" }}>
      {logs.length > REQUEST_AUDITLOG_RENDER_COUNT &&
        <Button size="small" color="primary" style={{ marginBottom: ".5rem", marginLeft: ".5rem" }} onClick={() => setShowMore(prev => !prev)}>
          <Typography variant="body2" style={{ fontSize: "0.675rem" }}>
            <Trans
              i18nKey={showMore ? "RequestDetails.Labels.HideComments" : "RequestDetails.Labels.ShowComments"}
              components={{
                number: <CommentNumber num={logs.length - REQUEST_AUDITLOG_RENDER_COUNT} />
              }}
            />
          </Typography>
        </Button>
      }
      <List>
        {(showMore ? logs : logsLast).map(log =>
          <ListItem button disableRipple
          // style={{ background: log.userId === userId && "rgb(0 0 0 / 3%)" }}
          >
            <Box>
              <Box display="flex" alignItems="end" style={{ paddingBottom: "0.25rem" }}>
                <Typography variant="body2" color={log.userId === userId ? "primary" : "textSecondary"}>
                  {log.userId}
                </Typography>
                <Typography variant="caption" color="textSecondary" style={{ paddingLeft: "0.5rem", fontSize: ".675rem" }}>
                  {formatTimestamp(log.createdAt, dateTimeFormat, i18n.language)}
                </Typography>
              </Box>
              <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>{log.comment}</Typography>
            </Box>
          </ListItem>
        )}
      </List>
    </Box>
  )
}

const RequestStateActionIcons = {
  [RequestState.REQUEST_STATE_APPROVED]: <PlaylistCheck />,
  [RequestState.REQUEST_STATE_REJECTED]: <PlaylistRemove />,
}

const requestStateMenuItems = [
  // RequestState.REQUEST_STATE_PENDING,
  RequestState.REQUEST_STATE_APPROVED,
  RequestState.REQUEST_STATE_REJECTED
];

const requestCancelStateMenuItems = [
  // RequestState.REQUEST_STATE_PENDING,
  RequestState.REQUEST_STATE_APPROVED,
  // RequestState.REQUEST_STATE_REJECTED
];


const RequestStateSelect = ({ initialValue, value, setValue, error, options, movementType }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const classes = useStyles();
  const buttonClass = Boolean(error)
    ? classes.buttonError
    : value !== initialValue
      ? classes.buttonPrimary
      : undefined;//value !== initialValue ? "primary" : undefined
  return (
    <Box display="flex" flexDirection="column">
      <Button
        id={`RequestSelectButton${movementType ? `-${movementType}` : ""}`}
        className={buttonClass} color={value !== initialValue ? "primary" : undefined} variant="outlined" ref={anchorEl}
        onClick={() => setOpen(true)} disabled={agentPortal} style={{ alignSelf: "flex-end" }}>
        {value === initialValue ? t(RequestStateLabelsKeys[value]) : t(RequestStateActionLabelsKeys[value])}
        {!agentPortal && (value === initialValue
          ? <IconButton disabled size="small"> <MenuDown fontSize="small" /></IconButton>
          : <IconButton style={{ marginLeft: ".25rem" }} size="small" onClick={e => { setValue(initialValue); e.stopPropagation() }} onMouseDown={e => e.stopPropagation()}>
            <Close fontSize="small" />
          </IconButton>
        )}
      </Button>
      {error && <Typography variant="caption" id={"approvedError"} color="error" style={{ padding: ".25rem" }}>{error}</Typography>}
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={() => setOpen(false)}
      >
        {(options || requestStateMenuItems).map(i =>
          <MenuItem key={i} id={i} selected={value === i} onClick={() => { setValue(i); setOpen(false); }}>
            <ListItemIcon>{(i === RequestState.REQUEST_STATE_PENDING) ? t('RequestState.Labels.Pending') : RequestStateActionIcons[i]}</ListItemIcon>
            {t(RequestStateActionLabelsKeys[i])}
          </MenuItem>
        )}
      </Popover>
    </Box>
  )
}

const COMMENT_CHARACTER_LIMIT = 256;

const RequestActions = ({ comment, setComment, commentError, disabled, onSave }) => {
  const { t } = useTranslation();
  return (
    <Box m="0 -1rem -1rem">
      <TextField 
        id="RequestComment"
        label={t('RequestDetails.Labels.Comment')}
        variant="filled"
        multiline
        error={Boolean(commentError)}
        helperText={commentError}
        style={{ width: "100%" }}
        value={comment}
        onChange={(e) => setComment(e.target.value.substring(0, COMMENT_CHARACTER_LIMIT))}
        InputProps={{
          endAdornment:
            <Button id="UpdateRequestButton" color="primary" variant="contained" onClick={onSave} style={{ width: "15rem" }} disabled={disabled}>
              <Send fontSize="small" style={{ paddingRight: ".5rem" }} />
              <Typography noWrap>{t('RequestDetails.Labels.UpdateRequest')}</Typography>
            </Button>
        }}
      />
    </Box>
  )
}

const RequestDetailsHeader = ({ request }) => {
  const { t } = useTranslation()
  // for drop own menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n } = useTranslation();
  const auditMeta = useAuditMeta();
  const handleWithdrawRequest = async () => {
    setOpenConfirm(false);
    if (request.type === RequestType.REQUEST_TYPE_CREATE_PORTCALL) {
      await DataStore.save(Request.copyOf(request, updated => {
        updated.state = RequestState.REQUEST_STATE_WITHDRAWN
      }));

      const portCall = await DataStore.query(PortCall, request.requestPortCallId_)
      await DataStore.save(PortCall.copyOf(portCall, updated => {
        updated.status = PortCallStatus.DELETED
      }));

      const actions = await DataStore.query(Action, c => c.actionPortCallId_("eq", request.requestPortCallId_))
      for (let action of actions) {
        await DataStore.save(Action.copyOf(action, updated => {
          updated.state = ActionState.DELETED
        }));
      };

      const cargos = await DataStore.query(Cargo, c => c.cargoPortCallId("eq", request.requestPortCallId_));
      for (let cargo of cargos) {
        await DataStore.save(Cargo.copyOf(cargo, updated => {
          updated.deleted = true
        }));
      };
    }
    else {
      const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
      DataStore.save(Request.copyOf(request, updated => {
        updated.state = RequestState.REQUEST_STATE_WITHDRAWN;
        updated.portCall = portCall;
        updated.auditMeta = auditMeta;
      }));
      if (request.type === RequestType.REQUEST_TYPE_CREATE_DEPARTURE) {
        const action = await DataStore.query(Action, request.actionData[0].actionId)
        await DataStore.save(Action.copyOf(action, updated => {
          updated.state = ActionState.DELETED
        }));
      }
      // else if (request.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL) {}
      // else if (request.type === RequestType.REQUEST_TYPE_CANCEL_ACTION) {}
      // else if (request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED) {}
      // else if (request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED) {}
    }
    setOpenConfirm(false);
  };
  return (
    <Box display="flex" alignItems="center" style={{ textTransform: "uppercase" }}>
      <Typography color="textSecondary">
        {t(RequestTypeLabelsKeys[request.type])}
      </Typography>
      <Typography variant="caption" color="textSecondary" style={{ marginLeft: "auto" }}>
        <span style={{ opacity: .5 }}>{t('RequestDetails.Labels.LastUpdated')}</span> {formatTimestamp(request.updatedAt, dateTimeFormat, i18n.language)}
      </Typography>
      <IconButton style={{ marginLeft: "1rem" }} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <DotsVertical fontSize="small" />
      </IconButton>
      <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem onClick={() => { setOpenConfirm(true); setAnchorEl(null); }}>
          <ListItemIcon><Delete /></ListItemIcon>
          <ListItemText primary={t('RequestDetails.Labels.WithdrawRequest')} />
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={openConfirm}
        title={t('RequestDetails.Labels.WithdrawDialogTitle', { type: t(RequestTypeLabelsKeys[request.type]) })}
        message={t('RequestDetails.Labels.WithdrawDialogMessage')}
        onConfirm={handleWithdrawRequest}
        onClose={() => setOpenConfirm(false)}
        cancelText={t('Common.Buttons.Cancel')}
      />
    </Box>
  )
}

const RequestDateTimePicker = ({ label, value, setValue, disabled, error, clearValue, disableOutline }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" >
      <StringKeyboardDateTimePicker
        id='AgentRequestArrivalPlannedTime'
        warningPastDate={true}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        label={label}
        margin="normal"
        value={value}
        onChange={setValue}
        okLabel={t('Common.Buttons.Confirm')}
        keyboardIcon={disableOutline ? null : undefined}
        InputProps={{
          classes: disableOutline && { notchedOutline: classes.notchedNoOutline },
          startAdornment: clearValue &&
            <IconButton size="small" onClick={clearValue} disabled={disabled} style={{ position: "absolute", right: "3.5rem" }}>
              <Close fontSize="small" />
            </IconButton>
        }}
      />
    </Box>
  );
}

const RequestTimeline = ({ customPortCall, requestedPortCall }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const classes = useStyles();
  return (
    <Box margin="0 -1rem">
      <Box display="flex">
        <Button size="small" color="primary" style={{ marginBottom: ".5rem", marginLeft: ".5rem" }} onClick={() => setShow(prev => !prev)}>
          <Typography variant="body2" style={{ fontSize: "0.675rem" }}>
            {show ? t('RequestDetails.Labels.HideBerthUtilization') : t('RequestDetails.Labels.ShowBerthUtilization')}
          </Typography>
        </Button>
        {show && <Box display="flex" ml="auto" mr="1rem">
          {customPortCall && <Typography variant="caption" color="textSecondary"><CheckboxBlankCircle className={classes.legendCustomIcon} />{t('RequestDetails.Labels.Current')}</Typography>}
          {requestedPortCall && <Typography variant="caption" color="textSecondary"><CheckboxBlankCircle className={classes.legendRequestedIcon} />{t('RequestDetails.Labels.Requested')}</Typography>}
        </Box>}
      </Box>
      {show && <Box height="18rem">
        <LocationTimeline customPortCall={customPortCall} requestedPortCall={requestedPortCall} />
      </Box>}
    </Box>
  );
};

const RequestDetailsListItem = ({ label, movementType = null,
  currentTime, currentLocation,
  requestedTime, setRequestedTime, requestedTimeError, clearRequestedTime,
  time, setTime, timeError, clearTime, location, setLocation,
  approved, setApproved, approvedError, approvedOptions, initialApproved }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const [editTime, setEditTime] = useState(false);
  useEffect(() => {
    if (!requestedTime) setEditTime(false);
  }, [requestedTime]);
  const classes = useStyles();
  return (
    <Box>
      {label && <Typography color="textPrimary" style={{ marginBottom: ".5rem" }}>{label}</Typography>}
      <Box display="flex">
        {(currentTime || currentLocation) &&
          <Box display="flex" flexDirection="column" flexBasis="25%">
            {currentTime && <RequestDateTimePicker label={t('RequestDetails.Labels.CurrentTime')} disabled value={currentTime} disableOutline />}
            {currentLocation && <LocationAutocomplete label={t('RequestDetails.Labels.CurrentLocation')} disabled value={currentLocation} disableOutline />}
          </Box>
        }
        <Box display="flex" flexDirection="column" flexBasis="25%" ml={Boolean(currentTime || currentLocation) && "1rem"}>
          {(requestedTime || setRequestedTime) && <RequestDateTimePicker label={t('RequestDetails.Labels.RequestedTime')} disabled={!agentPortal} value={requestedTime} setValue={setRequestedTime} error={requestedTimeError} clearValue={clearRequestedTime} disableOutline={!setRequestedTime} />}
        </Box>
        {!agentPortal && (time || setTime) &&
          <Box display="flex" flexDirection="column" ml="1rem" flexBasis="25%">
            {editTime
              ? <RequestDateTimePicker label={t('RequestDetails.Labels.Time')} disabled={approved !== RequestState.REQUEST_STATE_APPROVED} value={time} setValue={setTime} error={timeError} clearValue={() => { clearTime(); setEditTime(false); }} />
              // I'm at a complete loss as to why <Button /> refuses to calculate height the same way as a TextField im sorry (笑)
              : <TextField
                variant="outlined"
                margin="normal"
                disabled={approved !== RequestState.REQUEST_STATE_APPROVED}
                InputProps={{
                  endAdornment: <Button className={classes.changeButton} disabled={approved !== RequestState.REQUEST_STATE_APPROVED} variant="outlined" onClick={() => { setTime(requestedTime); setEditTime(true); }}>{t('RequestDetails.Labels.ChangeTime')}</Button>
                }}
              />
            }
            {(location || setLocation) && <LocationAutocomplete label={t('RequestDetails.Labels.Location')} disabled={approved !== RequestState.REQUEST_STATE_APPROVED} value={location} onChange={setLocation} />}
          </Box>
        }
        <Box display="flex" alignItems="center" ml="auto">
          <RequestStateSelect movementType={movementType} value={approved} setValue={setApproved} initialValue={initialApproved} error={approvedError} options={approvedOptions} />
        </Box>
      </Box>
    </Box>
  )
}

const RequestDetailsItem_CreatePortCall = ({ portCall, request }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  // request data
  const arrivalActionData = useMemo(() => getRequestActionDataByMovementType(request, portCall.actions, ActionMovementType.ARRIVAL), [portCall?.actions, request]);
  const departureActionData = useMemo(() => getRequestActionDataByMovementType(request, portCall.actions, ActionMovementType.DEPARTURE), [portCall?.actions, request]);

  // port call data
  const arrivalAction = useMemo(() => portCall.actions.find(a => a.id === arrivalActionData.actionId), [portCall?.actions, arrivalActionData]);
  // const departureAction = useMemo(() => departureActionData && portCall.actions.find(a => a.id === departureActionData.actionId), [portCall?.actions, departureActionData]);

  // arrival
  const [requestedArrivalTime, setRequestedArrivalTime] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [arrivalLocation, setArrivalLocation] = useState(null);
  const [arrivalApproved, setArrivalApproved] = useState(null);
  useEffect(() => {
    if (!arrivalActionData) return;
    setRequestedArrivalTime(arrivalActionData.timePlanned);
    setArrivalApproved(arrivalActionData.approved);
  }, [arrivalActionData]);
  useEffect(() => {
    if (!arrivalAction) return;
    setArrivalLocation(arrivalAction.movementLocation);
  }, [arrivalAction]);

  // departure
  const [requestedDepartureTime, setRequestedDepartureTime] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [departureApproved, setDepartureApproved] = useState(departureActionData?.approved);
  useEffect(() => {
    if (!departureActionData) return;
    setRequestedDepartureTime(departureActionData.timeRequested);
    setDepartureApproved(departureActionData.approved);
  }, [departureActionData]);

  // times to use for validation (e.g. fall back to actual times stored in action in cases where requests are approved)
  const relevantArrivalTime = useMemo(() => portCall?.actions && getActionTime(getActionLastArrival(portCall.actions)), [portCall?.actions]);

  // validation cases
  const commentError = useMemo(() => !comment && (
    (arrivalApproved !== arrivalActionData.approved && arrivalApproved === RequestState.REQUEST_STATE_REJECTED) ||
    (departureActionData && departureApproved !== departureActionData.approved && departureApproved === RequestState.REQUEST_STATE_REJECTED)
  ) ? t('RequestDetails.Labels.ErrorCommentRequired') : null,
    [arrivalActionData, arrivalApproved, departureActionData, departureApproved, comment, t]);
  const requestedDepartureTimeError = useMemo(() => !departureActionData ? false :
    (!requestedDepartureTime && t('RequestDetails.Labels.ErrorInvalidTime')) ||
    requestedDepartureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [requestedDepartureTime, relevantArrivalTime, t]);
  const departureTimeError = useMemo(() =>
    departureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [departureTime, relevantArrivalTime, t]);
  const departureApprovedError = useMemo(() =>
    (arrivalApproved !== RequestState.REQUEST_STATE_APPROVED && departureApproved === RequestState.REQUEST_STATE_APPROVED) && t('RequestDetails.Labels.ErrorArrivalApproved'),
    [arrivalApproved, departureApproved, t]);

  const handleSave = async () => {
    setLoading(true);
    const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.actionData = [...updated.actionData.filter(ad => ad.actionId !== arrivalActionData.actionId), {
        ...arrivalActionData,
        timeRequested: agentPortal ? requestedArrivalTime : arrivalActionData.timeRequested,
        timePlanned: arrivalTime || requestedArrivalTime,
        approved: (agentPortal && arrivalActionData.timeRequested !== requestedArrivalTime) ? RequestState.REQUEST_STATE_PENDING : arrivalApproved,
        locationId: arrivalLocation ? arrivalLocation.id : arrivalActionData.locationId
      }];
      if (departureActionData) {
        updated.actionData = [...updated.actionData.filter(ad => ad.actionId !== departureActionData.actionId), {
          ...departureActionData,
          timeRequested: agentPortal ? requestedDepartureTime : departureActionData.timeRequested,
          timePlanned: departureTime || requestedDepartureTime,
          approved: (agentPortal && departureActionData.timeRequested !== requestedDepartureTime) ? RequestState.REQUEST_STATE_PENDING : departureApproved,
          locationId: arrivalLocation ? arrivalLocation.id : arrivalActionData.locationId
        }];
      }
      updated.state = arrivalApproved;
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.portCall = portCall;
    }));
    if (!agentPortal) {
      if (arrivalApproved !== arrivalActionData.approved && arrivalApproved === RequestState.REQUEST_STATE_APPROVED) {
        const action = await DataStore.query(Action, arrivalActionData.actionId);
        await DataStore.save(PortCall.copyOf(portCall, updated => {
          updated.status = PortCallStatus.PREARRIVAL
        }));
        await DataStore.save(Action.copyOf(action, updated => {
          updated.timePlanned = arrivalTime || arrivalActionData.timeRequested;
          updated.state = getInitialActionState(portCall?.vessel?.id);
          updated.auditMeta = auditMeta;
          if (arrivalLocation) updated.movementLocation = arrivalLocation;
        }));
      }
      if (departureActionData && departureApproved !== departureActionData.approved && departureApproved === RequestState.REQUEST_STATE_APPROVED) {
        const action = await DataStore.query(Action, departureActionData.actionId);
        await DataStore.save(Action.copyOf(action, updated => {
          updated.timePlanned = departureTime || departureActionData.timeRequested;
          updated.state = getInitialActionState(portCall?.vessel?.id);
          updated.auditMeta = auditMeta;
          if (arrivalLocation) updated.movementLocation = arrivalLocation;
        }));
      }
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || departureApprovedError || (departureTime ? departureTimeError : requestedDepartureTimeError) || commentError ||
    (!comment
      && arrivalApproved === arrivalActionData?.approved
      && requestedArrivalTime === arrivalActionData?.timeRequested
      && (!departureActionData || (
        departureApproved === departureActionData?.approved
        && requestedDepartureTime === departureActionData?.timeRequested)
      )
    );

  // needed to reconstruct a port call
  const [actionMovementType, setActionMovementType] = useState(null);
  useEffect(() => {
    (async () => setActionMovementType && setActionMovementType(await DataStore.query(ActionType, ActionTypeIds.MOVEMENT)))()
  }, [setActionMovementType]);

  // construct a temporary port call for rendering from request data in LocationTimeline
  const requestedPortCall = useMemo(() => actionMovementType && PortCall.copyOf(portCall, updated => {
    const arrival = new Action({
      type: actionMovementType,
      movementType: ActionMovementType.ARRIVAL,
      movementLocation: arrivalLocation,
      timePlanned: arrivalTime || arrivalActionData.timePlanned
    });
    const departure = departureActionData && new Action({
      type: actionMovementType,
      movementType: ActionMovementType.DEPARTURE,
      movementLocation: arrivalLocation,
      timePlanned: departureTime || departureActionData.timePlanned
    });
    updated.actions = [
      arrival,
      ...departure ? [departure] : []
    ];
  }), [portCall, arrivalTime, arrivalActionData, departureTime, departureActionData, actionMovementType, arrivalLocation]);
  // filter out planned actions for rendering in LocationTimeline
  const customPortCall = useMemo(() => {
    if (portCall) {
      const actions = getActionValidMovements(portCall.actions);
      if (actions.length)
        return PortCall.copyOf(portCall, updated => {
          updated.actions = actions;
        });
    }
    return null;
  }, [portCall]);

  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        {arrivalActionData.approved !== RequestState.REQUEST_STATE_APPROVED &&
          <RequestDetailsListItem
            movementType={ActionMovementType.ARRIVAL}
            label={t(ActionMovementTypeLabelKeys[ActionMovementType.ARRIVAL])}
            requestedTime={requestedArrivalTime} setRequestedTime={agentPortal && setRequestedArrivalTime} clearRequestedTime={requestedArrivalTime !== arrivalActionData.timeRequested && (() => setRequestedArrivalTime(arrivalActionData.timeRequested))}
            time={arrivalTime} setTime={setArrivalTime} clearTime={arrivalTime && (() => setArrivalTime(null))}
            location={arrivalLocation} setLocation={setArrivalLocation}
            approved={arrivalApproved} setApproved={setArrivalApproved} initialApproved={arrivalActionData.approved}
          />
        }
        {departureActionData && departureActionData.approved !== RequestState.REQUEST_STATE_APPROVED &&
          <RequestDetailsListItem
            movementType={ActionMovementType.DEPARTURE}
            label={t(ActionMovementTypeLabelKeys[ActionMovementType.DEPARTURE])}
            requestedTime={requestedDepartureTime} setRequestedTime={agentPortal && setRequestedDepartureTime} clearRequestedTime={requestedDepartureTime !== departureActionData.timeRequested && (() => setRequestedDepartureTime(departureActionData.timeRequested))} requestedTimeError={requestedDepartureTimeError}
            time={departureTime} setTime={setDepartureTime} clearTime={departureTime && (() => setDepartureTime(null))} timeError={departureTimeError}
            approved={departureApproved} setApproved={setDepartureApproved} approvedError={departureApprovedError} initialApproved={departureActionData.approved}
          />
        }
      </List>
      {!agentPortal && <RequestTimeline customPortCall={customPortCall} requestedPortCall={requestedPortCall} />}
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} commentError={commentError} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}

const RequestDetailsItem_CancelPortCall = ({ portCall, request }) => {
  const [{ agentPortal }] = useContext(UIContext);
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [approved, setApproved] = useState(request?.state);
  useEffect(() => {
    if (!request) return;
    setApproved(request.state);
  }, [request])

  const handleSave = async () => {
    setLoading(true);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.state = approved;
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.portCall = portCall;
    }));
    if (!agentPortal && approved === RequestState.REQUEST_STATE_APPROVED) {
      await cancelPortCall(portCall);
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || (!comment && approved === request.state);
  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        <RequestDetailsListItem approved={approved} setApproved={setApproved} initialApproved={request.state} approvedOptions={requestCancelStateMenuItems} />
      </List>
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}

const RequestDetailsItem_CancelAction = ({ portCall, request }) => {
  const [{ agentPortal }] = useContext(UIContext);
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [approved, setApproved] = useState(request?.state);
  useEffect(() => {
    if (!request) return;
    setApproved(request.state);
  }, [request])

  const handleSave = async () => {
    setLoading(true);
    const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.state = approved;
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.actionData = [{ ...updated.actionData[0], approved }];
      updated.portCall = portCall;
    }));
    if (!agentPortal && approved === RequestState.REQUEST_STATE_APPROVED) {
      const action = await DataStore.query(Action, request.actionData[0].actionId);
      await DataStore.save(Action.copyOf(action, updated => {
        updated.state = ActionState.CANCELLED;
        updated.auditMeta = auditMeta;
      }));
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || (!comment && approved === request.state);
  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        <RequestDetailsListItem approved={approved} setApproved={setApproved} initialApproved={request.state} approvedOptions={requestCancelStateMenuItems} />
      </List>
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}

const RequestDetailsItem_CreateActionDeparture = ({ portCall, request }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  // request data
  const departureActionData = useMemo(() => request?.actionData[0], [portCall?.actions, request]);

  // departure
  const [requestedDepartureTime, setRequestedDepartureTime] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [departureApproved, setDepartureApproved] = useState(departureActionData?.approved);
  useEffect(() => {
    if (!departureActionData) return;
    setRequestedDepartureTime(departureActionData.timeRequested);
    setDepartureApproved(departureActionData.approved);
  }, [departureActionData]);

  // times to use for validation (e.g. fall back to actual times stored in action in cases where requests are approved)
  const relevantArrivalTime = useMemo(() => portCall?.actions && getActionTime(getActionLastArrival(portCall.actions)), [portCall?.actions]);

  // validation cases
  const commentError = useMemo(() => !comment && departureApproved !== departureActionData.approved && departureApproved === RequestState.REQUEST_STATE_REJECTED ? t('RequestDetails.Labels.ErrorCommentRequired') : null, [departureActionData, departureApproved, comment, t]);
  const requestedDepartureTimeError = useMemo(() => !departureActionData ? false :
    (!requestedDepartureTime && t('RequestDetails.Labels.ErrorInvalidTime')) ||
    requestedDepartureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [requestedDepartureTime, relevantArrivalTime, t]);
  const departureTimeError = useMemo(() =>
    departureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [departureTime, relevantArrivalTime, t]);

  const handleSave = async () => {
    setLoading(true);
    const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.actionData = [{
        ...departureActionData,
        timeRequested: agentPortal ? requestedDepartureTime : departureActionData.timeRequested,
        timePlanned: departureTime || requestedDepartureTime,
        approved: (agentPortal && departureActionData.timeRequested !== requestedDepartureTime) ? RequestState.REQUEST_STATE_PENDING : departureApproved
      }];
      if (departureApproved === RequestState.REQUEST_STATE_APPROVED) {
        updated.state = RequestState.REQUEST_STATE_APPROVED;
      }
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.portCall = portCall;
    }));
    if (!agentPortal) {
      if (departureApproved !== departureActionData.approved && departureApproved === RequestState.REQUEST_STATE_APPROVED) {
        const action = await DataStore.query(Action, departureActionData.actionId);
        await DataStore.save(Action.copyOf(action, updated => {
          updated.timePlanned = departureTime || departureActionData.timeRequested;
          updated.state = getInitialActionState(portCall?.vessel?.id);
          updated.auditMeta = auditMeta;
        }));
      }
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || (departureTime ? departureTimeError : requestedDepartureTimeError) || commentError ||
    (!comment
      && departureApproved === departureActionData?.approved
      && requestedDepartureTime === departureActionData?.timePlanned);

  // needed to reconstruct a port call
  const [actionMovementType, setActionMovementType] = useState(null);
  useEffect(() => {
    (async () => setActionMovementType && setActionMovementType(await DataStore.query(ActionType, ActionTypeIds.MOVEMENT)))()
  }, [setActionMovementType]);

  // construct a temporary port call for rendering from request data in LocationTimeline
  const requestedPortCall = useMemo(() => actionMovementType && PortCall.copyOf(portCall, updated => {
    const departure = departureActionData && new Action({
      type: actionMovementType,
      movementType: ActionMovementType.DEPARTURE,
      movementLocation: getActionLastArrival(portCall.actions).movementLocation,
      timePlanned: departureTime || departureActionData.timePlanned
    });
    updated.actions = [
      ...portCall.actions.filter(a => a.movementType !== ActionMovementType.DEPARTURE),
      ...departure ? [departure] : []
    ];
  }), [portCall, departureTime, departureActionData, actionMovementType]);
  // filter out planned actions for rendering in LocationTimeline
  const customPortCall = useMemo(() => {
    if (portCall) {
      const actions = getActionValidMovements(portCall.actions);
      if (actions.length)
        return PortCall.copyOf(portCall, updated => {
          updated.actions = actions;
        });
    }
    return null;
  }, [portCall]);

  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        {departureActionData && departureActionData.approved !== RequestState.REQUEST_STATE_APPROVED &&
          <RequestDetailsListItem
            requestedTime={requestedDepartureTime} setRequestedTime={agentPortal && setRequestedDepartureTime} clearRequestedTime={requestedDepartureTime !== departureActionData.timeRequested && (() => setRequestedDepartureTime(departureActionData.timeRequested))} requestedTimeError={requestedDepartureTimeError}
            time={departureTime} setTime={setDepartureTime} clearTime={departureTime && (() => setDepartureTime(null))} timeError={departureTimeError}
            approved={departureApproved} setApproved={setDepartureApproved} initialApproved={departureActionData.approved}
          />
        }
      </List>
      {!agentPortal && <RequestTimeline customPortCall={customPortCall} requestedPortCall={requestedPortCall} />}
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} commentError={commentError} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}


const RequestDetailsItem_UpdateActionDepartureTimePlanned = ({ portCall, request }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  // request data
  const departureActionData = useMemo(() => getRequestActionDataByMovementType(request, portCall?.actions, ActionMovementType.DEPARTURE), [portCall?.actions, request]);

  // port call data
  const action = useMemo(() => departureActionData && portCall.actions.find(a => a.id === departureActionData.actionId), [portCall?.actions, departureActionData]);

  // departure
  const [requestedDepartureTime, setRequestedDepartureTime] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [departureApproved, setDepartureApproved] = useState(departureActionData?.approved);
  useEffect(() => {
    if (!departureActionData) return;
    setRequestedDepartureTime(departureActionData.timeRequested);
    setDepartureApproved(departureActionData.approved);
  }, [departureActionData]);

  // times to use for validation (e.g. fall back to actual times stored in action in cases where requests are approved)
  const relevantArrivalTime = useMemo(() => portCall?.actions && getActionTime(getActionLastArrival(portCall.actions)), [portCall?.actions]);

  // validation cases
  const commentError = useMemo(() => !comment && departureApproved !== departureActionData?.approved && departureApproved === RequestState.REQUEST_STATE_REJECTED ? t('RequestDetails.Labels.ErrorCommentRequired') : null, [departureActionData, departureApproved, comment, t]);
  const requestedDepartureTimeError = useMemo(() => !departureActionData ? false :
    (!requestedDepartureTime && t('RequestDetails.Labels.ErrorInvalidTime')) ||
    requestedDepartureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [requestedDepartureTime, relevantArrivalTime, t]);
  const departureTimeError = useMemo(() =>
    departureTime <= relevantArrivalTime && t('RequestDetails.Labels.ErrorDepartureAfterArrival'),
    [departureTime, relevantArrivalTime, t]);

  const handleSave = async () => {
    setLoading(true);
    const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.actionData = [{
        ...departureActionData,
        timeRequested: agentPortal ? requestedDepartureTime : departureActionData.timeRequested,
        timePlanned: departureTime || requestedDepartureTime,
        approved: (agentPortal && departureActionData.timeRequested !== requestedDepartureTime) ? RequestState.REQUEST_STATE_PENDING : departureApproved
      }];
      if (departureApproved === RequestState.REQUEST_STATE_APPROVED) {
        updated.state = RequestState.REQUEST_STATE_APPROVED;
      }
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.portCall = portCall;
    }));
    if (!agentPortal) {
      if (departureApproved !== departureActionData.approved && departureApproved === RequestState.REQUEST_STATE_APPROVED) {
        const action = await DataStore.query(Action, departureActionData.actionId);
        await DataStore.save(Action.copyOf(action, updated => {
          updated.timePlanned = departureTime || departureActionData.timeRequested;
          updated.auditMeta = auditMeta;
        }));
      }
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || (departureTime ? departureTimeError : requestedDepartureTimeError) || commentError ||
    (!comment
      && departureApproved === departureActionData?.approved
      && requestedDepartureTime === departureActionData?.timePlanned);

  // needed to reconstruct a port call
  const [actionMovementType, setActionMovementType] = useState(null);
  useEffect(() => {
    (async () => setActionMovementType && setActionMovementType(await DataStore.query(ActionType, ActionTypeIds.MOVEMENT)))()
  }, [setActionMovementType]);

  // construct a temporary port call for rendering from request data in LocationTimeline
  const requestedPortCall = useMemo(() => actionMovementType && PortCall.copyOf(portCall, updated => {
    const departure = departureActionData && new Action({
      type: actionMovementType,
      movementType: ActionMovementType.DEPARTURE,
      movementLocation: getActionLastArrival(portCall.actions).movementLocation,
      timePlanned: departureTime || departureActionData.timePlanned
    });
    updated.actions = [
      ...portCall.actions.filter(a => a.movementType !== ActionMovementType.DEPARTURE),
      ...departure ? [departure] : []
    ];
  }), [portCall, departureTime, departureActionData, actionMovementType]);
  // filter out planned actions for rendering in LocationTimeline
  const customPortCall = useMemo(() => {
    if (portCall) {
      const actions = getActionValidMovements(portCall.actions);
      if (actions.length)
        return PortCall.copyOf(portCall, updated => {
          updated.actions = actions;
        });
    }
    return null;
  }, [portCall]);

  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        {departureActionData && departureActionData.approved !== RequestState.REQUEST_STATE_APPROVED &&
          <RequestDetailsListItem
            currentTime={getActionTime(action)}
            requestedTime={requestedDepartureTime} setRequestedTime={agentPortal && setRequestedDepartureTime} clearRequestedTime={requestedDepartureTime !== departureActionData.timeRequested && (() => setRequestedDepartureTime(departureActionData.timeRequested))} requestedTimeError={requestedDepartureTimeError}
            time={departureTime} setTime={setDepartureTime} clearTime={departureTime && (() => setDepartureTime(null))} timeError={departureTimeError}
            approved={departureApproved} setApproved={setDepartureApproved} initialApproved={departureActionData.approved}
          />
        }
      </List>
      {!agentPortal && <RequestTimeline customPortCall={customPortCall} requestedPortCall={requestedPortCall} />}
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} commentError={commentError} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}

const RequestDetailsItem_UpdateActionArrivalTimePlanned = ({ portCall, request }) => {
  const { t } = useTranslation();
  const [{ agentPortal }] = useContext(UIContext);
  const auditMeta = useAuditMeta();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  // request data
  const arrivalActionData = useMemo(() => request?.actionData[0], [portCall?.actions, request]);

  // port call data
  const action = useMemo(() => arrivalActionData && portCall.actions.find(a => a.id === arrivalActionData.actionId), [portCall?.actions, arrivalActionData]);

  // arrival
  const [requestedArrivalTime, setRequestedArrivalTime] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [arrivalApproved, setArrivalApproved] = useState(arrivalActionData?.approved);
  useEffect(() => {
    if (!arrivalActionData) return;
    setRequestedArrivalTime(arrivalActionData.timeRequested);
    setArrivalApproved(arrivalActionData.approved);
  }, [arrivalActionData]);

  // times to use for validation (e.g. fall back to actual times stored in action in cases where requests are approved)
  const firstNextActionTime = useMemo(() => portCall?.actions && getActionTime(getFirstAction(getActionsValidLifecycle(portCall.actions).filter((action) => action.movementType !== ActionMovementType.ARRIVAL))), [portCall?.actions]);

  // validation cases
  const commentError = useMemo(() => !comment && arrivalApproved !== arrivalActionData.approved && arrivalApproved === RequestState.REQUEST_STATE_REJECTED ? t('RequestDetails.Labels.ErrorCommentRequired') : null, [arrivalActionData, arrivalApproved, comment, t]);
  const requestedArrivalTimeError = useMemo(() => !arrivalActionData ? false :
    (!requestedArrivalTime && t('RequestDetails.Labels.ErrorInvalidTime')) ||
    (firstNextActionTime && requestedArrivalTime >= firstNextActionTime && t('RequestDetails.Labels.ErrorArrivalBeforeAction')),
    [requestedArrivalTime, firstNextActionTime, t]);
  const arrivalTimeError = useMemo(() =>
    firstNextActionTime && arrivalTime >= firstNextActionTime && t('RequestDetails.Labels.ErrorArrivalBeforeAction'),
    [arrivalTime, firstNextActionTime, t]);

  const handleSave = async () => {
    setLoading(true);
    const portCall = await DataStore.query(PortCall, request.requestPortCallId_);
    await DataStore.save(Request.copyOf(request, updated => {
      updated.actionData = [{
        ...arrivalActionData,
        timeRequested: agentPortal ? requestedArrivalTime : arrivalActionData.timeRequested,
        timePlanned: arrivalTime || requestedArrivalTime,
        approved: (agentPortal && arrivalActionData.timeRequested !== requestedArrivalTime) ? RequestState.REQUEST_STATE_PENDING : arrivalApproved
      }];
      if (arrivalApproved === RequestState.REQUEST_STATE_APPROVED) {
        updated.state = RequestState.REQUEST_STATE_APPROVED;
      }
      updated.auditMeta = { ...auditMeta, comment: comment || null };
      updated.portCall = portCall;
    }));
    if (!agentPortal) {
      if (arrivalApproved !== arrivalActionData.approved && arrivalApproved === RequestState.REQUEST_STATE_APPROVED) {
        const action = await DataStore.query(Action, arrivalActionData.actionId);
        await DataStore.save(Action.copyOf(action, updated => {
          updated.timePlanned = arrivalTime || arrivalActionData.timeRequested;
          updated.auditMeta = auditMeta;
        }));
      }
    }
    setComment('');
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 1000)
  };

  const saveDisabled = loading || arrivalTimeError || requestedArrivalTimeError || commentError ||
    (!comment
      && arrivalApproved === arrivalActionData?.approved
      && requestedArrivalTime === arrivalActionData?.timePlanned);

  // needed to reconstruct a port call
  const [actionMovementType, setActionMovementType] = useState(null);
  useEffect(() => {
    (async () => setActionMovementType && setActionMovementType(await DataStore.query(ActionType, ActionTypeIds.MOVEMENT)))()
  }, [setActionMovementType]);

  // construct a temporary port call for rendering from request data in LocationTimeline
  const requestedPortCall = useMemo(() => actionMovementType && PortCall.copyOf(portCall, updated => {
    const arrival = arrivalActionData && new Action({
      type: actionMovementType,
      movementType: ActionMovementType.ARRIVAL,
      movementLocation: getActionLastArrival(portCall.actions).movementLocation,
      timePlanned: arrivalTime || arrivalActionData.timePlanned
    });
    updated.actions = [
      ...arrival ? [arrival] : [],
      ...portCall.actions.filter(a => a.movementType !== ActionMovementType.ARRIVAL),
    ];
  }), [portCall, arrivalTime, arrivalActionData, actionMovementType]);
  // filter out planned actions for rendering in LocationTimeline
  const customPortCall = useMemo(() => {
    if (portCall) {
      const actions = getActionValidMovements(portCall.actions);
      if (actions.length)
        return PortCall.copyOf(portCall, updated => {
          updated.actions = actions;
        });
    }
    return null;
  }, [portCall]);

  return (
    <>
      <List style={{ padding: "1rem 0" }}>
        {arrivalActionData && arrivalActionData.approved !== RequestState.REQUEST_STATE_APPROVED &&
          <RequestDetailsListItem
            currentTime={getActionTime(action)}
            requestedTime={requestedArrivalTime} setRequestedTime={agentPortal && setRequestedArrivalTime} clearRequestedTime={requestedArrivalTime !== arrivalActionData.timeRequested && (() => setRequestedArrivalTime(arrivalActionData.timeRequested))} requestedTimeError={requestedArrivalTimeError}
            time={arrivalTime} setTime={setArrivalTime} clearTime={arrivalTime && (() => setArrivalTime(null))} timeError={arrivalTimeError}
            approved={arrivalApproved} setApproved={setArrivalApproved} initialApproved={arrivalActionData.approved}
          />
        }
      </List>
      {!agentPortal && <RequestTimeline customPortCall={customPortCall} requestedPortCall={requestedPortCall} />}
      <RequestMessageLog id={request.id} />
      <RequestActions request={request} comment={comment} setComment={setComment} commentError={commentError} onSave={handleSave} disabled={saveDisabled} />
    </>
  )
}

const RequestDetails = ({ portCall, request, ...other }) => {
  return (
    <Card style={{ margin: "1rem 2rem", position: "relative" }} variant="outlined" {...other}>
      <Box p="1rem">
        <RequestDetailsHeader request={request} />
        {request.type === RequestType.REQUEST_TYPE_CREATE_PORTCALL && <RequestDetailsItem_CreatePortCall portCall={portCall} request={request} />}
        {request.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL && <RequestDetailsItem_CancelPortCall portCall={portCall} request={request} />}
        {request.type === RequestType.REQUEST_TYPE_CANCEL_ACTION && <RequestDetailsItem_CancelAction portCall={portCall} request={request} />}
        {request.type === RequestType.REQUEST_TYPE_CREATE_DEPARTURE && <RequestDetailsItem_CreateActionDeparture portCall={portCall} request={request} />}
        {request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED && <RequestDetailsItem_UpdateActionArrivalTimePlanned portCall={portCall} request={request} />}
        {request.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED && <RequestDetailsItem_UpdateActionDepartureTimePlanned portCall={portCall} request={request} />}
      </Box>
    </Card>
  );
};
export default RequestDetails;