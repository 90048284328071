import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PortCallStatus } from '../../models';
import { capitalize } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  PortCalTmelineState: {
    ...theme.typography.button,
    color: theme.palette.text.disabled,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    padding: '6px 16px',
    }
}));

export const PortCallTimelineStatusLabel = ({
  portCall
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  let portCallStatusKey = ''
  if(portCall.status == PortCallStatus.CLOSED) {
    portCallStatusKey = 'PortCallStatus.Labels.Closed';
  }else if(portCall.status == PortCallStatus.CANCELLED) {
    portCallStatusKey = 'PortCallStatus.Labels.Cancelled';
  }

  return (
    <>
      {portCallStatusKey && <Box className={classes.PortCalTmelineState}>
        <Typography id={'PortCallTimelineStatusLabel'}>{capitalize(t(portCallStatusKey))}</Typography>
      </Box>
      }
    </>
  )
}

export default PortCallTimelineStatusLabel;
