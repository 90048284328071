import { Grid, DialogContent, makeStyles, Typography, DialogTitle, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { Close,} from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';

import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { getActionArrival, getActionDeparture, getActionTime, getLocationsMap, getParentLocationName, getPortCallCurrencySetting } from '../../utils/getters';
import { getPortCallChargeableItems } from '../../utils/chargeableItems'
import { clientName } from '../../environment';
import { LIST_OF_CHARGES_COLUMNS, SCHEDULE_SUMMARY_COLUMNS, VESSEL_DETAILS_COLUMNS  } from '../../constants/InvoiceConstants';
import GenericTable from './GenericTable';
import { getListOfChargesRows, getScheduleSummaryRows, getVesselDetailsRows } from './rows';
import { getChargeableItemsGrossTotal } from '../../utils/chargeableItems'; 

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '75%',
      maxWidth: '75%',
      padding: '0 1.2rem 1.2rem 1.2rem',
    },
  },
  divider: {
    marginBottom: '2rem'
  },
  halfTable: {
    width: '50%'
  },
  subHeader: {
    fontSize: '1rem',
    padding: '0 24px 16px 24px'
  }
}));

const InvoiceDialog = ({ open, onClose, portCall }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { dateTimeFormat } = useDateTimeSetting();
  const { vesselData, actions } = portCall;
  const { settings, locations } = useContext(DataStoreContext);
  const currencySettings = useMemo(() => getPortCallCurrencySetting(settings), [settings]);
  const currency = currencySettings?.code
  const {language} = i18n;
  const arrival = getActionArrival(actions);
  const departure = getActionDeparture(actions);
  const arrivalTime = getActionTime(arrival)
  const departureTime = getActionTime(departure)
  const locationsMap = getLocationsMap(locations)
  const portName = getParentLocationName(arrival?.movementLocation, locationsMap);
  const portCallChargeableItems = getPortCallChargeableItems(portCall);
  const grossTotal = getChargeableItemsGrossTotal(portCallChargeableItems)
  const portCallChargeableItemsExist = portCallChargeableItems && portCallChargeableItems.length > 0;
  const listOfChargesRows = getListOfChargesRows(portCallChargeableItems, language, currency, grossTotal, t)
  const vesselDetailsRows = getVesselDetailsRows(vesselData)
  const scheduleSummaryRows = getScheduleSummaryRows(portName, arrival, arrivalTime, departure, departureTime, dateTimeFormat, i18n)

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog id='invoiceDialog' onClose={handleClose} aria-labelledby="invoice" open={open} className={classes.dialog}>
      <Grid container justifyContent="flex-end">
        <IconButton aria-label="close" onClick={onClose} id={'closeInvoice'}>
          <Close className={classes.closeButton}/>
        </IconButton>
      </Grid>
      <DialogTitle>{clientName} - {t('Invoice.Labels.Invoice')}</DialogTitle>
      <Typography variant='h3' className={classes.subHeader}>{`${t('Invoice.Labels.PortCallId')} : ${portCall.referenceId}`}</Typography>
      <DialogContent>
        <Box className={classes.halfTable}>
        <GenericTable
        title={t('Invoice.Labels.ScheduleSummary')}
        columns={SCHEDULE_SUMMARY_COLUMNS}
        rows={scheduleSummaryRows} />
      </Box>
      <Box className={classes.halfTable}>
        <GenericTable
        title={t('Invoice.Labels.VesselDetails')}
        columns={VESSEL_DETAILS_COLUMNS}
        rows={vesselDetailsRows} />
      </Box>
      {portCallChargeableItemsExist ? <GenericTable
        title={t('Invoice.Labels.ListOfCharges')}
        columns={LIST_OF_CHARGES_COLUMNS}
        rows={listOfChargesRows}
        emphasiseLastRow={true}
        /> :
        <Typography>{t('Invoice.Labels.NoItems')}</Typography>}
      </DialogContent>
    </Dialog>
  );
}
export default InvoiceDialog;