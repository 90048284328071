import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import {
  CardContent,
  Typography,
} from '@material-ui/core';

import ActionScheduleEdit from './ActionScheduleEdit';
import ActionVesselEdit from './ActionVesselEdit';
import ActionPropulsionEdit from './ActionPropulsionEdit';
import ChargeablesEditor from '../Chargeables/ChargeablesEditor';
import { Action, FileType } from '../../models';
import { DataStore } from 'aws-amplify';
import { ActionDetailsHref } from '../../constants/ActionDetails';
import PortCallDataEdit from '../PortCallData/PortCallDataEdit';
import { FileTypeLabels } from '../../constants/PortCallDataLabels';
import Feature from '../Feature/Feature';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const MovementEditor = ({
  portCall,
  portCallReference,
  setPortCall,
  action,
  setAction,
  selectedRequest,
  setSelectedRequest,
  activeAgent,
  saveDisabled,
  checkSaveState,
  handleLocalError
}) => {

  // TODO until datastore supports empty string arrays
  const documents = useMemo(() => action && action.documents ? action.documents.filter(d => d) : [], [action]);
  const setDocuments = (value) => DataStore.save(Action.copyOf(action, updated => { updated.documents = value.length ? value : ['']; }));
  const { t } = useTranslation();

  const handleChangeTodos = async (todos) => {
    setAction(Action.copyOf(action, updated => { updated.todos = todos; }));
  };

  return (
    <>
      <ActionScheduleEdit
        portCallReference={portCallReference}
        selectedRequest={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        portCall={portCall}
        setPortCall={setPortCall}
        action={action}
        setAction={setAction}
        activeAgent={activeAgent}
        objDocuments={documents}
        setDocuments={setDocuments}
        checkSaveState={checkSaveState}
        saveDisabled={saveDisabled}
        handleChangeTodos={handleChangeTodos}
        handleLocalError={handleLocalError}
      />
      <CardContent>
        <ActionVesselEdit
          action={action}
          setAction={setAction}
          // dispatchAdjustSailingDraught={dispatchAdjustSailingDraught}
        />
        <ActionPropulsionEdit
          action={action}
          setAction={setAction}
          vesselData={portCall.vesselData}
        />
      </CardContent>
      <CardContent>
        <Typography
          id={ActionDetailsHref.HREF_CHARGEABLES}
          variant="h6"
        >
          {t('MovementEdit.Labels.ChargeableItems')}
        </Typography>
        <ChargeablesEditor
          portCall={portCall}
          action={action}
          setAction={(value) => DataStore.save(value)}
          checkSaveState={checkSaveState}
          saveDisabled={saveDisabled}
        />
      </CardContent>
      <Feature flag="crew-list-submission">
        <CardContent>
          <Typography
            id={ActionDetailsHref.HREF_FAL5_CREW_LIST}
            variant="h6"
          >
            {t(FileTypeLabels.FAL5_CREW_LIST)}
          </Typography>
          <PortCallDataEdit portCall={portCall} action={action} type={FileType.FAL5_CREW_LIST} />
        </CardContent>
      </Feature>
    </>
  )
}

MovementEditor.propTypes = {
  portCall: PropTypes.object,
  setPortCall: PropTypes.func,
  action: PropTypes.object,
  setAction: PropTypes.func,
  selectedRequest: PropTypes.object,
  setSelectedRequest: PropTypes.func,
  activeAgent: PropTypes.object,
  checkSaveState: PropTypes.func
}

export default MovementEditor;