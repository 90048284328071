import React from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import CertificateListItem from '../Certificate/CertificateListItem';
import { sortCertificatesByValidFrom } from '../../utils/sorters';

const CertificateListTooltipItem = ({certificate, showValidity, icon}) => {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useDateTimeSetting();

  const validFrom = format(new Date(certificate.validFromDate), dateFormat, { locale: DateFnsLanguageMap[i18n.language] });
  const validTo = format(new Date(certificate.validToDate), dateFormat, { locale: DateFnsLanguageMap[i18n.language] });

  return (
    <Box display="flex" flexDirection='column'>
      <CertificateListItem
        certificate={certificate}
        showValidity={showValidity}
        icon={icon}
        fromDate={new Date()}
        toDate={new Date()}
      />
      <Typography variant='caption'>
        {`${validFrom} ${t('CertificateType.Labels.To')} ${validTo}`}
      </Typography>
    </Box>
  )
};

const CertificateListTooltip = ({certificates, showValidity, icon}) => {
  return (
    <List dense>
      {
        certificates
          .slice()
          .sort(sortCertificatesByValidFrom)
          .map(cert =>
          <ListItem key={cert.id} alignItems="flex-start">
            <CertificateListTooltipItem certificate={cert} showValidity={showValidity} icon={icon} />
          </ListItem>
        )
      }
    </List>
  );
};

export default CertificateListTooltip;