import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Box
} from '@material-ui/core';
import { Certificate, Alert } from 'mdi-material-ui';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { checkValidityForCertificate } from '../../utils/certificates';

const CertificateListItem = ({ certificate, showValidity, fromDate, toDate, icon, ...other} ) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const certificateWithValidty = checkValidityForCertificate(certificate, fromDate, toDate);
  
  return (
    <ListItem dense component="div" style={{padding: '0rem'}} data-is-expired={certificateWithValidty?.isExpired} aligncontent="flex-start" {...other}>
      {icon &&
      <ListItemIcon style={{marginRight: '0.5rem'}}>
        <Certificate />
      </ListItemIcon>
      }
      <ListItemText primary={certificateWithValidty?.name} />
      {showValidity && (certificateWithValidty?.isExpired || !certificateWithValidty?.isValid) &&
        <>
          <Box mr="0.5rem" />
          <Tooltip title={certificateWithValidty?.isExpired ? t("CertificateType.Labels.ExpiredHelp") : t("CertificateType.Labels.InvalidHelp")}>
            <Alert fontSize='small' style={{ color: theme?.palette?.warning?.main }}/>
          </Tooltip>
        </>
      }
    </ListItem>
  )
}

export default CertificateListItem;
