import React from 'react';
import PopoverTooltip from "../Tooltip/PopoverTooltip";
import SimpleTooltip from "../Tooltip/SimpleTooltip";
import { MessageOutline } from 'mdi-material-ui';
import { Box } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const RequestRemark = ({ remark }) => {
  const { t } = useTranslation();
  return (
    <PopoverTooltip tooltip={<SimpleTooltip text={remark} title={t('RequestInbox.Labels.RequestHeadingsRemarks')}></SimpleTooltip>}>
      <Box display='grid' justifyContent='center'>
        <MessageOutline />
      </Box>
    </PopoverTooltip>
  );
};

export default RequestRemark;