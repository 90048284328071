
import { useCallback, useContext } from 'react'
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import { UserSetting } from '../models';
import { UIContext } from '../contexts/ui';
import { createUserSetting } from '../graphql/mutations';
import useQuery from './useQuery';

/**
 * Hook to set/update user settings for user
 * @returns {Object} user settings object
 * @returns {function} function to update a user setting property
 * Note: this hook returns [] while it's still loading, undefined when UserSetting is missing
 */
const useUserSettings = () => {
  const [uiContext,] = useContext(UIContext);
  const userSetting = useQuery(UserSetting, { condition: uiContext.userName });
  
  const updateUserSettings = useCallback(async (settingKey, settingValue) => {
    if (userSetting) {
      await DataStore.save(UserSetting.copyOf(userSetting, updated => { updated[settingKey] = settingValue }));
    } else {
      // DataStore does not allow manually setting id so need to do it the API way
      await API.graphql(graphqlOperation(createUserSetting, { input: { id: uiContext.userName, [settingKey]: settingValue } }));
    }
  }, [userSetting])

  return { userSetting,  updateUserSettings };
};

export default useUserSettings;