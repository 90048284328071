import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { Category, CategoryType } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { sortCategories } from '../../utils/sorters';
import { useTranslation } from 'react-i18next';
import { DataStore } from 'aws-amplify';

const AdminCategoryHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('Category.Labels.Name')} flexBasis="20%" />
      <AdminModelEditorHeaderColumn label={t('Category.Labels.Description')} flexBasis="80%" />
    </>
  );
};

const AdminCategoryListItemContent = ({ value }) =>
  <>
    <Typography noWrap style={{ flexBasis: "20%" }}>{value.name}</Typography>
    <Typography variant="caption" noWrap style={{ flexBasis: "80%", opacity: 0.5 }}>{value.description}</Typography>
  </>

const AdminCategoryFormContent = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  return (<>
    <TextField required disabled={disabled} value={value.name || ''} name="name" label={t('Category.Labels.Name')} fullWidth variant="outlined" margin="normal" onChange={onChange} id={'CategoryName'} />
    <TextField disabled={disabled} value={value.description || ''} name="description" label={t('Category.Labels.Description')} fullWidth variant="outlined" margin="normal" onChange={onChange} id={'CategoryDescription'}/>
  </>)
}

const AdminPortCallCategory = () => {
  const { t } = useTranslation();
  const itemName = t('AdminPortCallCategory.Labels.PortCallCategory');

  return (
    <AdminModelEditor
    HeaderContent={AdminCategoryHeaderContent}
    ListItemContent={AdminCategoryListItemContent}
    FormContent={AdminCategoryFormContent}
    model={Category}
    condition={c => c.type("eq", CategoryType.PORTCALL)}
    filter={i => (i.type === CategoryType.PORTCALL) && !i.deleted}
    sort={sortCategories}
    canUpdate={() => true}
    canDelete={() => true}
    onDelete = {async (i) => {
          await DataStore.save(Category.copyOf(i, updated => { updated.deleted = true; }));
        }}
    canSave={i => Boolean(i.name)}
    canAdd={() => true}
    defaultItem={() => {
      return {
        type: CategoryType.PORTCALL
      }
    }}
    customDeleteTooltip={() => t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: itemName })}
  />
  );
};
export default AdminPortCallCategory