import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports';
import appTheme from './theme/ion';
import { UIContextProvider } from './contexts/ui';
import { DataContextProvider } from './contexts/dataContext';
import { DatastoreStatusProvider } from './contexts/datastoreStatusContext';
import { ComponentsCustomStyle } from './components/Authenticator/CustomStyle';
import { CustomFormFields } from './components/Authenticator/CustomFormFields';
import './index.css';

const theme = createTheme({ ...appTheme });

// SP-1128, force DataStore into creating a separate instance of indexdb on each reload
const sessionId = uuidv4();
const datastoreName = `amplify-datastore-${sessionId}-${awsconfig.aws_appsync_graphqlEndpoint.split('/')[2].split('.')[0]}`
sessionStorage.setItem('datastoreSessionId', sessionId);

// store timestamps of indexDB database last use in localStorage
const databasesKey = 'smartport::databases';
const updateDatabases = () => {
  const databases = JSON.parse(localStorage.getItem(databasesKey)) || {};
  // perform cleanup of indexDB databases not in use
  for (let key of Object.keys(databases)) {
    if (key !== datastoreName && (+new Date() - databases[key]) > 1000 * 60 * 15) { // older than 15 min
      indexedDB.deleteDatabase(key);
      delete databases[key];
    }
  }
  databases[datastoreName] = +new Date();
  localStorage.setItem(databasesKey, JSON.stringify(databases));
};
updateDatabases();
setInterval(updateDatabases, 1000 * 60 * 5); // every 5 min

Amplify.configure({
  ...awsconfig,
  DataStore: {
    syncPageSize: 500,
    fullSyncInterval: (60 * 24) * 1
  },
  Storage: {
    AWSS3: {
      bucket: awsconfig.aws_user_files_s3_bucket,
      region: awsconfig.aws_user_files_s3_bucket_region
    }
  }
});

const Root = () => (
  <UIContextProvider>
    <ThemeProvider theme={theme}>
      <DataContextProvider>
        <DatastoreStatusProvider>
          {/* <CustomAuthenticator hide={[Greetings, SignIn, ForgotPassword, RequireNewPassword, VerifyContact]}>
              <CustomSignIn />
              <CustomForgotPassword />
              <CustomRequireNewPassword />
              <CustomVerifyContact />
              <App />
            </CustomAuthenticator> */}
          <Authenticator hideSignUp={true} components={ComponentsCustomStyle} formFields={CustomFormFields}>
            {({ signOut, user, route, authStatus }) => (
              <App authState={authStatus} />
            )}
          </Authenticator>
        </DatastoreStatusProvider>
      </DataContextProvider>
    </ThemeProvider>
  </UIContextProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
