import { useEffect, useState, useContext } from 'react';
import { createTariffMap, validateActionChargableItemsMap, validateChargeableItemMap } from "../utils/tariffBook";
import useTariffBooks from './useTariffBooks';
import FeatureFlags from "../constants/FeatureFlags";
import useFeatureSetting from "./useFeatureSetting";

const valid = { error: 0, errorMessageT: undefined };

const useChargeableItemValidation = () => {
  const { tariffBooks } = useTariffBooks();
  const [tariffMap, setTariffMap ] = useState(new Map());
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  useEffect(() => {
    setTariffMap(createTariffMap(tariffBooks));
  }, [ tariffBooks ]);

  return {
    validateChargeableItem: (chargeableItem, date) => 
      isTariffBookEnabled ? validateChargeableItemMap(chargeableItem, date, tariffBooks, tariffMap) : valid,
    validateActionChargeableItem: (action) => 
      isTariffBookEnabled ? validateActionChargableItemsMap(action, tariffBooks, tariffMap) : valid,
  }
};

export default useChargeableItemValidation;