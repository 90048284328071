import React, { useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import { DataStore } from 'aws-amplify';
import { PilotTag } from '../../models';
import useQuery from '../../hooks/useQuery';

export default ({ value, onChange, label, ...other }) => {
  const _tags = useQuery(PilotTag);
  const tags = useMemo(() => _tags
    .map(tag => tag.name)
    .sort((a, b) => a.localeCompare(b)),
    [_tags]);
  
  const handleChange = (value) => {
    for (let name of value) {
      if (!tags.includes(name))
        DataStore.save(new PilotTag({ name }));
    }
    onChange && onChange(value);
  };

  return (
    <Autocomplete
      {...other}
      filterSelectedOptions
      freeSolo
      forcePopupIcon
      multiple
      autoHighlight
      options={tags}
      getOptionLabel={(item) => item}
      value={value}
      onChange={(e, value) => handleChange(value)}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
};
