
import { useContext, useEffect } from 'react'
import SettingConstants from '../constants/SettingConstants';
import { API, DataStore, graphqlOperation } from 'aws-amplify';
import { Setting, UserSetting } from '../models';
import { UIContext } from '../contexts/ui';
import { createUserSetting } from '../graphql/mutations';
import useQuery from './useQuery';
import { useTranslation } from 'react-i18next';
import useFeatureSetting from './useFeatureSetting';
import FeatureFlags from '../constants/FeatureFlags';

/**
 * Hook to use when interfacing with language in the application
 * @returns {Object} _ user and app-wide language getters/setters
 * @returns {function} _.languages available (loaded in i18n) languages
 * @returns {string} _.userLanguage current user language
 * @returns {function} _.setUserLanguage set user language and update UserSetting
 * @returns {string} _.language current app-wide language
 * @returns {function} _.setLanguage set app-wide language and update Setting
 */
const useLanguage = (sync = false) => {
  const { i18n } = useTranslation();
  const [uiContext,] = useContext(UIContext);
  const setting = useQuery(Setting, { condition: c => c.name("eq", SettingConstants.APP_LANGUAGE), filter: c => c.name === SettingConstants.APP_LANGUAGE })[0];
  const userSetting = useQuery(UserSetting, { condition: uiContext.userName });
  const isTestDictionaryEnabled = useFeatureSetting(FeatureFlags.TEST_DICTIONARY);

  const languages = Object.keys(i18n.services.resourceStore.data);
  const languageFallback = i18n.services.languageUtils.options.fallbackLng[0];
  
  useEffect(() => {
    if (!sync) return;
    const newLanguage = userSetting?.language || (setting?.value ? JSON.parse(setting.value) : null) || languageFallback;
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
  }, [setting, userSetting, sync]);

  const language = setting?.value ? JSON.parse(setting.value) : null;
  const userLanguage = userSetting?.language;

  const setUserLanguage = async (newLanguage) => {
    if (userLanguage === newLanguage) return;
    if (userSetting) {
      await DataStore.save(UserSetting.copyOf(userSetting, updated => { updated.language = newLanguage; }));
    } else {
      // DataStore does not allow manually setting id so need to do it the API way
      await API.graphql(graphqlOperation(createUserSetting, { input: { id: uiContext.userName, language: newLanguage } }));
    }
  };

  const setLanguage = async (newLanguage) => {
    if (language === newLanguage) return;
    await DataStore.save(setting
      ? Setting.copyOf(setting, updated => { updated.value = JSON.stringify(newLanguage) })
      : new Setting({ name: SettingConstants.APP_LANGUAGE, value: JSON.stringify(newLanguage) })
    );
  };

  return { 
    languages: languages?.filter(l => !(l === 'test' && !isTestDictionaryEnabled)), 
    userLanguage: userLanguage || language || languageFallback, 
    language: language || languageFallback, 
    setUserLanguage, 
    setLanguage 
  };
};

export default useLanguage;