/**
 * get chunk array, for each array has x items (x is a chuck size )
 * @param {Array} array 
 * @param {Number} chuck size , default to 25 dynamo db maximum transaction/time
 * @returns {Object} { isChunk, items}
 * isChunk is Boolean, return true if array is mutate,else false 
 * items return chunks mutate array or original array;
 */

const MAXIMUM_TRANSACTION = 25;

export const getItemByMaxmimumTransaction = (array = [], chunkSize = MAXIMUM_TRANSACTION) => {
  const arraySize = array.length;
  let isChunk = null;
  const chunks = [];
  if (typeof chunkSize === "number" && chunkSize > 1 && arraySize > chunkSize) {
    isChunk = true;
    for (let i = 0; i < arraySize; i += chunkSize) {
      const item = array.slice(i, i + chunkSize);
      item && chunks.push(item);
    }
    return { isChunk, batchItems: chunks };
  }
  // console.log("original array add an extra layer", [array]);
  return { isChunk, batchItems: [array] };
};
