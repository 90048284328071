import React, { useState, useEffect } from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlusBoxOutline, MinusBoxOutline } from 'mdi-material-ui';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { CheckboxMarked, CheckboxBlankOutline, ExpandAll, CollapseAll } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  treeNode: {
    display: "flex",
    alignItems: "center",
  }
});

/**
 * Build tree object amd lookup table of items used when building and processing tree state updates
 * @param {Array[Object]} items 
 * @returns {Object} Object containing tree and lookup table
 */
const buildTree = (items) => {
  let hashTable = Object.create(null)
  items.forEach(item => hashTable[item.id] = {
    id: item.id,
    parent: item.parent,
    name: item.name,
    children: [],
    data: { ...item }
  });
  let dataTree = []
  items.forEach(aData => {
    if (aData.parent)
      hashTable[aData.parent].children.push(hashTable[aData.id])
    else
      dataTree.push(hashTable[aData.id])
  });
  return { tree: dataTree, table: hashTable };
};

const initialMenuState = {
  mouseX: null,
  mouseY: null
};

/**
 * Tree component with checkboxes on every nodes.
 * Features
 *   Based on Mui TreeView and TreeItem
 *   State of parents can be propagated down to child tree nodes
 *   State of child nodes will propagate up to parent nodes
 *   Parent nodes with some child nodes selected are shown in indeterminate state (neither true or false)
 *   Context menu to select, de-select all, expand or collapse all nodes 
 * Input, items[] prop is an array of objects which must follow this structure
 * {
 *   id: String Unique identifier
 *   name: String Display name
 *   parent: String  Unique identifier of parent node
 * }
 * Item will be inserted in order of items[].
 */
const CheckBoxTree = ({id, items, checked = [], onChange, disabled}) => {
  const data = buildTree(items);
  const [treeNodes] = useState(data.tree);
  const [allNodes] = useState(data.table);
  const [checkedItems, setCheckedItems] = useState(checked);
  const [expandedItems, setExpandedItems] = useState([]);
  const [contextMenu, setContextMenu] = useState(initialMenuState);
  const classes = useStyles();
  const { t } = useTranslation();

  /**
   * Return all child nodes of nodeId. If there are no children return just nodeId
   * @param {String} nodeId 
   * @returns {Array[String]} Array of node IDs
   */
  const leafIds = (nodeId) => {
    const node = allNodes[nodeId];
    return !node.children?.length ? [node.id] : node.children.map(n => leafIds(n.id)).flat();
  };

  useEffect(() => {
    onChange && onChange(checkedItems);
  }, [checkedItems])

  useEffect(() => {
    setCheckedItems(checked);
  }, [checked])

  const handleCheckboxClicked = (node, parentNode, newValue) => {
    const value = checkedItems.includes(node.id);
    if(!node.children?.length) {
      if(value === newValue) return;
      // add node to checked list and remove parent from list if child was unchecked
      setCheckedItems(newValue ? [...checkedItems, node.id] : checkedItems.filter(id => (id !== node.id && id !== parentNode?.id)));
    } else {
      // propogate a state change to a parent node down to all child nodes
      const childIds = leafIds(node.id);
      const nodeAndChildIds = [...childIds, node.id];
      const remaining = checkedItems.filter(id => !nodeAndChildIds.includes(id));
      setCheckedItems(newValue ? [...remaining, ...nodeAndChildIds] : remaining);
    }
  };

  const handleContextMenuClicked = (event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu(initialMenuState);
  }

  const handleSelectAllClicked = () => {
    setCheckedItems(items.map(item => item.id));
    handleContextMenuClose();
  };

  const handleDeselectAllClicked = () => {
    setCheckedItems([]);
    handleContextMenuClose();
  };

  const handleExpandAllClicked = () => {
    setExpandedItems(items.map(item => item.id));
    handleContextMenuClose();
  };

  const handleCollapseAllClicked = () => {
    setExpandedItems([]);
    handleContextMenuClose();
  };

  const LocationItem = (node, disabled) => {
    // should node be checked because all child nodes are checked
    const isChecked = leafIds(node.id).every(id => checkedItems.includes(id));
    // is node in indeterminate state because some child nodes are checked
    const isIndeterminate = !isChecked && leafIds(node.id).some(id => checkedItems.includes(id));
  
    const onChange = () => {
      const parent = allNodes[node.parent];
      handleCheckboxClicked(node, parent, !isChecked);
    };

    return (
      <TreeItem
        data-id={node.id}
        key={node.id}
        nodeId={node.id}
        label={
          <Box className={classes.treeNode}>
            <Checkbox 
              name={node.id}
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()}
              disabled={disabled}
            />
            <Typography>{node.name}</Typography>
          </Box>
        }
      >
        {Array.isArray(node.children) ? node.children.map((node) => LocationItem(node, disabled)) : null}
      </TreeItem>
    )
  }

  return (
    <div id={id} onContextMenu={handleContextMenuClicked} style={{pointerEvents: disabled ? 'none' : 'all'}}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<MinusBoxOutline />}
        defaultExpanded={['root']}
        defaultExpandIcon={<PlusBoxOutline />}
        expanded={expandedItems}
        onNodeToggle={(_, nodeIds) => setExpandedItems(nodeIds)}
      >
        {treeNodes.map(node => LocationItem(node, disabled))}
      </TreeView>
      <Menu
        keepMounted
        open={contextMenu.mouseY !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.mouseY !== null && contextMenu.mouseX !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
        }
      >
        <MenuItem
          id="CheckBoxTreeExpandAllMenuItem" 
          onClick={handleExpandAllClicked}
        >
          <ListItemIcon>
            <ExpandAll />
          </ListItemIcon>
          <ListItemText primary={t('CheckboxTree.Labels.ExpandAll')} />
        </MenuItem>
        <MenuItem
          id="CheckBoxTreeCollapseAllMenuItem" 
          onClick={handleCollapseAllClicked}
        >
          <ListItemIcon>
            <CollapseAll />
          </ListItemIcon>
          <ListItemText primary={t('CheckboxTree.Labels.CollapseAll')} />
        </MenuItem>
        <Divider />
        <MenuItem
          id="CheckBoxTreeSelectAllMenuItem" 
          onClick={handleSelectAllClicked}
        >
          <ListItemIcon>
            <CheckboxMarked />
          </ListItemIcon>
          <ListItemText primary={t('CheckboxTree.Labels.SelectAll')} />
        </MenuItem>
        <MenuItem
          id="CheckBoxTreeDeselectAllMenuItem" 
          onClick={handleDeselectAllClicked}
        >
          <ListItemIcon>
            <CheckboxBlankOutline />
          </ListItemIcon>
          <ListItemText primary={t('CheckboxTree.Labels.DeselectAll')} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CheckBoxTree;