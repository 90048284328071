import React, { } from 'react';
import { makeStyles } from '@material-ui/core';
import { MovementActionType } from '../../constants/MovementActionType';
import { orange, green } from '@material-ui/core/colors';
import MoveDepart from '../../icons/MoveDepart';
import MoveArrive from '../../icons/MoveArrive';
import ShiftDepart from '../../icons/ShiftDepart';
import ShiftArrive from '../../icons/ShiftArrive';
import { ActionTypeIds } from '../../environment';
import { getCustomActionIcon } from '../../constants/CustomActionConstants';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: theme.typography.h4.fontSize
  },
  PLANNED: {
    opacity: 0.54
  },
  APPROVAL_PENDING: {
    opacity: 0.54
  },
  APPROVAL_DECLINED: {
    opacity: 0.54
  },
  IN_PROGRESS: {
    color: orange[500],
  },
  COMPLETED: {
    color: green[500]
  },
  CANCELLED: {
    opacity: 0.54
  },
  DELETED: {
    opacity: 0.54
  }
}));

const ActionState =  ({ action }) => {
  const classes = useStyles();
  if (action?.type?.id === ActionTypeIds.MOVEMENT) {
    if (action?.movementType === MovementActionType.ARRIVAL) return <MoveArrive className={`MovementIcon MovementArrivalIcon ${classes.icon} ${classes[action.state]}`} />
    if (action?.movementType === MovementActionType.DEPARTURE) return <MoveDepart className={`MovementIcon MovementDepartureIcon ${classes.icon} ${classes[action.state]}`} />
    if (action?.movementType === MovementActionType.SHIFT_ARRIVAL) return <ShiftArrive className={`MovementIcon MovementShiftArrivalIcon ${classes.icon} ${classes[action.state]}`} />
    if (action?.movementType === MovementActionType.SHIFT_DEPARTURE) return <ShiftDepart className={`MovementIcon MovementShiftDepartureIcon ${classes.icon} ${classes[action.state]}`} />
  } else if(action?.type?.id) {

     return getCustomActionIcon(`${classes.icon} ${classes[action?.state]}`);
  }
  return null;
}

export default ActionState;