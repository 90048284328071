import React from 'react';
import { 
  Box, 
  makeStyles, 
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(theme => ({
  sailingText: {
    color: 'rgba(0,0,0)',
  },
  nominalText: {
    color: 'rgba(0,0,0,0.54)',
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  },
}))

const VesselDraughtListItem = ({vesselDraught, ...props}) => {
  
  const classes = useStyles();
  const { t } = useTranslation();
  const {nominal: rawNominal = null, sailing: rawSailing = null} = vesselDraught;
  const nominal = rawNominal;
  const sailing = rawSailing;
  return(
  <ListItem
      component="div"
      {...props}
    >
      <ListItemText
        className={sailing ? classes.sailingText : classes.nominalText}
        primary={<>{sailing ? sailing : nominal ? nominal : "-"}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></>}
        primaryTypographyProps={{
          component: 'p',
          noWrap: true,
          variant: 'body1',
        }}
        secondary={<Box component="span" display="flex" alignItems="center">{sailing ? null : nominal ? t('Common.Labels.Nominal') : null}</Box>}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'body2'
        }}
      />
    </ListItem>  
  );
}

export default VesselDraughtListItem;