import { useContext, useMemo } from 'react';
import { getActionArrival, getActionTime, isAgentAssociatedWithPortCall } from '../utils/getters';
import { isUncategorizedCategory } from '../components/DataBrowserSidebar/DataBrowserSidebar';
import { UIContext } from '../contexts/ui';
import { SORT_DIRECTION } from '../utils/sorters';

const useFilterPortCalls = (portCalls, filter) => {

  const [uiContext,] = useContext(UIContext);
  const userContactId = uiContext.userContactId;

  const itemsFiltered = useMemo(() => {
    if (!portCalls) return [];
    const filtered = portCalls
      .filter(p => {
        if (filter.status && !filter.status.includes(p.status)) return false;
        const arrival = p?.actions && getActionArrival(p.actions);
        const arrivalTime = getActionTime(arrival)
        if (userContactId && !isAgentAssociatedWithPortCall(p, userContactId)) return false;
        if (filter.from && (!arrival || arrivalTime < filter.from.toISOString())) return false;
        if (filter.to && (!arrival || arrivalTime > filter.to.toISOString())) return false;
        if (filter.vessel && (!p.portCallVesselId || p.portCallVesselId !== filter.vessel.id)) return false;
        if (filter.category && (isUncategorizedCategory(filter.category)
          ? p.portCallCategoryId
          : (!p.portCallCategoryId || p.portCallCategoryId !== filter.category.id)
        )) return false;
        if (filter.location && !p.actions?.some(a => a.movementLocation?.portUnlocode === filter.location.portUnlocode)) return false;
        if (filter.tags?.length && (!p.portCallTags || !p.portCallTags.some(t => filter.tags.some(ft => t === ft.name)))) return false;
        return true;
      })
      .sort((a, b) => {
        const a_ = new Date(a.sortTime);
        const b_ = new Date(b.sortTime);
        return filter.sortDirection === SORT_DIRECTION.ASC
          ? (a_.getTime() > b_.getTime() ? 1 : -1)
          : (a_.getTime() > b_.getTime() ? -1 : 1);
      });

    return filtered;
  }, [portCalls, filter, userContactId]);

  return itemsFiltered;
};

export default useFilterPortCalls;