
import { useCallback, useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { getParameterStoreValue } from '../graphql/queries';

const EXTERNAL_NOTIFICATION_PS_KEY = 'sendnotification/port'

/**
 * A hook that handles getting a value from the paramter store based on key
 * Note: This is only used to get values of function parameter store items. 
 * @return {Object} a flag indicating if the external notification are fully configured or not.
 */
const useParameterStore = () => {

  const [isNotificationsConfigComplete, setIsNotificationsConfigComplete] = useState(undefined)

  const getPSValue = useCallback( async (key) => {
    const { data } = await API.graphql(graphqlOperation(getParameterStoreValue, { 
      key
    }));
    
    try{
      return JSON.parse(data?.getParameterStoreValue)
    }catch(err){
    }
    return null;
  })

  const checkPortDetails = (params) => {
    return params['sendEmail'] && params['name'] && params['smartportUrl'] && params['supportEmail'] && params['sender'] && params['timezone']
  }

  const getNotificationConfigurations = useCallback( async () => {
    const value = await getPSValue(EXTERNAL_NOTIFICATION_PS_KEY);
    if(value && value['params']) {
      setIsNotificationsConfigComplete(checkPortDetails(value['params']) ?? false)
    }else{
      setIsNotificationsConfigComplete(false)
    }
  } , [])

  useEffect(() => {
    getNotificationConfigurations();
  }, [])

  return {
    isNotificationsConfigComplete
  }
}

export default useParameterStore;