import { useContext, useMemo } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import SettingConstants from '../constants/SettingConstants';
import { Setting } from '../models';

const DefaultSetting = { adminEditOnly: false };

const useFilterSettings = () => {
  const { settings } = useContext(DataStoreContext);
  const filterSetting = useMemo(() => settings.find(el => el.name === SettingConstants.APP_FILTERS), [settings]);

  const setFilterSetting = async (newSetting) => {
    await DataStore.save(filterSetting
      ? Setting.copyOf(filterSetting, updated => { updated.value = JSON.stringify(newSetting) })
      : new Setting({ name: SettingConstants.APP_FILTERS, value: JSON.stringify(newSetting) })
    );
  };

  return {
    filterSetting: filterSetting ? JSON.parse(filterSetting.value) : DefaultSetting,
    setFilterSetting: setFilterSetting
  }
};

export default useFilterSettings;