import React, {Fragment, useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { 
  FileImageOutline, 
  FileOutline,
  FileDocumentOutline,
  FileMusicOutline,
  ArrowDownBold,
  Close,
} from 'mdi-material-ui';
import {
  documentType,
  documentStatusArray,
} from '../../constants/Documents';
import ConfirmDialog from "../Dialog/ConfirmDialog";
import { UIContext } from '../../contexts/ui';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const { 
  APPLICATION,
  IMAGE,
  TEXT,
  AUDIO,
} = documentType;

const {
  PROGRESS_BAR, 
} = documentStatusArray;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '0'
  },
  listItem: {
    padding: "0px"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

const avatarIcon = (fileType, iconClass) => {
  if(!fileType) return <FileOutline className={iconClass}  />
  const [type] = fileType.split("/");
  switch (type) {
    case TEXT:
    case APPLICATION:
      return <FileDocumentOutline className={iconClass}/>
    case IMAGE: 
      return <FileImageOutline className={iconClass}/>
    case AUDIO:
      return <FileMusicOutline className={iconClass}/>
    default:
      return <FileOutline className={iconClass}/>
  }
}

const DocumentList = ({
  documents, 
  deleteDocument, 
  getDocument,
  fileStatus: {key: statusKey},
  disableDownload,
  disableDelete,
  checkSaveState,
  saveDisabled,
  hideEmptyMessage = false
}) => {
  const [uiContext] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const classes = useStyles();
  const { t } = useTranslation();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmProps, setConfirmProps] = useState(null);

  const handleDocumentDelete = (name, key, index) => {
    setConfirmProps({
      title: t('DocumentList.Labels.DeleteAttachmentTitle', {doc: name}),
      message: t('DocumentList.Labels.DeleteAttachmentMessage', {doc: name}),
      onConfirm: () => {
        deleteDocument(key, index);
        setOpenConfirm(false);
      }
    });

    setOpenConfirm(true);
  }

  const renderList = () => {
    const documentLength = documents.length;
    return documents.map((document, index) => {
      const {key, name, stringName, type, size, upload, user} = document;
      const userId = user ? `${user} - ` : ''
      return (
        <Fragment key={key}>
          <ListItem className={`${classes.listItem} DocumentListItem`}>
            <ListItemAvatar>
                  {avatarIcon(type, classes.icon)}
            </ListItemAvatar>
            <Tooltip title={name} placement="top-start">
              <ListItemText 
                primary={stringName} 
                secondary={`${userId}${upload} ${size}`} 
              />
            </Tooltip>
            <ListItemSecondaryAction>
                <div>
                  <div style={{display: 'inline-flex'}}>
                  <IconButton 
                  id={`button-download-${index}`}
                  onClick={() => getDocument(key)} 
                  edge="end"
                  disabled={statusKey===PROGRESS_BAR.key || disableDownload} 
                  >
                    <ArrowDownBold/>
                  </IconButton>
                  </div>
                  {!readOnly && 
                  <Tooltip title={disableDelete ? t('DocumentList.Labels.NoDeletePermission') : ''} placement="top-start">
                    <div style={{display: 'inline-flex'}}>
                    <IconButton
                      id={`button-delete-${index}`}
                      onClick={saveDisabled ?  () => handleDocumentDelete(name, key, index) : () => checkSaveState()} 
                      edge="end"
                      disabled={statusKey===PROGRESS_BAR.key || disableDelete}
                    >
                      <Close/>
                    </IconButton>
                    </div>
                    </Tooltip>
                  }
                </div>
              </ListItemSecondaryAction>
          </ListItem>
          {index !== documentLength && <Divider /> }
          <ConfirmDialog 
            {...confirmProps} 
            onClose={() => setOpenConfirm(false)} 
            open={openConfirm}
            waitForSync={true}
          />
        </Fragment>
      )
    })
  }

  const renderListEmpty = () => {
    if(hideEmptyMessage) {
      return <></>
    }
    return(
      <Typography align="center" variant="overline" >
        {t('DocumentList.Labels.NoAttachments')}
      </Typography>
    )
  }

  return (
    <List className={classes.root}>
      {documents.length !== 0 ? renderList() : renderListEmpty()}
    </List>
  );
}

export default DocumentList;