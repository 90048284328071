import React from 'react';
import { ListItem, ListItemText, Grid, Divider, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { getCertificateName } from '../../utils/getters';

export default ({ certificate }) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateTimeSetting();
  return(
    <Grid container spacing={0}>
      <Grid item>
        <ListItem>
          <ListItemText
            primary={getCertificateName(certificate)}
            secondary={
              certificate.referenceNumber && <>
                <Typography variant='caption' color='textPrimary' >{`${t('CertificateType.Labels.ReferenceNumberShort')}: `}</Typography>
                <Typography variant='caption'>{certificate.referenceNumber}</Typography>
              </>
            }
          />
        </ListItem>
        <Divider />
        {certificate.nameOfHolder &&
          <ListItem>
            <ListItemText
              primary={certificate.nameOfHolder}
              secondary={t('CertificateType.Labels.NameOfHolder')}
            />
          </ListItem>
        }
        {certificate.validFromDate &&
          <ListItem>
            <ListItemText
              primary={format(new Date(certificate.validFromDate), dateFormat) ?? ''}
              secondary={t('CertificateType.Labels.ValidFrom')}
            />
          </ListItem>
        }
        {certificate.validToDate &&
          <ListItem>
            <ListItemText
              primary={format(new Date(certificate.validToDate), dateFormat) ?? ''}
              secondary={t('CertificateType.Labels.ValidTo')}
            />
          </ListItem>
        }
        {certificate.notes &&
          <ListItem>
            <ListItemText
              primary={certificate.notes}
              secondary={t('CertificateType.Labels.Notes')}
            />
          </ListItem>
        }
      </Grid>
    </Grid>
  );
}