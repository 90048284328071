import { Tooltip, Box, Grid, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close, CrosshairsGps } from 'mdi-material-ui';
import PortMap from '../Map/PortMap';
import VesselListItem from '../Vessel/VesselListItem';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getVesselState } from '../../graphql/queries';
import { format, formatDistanceToNow } from 'date-fns';
import { DateFnsLanguageMap } from '../../translations';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    margin: 0,
    padding: 0
  },
  titleCloseButton: {
    position: 'absolute',
    right: '7px',
    top: '7px',
    color: theme.palette.grey[500],
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    }
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '33vw',
      padding: '0.5rem 1.2rem 1.2rem 1.2rem',
    },
  },
  dialogContent: {
    padding: 0
  },
  titleCenterButton: {
    position: 'absolute',
    zIndex: 1,
    color: 'rgb(5,125,215)',
    backgroundColor: 'rgb(255,255,255)',
    bottom:'45px',
    right:'7px',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    }
  },
  title:{
    fontSize: '1rem',
    textAlign: 'start',
    marginTop: '0.35rem'
  },
  titleInactive: {
    color: theme.palette.warning.main,
  },
  titleError: {
    color: theme.palette.error.main, 
  },
  container: {
    width: '33vw',
    height: '55vh',
    position: 'relative',
    padding: 0,
    overflow: 'hidden',
  }
}));

const SpotlightDialogTitle = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot} {...other}>
      {children}
      <IconButton aria-label="close" className={classes.titleCloseButton} onClick={onClose} id={'CloseMap'}>
        <Close />
      </IconButton>
    </MuiDialogTitle>
  )
};

const SpotlightMapDialog = ({ open, onClose, vesselData }) => {
  const vesselMmsi = vesselData?.mmsi;
  const [currentVessel, setCurrentVessel] = useState({
    name: vesselData?.name,
    mmsi: vesselData?.mmsi,
    imo: vesselData?.imo,
    flag: vesselData?.flag,
    inactive: !Boolean(vesselData),
    positionTimestamp: null,
    lon: null,
    lat: null
  });
  const classes = useStyles();
  const isFirstRender = useRef(true);
  const { t, i18n } = useTranslation();
  const { dateTimeFormat } = useDateTimeSetting();

  const getVesselByMMSI = useCallback(async (mmsi) => {
    let vessel = null;
    try {
      const result = await API.graphql(graphqlOperation(getVesselState, { id: mmsi }));
      vessel = result?.data?.getVesselState;
    } catch (err) {
      console.error('SpotlightMapDialog ERROR:', err);
    }
    return vessel;
  }, []);

  useEffect(() => {
    if (!vesselMmsi) return;
    const update = (async () => {
      const vessel = await getVesselByMMSI(vesselMmsi);
      if (vessel) {
        setCurrentVessel((prev) => ({
          ...prev,
          inactive: !Boolean(vessel?.active),
          positionTimestamp: vessel?.positionTimestamp,
          lon: vessel.lon,
          lat: vessel.lat
        }));
        isFirstRender.current = false;
      }
    });
    isFirstRender.current && update();
  }, []);

  /**
   * Update vessel on spotligthMap dialog
   * @param {Vessel} vessel
   * @param {Boolean} inactive 
   */
  const onSpotlightVesselUpdate = useCallback((vessel, inactive) => {
    setCurrentVessel((prev) => ({ ...prev, inactive: inactive, positionTimestamp: vessel.positionTimestamp }));
  }, []);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  const handleVesselCenter = async () => {
    if (!vesselMmsi) return;
    const vessel = await getVesselByMMSI(vesselMmsi);
    if (vessel) {
      setCurrentVessel((prev) => ({ ...prev, lon: vessel.lon, lat: vessel.lat }));
    }
  }

  return (
    <Dialog data-testid='SpotlightMapDialog' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={classes.dialogContainer}>
      <SpotlightDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {vesselData &&
          <VesselListItem
            id={'VesselData'}
            vesselData={vesselData}
            disabled={!Boolean(vesselData)}
          />
        }
      </SpotlightDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container alignItems="center">
          <Box className={classes.container}>
            <PortMap
              mapId={'spotlightDialog'}
              showLayerSwitcherControl={false}
              centerPosition={currentVessel ? { lat: currentVessel?.lat, lon: currentVessel?.lon } : null}
              restoreZoom={true}
              restorePan={false}
              onSpotlightVesselUpdate={onSpotlightVesselUpdate}
              spotlightVessels={[vesselMmsi]}
            />
            <Tooltip title={t('Vessel.Labels.CentreVessel')} enterNextDelay={800}>
              <IconButton aria-label="close" className={classes.titleCenterButton} onClick={handleVesselCenter}>
                <CrosshairsGps />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </DialogContent>
      {currentVessel?.positionTimestamp ?
        <Typography variant="p" className={`${classes.title} ${currentVessel.inactive ? classes.titleInactive : ''}`}>
          {t('Vessel.Labels.LastVesselPosition', {
            positionTimestamp: format(new Date(currentVessel.positionTimestamp), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }),
            age: formatDistanceToNow(new Date(currentVessel.positionTimestamp), { locale: DateFnsLanguageMap[i18n.language] }),
            interpolation: { escapeValue: false }
          })}
        </Typography> : 
        <Typography variant="p" className={`${classes.title} ${classes.titleError}`}>
          {t('Vessel.Errors.VesselPositionNotFound')}
        </Typography>
      }
    </Dialog>
  );
}
export default SpotlightMapDialog;