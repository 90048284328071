import { Button, View, useAuthenticator } from '@aws-amplify/ui-react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { LOGIN_CONSTANTS } from '../constants';

export const SignInHeader = () => {

  return (
    <View className="SignInHeader">
      <Typography variant="h5">{LOGIN_CONSTANTS.Authentication.Common.Labels.SignIn}</Typography>
    </View>

  );
}

export const SignInBody = () => {
  const { updateForm, isAuthenticating, error } = useAuthenticator();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateForm({ name, value });
  };

  return (
    <View>
      <Grid>
        <TextField
          disabled={isAuthenticating}
          onChange={handleInputChange}
          label="Username"
          type="username"
          name="username"
          autoComplete="username"
          variant="outlined"
          fullWidth
          autoFocus
          error={error}
        />
      </Grid>

      <Grid>
        <TextField
          disabled={isAuthenticating}
          onChange={handleInputChange}
          label="Password"
          type="password"
          key="password"
          name="password"
          autoComplete="password"
          variant="outlined"
          fullWidth
          error={error}
          helperText={error}
        />
      </Grid>
    </View>
  )
}

export const SignInFooter = () => {
  const { toResetPassword } = useAuthenticator();

  return (
    <View textAlign="center">
      <Button
        fontWeight="normal"
        onClick={toResetPassword}
        size="small"
        variation="link"
      >
        {LOGIN_CONSTANTS.Authentication.Common.Buttons.ForgotPassword}
      </Button>
    </View>
  );
}