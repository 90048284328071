import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  CardContent,
  Typography,
  Button
} from '@material-ui/core';

import { ActionMovementType, ActionState, RequestType } from '../../../models';
import {
  hasRequestTypeForAction
} from '../../../utils/getters';
import { UIContext } from '../../../contexts/ui';
import { MovementActionDisplayTypeKeys as mt } from '../../../constants/MovementActionDisplayType';
import { RequestColor } from "../../../constants/RequestConstants";
import LocationTimeline from '../../Location/LocationTimeline';
import LocationAutocomplete from '../../Location/LocationAutocomplete';
import AgentRequestTimeChangeDialog from './AgentRequestTimeChangeDialog';
import StringKeyboardDateTimePicker from '../../Common/StringKeyboardDateTimePicker';
import { ActionTypeIds } from '../../../environment';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';

const { color } = RequestColor;

const useStyles = makeStyles(() => ({
  formLabelLabel: {
    //textTransform: 'uppercase',
    marginBottom: '9px',
    marginTop: '26px',
  },
  formLabelLabelFirst: {
    marginTop: '0',
  },
  chipRoot: {
    marginRight: '0.5rem',
  },
  cssLabel: {
    color: color,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${color} !important`,
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '5px',
    borderColor: `${color} !important`,
  },

}));

export const AgentActionScheduleEdit = ({
  portCall,
  setPortCall,
  action,
  setAction,
  selectedRequest,
  setSelectedRequest,
}) => {
  const classes = useStyles();
  const [request, setRequest] = useState();
  const [createRequestAnchorEl, setCreateRequestAnchorEl] = useState(null);
  const [uiContext,] = useContext(UIContext);
  const { userContactId, agentPortal } = uiContext;
  const { t } = useTranslation();

  // check if there is a cancel request on the action to prevent request change requests
  const hasCancelRequest = hasRequestTypeForAction(portCall, action.id, RequestType.REQUEST_TYPE_CANCEL_ACTION);
  const canRequestChange = action.movementType === ActionMovementType.ARRIVAL || action.movementType === ActionMovementType.DEPARTURE;

  useEffect(() => {
    if (!selectedRequest || !selectedRequest.id) {
      setRequest(null);
    } else if (selectedRequest.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_ARRIVAL_TIMEPLANNED
      || selectedRequest.type === RequestType.REQUEST_TYPE_UPDATE_ACTION_DEPARTURE_TIMEPLANNED) {
      setRequest({
        id: selectedRequest.id,
        timePlanned: selectedRequest.requestedTimes[action.id].timePlanned,
        time: selectedRequest.requestedTimes[action.id].timePlanned,
      });
    } else {
      setRequest(null);
    }
  }, [selectedRequest]);

  return (
    <div>
      <CardContent>
        <Typography
          className={classes.formLabelLabelFirst}
          id="action-schedule"
          variant="h6"
        >
          {action && action.type.id === ActionTypeIds.MOVEMENT && t('ActionScheduleEdit.Labels.MovementSchedule', {movement: t(mt[action.movementType])})}
        </Typography>
        {
          request && request.id ? (
            <StringKeyboardDateTimePicker
              disabled
              label={t('ActionScheduleEdit.Labels.PlannedTime')}
              fullWidth
              margin="normal"
              value={request.time}
              okLabel={t('ActionScheduleEdit.Buttons.SetPlannedTime')}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric"
              }}
            />
          ) : (
              <StringKeyboardDateTimePicker
                disabled
                label={t('ActionScheduleEdit.Labels.PlannedTime')}
                fullWidth
                margin="normal"
                value={action.timePlanned}
                okLabel={t('ActionScheduleEdit.Buttons.SetPlannedTime')}
              />
            )
        }

        {canRequestChange && <>
          <Box display="flex" justifyContent="flex-end" alignItems="right" marginBottom='1rem'>
            <Button
              variant="contained"
              color="primary"
              onClick={ e=> {
                setCreateRequestAnchorEl(e.currentTarget);
              }}
              disabled={hasCancelRequest || action.state !== ActionState.PLANNED}
            >
              {t('ActionScheduleEdit.Buttons.RequestChange')}
            </Button>
          </Box>

          <StringKeyboardDateTimePicker
            disabled={true}
            label={t('ActionScheduleEdit.Labels.RequestedTime')}
            fullWidth
            margin="normal"
            allowEmptyValue
            value={action.timeRequested}
            okLabel={t('ActionScheduleEdit.Buttons.SetRequestedTime')}
          />
        </>}

        <StringKeyboardDateTimePicker
          disabled={true}
          ampm={false}
          label={t('ActionScheduleEdit.Labels.ActualTime')}
          fullWidth
          margin="normal"
          allowEmptyValue
          value={action.timeActual}
          okLabel={t('ActionScheduleEdit.Buttons.SetActualTime')}
        />

        {action && action.type.id === ActionTypeIds.MOVEMENT &&
          <LocationAutocomplete
            disabled={true}
            value={action.movementLocation || null}
          />
        }

        {!agentPortal &&
          <Box height="20rem" mt="1rem">
            <LocationTimeline customPortCall={portCall} customAction={action} />
          </Box>
        }

      </CardContent>
      {createRequestAnchorEl && 
        <AgentRequestTimeChangeDialog
          portCall={portCall} 
          action={action} 
          contactId={userContactId}
          anchorEl={createRequestAnchorEl}
          onClose={() => setCreateRequestAnchorEl(null)}
        />
      }
    </div>
  )
}

export default AgentActionScheduleEdit;
