import React, { useState, useEffect, useRef } from "react";
import { FilterCheckboxTree } from "./FilterCheckBoxTree";
import { makeStyles } from "@material-ui/core/styles";
import {
  Popover,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Close } from "mdi-material-ui";
import { filterCondition, filterTypes, filterActions} from "../../constants/Filters";
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles((theme) => ({
  formControl: { marginTop: "1rem", marginRight: "1rem" },
  inputElement: { marginRight: "1rem" },
  buttons: {
    margin: "1rem",
  },
}));

const {DEFAULT_FILTER, UPDATE_FILTER, USE_FILTER, NO_NAME, SPECIAL_CHARACTERS, NOT_UNIQUE, NEW_FILTER} = filterActions;

const {CHANGED, NO_CHANGE, NEW} = filterCondition;

const Filters = ({
  open,
  setOpen,
  filterAnchorEl,
  filters,
  setFilter,
  filterList,
  allChecked,
  locationTree,
  enabledFilters,
  TYPE: { id: typeId, name: typeName, prefix },
  hideEditControls,
  disabledEdit,
}) => {
  //State to store filter before applying it on the main component
  //This is mainly the actual values of the filter
  const [newFilter, setNewFilter] = useState(filters);
  //State to store the currentFilter for the select component
  //The name of the filter
  const [currentFilter, setCurrentFilter] = useState(filters);
  const { t } = useTranslation();

  const [formChecks, setFormChecks] = useState({
    changed: false,
    newName: false,
    error: false,
    helperText: t('Filter.Labels.EnterFilterName'),
  });
  const inputEl = useRef();

  //Method to handleChange - find the id of the filter in the filterList
  const handleChange = ({ target: { value } }) => {
    const filter = filterList.find((e) => e.id === value);
    setNewFilter({ ...filter });
  };

  //handleSelect updates the newFilter value with the Radio Button Value
  const handleSelect = ({ target: { value } }) => {
    setNewFilter({ ...newFilter, status: value });
  };

  //Pass this on to the Checkbox Tree Component so that the current radio buttons
  //Values are maintained
  const setLocationFilter = (checked) => {
    setNewFilter({ ...newFilter, location: checked });
  };

  //This will set the newFilter value to what's currently in the filter variable
  //When the popover is open
  useEffect(() => {
    if (open === false) return;
    setNewFilter(filters);
  }, [open]);

  //This will take in the status and location and check if there are any filters that
  //match those. It will either return the filter[0] = "AllFilter" or the filter that matches
  const checkFilters = ({ status, location }) => {
    //Makes sure that allChecked array exists - with the array of the locations for allchecked
    if (allChecked) {
      //Checks for All Filter
      if (status === filterTypes.ALL && location[0] === filterTypes.ALL) return filterList[0];
      //Second check for All Filter - Check if the locations in all check are in locations array
      if (status === filterTypes.ALL && allChecked.every((e) => location.includes(e)))
        return filterList[0];
      //Check for the other filters
      const filter = filterList.find(
        ({ status: fStatus, location: fLocation }) => {
          //Check the status and locations against the filter List
          if (
            fStatus === status &&
            fLocation.every((e) => location.includes(e)) &&
            location.every((e) => fLocation.includes(e))
          )
            return true;
        }
      );
      return filter;
    }
  };

  //Helpfunction to set the form checks.
  const setChecks = (changeBool, newNameBool) => {
    setFormChecks({
      changed: changeBool,
      newName: newNameBool,
      error: false,
      helperText: t('Filter.Labels.EnterFilterName'),
    });
  };

  const setError = (errorMessage) => {
    setFormChecks({ ...formChecks, error: true, helperText: errorMessage });
  };

  const cleanText = (text) => {
    return text.replace(/\s/g, "").trim().toLowerCase();
  };

  const checkName = (filterName) => {
    const checkValue = filterList.find(({ name }) => {
      if (cleanText(name) === cleanText(filterName)) return true;
      return false;
    });
    return checkValue ? false : true;
  };

  const noSpecialCharacters = (filterName) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(filterName)) {
      return true;
    } else {
      return false;
    }
  };

  //Checks if there is an active filter
  //If there is an active filter then set the current filter to that
  useEffect(() => {
    let activeFilter = checkFilters(newFilter);
    //If there is an active filter (existing filter)
    //Then set the current filter as the active filter
    if (activeFilter) {
      //If there is a filter recognised from the list
      //And it is a complete match
      setCurrentFilter(activeFilter);
      setChecks(false, false);
    } else if (!activeFilter && currentFilter.id === typeId) {
      //If there are no returns but the current filter is the default
      //The then current filter is updated to be empty
      setCurrentFilter({ id: "" });
      setChecks(false, true);
    } else if (!activeFilter && currentFilter.id !== "") {
      //If there is no active filter and the id is empty then
      //The form checks are activated
      setChecks(true, true);
    } else {
      //This defaults to just allowing a new name being entered
      //If there is no filter
      setChecks(false, true);
    }
  }, [newFilter]);

  //Submit Method
  const submit = () => {
    //FilterName gets the text value from the text input
    const filterName = inputEl.current ? inputEl.current.value.trim() : null;
    //Action store the varible of what to do
    let action = "";

    //If statement figures out the next course of action
    currentFilter.id === typeId
      ? (action = DEFAULT_FILTER)
      : !filterName && currentFilter.id !== "" && formChecks.changed
      ? (action = UPDATE_FILTER)
      : !filterName && currentFilter.id !== "" && !formChecks.changed
      ? (action = USE_FILTER)
      : !filterName && currentFilter.id === ""
      ? (action = NO_NAME)
      : noSpecialCharacters(filterName)
      ? (action = SPECIAL_CHARACTERS)
      : !checkName(filterName)
      ? (action = NOT_UNIQUE)
      : (action = NEW_FILTER);

    //Switch statment takes care of what happens - done this way for clarity
    switch (action) {
      case DEFAULT_FILTER:
        setFilter(filterList[0]);
        setOpen(false);
        break;
      case NO_NAME:
        setError(t('Filter.Labels.NoName'));
        break;
      case UPDATE_FILTER:
        setFilter({
          ...currentFilter,
          location: newFilter.location,
          status: newFilter.status,
          condition: CHANGED,
        });
        setOpen(false);
        break;
      case USE_FILTER:
        setFilter({
          ...currentFilter,
          location: newFilter.location,
          status: newFilter.status,
          condition: NO_CHANGE,
        });
        setOpen(false);
        break;
      case NOT_UNIQUE:
        setError(t('Filter.Labels.UniqueName'));
        break;
      case SPECIAL_CHARACTERS:
        setError(t('Filter.Labels.SpecialCharacters'));
        break;
      case NEW_FILTER:
        setFilter({
          ...newFilter,
          id: prefix + filterName.replace(/ /g, "_"),
          name: filterName,
          condition: NEW,
        });
        setOpen(false);
        break;
      default:
        break;
    }
  };

  //This just closes the popover
  const close = () => setOpen(false);

  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={filterAnchorEl.current}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Card>
        <CardContent>
          {open === true ? (
            <div>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">{`${typeName}: ${t('Filter.Labels.ApplyFilters')}`}</Typography>
                <IconButton id="FilterCloseButton" onClick={close}>
                  <Close />
                </IconButton>
              </Box>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="selectFilterLabel">{t('Filter.Labels.SelectFilter')}</InputLabel>
                <Select
                  labelId="selectFilterLabel"
                  className={classes.inputElement}
                  id="selectFilter"
                  value={currentFilter.id}
                  onChange={handleChange}
                >
                  {filterList.map((filter, index) => {
                    return (
                      <MenuItem index={index} key={filter.id} value={filter.id}>
                        {filter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText
                  error={true}
                >
                  {formChecks.changed
                    ? t('Filter.Labels.FilterUpdated')
                    : null}
                </FormHelperText>
              </FormControl>
              {!hideEditControls &&
              <>
                <FilterCheckboxTree
                  locationTree={locationTree}
                  setLocationFilter={setLocationFilter}
                  filters={newFilter}
                  allChecked={allChecked}
                  disabled={disabledEdit}
                />
                <FormControl
                  className={classes.formControl}
                  component="fieldset"
                  fullWidth
                >
                  <InputLabel shrink id="selectRadioLabel">
                    {t('Filter.Labels.SelectType')}
                  </InputLabel>
                  <RadioGroup name="filter" onChange={handleSelect}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      {enabledFilters.map((filter, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            style={{ marginRight: "0px" }}
                            control={
                              <Radio
                                id={`Filter${filter.label}Input`}
                                value={filter.value}
                                checked={filter.value === newFilter.status}
                                disabled={disabledEdit}
                              />
                            }
                            label={filter.label}
                            id={filter.value}
                          />
                        );
                      })}
                    </div>
                  </RadioGroup>
                </FormControl>
                {currentFilter.id !== typeId && formChecks.newName ? (
                  <div className={classes.inputElement}>
                    <TextField
                      label={t('Filter.Labels.FilterName')}
                      id="FilterNameInput"
                      size="small"
                      placeholder={t('Filter.Labels.FilterNamePrompt')}
                      fullWidth
                      autoComplete="off"
                      inputRef={inputEl}
                      error={formChecks.error}
                      helperText={formChecks.helperText}
                      disabled={disabledEdit}
                    />
                  </div>
                ) : null}
              </>}
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  id="ApplyFiltersButton"
                >
                  {t('Filter.Labels.ApplyFilters')}
                </Button>
              </Box>
            </div>
          ) : null}
        </CardContent>
      </Card>
    </Popover>
  );
};

export default Filters;
