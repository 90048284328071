

export const ExportField = {
  PortCallReferenceID: "PortCallReferenceID",
  PortCallPortOfLoading: "PortCallPortOfLoading",
  PortCallLastPort: "PortCallLastPort",
  PortCallNextPort: "PortCallNextPort",
  PortCallPortOfDischarge: "PortCallPortOfDischarge",
  PortCallCategory: "PortCallCategory",
  PortCallStatus: "PortCallStatus",
  PortCallCargo: "PortCallCargo",
  PortCallCargoIn: "PortCallCargoIn",
  PortCallCargoOut: "PortCallCargoOut",
  PortCallRemarks: "PortCallRemarks",
  PortCallTags: "PortCallTags",
  PortCallPort: "PortCallPort",
  VesselName: "VesselName",
  VesselIMO: "VesselIMO",
  VesselMMSI: "VesselMMSI",
  VesselCallSign: "VesselCallSign",
  VesselFlag: "VesselFlag",
  VesselType: "VesselType",
  VesselYearBuilt: "VesselYearBuilt",
  VesselPortOfRegistry: "VesselPortOfRegistry",
  VesselLength: "VesselLength",
  VesselBeam: "VesselBeam",
  VesselDraught: "VesselDraught",
  VesselDeadweightTonnage: "VesselDeadweightTonnage",
  VesselGrossTonnage: "VesselGrossTonnage",
  VesselNetTonnage: "VesselNetTonnage",
  VesselCertificates: "VesselCertificates",
  AgentName: "AgentName",
  AgentEmail: "AgentEmail",
  AgentTelephoneNumber: "AgentTelephoneNumber",
  ArrivalTimeRequested: "ArrivalTimeRequested",
  ArrivalTimePlanned: "ArrivalTimePlanned",
  ArrivalTimeEstimated: "ArrivalTimeEstimated",
  ArrivalTimeReady: "ArrivalTimeReady",
  ArrivalTimeActual: "ArrivalTimeActual",
  ArrivalPilotBoarding: "ArrivalPilotBoarding",
  ArrivalLocation: "ArrivalLocation",
  ArrivalPilots: "ArrivalPilots",
  ArrivalLinesmen: "ArrivalLinesmen", 
  ArrivalMooringVessels: "ArrivalMooringVessels",
  ArrivalMastersName: "ArrivalMastersName",
  ArrivalCrew: "ArrivalCrew",
  ArrivalPassengers: "ArrivalPassengers",
  ArrivalForwardDraught: "ArrivalForwardDraught",
  ArrivalAftDraught: "ArrivalAftDraught",
  ArrivalSailingDraught: "ArrivalSailingDraught",
  ArrivalAirDraught: "ArrivalAirDraught",
  DepartureTimeRequested: "DepartureTimeRequested",
  DepartureTimePlanned: "DepartureTimePlanned",
  DepartureTimeReady: "DepartureTimeReady",
  DepartureTimeActual: "DepartureTimeActual",
  DeparturePilotBoarding: "DeparturePilotBoarding",
  DepartureLocation: "DepartureLocation",
  DeparturePilots: "DeparturePilots",
  DepartureLinesmen: "DepartureLinesmen",
  DepartureMooringVessels: "DepartureMooringVessels",
  DepartureMastersName: "DepartureMastersName",
  DepartureCrew: "DepartureCrew",
  DeparturePassengers: "DeparturePassengers",
  DepartureForwardDraught: "DepartureForwardDraught",
  DepartureAftDraught: "DepartureAftDraught",
  DepartureSailingDraught: "DepartureSailingDraught",
  DepartureAirDraught: "DepartureAirDraught"
};

export const ExportFieldKeysPortCall = {
  [ExportField.PortCallReferenceID]: "FileExportFields.Labels.PortCallReferenceID",
  [ExportField.PortCallPortOfLoading]: "FileExportFields.Labels.PortCallPortOfLoading",
  [ExportField.PortCallLastPort]: "FileExportFields.Labels.PortCallLastPort",
  [ExportField.PortCallNextPort]: "FileExportFields.Labels.PortCallNextPort",
  [ExportField.PortCallPortOfDischarge]: "FileExportFields.Labels.PortCallPortOfDischarge",
  [ExportField.PortCallCategory]: "FileExportFields.Labels.PortCallCategory",
  [ExportField.PortCallStatus]: "FileExportFields.Labels.PortCallStatus",
  [ExportField.PortCallCargo]: "FileExportFields.Labels.PortCallCargo", 
  [ExportField.PortCallRemarks]: "FileExportFields.Labels.PortCallRemarks",
  [ExportField.PortCallTags]: "FileExportFields.Labels.PortCallTags",
  [ExportField.PortCallPort]: "FileExportFields.Labels.PortCallPort"
};

export const ExportFieldKeysVessel = {
  [ExportField.VesselName]: "FileExportFields.Labels.VesselName",
  [ExportField.VesselIMO]: "Vessel.Labels.IMO",
  [ExportField.VesselMMSI]: "Vessel.Labels.MMSI",
  [ExportField.VesselCallSign]: "Vessel.Labels.CallSign",
  [ExportField.VesselFlag]: "Vessel.Labels.Flag",
  [ExportField.VesselType]: "Vessel.Labels.Type",
  [ExportField.VesselYearBuilt]: "Vessel.Labels.YearBuilt",
  [ExportField.VesselPortOfRegistry]: "Vessel.Labels.PortOfRegistry",
  [ExportField.VesselLength]: "Vessel.Labels.Length",
  [ExportField.VesselBeam]: "Vessel.Labels.Beam",
  [ExportField.VesselDraught]: "Vessel.Labels.Draught",
  [ExportField.VesselDeadweightTonnage]: "Vessel.Labels.DeadweightTonnage",
  [ExportField.VesselGrossTonnage]: "Vessel.Labels.GrossTonnage",
  [ExportField.VesselNetTonnage]: "Vessel.Labels.NetTonnage",
  [ExportField.VesselCertificates]: "Vessel.Labels.Certificates"
};
export const ExportFieldKeysAgent = {
  [ExportField.AgentName]: "Contact.Labels.Name",
  [ExportField.AgentEmail]: "Contact.Labels.Email",
  [ExportField.AgentTelephoneNumber]: "Contact.Labels.TelephoneNumber",
};

export const ExportFieldKeysArrival = {
  [ExportField.ArrivalTimeRequested]: "FileExportFields.Labels.ArrivalTimeRequested",
  [ExportField.ArrivalTimePlanned]: "FileExportFields.Labels.ArrivalTimePlanned",
  [ExportField.ArrivalTimeEstimated]: "FileExportFields.Labels.ArrivalTimeEstimated",
  [ExportField.ArrivalTimeReady]: "FileExportFields.Labels.ArrivalTimeReady",
  [ExportField.ArrivalTimeActual]: "FileExportFields.Labels.ArrivalTimeActual",
  [ExportField.ArrivalPilotBoarding]: "FileExportFields.Labels.ArrivalPilotBoarding",
  [ExportField.ArrivalLocation]: "FileExportFields.Labels.ArrivalLocation",
  [ExportField.ArrivalPilots]: "FileExportFields.Labels.ArrivalPilots",
  [ExportField.ArrivalLinesmen]: "FileExportFields.Labels.ArrivalLinesmen", 
  [ExportField.ArrivalMooringVessels]: "FileExportFields.Labels.ArrivalMooringVessels",
  [ExportField.ArrivalMastersName]: "FileExportFields.Labels.ArrivalMastersName",
  [ExportField.ArrivalCrew]: "FileExportFields.Labels.ArrivalCrew",
  [ExportField.ArrivalPassengers]: "FileExportFields.Labels.ArrivalPassengers",
  [ExportField.ArrivalForwardDraught]: "FileExportFields.Labels.ArrivalForwardDraught",
  [ExportField.ArrivalAftDraught]: "FileExportFields.Labels.ArrivalAftDraught",
  [ExportField.ArrivalSailingDraught]: "FileExportFields.Labels.ArrivalSailingDraught",
  [ExportField.ArrivalAirDraught]: "FileExportFields.Labels.ArrivalAirDraught",
};

export const ExportFieldKeysDeparture = {
  [ExportField.DepartureTimeRequested]: "FileExportFields.Labels.DepartureTimeRequested",
  [ExportField.DepartureTimePlanned]: "FileExportFields.Labels.DepartureTimePlanned",
  [ExportField.DepartureTimeActual]: "FileExportFields.Labels.DepartureTimeActual",
  [ExportField.DepartureTimeReady]: "FileExportFields.Labels.DepartureTimeReady",
  [ExportField.DeparturePilotBoarding]: "FileExportFields.Labels.DeparturePilotBoarding",
  [ExportField.DepartureLocation]: "FileExportFields.Labels.DepartureLocation",
  [ExportField.DeparturePilots]: "FileExportFields.Labels.DeparturePilots",
  [ExportField.DepartureLinesmen]: "FileExportFields.Labels.DepartureLinesmen",
  [ExportField.DepartureMooringVessels]: "FileExportFields.Labels.DepartureMooringVessels",
  [ExportField.DepartureMastersName]: "FileExportFields.Labels.DepartureMastersName",
  [ExportField.DepartureCrew]: "FileExportFields.Labels.DepartureCrew",
  [ExportField.DeparturePassengers]: "FileExportFields.Labels.DeparturePassengers",
  [ExportField.DepartureForwardDraught]: "FileExportFields.Labels.DepartureForwardDraught",
  [ExportField.DepartureAftDraught]: "FileExportFields.Labels.DepartureAftDraught",
  [ExportField.DepartureSailingDraught]: "FileExportFields.Labels.DepartureSailingDraught",
  [ExportField.DepartureAirDraught]: "FileExportFields.Labels.DepartureAirDraught"
};

export const ExportFieldKeys = { 
  ...ExportFieldKeysVessel, 
  ...ExportFieldKeysPortCall, 
  ...ExportFieldKeysAgent, 
  ...ExportFieldKeysArrival,
  ...ExportFieldKeysDeparture
};

export const ExportFieldGroups = [
  {
    id: "ExportVesselPanel",
    name: "FileExportFields.Labels.Vessel",
    fields: Object.entries(ExportFieldKeysVessel).map(([field, label]) => field)
  }, {
    id: "ExportPortCallPanel",
    name: "FileExportFields.Labels.PortCall",
    fields: Object.entries(ExportFieldKeysPortCall).map(([field, label]) => field)
  }, {
    id: "ExportAgentPanel",
    name: "FileExportFields.Labels.Agent",
    fields: Object.entries(ExportFieldKeysAgent).map(([field, label]) => field)
  }, {
    id: "ExportArrivalPanel",
    name: "FileExportFields.Labels.Arrival",
    fields: Object.entries(ExportFieldKeysArrival).map(([field, label]) => field)
  }, {
    id: "ExportDeparturePanel",
    name: "FileExportFields.Labels.Departure",
    fields: Object.entries(ExportFieldKeysDeparture).map(([field, label]) => field)
  }
];

export const ExportUnits = {
  METRES: 'm',
  FEET: 'ft'
}

export const ExportUnitKeys = {
  [ExportUnits.METRES]: "Common.Labels.M",
  [ExportUnits.FEET]: "Common.Labels.Feet"
}

export const ExportFieldLengthKeys = [
  'VesselLength',
  'VesselBeam',
  'VesselDraught',
  'ArrivalForwardDraught',
  'ArrivalAftDraught',
  'ArrivalSailingDraught',
  'ArrivalAirDraught',
  'DepartureForwardDraught',
  'DepartureAftDraught',
  'DepartureSailingDraught',
  'DepartureAirDraught'
]

export const DefaultLengthUnit = ExportUnits.METRES;

export const DefaultHeaderKeys = [
  ExportField.VesselName,
  ExportField.VesselLength,
  ExportField.PortCallCategory,
  ExportField.ArrivalLocation,
  ExportField.ArrivalTimePlanned,
  ExportField.ArrivalTimeActual,
  ExportField.DepartureTimePlanned,
  ExportField.DepartureTimeActual,
  ExportField.PortCallLastPort,
  ExportField.PortCallCargo,
  ExportField.AgentName,
  ExportField.AgentTelephoneNumber  
];

export const DefaultVesselHeaderKeys = [
  ExportField.VesselName,
  ExportField.VesselLength,
  ExportField.VesselGrossTonnage,
  ExportField.VesselMMSI,
  ExportField.VesselIMO,
  ExportField.VesselCertificates
];
