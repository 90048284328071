import React, { useContext } from 'react';
import CargoInstanceArrayFormRow from './CargoInstanceArrayFormRow';
import { Button, Typography } from '@material-ui/core';
import { Cargo } from '../../models';
import { UIContext } from '../../contexts/ui';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

export default ({ cargos, setCargos }) => {
  const { t } = useTranslation()
  const [uiContext,] = useContext(UIContext);
  const readOnly = uiContext.readOnly;

  const handleAddCargo = () => {
    setCargos([...cargos, new Cargo({})]);
  };
  const handleRemoveCargo = async (cargo) => {
    const newCargos = [...cargos];
    const deletedCargo = Cargo.copyOf(cargo, updated => {
      updated.deleted = true
    });
    newCargos.splice(newCargos.findIndex(c => c.id === cargo.id), 1, deletedCargo);
    setCargos(newCargos.filter(c => c.type));
  };
  const handleChangeCargo = (cargo) => {
    const newCargos = [...cargos];
    newCargos.splice(newCargos.findIndex(c => c.id === cargo.id), 1, cargo);
    setCargos(newCargos);
  };

  const filteredCargo = cargos.filter(c => c.deleted !== true)

  return (
    <>
      {filteredCargo.map((cargo, index) =>
        <CargoInstanceArrayFormRow
          key={index}
          disabled={readOnly}
          cargo={cargo}
          setCargo={(cargo) => handleChangeCargo(cargo)}
          onRemove={() => handleRemoveCargo(cargo)}
        />
      )}
      {!readOnly &&
        <Button id="AddCargoButton" color="primary" variant="outlined" onClick={handleAddCargo} style={{ marginTop: '.25rem' }}>
          <Typography>{t('Cargo.Buttons.AddCargo')}</Typography>
        </Button>
      }
    </>
  );
};