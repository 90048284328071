import React, { useCallback, useContext, useState } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, debounce, TextField } from '@material-ui/core';
import { vesselFilterOptions } from '../../utils/sorters';
// import { Magnify } from 'mdi-material-ui';
import VesselListItem from './VesselListItem';
import { API, graphqlOperation } from 'aws-amplify';
import { getVesselExternal } from '../../graphql/queries';
import { validateVesselImo, validateVesselMmsi } from '../../utils/validators';
import { DataStoreContext } from '../../contexts/dataStoreContext';

const VesselAutocomplete = ({ value, onChange, variant, textfieldProps, maxLength, filterOptions, label, ...other }) => {
  const { vessels } = useContext(DataStoreContext);
  const [loading, setLoading] = useState(false);
  const handleInputChange = useCallback(debounce(async (value) => {
    if (variant === "mmsi" || variant === "imo") {
      if ((variant === 'imo' && validateVesselImo(value) && !vessels.find(v => v.imo === value)) ||
        (variant === 'mmsi' && validateVesselMmsi(value) && !vessels.find(v => v.mmsi === value))) {
        setLoading(true);
        const result = await API.graphql(graphqlOperation(getVesselExternal, {
          [variant]: value,
          useApi: true
        }));
        setLoading(false);
        // this should automatically come through datastore
        console.log("getVesselExternal", result);
      }
    }
  }, 100), [vessels, setLoading]);

  return (
    <Autocomplete
      getOptionSelected={(option, value) => option.id === value.id}
      autoHighlight
      forcePopupIcon={false}
      options={vessels}
      filterOptions={filterOptions}
      getOptionLabel={value => value?.vesselData?.name || ''}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <VesselListItem
          vesselData={item.vesselData}
        />
      )}
      renderInput={props => (
        <TextField
          {...props}
          {...textfieldProps}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off',
            ...textfieldProps && textfieldProps.inputProps,
            // chrome ignores maxLength for type="number"
            onChange: (e) => { if (!maxLength || e.target.value.length <= maxLength) { handleInputChange(e.target.value); props.inputProps.onChange(e); } }
          }}
          InputProps={{
            ...props.InputProps,
            endAdornment: loading && <CircularProgress size="1rem" color="primary" />
          }}
        />
      )}
      {...other}
    />
  );
};

VesselAutocomplete.defaultProps = {
  filterOptions: vesselFilterOptions
};

export default VesselAutocomplete;