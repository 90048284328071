import React, {
  useContext,
  useEffect,
  useState,
  useMemo, useCallback, useRef
} from 'react';
import {
  CardContent,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, Prompt, useLocation } from 'react-router-dom';

import { EventType as et } from '../../constants/EventType';
import ContentIndex from '../ContentIndex';
import PortCallTimeline from '../PortCall/PortCallTimeline';
import AgentHandoverEdit from '../Action/AgentHandoverEdit';
import CustomActionEdit from './CustomActionEdit';
import MovementEdit from '../Action/MovementEdit';
import { NavigationContext, navigationActionConstants } from '../../contexts/navigation';
import {
  getActionTime,
  getPendingActionRequests,
  getActionActiveAgent
} from '../../utils/getters';
import Loading from '../Loading';
import { DataStore } from 'aws-amplify';
import { Action, ActionEventType, PortCall, ActionState } from '../../models';
import isEqual from 'lodash.isequal';
import { ActionTypeIds } from '../../environment';
import { sortActions } from '../../utils/sorters';
import { ActionDetailsHref } from '../../constants/ActionDetails';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { UIContext } from '../../contexts/ui';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import useApprovalSetting from '../../hooks/useApprovalSetting';
import useAuditMeta from '../../hooks/useAuditMeta';
import TextInputDialog from '../Dialog/TextInputDialog';
import { ActionMovementTypeLabelKeys as amt } from '../../constants/ActionMovementTypeLabel';
import EditorContentTabs, { EditorContentTabIds, useEditorContentTab } from '../Common/EditorContentTabs';
import RequestDetails from '../Requests/RequestDetails';
import LogBook from '../LogBook/LogBook';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { DatastoreStatusContext } from '../../contexts/datastoreStatusContext';

const useStyles = makeStyles(theme => ({
  scrollPane: {
    height: '100%',
    overflow: 'auto',
  },
  rootPane: {
    display: "flex",
    flexFlow: "column",
    height: '100%',
    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  greyPanel: {
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    position: 'relative'
  },
  linkButton: {
    display: 'inline-block',
    padding: '0.375rem 1rem',
    color: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  warningMessage: {
    color: '#ff9800',
    paddingBottom: '1rem'
  },
  contentWrapper: {
    '& > :last-child': {
      marginBottom: "5rem"
    }
  }
}));

const CONFIRM_SAVE_DIALOG = {
  UNSAVED: "UNSAVED",
  APPROVAL: "APPROVAL"
}

const ActionDetails = ({
  portCallId,
  actionId,
  requestId,
  portCallDetails
}) => {
  const location = useLocation();
  const history = useHistory();
  const [navigationContext,dispatchNavigationContext] = useContext(NavigationContext);
  const [uiContext,] = useContext(UIContext);
  const [confirmSave, setConfirmSave] = useState({ open: null });
  const [activeActionId, setActiveActionId] = useState(actionId);
  // Save a port call for frame of reference
  const [portCallReference, setPortCallReference] = useState(null);
  // Port call object for editting
  const [portCall, setPortCall] = useState(null);
  //detect any error occured in child components to disable save button
  const [localError, setLocalError] = useState(null);
  //Warn user when another user edit action details
  const isFirstRender = useRef(true);
  const [incomingChanges, setIncomingChanges] = useState({ open: null, acceptIncomingChanges: false });
  const isSynced = useContext(DatastoreStatusContext)

  const { actionTypes } = useContext(DataStoreContext);
  const customActionTypes = useMemo(() => actionTypes.filter(at => at.id !== ActionTypeIds.MOVEMENT && at.id !== ActionTypeIds.EVENT), [actionTypes]);

  const { t } = useTranslation();
  
  useEffect(() => {
      dispatchNavigationContext({ type: navigationActionConstants.SET_LAST_VIEWS, payload: window.location.pathname });
  }, [dispatchNavigationContext]);

  const indexTrees = useMemo(() => {
    return {
      MOVEMENT: [
        {
          href: ActionDetailsHref.HREF_SCHEDULE,
          label: t('ActionDetails.Labels.Schedule')
        },
        {
          href: ActionDetailsHref.HREF_CONTACT,
          label: t('ActionDetails.Labels.CharterAgent')
        },
        {
          href: ActionDetailsHref.HREF_CHECKLIST,
          label: t('ActionDetails.Labels.Checklist'),
        },
        {
          href: ActionDetailsHref.HREF_ATTACHMENTS,
          label: t('ActionDetails.Labels.Attachments'),
        },
        {
          href: ActionDetailsHref.HREF_PILOTAGE,
          label: t('ActionDetails.Labels.Pilotage')
        },
        {
          href: ActionDetailsHref.HREF_VESSEL,
          label: t('ActionDetails.Labels.Vessel'),
          children: [
            {
              href: ActionDetailsHref.HREF_VESSEL_DETAILS,
              label: t('ActionDetails.Labels.VesselDetails'),
            },
            {
              href: ActionDetailsHref.HREF_VESSEL_CONDITION,
              label: t('ActionDetails.Labels.VesselCondition'),
            },
          ]
        },
        {
          href: ActionDetailsHref.HREF_CHARGEABLES,
          label: t('ActionDetails.Labels.ChargeableItems')
        },
      ],
      EVENT: [
        {
          href: ActionDetailsHref.HREF_CONTACT,
          label: t('ActionDetails.Labels.CharterAgent')
        },
      ],
      CUSTOM_ACTION: [
        {
          href: ActionDetailsHref.HREF_SCHEDULE,
          label: t('ActionDetails.Labels.Schedule')
        },
        {
          href: ActionDetailsHref.HREF_NOTES,
          label: t('CustomActionEdit.Labels.Notes')
        },
        {
          href: ActionDetailsHref.HREF_CHARGEABLES,
          label: t('ActionDetails.Labels.ChargeableItems')
        },
        {
          href: ActionDetailsHref.HREF_ATTACHMENTS,
          label: t('ActionDetails.Labels.Attachments'),
        }
      ],
    }
  }, [t]);

  // action - Action object based on the actionId provided
  const action = useMemo(() => portCall && portCall.actions.find(a => a.id === actionId), [portCall, actionId]);

  const isMovement = action && action.type.id === ActionTypeIds.MOVEMENT;
  const isAgentHandOver = !isMovement && action && action.type.id === ActionTypeIds.EVENT && action.eventType === et.AGENT_HANDOVER;
  const isCustomAction = !isAgentHandOver && action && customActionTypes && customActionTypes.some(cat => cat.id === action.type.id);

  const activeAgent = useMemo(() => portCall && action && getActionActiveAgent(portCall.actions, action), [portCall, action]);

  // get the pending requests for an actions
  const actionRequests = useMemo(() => getPendingActionRequests(portCall, action), [portCall, action]);
  const [selectedRequest, setSelectedRequest] = useState();
  const { appApprovalEnabled } = useApprovalSetting();
  const auditMeta = useAuditMeta();

  const classes = useStyles();
  const tab = useEditorContentTab();

  const saveDisabled = useMemo(() =>
    localError ||
    !portCall ||
    !portCallReference ||
    // for now compare only actions
    isEqual([...portCall.actions].sort(sortActions), [...portCallReference.actions].sort(sortActions)) || // isEqual does not ignore ordering; child components may be setting actions out of order
    (action && action.eventType && action.eventType === ActionEventType.AGENT_HANDOVER && (!getActionTime(action) || !action.actionAgent)),
    [portCall, portCallReference, action, localError]);

  useEffect(() => {
    if (!isEqual(portCallReference, portCallDetails)) {
      // latest actions from hook
      const actionDetails = portCallDetails?.actions?.find(a => a.id === actionId);
      //Only show the upcoming changes to the change that made from other user edited the action and ignore, if it is the first render
      isFirstRender.current
        ? (isFirstRender.current = false)
        : actionDetails?.auditMeta?.userId &&
        uiContext?.userName !== actionDetails?.auditMeta?.userId && !saveDisabled &&
        setIncomingChanges(prev => { return { ...prev, open: true } });
        
      setPortCall(portCallDetails);
      setPortCallReference(portCallDetails);
      setActiveActionId(actionId);
    }

  }, [portCallReference, portCallDetails, setPortCallReference, setPortCall, actionId, uiContext?.userName, saveDisabled]);

  // reset changes when switching between actions
  useEffect(() => {
    if (!portCallReference || !actionId) return;
    if (activeActionId !== actionId) {
      setPortCall(portCallReference);
      setActiveActionId(actionId);
    }
  }, [portCallReference, setPortCall, activeActionId, setActiveActionId, actionId]);

  // Function for updating the portCall object with any changes
  const setAction = useCallback(value => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [...portCall.actions.filter(a => a.id !== value.id), value];
    }));
  }, [portCall, setPortCall]);

  const updatePortCallAgents = useCallback((action, agents) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.agents = [...agents];
      updated.actions = [...portCall.actions.filter(a => a.id !== action.id), action];
    }));
  }, [portCall, setPortCall]);

  const trySavePortCall = async () => {
    if (!uiContext.isApprover &&
      (action.state === ActionState.PLANNED || action.state === ActionState.APPROVAL_DECLINED) && isMovement) {
      setConfirmSave({
        open: CONFIRM_SAVE_DIALOG.APPROVAL,
        title: t('ActionDetails.Labels.ConfirmSaveTitle', { movementType: t(amt[action.movementType]), vesselName: portCall.vesselData.name }),
        message: t('ActionDetails.Labels.ConfirmSaveMessage', { movementType: t(amt[action.movementType]), vesselName: portCall.vesselData.name })
      })
    } else {
      handleSavePortCall();
    }
  };

  const handleSavePortCall = async (comment) => {
    const { markForApproval } = confirmSave;
    if (confirmSave.open) {
      setConfirmSave({ open: null });
    }
    // may need to save multiple actions
    for (let action of portCall.actions) {
      if (!isEqual(action, portCallReference.actions.find(a => a.id === action.id))) {
        const newAction = Action.copyOf(action, updated => {
          // update auditMeta and approval fields only in action that user is trying to save
          if (updated.id === actionId) {
            updated.auditMeta = { ...auditMeta, comment: comment || undefined };
            if (markForApproval) {
              updated.state = ActionState.APPROVAL_PENDING;
            }
          }
        });
        DataStore.save(newAction);
      }
    }
    // update the port call if any agent handovers have changed
    let portCallChanged = false;
    if (portCall.agents) {
      for (let agent of portCall.agents) {
        if (!isEqual(agent, portCallReference.agents.find(a => a.actionId === agent.actionId))) {
          portCallChanged = true;
        }
      }
      portCallChanged && DataStore.save(portCall);
    }
  };

  //set localError to true , if there are any errors 
  const handleLocalError = (error) => { setLocalError(Boolean(error)); }

  const checkSaveState = () => {
    setConfirmSave({ open: CONFIRM_SAVE_DIALOG.UNSAVED });
  }

  // return by timeout if item gets deleted and results in empty view
  useEffect(() => {
    let timeout;
    if (!portCall || !action) {
      timeout = setTimeout(() => {
        history.push(location.pathname.substring(0, location.pathname.indexOf("/action") || undefined));
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [portCall, action, history, location]);

  // add crew list to content index (feature)
  const isCrewListSubmissionEnabled = useFeatureSetting('crew-list-submission');
  const movementIndexTree = !isCrewListSubmissionEnabled ? indexTrees["MOVEMENT"] : [
    ...indexTrees["MOVEMENT"],
    {
      href: ActionDetailsHref.HREF_FAL5_CREW_LIST,
      label: t('FileTypes.Labels.Fal5CrewList'),
    }
  ];

  if (!portCall || !action) return <Loading />
  return (
    <>
      <Prompt
        when={!saveDisabled}
        message={(_, action) => {
          return action === "PUSH" ? t('ActionDetails.Labels.Prompt') : null;
        }}
      />
      <Grid container id={'ActionDetails'}>
        {/* Port Call TimeLines */}
        <Grid
          className={`${classes.scrollPane} ${classes.greyPanel}`}
          item
          xs={5}
        >
          <PortCallTimeline portCall={portCall} portCallReference={portCallReference} saveDisabled={saveDisabled} />
        </Grid>
        {/* Editor */}
        <Grid
          className={classes.rootPane}
          item
          xs={7}
        >
          <CardContent style={{ flexShrink: 0 }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <EditorContentTabs requests={actionRequests} />
              <Box position="absolute" right="2rem" display="flex">
                <Typography>
                  <Link
                    id="ActionDetailsDiscardCloseButton"
                    className={classes.linkButton}
                    to={navigationContext.lastView}
                  >
                    {!saveDisabled ? t('Common.Buttons.DiscardChanges') : t('Common.Buttons.Close')}
                  </Link>
                </Typography>
                {tab === EditorContentTabIds.DETAILS &&
                  <Button
                    id="ActionDetailsSave"
                    color="primary"
                    onClick={trySavePortCall}
                    variant="contained"
                    disabled={saveDisabled || !isSynced}
                  >
                    {t('Common.Buttons.Save')}
                  </Button>
                }
              </Box>
            </Grid>
          </CardContent>
          {action && tab === EditorContentTabIds.DETAILS &&
            <Grid
              item
              container
              style={{ flexWrap: "noWrap" }}
            >
              <CardContent style={{ flexShrink: "0" }}>
                {isMovement && <ContentIndex tree={movementIndexTree} containerId="ActionDetailsContent" />}
                {isAgentHandOver && <ContentIndex tree={indexTrees["EVENT"]} containerId="ActionDetailsContent" />}
                {isCustomAction && <ContentIndex tree={indexTrees["CUSTOM_ACTION"]} containerId="ActionDetailsContent" />}
              </CardContent>
              <Box flexGrow="1" overflow="auto" height="100%" id="ActionDetailsContent" className={classes.contentWrapper}>
                {
                  isMovement &&
                  <MovementEdit
                    portCall={portCall}
                    portCallReference={portCallReference}
                    setPortCall={setPortCall}
                    action={action}
                    setAction={setAction}
                    activeAgent={activeAgent}
                    selectedRequest={selectedRequest}
                    setSelectedRequest={setSelectedRequest}
                    checkSaveState={checkSaveState}
                    saveDisabled={saveDisabled}
                    handleLocalError={handleLocalError}
                  />
                }
                {
                  isAgentHandOver &&
                  <AgentHandoverEdit
                    action={action}
                    actions={portCall.actions}
                    portCall={portCall}
                    updatePortCallAgents={updatePortCallAgents}
                  />
                }
                {
                  isCustomAction &&
                  <CustomActionEdit
                    portCall={portCall}
                    setPortCall={setPortCall}
                    action={action}
                    setAction={setAction}
                    activeAgent={activeAgent}
                    selectedRequest={selectedRequest}
                    setSelectedRequest={setSelectedRequest}
                    checkSaveState={checkSaveState}
                    saveDisabled={saveDisabled}

                  />
                }
              </Box>
            </Grid>
          }
          {tab === EditorContentTabIds.REQUESTS &&
            <Grid
              className={classes.scrollPane}
              item
            >
              {actionRequests.map(r => <RequestDetails request={r} key={r.id} portCall={portCall} />)}
            </Grid>
          }
          {tab === EditorContentTabIds.LOGBOOK && <LogBook />}
          <ConfirmDialog
            open={!!incomingChanges?.open}
            title={t("Common.Labels.IncomingChanges")}
            message={t('Common.Errors.IncomingChangesWarning')}
            onConfirm={() => setIncomingChanges({ open: false, acceptIncomingChanges: true })}
            confirmText={t("Common.Buttons.Confirm")}
            hideCancel={true}
          />
          <ConfirmDialog
            open={confirmSave.open === CONFIRM_SAVE_DIALOG.UNSAVED}
            title={t('Common.Labels.UnsavedChanges')}
            message={t('ActionDetails.Labels.UnsavedChangesMessage')}
            onClose={() => setConfirmSave({ open: null })}
            cancelText={t('Common.Buttons.Close')}
          />
          {confirmSave.open === CONFIRM_SAVE_DIALOG.APPROVAL && <TextInputDialog
            open={confirmSave.open === CONFIRM_SAVE_DIALOG.APPROVAL}
            title={confirmSave.title}
            onClose={() => setConfirmSave({ ...confirmSave, open: null })}
            onConfirm={handleSavePortCall}
            inputLabel={t('Common.Labels.Comments')}
            message={
              <span>
                <Typography>{confirmSave.message}</Typography>
                {appApprovalEnabled &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(confirmSave.markForApproval)}
                        onChange={(e) => setConfirmSave({ ...confirmSave, markForApproval: Boolean(e && e.target && e.target.checked) })}
                        color="primary"
                      />
                    }
                    label={t('ActionDetails.Labels.SubmitForApproval')}
                  />
                }
              </span>
            }
          />}
        </Grid>
      </Grid >
    </>
  );
};

export default ActionDetails;
