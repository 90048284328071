import React from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import packageJson from '../../package.json';
import SmartPortBranding from './SmartPortBranding';

export default () => {
  const smartPortWithVersionText = `v${packageJson.version}`

  return (
    <Box display="flex" flexShrink={0} alignItems="center" justifyContent="center" >
      <Grid container justifyContent="space-evenly" direction="column" alignItems="center">
        <Grid item>
          <SmartPortBranding style={{fontSize: '1.6rem'}} />
        </Grid>
        <Grid item>
          <Typography variant="body2">{smartPortWithVersionText}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
};
