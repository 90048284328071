import React, { useEffect, useState } from 'react';
import { Box, Typography, Grow } from '@material-ui/core';
import SmartPortBranding from './SmartPortBranding';

export default () => {
  const [dots, setDots] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setDots(Math.floor(+new Date() / 1000) % 3 + 1), 500);
    return () => clearInterval(interval);
  }, [setDots]);
  return (
    <Grow in>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <SmartPortBranding style={{ marginTop: '1rem' }} />
        <Typography variant="caption" style={{ marginTop: '.5rem', lineHeight: '.5rem', marginBottom: '-1rem', fontWeight: 300, opacity: .65, display: "flex" }}>Syncing<Box width="0px">{'.'.repeat(dots)}</Box></Typography>
      </Box>
    </Grow>
  );
};
