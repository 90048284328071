import React, { useCallback } from 'react';
import { Box, Chip, Paper, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
  preview: {
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: 'hidden',
    flexShrink: 0,
    padding: ".5rem"
  },
  previewChip: {
    width: "100%"
  }
}));

const grid = 1;

const getItemStyle = (isDragging, draggableStyle, dragBackground) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? dragBackground : 'white',
  maxWidth: "200px",
  ...draggableStyle,
});

const PreviewItem = ({column, label, data, index}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Draggable draggableId={column} index={index} key={`ITEM_PREV_${index}`}>
      {(provided, snapshot) => (
        <Box
          l=".25rem"
          flexShrink={0}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            theme.palette.info.main
          )}      
        >
          <Chip size="small" label={label} className={classes.previewChip} />
          {Boolean(data) && 
            <Box marginLeft="0.5rem" marginRight="0.5rem">
              <Typography className='preview-value' variant="caption" component="div" noWrap>{data}</Typography>
            </Box>
          }
        </Box>
      )}
    </Draggable>
  );
};

const PreviewList = ({columns, preview}) => {
  return columns.map((column, index) => (
    <PreviewItem
      column={column}
      data={preview?.data[index]}
      label={preview?.labels[index]}
      index={index}
      key={column}
    />
  ));
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ExportPreview = ({columns, preview, onOrderChanged, ...other}) => {
  const classes = useStyles();

  const onDragEnd = useCallback((result) => {   
    if(!result.destination) {
      return;
    }
    if(result.destination.index === result.source.index) {
      return;
    }
    const fields = reorder(columns, result.source.index, result.destination.index);
    onOrderChanged && onOrderChanged(fields);
  }, [columns, onOrderChanged]);

  return (
    <Paper className={classes.preview} {...other}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='preview' direction='horizontal'>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} display="flex" overflow='auto'>
              <PreviewList columns={columns} preview={preview}/>
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
};

export default ExportPreview;