import React from "react";
import { makeStyles, TextField, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { DeleteOutline, Pencil } from 'mdi-material-ui';
import CurrencyFormat from '../../Common/CurrencyFormat';
import TariffUnitAdornment from "./TariffUnitAdornment";

const useStyles = makeStyles(theme => {
  return {
    columnIcon: {
      maxWidth: "40px",
      maxHeight: "40px",
      alignSelf: "auto",
      marginTop: ".6rem",
      width: "100%"
    },
  }
});

const TariffListEditorRow = ({ data, index, style}) => {
  const classes = useStyles();
  const disabled = true;

  return (
    <div
      style={{ 
        ...style, 
        width: "auto", 
        display: "flex", 
        alignItems: "baseline", 
        minWidth: "100%", 
        gap: "10px",
      }}
      key={data[index].data.id}
      data-id={data[index].data.id}
      className={'TariffEditorRow'}
    >
      <TextField
        name="code"
        value={data[index].data.cost.code || ""}
        fullWidth
        margin="normal"
        disabled={disabled}
        size="small"
        style={{ width: "30%" }}
      />
      <Tooltip title={data[index].data.cost.description || ""}>
        <TextField
          name="description"
          value={data[index].data.cost.description || ""}
          fullWidth
          margin="normal"
          disabled={disabled}
          size="small"
          style={{ width: "50%" }}
        />
      </Tooltip>
      <TextField
        name='unitCost'
        value={data[index].data.cost.unitCost || ""}
        fullWidth
        margin='normal'
        disabled={disabled}
        format='number'
        type='number'
        InputProps={{
          className: 'MinimumCostInput',
          inputComponent: CurrencyFormat,
          inputProps: { textAlign: 'left' },
          startAdornment: <InputAdornment position="start">{data[index].currency?.symbol}</InputAdornment>,
          endAdornment: <InputAdornment position="end"><TariffUnitAdornment unitText={data[index].unit}/></InputAdornment>,
          readOnly: disabled
        }}
        style={{ width: "20%" }}
      />
      <IconButton
        onClick={() => data[index].onUpdate && data[index].onUpdate(data[index].data)}
        className={classes.columnIcon}
      >
        <Pencil fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => data[index].onDelete && data[index].onDelete(data[index].data)}
        className={classes.columnIcon}
      >
        <DeleteOutline fontSize="small" />
      </IconButton>
    </div>
  )
};

export default TariffListEditorRow;