import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  List,
} from '@material-ui/core';
import { processTodoArray } from "../../utils/todo";
import { TodoStatus as ts } from '../../constants/TodoStatus';
import TodoListItem from './TodoListItem';
import TodoIconWithPopover from './TodoIconWithPopover';
import AddTodo from './AddTodo';
import { DataStore } from 'aws-amplify';
import { Todo, TodoTypeTemplate } from '../../models';
import { UIContext } from '../../contexts/ui';

const Checklist = ({
  clickHandler,
  createHandler = null,
  deleteHandler,
  items: initialItems,
  paperClassName,
  showDetails = false,
  showDelete = true,
  disabled = false,
  actionId
}) => {
  const [uiContext] = useContext(UIContext);
  const readOnly = uiContext.readOnly;
  const [todoTypeColorMap, setTodoTypeColorMap] = useState({});
  const [items, setItems] = useState([]);

  useEffect(() => {
    const createColorMap = async () => {
      const todoTypeTemplates = await DataStore.query(TodoTypeTemplate);
      const colorMap = todoTypeTemplates.reduce((acc, curr) => {
        acc[curr.todoTypeName] = curr.todoTypeColor;
        return acc;
      }, {});
      setTodoTypeColorMap(colorMap);
    };
    createColorMap();
  }, [setTodoTypeColorMap]);

  useEffect(() => {
    setItems(processTodoArray(initialItems, actionId));
  }, [initialItems])


  const toggleTodoStatus = (todo) => {
    const newStatus = todo.status === ts.READY ? ts.DONE : todo.status === ts.DONE ? ts.READY : todo.status;
    const newItems = [...items];
    newItems[todo.pos] = {...todo, status: newStatus};
    // cleanup from internal processing
    clickHandler && clickHandler(newItems.map(t => new Todo({
      description: t.description,
      status: t.status,
      title: t.title,
      todoType: t.todoType
    })));
  }

  const deleteTodo = (todo) => {
    const newItems = [...items];
    newItems[todo.pos] = {...todo, status: ts.DELETED};
    // cleanup from internal processing
    deleteHandler && deleteHandler(newItems.map(t => new Todo({
      description: t.description,
      status: t.status,
      title: t.title,
      todoType: t.todoType
    })));
  }

  const createTodo = (todo) => {
    const newItems = [...items];
    let newTodo = todo;
    if(items.length > 0) {
    const {actionId} = items[0];
    const pos = items.length;
    newTodo = {...todo, actionId, pos, id: `${actionId}${pos}`};
    }
    newItems.push(newTodo);
    // cleanup from internal processing
    createHandler && createHandler(newItems.map(t => new Todo({
      description: t.description,
      status: t.status,
      title: t.title,
      todoType: t.todoType
    })));
  }

  const existingTodoTypes = items.map(item => item.status !== ts.DELETED && item.todoType);

  return (
    <>
      {items && items.length > 0 &&
        showDetails
        ? (
          <List>
            {items
              .filter(item => item.status !== ts.DELETED)
              .map((item) => {
                return <TodoListItem
                  disabled={readOnly}
                  key={item.id}
                  item={item}
                  clickHandler={(!readOnly && !disabled) && toggleTodoStatus}
                  deleteHandler={deleteTodo}
                  showDelete={showDelete}
                  backgroundColor={todoTypeColorMap[item.todoType]}
                />
              })}
          </List>
        )
        :
        <Box
          display="flex"
          justifyContent="flex-start"
        >
          {items
            .filter(item => item.status !== ts.DELETED)
            .map(item => {

              return <TodoIconWithPopover
                key={item.id}
                item={item}
                clickHandler={(!readOnly && !disabled) && toggleTodoStatus}
                backgroundColor={todoTypeColorMap[item.todoType]}
                paperClassName={paperClassName}
              />
            })}
        </Box>
      }
      {!readOnly && createHandler &&
        <AddTodo
          existingTodoTypes={existingTodoTypes}
          createHandler={createTodo}
        />
      }
    </>
  )
}

export default Checklist;