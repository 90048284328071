import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import SimpleTooltip from '../Tooltip/SimpleTooltip';

/* 
 *
 *  Documentation
 *  Displays the port call remarks as 2 truncated lines
 *  Returns the remarks component
 *
*/

const useStyles = makeStyles(theme => ({
  remarks: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  }
}));


export const PortCallRemarks = ({
  remarks,
  disableGutters,
  delay,
  ...otherProps
}) => {
  const classes = useStyles();
  const parsedRemarks = remarks
    ? remarks.split('\n').map((item, index) => <span key={index}>{item}<br /></span>)
    : '';
  const truncatedRemarks = parsedRemarks.slice(0, 2);
  const small = remarks && remarks.length > 25;
  return (
    <ListItem
      component="div"
      disableGutters={disableGutters}
      {...otherProps}
    >
      <div className={classes.remarks}>
        <PopoverTooltip tooltip={<SimpleTooltip text={parsedRemarks} />} delay={delay}>
          <Typography variant={small ? "caption" : "body1"}>
            {truncatedRemarks}
          </Typography>
        </PopoverTooltip>
      </div>
    </ListItem>
  )
}

PortCallRemarks.defaultProps = {
  disableGutters: true,
}

PortCallRemarks.propTypes = {
  // Whether to display gutters
  disableGutters: PropTypes.bool,
  // Remark string text to display
  remarks: PropTypes.string,
}

export default PortCallRemarks;