import VectorSource from "ol/source/Vector";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Attrs, FeatureType } from '../constants';

class AISReceiverSource extends VectorSource {
  constructor(opt_options) {
    super({
      ...opt_options
    });
  }

  updateAISReceivers = (receivers, getColor) => {
    for(let r of receivers) {
      this.updateAISReceiver(r, getColor);
    }
  };

  updateAISReceiver = (receiver, getColor) => {
    let feature = this.getFeatureById(receiver.receiverName);

    const geomPoint = new Point(fromLonLat([ receiver?.long, receiver?.lat ]));

    if(!feature) {
      feature = new Feature({
        id: receiver.receiverName,
        name: receiver.receiverName
      });
      feature.setId(receiver.receiverName);
      feature.set(Attrs.POPUP, true);

      this.addFeature(feature);
    }

    feature.setGeometry(geomPoint);

    const lastMessage = this.getLastMessage(receiver)

    feature.set(Attrs.META, {
      ...lastMessage,
      receiverName: receiver.receiverName
    });
    feature.set(Attrs.TYPE, FeatureType.AISReceiver);
    getColor && feature.set(Attrs.COLOR, getColor(receiver.receiverName, lastMessage?.lastTimestamp));
  };

  getLastMessage = (receiver) => {
    const allMessages = receiver?.lastMessage;
    return (allMessages.length > 0) ? allMessages[allMessages.length - 1] : undefined
  }
};

export default AISReceiverSource;