import { ActionMovementType } from '../models';

export const MovementActionDisplayType = {
  ALL: "All",
  [ActionMovementType.ARRIVAL]: 'Arrival',
  [ActionMovementType.DEPARTURE]: 'Departure',
  [ActionMovementType.SHIFT_ARRIVAL]: 'Berth Shift Arrival',
  [ActionMovementType.SHIFT_DEPARTURE]: 'Berth Shift Departure'
};

export const MovementActionDisplayTypeKeys = {
  ALL: 'Common.Labels.All',
  [ActionMovementType.ARRIVAL]: 'ActionMovementType.Labels.Arrival',
  [ActionMovementType.DEPARTURE]: 'ActionMovementType.Labels.Departure',
  [ActionMovementType.SHIFT_ARRIVAL]: 'ActionMovementType.Labels.BerthShiftArrival',
  [ActionMovementType.SHIFT_DEPARTURE]: 'ActionMovementType.Labels.BerthShiftDeparture'
};

export const MovementActionTableHeaders = {
  Schedule: {label: 'MovementActionList.Labels.Schedule', description: "MovementActionList.Labels.ScheduleDesc"},
  PortCallID: {label: 'MovementActionList.Labels.PortCallId', description: "MovementActionList.Labels.PortCallIdDesc"},
  Vessel: {label: 'MovementActionList.Labels.Vessel', description: "MovementActionList.Labels.VesselDesc"},
  VoyageID: {label: 'MovementActionList.Labels.VoyageId', description: "MovementActionList.Labels.VoyageIdDesc"},
  PortOfRegistry: {label: 'MovementActionList.Labels.PortOfRegistry', description: "MovementActionList.Labels.PortOfRegistryDesc"},
  Dimensions: {label: 'MovementActionList.Labels.Dimensions', description: 'MovementActionList.Labels.DimensionsDesc'},
  Length: {label: 'MovementActionList.Labels.Length', fullLabel: 'MovementActionList.Labels.LengthFull', description: "MovementActionList.Labels.LengthDesc"},
  Beam: {label: 'MovementActionList.Labels.Beam', description: "MovementActionList.Labels.BeamDesc"},
  Draught: {label: 'MovementActionList.Labels.Draught', description: "MovementActionList.Labels.DraughtDesc"},
  AirDraught: {label: 'MovementActionList.Labels.AirDraught', description: "MovementActionList.Labels.AirDraughtDesc"},
  BowThrusters: {label: 'MovementActionList.Labels.BowThrusters', fullLabel: 'MovementActionList.Labels.BowThrustersFull', description: "MovementActionList.Labels.BowThrustersDesc"},
  Location: {label: 'MovementActionList.Labels.Location', description: "MovementActionList.Labels.LocationDesc"},
  CharterAgent: {label: 'MovementActionList.Labels.CharterAgent'},
  Cargo: {label: 'MovementActionList.Labels.Cargo', description: "MovementActionList.Labels.CargoDesc"},
  NextPort: {label: 'MovementActionList.Labels.NextPort', description: "MovementActionList.Labels.NextPortDesc"},
  LastPort: {label: 'MovementActionList.Labels.LastPort', description: "MovementActionList.Labels.LastPortDesc"},
  LatestUserLogEntry: {label: 'MovementActionList.Labels.LatestUserLogEntry', description: "MovementActionList.Labels.LatestUserLogEntryDesc"},
  Remarks: {label: 'MovementActionList.Labels.Remarks', description: "MovementActionList.Labels.RemarksDesc"},
  GrossTonnage: { label: 'MovementActionList.Labels.GrossTonnage', fullLabel: 'MovementActionList.Labels.GrossTonnageFull', description: "MovementActionList.Labels.GrossTonnageDesc"},
  Checklist: { label: 'MovementActionList.Labels.Checklist', description: "MovementActionList.Labels.ChecklistDesc"},
}