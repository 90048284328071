import { isBefore } from 'date-fns';
const DateDayMonthYear = 'dd/MM/yyyy';
const DefaultDateFormat = DateDayMonthYear;
const DefaultTimeFormat = 'HH:mm';
const DefaultDateTimeFormatString = `${DefaultDateFormat} ${DefaultTimeFormat}`;

export const DefaultDateTimeFormat = { dateFormat: DefaultDateFormat, timeFormat: DefaultTimeFormat };
export const dateTimeFormatString = format => format ? `${format.dateFormat} ${format.timeFormat}` : DefaultDateTimeFormatString;
export const dateFormatSetting = format => format ? `${format.dateFormat}` : DefaultDateFormat;
export const timeFormatString = format => format ? `${format.timeFormat}` : DefaultTimeFormat;
/**
 * Return invalid date message and data suitable for translation
 * @param {Object} format Current date format 
 * @returns {Array} Array containing translation string and any associated data
 */
export const invalidDateMessage = format => {
  if (!format) return ['Common.Errors.InvalidDateFormat', null];
  return [`Common.Errors.InvalidDateFormatUse`, { format: `${format.dateFormat.toLowerCase()}` }];
};

/**
 * Return invalid date/time message suitable for translation
 * @param {Object} format Current date format 
 * @returns {Array} Array containing translation string and any associated data
 */
export const invalidDateTimeMessage = (format) => {
  if (!format) return ['Common.Errors.InvalidDateFormat', null];
  return [`Common.Errors.InvalidDateFormatUse`, { format: `${format.dateFormat.toLowerCase()} ${format.timeFormat.toLowerCase()}` }];
};
export const isValidDate = date => date instanceof Date && !isNaN(date);

/**
 * Returns a date string for the daye after the input date
 * @param {Date} inputDate 
 * @returns Formated data DD-MM-YYYY
 */
export const dayAfter = (inputDate) => {
  if (!inputDate) return undefined;
  const date = new Date(inputDate);
  const newDate = new Date(date.setDate(date.getDate() + 1));
  return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
};

/**
 * Returns a date string for the day before the input date
 * @param {Date} inputDate 
 * @returns Formated data DD-MM-YYYY
 */
export const dayBefore = (inputDate) => {
  if (!inputDate) return undefined;
  const date = new Date(inputDate);
  const newDate = new Date(date.setDate(date.getDate() - 1));
  return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
};

/**
 * Returns Boolean ,if date is in the past return true, else return false
 * @param {Date} inputDate 
 * @returns {Boolean}
 */
export const isPastDate = (inputDate) => {
  
  if (!inputDate || !(inputDate instanceof Date) || isNaN(inputDate?.getDate())) return false;
  //ignore seconds
  const today = new Date();
  today.setSeconds(0);
  today.setMilliseconds(0);
  inputDate.setSeconds(0);
  inputDate.setMilliseconds(0);

  return isBefore(inputDate, today);
};