import React from 'react';
import {
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Clock from '../components/Clock';
import Tides from '../components/Weather/Tides';
import Weather from '../components/Weather/Weather';
import AccuWeatherAttribution from '../components/Weather/AccuWeatherAttribution';
import { tidesEnabled, weatherEnabled } from '../environment.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  windIcon: {
    fontSize: '3rem'
  },
  divider: {
    width: '1px',
    backgroundColor: 'rgba(255,255,255,0.2)',
    height: '3rem',
    margin: '0rem 1rem'
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
    paddingRight: '0.25rem'
  }
}));

const CurrentConditions = ({ showClock }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>

      {showClock &&
        <Grid item>
          <Tooltip title={t('AppHeader.Labels.CurrentDateAndTime')}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Clock />
              { weatherEnabled ? <AccuWeatherAttribution /> : <></> }
            </Box>
          </Tooltip>
        </Grid>
      }
      {weatherEnabled ? <div className={classes.divider} /> : null}
      {weatherEnabled ?
        <Weather
          basePollInterval={300000}
        />
        : null}
      { tidesEnabled ? <div className={classes.divider} /> : null }
      { tidesEnabled ?
        <Grid item>
          <Box display="flex" flex="1">
            <Tides duration={2} pollInterval={3600000} />
          </Box>
        </Grid>
        : null}
    </Grid>
  );
};

CurrentConditions.defaultProps = {
  loading: false
};

export default CurrentConditions;
