import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogLoadingProgress from './DialogLoadingProgress';
import { Button, DialogActions, DialogContentText, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '600px',
      minHeight: '150px'
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
      paddingTop: '1rem'
    }
  },
  message:{
    paddingBottom:'0.75rem'
  }
});

/**
 * Simple Loading dialog
 */
const LoadingDialog = ({ open, title, onClose, modal, loading, error, message = "" }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (modal && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} id='LoadingDialog' className={classes.container}>
      <DialogContent /* className={classes.dialogContent} */>
        <DialogContentText>{title}</DialogContentText>
        <DialogContentText className={classes.message}>{message ?? ""}</DialogContentText>
        <DialogLoadingProgress
          loading={loading}
          errorMessage={error?.errorMessage}
          errorCode={error?.errorCode}
        />
      </DialogContent>
      {error?.errorMessage && <DialogActions>
        <div id={'LoadingDialogCloseButton'}>
          <Button
            onClick={onClose}
            color="default"
          >
            {t('Common.Buttons.Close')}
          </Button>
        </div>
      </DialogActions>}
    </Dialog>
  );
}

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object
};



export default LoadingDialog;