import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, IconButton, debounce } from "@material-ui/core";
import { Close } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/styles';

const ListEditorLabels = {
  ADD: "Add"
};

const useStyles = makeStyles(theme => ({
  row: {
    '& > :not(:first-child)': {
      marginLeft: ".5rem"
    }
  },
  rowDelete: {
    alignSelf: "start",
    marginTop: ".6rem",
  }
}));

// Rows keep internal state and call outer setter via debounce 
const LISTEDITOR_INTERNAL_DEBOUNCE = 150;

/**
 * Generic editable list component with internal per-item states to improve performance
 * @prop {Array} list data
 * @prop {(value) => null} setListItem data setter NOTE: must use the (prev => [...prev, value])) form or expect undefined behaviur
 * @prop {String} createLabel label of add entry button
 * @prop {() => null} onCreate data setter that adds a new item
 * @prop {(item) => null} onDelete data setter that removes a given item
 * @prop {(item, index) => Component} renderItem render row for a given array item
 * @prop {Boolean} allowEmptyValue automatically adds (and forces on delete) one row when empty if false (default)
 * NOTE: Currently this component may not update row values unless they get re-mounted
 */
const ListEditor = ({ id, list, setList, setListItem, createLabel, onCreate, onDelete, renderItem, allowEmptyValue = false, disabled }) => {
  const classes = useStyles();
  // allowEmptyValue
  useEffect(() => {
    if ((!list || !list.length) && !allowEmptyValue) onCreate();
  }, [list, allowEmptyValue, onCreate]);
  return (
    <>
      {list?.map((i, index) =>
        <Box id={id} display="flex" className={classes.row} key={i.id || index}>
          <ListEditorItem item={i} setItem={newItem => setListItem && setListItem(newItem, index)} renderItem={renderItem} />
          {!disabled && <IconButton id={`${id}-RemoveButton`} onClick={() => onDelete && onDelete(index)} className={classes.rowDelete}>
            <Close fontSize="small" />
          </IconButton>}
        </Box>
      )}
      <Box mt="0.5rem">
        {!disabled && <Button id={`${id}-AddButton`} variant="outlined" color="primary" onClick={() => onCreate ? onCreate() : setList([...list, {}])}>
          {createLabel || ListEditorLabels.ADD}
        </Button>}
      </Box>
    </>
  )
}

/**
 * Keep internally per-row state to boost visual performance
 */
const ListEditorItem = ({ item, setItem, renderItem }) => {
  const [_item, _setItem] = useState(item);
  const setItemDebounce = useCallback(debounce((newItem) => setItem(newItem), LISTEDITOR_INTERNAL_DEBOUNCE), []);
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = value === '' ? null : (type === 'number') ? parseFloat(value) : value;
    // skip same value
    if (_item[name] === newValue || (!_item[name] && !newValue)) return;
    const newItem = { ..._item, [name]: newValue };
    _setItem(newItem);
    setItemDebounce(newItem);
  };
  return renderItem(_item, handleChange);
};

export default ListEditor;