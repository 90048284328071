import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


/**
 * 
 *  EditorField
 *  Acts as a proxy for the MUI TextField
 *  component and provides stylistic defaults
 *  and renders non-interactive elements 
 *  if the field is disabled.
 *  Props are mostlyspecific to the TextField
 *  component apart from fieldSuffix.
 *  
 */

const useStyles = makeStyles(theme => ({
  editorFieldLabel: {
    paddingRight: '2rem',
    textAlign: 'right',
    fontWeight: '300',
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  }
}));

export const EditorField = ({
  disabled,
  label,
  name,
  fieldSuffix,
  value,
  InputProps,
  inputProps,
  onChange,
  required,
  type,
}) => {
  const classes = useStyles();

  return (
    <>
      {!disabled &&
        <TextField
          autoComplete="off"
          variant="outlined"
          fullWidth
          label={label}
          margin="normal"
          name={name}
          onChange={onChange}
          value={value}
          InputProps={InputProps}
          inputProps={inputProps}
          type={type}
          required={required}
        />
      }
      {disabled &&
        <Box mb="1rem">
          <Grid container>
            <Grid
              item
              xs={6}
            >
              <Typography
                className={classes.editorFieldLabel}
                variant="body1"
              >
                {label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                variant="body1"
              >
                {value ? value : '-'}{value && fieldSuffix ? <span className={classes.editorFieldUnits}>{fieldSuffix}</span> : null}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}

EditorField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  // Used as a unit label in the same way 
  // as we use the end adornment for
  // TextFields
  fieldSuffix: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default EditorField;