import { createStyles, emphasize } from '@material-ui/core/styles';

// Generate a style for the snackbar based on the theme.
// This should result in a dark background with contrasting text.
// The method was taken from the notistck source.
export const styles = (theme) => {
  const mode = theme.palette.mode || theme.palette.type;
  const backgroundColor = emphasize(theme.palette.background.default, mode === 'light' ? 0.8 : 0.98);
  return createStyles({
    root: {
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
    }
  })
};