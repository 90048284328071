import VectorSource from "ol/source/Vector";
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Polygon from "ol/geom/Polygon";
import Circle from 'ol/geom/Circle';
import GeometryCollection from "ol/geom/GeometryCollection";
import { getCenter } from "ol/extent";
import { profileFromShipType } from '../utils/AisTypes';
import { Attrs, FeatureType } from '../constants';

const SPEED_THRESHOLD = 0.01; // as per Marlin VesselStyleDetailsProvider

class VesselSource extends VectorSource {
  constructor(opt_options) {
    super({
      ...opt_options,
      projection: 'EPSG:4326'
    });

    this.features = new Set();
  }

  spotlightFeatureName = (vessel) => {
    return `${vessel.id}-spotlight`;
  };

  updateVessels = (vessels) => {
    for(let v of vessels) {
      this.updateVessel(v);
    }
  };

  updateVessel = (vessel) => {
    //console.log(JSON.stringify(vessel));

    // {
    //   // DEBUG
    //   const tmp = dayjs.duration(dayjs().diff(dayjs(vessel.positionTimestamp))).as('minutes');
    //   console.log(vessel.name, vessel.positionTimestamp, tmp, vessel.active && tmp>30 ? 'CHECK' : null);
    // }

    if(!vessel) return;
    let feature = this.getFeatureById(vessel.id); 

    // Ensure no older versions are inserted
    if(feature && (feature.version > vessel._version)) return;

    if(feature && !vessel.active) {
      //console.log('Removing feature:', vessel.name);
      this.removeFeature(feature);
      return;
    }
    const gpsRefPos = [
      vessel.dimForward,
      vessel.dimAft,
      vessel.dimPort,
      vessel.dimStbd
    ];
    //diagonal of ractangle =d=√(l² + w²) , diagonal of circle = d=2*r
    const size = this.getSizeFromGPSRefPos(gpsRefPos);
    const outRadius =  2 * (Math.sqrt(Math.pow(size[0],2)+Math.pow(size[1],2)));
    const geom = new Point(fromLonLat([ vessel.lon, vessel.lat ]));
    const vesselRefPoint = new Circle(fromLonLat([ vessel.lon, vessel.lat ]), 
      Math.min(((vessel.dimPort + vessel.dimStbd) / 4), 4));

    // If no heading fall back to cmg
    if(!vessel.heading) {
      vessel.renderRotation = vessel.cmg;
    } else {
      vessel.renderRotation = vessel.heading;
    }

    const vesselPolygon = new Polygon([this.buildVesselSilouette(vessel)]);
    const outline = new GeometryCollection([ vesselPolygon, vesselRefPoint ]);

    if(!feature) {
      feature = new Feature({
        id: vessel.id,
        name: vessel.name,
        outline: outline
      });
      feature.setId(vessel.id);
      feature.set(Attrs.TYPE, FeatureType.Vessel);

      this.addFeature(feature);
      //  console.log(`[${this.getFeatures().length}] adding ${feature.getId()}`);
    }

    const meta = {
      ...vessel,
      type5: vessel.dimAft && vessel.dimForward,
      stationary: parseFloat(vessel.speed) < SPEED_THRESHOLD
    };

    //set feature for vesselHighligth to true if id match
    feature.setGeometry(geom);
    feature.set(Attrs.OUTLINE, outline);        // vessel shape geometry
    feature.set(Attrs.OUTER_RADIUS, outRadius); // radius of vessel shape
    feature.set(Attrs.NAME, vessel.name);
    feature.set(Attrs.HEADING, vessel.renderRotation - 90 * Math.PI / 180.0);
    feature.set(Attrs.META, meta);
    feature.set(Attrs.VERSION, vessel._version);
    feature.set(Attrs.POPUP, true);
    const profile = profileFromShipType(vessel.shipType);
    feature.set(Attrs.COLOR, profile.color);
   
    if(vessel.spotlight) {
      const spotlightId = this.spotlightFeatureName(vessel);
      let spotlight = this.getFeatureById(spotlightId); 

      if(!spotlight) {
        spotlight = new Feature({
          id: spotlightId,
          name: vessel.name,
        });
        spotlight.setId(spotlightId);
        spotlight.set(Attrs.TYPE, FeatureType.Spotlight);
        this.addFeature(spotlight);
      }
      const vesselCenter = getCenter(vesselPolygon.getExtent());
      spotlight.setGeometry(geom);
      spotlight.set(Attrs.SPOTLIGHT, new Circle(vesselCenter, outRadius) );
    }
  };

  removeVessel = (vessel) => {
    if(!vessel) return;
    const feature = this.getFeatureById(vessel.id); 
    feature && this.removeFeature(feature);

    const spotlight = this.getFeatureById(this.spotlightFeatureName(vessel))
    spotlight && this.removeFeature(spotlight);
    console.log('SPOT Removing vessel and spotlight');
  };

  getSizeFromGPSRefPos = (gpsRefPos) => {
    return [
      gpsRefPos[0] + gpsRefPos[1],
      gpsRefPos[2] + gpsRefPos[3]
    ];
  }
  
  getOffsetFromGPSRefPos = (gpsRefPos) => {
    return [
      -gpsRefPos[1], 
      -gpsRefPos[3]
    ];
  }
  
  resizeAndMovePoint = (point, size, offset) => {
    return [
      point[0] * size[0] + offset[0], 
      point[1] * size[1] + offset[1]
    ];
  };
  
  rotate = (point, angle) => {
    var x = point[0];
    var y = point[1];
    var si_z = Math.sin(angle);
    var co_z = Math.cos(angle);
    var newX = x * co_z - y * si_z;
    var newY = x * si_z + y * co_z;
    return [newX, newY];
  };
  
  rotateAllPoints = (points, angle, size=24) => {
    let result = [];
    for(let i=0;i<points.length;i+=2) {
      const x = points[i + 0] * size;
      const y = points[i + 1] * size;
      const pt = this.rotate([x, y], angle);
      result.push(pt[0]);
      result.push(pt[1]);
    }
    console.log('rotateAllPoints', result);
    return result;
  };
  
  getLatSizeOf = (value) => {
    return (value / 40075017) * 360;
  };
  
  getLngSizeOf = (value, lat) => {
    return ((value / 40075017) * 360) / Math.cos((Math.PI/180) * lat);
  };
  
  getViewAngleFromModel = (modelAngle) => {
    return Math.PI/2.0 - modelAngle;
  };
  
  transformAllPointsToView = (points, lnglat) => {
    let result = [];
    console.log('_transformAllPointsToView latlng', lnglat);    
    const symbolViewCenter = fromLonLat([lnglat[1], lnglat[0]]);
    console.log('_transformAllPointsToView symbolViewCenter', symbolViewCenter);    
  
    for(let i=0;i<points.length;i+=2) {
      var x = symbolViewCenter[0] + points[i+0];
      var y = symbolViewCenter[1] - points[i+1];
      result.push(x);
      result.push(y);
    }
    return result;
  };
  
  buildVesselSilouette = (vessel) => {
    const headingAngle = this.getViewAngleFromModel(vessel.renderRotation * Math.PI / 180.0);
    const gpsRefPos = [
      vessel.dimForward,
      vessel.dimAft,
      vessel.dimPort,
      vessel.dimStbd
    ];
    const size = this.getSizeFromGPSRefPos(gpsRefPos);
    const offset = this.getOffsetFromGPSRefPos(gpsRefPos);
    const silSymbol = [1,0.5, 0.75,1, 0,1, 0,0, 0.75,0];
    let result = [];
    for(let i=0;i<silSymbol.length;i+=2) {
      let pt = [
        silSymbol[i+0], 
        silSymbol[i+1]
      ];
  
      pt = this.resizeAndMovePoint(pt, size, offset);
      pt = this.rotate(pt, headingAngle);
      const pointLng = vessel.lon + this.getLngSizeOf(pt[0], vessel.lat);
      const pointLat = vessel.lat + this.getLatSizeOf(pt[1]);
      const viewPoint = fromLonLat([pointLng, pointLat]);
      result.push(viewPoint);
    }
    result.push(result[0]);
  
    return result;
  };

};

export default VesselSource;