import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, makeStyles, Typography } from '@material-ui/core';
import {
  useLocation,
} from 'react-router-dom';
import { Check } from 'mdi-material-ui';
import DataTable from './DataTable';
import PortCallRemarks from '../components/PortCall/PortCallRemarks';
import PortCallLatestLogbookEntry from '../components/PortCall/PortCallLatestLogbookEntry';
import BerthListItem from '../components/Berth/BerthListItem';
import {
  portName,
  portNameTrimmed,
  formatDimension
} from '../utils/utils';
import { MovementActionType } from '../constants/MovementActionType';
import {
  MovementActionDisplayType as movementDisplay,
  MovementActionTableHeaders as math,
} from '../constants/MovementActionDisplayType';
import VesselListItem from '../components/Vessel/VesselListItem';
import VesselDimensionsListItem from '../components/Vessel/VesselDimensionsListItem';
import PopoverTooltip from '../components/Tooltip/PopoverTooltip';
import VesselTooltip from '../components/Tooltip/VesselTooltip';
import AgentListItem from '../components/Agent/AgentListItem';
import ContactTooltip from '../components/Tooltip/ContactTooltip';
import ActionStateAndTimeListItem from '../components/Action/ActionStateAndTimeListItem';
import CargoListItem from '../components/Cargos/CargoListItem';
import CargoTooltip from '../components/Tooltip/CargoTooltip';
import PortCallTimelinePortCallMenu from '../components/PortCall/PortCallTimelinePortCallMenu';
import { UIContext } from '../contexts/ui';
import { NavigationContext, navigationActionConstants } from '../contexts/navigation';
import ListHeader from '../components/ListHeader/ListHeader';
import VesselDraughtListItem from '../components/Vessel/VesselDraughtListItem';
import { Action, ActionState, PortCallStatus, RequestState, RequestType } from '../models';
import Loading from '../components/Loading';
import { differenceInMilliseconds, startOfDay } from 'date-fns';
import { getActionActiveAgent } from '../utils/getters';
import ChecklistListItem from '../components/Todo/ChecklistListItem';
import { DataStore } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import useSyncFilter from '../hooks/useSyncFilter';
import useFilterSettings from "../hooks/useFilterSettings.js";
import useUserSettings from '../hooks/useUserSettings';
import { useHistory } from 'react-router-dom';
import useVoyages from '../hooks/useVoyages';
import SpotlightMapDialog from '../components/Spotlight/SpotlightMapDialog';
import { InactivePortCallStatus } from '../constants/StateCategories';

/**
 * 
 *  MovementActionList
 * 
 *  The movement action list component displays a list
 *  of movement actions in a DataTable component.
 *  It takes only one prop, an array of movement actions
 *  and the default is an empty array.
 * 
 *  The movement action list is also exported as AllActionWithData
 *  which is conencted to the listActions query to automatically
 *  supply the actions from the GQL endpoint.
 *  
 */

// TODO: this table will need an increment of version, when change is made to table column
const tableVersion = 0.86;

const useStyles = makeStyles(() => ({
  scrollSnap: {
    borderLeft: '0.0625rem solid #e0e0e0',
    scrollSnapType: 'y proximity',
    scrollPadding: '2.5rem 0 0 0',
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  },
  clampedTypography: {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3
  }
}));

const matchFilter = (item, filters) => {
  switch (filters) {
    case movementDisplay.ALL:
      return true;
    case movementDisplay[MovementActionType.ARRIVAL]:
      return item.movementType === MovementActionType.ARRIVAL
        || item.movementType === MovementActionType.SHIFT_ARRIVAL;
    case movementDisplay[MovementActionType.DEPARTURE]:
      return item.movementType === MovementActionType.DEPARTURE
        || item.movementType === MovementActionType.SHIFT_DEPARTURE;
    default: return false;
  }
};

const voyageNameFromId = (voyages, voyageId) => {
  const v = voyages.find(el => el.id === voyageId);
  if(v) return v.name;
  return '';
};

const MovementActionList = ({ activeActions: items }) => {
  const location = useLocation();
  const [navigationContext, dispatchNavigationContext] = useContext(NavigationContext);
  const queryParams = new URLSearchParams(location.search);
  const [uiContext,] = useContext(UIContext);
  const { t } = useTranslation();
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [vesselMapData, setVesselMapData] = useState(null);

  const getRequests = (portCall, actionId) => {
    return portCall.requests && portCall.requests.find(r => (
      (!agentPortal || (agentPortal && r.agent && userContactId === r.agent.id)) &&
      ((r.state === RequestState.REQUEST_STATE_PENDING &&
        r.actionData &&
        r.actionData.some(ad => ad.actionId === actionId)) || 
      (r.state === RequestState.REQUEST_STATE_PENDING &&
        r.type === RequestType.REQUEST_TYPE_CANCEL_PORTCALL)) &&
        !InactivePortCallStatus.includes(portCall.status))
    );
  };

  // updating logic moved out of
  const handleUpdateTodos = async (actionId, todos) => {
    const action = (await DataStore.query(Action, c => c.id("eq", actionId))).pop();
    await DataStore.save(Action.copyOf(action, updated => {
      updated.todos = todos;
    }));
  };

  const enabledFilters = useMemo(() => [{
    value: movementDisplay.ALL,
    label: t('Common.Labels.All')
  }, {
    value: movementDisplay[MovementActionType.ARRIVAL],
    label: t('ActionMovementType.Labels.Arrival')
   }, {
    value: movementDisplay[MovementActionType.DEPARTURE],
    label: t('ActionMovementType.Labels.Departure')
   }], [t]);

  const userSettingFilterIdField = "movementsFilterId";
 
  //When you add something to columns - change the tableVersion so that the changes are registered
  //Also add the corresponding data to return value of the last map function of transformedItems
  //Finally add the column title to MovementActionDisplayType file
  //math = MovementActionTableHeaders
  const defaultColumns = [
    {
      id: 'state',
      label: math.Schedule,
      format: val => <ActionStateAndTimeListItem id='schedule' action={val} request={getRequests(val.portCall, val.id)} delay={800} />,
      visible: true,
      width: 11
    },
    {
      id: 'portCall',
      label: math.PortCallID,
      format: (val) => (
        <Typography id='portCallID' noWrap>{val.referenceId}</Typography>
      ),
      visible: false,
      width: 6.75
    },
    {
      id: 'vessel',
      label: math.Vessel,
      format: val => {
        return (
          <PopoverTooltip tooltip={<VesselTooltip vesselData={val.vesselData} setMapDialogOpen={setMapDialogOpen} setVesselMapData={setVesselMapData}/>} delay={800}>
            <VesselListItem
              id='vessel'
              disableGutters={true}
              vesselData={val.vesselData}
              disabled={!val.portCallVesselId}
            />
          </PopoverTooltip>
        )
      },
      visible: true,
      width: 14
    },
    {
      id: 'voyage',
      label: math.VoyageID,
      format: (val) => {
        return (<Typography id='voyageID' variant="body1" align='justify' noWrap>{val}</Typography>)
      },
      visible: false,
      width: 10
    },
    {
      id: 'portOfRegistry',
      label: math.PortOfRegistry,
      format: val => {
        return (
          <PopoverTooltip tooltip={<ListItem><Typography>{portName(val)}</Typography></ListItem>} delay={800}><Typography id='portOfRegistry' variant={portNameTrimmed(val).length > 25 ? "caption" : "body1"} className={classes.clampedTypography}>{portNameTrimmed(val)}</Typography></PopoverTooltip>
        )
      },
      width: 9
    },
    {
      id: 'dimensions',
      label: math.Dimensions,
      format: val => <VesselDimensionsListItem
        id='dimensions'
        lengthOverall={val.lengthOverall}
        beam={val.beam}
        draught={val.draught}
      />,
      visible: true,
      width: 9
    },
    {
      id: 'grossTonnage',
      label: math.GrossTonnage,
      format: val => val > 0 && <Typography id='grossTonnage' variant="body1" noWrap>{val}</Typography>,
      visible: true,
      width: 4.25
    },
    {
      id: 'length',
      label: math.Length,
      format: val => val > 0 && <Typography id='length' variant="body1">{formatDimension(val)}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></Typography>,
      visible: false,
      width: 4.25
    },
    {
      id: 'beam',
      label: math.Beam,
      format: val => val > 0 && <Typography id='beam' variant="body1">{formatDimension(val)}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></Typography>,
      visible: false,
      width: 4.25
    },
    {
      id: 'draught',
      label: math.Draught,
      format: val => <VesselDraughtListItem id='draught' vesselDraught={val} disableGutters />,
      visible: false,
      width: 5.5
    },
    {
      id: 'airDraught',
      label: math.AirDraught,
      format: val => val > 0 && <Typography id='airDraught' variant="body1">{formatDimension(val)}<span className={classes.editorFieldUnits}>{t('Common.Labels.M')}</span></Typography>,
      visible: false,
      width: 6.5
    },
    {
      id: 'bt',
      label: math.BowThrusters,
      format: val => val && <Check id='bt'/>,
      visible: true,
      width: 1.5
    },
    {
      id: 'berth',
      label: math.Location,
      format: (val) => (
        <PopoverTooltip tooltip={<ListItem><BerthListItem item={val.location} disableGutters lastZone={val.lastZone} /></ListItem>} delay={800}><BerthListItem id='location' item={val.location} disableGutters lastZone={val.lastZone} /></PopoverTooltip>
      ),
      visible: true,
      width: 11
    },
    {
      id: 'agent',
      label: math.CharterAgent,
      format: val => val && <PopoverTooltip tooltip={<ContactTooltip contact={val} />} delay={800}><AgentListItem id='charterAgent' agent={val} display={true} /></PopoverTooltip>,
      visible: true,
      width: 10
    },
    {
      id: 'cargo',
      label: math.Cargo,
      format: val => <PopoverTooltip tooltip={<CargoTooltip cargo={val} />} delay={800}><CargoListItem id='cargo' cargo={val} /></PopoverTooltip>,
      visible: true,
      width: 10.5
    },
    {
      id: 'nextPort',
      label: math.NextPort,
      format: val => val && <PopoverTooltip tooltip={<ListItem><Typography>{portName(val)}</Typography></ListItem>} delay={800}><Typography id='nextPort' variant={portNameTrimmed(val).length > 25 ? "caption" : "body1"} className={classes.clampedTypography}>{portNameTrimmed(val)}</Typography></PopoverTooltip>,
      visible: false,
      width: 7
    },
    {
      id: 'lastPort',
      label: math.LastPort,
      format: val => val && <PopoverTooltip tooltip={<ListItem><Typography>{portName(val)}</Typography></ListItem>} delay={800}><Typography id='lastPort' variant={portNameTrimmed(val).length > 25 ? "caption" : "body1"} className={classes.clampedTypography}>{portNameTrimmed(val)}</Typography></PopoverTooltip>,
      visible: true,
      width: 7
    },
    {
      id: 'latestUserLogEntry',
      label: math.LatestUserLogEntry,
      format: val => <PortCallLatestLogbookEntry id='latestUserLogEntry' logbookEntry={val} delay={800}/>,
      visible: true,
      width: 9
    },
    {
      id: 'remarks',
      label: math.Remarks,
      format: val => <PortCallRemarks id='remarks' remarks={val} delay={800}/>,
      visible: true,
      width: 9
    },
    {
      id: 'checklist',
      label: math.Checklist,
      format: val => <ChecklistListItem {...val} disabled={readOnly} onChange={(value) => handleUpdateTodos(val.actionId, value)} />,
      visible: true,
      width: 7.5
    },
    {
      id: 'menu',
      label: '',
      format: val =>
        <PortCallTimelinePortCallMenu
          disabled={readOnly}
          relevantAction={val.action}
          portCall={val.portCall}
          contexts={['portCall', 'action', 'details']}
          skipCheck
        />,
      visible: true,
      width: 2.625
    }
  ];
  const readOnly = uiContext.readOnly;
  const agentPortal = uiContext.agentPortal;
  const userContactId = uiContext.userContactId;
  const { filterSetting } = useFilterSettings();
  const { userSetting } = useUserSettings();
  const { voyages } = useVoyages();

  const disableFilterEdits = useMemo(() => uiContext.isAdmin ? false : filterSetting.adminEditOnly, [filterSetting, uiContext.isAdmin]); 

  const agentPortalDenyList = new Set(['latestUserLogEntry', 'remarks', 'checklist']);

  const columns = defaultColumns.filter(el => {
    if(agentPortal) {
      return !agentPortalDenyList.has(el.id);
    }
    // Add other filter conditions here.
    return true;
  });

  const initialVisible = columns.reduce((accumulator, currentItem) => {
    accumulator[currentItem.id] = currentItem.visible;
    return accumulator;
  }, {});
  const initialOrder = columns.map(item => item.id);

  const classes = useStyles();

  const defaultFilters = movementDisplay.ALL;
  const TYPE = useMemo(() => ({
    full: "movements",
    type: "MOVEMENT",
    prefix: "MOVEMENT_",
    id: "AllMovements",
    name: t("MovementActionList.Labels.FilterType"),
    defaultFilters,
    extraNodes: [{
      id: "not_allocated",
      name: "Not Allocated",
      value: "not_allocated",
      label: t("MovementActionList.Labels.NotAllocated"),
    }],
    get AllFilter() {
      return {
        id: this.id,
        name: t("MovementActionList.Labels.AllType", {type: this.name}),
        status: this.defaultFilters,
        location: [movementDisplay.ALL],
      };
    },
  }), [t]);

  const [filters, setFilter] = useState();
  useSyncFilter(filters, setFilter, TYPE, userSettingFilterIdField);

  // forces filter update for default entry on language change
  useEffect(() => {
    setFilter(prev => prev && prev.id === TYPE.id ? TYPE.AllFilter : prev);
  }, [TYPE]);
  
  //update last view on initial render
  useEffect(() => {
    if (navigationContext.defaultView !== navigationContext.lastView){
      dispatchNavigationContext({ type: navigationActionConstants.SET_DEFAULT_VIEW, payload: navigationContext.defaultView });
    }
  }, [dispatchNavigationContext, navigationContext]);
  const history = useHistory();

  // updates route on log-in to match userSetting
  useEffect(() => {
    if (userSetting.fullScreen === '/lists/movements') {
      history.replace({pathname: "/lists/movements", search: location.search})
    } else if (userSetting.fullScreen === '/'){
      history.replace({pathname:"/", search: location.search})
    } else return
  }, [userSetting?.id])

  
  let rowClass = 'tableOdd';
  const transformedItems = useMemo(() => items && filters && items
    .filter(item => {
      if (!filters) return;
      if(!agentPortal) return true;
      if(item?.portCall?.agents?.find(agent => agent.contactId === userContactId)) return true
      return false;
    })
    .filter(item => matchFilter(item, filters.status) && item.movementType && (item.state === ActionState.PLANNED || item.state === ActionState.IN_PROGRESS || item.state === ActionState.APPROVAL_PENDING || item.state === ActionState.APPROVAL_DECLINED))
    .filter((item) => {
      if (filters.location.length === 0) return true;
      if (filters.location[0] === movementDisplay.ALL) return true;
      if (!item.movementLocation) {
        if (filters.location.includes("not_allocated")) return true;
      } else {
        if(filters.location.includes(item.movementLocation?.id) || 
          filters.location.includes(item.movementLocation?.parent?.id)) {
          return true;
        }
      }
    })
    .map((item, index, array) => {
      if (index > 0 && differenceInMilliseconds(startOfDay(new Date(item.timePlanned)), startOfDay(new Date(array[index - 1].timePlanned))) !== 0) {
        rowClass === 'tableOdd' ? rowClass = 'tableEven' : rowClass = 'tableOdd';
      }
      return {
        id: item.id,
        state: item,
        voyage: voyageNameFromId(voyages, item.actionVoyageId),
        pl:item,
        movementType: item.movementType,
        time: {
          planned: item.timePlanned,
          requested: item.timeRequested,
          actual: item.timeActual,
          estimated: item.timeEstimated,
          estimatedUpdatedAt: item.timeEstimatedUpdatedAt,
        },
        vessel: item.portCall,
        portOfRegistry: item.portCall.vesselData.portOfRegistry,
        berth: { lastZone: item.portCall.lastZone, location: item.movementLocation },
        agent: getActionActiveAgent(item.portCall.actions, item),
        checklist: item.todos ? {
          items: item.todos,
          paperClassName: rowClass,
          actionId: item.id,
        } : null,
        latestUserLogEntry: item?.latestUserLogEntry ?? item?.portCall?.latestUserLogEntry,
        remarks: item.portCall.remarks || '',
        action: item,
        // reference for PortCallMenu
        portCall: item.portCall,
        cargo: {
          manifest: item.portCall.cargos,
          movementType: [item.movementType],
        },
        dimensions: {
          lengthOverall: item.portCall.vesselData.lengthOverall,
          beam: item.portCall.vesselData.beam,
          draught: item.vesselSailingDraught,
        },
        grossTonnage: item.portCall.vesselData.grossTonnage,
        length: item.portCall.vesselData.lengthOverall,
        beam: item.portCall.vesselData.beam,
        draught: {
          sailing: item.vesselSailingDraught,
          nominal: item.portCall.vesselData.draught,
        },
        airDraught: item.vesselAirDraught,
        bt: Boolean(item.portCall.vesselData.fwdTunnelThrusterCount || item.portCall.vesselData.fwdAzimuthCount),
        lastPort: item.portCall.lastPort,
        nextPort: item.portCall.nextPort,
        meta: {
          className: rowClass
        },
        menu: {
          action: item,
          portCall: item.portCall
        },
        hasRequests: getRequests(item.portCall, item.id),
        hasApprovalDeclined: item && (item.state === ActionState.APPROVAL_DECLINED),
        hasApprovalPending: item && (item.state === ActionState.APPROVAL_PENDING)
      }
    }) || [],
    [items, filters, voyages]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="2"
      id="NavMovementActionList"
    >
      <ListHeader
        enabledFilters={enabledFilters}
        filters={filters}
        setFilter={setFilter}
        TYPE={TYPE}
        filterSettings={{
          hideEditControls: false,
          disableEdit: agentPortal || disableFilterEdits
        }}
        queryParams={queryParams}
      />
      <Box
        height="100%"
        className={classes.scrollSnap}
        overflow="hidden"
      >
        {transformedItems ?
          <DataTable
            id={'portCalls'}
            showHeader={true}
            columns={columns}
            initialOrder={initialOrder}
            initialVisible={initialVisible}
            rows={transformedItems}
            autoSavePreferences={true}
            tableVersion={tableVersion}
          />
          : <Loading />
        }
      </Box>
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={vesselMapData}
        /> 
      }
    </Box>
  )
};

MovementActionList.defaultProps = {
  activeActions: [],
}

MovementActionList.propTypes = {
  activeActions: PropTypes.array,
}

export default MovementActionList;