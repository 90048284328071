export const REQUESTED = 'REQUESTED'
export const PREARRIVAL = 'PREARRIVAL'
export const ARRIVAL = 'ARRIVAL'
export const IN_PORT = 'IN_PORT'
export const DEPARTURE = 'DEPARTURE'
export const CANCELLED = 'CANCELLED'
export const DELETED = 'DELETED'
export const CLOSED = 'CLOSED'

export const PortCallStatusLabelsKeys = {
  [REQUESTED]: 'PortCallStatus.Labels.Requested',
  [PREARRIVAL]: 'PortCallStatus.Labels.Prearrival',
  [ARRIVAL]: 'PortCallStatus.Labels.Arrival',
  [IN_PORT]: 'PortCallStatus.Labels.InPort',
  [DEPARTURE]: 'PortCallStatus.Labels.Departure',
  [CANCELLED]: 'PortCallStatus.Labels.Cancelled',
  [DELETED]: 'PortCallStatus.Labels.Deleted',
  [CLOSED]: 'PortCallStatus.Labels.Closed',
  default: 'PortCallStatus.Labels.Undefined'
};