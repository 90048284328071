import React, { useContext, useMemo } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { ListItem, ListItemText, TextField } from '@material-ui/core';
import { actionTypeFilterOptions } from '../../../utils/sorters';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import { ActionTypeIds } from '../../../environment';
import { ActionMovementType } from '../../../models';
import { sortActionTypes } from '../../../utils/sorters';


const ActionTypeWithMovementAutocomplete = ({ value, onChange, startAdornment, label, required, helperText, excludeOption, expandMovement, ...other }) => {
  const { t } = useTranslation();
  const { actionTypes } = useContext(DataStoreContext);
  const getActionTypeName = (at) => {
    if (at.id === ActionTypeIds.MOVEMENT) return t('ActionType.Labels.Movement');
    if (at.id === ActionTypeIds.EVENT) return t('ActionType.Labels.Event');
    return at.name;
  }

  const movementActionType = useMemo(() => actionTypes.find(at => at.id === ActionTypeIds.MOVEMENT), [actionTypes]);
  const movementSubType = useMemo(() => movementActionType && {
    [ActionMovementType.ARRIVAL]: { actionTypeId: movementActionType.id, movementType: ActionMovementType.ARRIVAL, name: t('AdminActionType.Labels.MovementArrival') },
    [ActionMovementType.DEPARTURE]: { actionTypeId: movementActionType.id, movementType: ActionMovementType.DEPARTURE, name: t('AdminActionType.Labels.MovementDeparture') },
    [ActionMovementType.SHIFT_ARRIVAL]: { actionTypeId: movementActionType.id, movementType: ActionMovementType.SHIFT_ARRIVAL, name: t('AdminActionType.Labels.MovementShiftArrival') },
    [ActionMovementType.SHIFT_DEPARTURE]: { actionTypeId: movementActionType.id, movementType: ActionMovementType.SHIFT_DEPARTURE, name: t('AdminActionType.Labels.MovementShiftDeparture') },
  }, [movementActionType, t]);
  // build options from all action types and movement types
  const options = useMemo(() => movementSubType ? [
    ...actionTypes
      .filter(at => !at.deleted)
      .filter(at => at.id !== ActionTypeIds.EVENT)
      .filter(at => at.id !== ActionTypeIds.MOVEMENT)
      .map(at => { return { actionTypeId: at.id, name: at.name, movementType: null } }),
    ...Object.keys(movementSubType).map(key => movementSubType[key])
  ]
  .sort(sortActionTypes)
  // remove any options passed in from final list
  .filter(at => excludeOption ? !excludeOption(at) : true) : [],
  [ actionTypes, movementSubType, excludeOption ]);

  return (
    <Autocomplete
      {...other}
      // debug={true}
      autoHighlight
      options={options}
      filterOptions={actionTypeFilterOptions}
      getOptionLabel={(item) => (item && getActionTypeName(item))}
      getOptionSelected={(option, value) => (option.actionTypeId === value.actionTypeId) && (option.movementType === value.movementType)}
      value={value}
      onChange={(e, value) => onChange && onChange(value)}
      renderOption={item => (
        <ListItem
          dense
          component="div"
          className='ActionTypeWithMovementAutoCompleteItem'
          data-id={`${item.actionTypeId}${item.movementType ? '_' + item.movementType : ''}`}
        >
          <ListItemText primary={getActionTypeName(item)}/>
        </ListItem>
      )}
      renderInput={props => (
        <TextField
          {...props}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          helperText={helperText}
        />
      )}
    />
  );
};

export default ActionTypeWithMovementAutocomplete;