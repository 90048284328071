
import React, { useContext, useEffect, useState } from 'react'
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { Setting } from '../models';
import SettingConstants from '../constants/SettingConstants';

/**
 * A hook that handle setting flag to enabled/disable external notifications
 * @return {Object} external notification enabled flag with a helper function to update app external notification setting
 */
const useExternalNotificationSettings = () => {
  const { settings } = useContext(DataStoreContext);

  const [externalNotificationSetting, setNotificationSetting] = useState();
  const [appExternalNotificationEnabled, setExternalNotificationEnabled] = useState(false);

  useEffect(() => {
    const setting = settings?.find(s => s.name === SettingConstants.APP_EXTERNAL_NOTIFICATIONS);
    setNotificationSetting(setting)
    const settingValue = setting ? JSON.parse(setting.value) : null;
    setExternalNotificationEnabled(settingValue?.enabled ?? false);
  }, [settings])

  const updateExternalNotificationSetting = React.useCallback((enabled) => {
    const settingValue = {enabled}
    DataStore.save(externalNotificationSetting
      ? Setting.copyOf(externalNotificationSetting, updated => { updated.value = JSON.stringify(settingValue) })
      : new Setting({ name: SettingConstants.APP_EXTERNAL_NOTIFICATIONS, value: JSON.stringify(settingValue) })
    );
  }, [externalNotificationSetting])

  return {
    appExternalNotificationEnabled, 
    updateExternalNotificationSetting
  }
}

export default useExternalNotificationSettings;