import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';
import { Account } from 'mdi-material-ui';

import { MovementActionType } from '../../constants/MovementActionType';
import VesselListItem from '../Vessel/VesselListItem';
import PopoverTooltip from '../Tooltip/PopoverTooltip';
import VesselTooltip from '../Tooltip/VesselTooltip';
import ActionStateAndTimeListItem from '../Action/ActionStateAndTimeListItem';
import CargoListItem from '../Cargos/CargoListItem';
import CargoTooltip from '../Tooltip/CargoTooltip';
import CurrentContactTooltip from '../Tooltip/CurrentContactTooltip';
import PortCallTimelinePortCallMenu from '../PortCall/PortCallTimelinePortCallMenu';
import { itemRequested } from '../../constants/RequestConstants';
import { itemApprovalPending, itemApprovalDeclined } from '../../constants/ApprovalConstants';
import { getNextActionableAction, getPendingRequests } from '../../utils/getters';
import { ActionState } from '../../models';
import SpotlightMapDialog from '../Spotlight/SpotlightMapDialog';

const useStyles = makeStyles(() => ({
  borderBox: {
    boxSizing: 'border-box',
    paddingRight: '3rem',
  },
  itemRequested,
  itemApprovalPending,
  itemApprovalDeclined
}));

const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

const BerthStatusListItem = ({
  portCall,
  showPtd,
  readOnly,
  fullscreen,
  fullscreenRenderItem,
  agentIdFilter,
  hideAgentDetails
}) => {
  const classes = useStyles();

  const nextAction = useMemo(() => portCall && getNextActionableAction(portCall.actions), [portCall]);
  const requests = useMemo(() => (portCall && portCall.requests) ? getPendingRequests(portCall, portCall.requests, agentIdFilter) : [], [portCall, agentIdFilter]);
  const departureRequest = useMemo(() => nextAction && requests.find(r => r.actionData && r.actionData.some(ad => ad.actionId === nextAction.id)), [nextAction, requests]);
  const portCallRequest = useMemo(() => requests.find(r => r.portCall?.id === portCall?.id && r.portCallData), [portCall, requests]);
  const approvalClass = nextAction && (nextAction.state === ActionState.APPROVAL_DECLINED ? classes.itemApprovalDeclined : nextAction.state === ActionState.APPROVAL_PENDING ? classes.itemApprovalPending : null);
  const cargo = {
    manifest: portCall.cargos,
    movementType: [MovementActionType.ARRIVAL, MovementActionType.DEPARTURE]
  };
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  return (
    <Box
      className={classes.borderBox}
      width="100%"
      minHeight="5rem"
      borderTop="0.0625rem #e0e0e0 solid"
      display="flex"
      position="relative"
      data-id={`BerthStatusListItem-${portCall.id}`}
    >
      { mapDialogOpen && 
        <SpotlightMapDialog
          open={mapDialogOpen}
          onClose={() => setMapDialogOpen(false)}
          vesselData={portCall.vesselData}
        /> 
      }
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={fullscreen ? 4 : 6}>
          <PopoverTooltip tooltip={<VesselTooltip vesselData={portCall.vesselData} portCall={portCall} setMapDialogOpen={setMapDialogOpen}/>}>
            <VesselListItem vesselData={portCall.vesselData} disabled={!portCall.portCallVesselId} />
          </PopoverTooltip>
        </Grid>
        {fullscreen ?
          <Grid item xs={6}>
            {fullscreenRenderItem(portCall, nextAction)}
          </Grid>
          :
          <>
            <Grid item xs={1}>
              {cargo.manifest && cargo.manifest.length ?
                <PopoverTooltip tooltip={<CargoTooltip cargo={cargo} />}>
                  <CargoListItem
                    cargo={cargo}
                    icon={true}
                    text={false}
                  />
                </PopoverTooltip>
                : ''}
            </Grid>
            { !hideAgentDetails && 
              <Grid item xs={1}>
                <PopoverTooltip tooltip={<CurrentContactTooltip portCall={portCall} />}>
                  <ListItemIcon>
                    <Account />
                  </ListItemIcon>
                </PopoverTooltip>
              </Grid>
            } 
          </>
        }
        <Grid
          container
          item
          xs={fullscreen ? 2 : 4}
          alignItems="flex-start"
          justifyContent="center"
        >
          {(showPtd && nextAction)
          ? <ActionStateAndTimeListItem action={nextAction} request={departureRequest || portCallRequest} /> 
          : (showPtd)
          ? <ActionStateAndTimeListItem request={departureRequest || portCallRequest} />
          : null
          }
        </Grid>
        <Box
          position="absolute"
          right="1rem"
        >
          <PortCallTimelinePortCallMenu
            disabled={readOnly}
            portCall={portCall}
            contexts={['action', 'portCall', 'details']}
            relevantAction={nextAction}
            anchorOrigin={anchorOrigin}
            ButtonProps={{
              // TODO
              // onClick: () => dispatchToPortCall(selectItem(portCall))
            }}
            skipCheck
          />
        </Box>
        {requests.length ? <div className={classes.itemRequested} /> : approvalClass ? <div className={approvalClass} /> : null }
      </Grid>
    </Box>
  );
};

BerthStatusListItem.defaultProps = {
  showLabels: true,
};

BerthStatusListItem.propTypes = {
  showLabels: PropTypes.bool,
  portCall: PropTypes.object,
  showPtd: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullscreen: PropTypes.bool,
  fullscreenRenderItem: PropTypes.func,
  agentIdFilter: PropTypes.string,
  hideAgentDetails: PropTypes.bool,
}

export default BerthStatusListItem;

