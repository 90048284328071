import { API, graphqlOperation } from "aws-amplify";
import { groupActionsByPortCall } from "../../utils/actions";
import { ActionMovementType, ActionState, PortCallStatus, VoyageStatus } from "../../models";

/** Get movement actions */
const getMovementActions = (actions) => actions && actions.filter(a => a.movementType === ActionMovementType.ARRIVAL || a.movementType === ActionMovementType.DEPARTURE);
/**
 * Build query for listing voyages
 * @param {String} nextToken Token for next page of result, can be null
 * @returns {String} GraphQL query
 */
export const buildVoyageQuery = ({ nextToken, filter }) => {
  const token = (nextToken == null) ? null : `"${nextToken}"`;
  const queryFilter = filter ? filter : null;
  return /* GraphQL */ `
  query getVoyages{
    listVoyages(
      nextToken: ${token}
      limit: 100
      filter: ${queryFilter}
    ) {
      items {
        _deleted
        id
        name
        status
        vessel {
          vesselData {
            name
            imo
            mmsi
            image
            type
            flag
          }
        }
        voyageFirstDeparture
        voyageLastArrival
      }
      nextToken
      startedAt
    }
  }`
};

const getVoyage = /* GraphQL */ `
  query GetVoyage($id: ID!) {
    getVoyage(id: $id) {
      id
      status
      _version
      actions {
        items {
          id
          _version
          actionPortCallId
          actionVoyageId
          movementType
          portCall {
            id
            _version
          }
        }
      }
    }
  }
`;

/**
 * Get voyage by id
 * @param {String} id 
 * @returns Voyage
 */
export const _getVoyage = async (id) => {
  try {
    const voyages = await API.graphql(graphqlOperation(getVoyage, { id: id }));
    return voyages?.data?.getVoyage;

  } catch (error) {
    console.error(`Failed to getVoyageByName ${id}`, error);
    return;
  }
}

/**
 * Prepared Voyage to update in transaction items
 * @param {Voyage} voyage voyage query object
 * @param {Array[Action]} array of actions
 * @param {Array[PortCall]} array of portCalls 
 * @param {String} updateStatus voyage state to be updated 
 * @param {AuditMeta} auditMeta Audit metadata 
 * @param {String} comment 
 * @returns {Array} Array of objects to be updated
 */
export const voyageTransactionItems = ({ voyage, actions, portCalls, updateStatus, auditMeta, comment }) => {
  if (!voyage || !actions || !portCalls || !updateStatus || !auditMeta) return;
  const UpdateVoyageStatus = {
    [VoyageStatus.DELETED]: {
      PortCallStatus: PortCallStatus.DELETED,
      ActionState: ActionState.DELETED
    },
    [VoyageStatus.CANCELLED]: {
      PortCallStatus: PortCallStatus.CANCELLED,
      ActionState: ActionState.CANCELLED
    },
  }
  const items = [];
  for (const action of actions) {
    const actionData = {
      id: action.id,
      _version: action._version,
      // _version: 100000,
      state: UpdateVoyageStatus[updateStatus].ActionState,
      automationLockedOut: true,
      auditMeta: comment ? { ...auditMeta, comment: comment } : { ...auditMeta }
    };
    items.push({
      model: 'Action',
      objects: [JSON.stringify(actionData)]
    })
  }

  for (const portCall of portCalls) {
    const movementActions = getMovementActions(portCall?.actions?.items);
    //Span port call is one of its movement belongs to another voyage or the movement has no voyage
    const isSpanPortCall = movementActions?.find((a) => a?.actionVoyageId !== voyage.id);
    if (!isSpanPortCall) {
      const portCallData = {
        id: portCall.id,
        _version: portCall._version,
        status: UpdateVoyageStatus[updateStatus].PortCallStatus
      };
      items.push({
        model: 'PortCall',
        objects: [JSON.stringify(portCallData)]
      });
    }
  }

  const voyageData = {
    id: voyage.id,
    _version: voyage._version,
    status: updateStatus,
    auditMeta: comment ? { ...auditMeta, comment: comment } : { ...auditMeta }
  };
  items.push({
    model: 'Voyage',
    objects: [JSON.stringify(voyageData)]
  });

  return items;
}

/**
 * Determined Voyage object to be updated 
 * @param {String} voyageId
 * @param {String} updateStatus voyage state to be updated 
 * @param {AuditMeta} auditMeta Audit metadata 
 * @param {String} comment 
 * @returns  Array of objects to be updated
 */
export const getUpdateVoyageTransactionInput = async ({ voyageId, updateStatus, comment, auditMeta }) => {

  //console.log({ voyageId, updateStatus, comment, auditMeta });
  if (!voyageId || !updateStatus || !auditMeta) return;

  const voyage = await _getVoyage(voyageId);
  if (!voyage) return;

  //movement actions
  const actions = voyage?.actions?.items;
  const portCalls = groupActionsByPortCall(actions);
  const updatePortCalls = [];
  for (const portCall of portCalls) {
    updatePortCalls.push(portCall);
  }

  return voyageTransactionItems({ voyage, actions, portCalls: updatePortCalls, updateStatus, auditMeta, comment });
}