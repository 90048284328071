import { PropulsionType, PropellerDirection, RudderType } from "../models";

export const PropulsionTypeToString = {
  [PropulsionType.PROPULSION_FIXED_PITCH]: 'Fixed Pitch Propeller (FPP)',
  [PropulsionType.PROPULSION_CONTROLLABLE_PITCH]: 'Controllable Pitch Propeller (CPP)',
  [PropulsionType.PROPULSION_FIXED_VARIABLE_PITCH]: 'Fixed Variable Pitch (FVPP)',
  [PropulsionType.PROPULSION_AZIMUTH_THRUSTER]: 'Azimith Thruster',
  [PropulsionType.PROPULSION_VOITH_SCHNEIDER]: 'Voith Schneider Drive',
  [PropulsionType.PROPULSION_Z_TYPE]: 'Z Type',
};

export const PropulsionTypeLabelKeys = {
  [PropulsionType.PROPULSION_FIXED_PITCH]: 'PropulsionType.Labels.FixedPitch',
  [PropulsionType.PROPULSION_CONTROLLABLE_PITCH]: 'PropulsionType.Labels.ControllablePitch',
  [PropulsionType.PROPULSION_FIXED_VARIABLE_PITCH]: 'PropulsionType.Labels.FixedVariablePitch',
  [PropulsionType.PROPULSION_AZIMUTH_THRUSTER]: 'PropulsionType.Labels.AzimuthThruster',
  [PropulsionType.PROPULSION_VOITH_SCHNEIDER]: 'PropulsionType.Labels.VoithSchneider',
  [PropulsionType.PROPULSION_Z_TYPE]: 'PropulsionType.Labels.ZType',
};

export const RudderTypeToString = {
  [RudderType.RUDDER_STANDARD]: 'Standard',
  [RudderType.RUDDER_FLAPPED]: 'Flap/Flapped (Becker)',
  [RudderType.RUDDER_FISHTAIL]: 'Fishtail (Schilling)',
};

export const RudderTypeLabelKeys = {
  [RudderType.RUDDER_STANDARD]: 'RudderType.Labels.Standard',
  [RudderType.RUDDER_FLAPPED]: 'RudderType.Labels.Flapped',
  [RudderType.RUDDER_FISHTAIL]: 'RudderType.Labels.Fishtail',
};

export const PropellerDirectionToString = {
  [PropellerDirection.PROPELLER_LEFT]: 'Left-hand',
  [PropellerDirection.PROPELLER_RIGHT]: 'Right-hand',
};

export const PropellerDirectionLabelKeys = {
  [PropellerDirection.PROPELLER_LEFT]: 'PropellerDirection.Labels.Left',
  [PropellerDirection.PROPELLER_RIGHT]: 'PropellerDirection.Labels.Right'
};

export const FormSectionHeadings = {
  PROPULSION: 'Primary Propulsion',
  RUDDER: 'Rudder',
  FWD_AZIMUTH: 'Forward Azimuth',
  AFT_AZIMUTH: 'Aft Azimuth',
  FWD_TUNNEL: 'Forward Tunnel Thrusters',
  AFT_TUNNEL: 'Aft Tunnel Thrusters',
}