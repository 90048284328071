import React , {useEffect, useState, useContext, useCallback } from 'react';
import { MapContext } from '../contexts/map';
import { Attrs, FeatureType, LayerAttrs } from '../constants';
import VesselSource from './VesselSource';
import VesselLabelLayer from './VesselLabelLayer';
import LayerGroup from 'ol/layer/Group';
import { DataStore } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { PortCall, PortCallStatus } from '../../../models';
import { listVesselStates } from '../../../graphql/queries';
import { onCreateVesselState, onDeleteVesselState, onUpdateVesselState } from '../../../graphql/subscriptions';
import { OLVesselLayer } from './OLVesselLayer';
import SpotlightVesselLayer from './SpotlightVesselLayer';


const VesselLayer = ({ id, title, zIndex, onClick, visible, showLabels, onSpotlightVesselUpdate, spotlightVessels }) => {

  const [ source ] = useState(new VesselSource());
  const [ spotlightLayer ] = useState(new SpotlightVesselLayer({
    source: source
  }));
  const [ layer ] = useState(new OLVesselLayer({
    source: source
  }));
  const [ labels ] = useState(new VesselLabelLayer({
    source: source,
    declutter: true
  }));
  const createLayerGroup = () => {
    const group = new LayerGroup({
      title: title,
      layers: [
        spotlightLayer,
        layer,
        labels
      ]
    });
    group.set(LayerAttrs.ID, id);
    group.set(LayerAttrs.I18N, 'Vessels');
    return group;
  };
  const [ layerGroup ] = useState(createLayerGroup());
  const [ added, setAdded ] = useState(false);
  const [context] = useContext(MapContext);

  const InactivePeriod = 1000 * 60 * 5;
  const InactiveTimeout = 1000 * 60 * 20;

  const checkInactive = () => {
    const tnow = Date.now();
    const features = source.getFeatures();
    const inactive = [];
    for(let f of features) {
      const meta = f.get(Attrs.META) 
      if (meta && meta.positionTimestamp) {
        const isCurrentVessel = spotlightVessels && spotlightVessels.includes(meta?.mmsi);
        const last = Date.parse(meta.positionTimestamp);
        let currentActiveStatus = true;
        if ((tnow - last) > InactiveTimeout) {
          inactive.push(f);
          currentActiveStatus = false;
        }
        if (isCurrentVessel) {
          // Update vessel inactive status on spotligthMap dialog
          onSpotlightVesselUpdate && typeof onSpotlightVesselUpdate === 'function' && onSpotlightVesselUpdate(meta,!currentActiveStatus);
        }
      }
    }
    if(inactive.length) {
      const activePortCalls = async () => {
        const ret = new Set();
        const portCalls = await DataStore.query(PortCall, c => c.or(
          c => c.status("eq", PortCallStatus.PREARRIVAL)
                .status("eq", PortCallStatus.ARRIVAL)
                .status("eq", PortCallStatus.IN_PORT)
                .status("eq", PortCallStatus.DEPARTURE)));
        for(let p of portCalls) {
          ret.add(p.vesselData.mmsi);
        }
        return ret;
      }

      activePortCalls().then((active) => {
        for(let f of inactive) {
          if(active.has(f.get(Attrs.META).mmsi)) continue;
          source.removeFeature(f);
        }
      });
    }
  };
  const setVessel = useCallback((vessel)=>{
    if(!vessel) return;
    let spotlight = false;
    if(spotlightVessels.includes(vessel.mmsi)) {
      spotlight = true;
    }
    return ({...vessel, spotlight: spotlight});
  },[spotlightVessels]);

  useEffect(() => {
    let timerId;

    const update = async () => {
      try {
        let nextToken;
        do {
          const result = await API.graphql(graphqlOperation(listVesselStates, {
            filter: {
              active: { eq: true }
            },
            limit: 1000,
            nextToken: nextToken
          }));

          nextToken = result.data.listVesselStates.nextToken;
          const vessels = result.data.listVesselStates.items;
          const updateVessels = vessels?.map((vessel)=>{
           return setVessel(vessel);
          });
          // console.log('vessels',updateVessels)
          source.updateVessels(updateVessels);

        } while(nextToken)
      } catch(error) {
        console.error('Failed to fetch vessels for map');
      }      
    }
    update();

    timerId = setInterval(() => checkInactive(), InactivePeriod);

    const createSubsciption = API.graphql(graphqlOperation(onCreateVesselState)).subscribe({
      next: ({ value }) => {
        const create = value?.data.onCreateVesselState;
        source.updateVessel(setVessel(create));
      },
      error: (error) => console.warn(error)
    });

    const updateSubsciption = API.graphql(graphqlOperation(onUpdateVesselState)).subscribe({
      next: ({ value }) => {
        const update = value?.data.onUpdateVesselState;
        source.updateVessel(setVessel(update));
      },
      error: (error) => console.warn(error)
    });

    const deleteSubscription = API.graphql(graphqlOperation(onDeleteVesselState)).subscribe({
      next: ({ value }) => {
        const remove = value?.data.onDeleteVesselState;
        source.removeVessel(remove);
      },
      error: (error) => console.warn(error)
    });
    
    return () => {
      createSubsciption.unsubscribe();
      updateSubsciption.unsubscribe();
      deleteSubscription.unsubscribe();
      clearInterval(timerId);
    }

  }, [source]);

  useEffect(() => {
    if(context.map) {
      if(!added) {
        layerGroup.getLayersArray().forEach((layer) => layer.setZIndex(zIndex));
        layerGroup.setZIndex(zIndex);
        context.map.addLayer(layerGroup)
        setAdded(true);

        onClick && context.map.on('click', (event) => {
          const feature = context.map.forEachFeatureAtPixel(event.pixel, (feature) => {
            return feature;
          });
          if(!feature) return;
          if(feature.get(Attrs.TYPE) !== FeatureType.Vessel)
            return;
  
          for(let f of source.getFeatures()) {
            if(feature === f) {
              onClick(feature);
            }
          }
        });
      }
    }
  }, [context, layerGroup]);

  useEffect(() => {
    labels.setVisible(showLabels)
  }, [showLabels]);

  useEffect(() => {
    layerGroup.setVisible(visible)
  }, [visible]);

  return (
    <>
    </>
  )
};

export default VesselLayer;