import React, { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  makeStyles,
  CardContent,
  Grid,
  IconButton,
  Divider
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import {degreesToStringHDMS} from 'ol/coordinate';

import * as P from '../../constants/Propulsion';
import { METRES, TONNES, UnitsKeys } from '../../constants/Units';
import { portName } from '../../utils/utils';
import { shipTypeDescription, flagCodeFromMMSI, flagCountryFromMMSI } from './utils/AisTypes';
import EditorField from '../Common/EditorField';
import VesselListItem from '../Vessel/VesselListItem';
import VesselImage from '../Image/VesselImage';
import { DataStore } from 'aws-amplify';
import { Vessel } from '../../models';
import { format, formatDistanceToNow } from 'date-fns';
import useDateTimeSetting from '../../hooks/useDateTimeSetting';
import { useTranslation } from 'react-i18next';
import { DateFnsLanguageMap } from '../../translations';

const useStyles = makeStyles({
  scrollPane: {
    height: 'calc(100% - 4.25rem)',
    overflow: 'auto',
  },
  rootPane: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',

    borderLeft: '0.0625rem solid #e0e0e0',
    '&:first-child': {
      borderLeft: 'none',
    }
  },
  header: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    padding: 0
  },
  card: {
    padding: '0rem 1rem 0rem 1rem',
  },
  boxDetails: {
    padding: '0rem 1rem 0rem 1rem',
    marginTop: '0.5rem',
  },
  imagePlaceholder: {
    backgroundColor: 'grey',
    width: '25rem',
  },
  container: {
    minWidth: '25rem'
  }

});

const MS_TO_KTS = 1.944;

const VesselInfoPanel = ({client, vessel, onClose}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [ dbVessel, setDbVessel ] = useState(null);
  const { dateTimeFormat } = useDateTimeSetting();
  const { i18n } = useTranslation();

  useEffect(() => {
    const getVesselRec = async (mmsi) => (await DataStore.query(Vessel, c => c.mmsi("eq", mmsi))).pop();
    
    getVesselRec(vessel.meta.mmsi).then((db) => {
      setDbVessel(db ? db : null);
    });

  },[vessel]);

  return vessel && (
    <Grid container className={classes.container}>
      <Grid item className={classes.rootPane}>
        <CardContent className={classes.card}>
          <Grid container className={classes.header}>
            <VesselListItem
              className={classes.item}
              vesselData={ {
                ...vessel.meta,
                imo: vessel.meta.imo !== '0' ? vessel.meta.imo : 'Unknown',
                flag: flagCodeFromMMSI(vessel.meta.mmsi),
              }}
              disableGutters={true}
            />
            <IconButton onClick={onClose}><Close /></IconButton>
          </Grid>
        </CardContent>

      <Grid item className={classes.scrollPane}>
        { (dbVessel && dbVessel.vesselData.image) ?
            <VesselImage 
              image={dbVessel.vesselData.image} 
              className={classes.imagePlaceholder}
              containerStyles={{
                maxWidth: '25rem',
                maxHeight: '14rem'
              }}
            />
          :
            <Divider/>
        }

        <Box display="flex" flexDirection="column" className={classes.boxDetails} >
          <Typography
            variant="overline"
          >
            {t("Vessel.Labels.Identity")}
          </Typography>
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.IMO")}
            name="imo"
            value={vessel.meta.imo !== '0' ? vessel.meta.imo : 'Unknown'}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.MMSI")}
            name="mmsi"
            value={vessel.meta.mmsi}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.CallSign")}
            name="callsign"
            value={vessel.meta.callSign}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.AISShipType")}
            name="shipType"
            value={shipTypeDescription(vessel.meta.shipType)}
          />
          <EditorField
            disabled={true}
            name="flag"
            label={t("Vessel.Labels.Flag")}
            value={`${flagCodeFromMMSI(vessel.meta.mmsi)} - ${flagCountryFromMMSI(vessel.meta.mmsi)}`}
          />

          {dbVessel && <>
            <EditorField
              disabled={true}
              label={t("Vessel.Labels.Type")}
              name="type"
              value={dbVessel.vesselData.type}
            />
          </>}

          <Typography
            variant="overline"
          >
            {t("Map.Labels.Navigation")}
          </Typography>
          <EditorField
            disabled={true}
            label={t("Map.Labels.Destination")}
            name="destination"
            value={vessel.meta.destination}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.ETA")}
            name="eta"
            value={!isNaN(new Date(vessel.meta.eta)) ? format(new Date(vessel.meta.eta), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] }) : null}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.Speed")}
            name="speed"
            value={(MS_TO_KTS * vessel.meta.speed).toFixed(2)}
            fieldSuffix={t(UnitsKeys.KN)}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.Heading")}
            name="heading"
            value={vessel.meta.heading ? vessel.meta.heading.toFixed(2) : null}
            fieldSuffix="&deg;"
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.CMG")}
            name="cmg"
            value={vessel.meta.cmg.toFixed(2)}
            fieldSuffix="&deg;"
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.Latitude")}
            name="lat"
            value={degreesToStringHDMS('NS', vessel.meta.lat, 2)}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.Longitude")}
            name="lon"
            value={degreesToStringHDMS('EW', vessel.meta.lon, 2)}
          />

          <Typography
            variant="overline"
          >
            {t("Map.Labels.Time")}
          </Typography>
          <EditorField
            disabled={true}
            label={t("Map.Labels.LastUpdated")}
            name="lastUpdated"
            value={format(new Date(vessel.meta.positionTimestamp), dateTimeFormat, { locale: DateFnsLanguageMap[i18n.language] })}
          />
          <EditorField
            disabled={true}
            label={t("Map.Labels.Age")}
            name="age"
            value={formatDistanceToNow(new Date(vessel.meta.positionTimestamp), { locale: DateFnsLanguageMap[i18n.language] })}
          />

          {dbVessel && dbVessel.vesselData && <>

          <Typography
            id="port-call-vessel-details"
            variant="overline"
          >
            {t("Vessel.Labels.Details")}
          </Typography>
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.YearBuilt")}
            name="yearBuilt"
            inputProps={{
              maxLength: 4,
            }}
            value={dbVessel.vesselData.yearBuilt}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.PortOfRegistry")}
            name="portOfRegistry"
            value={portName(dbVessel.portOfRegistry)}
          />

          <Typography
            id="port-call-vessel-dimensions"
            variant="overline"
          >
            {t("Vessel.Labels.Dimensions")}
            </Typography>
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.Length")}
            name="lengthOverall"
            type="number"
            fieldSuffix={t(UnitsKeys[METRES])}
            value={dbVessel.vesselData.lengthOverall}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.Beam")}
            name="beam"
            type="number"
            fieldSuffix={t(UnitsKeys[METRES])}
            value={dbVessel.vesselData.beam}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.Draught")}
            name="draught"
            type="number"
            fieldSuffix={t(UnitsKeys[METRES])}
            value={dbVessel.vesselData.draught}
          />

          <Typography
            id="port-call-vessel-tonnage"
            variant="overline"
          >
            {t("Vessel.Labels.Tonnage")}
          </Typography>
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.DeadweightTonnage")}
            name="deadWeightTonnage"
            type="number"
            fieldSuffix={t(UnitsKeys[TONNES])}
            value={dbVessel.vesselData.deadWeightTonnage}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.GrossTonnage")}
            name="grossTonnage"
            type="number"
            value={dbVessel.vesselData.grossTonnage}
          />
          <EditorField
            disabled={true}
            label={t("Vessel.Labels.NetTonnage")}
            name="netTonnage"
            type="number"
            value={dbVessel.vesselData.netTonnage}
          />
          {dbVessel.vesselAgent && <>
            <Typography
              variant="overline"
            >
              {t("Agent.Labels.Agent")}
            </Typography>
            <EditorField
              disabled
              label={t("Agent.Labels.Name")}
              name="vesselAgentName"
              type="number"
              value={dbVessel.vesselAgent.name}
            />
            <EditorField
              disabled
              label={t("Agent.Labels.TelephoneNumber")}
              name="vesselAgentNumber"
              value={dbVessel.vesselAgent.number}
            />
          </> }

          <Typography
            variant="overline"
          >
            {t("Vessel.Labels.PrimaryPropulsion")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselPropulsionCount"
            value={dbVessel.vesselData.propellerCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.Type")}
            name="vesselPropulsionType"
            value={t(P.PropulsionTypeLabelKeys[dbVessel.vesselData.propulsionType])}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.Direction")}
            name="vesselPropellerDirection"
            value={t(P.PropellerDirectionLabelKeys[dbVessel.vesselData.propellerDirection])}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.TotalPower")}
            name="vesselPropulsionPower"
            value={dbVessel.vesselData.propulsionPower}
            fieldSuffix={dbVessel.vesselData.propulsionPowerUnit && t(UnitsKeys[dbVessel.vesselData.propulsionPowerUnit])}
          />
          <Typography
            variant="overline"
          >
            {t("Vessel.Labels.Rudder")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselRudderCount"
            value={dbVessel.vesselData.rudderCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.Type")}
            name="vesselRudderType"
            value={t(P.RudderTypeLabelKeys[dbVessel.vesselData.rudderType])}
          />
          <Typography
            id="port-call-vessel-ancilliary-fwd-azimuth"
            variant="overline"
          >
            {t("Vessel.Labels.ForwardAzimuth")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselFwdAzimuthCount"
            value={dbVessel.vesselData.fwdAzimuthCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.TotalPower")}
            name="vesselFwdAzimuthPower"
            value={dbVessel.vesselData.fwdAzimuthPower}
            fieldSuffix={dbVessel.vesselData.fwdAzimuthPowerUnit && t(UnitsKeys[dbVessel.vesselData.fwdAzimuthPowerUnit])}
          />
          <Typography
            id="port-call-vessel-ancilliary-aft-azimuth"
            variant="overline"
          >
            {t("Vessel.Labels.AftAzimuth")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselAftAzimuthCount"
            value={dbVessel.vesselData.aftAzimuthCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.TotalPower")}
            name="vesselAftAzimuthPower"
            value={dbVessel.vesselData.aftAzimuthPower}
            fieldSuffix={dbVessel.vesselData.aftAzimuthPowerUnit && t(UnitsKeys[dbVessel.vesselData.aftAzimuthPowerUnit])}
          />
          <Typography
            variant="overline"
          >
            {t("Vessel.Labels.ForwardTunnelThrusters")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselFwdTunnelThrustCount"
            value={dbVessel.vesselData.fwdTunnelThrusterCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.TotalPower")}
            name="vesselFwdTunnelThrustPower"
            value={dbVessel.vesselData.fwdTunnelThrusterPower}
            fieldSuffix={dbVessel.vesselData.fwdTunnelThrusterPowerUnit && t(UnitsKeys[dbVessel.vesselData.fwdTunnelThrusterPowerUnit])}
          />
          <Typography
            variant="overline"
          >
            {t("Vessel.Labels.AftTunnelThrusters")}
          </Typography>
          <EditorField
            disabled
            label={t("Vessel.Labels.Number")}
            name="vesselAftTunnelThrustCount"
            value={dbVessel.vesselData.aftTunnelThrusterCount}
          />
          <EditorField
            disabled
            label={t("Vessel.Labels.TotalPower")}
            name="vesselAftTunnelThrustPower"
            value={dbVessel.vesselData.aftTunnelThrusterPower}
            fieldSuffix={dbVessel.vesselData.aftTunnelThrusterPowerUnit && t(UnitsKeys[dbVessel.vesselData.aftTunnelThrusterPowerUnit])}
          />
        </> }
      </Box>
      </Grid>
    </Grid>
    </Grid>
  );
};

export default VesselInfoPanel;