import { ListItemText, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { format } from 'date-fns';
import { DateFnsLanguageMap } from '../translations';
import { useTranslation } from 'react-i18next';
import TimeEstimate from './TimeEstimate';

dayjs.extend(duration);

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      background: 'none'
    }
  },
  listItemText: {
    flexGrow: 0,
    flexShrink: 0,
    paddingRight: '0.5rem'
  },
  smallFontSize: {
    fontSize: '0.85rem'
  },
  icon: {
    paddingLeft: '0.25rem'
  },
  popover: {
    pointerEvents: 'none'
  },
  ahead: {
    color: green[500],
  },
  behind: {
    color: red[500],
  }
}));

/**
 * ScheduleTime
 * 
 * Component for displaying complex times that may have estimates
 */
const ScheduleTime = ({ requested, requestedTime, planned, actual, estimated, estimatedUpdatedAt, reverse = false }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const time = requestedTime || actual || planned || requested;
  const timedayjs = (time && dayjs(time)) || null;

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={reverse ? 'column' : 'row'}
      justifyContent={reverse ? 'center' : 'space-between'}
      gridColumnGap = {reverse? '0.5rem' : '0rem'}
      className='ScheduleTime'
      id="MovementTime"
    >
      <ListItemText
        className={classes.listItemText}
        primary={timedayjs && format(timedayjs.toDate(), 'HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}
        primaryTypographyProps={{
          noWrap: true
        }}
        secondary={timedayjs && format(timedayjs.toDate(), 'dd MMM yyyy', { locale: DateFnsLanguageMap[i18n.language] })}
        secondaryTypographyProps={{
          noWrap: true
        }}
      />
      <TimeEstimate requested={requested} requestedTime={requestedTime} planned={planned} actual={actual} estimated={estimated} estimatedUpdatedAt={estimatedUpdatedAt} reverse={reverse} />
    </Box>
  )
};

export default ScheduleTime;