import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Chip,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

/**
 * 
 *  VesselListItem
 *  Displays a vesselData as a material list item. 
 *  Avatar is always the vesselData flag.
 *  Primary text is always the vesselData name.
 *  Secondary text can have a node passed in so that 
 *  it can render an icon alongside the secondary text
 *  if necessary.
 *  Meta field is displayed as a material chip element as 
 *  an end adornment.
 * 
 */

const useStyles = makeStyles(theme => ({
  flag: {
    width: '2.2rem',
    border: '1px solid #ccc'
  },
  meta: {
    minWidth: '2rem',
    height: '2rem',
    marginLeft: '0',
    marginRight: '0.25rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    borderRadius: 0,
    fontSize: '0.725rem'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '0.25rem'
  }
}));

const VesselListItem = ({
  disableGutters,
  vesselData,
  metaField,
  selected,
  secondaryField,
  disabled,
  portCallId,
  voyageId,
  voyageStatus,
  ...otherProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const idTypes = {
    PORTCALL: 'PORTCALL',
    VOYAGE: 'VOYAGE'
  };

  const renderListItem = () => {
    return (
      <ListItem
        component="div"
        disableGutters={disableGutters}
        selected={selected}
        disabled={disabled}
        dense={!!(portCallId || voyageId)}
        {...otherProps}
      >
        <ListItemAvatar>
          { vesselData.flag ? (
            <img
              alt="flag"
              src={`${process.env.PUBLIC_URL}/flags/${vesselData.flag.trim().toLowerCase()}.svg`}
              className={classes.flag}
            />
          ) : (
            <div className={classes.flag}>&nbsp;</div>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={vesselData.name}
          primaryTypographyProps={{
            component: 'p',
            noWrap: true,
            variant: 'body1',
            overflow: 'hidden',
            textoverflow: 'ellipsis',
            id: 'VesselListItemName',
          }}
          secondary={(vesselData.imo && !portCallId && !voyageId) &&
            <Box component="span" display="flex" alignItems="center" id={'VesselListItemIMO'}>
                <span className={classes.label}>{t('Vessel.Labels.IMO')}</span>
                <Typography component="span">{vesselData.imo}</Typography>
            </Box>
          }
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'body2'
          }}
          data-id={vesselData.mmsi}
        />
        {metaField && vesselData[metaField] && (
          <Chip
            avatar={<Avatar className={classes.meta}>{metaField.toUpperCase()}</Avatar>}
            label={vesselData[metaField]}
            color={selected ? 'primary' : 'default'}
          />
        )}
      </ListItem>
    );
  }

  const renderId = (idType) => {
    return(
      <ListItem
        component="div"
        disableGutters={disableGutters}
        dense={true}
      >
        <Typography id={idType === idTypes.VOYAGE ? 'VesselListItemVoyageId' : 'VesselListItemPortCallId'}>
          { idType === idTypes.VOYAGE ? t('Vessel.Labels.VoyageId', {voyageId}) : t('Vessel.Labels.PortCallID', {portCallId})}
        </Typography>
      </ListItem>
    )
  }

  return (
    <>
      {!vesselData ? null : renderListItem()}
      {!portCallId ? null : renderId(idTypes.PORTCALL)}
      {!voyageId ? null : renderId(idTypes.VOYAGE)}
    </>
  ) 
  
  
};

VesselListItem.defaultProps = {
  metaField: null,
  secondaryField: null,
  selected: false,
  disabled: false
};


VesselListItem.propTypess = {
  // Meta field is displayed a chip at the end of the item
  metaField: PropTypes.string,
  // Secondarty field is display in smaller type under the primary text
  // It is a node so that icons can be passed in as well as text
  secondaryField: PropTypes.node,
  // Selected is used to render the item as a highlighted item in a list
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

export default VesselListItem;
