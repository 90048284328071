import { useContext } from "react";
import VoyageBrowser from "../VoyageBrowser/VoyageBrowser"
import { sortVoyagesByEarliestDate } from "../../../utils/sorters";
import { UIContext } from "../../../contexts/ui";
import RouteConstants from "../../../constants/RouteConstants";

const VoyageSchedule = () => {
  const [uiContext] = useContext(UIContext);
  const { isVoyageAdmin } = uiContext;

  if (!isVoyageAdmin) return null;
  return <VoyageBrowser
    sortOrder={sortVoyagesByEarliestDate}
    displayStatus={true}
    lastView={RouteConstants.VOYAGE_SCHEDULE}
    liveVoyages={true}
    tableId={'voyage-schedule'}
  />;
};

export default VoyageSchedule;