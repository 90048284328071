import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActionTypeAutocomplete from './ActionTypeAutocomplete';
import ActionTypeListItem from './ActionTypeListItem';

export const useStyles = makeStyles(theme => ({
  optionTagWrapper: {
    '& .MuiChip-label': {
      paddingLeft: '0.50rem',
      paddingRight: '0.50rem'
    },
    '& .MuiListItemIcon-root': {
      marginRight: '0.50rem',
      '& .MuiSvgIcon-root': {
        color: '#000'
      }
    }
  }
}));

export default ({ ...other }) => {
  return (
    <ActionTypeAutocomplete
      {...other}
      multiple 
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            label={
              <ActionTypeListItem actionType={option} deletedWarning={true} />
            }
            {...getTagProps({ index })}
          />
        ));
      }}/>
  );
};
