import { Action, ActionMovementType, ActionTemplate, TemplateSubType, ChargeableItem, ChargeableItemTemplate, Template, TemplateType } from "../models";
import { v4 as uuidv4 } from 'uuid';

/*
 * Template type related utilities
 */



export const generateTemplateFromAction = (action, dataStoreContext) => {
  if (!action) return null;
  return new Template({
    name: action.type.name,
    type: TemplateType.ACTION,
    typeId: action.type.id,
    subType:
      (action.movementType === ActionMovementType.ARRIVAL && TemplateSubType.ACTION_ARRIVAL) ||
      (action.movementType === ActionMovementType.DEPARTURE && TemplateSubType.ACTION_DEPARTURE) ||
      (action.movementType === ActionMovementType.SHIFT_ARRIVAL && TemplateSubType.ACTION_SHIFT_ARRIVAL) ||
      (action.movementType === ActionMovementType.SHIFT_DEPARTURE && TemplateSubType.ACTION_SHIFT_DEPARTURE) ||
      null,
    value: JSON.stringify(actionToActionTemplate(action, dataStoreContext))
  });
};

// FIXME remove isTariffBookEnabled when feature flag removed
export const generateActionFromTemplate = (template, dataStoreContext, actionState, isTariffBookEnabled=false) => {
  console.log('generateActionFromTemplate 1', template, isTariffBookEnabled)
  if (!template) return null;
  const json = template.value ? JSON.parse(template.value) : {};
  const type = dataStoreContext.actionTypes.find(at => at.id === template.typeId);
  return new Action({
    state: actionState,
    type,
    movementType: json.movementType || null,
    // FIXME when tariff book fflag is removed, chargeableItems should be []
    chargeableItems: !isTariffBookEnabled && json.chargeableItems ? json.chargeableItems.map(t => chargeableItemTemplateToChargeableItem(t, dataStoreContext)).filter(ci => ci) : [],
    documents: [''],
    movementPilots: [''],
    movementLinesmen: [''],
    movementMooringVessels: [''],
    automationLockedOut: false
  });
};

const actionToActionTemplate = (action, dataStoreContext) =>
  new ActionTemplate({
    actionTypeId: action.type.id,
    movementType: action.movementType,
    chargeableItems: action.chargeableItems ? action.chargeableItems.map(ci => chargeableItemToChargeableItemTemplate(ci, dataStoreContext)).filter(ci => ci) : []
  });

const chargeableItemTemplateToChargeableItem = (chargeableItemTemplate, dataStoreContext) => {
  const tariff = dataStoreContext.tariffs.find(t => t.id === chargeableItemTemplate.tariffId);
  if (!tariff) {
    console.error(`Tariff ${chargeableItemTemplate.tariffId} is missing, skipping ChargeableItemTemplate`);
    return null;
  }
  let total = tariff.data.unitCost * chargeableItemTemplate.quantity;
  if(Number(chargeableItemTemplate.secondaryMult)){
    total *= chargeableItemTemplate.secondaryMult;
  }
  return new ChargeableItem({
    id: uuidv4(),
    tariffData: tariff.data,
    tariffUnit: tariff.unit.name,
    tariffSecondaryUnit: tariff.unit.secondaryUnitName,
    notes: chargeableItemTemplate.notes,
    quantity: chargeableItemTemplate.quantity,
    secondaryMult: chargeableItemTemplate.secondaryMult,
    total
  });
};

const chargeableItemToChargeableItemTemplate = (chargeableItem, dataStoreContext) => {
  const tariff = dataStoreContext.tariffs.find(t => t.data.code === chargeableItem.tariffData.code);
  if (!tariff) {
    console.error(`Tariff ${chargeableItem.tariffData.code} is missing, skipping ChargeableItem`);
    return null;
  }
  return new ChargeableItemTemplate({
    notes: chargeableItem.notes,
    quantity: chargeableItem.quantity,
    secondaryMult: chargeableItem.secondaryMult,
    tariffId: tariff.id
  });
};
