import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Popover, CardContent, Typography, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export const AnimatedIteratorRates = {
  // DISABLED: 0,
  SECONDS_1: 1000,
  SECONDS_5: 5000,
  SECONDS_15: 15000,
  MINUTES_1: 60000,
};

const DEFAULT_ANIMATED = true;
const DEFAULT_RATE = AnimatedIteratorRates.SECONDS_15;

export const useAnimatedIterator = ({ id, defaultItems, defaultAnimated, defaultRate }) => {
  const itemsKey = useMemo(() => `smartport::preferences::${id}::AnimatedIteratorItems`, [id]);
  const animatedKey = useMemo(() => `smartport::preferences::${id}::AnimatedIteratorAnimated`, [id]);
  const rateKey = useMemo(() => `smartport::preferences::${id}::AnimatedIteratorRate`, [id]);
  const lastIdKey = useMemo(() => `smartport::preferences::${id}::AnimatedIteratorLastId`, [id]);
  const [items, setItems] = useState(defaultItems);
  const [animated, setAnimated] = useState(defaultAnimated || DEFAULT_ANIMATED);
  const [rate, setRate] = useState(defaultRate || DEFAULT_RATE);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = useMemo(() => items[currentIndex], [currentIndex, items]);

  // setter wrappers for local storage

  const _setAnimated = useCallback(value => {
    localStorage.setItem(animatedKey, JSON.stringify(value ? 1 : 0));
    setAnimated(value);
  }, [animatedKey, setAnimated]);
  const _setRate = useCallback(value => {
    localStorage.setItem(rateKey, value);
    setRate(value);
  }, [rateKey, setRate]);
  const _setCurrentIndex = useCallback(value => {
    localStorage.setItem(lastIdKey, JSON.stringify(items[value].id));
    setCurrentIndex(value);
  }, [lastIdKey, setCurrentIndex, items]);
  const _setItems = useCallback(value => {
    localStorage.setItem(itemsKey, JSON.stringify(value.map(i => ({ id: i.id, checked: i.checked }))));
    setItems(value);
    // if current item is unchecked/does not exist, set current index to first valid one
    const checkCurrentItem = value.find(i => i.id === currentItem.id);
    if (!checkCurrentItem || !checkCurrentItem.checked) {
      _setCurrentIndex(value.findIndex(i => i.checked));
    }
  }, [itemsKey, setItems, currentItem, _setCurrentIndex]);

  const prev = useCallback(() => {
    const enabledItems = items.filter(i => i.checked);
    const enabledIndex = enabledItems.findIndex(i => i.id === currentItem.id);
    const newIndex = (enabledIndex !== -1) ? (!enabledIndex ? enabledItems.length - 1 : enabledIndex - 1) : 0;
    _setCurrentIndex(items.findIndex(i => i.id === enabledItems[newIndex].id));
  }, [currentItem, _setCurrentIndex, items]);
  const next = useCallback(() => {
    const enabledItems = items.filter(i => i.checked);
    const enabledIndex = enabledItems.findIndex(i => i.id === currentItem.id);
    const newIndex = (enabledIndex !== -1) ? (enabledIndex + 1) % enabledItems.length : 0;
    _setCurrentIndex(items.findIndex(i => i.id === enabledItems[newIndex].id));
  }, [currentItem, _setCurrentIndex, items]);

  // load

  useEffect(() => {
    // load item settings
    const saved = JSON.parse(localStorage.getItem(itemsKey)) || [];
    const newItems = (defaultItems && defaultItems.length) ? defaultItems.map(di => {
      const savedItem = saved.find(i => i.id === di.id);
      return { ...di, checked: savedItem ? savedItem.checked : di.checked };
    }) : [];
    // load last id
    const lastId = JSON.parse(localStorage.getItem(lastIdKey));
    let newIndex = newItems.findIndex(i => i.id === lastId && i.checked);
    if (newIndex === -1) {
      newIndex = newItems.findIndex(i => i.checked);
    }

    // load animated
    const savedAnimated = JSON.parse(localStorage.getItem(animatedKey));

    // load rate
    const savedRate = JSON.parse(localStorage.getItem(rateKey));

    setItems(newItems);
    setCurrentIndex(newIndex);
    setAnimated(savedAnimated !== null ? Boolean(savedAnimated) : defaultAnimated);
    setRate(savedRate ? savedRate : defaultRate);
  }, [defaultItems, itemsKey, setItems, defaultRate, rateKey, setRate, defaultAnimated, animatedKey, setAnimated, setCurrentIndex, lastIdKey]);

  useEffect(() => {
    if (!items || !items.length || !animated || !rate) return;
    const interval = setInterval(() => next(), rate);
    return () => clearInterval(interval);
  }, [items, rate, animated, currentIndex]);

  useEffect(() => {
    // next(true);
  }, [items]);

  const canChange = items.filter(i => i.checked).length > 1;
  return [currentItem, canChange && next, canChange && prev, items, _setItems, animated, _setAnimated, rate, _setRate];
};

export const AnimatedIteratorSettings = ({ items, setItems, rate, setRate, anchorEl, onClose }) => {
  const { t } = useTranslation();

  const handleChange = e => {
    const newItems = [...items];
    const index = items.findIndex(i => i.id === e.target.name);
    newItems[index] = { ...newItems[index], checked: e.target.checked };
    if (newItems.filter(i => i.checked).length)
      setItems(newItems);
  };

  const AnimatedIteratorLabels = useMemo(() => ({
    // DISABLED: 'Disabled',
    SECONDS_1: t('BerthStatusDisplay.Labels.Every1Second'),
    SECONDS_5: t('BerthStatusDisplay.Labels.Every5Second'),
    SECONDS_15: t('BerthStatusDisplay.Labels.Every15Second'),
    MINUTES_1: t('BerthStatusDisplay.Labels.Every1Minute'),
    ANIMATE_AUTO: t('BerthStatusDisplay.Labels.RefreshRate'),
    ENABLED_COLUMNS: t('BerthStatusDisplay.Labels.DisplayedDetails')
  }), [t]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <CardContent style={{ width: '12rem' }}>
        <FormControl variant="outlined" style={{ width: '100%', marginBottom: '1rem' }}>
          <InputLabel>{AnimatedIteratorLabels.ANIMATE_AUTO}</InputLabel>
          <Select
            value={rate}
            onChange={e => setRate(e.target.value)}
            variant="outlined"
            label={AnimatedIteratorLabels.ANIMATE_AUTO}
          >
            <MenuItem value={AnimatedIteratorRates.SECONDS_1}>{AnimatedIteratorLabels.SECONDS_1}</MenuItem>
            <MenuItem value={AnimatedIteratorRates.SECONDS_5}>{AnimatedIteratorLabels.SECONDS_5}</MenuItem>
            <MenuItem value={AnimatedIteratorRates.SECONDS_15}>{AnimatedIteratorLabels.SECONDS_15}</MenuItem>
            <MenuItem value={AnimatedIteratorRates.MINUTES_1}>{AnimatedIteratorLabels.MINUTES_1}</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h6">{AnimatedIteratorLabels.ENABLED_COLUMNS}</Typography>
        <FormControl component="fieldset">
          <FormGroup>
            {items && items.map(item =>
              <FormControlLabel
                key={item.id}
                control={<Checkbox checked={item.checked} onChange={handleChange} name={item.id} />}
                label={item.label}
              />
            )}
          </FormGroup>
        </FormControl>
      </CardContent>
    </Popover>
  );
};