import React from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { CertificateType } from '../../models';
import AdminModelEditor, { AdminModelEditorHeaderColumn } from './AdminModelEditor';
import { DataStore } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

const AdminCertificateTypeHeaderContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <AdminModelEditorHeaderColumn label={t('CertificateType.Labels.Name')} flexBasis="50%" />
      <AdminModelEditorHeaderColumn label={t('CertificateType.Labels.Description')} flexBasis="50%" />
    </>
  )
};

const AdminCertificateTypeListItemContent = ({ value }) => {
  return (
    <>
      <Box style={{ flexBasis: "50%" }}>
        <Typography noWrap style={{width: '100%', paddingLeft: value?.certificateTypeCategoryId ? '1rem' : '' }}>{value?.name ?? ''}</Typography>
      </Box>
      <Typography noWrap style={{ flexBasis: "50%" }}>{value?.description ?? ''}</Typography>
    </>

  )
}

const AdminCertificateTypeFormContent = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography id="CertificateMessage" variant="subtitle1" style={{ opacity: 0.5 }}>{(!value?._version && value?.certificateTypeCategoryId) ? (t('CertificateType.Labels.HelperMessage', {category: value?.category?.name ?? ''})) : ''}</Typography>
      <TextField required disabled={disabled} value={value.name || ''} id="CertificateName" name="name" label={t('CertificateType.Labels.Name')} fullWidth variant="outlined" onChange={onChange} margin="normal"/>
      <TextField disabled={disabled} value={value.description || ''} id="CertificateDescription" name="description" label={t('CertificateType.Labels.Description')} fullWidth variant="outlined" multiline minRows={3} maxRows={10} margin="normal" onChange={onChange} />
    </>
  )
}

const AdminCertificateType = () => {
  const { t } = useTranslation();
  const certificateType = t('CertificateType.Labels.CertificateTypeLowerCase');
  const certificateCategory = t(('CertificateType.Labels.CertificateCategoryLowerCase'));

  return (
    <AdminModelEditor
      HeaderContent={AdminCertificateTypeHeaderContent}
      ListItemContent={AdminCertificateTypeListItemContent}
      FormContent={AdminCertificateTypeFormContent}
      model={CertificateType}
      sort={(a, b) => (a.name > b.name) ? 1 : -1}
      filter={i => !i.deleted}
      canUpdate={() => true}
      canDelete={() => true}
      onDelete={async (item) => {
        await DataStore.save(CertificateType.copyOf(item, updated => { updated.deleted = true; }));
        if(item?.children) {
          for(const child of item?.children){
            await DataStore.save(CertificateType.copyOf(child, updated => { updated.deleted = true; }));
          }
        }
      }}
      customDeleteTooltip={i => i.certificateTypeCategoryId ? 
      t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: certificateType }) : 
      t('AdminModelEditor.Labels.CustomDeleteEntry', { itemName: certificateCategory })}
      canSave={i => Boolean(i.name)}
      canAdd={() => true}
      transform={(items) => {
        //Get a map of categoryType lists keyed by category id(parent)
        const listMap = items.reduce((accMap, curItem) => {
          if(!curItem?.certificateTypeCategoryId){
             return accMap;
          }
          const ls =  accMap.get(curItem?.certificateTypeCategoryId);
          return accMap.set(curItem?.certificateTypeCategoryId, [...(ls ?? []), curItem]);
        }, new Map())

        return items.reduce((acc, curItem) => {
          return (curItem?.certificateTypeCategoryId) ? acc : [...acc, CertificateType.copyOf(curItem, updated => updated.children = listMap.get(curItem.id))]
        }, [])
      }}
      menuItems={[
        {
          id: 'AddCategory',
          title: 'Add category', 
          enabled: () => true, 
          getDefaultItem: () => ({})
        },
        {
          id: 'AddType',
          title: 'Add type', 
          enabled: (selectedItem) => selectedItem && !selectedItem?.certificateTypeCategoryId && selectedItem._version, 
          getDefaultItem: (selectedItem) => ({
            certificateTypeCategoryId: selectedItem?.id,
            category:selectedItem
          })
        }
      ]}
    />
  );
};

export default AdminCertificateType;