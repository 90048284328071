import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { MovementActionDisplayTypeKeys as mt } from '../../constants/MovementActionDisplayType';
import { UIContext } from '../../contexts/ui';
import { Action } from '../../models';
import { ActionTypeIds } from '../../environment';
import { METRES, UnitsKeys } from '../../constants/Units';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';

const useStyles = makeStyles(() => ({
  formLabelLabel: {
    //textTransform: 'uppercase',
    // marginBottom: '9px',
    //marginTop: '26px',
  },
}));

export const ActionVesselEdit = ({
  action,
  setAction
}) => {
  const classes = useStyles();
  const [uiContext,] = useContext(UIContext);
  const { t } = useTranslation();

  const readOnly = uiContext.readOnly;

  const handleChange = (e) => {
    const { name, value, type, maxLength } = e.target;
    if (maxLength > 0 && value.length > maxLength) return;
    const newValue = value === '' ? null : (type === 'number') ? parseFloat(value) : value;
    setAction(Action.copyOf(action, updated => { updated[name] = newValue; }));
  };

  return (
    <>
      <Typography
        className={classes.formLabelLabel}
        id="action-vessel"
        variant="h6"
      >
        {t('Vessel.Labels.Vessel')}
      </Typography>
      <Typography
        className={classes.formLabelLabel}
        id="action-vessel-details"
        variant="overline"
      >
        {action && action.type.id === ActionTypeIds.MOVEMENT && t('ActionVesselEdit.Labels.MovementDetails', {movement: t(mt[action.movementType])}) }
      </Typography>

      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.MastersName')}
        margin="normal"
        name="vesselMastersName"
        value={action.vesselMastersName || ''}
        onChange={handleChange}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.NumCrew')}
        margin="normal"
        name="vesselCrew"
        value={action.vesselCrew || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
        }}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.NumPassengers')}
        margin="normal"
        name="vesselPassengers"
        value={action.vesselPassengers || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
        }}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.ForwardDraught')}
        margin="normal"
        name="vesselForwardDraught"
        value={action.vesselForwardDraught || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
          step: 0.1
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
        }}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.AftDraught')}
        margin="normal"
        name="vesselAftDraught"
        value={action.vesselAftDraught || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
          step: 0.1
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
        }}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.SailingDraught')}
        margin="normal"
        name="vesselSailingDraught"
        value={action.vesselSailingDraught || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
          step: 0.1
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
        }}
      />
      <TextField
        autoComplete="off"
        disabled={readOnly}
        variant="outlined"
        fullWidth
        label={t('Vessel.Labels.AirDraught')}
        margin="normal"
        name="vesselAirDraught"
        value={action.vesselAirDraught || ''}
        onChange={handleChange}
        type="number"
        inputProps={{
          min: 0,
          step: 0.1
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t(UnitsKeys[METRES])}</InputAdornment>
        }}
      />
    </>
  )
}

export default ActionVesselEdit;
