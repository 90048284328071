export const PORTCALL_PREVIEW = {
    "id": "0da7e9c1-6459-444c-bda7-a081cb952865",
    "referenceId": "23-0512-0006",
    "status": "PREARRIVAL",
    "lastZone": null,
    "remarks": null,
    "portCallCategoryId_": "75e75d42-98ce-4bd6-a496-d9c895453314",
    "portCallTags": [
        "Lay-by"
    ],
    "documents": [
        ""
    ],
    "portCallVesselId_": "937a2e29-0747-4af7-821b-880dbf8286bf",
    "portCallVesselId": "937a2e29-0747-4af7-821b-880dbf8286bf",
    "vesselData": {
        "name": "OCEAN OBSERVER",
        "imo": "8511275",
        "mmsi": "232217000",
        "image": null,
        "callSign": "GHXX",
        "type": "Research Survey Vessel",
        "portOfRegistry": {
            "name": "Lowestoft",
            "portCode": "LOW",
            "countryCode": "GB",
            "searchString": "Lowestoft LOW GB"
        },
        "flag": "GB",
        "lengthOverall": 80.42,
        "beam": 14.51,
        "draught": 4.765,
        "grossTonnage": 2820,
        "deadWeightTonnage": 1598,
        "netTonnage": 846,
        "yearBuilt": 1987,
        "propellerCount": 2,
        "propulsionType": "PROPULSION_FIXED_PITCH",
        "propellerDirection": null,
        "propulsionPower": 5328,
        "propulsionPowerUnit": "HP",
        "rudderCount": null,
        "rudderType": null,
        "fwdAzimuthCount": null,
        "fwdAzimuthPower": null,
        "fwdAzimuthPowerUnit": "KW",
        "aftAzimuthCount": null,
        "aftAzimuthPower": null,
        "aftAzimuthPowerUnit": "KW",
        "fwdTunnelThrusterCount": 1,
        "fwdTunnelThrusterPower": 668,
        "fwdTunnelThrusterPowerUnit": "KW",
        "aftTunnelThrusterCount": null,
        "aftTunnelThrusterPower": null,
        "aftTunnelThrusterPowerUnit": "KW"
    },
    "nextPort": {
        "name": "Fort Frances",
        "countryCode": "CA"
    },
    "lastPort": {
        "name": "Dadeng I.",
        "countryCode": "CN"
    },
    "portOfLoading": {
        "name": "Annandale",
        "countryCode": "AU"
    },
    "portOfDischarge": {
        "name": "Gigaquit, Mindanao",
        "countryCode": "PH"
    },
    "agents": [
        {
            "contactId": "d3d0dd25-354e-4335-b46d-b94538f652e3",
            "actionId": "836e6d2c-02ad-4ea1-8683-22aeb71ee2cc",
            "time": "2023-01-01T12:29:59.000Z"
        }
    ],
    "sourceData": null,
    "createdAtDate": "2023-05-12",
    "archived": null,
    "arrivalTime": null,
    "departureTime": null,
    "updatedAt": "2023-05-12T15:54:18.238Z",
    "createdAt": "2023-05-12T15:53:54.563Z",
    "_version": 3,
    "_deleted": null,
    "_lastChangedAt": 1683906858254,
    "category": {
        "id": "75e75d42-98ce-4bd6-a496-d9c895453314",
        "name": "Cargo",
        "description": "Cargo",
        "type": "PORTCALL",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1631706435547,
        "createdAt": "2021-09-15T11:47:15.530Z",
        "updatedAt": "2021-09-15T11:47:15.530Z"
    },
    "requests": [],
    "vessel": {
        "id": "937a2e29-0747-4af7-821b-880dbf8286bf",
        "imo": "8511275",
        "mmsi": "232217000",
        "callSign": "GHXX",
        "vesselData": {
            "name": "OCEAN OBSERVER",
            "imo": "8511275",
            "mmsi": "232217000",
            "image": null,
            "callSign": "GHXX",
            "type": "Research Survey Vessel",
            "portOfRegistry": {
                "name": "Lowestoft",
                "portCode": "LOW",
                "countryCode": "GB",
                "searchString": "Lowestoft LOW GB"
            },
            "flag": "GB",
            "lengthOverall": 80.42,
            "beam": 14.51,
            "draught": 4.765,
            "grossTonnage": 2820,
            "deadWeightTonnage": 1598,
            "netTonnage": 846,
            "yearBuilt": 1987,
            "propellerCount": 2,
            "propulsionType": "PROPULSION_FIXED_PITCH",
            "propellerDirection": null,
            "propulsionPower": 5328,
            "propulsionPowerUnit": "HP",
            "rudderCount": null,
            "rudderType": null,
            "fwdAzimuthCount": null,
            "fwdAzimuthPower": null,
            "fwdAzimuthPowerUnit": "KW",
            "aftAzimuthCount": null,
            "aftAzimuthPower": null,
            "aftAzimuthPowerUnit": "KW",
            "fwdTunnelThrusterCount": 1,
            "fwdTunnelThrusterPower": 668,
            "fwdTunnelThrusterPowerUnit": "KW",
            "aftTunnelThrusterCount": null,
            "aftTunnelThrusterPower": null,
            "aftTunnelThrusterPowerUnit": "KW"
        },
        "updatedAt": "2020-10-23T08:01:25.213Z",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1500000000000,
        "createdAt": "2020-10-20T10:18:38.308Z",
        "vesselAgent": null
    },
    "cargos": [
        {
            "id": "32f4355b-fc19-4f13-91e8-31a0468d265a",
            "cargoPortCallId": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "portCallArchived": null,
            "initialAmount": null,
            "amount": null,
            "comments": null,
            "_version": 1,
            "_deleted": null,
            "_lastChangedAt": 1683906836687,
            "createdAt": "2023-05-12T15:53:56.685Z",
            "updatedAt": "2023-05-12T15:53:56.685Z",
            "type": {
                "id": "f5e942bc-eea2-44cc-8371-da874c093c48",
                "name": "Cars",
                "units": 100,
                "code": "52",
                "charge": 0.93,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2021-05-17T11:48:36.632Z",
                "updatedAt": "2021-05-17T11:48:36.632Z"
            }
        }
    ],
    "actions": [
        {
            "id": "09239c88-969f-4652-9a8a-357b7f977b25",
            "actionPortCallId": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "actionPortCallId_": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "portCallArchived": null,
            "state": "PLANNED",
            "timeRequested": "2023-05-07T11:30:00.000Z",
            "timePlanned": "2023-05-07T11:30:00.000Z",
            "timeEstimated": "2023-05-07T11:30:00.000Z",
            "timeActual": "2023-05-07T11:30:00.000Z",
            "timeEstimatedUpdatedAt": null,
            "remarks": null,
            "vesselMastersName": null,
            "vesselCrew": null,
            "vesselPassengers": null,
            "vesselForwardDraught": null,
            "vesselAftDraught": null,
            "vesselSailingDraught": null,
            "vesselAirDraught": null,
            "movementType": "DEPARTURE",
            "movementVesselReadyTime": null,
            "movementPilotBoardingTime": null,
            "movementPilots": [
                ""
            ],
            "movementLinesmen": [
                ""
            ],
            "movementMooringVessels": [
                ""
            ],
            "movementPropulsionState": null,
            "movementPropulsionRemarks": null,
            "movementRudderState": null,
            "movementRudderRemarks": null,
            "movementFwdAzimuthState": null,
            "movementFwdAzimuthRemarks": null,
            "movementAftAzimuthState": null,
            "movementAftAzimuthRemarks": null,
            "movementFwdTunnelThrusterState": null,
            "movementFwdTunnelThrusterRemarks": null,
            "movementAftTunnelThrusterState": null,
            "movementAftTunnelThrusterRemarks": null,
            "eventType": null,
            "todos": [
                {
                    "title": "Paperwork Received",
                    "description": "Paperwork received",
                    "status": "READY",
                    "todoType": "PAPERWORK_RECEIVED"
                },
                {
                    "title": "Email CERS",
                    "description": "Email CERS",
                    "status": "READY",
                    "todoType": "EMAIL_CERS"
                }
            ],
            "documents": [
                ""
            ],
            "chargeableItems": [],
            "auditMeta": {
                "comment": null,
                "userId": "e2e_admin",
                "source": "app"
            },
            "updatedAt": "2023-05-12T15:53:56.014Z",
            "createdAt": "2023-05-12T15:53:56.014Z",
            "_version": 1,
            "_deleted": null,
            "_lastChangedAt": 1683906836017,
            "actionAgent": null,
            "type": {
                "id": "0a803439-330c-4346-a174-f11f73c8b3e9",
                "name": "Movement",
                "lifecycle": true,
                "editable": false,
                "used": true,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2020-10-28T14:57:33.721Z",
                "updatedAt": "2020-10-28T14:57:33.721Z"
            },
            "movementLocation": {
                "id": "58346274-ee75-4703-80fa-f3be75623ea9",
                "type": "PORT",
                "name": "Canonmills Port Authority",
                "portUnlocode": "GBTEST",
                "allocatable": true,
                "dockable": false,
                "parentName": null,
                "dimensions": null,
                "shape": null,
                "coords": null,
                "path": "Canonmills Port Authority",
                "number": null,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2019-10-09T11:20:27.092Z",
                "updatedAt": "2019-10-09T11:20:27.092Z",
                "parent": null
            }
        },
        {
            "id": "35c8ad56-0685-4b2f-85e5-cb081d254f4e",
            "actionPortCallId": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "actionPortCallId_": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "portCallArchived": null,
            "state": "PLANNED",
            "timeRequested": "2023-01-01T12:30:00.000Z",
            "timePlanned": "2023-01-01T12:30:00.000Z",
            "timeEstimated":  "2023-01-01T12:30:00.000Z",
            "timeActual": "2023-01-01T12:30:00.000Z",
            "timeEstimatedUpdatedAt": null,
            "remarks": null,
            "vesselMastersName": null,
            "vesselCrew": null,
            "vesselPassengers": null,
            "vesselForwardDraught": null,
            "vesselAftDraught": null,
            "vesselSailingDraught": null,
            "vesselAirDraught": null,
            "movementType": "ARRIVAL",
            "movementVesselReadyTime": null,
            "movementPilotBoardingTime": null,
            "movementPilots": [
                ""
            ],
            "movementLinesmen": [
                ""
            ],
            "movementMooringVessels": [
                ""
            ],
            "movementPropulsionState": null,
            "movementPropulsionRemarks": null,
            "movementRudderState": null,
            "movementRudderRemarks": null,
            "movementFwdAzimuthState": null,
            "movementFwdAzimuthRemarks": null,
            "movementAftAzimuthState": null,
            "movementAftAzimuthRemarks": null,
            "movementFwdTunnelThrusterState": null,
            "movementFwdTunnelThrusterRemarks": null,
            "movementAftTunnelThrusterState": null,
            "movementAftTunnelThrusterRemarks": null,
            "eventType": null,
            "todos": [
                {
                    "title": "Paperwork Received",
                    "description": "Paperwork received",
                    "status": "READY",
                    "todoType": "PAPERWORK_RECEIVED"
                },
                {
                    "title": "Email CERS",
                    "description": "Email CERS",
                    "status": "READY",
                    "todoType": "EMAIL_CERS"
                }
            ],
            "documents": [
                ""
            ],
            "chargeableItems": [],
            "auditMeta": {
                "comment": null,
                "userId": "e2e_admin",
                "source": "app"
            },
            "updatedAt": "2023-05-12T15:53:55.074Z",
            "createdAt": "2023-05-12T15:53:55.074Z",
            "_version": 1,
            "_deleted": null,
            "_lastChangedAt": 1683906835077,
            "actionAgent": null,
            "type": {
                "id": "0a803439-330c-4346-a174-f11f73c8b3e9",
                "name": "Movement",
                "lifecycle": true,
                "editable": false,
                "used": true,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2020-10-28T14:57:33.721Z",
                "updatedAt": "2020-10-28T14:57:33.721Z"
            },
            "movementLocation": {
                "id": "58346274-ee75-4703-80fa-f3be75623ea9",
                "type": "PORT",
                "name": "Canonmills Port Authority",
                "portUnlocode": "GBTEST",
                "allocatable": true,
                "dockable": false,
                "parentName": null,
                "dimensions": null,
                "shape": null,
                "coords": null,
                "path": "Canonmills Port Authority",
                "number": null,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2019-10-09T11:20:27.092Z",
                "updatedAt": "2019-10-09T11:20:27.092Z",
                "parent": null
            }
        },
        {
            "id": "836e6d2c-02ad-4ea1-8683-22aeb71ee2cc",
            "actionPortCallId": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "actionPortCallId_": "0da7e9c1-6459-444c-bda7-a081cb952865",
            "portCallArchived": null,
            "state": "PLANNED",
            "timeRequested": "2023-01-01T12:29:59.000Z",
            "timePlanned": "2023-01-01T12:29:59.000Z",
            "timeEstimated": "2023-01-01T12:29:59.000Z",
            "timeActual": "2023-01-01T12:29:59.000Z",
            "timeEstimatedUpdatedAt": null,
            "remarks": null,
            "vesselMastersName": null,
            "vesselCrew": null,
            "vesselPassengers": null,
            "vesselForwardDraught": null,
            "vesselAftDraught": null,
            "vesselSailingDraught": null,
            "vesselAirDraught": null,
            "movementType": null,
            "movementVesselReadyTime": null,
            "movementPilotBoardingTime": null,
            "movementPilots": null,
            "movementLinesmen": null,
            "movementMooringVessels": null,
            "movementPropulsionState": null,
            "movementPropulsionRemarks": null,
            "movementRudderState": null,
            "movementRudderRemarks": null,
            "movementFwdAzimuthState": null,
            "movementFwdAzimuthRemarks": null,
            "movementAftAzimuthState": null,
            "movementAftAzimuthRemarks": null,
            "movementFwdTunnelThrusterState": null,
            "movementFwdTunnelThrusterRemarks": null,
            "movementAftTunnelThrusterState": null,
            "movementAftTunnelThrusterRemarks": null,
            "eventType": "AGENT_HANDOVER",
            "todos": null,
            "documents": null,
            "chargeableItems": null,
            "auditMeta": {
                "comment": null,
                "userId": "e2e_admin",
                "source": "app"
            },
            "updatedAt": "2023-05-12T15:53:56.203Z",
            "createdAt": "2023-05-12T15:53:56.203Z",
            "_version": 1,
            "_deleted": null,
            "_lastChangedAt": 1683906836206,
            "actionAgent": {
                "id": "d3d0dd25-354e-4335-b46d-b94538f652e3",
                "name": "Demo agent",
                "displayName": null,
                "reference": null,
                "address1": null,
                "address2": null,
                "town": null,
                "county": null,
                "postcode": null,
                "number": "1234567890",
                "fax": null,
                "email": null,
                "contacta_name": null,
                "contacta_detail": null,
                "contactb_name": null,
                "contactb_detail": null,
                "_version": 4,
                "_deleted": null,
                "_lastChangedAt": 1647344831232,
                "createdAt": "2021-12-03T11:22:06.834Z",
                "updatedAt": "2022-03-15T11:47:11.231Z",
                "type": {
                    "id": "422867f7-e7f0-4716-bc47-0ef6c6b4e73b",
                    "name": "Agent",
                    "_version": 1,
                    "_deleted": null,
                    "_lastChangedAt": 1500000000000,
                    "createdAt": "2019-10-10T13:49:53.958Z",
                    "updatedAt": "2019-10-10T13:49:53.958Z"
                }
            },
            "type": {
                "id": "ee3e3479-13ef-410e-a2f3-6ffed837c9f7",
                "name": "Event",
                "lifecycle": false,
                "editable": false,
                "used": true,
                "_version": 1,
                "_deleted": null,
                "_lastChangedAt": 1500000000000,
                "createdAt": "2020-10-28T14:57:33.822Z",
                "updatedAt": "2020-10-28T14:57:33.822Z"
            },
            "movementLocation": null
        }
    ]
}