import React, { useContext, useMemo } from 'react';
import { MenuItem, ListItemIcon, ListItemText, ListSubheader, Divider } from '@material-ui/core';
import { CloseCircleOutline, Check, DeleteOutline, InformationOutline, UndoVariant, PlaylistCheck, PlaylistRemove, PlaylistEdit } from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import { IN_PORT, PREARRIVAL, ARRIVAL, DEPARTURE, CANCELLED, } from '../../../constants/PortCallStatus';
import MoveDepart from '../../../icons/MoveDepart';
import MoveArrive from '../../../icons/MoveArrive';
import { NavigationContext } from '../../../contexts/navigation';
import { DatastoreStatusContext } from '../../../contexts/datastoreStatusContext';
import { UIContext } from '../../../contexts/ui';
import { DataStore } from 'aws-amplify';
import { getActionNext, getActionPrev, getNextActionableAction, getOutstandingTodos, getActionName, isAgentAssociatedWithPortCall, isDepartureOnly } from '../../../utils/getters';
import { generateTimePlannedChecks, CHECKS } from '../../../utils/generators';
import { rejectPendingRequestsForAction, rejectRequests, } from '../../../utils/requests';
import { Action, ActionMovementType, ActionState, Request, RequestType, RequestState, PortCallStatus } from '../../../models';
import { ActionTypeIds } from '../../../environment';
import { sortActions } from '../../../utils/sorters';
import { getAgentContact, buildRequestSource, getUserEmail, getUserName } from '../../../utils/agentPortal';
import { DataStoreContext } from '../../../contexts/dataStoreContext';
import useApprovalSetting from '../../../hooks/useApprovalSetting';
import useAutomationSetting from '../../../hooks/useAutomationSetting';
import { useTranslation } from 'react-i18next';
import useAuditMeta from '../../../hooks/useAuditMeta';
import { isVoyageMovement } from '../utils';
import { NonLifeCycleActionStates } from '../../../constants/StateCategories';
import RouteConstants from '../../../constants/RouteConstants';
import { buildMovementMenuItems } from './buildMenuItems';

const MovementMenu = ({
  archived,
  portCall,
  relevantAction,
  skipCheck,
  anchorEl,
  contexts,
  nextUndoableActions,
  handleUndoAction,
  handleClose,
  setOpenText,
  setConfirmProps,
  setTimeProps,
  setOpenTime,
  setOpenConfirm,
  setOpenUnArchive,
  updateActionStateAsTransaction,
  classes,
  unarchivePortCall,
  disabledCancel,
  disabledDelete
}) => {
  const { actions } = portCall;

  const history = useHistory();
  const auditMeta = useAuditMeta();
  const { t } = useTranslation();
  const [uiContext,] = useContext(UIContext);
  const [navigationContext,] = useContext(NavigationContext);
  const isSynced = useContext(DatastoreStatusContext);
  const isPortCallArchived = useMemo(() => (portCall && portCall.archived) || archived, [archived, portCall])
  const readOnly = uiContext.readOnly;
  const agentPortal = uiContext.agentPortal;
  const userContactId = uiContext.userContactId;
  const isVoyageAdmin = uiContext.isVoyageAdmin;
  
  // actionDisable include readOnly from permssions and sync status from datastore
  const actionDisable = readOnly || !isSynced;

  const agentPortCall = !agentPortal || (agentPortal && isAgentAssociatedWithPortCall(portCall, userContactId));

  const { appAutomationEnabled } = useAutomationSetting();
  const { appApprovalEnabled } = useApprovalSetting();
  const isApprover = uiContext.isApprover;
  const { actionTypes } = useContext(DataStoreContext);

  const cancelledPortCall = portCall.status === CANCELLED;

  // if action id in url go back to portcall
  const resetUrlToPortCall = () => history.location.pathname.indexOf(action.id) > 0 && history.push(`/port-call/${portCall.id}/`);

  // if request in url go back to action
  const resetUrlforAction = () => history.location.pathname.indexOf("request") > 0 && history.push(`/port-call/${portCall.id}/action/${action.id}`);

  const customActionTypes = useMemo(() => actionTypes.filter(at => at.id !== ActionTypeIds.MOVEMENT && at.id !== ActionTypeIds.EVENT), [actionTypes]);

  const handlePortCallArrival = () => {
    handleClose();
    const basicConfirmProps = {
      title: t('PortCallTimelineMenu.Labels.ArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.ArrivalDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => {
            rejectPendingRequestsForAction(portCall, action.id);
            resetUrlforAction();
            setOpenText(false);
          }
        });
      }
    }
    setConfirmProps(appAutomationEnabled ? { ...basicConfirmProps, warningMessage: t('PortCallTimelineMenu.Labels.AutomationArrivalWarning') } : basicConfirmProps);
    setOpenText(true);
  };

  const handleShiftArrival = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.ShiftArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.ShiftArrivalDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  const handlePortCallRecordArrival = () => {

    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.AFTER);
    handleClose();
    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.RecordArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.RecordArrivalDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

  const handleShiftRecordArrival = () => {
    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BOTH);
    handleClose();
    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.RecordShiftArrivalDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.RecordShiftArrivalDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

  const handlePortCallDeparture = () => {
    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BEFORE);

    handleClose();
    const basicTimeProps = {
      title: t('PortCallTimelineMenu.Labels.DepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.DepartureDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => {
            rejectPendingRequestsForAction(portCall, action.id);
            resetUrlforAction();
            setOpenTime(false);
          }
        });
      }
    }
    setTimeProps(appAutomationEnabled ? { ...basicTimeProps, warningMessage: t('PortCallTimelineMenu.Labels.AutomationDepartureWarning') } : basicTimeProps);
    setOpenTime(true);
  };

  const handleShiftDeparture = () => {

    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BOTH);

    handleClose();
    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.ShiftDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '',
      inputLabel: t('PortCallTimelineMenu.Labels.ShiftDepartureDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

  const handleDeleteShift = (action) => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.DeleteShiftDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.DeleteShiftDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      onConfirm: async (comment) => {
        const movements = actions.filter(a => (a.type.id === ActionTypeIds.MOVEMENT) && (!NonLifeCycleActionStates.includes(a.state))).sort(sortActions);
        const departure = action.movementType === ActionMovementType.SHIFT_DEPARTURE ? action : getActionPrev(movements, action);
        const arrival = action.movementType === ActionMovementType.SHIFT_ARRIVAL ? action : getActionNext(movements, action);
        const updatedDeparture = Action.copyOf(departure, updated => {
          updated.state = ActionState.DELETED
        });
        const updatedArrival = Action.copyOf(arrival, updated => {
          updated.state = ActionState.DELETED
        });
        await DataStore.save(updatedDeparture)
        await DataStore.save(updatedArrival);
        // grab prev and next movements and get locations up to date
        const prev = getActionPrev(movements, departure);
        const next = getActionNext(movements, arrival);
        await DataStore.save(Action.copyOf(await DataStore.query(Action, next.id), updated => {
          updated.movementLocation = prev.movementLocation;
        }));
        setOpenConfirm(false);
        resetUrlToPortCall();
      }
    });
    setOpenConfirm(true);
  };

  const handlePortCallRecordDeparture = () => {
    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BEFORE);

    handleClose();
    const basicProps = {
      title: t('PortCallTimelineMenu.Labels.RecordDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.RecordDepartureDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
    }
    const timeProps = {
      ...basicProps,
      inputLabel: t('PortCallTimelineMenu.Labels.DepartureDialogInput'),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => {
            rejectRequests(portCall.requests);
            setOpenTime(false);
            //fallback on dashboard / voyageTimeline 
            let nextView = navigationContext.lastView;
            if(navigationContext.currentView?.match(RouteConstants.VOYAGE_DETAIL_URL_PATTERN)){
              nextView = navigationContext.currentView;
            }
            history.push(nextView);
          }
        });
      }
    };
    const textProps = {
      ...basicProps,
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => {
            setOpenText(false);
            rejectRequests(portCall.requests);
             //fallback on dashboard / voyageTimeline 
            let nextView = navigationContext.lastView;
            if(navigationContext.currentView?.match(RouteConstants.VOYAGE_DETAIL_URL_PATTERN)){
              nextView = navigationContext.currentView;
            }
            history.push(nextView);
          }
        });
      }
    }
    if (action.state === ActionState.PLANNED) {
      setTimeProps(timeProps);
      setOpenTime(true);
    } else {
      setConfirmProps(textProps);
      setOpenText(true);
    };
  };

  const handleShiftRecordDeparture = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.RecordShiftDepartureDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.RecordShiftDepartureDialogMessage', { vesselName: portCall.vesselData.name, todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: comment,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  const handleActionCancel = () => {
    if (agentPortal) {
      return handleDepartureCancelRequest();
    }
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.ActionCancelDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action, false) }),
      message: t('PortCallTimelineMenu.Labels.ActionCancelDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action, false), todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comments) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.CANCELLED,
          comments: comments,
          auditMeta: auditMeta,
          onSuccess: () => {
            rejectPendingRequestsForAction(portCall, action.id);
            resetUrlforAction();
            setOpenText(false);
          }
        });
      }
    });
    setOpenText(true);
  };

  const handleDepartureCancelRequest = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.CancelDepartureRequestDialogTitle', { vesselName: portCall.vesselData.name }),
      message: t('PortCallTimelineMenu.Labels.CancelDepartureRequestDialogMessage', { vesselName: portCall.vesselData.name }),
      inputLabel: t('PortCallTimelineMenu.Labels.Remarks'),
      onConfirm: async (remarks) => {
        const agent = await getAgentContact(userContactId);
        const requestSource = await buildRequestSource(agent);
        await DataStore.save(new Request({
          portCall: portCall,
          requestPortCallId_: portCall.id,
          state: RequestState.REQUEST_STATE_PENDING,
          type: RequestType.REQUEST_TYPE_CANCEL_ACTION,
          source: requestSource,
          portCallData: {
            vesselName: portCall.vesselData.name,
            vesselImo: portCall.vesselData.imo,
            vesselMmsi: portCall.vesselData.mmsi,
            vesselCallsign: portCall.vesselData.callSign
          },
          actionData: [{
            actionId: action.id,
            actionTypeId: action.type.id,
            movementType: action.movementType,
            approved: RequestState.REQUEST_STATE_PENDING,
            locationId: action.movementLocation.id
          }],
          agent: agent,
          requesterUserName: await getUserName(),
          requesterEmail: await getUserEmail(),
          remark: remarks,
          auditMeta: { ...auditMeta, comment: remarks }
        }));
        setOpenText(false);
        // redirect when viewing port call
        if (history.location.pathname.startsWith('/port-call'))
          history.push(navigationContext.lastView);
      }
    });
    setOpenText(true);
  };

  const handleActionDelete = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.DeleteActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action, false) }),
      message: t('PortCallTimelineMenu.Labels.DeleteActionDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action), todos: outstandingTodos ? t('PortCallTimelineMenu.Labels.OutstandingTodos') : '' }),
      onConfirm: async () => {
        // FIXME write an update prior to deletion
        const updated = Action.copyOf(action, updated => {
          updated.state = ActionState.DELETED
        });
        await DataStore.save(updated)
        rejectPendingRequestsForAction(portCall, action.id);
        setOpenConfirm(false);
        resetUrlToPortCall();
      }
    });
    setOpenConfirm(true);
  }

  // Record the time of when the custom action is recorded
  // On confirm set the action to In Progress and with a timeActual Value
  const handleCustomActionStart = () => {
    handleClose();
    const checks = generateTimePlannedChecks(portCall.actions, action, CHECKS.BOTH);

    setTimeProps({
      title: t('PortCallTimelineMenu.Labels.StartCustomActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      inputLabel: t('PortCallTimelineMenu.Labels.StartCustomActionDialogInput', { actionName: getActionName(t, action) }),
      initialValue: action.timePlanned,
      checks,
      hasComment: true,
      onConfirm: async (value, comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.IN_PROGRESS,
          comment: comment,
          auditMeta: auditMeta,
          updates: { timeActual: value },
          onSuccess: () => setOpenTime(false)
        });
      }
    });
    setOpenTime(true);
  };

  // Complete the custom action
  // On confirm set the action to COMPLETED
  const handleCustomActionRecord = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.RecordCustomActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      message: t('PortCallTimelineMenu.Labels.RecordCustomActionDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      hasComment: true,
      onConfirm: async (comment) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.COMPLETED,
          comment: null,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  // Approve the Action
  // On confirm set the action to PLANNED and updated the metadata
  const handleApproveAction = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.ApproveActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      message: t('PortCallTimelineMenu.Labels.ApproveActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comments) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.PLANNED,
          comments: comments,
          auditMeta: auditMeta,
          updates: null,
          ignorePortStatus: action.movementType === ActionMovementType.DEPARTURE,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  // Reject the Action
  // On confirm set the action to APPROVAL_DECLINED
  const handleDeclineAction = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.DeclineActionDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      message: t('PortCallTimelineMenu.Labels.DeclineActionDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      inputLabel: t('Common.Labels.Comments'),
      onConfirm: async (comments) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.APPROVAL_DECLINED,
          comments: comments,
          auditMeta: auditMeta,
          updates: null,
          ignorePortStatus: true,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  //Mark for Approval 
  // On confirm set the action to APPROVAL_PENDING
  const handleMarkApproval = () => {
    handleClose();
    setConfirmProps({
      title: t('PortCallTimelineMenu.Labels.MarkApprovalDialogTitle', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      message: t('PortCallTimelineMenu.Labels.MarkApprovalDialogMessage', { vesselName: portCall.vesselData.name, actionName: getActionName(t, action) }),
      inputLabel: `Comments`,
      onConfirm: async (comments) => {
        await updateActionStateAsTransaction({
          action: action,
          state: ActionState.APPROVAL_PENDING,
          comments: comments,
          auditMeta: auditMeta,
          onSuccess: () => setOpenText(false)
        });
      }
    });
    setOpenText(true);
  };

  const action = useMemo(() => relevantAction || (nextUndoableActions.length && nextUndoableActions[0]), [relevantAction, nextUndoableActions]);

  // boolean for if there are any todos in Ready State
  const outstandingTodos = getOutstandingTodos(action);
  const canCancelOrDeleteMovement = !isVoyageMovement(action) || isVoyageAdmin;
  const isDepartureOnlyPortCall = isDepartureOnly(portCall.actions);
  const nextValidAction = useMemo(() => getNextActionableAction(actions), [actions]);

  const nonDockableLocationWarningMessage = action?.movementLocation?.dockable ? "" : t('PortCallMenuItem.Labels.NonDockableLocationWarning');
  const actionableId = (nextValidAction && nextValidAction.id) || null;
  const prevAction = useMemo(() => (action && actions && getActionPrev(actions, action)) || [], [action, actions]);

  const menu = useMemo(() => {
    return buildMovementMenuItems(
      portCall,
      action,
      prevAction,
      contexts,
      agentPortal,
      skipCheck,
      anchorEl,
      nextUndoableActions,
      archived,
      customActionTypes,
      appApprovalEnabled,
      isApprover,
      agentPortCall
    );
  }, [action, archived, agentPortCall, agentPortal, anchorEl, appApprovalEnabled, contexts, customActionTypes, isApprover, nextUndoableActions, portCall, prevAction, skipCheck]);

  const handleActionDetails = async () => {
    if (isPortCallArchived) {
      setOpenUnArchive({ open: true, title: t('ActionDetails.Labels.Loading'), loading: true, error: null })
      await unarchivePortCall({
        portCallId: portCall.id,
        onSuccess: () => {
          portCall?.id && action?.id && history.push(`/port-call/${portCall.id}/action/${action.id}`);
        },
        onError: () => setOpenUnArchive((prev) => ({
          ...(prev || []),
          open: true, loading: false, error: {
            message: t('ActionDetails.Errors.Loading'),
            errorCode: 400
          }
        })),
        onAppSyncError: (message) => setOpenUnArchive((prev) => ({
          ...(prev || []),
          open: true, loading: false, error: {
            message: message,
            errorCode: 400
          }
        })),
      });
    } else {
      history.push(`/port-call/${portCall.id}/action/${action.id}`);
    }
  };

  return (
    menu.movement ?
      <div>
        <ListSubheader>{t('PortCallMenuItem.Labels.Movement')}</ListSubheader>
        {menu.movement.canHandlePortCallArrival &&
          <MenuItem
            onClick={handlePortCallArrival}
            disabled={!action?.movementLocation?.dockable || actionDisable}
            id="StartArrivalMenuItem"
          >
            <ListItemIcon>
              <MoveArrive />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.StartArrival')}
              secondary={nonDockableLocationWarningMessage}
            />
          </MenuItem>
        }
        {menu.movement.canHandlePortCallRecordArrival &&
          <MenuItem
            onClick={handlePortCallRecordArrival}
            disabled={!action?.movementLocation?.dockable || actionDisable}
            id="RecordArrivalMenuItem"
          >
            <ListItemIcon>
              <MoveArrive />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.RecordArrival')}
              secondary={nonDockableLocationWarningMessage}
            />
          </MenuItem>
        }
        {menu.movement.canHandleShiftArrival &&
          <MenuItem
            onClick={handleShiftArrival}
            disabled={!action?.movementLocation?.dockable || action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <MoveArrive />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.StartShiftArrival')}
              secondary={nonDockableLocationWarningMessage}
            />
          </MenuItem>
        }
        {menu.movement.canHandleShiftRecordArrival &&
          <MenuItem
            onClick={handleShiftRecordArrival}
            disabled={!action?.movementLocation?.dockable || action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <MoveArrive />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.RecordShiftArrival')} />
          </MenuItem>
        }
        {menu.movement.canHandlePortCallDeparture &&
          <MenuItem
            onClick={handlePortCallDeparture}
            id="StartDepartureMenuItem"
            disabled={(!isDepartureOnlyPortCall && portCall.status !== IN_PORT) || action.id !== actionableId || actionDisable || !action?.movementLocation?.dockable}
          >
            <ListItemIcon>
              <MoveDepart />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.StartDeparture')}
              secondary={action.id === actionableId && nonDockableLocationWarningMessage}
            />
          </MenuItem>
        }
        {menu.movement.canHandlePortCallRecordDeparture &&
          <MenuItem
            onClick={handlePortCallRecordDeparture}
            id="RecordDepartureMenuItem"
            disabled={(!isDepartureOnlyPortCall && portCall.status !== IN_PORT && portCall.status !== DEPARTURE) || !action?.movementLocation?.dockable || action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.RecordDeparture')}
              secondary={action.id === actionableId && nonDockableLocationWarningMessage}
            />
          </MenuItem>
        }
        {menu.movement.canHandlePortCallDepartureCancel && !disabledCancel &&
          <MenuItem
            onClick={handleActionCancel}
            disabled={actionDisable || !canCancelOrDeleteMovement}
            id="CancelDepartureMenuItem"
          >
            <ListItemIcon>
              <CloseCircleOutline />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.CancelDeparture')}
              secondary={!canCancelOrDeleteMovement && t('PortCallMenuItem.Labels.NoVoyageAdminPermissionToCancel')}
            />
          </MenuItem>
        }
        {menu.movement.canHandlePortCallDepartureDelete && !disabledDelete &&
          <MenuItem
            onClick={handleActionDelete}
            disabled={actionDisable || !canCancelOrDeleteMovement}
            id="DeleteDepartureMenuItem"
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText
              primary={t('PortCallMenuItem.Labels.DeleteDeparture')}
              secondary={!canCancelOrDeleteMovement && t('PortCallMenuItem.Labels.NoVoyageAdminPermissionToDelete')}
            />
          </MenuItem>
        }

        {menu.movement.canHandleShiftDeparture &&
          <MenuItem
            onClick={handleShiftDeparture}
            disabled={portCall.status !== IN_PORT || action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <MoveDepart />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.StartDeparture')} />
          </MenuItem>
        }
        {menu.movement.canHandleShiftRecordDeparture &&
          <MenuItem
            onClick={handleShiftRecordDeparture}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.RecordShiftDeparture')} />
          </MenuItem>
        }
        {menu.movement.canHandleDeleteShift &&
          <MenuItem
            onClick={() => handleDeleteShift(action)}
            disabled={actionDisable}
            id="DeleteShiftMenuItem"
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.DeleteShift')} />
          </MenuItem>
        }
        {menu.movement.canUndoCompleted &&
          <MenuItem
            onClick={() => handleUndoAction(action)}
            disabled={actionDisable}
            id="UndoCompletedActionMenuItem"
          >
            <ListItemIcon>
              <UndoVariant />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.UndoCompleted')} />
          </MenuItem>
        }
        {menu.movement.canHandleCustomActionStart &&
          <MenuItem
            onClick={() => handleCustomActionStart(action)}
            disabled={action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.StartCustom')} />
          </MenuItem>
        }
        {menu.movement.canHandleCustomActionRecord &&
          <MenuItem
            onClick={() => handleCustomActionRecord(action)}
            disabled={action.id !== actionableId || actionDisable}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.RecordCustom')} />
          </MenuItem>
        }
        {menu.movement.canHandleCustomActionCancel &&
          <MenuItem
            onClick={() => handleActionCancel()}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <CloseCircleOutline />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.CancelCustom')} />
          </MenuItem>
        }
        {menu.movement.canHandleCustomActionDelete &&
          <MenuItem
            onClick={() => handleActionDelete()}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.DeleteCustom')} />
          </MenuItem>
        }
        {menu.movement.canUndoInProgress &&
          <MenuItem
            onClick={() => handleUndoAction(action)}
            disabled={actionDisable}
            id="UndoInProgressActionMenuItem"
          >
            <ListItemIcon>
              <UndoVariant />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.UndoInProgress')} />
          </MenuItem>
        }
        {menu.movement.canHandleApproveAction &&
          <MenuItem
            onClick={() => handleApproveAction(action)}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <PlaylistCheck />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.Approve')} />
          </MenuItem>
        }
        {menu.movement.canHandleDeclineAction &&
          <MenuItem
            onClick={() => handleDeclineAction(action)}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <PlaylistRemove />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.Decline')} />
          </MenuItem>
        }
        {menu.movement.canHandleMarkForApproval &&
          <MenuItem
            onClick={() => handleMarkApproval(action)}
            disabled={actionDisable}
          >
            <ListItemIcon>
              <PlaylistEdit />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.MarkForApproval')} />
          </MenuItem>
        }
        {contexts.includes('details') &&
          <MenuItem
            disabled={!agentPortCall || !portCall?.id || !action?.id}
            onClick={handleActionDetails}
            id={'PortCallShowDetailsMenuItem'}
          >
            <ListItemIcon>
              <InformationOutline />
            </ListItemIcon>
            <ListItemText primary={t('PortCallMenuItem.Labels.ShowDetails')} />
          </MenuItem>
        }
        {contexts.includes('portCall') && <Divider className={classes.menuDivider} />}
      </div> : <></>
  )
}
export default MovementMenu;