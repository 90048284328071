import React, { createContext, useReducer } from 'react';
import RouteConstants, { MOVEMENT_LIST_URL_PATTERN, VOYAGE_DETAIL_URL_PATTERN } from '../constants/RouteConstants';
const MAIN_PAGES = [RouteConstants.PORTCALL_LIST, RouteConstants.VESSEL_LIST, RouteConstants.VOYAGE_LIST, RouteConstants.VOYAGE_SCHEDULE];
/**
 *
 *  Navigation Context is used to supplement 
 *  the router based navigation with any extra 
 *  contextual information.
 * 
 *  defaultView : store default main page i.e dashboard, dashboard with filter setting
 *  lastViews : history of visited pages (out of sequence)
 *  lastView : last visit page 
 *  currentView : current view page
 *  
 */

export const navigationActionConstants = {
  SET_DEFAULT_VIEW: 'SET_DEFAULT_VIEW',
  SET_LAST_VIEWS: 'SET_LAST_VIEWS'
}

const initialView = window.location.pathname.match(MOVEMENT_LIST_URL_PATTERN) ? window.location.pathname : RouteConstants.DEFAULT;
const initialState = {
  // Set the default view if it contains 'lists' (user selected dashboard view preference), otherwise set to /
  defaultView: initialView,
  // last main view so user can be redirected back from places like editor that drilled down through actions where history.goBack() wouldn't work
  lastView: initialView,
  lastViews: [initialView],
  currentView: initialView
}

/**
 * Get the last view
 * @param {String} latestVisit 
 * @param {Array} lastViews 
 * @param {Array} resetPages 
 * @returns String
 */
const findValidLastView = (latestVisit, lastViews, resetPages) => {
  //look from the beginning of history up until before the latestVisit
  const views = lastViews.slice(0, latestVisit);
  for (let i = views.length - 1; i >= 0; i--) {
    const lastView = views[i];
    //check if the last view on the main page list or voyage details
    if (lastView && (lastView.match(VOYAGE_DETAIL_URL_PATTERN) || resetPages.includes(lastView))) {
      return lastView;
    }
  }
  return views[0];
}

const reducer = (state, action) => {
  switch (action.type) {
    case navigationActionConstants.SET_DEFAULT_VIEW:
      //reset the navigation when on the (movement list page)
      const view = action.payload.match(MOVEMENT_LIST_URL_PATTERN) ? action.payload : RouteConstants.DEFAULT;
      return {
        ...state,
        defaultView: view,
        lastView: view,
        lastViews: [view],
        currentView: view
      };
    case navigationActionConstants.SET_LAST_VIEWS:
      const resetPages = [...MAIN_PAGES, state.defaultView];

      const url = action.payload?.match(MOVEMENT_LIST_URL_PATTERN) ? state.defaultView : action.payload;
      const views = state.lastViews;
      const isReset = resetPages.includes(url);

      let lastViews, lastView;
      //check if a page on the main page, if true reset the page
      if (isReset) {
        lastViews = [state.defaultView, url];
        lastView = lastViews[0];
      } else {
        const isVisited = views.indexOf(url) === -1 ? false : true;
        const historyViews = !isVisited ? [...views, url] : views;
        const latestVisit = historyViews.indexOf(url);
        lastView = findValidLastView(latestVisit, historyViews, resetPages);
        lastViews = historyViews;
      }
      //console.log({ lastView, lastViews, currentView:url })
      return {
        ...state,
        lastView: lastView || state.defaultView || RouteConstants.DEFAULT,
        lastViews: lastViews,
        currentView: url
      };
    default:
      return state;
  }
}

export const NavigationContext = createContext();

export const NavigationContextProvider = ({ children }) => (
  <NavigationContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </NavigationContext.Provider>
);
