import { VoyageStatus } from '../../models';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { green, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.button,
    padding: '6px 16px',
    color: 'white'
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  green: {
    backgroundColor: green[500],
    color: 'white'
  },
  orange: {
    backgroundColor: orange[500],
    color: 'white'
  },
  grey: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.disabled,
  },
  rowView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '7rem',
    padding: '0.15rem 0.45rem',
    borderRadius: '0.9rem',
    color: 'white'
  },
}));

const VoyageStatusLabel = ({ voyageStatus, rowView=false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const voyageStatusLabel = {
    [VoyageStatus.PLANNED]: {
      title: t('VoyageTimeline.Labels.Planned'),
      className: classes.main
    },
    [VoyageStatus.IN_PROGRESS]: {
      title: t('VoyageTimeline.Labels.InProgress'),
      className: classes.orange
    },
    [VoyageStatus.INCOMPLETE]: {
      title: t('VoyageTimeline.Labels.Incomplete'),
      className: classes.orange
    },
    [VoyageStatus.COMPLETED]: {
      title: t('VoyageTimeline.Labels.Completed'),
      className: classes.green
    },
    [VoyageStatus.CANCELLED]: {
      title: t('VoyageTimeline.Labels.Cancelled'),
      className: classes.grey
    },
    [VoyageStatus.ABORTED]: {
      title: t('VoyageTimeline.Labels.Aborted'),
      className: classes.grey
    }
  }

  const label = voyageStatus !== VoyageStatus.DELETED && voyageStatusLabel[voyageStatus];
  return (
    <>
      {label?.title &&
        <Box className={`${classes.label} ${label?.className} ${rowView && classes.rowView}`}>
          <Typography id={'PortCallTimelineStatusLabel'}>{label?.title}</Typography>
        </Box>}
    </>
  )
};

export default VoyageStatusLabel; 