import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Magnify } from 'mdi-material-ui';
import PortListItem from './PortListItem';
import { portFilterOptions } from '../../utils/sorters';
import { portName } from '../../utils/utils';

/* Documentation

Description

Returns

Usage example where appropriate

*/

/* Further custom functions */

const PortSearchAutocomplete = ({
  id,
  disabled,
  options,
  value,
  label,
  handleChange
}) => {

  return (
    <Autocomplete
      id={id}
      disabled={disabled}
      autoHighlight
      options={options}
      getOptionLabel={(item) => portName(item)}
      getOptionSelected={(option, value) => (option?.countryCode === value?.countryCode) && (option?.portCode === value?.portCode)}
      onChange={(e, value) => { handleChange(e, value )} }
      filterOptions={portFilterOptions}
      value={value}
      renderOption={item => (
        <PortListItem port={item} />
      )}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          fullWidth
          margin="normal"
          inputProps={{
            ...props.inputProps,
            autoComplete: 'off',
          }}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <>
                {props.InputProps.endAdornment}
                <Magnify />
              </>
            ),
          }}
        />
      )}
    />
  )
}

PortSearchAutocomplete.defaultProps = {}

PortSearchAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.object,
  label: PropTypes.string,
  handleChange: PropTypes.func,
}

export default PortSearchAutocomplete;