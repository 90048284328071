
import { useContext, useMemo } from 'react'
import { getUnitsSetting } from '../utils/getters';
import { DataStoreContext } from '../contexts/dataStoreContext';

/**
 * Return a unit setting. If key is set the individual unit from the array of units is returned
 * @param {String} key Key into unit map
 * @returns {Object} Unit setting
 */
const useUnitSetting = (key) => {
  const { settings } = useContext(DataStoreContext);
  const unitSetting = useMemo(() => getUnitsSetting(settings), [settings]);
  if(unitSetting && key) return unitSetting[key];
  return unitSetting;
}

export default useUnitSetting;