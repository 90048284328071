import React, {
  createContext,
  useReducer,
} from 'react';

export const DataContextConstants = {
  SET_CREATE_PORTCALL_VESSEL: 'SET_CREATE_PORTCALL_VESSEL',
  SET_PENDING_CREATE_VESSEL: 'SET_PENDING_CREATE_VESSEL',
};

export const DataContextOriginConstants = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE'
}
const initialState = {
  createPortCallVessel: null,
  pendingCreateVessel: null,
  origin: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case DataContextConstants.SET_CREATE_PORTCALL_VESSEL:
      return { ...state, createPortCallVessel: action.payload, origin:action?.origin };
    case DataContextConstants.SET_PENDING_CREATE_VESSEL:
      return { ...state, pendingCreateVessel: action.payload, origin:action?.origin  };
    default:
      return state;
  }
};

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => (
  <DataContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataContext.Provider>
);
