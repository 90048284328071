import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Box, CardContent, Link, Button, Grid, IconButton } from '@material-ui/core';
import { Close, } from 'mdi-material-ui';

import { makeStyles } from '@material-ui/styles';
import CargoInstanceArrayForm from './CargoInstanceArrayForm';
import { useHistory } from "react-router-dom";
import { NavigationContext } from '../../contexts/navigation';
import ContactForm from './ContactForm';
import VesselSearchForm from '../Vessel/VesselSearchForm';
import { PortCall, PortCallStatus, Action, ActionMovementType, VesselData, TemplateSubType, PortCallOrigin } from '../../models';
import LocationTimeline from '../Location/LocationTimeline';
import LocationAutocomplete from '../Location/LocationAutocomplete';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import DocumentEdit from '../Documents/DocumentEdit';
import StringKeyboardDateTimePicker from '../Common/StringKeyboardDateTimePicker';
import { add } from 'date-fns';
import { ActionTypeIds, ContactTypeIds } from '../../environment';
import { DataContext, DataContextConstants, DataContextOriginConstants } from '../../contexts/dataContext';
import { createPortCall } from './create';
import { DataStoreContext } from '../../contexts/dataStoreContext';
import { UIContext } from '../../contexts/ui';
import { generateActionFromTemplate } from '../../utils/templateUtils';
import { isSameDateHourMinute } from '../../utils/generators';
import useInitialActionState from '../../hooks/useInitialActionState';
import useAuditMeta from '../../hooks/useAuditMeta';
import useLastPortCallByVesselId from '../../hooks/useLastPortCallByVesselId';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import useFeatureSetting from '../../hooks/useFeatureSetting';
import FeatureFlags from "../../constants/FeatureFlags";
import useTariffBooks from '../../hooks/useTariffBooks';

const PAGE_MAIN = 0;
const PAGE_ACTIONAGENT = 2;

const useStyles = makeStyles(() => ({
  root: {
  },
  header: {
    height: '12rem',
    // minHeight: 'min-content'
    flexBasis: 'auto',
    flexShrink: 0
  },
  vesselFieldLabel: {
    paddingRight: '2rem',
    textAlign: 'right',
    fontWeight: '300',
  },
  editorFieldUnits: {
    paddingLeft: '0.125rem',
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    minHeight: 'min-content'
  },
  footer: {
    height: '8rem',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

const QuickPortCall = () => {
  const { t } = useTranslation()
  const classes = useStyles();
  const [page, setPage] = useState(PAGE_MAIN);
  const history = useHistory();
  const [navigationContext,] = useContext(NavigationContext);
  const [dataContext, dispatchDataContext] = useContext(DataContext);
  const dataStoreContext = useContext(DataStoreContext);
  const [uiContext,] = useContext(UIContext);
  const { agentPortal } = uiContext;
  const { getInitialActionState } = useInitialActionState();
  const auditMeta = useAuditMeta();
  const { tariffBooks } = useTariffBooks();
  const isTariffBookEnabled = useFeatureSetting(FeatureFlags.TARIFF_BOOK);

  const [portCall, setPortCall] = useState(() => {
    const actions = [];
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_ARRIVAL);
    if (template) {
      actions.push(Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(), isTariffBookEnabled), updated => {
        updated.timePlanned = new Date().toISOString();
        updated.auditMeta = auditMeta;
      }));
    } else {
      actions.push(new Action({
        state: getInitialActionState(),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.ARRIVAL,
        timePlanned: new Date().toISOString(),
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      }));
    }
    return new PortCall({
      status: PortCallStatus.PREARRIVAL,
      portCallTags: [''],
      documents: [''],
      vesselData: new VesselData({ name: '' }),
      actions,
      cargos: [],
      agents: [],
      origin: PortCallOrigin.PORT_CONTROLLER
    });
  });

  const lastPortCall = useLastPortCallByVesselId(portCall?.vessel?.id);
  useEffect(() => {
    console.log(portCall, lastPortCall);
    if (!portCall || !lastPortCall) return;
    setPortCall(prev => {
      if (!prev) return prev;
      let newItem = prev;
      const filteredLastPortCallTags = lastPortCall?.portCallTags?.filter(t => t)
      if (lastPortCall.portCallTags && JSON.stringify(filteredLastPortCallTags) !== JSON.stringify(prev.portCallTags)) {
        newItem = PortCall.copyOf(newItem, updated => {
          updated.portCallTags = filteredLastPortCallTags;
        });
      }
      if (lastPortCall.category && (!prev.category || prev.category.id !== lastPortCall.category.id)) {
        newItem = PortCall.copyOf(newItem, updated => {
          updated.category = lastPortCall.category;
          updated.portCallCategoryId_ = lastPortCall.category.id;
        });
      }
      return newItem;
    })
  }, [portCall, setPortCall, lastPortCall]);

  const vessel = useMemo(() => portCall && portCall.vessel || null, [portCall]);

  const setVesselData = useCallback(value => setPortCall(PortCall.copyOf(portCall, updated => {
    updated.vesselData = value
  })), [portCall, setPortCall]);

  const setVessel = useCallback((value) => {
    dispatchDataContext({ type: DataContextConstants.SET_CREATE_PORTCALL_VESSEL, payload: value, origin: DataContextOriginConstants.CREATE });
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.vessel = value;
      updated.portCallVesselId_ = value.id;
      updated.vesselData = value.vesselData;
      for (let action of updated.actions) {
        action.state = getInitialActionState(value.id)
      }
    }));
  }, [portCall, setPortCall, dispatchDataContext, getInitialActionState]);

  const clearVessel = useCallback(() => {
    dispatchDataContext({ type: DataContextConstants.SET_CREATE_PORTCALL_VESSEL, payload: null });
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.vessel = null;
      updated.vesselData = new VesselData({ name: '' });
    }));
  }, [portCall, setPortCall, dispatchDataContext]);

  const [isUnknownVessel, setIsUnknownVessel] = useState(false);

  // may as well handle this separately as its unused in LocationTimeline
  const [cargos, setCargos] = useState([]);

  const arrival = useMemo(() => portCall?.actions?.find(a => a.movementType === ActionMovementType.ARRIVAL), [portCall]);
  const setArrival = useCallback((value) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [
        ...updated.actions.filter(a => a.movementType !== ActionMovementType.ARRIVAL),
        value
      ].filter(a => a);
    }));
  }, [portCall, setPortCall]);
  const arrivalError = useMemo(() => !arrival.timePlanned, [arrival]);

  const departure = useMemo(() => portCall.actions.find(a => a.movementType === ActionMovementType.DEPARTURE), [portCall]);
  const setDeparture = useCallback((value) => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      updated.actions = [
        ...updated.actions.filter(a => a.movementType !== ActionMovementType.DEPARTURE),
        value
      ].filter(a => a);
    }));
  }, [portCall, setPortCall]);

  const departureError = useMemo(() => departure &&
    ((!departure.timePlanned && t('PortCall.Errors.InvalidDateFormat')) ||
      (departure.timePlanned <= arrival.timePlanned || (arrival.timePlanned && isSameDateHourMinute(departure.timePlanned, arrival.timePlanned))) && t('PortCall.Errors.DepartureAfterArrival')),
    [departure, arrival]);

  const addDeparture = useCallback((value) => {
    const timePlanned = add(new Date(arrival.timePlanned), { days: 1 }).toISOString();
    const template = dataStoreContext.templates.find(t => t.typeId === ActionTypeIds.MOVEMENT && t.subType === TemplateSubType.ACTION_DEPARTURE);
    if (template) {
      setDeparture(Action.copyOf(generateActionFromTemplate(template, dataStoreContext, getInitialActionState(portCall?.vessel?.id), isTariffBookEnabled), updated => {
        updated.timePlanned = timePlanned;
        updated.auditMeta = auditMeta;
      }));
    } else {
      setDeparture(new Action({
        state: getInitialActionState(portCall?.vessel?.id),
        type: { id: ActionTypeIds.MOVEMENT },
        movementType: ActionMovementType.DEPARTURE,
        timePlanned,
        documents: [''],
        movementPilots: [''],
        movementLinesmen: [''],
        movementMooringVessels: [''],
        auditMeta: auditMeta
      }));
    }
  }, [setDeparture, arrival, auditMeta, dataStoreContext, getInitialActionState, portCall]);
  const clearDeparture = useCallback(() => { setDeparture(null) }, [setDeparture]);

  const location = useMemo(() => arrival && arrival.movementLocation || null, [arrival]);
  const setLocation = useCallback((value) => {
    setArrival(Action.copyOf(arrival, updated => { updated.movementLocation = value; }));
  }, [arrival, setArrival]);

  const [agent, setAgent] = useState(null);

  const handleCreatePortCall = async (moreOptions) => {
    dispatchDataContext({ type: DataContextConstants.SET_CREATE_PORTCALL_VESSEL, payload: null });

    await createPortCall(portCall, location, arrival, departure, agent, cargos, auditMeta, isTariffBookEnabled, dataStoreContext, tariffBooks);

    if (moreOptions) {
      history.push(`/port-call/${portCall.id}/`);
    } else {
      history.push(navigationContext.defaultView);
    }
  };

  const disabled = useMemo(() =>
    (isUnknownVessel ? Boolean(!portCall.vesselData.name) : Boolean(!vessel)) ||
    (cargos.some(c => !c.type)) ||
    Boolean(arrivalError) ||
    Boolean(departureError),
    [isUnknownVessel, vessel, portCall.vesselData, arrivalError, departureError, cargos]);

  useEffect(() => {
    //only selected vessel when create new vessel in quickPortcall
    dataContext.createPortCallVessel && dataContext.origin !== DataContextOriginConstants.UPDATE && (!vessel || vessel.id !== dataContext.createPortCallVessel.id) && setVessel(dataContext.createPortCallVessel);
  }, [dataContext, vessel, setVessel]);

  useEffect(() => {
    if (dataContext.pendingCreateVessel) {
      //get origin where the vessel is set by create or update
      const origin = dataContext.origin;
      dispatchDataContext({ type: DataContextConstants.SET_PENDING_CREATE_VESSEL, payload: null, origin: DataContextOriginConstants.CREATE });
      dispatchDataContext({ type: DataContextConstants.SET_CREATE_PORTCALL_VESSEL, payload: dataContext.pendingCreateVessel, origin });
    }
  }, [dataContext, dispatchDataContext]);

  useEffect(() => {
    return () => {
      // Clear vessel on unmount
      dispatchDataContext({ type: DataContextConstants.SET_CREATE_PORTCALL_VESSEL, payload: null, origin: DataContextOriginConstants.CREATE });
    };
  }, [dispatchDataContext]);

  useEffect(() => {
    setPortCall(PortCall.copyOf(portCall, updated => {
      for (let action of updated.actions) {
        action.state = getInitialActionState(updated?.vessel?.id)
      }
    }));
  }, [getInitialActionState]);

  const handleUploadedDocuments = (value) => {
    setArrival(Action.copyOf(arrival, updated => { updated.documents = value; }));
  }

  if (page === PAGE_ACTIONAGENT) return (
    <ContactForm
      contact={agent}
      contactType={dataStoreContext.contactTypes.find(ct => ct.id === ContactTypeIds.AGENT)}
      onChange={(value) => setAgent(value)}
      showTitle
      onBack={() => setPage(PAGE_MAIN)}
    />
  );

  return (
    <Box display="flex" overflow="hidden auto" width="100%" id={'QuickPortCall'}>
      <Grid container wrap="nowrap" direction="column" className={classes.root}>

        <Grid item container justifyContent="center" alignItems="center" className={classes.header}>
          <Typography variant="h2">{t('PortCall.Labels.NewPortCallTitle')}</Typography>
          <IconButton onClick={() => { history.push(navigationContext.defaultView); }} style={{ marginLeft: -59, position: 'relative', left: 96 }}><Close fontSize="large" /></IconButton>
        </Grid>

        <Grid item className={classes.content} container justifyContent="center">

          <Box width="100%">
            <CardContent style={{ maxWidth: '60rem', margin: '0 auto', paddingTop: 0 }}>
              <VesselSearchForm
                vessel={vessel}
                setVessel={setVessel}
                clearVessel={clearVessel}
                vesselData={portCall.vesselData}
                setVesselData={setVesselData}
                isUnknownVessel={isUnknownVessel}
                setIsUnknownVessel={setIsUnknownVessel}
                certificateFrom={arrival.timePlanned}
                certificateTo={departure?.timePlanned || new Date().toISOString()}
                allowCreateVessel
              />
            </CardContent>
            <CardContent style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>

              <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Typography variant="h5">{t('PortCall.Labels.Schedule')}</Typography>
                {!agent && <Link onClick={() => setPage(PAGE_ACTIONAGENT)} id="NavCreateAgentButton"><Typography>{t('Agent.Buttons.CreateAgent')}</Typography></Link>}
              </Box>

              <Box display="flex" height="fit-content" >
                <StringKeyboardDateTimePicker
                  warningPastDate={true}
                  id="ArrivalTimePlannedInput"
                  label={t('PortCall.Labels.PlannedTimeOfArrival')}
                  style={{ width: '100%', marginTop: '0.25rem', marginBottom: '1rem' }}
                  value={arrival.timePlanned}
                  onChange={value => setArrival(Action.copyOf(arrival, updated => { updated.timePlanned = value }))}
                  okLabel={t('Common.Buttons.Confirm')}
                />

                <LocationAutocomplete
                  id="ArrivalLocationInput"
                  style={{ width: '100%', marginLeft: '1rem', marginRight: '1rem' }}
                  value={location}
                  onChange={(value) => setLocation(value)}
                  label={t('PortCall.Labels.ArrivalLocation')}
                  helperText={(location?.dockable === false) ? t('ActionScheduleEdit.Labels.NotBerthableLocation') : ""}
                />

                <ContactAutocomplete
                  id="CharteredAgentInput"
                  style={{ width: '100%' }}
                  value={agent}
                  onChange={(value) => setAgent(value)}
                  label={t('Agent.Labels.CharterAgent')}
                  filter={c => c.type?.id === ContactTypeIds.AGENT}
                />
              </Box>

              <Box id='ArrivalAttachmentsContainer' style={{ paddingTop: 0, paddingBottom: '1rem', maxWidth: '60rem', margin: '0 auto' }}>
                <DocumentEdit
                  filePath={`portcalls/${portCall.id}/actions/${arrival.id}/`}
                  portCallId={portCall.id}
                  objId={arrival.id}
                  objDocuments={arrival?.documents?.filter(d => d) ?? []}
                  onChange={handleUploadedDocuments}
                  saveDisabled
                  disableDownload
                  hideEmptyMessage
                />
              </Box>

              <Box display="flex" height="5rem" alignItems="center">
                {departure ?
                  <>
                    <StringKeyboardDateTimePicker
                      width='calc(33.333% - 0.666rem)'
                      warningPastDate={true}
                      id="DepartureTimePlannedInput"
                      label={t('PortCall.Labels.PlannedTimeOfDeparture')}
                      style={{ width: '100%', marginTop: '1.25rem', marginBottom: '1rem' }}
                      value={departure.timePlanned}
                      onChange={value => setDeparture(Action.copyOf(departure, updated => { updated.timePlanned = value }))}
                      error={Boolean(departureError)}
                      helperText={departureError}
                      okLabel={t('Common.Buttons.Confirm')}
                    />
                    <Grid item style={{ margin: '.25rem 0 1rem 1rem' }}>
                      <IconButton onClick={clearDeparture}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </>
                  :
                  <Button
                    id="AddDepartureButton"
                    color="primary"
                    variant="outlined"
                    onClick={addDeparture}
                    disabled={arrivalError}
                  ><Typography>{t('PortCall.Buttons.AddDeparture')}</Typography>
                  </Button>
                }
              </Box>
            </CardContent>

            {!agentPortal &&
              <div style={{ height: '20rem', margin: '-1rem 2rem 2rem' }}>
                <LocationTimeline customPortCall={portCall} />
              </div>
            }

            <CardContent id='CargoContainer' style={{ paddingTop: 0, maxWidth: '60rem', margin: '0 auto' }}>
              <Typography variant="h5">{t('Cargo.Labels.Cargo')}</Typography>
              <CargoInstanceArrayForm cargos={cargos} setCargos={setCargos} />
            </CardContent>

          </Box>
        </Grid>

        <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.footer}>
          <Button
            color="primary"
            size="large"
            disabled={disabled}
            onClick={() => handleCreatePortCall(true)}
            id="CreatePortCallMoreOptionsButton"
          >
            <Typography>{t('PortCall.Buttons.MoreOptions')}</Typography>
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
            style={{ margin: '1rem' }}
            onClick={() => handleCreatePortCall()}
            id="CreatePortCallButton"
          >
            {t('Common.Buttons.Create')}
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
};

export default QuickPortCall;