import base from './base';
import * as colors from '@material-ui/core/colors';

const primaryColor = { main: 'rgb(000,150,214)' };
const secondaryColor = colors.blue;

const topNavBgColor = 'rgb(000,150,214)';
const subNavBgColor = 'rgba(000,150,214, .5)';
const topNavContrastColor = '#fff';
const subNavContrastColor = '#333';


export default {
  ...base,
  palette: {
    ...base.palette,
    topNavBgColor,
    subNavBgColor,
    topNavContrastColor,
    subNavContrastColor,
    primary: primaryColor,
    secondary: secondaryColor,
  },
  // overrides: {
  //   ...base.overrides,
  //   MuiCardHeader: {
  //     ...base.overrides.MuiCardHeader,
  //     root: {
  //       ...base.overrides.MuiCardHeader.root,
  //       backgroundColor: secondaryColor[100],
  //     }
  //   }
  // }
};
