import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tooltip,
  ListItemIcon
} from '@material-ui/core';
import {
  Attachment,
  Alert
} from 'mdi-material-ui';

import ActionStateAndTimeTooltip from '../../components/Tooltip/ActionStateAndTimeTooltip';
import PopoverTooltip from '../../components/Tooltip/PopoverTooltip';
import ActionState from './ActionState';
import { ActionState as ActionStates } from "../../models";
import useChargeableItemValidation from '../../hooks/useChargeableItemValidation';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import InvalidTariffWarning from '../Chargeables/InvalidTariffWarning';

/* Make styles if needed */
const useStyles = makeStyles(theme => ({
  cancelledStyle: {
    textDecorationLine: 'line-through',
    color: '#aaaaaa'
  },
  icons: {
    marginRight: "2rem"
  },
  attachment: {
    transform: "rotate(-90deg)",
  },
}));

const TimelineCustomAction = ({
  item,
  item: {
    state,
    remarks,
    documents,
    type: {
      name,
    }
  }
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const strikeClassName = state === ActionStates.CANCELLED ? classes.cancelledStyle : '';
  const { validateActionChargeableItem } = useChargeableItemValidation();
  const validChargeables = validateActionChargeableItem(item);

  const getTooltip = (item) => {
    if (validChargeables.error && item?.type?.deleted) {
      return <span>
        <p>1. {t("AdminActionType.Labels.DeletedHelp")}</p>
        <p>2. {t(validChargeables.errorMessageT)}</p>
      </span>
    } else if (validChargeables.error) {
      return t(validChargeables.errorMessageT)
    } else if (item?.type?.deleted) {
      return t("AdminActionType.Labels.DeletedHelp")
    }
  }

  return (
    <>
      <PopoverTooltip tooltip={<ActionStateAndTimeTooltip action={item} />} >
        {/* Icon */}
        <Box display="flex" alignItems="center">
          <ListItemIcon>
            <ActionState action={item} />
          </ListItemIcon>
          <Box flexGrow="1">
            {/* Title */}
            <Typography
              variant="h6"
              noWrap
              className={strikeClassName}
            >
              {name}
            </Typography>
            {/* Remarks */}
            {
              remarks &&
              <Typography
                component="p"
                variant="body1"
                noWrap
              >
                {remarks}
              </Typography>
            }
            {/* Attachment - if there are any */}
          </Box>
          <Box
            className={classes.icons}
            style={{ marginRight: '1.5em', display: 'flex' }}
          >
            {(validChargeables?.error > 0 || item?.type?.deleted) &&
              <Box 
              style={{ marginLeft: '0.5em' }}
              id="InvalidTariffCustomAction">
                <InvalidTariffWarning
                  tooltip={getTooltip(item)}
                />
              </Box>}
          </Box>
          <Box
            flexGrow="0"
            marginLeft="-0.3rem"
            paddingRight="2rem"
          >
            {documents && documents.filter(d => d).length > 0 &&
              <Attachment
                className={classes.attachment}
                style={{
                  transform: "rotate(-90deg)",
                  marginRight: "0.5em",
                }}
              />
            }
          </Box>
        </Box>
      </PopoverTooltip>
    </>
  );
}

TimelineCustomAction.propTypes = {
  item: PropTypes.object.isRequired
}

export default TimelineCustomAction;