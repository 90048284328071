import React, {
  createContext,
  useReducer,
} from 'react';
import { Storage } from 'aws-amplify'; 
import { errorLabels } from "../constants/Image";

//Image context is used to store the image file
//that will be used for the creation of the Vessel.

const { 
  UPLOAD: UploadLabel,
  DELETE: DeleteLabel,
} = errorLabels;

export const imageFileActionConstants = {
  UPLOAD: "UPLOAD",
  DELETE: "DELETE",
  STORE: "STORE",
  RESET: "RESET",
  ERROR: "ERROR",
}

const initialState = {file: null, name: null};

const reducer = (state,action) => {
  switch(action.type) {
    case imageFileActionConstants.UPLOAD:
      if(!state.name || !state.file) break;
      Storage.put(state.name, state.file)
        .then (result => action.onUpload())
        .catch(err => action.onError({error: err, process: UploadLabel}));
      break;
    case imageFileActionConstants.DELETE:
      if(!state.name) break;
      Storage.remove(state.name)
        .then(result=> action.onUpload())
        .catch(err => action.onError({error: err, process: DeleteLabel}));
      break;
    case imageFileActionConstants.STORE:
      return {file: action.payload.file, name: action.payload.name, type: action.payload.type}
    case imageFileActionConstants.RESET:
      return initialState;
    case imageFileActionConstants.ERROR:
      return {error: action.payload.error, process: action.payload.process};
    default: 
      return state;
  }
}

export const ImageFileContext = createContext();

export const ImageFileContextProvider = ({children}) => {
  return ( 
  <ImageFileContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ImageFileContext.Provider>
)};