
import { useContext, useMemo, useState, useEffect, useCallback } from 'react'
import { getPortCallApprovalSetting } from '../utils/getters';
import { DataStore } from 'aws-amplify';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { Setting } from '../models';
import SettingConstants from '../constants/SettingConstants';

/** 
 * This hooks returns the approval setting for the application. This includes 
 * a flag to detect if the approval feature is enabled, a list of vessels exempt 
 * from approval, and a utility function to update the current approval settings.
 * returns {
 *  appApprovalEnabled, 
 *  approvalExceptionList,
 *  updateApprovalSetting
 * }
*/
const useApprovalSetting = () => {
  const { settings } = useContext(DataStoreContext);

  const approvalSetting = useMemo(() => getPortCallApprovalSetting(settings), [settings]);
  const [appApprovalEnabled, setAppApprovalEnabled] = useState(false);
  const [approvalExceptionList, setApprovalExceptionList] = useState([]);

  useEffect(() => {
    const settingValue = approvalSetting ? JSON.parse(approvalSetting.value) : null;
    setAppApprovalEnabled(settingValue?.enabled ?? false); 
    setApprovalExceptionList(settingValue?.approvalExceptionList ?? [])
  }, [approvalSetting])

  const updateApprovalSetting = useCallback((enabled, exceptionList) => {
    const approvalSettingValue = JSON.stringify({
      enabled,
      approvalExceptionList: exceptionList
    })
    DataStore.save(approvalSetting
      ? Setting.copyOf(approvalSetting, updated => { updated.value = approvalSettingValue })
      : new Setting({ name: SettingConstants.APP_PORTCALL_APPROVAL, value: approvalSettingValue })
    );
  }, [approvalSetting])

  return {
    appApprovalEnabled, 
    approvalExceptionList,
    updateApprovalSetting
  }
}

export default useApprovalSetting