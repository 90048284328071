import { ActionMovementType } from '../models';

export default {
  ARRIVAL: 'Arrival',
  DEPARTURE: 'Departure',
  SHIFT_ARRIVAL: 'Shift arrival',
  SHIFT_DEPARTURE: 'Shift departure'
};

export const ActionMovementTypeLabelKeys = {
  [ActionMovementType.ARRIVAL]: 'ActionMovementType.Labels.Arrival',
  [ActionMovementType.DEPARTURE]: 'ActionMovementType.Labels.Departure',
  [ActionMovementType.SHIFT_ARRIVAL]: 'ActionMovementType.Labels.ShiftArrival',
  [ActionMovementType.SHIFT_DEPARTURE]: 'ActionMovementType.Labels.ShiftDeparture'
};