import React, {useState, useEffect} from 'react';
import { Typography, makeStyles, Box, Chip} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'
import { ActionStateLabelKeys } from '../../constants/ActionStateLabel';
import { format } from 'date-fns';
import { ArrowRight } from 'mdi-material-ui';
import { API, graphqlOperation } from 'aws-amplify';
import { getAuditLogEntryByObjectId } from '../../graphql/queries';
import { DateFnsLanguageMap } from '../../translations';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  state: {
    display: 'flex',
    justifyContent: "center",
    paddingBottom: "0.5rem"
  },
  user: {
    display: 'flex',
    flex: "row",
    justifyContent: "flex-end",
  },
  comment: {
    fontStyle: 'italic'
  },
  commentContainer: {
    maxWidth: '250px'
  },
  tooltipContainer: {
    padding: '1rem'
  }
}));

const PreviousStateTooltip = ({ action }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // State to store the audit log details
  const [auditLog, setAuditLog] = useState();
  // Boolean state for whether or not to show any components
  const [showAuditLog, setShowAuditLog] = useState(true);
  
  // useEffect to retrieve the most recent audit log for the action
  // If no audit log then setShowAuditLog to false
  // if there is an audit log setAuditLog to most recent one
  useEffect(() => {
    const promise = API.graphql(graphqlOperation(getAuditLogEntryByObjectId, {
          objectId: action.id,
          sortDirection: 'DESC',
          limit: 4,
          filter: {
            type: {eq: "AUDIT_ACTION_STATE"}
          }
        }));
    const update = async () => {
      if(action && action.id) {
        try{
          const result = await promise;
          if(result && result.data && result.data.getAuditLogEntryByObjectId.items.length > 0 ) {
           setAuditLog(result.data.getAuditLogEntryByObjectId.items[0]);
          } else if(result && result.data && result.data.getAuditLogEntryByObjectId.items.length === 0) {
           setShowAuditLog(false);
          }
        }catch(error){
          console.log('Cancel Query getAuditLogEntryByObjectId',error);
        }
      }
    };
    update();
    return () => {
      API.cancel(promise);
    }
  }, [action.id, action.state])

  // Parse the oldState and newState
  const oldState = auditLog?.old ? JSON.parse(auditLog?.old) : "";
  const newState = auditLog?.new ? JSON.parse(auditLog?.new) : "";
  let oldStateLabel;
  let newStateLabel;

  // Render the correct label depending on the state
  if(oldState && newState) {
    oldStateLabel = t(ActionStateLabelKeys[oldState.state]);
     newStateLabel = t(ActionStateLabelKeys[newState.state]);
  } else if(newState) {
     newStateLabel = t(ActionStateLabelKeys[newState.state]);
     oldStateLabel = t('ActionState.Labels.Created');
  }
  return showAuditLog ? (
    <Box className={classes.tooltipContainer}>
      {/* Old State -> New State */}
      <Box className={classes.state}>
      {
        oldStateLabel && newStateLabel ? ( 
        <>   
          <Chip
            size="small"
            label={oldStateLabel}
          />
          <ArrowRight />
          <Chip
            size="small"
            color="primary"
            label={newStateLabel}
          />
        </>
        ) : <Skeleton width={"100%"}/>
      }
      </Box>
      {/* Comment */}
      <Box className={classes.commentContainer}>
       {auditLog ? auditLog?.comment ? 
        ( 
          <Typography className={classes.comment}>{`"${auditLog?.comment}"`}</Typography>
          )  : 
        <></> : (<Skeleton height={100} width={"100%"}/>) }
      </Box>
      {/* User and Time Planned */}
      <Box className={classes.user}>
        {
          (auditLog?.userId && auditLog?.timeChanged) ?
          (
              <Typography color="textSecondary" variant="caption">{`${auditLog?.userId} - ${format(new Date(auditLog.timeChanged), 'd MMMM Y, HH:mm', { locale: DateFnsLanguageMap[i18n.language] })}`}</Typography>
          ) : <Skeleton width={"100%"} />
        }
      </Box>
    </Box>
) : (<></>)
};

export default PreviousStateTooltip;