import React, { createContext, useState } from 'react';
import { Hub } from 'aws-amplify';

const OUTBOX_STATUS = "outboxStatus";
const DATASTORE = "datastore"

export const DatastoreStatusContext = createContext();
 
export const DatastoreStatusProvider = ({ children }) => {
  const [isSynced, setSynced] = useState(true);

  // Hub listens to datastore events
  // if the outbox status isEmpty then local changes have been uploaded
  Hub.listen(DATASTORE, async ({payload: { event, data}}) => event === OUTBOX_STATUS && setSynced(data.isEmpty));

 return (
    <DatastoreStatusContext.Provider value={isSynced}>
      {children}
    </DatastoreStatusContext.Provider>
 );
};

