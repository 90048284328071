import React from 'react';
import { Typography } from '@material-ui/core';

export default ({style}) => {
  return (
    <>
      <Typography variant="h4" style={{ fontWeight: 100 , ...style}}>Marlin SmartPort&#8482;</Typography>
    </>
  );
};
